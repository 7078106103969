import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "./ReportsStyles";

// Import Constants
import { ReportsConstants } from "../../config/constants";

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";

// Import Helpers
import { generateAccessLevel } from "../../helpers/appHelper";

function DlaRole(props) {
  // get props
  const { classes } = props;

  // Set User Preference
  const { queryDLA } = useSelector((state) => state.profile.user);
  const { metadata } = useSelector((state) => state.metadata);

  return (
    <React.Fragment>
      <Grid item sm={12}>
        <Grid container className={classes.dashBoardInformation}>
          <TypographyComponent variant={"h6"} color="primary">
            <span className={classes.dashboardAlertTitle}>
              {ReportsConstants.ACCESSLEVEL_TITLE}
            </span>
            {queryDLA && generateAccessLevel(queryDLA, metadata)}
          </TypographyComponent>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

// default props
DlaRole.defaultProps = {
  classes: {},
};

// prop types
DlaRole.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(ReportsStyles)(DlaRole);
