/* eslint-disable indent */
/* eslint-disable no-dupe-keys */
const MEStyles = () => ({
  backimg: {
    width: "28px",
    height: "28px",
    background: "#FFFFFF",
    border: "1px solid #E2E2E2",
    padding: "3px 9px",
    borderRadius: "30px",
    cursor: "pointer !important"
  },
  table: {
    "& .MuiTableCell-root": {
      padding: "8px 16px",
      borderBottom: "none !important"
    }
  }
});

export default MEStyles;