import React, { useState } from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import MultipleDatesPicker from "../../local_modules/react-multiple-datepicker/lib/components/Datepicker/index.js";
import Button from "@mui/material/Button";
import moment from "moment"

// Imports Styles
import MultiDatePickerComponentStyle from "./MultiDatePickerComponentStyle";

// Import Images
import CalendarIcon from "../../assets/images/svg/calendar.svg";

/**
 * Multi Date Picker Component
 * @param {} props
 */
function MultiDatePickerComponent (props) {
  const [open, setOpen] = useState(false);
  const { classes, name } = props;
  let { value } = props;
  value = value ? value.map(item => new Date(item)) : [];

  /**
     * Handle Date Picker Select Event
     */
  const handleSelect = (dates) => {
    dates = dates.map(item => moment(new Date(item)).format("MM/DD/YYYY"))
    props.handleMultiDateSelect(name, dates);
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        fullWidth
        className={classes.button}
        disableElevation
        onClick={() => { setOpen(true); } }
        endIcon={<img src={CalendarIcon} alt="calendar" style={{ height: "16px", weight: "16px", position: "absolute", top: "5px", right: "14px" }} />}
      >
        {value.length} Date Selected
      </Button>

      <MultipleDatesPicker
        open={open}
        selectedDates={value || []}
        onCancel={() => setOpen(false)}
        onSubmit={dates => handleSelect(dates)}
        submitButtonText={"Ok"}
      />
    </div>
  );
}

// default props
MultiDatePickerComponent.defaultProps = {
  classes: {},
  name: "",
  value: [],
  handleMultiDateSelect: () => { }
};

// prop types
MultiDatePickerComponent.propTypes = {
  classes: PropTypes.object,
  name: PropTypes.string,
  value: PropTypes.array,
  handleMultiDateSelect: PropTypes.func
};

/** Export Component */
export default withStyles(MultiDatePickerComponentStyle)(MultiDatePickerComponent);
