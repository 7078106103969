/* eslint-disable no-unneeded-ternary */
/* eslint-disable camelcase */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import SvgIcon from "@mui/icons-material/ExpandMore";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Import Icons PhoneAccord
import { PhoneAccordGrey, PhoneAccordWhite } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";

// constants
import { surveySettingsConstants } from "../../../../config/constants";
import { surveyConstant } from "../../../../redux/constants";
import { userRoles } from "../../../../config/pageAccess";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData } from "../../../../helpers";

function PhonecollectionPanel(props) {
  const { classes, data, expanded, surveyPermission } = props;
  const admin_role = useSelector((state) => state.profile.user.role);
  const dispatch = useDispatch();
  if (data.distribute && data.distribute.audience === undefined) {
    data.distribute.audience = {
      select: ""
    };
  }

  /**
      * Handle Text area Change Event
      * @param {*} panel
    */
  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };
  /**
  * Handle Change Event
  * @param {*} panel
  */
  const handleChange = (event) => {
    if (data._id && !data.isLoading) {
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
   * Handle Tab Change Event
   * @param {*} name
   */
  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  return (
    <Accordion
      className="mb-2 pe-accordion"
      expanded={expanded === "phone-panel"}
      onChange={handleTabChange("phone-panel")}
    >

      {/*
        panel header summary
      */}

      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {expanded === "phone-panel" ?
          <Grid className={"pr5"}>
            {PhoneAccordWhite()}
          </Grid>
          :
          <Grid className={"pr5"}>
            {PhoneAccordGrey()}
          </Grid>
        }
        <TypographyComponent
          className={` ${classes.setting_accordheading} ${expanded === "phone-panel" ? "panel-active" : ""} `}
          variant={"h5"}
        >{surveySettingsConstants.PHONE_PANEL_TITLE}</TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "phone-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{surveySettingsConstants.PHONE_PANEL_MESSAGE}</TypographyComponent>
      </AccordionSummary>

      {/*
        panel body
      */}

      {(data.distribute && surveyPermission) &&
        <AccordionDetails>
          <Grid container spacing={3} className={`${classes.setting_expandcontainer} `}>
            <Grid item sm={12}>
              <Grid item md={12} xs={12} className="mb-2">
                <SwitchComponent
                  name="distribute.phone.active"
                  checked={surveyPermission.phoneAccess ? data.distribute.phone.active : surveySettingsConstants.CHECKED_FALSE}
                  disabled={(surveyPermission.phoneAccess && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS) ? admin_role !== userRoles.superadmin : true}
                  color="primary"
                  handleChange={(e) => handleChange(e)}
                  label={
                    <div>
                      <TypographyComponent
                        variant="h5"
                        className="pt-2"
                      >
                        {surveySettingsConstants.PHONE_SELECT_TITLE}
                      </TypographyComponent>
                      <TypographyComponent
                        variant="h6"
                        className="txtgrey"
                      >
                        {surveySettingsConstants.PHONE_SELECT_MESSAGE}
                      </TypographyComponent>
                    </div>
                  }
                />
              </Grid>
              {/*
                Displays when phoneaccess toggle enabled and phoneaccess permission from client
              */}

              {(surveyPermission.phoneAccess === true && data.distribute.phone.active === true) && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS &&
                <Grid item sm={12} className={`${"pl-5"} ${"pt-13"}`}>
                  <Grid container spacing={3}
                    className={classes.settings_expandinsidediv}>
                    <Grid item md={6} xs={12}>
                      <TypographyComponent
                        variant="h6" >{surveySettingsConstants.MAX_ATTEMPT}</TypographyComponent>
                      <SelectFieldComponent
                        name="distribute.phone.remainder"
                        variant="outlined"
                        disabled={admin_role !== userRoles.superadmin}
                        className={classes.width135}
                        value={data.distribute.phone.remainder}
                        handleChange={(e) => handleChange(e)}
                        fullWidth={false}
                        options={surveySettingsConstants.ATTEMPT_DROPDOWN}
                      >
                      </SelectFieldComponent>

                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TypographyComponent variant="h6">
                        {surveySettingsConstants.CALLER_DETAIL}
                        <InformationComponent>
                          <TypographyComponent variant="tooltipbody">
                            {surveySettingsConstants.CALLER_DETAIL_HELP}
                          </TypographyComponent>
                        </InformationComponent>
                      </TypographyComponent>
                      <TextareaAutosize
                        className="width100 pe_textarea mb-m5 borderRadius6"
                        name="distribute.phone.details"
                        value={data.distribute.phone.details || ""}
                        onChange={handleTextChange}
                        disabled={admin_role !== userRoles.superadmin}
                        minRows={3} />
                    </Grid>
                  </Grid>
                </Grid>
              }

              {/*
                Displays when no phone access is given from client
              */}

              {
                (surveyPermission.phoneAccess === false || (data.distribute.phone.active === false && admin_role !== userRoles.superadmin)) &&
                <Grid container spacing={0}
                  className={classes.settings_expandinsidediv}>
                  <TypographyComponent variant="h6" >
                    {surveySettingsConstants.PHONE_DISABLED_MESSAGE}
                  </TypographyComponent>
                </Grid>
              }
            </Grid>
          </Grid>
        </AccordionDetails>
      }
    </Accordion>
  );
}

// default props
PhonecollectionPanel.defaultProps = {
  classes: {},
  data: {},
  expanded: false,
  handleTabChange: () => { },
  surveyPermission: {}
};

// prop types
PhonecollectionPanel.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  expanded: PropTypes.any,
  handleTabChange: PropTypes.func,
  surveyPermission: PropTypes.object
};

export default (PhonecollectionPanel);
