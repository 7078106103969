// People reducer types
export const peopleConstant = {
    // To Get Particular Participant Details
    PEOPLE_GET_ONE_REQUEST: "PEOPLE_GET_ONE_REQUEST",
    PEOPLE_GET_ONE_SUCCESS: "PEOPLE_GET_ONE_SUCCESS",
    PEOPLE_GET_ONE_FAILURE: "PEOPLE_GET_ONE_FAILURE",

    // To Update GetOne Reducer state
    PEOPLE_GET_ONE_UPDATE: "PEOPLE_GET_ONE_UPDATE",

    // To Store Init Profile
    PEOPLE_GET_ONE_INIT: "PEOPLE_GET_ONE_INIT",

    // To Get Particular Participant Details
    PEOPLE_GET_ALL_REQUEST: "PEOPLE_GET_ALL_REQUEST",
    PEOPLE_GET_ALL_SUCCESS: "PEOPLE_GET_ALL_SUCCESS",
    PEOPLE_GET_ALL_FAILURE: "PEOPLE_GET_ALL_FAILURE",

    // To update Participant details
    PEOPLE_UPDATE_REQUEST: "PEOPLE_UPDATE_REQUEST",
    PEOPLE_UPDATE_SUCCESS: "PEOPLE_UPDATE_SUCCESS",
    PEOPLE_UPDATE_FAILURE: "PEOPLE_UPDATE_FAILURE",
};
