import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../SurveyPreviewStyles";

// Import PreviewTakeSurvey Header
import TakeSurveyHeader from "./TakeSurveyHeader";
import TakeSurveyFooter from "./TakeSurveyFooter";
import TakeSurveyForm from "./TakeSurveyForm";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

// Import Container
import Container from "../../../Containers/Container";

//import constants
import { TakeSurveyConstants } from "../../../../config/constants";

// Import Actions
import { surveyPreviewAction } from "../../../../redux/actions";

function TakeSurvey(props) {
  //get props
  const { classes, match } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  // const surveyId = useSelector((state) => state.survey.previewSurveyId);
  const survey = useSelector((state) => state.surveyPreview.getOne);
  const langCode = useSelector((state) => state.surveyPreview.surveyLang);
  const questionData = useSelector((state) => state.surveyPreview.questions);
  const { languages, survey_name } = questionData;
  const completionPercentage = useSelector(
    (state) => state.surveyPreview.completionPercentage
  );

  const [activeLanguage, setActiveLanguage] = useState(langCode);
  useEffect(() => {
    dispatch(surveyPreviewAction.updateSurveyLanguage(activeLanguage));
  }, [activeLanguage]);

  const handleSurveyLanguage = (lang) => {
    setActiveLanguage(lang.code);
  };
  const activeLangObject = languages.find((lang) => lang.code == langCode);

  const surveyTitle = survey_name && survey_name[langCode] ? survey_name[langCode] : (survey.name ? survey.name : TakeSurveyConstants.TAKESURVEY_TITLE);


  return (
    <React.Fragment>
      <Grid container  aria-live="polite" aria-relevant="text">
        <TakeSurveyHeader langCode={langCode} match={match} />
        <Container page={"takeSurvey"}>
          <Grid item className={classes.takeSurvey_container}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <TypographyComponent variant="h3" className="txtRegular">
                  {surveyTitle}
                </TypographyComponent>
              </Grid>
              <Grid item className={"desktopShow"}>
                <TypographyComponent
                  variant="h6"
                  className="colorTheme"
                  align="right"
                >
                  {`${completionPercentage}% `}
                  {TakeSurveyConstants.TAKESURVEY_COMPLETED}
                </TypographyComponent>
              </Grid>
            </Grid>

            {/* Mobile Only Grid Starts */}
             <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              className="mobileShow"
            >
              <Grid item sm={6}>
               {/*  {languages.length > 1 ? (
                  <ValidatorForm onSubmit={() => {}}>
                    <AutoCompleteComponent
                      name={"clients"}
                      suggestions={languages}
                      handleAutoCompolete={handleSurveyLanguage}
                      value={activeLangObject}
                      placeholder={"Select Language"}
                      validators={[]}
                    ></AutoCompleteComponent>
                  </ValidatorForm>
                ) : null} */}
              </Grid>

              <Grid item sm={6}>
                <TypographyComponent variant="h6" className="colorTheme">
                  {`${completionPercentage}% `}
                  {TakeSurveyConstants.TAKESURVEY_COMPLETED}
                </TypographyComponent>
              </Grid>
            </Grid>
            {/* Mobile Only Grid Ends */}

            <Grid container className="mt-1">
              <Grid item>
                <TypographyComponent variant="body1">
                  {survey.description ? (survey.description[langCode] && survey.description[langCode] !== "" ? survey.description[langCode] : survey.description["en"] ) : null}
                </TypographyComponent>
              </Grid>
            </Grid>
            <TakeSurveyForm
              classes={classes}
              questionData={questionData}
              langCode={langCode}
            />
          </Grid>
        </Container>
        <TakeSurveyFooter langCode={langCode} />
      </Grid>
    </React.Fragment>
  );
}
// default props
TakeSurvey.defaultProps = {
  classes: {},
};

// prop types
TakeSurvey.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(TakeSurvey);
