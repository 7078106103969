import { externalApiConstant } from "../constants/externalApiConstant";

const initialState = {
        data_sync: false,
        type:"",
        api_key : "",
        report_id : "",
        sub_domain : "",
};

export default function (state = initialState, action) {  
  switch (action.type) {
    case externalApiConstant.POST_EXTERNAL_API_CONFIG:
      return {
        ...state
      };
      case externalApiConstant.POST_EXTERNAL_API_CONFIG_SUCCESS:
      return {
        ...state,
        data_sync: action?.data?.data_sync ? action.data.data_sync : false,
        type:action?.data?.type  ? action.data.type  : "",
        api_key : action?.data?.api_key ? action.data.api_key : "",
        report_id : action?.data?.report_id ? action.data.report_id  : "",
        sub_domain : action?.data?.sub_domain ? action.data.sub_domain : ""
      };
      case externalApiConstant.POST_EXTERNAL_API_CONFIG_FAILURE:
      return {
        ...state
      };

      case externalApiConstant.GET_EXTERNAL_API_CONFIG_SUCCESS:
        return {
            ...state,
            data_sync: action?.data?.data_sync ? action.data.data_sync : false,
            type:action?.data?.type  ? action.data.type  : "",
            api_key : action?.data?.api_key ? action.data.api_key : "",
            report_id : action?.data?.report_id ? action.data.report_id  : "",
            sub_domain : action?.data?.sub_domain ? action.data.sub_domain : ""
          };

      case externalApiConstant.PUT_EXTERNAL_API_HRIS_CONFIG:
        return {
          ...state,
          type: action.params ? action.params : ""
        };
    default:
      return state;
  }
}
