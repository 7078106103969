/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";

// Import Styles and Image
import PeopleStyles from "./PeopleStyles";

import { ManageParticipants, ManageUserRole, Demographics } from "../../assets/images/svgComponents/peopleSVG";

// Import Cofig Constants
import { peopleConstants } from "../../config/constants";

// Import Container
import Container from "../Containers/Container";

// Import History
import history from "../../config/history";

// Import Actions
import { adminsAction, profileAction } from "../../redux/actions";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

function People(props) {
  const { classes, match } = props;

  // Define Dispatch
  const dispatch = useDispatch();
  //get reducer data
  const { access, role, lastName, firstName, surveyAccess, _id } = useSelector((state) => state.profile.user);
  const { surveys } = useSelector(state => state.admin.surveyAccess);
  const allClientSurveys = surveyAccess && surveyAccess.surveys ? surveyAccess.surveys : null;

  // get all client surveys
  useEffect(() => {
    // if (!_id) {
      dispatch(adminsAction.getSurveys());
      dispatch(profileAction.user());
    // }
  }, [dispatch]);

  const handleChange = (route) => {
    history.push(`${match.url}/${route}`);
  };

  return (
    <Container align={"center"}>
      <Grid className="centercontainer">
        <Grid container>
          <Grid item xs={12} className="mt-5">
            <Grid container direction="row" justifyContent="center" alignItems={"center"}>

              <Grid item xs={12} className="mb-8">
                <TypographyComponent
                  title={(role === "superadmin" || access.indexOf("Survey") > -1) ? `${peopleConstants.WELCOME_TEXT}` : `Welcome ${firstName} ${lastName}`}
                  variant={"h1"}
                  className={"txtRegular"}
                  align={"center"}></TypographyComponent>
                <Grid className={"pt-1"}>
                  <TypographyComponent
                    className={"txtSemiBold txtgrey"}
                    variant={"h3"}
                    align={"center"}
                    title={peopleConstants.WELCOME_SUB_TEXT}>
                  </TypographyComponent>
                </Grid>
              </Grid>

              <Grid item>
                <Grid container direction="column" alignItems={"center"} className={`${classes.people_tile} ${"people_tile"} `} >
                  <Grid className={classes.people_tile_img}>
                    {/* <img className={classes.people_tile_image} src={ParticipantImg} alt={peopleConstants.MANAGE_PATICIPANTS} /> */}
                    {ManageParticipants()}
                  </Grid>
                  <Grid className="pt-5 pb-1">
                    <TypographyComponent
                      variant={"h3"}
                      align={"center"}
                      className="txtBold"
                      title={peopleConstants.MANAGE_PATICIPANTS}>
                    </TypographyComponent>
                  </Grid>
                  <Grid className={"txtcenter"}>
                    <TypographyComponent
                      variant={"h6"}
                      align={"center"}
                      title={peopleConstants.MANAGE_PATICIPANTS_TEXT}>
                    </TypographyComponent>
                  </Grid>
                  <ButtonComponent
                    title={peopleConstants.MANAGE_PATICIPANTS_BTN}
                    color={"primary"}
                    size={"medium"}
                    pageClassName="mt-3"
                    handleClick={() => handleChange("participants")}
                  // to={"/surveys/new"}
                  ></ButtonComponent>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction="column" alignItems={"center"} className={`${classes.people_tile} ${"people_tile"} `}>
                  <Grid className={classes.people_tile_img}>
                    {/* <img className={classes.people_tile_image} src={DemographicsImg} alt="Manage Surveys" /> */}
                    {Demographics()}
                  </Grid>
                  <Grid className="pt-5 pb-1">
                    <TypographyComponent
                      variant={"h3"}
                      align={"center"}
                      className="txtBold"
                      title={peopleConstants.SETUP_DEMOGRAPHY}>
                    </TypographyComponent>
                  </Grid>
                  <Grid className={"txtcenter"}>
                    <TypographyComponent
                      variant={"h6"}
                      align={"center"}
                      title={peopleConstants.SETUP_DEMOGRAPHY_TEXT}>
                    </TypographyComponent>
                  </Grid>
                  <ButtonComponent
                    title={peopleConstants.SETUP_DEMOGRAPHY_BTN}
                    color={"primary"}
                    size={"medium"}
                    pageClassName="mt-3"
                    handleClick={() => handleChange("demographics")}
                  // to={"/surveys/new"}
                  ></ButtonComponent>
                </Grid>
              </Grid>

              {(role === "clientadmin" && allClientSurveys.length === surveys.length) || role !== "clientadmin" ?
                <Grid item >
                  <Grid container direction="column" alignItems={"center"} className={`${classes.people_tile} ${"people_tile"} `}>
                    <Grid className={classes.people_tile_img}>
                      {/* <img className={classes.people_tile_image} src={UserrolesImg} alt="Create New Survey" /> */}
                      {ManageUserRole()}
                    </Grid>
                    <Grid className="pt-5 pb-1">
                      <TypographyComponent
                        variant={"h3"}
                        align={"center"}
                        className="nowrap txtBold"
                        title={peopleConstants.MANAGE_USER_ROLES}>
                      </TypographyComponent>
                    </Grid>
                    <Grid className={"txtcenter"}>
                      <TypographyComponent
                        variant={"h6"}
                        align={"center"}
                        title={peopleConstants.MANAGE_USER_ROLES_TEXT}>
                      </TypographyComponent>
                    </Grid>
                    <ButtonComponent
                      title={peopleConstants.MANAGE_USER_ROLES_BTN}
                      color={"primary"}
                      size={"Medium"}
                      pageClassName="mt-3"
                      handleClick={() => handleChange("admins")}
                    // to={"/surveys/new"}
                    ></ButtonComponent>
                  </Grid>
                </Grid> : null}

            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container >
  );
}

// default props
People.defaultProps = {
  classes: {}
};

// prop types
People.propTypes = {
  classes: PropTypes.object
};

export default withStyles(PeopleStyles)(People);
