/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveysStyles from "./SurveysStyles";

// Import Constants
import { surveyConstants, surveyTypes } from "../../config/constants";
import { surveyConstant } from "../../redux/constants";
import { getTableHeight, getTableRowSize } from "../../config/layout";

// Import History
import history from "../../config/history";

// Import Container
import Container from "../Containers/Container";

// Import Component
import TableComponent from "../../components/TableComponent/TableComponent";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import DeleteDialogComponent from "../../components/DeleteDialogComponent/DeleteDialogComponent";
import PreviewDialogComponent from "../../components/PreviewDialogComponent/PreviewDialogComponent";
import SurveyPreview from "./SurveyPreview/SurveyPreview";

// Import Actions
import { surveyAction, updateStateReducerAction } from "../../redux/actions";

// Import helper
import { getQueryVariable } from "../../helpers";

function SurveyList(props) {
  // Define Dispatch
  const dispatch = useDispatch();
  const tableHeight = getTableHeight();
  const tableRowSize = getTableRowSize();
  const { classes } = props;

  // Get Survey List
  const surveyList = useSelector(state => state.survey.search);
  let { data, page } = surveyList;

  // To display syrvey type as new hire for onboarding templates
  data.map((val) => {
    if( val.type && val.type === surveyTypes.ONBOARDING_WEEK_TWO || val.type === surveyTypes.ONBOARDING_WEEK_FIVE ) {
      val.type = surveyTypes.NEW_HIRE
    }
  })

  const clientSelected = useSelector(state => state.profile.user);
  const clientId = clientSelected && clientSelected.client_id ? clientSelected.client_id : null;

  // Get Survey Preview Status
  const surveyPreview = useSelector(state => state.survey.previewSurveyStatus);

  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    id: null,
    content: "",
    open: false
  });

  // Get Page Reset 
  const isPageReset = getQueryVariable(props.location.search, "reset") === "true";

  /**
   * Get Survey List
   */
  useEffect(() => {
    if (!isPageReset) {
      dispatch(surveyAction.search(1, tableRowSize, "status", "desc", [], []));
    }
  }, [dispatch]);

  /**
   * Reset all search text box to empty
   */
  useEffect(() => {
    if (isPageReset) {
      dispatch(surveyAction.search(1, tableRowSize, "status", "desc", [], []));
      history.replace(props.location.pathname);
    }
  }, [isPageReset]);

  /**
     * Handle Table pagination Event
     */
  const onChangePagination = (skip, limit) => {
    dispatch(surveyAction.search(skip, limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Sort Request Event
   */
  const onSortRequest = (sortBy, sort) => {
    dispatch(surveyAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey, userChange) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if (!isPageReset) {
      dispatch(surveyAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
    }
  };

  /**
   * Clear Search Text By Field
   * @param {*} searchField
   * @param {*} searchKey
   */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(surveyAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
  };

  /**
    * Handle Table Navigation Event
    */
  const handleNavigateAction = (id) => {
    // dispatch(formStepperAction.setPage(1));
    history.push({
      pathname: `/surveys/${id}/questions`
    });
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_CATEGORY_ACTIVE_TAB, 0));
  };

  /**
   * Handle SETTINGS, PREVIEW and COPY Button Event
   * @param {*} field
   * @param {*} id
   */
  const handelActionButtonClick = (field, id, name) => {
    switch (field) {
      case "SETTINGS":
        history.push({
          pathname: `/surveys/${id}/settings`
        });
        break;
      case "COPY":
        dispatch(surveyAction.copy({ _id: id, name: name }, () => {
          dispatch(surveyAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
        })
        );
        break;
      case "PREVIEW":
        dispatch(surveyAction.handleSurveyPreview({ _id: id, preview: true }));
        break;
      default:
        break;
    }
  };

  /**
   * Check Delete Survey Have Response or Not
   * @param {*} id
   */
  const customDeleteClickHandle = (id) => {
    dispatch(surveyAction.remove({ _id: id, confirm: false }, () => {
      setOpenDeleteDialog({
        id: id,
        open: true,
        content: surveyConstants.DELETE_CONTENT
      });
    })
    );
  };

  /** Delete Function*
   * @param {*} field
   * @param {*} id
   */
  const handleTableDeleteAction = () => {
    setOpenDeleteDialog({
      id: null,
      content: surveyConstants.DELETE_CONTENT,
      open: false
    });

    dispatch(surveyAction.remove({ _id: openDeleteDialog.id, confirm: true }, () => {
      // retain old search values
      if (page.skip) {
        dispatch(surveyAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
      } else {
        dispatch(surveyAction.search(1, tableRowSize, "status", "desc", [], []));
      }
    })
    );
  };

  // Handle Dialog Delete Item Cancel Event
  const handleTableDeleteOnCloseAction = () => {
    setOpenDeleteDialog({
      id: null,
      content: surveyConstants.DELETE_CONTENT,
      open: false
    });
  };

  // Handle Dialog Delete Item Cancel Event
  const closeSurveyPreview = () => {
    dispatch(surveyAction.handleSurveyPreview({ _id: null, preview: false }));
  };

  return (
    <Container align={"baseline"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <TypographyComponent
                variant={"h3"}
                title={surveyConstants.MY_SURVEYS} />
            </Grid>
            <Grid item>
              {page && page.sFields && page.sFields.length > 0 &&
                <ButtonComponent
                  form="clear-table-search"
                  title={"Clear all"}
                  color={"primary"}
                  size={"small"}
                  type="submit"
                  variant={"text"}
                  pageClassName="mr-1"
                  handleClick={() => {
                    history.replace(`${props.location.pathname}?reset=true`);
                  }}
                />
              }
              <ButtonComponent
                title={surveyConstants.LIST_BUILD_SURVEY_TEXT}
                color={"primary"}
                size={"small"}
                type="submit"
                component={Link}
                to={"/surveys/new"}
              ></ButtonComponent></Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="survey-list">
          <TableComponent
            data={data}
            header={page.fieldFilters.headerCols}
            field={page.fieldFilters.fieldCols}
            search={page.fieldFilters.searchCols}
            actions={["SETTING", "PREVIEW", "COPY", "DELETE"]}
            removeTooltipCols={["type", "startDate", "endDate", "frequency", "status"]}
            height={tableHeight}
            page={page.skip}
            count={page.count}
            rowsPerPage={page.limit}
            links={[page.fieldFilters.fieldCols[0]]}
            sort={page.sort}
            sortBy={page.sortBy}
            sFields={page.sFields}
            sValues={page.sValues}
            noDataErrorMessage={"No Records Found"}
            paginationVisible={!page.lastPage}
            alignCenterFields={["status"]}
            deleteContent={surveyConstants.DELETE_CONTENT}
            handleNavigateAction={handleNavigateAction}
            onChangePagination={onChangePagination}
            onSortRequest={onSortRequest}
            onInputChangeRequest={onInputChangeRequest}
            handelActionButtonClick={handelActionButtonClick}
            handleTableDeleteAction={handleTableDeleteAction}
            handleClearSearchByField={handleClearSearchByField}
            resetSearchText={isPageReset}
            customDelete={true}
            customOpenDeleteDialog={openDeleteDialog}
            customDeleteClickHandle={customDeleteClickHandle}
          >
          </TableComponent>

          <DeleteDialogComponent
            id={openDeleteDialog.id}
            open={openDeleteDialog.open}
            content={openDeleteDialog.content}
            handleDeleteAction={handleTableDeleteAction}
            handleDeleteOnCloseAction={handleTableDeleteOnCloseAction}
          />
        </Grid>
      </Grid>

      <PreviewDialogComponent className={classes.surveyPreview} fullScreen={true} open={surveyPreview} handleClosePreview={closeSurveyPreview}>
        <SurveyPreview />
      </PreviewDialogComponent>
    </Container>
  );
}

export default withStyles(SurveysStyles)(SurveyList);
