import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

// import actions
// import { alertAction } from "../../../../../redux/actions";

// import components
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";

// import Constants
import { surveyConstant } from "../../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../../redux/actions";

// import images
import { PencilEdit } from "../../../../../assets/images/svgComponents/ImageComponent";

function EditAnswerSetName (props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, tabarray, answerSet, sets, activeAnswerSetTab, currentTab } = props;

  // define state
  const [editAnswerSetName, setEditAnswerSetName] = useState(currentTab);
  const [editAnswerSetPopup, setEditAnswerSetPopup] = useState(null);

  useEffect(() => {
    setEditAnswerSetName(currentTab);
  }, [dispatch, tabarray]);

  // Update reducer data
  const updateReducer = (answerSet, ...updateFields) => {
    updateFields.map((item, index) => {
      answerSet = { ...answerSet, item };
    });
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_EDITANSWERSET_REDUCER_UPDATE, answerSet));
  };

  useEffect(() => {
    setEditAnswerSetName(tabarray[activeAnswerSetTab]);
  }, [tabarray, answerSet, sets]);

  // Anchor tag events
  const handleEditClick = (event) => {
    setEditAnswerSetPopup(event.currentTarget);
  };
  const handleClose = () => {
    setEditAnswerSetName(tabarray[activeAnswerSetTab]);
    setEditAnswerSetPopup(null);
  };

  const open = Boolean(editAnswerSetPopup);
  const id = open ? "edit-category-popover" : undefined;

  // Edit answer set name
  const saveeditAnswerSetName = (editAnswerSetName) => {
    let isDuplicateCategory = false;
    let isSameCatName = false;
    tabarray.map((cat, ind) => {
      if (editAnswerSetName.toLowerCase() === tabarray[activeAnswerSetTab].toLowerCase()) {
        isSameCatName = true;
        isDuplicateCategory = true;
      } else if (cat.toLowerCase() === editAnswerSetName.toLowerCase()) {
        isDuplicateCategory = true;
      }
    });
    if (editAnswerSetName && !isDuplicateCategory) {
      sets[editAnswerSetName] = sets[tabarray[activeAnswerSetTab]];
      delete sets[tabarray[activeAnswerSetTab]];
      tabarray[activeAnswerSetTab] = editAnswerSetName;
      updateReducer(answerSet, sets, tabarray);
      handleClose();
    } else if (!editAnswerSetName) {
      dispatch(alertAction.error("Answer set name is required"));
    } else if (isSameCatName) {
      handleClose();
    } else {
      dispatch(alertAction.error("Category already exists"));
    }
  };

  return (
    <React.Fragment>
      <div className={`${classes.peTabEdit} ${"peTabEdit pr5 pl5 pt5"}`} onClick={handleEditClick}>{PencilEdit()}</div>
      <Popover
        id = {id}
        open={open}
        anchorEl={editAnswerSetPopup}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          root: classes.editAnswerSetNamePopover
        }}
      >
        <Grid className={`${classes.quest_addtab} ${"p15"}`}>
          <TextFieldComponent
            name="catname"
            value={editAnswerSetName}
            placeholder={"Enter answer set name"}
            margin={"dense"}
            autoComplete="off"
            handleChange={(e) => {
              setEditAnswerSetName(e.value);
            }}
            className={"mb-1"}
            validators={["required"]}
          ></TextFieldComponent>
          <Button
            title={"Save"}
            size={"small"}
            variant="contained"
            color="primary"
            className={classes.add_catbtn}
            disableElevation
            onClick={() => {
              saveeditAnswerSetName(editAnswerSetName);
            }}
          >
            Save
          </Button>
          <Button
            title={"Cancel"}
            variant="contained"
            color="secondary"
            disableElevation
            size={"small"}
            className={`${"ml-1"} ${classes.add_catbtn}`}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Popover>
    </React.Fragment>
  );
}

// default props
EditAnswerSetName.defaultProps = {
  classes: {},
  tabarray: [],
  currentTab: "",
  answerSet: {},
  sets: {},
  activeAnswerSetTab: 0
};

// prop types
EditAnswerSetName.propTypes = {
  classes: PropTypes.object,
  tabarray: PropTypes.array,
  currentTab: PropTypes.string,
  answerSet: PropTypes.object,
  sets: PropTypes.object,
  activeAnswerSetTab: PropTypes.number
};

export default EditAnswerSetName;
