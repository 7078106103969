import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch, Route } from "react-router-dom";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import MeSurveyStyles from "./MeSurveyStyles";

// Import Actions
import { meTakeSurveyAction } from "../../redux/actions";

// Import Take Survey Components
import MeSurveyWelcome from "./MeSurveyWelcome";
import MeTakeSurvey from "./MeTakeSurvey";

function MeSurvey(props) {
    //get props
    const { match, takeSurveyUrl, queryString, surveyDeviceType, classes } = props;
    const { params } = match;
    const shortUrl = params.survey_url;

    // Define Dispatch
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <Switch>
                <Route
                    exact
                    path={match.url}
                    render={(props) => {
                        return <MeSurveyWelcome {...props} shortUrl={shortUrl} />
                    }}
                />
                <Route
                    exact
                    path={`${match.url}/:from/:to`}
                    render={(props) => {
                        return <MeTakeSurvey {...props} shortUrl={shortUrl} />
                    }}
                />
            </Switch>
        </React.Fragment>
    );
}

// default props
MeSurvey.defaultProps = {
    classes: {},
};

// prop types
MeSurvey.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(MeSurveyStyles)(MeSurvey);
