import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import ButtonBase from "@mui/material/ButtonBase";

// Imports Styles
import ButtonBaseComponentStyle from "./ButtonBaseComponentStyle";

/**
 * Button Base Component
 * @param {} props
 */
function ButtonBaseComponent(props) {
  const { className, children, classes, defaultValue, disabled } = props;
  let clsName = `${classes[className]} ${classes["root"]}`;

  /**
     * Handle
     * TextField Events
     */
  const handleButtonBaseChange = (event) => {
    props.handleButtonBaseChange(defaultValue)
  };

  return (
    <ButtonBase
      disabled={disabled}
      data-test="buttonBase-test"
      className={clsName}
      onClick={handleButtonBaseChange}
      defaultValue={defaultValue}>
      {children}
    </ButtonBase>
  );
}

// default props
ButtonBaseComponent.defaultProps = {
  className: "btnbase_comp",
  disabled: false,
  children: {},
  classes: {},
  defaultValue: "",
  handleButtonBaseChange: () => { }
};

// prop types
ButtonBaseComponent.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any,
  classes: PropTypes.object,
  defaultValue: PropTypes.string,
  handleButtonBaseChange: PropTypes.func
};

/** Export Component */
export default withStyles(ButtonBaseComponentStyle)(ButtonBaseComponent);
