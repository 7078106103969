import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import moment from "moment";

// Import Components
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import Tooltip from "@mui/material/Tooltip";
import MultiSelectComponent from "../../../../../components/MultiSelectComponent/MultiSelectComponent";
import SwitchComponent from "../../../../../components/SwitchComponent/SwitchComponent";
import DatePickerComponent from "../../../../../components/DatePickerComponent/DatePicker";
import { TimePickerValidator } from "../../../../../components/TimePickerComponent/TimePicker";
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";

// constants
import { surveySettingsConstants, errorsConstants } from "../../../../../config/constants";
import { surveyConstant } from "../../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, surveyAction, alertAction } from "../../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData, isTranslated, iseditedLangtxt } from "../../../../../helpers";

// Import SVG Icons
import { PaperPlane, Delete } from "../../../../../assets/images/svgComponents/ImageComponent";


function ManagerAlerts(props) {
  const { classes, data, langcode, surveyPermission, translatedFields, panel } = props;
  const dispatch = useDispatch();
  const [selectedSystemID, setSelectedSystemID] = useState([]);

  // const { me_subjectID } = useSelector((state) => state.survey);

  const actionAlert_data = data.distribute.notification.me && data.distribute.notification.me.actionAlert ? data.distribute.notification.me.actionAlert : []

  const meData = data.distribute.notification.me && data.distribute.notification.me.schedule ? data.distribute.notification.me.schedule : [];
  if (data.distribute.audience === undefined) {
    data.distribute.audience = {
      select: ""
    };
  }

  // Setting default time for distribute me time
  if (meData.length > 0 && meData[0]?.time === '') {
    meData[0].time = '9:00 AM'
  }

  // useEffect(() => {
  //   dispatch(surveyAction.getMESystemID())
  //   // dispatch(surveyAction.getActionAlert(data._id || ""))
  // }, [dispatch]);

  /**
   * Handle Action Coach Data
   * @param {*} panel
   */
  const handleActionCoachData = async (event, index) => {
    if (data._id && !data.isLoading) {
      let { name, value } = event;
      let scheduleData = data.distribute.notification.me ? data.distribute.notification.me.schedule : [];
      if (name === "triggerDay") {
        value = moment(value).format("MM/DD/YYYY");
      }
      scheduleData[index][name] = value;
      scheduleData[index]["new"] = true
      let reducerData = data;
      reducerData.distribute.notification.me.schedule = scheduleData;

      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
      * Handle Change Event
      * @param {*} panel
      */
  const handleChange = (event) => {
    if (data._id && !data.isLoading) {
      if (event.name === "distribute.notification.me.schedule.type" && event.value === data.distribute.notification.me.schedule.type) {
        event["value"] = "";
      }
      setSelectedSystemID(data)
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };


  /**
    * Handle Add Action 
    */
  function handleAddAction(e) {
    e.preventDefault();
    let reducerData = [
      ...meData,
      {
        triggerDay: "",
        time: "9:00 AM",
        topic: "",
        systemID: [],
        attachment: "",
        new: true
      }
    ]
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_ACTION_COACH_LIST_UPDATE, reducerData));
  }

  //autocomplete component change
  // const handleAutoCompleteChange = (event) => {
  //   const selectedVal = event.value;
  //   const action = event.action
  //   let selectedSystemIds = [];
  //   let { name, key } = event;
  //   let scheduleData = data.distribute.notification.me ? data.distribute.notification.me.schedule : [];
  //   /* if (name === "triggerDay") {
  //     value = moment(value).format("MM/DD/YYYY");
  //   } */
  //   // const isAllSelected = selectedVal.find((ele) => ele === "Select All");
  //   // if (isAllSelected) {
  //   //   if (action === "selectOption") {
  //   //     JSON.parse(JSON.stringify(allSurveys)).forEach((val) => {
  //   //       selectedSurveyIds.push(val._id);
  //   //       selectedSurveyNames.push(val.name);

  //   //     })
  //   //     setSelectedSystemID([...selectedSystemIds]);
  //   //   }
  //   //   else {
  //   //     selectedVal.forEach((val) => {
  //   //       if (val !== "Select All") {
  //   //         let surveyData = JSON.parse(JSON.stringify(allSurveys)).find((ele) => ele.name === val);
  //   //         selectedSystemIds.push(surveyData._id);
  //   //       }
  //   //     })
  //   //     setSelectedSystemID([...selectedSystemIds]);
  //   //   }
  //   // }
  //   // if(action === "removeOption") {
  //   if (me_subjectID.length === selectedVal.length && action === "removeOption") {
  //     setSelectedSystemID([]);
  //   }
  //   else if (me_subjectID.length === selectedVal.length && action === "selectOption") {
  //     JSON.parse(JSON.stringify(me_subjectID)).forEach((val) => {
  //       selectedSystemIds.push(val.empId);
  //     })
  //     setSelectedSystemID([...selectedSystemIds]);
  //   }
  //   else {
  //     selectedVal.forEach((val) => {
  //       let surveyData = JSON.parse(JSON.stringify(me_subjectID)).find((ele) => ele.empId === val);
  //       selectedSystemIds.push(surveyData.empId);
  //     })

  //     setSelectedSystemID([...selectedSystemIds]);
  //   }
  //   scheduleData[key][name] = selectedVal;
  //   let reducerData = data;
  //   reducerData.distribute.notification.me.schedule = scheduleData;

  //   dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
  //   // }
  // }

  const handleSelectContent = (e, key) => {
    if (data._id && !data.isLoading) {
      let scheduleData = data.distribute.notification.me ? data.distribute.notification.me.schedule : [];
      // const selectedValue = e.value;
      scheduleData[key]["content"] = e;
      let reducerData = data;
      reducerData.distribute.notification.me.schedule = scheduleData;

      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  }

  /**
    * Handle Add Action 
    */
  function handleRemoveAction(index) {
    if (data._id && !data.isLoading) {
      let reducerData = meData;
      if (reducerData.length > 1) {
        reducerData.splice(index, 1);
        dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_ACTION_COACH_LIST_UPDATE, reducerData));
      }
    }
  }

  /**
    * Handle Add Action 
    */
  function handleSendMail(index) {

    let scheduleData = data.distribute.notification.me ? data.distribute.notification.me.schedule : [];
    if (scheduleData.length > 0) {
      let currentSchedule = scheduleData[index];
      let surveyMailData = {
        _id: data._id,
        frequency: data.frequency,
        type: data.type,
        name: data.name,
        content_type: currentSchedule
      }
      if (currentSchedule.content && currentSchedule.content.value) {
        dispatch(surveyAction.sendActionCoachMail(surveyMailData));
      } else {
        dispatch(alertAction.error("Please select Content to send action coach report."))
      }
    } else {
      dispatch(alertAction.error("No schedule data found."))
    }
  }

  // const getSystemID = () => {
  //   // let surveyNames = ["Select All"]; // if need select all option
  //   let SystemID = [];
  //   JSON.parse(JSON.stringify(me_subjectID)).forEach((ele) => {
  //     if (!SystemID.includes(ele.empId)) {
  //       SystemID.push(ele.empId);
  //     }
  //   })
  //   return SystemID;
  // }

  return (
    <Grid item sm={12}>
      <Grid item md={12} xs={12} className="mb-2">
        <SwitchComponent
          name="distribute.notification.me.active"
          checked={surveyPermission.managerEffectiveness && data.distribute.notification.me ? data.distribute.notification.me.active : surveySettingsConstants.CHECKED_FALSE}
          disabled={(surveyPermission.managerEffectiveness && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? surveySettingsConstants.CHECKED_FALSE : surveySettingsConstants.CHECKED_TRUE}
          color="primary"
          handleChange={(e) => handleChange(e)}
          label={
            <span>
              <TypographyComponent variant="h5"
                className="pt-2">{surveySettingsConstants.ME_TOGGLE_TITLE}</TypographyComponent>
              <TypographyComponent variant="h6"
                className="txtgrey">{surveySettingsConstants.ME_TOGGLE_MESSAGE}</TypographyComponent>
            </span>
          }
        />
      </Grid>

      {/* Displays when sms toggle enabled and sms permission from client */}
      {(data.distribute.notification.me && data.distribute.notification.me.active === true && surveyPermission.managerEffectiveness === true) && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS &&
        <Grid item className={`${"pl-5"} ${"pt-13"}`}>
          <Grid container spacing={3}
            className={classes.settings_expandinsidediv}>

            {
              meData.map((val, key) => {
                let disabled = moment(val.triggerDay).format("MM/DD/YYYY") < moment(new Date()).format('MM/DD/YYYY')
                if (val.new) disabled = false
                return (
                  <Grid item xs={12} key={key}>
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item md={2} xs={6}>
                          <TypographyComponent variant="h6">
                            {surveySettingsConstants.SELECT_A_DAY}  *
                          </TypographyComponent>
                          <DatePickerComponent
                            name="triggerDay"
                            value={val.triggerDay}
                            className={classes.textField}
                            handleChange={(e) => handleActionCoachData(e, key)}
                            inputVariant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth={true}
                            disabled={false}
                            minDate={moment(new Date()).format('MM/DD/YYYY')}
                            validators={disabled ? ["required"] : ["required", "isFutureDate"]}
                            errorMessages={[errorsConstants.required, errorsConstants.invalidDateRange]}
                            panel={panel}
                          />
                        </Grid>
                        <Grid item md={2} xs={6}>
                          <TypographyComponent variant="h6">
                            {surveySettingsConstants.SELECT_TIME_MST}  *
                            <InformationComponent>
                              <TypographyComponent variant="tooltipbody">
                                {surveySettingsConstants.SMS_SELECT_TIME_MST_HELP}
                              </TypographyComponent>
                            </InformationComponent>
                          </TypographyComponent>
                          <TimePickerValidator
                            name="time"
                            fullWidth={true}
                            value={val.time ? val.time : "9:00 AM"}
                            panel="me"
                            onValueChange={(e) => {
                              handleActionCoachData(e, key)
                            }}
                            // panel={panel}
                          />
                        </Grid>
                        {/* 
                        <Grid item md={3} xs={6}>
                          <TypographyComponent variant="h6">
                            {surveySettingsConstants.ME_SELECT_ACTION_ALERT_SYSTEMID} *
                          </TypographyComponent>
                          {me_subjectID.length > 0 ?
                          <MultiSelectComponent
                            name={"systemID"}
                            className={`${classes.textField}`}
                            suggestions={getSystemID()}
                            selectedOptions={val.systemID ? val.systemID : ""}
                            handleAutoCompolete={(name, value, event, action) => {
                              handleAutoCompleteChange({ name, value, event, action, key });
                            }}
                            value={val.systemID}
                            // placeholder={participantConstants.SURVEY_ASSIGNED_DEFAULT_PLACEHOLDER}
                            isArrayObjects={true}
                            validators={["required"]}
                            errorMessages={[errorsConstants.required]}
                          />  :
                          <Select
                            className={"height40 width100"}
                            name={"SelectSubjectID"}
                            id="ID"
                            value={val.systemID ? val.systemID : ""}
                            displayEmpty
                            variant="outlined"
                          >
                            <MenuItem value="">Select a subject Id</MenuItem>
                          </Select>
                        }
                        </Grid> 
                        */}

                        <Grid item md={3} xs={6}>
                          <TypographyComponent variant="h6">
                            {surveySettingsConstants.ME_ACTION_COACH_SELECT_CONTENT}
                          </TypographyComponent>
                          <AutoCompleteComponent
                            name={"select_content"}
                            suggestions={surveySettingsConstants.ME_ACTION_COACH_SELECT_CONTENT_OPTIONS}
                            // suggestions={actionAlert_data}
                            handleAutoCompolete={(e) => handleSelectContent(e, key)}
                            value={val.content ? val.content : ''}
                            placeholder={"Select Content"}
                            validators={["required"]}
                          ></AutoCompleteComponent>
                        </Grid>


                        <Grid item md={2} xs={6}>
                          <TypographyComponent variant="h6">
                            &nbsp;
                          </TypographyComponent>
                          <div className={classes.actionCoachIcon}>
                            <div className="btnIcon" onClick={(e) => handleRemoveAction(key)}>
                              <Tooltip arrow title="Delete">
                                {Delete()}
                              </Tooltip>
                            </div>
                            <div className="btnIcon" onClick={(e) => handleSendMail(key)}>
                              <Tooltip arrow title="Send to subjects now">
                                {PaperPlane()}
                              </Tooltip>
                            </div>
                          </div>
                        </Grid>

                      </Grid>
                    </Grid>
                  </Grid>
                )
              })
            }



            <Grid item xs={12}>
              <Link href="#" onClick={(e) => handleAddAction(e)}>
                {surveySettingsConstants.ME_ADD_BUTTON}
              </Link>
              {/* <InformationComponent>
                <TypographyComponent variant="tooltipbody">
                  {surveySettingsConstants.SMS_SEND_BUTTON_HELP}
                </TypographyComponent>
              </InformationComponent> */}
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}

// default props
ManagerAlerts.defaultProps = {
  classes: {},
  data: {},
  langcode: "en",
  surveyPermission: {}
};

// prop types
ManagerAlerts.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  langcode: PropTypes.string,
  surveyPermission: PropTypes.object
};

export default (ManagerAlerts);
