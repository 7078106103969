import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import ParticipantList from "./ParticipantList";
import AddParticipant from "./AddParticipant";
import EditParticipant from "./EditParticipant";
import UnassignSurveyRouter from "./UnassignSurvey/UnassignSurvey.Router";
import AssignedDateReportRouter from "./AssignedDateReport/AssignedDateReport.Router";
import OverrideDemographicsRouter from "./OverrideDemographics/OverrideDemographics.Router";
import BulkDeleteRouter from "./BulkDeleteParticipants/BulkDelete.Router";
import ArchiveRouter from "./ArchiveParticipants/Archive.Router";
import UploadParticipantRouter from "./UploadParticipant/UploadParticipant.Router";
import Exports from "./Exports";
import MERouter from "./ME/ME.Router";

function ParticipantRouter(props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url} render={(props) => {
        return <ParticipantList
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/new`} render={(props) => {
        return <AddParticipant
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/:id/edit`} render={(props) => {
        return <EditParticipant
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/assignedDateReport`} render={(props) => {
        return <AssignedDateReportRouter
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/unassignSurvey`} render={(props) => {
        return <UnassignSurveyRouter
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/override/demographics`} render={(props) => {
        return <OverrideDemographicsRouter
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/excel`} render={(props) => {
        return <UploadParticipantRouter
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/exports`} render={(props) => {
        return <Exports
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/me-survey`} render={(props) => {
        return (
          <MERouter
            match={props.match}
            {...props} />
        );
      }} />
      <Route path={`${match.url}/bulkDelete`} render={(props) => {
        return <BulkDeleteRouter
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/archive`} render={(props) => {
        return <ArchiveRouter
          match={props.match}
          {...props} />;
      }} />
    </Switch>
  );
}

/**
 * Bind Component Property Types
 */
ParticipantRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default ParticipantRouter;
