import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const contentLibraryService = {
  getOne,
  update,
  remove,
  search,
  getOneReport,
  updateReport,
  searchReport,
  removeReport,
  getMessage,
  updateMessage,
  getBaseQusID,
  getAllQuestions,
  searchBaseQuestionID,
  searchQuestionCategory,
  updateRecommendation
};

/**
 * Get Content Library
 */
async function getOne() {
  const response = await apihelper.getRequest(`content-library/actionAlert`);
  return response;
}

/**
 * Update Content Library
 */
async function update(updatedData) {
  const response = await apihelper.putRequest(`content-library/actionAlert`, updatedData);
  return response;
}

/**
 * search Action alert/coach
 */
async function search(text) {
  const response = await apihelper.getRequest("content-library/actionAlert/search/" + text);
  return response;
};


/**
 * Delete Action alert/coach
 */
async function remove(deletedData) {
  const response = await apihelper.putRequest("content-library/actionAlert/remove", deletedData);
  return response;
};


/**
 * Get report Content Library
 */
async function getOneReport() {
  const response = await apihelper.getRequest(`content-library/reportContent`);
  return response;
}


/**
 * Update report Content Library
 */
async function updateReport(updatedData) {
  const response = await apihelper.putRequest(`content-library/reportContent`, updatedData);
  return response;
}


/**
 * search Report Content/coach
 */
async function searchReport(text) {
  const response = await apihelper.getRequest("content-library/reportContent/search/" + text);
  return response;
};


/**
 * Delete Report Content/coach
 **/
async function removeReport(deletedData) {
  const response = await apihelper.putRequest("content-library/reportContent/remove", deletedData);
  return response;
};

/**
* Get Content Library Message Data
*/
async function getMessage() {
  const response = await apihelper.getRequest(`content-library/getMessage`);
  return response;
}

/**
 * Update Content Library Message Data
 */
async function updateMessage(updatedData) {
  const response = await apihelper.putRequest(`content-library/updateMessage`, updatedData);
  return response;
}

/**
 * Get Base Questions Id's
 */
async function getBaseQusID() {
  const response = await apihelper.getRequest(`content-library/recommendations/getbasequsID`);
  return response;
}

/**
 * Get All questions
 */
 async function getAllQuestions() {
  const response = await apihelper.getRequest(`content-library/recommendations/getAllQuestions`);
  return response;
}

/**
 * search BaseQus ID Recommendations
 */
 async function searchBaseQuestionID(text) {
  const response = await apihelper.getRequest("content-library/recommendations/search/baseID" + text);
  return response;
};


/**
 * search Category / Question Recommendations
 */
async function searchQuestionCategory(text) {
  const response = await apihelper.getRequest("content-library/recommendations/search/" + text);
  return response;
};

/**
 * Update report Content Library
 */
async function updateRecommendation(updatedData) {
  const response = await apihelper.putRequest(`content-library/recommendations`, updatedData);
  return response;
}
