import React from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

// Assets
import settingsicon from "../../assets/images/svg/settings_demographic.svg";
import closeicon from "../../assets/images/svg/drawer_close.svg";

// Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";

// Import Config Layout
import { getDrawerHeight } from "../../config/layout";

// import constants
import { SpcallerConstants } from "../../config/constants";

function SelectColumn(props) {
    const { classes, open, toggleDrawer, page: { fieldFilters }, onSelectColumnChange } = props;
    const { visibleColumns, columnsMap } = fieldFilters;

    // Generate Default Columns
    const generateDefaultColumns = () => {
        return Object.keys(columnsMap).map((key) => {
            return (
                <Grid item className={classes.selectColumnCheckbox}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="isRequired"
                                color="primary"
                                checked={visibleColumns.indexOf(key) >= 0}
                                onChange={e => {
                                    const updatedVisibleColumns = Object.keys(columnsMap).filter((colkey) => {
                                        if (!e.target.checked && key === colkey) {
                                            return false;
                                        } else if (e.target.checked && key === colkey) {
                                            return true
                                        } else return visibleColumns.indexOf(colkey) !== -1;
                                    });
                                    onSelectColumnChange(updatedVisibleColumns);
                                }}
                            />
                        }
                        label={columnsMap[key]}
                    />
                </Grid>
            )
        });
    };

    return (
        <Drawer
            className={`${classes.pe_drawer} ${"pe_drawer"}`}
            anchor='right'
            PaperProps={{
                style: {
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    height: getDrawerHeight() + 20
                }
            }}
            open={open}
            onClose={() => {
                toggleDrawer(false);
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={`${"pr-1 pl-1 borderBottom"} ${classes.drawerTitle}`}
            >
                <Grid item>
                    <Grid container alignItems="center">
                        <IconButton variant="contained" size="large">
                            <img src={settingsicon} alt={"settings"} />
                        </IconButton>
                        <TypographyComponent variant={"h4"}
                            className="txtMedium">
                            {SpcallerConstants.SELECT_COLUMN_TITLE}
                        </TypographyComponent>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton
                        variant="contained"
                        onClick={() => {
                            toggleDrawer(false)
                        }}
                        size="large">
                        <img src={closeicon} alt={"Close"} />
                    </IconButton>
                </Grid>
            </Grid>
            <Accordion
                className={classes.MuiAccordion}
                classes={{
                    root: classes.peaccordion_nocolor
                }}
                expanded={true}
            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <TypographyComponent
                        variant={"h6"}
                        className="txtMedium panel-active"
                    >
                        Default
                    </TypographyComponent>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid item xs={12} className={`${classes.expansionDetail} ${"scrolly"}`}>
                        {generateDefaultColumns()}
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Drawer>
    );
}

// default props
SelectColumn.defaultProps = {
    classes: {},
    page: { fieldFilters: { visibleColumns: [], columnsMap: {} } },
    onSelectColumnChange: () => {}
};

// prop types
SelectColumn.propTypes = {
    classes: PropTypes.object,
    page: PropTypes.object,
    onSelectColumnChange: PropTypes.func
};

export default SelectColumn;