import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";
import { ValidatorComponent } from "react-material-ui-form-validator";
import moment from "moment";

// const usePrevious = (value) => {
//   let ref = useRef(null)
//   useEffect(() => {
//     ref.current = value
//   }, [value])
//   return ref.current
// }

const formatValue = (value) => {
  if (value && typeof value === "string" && value.indexOf("/") >= 0) {
    return value;
  } else if (typeof value === "object" && value) {
    return moment(value).format("YYYY-MM-DD");
  } else if (!value) {
    return null
  } else {
    if (value.length > 23) {
      value = value.split(/T/)[0]
    }
    const formatDate = moment(value).format("YYYY-MM-DD");
    const defaultDate = moment(formatDate).startOf('day')._d;
    return defaultDate;
  }
};

const PickerDialog = React.forwardRef(({ children }, ref) => {
  const {
    children: [PopElement],
  } = children.props;
  const { DialogProps } = PopElement.props;
  const { onCancel, onOk } = DialogProps;
  return (
    <Dialog open ref={ref}>
      <DialogContent style={{ padding: "0px" }}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{ color: "#000" }}>
          Cancel
        </Button>
        <Button onClick={onOk} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
});

// Import Images

/**
 *DatePicker Component
 *
 * @class DatePicker
 * @extends {Component}
 */
function DatePickerComponent(props) {
  const [tempValue, setTempValue] = useState(value || null);
  const [oldValue, setoldValue] = useState(value || null);

  // Handle TextField Events
  const handleChange = (event, value) => {
    if (value && event && moment(event).isValid()) {
      // for keyboard call directly
      props.handleChange({ name: props.name, value: new Date(event) });
    } else if (event) {
      let d = new Date();
      if (event._d && event._d.toString() === "Invalid Date") {
        d = new Date();
      } else {
        d = new Date(event);
      }
      // let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      setTempValue(d);
      // props.handleChange({ name: props.name, value: d });
    } else {
      setTempValue(null);
      // props.handleChange({ name: props.name, value: null });
    }
  };
  /**
   *render html
   *
   * @returns
   * @memberof DatePicker
   */
  const {
    name,
    value,
    fullWidth,
    label,
    disabled,
    margin,
    size,
    className,
    validators,
    errorMessages,
    validations,
    onClose,
    inputFormat,
    helperText,
    notRequired,
    panel,
    minDate,
    maxDate,
    border,
  } = props;
  const customClass =
    validators && validators.length > 0 && !notRequired
      ? className + " required"
      : className;

  // const previousValue = usePrevious(value)

  useEffect(() => {
    if (value) {
      setoldValue(value);
    }
  }, [value]);
  const getSelectedYear = () => {
    return tempValue || formatValue(value)
      ? moment(tempValue || formatValue(value)).format("YYYY")
      : "Select Date";
  };

  return (
    <>
      <DatePicker
        showToolbar
        toolbarTitle={
          <div
            style={{
              fontSize: "18px",
              lineHeight: "26px",
            }}
          >
            {getSelectedYear()}
          </div>
        }
        closeOnSelect={false}
        value={tempValue || formatValue(value) ? moment(tempValue || formatValue(value)).startOf("day") : oldValue}
        TransitionComponent={PickerDialog}
        DialogProps={{
          onCancel: () => {
            props.handleChange({ name: props.name, value: oldValue });
          },
          onOk: () => {
            if (moment(new Date(tempValue)).isValid()) {
              props.handleChange({ name: props.name, value: tempValue || oldValue });
            } else {
              props.handleChange({ name: props.name, value: null });
            }
          },
        }}
        onChange={handleChange}
        onOpen={() => {
          if (!value) {
            setTempValue(new Date());
          }
        }}
        onClose={() => {
          setTempValue(null);
          onClose();
        }}
        inputFormat={inputFormat}
        className={`${customClass} date-picker-custom`}
        disabled={disabled}
        invalidDateMessage={errorMessages}
        validators={validators}
        errorMessages={errorMessages}
        {...validations}
        panel={panel}
        minDate={minDate ? minDate : new Date("1900-01-01")}
        maxDate={maxDate ? maxDate : new Date("2100-01-01")}
        renderInput={(props) => {
          return (
            <TextField
              className={`${border === "square"
                ? "date-picker-custom " + className
                : "date-picker-custom"
                }`}
              helperText={helperText}
              label={label}
              name={name}
              margin={margin}
              size={size}
              placeholder="__/__/____"
              fullWidth={fullWidth}
              {...props}
              sx={{ svg: { color: "#b3b3b3" } }}
            />
          );
        }}
      />
    </>
  );
}

// default props
DatePickerComponent.defaultProps = {
  name: "",
  value: "",
  label: "",
  helperText: "",
  fullWidth: "",
  disabled: false,
  className: "cursor-pointer",
  errorMessages: [],
  validators: [],
  onOpen: () => { },
  onClose: () => { },
  handleChange: () => { },
  validations: {},
  inputFormat: "MM/dd/yyyy",
  fieldErrors: [],
  warningFieldName: "",
  notRequired: false,
  margin: "dense",
  size: "small",
  maxDate: null,
  minDate: null,
  border: "",
  renderInput: () => { },
};

// prop types
DatePickerComponent.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showToolbar: PropTypes.bool,
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputFormat: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
  validators: PropTypes.array,
  helperText: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  renderInput: PropTypes.func,
  border: PropTypes.string,
};

// Export Component
export default class Picker extends ValidatorComponent {
  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    const hText = (!isValid && this.getErrorMessage()) || helperText;

    return (
      <DatePickerComponent {...rest} error={!isValid} helperText={hText} />
    );
  }
}
