import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Input from "@mui/material/Input";

// Import Styles
import SearchInputComponentStyle from "./SearchInputComponentStyle";
import CloseImg from "../../assets/images/svg/close.svg";

/**
 * Search Input component
 */
function SearchInputComponent (props) {
  const { reset, timeOut } = props;
  const [value, setValue] = useState(props.value);
  const [userChange, setUserChange] = useState(false);
  const debouncedSearchTerm = useDebounce(value, timeOut);

  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm) {
        props.onInputChangeRequest(props.field, value, userChange);
      } else if (props.value) {
        props.onInputChangeRequest(props.field, value, userChange);
      } else {
        setValue("");
        props.handleClearSearch();
      }
    },
    [debouncedSearchTerm] // Only call effect if debounced search term changes
  );

  useEffect(() => {
    if (reset) {
      setValue("");
    }
  }, [reset]);

  // Handle Clear Text
  const handleClearSearch = () => {
    setValue("");
    props.handleClearSearch();
  };

  /**
     * Bind
     * HTML to DOM
     */
  const { classes, placeholder, className, showClearIcon, fullWidth, maxLength, ariaLabel } = props;
  return (
    <Input
      className={className ? `${classes.root} ${className}` : classes.root}
      value={value}
      onChange={e => {
        setValue(e.target.value)
        setUserChange(true)
      }}
      placeholder={placeholder}
      disableUnderline={true}
      fullWidth={fullWidth}
      onKeyPress={(e) => {
        const enterOrSpace =
          e.key === "Enter" ||
          e.which === 13
        if (enterOrSpace) {
          e.preventDefault();
          props.onEnter(props.field, value, userChange)
        }
      }}
      inputProps={{
        "aria-label": ariaLabel || "Search",
        className: (showClearIcon && value.length > 0) ? classes.inputRootWithValue : classes.inputRootWithOutValue,
        maxLength: maxLength
      }}
      endAdornment={(showClearIcon && value.length > 0) && (
        < InputAdornment position="end">
          <IconButton
            className={classes.closeicon}
            aria-label="search"
            onClick={handleClearSearch}
            edge={"start"}
            size="large">
            <img src={CloseImg} className={`${"peTabDelete"}`} alt="Close"/>
          </IconButton>
        </InputAdornment>
      )
      }
    />
  );
}

// default props
SearchInputComponent.defaultProps = {
  label: "",
  value: "",
  placeholder: "",
  field: "",
  reset: false,
  classes: {},
  className: "",
  showClearIcon: false,
  maxLength: 60,
  fullWidth: false,
  timeOut: 650,
  onInputChangeRequest: () => { },
  onEnter: () => { },
  handleClearSearch: () => { }
};

// prop types
SearchInputComponent.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  field: PropTypes.string,
  reset: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.any,
  showClearIcon: PropTypes.bool,
  maxLength: PropTypes.number,
  timeOut: PropTypes.number,
  fullWidth: PropTypes.bool,
  onInputChangeRequest: PropTypes.func.isRequired,
  handleClearSearch: PropTypes.func,
  // ariaLabel: "Search"
};

// export component
export default withStyles(SearchInputComponentStyle)(SearchInputComponent);

// Hook
function useDebounce (value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}
