import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import Components
import SuccessPageComponent from "../../../../components/SuccessPageComponent/SuccessPageComponent";

function SuccessPage(props) {
  // Get Survey Details
  const uploadResult = useSelector((state) => state.excel.upload);
  return (
    <SuccessPageComponent
      match={props.match}
      uploadResult={uploadResult}
      backPageURL={"/manage_people/participants/archive/upload"}
      backBtnURL={"/manage_people/participants"}
      pageName={"Archive/Restore participants"}
    />
  );
}
// default props
SuccessPage.defaultProps = {
  match: {}
};

// prop types
SuccessPage.propTypes = {
  match: PropTypes.object
};
export default SuccessPage;
