// import { maxHeight } from "@mui/system";

import { ImportantDevices } from "@mui/icons-material";

const SurveysStyles = () => ({
  root: {
    width: "100%"
  },
  rightPanelAppBarRoot: {
    background: "transparent",
    height: "45px"
  },
  hide_input: {
    display: "none"
  },
  nowarpValidationTxt: {
    "& .MuiFormHelperText-marginDense.Mui-error":
    {
      whiteSpace: "nowrap !important",
      left: "0 !important"
    }
  },

  noValidationTxt: {
    "& .MuiFormHelperText-marginDense.Mui-error":
    {
      display: "none !important"
    }
  },
  iconContainer: {
    width: "18px",
    height: "18px"
  },

  tapPanelAction: {
    position: "absolute",
    top: "4px",
    right: "0",
    height: "40px",
    display: "flex",
    alignItems: "center"
    // width:"68px"
  },
  rightPanelTabsRoot: {
    marginRight: "80px"
  },
  peTabEdit: {
    visibility: "hidden"
  },
  tabcharlimit:
  {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "175px",
    margin: "auto"
  },
  rightPanelTabRoot: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#EBECED",
    margin: "0px 4px",
    padding: "6px 12px",
    color: "rgba(0, 0, 0, 0.87) !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#54C0FD !important"
    },
    "&:hover .peTabEdit, &:hover .peTabDelete": {
      visibility: "visible !important",
      filter: "brightness(0) invert(1)"
    },
    "&$selected": {
      color: "#fff",
      backgroundColor: "#54C0FD"
    },
    "&:focus": {
      color: "#fff !important",
      backgroundColor: "#54C0FD"
    }
  },
  rightPanelTabSelected: {
    backgroundColor: "#54C0FD !important",
    color: "#fff !important"
  },
  colorred: {
    color: "red"
  },
  surveys_message_text: {
    minHeight: "41.6px !important"
  },
  servey_tile:
  {
    background: "#fff",
    padding: "45px 30px",
    borderRadius: "7px",
    position: "relative",
    margin: "20px",
    boxShadow: "0px 2px 20px rgba(0,0,0,0.06)"
  },
  servey_tile_img: {
    width: "110px",
    height: "110px",
    position: "absolute",
    background: "#fff",
    top: "-55px",
    borderRadius: "100%",
    border: "10px solid #EAECED",
    boxShadow: "0 0 10px #00000014"
  },

  servey_tile_img_padding: {
    padding: "20px"
  },
  bottomAlign: {
    bottom: "6px"
  },
  // Survey Header Component Styles
  headerSurveyDropDownRoot: {
    padding: "0px",
    backgroundColor: "#fff"
  },
  headerSurveyDropDownIcon: {
    display: "none"
  },
  headerSurveyDropDownSelect: {
    paddingRight: "0px !important"
  },
  headerSurveyDropDownDisabled: {
    color: "#292929"
  },

  // Survey Create Styles

  surveyheader: {
    background: "#fff",
    padding: "0px 20px",
    height: "46px"
  },
  nameursurvey: {
    maxWidth: "452px !important",
    width: "100%"
  },
  createsurv_div: {
    width: "100%",
    // maxWidth: "1095px",
    margin: "auto"
  },
  createsurv_letiles: {
    width: "calc(33.33% - 40px)",
    height: "100px",
    background: "#fff",
    margin: "8px 19px",
    boxShadow: "0 0 10px #00000014",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  createsurv_letiles_full:{
    width: "calc(100.33% - 50px)",
  },
  createsurv_rttiles: {
    width: "218px",
    height: "230px",
    background: "#fff",
    margin: "15px 20px",
    boxShadow: "0 0 10px #dfd8d8",
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center"
  },
  createservey_tile_icon: {
    width: "21px"
  },
  createsur_headtxtone: {
    fontSize: "14px !important",
    fontWeight: "500 !important",
    marginBottom: "0px !important",
    textAlign: "right"
  },
  createsur_headtxttwo: {
    fontSize: "13px !important",
    textAlign: "right !important"
  },

  // Stepper Styles
  stepper_container: {
    background: "#fff",
    width: "100%",
    position: "relative",
    boxShadow: "0px -1px 2px #0000000F"
  },
  stepper_div: {
    maxWidth: "320px",
    margin: "auto !important",
    height: 62
  },
  stepper_btns: {
    position: "absolute",
    right: "15px",
    top: "17px"
  },

  // Questions Page
  questsearch: {
    marginBottom: "12px"
  },
  add_catbtn:
  {
    minWidth: "95px"
  },
  quest_addtab: {
    maxWidth: "235px"
  },
  quest_TabEditClose: {
    width: "55px",
    display: "flex",
    justifyContent: "center",
    paddingLeft: "15px"
  },
  AddCateBtn: {
    width: "38px",
    height: "38px",
    opacity: "1"
  },
  categoryquesContainer: {
    boxShadow: "0px 0px 10px #dfd8d8",
    position: "absolute",
    justifyContent: "space-between",
    background: "#f2f4f5",
    left: "96%",
    top: "-45px",
    zIndex: "999999",
    width: "350px"
  },
  categoryques_Selected: {
    background: "#b5b5b5 !important"
  },
  category_quest_list: {
    border: "1px solid #EBECED",
    borderRadius: "6px",
    padding: "5px 7px",
    color: "#222",
    backgroundColor: "#FFF",
    cursor: "move",
    "&:hover": {
      color: "#54C0FD !important",
      backgroundColor: "#EDF9FF",
      borderColor: "#54C0FD"
    },
    "&.MuiListItem-root.Mui-disabled": {
      opacity: 1,
      cursor: "not-allowed"
    }
  },
  preview_questList: {
    paddingTop: "0px !important",
    paddingBottom: "8px !important",
  },
  selected_cat: {
    color: "#54C0FD !important",
    backgroundColor: "#EDF9FF",
    border: "1px solid #54C0FD !important",
  },
  category_quest_listText: {
    opacity: 0.4
  },
  categoryquestions: {
    padding: "10px 10px",
  },
  category_quest_icon: {
    marginLeft: "10px",
    top: "5px"
  },
  preview_container_arrow: {
    position: "absolute",
    right: "-10%",
    zIndex: "9999999"
  },
  quest_list: {
    border: "1px solid #EBECED",
    borderRadius: "6px",
    marginBottom: "8px",
    padding: "5px 7px",
    height: "34px",
    color: "#222",
    cursor: "move",
    "&:hover": {
      color: "#54C0FD !important",
      backgroundColor: "#EDF9FF",
      borderColor: "#54C0FD"
    }
  },
  quest_text_container: {
    "& .MuiOutlinedInput-root :hover": {
      backgroundColor: "#EBECED",
    }
  },
  quest_list_text: {
    "&:hover": {
      color: "#54C0FD !important"
    }
  },
  quest_listIcon: {
    marginTop: "5px !important"
  },
  quest_langchipdiv: {
    display: "flex",
    marginTop: "15px",
    flexWrap: "wrap"
  },
  quest_tabs: {
    flex: "1 1 auto!important",
    color: "rgba(0, 0, 0, 0.87) !important"
  },
  quest_linkimg: {
    display: "inline-flex",
    marginTop: "6px",
    alignItems: "center"
  },
  quest_cateitem: {
    padding: "3px 12px",
    boxShadow: "0px 0px 4px #0000000D",
    borderRadius: "4px",
    marginBottom: "15px",
    border: "1px solid #F3F4F5"
  },
  quest_cateitem_open: {
    padding: "12px",
    borderRadius: "4px",
    marginBottom: "15px",
    background: " #EAEBED",
    border: "1px solid #e7e7e7"
  },
  questionTextArea: {
    width: "100%",
    fontSize: "13px",
    padding: "10px 10px 0px",
    minHeight: "40.7px",
    backgroundColor: "#fff",
    resize: "vertical",
    margin: "8px 0px 4px",
    //border: "1px solid #aeaeaed9 !important",
    borderRadius: "2px",
    overflow: "auto",
    "&.textDisable": {
      color: "gray !important"
    }
  },

  width30: {
    width: "30px"
  },
  subquest_list: {
    marginTop: "8px",
    marginBottom: "4px"
  },
  questype_preview: {
    marginTop: "4px",
    marginBottom: "4px"
  },
  ques_catecheckboxrow: {
    justifyContent: "space-between"
  },
  bulk_ans_textarea: {
    minWidth: "320px",
    minHeight: "175px !important",
    height: "auto !important",
    lineHeight: "24px",
    resize: "none !important",
    whiteSpace: "pre",
    "&:focus": {
      border: "none",
    },
  },
  descriptionText: {
    padding: "15px 0px 0px 15px",
    maxWidth: "320px",
    fontSize: "14px"
  },
  morechoices_img: {
    background: "#eaebed",
    transform: "rotate(90deg)",
    padding: "3px 2px",
    borderRadius: "13px"
    // marginTop: '5px'
  },
  quest_listbadge_big: {
    height: "30px",
    width: "30px",
    borderRadius: "30px"
  },
  addquest_infoicon: {
    marginBottom: "-4px",
    marginLeft: "-8px !important"
  },
  answerset_items: {
    border: "1px solid #cccccc",
    borderRadius: "7px",
    position: "relative",
    background: "#fff",
    maxHeight: "60px",
    display: "flex",
    alignItems: "center",
    height: "34px",
    justifyContent: "space-between" 
  },
  answerset_items_badge: {
    "& .MuiBadge-colorSecondary": {
      background: "#C0C1C2",
      color: "#fff"
    },
    position: "absolute",
    left: "0",
    top: "16px"
  },
  addquestion_badge: {
    "& .MuiBadge-colorSecondary": {
      color: "#54c0fd",
      background: "#fff",
    }
  },
  answerset_items_dlt: {
    float: "right",
    padding: "7px",
    height: "26px",
    width: "26px",
    // left: "80px"
  },
  answerset_items_txt:
  {
    display: "inline-block",
    paddingLeft: "10px",
    margin: "0px"
  },
  moreOptions_Icon: {
    float: "right",
    padding: "7px",
    height: "26px",
    width: "26px"
  },
  category_desclabel: {
    overflowY: "auto",
    height: "22px"
  },
  bulk_Answer_Set:
  {
    overflowY: "hidden",
    maxHeight: "180px"
  },
  // answer_popup: {
  //   width:"300px"
  // },

  // Settings Page
  settings_accorddiv: {
    maxWidth: "1140px",
    margin: "auto",
    padding: "20px"
  },
  setting_accordheading: {
    flexBasis: "33.33%",
    flexShrink: 0,
    marginTop: "2px",
    fontWeight: "bold"
  },
  settings_icons:
  {
    paddingRight: "8px",
    width: "28px"
  },
  CommunicationConstant_size: {
    fontSize: "0.875rem"
  },
  Greetings_constant: {
    fontSize: "0.875rem"
  },
  url_constant: {
    fontSize: "0.875rem"
  },

  settings_dlticon: {
    textAlign: "right !important",
    padding: "15px !important"
  },
  settinfoicon: {
    marginBottom: "-3px",
    marginLeft: "-3px !important"
  },
  assignsettinfoicon: {
    marginBottom: "-4px",
    marginLeft: "10px !important"
  },
  anonymsettinfoicon: {
    marginLeft: "8px !important",
    marginBottom: "-2px"
  },
  audience_expandinsidediv: {
    padding: "10px",
    background: "#F7F9FA",
    borderRadius: "4px",
    marginLeft: "0px",
    marginRight: "-24px"
  },
  settings_expandinsidediv:
  {
    padding: "10px",
    paddingRight: "24px",
    background: "#F7F9FA",
    borderRadius: "4px",
    marginLeft: "-13px"
  },
  distri_langslt: {
    position: "absolute",
    right: "0",
    "& ..MuiFormControl-marginDense": {
      margin: 0,
    },
  },
  txtborder: {
    border: "1px solid #E3E4E6"
  },
  setting_expandcontainer: {
    padding: "20px"
  },
  width135: {
    width: "135px"
  },

  demographicField: {
    minWidth: "265px"
  },
  actionCoachIcon: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "0 1rem",
    "& .btnIcon": {
      padding: "0.5rem",
      cursor: "pointer"
    }
  },

  // Dialog
  dialog_close: {
    textAlign: "right"
  },

  // Customer Answer Set
  surveytabs_body: {
    background: "#F4F6F7CC !important",
    border: "1px solid #EAEBED",
    marginTop: "-16px",
    padding: "25px",
    minHeight: "144px"

  },

  answeredit_langselect: {
    position: "absolute",
    right: "20px",
    zIndex: "1"
  },
  cust_answerset_items: {
    border: "1px solid #cccccc",
    borderRadius: "7px",
    position: "relative",
    background: "#fff",
    maxHeight: "60px",
    display: "flex",
    alignItems: "center",
    height: "40px"
  },
  cust_answerset_items_badge: {
    position: "absolute",
    left: "0",
    top: "20px"
  },
  cust_answerset_items_dlt: {
    float: "right",
    padding: "7px"
  },
  cust_answerset_items_txt:
  {
    display: "inline-block",
    paddingLeft: "10px",
    margin: "0px"
  },
  customtab_appbar: {
    background: "transparent",
    height: "45px"
  },

  // Category Swapping
  categoryswap_tile: {
    background: "#F4F6F7",
    boxShadow: " 0px 0px 8px #0000000F",
    border: "1px solid #EAEBED",
    padding: "11px",
    borderRadius: "5px"
  },

  // Drag Container Changes
  dragcontainer: {
    background: "#FFFFFF",
    border: "1px dashed #C0C1C2"
  },

  // Edit Category
  editCategoryPopover: {
    marginTop: "10px"
  },

  //Survey Preview Dialog Box
  surveyPreview: {
    "& .MuiDialog-paper": {
      padding: 0,
    }
  },
  anonymousCopyIcon: {
    width: 26,
    height: 26
  },
  translatedField: {
    backgroundColor: "#fefaf4",
    borderColor: "#f9cd8e",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f9cd8e !important"
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fefaf4 !important",
      borderColor: "#f9cd8e  !important"
    },
    "&.br-none": {
      border: "1px solid",
      borderRadius: "0",
      borderColor: "#f9cd8e !important"
    }
  },
  editedtranslatedField: {
    backgroundColor: "#f4f6f7",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#f4f6f7 !important",
    }
  },
  editedtranslatedField_Mail: {
    backgroundColor: "#FFF",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#FFF !important",
    }
  },
  survey_name: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "2px !important"
    }
  },
  triggerInstructionColor: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  cancelBtn: {
    color: "#6a6a6a",
    backgroundColor: "#EBECED"
  },

  // Survey Name Edit
  surveyNameBoxWrap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: 4,
  },
  surveyNameEdit: {
    margin: 0,
    "& .MuiInputBase-input": {
      padding: "4px 12px",
      height: "auto",
    }
  }
});

export default SurveysStyles;
