import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All PDF Service Functions
 */
export const pdfService = {
    download
};

/* PDF download
 */
async function download(data) {
    try {
      const response = await apihelper.postRequest('pdf/export', data, false, 'json', true);
      if(response.data && response.data.filePath){
        const responseOne = await apihelper.postRequestExcel('pdf/exportPdf', response.data,false, "arraybuffer", true);        
        return responseOne;
      }else{
      return response;
      }
    } catch (e) {
      console.error(e)
    }
}