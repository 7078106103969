/* eslint-disable react/jsx-key */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Import Icons
import { SettingAccordWhite, SettingAccordGrey } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Constants
import { participantConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import SvgIcon from "@mui/icons-material/ExpandMore";

//Import Styles
import DemographicsStyles from '../DemographicsStyles';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";

function DeafaultPanel(props) {
  // Accordion
  const [expanded, setExpanded] = React.useState("default-panel");
  const [gridSize, setGridSize] = React.useState(8)
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Assign Props
  const { classes, metadata,externalApiAccess , handleApiKeyChange,handleChange } = props;
  // get demographic custom name
  const bindMetaData = () => {
    const data = metadata.filter((item) => item.type === "default");

    useEffect(()=>{
      ValidatorForm.addValidationRule("Obj_key_valid", (value) => {
        if (/\s/.test(value)) {
          return false
        } else {
          return true
        }
      });
    },[metadata])

    useEffect(()=>{
      if(externalApiAccess){
        setGridSize(4)
      }else{
        setGridSize(8)
      }
    },[externalApiAccess])

    return (
      <React.Fragment>
        {data.map((mdata, index) => {
          return (
            <Grid container alignItems="center" className={`${"mb-1"} ${classes.Demography_list}`}>
              <Grid item sm={4}>
                <TypographyComponent
                  title={mdata.dispName ? mdata.dispName : mdata.key}
                  variant={"h6"}
                >  {mdata.key === "status" &&
                <InformationComponent>{participantConstants.DEFAULT_STATUS_INFO_HELP}</InformationComponent>
              }
                </TypographyComponent>
              </Grid>
              <Grid item sm={gridSize}>
                <TextFieldComponent
                  name={mdata.key}
                  id={mdata.key}
                  value={mdata.value}
                  size={"small"}
                  margin={"none"}
                  fullWidth={false}
                  handleChange={(e) => handleChange(e)}
                  validators={mdata.visible ? ["required", "isSameCustomName"] : ["required"]}
                  className={`${classes.demography_input} ${classes.customName}`}
                ></TextFieldComponent>
              </Grid>
              {externalApiAccess ? 
              <Grid item sm={4}>
                <TextFieldComponent
                  name={mdata.key}
                  id={mdata.key}
                  value={mdata.api_key_value}
                  size={"small"}
                  margin={"none"}
                  fullWidth={false}
                  handleChange={(e) => handleApiKeyChange(e)}
                  validators={["Obj_key_valid"]}
                  className={`${classes.demography_input} ${classes.customName}`}
                ></TextFieldComponent>
              </Grid>
              :""}
            </Grid>
          );
        })
        }
      </React.Fragment>
    );
  };

  return (
    <Accordion
      className="mb-2 pe-accordion"
      expanded={expanded === "default-panel"}
      onChange={handlePanelChange("default-panel")}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {expanded === "default-panel" ?
          <Grid className={"pr5"}>
            {SettingAccordWhite()}
          </Grid> :
          <Grid className={"pr5"}>
            {SettingAccordGrey()}
          </Grid>
        }
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${expanded === "default-panel" ? "panel-active" : ""} `}
          variant={"h5"}>
          {participantConstants.DEMOGRAPH_DEFAULT}
        </TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "default-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{participantConstants.DEMOGRAPH_DEFAULT_HELP}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className="p-2 width100">
          <Grid container spacing={3} className={`${classes.setting_expandcontainer}`}>
            <Grid item sm={12}>
              <Grid container className="pb-15">
                <Grid item sm={4}>
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_SYSTEM_NAME}
                    variant={"h6"}
                    className="txtMedium"
                  >
                  </TypographyComponent>
                </Grid>
                <Grid item sm={gridSize}>
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_CUSTOM_NAME}
                    variant={"h6"}
                    className="txtMedium"
                  >
                    <InformationComponent>{participantConstants.DEMOGRAPHY_CUSTOM_NAME_HELP}</InformationComponent>
                  </TypographyComponent>
                </Grid>
                {externalApiAccess ? 
                <Grid item sm={4}>
                  <TypographyComponent
                    title={participantConstants.API_CUSTOM_NAME}
                    variant={"h6"}
                    className="txtMedium"
                  >
                    <InformationComponent>{participantConstants.DEMOGRAPHY_API_KEY_NAME_HELP}</InformationComponent>
                  </TypographyComponent>
                </Grid>
                :""}
              </Grid>
              {bindMetaData()}
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

// default props
DeafaultPanel.defaultProps = {
  classes: {},
  metadata: [],
  handleChange: () => { }
};

// prop types
DeafaultPanel.propTypes = {
  classes: PropTypes.object,
  metadata: PropTypes.array,
  handleChange: PropTypes.func
};

export default withStyles(DemographicsStyles)(DeafaultPanel);
