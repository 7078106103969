import { alertConstant, uiConstant, apiIntegrationConstants } from "../constants";
import { baseAction } from "./baseAction";
import { apiIntegrationService } from "../services/apiIntegrationService";

const { request, success, failure } = baseAction;

export const apiIntegrationAction = {
    getAllSurveys,
    selectedSurveys,
    selectedDemographics,
    wishlistDomain_update,
    startDate_update,
    endDate_update,
    limits_per_update,
    limits_update,
    generateToken,
    dialog,
    getPrevToken,
    dialogOpen,
    tokenUpdate
}

/**
 * Get All Surveys
 * @param {*} successCallback 
 */
function getAllSurveys(successCallback = () => { }, loader = true) {
    return dispatch => {
        if (loader) {
            dispatch(request(uiConstant.LOADING_TRUE));
        }
        dispatch(request(apiIntegrationConstants.GET_ALL_SURVEY_REQUEST));
        apiIntegrationService.getSurveys().then(data => {
            const filterData = data.data.filter((e)=>e.status !== "Pending" && e.isDelete == false && e.name != "" || undefined)
            dispatch(success(apiIntegrationConstants.GET_ALL_SURVEYS_SUCCESS, filterData));
            dispatch(request(uiConstant.LOADING_FALSE));
            successCallback(filterData)
        }, error => {
            dispatch(request(uiConstant.LOADING_FALSE));
            dispatch(failure(apiIntegrationConstants.GET_ALL_SURVEYS_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}

/**
 * Get Past Token
 * @param {*} successCallback 
 */
function getPrevToken(successCallback = () => { }, loader = true) {
   return dispatch => {
       if (loader) {
           dispatch(request(uiConstant.LOADING_TRUE));
       }
       dispatch(request(apiIntegrationConstants.GET_PREV_TOKEN_REQUEST));
       apiIntegrationService.getToken().then(data => {
        const resp = data.data[0]  
        const confiq ={      
        apiHit:resp?.apiConfig?.limit ? resp?.apiConfig?.limit : 10,
        dialog:false,
        domainUrl:resp?.apiConfig?.domainURL,
        endDate:resp?.apiConfig?.end_date ? resp?.apiConfig?.end_date : new Date(),
        limitsPer:resp?.apiConfig?.limits_per ? resp?.apiConfig?.limits_per : "Day",
        responseDemographics:resp?.apiConfig?.response_demograpics,
        selectedSurveyIds:resp?.apiConfig?.survey_id,
        startDate:resp?.apiConfig?.start_date ? resp?.apiConfig?.start_date : new Date(),
        token:resp?.apiKey
        }
           dispatch(success(apiIntegrationConstants.GET_PREV_TOKEN_SUCCESS, confiq));
           dispatch(request(uiConstant.LOADING_FALSE));
           successCallback(confiq)
       }, error => {
           dispatch(request(uiConstant.LOADING_FALSE));
           dispatch(failure(apiIntegrationConstants.GET_PREV_TOKEN_FAILURE, error));
           dispatch(failure(alertConstant.ALERT_ERROR, error));
       });
   };
}

// Store Selected Surveys
function selectedSurveys(selectedSurveyIds) {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.PUT_SURVEY_IDS_SUCCESS, selectedSurveyIds));
    }
}

// Store Selected Demographics
function selectedDemographics(selectedDemographics) {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.PUT_DEMOGRPAHICS_REQUEST, selectedDemographics))
    }
}
// Store wishlistDomain_update
function wishlistDomain_update(wishlistUrl) {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.PUT_WISHLIST_URL_REQUEST, wishlistUrl));
    }
}

// Store startDate_update
function startDate_update(startDate) {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.PUT_START_DATE_REQUEST, startDate));
    }
}

// Store endDate_update
function endDate_update(endDate) {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.PUT_END_DATE_REQUEST, endDate));
    }
}

// Store limits_per_update
function limits_per_update(limitper) {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.PUT_LIMIT_PER_REQUEST, limitper));
    }
}

// Store limits_update
function limits_update(limit) {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.PUT_LIMIT_REQUEST, limit));
    }
}

function dialogOpen() {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.SHOW_TOKEN_REQUEST_SUCCESS));
    }
}


function dialog() {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.SHOW_TOKEN_REQUEST));
    }
}

function tokenUpdate() {
    return dispatch => {
        dispatch(request(apiIntegrationConstants.REMOVE_TOKEN));
    }
}

function generateToken(payload,successCallback = () => { }, loader = true) {
    return dispatch => {
        if (loader) {
            dispatch(request(uiConstant.LOADING_TRUE));
        }
        dispatch(request(apiIntegrationConstants.POST_GENERATE_TOKEN_REQUEST));
        apiIntegrationService.generateToken(payload).then(data => {
            dispatch(success(apiIntegrationConstants.POST_GENERATE_TOKEN_SUCCESS, data));
            dispatch(request(uiConstant.LOADING_FALSE));
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message))
            successCallback()
        }, error => {
            dispatch(request(uiConstant.LOADING_FALSE));
            dispatch(failure(apiIntegrationConstants.POST_GENERATE_TOKEN_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
        });
    };
}