import React, { useState, useSelector } from "react";
import PropTypes from "prop-types";
import { TextValidator } from "react-material-ui-form-validator";
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, Grid } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Import Image
import { Pulse } from "../../assets/images/svgComponents/ImageComponent";

// Import Config Constants
import { errorsConstants } from "../../config/constants";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

/**
 * Autocomplete Component
 */
function MultiSelectComponent(props) {
  const [open, setOpen] = useState(false);

  /**
   * render html
   */
  const { suggestions, disabled, value, placeholder, className, validators, showErrorMessages, name, isArrayObjects, selectedOptions, pulseSurveys, checkboxClassName } = props;
  const errorMessages = showErrorMessages ? validators.map(s => errorsConstants[s]) : [];
  return (
    <React.Fragment>
      {
        !isArrayObjects ? (
          <div style={{}}>
            {suggestions &&
              <React.Fragment>
                <Autocomplete
                  classes={{
                    listbox: "multi-select-option-list"
                  }}
                  name={name}
                  variant={"outlined"}
                  multiple
                  disableClearable
                  autoHighlight
                  blurOnSelect={false}
                  value={Array.isArray(value) ? value : []}
                  disabled={disabled}
                  className={className}
                  options={suggestions}
                  isOptionEqualToValue={(option, value) => option.key === value.key}
                  onChange={(event, newValue, action) => {
                    props.handleAutoCompolete(name, newValue, event, action);
                  }}
                  getOptionLabel={(option) => option.key}
                  renderTags={(value, getTagProps) => {
                    return null;
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      variant={"outlined"}
                      value={Array.isArray(value) ? value : null}
                      name={name}
                      validators={validators}
                      errorMessages={errorMessages}
                      placeholder={value.length > 0 ? value.map(v => v).join(", ") : placeholder}
                      margin={"dense"}
                      InputProps={{ ...params.InputProps, type: "text", className: "multiSelectInput headerSearch pr-3" }}
                    />
                  )}
                  renderOption={(props, option, state) => {
                    return (
                      <li {...props} className={checkboxClassName}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className="autocomplete-checkbox"
                          checked={state.selected}
                          style={{ margin: 0, paddingRight: "4px" }}
                        />
                        {option.key}
                      </li>
                    );
                  }}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={(e, reason) => {
                    if (reason === "blur" || reason === "toggleInput") {
                      setOpen(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                  open={open || false}
                />
              </React.Fragment>
            }
          </div>
        ) : (
          <div style={{}}>
            {suggestions &&
              <React.Fragment>
                <Autocomplete
                  classes={{
                    listbox: "multi-select-option-list"
                  }}
                  name={name}
                  variant={"outlined"}
                  multiple
                  disableClearable
                  autoHighlight
                  blurOnSelect={false}
                  value={Array.isArray(value) ? value : []}
                  disabled={disabled}
                  className={className}
                  options={suggestions}
                  isOptionEqualToValue={(option, value) => option === value}
                  onChange={(event, newValue, action) => {
                    props.handleAutoCompolete(name, newValue, event, action);
                  }}
                  getOptionLabel={(option) => option}
                  renderTags={(value, getTagProps) => {
                    return null;
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      variant={"outlined"}
                      value={Array.isArray(value) ? value : null}
                      name={name}
                      validators={validators}
                      errorMessages={errorMessages}
                      placeholder={value.length > 0 ? value.filter(v => v !== "Select All").join(", ") : placeholder}
                      margin={"dense"}
                      InputProps={{ ...params.InputProps, type: "text", className: "multiSelectInput headerSearch pr-3" }}
                    />
                  )}
                  renderOption={(props, option, state) => {
                    return (
                      <li {...props} style={{padding: '2px'}} className={checkboxClassName}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className="autocomplete-checkbox"
                          checked={state.selected}
                          style={{ margin: 0, paddingRight: "4px" }}
                        />
                        {option}
                        {pulseSurveys.includes(option) ? <Grid className="p3">{Pulse()}</Grid> : null}
                      </li>
                    );
                  }}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={(e, reason) => {
                    if (reason === "blur" || reason === "toggleInput") {
                      setOpen(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                  open={open || false}
                />
              </React.Fragment>
            }
          </div>
        )
      }
    </React.Fragment>
  );
}

// default props
MultiSelectComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  placeholder: "",
  validators: [],
  errorMessages: [],
  showErrorMessages: true,
  fieldErrors: [],
  warningFieldName: "",
  margin: "dense",
  className: "",
  checkboxClassName: "",
  suggestionType: "arrayObject",
  defaultValue: null,
  handleAutoCompolete: () => { },
  multiple: true,
  isArrayObjects: false,
  selectedOptions: [],
  pulseSurveys: [],
};

// prop types
MultiSelectComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  suggestionType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  showErrorMessages: PropTypes.bool,
  fieldErrors: PropTypes.array,
  margin: PropTypes.string,
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  defaultValue: PropTypes.any,
  handleAutoCompolete: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  isArrayObjects: PropTypes.bool,
  selectedOptions: PropTypes.array,
  pulseSurveys: PropTypes.array,
};

/** Export Component */
export default MultiSelectComponent;
