import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Style
import SurveyPreviewStyles from "../SurveyPreviewStyles";

// Import Components
import LinearProgressComponent from "../../../../components/LinearProgressComponent/LinearProgressComponent";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";

// Import Logo
import IconLogo from "../../../../assets/images/svg/logo.svg";
import IconAvatar from "../../../../assets/images/default_profile.svg";

// Import Actions
import { surveyPreviewAction } from "../../../../redux/actions";

function TakeSurveyHeader(props) {
  const { classes, langCode, match } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  const clientData = useSelector((state) => state.surveyPreview.client);
  const questionData = useSelector((state) => state.surveyPreview.questions);
  const { languages } = questionData;

  const handleSurveyLanguage = (lang) => {
    dispatch(surveyPreviewAction.updateSurveyLanguage(lang.code));
  };

  const activeLangObject = languages.find((lang) => lang.code == langCode);

  return (
    <Grid className={classes.rootHeader}>
      <LinearProgressComponent top={`${languages.length > 1 ? "70px" : ""}`}></LinearProgressComponent>
      <AppBar
        position="static"
        color="default"
        className={classes.headerAppBar}
      >
        <Toolbar variant="dense" className={classes.headerToolBar}>
          <Grid item className={classes.logo} sm={6}>
            <img
              src={clientData && clientData.logo ? clientData.logo : IconLogo}
              className={classes.headerLogoImg}
              onClick={() => {}}
              alt="Logo"
            />
          </Grid>
          <Grid item sm={6} className={classes.headerRight}>
            <Grid item className={classes.headerRightBlock}>
              {match && languages.length > 1 ? (
                <Grid item sm={4} className={"desktopShow"}>
                  <ValidatorForm onSubmit={() => {}}>
                    <AutoCompleteComponent
                      name={"clients"}
                      suggestions={languages}
                      handleAutoCompolete={handleSurveyLanguage}
                      value={activeLangObject}
                      placeholder={"Select Language"}
                      validators={[]}
                    ></AutoCompleteComponent>
                  </ValidatorForm>
                </Grid>
              ) : null}
              <Grid item className={classes.headerUserInfo}>
                <Avatar alt="Resource" src={IconAvatar} />
              </Grid>
            </Grid>
            {match && languages.length > 1 ? (
            <div className={`mobileShow ${classes.mobileLangDropDown}`}>
              <ValidatorForm onSubmit={() => {}}>
                <AutoCompleteComponent
                  name={"clients"}
                  suggestions={languages}
                  handleAutoCompolete={handleSurveyLanguage}
                  value={activeLangObject}
                  placeholder={"Select Language"}
                  validators={[]}
                ></AutoCompleteComponent>
              </ValidatorForm>
            </div>
          ) : null}
          </Grid>
        </Toolbar>

      </AppBar>
    </Grid>
  );
}

// default props
TakeSurveyHeader.defaultProps = {
  classes: {},
  langCode: "en",
  match: null,
};

// prop types
TakeSurveyHeader.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  match: PropTypes.object,
};

/**
 * Export Container
 */
export default withStyles(SurveyPreviewStyles)(TakeSurveyHeader);
