import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Opportunity Areas Service Functions
 */
export const opportunityAreasService = {
  getOne
};


/**
* Get Client User Opportunity Areas
* @param id
* @param updatedData
*/
async function getOne(preference) {
  const response = await apihelper.postRequest("actions/opportunities", preference);
  return response;
}
