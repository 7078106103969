import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ButtonComponent from '../../../../components/ButtonComponent/ButtonComponent';
import { useDispatch } from "react-redux";
import { apiIntegrationAction } from '../../../../redux/actions';
import history from '../../../../config/history';


export default function ExitDialogInapi(props) {
 const { exitDialog , handleClose} = props
const dispatch = useDispatch()
 const handleConfirm = () =>{
    dispatch(apiIntegrationAction.selectedSurveys([]));
    dispatch(apiIntegrationAction.selectedDemographics([]));
    dispatch(apiIntegrationAction.wishlistDomain_update(""));
    dispatch(apiIntegrationAction.startDate_update(""));
    dispatch(apiIntegrationAction.endDate_update(""));
    dispatch(apiIntegrationAction.limits_per_update(""));
    dispatch(apiIntegrationAction.limits_update(""));
    dispatch(apiIntegrationAction.tokenUpdate())
    history.push({ pathname: "/surveys" });
 }

  return (
    <div>
      <Dialog
        open={exitDialog}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>You are about to exit the Peopleelment api configuration page. Do you want to save your changes?</DialogTitle>
        <DialogActions>
        <ButtonComponent
            title={"Yes"}
            color={"primary"}
            size={"small"}
            type={"button"}
            handleClick={handleConfirm}
          ></ButtonComponent>
        <ButtonComponent
            title={"Cancel"}
            color={"default"}
            size={"small"}
            type={"button"}
            handleClick={handleClose}
          ></ButtonComponent>
        </DialogActions>
      </Dialog>
    </div>
  );
}