import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Import Styles and Image
import SpCallerStyles from "./SpCallerStyles";
import BackImg from "../../assets/images/svg/chev_back.svg";

// Import Component
import Container from "../Containers/Container";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TableComponent from "../../components/TableComponent/TableComponent";
import OptionsMenu from "./OptionsMenu";
import SelectColumn from "./SelectColumn";

// import constants
import { SpcallerConstants } from "../../config/constants";
import { getTableHeight, getTableRowSize } from "../../config/layout";

// Import History
import history from "../../config/history";
// Import Actions
import { spCallerAction, alertAction, surveyAction } from "../../redux/actions";
// Import helper
import { getQueryVariable } from "../../helpers";

import CopyIcon from "../../assets/images/svg/copy_text.svg";

const PhoneMask = (field) => ({ data, handleChange }) => {
  if (!data.participant[field]) return null;
  return (
    <CopyToClipboard
      text={data.participant[field]}
      onCopy={() => { handleChange(field, data, '') }}>
      <span className='phone-mask-copy'>
        {/* {data.phone.replace(/[+0-9a-zA-Z]/ig, 'X')} */}
        {data.participant[field]}
        <IconButton className="p-1" style={{ width: "25px", height: "27px" }} size="large">
          <img src={CopyIcon} alt="copy"></img>
        </IconButton>
      </span>
    </CopyToClipboard>
  );
}

const componentMap = {
  "participant.phone": PhoneMask('phone'),
  "participant.altPhone": PhoneMask('altPhone')
};

let clearTimer = null

function CallerQueue(props) {
  // get props
  const [totalRecords, setTotalRecords] = useState("");
  const [searchData, setSearchData] = useState({ field: [], value: [] });
  const { classes, match, location } = props;
  const globalSearchState = location.state || {};

  const { params } = match;
  const survey_id = params.survey_id ? params.survey_id.split("?")[0] : null

  // sidebar column select
  const [drawer, setDrawer] = useState(false);

  // Define Dispatch
  const dispatch = useDispatch();
  const tableHeight = getTableHeight();
  const tableRowSize = getTableRowSize();

  // Get Survey Queue List
  const { user: { _id: caller_id } } = useSelector(state => state.profile);
  const { loading } = useSelector(state => state.UI);
  const { caller_queue } = useSelector(state => state.spCaller);
  const { data, page, clientSurveyDetails } = caller_queue;
  let isPageReset = getQueryVariable(props.location.search, "reset") === "true";

  const [pageReset, setPageReset] = useState(isPageReset)

  // if search reset is true
  if (globalSearchState && globalSearchState.isPageReset) {
    isPageReset = true;
  }

  useEffect(() => {
    if (pageReset) {
      dispatch(spCallerAction.getCallerQueue(1, tableRowSize, "survey.name", "asc", [], [], survey_id, {
        ...globalSearchState,
        isPageReset: true
      }));
      history.replace(props.location.pathname, {
        ...globalSearchState,
        isPageReset: true
      });
      setPageReset(false)
    }
  }, [pageReset]);

  /**
   * Get Caller Queue List
   */
  useEffect(() => {
    dispatch(spCallerAction.getCallerQueue(1, tableRowSize, "survey.name", "asc", [], [], survey_id, { ...globalSearchState }));
  }, [dispatch]);

  /**
     * Handle Table pagination Event
     */
  const onChangePagination = (skip, limit) => {
    dispatch(spCallerAction.getCallerQueue(skip, limit, page.sortBy, page.sort, page.sFields,
      page.sValues, survey_id, {
      ...globalSearchState,
      defaultCall: false
    }));
  };

  /**
     * Handle Table Sort Request Event
     */
  const onSortRequest = (sortBy, sort) => {
    dispatch(spCallerAction.getCallerQueue(page.skip, page.limit, sortBy, sort, page.sFields,
      page.sValues, survey_id, {
      ...globalSearchState,
      defaultCall: false
    }));
  };

  /**
     * Handle Table Search Input Change Event
     */
  const onInputChangeRequest = (searchField, searchKey, userChange) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    setSearchData({field:sFields,value:sValues})
    if (userChange) {
      if(history.location.pathname.indexOf("global-search")===-1){
        dispatch(spCallerAction.getCallerQueue(1, tableRowSize, page.sortBy, page.sort, page.sFields,
          page.sValues, survey_id, {
              ...globalSearchState,
              defaultCall: false
            }));
        }
    }
  };

  const onEnterSearch = () => {
    if(history.location.pathname.indexOf("global-search") >= 0) {
      clearTimeout(clearTimer)
      clearTimer = setTimeout(() => {
        dispatch(spCallerAction.getCallerQueue(1, tableRowSize, page.sortBy, page.sort, page.sFields,
          page.sValues, survey_id, {
              ...globalSearchState,
              defaultCall: false
        }));
      }, 500);
    }
  }

  /**
     * Clear Search Text By Field
     * @param {*} searchField
     * @param {*} searchKey
     */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(spCallerAction.getCallerQueue(1, tableRowSize, page.sortBy, page.sort, sFields,
      sValues, survey_id, {
      ...globalSearchState,
      defaultCall: false
    }));
  };

  /**
    * Handle Table Navigation Event
  */
  const handleNavigateAction = (id) => {
    alert(id)
  };

  /**
    * Handle Field Component On Change Event
  */
  const fieldComponentOnChange = (field, data, value) => {
    switch (field) {
      case "phone":
      case "altPhone":
        dispatch(alertAction.successAlert("Phone number copied!"));
        break;
      default:
    }
  };

  /**
   * handle actions button click
   */
  const handelActionButtonClick = (field, itemId, item) => {
    switch (field) {
      case 'CALLER_DETAIL':
        history.push({
          pathname: `/spcaller/caller-detail/${item._id}/${item.spcaller._id}`,
          state: {
            ...globalSearchState,
            from: survey_id
          }
        });
        break;
      case 'TAKE_SURVEY':
        const selectedLang = item.participant ? item.participant.selectedLang || "en" : "en"
        const win = window.open(`${window.location.origin}/#/takesurvey_spcaller/${item.client._id}/${item._id}/${item.participant._id}/${caller_id}?lang=${selectedLang}`, "Take Survey", "width=1024,height=768")
        win.onbeforeunload = () => {
          refreshCallerQueue()
        }
        break;
      case 'EMAIL':
        const { participant, _id, spcaller: { status } } = item;
        if (["Max Attempts", "Refused"].indexOf(status) >= 0) {
          dispatch(alertAction.error(`Email will not be sent as the call status is ${status}.`))
        } else if (participant && participant.email && participant._id) {
          dispatch(surveyAction.sendSurveyMail({ _id: _id, participant_id: participant._id }));
        } else {
          dispatch(alertAction.error("Email is empty!"));
        }
        break;
      default:
    }
  }

  /**
   * Callback method when menu is clicked
   * @param {*} menu selected menu name
   */
  const menuOnClick = (menu) => {
    switch (menu) {
      case SpcallerConstants.SELECT_COLUMN:
        setDrawer(true);
        break;
      default:
    }
  }

  /**
   * method call back when sidebar closes
   * @param {*} drawerState sidebar toggle state
   */
  const toggleDrawer = (drawerState) => {
    setDrawer(drawerState);
  }

  /**
   * callback method when column selected / deselected
   * @param {*} updatedVisibleColumns updated visible ccolumns
   */
  const onSelectColumnChange = (updatedVisibleColumns) => {
    const { sFields, sValues } = page;
    dispatch(spCallerAction.getCallerQueue(1, tableRowSize, page.sortBy, page.sort, sFields, sValues, survey_id, { updatedVisibleColumns, ...globalSearchState, defaultCall: false }));
  }

  const renderTitle = () => {
    let titleText = "";

    // if global search
    if (globalSearchState && globalSearchState.from === "assigned-calls") {
      titleText = 'Assigned Calls';
    } else if ((globalSearchState && globalSearchState.globalSearchKey) || globalSearchState.title) {
      if (globalSearchState.title) {
        titleText = `Global Queue`;
      } else {
        titleText = `Search - ${globalSearchState.globalSearchKey}`;
      }
    } else {
      const clientName = clientSurveyDetails
        && clientSurveyDetails.client_id ? ` - ${clientSurveyDetails.client_id.name}` : '';
      const surveyName = clientSurveyDetails && clientSurveyDetails.name ? `- ${clientSurveyDetails.name}` : '';
      titleText = `${SpcallerConstants.CALLER_QUEUE} ${surveyName} ${clientName}`
    }

    return (
      <TypographyComponent variant="h3" className="ml-1">
        {titleText}
      </TypographyComponent>
    )
  }

  // if user take survey return back to the caller detail window
  const refreshCallerQueue = () => {
    dispatch(spCallerAction.getCallerQueue(page.skip, page.limit, page.sortBy, page.sort, page.sFields,
      page.sValues, survey_id, {
      ...globalSearchState,
      defaultCall: false
    }));
  }

  const getTotalRecords = () => {
    dispatch(spCallerAction.getCallerQueue(page.skip, page.limit, page.sortBy, page.sort, page.sFields,
      page.sValues, survey_id, {
      ...globalSearchState,
      defaultCall: false,
      getRecordsCount: true
    }, (recordCount) => {
      setTotalRecords(recordCount)
    }));
  }

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid
          className="caller-queue-wrapper"
          container
          spacing={2}
          tabIndex={2}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid>
                <Grid container alignItems="center">
                  <img
                    tabIndex="0"
                    role="button"
                    aria-label="Go Back"
                    onKeyPress={(e) => {
                      const enterOrSpace =
                        e.key === "Enter" ||
                        e.key === " " ||
                        e.key === "Spacebar" ||
                        e.which === 13 ||
                        e.which === 32;
                      if (enterOrSpace) {
                        e.preventDefault();
                        history.push({
                          pathname: '/spcaller',
                          state: {
                            defaultCall: false,
                            isPageReset: true
                          }
                        })
                      }
                    }}
                    onClick={() => {
                      history.push({
                        pathname: '/spcaller',
                        state: {
                          defaultCall: false,
                          isPageReset: true
                        }
                      })
                    }}
                    className={classes.backimg}
                    src={BackImg} alt="back"
                  />
                  {renderTitle()}
                </Grid>
              </Grid>
              <Grid>
                {history.location.pathname.indexOf("global-search") > -1 &&
                  <ButtonComponent
                    title={"Search"}
                    color={"primary"}
                    size={"small"}
                    variant={"text"}
                    pageClassName="mr-1"
                    handleClick={() => {
                      dispatch(spCallerAction.getCallerQueue(1, tableRowSize, page.sortBy, page.sort, searchData.field,
                        searchData.value, survey_id, {
                        ...globalSearchState,
                        defaultCall: false
                      }));
                    }}
                  />
                }
                {page && page.sFields && page.sFields.length > 0 &&
                  <ButtonComponent
                    form="clear-table-search"
                    title={"Clear all"}
                    color={"primary"}
                    size={"small"}
                    type="submit"
                    variant={"text"}
                    pageClassName="mr-4"
                    handleClick={() => {
                      // setClearSearch(clearSearch + 1)
                      setPageReset(true)
                      history.replace(`${props.location.pathname}?reset=true`, {
                        ...location.state,
                        isPageReset: true
                      });
                    }}
                  />
                }
                <OptionsMenu
                  classes={classes}
                  menus={[
                    SpcallerConstants.SELECT_COLUMN
                  ]}
                  menuOnClick={menuOnClick}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <TableComponent
              id="caller-queue-table"
              actions={["EMAIL", "TAKE_SURVEY", "CALLER_DETAIL"]}
              data={data}
              header={page.fieldFilters.headerCols}
              field={page.fieldFilters.fieldCols}
              search={page.fieldFilters.searchCols}
              height={tableHeight}
              page={page.skip}
              count={page.count}
              rowsPerPage={page.limit}
              links={[]}
              sort={page.sort}
              sortBy={page.sortBy}
              sFields={page.sFields}
              sValues={page.sValues}
              noDataErrorMessage={!loading ? page && page.sFields && page.sFields.length > 0 ? "No Records Found" : "There are no more participants in the call queue" : "Loading Caller Queue"}
              paginationVisible={!page.lastPage}
              showPagination={true}
              dynamicPagination={false}
              handleNavigateAction={handleNavigateAction}
              onChangePagination={onChangePagination}
              onSortRequest={onSortRequest}
              onInputChangeRequest={onInputChangeRequest}
              handleClearSearchByField={handleClearSearchByField}
              resetSearchText={pageReset}
              componentMap={{ ...componentMap }}
              fieldComponentOnChange={fieldComponentOnChange}
              handelActionButtonClick={handelActionButtonClick}
              isLoading={loading}
              onEnterSearch={onEnterSearch}
              totalRecords={totalRecords}
              getTotalRecords={getTotalRecords}
            />
          </Grid>

          <SelectColumn
            classes={classes}
            open={drawer}
            page={page}
            toggleDrawer={toggleDrawer}
            onSelectColumnChange={onSelectColumnChange}
          />

        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
CallerQueue.defaultProps = {
  classes: {},
  handlePopoverClick: () => { }
};

// prop types
CallerQueue.propTypes = {
  classes: PropTypes.object,
  handlePopoverClick: PropTypes.func
};
export default withStyles(SpCallerStyles)(CallerQueue);
