import React from "react";
import PropTypes from "prop-types";
import HighLevelSummary from "../HighLevelSummary/Highlevelsummary";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Import Styles and Image
import CloseImg from "../../../../assets/images/svg/dashboard_close.svg";
import ReportsStyles from "../../ReportsStyles";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent"

const HighlevelsummaryPopup = (props) => {

  //Get props
  const { classes, onClose, isUpdate, updateReportPreference, stopChartReload, preference, allSurveys, summaryTableColumns } = props;

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className={`${classes.EIheight100} p15`}
      >
        <Grid item xs={6} className="height-90">
          <TypographyComponent variant="h4" className="txtBold fontSize16 ml-1 height50px mt-2">
            {"Summary"}
          </TypographyComponent>
        </Grid>
        <Grid item xs={6} className={`${classes.dialog_close} ${classes.EIheight10}`}>
          <Button variant="text" size={"small"} onClick={onClose} className="mt-2">
            <img src={CloseImg} alt="CloseImg" />
            <TypographyComponent variant="h5" className="pl5">
              {"Close"}
            </TypographyComponent>
          </Button>
        </Grid>

        <HighLevelSummary
          height="700px"
          isUpdate={isUpdate}
          preference={preference}
          stopChartReload={stopChartReload}
          allSurveys={allSurveys}
          updateReportPreference={updateReportPreference}
          summaryTableColumns={summaryTableColumns}
        />
      </Grid>
    </React.Fragment>
  );
}
// default props
HighlevelsummaryPopup.defaultProps = {
  classes: {},
  isUpdate: false,
  preference: {},
  updateReportPreference: () => { },
  stopChartReload: () => { },
  allSurveys: []
};

// prop types
HighlevelsummaryPopup.propTypes = {
  classes: PropTypes.object,
  isUpdate: PropTypes.bool,
  preference: PropTypes.object,
  updateReportPreference: PropTypes.func,
  stopChartReload: PropTypes.func,
  allSurveys: PropTypes.array
};
export default withStyles(ReportsStyles)(HighlevelsummaryPopup);