import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../../../SurveyPreviewStyles";

// Import Component
import TypographyComponent from "../../../../../../components/TypographyComponent/TypographyComponent";

//import Constants
import { TakeSurveyConstants } from "../../../../../../config/constants";

function ErrMsgComponent(props) {
  // Get Props Values
  const { classes, errMessage } = props;
  return (
    <React.Fragment>
      <Grid item md={12} xs={12} className="pb5">
        <TypographyComponent variant="body1" className={classes.textDanger}>
          {errMessage}
        </TypographyComponent>
      </Grid>
    </React.Fragment>
  );
}
// default props
ErrMsgComponent.defaultProps = {
  classes: {},
  errMessage: TakeSurveyConstants.DEFAULT_REQUIRED_ERROR_MESSAGE,
};

// prop types
ErrMsgComponent.propTypes = {
  classes: PropTypes.object,
  errMessage: PropTypes.string,
};
export default withStyles(SurveyPreviewStyles)(ErrMsgComponent);
