/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Button from "@mui/material/Button";

// Import Styles and Image
import SurveysStyles from "../../SurveysStyles";
import CloseImg from "../../../../assets/images/svg/dialog_close.svg";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

// Import Constant
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants, surveyConstants } from "../../../../config/constants";

// Import Components
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

function CategoryReorder (props) {
  // Define Dispatch
  const dispatch = useDispatch();
  const { classes, questionData } = props;

  // State
  const [category, setCategory] = useState([]);

  // Get Category List
  const questions = useSelector((state) => state.survey.questions);

  // Update Category
  useEffect(() => {
    setCategory(questions.catOrder || []);
  }, []);

  /**
     * Sortable Item
     */
  const SortableItem = SortableElement(props => {
    const { value: item } = props;

    return (
      <Grid item xs={3}
        className={"cursor-move"}
        style={{ zIndex: 99999999 }}>
        <TypographyComponent
          variant="h6"
          className={classes.categoryswap_tile}>
          {item}
        </TypographyComponent>
      </Grid >
    );
  });

  /**
     * Sortable List
     */
  const SortableList = SortableContainer(props => {
    const { items, ...restProps } = props;
    return (
      <Grid container spacing={3}>
        {items.map((item, index) => (
          <SortableItem
            key={`item-${item}`}
            index={index}
            value={item}
            {...restProps}
          />
        ))
        }
      </Grid>
    );
  });

  /**
     * Handle Sort End Event
     * @param {*} e
     */
  const onSortEnd = (e) => {
    const { oldIndex, newIndex } = e;
    setCategory(arrayMove(category, oldIndex, newIndex));
  };

  /**
     * Update Category Re-order
     */
  const updateCategoryReOrder = () => {
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_UPDATE_CATEGORY_REORDER, category));
    props.handleDialogClose();
  };

  /**
    * Handle Dialog Close Event
    */
  const handleDialogClose = () => {
    props.handleDialogClose();
  };

  return (
    <div className={"centercontainer mt-0 pt-2"}>
      <Grid container spacing={1}>
        <Grid item xs={12} className={classes.dialog_close}>
          <Button
            variant="text"
            size={"small"}
            onClick={handleDialogClose}
            pageClassName="ml-1">
            <img src={CloseImg} alt="CloseImg" />
            <TypographyComponent variant="h5" className="pl5">{surveyConstants.CLOSE_BUTTON}</TypographyComponent>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent variant="h4" className="txtSemiBold pt-35">
            {questionData.template==="Manager 360"?surveyQuestionsConstants.REORDER_COMPETENCY:surveyQuestionsConstants.REORDER_CATEGORY}
          </TypographyComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent variant="h6" color="textSecondary">
            {questionData.template==="Manager 360"?surveyQuestionsConstants.REORDER_COMPETENCY_TEXT:surveyQuestionsConstants.REORDER_CATEGORY_TEXT}
          </TypographyComponent>
        </Grid>
        <Grid item md={12} sm={12} xs={12} className={"pt-3 mb-1"}>
          <SortableList
            axis="xy"
            items={category}
            lockToContainerEdges={true}
            lockOffset={"0%"}
            onSortEnd={onSortEnd} 
          />
        </Grid>

        <Grid item xs={12} className={"mt-1"}>
          <Grid container justifyContent={"flex-end"}>
            <Grid item>
              <ButtonComponent
                title={"Save"}
                color={"primary"}
                size={"small"}
                handleClick={updateCategoryReOrder}
                pageClassName={classes.settings_addrulebtn}
              ></ButtonComponent>
              {/* <ButtonComponent
                                title={"Cancel"}
                                color={"secondary"}
                                size={"small"}
                                pageClassName="ml-1"
                                handleClick={handleDialogClose}
                            ></ButtonComponent> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

// default props
CategoryReorder.defaultProps = {
  classes: {},
  handleDialogClose: () => { }
};

// prop types
CategoryReorder.propTypes = {
  classes: PropTypes.object,
  handleDialogClose: PropTypes.func
};

export default withStyles(SurveysStyles)(CategoryReorder);
