import { opportunityAreas } from "../constants/opportunityAreas";

// Set Initial State
const initialState = {
    getOne: [],
    OpenNewPlan:false
};

export default function (state = initialState, action) {
    switch (action.type) {

        // //To get a Particular action plan
        case opportunityAreas.OPPORTUNITY_GET_ONE_REQUEST:
            return {
                ...state
            };
        case opportunityAreas.OPPORTUNITY_GET_ONE_SUCCESS:
            return {
                ...state,
                getOne: action.data.data,
            };
        case opportunityAreas.OPPORTUNITY_GET_ONE_FAILURE:
            return {
                ...state,
                search: initialState.search
            };


        // UPDATE GET ONE CLIENT REDUCER DATA 
        case opportunityAreas.OPPORTUNITY_GET_ONE_UPDATE:
            return {
                ...state,
                getOne: action.data
            };
        
        // Toggle add new plan dialog
        case opportunityAreas.TOGGLE_ADD_PLAN_DIALOG:
            return {
                ...state,
                OpenNewPlan: action.data
            }
        default:
            return state;
    }
}
