import { alertConstant, surveyPreviewConstant, uiConstant } from "../constants";
import { surveyPreviewService } from "../services";
import { baseAction } from "./baseAction";
import { saveAs } from "file-saver";

const { request, success, failure } = baseAction;

var CryptoJS = require("crypto-js");
// const Cryptr = require("cryptr");
// const cryptr = new Cryptr("preSurvey");

// Export All Survey Actions Functions
export const surveyPreviewAction = {
  getSurvey,
  handleSurveyResponse,
  updateRequiredQuestions,
  getFilteredPreviewSurvey,
  updateSurveyLanguage,
  updateSurveyPreviewList,
  setActiveCategoryTab,
  previewSubmit,
  previewReset,
  getPrintSurvey,
  handleSendMailPopup,
  sendTestMail,
  sendFeedBackMail,
};

/**
 * Get Survey For Preview
 * id => surveyId
 */
function getSurvey(surveyId, qryString = "") {
  return (dispatch, getState) => {
    if (surveyId) {

      const state = getState();
      const { getOne, questions, client, surveyRequiredQuestions, surveyResponse } = state.surveyPreview;

      const decrypt = qryString ? CryptoJS.AES.decrypt(decodeURIComponent(qryString), "123") : ""
      const filterOptions = decrypt !== "" ? JSON.parse(decrypt.toString(CryptoJS.enc.Utf8)) : ""

      dispatch(request(surveyPreviewConstant.PREVIEW_PAGE_LOADING_TRUE));
      dispatch(request(surveyPreviewConstant.GET_SURVEY_REQUEST));
      dispatch(request(uiConstant.LOADING_TRUE));
      surveyPreviewService.getSurvey(surveyId, filterOptions).then(
        (data) => {
          let surveyData = data.data && data.data.hasOwnProperty("survey_data") && data.data.survey_data ? data.data.survey_data : [];

          const previewQuestions = data.data && data.data.hasOwnProperty("preview_question_list") && data.data.preview_question_list ? data.data.preview_question_list : null;
          if (previewQuestions) {
            surveyData = { ...surveyData, questions: previewQuestions };
          }
          const demoGraphicFilters = data.data.demographic_filters;
          const filterQuestions = data.data.filter_questions;
          const getOneData =
            data && data.data.survey_data
              ? {
                _id: data.data.survey_data._id,
                name: data.data.survey_data.name,
                type: data.data.survey_data.type,
                description: data.data.survey_data.description,
                status: data.data.survey_data.status,
                expiry: data.data.survey_data.expiry,
                distribute: data.data.survey_data.distribute,
                isDelete: data.data.survey_data.isDelete,
                catOrder: data.data.survey_data.catOrder,
                catDetails: data.data.survey_data.catDetails,
                languages: data.data.survey_data.languages,
                pulse_obj: data.data.survey_data.pulse_obj,
                startDate: data.data.survey_data.startDate,
                endDate: data.data.survey_data.endDate,
                thanks_header: data.data.survey_data.thanks_header,
                thanks_description: data.data.survey_data.thanks_description,
                survey_name: data.data.survey_data.survey_name,
              }
              : getOne;
          let surveyDefaultLang = data && data.data.survey_data ? data.data.survey_data.languages.find((lang) => lang.hasOwnProperty("active") && lang.active) : questions.languages[0];

          if (data.data && data.data.hasOwnProperty("survey_default_lang")) {
            surveyDefaultLang = data.data.survey_default_lang;
          }

          const surveyClient = data && data.data.survey_data.client_id ? data.data.survey_data.client_id : client;

          let surveyReqQuesList = [];
          let surveyRequiredQuestionsObj = {};

          if (data && data.data.survey_data) {
            data.data.survey_data.questions.forEach((ques) => {
              if (ques.isRequired) {
                const ansObj = {
                  baseQusId: ques.baseQusId,
                  ansStatus: false,
                };
                surveyReqQuesList = [...surveyReqQuesList, ansObj];
              }
            });

            surveyRequiredQuestionsObj = surveyReqQuesList.reduce((obj, ques) => Object.assign(obj, { [ques.baseQusId]: ques.ansStatus }), {});
          } else {
            surveyRequiredQuestionsObj = surveyRequiredQuestions;
          }

          const surveyActionData = {
            getOneData,
            surveyData,
            surveyDefaultLang,
            surveyClient,
            surveyResponse,
            surveyRequiredQuestionsObj,
            demoGraphicFilters,
            filterQuestions,
            previewQuestions,
          };

          dispatch(success(surveyPreviewConstant.GET_SURVEY_SUCCESS, surveyActionData));
          dispatch(request(surveyPreviewConstant.PREVIEW_PAGE_LOADING_FALSE));
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(surveyPreviewConstant.GET_SURVEY_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
    } else {
      dispatch(success(surveyPreviewConstant.GET_SURVEY_CLEAR, null));
    }
  };
}

//handle Survey Response Update
function handleSurveyResponse(question, answer, langCode) {
  return (dispatch, getState) => {
    const state = getState();
    const { response, surveyRequiredQuestions, validatedQues, questions } = state.surveyPreview;
    if (question) {
      const { baseQusId, category, maxAnsScale, qusType, text, isRequired } = question;

      const questionText = text[langCode];
      let quesResponse = {
        baseQusId,
        category,
        qusType,
        question: questionText,
        answer,
        maxAnsScale,
      };

      if (isRequired) {
        if (Array.isArray(answer)) {
          surveyRequiredQuestions[baseQusId] = answer.length > 0 ? true : false;
        } else {
          surveyRequiredQuestions[baseQusId] = answer || answer === 0 ? true : false;
        }
      }

      const newResponse = response.length > 0 ? response.filter((res) => res.baseQusId != baseQusId && (res.answer || res.answer === 0)) : [];

      let errorQuestions = [];

      if (newResponse.length > 0 && validatedQues.length > 0) {
        validatedQues.forEach((qid) => {
          const reqStatus = newResponse.filter((res) => qid === res.baseQusId && (res.answer || res.answer === 0));
          if (!reqStatus) {
            errorQuestions.push(qid);
          }
        });
      } else if (Object.keys(surveyRequiredQuestions).length > 0) {
        let requiredQusKeys = Object.keys(surveyRequiredQuestions);
        errorQuestions = requiredQusKeys.filter((qKey) => !surveyRequiredQuestions[qKey]);
      } else {
        errorQuestions = [...validatedQues];
      }

      let surveyResponse = [];

      if (Array.isArray(answer)) {
        surveyResponse = answer.length > 0 ? [...newResponse, quesResponse] : newResponse;
      } else {
        surveyResponse = answer || answer === 0 ? [...newResponse, quesResponse] : newResponse;
      }

      const requiredQuesList = errorQuestions;

      //survey completion percentage calculation
      const totalQuestions = questions.questions;
      const participated = surveyResponse.filter((ans) => ans.answer || ans.answer === 0);
      const surveyPercentage = (parseInt(participated.length) / parseInt(totalQuestions.length)) * 100;
      const actionData = {
        surveyResponse,
        surveyRequiredQuestions,
        requiredQuesList,
        surveyPercentage,
      };
      dispatch(success(surveyPreviewConstant.SURVEY_RESPONSE_UPDATE, actionData));
    }
  };
}

//Get Filtered Preview Survey Questions
function getFilteredPreviewSurvey(filters) {
  return (dispatch, getState) => {
    const state = getState();
    const { filterQuestions, getOne } = state.surveyPreview;
    const surveyId = getOne._id;
    if (filterQuestions && filters) {
      dispatch(request(surveyPreviewConstant.PREVIEW_PAGE_LOADING_TRUE));
      surveyPreviewService.getFilteredPreviewSurvey(surveyId, filters).then(
        (result) => {
          const newQuestionSet = result.data;
          const newData = {
            newQuestionSet,
            filters,
          };
          dispatch(success(surveyPreviewConstant.PREVIEW_FILTERED_QUESTIONS_UPDATE, newData));
          dispatch(request(surveyPreviewConstant.PREVIEW_PAGE_LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(surveyPreviewConstant.GET_SURVEY_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
          dispatch(request(surveyPreviewConstant.PREVIEW_PAGE_LOADING_FALSE));
        }
      );
    }
  };
}

//Update Required Questions
function updateRequiredQuestions(questions) {
  return (dispatch) => {
    if (questions) {
      dispatch(success(surveyPreviewConstant.SURVEY_REQUIRED_QUESTIONS_UPDATE, questions));
    }
  };
}

//Update Survey Language
function updateSurveyLanguage(language) {
  return (dispatch) => {
    if (language) {
      dispatch(success(surveyPreviewConstant.TAKE_SURVEY_UPDATE_LANGUAGE, language));
    }
  };
}

//Update Survey Preview Confirmation List
function updateSurveyPreviewList(surveyId) {
  return (dispatch) => {
    if (surveyId) {
      dispatch(success(surveyPreviewConstant.SURVEY_PREVIEW_CONFIRM_LIST, surveyId));
    }
  };
}

//Update Survey Preview Submit
function setActiveCategoryTab(activeCategoryTab) {
  return (dispatch) => {
    dispatch(success(surveyPreviewConstant.PREVIEW_SET_ACTIVE_CATEGORY_TAB, activeCategoryTab));
  };
}

//Update Survey Preview Submit
function previewSubmit() {
  return (dispatch) => {
    dispatch(success(surveyPreviewConstant.PREVIEW_SURVEY_SUCCESS, true));
  };
}

//Update Survey Preview Reset
function previewReset() {
  return (dispatch) => {
    dispatch(success(surveyPreviewConstant.PREVIEW_SURVEY_SUCCESS, false));
  };
}

//Download Print Survey PDF
function getPrintSurvey(surveyId) {
  return (dispatch, getState) => {
    if (surveyId) {
      const state = getState();
      const { questions } = state.surveyPreview.questions;
      const { surveyLang } = state.surveyPreview;
      dispatch(request(surveyPreviewConstant.PREVIEW_SURVEY_DOWNLOAD_REQUEST, true));
      surveyPreviewService.getPrintSurvey(surveyId, questions, surveyLang).then(
        (data) => {
          if (data && data.data && (data.data.error || data.status === "failed")) {
            dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
            dispatch(request(uiConstant.LOADING_FALSE));
          } else {
            const blob = new Blob([data.data], { type: "application/pdf" });
            saveAs(blob, data.filename);
            dispatch(success(alertConstant.ALERT_SUCCESS, "Successfully downloaded"));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        },
        (error) => {
          // dispatch(failure(pdfConstant.PDF_DOWNLOAD_FAILURE, error));
          // dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
    } else {
      dispatch(success(surveyPreviewConstant.PREVIEW_SURVEY_DOWNLOAD_CLEAR, null));
    }
  };
}

//Survey Mail Popup
function handleSendMailPopup(status) {
  return (dispatch) => {
    dispatch(success(surveyPreviewConstant.PREVIEW_SURVEY_MAIL_BOX, status));
  };
}

//Send Test Preview Survey Mail
function sendTestMail(surveyId, testEmails, emailMessage) {
  return (dispatch, getState) => {
    if (surveyId) {
      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(surveyPreviewConstant.PREVIEW_SURVEY_MAIL_REQUEST, true));
      const state = getState();
      const { getOne, surveyLang, selectedDemoGraphicFilter } = state.surveyPreview;
      const { languages } = getOne;
      const filterLanguage = languages.find((lang) => lang.code === surveyLang);
      const optionsData = {
        surveyLang: filterLanguage,
        demoGraphicFilter: selectedDemoGraphicFilter,
      };

      // const optionStr = cryptr.encrypt(JSON.stringify(optionsData));

      let optionStr = CryptoJS.AES.encrypt(JSON.stringify(optionsData), "123").toString();
      optionStr = encodeURIComponent(optionStr);
      surveyPreviewService.sentTestSurveyMail(surveyId, testEmails, emailMessage, optionStr).then(
        (data) => {
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
          dispatch(success(surveyPreviewConstant.PREVIEW_SURVEY_MAIL_SUCCESS, data));
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(surveyPreviewConstant.PREVIEW_SURVEY_MAIL_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
    } else {
      dispatch(success(surveyPreviewConstant.PREVIEW_SURVEY_MAIL_CLEAR, null));
    }
  };
}

//Send Preview Take Survey Feed Back Mail
function sendFeedBackMail(emailMessage, userId) {
  return (dispatch) => {
    if (userId && emailMessage) {
      dispatch(request(surveyPreviewConstant.PREVIEW_FEED_BACK_MAIL_REQUEST, true));
      surveyPreviewService.sendFeedBackMail(userId, emailMessage).then(
        (data) => {
          dispatch(success(surveyPreviewConstant.PREVIEW_FEED_BACK_MAIL_SUCCESS, data));
        },
        (error) => {
          dispatch(failure(surveyPreviewConstant.PREVIEW_FEED_BACK_MAIL_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
      );
    } else {
      dispatch(success(surveyPreviewConstant.PREVIEW_FEED_BACK_MAIL_CLEAR, null));
    }
  };
}
