import { alertConstant, baseQuestionsConstant, surveyConstant, uiConstant } from "../constants";
import { baseQuestionsService } from "../services";
import { baseAction } from "./baseAction";
import { checkduplicateQuestionInDND } from "../../helpers"
import {surveyQuestionsConstants} from "../../config/constants";

const { request, success, failure } = baseAction;

// Export All Base Questions Actions Functions
export const baseQuestionsAction = {
  getQuestionsByCategoryandSurveyType,
  getQuestionsByQuestionandSurveyType
};

/**
 * Get Questions By Category and Survey Type
 */
function getQuestionsByCategoryandSurveyType(params) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(baseQuestionsConstant.BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_REQUEST));
    baseQuestionsService.getQuestionsByCategoryandSurveyType(params).then(
      (data) => {
        const funResult=checkduplicateQuestionInDND(data.data,params.questions,params.addQuestions,params.languages);
        if(funResult===3){
          dispatch(failure(alertConstant.ALERT_ERROR,surveyQuestionsConstants.SELECT_ENG_LANG));
          dispatch(request(uiConstant.LOADING_FALSE));       
        }else if(funResult===2){
          dispatch(failure(alertConstant.ALERT_ERROR,params.type==="Multi-rater feedback"?surveyQuestionsConstants.SOME_QUESTION_EXIST_DND_COMP:surveyQuestionsConstants.SOME_QUESTION_EXIST_DND));
          dispatch(request(uiConstant.LOADING_FALSE));       
        }else if(funResult===1){
          dispatch(failure(alertConstant.ALERT_ERROR,surveyQuestionsConstants.SAVE_QUESTION));
          dispatch(request(uiConstant.LOADING_FALSE));       
        }else{
          dispatch(success(surveyConstant.SURVEY_ADD_NEW_CATAGORY_QUESTIONS, {category:params.category, data}));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(baseQuestionsConstant.BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Questions By Question and Survey Type
 */
function getQuestionsByQuestionandSurveyType(params) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(baseQuestionsConstant.BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_REQUEST));
    baseQuestionsService.getQuestionsByQuestionandSurveyType(params).then(
      (data) => {
        const funResult=checkduplicateQuestionInDND(data.data,params.questions,params.addQuestions,params.languages);
        if(funResult===3){
          dispatch(failure(alertConstant.ALERT_ERROR,surveyQuestionsConstants.SELECT_ENG_LANG));
          dispatch(request(uiConstant.LOADING_FALSE));
        }else if(funResult===2){
          dispatch(failure(alertConstant.ALERT_ERROR,surveyQuestionsConstants.QUESTION_EXIST_DND));
          dispatch(request(uiConstant.LOADING_FALSE));
        }else{
          dispatch(success(surveyConstant.SURVEY_ADD_NEW_QUESTION_TO_CATEGORY, {category:params.category, data}));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(baseQuestionsConstant.BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}