/* eslint-disable quote-props */
/* eslint-disable prefer-const */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

// import actions
// import { alertAction } from "../../../../../redux/actions";

// import components
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";

// import Constants
import { surveyConstant } from "../../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../../redux/actions";

// import images
import TabAdd from "../../../../../assets/images/svg/plus.svg";

function AddNewAnswerSet (props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, answerData, tabarray, answerSet } = props;

  // define state
  const [newAnswerSet, setNewAnswerSet] = useState("");
  const [addAnswerSetPopup, setAddAnswerSetPopup] = useState(null);

  // Update reducer data
  const updateReducer = (answerSet, ...updateFields) => {
    updateFields.map((item) => {
      answerSet = { ...answerSet, item };
    });
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_EDITANSWERSET_REDUCER_UPDATE, answerSet));
  };

  // Anchor tag events
  const handleAddClick = (event) => {
    setAddAnswerSetPopup(event.currentTarget);
  };
  const handleClose = () => {
    setAddAnswerSetPopup(null);
  };

  const open = Boolean(addAnswerSetPopup);
  const id = open ? "simple-popover" : undefined;

  /**
    * Add New Answer Set
    * @param {*} panel
  */
  const addnewAnswerSet = (newAnswerSet) => {
    let isDuplicateCategory = false;
    tabarray.map((cat) => {
      if (cat.toLowerCase() === newAnswerSet.toLowerCase()) {
        isDuplicateCategory = true;
      }
    });
    if (newAnswerSet && !isDuplicateCategory) {
      tabarray.push(newAnswerSet);
      let newAnswerSetIndex = tabarray.indexOf(newAnswerSet);
      const sets = answerData;
      sets[newAnswerSet] = {
        "en": {
          "0": "",
          "1": "",
          "2": "",
          "3": "",
          "4": "",
          "5": ""
        }
      };
      updateReducer(answerSet, sets, tabarray);
      props.changeActiveTab(newAnswerSetIndex);
    } else if (!newAnswerSet) {
      dispatch(alertAction.error("Answer set name is required"));
    } else {
      dispatch(alertAction.error("Answer set name already exists"));
    }
    setNewAnswerSet("");
  };

  return (
    <React.Fragment>
      <IconButton
        className={"p-0"}
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleAddClick}
        size="large">
        <img src={TabAdd} alt="add tab" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={addAnswerSetPopup}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid className={`${classes.quest_addtab} ${"p15"}`}>
          <TextFieldComponent
            name="catname"
            value={newAnswerSet}
            margin={"dense"}
            size="small"
            autoComplete="off"
            handleChange={(e) => {
              e.value = e.value.replace(/  +/g, " ");
              if (e.value === " ") {
                e.value = e.value.trim();
              }
              setNewAnswerSet(e.value);
            }}
            className={"mb-1"}
          ></TextFieldComponent>
          <Button
            title={"Add"}
            size={"small"}
            variant="contained"
            color="primary"
            className={classes.add_catbtn}
            disableElevation
            onClick={() => {
              addnewAnswerSet(newAnswerSet);
              handleClose();
            }}
          >
            Add
          </Button>
          <Button
            title={"Cancel"}
            variant="contained"
            color="secondary"
            disableElevation
            size={"small"}
            className={`${"ml-1"} ${classes.add_catbtn}`}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Popover>
    </React.Fragment>
  );
}

// default props
AddNewAnswerSet.defaultProps = {
  classes: {},
  answerData: {},
  tabarray: [],
  answerSet: {},
  changeActiveTab: () => { }
};

// prop types
AddNewAnswerSet.propTypes = {
  classes: PropTypes.object,
  answerData: PropTypes.object,
  tabarray: PropTypes.array,
  answerSet: PropTypes.object,
  changeActiveTab: PropTypes.func
};

export default AddNewAnswerSet;
