import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid } from "@mui/material";
import Slider from '@mui/material/Slider';

//import styles
import SliderComponentStyle from './SliderComponentStyle'

const CustomSlider = withStyles({
    root: {
        color: '#54C0FD',
        height: 12,
        padding: 0
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: 0,
        marginLeft: 1,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        '&.Mui-disabled': {
            height: 24,
            width: 24,
            marginTop: 0,
            marginLeft: 1,
        }
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
        top: 47,
        background: 'transparent',
        '& *': {
            background: 'transparent',
            color: '#54C0FD',
            fontSize: "1rem",
            fontWeight: "100"
        },
    },
    track: {
        height: 6,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
        color: '#d8d8d8',
        opacity: 1,
    },
})(Slider);




function SliderComponent(props) {
    const { classes, value, defaultValue, minValue, maxValue, onSelect, disable, } = props;    
    const [currentValue, setCurrentValue] = useState(value)

    useEffect(() => {
        if (value !== currentValue) {
            setCurrentValue(value)
        }
    }, [value])

    const handleChange = (e, val) => {
        setCurrentValue(val)
        onSelect(val);
    }
    return (
        <Grid container alignItems="center" className="pt-1">
            <Grid item md={1} xs={1}>
                {minValue}
            </Grid>
            <Grid item md={8} xs={8} >
                <div className={classes.root}>
                    <CustomSlider
                        // key={`slider-${currentValue}`}
                        valueLabelDisplay="on"
                        aria-label="threshold slider"
                        disabled={disable}
                        min={minValue}
                        max={maxValue}
                        defaultValue={defaultValue}
                        value={currentValue ? currentValue : defaultValue}
                        // value={value ? value : defaultValue}
                        getAriaValueText={(val) => `${val}`}
                        onChange={handleChange}
                    />
                </div>
            </Grid>
            <Grid item md={2} xs={2} className="txtcenter">
                {maxValue}
            </Grid>
        </Grid>
    );
}

// default props
SliderComponent.defaultProps = {
    classes: {},
    disable: false,
    defaultValue: 0,
    minValue: 0,
    maxValue: 15,
    onSelect: () => { }
};

// prop types
SliderComponent.propTypes = {
    classes: PropTypes.object,
    disable: PropTypes.bool,
    defaultValue: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    onSelect: PropTypes.func
};

export default withStyles(SliderComponentStyle)(SliderComponent);
