/* eslint-disable prefer-const */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Import Helpers
import { getReducerUpdatedData } from "../../../../../helpers";

// Import constants
import { surveyQuestionsConstants, surveyIntentionQuesTextDisable, surveyEngagementQuesTextDisable } from "../../../../../config/constants";

// Import Actions
import { categoryAction, alertAction } from "../../../../../redux/actions";

function QuestionText(props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { questionDataSet, category, item, classes } = props;

  let disableText = item && item.baseQusId && surveyIntentionQuesTextDisable && ((surveyIntentionQuesTextDisable.includes(item.baseQusId)) || (surveyEngagementQuesTextDisable.includes(item.baseQusId))) ? true : false;

  // Change Question text value
  const handleQuestionTextChange = (event) => {
    let reducerData = getReducerUpdatedData(event, questionDataSet);
    
    // Empty Translated Questions if Question of Default Language(English) is Edited
    if (Object.keys((reducerData?.['text'] || {})).length > 1) {
      Object.keys(reducerData?.['text']).map(lang => {
        if (lang !== 'en') {
          reducerData['text'][lang] = ''
        }
      })
    }

    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, reducerData));
    } else {
      props.editQuestionsData(reducerData);
    }
  };

  // Dispatch alert for non editable questions
  const handleNotEditable = (event) => {
    if (disableText) {
      dispatch(alertAction.error(surveyQuestionsConstants.NON_EDITABLE_QUEST));
    }
  };

  return (
    <React.Fragment>
      <TextareaAutosize
        className={`${classes.questionTextArea} pe_textarea fontRoboto ${disableText ? 'textDisable cursor-not_allowed' : ''}`}
        id={`newQues-${questionDataSet.baseQusId}`}
        name={"text.en"}
        value={questionDataSet.text.en}
        onChange={(e) => handleQuestionTextChange({ name: "text.en", value: e.target.value })}
        minRows={2}
        style={{
          height: "40.7px",
          overflowY: "auto"
        }}
        readOnly={disableText}
        onClick={handleNotEditable}
      />
    </React.Fragment>
  );
}

// default props
QuestionText.defaultProps = {
  questionDataSet: {},
  item: null,
  category: null,
  editQuestionsData: () => { },
  disableText: false
};

// prop types
QuestionText.propTypes = {
  questionDataSet: PropTypes.object,
  item: PropTypes.object,
  category: PropTypes.string,
  editQuestionsData: PropTypes.func,
  disableText: PropTypes.bool
};

export default QuestionText;
