import { initial } from "lodash";
import { meTakeSurveyConstant } from "../constants/meTakeSurveyConstant";

// Set Initial Table Data
const tableDatas = (sortName) => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: []
      }
    }
  };
};

const initialState = {
  search: { ...tableDatas("subject_name") },
  survey_data: {},
  raters: [],
  getOne: {
    _id: "",
    name: "",
    type: "",
    description: "",
    status: "",
    expiry: "",
    distribute: "",
    isDelete: "",
    catOrder: "",
    catDetails: "",
    languages: "",
    pulse_obj: "",
    startDate: "",
    endDate: "",
  },
  questions: {
    _id: "",
    type: "",
    name: "",
    catOrder: [],
    questions: [],
    catDetails: {},
    survey_name: {},
    languages: [
      {
        code: "en",
        name: "English",
        active: true,
      },
    ],
  },
  client: {
    name: "",
    logo: "",
    _id: "",
  },
  participant: {
    firstName: "",
    lastName: "",
    _id: "",
    kiosk_username: "",
    kiosk_password: "",
  },
  meParticipant: {
    firstName: "",
    lastName: "",
    empId: "",
    subject_id: "",
    subject_name: ""
  },
  isLoading: false,
  activeCategoryTab: 0,
  surveyLang: "en",
  preResponse: [],
  response: [],
  validatedQues: [],
  surveyRequiredQuestions: {},
  completionPercentage: 0,
  surveyActionStatus: "open",
  surveyEditResponse: false,
  rgResponses: {},
  categoryQuestions: [],
  anonymousUserData: null,
  surveyDeviceType: "Internet",
  takeSurveyUrl: "",
  //Survey Auth Prams
  surveyAuthType: null,
  surveyAuthValidate: false,
  takeSurveyStatus: false,
  takeSurveyErrors: null,
  //anonymous user multiple Response Flag
  surveyUpdateFlag: false,
  callerId: null, // Caller Id Only For Sp Caller Take survey
  spCallerHistory: [],
  firstRender: false,
  surveyStartsAt: null,
  assignedDate: null,
  isUrlDelete: false,
  message: '',
};

export default function (state = initialState, action) {
  switch (action.type) {

    // ME Survey Raters
    case meTakeSurveyConstant.ME_SURVEY_GET_RATERS_REQUEST:
      return {
        ...state,
        raters: initialState.raters
      }
    case meTakeSurveyConstant.ME_SURVEY_GET_RATERS_SUCCESS:
      return {
        ...state,
        raters: action.data && action.data.ratersData ? action.data.ratersData : initialState.raters,
        getOne: action.data && action.data.surveyData && action.data.surveyData.getOneData ? action.data.surveyData.getOneData : initialState.getOne,
        client: action.data && action.data.surveyData && action.data.surveyData.surveyClient ? action.data.surveyData.surveyClient : initialState.client,
        participant: action.data && action.data.surveyParticipant ? action.data.surveyParticipant : initialState.participant,
      }
    case meTakeSurveyConstant.ME_SURVEY_GET_RATERS_FAILURE:
      return {
        ...state,
        raters: initialState.raters,
      }
    case meTakeSurveyConstant.ME_SURVEY_GETONE_CLEAR:
      return {
        ...state,
        raters: initialState.raters,
      }

    case meTakeSurveyConstant.ME_SURVEY_GET_ONE_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: initialState.settings,
        surveyUpdateFlag: initialState.surveyUpdateFlag,
        surveyStartsAt: initialState.surveyStartsAt,
      };
    case meTakeSurveyConstant.ME_SURVEY_GET_ONE_SUCCESS:
      return {
        ...state,
        getOne: action.data && action.data.getOneData ? action.data.getOneData : initialState.getOne,
        questions: action.data && action.data.surveyData ? action.data.surveyData : initialState.questions,
        client: action.data && action.data.surveyClient ? action.data.surveyClient : initialState.client,
        response: action.data && action.data.surveyResponse ? action.data.surveyResponse : initialState.response,
        preResponse: action.data && action.data.surveyResponse ? action.data.surveyResponse : initialState.preResponse,
        rgResponses: action.data && action.data.rgResponses ? action.data.rgResponses : initialState.rgResponses,
        meParticipant: action.data && action.data.surveyMeParticipant ? action.data.surveyMeParticipant : initialState.meParticipant,
        participant: action.data && action.data.surveyParticipant ? action.data.surveyParticipant : initialState.participant,
        surveyLang: action.data && action.data.surveyDefaultLang ? action.data.surveyDefaultLang.code : initialState.surveyLang,
        surveyRequiredQuestions: action.data && action.data.surveyRequiredQuestionsObj ? action.data.surveyRequiredQuestionsObj : initialState.surveyRequiredQuestions,
        completionPercentage: action.data && action.data.surveyPercentage ? Math.round(action.data.surveyPercentage, 2) : initialState.completionPercentage,
        surveyAuthType: action.data && action.data.surveyAuthType ? action.data.surveyAuthType : initialState.surveyAuthType,
        surveyAuthValidate: action.data && action.data.surveyAuthValidate ? action.data.surveyAuthValidate : initialState.surveyAuthValidate,
        surveyEditResponse: action.data && action.data.surveyEditResponse ? action.data.surveyEditResponse : initialState.surveyEditResponse,
        takeSurveyStatus: action.data && action.data.takeSurveyStatus ? action.data.takeSurveyStatus : initialState.takeSurveyStatus,
        anonymousUserData: initialState.anonymousUserData,
        firstRender: true,
        surveyStartsAt: new Date(),
        assignedDate: action.data && action.data.assignedDate ? action.data.assignedDate : initialState.assignedDate,
      };
    case meTakeSurveyConstant.ME_SURVEY_GET_ONE_CLEAR:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: initialState.settings,
        surveyStartsAt: initialState.surveyStartsAt,
      };
    case meTakeSurveyConstant.ME_SURVEY_GET_ONE_FAILURE:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: initialState.settings,
        takeSurveyErrors: action.error,
      };

    case meTakeSurveyConstant.ME_SURVEY_RESPONSE_UPDATE:
      return {
        ...state,
        response: action.data.surveyResponse,
        validatedQues: action.data.requiredQuesList,
        completionPercentage: Math.round(action.data.surveyPercentage),
        surveyRequiredQuestions: action.data.surveyRequiredQuestions,
        rgResponses: action.data.rgResponses,
      };

    case meTakeSurveyConstant.ME_SURVEY_REQUIRED_QUESTIONS_UPDATE:
      return {
        ...state,
        validatedQues: action.data,
      };

    case meTakeSurveyConstant.ME_TAKE_SURVEY_UPDATE_LANGUAGE:
      return {
        ...state,
        surveyLang: action.data,
      };

    case meTakeSurveyConstant.ME_SURVEY_RESPONSE_UPDATE_SUCCESS:
      const surveyActionStatus = action.data.data.status == "success" && action.data.actionType == "submit" ? "success" : "open";
      return {
        ...state,
        surveyActionStatus: surveyActionStatus,
        surveyUpdateFlag: true,
        preResponse: state.response,
      };
    // ME Takesurvey List
    case meTakeSurveyConstant.ME_TAKESURVEY_SEARCH_REQUEST:
      return {
        ...state,
        selectedParticipants: []
      };
    case meTakeSurveyConstant.ME_TAKESURVEY_SEARCH_SUCCESS:
      let returnData = action.data.data?.is_delete ? 
      { ...state, isUrlDelete: action.data.data?.is_delete ? action.data.data.is_delete : false, } : 
      {
        ...state,
        message: action?.data?.message ? action?.data?.message : initialState.message,
        search: {
          data: action?.data?.data?.raters,
          page: action?.data?.page
        },
        meParticipant: {
          ...state?.meParticipant,
          ...action?.data?.data?.participant,
          ...action.data?.data?.raters[0],
        },
        surveyLang: action?.data && action?.data?.data?.surveyLang?.code ? action.data.data.surveyLang.code : initialState.surveyLang,
        questions: action?.data && action?.data?.data?.survey_data ? action.data.data.survey_data : initialState.questions,
        survey_data: action?.data?.data?.survey_data,
        client: action?.data?.data?.survey_data?.client_id ? action.data.data.survey_data.client_id : initialState.client,
        isUrlDelete: action?.data?.data?.is_delete ? action.data.data.is_delete : false,
      };
      return returnData;
    case meTakeSurveyConstant.ME_TAKESURVEY_SEARCH_FAILURE:
      return {
        ...state
      };
    case meTakeSurveyConstant.CLEAR_ME_TAKE_SURVEY_DATA:
      return {
        ...initialState,
      };
    // Survey Default Reducer Update
    default:
      return state;
  }
}