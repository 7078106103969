import { surveyPreviewConstant } from "../constants/surveyPreviewConstant";

const initialState = {
  getOne: {
    _id: "",
    name: "",
    type: "",
    description: "",
    status: "",
    expiry: "",
    distribute: "",
    isDelete: "",
    catOrder: "",
    catDetails: "",
    languages: "",
    pulse_obj: "",
    startDate: "",
    endDate: "",
    thanks_header:"",
    thanks_description: "",
    survey_name: ""
  },
  questions: {
    _id: "",
    type: "",
    name: "",
    catOrder: [],
    questions: [],
    catDetails: {},
    survey_name:{},
    languages: [
      {
        code: "en",
        name: "English",
        active: true,
      },
    ],
  },
  client: {
    name: "",
    logo: "",
    _id: "",
  },
  isLoading: false,
  activeCategoryTab: 0,
  surveyLang: "en",
  response: [],
  validatedQues: [],
  surveyRequiredQuestions: {},
  completionPercentage: 0,
  rgResponses: {},
  categoryQuestions: [],
  previewConfQuestions: [],
  previewSurveySubmit: false,
  demoGraphicFilters: [],
  selectedDemoGraphicFilter: [],
  previewFilters: [],
  filterQuestions: [],
  surveyMailStatus: false,
  surveyMailBox: false,
  surveyFeedBackMail: false,
  pageLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case surveyPreviewConstant.PREVIEW_PAGE_LOADING_TRUE:
      return {
        ...state,
        pageLoading: true,
      };
    case surveyPreviewConstant.PREVIEW_PAGE_LOADING_FALSE:
      return {
        ...state,
        pageLoading: false,
      };
    // Survey Getone Reducer
    case surveyPreviewConstant.GET_SURVEY_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        previewSurveySubmit: initialState.previewSurveySubmit,
        response: initialState.response,
        validatedQues: initialState.validatedQues,
        completionPercentage: initialState.completionPercentage,
        surveyRequiredQuestions: initialState.surveyRequiredQuestions,
        rgResponses: initialState.rgResponses,
        activeCategoryTab: initialState.activeCategoryTab,
        surveyMailBox: initialState.surveyMailBox,
        surveyFeedBackMail: initialState.surveyFeedBackMail,
      };
    case surveyPreviewConstant.GET_SURVEY_SUCCESS:
      return {
        ...state,
        getOne: action.data && action.data.getOneData ? action.data.getOneData : initialState.getOne,
        questions: action.data && action.data.surveyData ? action.data.surveyData : initialState.questions,
        client: action.data && action.data.surveyClient ? action.data.surveyClient : initialState.client,
        surveyLang: action.data && action.data.surveyDefaultLang ? action.data.surveyDefaultLang : initialState.surveyLang,
        surveyRequiredQuestions: action.data && action.data.surveyRequiredQuestionsObj ? action.data.surveyRequiredQuestionsObj : initialState.surveyRequiredQuestions,
        completionPercentage: action.data && action.data.surveyPercentage ? Math.round(action.data.surveyPercentage, 2) : initialState.completionPercentage,
        demoGraphicFilters: action.data && action.data.demoGraphicFilters ? action.data.demoGraphicFilters : initialState.demoGraphicFilters,
        filterQuestions: action.data && action.data.filterQuestions ? action.data.filterQuestions : initialState.filterQuestions,
        activeCategoryTab: initialState.activeCategoryTab,
      };
    case surveyPreviewConstant.GET_SURVEY_CLEAR: {
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        previewSurveySubmit: initialState.previewSurveySubmit,
      };
    }
    case surveyPreviewConstant.GET_SURVEY_FAILURE:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
      };

    case surveyPreviewConstant.SURVEY_RESPONSE_UPDATE:
      return {
        ...state,
        response: action.data.surveyResponse,
        validatedQues: action.data.requiredQuesList,
        completionPercentage: Math.round(action.data.surveyPercentage),
        surveyRequiredQuestions: action.data.surveyRequiredQuestions,
        rgResponses: action.data.rgResponses,
      };
    case surveyPreviewConstant.SURVEY_REQUIRED_QUESTIONS_UPDATE:
      return {
        ...state,
        validatedQues: action.data,
      };
    case surveyPreviewConstant.TAKE_SURVEY_UPDATE_LANGUAGE:
      return {
        ...state,
        surveyLang: action.data,
      };
    case surveyPreviewConstant.SURVEY_PREVIEW_CONFIRM_LIST:
      return {
        ...state,
        previewConfQuestions: [...state.previewConfQuestions, action.data],
      };
    case surveyPreviewConstant.PREVIEW_SET_ACTIVE_CATEGORY_TAB:
      return {
        ...state,
        activeCategoryTab: action.data,
      };
    case surveyPreviewConstant.SURVEY_PREVIEW_CONFIRM_LIST:
      return {
        ...state,
        previewConfQuestions: [...state.previewConfQuestions, action.data],
      };
    case surveyPreviewConstant.PREVIEW_SURVEY_SUCCESS:
      return {
        ...state,
        previewSurveySubmit: action.data,
        response: initialState.response,
        validatedQues: initialState.validatedQues,
        completionPercentage: initialState.completionPercentage,
        surveyRequiredQuestions: initialState.surveyRequiredQuestions,
        rgResponses: initialState.rgResponses,
        activeCategoryTab: initialState.activeCategoryTab,
      };
    case surveyPreviewConstant.PREVIEW_FILTERED_QUESTIONS_UPDATE:
      return {
        ...state,
        questions: {
          ...state.questions,
          questions: action.data.newQuestionSet,
        },
        activeCategoryTab: initialState.activeCategoryTab,
        response: initialState.response,
        validatedQues: initialState.validatedQues,
        completionPercentage: initialState.completionPercentage,
        surveyRequiredQuestions: initialState.surveyRequiredQuestions,
        rgResponses: initialState.rgResponses,
        selectedDemoGraphicFilter: action.data && action.data.filters ? action.data.filters : initialState.selectedDemoGraphicFilter,
      };
    case surveyPreviewConstant.PREVIEW_SURVEY_MAIL_BOX:
      return {
        ...state,
        surveyMailBox: action.data,
      };
    case surveyPreviewConstant.PREVIEW_SURVEY_MAIL_REQUEST:
      return {
        ...state,
        surveyMailStatus: true,
      };
    case surveyPreviewConstant.PREVIEW_SURVEY_MAIL_SUCCESS:
      return {
        ...state,
        surveyMailStatus: initialState.surveyMailStatus,
      };
    case surveyPreviewConstant.PREVIEW_SURVEY_MAIL_FAILURE:
      return {
        ...state,
        surveyMailStatus: initialState.surveyMailStatus,
      };
    case surveyPreviewConstant.PREVIEW_FEED_BACK_MAIL_REQUEST:
      return {
        ...state,
        surveyFeedBackMail: false,
      };
    case surveyPreviewConstant.PREVIEW_FEED_BACK_MAIL_SUCCESS:
      return {
        ...state,
        surveyFeedBackMail: true,
      };
    case surveyPreviewConstant.PREVIEW_FEED_BACK_MAIL_FAILURE:
      return {
        ...state,
        surveyFeedBackMail: false,
      };
    // Survey Default Reducer Update
    default:
      return state;
  }
}
