import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";

/**
 * Dialog Component
 * @param {*} props
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function PreviewDialogComponent(props) {
  const { children, open, fullScreen, className } = props;
  /**
   * Handle Close
   */
  const handleClose = () => {
    handleClose(!open);
  };

  return (
    <Dialog
      data-test="dialog-test"
      onClose={handleClose}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      aria-labelledby="dialog"
      className={className}
      open={open}
    >
      {children}
    </Dialog>
  );
}

// default props
PreviewDialogComponent.defaultProps = {
  className: "",
  children: {},
  fullScreen: false,
  open: false,
  handleClose: () => {},
};

// prop types
PreviewDialogComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  fullScreen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

/**
 * Export Container
 */
export default PreviewDialogComponent;
