import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../SurveyPreviewStyles";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

function CategoryDescription(props) {
  //get props
  const {
    classes,
    catDetails,
    activeCategoryName,
    activeCategoryTab,
    langCode,
  } = props;

  const questionRef = useRef(null);

  useEffect(() => {
    questionRef.current.scrollIntoView({
      // behavior: "smooth",
      block: "start",
      inline: "start",
    });
    //  window.scrollTo(0, 0)
  }, []);
  const categoryDesc = catDetails[activeCategoryName][langCode];
  const engCategoryDesc = catDetails[activeCategoryName]["en"];
  const categoryDescText = categoryDesc && categoryDesc.hasOwnProperty("desc") && categoryDesc.desc ?  categoryDesc.desc : (engCategoryDesc && engCategoryDesc.hasOwnProperty("desc") ? engCategoryDesc.desc : null)

  return (
    <React.Fragment>
      <Grid
        item
        sm={12}
        className={classes.surveyCateDescription}
        ref={questionRef}
      >
        <TypographyComponent
          variant="body1"
          className={`${classes.surveyCateDescription} mt-1 mb5`}
          extraProps={{ "tabindex": 0, "aria-label": categoryDescText }}
        >
          {categoryDescText}
        </TypographyComponent>
      </Grid>
    </React.Fragment>
  );
}
// default props
CategoryDescription.defaultProps = {
  classes: {},
  catDetails: {},
  langCode: "en",
};

// prop types
CategoryDescription.propTypes = {
  classes: PropTypes.object,
  catDetails: PropTypes.any,
  langCode: PropTypes.string,
};
export default withStyles(SurveyPreviewStyles)(CategoryDescription);
