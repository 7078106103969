import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Import Styles and Image
import CloseImg from "../../../../assets/images/svg/dashboard_close.svg";
import ReportsStyles from "../../ReportsStyles";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

const WordsPopup = (props) => {
  //Get props
  const { classes, selectedWord, onClose, allAns } = props;
  let answers = [];
  let selectedText = selectedWord["word"];
  allAns.map((item) => {
    let matchFound = false;
    let resultText=""
    if (item.toLowerCase().match(selectedText)) {
      let textArr = item.split(/[\s.,!?:'();\/"@#$-]+/);
      textArr.map((text) => {
        if (text.toLowerCase() == selectedText.toLowerCase() && !matchFound) {
          resultText+=` <span class='highlighted'>${text}</span>`;
          matchFound = true;

        }else{
          resultText+=` ${text}`;
        }
      });
      if (matchFound) {
        answers.push(resultText);
      }
    }
  });

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        style={{
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Grid
          item
          xs={12}
          className={`${classes.dialog_close}`}
        >
          <Button variant="text" size={"small"} onClick={onClose}>
            <img src={CloseImg} alt="CloseImg" />
            <TypographyComponent variant="h5" className="pl5" title={"Close"} />
          </Button>
        </Grid>
        <Grid md={12}>
          <Grid className={`${classes.borderline} pb-1`}>
            {selectedWord && selectedWord["word"] && (
              <TypographyComponent variant="h3" className="pl5">
                {`${selectedWord["word"]}(${selectedWord["value"]})`}
              </TypographyComponent>
            )}
          </Grid>
          <Grid>
            {answers.length > 0 &&
              answers.map((item,index) => (
                <React.Fragment key={index}>
                  <p dangerouslySetInnerHTML={{ __html: item }} className={`${classes.borderline} pb-1`}></p>
                </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
// default props
WordsPopup.defaultProps = {
  classes: {},
};

// prop types
WordsPopup.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(WordsPopup);
