import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All dashboard Service Functions
 */
export const reportService = {
  getSurveys,
  getDemographics,
  demographicsByName,
  getCommentQuestions,
  saveOrUpdateReport,
  getSavedReports,
  getReportData,
  remove,
  getBenchmarkIndustryList
};

/**
 * GET RESPONSE SURVEYS
 */
async function getSurveys(search) {
  const response = await apihelper.getRequest('filter/getSurvey/' + search);
  return response;
}

/**
 * GET Demographics
 */
async function getDemographics(filterData) {
  const response = await apihelper.postRequest("filter/demographic", filterData);
  return response;
}

/**
 * Get Demographics by name
 */
async function demographicsByName(filterData) {
  const response = await apihelper.postRequest("filter/demographic/name", filterData);
  return response;
}

/**
 * Get Comment Questions
 */
async function getCommentQuestions(filterData) {
  const response = await apihelper.postRequest("report/getCommentQuestions", filterData);
  return response;
}

/**
 * SAve or update report
 */
async function saveOrUpdateReport(filterData) {
  const response = await apihelper.postRequest("report/saveReport", filterData);
  return response;
}

/**
 * get report data
 */
async function getReportData(id) {
  const response = await apihelper.postRequest("report/getReport", id);
  return response;
}

/**
 * get report data
 */
async function remove(id) {
  const response = await apihelper.deleteRequest("report", id);
  return response;
}

/**
 * Get Saved Reports
 */
async function getSavedReports(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`report/getSavedReports/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

/**
 * Get Benchmark Indsutry List
 */
async function getBenchmarkIndustryList() {
  const response = await apihelper.getRequest("report/getBenchmarkIndustryList");
  return response;
}