import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All filter Service Functions
 */
export const filterService = {
  getSurveys,
  getDemographics,
  demographicsByName,
  getDemographicsbyPreference
};

/**
 * GET RESPONSE SURVEYS
 */
async function getSurveys(search) {
  const response = await apihelper.getRequest('filter/getSurvey/'+search);
  return response;
}

/**
 * GET Demographics
 */
async function getDemographics(filterData) {
  const response = await apihelper.postRequest("filter/demographic", filterData);
  return response;
}

/**
 * GET Demographics
 */
async function getDemographicsbyPreference() {
  const response = await apihelper.postRequest("filter/demographicPreference");
  return response;
}

/**
 * Filter Demographics by name
 */
async function demographicsByName(filterData) {
  const response = await apihelper.postRequest("filter/demographic/name", filterData);
  return response;
}