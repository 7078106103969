// Survey reducer types
export const surveyConstant = {
  SURVEY_GETONE_REQUEST: "SURVEY_GETONE_REQUEST",
  SURVEY_GETONE_SUCCESS: "SURVEY_GETONE_SUCCESS",
  SURVEY_GETONE_REDUCER_UPDATE: "SURVEY_GETONE_REDUCER_UPDATE",
  SURVEY_GETONE_CLEAR: "SURVEY_GETONE_CLEAR",
  SURVEY_GETONE_FAILURE: "SURVEY_GETONE_FAILURE",

  SURVEY_TYPE_REDUCER_UPDATE: "SURVEY_TYPE_REDUCER_UPDATE",

  SURVEY_SETTINGS_MESYSTEMID_REQUEST: "SURVEY_SETTINGS_MESYSTEMID_REQUEST",
  SURVEY_SETTINGS_MESYSTEMID_SUCCESS: "SURVEY_SETTINGS_MESYSTEMID_SUCCESS",
  SURVEY_SETTINGS_MESYSTEMID_FAILURE: "SURVEY_SETTINGS_MESYSTEMID_FAILURE",

  SURVEY_SETTINGS_ACTIONALERT_REQUEST: "SURVEY_SETTINGS_ACTIONALERT_REQUEST",
  SURVEY_SETTINGS_ACTIONALERT_SUCCESS: "SURVEY_SETTINGS_ACTIONALERT_SUCCESS",
  SURVEY_SETTINGS_ACTIONALERT_FAILURE: "SURVEY_SETTINGS_ACTIONALERT_FAILURE",

  SURVEY_SEARCH_REQUEST: "SURVEY_SEARCH_REQUEST",
  SURVEY_SEARCH_SUCCESS: "SURVEY_SEARCH_SUCCESS",
  SURVEY_SEARCH_FAILURE: "SURVEY_SEARCH_FAILURE",

  SURVEY_DELETE_REQUEST: "SURVEY_DELETE_REQUEST",
  SURVEY_DELETE_SUCCESS: "SURVEY_DELETE_SUCCESS",
  SURVEY_DELETE_FAILURE: "SURVEY_DELETE_FAILURE",

  SURVEY_CREATE_REQUEST: "SURVEY_CREATE_REQUEST",
  SURVEY_CREATE_SUCCESS: "SURVEY_CREATE_SUCCESS",
  SURVEY_CREATE_FAILURE: "SURVEY_CREATE_FAILURE",

  SURVEY_UPDATE_REQUEST: "SURVEY_UPDATE_REQUEST",
  SURVEY_UPDATE_SUCCESS: "SURVEY_UPDATE_SUCCESS",
  SURVEY_UPDATE_FAILURE: "SURVEY_UPDATE_FAILURE",

  // Survey Copy Constants
  SURVEY_COPY_REQUEST: "SURVEY_COPY_REQUEST",
  SURVEY_COPY_SUCCESS: "SURVEY_COPY_SUCCESS",
  SURVEY_COPY_FAILURE: "SURVEY_COPY_FAILURE",

  // SURVEY QUESIONS PANAL CATEGORY TAB ACTIVE
  SURVEY_QUESTIONS_CATEGORY_ACTIVE_TAB: "SURVEY_QUESTIONS_CATEGORY_ACTIVE_TAB",

  // SURVEY QUESTION REDUCERS
  SURVEY_GET_QUESTIONS_REQUEST: "SURVEY_GET_QUESTIONS_REQUEST",
  SURVEY_GET_QUESTIONS_SUCCESS: "SURVEY_GET_QUESTIONS_SUCCESS",
  SURVEY_ADD_NEW_CATAGORY_QUESTIONS: "SURVEY_ADD_NEW_CATAGORY_QUESTIONS",
  SURVEY_ADD_NEW_QUESTION_TO_CATEGORY: "SURVEY_ADD_NEW_QUESTION_TO_CATEGORY",
  SURVEY_GET_QUESTIONS_FAILURE: "SURVEY_GET_QUESTIONS_FAILURE",

  SURVEY_SET_ACTIVE_LANGUAGE: "SURVEY_SET_ACTIVE_LANGUAGE",
  SURVEY_UPDATE_CATEGORY_REORDER: "SURVEY_UPDATE_CATEGORY_REORDER",
  SURVEY_QUESTIONS_REDUCER_UPDATE: "SURVEY_QUESTIONS_REDUCER_UPDATE",
  SURVEY_QUESTIONS_QUESTIONS_REDUCER_UPDATE: "SURVEY_QUESTIONS_QUESTIONS_REDUCER_UPDATE",
  SURVEY_QUESTIONS_MOVE_CATEGORY_REDUCER_UPDATE: "SURVEY_QUESTIONS_MOVE_CATEGORY_REDUCER_UPDATE",
  SURVEY_CATAGORY_DELETE_REDUCER_UPDATE: "SURVEY_CATAGORY_DELETE_REDUCER_UPDATE",

  SURVEY_SAVE_QUESTIONS_REQUEST: "SURVEY_SAVE_QUESTIONS_REQUEST",
  SURVEY_SAVE_QUESTIONS_SUCCESS: "SURVEY_SAVE_QUESTIONS_SUCCESS",
  SURVEY_SAVE_QUESTIONS_FAILURE: "SURVEY_SAVE_QUESTIONS_FAILURE",

  // Survey settings get constants
  SURVEY_SETTINGS_GET_REQUEST: "SURVEY_SETINGS_GET_REQUEST",
  SURVEY_SETTINGS_GET_SUCCESS: "SURVEY_SETTINGS_GET_SUCCESS",
  SURVEY_SETTINGS_REDUCER_UPDATE: "SURVEY_SETTINGS_REDUCER_UPDATE",
  SURVEY_SETTINGS_GET_FAILURE: "SURVEY_SETTINGS_GET_FAILURE",
  SURVEY_NATIVE_LANGUAGE_REDUCER_UPDATE: "SURVEY_NATIVE_LANGUAGE_REDUCER_UPDATE",


  SURVEY_SETTINGS_SAVE_REQUEST: "SURVEY_SETINGS_SAVE_REQUEST",
  SURVEY_SETTINGS_SAVE_SUCCESS: "SURVEY_SETTINGS_SAVE_SUCCESS",
  SURVEY_SETTINGS_SAVE_FAILURE: "SURVEY_SETTINGS_SAVE_FAILURE",

  // SEND MAIL TO ASSIGNED PARTICIPATS
  SURVEY_SETTINGS_SENDMAIL_REQUEST: "SURVEY_SETTINGS_SENDMAIL_REQUEST",
  SURVEY_SETTINGS_SENDMAIL_SUCCESS: "SURVEY_SETTINGS_SENDMAIL_SUCCESS",
  SURVEY_SETTINGS_SENDMAIL_FAILURE: "SURVEY_SETTINGS_SENDMAIL_FAILURE",

  // SEND ACTIONCOACH_MAIL MAIL TO ASSIGNED PARTICIPATS
  SURVEY_SETTINGS_ACTIONCOACH_MAIL_REQUEST: "SURVEY_SETTINGS_ACTIONCOACH_MAIL_REQUEST",
  SURVEY_SETTINGS_ACTIONCOACH_MAIL_SUCCESS: "SURVEY_SETTINGS_ACTIONCOACH_MAIL_SUCCESS",
  SURVEY_SETTINGS_ACTIONCOACH_MAIL_FAILURE: "SURVEY_SETTINGS_ACTIONCOACH_MAIL_FAILURE",

  // SEND SMS TO ASSIGNED PARTICIPATS
  SURVEY_SETTINGS_SENDSMS_REQUEST: "SURVEY_SETTINGS_SENDSMS_REQUEST",
  SURVEY_SETTINGS_SENDSMS_SUCCESS: "SURVEY_SETTINGS_SENDSMS_SUCCESS",
  SURVEY_SETTINGS_SENDSMS_FAILURE: "SURVEY_SETTINGS_SENDSMS_FAILURE",

  // SEND TRANSFLO NOTIFICATION TO ASSIGNED PARTICIPATS
  SURVEY_SETTINGS_SENDTRANSFLO_REQUEST: "SURVEY_SETTINGS_SENDTRANSFLO_REQUEST",
  SURVEY_SETTINGS_SENDTRANSFLO_SUCCESS: "SURVEY_SETTINGS_SENDTRANSFLO_SUCCESS",
  SURVEY_SETTINGS_SENDTRANSFLO_FAILURE: "SURVEY_SETTINGS_SENDTRANSFLO_FAILURE",

  //EDIT ANSWER SET_SAVE
  SURVEY_EDITANSWERSET_SAVE_REQUEST: "SURVEY_EDITANSWERSET_SAVE_REQUEST",
  SURVEY_EDITANSWERSET_SAVE_SUCCESS: "SURVEY_EDITANSWERSET_SAVE_SUCCESS",
  SURVEY_EDITANSWERSET_SAVE_FAILURE: "SURVEY_EDITANSWERSET_SAVE_FAILURE",

  //EDIT ANSWER SET_APPLY
  SURVEY_EDITANSWERSET_APPLY_REQUEST: "SURVEY_EDITANSWERSET_APPLY_REQUEST",
  SURVEY_EDITANSWERSET_APPLY_SUCCESS: "SURVEY_EDITANSWERSET_APPLY_SUCCESS",
  SURVEY_EDITANSWERSET_APPLY_FAILURE: "SURVEY_EDITANSWERSET_APPLY_FAILURE",

  //GET ANSWER SET
  SURVEY_EDITANSWERSET_GET_REQUEST: "SURVEY_EDITANSWERSET_GET_REQUEST",
  SURVEY_EDITANSWERSET_GET_SUCCESS: "SURVEY_EDITANSWERSET_GET_SUCCESS",
  SURVEY_EDITANSWERSET_GET_FAILURE: "SURVEY_EDITANSWERSET_GET_FAILURE",

  SURVEY_EDITANSWERSET_REDUCER_UPDATE: "SURVEY_EDITANSWERSET_REDUCER_UPDATE",

  //GET RESPONSE DETAILS
  SURVEY_RESPONSE_GET_REQUEST: "SURVEY_RESPONSE_GET_REQUEST",
  SURVEY_RESPONSE_GET_SUCCESS: "SURVEY_RESPONSE_GET_SUCCESS",
  SURVEY_RESPONSE_GET_FAILURE: "SURVEY_RESPONSE_GET_FAILURE",

  //GET ANONYMOUS KIOSK SHORTNAME
  ANONYMOUS_KIOSK_SHORTNAME_GET_REQUEST: "ANONYMOUS_KIOSK_SHORTNAME_GET_REQUEST",
  ANONYMOUS_KIOSK_SHORTNAME_GET_SUCCESS: "ANONYMOUS_KIOSK_SHORTNAME_GET_SUCCESS",
  ANONYMOUS_KIOSK_SHORTNAME_GET_FAILURE: "ANONYMOUS_KIOSK_SHORTNAME_GET_FAILURE",

  //trigger Assign survey
  TRIGGER_ASSIGN_SURVEY_GET_REQUEST: "TRIGGER_ASSIGN_SURVEY_GET_REQUEST",
  TRIGGER_ASSIGN_SURVEY_GET_SUCCESS: "TRIGGER_ASSIGN_SURVEY_GET_SUCCESS",
  TRIGGER_ASSIGN_SURVEY_GET_FAILURE: "TRIGGER_ASSIGN_SURVEY_GET_FAILURE",

  //Survey Preview
  //Open & Close Survey Preview Popup Screen
  SURVEY_PREVIEW_REQUEST: "SURVEY_PREVIEW_REQUEST",
  SURVEY_PREVIEW_SUCCESS: "SURVEY_PREVIEW_SUCCESS",
  SURVEY_PREVIEW_FAILURE: "SURVEY_PREVIEW_FAILURE",

  //Survey Settings
  //Open & Close Survey Preview Popup Screen
  SURVEY_SETTINGS_TRANSLATE_REQUEST: "SURVEY_SETTINGS_TRANSLATE_REQUEST",
  SURVEY_SETTINGS_TRANSLATE_SUCCESS: "SURVEY_SETTINGS_TRANSLATE_SUCCESS",
  SURVEY_SETTINGS_TRANSLATE_FAILURE: "SURVEY_SETTINGS_TRANSLATE_FAILURE",

  SURVEY_SETTINGS_COMMUNICATION_TRANSLATE_REQUEST: "SURVEY_SETTINGS_TRANSLATE_REQUEST",
  SURVEY_SETTINGS_COMMUNICATION_TRANSLATE_SUCCESS: "SURVEY_SETTINGS_TRANSLATE_SUCCESS",
  SURVEY_SETTINGS_COMMUNICATION_TRANSLATE_FAILURE: "SURVEY_SETTINGS_TRANSLATE_FAILURE",

  // Survey questions Translate
  SURVEY_QUESTIONS_TRANSLATE_REQUEST: "SURVEY_QUESTIONS_TRANSLATE_REQUEST",
  SURVEY_QUESTIONS_TRANSLATE_SUCCESS: "SURVEY_QUESTIONS_TRANSLATE_SUCCESS",
  SURVEY_QUESTIONS_TRANSLATE_FAILURE: "SURVEY_QUESTIONS_TRANSLATE_FAILURE",

  // Survey questions Translate
  SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_REQUEST: "SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_REQUEST",
  SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_SUCCESS: "SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_SUCCESS",
  SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_FAILURE: "SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_FAILURE",

  // Update Translated Edit history
  EDIT_TRANSLATE_HISTORY_UPDATE_SUCCESS: "EDIT_TRANSLATE_HISTORY_UPDATE_SUCCESS",

  // Update survey name
  SURVEY_NAME_REDUCER_UPDATE: "SURVEY_NAME_REDUCER_UPDATE",

  // Survey Errors
  SURVEY_ERRORS_UPDATE: "SURVEY_ERRORS_UPDATE",

  //Action Coach Items Array Update
  SURVEY_ACTION_DATA_UPDATE: "SURVEY_ACTION_DATA_UPDATE",

  //Action Coach Items Array Update
  SURVEY_ACTION_COACH_LIST_UPDATE: "SURVEY_ACTION_COACH_LIST_UPDATE",

  UPDATE_SURVEY_NAME: "UPDATE_SURVEY_NAME",

  UPDATE_SURVEY_AUTOMATIC_TRIGGERS: "UPDATE_SURVEY_AUTOMATIC_TRIGGERS",
};
