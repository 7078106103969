import { alertConstant, metadataConstant, uiConstant } from "../constants";
import { metadataService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const metadataAction = {
  getDemographicsName,
  updateMetadata,
  getMetadataWithRGI,
  getClientMetadata,
  selectedMetadata
};

/**
 * GET CLIENT DEMOGRAPHICS NAME
 */
function getDemographicsName() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(metadataConstant.GET_METADATA_NAME_REQUEST));
    metadataService.getDemographicsName().then(
      (data) => {
        dispatch(success(metadataConstant.GET_METADATA_NAME_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(metadataConstant.GET_METADATA_NAME_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET CLIENT DEMOGRAPHICS WITH RGI
 */
function getMetadataWithRGI(successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(metadataConstant.GET_METADATA_RGI_REQUEST));
    metadataService.getMetadataWithRGI().then(
      (data) => {
        dispatch(success(metadataConstant.GET_METADATA_RGI_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(metadataConstant.GET_METADATA_RGI_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET CLIENT METADATA
 */
function getClientMetadata(successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(metadataConstant.GET_METADATA_REQUEST));
    metadataService.getClientMetadata().then(
      (data) => {
        dispatch(success(metadataConstant.GET_METADATA_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(metadataConstant.GET_METADATA_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}
/**
 * Update METADATA
 */
function updateMetadata(updateData, isMsg = true, successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(metadataConstant.METADATA_UPDATE_REQUEST));
    return metadataService.updateMetadata(updateData).then(
      (data) => {
          dispatch(success(metadataConstant.METADATA_UPDATE_SUCCESS));
          isMsg && dispatch(success(alertConstant.ALERT_SUCCESS, "Saved Sucessfully"));
          dispatch(request(uiConstant.LOADING_FALSE));
          successCallback()
      },
      (error) => {
        dispatch(failure(metadataConstant.METADATA_UPDATE_FAILURE));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

function selectedMetadata(data={}){
  return {
    type: metadataConstant.UPDATE_SINGLE_METADATA,
    metadataSelected:data
  }
}