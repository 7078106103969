const MeSurveyStyles = (theme) => ({
    rootHeader: {
        flexGrow: 1,
    },
    headerAppBar: {
        height: 56,
    },
    takeSurvey_container: {
        width: "100%",
        height: "100%",
        background: "#fff",
        borderRadius: "6px",
        padding: "15px",
    },
    takeSurvey_welcome_page_root: {
        background: "#e6eff4",
        "& .orderedList":{
            whiteSpace: "normal",
        }
    },
});

export default MeSurveyStyles;
