import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Badge from "@mui/material/Badge";
import TextEditor from "../../../../components/TextEditor";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";

import { updateStateReducerAction, contentLibraryAction,reportContent} from "../../../../redux/actions";
import { contentLibraryConstant } from "../../../../redux/constants";

import { getReducerUpdatedData } from "../../../../helpers";


function AddReportContent(props) {

    const { classes, item, reportContent, index, addReportContent } = props;
    const dispatch = useDispatch();

    let reportContentNewData = {}
    if (reportContent  && item) {
        reportContentNewData = item
    } else if (addReportContent["description"] === undefined) {
        reportContentNewData["description"] = "";
        reportContentNewData["body"] = "";
    } else {
        reportContentNewData = addReportContent
    }

    const saveNewReportContent = () => {
       // let duplicateFound = false;
        let emptyreportContent = false
        reportContent.filter((actions) => {
            if (reportContentNewData.description.trim() === "") {
                emptyreportContent = true
            }
            //  else if (!item && reportContentNewData.description.toLowerCase() === actions.description.toLowerCase()) {
            //     duplicateFound = true
            // } else if (reportContentNewData._id !== actions._id && reportContentNewData.description.toLowerCase() === actions.description.toLowerCase()) {
            //     duplicateFound = true
            // }
        })
        if (!emptyreportContent) {
            if (!item) { 
                // reportContent.push(reportContentNewData);
                dispatch(contentLibraryAction.updateReport(reportContentNewData));
                dispatch(contentLibraryAction.addOrChangeReportContent({}));
                props.updateAddActionFlag(false);
            } else {
                // on save close edit
                props.updateAddReportContentFlag(true);
            }
        } 
        // else if (duplicateFound) {
        //     dispatch(reportContent.error("duplicate report content are not allowed"));
        // }
         else {
            dispatch(reportContent.error("Empty report content description is not allowed"));
        }
    };

    const closeReport = () => {
        if (!item) {
            dispatch(contentLibraryAction.addOrChangeReportContent({}));
            props.updateAddActionFlag(false);
        }
         else {
            // set / remove editable false for that report content
            reportContent.filter((reports, ind) => {
                if (reports.description === item.description) {
                    reportContent[ind].editable = false;
                }
                return true
            });
            props.updateAddReportContentFlag(false)
        }
    };

    const handleChange = (event) => {
        let reducerData = getReducerUpdatedData(event, reportContentNewData);
        if (!item) {
            dispatch(contentLibraryAction.addOrChangeReportContent(reducerData));
        } else {
            props.editReportContentItem(reducerData)
        }
    }

    // set badge value for add report content
    let badgeValue = 1;
    if (item) {
        badgeValue = index + 1
    } else {
        badgeValue = reportContent.length + 1;
    }



    return (
        <Grid className={` ${classes.TopicExpand} width100`}>
            <Grid container alignItems="stretch" className="p15">
                    <Grid item className={"pl-1 pr-2 pt-2"}>
                        <Badge
                            color="secondary"
                            className={`${classes.Count_badge} badgeextrabig`}
                            badgeContent={badgeValue}
                        ></Badge>
                    </Grid>
                    <Grid item className={"pr-1 pl-1    "} style={{ flexGrow: "7" }}>
                    <Grid item sm={12} className="mb-1">
                        <TextField
                            id={"actionItems"}
                            name={"description"}
                            className={`${classes.ContentLibMultiLineText} ${" fieldset width100"}`}
                            type={"text"}
                            disabled
                            // placeholder={"About this report"}
                            value={reportContentNewData ? reportContentNewData.description : ""}
                            onChange={(e) => { handleChange({ ...e, "name": "description", "value": e.target.value }) }}
                            variant="outlined"
                        />
                        </Grid>
                        <Grid item sm={12} className="mb-1 pt5">
                            <TextEditor
                                height={"200px"}
                                data={reportContentNewData ? reportContentNewData.body : ""}
                                onChange={(event, editor) => { handleChange({ ...event, "name": "body", "value": editor.getData() }) }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justifyContent={"flex-end"}>
                                <Grid item>
                                    <ButtonComponent
                                        title={"OK"}
                                        color={"primary"}
                                        size={"small"}
                                        handleClick={() => saveNewReportContent()}
                                        pageClassName={classes.settings_addrulebtn}
                                    ></ButtonComponent>
                                    <ButtonComponent
                                        title={"Cancel"}
                                        color={"secondary"}
                                        size={"small"}
                                        handleClick={() => closeReport()}
                                        pageClassName="ml-1"
                                    ></ButtonComponent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </Grid>
        </Grid>
    )
}

export default AddReportContent;

