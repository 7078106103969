import React from "react";
import Tooltip from "@mui/material/Tooltip";
import RadioCheckButton from "../../../../../components/RadioCheckButton/RadioCheckButton";
import _ from "lodash";

// Select All Radio Button
const RadioButton = ({ selectedRows, data, handleChange, componentProps }) => {
    const selValue = data === 'header' ? 'all' : `${data.fromTo}`
    const { editDisabled } = componentProps
    const title = data.hasResponse ? "Response already taken" : ""
    return (
        <div style={{width:"35px"}}>
        < Tooltip  arrow title={selValue === "all" ? "Select All" : "" || data.hasResponse ? "Response already taken" : ""}>
            <div> 
                <RadioCheckButton
                    checked={selectedRows.indexOf(selValue) !== -1}
                    onChange={(e) => {
                        handleChange('selected', data, e.target.checked)
                    }}
                    labelClassName="me-select-box"
                    disabled={editDisabled || data.hasResponse}
                />
            </div>
            </Tooltip>
            </div>
        
    )
}

export const getUpdatedParticipants = ({ value, selData, selectedParticipants,
    response_ids, count, all_ids }) => {

    if (count === 0) return []

    const selValue = selData === 'header' ? 'all' : `${selData.subject_id}_to_${selData.empId}`
    let updatedData = [...selectedParticipants]
    if (selData === 'header') {
        if (value) {
            updatedData = _.uniq([...selectedParticipants, ...all_ids])
            if (updatedData.indexOf(selValue) === -1) {
                updatedData.push(selValue)
            }
        } else {
            updatedData = [...selectedParticipants].filter(d => all_ids.indexOf(d) === -1)
            updatedData = [...updatedData, ...response_ids]
        }
    } else if (value) {
        updatedData.push(selValue)
    } else {
        updatedData = updatedData.filter(f => f !== selValue)
    }

    // if other than select all op
    if (_.difference(all_ids, updatedData).length > 0) {
        updatedData = updatedData.filter(f => f !== "all")
    } else if (updatedData.indexOf("all") === -1) {
        updatedData.push("all")
    }

    return updatedData
}

export default RadioButton