/* eslint-disable camelcase */
import { contentLibraryConstant } from "../constants";
import Profile_IMG from "../../assets/images/default_profile.svg";
// Set Initial State
const initialState = {
  actionAlert: [],
  addActionAlert: {},
  reportContent: [],
  addReportContent: {},
  message: [
    {
      type: "Engagement",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      }, kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    },
    {
      type: "Exit",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      }, kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    },
    {
      type: "Onboarding (Week 2)",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      }, kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    },
    {
      type: "Onboarding (Week 5)",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      }, kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    },
    {
      type: "New Hire",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      }, kiosk: {
        body: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    }, {
      type: "Diversity & inclusion",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      }, kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    }, {
      type: "Manager Effectiveness",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      }, kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    }, {
      type: "Stay",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      }, kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    }, {
      type: "Well-being",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      },
      kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    }, {
      type: "Other",
      email: {
        body: "",
        greetings: "",
        subject: "",
        url: ""
      },
      sms: {
        body: "",
        greetings: "",
        url: ""
      },
      transflo: {
        body: "",
        greetings: "",
        subject: "",
      },
      kiosk: {
        body: ""
      },
      thankyou_page: {
        thanks_description: "",
        thanks_header: "",
        subject: "",
        url: ""
      },
      Welcome_page: {
        Welcome_description: "",
        Welcome_header: ""
      }
    },

  ],
  baseQuestionIds: [],
  recommendation_search: {
    key: "",
    allQuestions: [],
    questions: [],
  },
};

export default function (state = initialState, action) {
  switch (action.type) {

    // GET ONE CONTENT LIBRARY DETAILS
    case contentLibraryConstant.CONTENTLIBRARY_GETONE_REQUEST:
      return {
        ...state,
        actionAlert: initialState.actionAlert
      };
    case contentLibraryConstant.CONTENTLIBRARY_GETONE_SUCCESS:
      return {
        ...state,
        actionAlert: action.data.data.actionAlert

      };
    case contentLibraryConstant.CONTENTLIBRARY_GETONE_FAILURE:
      return {
        ...state,
        actionAlert: initialState.actionAlert
      };

    // Remove Action Alert data
    case contentLibraryConstant.CONTENTLIBRARY_REMOVE_REQUEST:
      return {
        ...state,
      };
    case contentLibraryConstant.CONTENTLIBRARY_REMOVE_FAILURE:
      return {
        ...state,
      };



    // Search values for action Alert
    case contentLibraryConstant.CONTENTLIBRARY_SEARCH_REQUEST:
      return {
        ...state,
      };
    case contentLibraryConstant.CONTENTLIBRARY_SEARCH_SUCCESS:
      return {
        ...state,
        actionAlert: action.data.data

      };
    case contentLibraryConstant.CONTENTLIBRARY_SEARCH_FAILURE:
      return {
        ...state,
      };

    // GET ONE REPORT CONTENT LIBRARY DETAILS
    case contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_REQUEST:
      return {
        ...state,
        reportContent: initialState.reportContent
      };
    case contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_SUCCESS:
      return {
        ...state,
        reportContent: action.data.data.reportContent

      };
    case contentLibraryConstant.ONTENTLIBRARY_REPORTCONTENT_GETONE_FAILURE:
      return {
        ...state,
        reportContent: initialState.reportContent
      };

    // Search values for report Content
    case contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_SEARCH_REQUEST:
      return {
        ...state,
      };
    case contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_SEARCH_SUCCESS:
      return {
        ...state,
        reportContent: action.data.data
      };
    case contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_SEARCH_FAILURE:
      return {
        ...state,
      }


    // Content library reducer update
    case contentLibraryConstant.CONTENTLIBRARY_ACTIONALERT_REDUCER_UPDATE:
      return {
        ...state,
        actionAlert: action.data
      };
    case contentLibraryConstant.CONTENTLIBRARY_ONADD_ACTIONALERT:
      return {
        ...state,
        addActionAlert: action.data.actionAlertNewData
      };

    // Content library reducer update
    case contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_REDUCER_UPDATE:
      return {
        ...state,
        reportContent: action.data
      };
    case contentLibraryConstant.CONTENTLIBRARY_ONADD_REPORTCONTENT:
      return {
        ...state,
        addReportContent: action.data.reportContentNewData
      };
    default:
      return state;


    // GET ONE CONTENT LIBRARY Message DETAILS
    case contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_REQUEST:
      return {
        ...state
      };
    case contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_SUCCESS:
      return {
        ...state,
        message: action.data.data.message

      };
    case contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_FAILURE:
      return {
        ...state
      };

    // Update CONTENT LIBRARY Message data
    case contentLibraryConstant.CONTENTLIBRARY_MESSAGE_UPDATE_REQUEST:
      return {
        ...state
      };
    case contentLibraryConstant.CONTENTLIBRARY_MESSAGE_UPDATE_FAILURE:
      return {
        ...state
      };

    // GET All Basequestion ID's for Recommendation
    case contentLibraryConstant.CONTENTLIBRARY_GETBASEQUSID_REQUEST:
      return {
        ...state,
      };
    case contentLibraryConstant.CONTENTLIBRARY_GETBASEQUSID_SUCCESS:
      return {
        ...state,
        baseQuestionIds: action.data.data

      };
    case contentLibraryConstant.CONTENTLIBRARY_GETBASEQUSID_FAILURE:
      return {
        ...state,
      };

    // GET All questions for Recommendation
    case contentLibraryConstant.CONTENTLIBRARY_GETALLQUESTIONS_REQUEST:
      return {
        ...state,
      };
    case contentLibraryConstant.CONTENTLIBRARY_GETALLQUESTIONS_SUCCESS:
      return {
        ...state,
        recommendation_search: {
          ...state.recommendation_search,
          allQuestions: action.data.data
        }
      };
    case contentLibraryConstant.CONTENTLIBRARY_GETALLQUESTIONS_FAILURE:
      return {
        ...state,
      };

    // Search Question with basequestion id for Recommendations
    case contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_REQUEST:
      return {
        ...state,
      };
    case contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_SUCCESS:
      return {
        ...state,
        recommendation_search: {
          ...state.recommendation_search,
          questions: action.data.data
        }
      };
    case contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_FAILURE:
      return {
        ...state,
      };

    // Search values for Recommendations
    case contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_REQUEST:
      return {
        ...state,
      };
    case contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_SUCCESS:
      return {
        ...state,
        recommendation_search: {
          ...state.recommendation_search,
          questions: action.data.data
        }
      };
    case contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_FAILURE:
      return {
        ...state,
      };

    //Update reducer data
    case contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATION_QUESTIONS_REDUCER_UPDATE:
      return {
        ...state,
        recommendation_search: {
          ...state.recommendation_search,
          questions: action.data
        }
      };

  }
}
