import * as apihelper from "../../helpers/apiHelper";
import { removeToken, setToken } from "../../helpers/appHelper";

/**
 * Export All Authentication Service Functions
 */
export const authService = {
  login,
  logout,
  samlLogin,
  forgotPassword,
  resetPassword,
  resetPasswordToken
};

/**
 * Call Login Service
 * @param username
 * @param password
 */
async function login (email, password) {
  const response = await apihelper.postRequest("login", { email, password });
  setToken(response.data);
  return response;
}

/**
 * Call Logout Service
 */
async function logout (isTokenAvailable) {
  const response = await apihelper.postRequest("logout", {'isTokenAvailable': isTokenAvailable});
  if (response?.['message'] !== 'Valid Token') {
    removeToken();
  }
  return response;
}

/**
 * Saml Login Service
 */
async function samlLogin (samlToken) {
  const response = await apihelper.postRequest("saml/login", samlToken);
  setToken(response.data);
  return response;
}

/**
 * Call Forgot Password Service
 * @param username
 * @param password
 */
async function forgotPassword (email) {
  const response = await apihelper.postRequest("forgotpassword", { email });
  return response;
}

/**
 * Reset Passord
 * @param {*} params 
 */
async function resetPassword (params) {
  const response = await apihelper.postRequest("resetpassword", params);
  return response;
}

/**
 * Reset Passord Token
 * @param {*} params 
 */
async function resetPasswordToken (params) {
  const response = await apihelper.postRequest("reset/password/token", params);
  return response;
}