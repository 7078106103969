import PropTypes from "prop-types";
import { useState, useEffect } from "react";

import moment from 'moment';
import * as d3 from "d3";
import { Grid } from "@mui/material";

// Import Component
import TypographyComponent from "../../TypographyComponent/TypographyComponent";

// Import Styles for chart
import "./TurnoverChart.css";

const TurnoverChart = (props) => {
    // Props
    const { data, chartWidth } = props;

    const [groupedSurveys, setGroupedSurveys] = useState({});

    const fillColor = (dataKey, dataValue) => {
        const orangeColor = '#ffb457';
        const blueColor = '#54c0fd';

        if (dataKey === 'type') {
            return dataValue === 'Exit' ? orangeColor : blueColor;
        } else {
            const dFinder = data.filter(d => d[dataKey] === dataValue);
            return dFinder[0]?.type === 'Exit' ? orangeColor : blueColor;
        }
    };

    function LineChart(data, {
        x = ([x]) => x, // given d in data, returns the (temporal) x-value
        y = ([, y]) => y, // given d in data, returns the (quantitative) y-value
        z = () => 1, // given d in data, returns the (categorical) z-value
        title, // given d in data, returns the title text
        defined, // for gaps in data
        curve = d3.curveMonotoneX, // method of interpolation between points
        marginTop = 24, // top margin, in pixels
        marginRight = 24, // right margin, in pixels
        marginBottom = 24, // bottom margin, in pixels
        marginLeft = 24, // left margin, in pixels
        width, // outer width, in pixels
        height = 180, // outer height, in pixels
        xType = d3.scaleUtc, // type of x-scale
        xDomain, // [xmin, xmax]
        xRange = [marginLeft, width - marginRight], // [left, right]
        yType = d3.scaleLinear, // type of y-scale
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat, // a format specifier string for the y-axis
        yLabel, // a label for the y-axis
        zDomain, // array of z-values
        color = "currentColor", // stroke color of line, as a constant or a function of *z*
        strokeLinecap, // stroke line cap of line
        strokeLinejoin, // stroke line join of line
        strokeWidth = 1.5, // stroke width of line
        strokeOpacity, // stroke opacity of line
        mixBlendMode = "inherit", // blend mode of lines
    } = {}) {
        const turnOverChart = d3.select(`#turnOverChart`);

        // Removing Exisiting Chart
        turnOverChart.selectAll("*").remove();

        // Compute values.
        const X = d3.map(data, x);
        const Y = d3.map(data, y);
        const Z = d3.map(data, z);
        const O = d3.map(data, d => d);

        // Compute default domains, and unique the z-domain.
        xDomain = [new Date(Math.min(...X)) - 86400000, new Date(Math.max(...X) + 86400000)] //added 1 date in ms
        yDomain = [0, Math.max(...Y)]
        defined = (d, i) => d.count;
        const D = d3.map(data, defined);

        if (xDomain === undefined) xDomain = d3.extent(X);
        if (yDomain === undefined) yDomain = [0, d3.max(Y, d => typeof d === "string" ? +d : d)];
        if (zDomain === undefined) zDomain = Z;
        zDomain = new d3.InternSet(zDomain);

        const countByMonth = X.reduce((acc, date) => {
            const month = new Date(date).getMonth();
            if (acc[month]) {
                acc[month]++;
            } else {
                acc[month] = 1;
            }
            return acc;
        }, {});

        const countByMonthAxis = Object.keys(countByMonth).length;
        // Omit any data not present in the z-domain.
        const I = d3.range(X.length).filter(i => zDomain.has(Z[i]));

        // Construct scales and axes.
        const xScale = xType(xDomain, xRange);
        const yScale = yType(yDomain, yRange);
        const xAxis = d3.axisBottom(xScale).tickValues(X).tickFormat(function (date, index) {
            date = new Date(moment(date).add(1, 'days'))
            return d3.timeFormat("%b'%y")(date)
        }).tickSize(0);
        const yAxis = d3.axisLeft(yScale).ticks(height / 60, yFormat);

        // Construct a line generator.
        const line = d3.line()
            .defined(i => D[i])
            .curve(curve)
            .x(i => xScale(X[i]))
            .y(i => yScale(Y[i]))

        const area = d3.area()
            .defined(i => D[i])
            .curve(curve)
            .x(i => xScale(X[i]))
            .y0(height - marginBottom)
            .y1(i => yScale(Y[i]))

        const svg = turnOverChart.append("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto; height: intrinsic;")
            .style("-webkit-tap-highlight-color", "transparent")
            .on("pointerenter", pointerentered)
            .on("pointermove", pointermoved)
            .on("pointerleave", pointerleft)
            .on("touchstart", event => event.preventDefault());

        svg.append("g")
            .attr("transform", `translate(0,${height - marginBottom})`)
            .call(xAxis)
            .attr("class", "axis");

        svg.append("g")
            .attr("transform", `translate(${marginLeft},0)`)
            // .call(yAxis) /* Hide yAxis */
            .call(g => g.select(".domain").remove())
            .call(g => g.append("text")
                .attr("x", -marginLeft)
                .attr("y", 10)
                .attr("fill", "#b3b3b3")
                .attr("text-anchor", "start")
                .text(yLabel));

        const path = svg.append("g")
            .attr("stroke-linecap", strokeLinecap)
            .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-opacity", strokeOpacity)
            .attr("class", "grad")
            .selectAll("path")
            .data(d3.group(I, i => Z[i]))
            .join("path")
            .style("mix-blend-mode", mixBlendMode)
            .attr("fill", ([key, value]) => { return `url(#gradient${key})` })
            .attr("fill-opacity", 0.35)
            .attr("d", ([, I]) => area(I));

        svg.append("g")
            .attr("stroke-linecap", strokeLinecap)
            .attr("stroke-linejoin", strokeLinejoin)
            .attr("stroke-width", strokeWidth)
            .attr("stroke-opacity", strokeOpacity)
            .attr("class", "grad")
            .selectAll("path")
            .data(d3.group(I, i => Z[i]))
            .join("path")
            .attr("fill", "none")
            .attr("stroke", ([z]) => { return fillColor('_id', z) })
            .attr("d", ([, I]) => line(I))

        const gradient = svg.selectAll(".grad").append("defs")
            .data(d3.group(I, i => Z[i]))
            .append("linearGradient")
            .attr("id", ([key, value]) => { return `gradient${key}` })
            .attr("gradientTransform", "rotate(90)");

        gradient.append("stop")
            .data(d3.group(I, i => Z[i]))
            .attr("offset", "0")
            .attr("stop-color", ([z]) => { return fillColor('_id', z) });

        gradient.append("stop")
            .data(d3.group(I, i => Z[i]))
            .attr("offset", "1")
            .attr("stop-opacity", "0.25")
            .attr("stop-color", ([z]) => { return fillColor('_id', z) });

        // Append Circles
        svg.selectAll("myCircles")
            .data(data, function (d) { return d })
            .enter()
            .append("circle")
            .attr("fill", "#fff")
            .attr("class", "pathCircles")
            .attr("stroke-width", 2)
            .attr("stroke", (d) => { return fillColor('type', d.type) })
            .attr("cx", (d) => { return xScale(x(d)); })
            .attr("cy", (d) => { return yScale(y(d)); })
            .attr("r", 4)

        d3.selectAll(".tick text")
            .attr("y", "10");

        // Tooltip  
        var toolTip = turnOverChart.append("div")
            .style("opacity", 0)
            .attr("class", "tool-tip")

        let tooltipEmptyCheck = false;

        function pointermoved(event) {
            const tooltipElem = document.querySelector('#turnOverChart .tool-tip');
            const tootTipBoxHeight = tooltipElem.offsetHeight;
            const tootTipBoxWidth = tooltipElem.offsetWidth;
            const [xm, ym] = d3.pointer(event);
            const i = d3.least(I, i => Math.hypot(xScale(X[i]) - xm, yScale(Y[i]) - ym)); // closest point

            d3.selectAll('.pathCircles').attr("opacity", 1);
            d3.selectAll('.pathCircles').filter(d => d._id !== Z[i]).attr("opacity", 0);
            let totalcountBySurvey = 0;
            data.map(d => {
                if (Z[i] === d['_id']) {
                    totalcountBySurvey += parseInt(d['count']);
                }
            });

            let toolTipLeft = xScale(X[i]) - (tootTipBoxWidth / 2.5);
            let toolTipTop = yScale(Y[i]) - (tootTipBoxHeight) - 12;

            if (toolTipLeft < 0) {
                toolTipLeft = marginLeft;
            } else if ((toolTipLeft + (tootTipBoxWidth / 2) + marginRight) > (width - marginLeft - marginRight)) {
                toolTipLeft = width - tootTipBoxWidth;
            }
            tooltipEmptyCheck = true;

            path.style("stroke", ([z]) => Z[i] === z ? null : "#ddd").filter(([z]) => Z[i] === z).raise().style("fill-opacity", 0.75);
            toolTip
                .html(`${new Date(X[i]).toLocaleString('en-US', { month: 'long' })}: <b>${Y[i]}</b>`)
                .attr("style", `left: ${toolTipLeft}px; top: ${toolTipTop}px`)
            svg.property("value", O[i]).dispatch("input", { bubbles: true });
        }

        function pointerentered() {
            path.style("mix-blend-mode", null).style("stroke", "#ddd").style("z-index", 1);
            if (tooltipEmptyCheck) {
                toolTip.style("opacity", 1)
            }
            d3.selectAll('.pathCircles').attr("opacity", 1);
        }

        function pointerleft() {
            path.style("mix-blend-mode", mixBlendMode).style("stroke", null).style("fill-opacity", 0.35);
            toolTip.style("opacity", 0)
            svg.node().value = null;
            d3.selectAll('.pathCircles').attr("opacity", 1);
            svg.dispatch("input", { bubbles: true });
        }
    }

    const groupSurveys = (surveys) => {
        let groupData = {
            Exit: [],
            Others: [],
            OtherNames: ''
        };

        surveys.map(survey => {
            if (survey.type === 'Exit') {
                groupData['Exit'].push(survey);
            } else {
                const type = survey.type === 'New Hire' ? 'New hires projected to leave' : survey.type;

                groupData['Others'].push(survey);
                groupData['OtherNames'] +=
                    groupData['OtherNames'] === '' ?
                        type
                        : groupData['OtherNames'].indexOf(type) === -1 ?
                            `, ${type}`
                            : '';
            }
        })

        setGroupedSurveys(groupData);
    };

    useEffect(() => {
        if (data.length > 0) {
            groupSurveys(data);
            LineChart(data, {
                x: d => moment(new Date(d.date), "MM-YYYY"),
                y: d => d.count,
                z: d => d._id,
                width: chartWidth,
            });
        }
    }, [data, chartWidth]);

    const populateLegends = () => {
        return Object.entries(groupedSurveys).map(([key, val]) => {
            if (val.length > 0 && (key === 'Exit' || key === 'Others')) {
                const name = key === 'Exit' ? "Exits" : groupedSurveys['OtherNames'];
                const total = val.reduce((a, b) => { return a + parseInt(b['count']) }, 0);
                const avg = (total / val.length).toFixed()

                return (
                    <Grid item>
                        <div className={`chart-legend-item ${key.toLowerCase()}`}>
                            <TypographyComponent variant="content" color="#b3b3b3" mb={2}>
                                {name}
                            </TypographyComponent>
                            <Grid container spacing={3} alignItems={'flex-end'}>
                                <Grid item>
                                    <Grid container spacing={1} alignItems={'flex-end'} flexWrap={'nowrap'}>
                                        <Grid item>
                                            <TypographyComponent variant="h5" className="txtBold fontSize30">{avg}</TypographyComponent>
                                        </Grid>
                                        <Grid item style={{ marginBottom: '2px' }}>
                                            <TypographyComponent variant="content" color="#b3b3b3">{'Month avg'}</TypographyComponent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1} alignItems={'flex-end'} flexWrap={'nowrap'}>
                                        <Grid item>
                                            <TypographyComponent variant="h5" className="txtBold fontSize30">{total}</TypographyComponent>
                                        </Grid>
                                        <Grid item style={{ marginBottom: '2px' }}>
                                            <TypographyComponent variant="content" color="#b3b3b3">{'Total'}</TypographyComponent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                )
            }
        })
    };

    if (data.length > 0) {
        return (
            <Grid container>
                <Grid style={{ position: "relative" }}>
                    <div id="turnOverChart"></div>
                </Grid>
                <Grid container justifyContent={'center'} spacing={6}>
                    {populateLegends()}
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid container direction="column" justifyContent={'center'} alignItems={'center'} style={{ height: '100%' }}>
                <TypographyComponent variant="h4" className="txtBold fontSize16">
                    {'No Data Found'}
                </TypographyComponent>
            </Grid>
        )
    }
}

// default props
TurnoverChart.defaultProps = {
    classes: {},
    data: [],
    chartWidth: 0,
};
// prop types
TurnoverChart.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.any,
    chartWidth: PropTypes.number,
};

export default TurnoverChart;