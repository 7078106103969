import { fileManagerConstants } from "../constants";

// Set Initial State
export const initialState = {
    data: [],
    page: {
        fieldFilters: {
            headerCols: [],
            fieldCols: [],
            searchCols: [],
            columnSettings: [],
            visibleColumns: [],
            leftDefaultCols: []
        }
    },
    isLoading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        // UPLOAD ECEL REDUCER
        case fileManagerConstants.GET_FILES_LIST_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case fileManagerConstants.GET_FILES_LIST_SUCCESS:
            return {
                ...state,
                ...action.data,
                isLoading: false
            };
        case fileManagerConstants.GET_FILES_LIST_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        default:
            return state;
    }
}
