import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import withStyles from '@mui/styles/withStyles';
import { StickyTable, Row, Cell } from "./ReactStickyTable";
import { TableSortLabel } from '@mui/material';
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";

import _ from "lodash";

import { keyDriver, Filter } from "../../../../assets/images/svgComponents/ImageComponent"

import InformationComponent from "../../../../components/InformationComponent/InformationComponent"
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent"
import MultiSelectChecboxWithSearch from "../../../../components/MultiSelectComponent/MultiSelectCheckboxWithSearch";

// Import Styles
import HotspotTableStyle from "./HotspotTableStyle";

// Import Helpers
import { addEllipsis, generateKey } from "../../../../helpers";

const HotspotStickyTable = (props) => {
  //Get props
  const {
    classes,
    selectedType,
    updateSelectedType,
    header,
    staticRow,
    staticHeaders,
    rowOrder,
    colorRange,
    sortingData,
    leftAlignCells,
    handleSorting,
    zoom,
    keydriver,
    tableData,
    applyFilters,
    filtersApplied,
  } = props;

  const tableWidth = window.innerWidth - 125;
  const tableHeight = window.innerHeight - 125;
  const rowWidth = (tableWidth / header.length) <= 50 ? 50 : (tableWidth / header.length)

  //Background color for each row based in the color percentile data
  const getBgColor = (row, item) => {
    if (["_id", "catName", "Overall"].indexOf(row) === -1) {
      let cellColor = "#ffff";
      colorRange.map((obj) => {
        if (item >= 0 && (parseInt(item) >= obj["gt"] && parseInt(item) <= obj["lt"])) {
          cellColor = obj["value"];
        }
      })
      return cellColor;
    } else {
      return "";
    }
  }

  const getBorder = (row, item) => {
    if (["_id", "catName", "Overall"].indexOf(row) === -1) {
      let cellBorder = "#ffff";
      colorRange.map((obj) => {
        if (item) {
          cellBorder = "2px solid white";
        }
      })
      return cellBorder;
    } else {
      return "";
    }
  }

  const [viewByAnchor, setViewByAnchor] = useState(null);
  const [viewByCategoryAnchor, setViewByCategoryAnchor] = useState(null);

  const selectedTypeFilters = filtersApplied?.[selectedType];

  const getInitialFilteredData = ((data, type) => {
    // Filter is used for only question and category
    const typeFieldValue = ['question', 'keyDriver'].indexOf(type) !== -1 || ['question', 'keyDriver'].indexOf(selectedType) === -1 ? '_id' : 'catName';

    if (!data || !_.isArray(data) || data.length === 0) {
      return {}
    }

    return _.orderBy(data, [typeFieldValue]).reduce((acc, curr, index) => {
      if (curr?.[typeFieldValue] && Object.values(acc || {}).indexOf(curr?.[typeFieldValue]) === -1) {
        return {...acc, [generateKey(curr?.[typeFieldValue])]: curr?.[typeFieldValue]}
      } else {
        return acc;
      }
    }, {});
  });

  const getSelectedFilterData = ((data, type) => {
    // Filter is used for only question and category
    const questionKeyDriverCondition = ['question', 'keyDriver'].indexOf(type) !== -1;
    const selectedCategoryCondition = selectedType === 'category';

    const typeFieldValue = selectedCategoryCondition ? '_id' : questionKeyDriverCondition ? '_id' : 'catName';
    const typeFieldValueAlternative = selectedCategoryCondition ? '_id' : questionKeyDriverCondition ? 'catName' : '_id';

    const currentType = selectedCategoryCondition ? 'category' : questionKeyDriverCondition ? selectedType : 'category';
    const currentTypeAlternative = selectedCategoryCondition ? 'category' : questionKeyDriverCondition ? 'category' : selectedType;

    const reduxDataCondition = Boolean(filtersApplied?.[type] && _.isArray(filtersApplied?.[type]) && filtersApplied?.[type].length > 0);

    if (!reduxDataCondition || (!data || !_.isArray(data) || data.length === 0)) {
      return [];
    }

    const filterSorted = _(data).filter(f => {
      return (filtersApplied?.[currentTypeAlternative] || []).indexOf(f?.[typeFieldValueAlternative]) !== -1 || (filtersApplied?.[currentType] || []).indexOf(f?.[typeFieldValue]) !== -1
    }).orderBy(typeFieldValue).value();

    return filterSorted.reduce((acc, curr, index) => {
      const currentKey = generateKey(curr?.[typeFieldValue]);

      if ((acc || []).indexOf(currentKey) === -1) {
        acc = [...acc, currentKey];
      }
  
      return acc;
    }, []);
  });

  const applyFiltersBtn = (triggerType, data) => {
    const filterDataBasedOnType = getInitialFilteredData(tableData, triggerType);
    const isAllSelected = _.isEqual(_.orderBy(Object.keys(filterDataBasedOnType || {})), _.orderBy((data || [])));
    let reduxData = {};

    if (!isAllSelected) {
      const triggerTypeData = (data || []).reduce((acc, curr, index) => {
        if (filterDataBasedOnType?.[curr]) {
          acc = [...acc, filterDataBasedOnType?.[curr]];
        }

        return acc;
      }, []);

      reduxData = ['question', 'keyDriver'].indexOf(selectedType) !== -1 ?
      {
        [triggerType]: triggerTypeData
      } : {
        [selectedType]: triggerTypeData
      };
    }

    applyFilters(
      () => {
        ['question', 'keyDriver'].indexOf(triggerType) !== -1 || ['question', 'keyDriver'].indexOf(selectedType) === -1 ?
          setViewByAnchor(null) :
          setViewByCategoryAnchor(null)
      },
      'hotspost',
      reduxData
    )
  }

  return (
    <React.Fragment>
      {header && header.length > 0 && (
        <div style={{ width: "100%", height: `${zoom ? tableHeight : 600}px` }}>
          <StickyTable
            stickyHeaderCount={1}
            leftStickyColumnCount={staticHeaders}
          >
            <Row className={classes.tableheadercolor}>
              {header.map((item, inx) => (
                <React.Fragment>
                  {item === "text" ? (
                    <Cell style={{ minWidth: `${(tableWidth) / 4}px` }} >
                      {/* view by question - Filter Icon with tooltip */}
                      <Tooltip arrow title={`${filtersApplied?.[selectedType] ? 'Filters Applied' : 'Apply Filters'}`}>
                        <IconButton
                          className={`icon-hover ${filtersApplied?.[selectedType] ? 'active' : ''}`}
                          aria-describedby={'hotspotViewByPopover'}
                          onClick={(e) => setViewByAnchor(e.currentTarget)}
                        >
                          {Filter()}
                        </IconButton>
                      </Tooltip>
                      {/* view by question - Filter Icon popover */}
                      <Popover
                        id={'hotspotViewByPopover'}
                        open={Boolean(viewByAnchor)}
                        anchorEl={viewByAnchor}
                        onClose={() => {setViewByAnchor(null)}}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <MultiSelectChecboxWithSearch
                          checkboxData={getInitialFilteredData(tableData, 'question')}
                          defaultSelectAll={filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 ? false : true}
                          handleApply={applyFiltersBtn}
                          handleCancel={() => {setViewByAnchor(null)}}
                          selectedCheckboxDatas={getSelectedFilterData(tableData, selectedType)}
                          triggerType={selectedType}
                        />
                      </Popover>
                      <Select
                        className={`${classes.chartHeadings} ${classes.hotspotViewbySelect} ${"txtBold fontMontserrat disableBorder"}`}
                        value={keydriver.length > 0 ? selectedType : (selectedType === "keyDriver" ? "question" : selectedType)}
                        disableUnderline={true}
                        onChange={(e) => updateSelectedType(e.target.value)}
                      >
                        <MenuItem value={"category"}>
                          {"View By Category"}
                        </MenuItem>
                        <MenuItem value={"question"}>
                          {"View By Question"}
                        </MenuItem>
                        {keydriver && keydriver.length && (
                          <MenuItem value={"keyDriver"}>
                            {"View By Key Drivers"}
                          </MenuItem>)
                        }
                      </Select>
                      {selectedType === "keyDriver" &&
                        <InformationComponent>
                          <TypographyComponent variant="tooltipbody">
                          Items marked with a blue key refer to your top engagement 
                          drivers based on key driver analysis results for the entire engagement survey. 
                          <a
                            href="https://20709424.hs-sites.com/understanding-key-drivers"
                            target="_blank"
                            style={{color: "#54c0fd"}}
                          > Click here</a> to learn more.
                          </TypographyComponent>
                        </InformationComponent>
                      }
                    <div style={{ cursor: 'pointer', display: 'inline' }} onClick={() => handleSorting(item, sortingData[item])}>
                      <TableSortLabel
                        active={sortingData[item] && sortingData[item] !== ""}
                        direction={sortingData[item] === "asc" ? "asc" : 'desc'}
                      />
                    </div>
                    </Cell>
                  ) : (item === "Category" ?
                    <Cell className="txtBold fontMontserrat" style={{ width: `${rowWidth}px`, maxWidth: `${rowWidth}px`, textAlign: "center", fontWeight: "400" }}>
                      {/* view by category - Filter Icon with tooltip */}
                      <Tooltip arrow title={`${filtersApplied?.['category'] ? 'Filters Applied' : 'Apply Filters'}`}>
                        <IconButton
                          className={`icon-hover ${filtersApplied?.['category'] ? 'active' : ''}`}
                          aria-describedby={'hotspotViewByCategoryPopover'}
                          onClick={(e) => setViewByCategoryAnchor(e.currentTarget)}
                        >
                          {Filter()}
                        </IconButton>
                      </Tooltip>
                      {/* view by category - Filter Icon popover */}
                      <Popover
                        id={'hotspotViewByCategoryPopover'}
                        open={Boolean(viewByCategoryAnchor)}
                        anchorEl={viewByCategoryAnchor}
                        onClose={() => {setViewByCategoryAnchor(null)}}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <MultiSelectChecboxWithSearch
                          checkboxData={getInitialFilteredData(tableData, 'category')}
                          defaultSelectAll={filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 ? false : true}
                          handleApply={applyFiltersBtn}
                          handleCancel={() => {setViewByCategoryAnchor(null)}}
                          triggerType={'category'}
                          selectedCheckboxDatas={getSelectedFilterData(tableData, 'category')}
                        />
                      </Popover>
                      <span className={(inx != 2 && inx!= 1) ? 'rotate' : ''} >{item}</span>
                      <div style={{ position: "relative", zIndex: 9999 }} onClick={() => handleSorting(item, sortingData[item])}>
                        <TableSortLabel
                          active={sortingData[item] && sortingData[item] !== ""}
                          direction={sortingData[item] === "asc" ? "asc" : 'desc'}
                        />
                      </div>
                    </Cell> : (item === "Key Driver" ?
                      <Cell className="txtBold fontMontserrat" style={{ width: `${rowWidth}px`, maxWidth: `${rowWidth}px`, textAlign: "center", fontWeight: "400" }} onClick={() => { handleSorting(item, sortingData[item]) }}>
                        <span className={'rotate'} >{item}</span>
                        <div style={{ position: "relative", zIndex: 9999 }}>
                          <TableSortLabel
                            active={sortingData[item] && sortingData[item] !== ""}
                            direction={sortingData[item] === "asc" ? "asc" : 'desc'}
                          />
                        </div>
                      </Cell> : (item === "Overall" ?
                        <Cell style={{ width: `${rowWidth}px`, maxWidth: `${rowWidth}px`, textAlign: "center", fontWeight: "400" }} onClick={() => { handleSorting(item, sortingData[item]) }}>
                          <span className={'rotate'} >{item}</span>
                          <div style={{ position: "relative", zIndex: 9999 }}>
                            <TableSortLabel
                              active={sortingData[item] && sortingData[item] !== ""}
                              direction={sortingData[item] === "asc" ? "asc" : 'desc'}
                            />
                          </div>
                        </Cell> : <Cell style={{ width: `${rowWidth}px`, maxWidth: `${rowWidth}px`, textAlign: "center", fontWeight: "400" }} onClick={() => { handleSorting(item, sortingData[item]) }}>
                          <span className={inx != 1 ? 'rotate' : ''} >{item}</span>
                          <div style={{ position: "relative", zIndex: 9999 }}>
                            <TableSortLabel
                              active={sortingData[item] && sortingData[item] !== ""}
                              direction={sortingData[item] === "asc" ? "asc" : 'desc'}
                            />
                          </div>
                        </Cell>
                      )
                    )
                  )}
                </React.Fragment>
              ))}
            </Row>
            <React.Fragment>
              {staticRow &&
                staticRow.length > 0 &&
                staticRow.map((item, index) => (
                  <Row className={item._id === "Number of Respondents (N)"? classes.greyRow : classes}>
                    {rowOrder.map((row, ind) => (
                      <Cell
                        className={`${leftAlignCells.indexOf(row) > -1 ? classes.leftAlignTxt : classes.centerAlignTxt}`}>
                        {item[row]}
                      </Cell>
                    ))}
                  </Row>
                ))}
            </React.Fragment>
            <React.Fragment>
              {tableData &&
                tableData.length > 0 &&
                tableData.map((item, index) => {
                  const questionCondition = (selectedType) => {
                    if (filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length === 0) {
                      return true;
                    }

                    if (Object.keys(filtersApplied).length === 2) {
                      const questionCondition = (filtersApplied?.[selectedType] || []).indexOf(item?.['_id']) !== -1;
                      const categoryCondition = (filtersApplied?.['category'] || []).indexOf(item?.['catName']) !== -1;

                      return questionCondition && categoryCondition;
                    }

                    if (Object.keys(filtersApplied).length === 1) {
                      const objKey = Object.keys(filtersApplied)?.[0];
                      const tableKey = objKey === selectedType ? '_id' : 'catName';

                      if (filtersApplied?.[objKey]) {
                        return (filtersApplied?.[objKey] || []).indexOf(item?.[tableKey]) !== -1
                      }
                    }

                    return false;
                  }

                  if (
                    ['question', 'keyDriver'].indexOf(selectedType) !== -1 ? questionCondition(selectedType) : (selectedTypeFilters || []).indexOf(item?.['_id']) !== -1 ?
                      true :
                      !selectedTypeFilters || selectedTypeFilters.length === 0
                  ) {
                    return (
                      <Row>
                        {rowOrder.map((row, ind) => (
                          <Tooltip arrow title={["_id", "catName"].indexOf(row) > -1 ? item[row] : ""} placement="bottom-start" >
                            {row === "keyDriverValue" ?
                              <Cell
                                className={`${leftAlignCells.indexOf(row) > -1 ? classes.leftAlignTxt : classes.centerAlignTxt} relative`}
                              >
                                {item[row] ? <Tooltip arrow title={"Key Driver"}>{keyDriver()}</Tooltip> : ""}
                              </Cell> :
                              <Cell
                                style={{ backgroundColor: getBgColor(row, (item[row] >= 0 || item[row]) ? item[row] : -1), border: getBorder(row, item[row] ? item[row] : -1) }}
                                className={`${leftAlignCells.indexOf(row) > -1 ? classes.leftAlignTxt : classes.centerAlignTxt} relative`}
                              >
                                {addEllipsis(item[row] >= 0 || item[row] ? item[row] : "-", ind === 0 ? 61 : 20)}
                              </Cell>}
                          </Tooltip>
                        ))}
                      </Row>
                    )
                  }
                })}
            </React.Fragment>
          </StickyTable>
        </div>
      )}
    </React.Fragment>
  );
};
// default props
HotspotStickyTable.defaultProps = {
  classes: {},
  selectedType: "question",
  updateSelectedType: () => { },
  header: [],
  staticRow: [],
  staticHeaders: [],
  tableData: [],
  rowOrder: [],
  colorRange: [],
  sortingData: {},
  leftAlignCells: [],
  handleSorting: () => { },
  header2: [],
  zoom: false,
  applyFilters: () => {},
  filtersApplied: {},
};

// prop types
HotspotStickyTable.propTypes = {
  classes: PropTypes.object,
  selectedType: PropTypes.string,
  updateSelectedType: PropTypes.func,
  header: PropTypes.array,
  staticRow: PropTypes.array,
  staticHeaders: PropTypes.array,
  tableData: PropTypes.array,
  rowOrder: PropTypes.array,
  colorRange: PropTypes.array,
  sortingData: PropTypes.object,
  leftAlignCells: PropTypes.array,
  handleSorting: PropTypes.func,
  header2: PropTypes.array,
  zoom: PropTypes.bool,
  applyFilters: PropTypes.func,
  filtersApplied: PropTypes.object,
};
export default withStyles(HotspotTableStyle)(HotspotStickyTable);
