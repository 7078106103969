import React from 'react';
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

//Import Constants
import { ReportsConstants } from "../../../../config/constants"

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

function MEFavorableWidget(props) {
    // props
    const { classes, value, type } = props;

    return (
        <Grid className={classes.dashboardTile}>
            <Grid container direction="column" className=" width100 height100">
                <Grid container alignItems="center" justifyContent="center">
                    <TypographyComponent variant="h4" className="txtBold fontSize16">
                        {ReportsConstants.OVERALL_FAVORABLITYl}</TypographyComponent>
                </Grid>
                <Grid item className="marginAuto p5">
                    <Grid style={{ position: "relative", display: "flex" }}>
                        <CircularProgress
                            className={classes.circularBar}
                            variant="determinate"
                            value={value}
                            size={90}
                        />
                        <CircularProgress
                            className={classes.circularBarValue}
                            variant="determinate"
                            value={100}
                            size={90}
                        />
                        <TypographyComponent variant="h4" className={`${classes.circularvalue} txtBold fontSize22`}>
                            {`${Math.round(
                                value,
                            )}%`}</TypographyComponent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
// default props
MEFavorableWidget.defaultProps = {
    classes: {},
    value: "",
    type: ""
};

// prop types
MEFavorableWidget.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string

};
export default withStyles(ReportsStyles)(MEFavorableWidget);