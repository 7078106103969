import {IsEmail} from "../../../../helpers"
export const stepperValidation = (currentStepper, reportData, commentQuestions, savedReports = [], buttonType, isSaveReport, errorMails) => {
    if (currentStepper === 0) {
        return selectSurveyStep(reportData)
    } else if (currentStepper === 1) {
        return ChooseDatesStep(reportData)
    } else if (currentStepper === 2) {
        return true
    } else if (currentStepper === 3) {
        return chooseReportStep(reportData, commentQuestions)
    } else if (currentStepper === 4) {
        if (buttonType === "export") {
            let surveyStep = selectSurveyStep(reportData)
            let dateStep = ChooseDatesStep(reportData)
            let reportStep = chooseReportStep(reportData, commentQuestions)
            if (surveyStep === true && dateStep === true && reportStep === true) {
                return true;
            } else if (surveyStep !== true) {
                return surveyStep
            } else if (dateStep !== true) {
                return dateStep
            } else if (reportStep !== true) {
                return reportStep
            } else {
                return true
            }
        } else {
            const nameFoundd = findReportNames(savedReports, reportData["name"], isSaveReport)
            if (!reportData["name"] || reportData["name"] === " ") {
                return "Report name is required";
            } else if (nameFoundd === true) {
                return "Report name exists"
            } else if ((!reportData["frequency"] || reportData["frequency"] === " ") && reportData["emailIds"]?.length > 0) {
                return "Please choose frequency";
            } else {
                let surveyStep = selectSurveyStep(reportData)
                let dateStep = ChooseDatesStep(reportData)
                let reportStep = chooseReportStep(reportData, commentQuestions)
                let emailStep = emailValidation(reportData)
                if (surveyStep === true && dateStep === true && reportStep === true && emailStep === true) {
                    return true;
                } else if (surveyStep !== true) {
                    return surveyStep
                } else if (dateStep !== true) {
                    return dateStep
                } else if (reportStep !== true) {
                    return reportStep
                } else if (emailStep !== true) {
                    return emailStep
                } else {
                    return true
                }
            }
        }
    } else {
        return true;
    }
}

const findReportNames = (savedReports, name, isSaveReport) => {

    let nameFound = false;
    let nameCount = 0
    savedReports.map(item => {
        if (item && (item).toLowerCase() == (name).toLowerCase() && !isSaveReport) {
            nameFound = true;
        } else if (item && (item).toLowerCase() === (name).toLowerCase() && isSaveReport) {
            nameCount++;
        }
    })
    if (isSaveReport) {
        if (nameCount > 1) {
            return true
        } else {
            return false
        }
    } else {
        return nameFound;
    }
}

//choose survey stepper validation
const emailValidation = (reportData) => {
    let eMailID= getEmailChips(reportData["emailIds"] || [])
    let invalidMails = eMailID.filter(e=>(e.isValid===false))

    if (reportData["emailIds"] && reportData["emailIds"].length === 0 && reportData["frequency"] && reportData["frequency"] != " ") {
        return "Please add email"
    } else if (reportData["emailIds"] && reportData["emailIds"].length > 0 && (!reportData["frequency"] || reportData["frequency"] === " ")) {
        return "Please select frequency"
    }else if(reportData["frequency"] && reportData["frequency"] != " " && invalidMails && invalidMails.length){
        return "Report not saved.  Invalid email provided."
    } else {
        return true;
    }
}

//choose survey stepper validation
const selectSurveyStep = (reportData) => {
    if (reportData["surveys"] && reportData["surveys"].length === 0) {
        return "Please select a survey"
    } else {
        return true;
    }
}

//choose date stepper validation
const ChooseDatesStep = (reportData) => {
    if (reportData["date"] && (!reportData["date"]["type"] || reportData["date"]["type"] === " ")) {
        return "Please choose dates"
    } else if (reportData["date"] && (reportData["date"]["type"] === "Custom dates" && (!reportData["date"]["startDate"] || new Date(reportData["date"]["startDate"]) == "Invalid Date"))) {
        return "Please select start date"
    } else if (reportData["date"] && (reportData["date"]["type"] === "Custom dates" && (!reportData["date"]["endDate"] || new Date(reportData["date"]["endDate"]) == "Invalid Date"))) {
        return "Please select end date"
    } else if (reportData["date"] && (reportData["date"]["type"] === "Custom dates" && (new Date(reportData["date"]["startDate"]) > new Date(reportData["date"]["endDate"])))) {
        return "Invalid date range!"
    } else if (reportData["date"] && reportData["date"]["type"] === "Pulse dates" && !reportData["date"]["pulseDate"]) {
        return "Please select pulse dates"
    } else {
        return true;
    }
}

//choose report stepper validation
const chooseReportStep = (reportData, commentQuestions) => {
  const hasMESurvey = reportData["surveys"].filter(f => f.type === "Multi-rater feedback").length > 0
    if (!reportData["reportType"] || reportData["reportType"] === " ") {
        return "Please choose report"
        //comment report
    } else if (reportData["reportType"] === "Comment Report") {
        if (commentQuestions && commentQuestions.length > 0) {
            if (reportData["selectedDemographic"] && reportData["selectedDemographic"] !== " ") {
                return true
            } else {
                reportData["selectedDemographic"] = "No breakout"
                return true
            }
        } else {
            return "Please select questions"
        }
        //participation report 
    } else if (reportData["reportType"] === "Participation" && !hasMESurvey) {
        if (reportData["selectedDemographic"] && reportData["selectedDemographic"] !== " ") {
            return true
        } else {
            return "Please select demographics"
        }
        //red raw rehire 
    } else if (["Red Flag Report", "Rehire Report", "Raw Data"].indexOf(reportData["reportType"]) > -1) {
        if (reportData["selectedDemographic"] && Array.isArray(reportData["selectedDemographic"]) && reportData["selectedDemographic"].length > 0) {
            return true
        } else {
            return "Please select demographics"
        }
    } else if (reportData["reportType"] === "Individual Report") {
        if (reportData["selectedSubject"] && reportData["selectedSubject"] !== " ") {
            return true
        } else {
            return "Please select a subject"
        }
        //red raw rehire 
    } else {
        return true;
    }
}

const getEmailChips = (emailArr) => {
    let resultEmails = [];
    emailArr.map((i) => {
      if (i) {
        if (IsEmail(i.trim())) {
          resultEmails.push({ email: i.trim().toLowerCase(), isValid: true });
        } else {
          resultEmails.push({ email: i.trim().toLowerCase(), isValid: false });
        }
      }
    });
    return resultEmails;
  }