import { alertConstant, contentLibraryConstant, uiConstant } from "../constants";
import { contentLibraryService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;
// Export All category Actions Functions
export const contentLibraryAction = {
  addOrChangeActionAlert,
  addOrChangeReportContent,
  getOne,
  getOneReport,
  search,
  searchReport,
  update,
  updateReport,
  remove,
  removeReport,
  getMessage,
  updateMessage,
  getBaseQusID,
  getAllQuestions,
  searchBaseQuestionID,
  searchQuestionCategory,
  updateRecommendation
};

/**
 * Get Client action alert
 */
function getOne() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_GETONE_REQUEST));
    contentLibraryService.getOne().then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_GETONE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_GETONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get  Client report Content
 */
function getOneReport() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_REQUEST));
    contentLibraryService.getOneReport().then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* Search Action Alert/Coach
*/
/**
* Search Action Alert/Coach
*/
function search(text) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_SEARCH_REQUEST, text));
    contentLibraryService.search(text).then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* Search Report Content/Coach
*/
function searchReport(text) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_SEARCH_REQUEST, text));
    contentLibraryService.searchReport(text).then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* Update Action Alert Data
*
* @param {*} updateData data to be updated
* @returns
*/
function update(updateData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_UPDATE_REQUEST, updateData));

    contentLibraryService.update(updateData).then(
      (data) => {
        contentLibraryService.getOne().then(
          (content) => {
            dispatch(success(contentLibraryConstant.CONTENTLIBRARY_GETONE_SUCCESS, content));
            dispatch(request(uiConstant.LOADING_FALSE));
            successCallback();
          },
          (error) => {
            dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_GETONE_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        );
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Report Content Data
 *
 * @param {*} updateData data to be updated
 * @returns
 */
function updateReport(updateData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_UPDATE_REQUEST, updateData));

    contentLibraryService.updateReport(updateData).then(
      (data) => {
        contentLibraryService.getOneReport().then(
          (content) => {
            dispatch(success(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_SUCCESS, content));
            dispatch(request(uiConstant.LOADING_FALSE));
            successCallback();
          },
          (error) => {
            dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        );
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* delete Action Alert Data
*
* @param {*} updateData data to be updated
* @returns
*/

function remove(deleteData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_REMOVE_REQUEST));
    contentLibraryService.remove(deleteData).then(
      (data) => {
        contentLibraryService.getOne().then(
          (content) => {
            dispatch(success(contentLibraryConstant.CONTENTLIBRARY_GETONE_SUCCESS, content));
            dispatch(request(uiConstant.LOADING_FALSE));
            successCallback();
          },
          (error) => {
            dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_GETONE_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        );
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_REMOVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* delete Report Content Data
*
* @param {*} updateData data to be updated
* @returns
*/
function removeReport(updateData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_REMOVE_REQUEST, updateData));

    contentLibraryService.removeReport(updateData).then(
      (data) => {
        contentLibraryService.getOne().then(
          (content) => {
            dispatch(success(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_SUCCESS, content));
            dispatch(request(uiConstant.LOADING_FALSE));
            successCallback();
          },
          (error) => {
            dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_GETONE_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        );
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_REMOVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

// To add new action alert
function addOrChangeActionAlert(actionAlertNewData) {
  return {
    type: contentLibraryConstant.CONTENTLIBRARY_ONADD_ACTIONALERT,
    data: { actionAlertNewData }
  };
}

// To add new report content library
function addOrChangeReportContent(reportContentNewData) {
  return {
    type: contentLibraryConstant.CONTENTLIBRARY_ONADD_REPORTCONTENT,
    data: { reportContentNewData }
  };
}


/**
 * Get Content library Message data
 */
function getMessage() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_REQUEST));
    contentLibraryService.getMessage().then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

// Message Action
/**
* Update Message Data
*
* @param {*} updateData data to be updated
* @returns
*/
function updateMessage(updateData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_MESSAGE_UPDATE_REQUEST, updateData));
    contentLibraryService.updateMessage(updateData).then(
      (data) => {
        contentLibraryService.getMessage().then(
          (content) => {
            dispatch(success(contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_SUCCESS, content));
            dispatch(request(uiConstant.LOADING_FALSE));
            successCallback();
          },
          (error) => {
            dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_MESSAGE_GET_FAILURE, error));
            dispatch(failure(alertConstant.ALERT_ERROR, error));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        );
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_MESSAGE_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get BaseQuestions Id's for Recommendation
 */
function getBaseQusID() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_GETBASEQUSID_REQUEST));
    contentLibraryService.getBaseQusID().then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_GETBASEQUSID_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_GETBASEQUSID_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get All questions for Recommendation
 */
 function getAllQuestions() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_GETALLQUESTIONS_REQUEST));
    contentLibraryService.getAllQuestions().then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_GETALLQUESTIONS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_GETALLQUESTIONS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* Search Recommndation
*/
/**
* Search question with base question ID for Recommendation tab
*/
function searchBaseQuestionID(text) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_REQUEST, text));
    contentLibraryService.searchBaseQuestionID(text).then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
* Search Recommndation
*/
/**
* Search question with question text and category text for Recommendation tab
*/
function searchQuestionCategory(text) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_REQUEST, text));
    contentLibraryService.searchQuestionCategory(text).then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Recommendation Data in basequestion collection
 *
 * @param {*} updateData data to be updated
 * @returns
 */
function updateRecommendation(updateData) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATION_UPDATE_REQUEST, updateData));
    contentLibraryService.updateRecommendation(updateData).then(
      (data) => {
        dispatch(success(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATION_UPDATE_SUCCESS));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATION_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}
