import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles and Image
import SurveyPreviewStyles from "../../SurveyPreviewStyles";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

function Dropdown(props) {
  // Get Props Values
  const { classes, langCode, item, surveyQuesAnswer, errorStatus, questionIndex } = props;
  const { baseQusId, ansSet, text, isRequired } = item;
  const questionText = text[langCode];
  const ansOptions = ansSet && ansSet.hasOwnProperty(langCode) ? ansSet[langCode] : null;
  const answerSet = ansOptions ? Object.values(ansOptions) : [];

  const selectLang = useSelector((state) => state.takeSurvey.surveyLang);

  let selectedKey = null;
  if (surveyQuesAnswer) {
    selectedKey = Object.keys(ansOptions).find((key) => ansOptions[key] === surveyQuesAnswer);
  }

  // const [answer, setAnswer] = useState(surveyQuesAnswer);
  let answer = surveyQuesAnswer
  const [answerKey, setAnswerKey] = useState(selectedKey);

  // useEffect(() => {
    const newAnsSet = ansSet[selectLang];
    if (answerKey) {
      const newAnswer = newAnsSet[answerKey];
      // setAnswer(newAnswer)
      answer = newAnswer
    }
  // }, [selectLang, item]);

  const handleChange = (ans) => {
    const selectedVal = ans ? ans : null;
    const selectedKey = Object.keys(ansOptions).find((key) => ansOptions[key] === ans);
    setAnswerKey(selectedKey);
    props.handleAnswerUpdate(selectedVal);
  };
  return (
    <React.Fragment>
      <Grid container alignItems="center" className={classes.dropdownSingleQuestion}>
        <Grid item md={6} xs={12} className="pb5">
          <TypographyComponent variant="body1">
            {questionIndex}. {questionText} {isRequired ? "*" : ""}
          </TypographyComponent>
          {errorStatus ? <ErrMsgComponent /> : null}
        </Grid>

        {answerSet.length > 0 ? (
          <Grid item md={6} xs={12}>
            <Grid xs={12} item className={classes.answerSet}>
              <ValidatorForm name="ForgotPasswordForm" useref="form" autoComplete="off" onError={(errors) => console.log(errors)}>
                <AutoCompleteComponent
                  name={baseQusId}
                  id={`qus_${baseQusId}`}
                  variant="outlined"
                  value={answer}
                  suggestions={answerSet}
                  fullWidth={true}
                  handleAutoCompolete={handleChange}
                  placeholder="Select Answer"
                  className={classes.dropdownTextBox}
                  suggestionType="array"
                  disableClearable={answer ? false : true}
                />
              </ValidatorForm>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    </React.Fragment>
  );
}
// default props
Dropdown.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => {},
  errorStatus: false,
};

// prop types
Dropdown.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  errorStatus: PropTypes.bool,
};
export default withStyles(SurveyPreviewStyles)(Dropdown);
