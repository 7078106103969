// API Integration Reducer Types
export const apiIntegrationConstants = {
    GET_ALL_SURVEY_REQUEST: 'GET_ALL_SURVEY_REQUEST',
    GET_ALL_SURVEYS_SUCCESS: 'GET_ALL_SURVEYS_SUCCESS',
    GET_ALL_SURVEYS_FAILURE: 'GET_ALL_SURVEYS_FAILURE',

    GET_PREV_TOKEN_REQUEST: 'GET_PREV_TOKEN_REQUEST',
    GET_PREV_TOKEN_SUCCESS: 'GET_PREV_TOKEN_SUCCESS',
    GET_PREV_TOKEN_FAILURE: 'GET_PREV_TOKEN_FAILURE',

    PUT_SURVEY_IDS_REQUEST: 'PUT_SURVEY_IDS_REQUEST',
    PUT_SURVEY_IDS_SUCCESS: 'PUT_SURVEY_IDS_SUCCESS',
    PUT_SURVEY_IDS_FAILURE: 'PUT_SURVEY_IDS_FAILURE',

    PUT_DEMOGRPAHICS_REQUEST: 'PUT_DEMOGRPAHICS_REQUEST',

    PUT_WISHLIST_URL_REQUEST: 'PUT_WISHLIST_URL_REQUEST',
    PUT_START_DATE_REQUEST: 'PUT_START_DATE_SUCCESS',
    PUT_END_DATE_REQUEST: 'PUT_END_DATE_FAILURE',
    PUT_LIMIT_REQUEST : "PUT_LIMIT_REQUEST",
    PUT_LIMIT_PER_REQUEST : "PUT_LIMIT_PER_REQUEST",

    POST_GENERATE_TOKEN_REQUEST: 'POST_GENERATE_TOKEN_REQUEST',
    POST_GENERATE_TOKEN_SUCCESS: 'POST_GENERATE_TOKEN_SUCCESS',
    POST_GENERATE_TOKEN_FAILURE: 'POST_GENERATE_TOKEN_FAILURE',

    SHOW_TOKEN_REQUEST: 'SHOW_TOKEN_REQUEST',
    SHOW_TOKEN_REQUEST_SUCCESS: 'SHOW_TOKEN_REQUEST_SUCCESS',
    REMOVE_TOKEN:'REMOVE_TOKEN'
}