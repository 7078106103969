import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import Actions from "./Actions";
import EditActionPlan from "./EditActionPlan";

//import component 
import LiveChatComponent from "../../components/LiveChatComponent/LiveChatComponent";

function ActionRouter(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route exact path={match.url} render={(props) => {
          return <Actions
            match={props.match}
            {...props} />;
        }} />
        <Route exact path={`${match.url}/editplan/:id`} render={(props) => {
          return <EditActionPlan
            match={props.match}
            {...props} />;
        }} />
      </Switch>
    </>
  );
}

/**
 * Bind Component Property Types
 */
ActionRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default ActionRouter;
