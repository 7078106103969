/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable curly */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles and Image
// import { BackPage } from "../../../assets/images/svgComponents/ImageComponent";

import ParticipantStyles from "./PariticipantStyles";

// Import Actions
import { participantAction, updateStateReducerAction, alertAction, metadataAction } from "../../../redux/actions";

// Import Container
import Container from "../../Containers/Container";

// Import History
import history from "../../../config/history";

// Import Helpers
import { getReducerUpdatedData } from "../../../helpers";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import DeleteDialogComponent from "../../../components/DeleteDialogComponent/DeleteDialogComponent";
import CancelDialogComponent from "../../../components/CancelDialogComponent/CancelDialogComponent";

// Import Constants
import { participantConstant, metadataConstant } from "../../../redux/constants";
import { participantConstants, translateLanguagesList } from "../../../config/constants";

// Import Panels
import DeafaultPanel from "./AddEditParticipants/DeafaultPanel";
import AssignedSurveyPanel from "./AddEditParticipants/AssignedSurveyPanel";
import OtherDemographicsPanel from "./AddEditParticipants/OtherDemographicsPanel";
import AutomatedDemographicsPanel from "./AddEditParticipants/AutomatedDemographicsPanel";
import { reduce } from "lodash";

function EditParticipant (props) {
  // Assign Props
  const { classes, expanded, match } = props;
  const { params } = match;
  const location = useLocation();

  const formRef = useRef(null);
  const [isCalc, setCalc] = useState(false);
  // Define Dispatch
  const dispatch = useDispatch();

  // Define Selector
  const {getOne} = useSelector((state) => state.participant);
  const participant = {...getOne}
  const { metadata, metadataWithRGI } = useSelector((state) => state.metadata);

  // state
  const [actions, setActions] = React.useState({ isDelete: false, isCancel: false });
  /**
       * Fetch Participant Data
       */
  useEffect(() => {
    dispatch(participantAction.getOne(params.id ? params.id : null));
  }, [dispatch]);
  /**
      * Handle Cancel
      */
  const handleCancel = () => {
    setActions({ ...actions, isCancel: true });
  };
  /**
     * Handle Delete
     */
  const handleDelete = async () => {
    setActions({ ...actions, isDelete: true });
  };
  /**
   * Back Button
  */
  const BackPage = (props) =>
  (
      <svg class="backBtn" xmlns="http://www.w3.org/2000/svg" width="6.254" height="10.646" viewBox="0 0 6.254 10.646"><defs>
      </defs><g transform="translate(-101.48)"><g transform="translate(101.48 0)">
          <path fill="#c0c1c2" class="a" d="M103.582,5.325l3.983-3.983a.584.584,0,0,0,0-.823L107.215.17a.583.583,0,0,0-.824,0L101.65,4.912a.588.588,0,0,0,0,.826l4.737,4.737a.583.583,0,0,0,.824,0l.349-.349a.583.583,0,0,0,0-.824Z" transform="translate(-101.48 0)" /></g></g></svg>
  )
  // convert date
  const convertDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    const finaldate = `${month}/${dt}/${year}`;
    return finaldate === "01/01/1970" ? "" : finaldate;
  };
  /**
     * Handle Change
     */
  const handleChange = (e, dataType, newOption = false ) => {
    if (dataType === "date") {
      let valid = new Date(e.value) == "Invalid Date";
      if (!valid)
        e.value = convertDate(e.value);
    }

    // update lang code
    if (e.name === "languages") {
      let code = null
      let value = e.value

      const langCode = translateLanguagesList.filter(obj => (obj.name).toLowerCase() === e.value.toLowerCase())

      if(langCode.length > 0) {
        code = langCode[0].code
        value = langCode[0].name
      }

      e = {
        ...e,
        value: {
          code: code,
          name: value
        }
      };
    }
    
    if(newOption === true){
      let selMeta = metadataWithRGI.find((meta)=> meta.key === e.name);
      let oldMeta = metadataWithRGI.filter((meta)=> meta.key !== e.name);
      selMeta = selMeta ? selMeta : { rgi: [], key: e.name }
      selMeta["rgi"] = [...selMeta.rgi, e.value];
      dispatch(updateStateReducerAction.update(metadataConstant.UPDATE_METADATA_OPTIONS, [...oldMeta, selMeta]));
    }
    const reducerData = getReducerUpdatedData(e, participant);
    dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_UPDATE, reducerData));
     
    /* 
      // Email & Alt Email Validation
      if(reducerData.email && reducerData.altEmail && reducerData.email === reducerData.altEmail){      
        dispatch(
          alertAction.error(participantConstants.EMAIL_ALT_EMAIL_EQUAL_WARN)
        );
      }
      else{
        dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_UPDATE, reducerData));
      }
    */
  };

  /**
     * Handle Submit
     */
  const handleSubmit = () => {
    let addOnData = ["state", "country"];
    addOnData.map(d => {
      metadata.filter(meta => meta.key === d).map(addOn => addOn.rgi && !addOn.rgi.includes(participant[addOn.key]) && addOn.rgi.push(participant[addOn.key]));
    });
    dispatch(metadataAction.updateMetadata({ metadata }, false));
    
    // add date fields to post method in order to convert and save in api
    participant.dateFields = metadata.filter(s => s.dataType === "date").map(m => m.key);
    
    //Convert String to Integer for number datatype 
    metadata.filter(s => s.dataType === "integer").map(m => {
      if(participant.hasOwnProperty(m.key) && participant[m.key]){
        participant[m.key] = parseFloat(participant[m.key])
      }
    });

    
    dispatch(participantAction.update(participant, () => {
      history.push({
        pathname: `/manage_people/participants`,
        state: { skip: location.state && location.state.skip ? location.state.skip : 1 }
      });
    }));
    
    /* 
      // Email & Alt Email Validation
      if(participant.email && participant.altEmail && participant.email === participant.altEmail){      
        dispatch(
          alertAction.error(participantConstants.EMAIL_ALT_EMAIL_EQUAL_WARN)
        );
      }
      else{
        dispatch(participantAction.update(participant, () => {
          history.push({
            pathname: `/people/participants`,
            state: { skip: location.state && location.state.skip ? location.state.skip : 1 }
          });
        }));
      }
    */
  };

  /**
     * On Handle Cancel & Delete, cancel
     */
  const initActions = () => {
    setActions({ isCancel: false, isDelete: false });
  };

  /**
     * On handle Cancel, No
     */
  const backToParticipantsList = (isReset = false) => {
      history.replace("/manage_people/participants?refresh=true&reset=true");
    // if(isReset)
    //   history.replace("/people/participants?refresh=true&reset=true");
    // else
    //   history.replace("/people/participants?refresh=true");
  };
  /**
     * On Handle Cancel, Save
     */
  const handleParticipantYes = () => {
    formRef.current.submit();
  };

  /**
     * participant title
     */
  const getTitle = () => {
    let name = "";
    name += participant.firstName ? `${participant.firstName} ` : "";
    name += participant.lastName ? participant.lastName : "";
    return name;
  };

  /**
     * Handle Participant Delete
     */
  const handleParticipantDelete = () => {
    // if ((participant.survey || []).filter(f => f.isDelete === false).length > 0) {
    //   dispatch(alertAction.error(participantConstants.DELETE_SURVEY_WARN));
    // } if {
      dispatch(participantAction.remove(participant._id, () => {
        backToParticipantsList(true);
      }));
    // }
    setActions({ ...actions, isDelete: false });
  };

  const handleMetadataChange = (dataMeta) => {
    dispatch(
      updateStateReducerAction.update(
        metadataConstant.UPDATE_METADATA_REDUCER,
        dataMeta
      )
    );
  }

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ValidatorForm
              ref={formRef}
              id="edit_participant"
              name="Edit Participant"
              autoComplete="off"
              onSubmit={handleSubmit}
              onError={(error) => {
                initActions();
                console.log(error);
              }}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid className={classes.backimg} onClick={()=>backToParticipantsList(false)}>
                        { BackPage() }
                      </Grid>
                      <TypographyComponent
                        variant={"h3"}
                        className="pl-1"
                        title={getTitle()} />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <div className={"container"}>

                {/* Deafault Panel */}
                <DeafaultPanel metadata={metadata} participant={participant} handleChange={handleChange} classes={classes} handleMetadataChange={handleMetadataChange}></DeafaultPanel>

                {/* Assigned Survey Panel */}
                <AssignedSurveyPanel params = {params} setCalc = {setCalc} participant={participant} classes={classes}></AssignedSurveyPanel>

                {/* Automated Demographics panel */}
                <AutomatedDemographicsPanel metadataWithRGI={metadataWithRGI} metadata={metadata} setCalc = {setCalc} participant={participant} handleChange={handleChange} classes={classes} />

                {/* Other Demographics Panel */}
                <OtherDemographicsPanel
                  participant={participant}
                  metadata={metadata}
                  metadataWithRGI={metadataWithRGI}
                  handleChange={handleChange}
                  classes={classes}
                />
              </div>
              <Grid container justifyContent="flex-end" className={classes.footerBtns_container}>
                <Grid item>
                  <ButtonComponent
                    title={participantConstants.SAVE_BUTTON}
                    color={"primary"}
                    size={"small"}
                    type='submit'
                    pageClassName="mr-1"
                  ></ButtonComponent>
                  <ButtonComponent
                    title={participantConstants.DELETE_BUTTON}
                    color={"primary"}
                    size={"small"}
                    type={"button"}
                    pageClassName="buttonOrange mr-1"
                    handleClick={handleDelete}
                  ></ButtonComponent>
                  <ButtonComponent
                    title={participantConstants.CANCEL_BUTTON}
                    color={"default"}
                    size={"small"}
                    type={"button"}
                    handleClick={handleCancel}
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </ValidatorForm>
            <DeleteDialogComponent
              open={actions.isDelete}
              content={participantConstants.DELETE_ALERT}
              handleDeleteAction={handleParticipantDelete}
              handleDeleteOnCloseAction={initActions}
            />
            <CancelDialogComponent
              open={actions.isCancel}
              constent={participantConstants.CANCEL_ALERT}
              handleCancelAction={initActions}
              handleOnNoAction={()=>backToParticipantsList(false)}
              handleOnYesAction={handleParticipantYes}
            />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(ParticipantStyles)(EditParticipant);
