import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";

/**
 * Dialog Component
 * @param {*} props
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

function DialogComponent(props) {
  const { children, open, fullScreen, disableBackdropClick,
    onEntered, info, title, content, btnText, className, handleClick } = props;

  /**
   * Handle Close
   */
  const handleClose = () => {
    handleClose(!open);
  };

  return (
    <Dialog
      data-test="dialog-test"
      onClose={handleClose}
      fullScreen={fullScreen}
      TransitionComponent={Transition}
      aria-labelledby="dialog"
      open={open}
      className={`${className}`}
      TransitionProps={{
        onEntered
      }}>
      {!info ? children : (
        <>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClick}
              variant="contained"
              disableElevation
              size="small"
              color="primary"
            >
              {btnText}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

// default props
DialogComponent.defaultProps = {
  className: "",
  children: {},
  fullScreen: false,
  open: false,
  handleClose: () => { },
  handleClick: () => { },
  disableBackdropClick: false,
  onEntered: () => { },
  info: false,
  btnText: "Ok",
  title: "",
  content: "",
  btnText: "",
  className: ""
};

// prop types
DialogComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  fullScreen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  disableBackdropClick: PropTypes.bool,
  onEntered: PropTypes.func.isRequired
};

/**
 * Export Container
 */
export default DialogComponent;
