import { baseQuestionsConstant } from "../constants/baseQuestionsConstant";

const initialState = {
  category: [],
  question: {}
};

export default function (state = initialState, action) {
  // BASE QUESTIONS BY CATEGORY
  switch (action.type) {
    case baseQuestionsConstant.BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_REQUEST:
      return {
        ...state
      };
    case baseQuestionsConstant.BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_SUCCESS:
      return {
        ...state,
        category: action.data.data
      };
    case baseQuestionsConstant.BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_FAILURE:
      return {
        ...state,
        category: initialState.category
      };

      // BASE QUESTION BY QUESTION TEXT
    case baseQuestionsConstant.BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_REQUEST:
      return {
        ...state
      };
    case baseQuestionsConstant.BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_SUCCESS:
      return {
        ...state,
        question: action.data.data
      };
    case baseQuestionsConstant.BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_FAILURE:
      return {
        ...state,
        question: initialState.question
      };
    default:
      return state;
  }
}
