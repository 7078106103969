import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

function SentimentAnalysis(props) {
  const { frequency, sentiment, classes,zoom } = props;

  const percentageFields = [
    {
      name: "Negative Percentage",
      color: "#F79646",
      border: "50px 0px 0px 50px",
      marginRight: "3px",
    },
    {
      name: "Neutral Percentage",
      color: "#bfdbfd",
      border: "0px",
      marginRight: "3px",
    },
    {
      name: "Positive Percentage",
      color: "rgb(84, 192, 253)",
      border: "0px 50px 50px 0px",
    },
  ];
  const getBorderRadius = (field, sentiment) => {
    if (field.name === "Neutral Percentage") {
      if (
        sentiment["Negative Percentage"] === 0 &&
        sentiment["Positive Percentage"] === 0
      ) {
        return "50px 50px 50px 50px";
      } else if (sentiment["Negative Percentage"] === 0) {
        return "50px 0px 0px 50px";
      } else if (sentiment["Positive Percentage"] === 0) {
        return "0px 50px 50px 0px";
      } else {
        return field.border;
      }
    } else {
      return field.border;
    }
  };

  const generateLegend = () => (
    <React.Fragment>
      <List
        className={`fontSize14 ${classes.LegendContainer} `}
      >
        <ListItem style={{ width: "100%" }} className="txtgrey">
          <Grid style={{ width: "55%",margin:"auto", display: "flex" }} className="txtcenter">
            <Grid className={classes.CAlinechartRow}>
              <Grid className={classes.barContainer}>
                {percentageFields.map((field) => (
                  <Grid style={{width:`33%`,backgroundColor: field.color,borderRadius: field.border,marginRight: field.marginRight}}></Grid>
                ))}
              </Grid>
              <Grid className={classes.wordContainer}>
                {percentageFields.map((field) => (
                  <Grid className="fontSize12" style={{width: `33%`,color:"black"}}>{field.name.split(" ")[0]}</Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </React.Fragment>
  );

  return (
    <Grid>
      <React.Fragment>
        <Grid container>
          {Object.keys(frequency).length > 0 && (
            <React.Fragment>
              <List
                className={`fontSize14 `}
                style={{ width: "100%", fontWeight: 300 }}
              >
                <ListItem style={{ width: "100%" }} className="txtgrey">
                  <Grid style={{ width: "40%" }}>
                    <TypographyComponent variant="h6">
                      Topics
                    </TypographyComponent>
                  </Grid>
                  <Grid style={{ width: "30%" }}>
                    <TypographyComponent
                      variant="h6"
                      className="txtcenter"
                    >
                      Frequency
                    </TypographyComponent>
                  </Grid>
                  <Grid style={{ width: "30%" }}>
                    <TypographyComponent
                      variant="h6"
                      className="txtcenter"
                    >
                      Sentiment
                    </TypographyComponent>
                  </Grid>
                </ListItem>
              </List>
              <Grid
              className={classes.sentimentContainer}
                style={{ maxHeight: `${zoom ? "" : "450px"}`}}
              >
                <List
                  className={`fontSize14 `}
                  style={{ width: "100%", fontWeight: 300 }}
                >
                  {Object.keys(frequency).map((item, index) => frequency[item] !== 0 && (
                    
                    <ListItem style={{ width: "100%" }}>
                      <Grid style={{ width: "40%" }}>{item}</Grid>
                      <Grid
                        style={{ width: "30%" }}
                        className="txtcenter fontSize13"
                      >{`${Math.round(frequency[item])}%`}</Grid>
                      <Grid
                        style={{ width: "30%", display: "flex" }}
                        className="txtcenter"
                      >
                        <Grid className={classes.CAlinechartRow}>
                          <Grid className={classes.CARowContainer}>
                          <Grid className={classes.barContainer}>
                            {percentageFields.map((field) =>
                              sentiment[item][field.name] === 0 ? (
                                <Grid
                                  style={{
                                    width: `${sentiment[item][field.name]}%`,
                                    backgroundColor: field.color,
                                    borderRadius: getBorderRadius(
                                      field,
                                      sentiment[item]
                                    ),
                                    marginRight: 0,
                                  }}
                                ></Grid>
                              ) : sentiment[item][field.name] === 100 ? (
                                <Grid
                                  style={{
                                    width: `${sentiment[item][field.name]}%`,
                                    backgroundColor: field.color,
                                    borderRadius: "50px 50px 50px 50px",
                                    marginRight: 0,
                                  }}
                                ></Grid>
                              ) : (
                                <Grid
                                  style={{
                                    width: `${sentiment[item][field.name]}%`,
                                    backgroundColor: field.color,
                                    borderRadius: getBorderRadius(
                                      field,
                                      sentiment[item]
                                    ),
                                    marginRight: field.marginRight,
                                  }}
                                ></Grid>
                              )
                            )}
                          </Grid>
                          <Grid className={classes.wordContainer}>
                            {percentageFields.map((field) =>
                              sentiment[item][field.name] >= 10 ? (
                                <Grid
                                  className="txtright fontSize12"
                                  style={{
                                    width: `${sentiment[item][field.name]}%`,
                                  }}
                                >
                                  {`${sentiment[item][field.name]}%`}
                                </Grid>
                              ) : (
                                <Grid
                                  className="txtright fontSize12"
                                  style={{
                                    width: `${sentiment[item][field.name]}%`,
                                  }}
                                />
                              )
                            )}
                          </Grid>
                        </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
              </Grid>
                {generateLegend()}
            </React.Fragment>
          )}
          {Object.keys(frequency).length === 0 && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="mt-10"
            >
              <TypographyComponent variant="h3">
                {ReportsConstants.NO_RESPONSE_DATA_CHART}
              </TypographyComponent>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    </Grid>
  );
}

// prop types
SentimentAnalysis.propTypes = {
  frequency: PropTypes.object,
  sentiment: PropTypes.object,
  classes: PropTypes.object,
  zoom: PropTypes.bool 
};

// default types
SentimentAnalysis.defaultProps = {
  frequency: {},
  sentiment: {},
  classes: {},
  zoom:false
};

export default SentimentAnalysis;
