import { adminConstants } from "../../config/constants";
import { adminsConstant } from "../constants/adminsConstant";


// Set Initial Table Data
const tableDatas = (sortName) => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: []
      }
    }
  };
};

// Set Initial State
const initialState = {
  search: { ...tableDatas("firstName") },
  getOne: {
    surveyAccess: {
      surveys: [],
      meSurveys: [],
      surveyNames: [],
      meSurveyNames: []
    },
    surveys: [],
    meSurveys: [],
    surveyNames: [],
    meSurveyNames: []
  },
  getRoles: [],
  preference: {
    surveys: [],
    surveysCpy: [],
    demographics: {},
    date: {},
    hotspot: {
      type: "",
      rg: "",
    },
    EI: {
      rg: ""
    },
    yesno: {
      rg: "tenure_groupings",
    },
    commentAnalysis: {
      baseQuesId: "",
    },
    hls: {
      type: "category",
      rg: "tenure_groupings",
      selectedSummaryWidgetColumnsDropdown: [],
    },
    historical: {
      type: "Last 3 months",
      survey: {}
    },
    tot: {
      surveys: []
    },
    // competency chart
    competency: {
      viewBy: "category"
    },
    // Dashboard Widgets show / hide Preference
    hideDashWidgets: {
      participation: false,
      overallFavorability: false,
      favorableByCategory: false,
      overalleNPS: false,
      engagedEmployee: false,
      keydriver: false,
      suggestedAreaAction: false,
      engagementBenchMark: false,
      likelyToStay: false,
      LikelyToReturn: false,
      turnOver: false,
      projectedTurnover: false,
      highLowRatedItems: false,
    },
  },
  // for insight setting drawer on click open
  open: false,
  surveyAccess: {
    surveys: [],
    meSurveys: [],
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    // ADMIN Search
    case adminsConstant.ADMINS_SEARCH_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case adminsConstant.ADMINS_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: action.data.page
        }
      };
    case adminsConstant.ADMINS_SEARCH_FAILURE:
      return {
        ...state,
        search: initialState.search
      };

    // To get a Particular admin User
    case adminsConstant.ADMINS_GET_ONE_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case adminsConstant.ADMINS_GET_ONE_SUCCESS:
      return {
        ...state,
        getOne: action.data.data
      };
    case adminsConstant.ADMINS_GET_ONE_FAILURE:
      return {
        ...state,
        search: initialState.search
      };

    // To Create a Particular User
    case adminsConstant.ADMINS_CREATE_REQUEST:
      return {
        ...state
      };
    case adminsConstant.ADMINS_CREATE_SUCCESS:
      return {
        ...state,
        getOne: action.data.data
      };
    case adminsConstant.ADMINS_CREATE_FAILURE:
      return {
        ...state
      };

    // To Update a Particular User
    case adminsConstant.ADMINS_UPDATE_REQUEST:
      return {
        ...state
      };
    case adminsConstant.ADMINS_UPDATE_SUCCESS:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case adminsConstant.ADMINS_UPDATE_FAILURE:
      return {
        ...state,
        search: initialState.search
      };

    // UPDATE GET ONE CLIENT REDUCER DATA
    case adminsConstant.ADMINS_GET_ONE_UPDATE:
      return {
        ...state,
        getOne: action.data
      };
    // To Remove a Particular User
    case adminsConstant.ADMINS_REMOVE_REQUEST:
      return {
        ...state
      };
    case adminsConstant.ADMINS_REMOVE_SUCCESS:
      return {
        ...state
      };
    case adminsConstant.ADMINS_REMOVE_FAILURE:
      return {
        ...state
      };

    // To get all Roles
    case adminsConstant.ADMINS_ROLES_REQUEST:
      return {
        ...state
      };
    case adminsConstant.ADMINS_ROLES_SUCCESS:
      return {
        ...state,
        getRoles: action.data.data
      };
    case adminsConstant.ADMINS_ROLES_FAILURE:
      return {
        ...state
      };

    //To get all Roles
    case adminsConstant.ADMINS_STATUS_UPDATE_REQUEST:
      return {
        ...state
      };
    case adminsConstant.ADMINS_STATUS_UPDATE_SUCCESS:
      return {
        ...state
      };
    case adminsConstant.ADMINS_STATUS_UPDATE_FAILURE:
      return {
        ...state,
      };

    // Update admin preference
    case adminsConstant.ADMINS_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        preference: action.data.data
      }
    case adminsConstant.ADMINS_GET_PREFERENCE_SUCCESS:
      return {
        ...state,
        preference: action.data && action.data.data ? { ...initialState.preference, ...action.data.data } || initialState.preference : initialState.preference
      }
    case adminsConstant.ADMINS_REPORT_UPDATE_REQUEST,
      adminsConstant.ADMINS_GET_PREFERENCE_FAILURE,
      adminsConstant.ADMINS_GET_PREFERENCE_REQUEST,
      adminsConstant.ADMINS_REPORT_UPDATE_FAILURE:
      return {
        ...state,
      }
    case adminsConstant.ADMINS_UPDATE_PREFERENCE_REDUCER:
      return {
        ...state,
        preference: action.data
      }

    case adminsConstant.UPDATE_DLA_QUERY:
      return {
        ...state,
        getOne: {
          ...state.getOne,
          queryDLA: action.data
        }
      }

    case adminsConstant.TOGGLE_FILTER_DRAWER_OPEN_STATUS:
      return {
        ...state,
        open: !state.open
      }

    case adminsConstant.GET_CLIENT_SURVEYS_REQUEST:
      return {
        ...state,
        surveyAccess: initialState.surveyAccess
      }
    case adminsConstant.GET_CLIENT_SURVEYS_SUCCESS:
      return {
        ...state,
        surveyAccess: action.data.data
      }
    case adminsConstant.GET_CLIENT_SURVEYS_FAILURE:
      return {
        ...state,
        surveyAccess: initialState.surveyAccess
      }
    case adminsConstant.UPDATE_ME_SURVEY_ACCESS:
      return {
        ...state,
        getOne: {
          ...state.getOne,
          surveyAccess: {
            ...state.getOne.surveyAccess,
            ...action.data
          },
          ...action.data
        }
      }
    case adminsConstant.UPDATE_SURVEY_ACCESS:
      return {
        ...state,
        ...state.getOne,
        getOne: {
          surveyAccess: {
            ...state.getOne.surveyAccess,
            ...action.data
          }
        },
        ...action.data
      }
    case adminsConstant.UPDATE_SELECTED_NLP_PREFERENCE:
        return {
          ...state,
          preference: {
            ...state.preference,
            commentAnalysis: {
              ...state.preference.commentAnalysis,
              type: action.data
            }
          }
        }
    case adminsConstant.UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_REQUEST:
    case adminsConstant.UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_FAILURE:
          return {
            ...state,
            preference: {
              ...state.preference,
              hideDashWidgets: initialState.preference.hideDashWidgets
            }
          }
    case adminsConstant.UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_SUCCESS:
      return {
        ...state,
        preference: {
          ...state.preference,
          hideDashWidgets: {
            ...action?.data?.data?.hideDashWidgets
          }
        }
      }

      
    default:
      return state;
  }
}
