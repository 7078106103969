/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import IconButton from "@mui/material/IconButton";

// Import Styles and Image
import { Download } from "../../assets/images/svgComponents/ImageComponent";

import ActionsStyles from "./ActionsStyles";
import downloadImg from "../../assets/images/svg/reports_download.svg";

// Import Component
import Container from "../Containers/Container";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";

import OpportunityAreas from "./ActionsTabs/OpportunityAreas";
import ActionPlans from "./ActionsTabs/ActionPlans";

// import constants
import { ActionConstants, excelConstants } from "../../config/constants";

// Import Helpers
import { getQueryVariable } from "../../helpers"

// Import Actions
import { excelAction } from "../../redux/actions";
import { Tooltip } from "@mui/material";

// Tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        // Tab Panel
        <Grid>
          <TypographyComponent variant="h6">{children}</TypographyComponent>
        </Grid>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

//Tabs
function Actions(props) {
  // Set default drawer state
  const [drawer, setDrawer] = useState(false);
  const [optionsPopup, setOptionsPopup] = useState(null);

  // Define Dispatch
  const dispatch = useDispatch();
  /**
     * toggle drawer
     */
  const toggleDrawer = (open, callBack = () => { }) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOptionsPopup(null);
    setDrawer(open);
    callBack()
  };
  const tab = getQueryVariable(props.location.search, "tab");

  //get props
  const { classes, handlePopoverClick, history: { location: { state: navState } } } = props;

  //Tabs
  const [value, setValue] = React.useState(tab ? parseInt(tab) : 0);

  // Get People from store
  const actionplan = useSelector(state => state.actionplan.search);
  const { page, data } = actionplan;

  //CHanges tabs
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handldDownload = (e) => {
    dispatch(excelAction.download({
      sortBy: page.sortBy ? page.sortBy : "status",
      sort: page.sort ? page.sort : "desc",
      sFields: page.sFields ? page.sFields : [],
      sValues: page.sValues ? page.sValues : [],
      excelType: excelConstants.ACTION_PLAN_DOWNLOAD
    }, true));
  }
  return (
    <Grid container className={classes.reports_Container}>

      <Grid container alignItems="center" justifyContent="space-between" className={classes.reports_header}>
        <Grid item>
          <AppBar
            position="static"
            color="default"
            className={`${classes.rightPanelAppBarRoot
              } ${"tabborder_none pe_color_appbar"}`}>
            <Tabs value={value}
              onChange={handleChange}
              component="div"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="simple tabs example"
              className={classes.rightPanelTabsRoot}>
              <Tab label="Opportunity Areas" {...a11yProps(0)}
                classes={{
                  root: classes.rightPanelTabRoot,
                  selected: classes.rightPanelTabSelected
                }}
                onClick={toggleDrawer(true)}
              />
              <Tab label="Action plans" {...a11yProps(1)}
                classes={{
                  root: classes.rightPanelTabRoot,
                  selected: classes.rightPanelTabSelected
                }}
              />
            </Tabs>
          </AppBar>
        </Grid>
        {value == 1 ? <Grid item>
          <Grid container alignItems="center">
        <Grid item><Tooltip arrow title={"Export action plans"}><IconButton onClick={(e) => handldDownload(e)} size="large">{/* <img src={downloadImg} alt="download" onClick={(e) => handldDownload(e)}></img> */}{Download()}</IconButton></Tooltip></Grid>
          </Grid>
        </Grid>
          : ""}
      </Grid>
      <TabPanel value={value} index={0} className="width100">
        <Container page="HeaderTabs" align="baseline">
          <OpportunityAreas {...props} />
        </Container>
      </TabPanel>
      <TabPanel value={value} index={1} className="width100">
        <Container page="HeaderTabs" align="baseline">
          <ActionPlans {...props} />
        </Container>
      </TabPanel>
    </Grid>
  );
}
// default props
Actions.defaultProps = {
  classes: {},
  handlePopoverClick: () => { }
};

// prop types
Actions.propTypes = {
  classes: PropTypes.object,
  handlePopoverClick: PropTypes.func
};
export default withStyles(ActionsStyles)(Actions);
