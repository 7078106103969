import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";


import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";

// Import Images
import settingsicon from "../../../assets/images/svg/settings_demographic.svg";
import closeicon from "../../../assets/images/svg/drawer_close.svg";

// Import Config Layout
import { getDrawerHeight } from "../../../config/layout";

//  Import Components
import SearchInputComponent from "../../../components/SearchInputCompoment/SearchInputComponent";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import SwitchComponent from "../../../components/SwitchComponent/SwitchComponent";
import InformationComponent from "../../../components/InformationComponent/InformationComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

// Import Redux
import { surveyAction, alertAction, updateStateReducerAction } from "../../../redux/actions"
import { participantConstant } from "../../../redux/constants"


// Import Constants
import { participantConstants } from "../../../config/constants";
import { ListItemButton } from "@mui/material";

const SendIndividualEmail = (props) => {

  const dispatch = useDispatch();

  // props
  const { drawer, toggleDrawer, classes, surveyNotificationData } = props;

  const surveyData = surveyNotificationData.survey || []

  const { firstName, lastName, _id, allSurveys } = surveyNotificationData

  const participant_name = `${firstName || ""} ${lastName || ""}`

  const [mail, setMail] = useState(true)
  const [sms, setSms] = useState(true)
  const [selectedSurvey, setSelectedSurvey] = useState({})
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [search, setSearch] = useState('')

  /**
 * Set local state value to initial state
 */
  useEffect(() => {
    if(allSurveys && allSurveys.length===1) {
      setSelectedSurvey(allSurveys[0])
      setSelectedIndex(0)
    } else {
      setSelectedSurvey({})
      setSelectedIndex(null)
    }
    setMail(true)
    setSms(true)
    setSearch('');
  }, [toggleDrawer])

  // Handle toggle action
  const handleToggle = (type, value) => {
    if (type === "Email") {
      setMail(value)
    }
    if (type === "sms") {
      setSms(value)
    }
  }

  // Select survey and save it in local state
  const handleSelectSurvey = (value, index) => {
    if (selectedIndex === index) {
      setSelectedSurvey()
      setSelectedIndex()
    } else {
      setSelectedSurvey(value)
      setSelectedIndex(index)
    }
  }

  // Handle send button click
  const handleSendNotification = () => {
    const isEmailActive = selectedSurvey && selectedSurvey.survey_id && selectedSurvey.survey_id.distribute.notification.email.active
    const isSMSActive = selectedSurvey && selectedSurvey.survey_id && selectedSurvey.survey_id.distribute.notification.sms.active
    if (((surveyNotificationData.email && surveyNotificationData.email !== "" && surveyNotificationData.email !== null) || (surveyNotificationData.altEmail && surveyNotificationData.altEmail !== "" && surveyNotificationData.altEmail !== null)) || (surveyNotificationData.phone && surveyNotificationData.phone !== "" && surveyNotificationData.phone !== null)) {
      if (mail && isEmailActive && ((surveyNotificationData.email && surveyNotificationData.email !== "" && surveyNotificationData.email !== null) || (surveyNotificationData.altEmail && surveyNotificationData.altEmail !== "" && surveyNotificationData.altEmail !== null))) {
        dispatch(surveyAction.sendSurveyMail({ _id: selectedSurvey.survey_id._id, participant_id: _id, isIndividual: true }));
      } else if (mail && isEmailActive) {
        dispatch(alertAction.error("email field is empty"));
      }
      if (sms && isSMSActive && surveyNotificationData.phone && surveyNotificationData.phone !== "" && surveyNotificationData.phone !== null) {
        dispatch(surveyAction.sendSurveySMS({ _id: selectedSurvey.survey_id._id, participant_id: _id, isIndividual: true }));
      } else if (sms && isSMSActive) {
        dispatch(alertAction.error("Phone field is empty"));
      }
    } else {
      dispatch(alertAction.error("Email and Phone fields are empty"));
    }
    
  }

  /** 
 * Handle Search 
 */
  const handleSearch = (name, value) => {
    let searchSurveys = allSurveys.filter(obj => obj.survey_id.name && obj.survey_id.name.toLowerCase().includes(value.toLowerCase()))
    surveyNotificationData["survey"] = searchSurveys
    const data = { data: surveyNotificationData }
    dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_SUCCESS, data))
    setSearch(value)
  }

  // on clear search
  const handleClearSearch = () => {
    surveyNotificationData["survey"] = allSurveys
    const data = { data: surveyNotificationData }
    dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_SUCCESS, data))
    setSearch('')
  }


  // Generate surveys to select
  const generateSurveytoSelect = () => {
    return surveyData.map((opt, index) => {
      return <List component="nav" style={{ padding: "0px" }}>
        <ListItemButton
          className={classes.survey_list}
          selected={selectedIndex === index}
          onClick={() => {
            handleSelectSurvey(opt, index)
          }}
        >
          <ListItemText className={classes.survey_list} primary={opt.survey_id.name} />
        </ListItemButton>
      </List>;
    });
  };

  /**
  * Switch Toggles
  */
  const generateSwitchButton = () => {
    const isEmailActive = (selectedSurvey && selectedSurvey.survey_id && selectedSurvey.survey_id.distribute.notification.email.active)
    const isSMSActive = (selectedSurvey && selectedSurvey.survey_id && selectedSurvey.survey_id.distribute.notification.sms.active) 
    if(!isEmailActive || !isSMSActive) {
      const message = `${(!isEmailActive && !isSMSActive ? 'Email and SMS' : !isEmailActive ? 'Email' : !isSMSActive ? 'SMS': '')} option disabled at the survey level`
      drawer && (selectedIndex !== null && selectedIndex >= 0) && dispatch(alertAction.successAlert(message));
    }
    
    return (
      <React.Fragment>
        <TypographyComponent variant={"h6"}
          className="txtMedium">
          {participantConstants.SEND_VIA}
        </TypographyComponent>
        <Grid item xs={12} className="p-1 dflex">
          <Grid>
            <Tooltip title={"Email"} arrow className="infoToolTip">
              <SwitchComponent item xs={12}
                name={"Email"}
                color="primary"
                checked={isEmailActive ? mail : false}
                disabled={!isEmailActive}
                handleChange={({ value }) => {
                  handleToggle("Email", value);
                }}
                label={
                  <span>
                    <TypographyComponent
                      variant="h6"
                    >{"Email"}</TypographyComponent>

                  </span>
                }
              />
            </Tooltip>
          </Grid>
          <Grid className="pl-1">
            <Tooltip title={"sms"} arrow className="infoToolTip">
              <SwitchComponent item xs={12}
                name={"sms"}
                color="primary"
                className="pl-1"
                checked={isSMSActive ? sms : false}
                disabled={!isSMSActive}
                handleChange={({ value }) => {
                  handleToggle("sms", value);
                }}
                label={
                  <span>
                    <TypographyComponent
                      variant="h6"
                    >{"SMS"}</TypographyComponent>

                  </span>
                }
              />
            </Tooltip>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };


  return (
    <Drawer
      className={`${classes.pe_drawer} ${"pe_drawer"}`}
      anchor='right'
      PaperProps={{
        style: {
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          height: getDrawerHeight()
        }
      }}
      open={drawer}
      onClose={toggleDrawer(false)}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={`${"pr-1 pl-1 borderBottom"} ${classes.DrawerDemographyTitle}`}
      >
        <Grid item>
          <Grid container alignItems="center" className="pt-1 pb5">
            <TypographyComponent variant={"h4"}
              className="txtMedium">
              {participantConstants.SEND_SURVEY}
              <InformationComponent>
                {participantConstants.SEND_SURVEY_HELP}
              </InformationComponent>
            </TypographyComponent>
          </Grid>
          {(firstName || lastName) &&
            <Grid className="pb5">
              <TypographyComponent variant={"h6"}
                className="ques_text_grey">
                {`for ${participant_name}`}
              </TypographyComponent>
            </Grid>
          }
        </Grid>
        <Grid item>
          <IconButton variant="contained" onClick={toggleDrawer(false)} size="large">
            <img src={closeicon} alt={"Close"} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={`${"scrolly display-block"}`}
      >

        {/* surveys to send notification */}
        {allSurveys && allSurveys.length ? <Grid item xs={12} className="p-1">
          {allSurveys.length > 1 &&
            <SearchInputComponent
              id="searchSurveyNotification"
              placeholder={"Search Survey"}
              value={search}
              field={"searchCategory"}
              showClearIcon={true}
              fullWidth={true}
              className={`${classes.demographySearch}`}
              timeOut={10}
              onInputChangeRequest={handleSearch}
              handleClearSearch={handleClearSearch}
            />
          }
          <Grid item xs={12} className={`${classes.surveyNotificationExpansion} ${"scrolly p5"}`}>
            {generateSurveytoSelect()}
          </Grid>
          {generateSwitchButton()}
        </Grid> :
          <Grid item className="p-1">
            <TypographyComponent variant={"h5"}>
              {participantConstants.NO_OPEN_SURVEY}
            </TypographyComponent>
          </Grid>
        }
      </Grid>

      {allSurveys && allSurveys.length > 0 && <Grid className={`${classes.sendMailButton} pb-1 pr5 pt-2`}>
        <ButtonComponent
          disabled={(selectedSurvey && (mail || sms)) ? false : true}
          title={"Send"}
          color={"primary"}
          size={"small"}
          pageClassName="mr-1"
          handleClick={(e) => {
            handleSendNotification();
          }}
        ></ButtonComponent>
        <ButtonComponent
          title={"Cancel"}
          color={"default"}
          size={"small"}
          pageClassName="mr-1"
          handleClick={toggleDrawer(false)}
        ></ButtonComponent>
      </Grid>}
    </Drawer>
  );
};

// default props
SendIndividualEmail.defaultProps = {
  classes: {},
  page: {},
  drawer: false,
  toggleDrawer: () => { },
  onDemographicChange: () => { },
  demographicFields: {
    isAll: false,
    demographic: []
  }
};

// prop types
SendIndividualEmail.propTypes = {
  classes: PropTypes.object,
  page: PropTypes.object,
  demographicFields: PropTypes.object,
  drawer: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  onDemographicChange: PropTypes.func
};

export default SendIndividualEmail;
