import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

// Import Styles and Image
import { SettingGrey, SettingOrange } from "../../../assets/images/svgComponents/ImageComponent";

import ReportsStyles from "../ReportsStyles";

function FilterIcon(props) {
  const { classes, onClick } = props
  const { preference } = useSelector(state => state.admin)
  const hasDemographicFilter = preference && preference.demographics &&
    Object.keys(preference.demographics).length > 0

  return (
    <React.Fragment>
      <Tooltip title={"Data Filter"} arrow >
        <Grid className={classes.IconSaveContainer}>
          <IconButton className={classes.IconSaveContainer} onClick={onClick} size="large">
            <Grid className={`mt5 ${classes.IconContainer}`}>
              {hasDemographicFilter ? SettingOrange() : SettingGrey()}
            </Grid>
          </IconButton>
        </Grid>
      </Tooltip>
    </React.Fragment>
  );
}
// default props
FilterIcon.defaultProps = {
  classes: {},
};

// prop types
FilterIcon.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(FilterIcon);
