import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All ME Suvery Service Functions
 */
export const meSurveyService = {
  search,
  subjectsandRaters,
  saveOrUpdate,
  getDemographics,
  getDemographicsItems,
  updateRaterChange,
  getRelationship,
  updateRelationship,
  deleteRelationship,
  gerProcessStatus,
  updateSeenStatus,
  noSaveCheck,
  hasResponse
};

/**
 * Call ME Survey Get All Search Service
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`me-survey/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

/**
 * Call ME Assign Subjects and Raters Get All Search Service
 */
 async function subjectsandRaters(skip, limit, sortBy, sort, sFields, sValues, formData) {
  const response = await apihelper.postRequest(`me-survey/assign/${formData.survey_id}/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, ...formData });
  return response;
}

/**
 * Call Save or Update
 */
 async function saveOrUpdate(formData) {
  const response = await apihelper.postRequest(`me-survey/save/${formData.survey_id}`, { ...formData });
  return response;
}

/**
 * Call no save checking
 */
 async function noSaveCheck(survey_id) {
  const response = await apihelper.postRequest(`me-survey/no-save/${survey_id}`, {});
  return response;
}

/**
 * Get Demographics
 */
 async function getDemographics(dataFilter) {
  const response = await apihelper.postRequest(`me-filter/demographicPreference`, { dataFilter });
  return response;
}

/**
 * Get Demographics Items
 */
 async function getDemographicsItems(formData) {
  const response = await apihelper.postRequest(`me-filter/demographic/name`, formData);
  return response;
}

/**
 * Update Rater changes
 */
 async function updateRaterChange(raterData, survey_id) {
  const response = await apihelper.postRequest(`me-survey/${survey_id}/rater`, { raterData });
  return response;
}

/**
 * Get relationships
 */
 async function getRelationship(survey_id) {
  const response = await apihelper.getRequest(`me-survey/relationship/${survey_id}`);
  return response;
}

/**
 * Update relationships
 */
 async function updateRelationship(survey_id, raterData) {
  const response = await apihelper.postRequest(`me-survey/relationship/${survey_id}`, { ...raterData });
  return response;
}


/**
 * Delete relationships
 */
 async function deleteRelationship(survey_id, _id) {
  const response = await apihelper.deleteRequest(`me-survey/relationship/${survey_id}/${_id}`);
  return response;
}

/**
 * getprocess
 */
 async function gerProcessStatus(survey_id) {
  const response = await apihelper.getRequest(`me-survey/process-status/${survey_id}`);
  return response;
}

/**
 * update seen status
 */
 async function updateSeenStatus(survey_id, process_id, updateFields) {
  const response = await apihelper.putRequest(`me-survey/process-status/${survey_id}/${process_id}`, { updateFields });
  return response;
}

/**
 * is rater has response
 */
 async function hasResponse(survey_id, raterData) {
  const response = await apihelper.postRequest(`me-survey/has-response/${survey_id}`, { raterData });
  return response;
}