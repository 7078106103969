
const ContainerStyle = (theme) => ({
  root: {
    background: "transparent",
    padding: 20,
    width: "100%",
    overflowY: "auto"
  }

});

export default ContainerStyle;
