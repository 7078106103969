// import { maxHeight } from "@mui/system";

const ClientStyles = () => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: "24px",
    margin: "8px auto"
  },
  paper: {
    maxWidth: 400,
    margin: "8px auto",
    padding: "16px"
  },
  imgStyle: {
    width: "100px",
    height: "100px",
    objectFit: "fill",
    borderRadius: "50%"
  },
  avatarRoot: {
    width: 22,
    height: 22,
    left: "80px"
  },
  Client_Profile: {
    width: "130px",
    height: "130px",
    borderRadius: "130px",
    objectFit: "scale-down",
    border: "10px solid #fff",
    position: "absolute",
    top: "-85px",
    // padding: '15px',
    background: "#f2f4f5",
    boxShadow: "0px -3px 5px -1px #d9dadb"
  },
  hr: {
    border: "1px solid #f5f7f8",
    margin: "20px 15px 16px 0px"
  },
  profile_editicon: {
    position: "absolute",
    left: "32px",
    top: "4px"
  },
  client_profileBadge:
  {
    position: "absolute",
    right: "50%",
    left: "50%",
    zIndex: "100"
  },
  page_title:
  {
    margin: "auto",
    maxWidth: "1140px",
    padding: "30px 20px 20px 20px"
  }

});

export default ClientStyles;
