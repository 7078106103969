import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import * as dropEffects from "./dropEffects";

// Import Actions
import { updateStateReducerAction } from "../../redux/actions";

// Import Redux Constant
import { dragdropConstant } from "../../redux/constants";
import { isArray } from "lodash";

const draggingStyle = {
  opacity: 0.25
};


const Drag = props => {

  // Set State
  const [isDragging, setIsDragging] = React.useState(false);

  // Define Dispatch
  const dispatch = useDispatch();

  const startDrag = ev => {
    dispatch(updateStateReducerAction.update(dragdropConstant.DRAG));
    setIsDragging(true);
    if (isArray(props.dataItem)) {
      let newval = props.dataItem.join("%/-+*/%");
      ev.dataTransfer.setData("drag-item", `question%/-+*/%${newval}`);
    }
    else {
      ev.dataTransfer.setData("drag-item", props.dataItem);
    }
    ev.dataTransfer.effectAllowed = props.dropEffect;
  };

  const dragEnd = () => {
    setIsDragging(false);
    dispatch(updateStateReducerAction.update(dragdropConstant.DROP));
  };

  return (
    <div style={isDragging ? draggingStyle : {}} draggable onDragStart={startDrag} onDragEnd={dragEnd}>
      {props.children}
    </div>
  );
};

Drag.propTypes = {
  dataItem: PropTypes.string.isRequired,
  dragImage: PropTypes.string,
  dropEffect: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

Drag.defaultProps = {
  dragImage: null,
  dropEffect: dropEffects.All,
  type: PropTypes.string
};

export default Drag;
