import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import CircularProgress from "@mui/material/CircularProgress";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../SurveyPreviewStyles";

//import constants
import { TakeSurveyConstants } from "../../../../config/constants";

// Import Logo
import SuccessIcon from "../../../../assets/images/svg/takesurvey_success.svg";
import { surveyPreviewAction } from "../../../../redux/actions";

//Import Component
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

function FeedBackComponent(props) {
  const { classes, match } = props;
  const reqUser = match.params.req_by;

  // Define Dispatch
  const dispatch = useDispatch();
  const surveyFeedBackMail = useSelector(
    (state) => state.surveyPreview.surveyFeedBackMail
  );
  const [feedMsg, setFeedMsg] = useState("");
  const [sendMsg, setSendMsg] = useState(false);

  function handleSendMail() {
    if (feedMsg) {
      setSendMsg(true);
    }
  }

  function handleFeedBackMsg(e) {
    const message = e.target.value;
    setFeedMsg(message);
  }

  useEffect(() => {
    if (sendMsg && feedMsg && reqUser) {
      dispatch(surveyPreviewAction.sendFeedBackMail(feedMsg, reqUser));
    }
  }, [sendMsg]);

  return <>
    <Grid container className={classes.thankYouRootFull}>
      <Grid item sm={12} className={classes.thankYouContainer}>
        <span className={classes.gridIcon}>
          <img
            src={SuccessIcon}
            onClick={() => {}}
            alt={TakeSurveyConstants.SURVEY_FEED_BACK_PAGE_MESSAGE}
          />
        </span>
        <span className={classes.textThankYou}>
          {TakeSurveyConstants.SURVEY_FEED_BACK_PAGE_MESSAGE}
        </span>
        <span className={classes.textDescription}>
          {TakeSurveyConstants.SURVEY_FEED_BACK_PAGE_DESCRIPTION}
        </span>
        <Grid container className="dflex justifycenter" alignItems="center">
          <Grid item md={8} xs={10}>
            <TextareaAutosize
              className={`${"width100 pe_textarea lineHeight20 scrolly"}`}
              name={"feedBack_Msg"}
              placeholder=""
              value={feedMsg}
              onChange={handleFeedBackMsg}
              minRows={2}
              maxRows={3}
            />
          </Grid>
          <Grid item md={12} xs={12} className="dflex justifycenter mt-1">
            <ButtonComponent
              title="Send Feedback"
              size="small"
              color="primary"
              pageClassName="mr-1"
              handleClick={handleSendMail}
              disabled={sendMsg}
            ></ButtonComponent>

            {sendMsg ? <CircularProgress /> : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </>;
}

// default props
FeedBackComponent.defaultProps = {
  classes: {},
};

// prop types
FeedBackComponent.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(FeedBackComponent);
