/* eslint-disable dot-notation */
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Typography from "@mui/material/Typography";

// Imports Styles
import TypographyComponentStyle from "./TypographyComponentStyle";

/**
 * Typography Component
 * @param {*} props
 */
function TypographyComponent (props) {
  const { classes, required, className, color, title, variant, children, align, extraProps , style } = props;
  const clsName = required === true ? `${className} ${classes["required"]}` : className;

  return (
    <Typography
      variant={variant}
      className={clsName}
      align={align}
      color={color}
      style = {style}
      {...extraProps}
    > {title}{children}</Typography>
  );
}

// default props
TypographyComponent.defaultProps = {
  classes: {},
  title: "",
  variant: "h2",
  className: "",
  children: "",
  color: "",
  align: "left",
  required: false,
  extraProps: {}
};

// prop types
TypographyComponent.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
  color: PropTypes.string,
  align: PropTypes.string,
  required: PropTypes.bool
};

/** Export Component */
export default withStyles(TypographyComponentStyle)(TypographyComponent);
