/* eslint-disable no-unused-vars */
/* eslint-disable no-return-assign */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable curly */
/* eslint-disable prefer-const */
/* eslint-disable spaced-comment */
import React, { useEffect } from "react";
import lodash from "lodash";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";

// Import Images
import settingsicon from "../../../assets/images/svg/settings_demographic.svg";
import closeicon from "../../../assets/images/svg/drawer_close.svg";

// Import Config Layout
import { getDrawerHeight } from "../../../config/layout";

//  Import Components
import SearchInputComponent from "../../../components/SearchInputCompoment/SearchInputComponent";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";

// Import Constants
import { participantConstants } from "../../../config/constants";

const ParticipantDemograpics = (props) => {
  // const defaultSelect = ['empId', 'firstName', 'lastName', 'email', 'phone', 'survey', 'status'];

  // props
  const { drawer, toggleDrawer, classes, onDemographicChange, page } = props;
  let { columnSettings } = page.fieldFilters;
  let excludeDemographics = participantConstants.EXCLUDED_AUTOMATED_DEMOGRAPHICS;

  const metadata = useSelector((state) => state.metadata.metadata);

  //Accordion
  const [expanded, setExpanded] = React.useState("default-panel");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [expanded2, setExpanded2] = React.useState("other-panel");
  const handlePanelChange2 = (panel) => (event, newExpanded) => {
    setExpanded2(newExpanded ? panel : false);
  };

  const [expanded3, setExpanded3] = React.useState("auto-panel");
  const handlePanelChange3 = (panel) => (event, newExpanded) => {
    setExpanded3(newExpanded ? panel : false);
  };

  const [init, setInit] = React.useState(true);
  const [searchKey, setSearchKey] = React.useState("");
  const [autoSearchKey, setAutoSearchKey] = React.useState("");
  const [demographics, setDemographics] = React.useState([]);
  const [autoDemographics, setAutoDemographics] = React.useState([]);

  /**
   * Set Demographic Fields
   */
  useEffect(() => {
    let demographic = [];
    columnSettings.filter(k => k.type === "demographic").map(d => metadata.map(data => {
      if (d.key === data.key)
        data.visible && demographic.push(d);
    }));
    const demographicLen = demographic;
    const selectedDemoLen = demographic.filter(k => (k.selected));
    let allDemoWithSelectAll = [{
      key: "selectAll",
      selected: demographicLen.length === selectedDemoLen.length,
      type: "custom",
      value: "Select All"
    }];

    if (searchKey && searchKey.length > 0) {
      setDemographics(demographic.filter(k => (k.value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)));
    } else {
      allDemoWithSelectAll.push(...demographic);
      demographicLen.length > 0 ? setDemographics(allDemoWithSelectAll) : setDemographics(demographic);
    }
  }, [columnSettings]);


  /**
 * Set Automated Demographic Fields
 */
  useEffect(() => {
    let demographic = [];
    columnSettings.filter(k => k.type === "auto" && !excludeDemographics.includes(k.key)).map(d => metadata.map(data => {
      if (d.key === data.key)
        data.visible && demographic.push(d);
    }));
    const demographicLen = demographic;
    const selectedDemoLen = demographic.filter(k => (k.selected));
    let allDemoWithSelectAll = [{
      key: "selectAll",
      selected: demographicLen.length === selectedDemoLen.length,
      type: "custom",
      value: "Select All"
    }];

    if (searchKey && searchKey.length > 0) {
      setAutoDemographics(demographic.filter(k => (k.value.toLowerCase().indexOf(searchKey.toLowerCase()) > -1)));
    } else {
      allDemoWithSelectAll.push(...demographic);
      demographicLen.length > 0 ? setAutoDemographics(allDemoWithSelectAll) : setAutoDemographics(demographic);
    }
  }, [columnSettings]);


  /**
   * Filter Change
   * @param {*} param0
   * @param {*} opt
   */
  const handleChange = ({ target }, opt) => {
    setInit(false);

    let demographicType = target.value;
    if (opt.key === "selectAll" && demographicType === "auto") {
      columnSettings.map((s) => s.selected = (s.type === "auto" && s.visible === true) ? target.checked : s.selected);
      onDemographicChange(columnSettings.filter(s => s.selected && s.type !== "custom" && s.type !== "system" && s.visible === true).map(s => s.key));
    }
    else if (opt.key === "selectAll" && demographicType === "demographic") {
      columnSettings.map((s) => s.selected = (s.type === "demographic" && s.visible === true) ? target.checked : s.selected);
      onDemographicChange(columnSettings.filter(s => s.selected && s.type !== "custom" && s.type !== "system" && s.visible === true).map(s => s.key));
    }
    else {
      columnSettings.map((s) => s.selected = (s.key === opt.key && s.visible === true) ? target.checked : s.selected);
      onDemographicChange(columnSettings.filter(s => s.selected && s.type !== "custom" && s.type !== "system" && s.visible === true).map(s => s.key));
    }
  };

  // Generate Default Columns
  const generateDefaultColumns = () => {
    const columnData = columnSettings.filter(k => k.type === "default");
    return columnData.map((opt) => {
      return <Grid item className={classes.demographyCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              name="isRequired"
              color="primary"
              disabled={opt.key === "empId" ? true : false}
              checked={opt.selected}
              onChange={e => handleChange(e, opt)}
            />
          }
          label={opt.value}
        />
      </Grid>;
    });
  };

  // Generate Auto Demographic Columns
  const generateAutoColumns = () => {
    let columnData = autoDemographics;
    return columnData.map((opt) => {
      if (!excludeDemographics.includes(opt.key)) {
        return <Grid item className={classes.demographyCheckbox}>
          <FormControlLabel
            control={
              <Checkbox
                name="isRequired"
                color="primary"
                disabled={opt.key === "empId" ? true : false}
                checked={opt.selected}
                onChange={e => handleChange(e, opt)}
                value='auto'
              />
            }
            label={opt.value}
          />
        </Grid>;
      }
    });
  };

  // Generate Demograpics Columns
  const generateDemographicColumns = () => {
    let orderedDemoGraphic = demographics;
    if (demographics.length > 1) {
      orderedDemoGraphic = [demographics[0], ...lodash.orderBy(demographics.slice(1, demographics.length), 'value', 'asc')];
    }
    return orderedDemoGraphic.map((opt) => {
      return <Grid item className={classes.demographyCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              name="isRequired"
              color="primary"
              checked={opt.selected}
              onChange={e => handleChange(e, opt)}
              value='demographic'
            />
          }
          label={opt.value}
        />
      </Grid>;
    });
  };

  /**
* Search Automated Demographics Metadata
*/
  const searchAutoDemographics = (field, value) => {
    setAutoSearchKey(value);

    const demographicLen = columnSettings.filter(k => k.type === "auto" && k.visible === true);
    const selectedDemoLen = columnSettings.filter(k => (k.type === "auto" && k.selected && k.visible === true));
    let allDemoWithSelectAll = [{
      key: "selectAll",
      selected: demographicLen.length === selectedDemoLen.length,
      type: "custom",
      value: "Select All"
    }];

    if (value && value.length > 0) {
      setAutoDemographics(columnSettings.filter(k => (k.type === "auto" && !excludeDemographics.includes(k.key) && k.visible === true) && k.value.toLowerCase().indexOf(value.toLowerCase()) > -1));
    } else {
      allDemoWithSelectAll.push(...columnSettings.filter(k => k.type === "auto" && !excludeDemographics.includes(k.key) && k.visible === true));
      demographicLen.length > 0 ? setAutoDemographics(allDemoWithSelectAll) : setAutoDemographics(demographicLen);
    }
  };

  /**
 * Search Demographics Metadata
 */
  const searchDemographics = (field, value) => {
    setSearchKey(value);

    const demographicLen = columnSettings.filter(k => k.type === "demographic" && k.visible === true);
    const selectedDemoLen = columnSettings.filter(k => (k.type === "demographic" && k.selected && k.visible === true));
    let allDemoWithSelectAll = [{
      key: "selectAll",
      selected: demographicLen.length === selectedDemoLen.length,
      type: "custom",
      value: "Select All"
    }];

    if (value && value.length > 0) {
      setDemographics(columnSettings.filter(k => (k.type === "demographic" && k.visible === true) && k.value.toLowerCase().indexOf(value.toLowerCase()) > -1));
    } else {
      allDemoWithSelectAll.push(...columnSettings.filter(k => k.type === "demographic" && k.visible === true));
      demographicLen.length > 0 ? setDemographics(allDemoWithSelectAll) : setDemographics(demographicLen);
    }
  };

  /**
   * Handle Clear all
   */
  const handleClear = (e, type) => {
    // ignore accordion
    e.stopPropagation();
    let removeDemoCols = [...columnSettings];
    removeDemoCols.map((s) => {
      if (s.type === type)
        s.selected = false;
    });

    onDemographicChange(removeDemoCols.filter(s => s.selected && s.type !== "custom" && s.type !== "system" && s.visible === true).map(s => s.key));
  };

  return (
    <Drawer
      className={`${classes.pe_drawer} ${"pe_drawer"}`}
      anchor='right'
      PaperProps={{
        style: {
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          height: getDrawerHeight()
        }
      }}
      open={drawer}
      onClose={toggleDrawer(false)}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={`${"pr-1 pl-1 borderBottom"} ${classes.DrawerDemographyTitle}`}
      >
        <Grid item>
          <Grid container alignItems="center">
            <IconButton variant="contained" size="large">
              <img src={settingsicon} alt={"settings"} />
            </IconButton>
            <TypographyComponent variant={"h4"}
              className="txtMedium">
              {participantConstants.SELECT_TITLE}
            </TypographyComponent>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton variant="contained" onClick={toggleDrawer(false)} size="large">
            <img src={closeicon} alt={"Close"} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={`${"scrolly display-block"}`}
      >
        {/* Default Demo Graphics */}
        <Grid item xs={12}>
          <Accordion
            classes={{
              root: classes.peaccordion_nocolor
            }}
            expanded={expanded === "default-panel"}
            onChange={handlePanelChange("default-panel")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <TypographyComponent variant={"h6"}
                className={`${"txtMedium "} ${expanded === "default-panel" ? "panel-active" : ""} `}>
                {participantConstants.SELECT_COLUMN_DEFAULT}
              </TypographyComponent>
            </AccordionSummary>

            <AccordionDetails>
              <Grid item xs={12} className={`${classes.expansionDetail} ${"scrolly"}`}>
                {generateDefaultColumns()}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Auto Demographics */}
        <Grid item xs={12}>
          <Accordion
            classes={{
              root: classes.peaccordion_nocolor
            }}
            expanded={expanded3 === "auto-panel"}
            onChange={handlePanelChange3("auto-panel")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Grid container justifyContent="space-between">
                <TypographyComponent variant={"h6"}
                  className={`${"txtMedium "} ${expanded3 === "auto-panel" ? "panel-active" : ""} `}>
                  {participantConstants.SELECT_COLUMN_AUTOMATED_DEMOGRAPHICS}
                </TypographyComponent>
                <Link
                  className='cursor-pointer colorTheme right rem875'
                  onClick={e => handleClear(e, "auto")}
                >
                  {participantConstants.SELECT_DEMOGRAPICS_CLEAR}
                </Link>
              </Grid>
            </AccordionSummary>

            <AccordionDetails>
              <Grid item xs={12} className={`${classes.expansionDetail} ${"scrolly"}`}>
                <SearchInputComponent
                  className="mt5 mb5"
                  fullWidth={true}
                  placeholder={participantConstants.SELECT_DEMOGRAPICS_SEARCH}
                  timeOut={100}
                  onInputChangeRequest={searchAutoDemographics}
                  value={autoSearchKey}
                  showClearIcon={true}
                  field={"search"}
                  handleClearSearch={() => searchAutoDemographics("")}
                />
                {generateAutoColumns()}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {/* Other Demographics */}
        <Grid item xs={12}>
          <Accordion
            classes={{
              root: classes.peaccordion_nocolor
            }}
            expanded={expanded2 === "other-panel"}
            onChange={handlePanelChange2("other-panel")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container justifyContent="space-between">
                <TypographyComponent variant={"h6"}
                  className={`${"txtMedium "}  ${expanded2 === "other-panel" ? "panel-active" : ""} `}>
                  {participantConstants.SELECT_COLUMN_OTHER_DEMOGRAPICS}
                </TypographyComponent>
                <Link
                  className='cursor-pointer colorTheme right rem875'
                  onClick={e => handleClear(e, "demographic")}
                >
                  {participantConstants.SELECT_DEMOGRAPICS_CLEAR}
                </Link>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item xs={12} >
                <SearchInputComponent
                  className="mt5 mb5"
                  fullWidth={true}
                  placeholder={participantConstants.SELECT_DEMOGRAPICS_SEARCH}
                  timeOut={100}
                  onInputChangeRequest={searchDemographics}
                  value={searchKey}
                  showClearIcon={true}
                  field={"search"}
                  handleClearSearch={() => searchDemographics("")}
                />
                {generateDemographicColumns()}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Drawer>
  );
};

// default props
ParticipantDemograpics.defaultProps = {
  classes: {},
  page: {},
  drawer: false,
  toggleDrawer: () => { },
  onDemographicChange: () => { },
  demographicFields: {
    isAll: false,
    demographic: []
  }
};

// prop types
ParticipantDemograpics.propTypes = {
  classes: PropTypes.object,
  page: PropTypes.object,
  demographicFields: PropTypes.object,
  drawer: PropTypes.bool,
  toggleDrawer: PropTypes.func,
  onDemographicChange: PropTypes.func
};

export default ParticipantDemograpics;
