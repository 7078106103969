/* eslint-disable quote-props */
/* eslint-disable dot-notation */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// import actions
// import { alertAction } from "../../../../redux/actions";

// import components
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../redux/actions";

// import images
import {Plus} from "../../../../assets/images/svgComponents/surveySVG";

function AddCategory (props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, questionData, addQuestions } = props;
  const { catOrder, questions, catDetails, languages } = questionData;
  const disableTabs = questions.filter(q => q.editable).length > 0 || Object.keys(addQuestions).length > 0;

  // define state
  const [newCategory, setNewCategory] = useState("");
  const [addCatPopup, setAddCatPopup] = useState(null);

  // Update reducer data
  const updateReducer = (catOrder, catDetails) => {
    const reducerData = { ...questionData, catOrder: catOrder, catDetails: catDetails };
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, reducerData));
    // move to new category
    if (!disableTabs && questionData.catOrder && questionData.catOrder.length - 1 >= 0) {
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_CATEGORY_ACTIVE_TAB, (questionData.catOrder.length - 1)));
    }
  };

  // Anchor tag events
  const handleAddClick = (event) => {
    setAddCatPopup(event.currentTarget);
  };

  // Handle New Category Popup Close Event
  const handleClose = () => {
    setNewCategory("");
    setAddCatPopup(null);
  };

  const open = Boolean(addCatPopup);
  const id = open ? "new-category-popover" : undefined;

  // Add new category
  const addNewCategory = (newCategory, catOrder) => {
    let isDuplicateCategory = false;
    catOrder.map((cat, ind) => {
      if (cat.toLowerCase() === newCategory.toLowerCase()) {
        isDuplicateCategory = true;
      }
    });
    if (newCategory && !isDuplicateCategory) {
      catOrder.push(newCategory);
      catDetails[newCategory] = {};
      for (let i = 0; i < languages.length; i++) {
        catDetails[newCategory][languages[i]["code"]] = {
          "name": languages[i]["code"] === surveyQuestionsConstants.DEFAULT_LANG_CODE ? newCategory : "",
          "desc": ""
        };
      }
      updateReducer(catOrder, catDetails);
      handleClose();
    } else if (!newCategory) {
      dispatch(alertAction.error(questionData.template==="Manager 360"?surveyQuestionsConstants.EMPTY_COMPETENCY_NAME:surveyQuestionsConstants.EMPTY_CATEGORY_NAME));
    } else {
      dispatch(alertAction.error(questionData.template==="Manager 360" ? surveyQuestionsConstants.COMPETENCY_EXISTS : surveyQuestionsConstants.CATEGORY_EXISTS));
    }
    setNewCategory("");
  };

  return (
    <React.Fragment>
      <Tooltip arrow title= {questionData.template==="Manager 360"?"Add competency":"Add category"}>
        <IconButton
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={handleAddClick}
          className={classes.AddCateBtn}
          size="large">
          <Grid className={"pt5"}>
          {Plus()}
          </Grid>

        </IconButton>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={addCatPopup}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <Grid className={`${classes.quest_addtab} ${"p15"}`}>
          <TextFieldComponent
            name="catname"
            value={newCategory}
            placeholder={questionData.template==="Manager 360"?"Enter competency name":"Enter category name"}
            margin={"dense"}
            autoComplete="off"
            size="small"
            maxLength={60}
            handleChange={(e) => {
              setNewCategory(e.value);
            }}
            className={"mb-1"}
            validators={["required"]}
          ></TextFieldComponent>
          <Button
            title={"Add"}
            size={"small"}
            variant="contained"
            color="primary"
            className={classes.add_catbtn}
            disableElevation
            onClick={() => {
              addNewCategory(newCategory.trim(), catOrder);
            }}
          >
            Add
          </Button>
          <Button
            title={"Cancel"}
            variant="contained"
            color="secondary"
            disableElevation
            size={"small"}
            className={`${"ml-1"} ${classes.add_catbtn}`}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </Grid>
      </Popover>
    </React.Fragment>
  );
}

// default props
AddCategory.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  addQuestions: {}
};

// prop types
AddCategory.propTypes = {
  classes: PropTypes.object,
  addQuestions: PropTypes.object,
  questionData: PropTypes.object
};

export default AddCategory;
