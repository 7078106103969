import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Import Components
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

// Import constants
import { userRoles, userAccess } from "../../config/pageAccess";

function HeaderNavigations (props) {
  // Get Props
  const { user, match } = props;
  const isContentLib = user.client_id.name === "pe Global"
  // Get Active Header
  const page = match.path;
 const style = page === "/surveys" ? { fontWeight: "bold"} : {}
 const Pstyle = page === "/manage_people" ? { fontWeight: "bold"} : {}
 const Rstyle = page === "/reports" ? { fontWeight: "bold"} : {}
 const Astyle = page === "/actions" ? { fontWeight: "bold"} : {}
 const Cstyle = page === "/spcaller" ? { fontWeight: "bold"} : {}
  return (
    <React.Fragment>
      {(user.role === userRoles.superadmin || user.page.indexOf(userAccess.surveys) >= 0) && !isContentLib &&
        <ButtonComponent
          title={"Surveys"}
          color={"default"}
          size={"large"}
          component={Link}
          to="/surveys"
          type={"button"}
          style={style}
          variant={"text"}
          pageClassName={`navLink ${page === "/surveys" ? "activeHeader" : ""}`}
          componentClassName={"header"}
        ></ButtonComponent>
      }

      { (user.role === userRoles.superadmin || user.page.indexOf(userAccess.people) >= 0) && !isContentLib &&
        <ButtonComponent
          title={"People"}
          color={"default"}
          size={"large"}
          component={Link}
          to="/manage_people"
          style={Pstyle}
          type={"button"}
          variant={"text"}
          pageClassName={`navLink ${page === "/manage_people" ? "activeHeader" : ""}`}
          componentClassName={"header"}
        ></ButtonComponent>
      }

      { (user.role === userRoles.superadmin || user.page.indexOf(userAccess.reports) >= 0) && !isContentLib &&
        <ButtonComponent
          title={"Reports"}
          color={"default"}
          size={"large"}
          component={Link}
          to="/reports"
          style={Rstyle}
          type={"button"}
          variant={"text"}
          componentClassName={"header"}
          pageClassName={`navLink ${page === "/reports" ? "activeHeader" : ""}`}
        ></ButtonComponent>
      }

      { (user.role === userRoles.superadmin || user.page.indexOf(userAccess.actions) >= 0) && !isContentLib &&
        <ButtonComponent
          title={"Action"}
          color={"default"}
          size={"large"}
          component={Link}
          style={Astyle}
          to="/actions"
          type={"button"}
          variant={"text"}
          componentClassName={`${"header"}`}
          pageClassName={`navLink ${page === "/actions" ? "activeHeader" : ""}`}
        ></ButtonComponent>
      }

      {(user.role === userRoles.superadmin || user.role === userRoles.calleradmin || user.role === userRoles.calleruser || user.page.indexOf(userAccess.spcaller) >= 0) && !isContentLib &&
        <ButtonComponent
          title={"Call Site"}
          color={"default"}
          size={"large"}
          component={Link}
          style={Cstyle}
          to="/spcaller"
          type={"button"}
          variant={"text"}
          pageClassName={`navLink ${page === "/spcaller" ? "activeHeader" : ""}`}
          componentClassName={`${"header"}`}
        ></ButtonComponent>
      }

      { user.role === userRoles.superadmin && isContentLib &&
        <ButtonComponent
          title={"Content Library"}
          color={"default"}
          size={"large"}
          component={Link}
          style={Astyle}
          to="/content-library"
          type={"button"}
          variant={"text"}
          componentClassName={`${"header"}`}
          pageClassName={`navLink ${page === "/content-library" ? "activeHeader" : ""}`}
        ></ButtonComponent>
      }
    </React.Fragment>
  );
}

// default props
HeaderNavigations.defaultProps = {
  classes: {},
  match: {},
  user: {}
};

// prop types
HeaderNavigations.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object
};

/**
 * Export Container
 */
export default HeaderNavigations;
