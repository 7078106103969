/* eslint-disable react/jsx-key */
/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";

// import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import AnsChoicesPopUp from "./AnsChoicePopUp";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

import { isTranslated, iseditedLangtxt } from "../../../../helpers";


function TranslateQuestions(props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, questionData, item, index, langCode, translatedFields, activeCategoryTab, errors } = props;
  const { questions, catOrder } = questionData;

  // has error
  let hasError = false
  if (errors && errors[langCode]) {
    errors[langCode].filter(f => {
      const cat_name = f.split("_")
      if (cat_name[0] === catOrder[activeCategoryTab] && cat_name[1] === item.baseQusId) {
        hasError = true
      }
    })
  }

  const [ansPopUp, setansPopUp] = useState(null);

  // Update reducer data
  const updateReducer = (questions, translateKey = "") => {
    const reducerData = { ...questionData, questions: questions };
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, reducerData));
  };

  // Update translated history
  const updateTranslateHistory = (translateKey = "") => {
    const translateEditedData = iseditedLangtxt(translatedFields, langCode, translateKey);
    dispatch(updateStateReducerAction.update(surveyConstant.EDIT_TRANSLATE_HISTORY_UPDATE_SUCCESS, translateEditedData));
  };

  // show Answer choices On  Clicking question Type
  const showAnsChoices = (event) => {
    setansPopUp(event.currentTarget);
  };

  // Update All Language Question Text
  const updateQuestionText = (event, translateKey = "") => {
    let { name, value } = event;
    let field = name.split("  ");
    let baseQusID = field[0];
    let updateFieldName = field[1];
    let language = field[2];
    questions.filter((question, index) => {
      if (questions[index].baseQusId === baseQusID) {
        questions[index][updateFieldName][language] = value;
      }
    });
    updateReducer(questions);
    updateTranslateHistory(translateKey)
  };

  // update matrix sub Question
  const updateSubQuestionText = (event, translateKey = "") => {
    let { name, value } = event;
    let field = name.split("  ");
    let baseQusID = field[0];
    let updateFieldName = field[1];
    let updateIndex = field[2];
    let language = field[3];
    questions.filter((question, index) => {
      if (questions[index].baseQusId === baseQusID) {
        questions[index][updateFieldName][updateIndex]["text"][language] = value;
      }
    });
    updateReducer(questions);
    updateTranslateHistory(translateKey)
  };

  return (
    <React.Fragment>
      <Grid container className={`${classes.quest_cateitem} ${"p10"}`} alignItems={"flex-start"}>
        <Grid item sm={10}>
          <Grid container alignItems={"flex-start"}>
            <Grid item className="dflex pr-15">
            </Grid>
            <Grid item className="pl-1 pr-2">
              <Badge
                color="secondary"
                className={`${classes.quest_listbadge} ${"badgeMedium"}`}
                badgeContent={index + 1}
              ></Badge>
            </Grid>
            <Grid item className={`width90 ${hasError ? "quest_text se_err" : ""}`}>
              <TypographyComponent
                className={`${((item.qusType !== "Open Ended") && ansPopUp !== null) ? "bgGrey" : ""} ${"fontSize13"}`}
                variant={"h6"}>
                {item.text.en}
              </TypographyComponent>
              <TextFieldComponent
                id={`questionText${index}`}
                placeholder={"Please enter translation text here"}
                type="string"
                name={`${item.baseQusId}  text  ${langCode}`}
                fullWidth={true}
                value={item.text[langCode]}
                handleChange={(e) => updateQuestionText(e, `${item.baseQusId}_question`)}
                className={`${"boderRadius2 bgGrey height40"} ${translatedFields && translatedFields.hasOwnProperty(langCode) ? (isTranslated(translatedFields, langCode, `${item.baseQusId}_question`) ? classes.translatedField : classes.editedtranslatedField) : ""} `}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={2}>
          <Grid container justifyContent={"flex-end"} alignItems={"flex-start"}>
            <Grid item>
              <TypographyComponent variant={"h6"}>
                <Link color="inherit" aria-describedby={"ans_set_popup"} onClick={showAnsChoices}>
                  {item.qusType}
                </Link>
              </TypographyComponent>
            </Grid>
          </Grid>
        </Grid>
        {item.qusType === "Matrix" &&
          <Grid item sm={12} className="pl-5">
            <Grid className="pl-1 mt-1">
              <TypographyComponent variant={"h6"} className={"fontSize13 txtBold pb5"}>Sub questions</TypographyComponent>
            </Grid>
            <Grid className={"pl-1"}>
              {item.subQues.map((key, ind) => (
                <React.Fragment>
                  <TypographyComponent variant={"h6"} className={"fontSize13"}>
                    {key["text"]["en"]}
                  </TypographyComponent>
                  <TextFieldComponent
                    id={`subQuestionText${ind}`}
                    className={`${"boderRadius2 bgGrey pad14"} ${translatedFields && translatedFields.hasOwnProperty(langCode) ? (isTranslated(translatedFields, langCode, `${item.baseQusId}_subQues_${key.id}`) ? classes.translatedField : classes.editedtranslatedField) : ""} `}
                    type="string"
                    name={`${item.baseQusId}  subQues  ${ind}  ${langCode}`}
                    fullWidth={true}
                    value={key["text"][langCode]}
                    handleChange={(e) => updateSubQuestionText(e, `${item.baseQusId}_subQues_${key.id}`)}
                  />
                </React.Fragment>
              ))}
            </Grid>
          </Grid>
        }
      </Grid>

      {item.qusType !== "Open Ended" &&
        <AnsChoicesPopUp classes={classes} questionIndex={index} anchorEl={ansPopUp} classes={classes} langCode={langCode} question={item} questionData={questionData} translatedFields={translatedFields} showAnsChoices={() => { setansPopUp(null); }} />
      }
    </React.Fragment>
  );
}

// default props
TranslateQuestions.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  activeCategoryTab: 0,
  item: {},
  index: 0
};

// prop types
TranslateQuestions.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  langCode: PropTypes.string,
  activeCategoryTab: PropTypes.number,
  item: PropTypes.object,
  index: PropTypes.number
};

export default TranslateQuestions;
