import React from "react";
import { useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";

import { surveySettingsConstants } from "../../../../config/constants";
import { getReducerUpdatedData } from "../../../..//helpers";
import { contentLibraryAction } from "../../../../redux/actions";


function Email(props) {
  const { classes, data, messageData, expanded } = props;

  const dispatch = useDispatch();


  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };

  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, data);
    props.editMessageContent(reducerData)
  };

  const saveMessageData = () => {
    delete data.updatedData;
    messageData.filter((msg, index) => {
      if (msg.type === data.type) {
        messageData[index] = data
      }
    })
    dispatch(contentLibraryAction.updateMessage(messageData));
  };

  const closeMessage = () => {
    props.handleCancelMessage()
  };


  return (
    <React.Fragment>
      <Accordion
        className="mb-2 pe-accordion"
        expanded={expanded === "email-panel"}
        onChange={handleTabChange("email-panel")}
      >

        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expandicon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyComponent
            className={` ${classes.setting_accordheading} ${expanded === "email-panel" ? "panel-active" : ""} `}
            variant={"h5"}
          >{"Email"}</TypographyComponent>
        </AccordionSummary>


        <AccordionDetails>
          <Grid container spacing={3} className={`${classes.setting_expandcontainer} `}>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid item md={10} xs={12}>
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.EMAIL_SUBJECT}
                  </TypographyComponent>
                  <TextFieldComponent id="email.subject"
                    name={`email.subject`}
                    size={"small"}
                    fullWidth={true}
                    variant="outlined"
                    margin="none"
                    value={data.email ? data.email.subject : ""}
                    handleChange={(e) => handleChange(e)}
                    className={`${classes.settings_input} ${"boderRadius2"} `}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={10} xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.EMAIL_MESSAGE}
              </TypographyComponent>
              <Grid className={classes.txtborder}>
                <Grid container alignItems="center" className={`bgwhite `}>
                  <Grid item sm={2}>
                    <TextFieldComponent
                      id="email.greetings"
                      name={`email.greetings`}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      value={data.email ? data.email.greetings : ""}
                      handleChange={(e) => handleChange(e)}
                      className={`${"br-0 br-none m-0"} ${classes.distri_emailmsg}  `} />
                  </Grid>
                  <Grid item sm={10}>
                    {surveySettingsConstants.GREETINGS_CONSTANT_NAME}
                  </Grid>
                </Grid>
                <TextareaAutosize
                  className={` width100 pe_textarea mb-m4  `}
                  name={`email.body`}
                  value={data.email ? data.email.body : ""}
                  onChange={(e) => handleTextChange(e)}
                  minRows={3} />
                <Grid container alignItems="center" className={`bgwhite`}>
                  <Grid item sm={6}>
                    <TextFieldComponent id="email.url"
                      name={`email.url`}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      value={data.email ? data.email.url : ""}
                      handleChange={(e) => handleChange(e)}
                      className={`${"br-0 fontSize13 br-none m-0"} ${classes.distri_emailmsg}`} />
                  </Grid>
                  <Grid item sm={6}>
                    {surveySettingsConstants.URL_CONSTANT_NAME}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={10} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  <ButtonComponent
                    title={"OK"}
                    color={"primary"}
                    size={"small"}
                    handleClick={() => saveMessageData()}
                    pageClassName={classes.settings_addrulebtn}
                  ></ButtonComponent>
                  <ButtonComponent
                    title={"Cancel"}
                    color={"secondary"}
                    size={"small"}
                    handleClick={() => closeMessage()}
                    pageClassName="ml-1"
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>

      </Accordion>

    </React.Fragment>
  );
}

export default Email;

