import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment"

// import component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import DatePickerComponent from "../../components/DatePickerComponent/CustomDatePicker";

const MetricsDialog = (props) => {

    const { classes, onDownload, onCancel, open, title } = props;
    const [startDateValue, setStartDate] = useState(null);
    const [endDateValue, setEndDate] = useState(null);

    /**
     * Date Validation
     */
    useEffect(() => {
        ValidatorForm.addValidationRule("isEnddategreater", (value) => {
            return moment(new Date(value)).diff(new Date(startDateValue), 'days') >= 0
        });
        ValidatorForm.addValidationRule("isNoFutureDate", (value) => {
            return new Date() > new Date(value)
        });
    }, [startDateValue, endDateValue]);

    // convert date
    const convertDate = (dateStr) => {
        const date = new Date(dateStr);
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
            dt = "0" + dt;
        }
        if (month < 10) {
            month = "0" + month;
        }
        const finaldate = `${month}/${dt}/${year}`;
        return finaldate === "01/01/1970" ? "" : finaldate;
    };

    const handleSubmit = () => {
        let startDate = convertDate(startDateValue)
        let endDate = convertDate(endDateValue)
        onDownload({ startDate, endDate });
        setStartDate(null);
        setEndDate(null);
    }

    return (
        <ValidatorForm
            id="metrics_download_form"
            name="metrics_download_form"
            useref="metrics_download_form"
            autoComplete="off"
            onSubmit={handleSubmit}
            onError={(errors) => console.log(errors)}
        >
            <Dialog
                open={open}
                onClose={onCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="p15"
            >
                <Grid className="p-15">
                    <Typography
                        variant="h5"
                        title={title}
                        style={{ fontWeight: "bold", marginBottom: "5px" }}
                    >
                        {title}
                    </Typography>
                    <DialogContent>
                        <Grid container xs={12} spacing={2}>
                            <Grid item xs={6}>
                                <TypographyComponent
                                    variant="h6"
                                >
                                    Select Start Date
                                </TypographyComponent>
                                <DatePickerComponent
                                    name="startDate"
                                    value={startDateValue || ""}
                                    className={classes.textField}
                                    handleChange={({ value }) => setStartDate(value)}
                                    inputVariant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth={true}
                                    validators={["required", "isNoFutureDate"]}
                                    errorMessages={["Start Date is required", "Date cannot be in the future"]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TypographyComponent
                                    variant="h6"
                                >
                                    Select End Date
                                </TypographyComponent>
                                <DatePickerComponent
                                    name="endDate"
                                    value={endDateValue || ""}
                                    className={classes.textField}
                                    handleChange={({ value }) => setEndDate(value)}
                                    inputVariant="outlined"
                                    margin="dense"
                                    size="small"
                                    fullWidth={true}
                                    validators={["required", "isEnddategreater", "isNoFutureDate"]}
                                    errorMessages={["End Date is required", "End Date must be greater", "Date cannot be in the future"]}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent
                            title={"Download"}
                            color={"primary"}
                            size={"small"}
                            type="submit"
                            form="metrics_download_form"
                            pageClassName="mr-1"
                        />
                        <Button
                            onClick={onCancel}
                            variant="contained"
                            color="secondary"
                            size="small"
                            disableElevation
                            autoFocus
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Grid>
            </Dialog >
        </ValidatorForm >
    );
};

// default props
MetricsDialog.defaultProps = {
    classes: {},
    onDownload: () => { },
    onCancel: () => { },
    title: "",
    open: false
};

// prop types
MetricsDialog.propTypes = {
    classes: PropTypes.object,
    onDownload: PropTypes.func,
    onCancel: PropTypes.func
};

export default MetricsDialog;
