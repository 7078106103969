import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from '@mui/material/IconButton'
import Drawer from '@mui/material/Drawer';
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import _ from "lodash"

// Import Styles
import FilterStyles from "./FilterStyles";

// Import Config Layout
import { getDrawerHeight } from "../../config/layout";

// Import Styles and Image
import { DemographicSetting, DemographicSettingOrange, SettingGrey, SettingOrange, DrawerClose } from "../../assets/images/svgComponents/ImageComponent"

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from '../../components/ButtonComponent/ButtonComponent'

// Import Support Containsers
import SurveyFilterPanel from './FilterPanels/SurveyFilterPanel'
import DateFilterPanel from './FilterPanels/DateFilterPanel'
import DemographicFilterPanel from './FilterPanels/DemographicFilterPanel'

// Import Constants
import { FilterConstants } from '../../config/constants';

//Import Actions
import { updateStateReducerAction, adminsAction, alertAction, dashboardAction, filterAction } from '../../redux/actions'

// get init state to clear
import { initialState as preferenceInitState } from '../../redux/reducers/filterReducer';
import { topicSelectedInitState } from '../../redux/reducers/dashboardReducer';

// Import Reducer Constant
import { filterConstant, dashboardConstant } from '../../redux/constants';

function Filter(props) {

    const filterForm = useRef(null);

    //Get props
    const { classes, filterSaveEvent, className, needIcon } = props;

    //Set default drawer state
    const [drawer, setDrawer] = useState(false);

    // Define Dispatch
    const dispatch = useDispatch();

    /**
    * Get User Preference
    */
    let { preference, open } = useSelector(state => state.admin)

    const { queryDLA } = useSelector(state => state.profile.user)

    const { dashboard: { currentTab }, filter: { allSurveys } } = useSelector(state => state)
    let { dashboardData: { hasMESurvey }, hotspot, highlevelSummary, isDataFiltersApplied } = useSelector(state => state.dashboard)
    preference = { ...preference }
    const { surveys, reportVisibility } = preference

    /**
     * Get Filter Data
     */
    const { filterData } = useSelector(state => state.filter)
    const { commentinitialReload, hlsinitialReload, yesNoinitialReload, gridinitialReload, hotspotinitialReload, totinitialReload, EIinitialReload } = useSelector((state) => state.dashboard.initialReload);

    const hasPulse = surveys.filter((val) => (val.frequency === "Pulse")) || []

    useEffect(() => {
        if (allSurveys.length === 0) {
            dispatch(filterAction.getAllSurveys(() => { }, false));
        }
    }, [dispatch]);

    // Clear Filter 
    const handleClear = (e) => {
        preferenceInitState.filterData.surveys = []
        e.stopPropagation();
        dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, {
            ...preferenceInitState.filterData
        }))
    }

    // Check if date is gooddate
    const validateDateFormat = (date) => {
        if (date == "Invalid date" || date == "NaN/NaN/NaN") {
            return false
        }
        let value = moment(date).format("MM/DD/YYYY");
        var reGoodDate = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
        return reGoodDate.test(value);
    }

    // const executeTasks = (index, tasks) => {
    //     if (index + 1 < tasks.length) {
    //         var task = tasks[index];
    //         dispatch(task());
    //         executeTasks(index + 1, tasks)
    //     }
    // }

    const route = window.location.hash

    const executeTasks = (index, tasks) => {
        var task = tasks[index];
        dispatch(task(() => {
            if (index + 1 < tasks.length) {
                executeTasks(index + 1, tasks)
            }
        }));
    }

    const matchSurveyIds = (arrOfObj1, arrOfObj2) => {
        if (JSON.stringify(arrOfObj1) === '[{}]' || JSON.stringify(arrOfObj2) === '[{}]') {
            return false;
        }

        const arrayOfIds1CheckType = arrOfObj1 && Array.isArray(arrOfObj1) && arrOfObj1.length > 0 && typeof arrOfObj1?.[0] === 'string' ? 'str' : 'obj';
        const arrayOfIds2CheckType = arrOfObj2 && Array.isArray(arrOfObj2) && arrOfObj2.length > 0 && typeof arrOfObj2?.[0] === 'string' ? 'str' : 'obj';

        const arrayOfIds1 = (arrayOfIds1CheckType === 'obj' ? _.map(arrOfObj1, '_id') : arrOfObj1) || [];
        const arrayOfIds2 = (arrayOfIds2CheckType === 'obj' ? _.map(arrOfObj2, '_id') : arrOfObj2) || [];

        if (arrayOfIds1.length === 0 || arrayOfIds2.length === 0) {
            return false;
        }

        if (arrayOfIds1.length !== arrayOfIds2.length) {
            return true;
        }

        return !_.isEqual(_.orderBy(arrayOfIds1), _.orderBy(arrayOfIds2));
    }

    /**
     * Save Report Preferences
     */
    const saveReportPreferences = () => {
        const isSurveysChanged = matchSurveyIds((filterData?.['surveys'] || [{}]), (filterData?.['surveysCpy'] || [{}]));
        const isFiltersAppliedExists = (
            (highlevelSummary?.['filtersApplied'] && _.isObject(highlevelSummary?.['filtersApplied']) && Object.keys(highlevelSummary?.['filtersApplied']).length > 0) ||
            (hotspot?.['filtersApplied'] && _.isObject(hotspot?.['filtersApplied']) && Object.keys(hotspot?.['filtersApplied']).length > 0)
        )

        const dashboardInitialReloadParam = isSurveysChanged && isDataFiltersApplied ? 'all' : 'retainQuestionFilters';
        dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, 'all', () => {
            dispatch(updateStateReducerAction.update(dashboardConstant.RESET_COMMENT_CHART))
        }))

        if (!commentinitialReload && !hlsinitialReload && !yesNoinitialReload && !gridinitialReload && !hotspotinitialReload && !totinitialReload && !EIinitialReload) {
            dispatch(dashboardAction.resetChartData(dashboardInitialReloadParam))
        }

        let startDate = filterData.date.startDate
        let endDate = filterData.date.endDate

        let surveySelected = filterData.surveys.map(e => e._id)
        if (startDate === null || endDate === null) {
            dispatch(alertAction.error(FilterConstants.DATE_REQUIRED));
        }
        else if (!validateDateFormat(startDate) || !validateDateFormat(endDate)) {
            dispatch(alertAction.error(FilterConstants.INVALID_DATE_FORMAT));
        }
        else if (surveySelected.length == 0) {
            dispatch(alertAction.error(FilterConstants.SELECT_SURVEY));
        }
        else {
            const oldSurveys = preference.surveysCpy.map(m => m._id || m)
            const diff = _.difference(surveySelected, oldSurveys).length > 0 || surveySelected.length !== oldSurveys.length
            let totObj = {}
            let hlsPreference = preference?.['hls'] || {};
            let hotspotPreference = preference?.['hotspot'] || {};
            if (diff) {
                totObj = {
                    tot: {
                        surveys: []
                    }
                }
            }

            if (isSurveysChanged && filterData.surveys.length > 1) {
                if (preference?.['hls']?.['type'] === "keyDrivers") {
                    hlsPreference['type'] = 'question';
                }

                if (preference?.['hotspot']?.['type'] === "keyDriver") {
                    hotspotPreference['type'] = 'question';
                }
            }

            //saving datefilter as string
            // filterData.date["startDateString"] = filterData.date.startDate.split("T")[0] || filterData.date.startDate
            // filterData.date["endDateString"] = filterData.date.endDate.split("T")[0] || filterData.date.startDate

            const data = {
                ...preference,
                surveys: surveySelected,
                surveysCpy: surveySelected,
                date: filterData.date,
                demographics: filterData.demographics,
                reportVisibilty: filterData.reportVisibilty,
                ...totObj,
                hls: {
                    ...hlsPreference
                },
                hotspot: {
                    ...hotspotPreference
                }
            }

            setDrawer(false);
            if (!needIcon) {
                dispatch(adminsAction.toggleFilterDrawer())
            }
            setExpanded('survey-panel')

            const tasks = []

            dispatch(adminsAction.updateReportPreference(data, () => {
                dispatch(dashboardAction.resetDataFiltersAppliedFlag());
                if (currentTab !== "reports") {
                    if (currentTab === 'comment') {
                        dispatch(dashboardAction.gethighCommentQuestions())
                    } else {
                        if (reportVisibility[0]) {
                            tasks.push(dashboardAction.getHighLevelSummaryData)
                            tasks.push(dashboardAction.getHistoricalData)
                        }

                        if ((reportVisibility[1] || reportVisibility[4]) && (!queryDLA || queryDLA === "")) {
                            dispatch(dashboardAction.getKeyDriverData(() => { }, false))
                        }


                        // Get Engagement Index
                        if (reportVisibility[5]) {
                            dispatch(dashboardAction.EIOverall())
                        }

                        if (reportVisibility[2]) {
                            tasks.push(dashboardAction.getYesNoData)
                        }

                        // Get Grid Chart Data
                        if (reportVisibility[3] && hasPulse.length === 0) {
                            tasks.push(dashboardAction.getGridChartData)
                        }

                        //Get Hotspot Data
                        if (reportVisibility[4]) {
                            dispatch(dashboardAction.getHotspotData())
                        }

                        if (reportVisibility[6]) {
                            dispatch(dashboardAction.getTrendsOverTimeData())
                        }
                        if (tasks.length > 0) {
                            executeTasks(0, tasks)
                        }
                    }
                }
                filterSaveEvent(data)
            }, false))

            // reset comment analysis NLP
            dispatch(dashboardAction.updateNLPSelected({ ...topicSelectedInitState }));

            // // reset All chart Datas
            // dispatch(dashboardAction.updateChartData());

        }
    }

    // Demographics Filter Accordion
    const [expanded, setExpanded] = useState('survey-panel');
    const handlePanel = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
        // update expansion panel validate
        // to show validation message when panel opens
        if (filterForm && filterForm.current) {
            filterForm.current.isFormValid(false)
        }
    };

    /**
     * 
     */
    const closeDrawer = () => {
        setDrawer(false)
        setExpanded('survey-panel')
        dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, preference))
        if (!needIcon) {
            dispatch(adminsAction.toggleFilterDrawer())
        }
    }

    // Get Height
    const drawerHeight = getDrawerHeight();
    const panelHeight = drawerHeight

    return (
        <Grid item >

            {
                needIcon && (
                    <Grid className={classes.IconContainer}  style={{ position: route == "#/reports" ? "absolute" : "", right: hasMESurvey ? "-14px" : "15px" , top: "2px" }}>
                        <Tooltip title={"Data filters"} arrow >
                            <IconButton
                                style={{ width: "25px", height: "40px" }}
                                onClick={() => { setDrawer(true) }}
                                className={`${className}`}
                                size="large">
                                {preference && preference.demographics && Object.keys(preference.demographics).length > 0 ?
                                    <Grid className={classes.IconContainer}>{SettingOrange()}</Grid>
                                    :
                                    <Grid className={classes.IconContainer}>{SettingGrey()}</Grid>
                                }

                            </IconButton>
                        </Tooltip>
                    </Grid>
                )
            }

            <Drawer className={`${classes.drawer} ${"pe_drawer"}`}
                anchor='right'
                variant="temporary"
                open={needIcon ? drawer : open}
                onClose={() => { closeDrawer() }}
                PaperProps={{
                    style: {
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        height: drawerHeight
                    }
                }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={`${"pr-1 pl-1 borderBottom"} ${classes.filterTitle}`}>
                    <Grid item>
                        <Grid container alignItems="center">
                            <IconButton className={"p12"} variant="contained" size="large">
                                {preference && preference.demographics && Object.keys(preference.demographics).length > 0 ?
                                    <Grid className={classes.IconContainer}>{DemographicSettingOrange()}</Grid>
                                    :
                                    <Grid className={classes.IconContainer}>{DemographicSetting()}</Grid>
                                }
                            </IconButton>
                            <TypographyComponent variant={"h4"}
                                className="txtMedium mb5">
                                {FilterConstants.TITLE}
                            </TypographyComponent>
                        </Grid>
                    </Grid>
                    <Grid item className={"pr5"}>
                        <Link
                            className={`cursor-pointer colorTheme right ${classes.dataFiltersClearAll}`}
                            onClick={e => handleClear(e)}
                        >
                            {FilterConstants.CLEAR_ALL}
                        </Link>
                        <IconButton variant="contained" onClick={() => { closeDrawer() }} size="large">
                            {/* <img src={CloseIcon} alt={"Close"} /> */}
                            {DrawerClose()}
                        </IconButton>
                    </Grid>
                </Grid>

                <div style={{ height: drawerHeight - 107 }} className={'scrolly'}>
                    <ValidatorForm
                        id="datafilterform"
                        name="datafilterform"
                        useref="datafilterform"
                        autoComplete="off"
                        ref={filterForm}
                        onSubmit={() => saveReportPreferences()}
                        onError={(errors) => console.log(errors)}
                    >
                        {/* Survey Filter Panel */}
                        <SurveyFilterPanel preference={preference} panelHeight={panelHeight} parentExpanded={expanded} handlePanel={handlePanel}></SurveyFilterPanel>

                        {/* Date Filter Panel */}
                        <DateFilterPanel preference={preference} panelHeight={panelHeight} parentExpanded={expanded} handlePanel={handlePanel}></DateFilterPanel>

                        {/* Demographics Filter Panel */}
                        <DemographicFilterPanel preference={preference} panelHeight={panelHeight} parentExpanded={expanded} handlePanel={handlePanel}></DemographicFilterPanel>

                        {/* Submit Form */}
                        <div className={`p-1 ${classes.saveFilterBtn}`}>
                            <ButtonComponent
                                title='Apply'
                                color='primary'
                                type="submit"
                                form="datafilterform"
                            />
                        </div>
                    </ValidatorForm>
                </div>
            </Drawer>
        </Grid>
    );
}

// default props
Filter.defaultProps = {
    filterSaveEvent: () => { },
    needIcon: true
};

// prop types
Filter.propTypes = {
    filterSaveEvent: PropTypes.func,
    needIcon: PropTypes.bool
};

export default withStyles(FilterStyles)(Filter);