import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import PropTypes from 'prop-types';
import "./index.css";

/**
 * Please check out the readme.md file for the detailed info on the params
 */
function TextEditor({
    id,
    data,
    readOnly,
    editorConfig,
    onReady,
    onChange,
    onBlur,
    onFocus,
    onError,
    autoGrow,
    height,
    placeholder
}) {

    ClassicEditor.defaultConfig = {
        toolbar: {
            items: [
                'heading',
                'bold',
                'italic',
                'underline',
                'outdent',
                'alignment',
                'indent',
                'bulletedList',
                'numberedList',
                'link'
            ]
        },
        link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'http://',
        },
        language: 'en'
    };


    const onEditorReady = (editor) => {
        // if (!autoGrow && editor) {
        //     editor.editing.view.change((writer) => {
        //         writer.setStyle(
        //             "height",
        //             height,
        //             editor.editing.view.document.getRoot()
        //         );
        //     });
        // }
    }

    return (
        <div className={data ? "content" : "no-content"} style={{ position: "relative" }}>
            <div className="custom-cke-placeholder ck-editor__editable_inline" dangerouslySetInnerHTML={{ __html: placeholder }} />
            <CKEditor
                key={id}
                id={id}
                config={{ placeholder: "Placeholder text..." }}
                editor={ClassicEditor}
                data={data}
                onReady={onReady !== null ? onReady : onEditorReady}
                onChange={(e, editor) => {
                    // console.log(editor.getData())
                    onChange(e, editor)
                }}
                onBlur={onBlur}
                onFocus={onFocus}
                config={editorConfig}
                isReadOnly={readOnly}
                onError={onError}
            />
        </div>
    )
}

TextEditor.propTypes = {
    id: PropTypes.string,
    data: PropTypes.string,
    readOnly: PropTypes.bool,
    editorConfig: PropTypes.object,
    onReady: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onError: PropTypes.func,
    overrideCssVarList: PropTypes.array,
    autoGrow: PropTypes.bool,
    height: PropTypes.string
};

TextEditor.defaultProps = {
    editorConfig: {},
    autoGrow: false,
    height: "400px",
    imageUploadHeaders: [],
    overrideCssVarList: [{
        variable: '--ck-color-toolbar-background',
        value: '#ffffff'
    }],
    readOnly: false,
    onChange: (event, editor) => {
        const data = editor.getData();
        // console.log({ event, editor, data });
    },
    onBlur: (event, editor) => {
        // console.log('Blur.', editor);
    },
    onFocus: (event, editor) => {
        // console.log('Focus.', editor);
    },
    onReady: null,
    placeholder: ""
}

export default TextEditor;