import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

// To Suport IE 11
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

// Import App File
import App from "./containers/App";

// import material UI
// import MomentUtils from "@date-io/moment";
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { MuiPickersUtilsProvider } from "@material-ui/pickers";

// Import Css Files
import "./mediaquery.css";
import "./index.css";
// import 'react-datepicker/dist/react-datepicker.css';

// Import History and Service Worker Files
import history from "./config/history";
import * as serviceWorker from "./serviceWorker";

// Define Redux Store
import configureStore from "./redux/store";
export const store = configureStore();

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Provider store={store}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </LocalizationProvider>,
  document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
