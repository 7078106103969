import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

//Components
import TotChart from "../../../../components/Charts/TrendsOverTimeChart/totChart";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

function totContent(props) {
  const { totdata, filterData } = props;

  const { alldata, pulseData } = totdata;

  return (
    <Grid item xs={12}>
      {alldata && alldata.length > 0 && (
        <TotChart
          data={alldata}
          pulseData={pulseData}
          params={filterData}
          chartId="insightTotChart"
        />
      )}
      {(!alldata || alldata.length === 0) && (
        <Grid container justifyContent="center" alignItems="center" className="mt-9">
          <TypographyComponent variant="h3">
            {ReportsConstants.NO_DATA_CHART}
          </TypographyComponent>
        </Grid>
      )}
    </Grid>
  );
}

// default props
totContent.defaultProps = {
  classes: {},
  totdata: {},
  filterData: {},
};

// prop types
totContent.propTypes = {
  classes: PropTypes.object,
  totdata: PropTypes.object,
  filterData: PropTypes.object,
};
export default totContent;
