import React, { useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import ReportsStyles from "../../ReportsStyles";
import Button from "@mui/material/Button";

//import images
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

//import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import HotspotTable from "./hotspotTable";

const HotspotPopup = (props) => {
  //Get props
  const { classes, zoom, onClose, updateSelectedType, selectedType, keydriver, applyFilters, filtersApplied } = props;

  //table delayed loading in popup
  const [delayLoading, setDelayLoading] = useState(false);
  setTimeout(() => {
    setDelayLoading(true);
  }, 1000);

  return (
    <React.Fragment>
      <Grid container direction="row" justifyContent="center" alignItems="stretch" className="pt-1 pl-15 pr-15">
        <Grid xs={12} className={"dflex"}>
          <Grid item xs={6}>
            <TypographyComponent variant="h4" className="txtBold fontSize16  ml-1 mt-2">
              {"HotSpot"}
            </TypographyComponent>
          </Grid>
          <Grid item xs={6} className={`${classes.dialog_close}`}>
            <Button variant="text" size={"small"} onClick={onClose} className="mt-2">
              {DashboardClose()}
              <TypographyComponent variant="h5" className="pl5">
                {"Close"}
              </TypographyComponent>
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {delayLoading && (
            <HotspotTable
              classes={classes}
              zoom={zoom}
              updateSelectedType={updateSelectedType}
              selectedType={selectedType}
              keydriver={keydriver}
              applyFilters={applyFilters}
              filtersApplied={filtersApplied}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
// default props
HotspotPopup.defaultProps = {
  classes: {},
  zoom: false,
  onClose: () => { },
  updateSelectedType: () => { },
  selectedType: "question",
  applyFilters: () => {},
  filtersApplied: {},
};

// prop types
HotspotPopup.propTypes = {
  classes: PropTypes.object,
  zoom: PropTypes.bool,
  onClose: PropTypes.func,
  updateSelectedType: PropTypes.func,
  selectedType: PropTypes.string,
  applyFilters: PropTypes.func,
  filtersApplied: PropTypes.object,
};
export default withStyles(ReportsStyles)(HotspotPopup);
