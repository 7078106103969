import React, { useState } from "react";
import "./StackedBarChartStyles.css";
import StackedBarChart from "./StackedBarChart";
import PropTypes from "prop-types";

// Import Loadsh
import _ from "lodash";

function StackedBar(props) {
  const {
    chartData,
    zoomMode,
    groupArr,
    rangeArr,
    chartHeight,
    chartWidth,
    padding,
    chartId,
    className,
    keyarrChange,
    keyArr,
    quesId,
  } = props;
  const chartDataCopy = JSON.parse(JSON.stringify(chartData));
  let totalData = [];
  if (!zoomMode) {
    totalData =
      chartDataCopy.length > 7 ? chartDataCopy.splice(-7) : chartDataCopy;
  } else {
    totalData = chartDataCopy;
  }

  const [keys, setKeys] = useState(keyArr);
  return (
    <React.Fragment>
      <StackedBarChart
        className={className}
        chartId={chartId}
        data={totalData}
        keys={keyArr}
        colors={rangeArr}
        chartHeight={chartHeight}
        chartWidth={chartWidth}
        zoomMode={zoomMode}
        datLength={chartDataCopy.length}
        padding={padding}
      />

      <div id="barcontainer" className="stackBarFields">
        {groupArr.map((key) => {
          const u_key = `${quesId}-${key}`
          return (
            <div key={u_key} className="stackBarField">
              <input
                id={u_key}
                type="checkbox"
                style={{ display: "none" }}
                checked={keys.includes(key)}
                onChange={(e) => {
                  if (e.target.checked) {
                    let sKeys = [...keys, key];
                    setKeys(groupArr.filter((value) => sKeys.includes(value)));
                    keyarrChange(
                      groupArr.filter((value) => sKeys.includes(value)),
                      quesId
                    );
                  } else {
                    setKeys(keys.filter((_key) => _key !== key));
                    keyarrChange(
                      keys.filter((_key) => _key !== key),
                      quesId
                    );
                  }
                }}
              />
              <label
                className={"legendContainer"}
                htmlFor={u_key}
                style={{
                  cursor: "pointer",
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                  opacity: keyArr.indexOf(key) >= 0 ? "1" : "0.5",
                }}
              >
                <span
                  className={"colorLegend"}
                  style={{ background: `${rangeArr[key]}` }}
                ></span>
                <span style={{ paddingLeft: "10px", fontSize: "13px" }}>
                  {key}
                </span>
              </label>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

// default props
StackedBar.defaultProps = {
  chartData: [],
  chartId: "stackedBarChart",
  zoomMode: false,
  groupArr: [],
  rangeArr: [],
  chartWidth: 800,
  chartHeight: 300,
  padding: 0.2,
  keyarrChange: () => {},
  quesId: "",
  keyArr: [],
};
// prop types
StackedBar.propTypes = {
  chartData: PropTypes.array,
  chartId: PropTypes.string,
  zoomMode: PropTypes.bool,
  groupArr: PropTypes.array,
  rangeArr: PropTypes.array,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  padding: PropTypes.number,
  keyarrChange: PropTypes.func,
  keyArr: PropTypes.array,
  quesId: PropTypes.string,
};
export default StackedBar;