import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "./SurveyPreviewStyles";

import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

//import Constants
import { TakeSurveyConstants } from "../../../config/constants";

// Import Actions
import { surveyPreviewAction } from "../../../redux/actions";

function SurveyPreview(props) {
  const { classes } = props;
  // Define Dispatch
  const dispatch = useDispatch();
  const surveyId = useSelector((state) => state.survey.previewSurveyId);
  const previewConfText = TakeSurveyConstants.PREVIEW_CONFIRM_TEXT;

  function handleConfirm(surveyId) {
    dispatch(surveyPreviewAction.updateSurveyPreviewList(surveyId));
  }

  return (
    <>
      <Grid container className={classes.previewRoot}>
        <Grid item sm={12} className={classes.previewBox}>
          <Box
            display="flex"
            p={0}
            flexWrap="nowrap"
            flexDirection="column"
            justifyContent="center"
            className={classes.confirmBox}
          >
            <Typography variant={"h2"} className="title">
              {TakeSurveyConstants.PREVIEW_CONFIRM_TITLE}
            </Typography>

            {previewConfText.map((text, key) => (
              <Typography paragraph={true} key={key}>
                {text}
              </Typography>
            ))}

            <Box justifyContent="space-between">
              <ButtonComponent
                title={TakeSurveyConstants.PREVIEW_CONFIRM_BUTTON}
                color={"primary"}
                size={"small"}
                handleClick={(e) => {
                  handleConfirm(surveyId);
                }}
              ></ButtonComponent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

// default props
SurveyPreview.defaultProps = {
  classes: {},
};

// prop types
SurveyPreview.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(SurveyPreview);
