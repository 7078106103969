/* eslint-disable dot-notation */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";

//image
import { Plus } from "../../../../../assets/images/svgComponents/ImageComponent";

// Import constants
import { excelConstants } from "../../../../../config/constants";

// Import Actions
import { metadataAction, alertAction } from "../../../../../redux/actions";

// import helper
import { conversionFieldValidation } from "../../../../../helpers/ConversionValidation";
import { Tooltip } from "@mui/material";

const AddConversion = (props) => {
  const dispatch = useDispatch();

  // get Props
  const { classes, metadataSelected, metadata } = props;

  const defaultData = {
    from: "",
    to: metadataSelected["key"],
    match: [],
    value: "",
    operator: ""
  };

  // add a new conversion rows if validation passes
  const addConversionItem = () => {
    metadataSelected["conversion"] = metadataSelected["conversion"] ? metadataSelected["conversion"] : [];
    const { conditionValidation } = conversionFieldValidation(metadataSelected, metadata);
    
    if (conditionValidation) {
      dispatch(alertAction.error("All fields are required for conversion"));
    } else {
      metadataSelected["conversion"].push(defaultData);
      dispatch(metadataAction.selectedMetadata(metadataSelected));
    }
  };

  return (
    <React.Fragment>
      <Tooltip arrow title={excelConstants.ADD_CONVERSION}>
      <IconButton
        className={classes.demographyDialogBtn}
        onClick={() => { addConversionItem(); }}
        size="large">
        {Plus()}
      </IconButton>
      </Tooltip>
    </React.Fragment>
  );
};

AddConversion.defaultProps = {
  classes: {},
  metadataSelected: {},
  metadata: []
};

AddConversion.propTypes = {
  classes: PropTypes.object,
  metadataSelected: PropTypes.object,
  metadata: PropTypes.array
};

export default AddConversion;
