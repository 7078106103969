import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Profile Service Functions
 */
export const profileService = {
  getOne,
  user,
  update,
  updatePreference
};

/**
 * Get logged in user details
 */
async function getOne () {
  const response = await apihelper.getRequest("profile");
  return response;
}

/**
 * Get logged user details
 */
async function user() {
  const response = await apihelper.getRequest("profile/unique");
  return response;
}

/**
 * Get redirect page
 */
/* async function getRedirectPage() {
  const response = await apihelper.getRequest("profile/page/redirect");
  return response;
} */

/**
 * Update Logged In User Data
 * @param id
 * @param updatedData
 */
async function update(data) {
  const response = await apihelper.putRequest("profile", data);
  return response;
}

/**
 * Update Preference
 * @param {*} params 
 */
async function updatePreference (data) {
  const response = await apihelper.putRequest("profile/update/client",(data));
  return response;
}
