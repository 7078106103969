import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

import { getReducerUpdatedData } from "../../../..//helpers";
import { contentLibraryAction } from "../../../../redux/actions";
import { surveySettingsConstants } from "../../../../config/constants";


function Kiosk(props) {
  const { classes, data, messageData, expanded } = props;
  const dispatch = useDispatch();


  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };

  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, data);
    props.editMessageContent(reducerData)
  };


  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  const saveMessageData = () => {
    delete data.updatedData;
    messageData.filter((msg, index) => {
      if (msg.type === data.type) {
        messageData[index] = data
      }
    })
    dispatch(contentLibraryAction.updateMessage(messageData));
  };

  const closeMessage = () => {
    props.handleCancelMessage()
  };


  return (
    <React.Fragment>
      <Accordion
        className="mb-2 pe-accordion"
        expanded={expanded === "kiosk-panel"}
        onChange={handleTabChange("kiosk-panel")}
      >

        {/*
      panel header summary
    */}

        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expandicon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyComponent
            className={` ${classes.setting_accordheading} ${expanded === "kiosk-panel" ? "panel-active" : ""} `}
            variant={"h5"}
          >{"Kiosk"}</TypographyComponent>
        </AccordionSummary>


        <AccordionDetails>
          <Grid container spacing={3} className={`${classes.setting_expandcontainer} `}>
            <Grid item md={10} xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.KIOSK_TOGGLE_TITLE}
              </TypographyComponent>
              <TextareaAutosize
                className={` width100 pe_textarea mb-m4  `}
                name={`kiosk.body`}
                value={data.kiosk ? data.kiosk.body : ""}
                onChange={(e) => handleTextChange(e)}
                minRows={3} />
            </Grid>
            <Grid item md={10} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  <ButtonComponent
                    title={"OK"}
                    color={"primary"}
                    size={"small"}
                    handleClick={() => saveMessageData()}
                    pageClassName={classes.settings_addrulebtn}
                  ></ButtonComponent>
                  <ButtonComponent
                    title={"Cancel"}
                    color={"secondary"}
                    size={"small"}
                    handleClick={() => closeMessage()}
                    pageClassName="ml-1"
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>

      </Accordion>

    </React.Fragment >
  );
}

export default Kiosk;

