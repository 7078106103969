import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Participant Service Functions
 */
export const participantService = {
  getOne,
  search,
  update,
  create,
  remove,
  surveySearch,
  removeSurveysData,
  overrideSurveysData,
  applyConversion,
  surveyCheck,
  ageTenureCalculation,
  getSurveys,
  containsParticipants,
  getSurveydataForNotification
};

/**
 * Get participant By Id
 */
async function getOne(id) {
  const response = await apihelper.getRequest(`participant/${id}`);
  return response;
}

/**
 * Search Participants
 */
async function search(skip, limit, sortBy, sort, sFields, sValues, updatedVisibleColumns = []) {
  // const response = await apihelper.postRequest(`participant/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, updatedVisibleColumns });
  const response = await apihelper.postRequest(`participant/performance-search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, updatedVisibleColumns });
  return response;
}

/**
 * Search Surveys
 */
async function surveySearch(txt) {
  const response = await apihelper.getRequest(`participant/survey/${txt}`);
  return response;
}

/**
 * Call update Partcipant Service
 * @param updatedData
 */
async function update(updatedData) {
  const response = await apihelper.putRequest("participant", updatedData);
  return response;
}

/**
 * Add participant
 */
async function create(participant) {
  const response = await apihelper.postRequest('participant', participant);
  return response;
}

/**
 * Delete participant
 */
async function remove(id) {
  const response = await apihelper.deleteRequest('participant', { _id: id });
  return response
}

/* Get Survey Data fir remove survey
*/
async function removeSurveysData() {
  const response = await apihelper.getRequest('participant/removeSurveys');
  return response;
}

/* Get Survey Data for override demographics
*/
async function overrideSurveysData() {
  const response = await apihelper.getRequest('participant/overrideSurveys');
  return response;
}

/**
 * apply conversion
 */
async function applyConversion(conversionData) {
  const response = await apihelper.postRequest('participant/applyConversion', conversionData);
  return response;
}
/**
* check survey resp
*/
async function surveyCheck(checkData) {
  const response = await apihelper.postRequest('responses/partipant/validate', checkData);
  return response;
}
/**
* age tenure calculation
*/
async function ageTenureCalculation(clientDetails) {
  const response = await apihelper.postRequest('participant/ageTenureCalculation', clientDetails);
  return response;
}
/**
* get all survey of a client
*/
async function getSurveys(clientID) {
  const response = await apihelper.getRequest('participant/getSurveys');
  return response;
}
/**
* check the participants consist of particular demographics 
*/
async function containsParticipants(key) {
  const response = await apihelper.getRequest(`participant/containsParticipants/${key}`);
  return response;
}


/**
* Get surveys for a participant to send notification
*/
async function getSurveydataForNotification(participantDetails) {
  const response = await apihelper.postRequest(`participant/getSurveydataForNotification`, participantDetails);
  return response;
}
