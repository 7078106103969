/* eslint-disable dot-notation */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

// Import components
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";

// import helper
import { conversionFieldValidation } from "../../../../../helpers/ConversionValidation";

// Import Actions
import { participantAction, metadataAction, alertAction } from "../../../../../redux/actions";

const SaveAndApplyButton = (props) => {
  const dispatch = useDispatch();

  // Get props
  const { classes, metadataSelected, metadata, saveCallback } = props;

  // save conversion to metadata
  const saveConversion = () => {
    // field validation
    const { conditionValidation, errorMessage } = conversionFieldValidation(metadataSelected, metadata);
    if (conditionValidation > 0) {
      dispatch(alertAction.error(errorMessage));
    } else {
      if (metadata.length > 0) {
        for (let i = 0; i < metadata.length; i++) {
          if (metadata[i]["key"] === metadataSelected["key"]) {
            metadata[i]["conversion"] = metadataSelected["conversion"];
          }
        }
        dispatch(metadataAction.updateMetadata({ metadata }, true, () => {
          saveCallback();
        }));
      } else {
        dispatch(alertAction.error("No conversion is set up"));
      }
    }
  };

  // apply conversion to participant data
  const applyConversion = () => {
    // field validation
    const {conditionValidation} = conversionFieldValidation(metadataSelected, metadata);
    const conversionRules = metadataSelected["conversion"] ? metadataSelected["conversion"] : [];
    let isAllEmpty = false;

    if(conversionRules[0].from.trim() === '' && conversionRules[0].operator.trim() === '' && conversionRules[0].match.length <= 0 && conversionRules[0].value.trim() === '')
      isAllEmpty = true

    if (conditionValidation > 0) {
      dispatch(alertAction.error(isAllEmpty ? "No conversions found" : 'All fields are required for conversion'));
    } else if (conversionRules.length <= 0) {
      dispatch(alertAction.error("No conversion is set up"));
    } else {
      if (metadata.length > 0) {
        for (let i = 0; i < metadata.length; i++) {
          if (metadata[i]["key"] === metadataSelected["key"]) {
            metadata[i]["conversion"] = metadataSelected["conversion"];
          }
        }
        dispatch(metadataAction.updateMetadata({ metadata }, false, ()=>{
          dispatch(participantAction.applyConversion({ conversionRules }));
          saveCallback();
        }));
      }else{
        dispatch(alertAction.error("No conversion is set up"));
      }
    }
  };

  return (
    <React.Fragment>
      <Grid container justifyContent={"space-between"}>
        <Grid item>
          <ButtonComponent
            title={"Run conversion now"}
            color={"primary"}
            size={"small"}
            handleClick={() => { applyConversion(); }}
            pageClassName="buttonOrange"
          ></ButtonComponent>
        </Grid>
        <Grid item>
          <ButtonComponent
            title={"Save"}
            color={"primary"}
            size={"small"}
            handleClick={() => { saveConversion(); }}
            pageClassName={classes.settings_addrulebtn}
          ></ButtonComponent>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

// default props
SaveAndApplyButton.defaultProps = {
  classes: {},
  metadataSelected: {},
  metadata: [],
  saveCallback: () => {}
};

// prop types
SaveAndApplyButton.propTypes = {
  classes: PropTypes.object,
  metadataSelected: PropTypes.object,
  metadata: PropTypes.array
};

export default SaveAndApplyButton;
