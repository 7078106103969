import { queryBuilderConstant } from "../constants";

// use seperate keys for seperate query builders
// e-g {audinece: { fields: [], fieldValues: [firstName: []]} }, for audience page query builder
const defaultQueryBuilderValues = {
  fields: [], // [{ name: "firstname", label: "First Name", type: "string" }, ...]
  fieldValues: {}, // { firstName: ["robert", "siva", ...], ... },
  queryChanges: null
};
const initialState = {
  audience: { ...defaultQueryBuilderValues },
  loading: false
};

export default function (state = initialState, action) {
  if (!action || (action && !action.type)) return state;

  switch (action.type) {
    // get field names pass the page as key
    case queryBuilderConstant.GET_FIELD_NAMES_ON_REQUEST:
      return {
        ...initialState,
        loading: true
      };
    case queryBuilderConstant.GET_FIELD_NAMES_ON_SUCCESS:
      return {
        ...state,
        [action.data.page]: {
          ...state[action.data.page],
          fields: action.data.fields
        },
        loading: false
      };
    case queryBuilderConstant.GET_FIELD_NAMES_ON_FAILURE:
      return {
        ...state,
        loading: false
      };
    // get field values pass page and field name
    case queryBuilderConstant.GET_FIELD_VALUES_ON_REQUEST:
      return {
        ...state,
        loading: true
      };
    case queryBuilderConstant.GET_FIELD_VALUES_ON_SUCCESS:
      return {
        ...state,
        [action.data.page]: {
          ...state[action.data.page],
          fieldValues: {
            ...state[action.data.page].fieldValues,
            [action.data.fieldName]: action.data.fieldValues
          }
        },
        loading: false
      };
    case queryBuilderConstant.GET_FIELD_VALUES_ON_FAILURE:
      return {
        ...state,
        loading: false
      };
    // query on change
    case queryBuilderConstant.SET_QUERY_ON_CHANGE:
      return {
        ...state,
        [action.data.page]: {
          ...state[action.data.page],
          queryChanges: action.data.mongoQuery
        }
      }
    default:
      return state;
  }
}
