import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import _ from 'lodash'
// Import Styles
import FilterStyles from "../FilterStyles";

// Import Constants
import { FilterConstants } from '../../../config/constants';

//Import Actions
import { alertAction, filterAction, updateStateReducerAction } from '../../../redux/actions'

//Import Images
import { Pulse, MeSurvey } from '../../../assets/images/svgComponents/ImageComponent';
import { surveyTypes, ReportsConstants } from "../../../config/constants";

// Import Reducer Constant
import { filterConstant, dashboardConstant } from '../../../redux/constants';

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../../components/SearchInputCompoment/SearchInputComponent";

function SurveyFilterPanel(props) {

    const { classes, panelHeight, parentExpanded, handlePanel } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Set Search State
    const [search, setSearch] = useState('')

    // Get filter Data
    const { surveys, filterData, allSurveys } = useSelector(state => state.filter)

    // Set Surveys In State
    const [surveyData, setSurveyData] = useState([]);

    /**
     * Set Surveys Values In State
     */
    useEffect(() => {
        setSurveyData(surveys)
        surveys.sort((a, b) => (a.endDate > b.endDate) ? -1 : 1)
        surveys.map((eachSurvey) => {
            const surveydetails = filterData.surveys.filter(obj => obj._id === eachSurvey._id)
            if (surveydetails.length > 0) {
                if (!filterData.date.startDate || !filterData.date.endDate) {
                    filterData.date.startDate = eachSurvey.startDate
                    filterData.date.endDate = eachSurvey.endDate
                }
            }
        })
    }, [surveys])

    // Get Demographics Data
    const getDemograpicsData = () => {
        dispatch(filterAction.getDemographics({
            metadata: [],
            surveys: filterData.surveys,
            date: filterData.date
        }))
    }

    // Bind Survey Data
    const bindSurveys = () => {
        if (surveyData && surveyData.length > 0) {
            let checkboxJSX = [];
            surveyData.map((data) => {
                if (data.frequency !== "Pulse" && data.type !== surveyTypes.MULTI_RATER_FEEDBACK) {
                    checkboxJSX.push(<Grid item xs={12} key={"div_" + data._id} className={classes.demographyCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={data._id}
                                    id={data._id}
                                    name={data.name}
                                    color="primary"
                                    checked={getCheckedData(data._id)}
                                    onChange={e => handleChange({ name: e.target.name, _id: e.target.id, frequency: data.frequency, pulse_obj: data.pulse_obj, type: data.type })}
                                />
                            }
                            label={data.name}
                        />
                    </Grid>)
                }
            })
            surveyData.map((data) => {
                if (data.frequency === "Pulse") {
                    checkboxJSX.push(<Grid item xs={12} key={"div_" + data._id} className={classes.demographyCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={data._id}
                                    id={data._id}
                                    name={data.name}
                                    color="primary"
                                    checked={getCheckedData(data._id)}
                                    onChange={e => handleChange({ name: e.target.name, _id: e.target.id, frequency: data.frequency, pulse_obj: data.pulse_obj, type: data.type })}
                                />
                            }
                            label={data.name}
                        ></FormControlLabel>
                        <Grid className="p3">{Pulse()}</Grid>
                    </Grid>)
                }
                if (data.type === surveyTypes.MULTI_RATER_FEEDBACK) {
                    checkboxJSX.push(<Grid item xs={12} key={"div_" + data._id} className={classes.demographyCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    key={data._id}
                                    id={data._id}
                                    name={data.name}
                                    color="primary"
                                    checked={getCheckedData(data._id)}
                                    onChange={e => handleChange({ name: e.target.name, _id: e.target.id, frequency: data.frequency, pulse_obj: data.pulse_obj, type: data.type })}
                                />
                            }
                            label={data.name}
                        ></FormControlLabel>
                        <Grid className="p3">{MeSurvey()}</Grid>
                    </Grid>)
                }
            })
            return checkboxJSX.sort((a, b) => {
                let elemB, elemA;
                if (a.props.children.length > 0) {
                    elemA = a.props.children[0]
                } else {
                    elemA = a.props.children
                }
                if (b.props.children.length > 0) {
                    elemB = b.props.children[0]
                } else {
                    elemB = b.props.children
                }
                return elemB.props.control.props.checked - elemA.props.control.props.checked
            })
        }
        else
            return (
                <Grid item xs={12} className={"mt-1"}>
                    <TypographyComponent title='No Surveys Responded!' variant='inherit' />
                </Grid>
            )
    }

    /**
     * Check box event
     * @param {*} name 
     */
    const getCheckedData = (id) => {
        return filterData.surveys.filter(d => d._id === id).length > 0 ? true : false;
    }

    /**
     * Handle Change Event
     * @param {*} e 
     * @param {*} type 
     */
    const handleChange = (e) => {

        const isPulse = filterData.surveys.filter(d => d.frequency === 'Pulse').length > 0;
        const isOther = filterData.surveys.filter(d => d.frequency !== 'Pulse').length > 0;
        const isME = filterData.surveys.filter(d => d.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0;
        const isNotME = filterData.surveys.filter(d => d.type !== surveyTypes.MULTI_RATER_FEEDBACK).length > 0;

        // if pulse and chosen not pulse OR if other and chosen pulse show alert
        if ((isPulse && e.frequency !== 'Pulse') || (isOther && e.frequency === 'Pulse')) {
            dispatch(alertAction.error(FilterConstants.COMBINE_ALERT))
        }
        else if ((isME && e.type !== surveyTypes.MULTI_RATER_FEEDBACK) || (isNotME && e.type === surveyTypes.MULTI_RATER_FEEDBACK)) {
            dispatch(alertAction.error(FilterConstants.COMBINE_ME_ALERT))
        }


        else {
            if (filterData.surveys.filter(d => d._id === e._id).length > 0) {
                filterData.surveys = filterData.surveys.filter(d => d._id !== e._id)
            } else {
                filterData.surveys.push(e);
            }

            surveys.map((eachSurvey) => {
                const surveydetails = filterData.surveys.filter(obj => obj._id === eachSurvey._id)
                if (surveydetails.length > 0) {
                    filterData.date.type = "Custom dates"
                    filterData.date.startDate = eachSurvey.startDate
                    filterData.date.endDate = eachSurvey.endDate
                }
            })

            const hasMESurvey = (filterData.surveys || []).filter(s =>
                s.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0

            if (hasMESurvey) {
                filterData.reportVisibility = { ...(filterData.reportVisibility || {}), '7': 'Competency Summary' }
                filterData.demographics = {}
            } else {
                filterData.reportVisibility = {
                    ...ReportsConstants.CHARTS
                }
            }

            dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, "all", () => {
                dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, filterData))
            }))



            //Get getDemograpicsData
            // getDemograpicsData()
        }
    }

    /** 
     * Handle Search 
     */
    const handleSearch = (name, value) => {
        let searchSurveys = allSurveys.filter(obj => obj.name && obj.name.toLowerCase().includes(value.toLowerCase()))
        const data = { data: searchSurveys }
        dispatch(updateStateReducerAction.update(filterConstant.FILTER_GET_SURVEYS_SUCCESS, data))
        setSearch(value)
    }

    // on clear search
    const handleClearSearch = () => {
        const data = { data: allSurveys }
        dispatch(updateStateReducerAction.update(filterConstant.FILTER_GET_SURVEYS_SUCCESS, data))
        setSearch('')
    }

    return (
        <Accordion
            classes={{
                root: classes.peaccordion_nocolor
            }}
            expanded={parentExpanded === 'survey-panel'}
            onChange={handlePanel('survey-panel')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header">
                <TypographyComponent variant={"h6"}
                    className={`${"txtBold "} ${parentExpanded === 'survey-panel' ? "panel-active" : ""} `}>
                    {FilterConstants.SURVEYNAMES}
                </TypographyComponent>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container className={`${classes.expansionDetail}`} >
                    {surveys &&
                        <Grid item xs={12} className="relative">
                            {/* <img className={classes.demographySearchImg} src={SearchImg}></img> */}
                            <SearchInputComponent
                                id="searchCategoryTex"
                                placeholder={"Search"}
                                value={search}
                                field={"searchCategory"}
                                showClearIcon={true}
                                fullWidth={true}
                                className={`${classes.demographySearch}`}
                                timeOut={10}
                                onInputChangeRequest={handleSearch}
                                handleClearSearch={handleClearSearch}
                                onEnter={() => { }} />
                        </Grid>
                    }
                    <Grid 
                        item
                        xs={12}
                        className={`${classes.expansionDetail} ${"scrolly"}`} 
                        style={{ maxHeight: panelHeight / 2 }}
                    >
                        {bindSurveys()}
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    )
}

// default props
SurveyFilterPanel.defaultProps = {
    classes: {},
    panelHeight: 0,
    preference: {},
    parentExpanded: null,
    handlePanel: () => { }
};

// prop types
SurveyFilterPanel.propTypes = {
    classes: PropTypes.object,
    panelHeight: PropTypes.number,
    preference: PropTypes.object
};

export default withStyles(FilterStyles)(SurveyFilterPanel);