// TakeSurvey reducer types
export const meTakeSurveyConstant = {
  ME_SURVEY_GET_ONE_REQUEST: "ME_SURVEY_GET_ONE_REQUEST",
  ME_SURVEY_GET_ONE_SUCCESS: "ME_SURVEY_GET_ONE_SUCCESS",
  ME_SURVEY_GET_ONE_CLEAR: "ME_SURVEY_GET_ONE_CLEAR",
  ME_SURVEY_GET_ONE_FAILURE: "ME_SURVEY_GET_ONE_FAILURE",

  ME_SURVEY_GET_RATERS_REQUEST: " ME_SURVEY_GET_RATERS_REQUEST",
  ME_SURVEY_GET_RATERS_SUCCESS: " ME_SURVEY_GET_RATERS_SUCCESS",
  ME_SURVEY_GET_RATERS_CLEAR: " ME_SURVEY_GET_RATERS_CLEAR",
  ME_SURVEY_GET_RATERS_FAILURE: " ME_SURVEY_GET_RATERS_FAILURE",

  ME_TAKESURVEY_SEARCH_REQUEST: "ME_TAKESURVEY_SEARCH_REQUEST",
  ME_TAKESURVEY_SEARCH_SUCCESS: "ME_TAKESURVEY_SEARCH_SUCCESS",
  ME_TAKESURVEY_SEARCH_FAILURE: "ME_TAKESURVEY_SEARCH_FAILURE",

  ME_SURVEY_RESPONSE_UPDATE_REQUEST: "ME_SURVEY_RESPONSE_UPDATE_REQUEST",
  ME_SURVEY_RESPONSE_UPDATE_SUCCESS: "ME_SURVEY_RESPONSE_UPDATE_SUCCESS",
  ME_SURVEY_RESPONSE_UPDATE_FAILURE: "ME_SURVEY_RESPONSE_UPDATE_FAILURE",

  ME_SURVEY_RESPONSE_UPDATE: "ME_SURVEY_RESPONSE_UPDATE",
  ME_SURVEY_REQUIRED_QUESTIONS_UPDATE: "ME_SURVEY_REQUIRED_QUESTIONS_UPDATE",
  ME_TAKE_SURVEY_UPDATE_LANGUAGE: "ME_TAKE_SURVEY_UPDATE_LANGUAGE",

  CLEAR_ME_TAKE_SURVEY_DATA: "CLEAR_ME_TAKE_SURVEY_DATA",
};
