/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from '@mui/styles/withStyles';

import { ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";

// Import Images
import CreateIcon from "../../assets/images/svg/editprofile.svg";
import ProfileImg from "../../assets/images/default_profile.svg";
import UpArrow from "../../assets/images/svg/uparrow.svg";

// Import Container
import Container from "../Containers/Container";

// Import Actions
import { profileAction, alertAction, updateStateReducerAction } from "../../redux/actions";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";

// Import Constants
import { profileConstants, errorsConstants } from "../../config/constants";

// Import Styles
import ProfileStyles from "./ProfileStyles";

// Import Redux Constants
import { profileConstant } from "../../redux/constants";

// Import Helpers
import { getReducerUpdatedData } from "../../helpers";


// Import History
import history from "../../config/history";

function Profile(props) {
  // Get Props
  const { classes, user } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  // check whether data is changed
  const [isPasswordChange, setPasswordChange] = useState(false);
  const [isValid, setValid] = useState(true);
  const [isChanged, setIsChanged] = useState(false)

  const [password, setPassword] = useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    showPassword1: false,
    showPassword2: false,
    showPassword3: false

  });


  const { showPassword1,showPassword2,showPassword3 } = password;
  /**
* Set Profile Data
*/
  useEffect(() => {
    dispatch(profileAction.getOne());
  }, [dispatch]);

  // Get Reducer Updates
  const profile = useSelector(state => state.profile.getOne);

  /**
   * Set Password policy
   */
  useEffect(() => {
    ValidatorForm.addValidationRule("passwordPolicy", (value) => {
      if (value.length < 6) {
        return false;
      } else if (value.length > 50) {
        return false;
      } else if (value.search(/\d/) === -1 && value.search(/[!@#$^&*()_]/) === -1) {
        return false;
      } else if (value.search(/[a-z]/) === -1) {
        return false;
      } else if (value.search(/[A-Z]/) === -1) {
        return false;
      } else {
        return true;
      }
    });
  }, []);

  /**
  * Set Password policy
  */
  useEffect(() => {
    ValidatorForm.addValidationRule("passwordPolicyNew", (value) => {
      if (value.length < 6) {
        setValid(false);
        return false;
      } else if (value.length > 50) {
        setValid(false);
        return false;
      } else if (value.search(/\d/) === -1 && value.search(/[!@#$^&*()_]/) === -1) {
        setValid(false);
        return false;
      } else if (value.search(/[a-z]/) === -1) {
        setValid(false);
        return false;
      } else if (value.search(/[A-Z]/) === -1) {
        setValid(false);
        return false;
      } else {
        setValid(true);
        return true;
      }
    });
  }, []);

  // New Password and Confirm Password Match Validation
  useEffect(() => {
    ValidatorForm.addValidationRule("confirmPasswordMismatch", (value) => {
      if (value !== password.newPassword) {
        return false;
      }
      return true;
    });
  }, [password]);

  /**
    * Profile image change handler
    */
  let imageHandler = e => {
    setIsChanged(true)
    const validExts = [".png", ".jpeg", ".jpg"];
    let fileExt = e.target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));

    if (validExts.indexOf(fileExt) < 0) {
      dispatch(alertAction.error(errorsConstants.invalidImageFormat))
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(updateStateReducerAction.update(profileConstant.PROFILE_GET_ONE_UPDATE, {
            ...profile,
            img: reader.result
          }
          ));
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  /**
   * Handle Form Submit
   */
  let handleSubmit = (e) => {
    if (isPasswordChange) {
      if (password.newPassword === password.confirmPassword) {
        dispatch(profileAction.update({
          ...profile,
          isPasswordChange: true,
          currentPassword: password.currentPassword,
          newPassword: password.newPassword,
          confirmPassword: password.confirmPassword
        }, () => {
          history.goBack()
        })
        );
      } else {
        dispatch(alertAction.error(errorsConstants.isPasswordMatch));
      }
    } else {
      dispatch(profileAction.update({
        ...profile,
        isPasswordChange: false
      }, () => {
        history.goBack()
      })
      );
    }
  };

  /**
  *Handle Cancel Navigage to Tab based on Roles
  */
  const handleCancel = () => {
    dispatch(profileAction.getOne());
    history.goBack()
  };

  // Show / Hide Password Visibility
  function handleClickShowPassword(box) {
    if(box===1){
      setPassword({ ...password, showPassword1: !password.showPassword1 });
    }else if(box===2){
      setPassword({ ...password,showPassword2: !password.showPassword2 });
    }else if(box===3){
      setPassword({ ...password,showPassword3: !password.showPassword3 });
    }else{
      setPassword({ ...password, showPassword1: !password.showPassword1,showPassword2: !password.showPassword2,showPassword3: !password.showPassword3 });
    }
  }

  /**
   * Handle Form Change
   */
  let handleChange = (event) => {
    setIsChanged(true)
    const reducerData = getReducerUpdatedData(event, profile);
    dispatch(updateStateReducerAction.update(profileConstant.PROFILE_GET_ONE_UPDATE, reducerData));
  };
  const togglePassChange = () => setPasswordChange(!isPasswordChange);
  const handlePassChange = (event) => {
    setIsChanged(true)
    setPassword({
      ...password,
      [event.name]: event.value
    });
  };

  return (
    <React.Fragment>
      <Grid className={classes.page_title}>
        <TypographyComponent
          variant="h3"
          title={"My Profile"}
        />
      </Grid>

      <Container page={"sub"}>
        <Grid className={"container width100 mt-8"}>
          <Badge className={classes.client_profileBadge}
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={<Grid className="image-upload">
              <label htmlFor="file-input">
                <img className={classes.profile_editicon} src={CreateIcon} alt="create" />
              </label>
              <input id="file-input" onChange={imageHandler} type="file" accept=".png,.jpeg,.jpg" />
            </Grid>} >
            {/* <img className={classes.imgStyle} alt="Travis Howard" src={PROFILE_IMAGE} /> */}
          </Badge>

          <Paper className="borderRadius6" elevation={1}>
            <Grid className="p-4">
              <ValidatorForm
                name="MyProfileForm"
                useref="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                onError={(errors) => {}}
              >
                <Grid container justifyContent="center">
                  <img src={profile.img ? profile.img : ProfileImg} className={classes.Client_Profile} alt="profile img" />
                </Grid>
                <Grid container justifyContent="space-between" className="pt-2 pb-1" spacing={3} >
                  <Grid item xs={12} className="sm-30" >
                    <TypographyComponent
                      title={profileConstants.FIRST_NAME}
                      variant={"h6"}
                    ></TypographyComponent>
                    <TextFieldComponent
                      id={"first_name"}
                      name={"firstName"}
                      value={profile.firstName}
                      size={"small"}
                      margin={"none"}
                      handleChange={(e) => handleChange(e)}
                      className="height36"
                    ></TextFieldComponent>
                  </Grid>
                  <Grid item xs={12} className="sm-30" >
                    <TypographyComponent
                      title={profileConstants.LAST_NAME}
                      variant={"h6"}
                    ></TypographyComponent>
                    <TextFieldComponent
                      id={"last_name"}
                      name={"lastName"}
                      size={"small"}
                      value={profile.lastName}
                      margin={"none"}
                      handleChange={(e) => handleChange(e)}
                      className="height36"
                    ></TextFieldComponent>
                  </Grid>

                  <Grid item xs={12} className="sm-30" >
                    <TypographyComponent
                      title={profileConstants.EMAIL_ID_WITHOUT_MANDATORY}
                      variant={"h6"}
                    ></TypographyComponent>
                    <TextFieldComponent
                      id={"email_id"}
                      name={"email"}
                      value={profile.email}
                      margin={"none"}
                      size={"small"}
                      disabled={true}
                      handleChange={(e) => handleChange(e)}
                      validators={["required", "isEmail"]}
                      className="height36"
                    ></TextFieldComponent>
                  </Grid>
                </Grid>
                <Grid container spacing={3} >
                  <Grid item xs={12} lg={12} md={12} className="lg-pr-80">
                    <Link
                      className='cursor-pointer'
                      onClick={togglePassChange}>
                      Change password
                    </Link>
                  </Grid>
                  {isPasswordChange && <React.Fragment>
                    <Grid item xs={12}>
                      <Grid container justifyContent="space-between" className={`${"relative"} ${classes.changePassword}`} spacing={1}>
                        <img src={UpArrow} className={classes.upArrowImg} alt="up arrow"></img>
                        <Grid item xs={12} className="sm-30">
                          <TypographyComponent
                            title={"Current password *"}
                            variant={"h6"}
                          ></TypographyComponent>
                          <TextFieldComponent
                            id={'txt_currentPassword'}
                            name={"currentPassword"}
                            value={password.currentPassword}
                            margin={"none"}
                            type='password'
                            size={"small"}
                            handleChange={(e) => handlePassChange(e)}
                            pwdVisibileHide={true}
                            showPassword={showPassword1}
                            handleClickShowPassword={(e) => handleClickShowPassword(1)}
                            validators={["required"]}
                            className="height36"
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item xs={12} className="sm-30">
                          <TypographyComponent
                            title={"New password *"}
                            variant={"h6"}
                          ></TypographyComponent>
                          <TextFieldComponent
                            id={'txt_newPassword'}
                            name={"newPassword"}
                            value={password.newPassword}
                            margin={"none"}
                            type='password'
                            size={"small"}
                            handleChange={(e) => handlePassChange(e)}
                            pwdVisibileHide={true}
                            showPassword={showPassword2}
                            handleClickShowPassword={(e) => handleClickShowPassword(2)}
                            validators={["required", "passwordPolicyNew"]}
                            className="height36"
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item xs={12} className="sm-30">
                          <TypographyComponent
                            title={"Confirm new password *"}
                            variant={"h6"}
                          ></TypographyComponent>
                          <TextFieldComponent
                            id={'txt_confrimPassword'}
                            name={"confirmPassword"}
                            type='password'
                            value={password.confirmPassword}
                            margin={"none"}
                            size={"small"}
                            handleChange={(e) => handlePassChange(e)}
                            pwdVisibileHide={true}
                            showPassword={showPassword3}
                            handleClickShowPassword={(e) => handleClickShowPassword(3)}
                            validators={["required", "passwordPolicyNew", "confirmPasswordMismatch"]}
                            className="height36"
                          ></TextFieldComponent>
                        </Grid>
                        {!isValid && <Grid item xs={12} className="mt-2">
                          <Grid container justifyContent="flex-end">
                            <Grid>
                              <TypographyComponent
                                title={"Password must meet below requirements"}
                                variant={"h5"}
                              ></TypographyComponent>
                              <TypographyComponent
                                title={"  At least 6 characters"}
                                variant={"h6"}
                                className={"fontSize13"}
                              ></TypographyComponent>
                              <TypographyComponent
                                title={"At least 1 uppercase letter"}
                                variant={"h6"}
                                className={"fontSize13"}
                              ></TypographyComponent>
                              <TypographyComponent
                                title={"At least 1 lowercase letter"}
                                variant={"h6"}
                                className={"fontSize13"}
                              ></TypographyComponent>
                              <TypographyComponent
                                title={"At least 1 number or symbol [!@#$^&*()_]"}
                                variant={"h6"}
                                className={"fontSize13"}
                              ></TypographyComponent>
                            </Grid>
                          </Grid>
                        </Grid>
                        }
                      </Grid>
                    </Grid>
                  </React.Fragment>}

                  
                  <Grid item xs={12} className={"mt-1"}>
                    <Grid container justifyContent={"flex-end"}>
                      <Grid item>
                        {isChanged &&
                        <ButtonComponent
                          title={"Save"}
                          color={"primary"}
                          size={"small"}
                          type="submit"
                        ></ButtonComponent>
                      }
                        <ButtonComponent
                          title={"Cancel"}
                          color={"default"}
                          size={"small"}
                          pageClassName="ml-1"
                          handleClick={handleCancel}
                        ></ButtonComponent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
          </Paper>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(ProfileStyles)(Profile);
