import React from "react";
import PropTypes from "prop-types";

// import Components
import SuccessPageComponent from "../../../../components/SuccessPageComponent/SuccessPageComponent";

// import constants
// import {participantConstants,excelConstants,} from "../../../../config/constants";

function SuccessPage (props) {
  const { uploadResult, onDone } = props
  return <SuccessPageComponent
   match={props.match} 
   uploadResult={uploadResult} 
   backBtnURL={'/manage_people/participants'} 
   backPageURL={'/manage_people/participants'} {...props}
   onDone={onDone}
   pageName={"Participant upload"}
   />;
}
// default props
SuccessPage.defaultProps = {
  match: {}
};

// prop types
SuccessPage.propTypes = {
  match: PropTypes.object,
  onDone: null
};
export default SuccessPage;
