import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { Grid, IconButton } from "@mui/material";

import {
    DemographicSetting,
    DemographicSettingOrange,
    DrawerClose
} from "../../../../../../assets/images/svgComponents/ImageComponent";
import TypographyComponent from "../../../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../../../components/ButtonComponent/ButtonComponent";

import DemographicFilter from "./DemographicFilter";

// Import Config Layout
import { getDrawerHeight } from "../../../../../../config/layout";

import { Link } from "react-router-dom";

// Import Styles and Image
import TabStyle from "../../styles/METabStyles";

//Import Actions
import { meSurveyAction } from '../../../../../../redux/actions'

import DrawerPanel from "../DrawerPanel"

const DataFilter = (props) => {

    const { classes, open, onClose, isRaterTab } = props
    const drawerHeight = getDrawerHeight()
    const dispatch = useDispatch()

    // Get filter Data
    const { managerEffectiveness, metadata: { metadata } } = useSelector(state => state)
    const { dataFilter } = managerEffectiveness
    const { filterData } = dataFilter
    const demoMeta = {}
    metadata.map(meta => {
        demoMeta[meta.key] = meta.value
    })

    const onSearchkeyChange = (value) => {
        dispatch(meSurveyAction.dataFilterUpdate({
            ...dataFilter,
            filterData: {
                ...filterData,
                search: value
            }
        }))
        dispatch(meSurveyAction.getDemographics(isRaterTab))
    }

    return <>
        <DrawerPanel
            className={`${classes.drawer} ${"pe_drawer"}`}
            anchor='right'
            variant="temporary"
            open={open}
            onClose={() => { onClose(false) }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={`${"pr-1 pl-1 borderBottom"} ${classes.filterTitle}`}>
                <Grid item>
                    <Grid container alignItems="center">
                        <IconButton variant="contained" size="large">
                            {/* <img src={SettingsIcon} alt={"settings"} /> */}
                    {filterData.selectedEmpIds.length > 0 ? DemographicSettingOrange() : DemographicSetting()}
                        </IconButton>
                        <TypographyComponent variant={"h4"}
                            className="txtMedium">
                            {"People Filters"}
                        </TypographyComponent>
                    </Grid>
                </Grid>
                <Grid item>
                    <Link
                        className={`cursor-pointer colorTheme right ${classes.clearAll}`}
                        onClick={e => {
                            // dispatch(meSurveyAction.updateSelectedParticipants([]))
                            dispatch(meSurveyAction.dataFilterUpdate({
                                ...dataFilter,
                                filterData: {
                                    ...filterData,
                                    demographics: {},
                                    selectedEmpIds: []
                                }
                            }))
                        }}
                        style={{ marginTop: 5, marginRight: 7 }}
                    >
                        {"Clear All"}
                    </Link>
                    <IconButton variant="contained" onClick={() => { onClose(false) }} size="large">
                        {/* <img src={CloseIcon} alt={"Close"} /> */}
                        {DrawerClose()}
                    </IconButton>
                </Grid>
            </Grid>
            <div
                style={{
                    height: drawerHeight - 107,
                    padding: '12px'
                }}
                className={'scrolly'}
            >
                <DemographicFilter onSearchkeyChange={onSearchkeyChange} />
            </div>
            <div className={classes.saveFilterBtn}>
                <ButtonComponent
                    title={"Apply"}
                    color={"primary"}
                    size={"small"}
                    type={"button"}
                    handleClick={() => {
                        dispatch(meSurveyAction.dataFilterUpdate({
                            ...dataFilter,
                            filterChanged: true
                        }))
                        onClose(false)
                    }}
                />
            </div>
        </DrawerPanel>
    </>;
}

export default withStyles(TabStyle)(DataFilter);