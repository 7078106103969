import React, { useState } from "react";
import ReactWordcloud from "react-wordcloud";
import _ from "lodash";

//import comonent
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import WordsPopup from "./WordsPopup";

const WordCloudChart = (props) => {
  //get props
  const { wordCloud, allAns } = props;

  //state for popup open/close
  const [ansPopup, setAnsPopup] = useState(false);
  const [selectedWord, setSelectedWord] = useState({});

  // popup close
  const onClose = () => {
    setAnsPopup(false);
  };
  // open popup
  const openPopup = (word) => {
    setSelectedWord({ word: word["text"], value: word["value"] });
    setAnsPopup(true);
  };

  //create words for word cloud
  let wordsList = Object.keys(wordCloud).map((item) => {
    return { text: item, value: wordCloud[item] };
  });
  wordsList = _.orderBy(wordsList, ["value"] ,["desc"]);
  wordsList = wordsList.length > 35 ? wordsList.splice(0, 35) : wordsList;

  //chart callback
  const callbacks = {
    getWordColor: (word) => (word.value > 50 ? "black" : "black"),
    onWordClick: openPopup,
    getWordTooltip: (word) => ``,
  };

  const options = {
    rotations: 1,
    rotationAngles: [0, 0],
    fontFamily: 'roboto',
    fontSizes: [10, 40],
    // fontWeight: 'bold',
    padding: 1,
  };

  return (
    <React.Fragment>
      <ReactWordcloud
        words={wordsList}
        callbacks={callbacks}
        options={options}
      />
      {ansPopup && selectedWord["word"] && (
        <React.Fragment>
          <DialogComponent open={true} fullScreen={true}>
            <WordsPopup selectedWord={selectedWord} onClose={onClose} allAns={allAns}/>
          </DialogComponent>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// default props
WordCloudChart.defaultProps = {};
// prop types
WordCloudChart.propTypes = {};

export default WordCloudChart;
