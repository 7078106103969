import axios from "axios";

// Import Config and Helpers
import { _api } from "../config/environment";
import history from "../config/history";
import { getToken } from "./appHelper";

const apiBaseUrl = (isMS, type = null) => {
  if (type) {
    return _api[type] || _api.url
  } else {
    return isMS ? _api.ms_url : _api.url
  }
}

const timeStampKey = new Date().getTime();

const source = axios.CancelToken.source();


/**
 * GET request
 * @param {*} endpoint
 */
export const getRequest = async (endpoint, isMS = false, api_type) => {
  return makeRequest(`${apiBaseUrl(isMS, api_type)}/${endpoint}`, "GET");
};

/**
 * PUT requests
 * @param {* The put endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the put method contains any images, <false> otherwise.} isFormData
 */
export const putRequest = async (endpoint, body, isFormData, isMS = false) => {
  return makeRequest(`${apiBaseUrl(isMS)}/${endpoint}`, "PUT", body, isFormData);
};

/**
 * POST request
 * @param {* The post endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the post method contains any images, <false> otherwise.} isFormData
 */
export const postRequest = async (endpoint, body, isFormData, responseType, isMS = false, api_type) => {
  return makeRequest(`${apiBaseUrl(isMS, api_type)}/${endpoint}`, "POST", body, isFormData, responseType);
};

/**
 * POST request Excel
 * @param {* The post endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the post method contains any images, <false> otherwise.} isFormData
 */
export const postRequestExcel = async (endpoint, body, isFormData, responseType, isMS = false) => {
  return makeRequestExcel(`${apiBaseUrl(isMS)}/${endpoint}`, "POST", body, isFormData, responseType);
};

/**
 * DELETE request
 * @param {* The delete endpoint} endpoint
 */
export const deleteRequest = async (endpoint, body, isMS = false) => {
  return makeRequest(`${apiBaseUrl(isMS)}/${endpoint}`, "DELETE", body);
};

/**
 *
 * @param {*} endpoint
 * @param {*} verb
 * @param {*} body
 * @param {*} isFormData
 */
const makeRequest = (endpoint, verb, data, isFormData = false, responseType) => {
  const newEndPoint = `${endpoint}`
  const requestOptions = {
    method: verb,
    url: newEndPoint,
    headers: getHeaders(isFormData),
    responseType: responseType,
    data
  };
  return axios(requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (!error.response || error.code === 'ECONNABORTED') {
        throw (error || "").toString() === 'timeout of 0ms exceeded' ? 'Please refresh page.' : error
      } else {
        throw error;
      }
    });
};

// const makeRequest = (endpoint, verb, data, isFormData = false, responseType) => {
//   const newEndPoint = `${endpoint}`
//   const requestOptions = {
//     method: verb,
//     url: newEndPoint,
//     headers: getHeaders(isFormData),
//     responseType: responseType,
//     data,
//     cancelToken: source.token,
//     timeout: 10000
//   };
//   return axios(requestOptions)
//     .then(handleResponse)
//     .then((response) => {
//       return response;
//     })
//     .catch((error) => {
//       if (axios.isCancel()) {
//         throw "Network Error. Please Check The Connection";
//       }
//       else if (!error.response || error.code === 'ECONNABORTED') {
//         throw (error || "").toString() === 'timeout of 0ms exceeded' ? 'Please refresh page.' : error
//       } else {
//         throw error;
//       }
//     });
// };

/**
 *
 * @param {*} endpoint
 * @param {*} verb
 * @param {*} body
 * @param {*} isFormData
 */
const makeRequestExcel = (endpoint, verb, data, isFormData = false, responseType) => {
  const newEndPoint = `${endpoint}`
  const requestOptions = {
    method: verb,
    url: newEndPoint,
    headers: getHeaders(isFormData),
    responseType: responseType,
    data
  };
  return axios(requestOptions)
    .then(handleResponseExcel)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Handler for the response recieved from fetch request for excel download
 * @param {* The response recieved from fetch request} response
 */
const handleResponseExcel = (response) => {
  if (response.status === 200) {
    return {
      data: response.data,
      filename: response.headers["content-type"]
    };
  } else {
    throw new Error("Server connection issue");
  }
};

/**
 * Handler for the response recieved from fetch request
 * @param {* The response recieved from fetch request} response
 */
const handleResponse = (response) => {
  if (response.status === 200) {
    if (response.data.http_code === 200) {    
      return response.data;
    } else if (response.data.http_code === 400) {
      history.push({
        pathname: "/"
      });
      throw response.data.message;
    } else if (response.data.http_code === 307) {
      history.push({
        pathname: "/resetpassword/" + response.data.hash + "/" + response.data.key
      });
      throw response.data.message;
    } else if (response.data.http_code === 308) {
      history.push({
        pathname: "/error/saml/login"
      });
      throw response.data.message;
    } else {
      throw response.data.message;
    }
  } else {
    throw new Error("Server connection issue");
  }
};

/**
 * Prepares headers for the request
 * @param {* <true> if the request contains any images, <false> otherwise.} isFormData
 */
const getHeaders = (isFormData) => {
  const token = getToken();
  const headers = {};
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  if (!isFormData) {
    headers["Content-Type"] = "application/json";
  }
  return headers;
};

/**
 * Prepares the query string based on the given input prams
 * @param {* The params used to prepare query string} params
 */
export const makeQueryString = (params) => {
  let queries = Object.keys(params).map((key) =>
    params[key] && params[key].length ? key + "=" + params[key] : ""
  );
  queries = queries.filter((query) => query.length > 0);
  return "?" + queries.join("&");
};
