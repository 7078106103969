import { spCallerConstant } from "../constants";

const tableDatas = (sortName) => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: [],
        visibleColumns: [],
        columnsMap: {}
      }
    },
    loading: false
  };
};

const initialState = {
  survey_queue: { ...tableDatas("startDate") },
  caller_queue: {
    ...tableDatas("startDate"),
    clientSurveyDetails: null,
    callerQueueIds: []
  },
  caller_detail: {
    client_id: { name: "" },
    survey_id: { name: "", frequency: "" },
    participant_id: {
      altPhone: "",
      email: "",
      firstName: "",
      languages: { code: null, name: "" },
      lastName: "",
      phone: "",
      state: "",
      _id: null
    },
    copyData: {},
    status: "",
    history: [],
    survey_dateMap: {}
  },
  isLoading: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Surve Queue List
    case spCallerConstant.GET_SURVEY_QUEUE_LIST_REQUEST:
      return {
        ...state,
        caller_queue: {
          ...initialState.caller_queue
        },
        survey_queue: {
          ...state.survey_queue,
          loading: true
        }
      };
    case spCallerConstant.GET_SURVEY_QUEUE_LIST_SUCCESS:
      return {
        ...state,
        survey_queue: {
          ...state.survey_queue,
          ...action.data,
          loading: false
        }
      };
    case spCallerConstant.GET_SURVEY_QUEUE_LIST_FAILURE:
      return {
        ...state,
        survey_queue: {
          ...state.survey_queue,
          loading: false
        }
      };

    // Caller Queue List
    case spCallerConstant.GET_CALLER_QUEUE_LIST_REQUEST:
      return {
        ...state,
        caller_queue: {
          ...state.caller_queue,
          callerQueueIds: []
        }
      };
    case spCallerConstant.GET_CALLER_QUEUE_LIST_SUCCESS:
      return {
        ...state,
        caller_queue: {
          ...state.caller_queue,
          ...action.data
        },
        survey_queue: {
          ...initialState.survey_queue
        }
      };
    case spCallerConstant.GET_CALLER_QUEUE_LIST_FAILURE:
      return {
        ...state
      };

    // Caller Detail
    case spCallerConstant.GET_CALLER_DETAIL_REQUEST:
      return {
        ...state,
        caller_detail: {
          ...initialState.caller_detail
        }
      };
    case spCallerConstant.GET_CALLER_DETAIL_SUCCESS:
      return {
        ...state,
        caller_detail: {
          ...initialState.caller_detail,
          ...action.data.data,
          copyData: {
            ...JSON.parse(JSON.stringify(action.data.data))
          }
        }
      };
    case spCallerConstant.GET_CALLER_DETAIL_FAILURE:
      return {
        ...state
      };
    case spCallerConstant.UPDATE_CALLER_DETAIL_REDUCER:
      return {
        ...state,
        caller_detail: {
          ...action.data
        }
      }

    // no cases for caller detail update, case only for history update failure
    // incase of history push failure pop the history data
    case spCallerConstant.ADD_HISTORY_TO_CALLER_DETAIL_FAILURE:
      return {
        ...state,
        caller_detail: {
          ...state.caller_detail,
          history: (state.caller_detail.history || []).filter(h => h.attempt !== action.data.attempt)
        }
      }

    default:
      return state;
  }
}
