import { yellow } from "@mui/material/colors";

// const ProfileStyles = makeStyles((theme) => ({
const ProfileStyles = (theme) => ({
  //Take Survey Header
  rootHeader: {
    flexGrow: 1,
  },
  headerAppBar: {
    height: 56,
    position: "relative"
  },
  headerToolBar: {
    height: 56,
  },
  headerRightBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerUserInfo: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "10px",
    "& span": {
      marginRight: "5px",
      height: "26px",
      lineHeight: "26px",
    },
  },
  logo: {
    marginRight: "20px",
    flexGrow: 1,
  },
  headerLogoImg: {
    maxHeight: "45px",
  },

  //Preview Opitions
  rootBar: {
    height: "56px !important",
  },
  rootToolBar: {
    minHeight: "56px !important",
  },
  rootPreview: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - 350px)`,
    marginRight: "350px",
  },
  pe_drawer: {
    width: "350px",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      padding: "15px",
      background: "#E3E4E6",
    },
  },
  drawerPaper: {
    width: "350px",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  //Preview Opitions

  takeSurvey_container: {
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "6px",
    padding: "15px",
  },

  //Tabs
  rightPanelAppBarRoot: {
    background: "transparent",
    height: "45px",
  },
  tabcharlimit: {
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  rightPanelTabsRoot: {
    "& .MuiTab-root": { maxWidth: "100%" },
  },
  rightPanelTabRoot: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#EBECED",
    margin: "0px 4px",
    color: "rgba(0, 0, 0, 0.87) !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#54C0FD !important",
    },
    "&:hover .peTabEdit, &:hover .peTabDelete": {
      visibility: "visible !important",
      filter: "brightness(0) invert(1)",
    },
    "&$selected": {
      color: "#fff",
      backgroundColor: "#54C0FD",
    },
  },
  rightPanelTabSelected: {
    backgroundColor: "#54C0FD !important",
    color: "#fff !important",
  },

  surveyCateDescription: {
    color: "black",   
    fontWeight: "bold",  
    fontStyle: "normal" 
  },

  //Multi Quest Answer
  answerSet: {
    padding: "0px 6px",
    "& .answerSettxt": {
      fontSize: "10px !important",
      wordBreak: "break-word",
      overflowWrap: "anywhere !important",
    },
  },
  answerSetTxt:{
    overflowWrap: "anywhere !important",
  },
  singleSelect: {
    padding: "6px",
  },
  singleSelectTile: {
    padding: "10px",
    width: "100%",
    padding: "5px",
    borderRadius: "0px",
    minWidth: "50px !important",
    color: "#000",
    "&:hover": {
      backgroundColor: "#EBECED",
    },
    "& span.MuiButton-label": {
      padding: "0px !important",
    },
    "&.activeSelect": {
      background: "#FFA12D",
    },
    "&.activeSelectBlue": {
      background: "#54C0FD",
    },
    "&.op0": {
      backgroundColor: "#999999 ",
    },
    "&.op1": {
      backgroundColor: "#FFA12D ",
      opacity: "10%",
    },
    "&.op2": {
      backgroundColor: "#FFA12D ",
      opacity: "20%",
    },
    "&.op3": {
      backgroundColor: "#FFA12D",
      opacity: "30%",
    },
    "&.op4": {
      backgroundColor: "#FFA12D",
      opacity: "40%",
    },
    "&.op5": {
      backgroundColor: "#FFA12D",
      opacity: "50%",
    },
    "&.op6": {
      backgroundColor: "#FFA12D",
      opacity: "60%",
    },
    "&.op7": {
      backgroundColor: "#FFA12D",
      opacity: "70%",
    },
    "&.op8": {
      backgroundColor: "#FFA12D",
      opacity: "80%",
    },
    "&.op9": {
      backgroundColor: "#FFA12D",
      opacity: "90%",
    },
    "&.op10": {
      backgroundColor: "#FFA12D",
      opacity: "100%",
    },
  },

  singleQuestion: {
    borderBottom: "0.2px #EBECED solid",
    padding: "15px 0",
  },
  singleSelectQuestion: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  //Matrix
  subQuestionChoices: {
    textAlign: "center",
  },
  singleQuestionMatrix: {
    padding: "10px",
  },
  subQuestionChoicesRadio: {
    padding: "10px !important",
    "&$checked": {
      color: "#54C0FD",
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#54C0FD !important",
    },
    "&.Mui-checked": {
      color: "#FFAE4B !important",
    },
  },
  table: {
    "& .MuiTableCell-root": {
      padding: "0px 5px",
      borderBottom: "none !important",
    },
    "& tr th.matrixQuesCol": {
      position: "-webkit-sticky",
      position: "sticky",
      left: 0,
      zIndex: 1,
    },
    "& tr:nth-child(even) th.matrixQuesCol": {
      background: "#fff",
    },
    "& tr:nth-child(odd) th.matrixQuesCol": {
      background: "#f4f6f7",
    },
    "& thead tr th.MuiTableCell-head": {
      position: "-webkit-sticky",
      position: "sticky",
      background: "#fff",
      top: 0,
      zIndex: "99 !important",
    },
    "& thead tr th.matrixAbsCol": {
      left: 0,
      zIndex: "999 !important",
    },
  },
  matricTabHeader: {
    "& .MuiTableCell-head": {
      fontSize: "12px",
    },
  },
  matrixTable: {
    position: "relative",
    "& .MuiTableRow-root, .MuiTableRow-root, .MuiTableCell-head": {
      height: 40,
    },
  },

  //YesorNo
  yesNoSingleQuestion: {
    borderBottom: "0.2px #EBECED solid",
    padding: "15px 0",
    "& .MuiButton-root": {
      marginRight: "10px",
      borderRadius: 0,
    },
  },
  yesNoActive: {
    background: "#54C0FD !important",
  },

  //Dropdown
  dropdownSingleQuestion: {
    borderBottom: "0.2px #EBECED solid",
    padding: "15px 0",
  },
  dropdownTextBox: {
    padding: "0px",
    "& .MuiAutocomplete-popper": {
      border: "1px solid",
    },
  },
  //Open Ended
  textAreaResize: {
    "& textarea": {
      resize: "auto",
    },
  },

  //Preview Options
  pe_drawer: {
    "& .MuiDrawer-paper": {
      padding: "15px",
      background: "#E3E4E6",
    },
  },

  //Expansion Panel
  peaccordion_nocolor: {
    margin: "0px !important",
    width: "100%",
  },
  peaccordion_nocolor: {
    "&.MuiPaper-root": {
      borderBottom: "1px solid #E9E9E9",
      width: "100%",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // boxShadow: '0px 0px 4px #0000000F !important',
      color: "#292929 !important",
      background: "#fff !important",
      // borderTop: '1px solid #E9E9E9',
      borderBottom: "1px solid #E9E9E9",
    },
    "& .panel-active": {
      color: "#292929 !important",
    },
  },

  takeSurveyFooter: {
    height: "50px",
    background: "#54C0FD",
  },

  textDanger: {
    color: "red",
  },

  // QuestionPanel styles
  buttonFix: {
    position: "absolute",
    bottom: 0,
    right: 0,
    marginRight: "20px",
    marginBottom: "30px",
  },

  //openended
  bulk_ans_textarea: {
    minWidth: "320px",
    lineHeight: "24px",
    resize: "vertical !important",
    [theme.breakpoints.down('sm')]: {
      minHeight: '78px',
      maxHeight: '140px'
    }
  },

  /**** Thank You Component Styles ****/
  thankYouRoot: {
    width: "100%",
    height: "100vh",
    display: "flex",
    background: "#fff",
    textAlign: "center",
    alignItems: "center",
    color: "#292929",
  },
  thankYouContainer: {
    // display: "flex",
    // flexDirection: "column",
    // width: "auto",
    // height: "auto",
    // textAlign: "center",
    height: "100vh",
    width: "100%",
  },
  thankYouErrorContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  gridIcon: {
    // fontSize: "3rem",
    // fontWeight: "600",
    maxWidth: "300px",
    marginBottom: "20px",
  },
  textThankYou: {
    fontFamily: "Montserrat",
    // fontSize: "3rem",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "15px",
  },
  textDescription: {
    // fontSize: "1.5rem",
    textAlign: "center",
  },
  errorText: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "600",
    color: "red",
  },
  thankYouAnimationContainer: {
    height: "100vh",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    "& img": {
      height: "100%",
      position: "absolute",
    },
  },
  thankYouAnimationMessage:{
    zIndex: "99",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "7.5rem",
    "& .thanksHeader":{
      fontSize: "4rem",
      fontWeight: "600",
      marginBottom: "1rem",
      textAlign: "center"
    },
    "& .thanksDescription":{
      fontSize: "2rem",
      textAlign: "center"
    },
  },
  /***** Footer Bar *****/
  rootFooter: {
    flexGrow: 1,
  },
  footerAppBar: {
    height: 56,
  },
  footerToolBar: {
    height: 56,
  },
  takeActionMobile: {
    position: "fixed",
    left: "0",
    bottom: "0",
    padding: "1rem",
    background: "#fff",
    boxShadow: "0px 0px 20px 0px #c5c5c5",
    zIndex: "99999 !important",
    display: "none !important",
  },
  footerBox: {
    padding: "0.75rem 1rem",
    background: "#fff",
  },
  mobileLangDropDown: {
    width: "40%",
    position: "absolute",
    bottom: "0px",
    right: "1rem",
  },
  "@media screen and (max-width:768px)": {
    subQuestionChoicesRadio: {
      padding: "10px !important",
      "&$checked": {
        color: "#54C0FD",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#54C0FD !important",
      },
      "&.Mui-checked": {
        color: "#54C0FD !important",
      },
    },
    yesNoActive: {
      background: "#54C0FD !important",
    },
    showDiv: {
      display: "none !important"
    },
    thankYouAnimationContainer: {
      height: "100vh",
      background: "#fff",
      justifyContent: "flex-end",
      "& img": {
        width: "100%",
        height: "auto",
        position: "relative"
      },
    },
    rootHeaderMobile: {
      "& header": {
        height: "80px",
        position: "relative",
      },
    },
    headerLogoImg: {
      maxHeight: "45px",
    },
    matrixTable: {
      position: "relative",
    },
    singleSelectTile: {
      minWidth: "25px !important",
      fontSize: "10px"
    },
    answerSet: {
      minWidth: "25px",
      padding: "0px 2px",
      "& .answerSettxt": {
        fontSize: "10px !important",
        wordBreak: "break-word",
        overflowWrap: "anywhere !important",
      },
    },
  },
  takeAnotherSurveyButton: {
    marginTop:'40px',
    padding: '4px 16px !important',
    color: "#000", 
    fontSize: "18px", 
    border: "3px solid #56C1FD", 
    background: "#eef9ff", 
    fontWeight: "500",  
    "&:hover": {
      background: "#56C1FD",
      color: "#fff",
    }
},
});

export default ProfileStyles;
