/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Import Styles and Image
import { BackPage } from "../../../assets/images/svgComponents/ImageComponent";

import ParticipantStyles from "../Pariticipant/PariticipantStyles";

// Import Constants
import { participantConstants } from "../../../config/constants";
import { participantConstant, metadataConstant } from "../../../redux/constants";

// Import Actions
import { updateStateReducerAction, clientAction, metadataAction } from "../../../redux/actions";

// Import History
import history from "../../../config/history";

// Import Container
import Container from "../../Containers/Container";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import CancelDialogComponent from "../../../components/CancelDialogComponent/CancelDialogComponent";

// Import Panels
import DefaultPanel from "./DemographicsPanels/DefaultPanel";
import AutomatedPanel from "./DemographicsPanels/AutomatedPanel";
import OtherDemographicsPanel from "./DemographicsPanels/OtherDemographicsPanel";

function DemographicsSetup (props) {
  // Assign Props
  const { classes } = props;

  // Get Data from Redux
  const participant = useSelector((state) => state.participant.getOne);
  const metadataWithRGI = useSelector((state) => state.metadata.metadataWithRGI);
  let metadata = useSelector((state) => state.metadata.metadata);
  const externalApiAccess = useSelector(state => state.client.getOne.externalApiAccess);  
  metadata = JSON.parse(JSON.stringify(metadata));

  // Define State
  const [isCancel, setCancel] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [initMetaData, setInitMetaData] = useState(JSON.parse(JSON.stringify(metadata)));

  // Ref
  const formRef = useRef(null);

  // Define Dispatch
  const dispatch = useDispatch();

  /**
   * Fetch Participant Data
   */
  useEffect(() => {
    dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_INIT));
    dispatch(metadataAction.getClientMetadata())
  }, [dispatch]);

  // Set Validatore Rules
  useEffect(() => {
    ValidatorForm.addValidationRule("isSameCustomName", (value) => {
      return value === "" ? true : (metadata.filter(d => (d.value.toLowerCase() === value.toLowerCase()) && d.visible === true)).length > 1 ? false : true;
    });
  }, [metadata]);

  /**
   * Handle Cancel
   */
  const handleCancel = () => {
    setCancel(true);
  };
  /**
   * On Handle Cancel & Delete, cancel
   */
  const initActions = () => {
    setCancel(false);
  };

  /**
   * On handle Cancel, No
   */
  const backToParticipantsList = () => {
    dispatch(
      updateStateReducerAction.update(
        metadataConstant.UPDATE_METADATA_REDUCER,
        initMetaData
      )
    );
    handleBack();
  };

  /**
   * On Handle Cancel, Save
   */
  const handleParticipantYes = () => {
    formRef.current.submit();
    setCancel(false); // close cancel dialog
    handleBack();
  };

  /**
   * Handle Change
   */
  const handleChange = (e) => {
    let isNewMetaData = true;
    metadata.filter((d) => {
      if (d.key === e.name) {
        typeof e.value === "boolean"
          ? (d.visibleInFilter = e.value)
          : (d.value = e.value);
        isNewMetaData = false;
      }
    });
    isNewMetaData &&
      metadata.push({
        key: e.name,
        value: e.value
      });
    dispatch(
      updateStateReducerAction.update(
        metadataConstant.UPDATE_METADATA_REDUCER,
        metadata
      )
    );
  };
 //api key handlechange
  const handleApiKeyChange = (e) => {
    let isNewMetaData = true;
    metadata.filter((d) => {
      if (d.key === e.name) {
        typeof e.value === "boolean"
          ? (d.visibleInFilter = e.value)
          : (d.api_key_value = e.value);
        isNewMetaData = false;
      }
    });
    isNewMetaData &&
      metadata.push({
        key: e.name,
        api_key_value: e.value
      });
    dispatch(
      updateStateReducerAction.update(
        metadataConstant.UPDATE_METADATA_REDUCER,
        metadata
      )
    );
  };
  /**
   *Handle Back
   */
  const handleBack = () => {
    history.push({
      pathname: "/manage_people"
    });
  };

  /**
   * Handle Submit
   */
  const handleSubmit = () => {
    // remove isDeletable key from metadata
    const updatedMetadata = metadata.filter(d => d.value !== '').map(m => {
      delete m.isDeletable;
      return m
    });
    dispatch(clientAction.updateDemographics({ metadata: updatedMetadata }, () => {
      dispatch(metadataAction.getClientMetadata(() => {
        dispatch(metadataAction.getMetadataWithRGI(() => {
          handleBack();
        }));
      }));
    }));
  };

  return (
    <React.Fragment>
      <ValidatorForm
        ref={formRef}
        id="setup_demographic"
        name="Setup Demographic"
        autoComplete="off"
        onSubmit={handleSubmit}
        onError={(error) => {
          initActions();
          console.log(error);
        }}
      >
        <Container align={"baseline"}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid onClick={() => history.push("/manage_people")}
                        className={classes.backimg}>
                      {BackPage()}
                      </Grid>
                      <TypographyComponent
                        variant={"h3"}
                        className="pl-1"
                        title={participantConstants.SETUP_DEMOGRAPHICS}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <div className={"container"}>
                <Grid container className="pb-2 pt-1">
                  <TypographyComponent variant="h5">
                    {participantConstants.SETUP_DEMOGRAPHICS_DESC}
                  </TypographyComponent>
                </Grid>

                {/* Deafault Panel */}
                <DefaultPanel
                  metadata={metadata}
                  participant={participant}
                  handleChange={handleChange}
                  handleApiKeyChange = {handleApiKeyChange}
                  externalApiAccess ={externalApiAccess}
                  classes={classes}
                ></DefaultPanel>

                {/* Assigned Survey Panel */}
                <AutomatedPanel
                  metadata={metadata}
                  handleChange={handleChange}
                  participant={participant}
                  metadataWithRGI={metadataWithRGI}
                  externalApiAccess ={externalApiAccess}
                  handleApiKeyChange = {handleApiKeyChange}
                  classes={classes}
                ></AutomatedPanel>

                {/* Other Demographics Panel */}
                <OtherDemographicsPanel
                  participant={participant}
                  metadata={metadata}
                  handleChange={handleChange}
                  externalApiAccess ={externalApiAccess}
                  metadataWithRGI={metadataWithRGI}
                  classes={classes}
                />
              </div>
            </Grid>
          </Grid>
        </Container>

        <Grid
          container
          justifyContent="flex-end"
          className={classes.footerBtns_container}
        >
          <Grid item>
            <ButtonComponent
              title={participantConstants.SAVE_BUTTON}
              color={"primary"}
              size={"small"}
              type="submit"
              pageClassName="mr-1"
            ></ButtonComponent>
            <ButtonComponent
              title={participantConstants.CANCEL_BUTTON}
              color={"default"}
              size={"small"}
              type={"button"}
              handleClick={handleCancel}
            ></ButtonComponent>
          </Grid>
        </Grid>
      </ValidatorForm>
      <CancelDialogComponent
        open={isCancel}
        content={participantConstants.DEMOGRAPHY_ALERT}
        handleCancelAction={initActions}
        handleOnNoAction={backToParticipantsList}
        handleOnYesAction={handleParticipantYes}
      />
    </React.Fragment>
  );
}

// default props
DemographicsSetup.defaultProps = {
  classes: {}
};
// prop types
DemographicsSetup.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ParticipantStyles)(DemographicsSetup);
