/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-key */
/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import TextField from '@mui/material/TextField';
import DlaRole from "../../Reports/DlaRole";
import CloseIcon from '@mui/icons-material/Close';
// Import Styles and Image
import { keyDriver } from "../../../assets/images/svgComponents/ImageComponent";

import ActionsStyles from "../ActionsStyles";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import Filter from '../../Filter/Filter';
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import NewActionPlan from "../NewActionPlan";

// Import Loadsh
import _ from "lodash";

//Import Constants
import { ActionConstants } from "../../../config/constants";

// Import Actions
import { opportunityAreasAction, dashboardAction, updateStateReducerAction } from "../../../redux/actions";

// Import History
import { opportunityAreas, actionsConstant, filterConstant } from "../../../redux/constants";

//Import Helper
import { queryDLASpliter, generateOverViewText, updateLinksInHTML } from "../../../helpers";
import ToolTipComponent from '../../../components/ToolTipComponent/ToolTipComponent';

function OpportunityAreas(props) {
  const [expanded, setExpanded] = React.useState("default-panel");
  const [open, setOpen] = React.useState(false);
  const [split, setsplit] = React.useState(14);

  // Get props
  const { classes } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  // Get Particular Plan from store
  const profile = useSelector(state => state.profile);
  const { filterData } = useSelector(state => state.filter);
  const { getOne, OpenNewPlan } = useSelector(state => state.opportunity);
  const { preference } = useSelector(state => state.admin);
  const { dashboardData: { keydriver } } = useSelector(state => state.dashboard);

  const hasMESurvey = (filterData.surveys || []).filter(f => f.type === "Multi-rater feedback").length > 0
  useEffect(() => {
    dispatch(updateStateReducerAction.update(actionsConstant.ACTION_PLAN_GET_ONE_CLEAR, {}))
  }, [getOne]);

  useEffect(() => {
    if (!profile.user.queryDLA || profile.user.queryDLA === "") {
      dispatch(dashboardAction.getKeyDriverData(() => { }, false))
    }
  }, [preference]);

  /**
  * Action Dispatching to Particular Action Plan
  */
  useEffect(() => {
    dispatch(opportunityAreasAction.getOne(filterData));
    dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, preference))
  }, [preference.client_id]);

  let opportunities = [...getOne];

  // Added keydriver flag in oppurtunity
  let keydriverIds = keydriver.map((k) => k.baseQusId)
  opportunities.map((val) => {
    val["iskeyDriver"] = 0
    val["corelation"] = 0
    keydriver.map((k) => {
      if (k.baseQusId === val.baseQusId) {
        val["iskeyDriver"] = 1
        val["corelation"] = k.corelation
      }
    })
  })

  // Sort with keyDriver
  if (keydriverIds.length > 0) {
    opportunities = _.orderBy(opportunities, ["corelation"], ["desc"])
    opportunities = opportunities.filter((o, i) => ((o.iskeyDriver === 1 && i < 10) || o.iskeyDriver === 0))
  }


  //To Convert the Query string in Readable Format
  if (profile.user.queryDLA) {
    profile.user.queryDLA = queryDLASpliter(profile.user.queryDLA);
  }

  //Set default drawer state
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(expanded === panel ? null : panel);
  };

  //To Submit 
  function handleSubmit(e) {
    e.preventDefault();
  }

  //Close New Add Plan Pop Up
  const handleNewPlanClose = () => {
    setOpen(false);
    dispatch({
      type: opportunityAreas.TOGGLE_ADD_PLAN_DIALOG,
      data: false
    })
    dispatch({
      type: actionsConstant.TOGGLE_ADD_PLAN_DIALOG
    })
  };

  /**
   * Handle Add Plan click
   * @param {*} id
   */
  const handleAddplan = (e, opt, index) => {
    dispatch({
      type: opportunityAreas.TOGGLE_ADD_PLAN_DIALOG,
      data: true
    })

    dispatch({
      type: actionsConstant.OPPORTNITY_AREA_CHOICES,
      data: opt
    })
  };

  /**
   * Show More Option
   */
  const showMore = () => {
    setsplit(opportunities.length)
  }

  /**
   * Show Less Functionality
   */
  const showLess = () => {
    setsplit(14)
  }

  // Save Filter Changes and Get Data Based On Filter
  const filterSaveEvent = (data) => {
    dispatch(opportunityAreasAction.getOne(data));
  };

  /**
* Handle Add aciton Items
*/
  function handleAddactionItems(e, item, index) {
    item.actionItems.Default = item.actionItems && item.actionItems.Default ? item.actionItems.Default : [];
    opportunities[index].actionItems.Default = [...item.actionItems.Default, ""]
    dispatch(updateStateReducerAction.update(opportunityAreas.OPPORTUNITY_GET_ONE_UPDATE, opportunities));
  }

  /**Filter title */
  let titleFilter = generateOverViewText('full', filterData.surveys, filterData.date);

  /**
     * Handle form change
     */
  const handleChangeAct = (actItems, index) => {
    const updatedOpp = [...opportunities];
    updatedOpp[index]["actionItems"]["Default"] = actItems;
    dispatch(updateStateReducerAction.update(opportunityAreas.OPPORTUNITY_GET_ONE_UPDATE, updatedOpp));
  };


  /**Recommandations*/
  const renderArea = (optItem, index) => {
    const defaultItems = optItem && optItem.actionItems ? optItem.actionItems.Default || [] : []
    return (
      <Grid className="width100">
        <Grid container spacing={2}
          className={`${classes.setting_expandcontainer}`} >
          <Grid item sm={12} className="pt-2">
            {defaultItems.map((actions, i) => {
              const linkRx = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1/;
              actions = updateLinksInHTML(actions)
              return (
                <Grid container alignItems="center" className="pb-1" key={`oppDiv_${i}`}>
                  <Grid item sm={12} className={classes.Addaction_container}>
                    <TextField
                      id={"actionItems"}
                      name={"actionItems"}
                      className={`${classes.ActionMultiLineText} ${"bordernone fieldset bgGrey width100 hauto lineHeight18"}`}
                      type={"text"}
                      // size={"small"}
                      multiline
                      value={actions}
                      onChange={(e) => {
                        const actionItems = [...optItem["actionItems"]["Default"]];
                        actionItems[i] = e.target.value;
                        handleChangeAct(actionItems, index);
                      }}
                      variant="outlined"
                    />
                    <Grid className="txtright"
                      onClick={(e) => {
                        const actionItems = [...optItem["actionItems"]["Default"]];
                        actionItems.splice(i, 1);
                        handleChangeAct(actionItems, index);
                      }}
                    >
                      <CloseIcon />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <ValidatorForm
        id="actionEditForm"
        name="actionEditForm"
        useref="actionEditForm"
        autoComplete="off"
        onSubmit={handleSubmit}
        className={'width100'}
        onError={(errors) => console.log(errors)}>
        <Grid container spacing={2} alignItems="stretch" className={`${classes.opportunityContainer} ${"mb-2"}`}>
          {/* Reports Imformation Container */}
          {profile.user.queryDLA && profile.user.queryDLA != " " &&
            <DlaRole />
          }
          {/* Reports Imformation Container */}

          <Grid item sm={12}>
            <Grid container justifyContent="space-between">
              <Grid item sm={12} className={"pr-2"}>
                <TypographyComponent variant="h6">
                  {ActionConstants.OPURTUNITY_DESCRIPTION_KEY_DRIVER_1}
                  <br />
                  <Link
                    href="https://20709424.hs-sites.com/understanding-key-drivers"
                    target="_blank"
                    className={"pr5"}
                  >
                    {"Click here"}
                  </Link>
                  {ActionConstants.OPURTUNITY_DESCRIPTION_KEY_DRIVER_2}
                </TypographyComponent>
              </Grid>
              <Grid item className={`${classes.ActionsSettings} ${"pl-2"}`}>
                <Grid container justifyContent="flex-end" alignItems="center" className="flexnowrap">
                  <ToolTipComponent title={titleFilter} placement={hasMESurvey ? 'bottom-end' : 'bottom'}>
                    <Grid item><TypographyComponent variant="h6" className={`cursor-pointer ${classes.ActionsSettingsText}`}>{'type' in filterData.date && titleFilter}</TypographyComponent></Grid>
                  </ToolTipComponent>

                  {/* Filter */}
                  <Filter className={classes.ActionsSettingsIcon} filterSaveEvent={filterSaveEvent} />

                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12} >
            {opportunities && opportunities.map((optItem, index) => (
              <React.Fragment>
                {index <= split ?
                  <Accordion className="mb-2 pe-accordion"
                    expanded={expanded === index}
                    key={`oppAcc_${index}`}
                    onChange={handlePanelChange(index)}>
                    <AccordionSummary
                      className={classes.actionCard}
                      style={{alignItems:"center"}}
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header">
                      <Grid item sm={10} style={{display:"flex"}}>
                      <svg xmlns="http://www.w3.org/2000/svg" className={`${classes.Accord_icons} ${expanded === index ? "panel-active" : ""}`} width="13.774" height="16.528" viewBox="0 0 13.774 16.528" style={{ fill: '#333' }}><defs></defs><g transform="translate(0 2.41)"><path className="a" d="M56.382,86.2,55.2,84.227a5.859,5.859,0,0,1-.834-3.012V79.488a4.821,4.821,0,0,0-9.641,0v1.727a5.859,5.859,0,0,1-.834,3.012L42.707,86.2a.344.344,0,0,0,.3.521H56.087a.344.344,0,0,0,.3-.521ZM43.61,86.03l.87-1.45a6.541,6.541,0,0,0,.932-3.366V79.488a4.132,4.132,0,1,1,8.264,0v1.727a6.547,6.547,0,0,0,.932,3.366l.87,1.45Z" transform="translate(-42.657 -74.667)" /></g><g transform="translate(5.51)"><path class="a" d="M214.71,0a1.379,1.379,0,0,0-1.377,1.377V2.755a.344.344,0,0,0,.689,0V1.377a.689.689,0,1,1,1.377,0V2.755a.344.344,0,0,0,.689,0V1.377A1.379,1.379,0,0,0,214.71,0Z" transform="translate(-213.333)" /></g><g transform="translate(5.165 13.774)"><path class="a" d="M205.878,426.849a.345.345,0,1,0-.6.348,1.033,1.033,0,1,1-1.789,0,.345.345,0,0,0-.6-.348,1.722,1.722,0,1,0,2.979,0Z" transform="translate(-202.666 -426.68)" /></g></svg>
                      {/* <img src={BellImg}
                        className={`${classes.Accord_icons} ${expanded === index ? "panel-active" : ""}`}
                        alt={"settings"} /> */}
                      <Grid item>
                        <TypographyComponent
                          className={`${expanded === index ? "panel-active" : ""} `}
                          variant={"h6"}>
                          {optItem.questionText}
                          <span className={"pl5"}>{optItem.iskeyDriver ? <ToolTipComponent title={"Key Driver"}>{keyDriver()}</ToolTipComponent> : ""}</span>
                        </TypographyComponent>
                        <TypographyComponent
                          className={`${""} ${expanded === index ? "panel-active" : ""} `}
                          variant={"body1"}>
                          {optItem.category}
                        </TypographyComponent>
                      </Grid>
                      </Grid>
                      <Grid item sm={2}>
                          <Grid container justifyContent={'flex-end'} sx={{paddingRight: '30px'}}>
                            {/* <Link
                              component="button" onClick={(e) => handleAddactionItems(e, optItem, index)}
                            >
                              {ActionConstants.ADD_ACTION}
                            </Link> */}
                            <ButtonComponent
                              color="primary"
                              pageClassName="buttonOrange"
                              handleClick={(e) => handleAddplan(e, optItem, index)}
                              title="+Add Plan"></ButtonComponent>
                          </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails className={classes.ActionExpand}>
                      {renderArea(optItem, index)}
                    </AccordionDetails>
                  </Accordion>
                  : ""}
              </React.Fragment>
            ))}
          </Grid>
        </Grid>
        <Grid item md={12} className="txtcenter">
        {opportunities.length > 14 ? split <= 14 ? (
          <ButtonComponent
            title={"View More 🡣" }
            handleClick ={showMore}
            color="primary" 
            size="small"   
            className={classes.ViewButton}
          >
          </ButtonComponent>
        ) : (
          <ButtonComponent
            title={"View Less 🡡"}
            handleClick={showLess}
            color="primary" 
            size="small"   
            className={classes.ViewButton}
          >
          </ButtonComponent>
        ) : ""}
      </Grid>
      </ValidatorForm>
      {OpenNewPlan && <DialogComponent fullScreen open={OpenNewPlan}>
        <NewActionPlan handleNewPlanClose={handleNewPlanClose} open={OpenNewPlan}></NewActionPlan>
      </DialogComponent>}
    </React.Fragment>
  );
}
// default props
OpportunityAreas.defaultProps = {
  classes: {}
};

// prop types
OpportunityAreas.propTypes = {
  classes: PropTypes.object
};

export default withStyles(ActionsStyles)(OpportunityAreas);
