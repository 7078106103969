import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

import withStyles from '@mui/styles/withStyles';
// Import panels
import Email from "./MessageTab/Email";
import Sms from "./MessageTab/Sms";
import Kiosk from "./MessageTab/Kiosk";
import Transflo from "./MessageTab/Transflo";
import WelcomeMessage from "./MessageTab/WelcomeMessage";
import ThankYouMessage from "./MessageTab/ThankYouMessage";

import SelectFieldComponent from "../../../components/SelectFieldComponent/SelectFieldComponent"

import { contentLibraryConstants } from "../../../config/constants"
import { contentLibraryAction } from "../../../redux/actions"

import ContentLibraryStyles from "../ContentLibraryStyles";



function Message(props) {

  const { classes, match } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(contentLibraryAction.getMessage());
  }, [dispatch]);

  const settingsForm = useRef(null);
  const { message } = useSelector(state => state.contentLibrary)

  const [expanded, setExpanded] = useState("setup-panel");
  const [errorPanels, setErrorPanels] = useState({});
  const [item, setItem] = useState({})


  const handleSurveyTypechange = (data) => {
    let selectedItem = message.filter((typ) => typ.type === data.value)[0]
    setItem(selectedItem)
  }

  const handleTabChange = (data) => {
    const { panel } = data;
    // setExpanded(isExpanded ? panel : false);
    setExpanded(panel);
  };

  const handleDataChange = (data) => {
    setItem(data);
  };

  function handleSubmit(e) {
    e.preventDefault();
  }

  const handleCancel = () => {
    if (item) {
      setItem(null)
      dispatch(contentLibraryAction.getMessage());
    }
  }


  return (
    <React.Fragment>

      <ValidatorForm
        id="contentLibraryMessagePanelForm"
        className={"width100"}
        name="contentLibraryMessagePanelForm"
        useref="contentLibraryMessageForm"
        ref={settingsForm}
        autoComplete="off"
        onSubmit={handleSubmit}
        onError={(error) => {
          const errPanels = {};
          error.map(({ props: { panel } }) => {
            if (!errPanels[panel]) {
              errPanels[panel] = true;
            }
          });
          setErrorPanels(errPanels);
          console.log(error);
        }}>
        <div className={"containerTop0"}>
          <Grid sm={12} className={"mb-2 dflex justifyend"}>
            <Grid sm={2}>
              <SelectFieldComponent
                select
                name="surveytype"
                value={item ? item.type : "Engagement"}
                validators={["required"]}
                disabled={false}
                fullWidth={true}
                handleChange={(e) => handleSurveyTypechange(e)}
                options={contentLibraryConstants.SURVEY_TYPES}
              />
            </Grid>
          </Grid>

          {/* setup panel */}
          <Email
            data={item || message[0]}
            messageData={message}
            classes={classes}
            expanded={expanded}
            handleTabChange={handleTabChange}
            editMessageContent={handleDataChange}
            handleCancelMessage={handleCancel}
            panel={"Email"}
            panelError={errorPanels.Email} />

          {/* audience panel */}
          <Sms
            data={item || message[0]}
            messageData={message}
            classes={classes}
            expanded={expanded}
            handleCancelMessage={handleCancel}
            handleTabChange={handleTabChange}
            editMessageContent={handleDataChange}
            panel={"Sms"}
            panelError={errorPanels.Sms}
            settingsForm={settingsForm} />

          {/* responses panel */}
          <Kiosk
            data={item || message[0]}
            messageData={message}
            classes={classes}
            expanded={expanded}
            handleCancelMessage={handleCancel}
            handleTabChange={handleTabChange}
            editMessageContent={handleDataChange}
            panel={"Kiosk"}
            panelError={errorPanels.Kiosk} />

          {/* survey distribution option panel */}
          <Transflo
            data={item || message[0]}
            messageData={message}
            classes={classes}
            expanded={expanded}
            handleCancelMessage={handleCancel}
            handleTabChange={handleTabChange}
            editMessageContent={handleDataChange}
            panel={"Transflo"}
            panelError={errorPanels.Transflo}
            settingsForm={settingsForm} />

          {/* communication panel */}
          <WelcomeMessage
            data={item || message[0]}
            messageData={message}
            classes={classes}
            expanded={expanded}
            handleCancelMessage={handleCancel}
            handleTabChange={handleTabChange}
            editMessageContent={() => handleDataChange}
            panel={"WelcomeMessage"}
            panelError={errorPanels.WelcomeMessage} />

          {/* phone collection panel */}
          <ThankYouMessage
            data={item || message[0]}
            messageData={message}
            classes={classes}
            expanded={expanded}
            handleCancelMessage={handleCancel}
            handleTabChange={handleTabChange}
            editMessageContent={() => handleDataChange}
            panel={"ThankYouMessage"}
            panelError={errorPanels.ThankYouMessage} />

        </div>
      </ValidatorForm>
    </React.Fragment>
  )
}

export default withStyles(ContentLibraryStyles)(Message);

