import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import ChipInput from '@jansedlon/material-ui-chip-input';
import Chip from "@mui/material/Chip";

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";

// import helpers
import { IsEmail } from "../../../../../helpers/appHelper";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../../../components/SelectFieldComponent/SelectFieldComponent";
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";

// import constants
import { ReportsConstants } from "../../../../../config/constants";

//Import Actions
import { reportAction, alertAction } from "../../../../../redux/actions";

function SaveReport(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // get props
  const { classes, isDrawer } = props;

  const { reportData } = useSelector((state) => state.report);

  let reportSchedules = [{ "displayName": "Weekly", "name": "Weekly" }, { "displayName": "Monthly", "name": "Monthly" }, { "displayName": "Quarterly", "name": "Quarterly" }]

  //add daily to the report frequency
  if(reportData && reportData.reportType && reportData.reportType === "Participation" || reportData.reportType === "Rehire Report" || reportData.reportType === "Red Flag Report") {
    reportSchedules = [{ "displayName": "Daily", "name": "Daily" }, ...reportSchedules]
  }


  //add emailIDs
  const handleAddChipOnblur = (chipvalue) => {

    let chip = chipvalue.target.value;
    if (chip && chip !== null && chip !== " ") {
      let emailIdArr = chip.split(/[\s,\;]+/);
      let resultEmails = emailIdArr;
      let allemails = [...reportData.emailIds, ...resultEmails]
      reportData.emailIds = allemails.filter((e) => e !== "")
      dispatch(reportAction.updateReport(reportData));
    }

  }



  const handleAddChip = (chip) => {
    if (chip && chip !== null && chip !== " ") {
    let emailIdArr = chip.split(/[\s,\;]+/);
    let resultEmails = emailIdArr
    let allemails = [...reportData.emailIds, ...resultEmails]
    reportData.emailIds = allemails.filter((e) => e !== "")
    dispatch(reportAction.updateReport(reportData));
    }

  }

  const getEmailChips = (emailArr) => {
    let resultEmails = [];
    if (emailArr){
      emailArr.map((i) => {
        if (i.email) {
          resultEmails.push(i)
        }
        else {
          if (IsEmail(i.trim())) {
            resultEmails.push({ email: i.trim().toLowerCase(), isValid: true });
          } else {
            resultEmails.push({ email: i.trim().toLowerCase(), isValid: false });
          }
        }
      });
    }
    return resultEmails;
  }

  // const handleAddChip = (chip) => {
  //   let emailIdArr = chip.split(/[\s,\;]+/);
  //   let resultEmails = [];
  //   let invalidEmails = [];
  //   emailIdArr.map((i) => {
  //     if (IsEmail(i.trim())) {
  //       resultEmails.push(i.trim());
  //     }reportData.emailIds else {
  //       invalidEmails.push(i.trim());
  //     }
  //   });
  //   reportData.emailIds = [...reportData.emailIds, ...resultEmails]
  //   dispatch(reportAction.updateReport(reportData));
  //   if (invalidEmails.length > 0) {
  //     dispatch(alertAction.error("Invalid email"));
  //   }
  // }

  //delete email Ids
  const handleDeleteChip = (chip, index) => {
    reportData.emailIds.splice(index, 1)
    dispatch(reportAction.updateReport(reportData));
  }

  //update name fileds
  const updateReportName = (e) => {
    const updatedReports = { ...reportData, name: e["value"] };
    dispatch(reportAction.updateReport(updatedReports));
  }

  //change schedule type
  const changeReportSchedule = (e) => {
    reportData["frequency"] = e["value"];
    dispatch(reportAction.updateReport(reportData));
  }

  //frequency help text
  const getHelpTxt = () => {
    switch (reportData.frequency) {
      case reportSchedules[0]:
        return ReportsConstants.FREQUENCYOF_REPORT_HELP_WEEKLY;
      case reportSchedules[1]:
        return ReportsConstants.FREQUENCYOF_REPORT_HELP_MONTHLY;
      case reportSchedules[2]:
        return ReportsConstants.FREQUENCYOF_REPORT_HELP_QUARTERLY;
      default:
        return ReportsConstants.FREQUENCYOF_REPORT_HELP;
    }
  }

  return (
    <React.Fragment>
      <Grid container className="pb-1 mb-2">
        <Grid item sm={12}>
          <Grid container className="pb-15 divWidth" sm={isDrawer ? 12 : 6}>
            <TypographyComponent variant="h6">
              {ReportsConstants.ENTER_REPORTNAME}
            </TypographyComponent>
            <TextFieldComponent
              id={"report_name"}
              name={"email"}
              size={"small"}
              value={reportData.name || ""}
              margin={"dense"}
              handleChange={(e) => updateReportName(e)}
              validators={["required", "isFileName"]}
            ></TextFieldComponent>
          </Grid>

          <Grid container className="pb-15">
            <TypographyComponent variant="h6">
              {ReportsConstants.EMAILOPTIONAL}
            </TypographyComponent>
            {/* <Grid item sm={6}>
              <TypographyComponent variant="h8">
                {ReportsConstants.EMAILOPTIONAL_INFO}
              </TypographyComponent>
            </Grid> */}
            <Grid item sm={12} className={classes.saveReportEmail}>
              <ChipInput
                className={`${classes.chipInput} ${classes.frequencyWidth} chipboderRadius10 ${isDrawer ? 'drawer' : ''}`}
                variant="outlined"
                color="primary"
                value={getEmailChips(reportData.emailIds) || []}
                onBlur={(chip) => handleAddChipOnblur(chip)}
                onAdd={(chip) => handleAddChip(chip)}
                onDelete={(chip, index) => handleDeleteChip(chip, index)}
                dataSource={getEmailChips(reportData.emailIds) || []}
                chipRenderer={({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => {
                  return (
                    <Chip
                      key={key}
                      label={value.email}
                      onClick={(e) => e.stopPropagation()}
                      onDelete={handleDelete}
                      className={value.isValid ? "maxWidth100" : `${classes.errorChipReport} maxWidth100`}
                    />
                  )
                }}
              />
            </Grid>
          </Grid>

          <Grid container sm={isDrawer ? 12 : 6}>
            <TypographyComponent variant="h6">
              {ReportsConstants.FREQUENCYOF_REPORT}
              <InformationComponent>
                <TypographyComponent variant="tooltipbody">
                  {getHelpTxt()}
                </TypographyComponent>
              </InformationComponent>
            </TypographyComponent>
            <Grid item sm={12}>
              <Grid className={classes.frequencyWidth}>
                <SelectFieldComponent
                  select
                  name="RGConversion"
                  value={reportData.frequency || ""}
                  fullWidth={true}
                  handleChange={e => changeReportSchedule(e)}
                  options={reportSchedules}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={isDrawer ? 12 : 6}></Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
SaveReport.defaultProps = {
  classes: {},
  isDrawer: false
};

// prop types
SaveReport.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(SaveReport);
