import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

//Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

const tHeaders = ["Avg", "% Fav", "Hi", "Lo"]
const tColumns = ["mean", "percentfavorable", "maxAns", "lowAns"]

function CompetencySummaryCard(props) {

    const { classes, item, viewBy } = props

    return (
        <React.Fragment>
            <Grid container sm={12} style={{ alignSelf: "baseline" }}>
                <Grid item xs={6} className={``}>
                    <Grid item xs={12} style={{ display: "flex", alignItems: "center", height: "100%" }}>
                        {
                            viewBy === "category" && (
                                <TypographyComponent
                                    variant={"h4"}
                                    className="txtSemiBold">
                                    {item.catName}
                                </TypographyComponent>
                            )
                        }
                        <TypographyComponent
                            variant={"h6"}
                            className={classes.txtCenter}>
                            {item.catDes}
                        </TypographyComponent>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid item xs={12} className={"dflex"}>
                        <Grid item xs={5} className={"dflex justifyend"}>
                            <table className={'competency-chart-table relationship-table'}>
                                <thead>
                                    <th></th>
                                    <th></th>
                                </thead>
                                <tbody>
                                    {
                                        item.relationships.map((rel) => (
                                            <tr>
                                                <td className="dflex justifyend"><div className="mr5">{rel.relationship}</div><div>{`(${rel.total_responseCount})`}</div></td>
                                                
                                                <td>
                                                    <Grid
                                                        className={classes.Relation_Percentage_container}
                                                        style={{
                                                            minWidth: "130px",
                                                            height: "20px",
                                                            position: "relative"
                                                        }}
                                                    >
                                                        <div className="bar-shadow" />
                                                        <Grid
                                                            className="bar-original"
                                                            style={{
                                                                width: `${(rel.mean / 5) * 100}%`,
                                                                float: "left",
                                                                zIndex: 1,
                                                                backgroundColor: `${rel.color}`
                                                            }}
                                                        >
                                                        </Grid>
                                                    </Grid>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </Grid>
                        <Grid item xs={7} className={"dflex justifyend"}>
                            <table className="competency-chart-table">
                                <thead>
                                    {
                                        tHeaders.map(th => (
                                            <th>
                                                <TypographyComponent
                                                    variant="h6"
                                                    className={`${classes.Fav} txtcenter txtgrey`}>
                                                    {th}
                                                </TypographyComponent>
                                            </th>
                                        ))
                                    }
                                </thead>
                                <tbody>
                                    {item.relationships.map((val) => (
                                        <tr>
                                            {
                                                tColumns.map(colKey => (
                                                    <td>
                                                        {val[colKey] || "0"}
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

// default props
CompetencySummaryCard.defaultProps = {
    classes: {},
    gridData: {},
    onClickButton: () => { }
};

// prop types
CompetencySummaryCard.propTypes = {
    classes: PropTypes.object,
    gridData: PropTypes.object,
    onClickButton: PropTypes.func
};

export default CompetencySummaryCard;