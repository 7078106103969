/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-key */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";

// Import Icons
import { SettingAccordGrey, SettingAccordWhite, CodeConversionGrey } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Constants
import { participantConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import DatePickerComponent from "../../../../components/DatePickerComponent/CustomDatePicker";

function AutomatedDemographicsPanel(props) {
  // Assign Props
  const { classes, handleChange, metadata, participant, metadataWithRGI } = props;

  // Disable Fields
  const disableFields = ["tenure_calculation", "age_calculation", "manager_role"];

  // Accordion
  const [expanded, setExpanded] = React.useState("automateddemographic-panel");

  const tenureGroup = participantConstants.PARTICIPANT_TENURE_GROUP.map((t => t.value))
  const generationGroup = participantConstants.PARTICIPANT_GENERATION_GROUP.map((g => g.value))
  const ageGroup = participantConstants.PARTICIPANT_AGE_GROUP.map((a => a.value))


  /**
     * Handle Panel Change Event
     * @param {*} panel
     */
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Age grouping Dropdown rgi values
  let Age_group_rgi = [...participantConstants.AUTOMATED_AGEGROUPINGS_DROPDOWN];
  if (!Age_group_rgi.includes(participant.age_groupings)) {
    Age_group_rgi.push(participant.age_groupings || "")
  }


  /**
     * Check Converstion Avilable
     * @param {*} data
     */
  const isConversionFound = (data) => {
    let flagConversion = metadata.filter(m => (m.key === data));
    return flagConversion[0] && flagConversion[0]["conversion"] && flagConversion[0]["conversion"].length > 0 ? true : false;
  };

  return (
    <Accordion
      className="mb-2 pe-accordion"
      expanded={expanded === "automateddemographic-panel"}
      onChange={handlePanelChange("automateddemographic-panel")}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {expanded === "automateddemographic-panel" ?
          <Grid className={"pr5"}>
            {SettingAccordWhite()}
          </Grid>
          :
          <Grid className={"pr5"}>
            {SettingAccordGrey()
            }
          </Grid>}
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${expanded === "automateddemographic-panel" ? "panel-active" : ""} `}
          variant={"h5"}>
          {participantConstants.AUTOMATED_DEMOGRAPHY}</TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "automateddemographic-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{participantConstants.AUTOMATED_DEMOGRAPHY_HELPTEXT}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className="p-2 width100">
          <Grid container spacing={3} className={`${classes.setting_expandcontainer}`}>
            {metadata.filter(a => a.type === "auto" && a.key !== "IntentiontoStay" && a.key !== "Engagement_Index").map(m => {
              let autoFieldRgi = []
              if (m.key === "age_groupings") {
                autoFieldRgi = ageGroup
              } else if (m.key === "tenure_groupings") {
                autoFieldRgi = tenureGroup
              } else if (m.key === "generation") {
                autoFieldRgi = generationGroup
              } else if (m.key === "Remote or In Office") {
                autoFieldRgi = ['In office', 'Remote', 'Hybrid']
              }
              return (
                <Grid item md={6} xs={12}>
                  <Grid container alignItems="center">
                    <Grid item md={5} xs={12}>
                      <TypographyComponent
                        className={classes.wordContainer}
                        title={m.value}
                        variant={"h6"}
                      >
                        {isConversionFound(m.key) &&
                          <Tooltip arrow title={"Automated demographics conversion"}>
                            <IconButton
                              //disabled={true}
                              disableRipple={true}
                              color="inherit"
                              className={`${"p5 ml-1"} ${classes.codeicon}`}
                              size="large">
                              {CodeConversionGrey()}
                            </IconButton>
                          </Tooltip>
                        }

                      </TypographyComponent>
                    </Grid>
                    <Grid item md={7} xs={12}>
                      <React.Fragment>
                        {m.key === "age_groupings" && m.rgi.length < 0 &&
                          <AutoCompleteComponent
                            id={m.key}
                            name={m.key}
                            className={"heightone"}
                            suggestionType='array'
                            fullWidth={true}
                            freeSolo={false}
                            handleAutoCompolete={(event, name) => handleChange({ name, value: event }, m.dataType)}
                            suggestions={Age_group_rgi}
                            value={participant[m.key]}
                          ></AutoCompleteComponent>
                        }
                      </React.Fragment>

                      <React.Fragment>
                        {m.dataType === "date" &&
                          <DatePickerComponent
                            name={m.key}
                            value={participant[m.key] || ""}
                            className={classes.textField}
                            handleChange={(e) => handleChange(e, "date")}
                            inputVariant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth={true}
                            disabled={false}
                            validators={["isGoodDate"]}
                            errorMessages={["Date format is MM/DD/YYYY"]}
                          />
                        }
                      </React.Fragment>

                      <React.Fragment>
                        {m.dataType === "integer" &&
                          <TextFieldComponent
                            id={m.key}
                            name={m.key}
                            value={participant[m.key]}
                            size={"small"}
                            margin={"none"}
                            disabled={true}
                            handleChange={(e) => handleChange(e, m.type)}
                          ></TextFieldComponent>
                        }
                      </React.Fragment>

                      <React.Fragment>
                        {(m.dataType === "string" && (!m.rgi || (m.rgi && m.rgi.length === 0))) && m.key !== "age_groupings" && m.key !== "manager_role" &&
                          <TextFieldComponent
                            id={m.key}
                            name={m.key}
                            value={participant[m.key]}
                            size={"small"}
                            margin={"none"}
                            disabled={disableFields.indexOf(m.key) >= 0}
                            handleChange={(e) => handleChange(e, m.type)}
                          ></TextFieldComponent>
                        }
                      </React.Fragment>

                      <React.Fragment>
                        {(m.dataType === "string" && (!m.rgi || (m.rgi && m.rgi.length === 0))) && m.key !== "age_groupings" && m.key === "manager_role" &&
                          <TextFieldComponent
                            id={m.key}
                            name={m.key}
                            value={m.key === "manager_role" && participant["empId"] ? (m.key === "manager_role" && participant[m.key] === null ? participant[m.key] : participant[m.key]) : ""}
                            size={"small"}
                            margin={"none"}
                            disabled={disableFields.indexOf(m.key) >= 0}
                            handleChange={(e) => handleChange(e, m.type)}
                          ></TextFieldComponent>
                        }
                      </React.Fragment>

                      <React.Fragment>
                        {(m.dataType === "string" && m.rgi && m.rgi.length > 0) &&
                          <AutoCompleteComponent
                            id={m.key}
                            name={m.key}
                            suggestionType='array'
                            className={"heightone"}
                            fullWidth={true}
                            freeSolo={false}
                            handleAutoCompolete={(event, name) => handleChange({ name, value: event }, m.dataType)}
                            // suggestions={metadataWithRGI.filter(d => d.key === m.key)[0] ? metadataWithRGI.filter(d => d.key === m.key)[0].rgi : []}
                            suggestions={autoFieldRgi ? autoFieldRgi : []}
                            value={participant[m.key]}
                          ></AutoCompleteComponent>
                        }
                      </React.Fragment>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
// default props
AutomatedDemographicsPanel.defaultProps = {
  classes: {},
  metadataWithRGI: [],
  metadata: [],
  participant: {},
  handleChange: () => { }
};

// prop types
AutomatedDemographicsPanel.propTypes = {
  classes: PropTypes.object,
  metadataWithRGI: PropTypes.array,
  metadata: PropTypes.array,
  participant: PropTypes.object,
  handleChange: PropTypes.func
};
export default (AutomatedDemographicsPanel);
