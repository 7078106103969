import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import Components
import SuccessPageComponent from "../../../../../../components/SuccessPageComponent/SuccessPageComponent";

function SuccessPage (props) {
  // Get Survey Details
  const uploadResult = useSelector((state) => state.excel.upload);

  return (
    <SuccessPageComponent
      match={props.match}
      uploadResult={uploadResult}
      backPageURL={"/manage_people/demographics/conversion/upload"}
      backBtnURL={"/manage_people/demographics"}
      pageName={"Demographics conversion"}
    />
  );
}
// default props
SuccessPage.defaultProps = {
  match: {}
};

// prop types
SuccessPage.propTypes = {
  match: PropTypes.object
};
export default SuccessPage;
