export function getContainterHeight () {
  return (window.innerHeight - 56);
}

export function getSubContainterHeight () {
  return (window.innerHeight - 164);
}

export function getHeaderTabsHeight () {
  return (window.innerHeight - 122);
}

export function getTakeSurveyContainerHeight () {
  return (window.innerHeight - 100);
}

export function getMETakeSurveyContainerHeight () {
  return (window.innerHeight - 180);
}

export function getMETakeSurveySingleContainerHeight() {
  return (window.innerHeight - 105);
}

export function getDrawerHeight () {
  return (window.innerHeight - 56);
}

export function getTableHeight (defaultHeight = 196) {
  return (window.innerHeight - defaultHeight);
}

export function getTableRowSize (defaultHeight = 196) {
  return (Math.floor((getTableHeight(defaultHeight) - 108) / 48));
}

export function getTabsHeight () {
  return (window.innerHeight - 214);
}

export function getQuestionPanelHeight () {
  return (window.innerHeight - 264);
}

export function getQuestionCategoryPanelHeight () {
  return (window.innerHeight - 320);
}

export function getHeaderTabTableHeight () {
  return (window.innerHeight - 262);
}

export function getHeaderTabActionTableHeight () {
  return (window.innerHeight - 216);
}

export function getReportTableRowSize () {
  return (Math.floor((getHeaderTabTableHeight() - 108) / 48));
}