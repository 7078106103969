/* eslint-disable react/no-unescaped-entities */
/* eslint-disable curly */
/* eslint-disable dot-notation */
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { TextField, Checkbox, CircularProgress } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// import components
import { SelectValidator } from "../../../../../components/QueryBuilderComponent/widgets/TextValidator";

// Import Actions
import { metadataAction } from "../../../../../redux/actions";
import { metadataService } from "../../../../../redux/services/metadataService"

const convertDate = (r, format = "MM/DD/YYYY") => {
  return moment(r).isValid() ? moment(r).format(format) : r
}

const MatchDropDown = (props) => {
  const [loading, setLoading] = useState(false)
  const [RGIArray, setRGIArray] = useState([])
  const dispatch = useDispatch();

  // get Props
  let { matchFieldValue, metadataSelected, currentIndex, fromDataType, fromDataKey } = props;

  useEffect(() => {
    // autcompleteAPI(fromDataKey, "")
  }, [fromDataKey])

  async function autcompleteAPI(name, val) {
    try {
      const data = await metadataService.getMetadataValues(name)
      if (data && data.data && data.data[0] && data.data[0].rgi) {
        // format date for date data types
        let RGIArrayResult = data.data[0].rgi
        if (fromDataType === "date") {
          RGIArrayResult = data.data[0].rgi.map(r => {
            return r ? convertDate(r, "MM/DD/YYYY") : r;
          });
        }
        setRGIArray(RGIArrayResult)
      }
      setLoading(false)
    } catch(err) {
      console.log(err)
      setRGIArray([])
      setLoading(false)
    }
  }


  if (fromDataType === "date") {
    // convert YYYY-MM-DD to MM/DD/YYYY
    matchFieldValue = matchFieldValue.map(r => {
      return r ? convertDate(r, "MM/DD/YYYY") : r;
    });
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

  // change Match field values for multiselect
  const handleMatchChange = (value) => {
    // const { name, value } = e;
    if (metadataSelected && metadataSelected["conversion"]) {
      for (let i = 0; i < metadataSelected["conversion"].length; i++) {
        if (i === currentIndex) {
          metadataSelected["conversion"][i]["match"] = value;
        }
      }
      dispatch(metadataAction.selectedMetadata(metadataSelected));
    }
  };

  const onOpen = () => {
    if (RGIArray.length === 0) {
      setLoading(true)
      autcompleteAPI(fromDataKey, "")
    }
  }

  // dropdown load time fix
  const MAX_OPTIONS_COUNT = 100;
  const sortOptions = (options, values, maxCount, inputValue = "") => {
    values = values || [];
    // for single select
    if (!Array.isArray(values)) {
      values = [values];
    }

    // filter values from options
    // filter empty or null options
    if (values.length > 0) {
      options = options.filter((op) => values.indexOf(op) < 0 || !op);
    }

    return [...values.sort(), ...options]
      .filter((f) => {
        if (f)
          return `${f}`.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
        else return f; // for null values
      })
      .slice(0, maxCount);
  };

  return (
    <React.Fragment>
      <SelectValidator
        fullWidth
        classes={{
          paper: "query-builder-option-wrapper",
          option: "query-builder-autocomplete-option"
        }}
        multiple
        disableClearable
        autoHighlight
        options={sortOptions(RGIArray, matchFieldValue, MAX_OPTIONS_COUNT, "")}
        onOpen={onOpen}
        filterOptions={(opt, { inputValue }) => {
          if (inputValue) {
            const fOptions = sortOptions(
              RGIArray,
              matchFieldValue,
              MAX_OPTIONS_COUNT,
              inputValue
            );
            if (fOptions.length > 0) {
              return fOptions;
            } else {
              // return add options concep
              return [{ option: inputValue, type: "new" }];
            }
          } else {
            return sortOptions(
              RGIArray,
              matchFieldValue,
              MAX_OPTIONS_COUNT,
              ""
            );
          }
        }}
        value={Array.isArray(matchFieldValue) ? matchFieldValue : []}
        className="input-value-editor autocomplete text-validator"
        getOptionLabel={(option) =>
          option ? option.option || option : "null or empty"
        }
        onChange={(e, val) => {
          val = val.map((v) => v.option || v);
          if (fromDataType === "date" && val && val.length > 0) {
            // convert YYYY-MM-DD to MM/DD/YYYY
            val = val.map(r => {
              return r ? convertDate(r, "YYYY-MM-DD") : r;
            });
          }
          handleMatchChange(val);
        }}
        renderInput={(params) => (
          <TextField
            variant={"outlined"}
            size={"small"}
            className={`${"height28 conversionAutoComplete"}`}
            {...params}
            placeholder={
              Array.isArray(matchFieldValue) && matchFieldValue.length > 0
                ? `(${matchFieldValue.length}) selected`
                : "Search Values"
            }
            InputProps={{
              ...params.InputProps,
              endAdornment:(
                <React.Fragment>
                  {loading ? <div style={{padding: "4px"}} className="MuiAutocomplete-endAdornment css-aumodo-MuiAutocomplete-endAdornment">
                    <CircularProgress size={15} />
                  </div> : params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
        renderTags={(value, getTagProps) => {
          return null;
        }}
        renderOption={(props, option, { selected }) => {
          return option && option.type === "new" ? (
            <li {...props}>
              <span>
                Create "
                <span style={{ color: "#54C0FD" }}>{option.option}</span>" ?
              </span>
            </li>
          ) : (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                className="autocomplete-checkbox"
                checked={selected}
              />
              {option}
            </li>
          );
        }}
        inputField={Autocomplete}
      />
    </React.Fragment>
  );
};

// default props
MatchDropDown.defaultProps = {
  RGIArray: [],
  matchFieldValue: [],
  metadataSelected: {},
  currentIndex: 0,
  fromDataType: "string"
};

// prop types
MatchDropDown.propTypes = {
  matchFieldValue: PropTypes.array,
  RGIArray: PropTypes.array,
  metadataSelected: PropTypes.object,
  currentIndex: PropTypes.number,
  fromDataType: PropTypes.string
};

export default MatchDropDown;
