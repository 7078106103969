import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

/**
 *Delete Dialog Component
 *
 * @class DeleteDialogComponent
 * @extends {React.Component}
 */
function DeleteDialogComponent (props) {
  /** Handle Dialog close Action  */

  const { handleDeleteAction, open, handleDeleteOnCloseAction, content, padding } = props;

  const handleClose = () => {
    handleDeleteOnCloseAction();
  };

  /** Handle Dialog Delete Action */
  const handleDelete = () => {
    handleDeleteAction();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="p15"
    >
      {/* <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle> */}
      <DialogContent className={`${padding !== "" && padding}`}>
        {/* <DialogContentText id="alert-dialog-description"> */}
        {content}
        {/*
                    </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleDelete}
          variant="contained"
          disableElevation
          size="small"
          color="primary">
                    Yes
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          autoFocus>
                    No
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// default props
DeleteDialogComponent.defaultProps = {
  open: false,
  id: null,
  content: "Are you sure you want to delete this record?",
  handleDeleteAction: () => { },
  handleClose: () => { },
  padding: ""
};

// prop types
DeleteDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.any,
  handleDeleteAction: PropTypes.func.isRequired,
  handleDeleteOnCloseAction: PropTypes.func.isRequired,
  padding: PropTypes.string
};

/** Export Component */
export default DeleteDialogComponent;
