/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import FormGroup from "@mui/material/FormGroup";

// import constants
import { surveyQuestionsConstants, surveyIntentionQuesTextDisable, surveyEngagementQuesTextDisable, surveyTypes } from "../../../../config/constants";
import { surveyConstant } from "../../../../redux/constants";

// Import Helpers
import { addLanguageInNewQuestion, checkduplicateQuestion, checkAddQuestion } from "../../../../helpers";

// import components
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import AnswerChoices from "./AddQuestion/AnswerChoices";
import AddQuestionsCheckBox from "./AddQuestion/AddQuestionsCheckBox";
import MatrixSubQuestion from "./AddQuestion/MatrixSubQuestion";
import QuestionText from "./AddQuestion/QuestionText";
import QuestionType from "./AddQuestion/QuestionType";
import SelectDemographics from "./AddQuestion/SelectDemographics";
import SelectAnsSet from "./AddQuestion/SelectAnsSet";

// Import Actions
import { categoryAction, alertAction, updateStateReducerAction } from "../../../../redux/actions";

function AddQuestions(props) {
  const dispatch = useDispatch();
  const questionRef = useRef(null);

  // Get Props Values
  const { classes, questionData, category, addQuestions, item } = props;
  const { questions, languages } = questionData;

  //Update answerset for Old survey
  let defaultAnswerSet = surveyQuestionsConstants.DEFAULT_OUES_SET
  if (questionData.isOldSurvey) {
    defaultAnswerSet = surveyQuestionsConstants.OLD_DEFAULT_OUES_SET
  }

  let questionDataSet = {};
  // for edit purpose pass question as item
  if (questionData && item) {
    questionDataSet = item;
  } else if (addQuestions[category] === undefined) {
    if (questionData.type === surveyTypes.MULTI_RATER_FEEDBACK) {
      questionDataSet = JSON.parse(JSON.stringify(surveyQuestionsConstants.DEFAULT_ME_OUES_SET));
    } else {
      questionDataSet = JSON.parse(JSON.stringify(defaultAnswerSet));
    }
    questionDataSet["baseQusId"] = `newQues-${Math.random(new Date().getTime())}`.replace(".", "");
    questionDataSet["category"] = category;

    // check and update add question flag
    if (!item) {
      let requireCat = [];
      questions.filter((question) => question.category === category)
        .map((item) => {
          requireCat.push(item.isRequired);
        });
      if (requireCat.length && requireCat.indexOf(false) === -1) {
        questionDataSet.isRequired = true;
      }
    }
  } else {
    questionDataSet = addQuestions[category];
  }
  questionDataSet = addLanguageInNewQuestion(questionDataSet, languages);

  useEffect(() => {
    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, questionDataSet));
    }
  }, [dispatch, item]);

  useEffect(() => {
    questionRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
  }, []);

  const saveNewQuestion = () => {
    let checkEmpty = checkAddQuestion(questionDataSet, "en");
    if (!checkduplicateQuestion(questionDataSet, questions, languages) && !checkEmpty) {
      questionDataSet = addLanguageInNewQuestion(questionDataSet, languages);
      // Add or Update answer Scale
      if (questionDataSet.qusType === "Single Select") {
        let ansScale = Object.keys(questionDataSet["ansSet"]["en"]);
        questionDataSet.maxAnsScale = parseInt(ansScale[(ansScale.length) - 1]);
      }
      if (!item) {
        questionData.questions.push(questionDataSet);
        dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, questionData));
        dispatch(categoryAction.onCancelAddQuestion(category));
        props.updateAddQuesFlag();
      } else {
        // on save close edit
        props.updateAddQuesFlag(true);
      }
    } else if (checkEmpty) {
      dispatch(alertAction.error(checkEmpty));
    } else {
      dispatch(alertAction.error("duplicate or empty questions are not allowed"));
    }
  };

  const closeAction = () => {
    if (!item) {
      dispatch(categoryAction.onCancelAddQuestion(category));
      props.updateAddQuesFlag();
    } else {
      // set / remove editable false for that question
      props.updateAddQuesFlag(false);
    }
  };

  // set badge value for add question
  let badgeValue = 1;
  let categoryQuestions = questions.filter((question) => question.category === category);
  if (item) {
    badgeValue = categoryQuestions.map((ques, ind) => { if (ques.baseQusId === item.baseQusId) { return ind + 1; } });
  } else {
    badgeValue = categoryQuestions.length + 1;
  }

  return (
    <Grid
      container
      className={` ${classes.quest_cateitem_open} ${"padding20"}`}
      alignItems={"center"}
      ref={questionRef}
    >
      <Grid item sm={12}>
        <Grid container spacing={3}>
          <Grid container spacing={3} alignItems="stretch" className="p15" style={{ margin: -12 }}>
            <Grid item style={{ flexGrow: "7" }}>
              <Grid container alignItems="center">
                <Grid item className="pl-1 pr-2">
                  <Badge
                    color="secondary"
                    className={`${classes.addquestion_badge} badgebig`}
                    badgeContent={badgeValue}
                  ></Badge>
                </Grid>
                <Grid item className="dflex flexgrow">
                  <QuestionText
                    classes={classes}
                    category={category}
                    questionDataSet={questionDataSet}
                    editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)}
                    item={item}
                  />
                  <QuestionType
                    classes={classes}
                    category={category}
                    questionData={questionData}
                    questionDataSet={questionDataSet}
                    editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)}
                    item={item}
                  />
                </Grid>
              </Grid>
            </Grid>
            {(questionDataSet.qusType === "Single Select" || questionDataSet.qusType === "Yes/No" || questionDataSet.qusType === "Dropdown") && questionData.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
              <Grid item style={{ flexGrow: "1" }}>
                <SelectDemographics
                  classes={classes}
                  category={category}
                  questionData={questionData}
                  questionDataSet={questionDataSet}
                  editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)}
                  item={item}
                />
              </Grid>}
            {questionDataSet.qusType === "Single Select" &&
              <Grid item style={{ flexGrow: "1" }}>
                <SelectAnsSet
                  category={category}
                  questionData={questionData}
                  questionDataSet={questionDataSet}
                  editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)}
                  item={item}
                />
              </Grid>
            }
          </Grid>
          {questionDataSet.qusType !== "Open Ended" &&
            <AnswerChoices
              classes={classes}
              category={category}
              questionData={questionData}
              questionDataSet={questionDataSet}
              item={item}
              editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)}
            />
          }
          {questionDataSet.qusType === "Matrix" &&
            <React.Fragment>
              <Grid className={"pl-55 txtSemiBold"}>Sub Questions</Grid>
              <MatrixSubQuestion
                classes={classes}
                category={category}
                questionData={questionData}
                addQuestions={addQuestions}
                questionDataSet={questionDataSet}
                editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)}
                item={item}
              />
            </React.Fragment>
          }
          <Grid item sm={12} className={`${"pt-0"} ${classes.ques_catecheckboxdiv}`}>
            <FormGroup row className=" pl-5">
              <Grid container justifyContent="space-between">
                <AddQuestionsCheckBox
                  classes={classes}
                  category={category}
                  questionDataSet={questionDataSet}
                  item={item}
                  editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)} />
              </Grid>
            </FormGroup>
          </Grid>

          <Grid item xs={12} className="pt-0">
            <Grid container justifyContent={"flex-end"}>
              <Grid item>
                <ButtonComponent
                  title={"OK"}
                  color={"primary"}
                  size={"small"}
                  handleClick={() => saveNewQuestion()}
                  pageClassName={classes.settings_addrulebtn}
                ></ButtonComponent>
                <ButtonComponent
                  title={"Cancel"}
                  // color={"secondary"}
                  className={classes.cancelBtn}
                  size={"small"}
                  handleClick={() => closeAction()}
                  pageClassName="ml-1"
                ></ButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

// default props
AddQuestions.defaultProps = {
  classes: {},
  addQuestions: {},
  category: null,
  questionDataSet: surveyQuestionsConstants.DEFAULT_OUES_SET,
  questionData: { questions: [], catOrder: [], catDetails: {} },
  item: null,
  updateAddQuesFlag: () => { },
  editQuestionsData: () => { }
};

// prop types
AddQuestions.propTypes = {
  classes: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  questionDataSet: PropTypes.object,
  questionData: PropTypes.object,
  addQuestions: PropTypes.object,
  category: PropTypes.string,
  item: PropTypes.object,
  updateAddQuesFlag: PropTypes.func,
  editQuestionsData: PropTypes.func
};

export default AddQuestions;
