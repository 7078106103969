import moment from "moment";
import { alertConstant, takeSurveyConstant, uiConstant } from "../constants";
import { takeSurveyService } from "../services";
import { baseAction } from "./baseAction";
import { browserName, osName } from "react-device-detect";
import { TakeSurveyConstants } from "../../config/constants";

const { request, success, failure } = baseAction;

// Export All Survey Actions Functions
export const takeSurveyAction = {
  getSurvey,
  handleSurveyResponse,
  updateSurveyResponse,
  updateRequiredQuestions,
  updateSurveyLanguage,
  kioskUserAuthentication,
  anonymousUserAuthentication,
  openTakeSurvey,
};

/**
 * Get Survey
 * id => surveyId
 * pId=> participantId default null
 */
function getSurvey(surveyGetQuery) {
  return (dispatch, getState) => {
    if (surveyGetQuery) {
      // const state = getState();
      // const { getOne, questions, client, participant, surveyRequiredQuestions, surveyAuthType } = state.takeSurvey;
      const { takeSurveyUrl, surveyDeviceType, params } = surveyGetQuery;

      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(takeSurveyConstant.SURVEY_GETONE_REQUEST));
      const surveyGetDataQuery = { ...surveyGetQuery, browserName };
      takeSurveyService.getOne(surveyGetDataQuery).then(
        (data) => {
          let actionData = data && data.data ? data.data : {};
          let surveyActionData = {
            ...actionData,
            takeSurveyUrl,
            surveyDeviceType,
          };

          //Set Caller Id Data in Reducer
          if (params.hasOwnProperty("caller_id") && params.caller_id) {
            surveyActionData = {
              ...surveyActionData,
              caller_id: params.caller_id,
            };
          }

          dispatch(success(takeSurveyConstant.SURVEY_GETONE_SUCCESS, surveyActionData));
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(takeSurveyConstant.SURVEY_GETONE_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
    } else {
      dispatch(success(takeSurveyConstant.SURVEY_GETONE_CLEAR, null));
    }
  };
}

function handleSurveyResponse(question, answer, langCode, responseEN = null) {
  return (dispatch, getState) => {
    const state = getState();
    const { response, surveyRequiredQuestions, validatedQues, rgResponses, questions, surveyLang } = state.takeSurvey;
    if (question) {
      const { baseQusId, category, maxAnsScale, qusType, text, isRequired, RGConversion, ansSet, subQues } = question;

      const questionText = text["en"]; //text[langCode];
      let quesResponse = {
        baseQusId,
        category,
        qusType,
        question: questionText,
        answer,
        maxAnsScale,
      };

      if (qusType == "Matrix") {
        const langs = Object.keys(ansSet);
        let newAnsSet = {};
        langs.forEach((lang) => {
          newAnsSet[lang] = answer;
        });
        quesResponse = {
          ...quesResponse,
          answer: newAnsSet,
          subQuestions: subQues,
          answerSet: ansSet,
        };
      }

      if (qusType == "Dropdown") {
        quesResponse = {
          ...quesResponse,
          answer: responseEN,
          answer_native: answer,
          subQuestions: subQues,
          answerSet: ansSet,
        };
      }


      let newRgResponse = { ...rgResponses };
      if (RGConversion && answer !== null && answer !== undefined) {
        let answerTxt = "";
        let answerOri = answer
        if (qusType == "Yes/No") {
          answerTxt = answerOri === 0 ? "Yes" : "No";
        } else if (qusType == "Single Select") { // always english for demographic fields no translate needed
          const ansSet = question.ansSet["en"];
          answerTxt = ansSet[answerOri];
        } else if (qusType == "Dropdown") { // always english for demographic fields no translate needed
          answerOri = responseEN || answer;
          answerTxt = responseEN || answer;
        } else {
          answerTxt = answerOri;
        }

        newRgResponse = {
          ...rgResponses,
          [RGConversion]: answerTxt,
        };
        // newRgResponse = { ...rgResponses, [RGConversion]: answer};
        quesResponse = {
          ...quesResponse,
          RGConversion: {
            column: RGConversion,
            answer: answerOri,
            answerTxt: answerTxt,
          },
        };
      } else if (rgResponses.hasOwnProperty(RGConversion) && !answer) {
        delete rgResponses[RGConversion];
      }

      if (isRequired) {
        if (Array.isArray(answer)) {
          surveyRequiredQuestions[baseQusId] = answer.length > 0 ? true : false;
        } else {
          surveyRequiredQuestions[baseQusId] = answer || answer === 0 ? true : false;
        }
      }

      //remove new response questions on existing answered questions
      const newResponse = response.length > 0 ? response.filter((res) => res.baseQusId != baseQusId && (res.answer || res.answer === 0)) : [];

      let errorQuestions = [];
      if (newResponse.length > 0) {
        validatedQues.forEach((qid) => {
          const reqStatus = newResponse.filter((res) => qid === res.baseQusId && (res.answer || res.answer === 0));
          if (!reqStatus) {
            errorQuestions.push(qid);
          }
        });
      } else if (Object.keys(surveyRequiredQuestions).length > 0) {
        let requiredQusKeys = Object.keys(surveyRequiredQuestions);
        errorQuestions = requiredQusKeys.filter((qKey) => !surveyRequiredQuestions[qKey]);
      } else {
        errorQuestions = [...validatedQues];
      }
      let surveyResponse = [];

      if (Array.isArray(answer)) {
        surveyResponse = answer.length > 0 ? [...newResponse, quesResponse] : newResponse;
      } else if (answer && typeof answer === "object" && Object.keys(answer).length === 0) {
        surveyResponse = newResponse;
      } else {
        surveyResponse = answer || answer === 0 ? [...newResponse, quesResponse] : newResponse;
      }

      const requiredQuesList = errorQuestions;

      //survey completion percentage calculation
      const totalQuestions = questions.questions;
      const participated = surveyResponse.filter((ans) => ans.answer || ans.answer === 0);

      const surveyPercentage = (parseInt(participated.length) / parseInt(totalQuestions.length)) * 100;
      const actionData = {
        surveyResponse,
        surveyRequiredQuestions,
        requiredQuesList,
        rgResponses: newRgResponse,
        surveyPercentage,
      };
      dispatch(success(takeSurveyConstant.SURVEY_RESPONSE_UPDATE, actionData));
    }
  };
}

function updateSurveyResponse(actionType) {
  return (dispatch, getState) => {
    const { takeSurvey } = getState();
    const { response, getOne, participant, rgResponses, surveyLang, surveyDeviceType, completionPercentage, surveyUpdateFlag, callerId, spCallerHistory, takeSurveyUrl, surveyStartsAt, assignedDate } = takeSurvey;
    const surveyStarts = moment(surveyStartsAt);
    const currentTime = moment();
    const takeSurveyDuration = currentTime.diff(surveyStarts, "seconds");
    const survey_id = getOne._id;
    const participant_id = participant._id ? participant._id : "";
    const rg_responses = rgResponses ? rgResponses : null;

    let data = {
      response,
      survey_id,
      participant_id,
      rg_responses,
      browserName,
      osName,
      langCode: surveyLang,
      deviceType: surveyDeviceType,
      pctCompleted: completionPercentage,
      surveyUpdateFlag,
      actionType,
      callerId,
      spCallerHistory,
      takeSurveyDuration,
      takeSurveyUrl: takeSurveyUrl ? takeSurveyUrl : "shortUrl",
      assignedDate
    };

    if (response) {
      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(takeSurveyConstant.SURVEY_RESPONSE_UPDATE_REQUEST));

      let apiUrl = "";
      if (participant_id) {
        if (takeSurveyUrl == "kiosk") {
          apiUrl = "takesurvey/kiosk";
        } else if (takeSurveyUrl == "sp_caller") {
          apiUrl = "takesurvey/sp_caller";
        } else if (takeSurveyUrl == "shortUrl" && surveyDeviceType == "Mobile") {
          apiUrl = "takesurvey/sms";
        } else {
          apiUrl = "takesurvey";
        }
      } else {
        apiUrl = "takesurvey/anonymous";

        // Get participant id form local storage if avail and send
        let participantId = null;
        if (localStorage.getItem(window.location.href.split("/").pop()))
          participantId = localStorage.getItem(window.location.href.split("/").pop());
        data = { ...data, participant_id: participantId }
      }

      takeSurveyService.updateSurveyResponse(data, apiUrl).then(
        (data) => {
          if (apiUrl === "takesurvey/anonymous" && data && data.data && data.data.participant_id && data.data.survey_id) {
            localStorage.setItem(window.location.href.split("/").pop(), data.data.participant_id)
          }
          const respData = { data: data, actionType: actionType };
          dispatch(success(takeSurveyConstant.SURVEY_RESPONSE_UPDATE_SUCCESS, respData));
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(takeSurveyConstant.SURVEY_RESPONSE_UPDATE_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
    }
  };
}

function updateRequiredQuestions(questions) {
  return (dispatch) => {
    if (questions) {
      dispatch(success(takeSurveyConstant.SURVEY_REQUIRED_QUESTIONS_UPDATE, questions));
    }
  };
}

function updateSurveyLanguage(language) {
  return (dispatch) => {
    if (language) {
      dispatch(success(takeSurveyConstant.TAKE_SURVEY_UPDATE_LANGUAGE, language));
    }
  };
}

function kioskUserAuthentication(username, password) {
  return (dispatch, getState) => {
    if (username && password) {
      const state = getState();
      const { getOne, participant, surveyRequiredQuestions, client } = state.takeSurvey;
      const data = {
        username,
        password,
        survey_id: getOne._id,
        client_id: client._id,
      };
      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(takeSurveyConstant.TAKE_SURVEY_KIOSK_AUTH_REQUEST));
      takeSurveyService.kioskUserAuthValidation(data).then(
        (data) => {
          const actionData = data && data.data ? data.data : {};
          const surveyActionData = {
            ...actionData,
          };
          dispatch(success(takeSurveyConstant.TAKE_SURVEY_KIOSK_AUTH_SUCCESS, surveyActionData));
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(takeSurveyConstant.TAKE_SURVEY_KIOSK_AUTH_CLEAR, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
      return false;
    } else {
      dispatch(success(takeSurveyConstant.TAKE_SURVEY_AUTH_CLEAR, null));
    }
  };
}

function anonymousUserAuthentication(userPassword) {
  return (dispatch, getState) => {
    if (userPassword) {
      const state = getState();
      const { anonymous } = state.takeSurvey.getOne.distribute.audience;
      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(takeSurveyConstant.TAKE_SURVEY_AUTH_REQUEST));

      if (anonymous.password == userPassword) {
        dispatch(success(takeSurveyConstant.TAKE_SURVEY_AUTH_SUCCESS, true));
        dispatch(request(uiConstant.LOADING_FALSE));
      } else {
        const error = TakeSurveyConstants.INVALID_PASSWORD;
        dispatch(failure(takeSurveyConstant.TAKE_SURVEY_AUTH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    } else {
      dispatch(success(takeSurveyConstant.TAKE_SURVEY_AUTH_CLEAR, null));
    }
  };
}

function openTakeSurvey() {
  return (dispatch) => {
    dispatch(success(takeSurveyConstant.TAKE_SURVEY_UPDATE_OPEN_STATUS));
  };
}
