import { REPORT_TYPE_CONSTANTS } from './constants'

/* eslint-disable no-case-declarations */
/* eslint-disable curly */
/* eslint-disable prefer-const */
// User Roles
export const userRoles = {
  superadmin: "superadmin",
  calleradmin: "calleradmin",
  calleruser: "calleruser",
  clientadmin: "clientadmin"
};

export const userAccess = {
  surveys: "surveys",
  people: "manage_people",
  reports: "reports",
  actions: "actions",
  spcaller: "spcaller"
};

export const rolesAccess = {
  surveys: "Survey",
  people: "People",
  reports: "Report",
  actions: "Actions",
  spcaller: "Spcaller",
  rehire:"Rehire Potential",
  rawData:"Raw Data",
  compilance:"Compliance",
  comments : "Comments",
  profile:"profile"
};

export const reportAccess=["Rehire Potential", "Compliance", "Raw Data"];

// Check and return page access
export const checkPageAccess = (user, component) => {
  const path = component.path;
  const location = component.location;
  const splitPath = path.split("/");
  const role = user.role ? user.role : "clientadmin";
  let page = user.page;
  switch (role) {
    case userRoles.superadmin:
      return true;
    case userRoles.clientadmin:
      if (splitPath[1] === "client") {
        return location.pathname.indexOf("edit") >= 0;
      } else {
        return page.indexOf(splitPath[1]) >= 0;
      }
    case userRoles.calleradmin:
      return page.indexOf(splitPath[1]) >= 0;
    case userRoles.calleruser:
      return page.indexOf(splitPath[1]) >= 0;
    default:
      return false;
  }
};

/**
 * Get Redirect Page
 * @param {*} user
 */
export const redirectPage = function (user) {
  if (user) {
    const role = user.role ? user.role : "clientadmin";
    const page = user.page;

    switch (role) {
      case "superadmin":
        return "surveys";
      case "spcaller":
        return "spcaller";
      default:
        if (page.length > 0) {
          return page[0];
        } else {
          return "noaccess";
        }
    }
  } else {
    return "noaccess";
  }
};

/**
 * Get Redirect Page
 * @param {*} user
 */
export const getCurrentListPagePath = function (user, match) {
  if (user) {
    const path = match.path.split("/")[1];
    const urlPath = window.location.href.split("/");
    const urlPathLeng = urlPath.length;
    if (path === "profile" || path === "client")
      return redirectPage(user);
    else {
      switch (path) {
        case "surveys":
          return "surveys/manage?reset=true";
        case "people":
          const pIndex = urlPath.indexOf("people") + 1;
          if (urlPathLeng > pIndex) {
            if (urlPath[pIndex] === "demographics")
              return "people";
            else
              return `people/${urlPath[pIndex]}?reset=true`;
          } else {
            return "people";
          }
        case "reports":
          if (urlPathLeng === 5)
            return "reports";
          else
            return `reports/${urlPath[urlPathLeng - 1]}`;
        case "actions":
          return "actions";
        case "spcaller":
          return "spcaller?reset=true";
        default:
          return path;
      }
    }
  } else {
    return "noaccess";
  }
};

const reportUserAccessMap = {
  [rolesAccess.reports]: [
    REPORT_TYPE_CONSTANTS.COMMENT_REPORT, 
    REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT, 
    REPORT_TYPE_CONSTANTS.BENCHMARK_REPORT,
    REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT,
    REPORT_TYPE_CONSTANTS.GROUP_REPORT,
  ],
  [rolesAccess.compilance]: [
    REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT
  ],
  [rolesAccess.rawData]: [
    REPORT_TYPE_CONSTANTS.RAW_DATA
  ],
  [rolesAccess.rehire]: [
    REPORT_TYPE_CONSTANTS.REHIRE_REPORT
  ]
}

const reportUserAccessPageMap = {
  [rolesAccess.reports]: [
    '/reports',
    '/reports/insight',
    '/reports/report',
    '/reports/comment'
  ],
  [rolesAccess.compilance]: [
    '/reports/report'
  ],
  [rolesAccess.rawData]: [
    '/reports/report'
  ],
  [rolesAccess.rehire]: [
    '/reports/report'
  ]
}

export const accessReportsList = (currentPage, { role, access }) => {
  
  let R_REPORTS = []
  let R_REPORTS_TABS = []
  access.map((a) => {
    R_REPORTS = [...R_REPORTS, ...(reportUserAccessMap[a] || [])].sort()
    R_REPORTS_TABS = [...R_REPORTS_TABS, ...(reportUserAccessPageMap[a] || [])]
  })

  return {
    R_REPORTS,
    R_REPORTS_TABS,
    R_CURRENT_PAGE: R_REPORTS_TABS.indexOf(currentPage) >= 0 || role === userRoles.superadmin
  }
}
