import React, { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Import Components
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import SwitchComponent from "../../../../../components/SwitchComponent/SwitchComponent";
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";

import CopyIcon from "../../../../../assets/images/svg/copy_settings.svg";

// constants
import { surveySettingsConstants, errorsConstants } from "../../../../../config/constants";
import { surveyConstant } from "../../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData } from "../../../../../helpers";
// import { Icon } from "@mui/material";

function Kiosk(props) {
  const { classes, data, anonKioskShortName, panel, translated } = props;
  const dispatch = useDispatch();

  const {initial_notification} = useSelector((state) => state.survey);

  useEffect(() => {
    ValidatorForm.addValidationRule("kioskNameExists", (value) => {
      // Convert both the existing kiosk names and the input name to lowercase for case-insensitive comparison
      const lowerCaseKioskNames = anonKioskShortName?.kiosk?.map(name => name?.toLowerCase());
      const inputName = data?.distribute?.kiosk?.name.toLowerCase();
    
      // Check if the input name exists in the list of kiosk names
      if (lowerCaseKioskNames.indexOf(inputName) !== -1) {
        return false;
      } else {
        return true;
      }
    });
    ValidatorForm.addValidationRule("specialCharacterskiosk", (value) => {
      // Regex for Valid Characters i.e. Alphabets, Numbers, Underscore and Space.
      if (data.distribute.kiosk.name) {
        const regex = /^[a-zA-Z0-9_-]+$/;

        // Validate TextBox value against the Regex.
        const isValid = regex.test(data.distribute.kiosk.name);
        return isValid;
      } else {
        return true;
      }
    });
  }, [data]);
  /**
      * Handle Change Event
      * @param {*} panel
      */
  const handleChange = (event) => {
    if (data._id && !data.isLoading) {
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  useEffect(()=>{
    if(data.distribute.audience.select === "anonymous"){
      data.distribute.kiosk.active = false
    } else { 
      data.distribute.kiosk.active = initial_notification?.kiosk?.active
    }
  },[data.distribute.audience.select])
  return (
    <Grid item sm={12}>
      <Grid item md={12} xs={12} className="mb-2">
        <SwitchComponent
          name="distribute.kiosk.active"
          checked={data.distribute.kiosk.active}
          disabled={(data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? surveySettingsConstants.CHECKED_FALSE : surveySettingsConstants.CHECKED_TRUE}
          color="primary"
          handleChange={(e) => handleChange(e)}
          label={
            <span>
              <TypographyComponent variant="h5"
                className="pt-2">{surveySettingsConstants.KIOSK_TOGGLE_TITLE}</TypographyComponent>
              <TypographyComponent variant="h6"
                className="txtgrey">{surveySettingsConstants.KIOSK_TOGGLE_MESSAGE}</TypographyComponent>
            </span>
          }
        />
      </Grid>
      {/*
                Displays when kiosk toggle enabled
            */}

      {data.distribute.kiosk.active === true && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS &&
        <Grid item sm={12} className={`${"pl-5"} ${"pt-13"}`}>
          <Grid container spacing={3}
            className={classes.settings_expandinsidediv}>
            <Grid item md={6} xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.KIOSK_SURVEY_URL_MESSAGE}
                <InformationComponent>
                  <TypographyComponent variant="tooltipbody">
                    {surveySettingsConstants.KIOSK_ANONYMOUS_SURVEY_URL_HELP}
                  </TypographyComponent>
                </InformationComponent>
              </TypographyComponent>

              <TextFieldComponent id="distribute.kiosk.name"
                name="distribute.kiosk.name"
                size={"small"}
                fullWidth={false}
                variant="outlined"
                validators={["required", "kioskNameExists", "specialCharacterskiosk"]}
                errorMessages={[errorsConstants.required, errorsConstants.kioskNameExists, errorsConstants.specialCharacterskiosk]}
                value={data.distribute.kiosk.name || ""}
                handleChange={(e) => handleChange(e)}
                className={`${classes.settings_input}`}
                panel={panel}
              />

            </Grid>
            <Grid item md={6} xs={12}>
              <TypographyComponent variant="h6" className="txtgrey">
                {surveySettingsConstants.KIOSK_SURVEY_URL_MESSAGE}
                <CopyToClipboard
                  text={`${window.location.protocol}//${window.location.host}/#${surveySettingsConstants.KIOSK_SURVEY_URL_MESSAGE_URL}${data.distribute.kiosk.name}`}
                  onCopy={() => {
                    dispatch(alertAction.successAlert("KIOSK Survey URL copied!"));
                  }}>
                  <IconButton className={`${classes.anonymousCopyIcon} p-1`} size="large">
                    <img src={CopyIcon} alt="copy"></img>
                  </IconButton>
                </CopyToClipboard>
              </TypographyComponent>
              <TypographyComponent variant="h6" className="txtgrey breakWord">
                {window.location.protocol}{`//${window.location.host}/#`}{surveySettingsConstants.KIOSK_SURVEY_URL_MESSAGE_URL}{data.distribute.kiosk.name}
              </TypographyComponent>
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}

// default props
Kiosk.defaultProps = {
  classes: {},
  data: {},
  anonKioskShortName: {}
};

// prop types
Kiosk.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  anonKioskShortName: PropTypes.object
};

export default (Kiosk);
