import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Import Styles and Image
import CloseImg from "../../../../assets/images/svg/dashboard_close.svg";
import ReportsStyles from "../../ReportsStyles";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

const TopicPopup = (props) => {
  //Get props
  const { classes, topics, onClose, word, wordCount } = props;

  let answers=[];
   Object.keys(topics).map((topic)=>{
     Object.keys(topics[topic]).map((item)=>{
        if(item===word){
          answers=topics[topic][item]["response"]
        }
     })
   })

  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        style={{
          paddingLeft: '16px',
          paddingRight: '16px'
        }}
      >
        <Grid
          item
          xs={12}
          className={`${classes.dialog_close}`}
        >
          <Button variant="text" size={"small"} onClick={onClose}>
            <img src={CloseImg} alt="CloseImg" />
            <TypographyComponent variant="h5" className="pl5" title={"Close"} />
          </Button>
        </Grid>
        <Grid md={12}>
          <Grid className={`${classes.borderline} pb-1`}>
            {word && word && (
              <TypographyComponent variant="h3" className="pl5">
                {`${word} (${wordCount})`}
              </TypographyComponent>
            )}
          </Grid>
          <Grid>
            {answers.length > 0 &&
              answers.map((item,index) => (
                item !== "" &&
                <React.Fragment key={index}>
                  <p dangerouslySetInnerHTML={{ __html: item }} className={`${classes.borderline} pb-1`}></p>
                </React.Fragment>
              ))}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
// default props
TopicPopup.defaultProps = {
  classes: {},
};

// prop types
TopicPopup.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(TopicPopup);
