import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

// import images
import { Option } from "../../assets/images/svgComponents/ImageComponent";

// Import History
import history from "../../config/history";

// import constants
import { ReportsConstants, REPORT_TYPE_CONSTANTS, surveyTypes, FilterConstants } from "../../config/constants";

// import redux actions
import { adminsAction, reportAction, dashboardAction } from '../../redux/actions';

import { getReportsList } from '../Reports/Report/CreateReport/ReportSteppers/SelectReport'

import AdditionalReports from './Insight/AdditionalReports/AdditionalReports'

const OptionsMenu = (props) => {
  // Get Props
  const { classes, match, onChange, anchorEl, setAnchorEl } = props;

  const dispatch = useDispatch();
  const {
    admin: { preference },
    report,
    profile: { user },
    filter: { filterData, allSurveys }
  } = useSelector(state => state)

  // Set default drawer state
  const [drawer, setDrawer] = useState(null);
  const [optionsPopup, setOptionsPopup] = useState(null);
  const hasMESurvey = (filterData.surveys || []).filter(s => s.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0
  const chartsList = hasMESurvey ? ReportsConstants.CHARTS_ME : ReportsConstants.CHARTS

  // set default options
  useEffect(() => {
    if (preference && preference.surveys) {
      report.reportData.surveys = preference.surveys;
      report.reportData.date = preference.date;
      report.reportData.demographics = preference.demographics;
    }
  }, [preference])

  const reportsList = getReportsList(user, { ...report, allSurveys })

  // Open options menu
  const OpenOptions = (event) => {
    setOptionsPopup(event.currentTarget);
  };

  // close options menu
  const handleClose = () => {
    setOptionsPopup(null);
  };

  /**
   * Open More Options Menu
   */
  const openMoreOptionMenu = (menu) => {
    setOptionsPopup(null);
    history.push(`${match.url}/${menu}`);
  };

  /**
   * toggle drawer
   */
  const toggleDrawer = () => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOptionsPopup(null);
    setDrawer(null);
  };

  const getHiddenChartList = () => {
    let visibleItems = [];
    if ('reportVisibility' in preference) {

      // if (!hasMESurvey && preference.reportVisibility) {
      //   delete preference.reportVisibility['7']
      // }

      if (Object.keys(chartsList).length > 0) {
        Object.keys(chartsList).map((d) => {
          if (!(d in preference.reportVisibility)) {
            visibleItems.push(d)
          }
        })
      } else {
        visibleItems = [
          ...Object.keys(chartsList)
        ]
      }
    } else {
      visibleItems = [
        ...Object.keys(chartsList)
      ]
    }
    return visibleItems
  }

  // render hidden chart menus
  const getChartMenus = () => {
    const visibleItems = getHiddenChartList()

    // if no hidden Charts or except comment analysis widget
    if (visibleItems.length === 0 || (visibleItems.length === 1 && visibleItems[0] == 1)) {
      return null
    } else {
      return [
        <MenuItem className="menu-header">
          {ReportsConstants.ADDITIONAL_REPORTS}
        </MenuItem>,
        ...(
          visibleItems.map((d, index) => {
            // Hide Comment Report
            if (d != 1)
              return (
                <MenuItem
                  key={`mt-hc-${index}`}
                  className={`menu-item-tab-space ${index === visibleItems.length - 1 ? 'bt-divider' : ''}`}
                  onClick={() => handleChartVisible(d)} data-my-value={d}>
                  {chartsList[d]}
                </MenuItem>
              )
          })
        )
      ]
    }
  }

  // render hidden chart menus
  const renderReportsMenu = () => {
    // if no menu render dummy menu
    // No hidden Charts
    if (reportsList.length === 0) {
      return (
        <MenuItem
          disabled
          className="menu-item-tab-space"
        >
          No Reports
        </MenuItem>
      )
    } else {
      return reportsList.map(d => {
        // Hide Comment Report
        if (d !== 'Comment Report')
          return (
              <MenuItem
                className="menu-item-tab-space"
                onClick={() => handleReportMenuClick(d)} data-my-value={d}>
                {d}
              </MenuItem>
          )
      })
    }
  }


  // Handle Chart Visible
  const handleChartVisible = (chart) => {
    let updatedChart = {
      ...preference.reportVisibility,
      [chart]: chartsList[chart]
    }
    dispatch(adminsAction.updateReportPreference({
      ...preference,
      reportVisibility: {
        ...updatedChart
      }
    }, () => {
      if (`${chart}` === "0") {
        dispatch(dashboardAction.getHighLevelSummaryData(() => {
          dispatch(dashboardAction.getHistoricalData())
        }))
      }

      // Get Comment Anaysis Question
      if (`${chart}` === "1") {
        dispatch(dashboardAction.gethighCommentQuestions((commQues) => {
          const firstQues = commQues && commQues[0] ? commQues[0]["baseQusId"] : "";
          let checkQuesExists = commQues && commQues.length ? commQues.filter(e => e.baseQusId === preference.commentAnalysis.baseQuesId).length > 0 : false;
          dispatch(dashboardAction.getCommentAnalysis({ baseQusId: firstQues, quesExists: checkQuesExists }));
        }))
      }

      if (`${chart}` === "2") {
        dispatch(dashboardAction.getYesNoData())
      }

      // Get Grid Chart Data
      if (`${chart}` === "3") {
        dispatch(dashboardAction.getGridChartData());
      }

      if (`${chart}` === "4") {
        dispatch(dashboardAction.getHotspotData())
      }

      // Get Engagement Index
      if (`${chart}` === "5") {
        dispatch(dashboardAction.EIOverall(() => {
          dispatch(dashboardAction.EIWithRgi());
        }));
      }

      if (`${chart}` === "6") {
        dispatch(dashboardAction.getTrendsOverTimeData());
      }
      // if all visible hide popup menu
      if (Object.keys(updatedChart).length === Object.keys(chartsList).length)
        setAnchorEl(null);

    }
    ))
  }

  const handleReportMenuClick = (r) => {
    setOptionsPopup(null);
    let updatedReportData;
    let reportData = {
      surveys: [...filterData.surveys],
      demographics: { ...filterData.demographics },
      date: { ...filterData.date }
    }
    // set default demographic to 'no breakout' for multi select
    if ([REPORT_TYPE_CONSTANTS.RAW_DATA, REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT, REPORT_TYPE_CONSTANTS.REHIRE_REPORT].indexOf(r) !== -1) {
      updatedReportData = { ...reportData, reportType: r, selectedDemographic: ['No breakout'] }
    } else {
      updatedReportData = { ...reportData, reportType: r }
    }

    if ((reportData.date && ((reportData.date.type === "Custom dates" && reportData.date.startDate && reportData.date.endDate) || (reportData.date.type && reportData.date.type !== "Custom dates")))) {
      dispatch(
        reportAction.getDemographics({
          metadata: [],
          surveys: reportData.surveys,
          date: reportData.date,
        })
      );
    }
    dispatch(reportAction.updateReport(updatedReportData));
    if (updatedReportData.reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
      dispatch(reportAction.getCommentQuestions(updatedReportData));
    }
    setDrawer(r)
  }

  return (
    <React.Fragment>
      <Tooltip arrow title={FilterConstants.MORE_OPTIONS}>
        <IconButton color="inherit" onClick={OpenOptions} className="p5 ml5" size="large">
          {Option()}
        </IconButton>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={optionsPopup}
        keepMounted
        open={Boolean(optionsPopup)}
        onClose={handleClose}
        classes={{ list: 'no-pad-top' }}
      >
        {getChartMenus()}
        <MenuItem
          className={`menu-header ${getHiddenChartList().length > 0 ? 'bt-divider' : ''}`}
        >
          {ReportsConstants.ADDITIONAL_EXPORTS}
        </MenuItem>
        {renderReportsMenu()}
      </Menu>
      <AdditionalReports
        open={drawer}
        toggleDrawer={toggleDrawer}
      />
    </React.Fragment>
  );
};

// default props
OptionsMenu.defaultProps = {
  classes: {},
  match: {},
  onChange: () => { }
};

// prop types
OptionsMenu.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  onChange: PropTypes.func
};

export default OptionsMenu;
