/* eslint-disable dot-notation */
import { alertConstant, participantConstant, uiConstant } from "../constants";
import { participantService } from "../services";
import { baseAction } from "./baseAction";

// Import History
import { participantConstants } from "../../config/constants";

const { request, success, failure } = baseAction;

// Export All Participant Actions Functions
export const participantAction = {
  getOne,
  search,
  update,
  create,
  surveySearch,
  remove,
  applyConversion,
  removeSurveysData,
  overrideSurveysData,
  surveyCheck,
  ageTenureCalculation,
  getSurveys,
  containsParticipants,
  getSurveydataForNotification
};

/**
 * Particular Participant Details
 */
function getOne (id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_GET_ONE_REQUEST));
    participantService.getOne(id).then(
      (data) => {
        dispatch(success(participantConstant.PARTICIPANT_GET_ONE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * age tenure calculation
 */
function ageTenureCalculation (clientDetails, successCallback = ()=>{}, popup = ()=>{}){
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE))
    dispatch(request(participantConstant.PARTICIPANT_AGETENURE_REQUEST))
    participantService.ageTenureCalculation(clientDetails).then(
      data => {
        dispatch(request(uiConstant.LOADING_FALSE))
        // if age tenure calculation not found
        if(data.data.birth_date === null || (data.data.tenure_calculation === '' && data.data.age_calculation === '')){
          successCallback()
        }
        else{
          popup(null)
          dispatch(success(participantConstant.PARTICIPANT_AGETENURE_SUCCESS, data))
        }
      },
      error => {
        dispatch(failure(participantConstant.PARTICIPANT_AGETENURE_FAILURE))
        dispatch(failure(alertConstant.ALERT_ERROR, error))
        dispatch(request(uiConstant.LOADING_FALSE))
      }
    )
  }
}

/**
 * Search Participant Details
 */
function search (skip, limit, sortBy, sort, sFields, sValues, updatedVisibleColumns = []) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_SEARCH_REQUEST));
    participantService.search(skip, limit, sortBy, sort, sFields, sValues, updatedVisibleColumns).then(data => {
      dispatch(success(participantConstant.PARTICIPANT_SEARCH_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
    },
    error => {
      dispatch(failure(participantConstant.PARTICIPANT_SEARCH_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(uiConstant.LOADING_FALSE));
    });
  };
}
/**
 * Search surveys
 */
function surveySearch (txt) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_SURVEY_SEARCH_REQUEST));
    participantService.surveySearch(txt).then(
      (data) => {
        dispatch(success(participantConstant.PARTICIPANT_SURVEY_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_SURVEY_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Participant
 *
 * @param {*} id  client id to update
 * @param {*} updateData data to be updated
 * @returns
 */
function update (updateData, successCallback = () => {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_UPDATE_REQUEST, updateData));
    return participantService.update(updateData).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(participantConstant.PARTICIPANT_UPDATE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback();
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * check survey response by Participant
 * updateData -> {survey_id, participant_id}
 */
function surveyCheck (updateData, successCallback = () => {}, survey) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_SURVEY_CHECK_REQUEST, updateData));
    return participantService.surveyCheck(updateData).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(participantConstant.PARTICIPANT_SURVEY_CHECK_SUCCESS, data));
        if (data.data) {
          dispatch(failure(alertConstant.ALERT_ERROR, participantConstants.REMOVE_SURVEY_WARN));
        } else {
          successCallback(data, survey);
        }
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_SURVEY_CHECK_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Add Participant
 */
function create (participant, successCallback = () => {}) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_ADD_REQUEST, participant));
    return participantService.create(participant).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(participantConstant.PARTICIPANT_ADD_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback();
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_ADD_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}
/**
 * Delete particular participant
 */
function remove (id, cb = () => {}) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_REMOVE_REQUEST));
    participantService.remove(id).then(
      (data) => {
        dispatch(success(participantConstant.PARTICIPANT_REMOVE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        if (data.status !== "failed") {
          cb()
        }
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_REMOVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Surveys Data for remove surveys
 */
function removeSurveysData () {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.REMOVE_SURVEYS_GET_REQUEST));
    participantService.removeSurveysData().then(
      (data) => {
        dispatch(success(participantConstant.REMOVE_SURVEYS_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(participantConstant.REMOVE_SURVEYS_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Surveys Data for override demographics
 */
function overrideSurveysData () {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.OVERRIDE_SURVEYS_GET_REQUEST));
    participantService.overrideSurveysData().then(
      (data) => {
        dispatch(success(participantConstant.OVERRIDE_SURVEYS_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(participantConstant.OVERRIDE_SURVEYS_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Apply Conversion
 */
function applyConversion (conversionData) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.APPLY_CONVERSION_REQUEST));
    participantService.applyConversion(conversionData).then(
      (data) => {
        dispatch(success(participantConstant.APPLY_CONVERSION_SUCCESS, data));
        if (data && data.data && (data.data["updateCount"] > 0 || data.data["totalCount"] > 0)) {
          dispatch(success(alertConstant.ALERT_SUCCESS, "Conversion applied successfully"));
        } else {
          dispatch(failure(alertConstant.ALERT_ERROR, "No participants found"));
        }
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(participantConstant.APPLY_CONVERSION_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
};

/**
 * get all surveys
 */
function getSurveys () {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_GET_ALL_SURVEY_REQUEST));
    participantService.getSurveys().then(
      (data) => {
        dispatch(success(participantConstant.PARTICIPANT_GET_ALL_SURVEY_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_GET_ALL_SURVEY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * checks wheather the participants have selected demographics
 */
function containsParticipants (key, successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_DEMOGRAPHICS_SEARCH_REQUEST));
    participantService.containsParticipants(key).then(
      (data) => {
        dispatch(success(participantConstant.PARTICIPANT_DEMOGRAPHICS_SEARCH_SUCCESS));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback(data.data)
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_DEMOGRAPHICS_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get surveys for a participant to send notification
 */
 function getSurveydataForNotification (key, successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_REQUEST));
    participantService.getSurveydataForNotification(key).then(
      (data) => {
        dispatch(success(participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback(data.data)
      },
      (error) => {
        dispatch(failure(participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}
