import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import PageNoIeSupportStyle from "./PageNoIeSupportStyle";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";

function PageNoIeSupport(props) {
    const { classes } = props;
    const { errorMsg } = props;
    return (
        <>
            <Grid container className={classes.ieErrorRoot}>
                <Grid item sm={12} className={classes.ieErrorContainer}>
                    <TypographyComponent
                        title={errorMsg.split('\n').map(str => <p>{str}</p>)}
                        variant={"h1"}
                        align={"center"}
                        className={classes.errorText}
                    > </TypographyComponent>
                </Grid>
            </Grid>
        </>
    );
}

// default props
PageNoIeSupport.defaultProps = {
    classes: {},
};

// prop types
PageNoIeSupport.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(PageNoIeSupportStyle)(PageNoIeSupport);
