/* eslint-disable prefer-const */
// import constants
import { alertConstant, uiConstant } from "../constants";
import { baseAction } from "./baseAction";
import { filterConstant } from "../constants/filterConstant";
import { filterService } from "../services/filterService";

const { request, success, failure } = baseAction;

export const filterAction = {
  getDemographics,
  getSurveys,
  demographicsByName,
  getAllSurveys,
  getDemographicsDropdown
};

/**
 * Search Survey
 * @param {*} successCallback 
 */
function getSurveys(search, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(filterConstant.FILTER_GET_SURVEYS_REQUEST));
    filterService.getSurveys(search).then(data => {
      dispatch(success(filterConstant.FILTER_GET_SURVEYS_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback()
    }, error => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(filterConstant.FILTER_GET_SURVEYS_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

/**
 * Get All Surveys List
 * @param {*} successCallback 
 */
function getAllSurveys(successCallback = () => { }, loader = true) {
  return dispatch => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(filterConstant.FILTER_GET_ALL_SURVEYS_REQUEST));
    filterService.getSurveys('').then(data => {
      dispatch(success(filterConstant.FILTER_GET_ALL_SURVEYS_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback()
    }, error => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(failure(filterConstant.FILTER_GET_ALL_SURVEYS_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
    });
  };
}

/**
 * 
 * @param {*} filterData 
 * @param {*} successCallback 
 */
function getDemographics(filterData, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(filterConstant.FILTER_GET_DEMOGRAPHICS_REQUEST));
    filterService.getDemographics(filterData).then(data => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(success(filterConstant.FILTER_GET_DEMOGRAPHICS_SUCCESS, data));
      successCallback();
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(filterConstant.FILTER_GET_DEMOGRAPHICS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * 
 * @param {*} filterData 
 * @param {*} successCallback 
 */
function getDemographicsDropdown(filterData, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(filterConstant.FILTER_GET_DEMOGRAPHICS_DDN_REQUEST));
    filterService.getDemographicsbyPreference(filterData).then(data => {
      dispatch(request(uiConstant.LOADING_FALSE));
      dispatch(success(filterConstant.FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS, data));
      successCallback();
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(filterConstant.FILTER_GET_DEMOGRAPHICS_DDN_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}

/**
 * 
 * @param {*} filterData 
 * @param {*} successCallback 
 */
function demographicsByName(filterData, successCallback = () => { }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(filterConstant.FILTER_DEMOGRAPHICS_BY_NAME_REQUEST));
    filterService.demographicsByName(filterData).then(data => {
      dispatch(success(filterConstant.FILTER_DEMOGRAPHICS_BY_NAME_SUCCESS, data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback();
    },
      error => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(failure(filterConstant.FILTER_DEMOGRAPHICS_BY_NAME_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      });
  };
}