import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

// Import Component
import DonutChart from "../../../../components/Charts/DonutChart/DonutChart";

const OverallYNChart = (props) => {
  const { data, index} = props;

  const resArr = [
    { name: data?.ansSet?.en?.[0] ? data?.ansSet.en[0] : "Yes", value: Math.round((data?.OverallYesNo?.yes / data?.OverallYesNo?.response) * 100) },
    { name: data?.ansSet?.en?.[1] ? data?.ansSet.en[1] : "No", value: Math.round((data?.OverallYesNo?.no / data?.OverallYesNo?.response) * 100) }
  ]

  const chartLayout = { width: 160, height: 200, thickness: 30 };
  const domainArr = [
    "Yes",
    "No"
  ];
  const rangeArr = ["#54C0FD", "#bad6e9"];

  return (
    <DonutChart
      chartId={`yesno${index}chart`}
      chartData={resArr}
      chartName="Overall"
      rangeArr={rangeArr}
      domainArr={domainArr}
      chartLayout={chartLayout}
      tooltip_text={"YesNo"}
    />
  );
};
// default props
OverallYNChart.defaultProps = {
  classes: {},
  data: {},
  index:""
};

// prop types
OverallYNChart.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  index:PropTypes.any
};
export default withStyles(ReportsStyles)(OverallYNChart);
