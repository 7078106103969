import React from 'react';
import Grid from "@mui/material/Grid";

// Panel
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SaveReport from "../../Report/CreateReport/ReportSteppers/SaveReport";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

function SaveReportPanel(props) {

    const { classes, currentPanel, onChangePanel, hasMEREPORTS } = props

    return (
        <Grid item xs={12}>  
        {hasMEREPORTS !== true &&
            <Accordion
                classes={{
                    root: classes.peaccordion_nocolor
                }}
                expanded={currentPanel === "SAVE REPORT (OPTIONAL)"}
                onChange={() => onChangePanel("SAVE REPORT (OPTIONAL)")}
                disabled={hasMEREPORTS}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <TypographyComponent variant={"h6"}
                    className={`${"txtBold "} ${currentPanel === "SAVE REPORT (OPTIONAL)" ? "panel-active" : ""} `}>
                        SAVE REPORT (OPTIONAL)
                    </TypographyComponent>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <SaveReport
                            isDrawer
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>}
        </Grid>
    )
}

export default SaveReportPanel