import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import Button from "@mui/material/Button";

// Import Styles and Image
import { DashboardClose, Expand } from "../../../../assets/images/svgComponents/ImageComponent";
import ReportsStyles from "../../ReportsStyles";

// Import Actions
import { dashboardAction, updateStateReducerAction } from "../../../../redux/actions";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import YesNoContent from "./YesNoContent";
import YesNoPopup from "./YesNoPopup";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent"

// Import Constants
import { ReportsConstants } from "../../../../config/constants";

// Import Reducer Constant
import { dashboardConstant } from '../../../../redux/constants';

function YesNo(props) {

  // Define Dispatch
  const dispatch = useDispatch();

  // Get Props
  const { classes, handleClose, DragHandle, isUpdate, updateReportPreference, preference, stopChartReload } = props;

  // Set Statue
  const [selectedValueIndex, setSelectedValueIndex] = useState();

  // Get Reducer Values
  let { yesno: yesnodata, metadataKeyname, metadataDispname, loading, showPopup } = useSelector((state) => state.dashboard.yesNo);

  // console.log("y/n: ", yesnodata, preference.yesno.rg);
  if (preference.yesno.rg === "" || !preference.yesno.rg) {
    preference.yesno.rg = "no_breakout";
  }

  metadataKeyname = { ...{ "no_breakout": "No Breakout" }, ...metadataKeyname }

  metadataDispname = { ...{ "No Breakout": "no_breakout" }, ...metadataDispname }

  // Get Yes / No Chart Details
  // useEffect(() => {
  //   if (isUpdate && !loading) {
  //     dispatch(dashboardAction.getYesNoData());
  //     stopChartReload("2")
  //   }
  // }, [dispatch, isUpdate, loading]);

  // Using spread to prevent reducer update on value chage//address error
  const { yesno } = { ...preference };

  // Sorting the Values
  let defaultData = ["No Breakout"]

  let metadataWithRGI = []
  if (yesnodata.demographics) {
    metadataWithRGI = yesnodata.demographics.map(demo => {
      return metadataKeyname[demo]
    }).sort((a, b) => a > b ? 1 : -1)
    if (yesnodata.demographics.indexOf(yesno.rg) === -1 && yesno.rg !== "no_breakout") {
      yesno.rg = metadataDispname[metadataWithRGI[0]]
    }
  }

  metadataWithRGI = [...defaultData, ...metadataWithRGI]

  /**
   * Hanlde Change Event
   * @param {*} value 
   * @param {*} name 
   */
  const handlechange = (value, name) => {
    const data = {
      ...preference,
      yesno: {
        rg: metadataDispname[value],
      },
    };
    dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, "yesNoinitialReload"))

    updateReportPreference(data, "-1")
    setSelectedValueIndex(metadataWithRGI.indexOf(value))
  }

  /**
 * Open Popup Event
 */
  const openPopup = () => {
    dispatch(updateStateReducerAction.update(dashboardConstant.SHOW_YESNO_POPUP, !showPopup))
  };

  /**
   * Close Popup Event
   */
  const onClose = () => {
    dispatch(updateStateReducerAction.update(dashboardConstant.SHOW_YESNO_POPUP, !showPopup))
  };

  return (
    <React.Fragment>
      <Grid item xs={12} className={"mb-14"}>
        <Grid item className={classes.insightTile}>
          <Grid container alignItems="center" className="mb-1">
            {
              !loading && <Grid item> <DragHandle /> </Grid>
            }

            <Grid item>
              <TypographyComponent variant="h4" className="txtBold fontSize16 ml-1">
                {"Yes/No Question"}
              </TypographyComponent>
            </Grid>

            <Grid item className={classes.viewActionButton}>
              {
                !loading && <Grid container>
                  <Tooltip title={"Expand"} arrow >
                    <IconButton onClick={() => { openPopup(); }} size="large">
                      {Expand()}
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={"Close"} arrow >
                    <IconButton onClick={() => handleClose('2')} size="large">
                      {DashboardClose()}
                    </IconButton>
                  </Tooltip>
                </Grid>
              }
            </Grid>
          </Grid>

          <Grid container>
            <Grid item sm={12}>
              <Grid>
                <TypographyComponent
                  className={`${classes.chartHeadings}`}
                  title={ReportsConstants.VIEW_BY}
                  variant={"h6"}>
                </TypographyComponent>
              </Grid>

            </Grid>
            <Grid item sm={12} className={`${classes.Yesno_Form_align} mb-3`}>
              <Grid md={2}>
                <ValidatorForm
                  id="yesnochart"
                  className={`${"height28 conversionAutoComplete boderRadius4"}`}
                  name="yesnochart"
                  useref="yesnochartform"
                  autoComplete="off">
                  <AutoCompleteComponent
                    name="demographics"
                    suggestions={metadataWithRGI}
                    handleAutoCompolete={(e, n) => handlechange(e, n)}
                    suggestionType="array"
                    value={(metadataKeyname && metadataKeyname[yesno.rg]) ? metadataKeyname[yesno.rg] : ""}
                  ></AutoCompleteComponent>
                </ValidatorForm>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <Grid container>
                <YesNoContent
                  classes={classes}
                  chartData={yesnodata}
                  selectedValue={yesno.rg}
                  loading={loading}
                  selectedValueIndex={selectedValueIndex}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {showPopup && (
        <DialogComponent open={showPopup} fullScreen={true}>
          <Grid container spacing={4} style={{ padding: "50px" }}>
            <Grid xs={12}>
              <Grid item>
                <TypographyComponent variant="h4" className="txtBold fontSize16 ml-1 pt-1">
                  {"Yes/No Question"}
                </TypographyComponent>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              {!yesnodata.demographics.length === 0 &&
                <TypographyComponent variant="h5" className="pl5">
                  {metadataKeyname[yesno.rg] ? metadataKeyname[yesno.rg] : ""}
                </TypographyComponent>
              }
            </Grid>
            <Grid item xs={8} className={classes.dialog_close}>
              <Button variant="text" size={"small"} onClick={onClose} style={{ top: "-56px" }}>
                {DashboardClose()}
                <TypographyComponent variant="h5" className="pl5">
                  {"Close"}
                </TypographyComponent>
              </Button>
            </Grid>
            <Grid container className={"ml-3"}>
              <Grid item sm={12}>
                <Grid>
                  <TypographyComponent
                    className={`${classes.chartHeadings}`}
                    title={ReportsConstants.VIEW_BY}
                    variant={"h6"}>
                  </TypographyComponent>
                </Grid>

              </Grid>

              <Grid item sm={12} className={`${classes.Yesno_Form_align} mb-3`}>
                <Grid md={2}>
                  <ValidatorForm
                    id="yesnochart1"
                    className={`${"height28 conversionAutoComplete boderRadius4"}`}
                    name="yesnochart1"
                    useref="yesnochartform"
                    autoComplete="off">
                    <AutoCompleteComponent
                      name="demographics"
                      suggestions={metadataWithRGI}
                      handleAutoCompolete={(e, n) => handlechange(e, n)}
                      suggestionType="array"
                      value={(metadataKeyname && metadataKeyname[yesno.rg]) ? metadataKeyname[yesno.rg] : ""}
                    ></AutoCompleteComponent>
                  </ValidatorForm>
                </Grid>
              </Grid>
            </Grid>
            <YesNoPopup
              chartData={yesnodata}
              selectedValue={yesno.rg}
              selectedValueDemoItem={metadataKeyname[yesno.rg] ? metadataKeyname[yesno.rg] : ""}
              selectedValueIndex={selectedValueIndex}
              onClose={onClose}
            ></YesNoPopup>
          </Grid>
        </DialogComponent>
      )}

    </React.Fragment>
  );
}
// default props
YesNo.defaultProps = {
  classes: {},
  isUpdate: false,
  handleClose: () => { },
  preference: {},
  updateReportPreference: () => { },
  stopChartReload: () => { }
};

// prop types
YesNo.propTypes = {
  classes: PropTypes.object,
  isUpdate: PropTypes.bool,
  handleClose: PropTypes.func,
  preference: PropTypes.object,
  updateReportPreference: PropTypes.func,
  stopChartReload: PropTypes.func
};

export default withStyles(ReportsStyles)(YesNo);