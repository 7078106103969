import React, { useState } from "react";
import PropTypes from "prop-types";

import { Box, Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import _ from "lodash";

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

//Import Constants
import { dashboardConstants, ReportsConstants } from "../../../../config/constants";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

function EngagementBenchmark(props) {
  // props
  const { classes, data, adminPreference } = props;
  const { overallPct } = data
  
  let overallPct_line ;
  if (overallPct <= 49){
    overallPct_line = (25 / 49) * overallPct;
  } else if (overallPct <= 58){
    overallPct_line = 25 + (25/ 9) * (overallPct-49);
  } else if(overallPct <= 66 ){
    overallPct_line = 50 + (25 / 8) * (overallPct - 58);
  }else{
    overallPct_line = 75 + (25/34)* (overallPct-66);
  }

  let textalignmargin = -66
  if(overallPct_line > 89){
    textalignmargin =  textalignmargin-((overallPct_line - 75)*3) 
  } else if(overallPct_line < 30){
    textalignmargin = textalignmargin - ((overallPct_line -20)*3)
  }

  const handleClose = () => {
    if (typeof adminPreference?.data?.hideDashWidgets?.engagementBenchMark === 'boolean') {
        adminPreference['data']['hideDashWidgets']['engagementBenchMark'] = true;
        adminPreference.updatePreference(adminPreference?.data)
    }
  }

  return (
    <Grid
      className={`${classes.dashboardTile} ss`}
      style={{ minWidth: "300px", display: "flex", flexDirection: "column" , position:"relative"}}
    >
      <Grid item className='dflexCenter width100 spacebetween'>
        <TypographyComponent variant='h5' className={'fontWeight600 width100 txtLeft'}>{ReportsConstants.ENGAGEMENT_BENCHMARK}</TypographyComponent>
        <ToolTipComponent title={ReportsConstants.CLOSE}>
          <IconButton onClick={handleClose} size="large">
            {DashboardClose()}
          </IconButton>
        </ToolTipComponent>
      </Grid>
      <Grid className={`width100 height100 ${classes.engagementInnerContainer}`}>
        <Grid style={{ width: "100%", position: "relative" }}>
          <Grid
            container
            spacing={2}
            style={{ width: "100%", margin: 0, position: "absolute", top: "-40%" }}
          >
            <Grid item xs={4} style={{ height: "35px", padding: 0 }}>
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular displayInline mb-15 fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.BOTTOM_QUARTILE}</TypographyComponent>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ height: "35px", padding: 0, textAlign: "center" }}
            >
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular displayInline mb-15 fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.MEDIAN}</TypographyComponent>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ height: "35px", padding: 0, textAlign: "right" }}
            >
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular displayInline mb-15 fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.TOP_QUARTILE}</TypographyComponent>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
            <Grid style={{
              padding: 0, marginLeft: `${overallPct_line}%`,
              position: "absolute", top: "-54%"
            }}>
              <Grid style={{ position: "absolute", top: "-34%", right: "-3", transform: "translateX(-18px)", width:"105px"}} >
                <TypographyComponent className="fontSize16 fontRoboto fontweight600" align="center" color="black" style={{ marginBottom: "-9px" ,marginLeft : `${textalignmargin}px`,}}>{dashboardConstants.ENGAGEMENT_BENCHMARK.YOUR_SCORE} {overallPct}%</TypographyComponent>
                <ArrowDropDownIcon style={{width: '29px', height: 'inherit', margin: '-2px 0 0 4px'}} />
              </Grid>
              <Grid
                style={{
                  backgroundColor: "black",
                  height: "70px",
                  width: "1px",
                }}
              >
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ width: "100%", margin: 0 }} >
          <ToolTipComponent title={"Don’t be discouraged. Leverage these results as motivation for change!"}>
            <Grid
              item
              xs={3}
              style={{ backgroundColor: "#ffae4b", height: "35px", padding: 0 }}
              ></Grid>
          </ToolTipComponent>
          <ToolTipComponent title={"Focus on areas that improve engagement and are actionable."}>
            <Grid
              item
              xs={3}
              style={{ background: "#E2E4E5", height: "35px", padding: 0 }}
              ></Grid>
            </ToolTipComponent>  
            <ToolTipComponent title={"Looking good! Your scores fall in the top half of all organizations! Keep at it to get even better results."}>
            <Grid
              item
              xs={3}
              style={{ background: "#B8E5FE", height: "35px", padding: 0 }}
            ></Grid>
            </ToolTipComponent>
            <ToolTipComponent title={"Congratulations! You’re in the top quartile of all organizations. Celebrate strengths and identify new opportunities."}>
            <Grid
              item
              xs={3}
              style={{ background: "#54C0FD", height: "35px", padding: 0 }}
              ></Grid>
            </ToolTipComponent>
          </Grid>
          <Grid container spacing={2} style={{ width: "100%", margin: 0 }}>
            <Grid
              item
              xs={3}
              style={{
                borderLeft: "1px solid #babfc1",
                borderRight: "1px solid #babfc1",
                height: "10px",
                padding: 0,
              }}
            ></Grid>
            <Grid
              item
              xs={3}
              style={{
                borderRight: "1px solid #babfc1",
                height: "10px",
                padding: 0,
              }}
            ></Grid>
            <Grid
              item
              xs={3}
              style={{
                borderRight: "1px solid #babfc1",
                height: "10px",
                padding: 0,
              }}
            ></Grid>
            <Grid
              item
              xs={3}
              style={{
                borderRight: "1px solid #babfc1",
                height: "10px",
                padding: 0,
              }}
            ></Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            justifyContent={'space-between'}
            width={'calc(100% + 20px)'}
            className="mt5 mb-0 mr-0"
            marginLeft={'-5px'}
          >
            <Grid item className={'p-0'}>
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.ZERO_PERCENT}</TypographyComponent>
            </Grid>
            <Grid item className={'p-0'}>
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.TWENTY_FIVE_PERCENT}</TypographyComponent>
            </Grid>
            <Grid item className={'p-0'}>
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.FIFTY_PERCENT}</TypographyComponent>
            </Grid>
            <Grid item className={'p-0'}>
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.SEVENTY_FIVE_PERCENT}</TypographyComponent>
            </Grid>
            <Grid item className={'p-0'}>
              <TypographyComponent color={'#B1B2B3'} className={'fontSize12 txtRegular fontRoboto'}>{dashboardConstants.ENGAGEMENT_BENCHMARK.HUNDREAD_PERCENT}</TypographyComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

// default props
EngagementBenchmark.defaultProps = {
  classes: {},
  data: {},
  adminPreference: {},
};

// prop types
EngagementBenchmark.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(EngagementBenchmark);
