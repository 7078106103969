import { uiConstant } from "../constants/uiConstant";

// Set Initial State
const initialState = {
  loading: false,
  requestPending: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case uiConstant.LOADING_TRUE:
      return {
        ...state,
        requestPending: state.requestPending + 1,
        loading: true
      };
    case uiConstant.LOADING_FALSE:
      const requestPending = state.requestPending - 1 > 0 ? state.requestPending - 1 : 0;
      return {
        ...state,
        requestPending: requestPending,
        loading: !(requestPending === 0)
      };
    default:
      return state;
  }
}
