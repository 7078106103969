/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { SortableHandle } from "react-sortable-hoc";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";

// import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import QueryBuilderComponent from "../../../../components/QueryBuilderComponent/QueryBuilderComponent";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants, surveyTypes } from "../../../../config/constants";
import { duplicateQuesFind } from "../../../../helpers";
import { surveyIntentionQuesTextDisable, surveyEngagementQuesTextDisable, surveyWorkFromHomeQuesTextDisable } from "../../../../config/constants";

// Import Actions
import { updateStateReducerAction, queryBuilderAction, alertAction } from "../../../../redux/actions";

// Import Images
import { Filter, Move, SettingAccordSurvey, TrashSurvey, DialogueClose, /* RepeatGrid */ } from "../../../../assets/images/svgComponents/ImageComponent";

import RepeatGrid from "../../../../assets/images/svg/repeat-grid.svg";

// Import Containter
import MoveQuestionsToCategory from "./MoveQuestionsToCategory";
import AnsChoicesPopUp from "./AnsChoicePopUp";

import { deFormatQuery } from "../../../../components/QueryBuilderComponent/config";

/**
 * Drage Handle
 */
const DragHandle = SortableHandle(() => (
  <Tooltip arrow title="Reorder Icon">
  <img src={RepeatGrid} className="cursor-move pt5 icon-hover" alt={"Menu"} draggable={false} />
  </Tooltip>
));

function Questions (props) {
  const dispatch = useDispatch();
  const [moveQuesToCategory, setMoveQuesToCategory] = useState(null);
  const [ansPopUp, setansPopUp] = useState(null);
  const [editable, setEditable] = useState(false);
  const [removeQuesDialog, setRemoveQuesDialog] = useState({ flag: false, ind: "" ,notify:false});
  const [querBuilderPopup, setQueryBuilderPopup] = useState(false);
  const [respText, setRespText] = useState(false);
  const [reloadQB, setReloadQB] = useState(0);
  const [qbQuery, setQBQuery] = useState(null);

  // Get Props Values
  const { classes, questionData, item, index, langCode, queryBuilderData, activeCategoryTab, errors } = props;
  const { questions, catOrder } = questionData;

  // has error
  let hasError = false
  if (errors && errors[langCode]) {
    errors[langCode].filter(f => {
      const cat_name = f.split("_")
      if (cat_name[0] === catOrder[activeCategoryTab] && cat_name[1] === item.baseQusId) {
        hasError = true
      }
    })
  }

  // Get Reducer Data
  const questionWithResponse = useSelector(state => state.survey.responseDetails.question_ids);
  const surveyType = useSelector( state => state.survey.getOne.type );

  // Update reducer data
  const updateReducer = (questions) => {
    const reducerData = { ...questionData, questions: questions };
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, reducerData));
  };

  // Delete Questions
  const deleteQuestions = (questionID) => {
    questions.filter((item,index) => {
      if (questions[index].baseQusId === questionID) {
        questions.splice(index, 1);
      }
      return true
    });
    updateReducer(questions);
    setRemoveQuesDialog({ flag: false, ind: "" ,notify:false});
    return true;
  };

  // Update Question Text
  const updateQuestionText = (event) => {
    let { name, value } = event;
    let field = name.split("  ");
    let baseQusID = field[0];
    let updateFieldName = field[1];
    let language = field[2];
    questions.filter((item,index) => {
      if (questions[index].baseQusId === baseQusID) {
        questions[index][updateFieldName][language] = value;
        
        // Empty Translated Questions if Question of Default Language(English) is Edited
        if (Object.keys((questions?.[index]?.[updateFieldName] || {})).length > 1) {
          Object.keys(questions?.[index]?.[updateFieldName]).map(lang => {
            if (lang !== 'en') {
              questions[index][updateFieldName][lang] = '';
            }
          })
        }
      }
      return true;
    });
    updateReducer(questions);
    if (duplicateQuesFind(questions, langCode) === true) {
      dispatch(alertAction.error(surveyQuestionsConstants.DUPLICATE_QUESTION));
    }
    return true;
  };

  // Update Matrix Sub Question Text
  // eslint-disable-next-line no-unused-vars
  /* const updateSubQuestionText = (event) => {
    let { name, value } = event;
    let field = name.split("  ");
    let baseQusID = field[0];
    let updateFieldName = field[1];
    let language = field[2];
    let subIndex = field[3];
    questions.filter((question, index) => {
      if (questions[index].baseQusId === baseQusID) {
        questions[index][updateFieldName][subIndex]["text"][language] = value;
        if (duplicateSubQuesFind(question, langCode) === true) {
          dispatch(alertAction.error(surveyQuestionsConstants.DUPLICATE_SUB_QUESTION));
        }
      }
    });
    updateReducer(questions);
  }; */

  // Handle Question Move To Category Popup
  const handleQuestionMoveToCategory = (event) => {
    setMoveQuesToCategory(event.currentTarget);
  };

  // show Answer choices On  Clicking question Type
  const showAnsChoices = (event) => {
    setansPopUp(event.currentTarget);
  };

    // Dispatch alert for non editable questions
    const handleNotEditable = (event) => {
      dispatch(alertAction.error(surveyQuestionsConstants.NON_EDITABLE_QUEST));
      
    };

  // Check Question Response Before Delete
  const checkQuestionAndDelete = (deleteBaseQusID,initialCall) => {
    const quesId = ["21","33","39","52",,"64","106","16","62"];
    if( quesId.includes(deleteBaseQusID) && !initialCall){
      setRemoveQuesDialog({ flag: false, ind: deleteBaseQusID ,notify:true})
    } else {
    if (questionWithResponse.indexOf(deleteBaseQusID) > -1) {
      setRespText(true);
    }
    setRemoveQuesDialog({ flag: true, ind: deleteBaseQusID ,notify:false});
  }
  };

  // edit question settings icon onclick
  const editOnClick = (event) => {
    const toggle = !editable;
    setEditable(toggle);
    questions.filter((question, index) => {
      if (question.baseQusId === item.baseQusId) {
        questions[index].editable = toggle;
      }
      return true
    });
    updateReducer(questions);
    return true;
  };


  // update query filter for question
  // reportGroupmapping
  const updateReportGroupMapping = (query) => {
    let queryy = JSON.parse(JSON.stringify(query));
    if (queryy && queryy.rules && queryy.rules[0] && queryy.rules[0].value && queryy.rules[0].value && queryy.rules[0].value.length === 0) {
      query = null;
    }
    questions.filter((question, index) => {
      if (question.baseQusId === item.baseQusId) {
        questions[index].reportGroupMappings = query;
      }
      return true;
    });
    updateReducer(questions);
    return true;
  };

  return (
    <React.Fragment>
      <Grid container className={classes.quest_cateitem} alignItems={"flex-start"}>
        <Grid item sm={9}>
          <Grid container alignItems={"flex-start"}>
            <Grid item className={`${"dflex pr-15"} ${classes.subquest_list}`}>
              <DragHandle></DragHandle>
            </Grid>
            <Grid item className={`${"pl-1 pr-1 pt2"} ${classes.subquest_list}`}>
              <Badge
                color="secondary"
                className="badgebig"
                badgeContent={index + 1}
              ></Badge>
            </Grid>
              <Tooltip title={item.text.en} arrow placement='bottom-start'>
            <Grid item className={`width90 ${hasError ? "quest_text se_err" : ""}`}>
                <TextFieldComponent
                id={`questionText${index}`}
                type="string"
                name={`${item.baseQusId}  text  en`}
                fullWidth={true}
                size="small"
                onClick = { surveyIntentionQuesTextDisable.includes(item.baseQusId) ? handleNotEditable : (surveyType ==="Engagement" && surveyEngagementQuesTextDisable.includes(item.baseQusId) ? handleNotEditable: "")}
                value={item.text.en}
                handleChange={(e) => updateQuestionText(e)}
                disabled={surveyIntentionQuesTextDisable.includes(item.baseQusId) ? true : (/* surveyType ==="Engagement" &&  */surveyEngagementQuesTextDisable.includes(item.baseQusId) ? true: false)}
                className={`${classes.quest_text_container} ${"br-none ml5 questionPanel height29"} ${((item.qusType !== "Open Ended") && ansPopUp !== null) ? "bgGrey" : ""}`}
              />
              
              {item.qusType === "Matrix" && item.subQues && item.subQues.length > 0 &&
                <React.Fragment>
                  <Grid className="mt-1"><TypographyComponent variant="h6" className={"fontSize13 txtBold"}>Sub questions</TypographyComponent></Grid>
                  {item.subQues.map((key, indexx) => (
                    <Grid container key={indexx} alignItems="center" className="mb5">
                      <Grid item className={`${"pl-1 pr-1"} ${classes.subquest_list}`}>
                        <Badge
                          color="secondary"
                          className="badgeMedium"
                          badgeContent={indexx + 1}
                        ></Badge>
                      </Grid>
                      <Grid item className="width90">
                        {/* <TextFieldComponent
                          id={`subQuestion${indexx}`}
                          type="string"
                          name={`${item.baseQusId}  subQues  en  ${indexx}`}
                          fullWidth={true}
                          value={key.text.en}
                          handleChange={(e) => updateSubQuestionText(e)}
                          className="br-none"
                        /> */}
                        <TypographyComponent variant={"h6"} className={"fontSize13 pl-1 pt5"}>
                          {key.text.en}
                        </TypographyComponent>
                      </Grid>
                    </Grid>
                  ))}
                </React.Fragment>
              }
            </Grid>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid item sm={3} className={classes.subquest_list}>
          <Grid container justifyContent={"flex-end"} alignItems={"center"}>
            <Grid item>
              <TypographyComponent variant={"h6"}>
                <Link
                  aria-describedby={"ans_set_popup"}
                  onClick={showAnsChoices}
                  className={"fontSize13"}
                >
                  {item.qusType}
                </Link>
              </TypographyComponent>
            </Grid>
            <Grid item className="pl-3 dflex">
              <Tooltip arrow title="Settings">
                <IconButton
                  color="inherit"
                  className={`${"p5 icon-hover"} ${item.RGConversion || surveyIntentionQuesTextDisable.includes(item.baseQusId) || surveyWorkFromHomeQuesTextDisable.includes(item.baseQusId) ? "settings_icon_active" : ""}`}
                  onClick={editOnClick}
                  size="large">
                  {/* <img src={SettingsImg} alt={"Settings"} /> */}
                  {SettingAccordSurvey()}
                </IconButton>
              </Tooltip>
              {surveyType !== surveyTypes.MULTI_RATER_FEEDBACK &&
                <Tooltip arrow title="Filter">
                <IconButton
                  color="inherit"
                  className={`${"p5 icon-hover"} ${item.reportGroupMappings ? "settings_icon_active" : ""}`}
                  onClick={() => {
                    dispatch(queryBuilderAction.getFieldNames("audience"));
                    setQBQuery(item.reportGroupMappings);
                    setQueryBuilderPopup(true);
                  }}
                  size="large">
                  {Filter()}
                </IconButton>
              </Tooltip>
              }
              
              <Tooltip arrow title={questionData.template==="Manager 360"?"Competency":"Category"}>
                <div className="c_tooltip">
                  <IconButton
                    color="inherit"
                    className="p5 icon-hover"
                    disabled={catOrder.length <= 1}
                    aria-describedby={"move_ques_to_category"}
                    onClick={handleQuestionMoveToCategory}
                    size="large">
                    {Move()}
                  </IconButton>
                </div>
              </Tooltip>
              <Tooltip arrow title="Delete">
                <IconButton
                  color="inherit"
                  className="p5 icon-hover ImgActiveOrange"
                  onClick={(e) => {
                    checkQuestionAndDelete(item["baseQusId"]);
                  }}
                  size="large">
                  {TrashSurvey()}
                  {/* <img src={DeleteImg} alt={"Delete"} /> */}
                </IconButton>
              </Tooltip>
            </Grid>

            <MoveQuestionsToCategory
              questionIndex={index}
              anchorEl={moveQuesToCategory}
              classes={classes}
              question={item}
              questionData={questionData}
              handelCloseMoveQuesToCat={() => {
                setMoveQuesToCategory(null);
              }}
            />
            {item.qusType !== "Open Ended" && (
              <AnsChoicesPopUp
                questionIndex={index}
                anchorEl={ansPopUp}
                classes={classes}
                langCode={langCode}
                question={item}
                className="AnsChoicesPopUp"
                questionData={questionData}
                showAnsChoices={() => {
                  setansPopUp(null);
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      {removeQuesDialog.ind !== "" && (
        <Dialog open={removeQuesDialog.flag}>
          <DialogContent>
            {respText ? surveyQuestionsConstants.RESPONSE_QUESTION_DELETE_DIALOG_MESSSAGE : surveyQuestionsConstants.QUESTION_DELETE_DIALOG_MESSSAGE}
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Yes"}
              color={"primary"}
              size={"small"}
              handleClick={() => { deleteQuestions(removeQuesDialog.ind); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
            <ButtonComponent
              title={"No"}
              color={"secondary"}
              size={"small"}
              handleClick={() => { setRemoveQuesDialog({ flag: false, ind: "",notify:false }); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
        </Dialog>
      )}
      <Dialog open={removeQuesDialog.notify}>
          <DialogContent>
            {surveyQuestionsConstants.REMOVING_THIS_QUESTION_MAY_ALTER_REPORTING}
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Okay"}
              color={"primary"}
              size={"small"}
              handleClick={() => { checkQuestionAndDelete(removeQuesDialog.ind,true); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
            <ButtonComponent
              title={"Cancel"}
              color={"secondary"}
              size={"small"}
              handleClick={() => { setRemoveQuesDialog({ flag: false, ind: "" ,notify:false}); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
        </Dialog>
      {
        querBuilderPopup && (
          <DialogComponent open={true} fullScreen={true}>
            <div className="centercontainer pt-2" style={{ margin: "0px auto", width: "90%" }}>
              <Grid item xs={12} className={classes.dialog_close}>
                <Button
                  variant="text"
                  size={"small"}
                  onClick={() => {
                    setQBQuery(null);
                    setQueryBuilderPopup(false);
                  }}
                  pageClassName="ml-1">
                  {/* <img src={CloseImg} alt="CloseImg" /> */}
                  {DialogueClose()}
                  <TypographyComponent variant="h5" className="pl5">{"Close"}</TypographyComponent>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <TypographyComponent variant="h4" className="txtSemiBold">
                  Filter Questions
                </TypographyComponent>
              </Grid>
              <Grid item xs={12}>
                <TypographyComponent variant="h6">
                  {item.text.en}
                </TypographyComponent>
              </Grid>
              <div>
                <QueryBuilderComponent
                  classes={classes}
                  needSaveCancel
                  hideCancelButton
                  handleSaveOnClick={(query) => {
                    if (typeof query === "string") {
                      // eslint-disable-next-line quotes
                      if ((query !== null && !(new RegExp('""')).test(query) && query.indexOf("[]") === -1 && query.indexOf("{}") === -1)) {
                        updateReportGroupMapping(query);
                        setQBQuery(null);
                        setQueryBuilderPopup(false);
                        dispatch(alertAction.successAlert("Question filter saved successfully"));
                      } else {
                        dispatch(alertAction.error("All conditions are required"));
                      }
                    } else if ((query && query.rules && Array.isArray(query.rules) && query.rules.length > 0 && typeof query.rules[0] === "object" && Object.keys(query.rules[0]).length === 0)) {
                      dispatch(alertAction.error("All conditions are required"));
                    } else {
                      if (!query) {
                        updateReportGroupMapping(query);
                      }
                      setQBQuery(null);
                      setQueryBuilderPopup(false);
                      dispatch(alertAction.successAlert("Question filter saved successfully"));
                    }
                  }}
                  handleCancelOnClick={() => {
                    setQBQuery(null);
                    setQueryBuilderPopup(false);
                  }}
                  fieldOnChange={(fieldName) => {
                    dispatch(queryBuilderAction.getFieldValues("audience", fieldName));
                  }}
                  handleQueryChange={(queryJSON, moongoQuery) => {
                    const { hadEmptySet } = deFormatQuery(moongoQuery);
                    if (hadEmptySet) {
                      setQBQuery(moongoQuery);
                      setReloadQB(reloadQB + 1);
                    }
                  }}
                  collectionFields={queryBuilderData.fields.filter(t => t.type !== "date")}
                  values={queryBuilderData.fieldValues}
                  allowedOperators={["includes", "does not include"]}
                  query={qbQuery}
                  isLoadedFromDB={item._id || item.baseQusId}
                />
              </div>
            </div>
          </DialogComponent>
        )
      }
    </React.Fragment>
  );
}

// default props
Questions.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  item: {},
  index: 0,
  queryBuilderData: {}
};

// prop types
Questions.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  item: PropTypes.object,
  index: PropTypes.number,
  queryBuilderData: PropTypes.object
};

export default Questions;
