
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { contentLibraryAction, updateStateReducerAction } from "../../../../redux/actions";
import { contentLibraryConstant } from "../../../../redux/constants";

import AddActionAlert from "./AddActionAlert";
import ActionAlertCard from "./ActionAlertCard";

function RenderActionAlert(props) {

    const { classes, item, index } = props;
    const dispatch = useDispatch();

    const { actionAlert } = useSelector(state => state.contentLibrary);
    const [actionAlertItem, setActionAlertItem] = useState(null);

    // update the edited action alert here for both save or cancel
    const updateActionAlert = (topicName, updatedAction) => {
        actionAlert.filter((action, index) => {
            if (topicName === action.TopicName) {
                actionAlert[index] = { ...updatedAction };
            }
        });
        dispatch(contentLibraryAction.update(updatedAction));
    };

    // update the edited action alert here for cancel
    const updateActionAlertCancel = (topicName, updatedAction) => {
        actionAlert.filter((action, index) => {
            if (topicName === action.TopicName) {
                actionAlert[index] = { ...updatedAction };
            }
        });
        dispatch(updateStateReducerAction.update(contentLibraryConstant.CONTENTLIBRARY_ACTIONALERT_REDUCER_UPDATE, actionAlert));
    };

    return (
        <Grid item sm={12} className={"mt5"}>
            <Grid item sm={12} >
                <React.Fragment>
                    {item.editable ?
                        <AddActionAlert
                            classes={classes}
                            item={actionAlertItem || item}
                            actionAlert={actionAlert}
                            index={index}
                            editActionAlertItem={(reducerData) => setActionAlertItem(reducerData)}
                            updateAddActionAlertFlag={(isSave) => {
                                // if changes and save clicked
                                // else no changes or cancel
                                if (isSave && actionAlertItem) {
                                    // remove editable state after save
                                    delete actionAlertItem.editable;
                                    // reset to old Action Alert
                                    updateActionAlert(item.TopicName, actionAlertItem);
                                    setActionAlertItem(null);
                                } else {
                                    // reset to old question item and disable edit
                                    delete item.editable;
                                    updateActionAlertCancel(item.TopicName, item);
                                    setActionAlertItem(null);
                                }
                            }}
                        /> :
                        <ActionAlertCard
                            classes={classes}
                            item={actionAlertItem || item}
                            actionAlert={actionAlert}
                            index={index}
                        />
                    }
                </React.Fragment>
            </Grid>
        </Grid>
    )
}

export default RenderActionAlert;

