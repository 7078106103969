/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";

// Import History
import history from "../../config/history";

// Import Styles and Image
import SurveysStyles from "./SurveysStyles";

// Import Cofig Constants
import { surveyConstants } from "../../config/constants";

// Import Container
import Container from "../Containers/Container";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import {CreateSurvey, ManageSurvey} from "../../assets/images/svgComponents/surveySVG";

function Survey (props) {
  const { classes, match, user } = props;

  // Goto Survey List Page
  const gotoSurveyList = () => {
    localStorage.removeItem("formSubmitCancelAction");
    history.replace("/surveys/manage?reset=true");
  };

  return (
    <Container align={"center"}>
      <Grid container>
        <Grid item xs={12} className="mb-2">
          <TypographyComponent
            title={`${surveyConstants.WELCOME_SURVEY_TEXT} ${user.firstName ? user.firstName : ""} ${user.lastName ? user.lastName : ""}`}
            variant={"h1"}
            className={"txtRegular"}
            align={"center"}></TypographyComponent>
          <Grid className={"pt-1"}>
            <TypographyComponent
              className={"txtSemiBold txtgrey"}
              variant={"h3"}
              align={"center"}
              title={"Let's get started!"}>
            </TypographyComponent>
          </Grid>
        </Grid>
        <Grid item xs={12} className="mt-5">
          <Grid container direction="row" justifyContent="center" alignItems={"center"}>
            <Grid item >
              <Grid container direction="column" alignItems={"center"} className={`${classes.servey_tile} ${"servey_tile"} `}>
                <Grid className={classes.servey_tile_img}>
                  {/* <img className={classes.servey_tile_img_padding} src={createSurvey} alt="Create New Survey" /> */}
                  {CreateSurvey()}

                </Grid>
                <Grid className="pt-3 pb-1">
                  <TypographyComponent
                    variant={"h2"}
                    align={"center"}
                    className={`${"nowrap fontSize22"}`}
                    title={surveyConstants.CREATE_NEW_SURVEY}>
                  </TypographyComponent>
                </Grid>
                <Grid className={"pb-3 txtcenter"}>
                  <TypographyComponent
                    variant={"h6"}
                    align={"center"}
                    className={classes.surveys_message_text}
                    title={surveyConstants.CREATE_NEW_SURVEY_HELP_TEXT}>
                  </TypographyComponent>
                </Grid>
                <Grid>
                  <ButtonComponent
                    title={surveyConstants.BUILD_SURVEY_TEXT}
                    color={"primary"}
                    size={"medium"}
                    component={Link}
                    to={`${match.url}/new`}
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems={"center"} className={`${classes.servey_tile} ${"servey_tile"} `} >
                <Grid className={classes.servey_tile_img}>
                  {ManageSurvey()}
                </Grid>
                <Grid className="pt-3 pb-1">
                  <TypographyComponent
                    variant={"h2"}
                    align={"center"}
                    className="fontSize22"
                    title={surveyConstants.MANAGE_SURVEY_TEXT}>
                  </TypographyComponent>
                </Grid>
                <Grid className={"pb-3 txtcenter"}>
                  <TypographyComponent
                    variant={"h6"}
                    align={"center"}
                    className={classes.surveys_message_text}
                    title={surveyConstants.MANAGE_SURVEY_HELP_TEXT}>
                  </TypographyComponent>
                </Grid>
                <Grid>
                  <ButtonComponent
                    title={surveyConstants.MANAGE_SURVEY_TEXT}
                    color={"primary"}
                    size={"medium"}
                    handleClick={gotoSurveyList}
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container >

  );
}

// default props
Survey.defaultProps = {
  classes: {},
  match: {},
  user: {}
};

// prop types
Survey.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  user: PropTypes.object
};

export default withStyles(SurveysStyles)(Survey);
