import { surveyConstant } from "../constants/surveyConstant";

const tableDatas = (sortName) => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: []
      }
    }
  };
};

const getAnswerset = () => {
  return JSON.parse(JSON.stringify({
    "sets": {
      "1-5 agreement scale": {
        "en": {
          "0": "No Answer",
          "1": "Strongly Disagree",
          "2": "Disagree",
          "3": "Somewhat Agree / Disagree",
          "4": "Agree",
          "5": "Strongly Agree"
        },
        "es": {
          "0": "Sin respuesta",
          "1": "Muy en desacuerdo",
          "2": "En desacuerdo",
          "3": "Algo de acuerdo / en desacuerdo",
          "4": "De acuerdo",
          "5": "Totalmente de acuerdo"
        }
      }
    },
    "selected": "1-5 agreement scale",
    "tabarray": ["1-5 agreement scale"]
  }))
}

const getOldAnswerset = () => {
  return JSON.parse(JSON.stringify({
    "sets": {
      "1-5 agreement scale": {
        "en": {
          "0": "No Answer/Does Not Apply",
          "1": "Strongly Disagree",
          "2": "Disagree",
          "3": "Somewhat Agree / Somewhat Disagree",
          "4": "Agree",
          "5": "Strongly Agree"
        },
        "es": {
          "0": "Sin respuesta / No se aplica",
          "1": "Muy en desacuerdo",
          "2": "En desacuerdo",
          "3": "Algo de acuerdo / Algo en desacuerdo",
          "4": "De acuerdo",
          "5": "Totalmente de acuerdo"
        }
      }
    },
    "selected": "1-5 agreement scale",
    "tabarray": ["1-5 agreement scale"]
  }))
}

const getSettings = () => {
  return JSON.parse(JSON.stringify({
    _id: "",
    type: "",
    description: {
      en: ""
    },
    thanks_header: {
      en: "Thank You"
    },
    email_name_access : "",
    welcome_name_access :"",
    sms_name_access : "",
    thanks_description: {
      en: "Your Feedback has been Received"
    },
    welcome_header: {
      en: "Welcome "
    },
    welcome_description: {
      en: "Your feedback is requested for the people below, if you have a self evaluation listed, please complete it first"
    },
    languages: [{
      code: "en",
      name: "English",
      active: true
    }],
    expiry: {},
    frequency: "",
    status: "",
    survey_threshold: 3,
    pulse_obj: {
      period: {},
    },
    distribute: {
      kiosk: {
        active: false
      },
      audience: {
        select: "trigger",
        anonymous: {}
      },
      phone: {
        active: false
      },
      triggers: null,
      prevTrigger : null,
      editresp: false,
      url: "",
      notification: {
        email: {
          active: false,
          schedule: {},
          message: {
            greetings: {},
            url: {},
            subject: {},
            body: {}
          }
        },
        me: {
          active: false,
          actionAlert: [],
          schedule: [
            {
              triggerDay: "",
              time: "",
              topic: "",
              systemID: [],
              attachment: ""
            }
          ]
        },
        sms: {
          active: false,
          schedule: {},
          message: {
            greetings: {},
            url: {},
            body: {}
          }
        },
        transflo: {
          active: false,
          schedule: {},
          message: {
            greetings: {},
            subject: {},
            body: {}
          }
        }
      }
    }
  }))
}

const initialState = {
  search: { ...tableDatas("startDate") },
  getOne: {
    _id: "",
    name: "",
    template: "",
    type: "",
    status: "",
    frequency: ""
  },
  activeCategoryTab: 0,
  questions: {
    _id: "",
    type: "",
    name: "",
    isOldSurvey: false,
    catOrder: [],
    questions: [],
    catDetails: {},
    languages: [{
      code: "en",
      name: "English",
      active: true
    }],
    survey_name: {}
  },
  me_subjectID: [],
  settings: getSettings(),
  initial_notification : {},
  answerSet: {
    ...getAnswerset()
  },
  answerSetCopy: {
    ...getAnswerset()
  },
  responseDetails: {
    languages: [],
    question_ids: []
  },
  anonKioskShortName: {
    anonymous: [],
    kiosk: []
  },
  isLoading: false,
  previewSurveyStatus: false,
  previewSurveyId: null,
  translated_history: {},
  native_language: false,
  errors: {}
};

export default function (state = initialState, action) {
  switch (action.type) {

    //Get ME systemID for action coach
    case surveyConstant.SURVEY_SETTINGS_MESYSTEMID_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_MESYSTEMID_SUCCESS:
      return {
        ...state,
        me_subjectID: action.data.data

      };
    case surveyConstant.SURVEY_SETTINGS_MESYSTEMID_FAILURE:
      return {
        ...state
      };

    // Survey Getone Reducer
    case surveyConstant.SURVEY_GETONE_REQUEST:
      return {
        ...state,
        error: {},
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: { ...getSettings() },
        initial_notification : {},
      };
    case surveyConstant.SURVEY_GETONE_SUCCESS:
      return {
        ...state,
        getOne: action.data && action.data.data ? action.data.data : initialState.getOne,
        questions: {
          ...state.questions, _id: action.data && action.data.data ? action.data.data._id : ""
        },
        settings: {
          ...state.settings, _id: action.data && action.data.data ? action.data.data._id : ""
        },
      };
    case surveyConstant.SURVEY_GETONE_REDUCER_UPDATE:
      return {
        ...state,
        getOne: action.data,
        questions: {
          ...initialState.questions,
          ...action.data
        },
        settings: { ...getSettings() }
      };
    case surveyConstant.SURVEY_TYPE_REDUCER_UPDATE:
      return {
        ...state,
        getOne: {
          ...state.getOne,
          type: action.data
        },
        questions: {
          ...state.questions,
          type: action.data
        },
        settings: {
          ...state.settings,
          type: action.data
        }
      };
    case surveyConstant.SURVEY_GETONE_CLEAR: {
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: { ...getSettings() }
      };
    }
    case surveyConstant.SURVEY_GETONE_FAILURE:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: { ...getSettings() }
      };

    // Survey Search Reducer
    case surveyConstant.SURVEY_SEARCH_REQUEST:
      return {
        ...state,
        // reset old datas when comes to list page
        settings: { ...getSettings() }
      };
    case surveyConstant.SURVEY_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: action.data.page
        }
      };
    case surveyConstant.SURVEY_SEARCH_FAILURE:
      return {
        ...state,
        search: initialState.search
      };

    // Survey Copy reducer
    case surveyConstant.SURVEY_COPY_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_COPY_SUCCESS:
      return {
        ...state,
      };
    case surveyConstant.SURVEY_COPY_FAILURE:
      return {
        ...state,
      };

    // Survey create reducer
    case surveyConstant.SURVEY_CREATE_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_CREATE_SUCCESS:
      return {
        ...state,
        getOne: { ...state.getOne, _id: action.data.data },
        questions: { ...state.questions, _id: action.data.data },
        settings: { ...state.settings, _id: action.data.data }
      };
    case surveyConstant.SURVEY_CREATE_FAILURE:
      return {
        ...state,
        surveyPanel: initialState.getOne
      };

    // Survey Update reducer
    case surveyConstant.SURVEY_UPDATE_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_UPDATE_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_UPDATE_FAILURE:
      return {
        ...state
      };

    // Survey Delete Reducer Update
    case surveyConstant.SURVEY_DELETE_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_DELETE_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_DELETE_FAILURE:
      return {
        ...state
      };

    // Survey Get Settings Tab Reducer
    case surveyConstant.SURVEY_SETTINGS_GET_REQUEST:
      return {
        ...state,
        settings: { ...getSettings(), isLoading: true }
      };
    case surveyConstant.SURVEY_SETTINGS_GET_SUCCESS:
      if (action.data.data.distribute.audience === undefined) {
        action.data.data.distribute.audience = {}
      }
      if (action.data.data.distribute.audience.anonymous === undefined) {
        action.data.data.distribute.audience.anonymous = {}
      }
      if (action.data.data.distribute.audience.anonymous.shortName === undefined || action.data.data.distribute.audience.anonymous.shortName === "" || action.data.data.distribute.audience.anonymous.shortName === null) {
        action.data.data.distribute.audience.anonymous.shortName = action.data.data._id
      }
      return {
        ...state,
        settings: { ...{ ...getSettings() }, ...action.data.data, isLoading: false },
        initial_notification : JSON.parse(JSON.stringify(action.data.data.distribute)),
        native_language: action.data.data.native_language || false,
        translated_history: action.data.data.translated_history ? action.data.data.translated_history : {}
      };
    case surveyConstant.SURVEY_SETTINGS_GET_FAILURE:
      return {
        ...state,
        settings: { ...getSettings(), isLoading: false }
      };

    // Survey Save Settings Tab Reducer
    case surveyConstant.SURVEY_SETTINGS_SAVE_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_SAVE_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE:
      return {
        ...state,
        settings: action.data
      };
    case surveyConstant.SURVEY_SETTINGS_SAVE_FAILURE:
      return {
        ...state,
        saveSettings: initialState.saveSettings
      };

    case surveyConstant.SURVEY_NATIVE_LANGUAGE_REDUCER_UPDATE:
      return {
        ...state,
        native_language: action.data ? action.data : false
      }

    // SURVEY QUESTIONS CATEGORY ACTIVE TAB 
    case surveyConstant.SURVEY_QUESTIONS_CATEGORY_ACTIVE_TAB:
      return {
        ...state,
        activeCategoryTab: action.data
      };

    //GET SURVEY QUESTIONS
    case surveyConstant.SURVEY_GET_QUESTIONS_REQUEST:
      return {
        ...state,
        questions: initialState.questions
      };
    case surveyConstant.SURVEY_GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        answerSet: action.data.data.isOldSurvey ? getOldAnswerset() : state.answerSet,
        questions: { ...initialState.questions, ...action.data.data },
        translated_history: action.data.data.translated_history ? action.data.data.translated_history : {},
        survey_name: action.data.data.survey_name ? action.data.data.survey_name : {},
        native_language: action.data.data.native_language ? action.data.data.native_language : false,
      };
    case surveyConstant.SURVEY_GET_QUESTIONS_FAILURE:
      return {
        ...state,
        questions: initialState.questions
      };

    // Add New Category and Questions
    case surveyConstant.SURVEY_ADD_NEW_CATAGORY_QUESTIONS:
      const category = [...state.questions.catOrder, action.data.category];
      const catDetail = state.questions.catDetails ? state.questions.catDetails : {};
      catDetail[action.data.category] = action.data.data.data[0].category;
      let finaldataArr = action.data.data.data.map((item, index) => {
        item.category = item.category["en"]["name"];
        return item;
      })
      return {
        ...state,
        questions: {
          ...state.questions,
          questions: [...state.questions.questions, ...finaldataArr],
          catOrder: category,
          catDetails: catDetail
        },
        activeCategoryTab: category.length - 1 <= 0 ? 0 : category.length - 1
      };

    // Add New Question to Category
    case surveyConstant.SURVEY_ADD_NEW_QUESTION_TO_CATEGORY:
      let categoryName = state.questions.catOrder[state.activeCategoryTab];
      let selectedCat = state.questions.questions.filter(x => x.category === categoryName);
      let finalData = action.data.data.data;
      for (let i = 0; i < finalData.length; i++) {
        if (selectedCat > 0) {
          finalData[i]["category"] = selectedCat[0].category;
        } else {
          finalData[i]["category"] = categoryName;
        }
      }
      return {
        ...state,
        questions: {
          ...state.questions,
          questions: [...state.questions.questions, ...finalData]
        }
      };

    // UPDATE SURVEY SELECTED LANGUGE
    case surveyConstant.SURVEY_SET_ACTIVE_LANGUAGE:
      return {
        ...state,
        questions: {
          ...state.questions,
          languages: action.data
        }
      }

    // UPDATE CATEGORY REORDER
    case surveyConstant.SURVEY_UPDATE_CATEGORY_REORDER:
      return {
        ...state,
        questions: {
          ...state.questions,
          catOrder: action.data
        }
      }

    //Update Questions Reducer Data
    case surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE:
      return {
        ...state,
        questions: action.data
      };

    //Update Questions inside Questions Reducer Data
    case surveyConstant.SURVEY_QUESTIONS_QUESTIONS_REDUCER_UPDATE:
      return {
        ...state,
        questions: {
          ...state.questions,
          questions: action.data
        }
      };

    //Update Questions category delete Reducer Data
    case surveyConstant.SURVEY_CATAGORY_DELETE_REDUCER_UPDATE:
      return {
        ...state,
        questions: action.data.questions,
        activeCategoryTab: action.data.activeCategoryTab
      };

    //Update Questions Reducer Data
    case surveyConstant.SURVEY_QUESTIONS_MOVE_CATEGORY_REDUCER_UPDATE:
      return {
        ...state,
        questions: {
          ...state.questions,
          questions: action.data
        }
      };

    // SAVE SURVEY QUESTIONS
    case surveyConstant.SURVEY_SAVE_QUESTIONS_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SAVE_QUESTIONS_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SAVE_QUESTIONS_FAILURE:
      return {
        ...state,
        questions: initialState.questions
      };

    //SURVEY EDIT ANSWERSET SAVE
    case surveyConstant.SURVEY_EDITANSWERSET_SAVE_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_EDITANSWERSET_SAVE_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_EDITANSWERSET_SAVE_FAILURE:
      return {
        ...state
      };

    //SURVEY EDIT ANSWERSET SAVE
    case surveyConstant.SURVEY_EDITANSWERSET_APPLY_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_EDITANSWERSET_APPLY_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_EDITANSWERSET_APPLY_FAILURE:
      return {
        ...state
      };

    //SURVEY ANSWER SET GET
    case surveyConstant.SURVEY_EDITANSWERSET_GET_REQUEST:
      return {
        ...state,
        answerSet: { ...getAnswerset() },
        answerSetCopy: { ...getAnswerset() }
      };
    case surveyConstant.SURVEY_EDITANSWERSET_GET_SUCCESS:
      if (action.data.data && action.data.data.answerSet && action.data.data.answerSet.sets) {
        return {
          ...state,
          answerSet: action.data.data.answerSet,
          answerSetCopy: JSON.parse(JSON.stringify(action.data.data.answerSet))
        };
      }
      else {
        return {
          ...state,
          answerSet: state.questions.isOldSurvey ? { ...getOldAnswerset() } : { ...getAnswerset() },
          answerSetCopy: state.questions.isOldSurvey ? { ...getOldAnswerset() } : { ...getAnswerset() }
        }
      }
    case surveyConstant.SURVEY_EDITANSWERSET_GET_FAILURE:
      return {
        ...state,
        answerSet: { ...getAnswerset() },
        answerSetCopy: { ...getAnswerset() }
      };

    case surveyConstant.SURVEY_EDITANSWERSET_REDUCER_UPDATE:
      return {
        ...state,
        answerSet: action.data
      };

    //SURVEY GET RESPONSE
    case surveyConstant.SURVEY_RESPONSE_GET_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_RESPONSE_GET_SUCCESS:
      if (action.data.data && action.data.data.question_ids) {
        return {
          ...state,
          responseDetails: action.data.data
        };
      }
      else {
        return {
          ...state,
          responseDetails: initialState.responseDetails
        }
      }
    case surveyConstant.SURVEY_RESPONSE_GET_FAILURE:
      return {
        ...state
      };

    //SURVEY GET ANONYMOUS KIOSK SHORTNAME
    case surveyConstant.ANONYMOUS_KIOSK_SHORTNAME_GET_REQUEST:
      return {
        ...state
      };
    case surveyConstant.ANONYMOUS_KIOSK_SHORTNAME_GET_SUCCESS:
      return {
        ...state,
        anonKioskShortName: action.data.data
      }
    case surveyConstant.ANONYMOUS_KIOSK_SHORTNAME_GET_FAILURE:
      return {
        ...state
      };

    //SEND MAIL FROM DISTRIBUTION PANEL FOR PARTICIPANTS
    case surveyConstant.SURVEY_SETTINGS_SENDMAIL_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_SENDMAIL_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_SENDMAIL_FAILURE:
      return {
        ...state
      };

    //SEND SMS FROM DISTRIBUTION PANEL FOR PARTICIPANTS
    case surveyConstant.SURVEY_SETTINGS_SENDSMS_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_SENDSMS_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_SENDSMS_FAILURE:
      return {
        ...state
      };

    //SEND TRANSFLO NOTIFICATION FROM DISTRIBUTION PANEL FOR PARTICIPANTS
    case surveyConstant.SURVEY_SETTINGS_SENDTRANSFLO_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_SENDTRANSFLO_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_SENDTRANSFLO_FAILURE:
      return {
        ...state
      };

    //TRIGGER ASSIGN 
    case surveyConstant.TRIGGER_ASSIGN_SURVEY_GET_REQUEST:
      return {
        ...state
      };
    case surveyConstant.TRIGGER_ASSIGN_SURVEY_GET_SUCCESS:
      return {
        ...state
      };
    case surveyConstant.TRIGGER_ASSIGN_SURVEY_GET_FAILURE:
      return {
        ...state
      };

    // Survey preview
    case surveyConstant.SURVEY_PREVIEW_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_PREVIEW_SUCCESS:
      return {
        ...state,
        previewSurveyId: action.data._id ? action.data._id : null,
        previewSurveyStatus: action.data.preview,
      };
    case surveyConstant.SURVEY_PREVIEW_FAILURE:
      return {
        ...state,
        previewSurveyId: initialState.previewSurveyId,
        previewSurveyStatus: initialState.previewSurveyStatus
      };


    // Survey preview
    case surveyConstant.SURVEY_SETTINGS_TRANSLATE_REQUEST:
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: true
        }
      };
    case surveyConstant.SURVEY_SETTINGS_TRANSLATE_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          distribute: action.data && action.data.data.distribute ? action.data.data.distribute : "",
          description: action.data && action.data.data.description ? action.data.data.description : "",
          thanks_header: action.data && action.data.data.thanks_header ? action.data.data.thanks_header : "",
          thanks_description: action.data && action.data.data.thanks_description ? action.data.data.thanks_description : "",
          welcome_header: action.data && action.data.data.welcome_header ? action.data.data.welcome_header : "",
          welcome_description: action.data && action.data.data.welcome_description ? action.data.data.welcome_description : "",
          isLoading: false
        },
        translated_history: action.data && action.data.data.translated_history ? action.data.data.translated_history : ""
      };
    case surveyConstant.SURVEY_SETTINGS_TRANSLATE_FAILURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          isLoading: false
        }
      };

    // SURVEY Questions Translate
    case surveyConstant.SURVEY_QUESTIONS_TRANSLATE_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_QUESTIONS_TRANSLATE_SUCCESS:
      return {
        ...state,
        questions: {
          ...state.questions,
          ...action.data.questions
        },
        answerSetCopy: action.data.answerSet || {},
        translated_history: action.data.translated_history,
      };
    case surveyConstant.SURVEY_QUESTIONS_TRANSLATE_FAILURE:
      return {
        ...state
      };

    // SURVEY Custom Answer set Translate
    case surveyConstant.SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_REQUEST:
      return {
        ...state
      };
    case surveyConstant.SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_SUCCESS:
      return {
        ...state,
        answerSet: action.data.answerSet || {},
        translated_history: action.data.translated_history,
      };
    case surveyConstant.SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_FAILURE:
      return {
        ...state
      };
    case surveyConstant.SURVEY_SETTINGS_TRANSLATE_FAILURE:
      return {
        ...state,
      };
    case surveyConstant.EDIT_TRANSLATE_HISTORY_UPDATE_SUCCESS:
      return {
        ...state,
        translated_history: action.data
      };

    case surveyConstant.SURVEY_NAME_REDUCER_UPDATE:
      return {
        ...state,
        survey_name: action.data ? action.data : {}
      }

    case surveyConstant.SURVEY_ERRORS_UPDATE:
      return {
        ...state,
        errors: { ...action.data }
      };

    case surveyConstant.SURVEY_ACTION_COACH_LIST_UPDATE:
      return {
        ...state,
        settings: {
          ...state.settings,
          distribute: {
            ...state.settings.distribute,
            notification: {
              ...state.settings.distribute.notification,
              me: {
                ...state.settings.distribute.notification.me,
                schedule: action.data
              }
            }
          }
        }

      }

    case surveyConstant.SURVEY_SETTINGS_ACTIONALERT_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          distribute: {
            ...state.settings.distribute,
            notification: {
              ...state.settings.distribute.notification,
              me: {
                ...state.settings.distribute.notification.me,
                actionAlert: action.data.data
              }
            }
          }
        }

      }

    case surveyConstant.UPDATE_SURVEY_NAME:
      return {
        ...state,
        getOne: {
          ...state.getOne,
          name: action.data
        },
        questions: {
          ...state.questions,
          name: action.data,
        },
        settings: {
          ...state.settings,
          name: action.data
        },
        survey_name: {}
      }

    case surveyConstant.UPDATE_SURVEY_AUTOMATIC_TRIGGERS:
      return {
        ...state,
        settings: {
          ...state.settings,
          distribute: {
            ...state.settings.distribute,
            prevTrigger: action.data,
            triggers: action.data,
          },
        },
      };

    case surveyConstant.SURVEY_SETTINGS_ACTIONALERT_SUCCESS:
      return {
        ...state,
      };

    // Survey Default Reducer Update
    default:
      return state;
  }
}
