import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Import Component
import TabPanelComponent from "../../../../components/TabPanelComponent/TabPanelComponent";

// Import Category and Language Containers
import CategoryList from "./CategoryList";
import LanguageList from "./LanguageList";

// Import Actions
import { categoryAction, clientAction, surveyAction } from "../../../../redux/actions";

// Set Id and Controls
function a11yProps(index) {
  return {
    id: `questions-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  };
}

function LeftPanel(props) {
  // Define Dispatch and State
  const dispatch = useDispatch();
  // Get Props Values
  const { classes, match, settings } = props;

  // Set State Value
  const [value, setValue] = React.useState(0);
  const { params } = match;

  /**
    * Get categories
    */
  useEffect(() => {
    dispatch(surveyAction.getOne((params.id ? params.id : null), () => {
      dispatch(categoryAction.search(""));
    }));
    dispatch(clientAction.getOne());

  }, [dispatch]);

  // Set State Value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  return (
    <React.Fragment>
      <div className={"p15 pb-0 pt-0"}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            // indicatorColor="primary"
            aria-label="simple tabs example">
            <Tab
              className={classes.quest_tabs}
              label="Library"
              {...a11yProps(0)} />
            <Tab
              className={classes.quest_tabs}
              label="Languages"
              {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </div>
      <TabPanelComponent value={value} index={0}>
        <CategoryList settings={settings} classes={classes} params={params}></CategoryList>
      </TabPanelComponent>

      <TabPanelComponent value={value} index={1} className="lefttab_height">
        <LanguageList classes={classes}></LanguageList>
      </TabPanelComponent>
    </React.Fragment>
  );
}

// default props
LeftPanel.defaultProps = {
  classes: {}
};

// prop types
LeftPanel.propTypes = {
  classes: PropTypes.object
};

export default LeftPanel;
