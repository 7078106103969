/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

// Import Actions
import { updateStateReducerAction, categoryAction } from "../../../../redux/actions";

function RequiredQuestions (props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { questionData, activeCategoryTab, addQuestions } = props;
  const { questions, catOrder } = questionData;

  let requireCategory = false;
  let requireCat = [];
  questions.filter((question,ind) => question.category === catOrder[activeCategoryTab])
    .map((item,ind) => {
      requireCat.push(item.isRequired);
    });
  // check require flag in new questions
  if (addQuestions && addQuestions[catOrder[activeCategoryTab]]) {
    const addQuesFlag = addQuestions[catOrder[activeCategoryTab]]["isRequired"] ? addQuestions[catOrder[activeCategoryTab]]["isRequired"] : false;
    requireCat.push(addQuesFlag);
  }
  // update Require all question check box
  if (requireCat.length && requireCat.indexOf(false) === -1) {
    requireCategory = true;
  }

  // Update reducer data
  const updateReducer = (updateData) => {
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_QUESTIONS_REDUCER_UPDATE, updateData));
  };

  // Check Required Questions In category on Page Loading
  const chechRequiredCategory = (categoryName) => {
    let notMatchQuestions = questions.filter((question) => question.category !== categoryName);
    let requiredUpdateQues = questions.filter((question) => question.category === categoryName);
    requiredUpdateQues.map((item) => {
      item.isRequired = !requireCategory;
    });
    updateReducer([...notMatchQuestions, ...requiredUpdateQues]);

    // update new Questions data
    if (addQuestions && addQuestions[catOrder[activeCategoryTab]]) {
      addQuestions[catOrder[activeCategoryTab]]["isRequired"] = !requireCategory;
      dispatch(categoryAction.addOrChangeQuestion(catOrder[activeCategoryTab], addQuestions[catOrder[activeCategoryTab]]));
    }
  };

  return (
    <FormControlLabel
      name={`form-required-${activeCategoryTab}`}
      className="pl-15 fontSize13 p-0"
      control={
        <Checkbox
          checked={requireCategory}
          onChange={() => chechRequiredCategory(catOrder[activeCategoryTab])}
          name={`required-${activeCategoryTab}`}
          color="primary"
        />
      }
      label={"Require all questions"}
    />
  );
}

// default props
RequiredQuestions.defaultProps = {
  classes: {},
  addQuestions: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE
};

// prop types
RequiredQuestions.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  addQuestions: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  langCode: PropTypes.string,
  updateReqQuestions: PropTypes.func
};

export default RequiredQuestions;
