/* eslint-disable no-unneeded-ternary */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";

// Import Styles and Image
import PariticipantStyles from "../PariticipantStyles";
import BackImg from "../../../../assets/images/svg/chev_back.svg";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import Container from "../../../Containers/Container";
import DatePickerComponent from "../../../../components/DatePickerComponent/CustomDatePicker";
import MultiSelectComponent from "../../../../components/MultiSelectComponent/MultiSelectComponent";

// Import Actions
import { participantAction, excelAction, alertAction } from "../../../../redux/actions";

// import constants
import { participantConstants, excelConstants, errorsConstants } from "../../../../config/constants";

// Import History
import history from "../../../../config/history";

function SelectSurvey(props) {
  const dispatch = useDispatch();

  // get props
  const { classes, match } = props;

  // Get Survey Data
  useEffect(() => {
    dispatch(participantAction.getSurveys());
  }, [dispatch]);


  // Get Survey Details
  const { allSurveys } = useSelector((state) => state.participant);


  // set state for survey name
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [selectedSurveyNames, setSelectedSurveyNames] = useState([]);
  const [reportDate, setReportDate] = useState(
    {
      startDate: moment(new Date()).subtract(3, 'months').format('MM/DD/YYYY'),
      endDate: moment(new Date()).format('MM/DD/YYYY'),
    }
  );

  // validation for end date
  useEffect(() => {
    if (reportDate && reportDate.startDate && reportDate.endDate) {
      ValidatorForm.addValidationRule("isEnddategreater", (value) => {
        if (new Date(reportDate.endDate) < new Date(reportDate.startDate)) {
          return false;
        } else {
          return true;
        }
      });
    }
  }, [reportDate.startDate, reportDate.endDate])

  // export data
  const surveyAssignedDateExport = (selectedSurvey, reportDate) => {
    if (selectedSurvey.length > 0) {
      const { startDate, endDate } = reportDate;
      if (moment(new Date(endDate)).isSameOrAfter(new Date(startDate), 'day')) {
        let minDate = moment(new Date()).subtract(24, 'months').format('MM/DD/YYYY');
        let maxDate = moment(new Date()).format('MM/DD/YYYY');
        if (moment(new Date(startDate)).isSameOrAfter(new Date(minDate), 'day') && moment(new Date(endDate)).isSameOrBefore(new Date(maxDate), 'day')) {
          dispatch(excelAction.download({ "survey": selectedSurvey, "date": reportDate, "excelType": excelConstants.SURVEY_ASSIGNED_DATE_REPORT }, true));
        }
        else if (moment(new Date(endDate)).isAfter(new Date(maxDate), 'day')) {
          dispatch(alertAction.error(participantConstants.SURVEY_ASSIGNED_DATE_FUTURE_DATE_VALIDATION));
        }
        else {
          dispatch(alertAction.error(participantConstants.SURVEY_ASSIGNED_DATE_DATE_VALIDATION));
        }
      } else {
        dispatch(alertAction.error(participantConstants.SURVEY_ASSIGNED_DATE_INVALID_DATE_VALIDATION));
      }
    } else {
      dispatch(alertAction.error(participantConstants.SELECT_SURVEY));
    }
  };

  const handleChange = (e) => {
    let { name, value } = e;
    if (value !== null) {
      setReportDate({ ...reportDate, [name]: moment(new Date(value)).format('MM/DD/YYYY') });
    }
    else {
      setReportDate({ ...reportDate, [name]: "" });
    }
  }


  //autocomplete component change
  const handleAutoCompleteChange = (event) => {
    const selectedVal = event.value;
    const action = event.action
    let selectedSurveyIds = [];
    let selectedSurveyNames = [];
    const isAllSelected = selectedVal.find((ele) => ele === "Select All");
    if (isAllSelected) {
      if (action === "selectOption") {
        JSON.parse(JSON.stringify(allSurveys)).forEach((val) => {
          selectedSurveyIds.push(val._id);
          selectedSurveyNames.push(val.name);

        })
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames(["Select All", ...selectedSurveyNames]);
      }
      else {
        selectedVal.forEach((val) => {
          if (val !== "Select All") {
            let surveyData = JSON.parse(JSON.stringify(allSurveys)).find((ele) => ele.name === val);
            selectedSurveyIds.push(surveyData._id);
            selectedSurveyNames.push(surveyData.name);
          }
        })
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames([...selectedSurveyNames]);
      }
    }
    else {
      if (allSurveys.length === selectedVal.length && action === "removeOption") {
        setSelectedSurvey([]);
        setSelectedSurveyNames([]);
      }
      else if (allSurveys.length === selectedVal.length && action === "selectOption") {
        JSON.parse(JSON.stringify(allSurveys)).forEach((val) => {
          selectedSurveyIds.push(val._id);
          selectedSurveyNames.push(val.name);
        })
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames(["Select All", ...selectedSurveyNames]);
      }
      else {
        selectedVal.forEach((val) => {
          let surveyData = JSON.parse(JSON.stringify(allSurveys)).find((ele) => ele.name === val);
          selectedSurveyIds.push(surveyData._id);
          selectedSurveyNames.push(surveyData.name);
        })

        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames([...selectedSurveyNames]);
      }
    }
  }

  const getSurveyNames = () => {
    // let surveyNames = ["Select All"]; // if need select all option
    let surveyNames = [];
    JSON.parse(JSON.stringify(allSurveys)).forEach((ele) => {
      surveyNames.push(ele.name);
    })
    return surveyNames;
  }

  //multiselect dropdown value
  const autocompleteValue = () => {
    let surveyNames = [];
    if (selectedSurvey.length > 0) {
      let resArr = []
      for (let i = 0; i < selectedSurvey.length; i++) {
        let sId = selectedSurvey[i]._id;
        if (sId == "all") {
          resArr.push("Select All")
        } else {
          let sData = allSurveys.find((ele) => ele._id == sId);
          resArr.push(sData.name);
        }
      }
      surveyNames = resArr;
    }
    return surveyNames;
  }

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p-2"
      >
        <Grid item>
          <Grid container alignItems="center">
            <IconButton
              color="inherit"
              className="p5"
              onClick={() => history.push("/manage_people/participants")}
              size="large">
              <img className={classes.backimg} src={BackImg} alt="back"></img>
            </IconButton>
            <TypographyComponent variant={"h3"} className="ml-1">
              {participantConstants.SURVEY_ASSIGNED_DATE}
            </TypographyComponent>
          </Grid>
        </Grid>
      </Grid>

      <Container page={"sub"}>
        <Grid container>
          <Grid className="container" justifyContent="center">
            <Grid item className="mb-2">
              <TypographyComponent
                variant="h2"
                align="center"
                className="txtBold"
              >
                {participantConstants.SURVEY_ASSIGNED_DATE_TITLE}
              </TypographyComponent>
            </Grid>
            <Grid item className="mb-4">
              <TypographyComponent variant="h6" align="center" className="">
                {participantConstants.SURVEY_ASSIGNED_DATE_TEXT}
              </TypographyComponent>
            </Grid>
            <Grid item className={`${"mb-5"} ${classes.SelectSurvey}`}>
              <ValidatorForm
                id="add_participant"
                name="Add Participant"
                autoComplete="off"
                className="width100 height100"
                // onSubmit={handleSubmit}
                onError={(error) => {
                  console.log(error);
                }}
              >
                <FormControl className="width100">
                  <Grid item md={12} className={"mb-3"}>
                    <Grid container spacing={8}>
                      <Grid item sm={12}>
                        <TypographyComponent variant="h6">
                          {participantConstants.SURVEY_ASSIGNED_VIEW_PARTICIPANTS}
                        </TypographyComponent>
                        {allSurveys.length > 0 ?
                          <MultiSelectComponent
                            name={"surveys"}
                            className={`${"height40"} ${classes.textField}`}
                            suggestions={getSurveyNames()}
                            selectedOptions={selectedSurvey}
                            handleAutoCompolete={(name, value, event, action) => {
                              handleAutoCompleteChange({ name, value, event, action });
                            }}
                            value={selectedSurveyNames}
                            placeholder={participantConstants.SURVEY_ASSIGNED_DEFAULT_PLACEHOLDER}
                            isArrayObjects={true}
                          /> :
                          <Select
                            className={"height40 width100"}
                            name={"SelectRemoveSurvey"}
                            id="demographics"
                            value={selectedSurvey}
                            displayEmpty
                            variant="outlined"
                          >
                            <MenuItem value="">Select a survey</MenuItem>
                          </Select>
                        }
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item md={12}>
                    <Grid container spacing={8}>
                      <Grid item sm={6}>
                        <TypographyComponent variant="h6">
                          {participantConstants.SURVEY_ASSIGNED_DATE_LABEL_START_DATE}
                        </TypographyComponent>
                        <DatePickerComponent
                          name="startDate"
                          value={reportDate.startDate}
                          className={`${"height40"} ${classes.textField}`}
                          handleChange={(e) => handleChange(e)}
                          inputVariant="outlined"
                          margin="dense"
                          size="small"
                          fullWidth={true}
                          validators={["required", "isEnddategreater"]}
                          errorMessages={[errorsConstants.required, errorsConstants.invalidDateRange]}
                          minDate={new Date(moment(new Date()).subtract(24, 'months').format("YYYY-MM-DD"))}
                          maxDate={new Date(moment(new Date()).format("YYYY-MM-DD"))}
                        />
                      </Grid>
                      <Grid item sm={6}>
                        <TypographyComponent variant="h6">
                          {participantConstants.SURVEY_ASSIGNED_DATE_LABEL_END_DATE}
                        </TypographyComponent>
                        <DatePickerComponent
                          name="endDate"
                          value={reportDate.endDate}
                          className={`${"height36"} ${classes.textField}`}
                          handleChange={(e) => handleChange(e)}
                          inputVariant="outlined"
                          margin="dense"
                          size="small"
                          fullWidth={true}
                          validators={["required", "isEnddategreater"]}
                          errorMessages={[errorsConstants.required, errorsConstants.invalidDateRange]}
                          minDate={new Date(moment(new Date()).subtract(24, 'months').format("YYYY-MM-DD"))}
                          maxDate={new Date()}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </FormControl>
              </ValidatorForm>
            </Grid>

            <Grid item>
              <Grid container justifyContent="center">
                <ButtonComponent
                  disabled={!selectedSurvey ? true : false}
                  // disabled={true}
                  title={participantConstants.EXPORT_BUTTON}
                  color={"primary"}
                  size={"small"}
                  pageClassName="mr-1"
                  handleClick={() => {
                    surveyAssignedDateExport(selectedSurvey, reportDate);
                  }}
                ></ButtonComponent>
                <ButtonComponent
                  title={participantConstants.CANCEL_BUTTON}
                  // color={"secondary"}
                  className={classes.cancelBtn}
                  size={"small"}
                  pageClassName="mr-1"
                  handleClick={() => history.push("/manage_people/participants")}
                ></ButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
SelectSurvey.defaultProps = {
  classes: {},
  match: {}
};

// prop types
SelectSurvey.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object
};
export default withStyles(PariticipantStyles)(SelectSurvey);
