import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { IconButton, Tooltip } from "@mui/material";

// Import Icons CodeConversionBlue
import { CodeConversionBlue } from "../../../../../assets/images/svgComponents/ImageComponent";


// import components
import DialogComponent from "../../../../../components/DialogComponent/DialogComponent";
import Conversion from "./Conversion";

// Import Actions
import { metadataAction } from "../../../../../redux/actions";

const ConversionPopup = (props) => {
  const dispatch = useDispatch();
  const [isRendered, setRender] = useState(false)
  /**
  * metadataSelected=>selcted meta data item for conversion
  * metadataWithRGI=>popup values
  * metadata=client entire metadata
  * USING DEEP CLONONG TO ELIMINATE PARTENT DATA UPDATE
  */
  const { classes, metadataSelected, metadataWithRGI, metadata } = props;

  // set state for popup
  const [conversionPopup, setConversionPopup] = useState(false);

  // call during conversion popup close
  const onClose = () => {
    setRender(false)
    dispatch(metadataAction.selectedMetadata(JSON.parse(JSON.stringify(metadataSelected))));
    setConversionPopup(false);
  };

  // save current data in reducer and open popup
  const openPopup = () => {
    dispatch(metadataAction.selectedMetadata(JSON.parse(JSON.stringify(metadataSelected))));
    setConversionPopup(true);
  };

  const onDialogOpens = ()=>{
    setRender(true)
  }

  return (
    <React.Fragment>
      <Tooltip arrow title={"Add/edit conversions"}>
      <IconButton
        style={{ margin: "0px auto" }}
        disabled={metadata.filter(d => 
          (d || "").value.trim().toLowerCase() === (metadataSelected.value || "").trim().toLowerCase()).length > 1 || !metadataSelected.visible}
        className={`${"p-0 txtcenter"} ${metadataSelected["conversion"] && Array.isArray(metadataSelected["conversion"]) && metadataSelected["conversion"].length > 0 ? "settings_icon_active" : ""}`}
        onClick={() => { openPopup(); }}
        size="large">
        {CodeConversionBlue()}
      </IconButton>
      </Tooltip>
      {conversionPopup && (
        <DialogComponent open={true} onEntered={onDialogOpens} fullScreen={true}>
          <Conversion
            classes={classes}
            onClose={onClose}
            isRendered={isRendered}
            selConversion={metadataSelected.conversion || []}
            metadataSelected={metadataSelected}
            metadataWithRGI={metadataWithRGI}
            metadata={metadata}
          ></Conversion>
        </DialogComponent>
      )}
    </React.Fragment>
  );
};

// default props
ConversionPopup.defaultProps = {
  classes: {},
  metadataSelected: {},
  metadataWithRGI: [],
  metadata: []
};

// prop types
ConversionPopup.propTypes = {
  classes: PropTypes.object,
  metadataSelected: PropTypes.object,
  metadataWithRGI: PropTypes.array,
  metadata: PropTypes.array
};

export default ConversionPopup;
