/* eslint-disable react/jsx-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import InputAdornment from '@mui/material/InputAdornment';

// Import Icon
import Tiledelete from "../../../../../assets/images/svg/trash.svg";
import Addquestion from "../../../../../assets/images/svg/plus-blue.svg";

// Import Components
import TabPanelComponent from "../../../../../components/TabPanelComponent/TabPanelComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";

// Import Helpers
import { getReducerUpdatedData, isTranslated, iseditedLangtxt } from "../../../../../helpers";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../../redux/actions";

// Import Constants
import { surveyConstant } from "../../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../../config/constants";

function AnswerSetTabPanel(props) {
  const { classes, activeAnswerSetTab, answerData, langcode, tabarray, answerSet, responseDetails, translatedFields } = props;
  const dispatch = useDispatch();
  const [textFieldEnable, setTextFieldEnable] = useState(false);
  const [deleteTextFieldEnable, setDeleteTextFieldEnable] = useState(false);

  useEffect(() => {
    if (activeAnswerSetTab === 0 && langcode === "en") {
      setTextFieldEnable(true);
    } else {
      setTextFieldEnable(false);
    }
    if (responseDetails.sets.indexOf(tabarray[activeAnswerSetTab]) !== -1) {
      setDeleteTextFieldEnable(true);
    } else {
      setDeleteTextFieldEnable(false);
    }
  }, [langcode, activeAnswerSetTab]);

  // Update translated history
  const updateTranslateHistory = (translateKey = "") => {
    const translateEditedData = iseditedLangtxt(translatedFields, langcode, translateKey);
    dispatch(updateStateReducerAction.update(surveyConstant.EDIT_TRANSLATE_HISTORY_UPDATE_SUCCESS, translateEditedData));
  };

  /**
   * Handle change
   * @param {*} panel
   */
  const handleEditChoices = (event, translateKey = "") => {
    let { name, value } = event;
    value = value.replace(/  +/g, " ");
    const reducerData = getReducerUpdatedData({ name, value }, answerSet);
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_EDITANSWERSET_REDUCER_UPDATE, reducerData));
    updateTranslateHistory(translateKey)
  };

  /**
  * Update reducer
  * @param {*} panel
  */
  const updateReducer = (answerSet, ...updateFields) => {
    updateFields.map((item, index) => {
      answerSet = { ...answerSet, item };
    });
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_EDITANSWERSET_REDUCER_UPDATE, answerSet));
  };

  /**
   * Delete Answer Choice
   * @param {*} panel
   */
  const deleteAnsSetChoice = (objkey, index) => {
    if (deleteTextFieldEnable === true) {
      dispatch(alertAction.error("cannot delete, response has been collected"));
    } else {
      const sets = answerData;
      for (let langkey in sets[tabarray[index]]) {
        delete sets[tabarray[index]][langkey][objkey];
        let newArr = [];
        for (let key1 in sets[tabarray[index]][langkey]) {
          newArr.push(sets[tabarray[index]][langkey][key1]);
        }
        let newObj = {};
        let it = 0;
        if (objkey === 0) {
          it = 1;
        } else if ("0" in sets[tabarray[index]]["en"]) {
          it = 0;
        } else {
          it = 1;
        }
        for (let x = 0; x < newArr.length; x++) {
          newObj[it + ""] = newArr[x];
          ++it;
        }
        sets[tabarray[index]][langkey] = newObj;
      }
      updateReducer(answerSet, sets);
    }
  };

  /**
        * Add Answer Choice
        * @param {*} panel
    */
  const addAnsSetChoice = (index) => {
    if (deleteTextFieldEnable === true) {
      dispatch(alertAction.error("cannot add, response has been collected"));
    } else {
      const sets = answerData;
      let maxidx = Object.keys(sets[tabarray[index]]["en"]).length;
      for (let langkey in sets[tabarray[index]]) {
        if (maxidx < 5) {
          if (sets[tabarray[index]][langkey].hasOwnProperty("0")) {
            sets[tabarray[index]][langkey][maxidx + ""] = "";
          } else {
            sets[tabarray[index]][langkey][(maxidx + 1) + ""] = "";
          }
        } else if (maxidx === 5) {
          if (sets[tabarray[index]][langkey].hasOwnProperty("0")) {
            sets[tabarray[index]][langkey][maxidx + ""] = "";
          } else {
            sets[tabarray[index]][langkey]["0"] = "";
          }
        }
      }
      updateReducer(answerSet, sets);
    }
  };

  return (
    <React.Fragment>
      {tabarray && answerData &&
        <div>
          {tabarray.map((item, index) => (
            <TabPanelComponent className={classes.surveytabs_body} value={activeAnswerSetTab} key={index} index={index}>
              <Grid container spacing={3} className={"pb-15"}>
                {Object.keys(answerData[tabarray[activeAnswerSetTab]]["en"]).map((objkey, ind) => (
                  <Grid item sm={4}>
                    {langcode !== "en"
                      ? <TypographyComponent
                        constiant="body1"
                        display="block"
                        variant="h6"
                        className={classes.cust_answerset_items_txt} >
                        {answerData[tabarray[activeAnswerSetTab]]["en"][objkey] || ""}
                      </TypographyComponent>
                      : ""}

                    <Grid className={`divWidth ${classes.cust_answerset_items} ${translatedFields && translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, `answerSet_${tabarray[activeAnswerSetTab]}_${objkey}`) ? classes.translatedField : classes.editedtranslatedField) : ""}`} >
                      <Badge
                        badgeContent={objkey}
                        showZero
                        color="secondary"
                        className={`${classes.cust_answerset_items_badge} ${"badgebig"}`}>
                      </Badge>
                      <TextFieldComponent
                        disabled={textFieldEnable}
                        className={`${classes.cust_answerset_items_txt} ${"br-none"}`}
                        name={`sets.${tabarray[activeAnswerSetTab]}.${langcode}.${objkey}`}
                        value={(answerData[tabarray[activeAnswerSetTab]][langcode] &&
                          answerData[tabarray[activeAnswerSetTab]][langcode][objkey])
                          ? answerData[tabarray[activeAnswerSetTab]][langcode][objkey] : ""}
                        margin={"dense"}
                        size="small"
                        variant="outlined"
                        showErrorMessages={false}
                        handleChange={(e) => handleEditChoices(e, `answerSet_${tabarray[activeAnswerSetTab]}_${objkey}`)}
                      ></TextFieldComponent>
                      {(langcode === "en" && activeAnswerSetTab !== 0 && objkey === "0") &&
                        <IconButton
                          onClick={() => deleteAnsSetChoice(objkey, index)}
                          className="p5 icon-hover ImgActiveOrange absolutert"
                          size="large"
                          position="end">
                          <img src={Tiledelete} alt="delete" />
                        </IconButton>
                      }
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid container spacing={3}>
                {Object.keys(answerData[tabarray[activeAnswerSetTab]]["en"]).length < 6
                  ? <Link
                      style={{ marginTop: 32, marginLeft: 18 }}
                    onClick={() => { addAnsSetChoice(index); }}
                    className={`${classes.quest_linkimg} ${"pt-1 quest_link txtright cursor-pointer"}`}
                    color="primary">
                    <IconButton color="inherit" className={"p-0"} size="large"><img src={Addquestion} alt={""} /></IconButton>
                    {surveyQuestionsConstants.ADD_ZERO_ANSWER_CHOICE}
                  </Link>
                  : ""}
              </Grid>
            </TabPanelComponent>
          ))}
        </div>
      }
    </React.Fragment>
  );
}

// default props
AnswerSetTabPanel.defaultProps = {
  classes: {},
  answerData: {},
  activeAnswerSetTab: 0,
  langcode: "en",
  tabarray: [],
  answerSet: {},
  responseDetails: {}
};

// prop types
AnswerSetTabPanel.propTypes = {
  classes: PropTypes.object,
  activeAnswerSetTab: PropTypes.number,
  answerData: PropTypes.object,
  langcode: PropTypes.string,
  tabarray: PropTypes.array,
  answerSet: PropTypes.object,
  responseDetails: PropTypes.object
};

export default AnswerSetTabPanel;
