import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import withStyles from '@mui/styles/withStyles';
import ChipInput from "@jansedlon/material-ui-chip-input";
import Chip from "@mui/material/Chip";

// import helpers
import { IsEmail } from "../../../../helpers/appHelper";

// Import Styles
import SurveyPreviewStyles from "../SurveyPreviewStyles";

//Import Component
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

//import constants
import { TakeSurveyConstants } from "../../../../config/constants";

// Import Actions
import { surveyPreviewAction, alertAction } from "../../../../redux/actions";

function PreviewSendMail(props) {
  const { classes, closePreviewSendMail } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  const surveyId = useSelector((state) => state.survey.previewSurveyId);
  const surveyMailRequest = useSelector((state) => state.surveyPreview.surveyMailStatus);
  const [testEmails, setTestEmails] = useState([]);
  const [emailMessage, setEmailMessage] = useState(TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_MESSAGE_DEFAULT);
  const [emailError, setEmailError] = useState([]);


  function handleEmailMessage(e) {
    const emailMessage = e.target.value;
    setEmailMessage(emailMessage);
  }

  function handleSendMail() {
    if (testEmails.length > 0 && emailMessage) {
      let toEmails = [];
      let errorEmails = [];
      testEmails.filter((val) => {
        if (!toEmails.includes(val.email) && val.isValid === true) {
          toEmails.push(val.email);
        }
        else if (val.isValid === false) {
          errorEmails.push(val.email);
          dispatch(alertAction.error(TakeSurveyConstants.PREVIEW_INVALID_EMAIL));
        }
      });
      if (!errorEmails.length > 0) {
        dispatch(surveyPreviewAction.sendTestMail(surveyId, toEmails, emailMessage));
      }
      /* const checkMail = emailValidation();
      if (checkMail) {
        dispatch(surveyPreviewAction.sendTestMail(surveyId, toEmails, emailMessage));
      } else {
        dispatch(alertAction.error(TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_VALID_ERROR));
      } */
    } else {
      dispatch(alertAction.error(TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_EMPTY_ERROR));
    }
  }

  function emailValidation() {
    const emails = String(testEmails).toLowerCase().replace(/\n/g, "").split(",");
    var valid = true;
    var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    for (var i = 0; i < emails.length; i++) {
      let testEmail = emails[i].trim();
      if (testEmail == "" || !regex.test(testEmail)) {
        valid = false;
      }
    }
    return valid;
  }

  function handleClose() {
    closePreviewSendMail();
  }

  //add emailIDs 
  const handleAddChipOnblur = (chipvalue) => {
    let chip = chipvalue.target.value;
    let emailIdArr = chip.split(/[\s,\;]+/);
    let resultEmails = getEmailChips(emailIdArr);
    let newEmailsArray = [...testEmails, ...resultEmails];
    setEmailError(newEmailsArray.filter(val => val.isValid === false));
    setTestEmails(newEmailsArray);

  };

  const handleAddChip = (chip) => {
    let emailIdArr = chip.split(/[\s,\;]+/);
    let resultEmails = getEmailChips(emailIdArr);
    let newEmailsArray = [...testEmails, ...resultEmails];
    setEmailError(newEmailsArray.filter(val => val.isValid === false));
    setTestEmails(newEmailsArray);

    // if (emailError.length > 0) {
    //   dispatch(alertAction.error(TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_VALID_ERROR));
    // }

  };

  const getEmailChips = (emailArr) => {
    let resultEmails = [];
    emailArr.map((i) => {
      if (i) {
        if (IsEmail(i.trim())) {
          resultEmails.push({ email: i.trim().toLowerCase(), isValid: true });
        } else {
          resultEmails.push({ email: i.trim().toLowerCase(), isValid: false });
        }
      }
    });
    return resultEmails;
  }

  //delete email Ids
  const handleDeleteChip = (chip, index) => {
    testEmails.splice(index, 1);
    setTestEmails([...testEmails]);
  };

  return <>
    <Grid container className={classes.sendPreviewMailRoot}>
      <Grid item xs={12} className="mb-15 pt-1">
        <TypographyComponent variant={"h3"} className="txtBold">
          {TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_TITLE}
          <ButtonComponent title="X Close" size="small" pageClassName="previewClose" componentClassName="previewClose" handleClick={handleClose}></ButtonComponent>
        </TypographyComponent>
      </Grid>
      <Grid item xs={12} className="mb-15 pt-1">
        <Grid container alignItems="center">
          <Grid item md={12} xs={12} className="pb5">
            <TypographyComponent variant="body1">{TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_EMAIL_LABEL}</TypographyComponent>
          </Grid>
          <Grid item md={12} xs={12}>
            {/* <ChipInput
              className={`${classes.chipInput} width100 lineHeight20`}
              variant="outlined"
              color="primary"
              value={testEmails || []}
              onBlur={(chip) => handleAddChipOnblur(chip)}
              onAdd={(chip) => handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
              placeholder={TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_EMAIL_PLACEHOLDER}
            /> */}
            <ChipInput
              className={`${classes.chipInput} width100 lineHeight20`}
              variant="outlined"
              color="primary"
              value={testEmails || []}
              onBlur={(chip) => handleAddChipOnblur(chip)}
              onAdd={(chip) => handleAddChip(chip)}
              onDelete={(chip, index) => handleDeleteChip(chip, index)}
              placeholder={TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_EMAIL_PLACEHOLDER}
              dataSource={testEmails || []}
              InputProps={{ autoComplete: "no" }}
              chipRenderer={({ value, text, chip, isFocused, isDisabled, isReadOnly, handleClick, handleDelete, className }, key) => {
                return (
                  <Chip
                    key={key}
                    label={value.email}
                    onClick={(e) => e.stopPropagation()}
                    onDelete={handleDelete}
                    className={value.isValid ? "maxWidth100" : `${classes.errorChip} maxWidth100`}
                  />
                )
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mb-15 pt-1">
        <Grid container alignItems="center">
          <Grid item md={12} xs={12} className="pb5">
            <TypographyComponent variant="body1">{TakeSurveyConstants.PREVIEW_SEND_TEST_MAIL_MESSAGE_LABEL}</TypographyComponent>
          </Grid>
          <Grid item md={12} xs={12} /* className={classes.textAreaResize} */>
            <TextareaAutosize className={`${"width100 pe_textarea lineHeight20 scrolly"}`} name={"email_message"} value={emailMessage} onChange={handleEmailMessage} minRows={3} maxRows={3} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container md={12} xs={12} className="dflex justifycenter mt-2 mb-1">
        <ButtonComponent title="Send" size="small" color="primary" pageClassName="mr-1" handleClick={handleSendMail} disabled={surveyMailRequest}></ButtonComponent>
      </Grid>
    </Grid>
  </>;
}

// default props
PreviewSendMail.defaultProps = {
  classes: {},
};

// prop types
PreviewSendMail.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(PreviewSendMail);
