/* eslint-disable prefer-const */
import moment from "moment"
import momentTZ from "moment-timezone"
import { surveyTypes } from "../config/constants"
import crypto from "crypto-js";

/**
 * Set Token
 * @param data
 */
export const setToken = (data) => {
    localStorage.setItem("key", data);
};

/**
 * Remove Token
 */
export const removeToken = () => {
    
    localStorage.removeItem("key");

    // destroy the Summary Widget dropdowns on logout
    localStorage.removeItem("summaryWidgetColumns")
};

/**
 * Get Token
 */
export const getToken = () => {
    const token = localStorage.getItem("key");
    if (token) {
        return token;
    }
    return null;
};

export const addEllipsis = (input, charCount = 10) => {
    if (input.length > charCount) {
        return input.substring(0, charCount) + "...";
    }
    return input;
};

export const replaceString = (sentence = "", word = "", replaceword = "") => {
    const strRegExPattern = '\\b' + word + '\\b';
    return sentence.replace(new RegExp(strRegExPattern, 'g'), replaceword);
}

/**
 *
 * @param value
 */
export const attributeSplit = (value) => {
    value = value || "";
    value = value.length > 5 ? value.substring(5, 0).concat("...") : value;
    return value;
};

export const getReducerUpdatedData = (keyvalue, data) => {
    let { name, value } = keyvalue;
    let nameBag = name.split(".");
    let len = nameBag.length;
    let updatedData = {};

    if (len === 1) {
        updatedData = { ...data, [name]: value };

        return updatedData;
    } else {
        updatedData = { ...data };
        for (var a = 0; a < len - 1; a++) {
            var elem = nameBag[a];
            if (!updatedData[elem]) updatedData[elem] = {};
            updatedData = updatedData[elem];
        }
        updatedData[nameBag[len - 1]] = value;

        return { ...data, updatedData };
    }
};

/**
 * Swap Array Elements
 * @param {*} items
 * @param {*} x
 * @param {*} y
 */
export const swapArrayElements = (items, x, y) => {
    if (items.length === 1) return items;
    let b = items[y];
    items[y] = items[x];
    items[x] = b;
    return items;
};

/**
 *
 * @param dataType
 */
export const isNumeric = (dataType) => {
    return (
        dataType.toLowerCase() === "integer" ||
        dataType.toLowerCase() === "numeric" ||
        dataType.toLowerCase() === "money"
    );
};

/**
 *
 * @param dataType
 */
export const isDateTime = (dataType) => {
    return (
        dataType.toLowerCase() === "date" ||
        dataType.toLowerCase() === "datetime" ||
        dataType.toLowerCase() === "datetimeoffset" ||
        dataType.toLowerCase() === "time"
    );
};

/**
 * @param obj1 JSONOBJECT
 * @param obj2 JSONOBJECT
 */
export const isSameObject = (obj1, obj2) => {
    return JSON.stringify({ a: obj1 }) === JSON.stringify({ a: obj2 });
};

export const deepClone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
};

export function getQueryVariable(queryString = "", variable) {
    var query = queryString.split("?")[1] || "";
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
}

/*
 *date format creater mm/dd/yyyy
 */
export const dateFormatter = (date) => {
    if (new Date(date) instanceof Date) {
        const dateValue = moment(date).add(7, 'hours')
        const defaultDate = new Date(dateValue);
        
        let day = defaultDate.getDate();
        let month = defaultDate.getMonth() + 1;
        let year = defaultDate.getFullYear();

        day = day < 10 ? ("0" + day) : day;
        month = month < 10 ? ("0" + month) : month;

        const formattedDate = month + "/" + day + "/" + year;
        //if(typeof month === 'number' || typeof day === 'number' , typeof year === 'number')
        // return 'invalid Date'
        return formattedDate;
    } else {
        return "invalid Date";
    }
};

/**
 * Get Readable String from Large Strings value
 */
export const getReadableString = (str) => {
    // truncate if too long and elipsis...
    if (str && str.length > 35 && typeof (str) === "string") {
        return str.substring(0, 32) + "...";
    }
    return str;
};

// Readable Access Level
export const generateAccessLevel = (queryDLA, metadata = []) => {
    var _dlaFormate = queryDLA
    _dlaFormate = _dlaFormate.replace(/[$]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[/"]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[{]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[}]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[{]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[}]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[[]+/g, '');
    _dlaFormate = _dlaFormate.replace(/]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[:]+/g, ' ');

    let regex = /(\bin\b|\bnin\b|,)/g;
    var splitString = _dlaFormate.split(regex);
    splitString = splitString.map(item => item?.trim());

    if (splitString[0] && (splitString[0].startsWith("and") || splitString[0].startsWith("or"))) {
        if (splitString[0] === "and" || splitString[0] === "or") {
            splitString.splice(0, 1);
        } else {
            splitString[0] = splitString[0].replace(/^(and|or)\s*/, '');
        }
    }
    for (let i = 0; i < splitString.length; i++) {
        try {
            let vvalue = metadata.filter(m => (m["key"] === splitString[i]?.trim()))
            if (vvalue.length === 1) {
                splitString[i] = vvalue[0]["value"]
            }
        } catch (err) {
            console.log(err)
        }
        if (splitString[i] === "in") {
            splitString[i] = "includes"
        } else if (splitString[i] === "nin") {
            splitString[i] = "does not include"
        }
    }
    _dlaFormate = splitString.join(' ');
    _dlaFormate = _dlaFormate.replace(/[,]+/g, ', ');
    return _dlaFormate
}

/**
 * Dashboard / Action Overview Text
 */
export const generateOverViewText = (type, surveys, date) => {

    let overViewText = '';
    // Set Surveys Names
    if (surveys && surveys.length > 0) {
        surveys.map(d => overViewText += typeof d !== 'undefined' ? (d && d.name ? `${d.name}, ` : '') : '');
        overViewText = overViewText.replace(/,\s*$/, "");
    }

    // If No Surveys Selected
    if (overViewText === '')
        overViewText = 'No Surveys Selected'

    // If Type Is Read Mode
    if (type === 'read')
        overViewText = getReadableString(overViewText);

    // Set Date
    if (date && date.type && surveys && surveys[0] && surveys[0].type !== surveyTypes.MULTI_RATER_FEEDBACK) {
        overViewText += ` | `;
        if (date.type === 'Custom dates' && dateFormatter(date.startDate) !== 'NaN/NaN/NaN' && dateFormatter(date.endDate) !== 'NaN/NaN/NaN' && dateFormatter(date.startDate) !== 'invalid Date' && dateFormatter(date.endDate) !== 'invalid Date')
            overViewText += `${dateFormatter(date.startDate)} - ${dateFormatter(date.endDate)}`
        else
            overViewText += date.type || 'No Date type';
    }
    return overViewText;
}

//Filter and report start date
export const getStartDate = function (req) {
    const data = req;
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    today = mm + '/' + dd + '/' + yyyy;
    const d = new Date(today);
    let start_date;
    if (data.type === "Last month") {
        d.setMonth(d.getMonth() - 1);
        d.setDate(d.getDate() - 1);
        start_date = d;
    } else if (data.type === "Last 3 months") {
        d.setMonth(d.getMonth() - 3);
        d.setDate(d.getDate() - 1);
        start_date = d;
    } else if (data.type === "Last 6 months") {
        d.setMonth(d.getMonth() - 6);
        d.setDate(d.getDate() - 1);
        start_date = d;
    } else if (data.type === "Last year") {
        d.setMonth(d.getMonth() - 12);
        d.setDate(d.getDate() - 1);
        start_date = d;
    } else if (data.type === "Custom dates") {
        start_date = moment(data.startDate).add(7, 'hours')
    }
    let startdate = moment(start_date);
    startdate = startdate.format("MM/DD/YYYY");
    return (startdate);
}

//Filter end date
export const getEndDate = function (req) {
    const data = req;
    if (data.type === "Custom dates") {
        const s = moment(data.endDate).add(7, 'hours')
        let end_date = moment(s);
        end_date = end_date.format("MM/DD/YYYY");
        return (end_date);
    } else {
        let end_date = moment().subtract(1, 'days');
        end_date = end_date.format("MM/DD/YYYY");
        return (end_date);
    }
}

//Query DLA spliter
export const queryDLASpliter = function (_dlaFormate) {

    _dlaFormate = _dlaFormate.replace(/[$]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[/"]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[{]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[}]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[{]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[}]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[[]+/g, '');
    _dlaFormate = _dlaFormate.replace(/]+/g, '');
    _dlaFormate = _dlaFormate.replace(/[:]+/g, ' ');
    var splitString = _dlaFormate.split(" ");
    if (splitString[0] == "and" || splitString[0] == "or") {
        splitString.splice(0, 1);
    }
    _dlaFormate = splitString.join(' ');
    _dlaFormate = _dlaFormate.replace(/[,]+/g, ', ');

    return _dlaFormate;
}

//check whether the date is valid or not
export const dateValidator = (date) => {
    if (!date || date === " ") {
        return false;
    } else {
        const dateValid = moment(date).format("MM/DD/YYYY")
        if (dateValid == "Invalid Date") {
            return false;
        } else {
            return true;
        }
    }
}

// Email Validation
export const IsEmail = (email) => {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/
    return regex.test(email)
}

export const isNineToNine = () => {
    return true;
    const MTCHour = momentTZ().tz('America/Denver').hour()
    return MTCHour >= 9 && MTCHour < 21;
}

export const isTranslated = (allFields = [], langcode, currentField,) => {
    const current_langcode = langcode
    const translated_history = allFields[current_langcode] ? allFields[current_langcode] : []
    if (translated_history.includes(currentField)) {
        return true
    } else {
        return false
    }
}

export const iseditedLangtxt = (allFields = [], langcode, currentField) => {
    const updatedFields = allFields[langcode] && allFields[langcode].filter((val) => val !== currentField)
    if (allFields[langcode]) {
        allFields[langcode] = updatedFields
    }
    return allFields;
}

export const linkIfy = (text, template_data = {}) => {
    // Put the URL to variable $1 after visiting the URL
    var urlText = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

    // Replace the RegExp content by HTML element
    let result = text.replace(urlText, `<a href='$1' target='_blank'>$1</a>`);

    // new line
    result = result.replace(/\n/gi, "</br>")

    // firstName and lastName replace
    result = result.replace(/{{firstName}}/i, template_data.firstName || "")
    result = result.replace(/{{lastName}}/i, template_data.lastName || "")

    return result;
}

// To remove anchor tag
export const updateLinksInHTML = (html) => {
    var regex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/gi;
    var link;
    while ((link = regex.exec(html)) !== null) {
      html = html.replace(
        `<a href="${link[2]}" target="_blank">${link[2]}</a>`,
        link[2]
      );
    }
    html = html.replace(/<br\s*\/?>/gi, "\n")
    return html;
  }

export const generateKey = (...strings) => {
    // Concatenate all strings
    const concatenatedString = strings.join('').toLowerCase();

    // Generate SHA-256 hash
    const sha256Hash = crypto.SHA256(concatenatedString).toString(crypto.enc.Hex);

    return sha256Hash;
}