import React, { useState, useSelector } from "react";
import PropTypes from "prop-types";
import { TextValidator } from "react-material-ui-form-validator";
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox, Grid } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Import Image
import { Pulse } from "../../assets/images/svgComponents/ImageComponent";

// Import Config Constants
import { errorsConstants } from "../../config/constants";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

/**
 * Autocomplete Component
 */
function MultiSelectComponentwithsuboption(props) {
  const [open, setOpen] = useState(false);

  const handleSelect = (e,option) =>{
    if(option){
      if(option.subOption){
        if(value.includes(e)){
          let event = value.filter(opt=>opt !== e)
          // if(!option.subOption.some(elem => event.includes(elem))){
          //   event = event.filter(op=>op !== option.option)
          // }
          props.handleAutoCompolete(name, event);
        } else {
          let event = value;
          if(!value.includes(option.option)){
          event.push(option.option);
          }
          event.push(e);
          props.handleAutoCompolete(name, event);
        }
      }else {
      if(!option.includes(e)){
        if(value.includes(e)){
          let event = value.filter(opt=>opt !== e && !option.includes(opt))
          props.handleAutoCompolete(name, event);
          }else {
            let event = value;
            event.push(e)
            if(e=='Report'){
              event.push("Comments")
            }
            // event = event.concat(option)
            props.handleAutoCompolete(name, event);
          }
      }
    }
   } else {
      if(value.includes(e)){
        let event = value.filter(opt=>opt !== e)
        props.handleAutoCompolete(name, event);
      } else {
        let event = value;
        event.push(e)
        props.handleAutoCompolete(name, event);
      }
    }
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }
  /**
   * render html
   */
  const { suggestions, disabled, value, placeholder, className, validators, showErrorMessages, name, isArrayObjects, selectedOptions, pulseSurveys, checkboxClassName } = props;
  const errorMessages = showErrorMessages ? validators.map(s => errorsConstants[s]) : [];
  return (
    <React.Fragment>
          <div style={{}}>
            {suggestions &&
              <React.Fragment>
                <Autocomplete
                  classes={{
                    listbox: "multi-select-option-list"
                  }}
                  name={name}
                  variant={"outlined"}
                  multiple
                  disableClearable
                  autoHighlight
                  blurOnSelect={false}
                  value={Array.isArray(value) ? value : []}
                  disabled={disabled}
                  className={className}
                  options={suggestions}
                  isOptionEqualToValue={(option, value) => option.option === value || option.subOption === value}
                  // onChange={(event, newValue, action) => {
                  //   handleSelect(newValue)
                  //   props.handleAutoCompolete(name, newValue, event, action);
                  // }}
                  getOptionLabel={(option) => option}
                  renderTags={(value, getTagProps) => {
                    return null; 
                  }}
                  renderInput={(params) => (
                    <TextValidator
                      {...params}
                      variant={"outlined"}
                      value={Array.isArray(value) ? value : null}
                      name={name}
                      validators={validators}
                      errorMessages={errorMessages}
                      placeholder={value.length > 0 ? value.join(", ") : placeholder}
                      margin={"dense"}
                      onKeyDown={handleKeyDown}
                      InputProps={{ ...params.InputProps, type: "text", className: "multiSelectInput headerSearch pr-3" }}
                    />
                  )}
                  renderOption={(props, option, state) => {
                    return (
                      <li {...props} style={{padding: '2px'}} className={checkboxClassName} >
                        <Grid style={{display:"flex" , flexDirection:"column"}}>
                        <Grid style={{display:"flex" , flexDirection:"row", alignItems:"center"}} onClick={()=>{handleSelect(option.option, option?.subOption)}}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className="autocomplete-checkbox"
                          checked={value.includes(option.option)}
                          style={{ margin: 0, paddingRight: "4px" }}
                        />
                        {option.option}
                        </Grid>
                        {option?.subOption?.length > 0 &&
                         option.subOption.map((sub,index)=>(
                        <Grid key={index} style={{display:"flex" , flexDirection:"row", alignItems:"center", marginLeft:"20px"}} onClick={()=>{handleSelect(sub,option)}}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          className="autocomplete-checkbox"
                          checked={value.includes(sub)}
                          style={{ margin: 0, paddingRight: "4px" }}
                        />
                        {sub}
                        </Grid>
                        ))}
                       </Grid>
                      </li>
                    );
                  }}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={(e, reason) => {
                    if (reason === "blur" || reason === "toggleInput") {
                      setOpen(false);
                    } else {
                      setOpen(true);
                    }
                  }}
                  open={open || false}
                />
              </React.Fragment>
            }
          </div>
    </React.Fragment>
  );
}

// default props
MultiSelectComponentwithsuboption.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  placeholder: "",
  validators: [],
  errorMessages: [],
  showErrorMessages: true,
  fieldErrors: [],
  warningFieldName: "",
  margin: "dense",
  className: "",
  checkboxClassName: "",
  suggestionType: "arrayObject",
  defaultValue: null,
  handleAutoCompolete: () => { },
  multiple: true,
  isArrayObjects: false,
  selectedOptions: [],
  pulseSurveys: [],
};

// prop types
MultiSelectComponentwithsuboption.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  suggestionType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  showErrorMessages: PropTypes.bool,
  fieldErrors: PropTypes.array,
  margin: PropTypes.string,
  className: PropTypes.string,
  checkboxClassName: PropTypes.string,
  defaultValue: PropTypes.any,
  handleAutoCompolete: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  isArrayObjects: PropTypes.bool,
  selectedOptions: PropTypes.array,
  pulseSurveys: PropTypes.array,
};

/** Export Component */
export default MultiSelectComponentwithsuboption;
