import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

// import child route
import FailedPageComponent from "../../../../components/FailedPageComponent/FailedPageComponent";

// Import History
import history from "../../../../config/history";

// import constants
import { participantConstants } from "../../../../config/constants";

function FailedPage (props) {
  // Get Error Details
  const errorData = useSelector((state) => state.excel.upload);

  // Redirect to upload page
  const redirectURL = () => {
    history.push({ pathname: "/manage_people/participants/archive/upload" });
  };

  return (
    <FailedPageComponent
      match={props.match}
      redirectURL={redirectURL}
      errorData={errorData && errorData.error ? errorData.error : participantConstants.FAILED_SUBTITLE }
    />
  );
}
// default props
FailedPage.defaultProps = {
  match: {}
};

// prop types
FailedPage.propTypes = {
  match: PropTypes.object
};
export default FailedPage;
