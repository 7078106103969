/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// import components
import SelectFieldComponent from "../../../../../components/SelectFieldComponent/SelectFieldComponent";

// Import Actions
import { categoryAction, alertAction } from "../../../../../redux/actions";

// import constants
import { surveyQuestionsConstants, surveyTypes } from "../../../../../config/constants";

function SelectAnsSet(props) {
  const dispatch = useDispatch();

  // Get state data
  const answerSet = useSelector((state) => state.survey.answerSet);
  const { type } = useSelector((state) => state.survey.getOne);
  const tabArray = answerSet.tabarray ? answerSet.tabarray : [];

  // Get Props Values
  const { category, questionDataSet, questionData, item } = props;
  const { languages } = questionData;

  // set selected answer set
  let selectedSet = "";
  let ansLen = Object.keys(answerSet["sets"])
  if (type === surveyTypes.MULTI_RATER_FEEDBACK && ansLen.length === 1 && JSON.stringify(surveyQuestionsConstants.ME_CUSTOM_ANSWERSET["sets"]["1-5 agreement scale"][surveyQuestionsConstants.DEFAULT_LANG_CODE]) === JSON.stringify(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE])) {
    selectedSet = "1-5 agreement scale"
  }
  if (questionDataSet["ansSet"] && answerSet["sets"]) {
    for (let key in answerSet["sets"]) {
      if (JSON.stringify(answerSet["sets"][key][surveyQuestionsConstants.DEFAULT_LANG_CODE]) === JSON.stringify(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE])) {
        selectedSet = key;
      }
    }
  }

  // change ansset value
  const changeAnsSet = (value) => {
    if (value && answerSet["sets"][value] && answerSet["sets"][value][surveyQuestionsConstants.DEFAULT_LANG_CODE]) {
      const customisedSet = Object.keys(answerSet["sets"][value][surveyQuestionsConstants.DEFAULT_LANG_CODE]);
      const questionAnsSet = Object.keys(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE]);
      // check for five scale matchingq
      if (customisedSet[(customisedSet.length) - 1] === questionAnsSet[(questionAnsSet.length) - 1]) {
        let reducerData = {};
        if (type === surveyTypes.MULTI_RATER_FEEDBACK && surveyQuestionsConstants.ME_CUSTOM_ANSWERSET["sets"][value]) {
          reducerData = { ...questionDataSet, ansSet: JSON.parse(JSON.stringify(surveyQuestionsConstants.ME_CUSTOM_ANSWERSET["sets"][value])) }
        } else {
          reducerData = { ...questionDataSet, ansSet: JSON.parse(JSON.stringify(answerSet["sets"][value])) };
        }
        if (!item) {
          dispatch(categoryAction.addOrChangeQuestion(category, reducerData));
        } else {
          props.editQuestionsData(reducerData);
        }
      } else {
        dispatch(alertAction.error(surveyQuestionsConstants.ANS_SCALE_NOT_MATCH));
      }
    } else if (value) {
      dispatch(alertAction.error(surveyQuestionsConstants.ANS_SET_EMPTY));
    }
  };

  return (
    <React.Fragment>
      <FormControl className="width100">
        <Select
          className={"boderRadius2 height40"}
          name={"Answer_Sets"}
          id="Answer Sets"
          value={selectedSet}
          displayEmpty
          variant="outlined"
          onChange={(e) => changeAnsSet(e.target.value)}
        >
          <MenuItem className={"txtgrey"} value="">Custom scale</MenuItem>
          {tabArray.map((item, keyIndex) => (
            <MenuItem key={keyIndex} name={item} value={item} >
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </React.Fragment>
  );
}

// default props
SelectAnsSet.defaultProps = {
  questionDataSet: {},
  item: null,
  category: null,
  editQuestionsData: () => { }
};

// prop types
SelectAnsSet.propTypes = {
  questionDataSet: PropTypes.object,
  item: PropTypes.object,
  category: PropTypes.string,
  editQuestionsData: PropTypes.func
};

export default SelectAnsSet;
