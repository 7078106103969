/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";

// import components
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";

import { closeSurvey } from "../../../../assets/images/svgComponents/ImageComponent";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

// Import Containers
import EditCategory from "./EditCategory";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../redux/actions";

// import images
import CloseImg from "../../../../assets/images/svg/close.svg";

function CategoryTabs(props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, questionData, activeCategoryTab, addQuestions, langCode, errors } = props;
  const { questions, catOrder, catDetails } = questionData;
  const disableTabs = questions.filter(q => q.editable).length > 0 || Object.keys(addQuestions).length > 0;

  // error tabs
  let error_tabs = []
  if (errors && errors[langCode]) {
    error_tabs = errors[langCode].map(m => m.split("_")[0])
  }

  // set state
  const [removeCatDialog, setRemoveCatDialog] = React.useState({ flag: false, ind: "" });

  // Update reducer data for delete category functionality
  const updateReducer = (activeTab, questions, catOrder, catDetails) => {
    const reducerData = { ...questionData, questions: questions, catOrder: catOrder, catDetails: catDetails };
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_CATAGORY_DELETE_REDUCER_UPDATE, { questions: reducerData, activeCategoryTab: activeTab }));
  };

  // pass State Value for Tabs
  const handleChange = (event, value) => {
    if (event.target.dataset.custom) {
      event.preventDefault();
    } else {
      if (disableTabs) {
        dispatch(alertAction.error(surveyQuestionsConstants.SAVE_QUESTION));
      } else {
        props.changeTabValue(value);
      }
    }
  };

  // Set Id and Controls for tabs
  function getCategoryTabsProps(index) {
    return {
      id: `category-tab-${index}`,
      "aria-controls": `Category-Tab-Panel-${index}`
    };
  }

  // Remove Category Tabs
  const removeCategory = (index) => {
    for (let i = 0; i < questions.length; i++) {
      if (questions[i]["category"] === catOrder[index]) {
        questions.splice(i, 1);
        i--;
      }
    }
    delete catDetails[catOrder[index]];
    catOrder.splice(index, 1);

    // Set Active Tab
    let activeTab = index !== 0 ? (index >= catOrder.length ? index - 1 : index) : 0;

    // Update Survey Question Root Reducers
    updateReducer(activeTab, questions, catOrder, catDetails);
    setRemoveCatDialog({ flag: false, ind: "" });
  };

  return (
    <React.Fragment>
      <AppBar
        position="static"
        color="default"
        className={`${classes.rightPanelAppBarRoot
          } ${"tabborder_none pe_color_appbar"}`}
      >
        <Tabs
          value={activeCategoryTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          className={`${classes.rightPanelTabsRoot} ${"peTabs"}`}
          aria-label="categoryTabs"
        >
          {catOrder.map((item, index) => (
            <Tab
              key={index}
              component="div"
              color={"primary"}
              className={`cat_tab ${error_tabs.indexOf(item) > -1 ? "se_err" : ""}`}
              // disabled={disableTabs}
              classes={{
                root: classes.rightPanelTabRoot,
                selected: classes.rightPanelTabSelected
              }}
              label={
                <span className="dflex">
                  <Tooltip arrow title={item}><span className={classes.tabcharlimit}>{item}</span></Tooltip>
                  <span className={classes.quest_TabEditClose}>
                    {langCode === surveyQuestionsConstants.DEFAULT_LANG_CODE &&
                      <EditCategory
                        classes={classes}
                        questionData={{ ...questionData }}
                        category={item}
                      ></EditCategory>
                    }
                    <Tooltip arrow title={questionData.template==="Manager 360"?surveyQuestionsConstants.COMPETENCY_CLOSE:surveyQuestionsConstants.CATEGORY_CLOSE}>
                      <div className={`${"pl5 peTabDelete"}`} onClick={() => { setRemoveCatDialog({ flag: true, ind: index }); }}>{closeSurvey()}</div>
                      {/* <img
                      data-custom={true}
                      src={CloseImg}
                      onClick={() => { setRemoveCatDialog({ flag: true, ind: index }); }}
                      className={`${"pl5 peTabDelete"}`}
                      alt="Close"
                    /> */}
                    </Tooltip>
                  </span>
                </span>
              }
              {...getCategoryTabsProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {removeCatDialog.ind !== "" &&
        <Dialog
          open={removeCatDialog.flag}
          maxWidth={"sm"}
        >
          <DialogContent>{questionData.template==="Manager 360"?surveyQuestionsConstants.COMPETENCY_DELETE_DIALOG_MESSSAGE:surveyQuestionsConstants.CATEGORY_DELETE_DIALOG_MESSSAGE}</DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Yes"}
              color={"primary"}
              size={"small"}
              handleClick={() => { removeCategory(removeCatDialog.ind); }}
              componentClassName={"marginLR5"}
              type="submit"
            ></ButtonComponent>
            <ButtonComponent
              title={"No"}
              color={"default"}
              size={"small"}
              handleClick={() => { setRemoveCatDialog({ flag: false, ind: "" }); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
        </Dialog>
      }
    </React.Fragment>
  );
}

// default props
CategoryTabs.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  addQuestions: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE
};

// prop types
CategoryTabs.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  addQuestions: PropTypes.object,
  langCode: PropTypes.string
};

export default CategoryTabs;
