// TakeSurvey reducer types
export const takeSurveyConstant = {
  SURVEY_GETONE_REQUEST: "SURVEY_GETONE_REQUEST",
  SURVEY_GETONE_SUCCESS: "SURVEY_GETONE_SUCCESS",
  SURVEY_GETONE_CLEAR: "SURVEY_GETONE_CLEAR",
  SURVEY_GETONE_FAILURE: "SURVEY_GETONE_FAILURE",
  SURVEY_RESPONSE_UPDATE: "SURVEY_RESPONSE_UPDATE",
  SURVEY_RESPONSE_UPDATE_REQUEST: "SURVEY_RESPONSE_UPDATE_REQUEST",
  SURVEY_RESPONSE_UPDATE_SUCCESS: "SURVEY_RESPONSE_UPDATE_SUCCESS",
  SURVEY_RESPONSE_UPDATE_FAILURE: "SURVEY_RESPONSE_UPDATE_FAILURE",
  SURVEY_REQUIRED_QUESTIONS_UPDATE: "SURVEY_REQUIRED_QUESTIONS_UPDATE",
  TAKE_SURVEY_UPDATE_LANGUAGE: "TAKE_SURVEY_UPDATE_LANGUAGE",
  TAKE_SURVEY_AUTH_REQUEST: "TAKE_SURVEY_AUTH_REQUEST",
  TAKE_SURVEY_AUTH_SUCCESS: "TAKE_SURVEY_AUTH_SUCCESS",
  TAKE_SURVEY_AUTH_FAILURE: "TAKE_SURVEY_AUTH_FAILURE",
  TAKE_SURVEY_AUTH_CLEAR: "TAKE_SURVEY_AUTH_CLEAR",
  TAKE_SURVEY_KIOSK_AUTH_REQUEST: "TAKE_SURVEY_KIOSK_AUTH_REQUEST",
  TAKE_SURVEY_KIOSK_AUTH_SUCCESS: "TAKE_SURVEY_KIOSK_AUTH_SUCCESS",
  TAKE_SURVEY_KIOSK_AUTH_FAILURE: "TAKE_SURVEY_KIOSK_AUTH_FAILURE",
  TAKE_SURVEY_KIOSK_AUTH_CLEAR: "TAKE_SURVEY_KIOSK_AUTH_CLEAR",
  CATEGORY_QUESTIONS_UPDATE_REQUEST: "CATEGORY_QUESTIONS_UPDATE_REQUEST",
  CATEGORY_QUESTIONS_UPDATE_SUCCESS: "CATEGORY_QUESTIONS_UPDATE_SUCCESS",
  CATEGORY_QUESTIONS_UPDATE_FAILURE: "CATEGORY_QUESTIONS_UPDATE_FAILURE",
  TAKE_SURVEY_UPDATE_OPEN_STATUS: "TAKE_SURVEY_UPDATE_OPEN_STATUS",
};
