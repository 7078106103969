// import constants
import { alertConstant } from "../constants";
import { baseAction } from "./baseAction";
const { request, success, failure } = baseAction;

// Export All Alert Actions
export const alertAction = {
  successAlert,
  info,
  error,
  clear
};

/**
 *success message for any alert
 *
 * @param {*} message
 * @returns
 */
function successAlert(message, duration = alertConstant.ALERT_DEFAULT_DURATION) {
  return (dispatch) => {
    dispatch(success(alertConstant.ALERT_SUCCESS, message, duration));
  }
};

/**
 *any alert information
 *
 * @param {*} message
 * @returns
 */
function info(message, duration = alertConstant.ALERT_DEFAULT_DURATION) {
  return (dispatch) => {
    dispatch(request(alertConstant.ALERT_INFO, message, duration));
  }
};

/**
 *Error message for any alert
 *
 * @param {*} message
 * @returns
 */
function error(message) {
  return (dispatch) => {
    dispatch(failure(alertConstant.ALERT_ERROR, message));
  }
};

/**
 *Clear alert
 *
 * @returns
 */
function clear() {
  return { type: alertConstant.ALERT_CLEAR };
};
