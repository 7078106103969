import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Helper Function
import { getToken } from "../../helpers";

// Import Actions
import { authAction } from "../../redux/actions";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import AnchorComponent from "../../components/AnchorComponent/AnchorComponent";

// Import Cofig Constants
import { authenticationConstants } from "../../config/constants";

function Login(props) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const UI = useSelector((state) => state.UI);
  const { email, password, showPassword } = formData;
  const dispatch = useDispatch();

  /**
   * Reset login status
   */
  useEffect(() => {
    // Check token when visibility changes
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        const isTokenAvailable = getToken() ? true : false;
        dispatch(authAction.logout(isTokenAvailable));
      }
    };
    // Call action initially
    const isTokenAvailable = getToken() ? true : false;

    dispatch(authAction.logout(isTokenAvailable));
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [dispatch]);

  /**
   * Handle Textbox Changes
   */
  function handleChange(e) {
    const { name, value } = e;
    setFormData((formData) => ({ ...formData, [name]: value }));
  }

  // Show / Hide Password Visibility
  function handleClickShowPassword() {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  }

  /**
   * Login Form Submit
   * @param {*}
   */
  function handleSubmit(e) {
    e.preventDefault();
    if (email && password) {
      dispatch(authAction.login(email, password));
    }
  }

  return (
    <ValidatorForm
      name="logInForm"
      useref="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.SIGNIN_TEXT}
            variant={"h2"}
            className="txtBold"
          ></TypographyComponent>
          <TypographyComponent
            title={authenticationConstants.SIGNIN_SUB_TEXT}
            variant={"h5"}
            className={"txtgrey mt-1"}
          ></TypographyComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.SIGNIN_EMAIL_ID}
            variant={"h6"}
          ></TypographyComponent>
          <TextFieldComponent
            id={"signin_email_txtbox"}
            name={"email"}
            value={email}
            margin={"none"}
            handleChange={(e) => handleChange(e)}
            validators={["required", "isEmail"]}
            className={"heightInput"}
            style={{ height: "3.3em" }}
          ></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.SIGNIN_PASSWORD}
            variant={"h6"}
          ></TypographyComponent>
          <TextFieldComponent
            id={"signin_password_txtbox"}
            name={"password"}
            type={"password"}
            margin={"none"}
            className={"heightInput"}
            value={password}
            pwdVisibileHide={true}
            showPassword={showPassword}
            handleChange={(e) => handleChange(e)}
            handleClickShowPassword={(e) => handleClickShowPassword(e)}
            validators={["required"]}
          ></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5} justifyContent="center" alignItems="center">
            <Grid item xs={6}>
              <ButtonComponent
                title={authenticationConstants.SIGNIN_BTN_TEXT}
                color={"primary"}
                size={"large"}
                type="submit"
                loading={UI.loading}
                style={{
                  height: "42px",
                  minWidth: "118px",
                  padding: "8px 22px",
                }}
              ></ButtonComponent>
            </Grid>
            <Grid item xs={6} className={"txtright"}>
              <AnchorComponent
                title={authenticationConstants.SIGNIN_FORGOT_PASSWORD}
                href="#/forgotpassword"
              ></AnchorComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default Login;
