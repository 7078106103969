// Survey reducer types
export const surveyPreviewConstant = {
  GET_SURVEY_REQUEST: "GET_SURVEY_REQUEST",
  GET_SURVEY_SUCCESS: "GET_SURVEY_SUCCESS",
  GET_SURVEY_CLEAR: "GET_SURVEY_CLEAR",
  GET_SURVEY_FAILURE: "GET_SURVEY_FAILURE",

  SURVEY_RESPONSE_UPDATE: "SURVEY_RESPONSE_UPDATE",
  SURVEY_REQUIRED_QUESTIONS_UPDATE: "SURVEY_REQUIRED_QUESTIONS_UPDATE",
  TAKE_SURVEY_UPDATE_LANGUAGE: "TAKE_SURVEY_UPDATE_LANGUAGE",
  SURVEY_PREVIEW_CONFIRM_LIST: "SURVEY_PREVIEW_CONFIRM_LIST",
  PREVIEW_SURVEY_SUCCESS: "PREVIEW_SURVEY_SUCCESS",
  PREVIEW_FILTERED_QUESTIONS_UPDATE: "PREVIEW_FILTERED_QUESTIONS_UPDATE",
  PREVIEW_SET_ACTIVE_CATEGORY_TAB: "PREVIEW_SET_ACTIVE_CATEGORY_TAB",

  PREVIEW_SURVEY_DOWNLOAD_REQUEST: "PREVIEW_SURVEY_DOWNLOAD_REQUEST",
  PREVIEW_SURVEY_DOWNLOAD_SUCCESS: "PREVIEW_SURVEY_DOWNLOAD_SUCCESS",
  PREVIEW_SURVEY_DOWNLOAD_FAILURE: "PREVIEW_SURVEY_DOWNLOAD_FAILURE",
  PREVIEW_SURVEY_DOWNLOAD_CLEAR: "PREVIEW_SURVEY_DOWNLOAD_CLEAR",

  PREVIEW_SURVEY_MAIL_BOX: "PREVIEW_SURVEY_MAIL_BOX",
  PREVIEW_SURVEY_MAIL_REQUEST: "PREVIEW_SURVEY_MAIL_REQUEST",
  PREVIEW_SURVEY_MAIL_SUCCESS: "PREVIEW_SURVEY_MAIL_SUCCESS",
  PREVIEW_SURVEY_MAIL_FAILURE: "PREVIEW_SURVEY_MAIL_FAILURE",
  PREVIEW_SURVEY_MAIL_CLEAR: "PREVIEW_SURVEY_MAIL_CLEAR",

  PREVIEW_FEED_BACK_MAIL_REQUEST: "PREVIEW_FEED_BACK_MAIL_REQUEST",
  PREVIEW_FEED_BACK_MAIL_SUCCESS: "PREVIEW_FEED_BACK_MAIL_SUCCESS",
  PREVIEW_FEED_BACK_MAIL_FAILURE: "PREVIEW_FEED_BACK_MAIL_FAILURE",
  PREVIEW_FEED_BACK_MAIL_CLEAR: "PREVIEW_FEED_BACK_MAIL_CLEAR",

  PREVIEW_PAGE_LOADING_TRUE: "PREVIEW_PAGE_LOADING_TRUE",
  PREVIEW_PAGE_LOADING_FALSE: "PREVIEW_PAGE_LOADING_FALSE",
};
