import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

/**
 * Checkbox Component
 * @param {*} props
 */
const CheckboxComponent = ({ onChange, ...rest }) => {
  return (
    <Checkbox
      color="primary"
      onChange={onChange}
      {...rest}
    />
  );
};

// default props
CheckboxComponent.defaultProps = {};

// prop types
CheckboxComponent.propTypes = {};

/** Export Component */
export default CheckboxComponent;
