export const actionsConstant = {
    // To get All Action Plan
    ACTION_PLAN_SEARCH_REQUEST: "ACTION_PLAN_SEARCH_REQUEST",
    ACTION_PLAN_SEARCH_SUCCESS: "ACTION_PLAN_SEARCH_SUCCESS",
    ACTION_PLAN_SEARCH_FAILURE: "ACTION_PLAN_SEARCH_FAILURE",

    // To get single Action Plan
    ACTION_PLAN_GET_ONE_REQUEST: "ACTION_PLAN_GET_ONE_REQUEST",
    ACTION_PLAN_GET_ONE_UPDATE: "ACTION_PLAN_GET_ONE_UPDATE",
    ACTION_PLAN_GET_ONE_SUCCESS: "ACTION_PLAN_GET_ONE_SUCCESS",
    ACTION_PLAN_GET_ONE_FAILURE: "ACTION_PLAN_GET_ONE_FAILURE",
    ACTION_PLAN_GET_ONE_CLEAR: "ACTION_PLAN_GET_ONE_CLEAR",

    // To Create Single Action Plan
    ACTION_PLAN_CREATE_REQUEST: "ACTION_PLAN_CREATE_REQUEST",
    ACTION_PLAN_CREATE_SUCCESS: "ACTION_PLAN_CREATE_SUCCESS",
    ACTION_PLAN_CREATE_FAILURE: "ACTION_PLAN_CREATE_FAILURE",

    // To update single Action Plan
    ACTION_PLAN_UPDATE_REQUEST: "ACTION_PLAN_UPDATE_REQUEST",
    ACTION_PLAN_UPDATE_SUCCESS: "ACTION_PLAN_UPDATE_SUCCESS",
    ACTION_PLAN_UPDATE_FAILURE: "ACTION_PLAN_UPDATE_FAILURE",

    // To Remove single Action Plan
    ACTION_PLAN_REMOVE_REQUEST: "ACTION_PLAN_REMOVE_REQUEST",
    ACTION_PLAN_REMOVE_SUCCESS: "ACTION_PLAN_REMOVE_SUCCESS",
    ACTION_PLAN_REMOVE_FAILURE: "ACTION_PLAN_REMOVE_FAILURE",

    // To get Action Plan Data while crating first Time
    ACTION_PLAN_DATA_REQUEST: "ACTION_PLAN_DATA_REQUEST",
    ACTION_PLAN_DATA_SUCCESS: "ACTION_PLAN_DATA_SUCCESS",
    ACTION_PLAN_DATA_FAILURE: "ACTION_PLAN_DATA_FAILURE",

    //To update choices in add plan
    OPPORTNITY_AREA_CHOICES :"OPPORTNITY_AREA_CHOICES",

    //close add action plan dialogue
    TOGGLE_ADD_PLAN_DIALOG: "TOGGLE_ADD_PLAN_DIALOG"
};
