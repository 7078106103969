import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import moment from "moment"
import './totChartStyle.css'


const TotChart = (props) => {
    const { data, pulseData, params, chartId } = props

    let dataChart = JSON.parse(JSON.stringify(data))
    let pulseDataChart = JSON.parse(JSON.stringify(pulseData))
    params.customerSurveys = params.surveys
    let surveyhidden = [];

    useEffect(() => {
        totChart();
    }, [dataChart, pulseDataChart, params]);

    //Render linechart Chart Here
    const totChart = () => {
        d3.select(`#${chartId}`)
            .select("svg")
            .remove()

        const elmnt = document.getElementById(`${chartId}`);
        let width = elmnt.offsetWidth - 100;
        let legendWidth = width / 4;
        let height = 362;
        let margin = 126;
        let lineOpacity = "1";
        let circleOpacity = '1';
        let circleRadius = 6;

        /* Format Data */
        let parseDate = d3.timeParse("%Y-%m-%d");
        dataChart.forEach(function (d1) {
            d1.values.forEach(function (d) {
                d.date = parseDate(d.date);
                d.fav = +d.fav;
            });
        });
        let dateArr = [];
        let dateArrDup = [];
        let valueArr = [];
        let surveyNameArr = [];
        let dateDispArr = []
        for (let i = 0; i < data.length; i++) {
            surveyNameArr.push(data[i].name)
            for (let j = 0; j < data[i].values.length; j++) {
                if (Object.keys(pulseData).length > 0) {
                    if (Object.keys(pulseData).length === 1 && Object.values(pulseData)[0] === "Weeks") {
                        dateArr.push(new Date(data[i].values[j].date))
                        let additionalDate = new Date(data[i].values[j].date)
                        let sampleDate = new Date(additionalDate.setDate(additionalDate.getDate() - 1))
                        let dd = sampleDate.getDate()
                        if (dd < 10)
                            dd = `0${dd}`
                        let mm = sampleDate.getMonth()
                        let yyyy = sampleDate.getFullYear()
                        if (mm > 8) {
                            if (mm + 1 <= 12)
                                dateDispArr.push(`${dd}/${mm + 1}/${yyyy}`)
                            else
                                dateDispArr.push(`${dd}/0${mm + 1 - 12}/${yyyy + 1}`)
                        }
                        else
                            dateDispArr.push(`${dd}/0${mm + 1}/${yyyy}`)
                    } else if (Object.keys(pulseData).length === 1 && Object.values(pulseData)[0] !== "Weeks") {
                        dateArr.push(new Date(data[i].values[j].date))
                        let additionalDate = new Date(data[i].values[j].date)
                        let sampleDate = new Date(additionalDate.setDate(additionalDate.getDate() - 1))
                        let dd = sampleDate.getDate()
                        if (dd < 10)
                            dd = `0${dd}`
                        let mm = sampleDate.getMonth()
                        let yyyy = sampleDate.getFullYear()
                        if (mm > 8) {
                            if (mm + 1 <= 12)
                                dateDispArr.push(`01/${mm + 1}/${yyyy}`)
                            else
                                dateDispArr.push(`01/0${mm + 1 - 12}/${yyyy + 1}`)
                        }
                        else
                            dateDispArr.push(`01/0${mm + 1}/${yyyy}`)
                    } else {
                        let StDDt = moment(new Date(data[i].values[j].date)).format("MM/YYYY")
                        if (dateArrDup.indexOf(StDDt) === -1) {
                            dateArrDup.push(StDDt)
                            dateArr.push(new Date(data[i].values[j].date))
                            let mm = new Date(data[i].values[j].date).getMonth()
                            let yyyy = new Date(data[i].values[j].date).getFullYear()
                            if (mm > 7) {
                                if (mm + 2 <= 12)
                                    dateDispArr.push(`01/${mm + 2}/${yyyy}`)
                                else
                                    dateDispArr.push(`01/0${mm + 2 - 12}/${yyyy + 1}`)
                            }
                            else
                                dateDispArr.push(`01/0${mm + 2}/${yyyy}`)
                        }
                    }
                } else {
                    let dtaTmp = moment(new Date(data[i].values[j].date)).format("MM/YYYY");
                    if (dateArrDup.indexOf(dtaTmp) === -1) {
                        dateArrDup.push(dtaTmp)
                        dateArr.push(new Date(data[i].values[j].date))
                        let mm = new Date(data[i].values[j].date).getMonth()
                        let yyyy = new Date(data[i].values[j].date).getFullYear()
                        if (mm > 7) {
                            if (mm + 2 <= 12)
                                dateDispArr.push(`01/${mm + 2}/${yyyy}`)
                            else
                                dateDispArr.push(`01/0${mm + 2 - 12}/${yyyy + 1}`)
                        }
                        else
                            dateDispArr.push(`01/0${mm + 2}/${yyyy}`)
                    }
                }
                valueArr.push(data[i].values[j].fav)

            }
        }

        valueArr.sort(function (a, b) {
            return a - b
        })
        dateArr.sort(function (a, b) {
            return new Date(a) - new Date(b);
        });
        let newDateArr = []
        if (Object.keys(pulseData).length === 1 && Object.values(pulseData)[0] === "Weeks") {
            let additionalDate = new Date(dateArr[dateArr.length - 1])
            //dateArr.push(additionalDate.setMonth(additionalDate.getDate() + 7))
            additionalDate = new Date(dateArr[0])
            const dateFirst = new Date(dateArr[0]);
            const dateSecond = new Date(dateArr[1]);

            // time difference
            const timeDiff = Math.abs(dateSecond.getTime() - dateFirst.getTime());

            // days difference
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
            dateArr.unshift(additionalDate.setDate(additionalDate.getDate() - diffDays))
            newDateArr = dateArr
        }
        else {
            let additionalDate = new Date(dateArr[dateArr.length - 1])
            dateArr.push(additionalDate.setMonth(additionalDate.getMonth() + 1))
            additionalDate = new Date(dateArr[0])
            dateArr.unshift(additionalDate.setMonth(additionalDate.getMonth() - 1))
            newDateArr.unshift(additionalDate.setMonth(additionalDate.getMonth() - 1))
            do {
                additionalDate = new Date(newDateArr[newDateArr.length - 1])
                newDateArr.push(additionalDate.setMonth(additionalDate.getMonth() + 1))
            } while (new Date(newDateArr[newDateArr.length - 1]) < new Date(dateArr[dateArr.length - 1]))
            newDateArr.shift()
        }

        /* Scale */
        let xScale = d3.scaleTime()
            .domain([newDateArr[0], newDateArr[newDateArr.length - 1]])
            .range([0, width - margin]);


        let yScale = d3.scaleLinear()
            .domain([valueArr[0] - 2, valueArr[valueArr.length - 1] + 2])
            .range([height - margin, 2]);

        let color = [];
        color.push("#57C1FD"); //darkblue
        color.push("#C0E2F5"); //blue
        color.push("#0A5C8C"); //yellow
        color.push("#939697"); //orange2574C3
        color.push("#81C4FF"); //green
        color.push("#79B5D6"); //eggplant
        color.push("#1093FF"); //red
        color.push("#00D3FF"); //bright blue
        color.push("#0F2D6B"); //bright green
        color.push("#6485B5"); //bright berry 
        color.push("#BECED2"); //bright blue
        color.push("#2574C3"); //bright green
        color.push("#5E5E5E"); //bright berry 

        let colorNames = [];
        colorNames.push("darkblue");
        colorNames.push("blue");
        colorNames.push("yellow");
        colorNames.push("orange");
        colorNames.push("green");
        colorNames.push("eggplant");
        colorNames.push("red");
        colorNames.push("brightblue");
        colorNames.push("brightgreen");
        colorNames.push("brightberry");
        colorNames.push("brightblue1");
        colorNames.push("brightgreen1");
        colorNames.push("brightberry1");

        /* Add SVG */
        let svg = d3.select(`#${chartId}`).append("svg")
            .attr("width", (width + margin) + "px")
            .attr("height", (height + margin) + "px")
            .attr("id", "historicalId")
            .append('g')
            .attr("transform", `translate(${margin}, ${margin})`);

        /* Add line into SVG */
        let line = d3.line()
            .x(d => xScale(d.date))
            .y(d => yScale(d.fav));

        let lines = svg.append('g')
            .attr('class', 'lines')
            .attr('stroke-width', "2")
            .attr('fill', "none");

        lines.selectAll('.line-group')
            .data(dataChart).enter()
            .append('g')
            .attr('class', 'line-group')
            .append('path')
            .attr('class', 'line')
            .attr('d', d => line(d.values))
            .style('stroke', (d, i) => color[i])
            .attr("stroke-dasharray", function (d) { if (pulseDataChart[d.name] == "Weeks") { return (3, 3) } })
            .style('opacity', lineOpacity)

        //Tooltip functionality
        let tooltip = d3.select(`#${chartId}`)
            .append('div')
            .attr('class', 'tooltip');

        tooltip.append('div')
            .attr('class', 'nameTool');

        /* Add circles in the line */
        lines.selectAll("circle-group")
            .data(dataChart).enter()
            .append("g")
            .style("fill", (d, i) => color[i])
            .selectAll("circle")
            .data(d => d.values).enter()
            .append("g")
            .attr("class", "circle")
            .append("circle")
            .attr("cx", d => xScale(d.date))
            .attr("cy", d => yScale(d.fav))
            .attr("r", circleRadius)
            .style('opacity', circleOpacity)
            .style("cursor", "pointer")
            .on("mouseover", function (i, d) {
                let htmlData = `<div style="min-width:170px;font-weight:600;font-size:14px;margin-bottom:10px;padding:2px;border-bottom:1px solid #b5b5b5">
                <span style="background:${color[surveyNameArr.indexOf(d.surveyname)]};width:24px;height:12px;margin-right:4px;padding-right:20px"></span>
                ${d.surveyname}</div>`
                htmlData += `<p style="padding:0px;margin:0px;display:flex;text-align:left;border-bottom:1px solid #EAEBED">
                            <span style="width:60%;padding-right:10px"> Favorable </span>
                            <span style="width:40%;text-align:right"> ${Math.round(d.fav)}%</span> 
                            </p> 
                            <p style="padding:0px;margin:0px;display:flex;text-align:left;border-bottom:1px solid #EAEBED">
                            <span style="width:60%;padding-right:10px"> Count </span>
                            <span style="width:40%;text-align:right;margin-right:9px">  ${d.count}</span> 
                            </p>`
                tooltip.select('.nameTool').html(htmlData);
                tooltip.style('display', 'block');
                tooltip.style('top', (window.event.layerY - 130) + 'px')
                tooltip.style('left', (window.event.layerX - 170) + 'px');
            })
            .on('mousemove', function (d) {
                tooltip.style('top', (window.event.layerY - 130) + 'px')
                tooltip.style('left', (window.event.layerX - 170) + 'px');
            })
            .on("mouseout", function (d) {
                tooltip.style('display', 'none');
            })


        /* Add Axis into SVG */
        let tickXaxis = newDateArr.length;
        let xAxis = d3.axisBottom(xScale).ticks(tickXaxis).tickFormat(function (date, index) {
            if (dateDispArr.indexOf(moment(new Date(date)).format("DD/MM/YYYY")) !== -1) {
                if (params.customerSurveys && params.customerSurveys[0] && params.customerSurveys[0].frequency === "Pulse" && params.customerSurveys.length === 1) {
                    let cSurveys = params.customerSurveys[0] ? params.customerSurveys[0] : "";
                    let pulseDate = cSurveys.pulse_obj ? cSurveys.pulse_obj : {}
                    let PulsePrd = pulseDate.period && pulseDate.period.frequencyType ? pulseDate.period.frequencyType : ""
                    if (PulsePrd === "Weeks") {
                        date.setDate(date.getDate() + 1);
                        return d3.timeFormat('%b')(date) + " " + d3.timeFormat('%d')(date) + " " + d3.timeFormat('%Y')(date);
                    } else {
                        return d3.timeFormat('%b')(date) + " " + d3.timeFormat('%Y')(date);
                    }
                } else if (params.customerSurveys && params.customerSurveys[0] && params.customerSurveys[0].frequency === "Pulse") {
                    return d3.timeFormat('%b')(date) + " " + d3.timeFormat('%Y')(date);
                } else {
                    date.setMonth(date.getMonth() - 1);
                    return d3.timeFormat('%b')(date) + " " + d3.timeFormat('%Y')(date);
                }
            }
            else {
                return ("")
            }
        });
        let yAxis = d3.axisLeft(yScale).ticks(10);
        svg.append("g")
            .attr("class", "x axis, axisgrey")
            .attr("transform", `translate(0, ${height - margin})`)
            .call(xAxis)
            .selectAll("text")
            .attr("transform", "rotate(30)")
            .attr("y", 12)
            .attr("x", 40)
            //.attr("y", 12)
            //.attr("x", 0)
            .attr("dy", ".70em");

        svg.append("g")
            .attr("class", "y axis, axisgrey")
            .call(yAxis)
            .append('text')
            .style("font-size", "13")
            .style("font-family", "Roboto")
            .attr("y", -(margin / 2))
            .attr("x", -((height - margin) / 2))
            .attr("transform", "rotate(-90)")
            .attr("fill", "#000")
            .text("% Favorable");

        let legend = svg.append("g")
            .attr("class", "legend")
            .style("padding-top", "10")
            .attr("height", 100)
            .attr("width", 100)
            .attr('transform', 'translate(0,' + (height - (margin * (1 / 2))) + ')')

        let legendusedwidth = 0;
        let legendusedwidthdup = 0
        if (surveyNameArr.length === 1) {
            legendusedwidth = legendWidth;
            legendusedwidthdup = legendWidth
        }
        else if (surveyNameArr.length === 2) {
            legendusedwidth = 0;
            legendusedwidthdup = 0
        }
        else if (surveyNameArr.length === 3) {
            legendusedwidth = -350;
            legendusedwidthdup = -350
        }
        else {
            legendusedwidth = -legendWidth;
            legendusedwidthdup = -legendWidth
        }
        let legendrow = 0;
        legend.selectAll('rect')
            .data(dataChart)
            .enter()
            .append("rect")
            .attr("x", function (d, i) {
                legendusedwidth = legendusedwidth + legendWidth
                if (legendusedwidth > (width - legendWidth))
                    legendusedwidth = 0
                return legendusedwidth
            })
            .attr("y", function (d, i) {
                legendusedwidthdup = legendusedwidthdup + legendWidth
                if (legendusedwidthdup > (width - legendWidth)) {
                    legendrow = legendrow + 1
                    legendusedwidthdup = 0
                }
                return (10 + (legendrow * 20))
            })
            .attr("width", 19)
            .attr("height", 11)
            .style("fill", function (d, i) {
                return color[i];
            })
            .style("cursor", "pointer")
            .on("click", function (i, d) {
                dataChart = JSON.parse(JSON.stringify(data))
                if (surveyhidden.indexOf(d.name) === -1) {
                    surveyhidden.push(d.name)
                    dataChart = dataChart.map((data) => {
                        if (surveyhidden.indexOf(data.name) !== -1) {
                            data.values = []
                        }
                        return data
                    })
                }
                else {
                    surveyhidden = surveyhidden.filter(data => data !== d.name)
                    dataChart = dataChart.map((data) => {
                        if (surveyhidden.indexOf(data.name) !== -1) {
                            data.values = []
                        }
                        return data
                    })
                }
                totChart();
            })
        if (surveyNameArr.length === 1) {
            legendusedwidth = legendWidth;
            legendusedwidthdup = legendWidth
        }
        else if (surveyNameArr.length === 2) {
            legendusedwidth = 0;
            legendusedwidthdup = 0
        }
        else if (surveyNameArr.length === 3) {
            legendusedwidth = -350;
            legendusedwidthdup = -350
        }
        else {
            legendusedwidth = -legendWidth;
            legendusedwidthdup = -legendWidth
        }
        legendrow = 0;
        legend.selectAll("text")
            .data(dataChart)
            .enter()
            .append("text")
            .style("font-size", "13px")
            .style("font-family", "Roboto")
            .style("cursor", "pointer")
            .attr("x", function (d, i) {
                legendusedwidth = legendusedwidth + legendWidth
                if (legendusedwidth > (width - legendWidth))
                    legendusedwidth = 0

                return legendusedwidth + 20
            })
            .attr("y", function (d, i) {
                legendusedwidthdup = legendusedwidthdup + legendWidth
                if (legendusedwidthdup > (width - legendWidth)) {
                    legendrow = legendrow + 1
                    legendusedwidthdup = 0
                }
                return (20 + (legendrow * 20))
            })
            .text(function (d) { return d.name })
            .on("click", function (i, d) {
                dataChart = JSON.parse(JSON.stringify(data))
                if (surveyhidden.indexOf(d.name) === -1) {
                    surveyhidden.push(d.name)
                    dataChart = dataChart.map((data) => {
                        if (surveyhidden.indexOf(data.name) !== -1) {
                            data.values = []
                        }

                        return data
                    })
                }
                else {
                    surveyhidden = surveyhidden.filter(data => data !== d.name)
                    dataChart = dataChart.map((data) => {
                        if (surveyhidden.indexOf(data.name) !== -1) {
                            data.values = []
                        }
                        return data
                    })
                }
                totChart();
            })

    }

    return <div id={chartId} className="selecttot"></div>
}

// default props
TotChart.defaultProps = {
    classes: {},
    data: [],
    pulseData: {},
    params: {},
    chartId: "totChart"
};
// prop types
TotChart.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.any,
    pulseData: PropTypes.any,
    params: PropTypes.any,
    chartId: PropTypes.string
};

export default TotChart;
