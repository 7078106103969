export const metadataConstant = {

  //GET METADATA NAME
  GET_METADATA_NAME_REQUEST:"GET_METADATA_NAME_REQUEST",
  GET_METADATA_NAME_SUCCESS:"GET_METADATA_NAME_SUCCESS",
  GET_METADATA_NAME_FAILURE:"GET_METADATA_NAME_FAILURE",

  //GET METADATA WITH RGI
  GET_METADATA_RGI_REQUEST:"GET_METADATA_RGI_REQUEST",
  GET_METADATA_RGI_SUCCESS:"GET_METADATA_RGI_SUCCESS",
  GET_METADATA_RGI_FAILURE:"GET_METADATA_RGI_FAILURE",

  //UPDATE METADATA
  METADATA_UPDATE_REQUEST: 'METADATA_UPDATE_REQUEST',
  METADATA_UPDATE_SUCCESS: 'METADATA_UPDATE_SUCCESS',
  METADATA_UPDATE_FAILURE: 'METADATA_UPDATE_FAILURE',
  
  //Get Client Metadata 
  GET_METADATA_REQUEST: 'GET_METADATA_REQUEST',
  GET_METADATA_SUCCESS: 'GET_METADATA_SUCCESS',
  GET_METADATA_FAILURE: 'GET_METADATA_FAILURE',

  //update single metadata field
  UPDATE_SINGLE_METADATA:"UPDATE_SINGLE_METADATA",

  // Update Metadata reducer
  UPDATE_METADATA_REDUCER: 'UPDATE_METADATA_REDUCER',

  //update metadata options
  UPDATE_METADATA_OPTIONS: 'UPDATE_METADATA_OPTIONS',
};
