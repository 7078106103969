import React from "react";
import { useSelector } from "react-redux";
// import CircularProgress from "@material-ui/core/CircularProgress";

// Import css
import "./PageLoaderComponent.css";
const loaderWrapperStyle = {
  position: "absolute",
  top: "0px",
  left: "0px",
  bottom: "0px",
  width: "100%",
  height: "100%",
  zIndex: 2000,
  // backgroundColor: "rgba(255, 255, 255, 0.85)",
  color: "#222"
};

const loaderTextStyle = {
  color: "rgb(255, 255, 255)",
  height: "100px",
  position: "absolute",
  width: "100px",
  margin: "auto",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};

const PageLoaderComponent = () => {
  const UI = useSelector((state) => state.UI);

  if (!UI.loading) { return (
    <form>
      <input type="hidden" name="virtualbufferupdate" id="virtualbufferupdate" value={0} />
    </form>
  ) }
  return (
    <div style={loaderWrapperStyle}>
      <div style={loaderTextStyle}>
        <div className="loader_container">
          {/* <div class="loader">
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__bar"></div>
            <div class="loader__ball"></div>
          </div>
          <div class="loadingtxt">Loading ...</div> */}
        </div>
        <form>
          <input type="hidden" name="virtualbufferupdate" id="virtualbufferupdate" value={1} />
        </form>
      </div>
    </div>
  );
};

export default PageLoaderComponent;
