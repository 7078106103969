import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Participant Service Functions
 */
export const excelService = {
  upload,
  download
};

/* Excel Upload
 */
async function upload(file, isMS = false, responseType) {
  const response = await apihelper.postRequest('excel/import', file, false, responseType, isMS);
  return response;
}

/* Excel download
 */
async function download(data, isMS = false, responseType) {
  try {
    const response = await apihelper.postRequest('excel/export', data, false, responseType, isMS);
    if (response.data && response.data.filePath) {
      const responseOne = await apihelper.postRequestExcel('excel/exportExcel', response.data, false, "arraybuffer", isMS);
      return responseOne;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e)
  }
}