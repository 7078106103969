/* eslint-disable curly */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import { useDispatch, useSelector } from "react-redux";

// Import Styles and Image
import { BackPage } from "../../../assets/images/svgComponents/ImageComponent";

import ParticipantStyles from "./PariticipantStyles";

// Import Constants
import { participantConstants, translateLanguagesList} from "../../../config/constants";
import { participantConstant, metadataConstant } from "../../../redux/constants";

// Import Actions
import { participantAction, updateStateReducerAction, metadataAction } from "../../../redux/actions";

// Import History
import history from "../../../config/history";

// Import Helpers
import { getReducerUpdatedData } from "../../../helpers";

// Import Containers
import Container from "../../Containers/Container";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import CancelDialogComponent from "../../../components/CancelDialogComponent/CancelDialogComponent";

// Import Panels
import DeafaultPanel from "./AddEditParticipants/DeafaultPanel";
import AssignedSurveyPanel from "./AddEditParticipants/AssignedSurveyPanel";
import OtherDemographicsPanel from "./AddEditParticipants/OtherDemographicsPanel";
import AutomatedDemographicsPanel from "./AddEditParticipants/AutomatedDemographicsPanel";

function AddParticipant (props) {
  // Assign Props
  const { classes, params } = props;
  // ref
  const formRef = useRef(null);

  // state
  const [actions, setActions] = React.useState({ isDelete: false, isCancel: false });
  const [isCalc, setCalc] = useState(false);

  // Define Selector
  const participant = useSelector((state) => state.participant.getOne);
  const { user } = useSelector((state) => state.profile);
  const { metadata } = useSelector((state) => state.metadata);
  const { metadataWithRGI } = useSelector((state) => state.metadata);
  const trasfloAccess = useSelector(state => state.client.getOne);

  // Define Dispatch
  const dispatch = useDispatch();

  /**
     * Fetch Participant Data
     */
  useEffect(() => {
    dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_INIT));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      // comp will unmount set init data
      dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_INIT));
    };
  }, []);

  /**
  * Handle Cancel
  */
  const handleCancel = () => {
    setActions({ ...actions, isCancel: true });
  };

  /**
     * On Handle Cancel & Delete, cancel
     */
  const initActions = () => {
    setActions({ isCancel: false, isDelete: false });
  };

  /**
     * On handle Cancel, No
     */
  const backToParticipantsList = () => {
    handleBack(false);
  };
  /**
     * On Handle Cancel, Save
     */
  const handleParticipantYes = () => {
    formRef.current.submit();
  };

  // convert date
  const convertDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    const finaldate = `${month}/${dt}/${year}`;
    return finaldate === "01/01/1970" ? "" : finaldate;
  };
  /**
     * Handle Change
     */
  const handleChange = (e, dataType, newOption = false) => {
    if (dataType === "date") {
      let valid = new Date(e.value) == "Invalid Date";
      if (!valid)
        e.value = convertDate(e.value);
    }
    
    // update lang code
    if (e.name === "languages") {
      let code = null
      let value = e.value
      const langCode = translateLanguagesList.filter(obj => (obj.name).toLowerCase() === e.value.toLowerCase())

      if(langCode.length > 0) {
        code = langCode[0].code
        value = langCode[0].name
      }

      e = {
        ...e,
        value: {
          code: code,
          name: value
        }
      };
    }
    
    if(newOption === true){
      let selMeta = metadataWithRGI.find((meta)=> meta.key === e.name);
      let oldMeta = metadataWithRGI.filter((meta)=> meta.key !== e.name);
      selMeta["rgi"] = [...selMeta.rgi, e.value];
      dispatch(updateStateReducerAction.update(metadataConstant.UPDATE_METADATA_OPTIONS, [...oldMeta, selMeta]));
    }
    
    const reducerData = getReducerUpdatedData(e, participant);
    dispatch(updateStateReducerAction.update(participantConstant.PARTICIPANT_GET_ONE_UPDATE, reducerData));
  };

  /**
    *Handle Back
    */
  const handleBack = (isReset = false) => {
      history.replace("/manage_people/participants?refresh=true&reset=true");
    // if(isReset)
    //   history.replace("/people/participants?refresh=true&reset=true");
    // else
    //   history.replace("/people/participants?refresh=true");
  };

  /**
     * Handle Submit
     */
  const handleSubmit = () => {
    let addOnData = ["state", "country"];
    addOnData.map(d => {
      metadata.filter(meta => meta.key === d).map(addOn => addOn.rgi && !addOn.rgi.includes(participant[addOn.key]) && addOn.rgi.push(participant[addOn.key]));
    });
    dispatch(metadataAction.updateMetadata({ metadata }, false));
    
    // add date fields to post method in order to convert and save in api
    participant.dateFields = metadata.filter(s => s.dataType === "date").map(m => m.key);
    
    //Convert String to Integer for number datatype 
    metadata.filter(s => s.dataType === "integer").map(m => {
      if(participant.hasOwnProperty(m.key) && participant[m.key]){
        participant[m.key] = parseFloat(participant[m.key])
      }
    });

    dispatch(participantAction.create({ ...participant, client_id: user.client_id._id }, () => {
      handleBack(true);
    }));
  };

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ValidatorForm
              ref={formRef}
              id="add_participant"
              name="Add Participant"
              autoComplete="off"
              onSubmit={handleSubmit}
              onError={(error) => {
                initActions();
                console.log(error);
              }}>
              <Grid item xs={12}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid container alignItems="center">
                      <Grid onClick={()=>handleBack(false)} className={classes.backimg}>
                        { BackPage() }
                      </Grid>
                      <TypographyComponent
                        variant={"h3"}
                        className="pl-1"
                        title={participantConstants.PARTICIPANT_ADD} />
                    </Grid>
                  </Grid>

                </Grid>
              </Grid>
              <div className={"container"}>

                {/* Deafault Panel */}
                <DeafaultPanel transfloAccess={trasfloAccess} metadata={metadata} participant={participant} handleChange={handleChange} classes={classes}></DeafaultPanel>

                {/* Assigned Survey Panel */}
                <AssignedSurveyPanel params = {params} setCalc = {setCalc} participant={participant} classes={classes}></AssignedSurveyPanel>

                {/* Automated Demographics panel */}
                <AutomatedDemographicsPanel metadataWithRGI = {metadataWithRGI} metadata={metadata} participant={participant} isCalc = {isCalc} handleChange={handleChange} classes={classes} />
               
                {/* Other Demographics Panel */}
                <OtherDemographicsPanel
                  metadataWithRGI = {metadataWithRGI}
                  participant={participant}
                  metadata={metadata}
                  handleChange={handleChange}
                  classes={classes}
                />
              </div>
              <Grid container justifyContent="flex-end" className={classes.footerBtns_container}>
                <Grid item>
                  <ButtonComponent
                    title={participantConstants.SAVE_BUTTON}
                    color={"primary"}
                    size={"small"}
                    type='submit'
                    pageClassName="mr-1"
                  ></ButtonComponent>
                  <ButtonComponent
                    title={participantConstants.CANCEL_BUTTON}
                    // color={"secondary"}
                    className={classes.cancelBtn}
                    size={"small"}
                    type={"button"}
                    handleClick={handleCancel}
                  ></ButtonComponent>
                </Grid>
              </Grid>
              <CancelDialogComponent
                open={actions.isCancel}
                constent={participantConstants.CANCEL_ALERT}
                handleCancelAction={initActions}
                handleOnNoAction={backToParticipantsList}
                handleOnYesAction={handleParticipantYes}
              />
            </ValidatorForm>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
export default withStyles(ParticipantStyles)(AddParticipant);
