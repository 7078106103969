/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Avatar from "@mui/material/Avatar";

// Import Styles and Image
import { BackPage, Download } from "../../../assets/images/svgComponents/ImageComponent";

import PariticipantStyles from "./PariticipantStyles";

// Import Table Size
import { getTableHeight, getTableRowSize } from "../../../config/layout";

// Import History
import history from "../../../config/history";

// Import Component
import TableComponent from "../../../components/TableComponent/TableComponent";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import Container from "../../Containers/Container";

// Import helper
import { getQueryVariable } from "../../../helpers";

// Import Actions
import { fileManagerAction } from "../../../redux/actions";

// import constants
import { participantConstants, excelConstants } from "../../../config/constants";
import { Tooltip } from "@mui/material";

const defaultSearchData = {
  search: {
    type: "Export",
    module: excelConstants.PARTICIPANT_DOWNLOAD
  }
}

// Action Buttons
const ExportIcon = ({ data, handleChange, classes }) => {
  const isDone = data.status === "DONE"
  const props = isDone ? { stroke: "#54c0fd" } : {}
  if (data.status === "ERROR") {
    return null
  } else {
    return (
      <div className={classes.tableactions}>
        <Tooltip title="Download" arrow>
          <Avatar
            alt="Setting"
            role="button"
            className={`tableIcon MuiAvatar-root MuiAvatar-circle ml5 mr5 mt3 scrollIntial cursor-pointer`}
            onClick={() => {
              if (isDone) {
                handleChange("EXPORT_SETTING", data, null)
              }
            }}
          >
            <Grid>{Download(props)}</Grid>
          </Avatar>
        </Tooltip>
      </div>
    );
  }
};

const actionComponent = {
  EXPORT: ExportIcon
};

const Exports = (props) => {
  // Define Dispatch
  const dispatch = useDispatch();

  // Assign Props
  const { classes, match, location } = props;

  // Table size
  let tableHeight = getTableHeight();
  tableHeight = tableHeight - 5;
  const tableRowSize = getTableRowSize();

  // Get Exports from store
  const { data, page } = useSelector(state => state.fileManager);

  /**
  * Reset all search text box to empty
  */
  let isPageReset = getQueryVariable(location.search, "reset") === "true";
  let isPageRefresh = getQueryVariable(location.search, "refresh") === "true";
  /**
   * Get Participants
   */
  useEffect(() => {
    if (!isPageRefresh) {
      const pageSkip = location.state && location.state.skip ? location.state.skip : 1
      let { sFields, sValues } = page;
      if (typeof sFields === 'undefined')
        sFields = []
      if (typeof sValues === 'undefined')
        sValues = []
      dispatch(fileManagerAction.getList(pageSkip, tableRowSize, "cdt", "desc", sFields, sValues, defaultSearchData));
    }
  }, [dispatch]);

  useEffect(() => {
    if (isPageReset) {
      history.replace(props.location.pathname)
      dispatch(fileManagerAction.getList(1, tableRowSize, "cdt", "desc", [], [], defaultSearchData));
    }
    if (isPageRefresh)
      history.replace(props.location.pathname)
  }, [isPageRefresh, isPageReset])

  useEffect(() => {
    return () => {
      dispatch(fileManagerAction.clearList())
    }
  }, [])

  /**
  * Handle Table pagination Event
  */
  const onChangePagination = (skip) => {
    dispatch(fileManagerAction.getList(skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues, defaultSearchData));
  };

  /**
  * Handle Table Sort Request Event
  */
  const onSortRequest = (sortBy, sort) => {
    dispatch(fileManagerAction.getList(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues, defaultSearchData));
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if (isPageRefresh)
      dispatch(fileManagerAction.getList(page.skip, tableRowSize, page.sortBy, page.sort, sFields, sValues, defaultSearchData));
    else
      dispatch(fileManagerAction.getList(1, tableRowSize, page.sortBy, page.sort, sFields, sValues, defaultSearchData));
  };

  /**
    * Handle Table Navigation Event
    */
  const handleNavigateAction = (id) => {
    history.push({
      pathname: `${match.url}/${id}/edit`,
      state: { skip: page.skip }
    });
  };

  /**
   * Handle Action Button Event
   */
  const handelActionButtonClick = (field, id, name) => {
    /**
     * TODO
     * handle action switch
     */
  };

  /**
  * Handle Field Component On Change Event
  */
  const fieldComponentOnChange = (field, data, value) => {
    switch (field) {
      case "EXPORT_SETTING":
        dispatch(fileManagerAction.downloadFile(data))
        break;
      case "DELETE_SETTING":
        handleTableDeleteAction(data._id)
        break;
      default:
    }
  };

  /** Delete Function*
  * @param {*} field
  * @param {*} id
  */
  const handleTableDeleteAction = (id) => {
    dispatch(fileManagerAction.deleteFile(id, () => {
      dispatch(fileManagerAction.getList(1, tableRowSize, page.sortBy, page.sort, [], [], defaultSearchData));
    }))
  };

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid onClick={() => history.push("/manage_people/participants")} className={classes.backimg}>
                    {BackPage()}
                  </Grid>
                  <Grid>
                  <TypographyComponent
                    variant={"h3"}
                    className="ml-1 desc">
                    {participantConstants.PARTICIPANT_EXPORTS}
                    {/* <span class="title-description">
                      Your exports will be available on this page for two weeks.  After this time, files are automatically removed.
                    </span> */}
                  </TypographyComponent>
                    <TypographyComponent variant="h6" align="center" className="ml-1">
                {"Your exports will be available on this page for two weeks.  After this time, files are automatically removed."}
                  </TypographyComponent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
              <TableComponent
                data={data}
                header={page.fieldFilters.headerCols}
                field={page.fieldFilters.fieldCols}
                search={page.fieldFilters.searchCols}
                actions={["EXPORT", "DELETE"]}
                // actions={["DELETE"]}
                height={tableHeight}
                page={page.skip}
                count={page.count}
                rowsPerPage={page.limit}
                links={[]}
                dateFields={page.fieldFilters.dateCols}
                sort={page.sort}
                sortBy={page.sortBy}
                sFields={page.sFields}
                sValues={page.sValues}
                noDataErrorMessage={"No Exports Found"}
                removedSortingRow={[]}
                moreInfoIcons={[]}
                columnsMap={{}}
                showLoading={false}
                prevNext={false}
                paginationVisible={!page.lastPage}
                handleNavigateAction={handleNavigateAction}
                onChangePagination={onChangePagination}
                onSortRequest={onSortRequest}
                onInputChangeRequest={onInputChangeRequest}
                fieldComponentOnChange={fieldComponentOnChange}
                handelActionButtonClick={handelActionButtonClick}
                handleTableDeleteAction={handleTableDeleteAction}
                actionComponent={actionComponent}
                resetSearchText={isPageReset}
                deleteContent={"Are you sure to delete the file?"}
              />
            </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};
export default withStyles(PariticipantStyles)(Exports);
