import React, {useEffect} from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import Surveys from "./Surveys";
import SurveysList from "./SurveysList";
import SurveysNewEdit from "./SurveysNewEdit";

import LiveChatComponent from "../../components/LiveChatComponent/LiveChatComponent";

function SurveyRouter(props) {
  const { user, match } = props;

  return (
    <>
      <Switch>
        <Route exact path={match.url} render={(props) => {
          return <Surveys
            match={match}
            user={user}
            {...props} />;
        }} />
        <Route exact path={`${match.url}/manage`} render={props => {
          return (
            <SurveysList
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
        <Route exact path={`${match.url}/new`} render={props => {
          return (
            <SurveysNewEdit
              page={0}
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
        <Route exact path={`${match.url}/:id`} render={props => {
          return (
            <SurveysNewEdit
              page={0}
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
        <Route exact path={`${match.url}/:id/questions`} render={props => {
          return (
            <SurveysNewEdit
              page={1}
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
        <Route exact path={`${match.url}/:id/settings`} render={props => {
          return (
            <SurveysNewEdit
              page={2}
              match={match}
              user={user}
              {...props}
            />
          );
        }} />
      </Switch>
    </>
  );
}

/**
 * Bind Component Property Types
 */
SurveyRouter.propTypes = {
  match: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default SurveyRouter;
