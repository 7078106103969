import React from 'react';
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { Grid, IconButton } from "@mui/material";

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";
import withStyles from '@mui/styles/withStyles';

// Constants
import { ReportsConstants } from "../../../../config/constants"

//Components
import DashboardGaugeComponent from "../../../../components/DashboardGaugeComponent/DashboardGaugeComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

function EngagementEmployeeWidget(props) {

    const { classes, data, historicalData, adminPreference } = props;
    const { overallPct, total, historicalData: historical, engageCount } = data
    const { historical_engagement, singleEngagement } = historicalData
    const { engagementComparison } = useSelector(state => state.client.getOne)

    let diffData = historical_engagement;
    let points = { indicationText: '' }
    if (singleEngagement && engagementComparison) {
        diffData = overallPct - singleEngagement;
        points['indicationText'] = 'since last survey'
    }

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.engagedEmployee === 'boolean') {
            adminPreference['data']['hideDashWidgets']['engagedEmployee'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return <Grid className={`${classes.dashboardTile} flexdirection`}>
        <Grid container>
            <Grid item className='dflexCenter width100 spacebetween'>
                <TypographyComponent variant='h5' className={'fontWeight600 width100 txtLeft'}>{ReportsConstants.ENGAGED_EMPLOYEE}</TypographyComponent>
                <ToolTipComponent title={ReportsConstants.CLOSE}>
                    <IconButton onClick={handleClose} size="large">
                        {DashboardClose()}
                    </IconButton>
                </ToolTipComponent>
            </Grid>
        </Grid>
        <Grid style={{ position: "relative", display: "flex", flexDirection: "column" }} className='height100' marginTop={'35px'} marginBottom={'20px'}>
            <DashboardGaugeComponent
                classes={classes}
                data={{ fillPercent: overallPct }}
                chartType={"Engaged"}
                responseCount={engageCount ? engageCount : 0}
                diff={diffData ? diffData : 0}
                historical={historical}
                showComparison={singleEngagement && engagementComparison ? true : false}
                isStartFromZero={true}
                points={points}
            />
        </Grid>
    </Grid >
}

// default props
EngagementEmployeeWidget.defaultProps = {
    classes: {},
    data: {},
    historicalData: {},
    adminPreference: {},
};

// prop types
EngagementEmployeeWidget.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    historicalData: PropTypes.object,
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(EngagementEmployeeWidget);
