import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../SurveyPreviewStyles";

//import constants
import { TakeSurveyConstants } from "../../../../config/constants";

// Import Logo
import SuccessIcon from "../../../../assets/images/svg/takesurvey_success.svg";
import { surveyPreviewAction } from "../../../../redux/actions";

//import helper
import { linkIfy } from "../../../../helpers/appHelper";

function ThankYouComponent(props) {
  const { classes, type, refresh } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    if (refresh) {
      setTimeout(() => {
        dispatch(surveyPreviewAction.previewReset());
      }, 5000);
    }
  }, [dispatch]);
  const survey = useSelector((state) => state.surveyPreview.getOne);
  const langCode = useSelector((state) => state.surveyPreview.surveyLang);

  const thanksHeader = survey.thanks_header ? (survey.thanks_header[langCode] && survey.thanks_header[langCode] !== "" ? survey.thanks_header[langCode] : survey.thanks_header["en"]) : TakeSurveyConstants.SURVEY_THANKS_PAGE_MESSAGE;
  const thanksDesc = survey.thanks_description ? (survey.thanks_header[langCode] && survey.thanks_description[langCode] !== "" ? survey.thanks_description[langCode] : survey.thanks_description["en"]) : TakeSurveyConstants.SURVEY_THANKS_PAGE_DESCRIPTION;

  return (
    <>
      <Grid
        container
        className={refresh ? classes.thankYouRoot : classes.thankYouRootFull}
      >
        <Grid item sm={12} className={classes.thankYouContainer}>
          <span className={classes.gridIcon}>
            <img
              src={SuccessIcon}
              onClick={() => { }}
              alt={thanksHeader}
            />
          </span>
          <span className={classes.textThankYou} dangerouslySetInnerHTML={{ __html: linkIfy(thanksHeader) }} />
          {/* {type && type === "feedBack" ? ( */}
            <span className={classes.textDescription}>
              <div dangerouslySetInnerHTML={{ __html: linkIfy(thanksDesc) }} />
            </span>
          {/* ) : null} */}
        </Grid>
      </Grid>
    </>
  );
}

// default props
ThankYouComponent.defaultProps = {
  classes: {},
  types: "",
  refresh: true,
};

// prop types
ThankYouComponent.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  refresh: PropTypes.bool,
};
export default withStyles(SurveyPreviewStyles)(ThankYouComponent);
