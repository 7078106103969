import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import queryString from "query-string";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../Surveys/SurveyPreview/SurveyPreviewStyles";

// Import Preview Survey Components
import TakeSurvey from "../Surveys/SurveyPreview/PreviewTakeSurvey/TakeSurvey";
// import TakeSurvey from "./TakeSurvey";
import FeedBackComponent from "../Surveys/SurveyPreview/PreviewTakeSurvey/FeedBackComponent";
import ThankYouComponent from "../Surveys/SurveyPreview/PreviewTakeSurvey/ThankYouComponent";

// Import Actions
import { surveyPreviewAction } from "../../redux/actions";

//import constants
import { TakeSurveyConstants } from "../../config/constants";

function TakeSurveyPreview(props) {
  //get props
  const { match, location } = props;
  const qryString = queryString.parse(location.search);

  const surveyId = match.params.survey_url;
  const previewSurveySubmit = useSelector((state) => state.surveyPreview.previewSurveySubmit);
  const surveyFeedBackMail = useSelector((state) => state.surveyPreview.surveyFeedBackMail);
  const survey = useSelector((state) => state.takeSurvey.getOne);
  const langCode = useSelector((state) => state.takeSurvey.surveyLang);

  // Define Dispatch
  const dispatch = useDispatch();

  //Get Survey
  useEffect(() => {
    if (!previewSurveySubmit) {
      dispatch(surveyPreviewAction.getSurvey(surveyId, qryString.search));
    }
  }, [dispatch, previewSurveySubmit]);

  const thanksHeader = survey.thanks_header && survey.thanks_header[langCode] ? survey.thanks_header[langCode] : TakeSurveyConstants.THANKS_MESSAGE_HEADER;
  const thanksDesc = survey.thanks_description && survey.thanks_description[langCode] ? survey.thanks_description[langCode] : TakeSurveyConstants.THANKS_MESSAGE_DESCRIPTION;

  return <>{!previewSurveySubmit ? <TakeSurvey {...props} /> : !surveyFeedBackMail ? <FeedBackComponent {...props} /> : <ThankYouComponent thanksDesc={thanksDesc} thanksHeader={thanksHeader} {...props} type="feedBack" refresh={false} />}</>;
}
// default props
TakeSurveyPreview.defaultProps = {
  classes: {},
};

// prop types
TakeSurveyPreview.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(TakeSurveyPreview);
