import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';

import Img404 from "../../assets/images/svg/404.svg";
const useStyles = makeStyles({
  root: {
    position: "relative",
    textAlign: "center",
    padding: "100px",
    height: "100%"
  }
});

function Page404 () {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="height100"
      >
        <Grid item xs>
          <img className="mb-3" src={Img404} alt="page 404"></img>
          <Typography variant="h2" className="mb-2 txtBold">
            Page not found
          </Typography>

          <Typography variant="h4">
            Maybe the page you are looking for has been removed, or you typed in the wrong URL
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default Page404;
