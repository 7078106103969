import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "./SurveyPreviewStyles";

//import Components
import PreviewTakeSurvey from "./PreviewTakeSurvey/PreviewTakeSurvey";
import PreviewFilter from "./PreviewFilter/PreviewFilter";

//import Actions
// Import Actions
import { surveyPreviewAction } from "../../../redux/actions";

function SurveyPreviewComponent(props) {
  const { classes } = props;
  // Define Dispatch
  const dispatch = useDispatch();
  const surveyId = useSelector((state) => state.survey.previewSurveyId);
  //Get Survey
  useEffect(() => {
    dispatch(surveyPreviewAction.getSurvey(surveyId));
  }, [dispatch]);

  return (
    <>
      <Grid container className={classes.previewRoot}>
        <Grid item sm={12}>
          <Box display="flex" p={0} flexWrap="nowrap">
            <Box flexGrow={8} className={classes.sectionSurvey}>
              <PreviewTakeSurvey {...props} />
            </Box>
            <Box flexGrow={2} p={1.5} className={classes.sectionFilter}>
              <PreviewFilter {...props} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

// default props
SurveyPreviewComponent.defaultProps = {
  classes: {},
};

// prop types
SurveyPreviewComponent.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(SurveyPreviewComponent);
