import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "./TakeSurveyStyles";
//import constants
import { TakeSurveyConstants } from "../../config/constants";

//import helper
import { linkIfy } from "../../helpers/appHelper";
// Import Logo
// import SuccessIcon from "../../assets/images/svg/takesurvey_success_animtaed.gif";
import SuccessIcon from "../../assets/images/svg/takesurvey_success-message.gif";

function ThankYouComponent(props) {
  const { classes, match } = props;
  const langCode = useSelector((state) => state.takeSurvey.surveyLang);
  const survey = useSelector((state) => state.takeSurvey.getOne);
  const { params } = match;

  //If Sp Caller Survey
  if (params.caller_id) {
    setTimeout(() => {
      window.close();
    }, 1500);
  }

  const thanksHeader = survey.thanks_header ? (survey.thanks_header[langCode] && survey.thanks_header[langCode] !== "" ? survey.thanks_header[langCode] : survey.thanks_header["en"]) : TakeSurveyConstants.THANKS_MESSAGE_HEADER;
  const thanksDesc = survey.thanks_description ? (survey.thanks_description[langCode] && survey.thanks_description[langCode] !== "" ? survey.thanks_description[langCode] : survey.thanks_description["en"]) : "";

  return (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.thankYouAnimationContainer}>
          <img src={SuccessIcon} onClick={() => { }} alt={TakeSurveyConstants.SURVEY_THANKS_PAGE_MESSAGE} />
          <div className={`scrolly ${classes.thankYouAnimationMessage}`}>
            <div className="thanksHeader" dangerouslySetInnerHTML={{ __html: linkIfy(thanksHeader) }} />
            <div className="thanksDescription" dangerouslySetInnerHTML={{ __html: linkIfy(thanksDesc) }}/>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

// default props
ThankYouComponent.defaultProps = {
  classes: {},
  thanksHeader: "Thank You",
  thanksDesc: "Your feedback has been received.",
};

// prop types
ThankYouComponent.propTypes = {
  classes: PropTypes.object,
  thanksHeader: PropTypes.string,
  thanksDesc: PropTypes.string
};
export default withStyles(TakeSurveyStyles)(ThankYouComponent);
