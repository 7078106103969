/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Tooltip from "@mui/material/Tooltip";

// Import Containers
import AddNewAnswerSet from "./AddNewAnswerSet";
import EditAnswerSetName from "./EditAnswerSetName";

// Import Actions
import { alertAction } from "../../../../../redux/actions";

// import images
import { closeSurvey } from "../../../../../assets/images/svgComponents/ImageComponent";

// Import Components
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";

// Import Constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

function AnswerSetTabs(props) {
  const dispatch = useDispatch();
  // Get Props Values
  const { classes, activeAnswerSetTab, answerData, langcode, tabarray, answerSet, responseDetails } = props;

  const [removeAnswerSetDialog, setRemoveAnswerSetDialog] = React.useState({ flag: false, ind: "", value: "" });

  // pass State Value for Tabs
  const handleChange = (event, value) => {
    props.changeTabValue(value);
  };

  // Set Id and Controls for tabs
  function changeAnswerSetTab(index) {
    return {
      id: `answerset-tab-${index}`,
      "aria-controls": `answerset-Tab-Panel-${index}`
    };
  }

  const changeActiveTab = (e) => {
    props.changeTabValue(e);
  };
  /**
   * Delete Answer Set
   * @param {*} panel
   */

  const handleDeleteAnswerSet = (index, value) => {
    setRemoveAnswerSetDialog({ flag: false, ind: "", value: "" });
    if (responseDetails.sets.indexOf(value) !== -1) {
      dispatch(alertAction.error("cannot delete, response has been collected"));
    } else {
      const sets = answerData;
      delete sets[value];
      tabarray.splice(index, 1);
      props.changeTabValue(0);
      props.handleDeleteAnswerSet({ sets, tabarray });
    }
  };

  return (

    <React.Fragment>
      {tabarray &&
        <div>
          <AppBar
            position="static"
            color="default"
            className={`${classes.rightPanelAppBarRoot
              } ${"tabborder_none pe_color_appbar"}`}
          >
            <Tabs
              value={activeAnswerSetTab}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              component="div"
              className={classes.rightPanelTabsRoot}
              aria-label="answerset-tab"
            >
              {tabarray.map((item, index) => (
                <Tab
                  key={index}
                  component="div"
                  color={"primary"}
                  classes={{
                    root: classes.rightPanelTabRoot,
                    selected: classes.rightPanelTabSelected
                  }}

                  label={
                    <span className={"dflex"}>
                      <Tooltip arrow title={item}><span className={classes.tabcharlimit}>{item}</span></Tooltip>

                      {langcode === "en" && item !== "Default" && item !== "1-5 agreement scale" &&
                        <span className={classes.quest_TabEditClose}>
                          <EditAnswerSetName
                            classes={classes}
                            sets={answerData}
                            tabarray={tabarray}
                            answerSet={answerSet}
                            activeAnswerSetTab={index}
                          ></EditAnswerSetName>
                          <div className={`${"pl5 pt5 peTabDelete"}`} onClick={() => { setRemoveAnswerSetDialog({ flag: true, ind: index }); }}>{closeSurvey()}</div>
                        </span>
                      }
                    </span>
                  }
                  {...changeAnswerSetTab(index)}
                />
              ))}
              <span className={"dflex"}>
                {tabarray.length < 5 && responseDetails.question_ids.length < 1 && langcode === "en" &&
                  <AddNewAnswerSet
                    classes={classes}
                    answerData={answerData}
                    tabarray={tabarray}
                    answerSet={answerSet}
                    changeActiveTab={(e) => changeActiveTab(e)}
                  ></AddNewAnswerSet>
                }
              </span>
            </Tabs>
          </AppBar>
          {removeAnswerSetDialog.ind !== "" &&
            <Dialog
              open={removeAnswerSetDialog.flag}
              onClose={""}
            >
              <DialogContent>{surveyQuestionsConstants.ANSWER_SET_DELETE_DIALOG_MESSSAGE}</DialogContent>
              <DialogActions>
                <ButtonComponent
                  title={"Yes"}
                  color={"primary"}
                  size={"small"}
                  handleClick={() => { handleDeleteAnswerSet(removeAnswerSetDialog.ind, removeAnswerSetDialog.value); }}
                  componentClassName={"marginLR5"}
                  type="submit"
                ></ButtonComponent>
                <ButtonComponent
                  title={"No"}
                  color={"secondary"}
                  size={"small"}
                  handleClick={() => { setRemoveAnswerSetDialog({ flag: false, ind: "", value: "" }); }}
                  componentClassName={"marginLR5"}
                ></ButtonComponent>
              </DialogActions>
            </Dialog>
          }
        </div>
      }
    </React.Fragment>
  );
}

// default props
AnswerSetTabs.defaultProps = {
  classes: {},
  answerData: {},
  activeAnswerSetTab: 0,
  langcode: "en",
  tabarray: [],
  answerSet: {},
  responseDetails: {}
};

// prop types
AnswerSetTabs.propTypes = {
  classes: PropTypes.object,
  activeAnswerSetTab: PropTypes.number,
  answerData: PropTypes.object,
  langcode: PropTypes.string,
  tabarray: PropTypes.array,
  answerSet: PropTypes.object,
  responseDetails: PropTypes.object
};

export default AnswerSetTabs;
