import { dragdropConstant } from "../constants/dragdropConstant";

const initialState = {
  drag: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case dragdropConstant.DRAG:
      return {
        ...state,
        drag: true
      };
    case dragdropConstant.DROP:
      return {
        ...state,
        drag: false
      };
    default:
      return {
        ...state,
        drag: false
      };
  }
}
