import moment from 'moment';
import _ from "lodash";
/* eslint-disable prefer-const */
import { operatorsNameMap } from "./operators";

function isJsonString(str) {
  let valid = false;
  try {
    valid = JSON.parse(str);
  } catch (e) {
    return false;
  }
  return valid;
}

// replacing the operator to equalent one
// removing eq and ne operators
function replaceOperators(operator, value) {
  switch (operator) {
    case "$eq":
      return { rOperator: "in", rValue: [value] };
    case "$ne":
      return { rOperator: "notIn", rValue: [value] };
    default:
      return { rOperator: operatorsNameMap[operator], rValue: value };
  }
}

// date convert from YYYY-MM-DD to MM/DD/YYYY
function convertDate(value) {
  // if date
  if (value && `${value}`.split("-").length === 3) {
    value = moment(value).isValid() ? moment(value).format("MM/DD/YYYY") : value;
  }

  return value;
}

export function formatJSONQuery(mongoQuery) {
  const combinators = { $and: "and", $or: "or" };
  const querySet = isJsonString(mongoQuery);
  // return if in vlaid json query string
  if (!querySet) {
    return {};
  }

  const mapRule = rule => {
    const field = Object.keys(rule)[0];
    // if more than one operator means between operator { generation: { $gte: 5 }}
    const keys = Object.keys(rule[field]);
    let operator = "";
    let value = "";
    if (keys.length === 1) {
      const { rOperator, rValue } = replaceOperators(keys[0], rule[field][keys[0]]);
      operator = rOperator;
      value = convertDate(rValue);
    }  else if (keys.length > 0) {
      operator = "between";
      value = {
        value1: convertDate(rule[field][keys[0]]),
        value2: convertDate(rule[field][keys[1]])
      };
    }

    return {
      field,
      value,
      operator
    };
  };

  // used to get the list of field name values for update / edit scenario
  let fieldNames = [];
  let hadEmptySet = false;

  const mapRuleSet = ruleSet => {
    const combinator = Object.keys(ruleSet)[0];
    if (Array.isArray(ruleSet[combinator])) {
      let sRules = [{}];
      if (ruleSet[combinator].length > 0) {
        sRules = ruleSet[combinator].map(rule => {
          const keyName = Object.keys(rule)[0];
          // has object
          if (keyName) {
            if (Array.isArray(rule[keyName])) {
              return mapRuleSet(rule);
            } else {
              const ruleData = mapRule(rule);
              // for update / edit scenario
              if (ruleData && fieldNames.indexOf(ruleData.field) < 0) {
                fieldNames.push(ruleData.field);
              }
              return mapRule(rule);
            }
          } else {
            hadEmptySet = true;
            return {}; // empty object
          }
        })
      }

      if (ruleSet[combinator].length === 0) {
        hadEmptySet = true;
      }

      return {
        combinator: combinators[combinator],
        rules: sRules
      }
    } else {
      return {};
    }
  };

  return { fieldNames, queryJSON: mapRuleSet(querySet), hadEmptySet };
}

export function deFormatQuery(mongoQuery) {
  const { fieldNames, queryJSON, hadEmptySet } = formatJSONQuery(mongoQuery);
  return { fieldNames, queryJSON, hadEmptySet };
}

export function getDefaultRow(fieldName) {
  // set default row
  const defaultJSONQuery = {
    $and: [
      {
        // [fieldName]: { $in: [] }
      }
    ]
  };
  const { fieldNames, queryJSON } = deFormatQuery(JSON.stringify(defaultJSONQuery));
  return {
    defaultJSON: queryJSON,
    fieldNames
  };
};

let destructQueryValues = {
  'dla': {},
  'query': {},
}

const destructQuery = (currentLevel, prevLevel = '', type) => {
  if (currentLevel?.constructor === Array) {
    if (currentLevel.length === 0) {
      return false;
    }

    if (currentLevel.some(level => level?.constructor === Object)) {
      for (let i = 0; i < currentLevel.length; i++) {
        destructQuery(currentLevel[i], prevLevel, type);
      }
    } else {
      const storeLevel = {};
      const presentkeys = Object.keys(destructQueryValues[type]);
      const currentKey =
        presentkeys.indexOf(prevLevel) !== -1 ?
          `${prevLevel}__${presentkeys.filter(key => key.startsWith(prevLevel)).length}`
          : prevLevel;
      storeLevel[currentKey] = currentLevel;

      destructQueryValues[type] =
        Object.keys(destructQueryValues[type]).length > 0 ?
          { ...destructQueryValues[type], ...storeLevel }
          : storeLevel;
    }
  } else if (currentLevel?.constructor === Object) {
    const currentKeys = Object.keys(currentLevel);
    const isNumberOrDateType = _.intersection(currentKeys, ['$gte', '$lte']).length > 0;
    const currentLevelKey = `${prevLevel}__${currentKeys[0]}`;

    if (currentKeys.length === 0) {
      return false;
    }

    if (isNumberOrDateType) {
      const storeLevel = {};
      let currentKey = prevLevel;

      if (_.isEqual(currentKeys, ['$gte', '$lte'])) {
        currentKey += `__between`;
        storeLevel[currentKey] = Object.values(currentLevel);
      } else if (currentKeys.length === 1 && ['$gte', '$lte'].indexOf(currentKeys?.[0] !== -1)) {
        currentKey += `__${currentKeys?.[0].replaceAll('$', '')}`;
        storeLevel[currentKey] = Object.values(currentLevel);
      }

      destructQueryValues[type] =
        Object.keys(destructQueryValues[type]).length > 0 ?
          { ...destructQueryValues[type], ...storeLevel }
          : storeLevel;
    } else if (currentKeys.length === 1) {
      const prevLevelGenerator =
        prevLevel !== '' && currentKeys[0] !== '0' ?
          currentLevelKey :
          prevLevel === '' ?
            currentKeys[0] :
            prevLevel;

        if (currentLevel[currentKeys[0]]?.constructor === Object || currentLevel[currentKeys[0]]?.constructor === Array) {
        destructQuery(currentLevel?.[currentKeys] ? currentLevel[currentKeys] : null, prevLevelGenerator, type);
      }
    }
  }

  return false;
}

const compareQueries = (dla, query) => {
  const dlaKeys = (Object.keys(dla) || []);
  const queryKeys = (Object.keys(query) || []);
  let tempStatus = true;

  if (_.every(dlaKeys, key => _.includes(queryKeys, key))) {
    for (let i = 0; i < Math.max(dlaKeys.length, queryKeys.length); i++) {
      const currentKey = dlaKeys.length > queryKeys.length ? dlaKeys?.[i] : queryKeys?.[i];
      const dlaValue = dla?.[currentKey] ? dla[currentKey] : [];
      const queryValue = query?.[currentKey] ? query[currentKey] : [];
      // Compare types
      if (dlaValue?.constructor !== queryValue?.constructor) {
        tempStatus = true;
        break;
      }

      // If Array check whether dlaValues present in queryValues
      if (dlaValue?.constructor === Array && queryValue.length > 0) {
        const diffValue = _.difference(dlaValue, queryValue);
        const intersectValue = _.intersection(dlaValue, queryValue);
        const diffCondition = _.every(diffValue, diffVal => _.includes(dlaValue, diffVal));

        if (dlaValue.length > queryValue.length && _.every(_.intersection(dlaValue, queryValue), dla => _.includes(queryValue, dla)) && diffCondition) {
          if (!_.every(diffValue , val => _.includes(queryValue, val)) && _.every(diffValue, val => _.includes(dlaValue, val)) && intersectValue.length > 0) {
            tempStatus = false;
          }
        } else if (dlaValue.length < queryValue.length && (_.every(queryValue, val => _.includes(dlaValue, val)) || diffCondition)) {
          if (dlaValue.length > 0 && queryValue.length) {
            tempStatus = true;
            break;
          }
        } else if (dlaValue.length === queryValue.length && intersectValue.length === dlaValue.length && intersectValue.length === queryValue.length) {
          tempStatus = false;
        } else {
          tempStatus = true;
          break;
        }
      }

      // If it's not array and object, check the values same or not
      if (dlaValue?.constructor !== Array && dlaValue?.constructor !== Object && dlaValue !== queryValue) {
        tempStatus = false;
        break;
      }
    }
  }
  return tempStatus;
}

export function compareBetweenQueries(dla, query) {
  const parsedDla = (dla ? JSON.parse(dla) : '');
  const parsedQuery = (query ? JSON.parse(query) : '');
  let emptyCheckFlag = false;

  if (dla) {
    destructQueryValues['dla'] = {};
    destructQuery(parsedDla, '', 'dla');
  } else {
    emptyCheckFlag = true;
  }

  if (query) {
    destructQueryValues['query'] = {};
    destructQuery(parsedQuery, '', 'query');
  } else {
    emptyCheckFlag = true;
  }
  if (emptyCheckFlag) {
    return false;
  }
  return (destructQueryValues['dla'] && destructQueryValues['query'] && Object.keys(destructQueryValues['dla']).length > 0 && Object.keys(destructQueryValues['query']).length > 0) ?
    compareQueries(destructQueryValues['dla'], destructQueryValues['query'])
    : false;
}