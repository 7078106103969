import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Link from "@mui/material/Link";
// import MenuItem from "@mui/material/MenuItem";
// import Select from "@mui/material/Select";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextEditor from "../../../../components/TextEditor";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";

import { Plus_blue, TrashSurvey } from "../../../../assets/images/svgComponents/ImageComponent";
import { updateStateReducerAction, contentLibraryAction, alertAction } from "../../../../redux/actions";
import { contentLibraryConstant } from "../../../../redux/constants";
// import { contentLibraryConstants } from "../../../config/constants"


import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import { getReducerUpdatedData } from "../../../../helpers";


function AddRecomendation(props) {

    const { classes, item, questions, index, addActionAlert } = props;
    const dispatch = useDispatch();


    let recommendationNewData = {}
    if (questions && item) {
        recommendationNewData = item
    } else {
        recommendationNewData = addActionAlert
    }

    const handleNewRecommendation = () => {
        recommendationNewData.actionItems.Default = recommendationNewData.actionItems && recommendationNewData.actionItems.Default ? recommendationNewData.actionItems.Default : [];
        recommendationNewData.actionItems.Default = [...recommendationNewData.actionItems.Default, " "]
        props.editRecommendationData(recommendationNewData)

    }

    const handleChangeAct = (actItems,deletedItems,index) => {
        const updatedAcc = { ...recommendationNewData };
        updatedAcc["actionItems"]["Default"] = actItems;
        updatedAcc["actionItems"]["isDeleted"] = deletedItems;
        props.editRecommendationData(updatedAcc)
    };

    const saveQuestion = () => {
        // on save close edit
        props.updateQuestionFlag(true);
    };

    const closeQuestion = () => {
        // set / remove editable false for that action alert
        questions.filter((qusText, ind) => {
            if (qusText.baseQusId === item.baseQusId) {
                questions[ind].editable = false;
            }
            return true
        });
        props.updateQuestionFlag(false)
    };

    return (
        <Grid className={` ${classes.TopicExpand} width100`}>
            <Grid container className="p15">
                <Grid item className={"pl-1 pr-2 pt-2"}>
                    <Badge
                        color="secondary"
                        className={`${classes.Count_badge} badgeextrabig`}
                        badgeContent={item.baseQusId}
                        max={400}
                    ></Badge>
                </Grid>
                <Grid item className={`pr-1 pl-1 ${classes.addRecomendation_body}`} >
                    <Grid item sm={12} className="dflex mb-1">
                        <Grid item className={"mr5 width20"}>
                            <TypographyComponent
                                className={`txtgrey ${classes.recommendation_Addquestion_card}`}
                                variant={"h6"}>
                                {recommendationNewData ? recommendationNewData.category.Default.en.name : ""}
                            </TypographyComponent>
                        </Grid>
                        <Grid item className={"width80"}>
                            <TypographyComponent
                                className={classes.recommendation_Addquestion_card}
                                variant={"h6"}>
                                {recommendationNewData ? recommendationNewData.qusText.Default.en : ""}
                            </TypographyComponent>
                        </Grid>
                        {/* <Grid item className={"width20"}>
                            <Select
                                className={`${classes.baseQusId_select} mr5`}
                                value={"Both"}
                                disableUnderline={true}
                                placeholder={"Base question ID"}
                                // onChange={(e) => { handleBaseQusIdChange(e) }}
                            >
                                <MenuItem disabled value="Base question ID">
                                    {"Base question ID"}
                                </MenuItem>
                                {contentLibraryConstants.RECOMMENDATIONS_TYPES.map((item, keyIndex) => (
                                    <MenuItem
                                        key={keyIndex}
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid> */}
                    </Grid>
                    {recommendationNewData.actionItems.Default && recommendationNewData.actionItems.Default.map((actions, i) => (
                        <Grid item sm={12} className="mb-1 pt5 relative">
                            <TextEditor
                                height={"200px"}
                                data={actions ? actions : ""}
                                onChange={(event, editor) => {
                                    const actionItems = [...recommendationNewData["actionItems"]["Default"]];
                                    const deletedItems = recommendationNewData["actionItems"]["isDeleted"] !== undefined ? [...recommendationNewData["actionItems"]["isDeleted"]] : [];
                                    actionItems[i] = editor.getData();
                                    handleChangeAct(actionItems,deletedItems, index);
                                }}
                            />
                            <Grid item className={classes.actionItem_DeleteIcon}>
                                <Tooltip arrow title="Delete">
                                    <IconButton
                                        color="inherit"
                                        className="p5 icon-hover ImgActiveOrange"
                                        onClick={(e) => {
                                            const actionItems = [...recommendationNewData["actionItems"]["Default"]];
                                            const deletedItems = recommendationNewData["actionItems"]["isDeleted"] !== undefined ? [...recommendationNewData["actionItems"]["isDeleted"]] : [];
                                            deletedItems.push(actionItems[i])
                                            actionItems.splice(i, 1);
                                            handleChangeAct( actionItems,deletedItems,index);
                                        }}
                                        size="large">
                                        {TrashSurvey()}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item sm={12} className={"p5 dflex spacebetween"}>
                        <Grid item>
                            <Link
                                onClick={handleNewRecommendation}
                                className={`${classes.content_linkimg}${" cursor-pointer"}`}
                                color="primary">
                                <IconButton color="inherit" className={"p-0"} size="large">{Plus_blue()}</IconButton>
                                Add Another
                            </Link>
                        </Grid>
                        <Grid item>
                            <ButtonComponent
                                title={"OK"}
                                color={"primary"}
                                size={"small"}
                                handleClick={() => saveQuestion()}
                                pageClassName={classes.settings_addrulebtn}
                            ></ButtonComponent>
                            <ButtonComponent
                                title={"Cancel"}
                                color={"secondary"}
                                size={"small"}
                                handleClick={() => closeQuestion()}
                                pageClassName="ml-1"
                            ></ButtonComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AddRecomendation;

