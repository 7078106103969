import React ,{ useEffect } from "react";
import { useDispatch , useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Import Components
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";
import SwitchComponent from "../../../../../components/SwitchComponent/SwitchComponent";
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";
import MultiDatePickerComponent from "../../../../../components/MultiDatePickerComponent/MultiDatePickerComponent";
import TimePickerComponent from "../../../../../components/TimePickerComponent/TimePicker";

// constants
import { surveySettingsConstants } from "../../../../../config/constants";
import { surveyConstant } from "../../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, surveyAction, alertAction } from "../../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData, isTranslated, iseditedLangtxt } from "../../../../../helpers";

function Transflo(props) {
  const { classes, data, langcode, surveyPermission, translatedFields } = props;
  const dispatch = useDispatch();

  const {initial_notification} = useSelector((state) => state.survey);

  if (data.distribute && data.distribute.audience === undefined) {
    data.distribute.audience = {
      select: ""
    };
  }

  /**
   * Handle Radio Group Change Event
   * @param {*} panel
   */

  const radiogrouphandlechange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };

  /**
  * Handle Text area Change Event
  * @param {*} panel
  */
  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value, fieldName: "transflo_message_body" });
  };

  /**
  * Get Multi Select Date Values
  * @param {*} name
  * @param {*} value
  */
  const handleMultiDateSelect = (name, value) => {
    if (data._id && !data.isLoading) {
      const reducerData = getReducerUpdatedData({ name, value }, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  // Handle Auto Complete
  const handleAutoCompolete = (value, name) => {
    handleChange({ name, value });
  };

  /**
      * Handle Change Event
      * @param {*} panel
      */
  const handleChange = (event) => {
    if (data._id && !data.isLoading) {
      if(event.name=== "data.name_access"){
        data.name_access = event.value
      }
      if (event.name === "distribute.notification.transflo.schedule.type" && event.value === data.distribute.notification.transflo.schedule.type) {
        event["value"] = "";
      }
      iseditedLangtxt(translatedFields, langcode, event.fieldName)
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
   * Send Transflo Notifications to participants
   */
  const handleSendTransfloBtnClick = () => {
    if (data.status === surveySettingsConstants.SURVEY_STATUS_OPEN) {
      dispatch(surveyAction.sendSurveyTransflo({ _id: data._id, transfloContent: data.distribute.notification.transflo }));
    }
    else {
      dispatch(alertAction.error(surveySettingsConstants.SMS_EMAIL_TRANSFLO_SEND_ERROR));
    }
  };

  useEffect(()=>{
    if(data.distribute.audience.select === "anonymous"){
      data.distribute.notification.transflo.active = false
    } else if (surveyPermission.transfloAccess) { 
      data.distribute.notification.transflo.active = initial_notification?.notification?.transflo?.active
    }
  },[data.distribute.audience.select])

  return (
    <Grid item sm={12}>
      <Grid item md={12} xs={12} className="mb-2">
        <SwitchComponent
          name="distribute.notification.transflo.active"
          checked={surveyPermission.transfloAccess ? data.distribute.notification.transflo.active : surveySettingsConstants.CHECKED_FALSE}
          disabled={(surveyPermission.transfloAccess && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? surveySettingsConstants.CHECKED_FALSE : surveySettingsConstants.CHECKED_TRUE}
          color={(surveyPermission.transfloAccess && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? "primary" : "default"}
          handleChange={(e) => handleChange(e)}
          label={
            <div>
              <TypographyComponent variant="h5"
                className="pt-2">{surveySettingsConstants.TRANSFLO_TOGGLE_TITLE}</TypographyComponent>
              <TypographyComponent variant="h6"
                className="txtgrey">{surveySettingsConstants.TRANSFLO_TOGGLE_MESSAGE}</TypographyComponent>
            </div>
         }
        />
      </Grid>
      {/*
                Displays when transflo toggle enabled and transflo permission from client
              */}

      {(surveyPermission.transfloAccess === true && data.distribute.notification.transflo.active === true && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS &&
        data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS) &&
        <Grid item sm={12} className={`${"pl-5"} ${"pt-13"}`}>
          <Grid container spacing={3}
            className={classes.settings_expandinsidediv}>
            {data.frequency !== "Pulse" &&
              <Grid container>
                <Grid item xs={12}>
                  <RadioGroup aria-label="distribute.notification.transflo.schedule.type"
                    row name="distribute.notification.transflo.schedule.type"
                    value={data.distribute.notification.transflo.schedule.type || ""}
                    // onChange={radiogrouphandlechange}
                    className={classes.audiencetype_div}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        value={surveySettingsConstants.RADIOGROUP_CUSTOM_DAY}
                        control={<Radio color="primary" onClick={radiogrouphandlechange} />}
                        label={surveySettingsConstants.DISTRIBUTION_RADIOGRP_NAME1} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        value={surveySettingsConstants.RADIOGROUP_CUSTOM_DATE}
                        control={<Radio color="primary" onClick={radiogrouphandlechange} />}
                        label={surveySettingsConstants.DISTRIBUTION_RADIOGRP_NAME2} />
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>

                    <Grid item md={6} xs={12}>
                      {/*
                                                Displays when transflo schedule type is day
                                            */}
                      {data.distribute.notification.transflo.schedule.type === surveySettingsConstants.RADIOGROUP_CUSTOM_DAY &&
                        <Grid container spacing={1}>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_A_DAY}
                            </TypographyComponent>
                            <AutoCompleteComponent
                              name="distribute.notification.transflo.schedule.triggerDay"
                              suggestionType="array"
                              suggestions={surveySettingsConstants.WEEK_DAYS}
                              handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                              value={data.distribute.notification.transflo ? (data.distribute.notification.transflo.schedule ? (data.distribute.notification.transflo.schedule.triggerDay ? data.distribute.notification.transflo.schedule.triggerDay : "") : "") : ""}
                            ></AutoCompleteComponent>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_TIME_MST}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {surveySettingsConstants.SMS_SELECT_TIME_MST_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TimePickerComponent
                              name="distribute.notification.transflo.schedule.time"
                              fullWidth={true}
                              value={data.distribute.notification.transflo.schedule.time || ""}
                              handleChange={(e) => handleChange(e)}
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>

                    <Grid item md={6} xs={12}>
                      {/*
                                                Displays when transflo schedule type is date
                                            */}
                      {data.distribute.notification.transflo.schedule.type === surveySettingsConstants.RADIOGROUP_CUSTOM_DATE &&
                        <Grid container spacing={1}>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_DATES}
                            </TypographyComponent>

                            <MultiDatePickerComponent
                              handleMultiDateSelect={handleMultiDateSelect}
                              name={"distribute.notification.transflo.schedule.triggerDate"}
                              value={data.distribute.notification.transflo.schedule.triggerDate || []} />
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_TIME_MST}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {surveySettingsConstants.SMS_SELECT_TIME_MST2_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TimePickerComponent
                              name="distribute.notification.transflo.schedule.time"
                              value={data.distribute.notification.transflo.schedule.time || ""}
                              handleChange={(e) => handleChange(e)}
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            {data.frequency === "Pulse" &&
              <Grid item xs={12} container>
                {data.pulse_obj.period.frequencyType !== surveySettingsConstants.WEEKS &&
                  <Grid item md={6}>
                    <Grid item md={6} xs={12}>
                      <TypographyComponent variant="h6">
                        {surveySettingsConstants.SELECT_A_DAY}
                      </TypographyComponent>
                      <AutoCompleteComponent
                        name="distribute.notification.transflo.schedule.triggerDay"
                        suggestionType="array"
                        suggestions={surveySettingsConstants.WEEK_DAYS}
                        handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                        value={data.distribute.notification.transflo ? (data.distribute.notification.transflo.schedule ? (data.distribute.notification.transflo.schedule.triggerDay ? data.distribute.notification.transflo.schedule.triggerDay : "") : "") : ""}
                      ></AutoCompleteComponent>
                    </Grid>
                  </Grid>
                }
                <Grid item md={6}>
                  <Grid item md={6} xs={12}>
                    <TypographyComponent variant="h6">
                      {surveySettingsConstants.SELECT_TIME_MST}
                      <InformationComponent>
                        <TypographyComponent variant="tooltipbody">
                          {surveySettingsConstants.SMS_SELECT_TIME_MST2_HELP}
                        </TypographyComponent>
                      </InformationComponent>
                    </TypographyComponent>
                    <TimePickerComponent
                      name="distribute.notification.transflo.schedule.time"
                      value={data.distribute.notification.transflo.schedule.time || ""}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.TRANSFLO_SUBJECT}
              </TypographyComponent>
              <TextFieldComponent id="distribute.notification.transflo.message.subject"
                name={`distribute.notification.transflo.message.subject.${langcode}`}
                placeholder={data.distribute.notification.transflo.message.subject["en"] ? data.distribute.notification.transflo.message.subject["en"] : surveySettingsConstants.PLACEHOLDER_SUBJECT}
                size={"medium"}
                fullWidth={true}
                variant="outlined"
                margin="none"
                validators={langcode === "en" ? ["required"] : []}
                value={data.distribute.notification.transflo.message ? (data.distribute.notification.transflo.message.subject ? (data.distribute.notification.transflo.message.subject[langcode] ? data.distribute.notification.transflo.message.subject[langcode] : "") : "") : ""}
                handleChange={(e) => handleChange({ ...e, "fieldName": "transflo_message_subject" })}
                className={`br-0 ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "transflo_message_subject") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""} `} />
            </Grid>
            <Grid item xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.TRANSFLO_MESSAGE}
              </TypographyComponent>
              <Grid className={classes.txtborder}>
                <Grid container alignItems="center" className={`bgwhite ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "transflo_message_greetings") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""}`}>
                  <Grid item sm={2}>
                    <TextFieldComponent id="distribute.notification.transflo.message.greetings"
                      name={`distribute.notification.transflo.message.greetings.${langcode}`}
                      placeholder={data.distribute.notification.transflo.message.greetings["en"] ? data.distribute.notification.transflo.message.greetings["en"] : surveySettingsConstants.PLACEHOLDER_GREETINGS}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      value={data.distribute.notification.transflo.message ? (data.distribute.notification.transflo.message.greetings ? (data.distribute.notification.transflo.message.greetings[langcode] ? data.distribute.notification.transflo.message.greetings[langcode] : "") : "") : ""}
                      handleChange={(e) => handleChange({ ...e, "fieldName": "transflo_message_greetings" })}
                      className={`${"br-0 br-none m-0"} ${classes.distri_emailmsg} ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "transflo_message_greetings") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""}`} />
                  </Grid>
                  <Grid item sm={10}>
                    {surveySettingsConstants.GREETINGS_CONSTANT_NAME}
                  </Grid>
                </Grid>
                <Grid item xs={12} className={'relative'}>
                  <TextareaAutosize
                    className={`width100 pe_textarea mb-m5 ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "transflo_message_body") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""} `}
                    name={`distribute.notification.transflo.message.body.${langcode}`}
                    placeholder={data.distribute.notification.transflo.message.body["en"] ? data.distribute.notification.transflo.message.body["en"] : surveySettingsConstants.PLACEHOLDER_MESSAGE}
                    maxLength={250}
                    value={data.distribute.notification.transflo.message ? (data.distribute.notification.transflo.message.body ? (data.distribute.notification.transflo.message.body[langcode] ? data.distribute.notification.transflo.message.body[langcode] : "") : "") : ""}
                    onChange={handleTextChange}
                    minRows={3} />
                  <div className={'smsCharLimit'}>{data.distribute.notification.transflo.message ? (data.distribute.notification.transflo.message.body ? (data.distribute.notification.transflo.message.body[langcode] ? data.distribute.notification.transflo.message.body[langcode].length : 0) : 0) : 0}/250</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonComponent
                title={surveySettingsConstants.TRANSFLO_SEND_BUTTON}
                color={"primary"}
                size={"small"}
                handleClick={handleSendTransfloBtnClick}
              ></ButtonComponent>
              <InformationComponent>
                <TypographyComponent variant="tooltipbody">
                  {surveySettingsConstants.TRANSFLO_SEND_BUTTON_HELP}
                </TypographyComponent>
              </InformationComponent>
            </Grid>
          </Grid>
        </Grid>
      }

      {/*
                Displays when no transflo access is given from client
              */}

      {surveyPermission.transfloAccess !== true &&
        <Grid container spacing={3}
          className={classes.settings_expandinsidediv}>
          <TypographyComponent variant="h6" >{surveySettingsConstants.TRANSFLO_DISABLED_MESSAGE}</TypographyComponent>
        </Grid>
      }
    </Grid>
  );
}

// default props
Transflo.defaultProps = {
  classes: {},
  data: {},
  langcode: "en",
  surveyPermission: {}
};

// prop types
Transflo.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  langcode: PropTypes.string,
  surveyPermission: PropTypes.object
};

export default (Transflo);
