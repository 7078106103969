import PropTypes from "prop-types";
import { useState } from "react";

import withStyles from '@mui/styles/withStyles';

// Import Styles
import DashboardGaugeComponentStyles from './DashboardGaugeComponentStyles';

// Import Components
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import { peopleCount } from "../../assets/images/svgComponents/ImageComponent";

function DashboardGaugeComponent(props) {
    const { classes, data, chartType, responseCount, diff, showComparison, isStartFromZero, points , max, min, mid} = props;
    const { fillPercent } = data;
    let isPositive = false
    if (fillPercent > 0) {
        isPositive = true
    }
    const content = {}
    content["title"] = chartType === 'eNPS' ? Math.round(fillPercent) : `${Math.round(fillPercent)}%`;

    if (responseCount) {
        content["peopleCount"] = responseCount
    }

    // Subtitle for eNPS Widget only
    if (chartType === 'eNPS') {
        content["subtitle"] = chartType
    }

    if (showComparison) {
        points["count"] = diff
        if (diff > 0) {
            points["indicationType"] = "high"
        } else if (diff === 0) {
            points["indicationType"] = "neutral"
        } else {
            points["indicationType"] = "low"
        }
    }
    // DO NOT EDIT THIS
    const transformAttributes = ['translate(11.556 124.404) rotate(-122)', 'translate(7.173 116.267) rotate(-115)', 'translate(4.227 108.066) rotate(-107)', 'translate(2.177 100.143) rotate(-102)', 'translate(0.125 91.981) rotate(-95)', 'translate(0.038 83.558) rotate(-90)', 'translate(0.004 74.791) rotate(-86)', 'translate(1.648 66.653) rotate(-80)', 'translate(3.618 57.434) rotate(-72)', 'translate(6.564 49.274) rotate(-65)', 'translate(10.464 42.276) rotate(-60)', 'translate(15.448 35.1) rotate(-53)', 'translate(20.313 28.25) rotate(-49)', 'translate(26.057 22.001) rotate(-44)', 'matrix(0.788, -0.616, 0.616, 0.788, 32.987, 16.879)', 'translate(40.149 11.669) rotate(-30)', 'translate(47.446 7.657) rotate(-25)', 'translate(55.542 4.513) rotate(-18)', 'matrix(0.978, -0.208, 0.208, 0.978, 64.493, 2.417)', 'translate(73.107 0.325) rotate(-3)', 'matrix(1, 0.017, -0.017, 1, 81.174, 0)', 'matrix(0.999, 0.052, -0.052, 0.999, 88.79, 0.249)', 'translate(97.902 2.331) rotate(10)', 'matrix(0.946, 0.326, -0.326, 0.946, 106.308, 4.325)', 'translate(114.1 7.458) rotate(23)', 'translate(121.752 11.383) rotate(29)', 'matrix(0.788, 0.616, -0.616, 0.788, 129.132, 16.471)', 'matrix(0.731, 0.682, -0.682, 0.731, 136.069, 21.41)', 'matrix(0.669, 0.743, -0.743, 0.669, 141.844, 28.236)', 'matrix(0.574, 0.819, -0.819, 0.574, 147.871, 34.345)', 'translate(152.779 41.164) rotate(59)', 'translate(156.859 49.12) rotate(66)', 'matrix(0.326, 0.946, -0.946, 0.326, 159.896, 57.013)', 'translate(162.076 65.254) rotate(79)', 'matrix(0.07, 0.998, -0.998, 0.07, 163.093, 73.892)', 'translate(163.197 82.77) rotate(91)', 'translate(163.223 90.615) rotate(95)', 'matrix(0.174, -0.985, 0.985, 0.174, 149.246, 98.056)', 'translate(146.801 105.571) rotate(-73)', 'translate(143.926 111.593) rotate(-64)', 'translate(141.421 118.535) rotate(-58)']
    const defaultStyles = {
        fill: "#eaebed",
        stroke: "#eaebed"
    }
    const activeStyles = {
        fill: "#76cdfd",
        stroke: "#54c0fd"
    }
    const activeNegativetyles = {
        fill: "#ffae4b",
        stroke: "#ffae4b"
    }

    const populateTags = () => {
        let html = '';
        const maxScale = isStartFromZero ? 41 : 20;
        const calculatedPercent = parseInt((maxScale / 100) * fillPercent);

        // Min Max and Mid ranges
        if (min && max && mid==0 ){
                html += `<text x="78" y="46"  style="font-size: 13px; fill: #757878;">${mid}</text>`;
                html += `<text x="0" y="150" text-anchor="start"  style="font-size: 13px; fill: #757878;">${min}</text>`;
                html += `<text x="162" y="150" text-anchor="end"   style="font-size: 13px; fill: #757878;">${max}</text>`;
        }
        for (let inc = 0; inc < 41; inc++) {
            let currentStyles = { ...defaultStyles };
            if (isStartFromZero) {
                if (fillPercent > 0 && inc <= calculatedPercent) {
                    currentStyles = { ...activeStyles };
                }
            } else {
                if (isPositive) {
                    if (inc >= maxScale && inc <= maxScale + calculatedPercent) {
                        currentStyles = { ...activeStyles };
                    }
                } else {
                    if (fillPercent !== 0 && inc <= maxScale && inc >= maxScale + calculatedPercent) {
                        currentStyles = { ...activeNegativetyles };
                    }
                }
            }

            html += `<rect id="Rectangle_${inc}" data-name="Rectangle ${inc}" width="1.439" height="13.052" rx="0.72" transform="${transformAttributes[inc]}" fill="${currentStyles.fill}" stroke="${currentStyles.stroke}" stroke-width="1" />`;
        }
        return html;
    }
    const indicatorTitle = `
        ${points.indicationType === 'low' ? 
            '' : 
            (points.indicationType === 'high' ? 
            '+' : '')}${points.count}pts`;

    return (
        <>
            <div className={classes.gaugeContainer}>
                <div className={classes.gaugeInnerContainer}>
                    <svg viewBox="0 0 164.307 139.096" height={"150px"} width={"150px"}>
                        <defs>
                            <linearGradient id="linear-gradient" x1="0.101" y1="0.527" x2="0.951" y2="0.53" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#54c0fd" />
                                <stop offset="1" stop-color="#eaebed" />
                            </linearGradient>
                            <linearGradient id="linear-gradient-2" x1="0.5" y1="0.5" x2="19.175" y2="0.697" gradientUnits="objectBoundingBox">
                                <stop offset="0" stop-color="#54c0fd" />
                                <stop offset="1" stop-color="#2a607f" />
                            </linearGradient>
                        </defs>
                        <g id="Group_4851" data-name="Group 4851" transform="translate(-384.318 -357.332)">
                            <g id="Group_4599" data-name="Group 4599" transform="translate(409.025 383.239)">
                                <rect id="Rectangle_1904" data-name="Rectangle 1904" width="0.934" height="7.482" rx="0.467" transform="translate(57.113 0)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1905" data-name="Rectangle 1905" width="7.385" height="0.933" rx="0.467" transform="translate(0 60.47)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1907" data-name="Rectangle 1907" width="6.685" height="0.933" rx="0.467" transform="translate(108.475 60.47)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1908" data-name="Rectangle 1908" width="3.518" height="0.933" rx="0.466" transform="translate(104.166 31.922) rotate(-30.056)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1909" data-name="Rectangle 1909" width="4.205" height="0.933" rx="0.466" transform="matrix(0.501, -0.866, 0.866, 0.501, 83.867, 11.122)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1910" data-name="Rectangle 1910" width="0.932" height="3.962" rx="0.466" transform="matrix(0.5, -0.866, 0.866, 0.5, 7.48, 30.972)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1911" data-name="Rectangle 1911" width="0.933" height="3.963" rx="0.466" transform="matrix(0.866, -0.5, 0.5, 0.866, 28.384, 7.946)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1916" data-name="Rectangle 1916" width="0.933" height="3.915" rx="0.466" transform="translate(51.098 0.364) rotate(-6.001)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1917" data-name="Rectangle 1917" width="0.934" height="3.914" rx="0.467" transform="translate(45.153 1.355) rotate(-11.982)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1918" data-name="Rectangle 1918" width="0.934" height="3.915" rx="0.467" transform="translate(39.344 2.962) rotate(-17.984)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1919" data-name="Rectangle 1919" width="0.934" height="3.915" rx="0.467" transform="translate(33.735 5.167) rotate(-23.971)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1927" data-name="Rectangle 1927" width="3.915" height="0.933" rx="0.467" transform="matrix(0.105, -0.994, 0.994, 0.105, 62.725, 4.159)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1928" data-name="Rectangle 1928" width="3.914" height="0.933" rx="0.467" transform="translate(68.283 4.99) rotate(-78.041)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1929" data-name="Rectangle 1929" width="3.915" height="0.933" rx="0.467" transform="translate(73.719 6.396) rotate(-71.974)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1930" data-name="Rectangle 1930" width="3.915" height="0.934" rx="0.467" transform="translate(78.982 8.365) rotate(-66.038)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1931" data-name="Rectangle 1931" width="3.915" height="0.933" rx="0.466" transform="translate(0.266 66.839) rotate(-5.992)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1932" data-name="Rectangle 1932" width="3.915" height="0.933" rx="0.467" transform="translate(1.161 73.825) rotate(-11.959)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1933" data-name="Rectangle 1933" width="3.915" height="0.934" rx="0.467" transform="translate(2.674 79.659) rotate(-17.975)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1934" data-name="Rectangle 1934" width="3.915" height="0.933" rx="0.467" transform="translate(4.788 85.303) rotate(-23.963)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1935" data-name="Rectangle 1935" width="0.933" height="3.914" rx="0.467" transform="matrix(0.208, -0.978, 0.978, 0.208, 109.977, 73.926)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1936" data-name="Rectangle 1936" width="0.934" height="3.915" rx="0.467" transform="matrix(0.309, -0.951, 0.951, 0.309, 108.475, 79.339)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1937" data-name="Rectangle 1937" width="0.934" height="3.914" rx="0.467" transform="translate(106.416 84.566) rotate(-66.046)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1938" data-name="Rectangle 1938" width="3.914" height="0.933" rx="0.467" transform="translate(110.903 54.526) rotate(-6.028)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1939" data-name="Rectangle 1939" width="3.914" height="0.933" rx="0.467" transform="translate(109.977 48.643) rotate(-11.912)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1940" data-name="Rectangle 1940" width="3.915" height="0.933" rx="0.467" transform="matrix(0.951, -0.309, 0.309, 0.951, 108.475, 42.898)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1941" data-name="Rectangle 1941" width="3.915" height="0.934" rx="0.467" transform="matrix(0.914, -0.406, 0.406, 0.914, 106.416, 37.345)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1942" data-name="Rectangle 1942" width="0.934" height="3.914" rx="0.467" transform="translate(23.811 10.94) rotate(-35.435)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1943" data-name="Rectangle 1943" width="0.934" height="3.915" rx="0.467" transform="matrix(0.75, -0.662, 0.662, 0.75, 19.12, 15.582)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1944" data-name="Rectangle 1944" width="0.933" height="3.914" rx="0.466" transform="translate(14.853 20.088) rotate(-47.426)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1945" data-name="Rectangle 1945" width="0.933" height="3.915" rx="0.466" transform="matrix(0.596, -0.803, 0.803, 0.596, 11.051, 25.04)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1954" data-name="Rectangle 1954" width="3.915" height="0.933" rx="0.467" transform="matrix(0.815, -0.58, 0.58, 0.815, 101.032, 27.518)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1955" data-name="Rectangle 1955" width="3.914" height="0.934" rx="0.467" transform="matrix(0.75, -0.661, 0.661, 0.75, 97.5, 22.867)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1956" data-name="Rectangle 1956" width="3.914" height="0.933" rx="0.466" transform="matrix(0.676, -0.736, 0.736, 0.676, 93.533, 18.636)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1957" data-name="Rectangle 1957" width="3.914" height="0.933" rx="0.466" transform="matrix(0.596, -0.803, 0.803, 0.596, 89.171, 14.194)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1958" data-name="Rectangle 1958" width="0.933" height="3.915" rx="0.467" transform="translate(0.266 55.044) rotate(-83.998)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1959" data-name="Rectangle 1959" width="0.933" height="3.915" rx="0.467" transform="translate(1.161 48.746) rotate(-78.012)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1960" data-name="Rectangle 1960" width="0.933" height="3.915" rx="0.467" transform="translate(2.674 42.577) rotate(-72.016)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_1961" data-name="Rectangle 1961" width="0.933" height="3.915" rx="0.467" transform="translate(4.788 36.608) rotate(-66.038)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                                <rect id="Rectangle_2105" data-name="Rectangle 2105" width="0.933" height="3.914" rx="0.467" transform="translate(110.887 68.628) rotate(-82)" fill="#eaebed" stroke="#eaebed" stroke-linecap="round" stroke-width="0.5" />
                            </g>
                            <g id="Group_4684" data-name="Group 4684" transform="translate(384.859 357.84)" dangerouslySetInnerHTML={{ __html: populateTags() }}>
                            </g>
                        </g>
                    </svg>
                    {
                        (chartType === 'eNPS' || content.title) &&
                        <div className={classes.gaugeBlock}>
                            <TypographyComponent title={content.title} align={'center'} variant="h5" className={`MuiSnackbarContent-message ${chartType==='eNPS'?"mt15":""} fontSize30`} />
                            {
                                content.subtitle &&
                                <TypographyComponent className={'fontSize12'} color={"#B1B2B3"} title={content.subtitle} align={'center'} variant="h6" />
                            }
                        </div>
                    }
                    {
                        (showComparison) ?
                            <TypographyComponent className={`${classes.pointIndicator} ${points.indicationType}`} title={indicatorTitle} align={'center'} variant="h6" />
                            : <></>
                    }
                </div>
                {
                    (showComparison) ?
                        <TypographyComponent className={`${classes.pointIndicatorText} fontSize12 txtRegular`} color={'#B1B2B3'} title={points.indicationText} variant="h6" align={'center'} />
                        : <></>
                }
                {
                    content.peopleCount  || chartType == "Engaged" ?
                    <div className={classes.peopleCountBlock}>
                        <>{peopleCount()}</>
                        <TypographyComponent className={'fontSize14 txtRegular'} title={`${content.peopleCount ? content.peopleCount : 0} ${chartType == "Engaged" ? "":"People"} ${chartType == "Engaged" ? "Engaged" : ""}`} variant="h6" />
                    </div>
                    : ""
                }
            </div>

        </>
    )
}

// default props
DashboardGaugeComponent.defaultProps = {
    classes: {},
    fillPercent: 0,
    isPositive: true,
    content: {
        title: '',
        subtitle: '',
        peopleCount: 0
    },
    points: {
        count: 0,
        indicationType: null, /* pass either high or low */
        indicationText: ''
    },
    isStartFromZero: false,
}

// prop types
DashboardGaugeComponent.propTypes = {
    classes: PropTypes.object,
    fillPercent: PropTypes.number.isRequired,
    isPositive: PropTypes.bool.isRequired,
    content: PropTypes.object,
    points: PropTypes.object,
    isStartFromZero: PropTypes.bool,
}

export default withStyles(DashboardGaugeComponentStyles)(DashboardGaugeComponent);