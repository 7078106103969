import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child route
import MESurveyAssignSubjects from "./MESurveyAssignSubjects";
import MESurveyAssignRaters from "./MESurveyAssignRaters";
import ImportME from "./ImportME";

function MESurveyAssignRouter(props) {
    // get props
    const { match, survey_id, editDisabled } = props;
    return (
        <Switch>
            <Route exact path={`${match.url}`} render={(props) => {
                return (
                    <MESurveyAssignSubjects
                        match={props.match}
                        {...props}
                        survey_id={survey_id}
                        editDisabled={editDisabled}
                    />
                );
            }} />
            <Route exact path={`${match.url}/raters`} render={(props) => {
                return (
                    <MESurveyAssignRaters
                        match={props.match}
                        {...props}
                        survey_id={survey_id}
                        editDisabled={editDisabled}
                    />
                );
            }} />
            <Route exact path={`${match.url}/import`} render={(props) => {
                return (
                    <ImportME
                        match={props.match}
                        {...props}
                        survey_id={survey_id}
                        editDisabled={editDisabled}
                    />
                );
            }} />
        </Switch>
    );
}
// default props
MESurveyAssignRouter.defaultProps = {
    match: {}
};

// prop types
MESurveyAssignRouter.propTypes = {
    match: PropTypes.object
};
export default MESurveyAssignRouter;