// import constants
import { alertConstant, uiConstant } from "../constants";
import { baseAction } from "./baseAction";
import { fileManagerConstants } from "../constants";
import { fileManagerService } from "../services";
import { saveAs } from 'file-saver';
// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

export const fileManagerAction = {
  getList,
  upadateSeenStatus,
  downloadFile,
  deleteFile,
  upadateNotifiedtatus,
  clearList
};

/**
 * Get All File list
 *
 * @param {*}
 * @returns
 */
function getList(skip, limit, sortBy, sort, sFields, sValues, formData = {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(fileManagerConstants.GET_FILES_LIST_REQUEST));

    fileManagerService.getList(skip, limit, sortBy, sort, sFields, sValues, formData).then(
      (data) => {
        dispatch(success(fileManagerConstants.GET_FILES_LIST_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(fileManagerConstants.GET_FILES_LIST_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update file status done click
 *
 * @param {*} id  file manager id to update
 * @returns
 */
function upadateSeenStatus(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(fileManagerConstants.UPDATE_FILE_SEEN_STATUS_REQUEST));

    fileManagerService.updateSeenStatus(id).then(
      (data) => {
        dispatch(success(fileManagerConstants.UPDATE_FILE_SEEN_STATUS_SUCCESS, data));
        history.push('/manage_people/participants')
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(fileManagerConstants.UPDATE_FILE_SEEN_STATUS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * File Download
 */
function downloadFile({ _id, fileName }) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(fileManagerConstants.DOWNLOAD_FILE_REQUEST));
    fileManagerService.downloadFile(_id)
      .then(
        (data) => {
          console.log(data)
          if (data && data.data && (data.data.error || data.status === "failed")) {
            dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
            dispatch(request(fileManagerConstants.DOWNLOAD_FILE_FAILURE));
          } else {
            const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, fileName);
            dispatch(success(alertConstant.ALERT_SUCCESS, "Successfully downloaded"));
            dispatch(request(fileManagerConstants.DOWNLOAD_FILE_SUCCESS));
          }
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          console.log(error)
          dispatch(request(uiConstant.LOADING_FALSE));
          dispatch(request(fileManagerConstants.DOWNLOAD_FILE_FAILURE));
        }
      );
  }
}

/**
 * Delete file
 *
 * @param {*} id  file manager id to update
 * @returns
 */
function deleteFile(id, cb) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(fileManagerConstants.DELETE_FILE_REQUEST));

    fileManagerService.deleteFile(id).then(
      (data) => {
        dispatch(success(fileManagerConstants.DELETE_FILE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        cb()
      },
      (error) => {
        dispatch(failure(fileManagerConstants.DELETE_FILE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update file notified after popup shown
 *
 * @param {*} id  file manager id to update
 * @returns
 */
function upadateNotifiedtatus(id) {
  return (dispatch) => {
    dispatch(request(fileManagerConstants.UPDATE_FILE_NOTIFIED_STATUS_REQUEST));

    fileManagerService.updateNotifiedStatus(id).then(
      (data) => {
        dispatch(success(fileManagerConstants.UPDATE_FILE_NOTIFIED_STATUS_SUCCESS, data));
      },
      (error) => {
        dispatch(failure(fileManagerConstants.UPDATE_FILE_NOTIFIED_STATUS_FAILURE, error));
      }
    );
  };
}

function clearList() {
  return (dispatch) => {
    dispatch(success(fileManagerConstants.GET_FILES_LIST_SUCCESS, {
      data: [],
      page: {
        fieldFilters: {
          headerCols: [],
          fieldCols: [],
          searchCols: [],
          columnSettings: [],
          visibleColumns: [],
          leftDefaultCols: []
        }
      }
    }));
  };
}