import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "./TakeSurveyStyles";

// Import TakeSurvey Header
import TakeSurveyHeader from "./TakeSurveyHeader";
import TakeSurveyFooter from "./TakeSurveyFooter";
import ThankYouComponent from "./ThankYouComponent";
import TakeSurveyWelcome from "./TakeSurveyWelcome";

// Network Detector
import NetworkDetector from "../../components/NetworkDetector/NetworkDetector";

// Import TakeSurvey Form
import TakeSurveyForm from "./TakeSurveyForm";

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import SessionTimeComponent from "../../components/SessionTimeOutComponent/SessionTimeOutComponent";

// Import Container
import Container from "../Containers/Container";

//import constants
import { TakeSurveyConstants } from "../../config/constants";

// Import Actions
import { takeSurveyAction } from "../../redux/actions";

function TakeSurvey(props) {
  //get props
  const { classes, match } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  const survey = useSelector((state) => state.takeSurvey.getOne);
  const questionData = useSelector((state) => state.takeSurvey.questions);
  const completionPercentage = useSelector((state) => state.takeSurvey.completionPercentage);
  const { languages, survey_name } = questionData;
  const langCode = useSelector((state) => state.takeSurvey.surveyLang);
  const surveyActionStatus = useSelector((state) => state.takeSurvey.surveyActionStatus);

  const [isTimeOut, setIsTimeOut] = useState(false);

  const [activeLanguage, setActiveLanguage] = useState(langCode);
  useEffect(() => {
    dispatch(takeSurveyAction.updateSurveyLanguage(activeLanguage));
  }, [activeLanguage]);

  const handleSurveyLanguage = (lang) => {
    setActiveLanguage(lang.code);
  };
  const activeLangObject = languages.find((lang) => lang.code == langCode);

  const surveyTitle = survey_name && survey_name[langCode] ? survey_name[langCode] : (survey.name ? survey.name : TakeSurveyConstants.TAKESURVEY_TITLE);
  const thanksHeader = survey.thanks_header ? (survey.thanks_header[langCode] && survey.thanks_header[langCode] !== "" ? survey.thanks_header[langCode] : (survey.thanks_header["en"] ? survey.thanks_header["en"] : TakeSurveyConstants.THANKS_MESSAGE_HEADER)) : TakeSurveyConstants.THANKS_MESSAGE_HEADER;
  const thanksDesc = survey.thanks_description ? (survey.thanks_description[langCode] && survey.thanks_description[langCode] !== "" ? survey.thanks_description[langCode] : (survey.thanks_description["en"] ? survey.thanks_description["en"] : TakeSurveyConstants.THANKS_MESSAGE_DESCRIPTION)) : TakeSurveyConstants.THANKS_MESSAGE_DESCRIPTION;
  const welcomeHeader = survey.welcome_header ? (survey.welcome_header[langCode] && survey.welcome_header[langCode] !== "" ? survey.welcome_header[langCode] : (survey.welcome_header["en"] ? survey.welcome_header["en"] : "")) : "";
  const welcomeDesc = survey.welcome_description ? (survey.welcome_description[langCode] && survey.welcome_description[langCode] !== "" ? survey.welcome_description[langCode] : (survey.welcome_description["en"] ? survey.welcome_description["en"] : "")) : "";

  return (
    <React.Fragment>
      {surveyActionStatus === "success"  ? (
        <ThankYouComponent thanksDesc={thanksDesc} thanksHeader={thanksHeader} {...props} />
      ) : surveyActionStatus === "welcome" ?
        <TakeSurveyWelcome welcomeDesc={welcomeDesc} welcomeHeader={welcomeHeader} {...props} />
        : (
          <Grid container aria-live="polite" aria-relevant="text">
            <TakeSurveyHeader langCode={langCode} />
            <Container page={"takeSurvey"}>
              <Grid item className={classes.takeSurvey_container}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <TypographyComponent variant="h3" className="txtRegualr" extraProps={{ tabindex: 0, "aria-label": "Survey Title " + surveyTitle }}>
                      {surveyTitle}
                    </TypographyComponent>
                  </Grid>
                  <Grid item className={"desktopShow"}>
                    <TypographyComponent variant="h6" className="colorTheme">
                      {`${completionPercentage}% `}
                      {TakeSurveyConstants.TAKESURVEY_COMPLETED}
                    </TypographyComponent>
                  </Grid>
                </Grid>

                {/* Mobile Only Grid Starts */}
                <Grid container justifyContent="space-between" alignItems="center" className="mobileShow">
                  <Grid item sm={6}>
                    {/* {languages.length > 1 ? (
                    <ValidatorForm onSubmit={() => {}}>
                      <AutoCompleteComponent
                        name={"clients"}
                        suggestions={languages}
                        handleAutoCompolete={handleSurveyLanguage}
                        value={activeLangObject}
                        placeholder={"Select Language"}
                        validators={[]}
                      ></AutoCompleteComponent>
                    </ValidatorForm>
                  ) : null} */}
                  </Grid>

                  <Grid item sm={6}>
                    <TypographyComponent variant="h6" className="colorTheme">
                      {`${completionPercentage}% `}
                      {TakeSurveyConstants.TAKESURVEY_COMPLETED}
                    </TypographyComponent>
                  </Grid>
                </Grid>
                {/* Mobile Only Grid Ends */}

                <Grid container className="mt-1">
                  <Grid item>
                    <TypographyComponent variant="body1" extraProps={{ tabindex: 0, "aria-label": survey.description ? "Survey Description " + survey.description[langCode] : null }}>
                      {survey.description ? (survey.description[langCode] && survey.description[langCode] !== "" ? survey.description[langCode] : survey.description["en"]) : null}
                    </TypographyComponent>
                  </Grid>
                </Grid>
                <TakeSurveyForm classes={classes} match={match} questionData={questionData} langCode={langCode} />
              </Grid>
            </Container>
            <TakeSurveyFooter langCode={langCode} />
            <SessionTimeComponent
              hanldeTimeOut={() => setIsTimeOut(true)}
            />
          </Grid>
        )}
      <NetworkDetector />
    </React.Fragment>
  );
}
// default props
TakeSurvey.defaultProps = {
  classes: {},
};

// prop types
TakeSurvey.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(TakeSurveyStyles)(TakeSurvey);
