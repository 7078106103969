import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Take Survey Service Functions
 */
export const meTakeSurveyService = {
  getOne,
  getSurveyRaters,
  search,
  updateSurveyResponse,
};

/**
 * Call Survey GetOne Service
 * id => surveyId
 * pId=> participantId default null
 */
async function getOne(surveyGetQuery) {
  const { params, browserName } = surveyGetQuery;
  const response = await apihelper.postRequest("me-takesurvey/getSurvey", surveyGetQuery);
  return response;
}



async function getSurveyRaters(params) {
  const response = await apihelper.postRequest(`me-takesurvey/raters`, params);
  return response;
}

/**
 * Get All me-survey raters data
 * @param id
 * @param updatedData
 */
 async function search(skip, limit, sortBy, sort, sFields, sValues,shortUrl) {
  const response = await apihelper.postRequest(`me-takesurvey/raters/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, shortUrl });
  return response;
}

async function updateSurveyResponse(data, apiUrl) {
  const responseData = { ...data };
  const response = await apihelper.postRequest(apiUrl, responseData);
  return response;
}