import React,{ useEffect } from "react";
import moment from "moment";
import { useDispatch , useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Import Components
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";
import SwitchComponent from "../../../../../components/SwitchComponent/SwitchComponent";
import { TimePickerValidator } from "../../../../../components/TimePickerComponent/TimePicker";
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";
import MultiDatePickerComponent from "../../../../../components/MultiDatePickerComponent/MultiDatePickerComponent";

// constants
import { surveySettingsConstants } from "../../../../../config/constants";
import { surveyConstant } from "../../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, surveyAction, alertAction } from "../../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData, isTranslated, iseditedLangtxt } from "../../../../../helpers";

function Sms(props) {
  const { classes, data, langcode, surveyPermission, translatedFields } = props;
  const dispatch = useDispatch();

  const {initial_notification} = useSelector((state) => state.survey);

  if (data.distribute.audience === undefined) {
    data.distribute.audience = {
      select: ""
    };
  }

  /**
   * Handle Radio Group Change Event
   * @param {*} panel
   */

  /**
     * Radio Group Button Change Event
     * @param {*} event
     */
  const radiogrouphandlechange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };

  /**
   * Handle Text area Change Event
   * @param {*} panel
   */
  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value, fieldName: "sms_message_body" });
  };

  // Handle Auto Complete
  const handleAutoCompolete = (value, name) => {
    handleChange({ name, value });
  };

  useEffect(()=>{
    if(data.distribute.audience.select === "anonymous"){
      data.distribute.notification.sms.active = false
    } else if (surveyPermission.smsAccess) {
      data.distribute.notification.sms.active = initial_notification?.notification?.sms?.active
    }
  },[data.distribute.audience.select])

  /**
  * Get Multi Select Date Values
  * @param {*} name
  * @param {*} value
  */
  const handleMultiDateSelect = (name, value) => {
    if (data._id && !data.isLoading) {
      const reducerData = getReducerUpdatedData({ name, value }, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
      * Handle Change Event
      * @param {*} panel
      */
  const handleChange = (event) => {
    if (data._id && !data.isLoading) {
      if(event.name=== "data.sms_name_access"){
        data.sms_name_access = event.value
      }
      if (event.name === "distribute.notification.sms.schedule.type" && event.value === data.distribute.notification.sms.schedule.type) {
        event["value"] = "";
      }
      iseditedLangtxt(translatedFields, langcode, event.fieldName)
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
     * Send SMS to participants
     */
  const handleSendSMSBtnClick = () => {
    if (data.status === surveySettingsConstants.SURVEY_STATUS_OPEN) {
      dispatch(surveyAction.sendSurveySMS({ _id: data._id, smsContent: data.distribute.notification.sms ,sms_name_access :data.sms_name_access}));
    }
    else {
      dispatch(alertAction.error(surveySettingsConstants.SMS_EMAIL_TRANSFLO_SEND_ERROR));
    }
  };

  return (
    <Grid item sm={12}>
      <Grid item md={12} xs={12} className="mb-2">
        <SwitchComponent
          name="distribute.notification.sms.active"
          checked={surveyPermission.smsAccess ? data.distribute.notification.sms.active : surveySettingsConstants.CHECKED_FALSE}
          disabled={(surveyPermission.smsAccess && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? surveySettingsConstants.CHECKED_FALSE : surveySettingsConstants.CHECKED_TRUE}
          color={(surveyPermission.smsAccess && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? "primary" : "default"}
          handleChange={(e) => handleChange(e)}
          label={
            <span>
              <TypographyComponent variant="h5"
                className="pt-2">{surveySettingsConstants.SMS_TOGGLE_TITLE}
                  <InformationComponent>
                    <Grid container>
                      <TypographyComponent variant="tooltipbody">
                        {surveySettingsConstants.SURVEY_SENT_ON_DATE}
                      </TypographyComponent>
                    </Grid>
                  </InformationComponent>
                </TypographyComponent>
              <TypographyComponent variant="h6"
                className="txtgrey">{surveySettingsConstants.SMS_TOGGLE_MESSAGE}</TypographyComponent>
            </span>
          }
        />
      </Grid>
      {/*
                Displays when sms toggle enabled and sms permission from client
              */}

      {(data.distribute.notification.sms.active === true && surveyPermission.smsAccess === true) && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS &&
        <Grid item className={`${"pl-5"} ${"pt-13"}`}>
          <Grid container spacing={3}
            className={classes.settings_expandinsidediv}>
            {data.frequency !== "Pulse" &&
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <RadioGroup aria-label="distribute.notification.sms.schedule.type"
                    row name="distribute.notification.sms.schedule.type"
                    value={data.distribute.notification.sms.schedule.type || ""}
                    // onChange={radiogrouphandlechange}
                    className={classes.audiencetype_div}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        value={surveySettingsConstants.RADIOGROUP_CUSTOM_DAY}
                        control={<Radio color="primary" onClick={radiogrouphandlechange} />}
                        label={surveySettingsConstants.DISTRIBUTION_RADIOGRP_NAME1} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        value={surveySettingsConstants.RADIOGROUP_CUSTOM_DATE}
                        control={<Radio color="primary" onClick={radiogrouphandlechange} />}
                        label={surveySettingsConstants.DISTRIBUTION_RADIOGRP_NAME2} />
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>

                    <Grid item md={6} xs={12}>
                      {/*
                                                Displays when sms schedule type is day
                                            */}
                      {data.distribute.notification.sms.schedule.type === surveySettingsConstants.RADIOGROUP_CUSTOM_DAY &&
                        <Grid container spacing={1}>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_A_DAY}
                            </TypographyComponent>
                            <AutoCompleteComponent
                              name="distribute.notification.sms.schedule.triggerDay"
                              suggestionType="array"
                              suggestions={surveySettingsConstants.WEEK_DAYS}
                              handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                              value={data.distribute.notification.sms ? (data.distribute.notification.sms.schedule ? (data.distribute.notification.sms.schedule.triggerDay ? data.distribute.notification.sms.schedule.triggerDay : "") : "") : ""}
                            ></AutoCompleteComponent>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_TIME_MST}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {surveySettingsConstants.SMS_SELECT_TIME_MST_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TimePickerValidator
                              name="distribute.notification.sms.schedule.time"
                              fullWidth={true}
                              value={data.distribute.notification.sms.schedule.time || moment().hour(9).minutes(0)}
                              panel="sms"
                              validators={['is7To6']}
                              errorMessages={['Enter Time between 7 AM to 6 PM']}
                              onValueChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>

                    <Grid item md={6} xs={12}>
                      {/*
                                                Displays when sms schedule type is date
                                            */}
                      {data.distribute.notification.sms.schedule.type === surveySettingsConstants.RADIOGROUP_CUSTOM_DATE &&
                        <Grid container spacing={1}>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_DATES}
                            </TypographyComponent>

                            <MultiDatePickerComponent
                              handleMultiDateSelect={handleMultiDateSelect}
                              name={"distribute.notification.sms.schedule.triggerDate"}
                              value={data.distribute.notification.sms.schedule.triggerDate || []} />
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_TIME_MST}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {surveySettingsConstants.SMS_SELECT_TIME_MST2_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TimePickerValidator
                              name="distribute.notification.sms.schedule.time"
                              value={data.distribute.notification.sms.schedule.time || moment().hour(9).minutes(0)}
                              validators={['is7To6']}
                              panel="sms"
                              errorMessages={['Enter Time between 7 AM to 6 PM']}
                              onValueChange={(e) => {
                                handleChange(e)
                              }}
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            {data.frequency === "Pulse" &&
              <Grid item xs={12} container>
                {data.pulse_obj.period.frequencyType !== surveySettingsConstants.WEEKS &&
                  <Grid item md={6}>
                    <Grid item md={6} xs={12}>
                      <TypographyComponent variant="h6">
                        {surveySettingsConstants.SELECT_A_DAY}
                      </TypographyComponent>
                      <AutoCompleteComponent
                        name="distribute.notification.sms.schedule.triggerDay"
                        suggestionType="array"
                        suggestions={surveySettingsConstants.WEEK_DAYS}
                        handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                        value={data.distribute.notification.sms ? (data.distribute.notification.sms.schedule ? (data.distribute.notification.sms.schedule.triggerDay ? data.distribute.notification.sms.schedule.triggerDay : "") : "") : ""}
                      ></AutoCompleteComponent>
                    </Grid>
                  </Grid>
                }
                <Grid item md={6}>
                  <Grid item md={6} xs={12}>
                    <TypographyComponent variant="h6">
                      {surveySettingsConstants.SELECT_TIME_MST}
                      <InformationComponent>
                        <TypographyComponent variant="tooltipbody">
                          {surveySettingsConstants.SMS_SELECT_TIME_MST2_HELP}
                        </TypographyComponent>
                      </InformationComponent>
                    </TypographyComponent>
                    <TimePickerValidator
                      name="distribute.notification.sms.schedule.time"
                      value={data.distribute.notification.sms.schedule.time || moment().hour(9).minutes(0)}
                      panel="sms"
                      validators={['is7To6']}
                      errorMessages={['Enter Time between 7 AM to 6 PM']}
                      onValueChange={(e) => {
                        handleChange(e)
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.SMS_TEXT}
              </TypographyComponent>
              <Grid className={classes.txtborder}>
                <Grid container alignItems="center" className={`bgwhite ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "sms_message_greetings") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""}`}>
                  <Grid item sm={2.5} marginRight="10px">
                    <TextFieldComponent id="distribute.notification.sms.message.greetings"
                      name={`distribute.notification.sms.message.greetings.${langcode}`}
                      placeholder={data.distribute.notification.sms.message.greetings["en"] ? data.distribute.notification.sms.message.greetings["en"] : surveySettingsConstants.PLACEHOLDER_GREETINGS}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      value={data.distribute.notification.sms.message ? (data.distribute.notification.sms.message.greetings ? (data.distribute.notification.sms.message.greetings[langcode] ? data.distribute.notification.sms.message.greetings[langcode] : "") : "") : ""}
                      handleChange={(e) => handleChange({ ...e, "fieldName": "sms_message_greetings" })}
                      className={`${"br-0 br-none m-0 "} ${classes.distri_emailmsg}  ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "sms_message_greetings") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""} `} />
                  </Grid>
                  <SwitchComponent item xs={4.5}
                  name="data.sms_name_access"
                  color="primary"
                  size="small"
                  checked={data.sms_name_access}
                  handleChange={(e) => { handleChange(e); }}
                  label={
                    <span>
                      {/* <TypographyComponent
                        variant="h6"
                      >{data.label}</TypographyComponent> */}
                    </span>
                  }
                />
                  <Grid item sm={4.5} className={classes.Greetings_constant} color={data.sms_name_access ? "" :"#888383" }>
                    {surveySettingsConstants.GREETINGS_CONSTANT_NAME}
                  </Grid>
                </Grid>
                <Grid item xs={12} className={'relative'}>
                  <TextareaAutosize
                    maxLength={120}
                    className={` width100 pe_textarea mb-m4 ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "sms_message_body") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""}  `}
                    name={`distribute.notification.sms.message.body.${langcode}`}
                    placeholder={data.distribute.notification.sms.message.body["en"] ? data.distribute.notification.sms.message.body["en"] : surveySettingsConstants.PLACEHOLDER_MESSAGE}
                    value={data.distribute.notification.sms.message ? (data.distribute.notification.sms.message.body ? (data.distribute.notification.sms.message.body[langcode] ? data.distribute.notification.sms.message.body[langcode] : "") : "") : ""}
                    onChange={handleTextChange}
                    minRows={3} />
                  <div className={'smsCharLimit'}>{data.distribute.notification.sms.message ? (data.distribute.notification.sms.message.body ? (data.distribute.notification.sms.message.body[langcode] ? data.distribute.notification.sms.message.body[langcode].length : 0) : 0) : 0}/120</div>
                </Grid>
                <Grid container alignItems="center" className={`bgwhite ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "sms_message_url") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""}`}>
                  <Grid item sm={6}>
                    <TextFieldComponent id="distribute.notification.sms.message.url"
                      name={`distribute.notification.sms.message.url.${langcode}`}
                      placeholder={data.distribute.notification.sms.message.url["en"] ? data.distribute.notification.sms.message.url["en"] : surveySettingsConstants.PLACEHOLDER_SMS_URL}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      value={data.distribute.notification.sms.message ? (data.distribute.notification.sms.message.url ? (data.distribute.notification.sms.message.url[langcode] ? data.distribute.notification.sms.message.url[langcode] : "") : "") : ""}
                      handleChange={(e) => handleChange({ ...e, "fieldName": "sms_message_url" })}
                      className={`${"br-0 br-none m-0 fontSize13"} ${classes.distri_emailmsg} ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "sms_message_url") ? classes.translatedField : classes.editedtranslatedField) : ""} `} />
                  </Grid>
                  <Grid item sm={6} className={classes.url_constant}>
                    {surveySettingsConstants.URL_CONSTANT_NAME}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <ButtonComponent
                title={surveySettingsConstants.SMS_SEND_BUTTON}
                color={"primary"}
                size={"small"}
                handleClick={handleSendSMSBtnClick}
              ></ButtonComponent>
              <InformationComponent>
                <TypographyComponent variant="tooltipbody">
                  {surveySettingsConstants.SMS_SEND_BUTTON_HELP}
                </TypographyComponent>
              </InformationComponent>
            </Grid>
          </Grid>
        </Grid>
      }

      {/*
                Displays when no sms access is given from client
              */}

      {surveyPermission.smsAccess !== true &&
        <Grid container spacing={3}
          className={classes.settings_expandinsidediv}>
          <TypographyComponent variant="h6" >{surveySettingsConstants.SMS_DISABLED_MESSAGE}</TypographyComponent>
        </Grid>
      }
    </Grid>
  );
}

// default props
Sms.defaultProps = {
  classes: {},
  data: {},
  langcode: "en",
  surveyPermission: {}
};

// prop types
Sms.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  langcode: PropTypes.string,
  surveyPermission: PropTypes.object
};

export default (Sms);
