import { alertConstant, categoryConstant, uiConstant } from "../constants";
import { externalApiConstant } from "../constants/externalApiConstant";
import { externalApiService } from "../services/externalApiServices";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

export const externalApiAction = {
    postApiConfig,
    getApiConfig,
    putHrisType
  };

/**
 * post apiConfig Client
 */
function postApiConfig(successCallback = () => { },apiConfig) {
    return (dispatch) => {
      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(externalApiConstant.POST_EXTERNAL_API_CONFIG,apiConfig));
      externalApiService.postExternalApiConfig(apiConfig).then(
        (data) => {
          dispatch(success(externalApiConstant.POST_EXTERNAL_API_CONFIG_SUCCESS,apiConfig));
          dispatch(success(alertConstant.ALERT_SUCCESS, "API Config Success"));
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          dispatch(failure(externalApiConstant.POST_EXTERNAL_API_CONFIG_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
    };
  }

  /**
 * post apiConfig Client
 */
function getApiConfig(successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    externalApiService.getExternalApiConfig().then(
      (data) => {
        dispatch(success(externalApiConstant.GET_EXTERNAL_API_CONFIG_SUCCESS,data?.data?.internal_api_config || {}));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(externalApiConstant.GET_EXTERNAL_API_CONFIG_FAILURE, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  }; 
}

/**
 * upodate hris type
 */
function putHrisType(successCallback = () => {}, hrisType) {
  return (dispatch) => {
    dispatch(request(externalApiConstant.PUT_EXTERNAL_API_HRIS_CONFIG, hrisType));
  }
}