import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";


const LineChart = (props) => {
  const {data, chartId, height} = props

  useEffect(() => {
    lineChart();
  }, [data, chartId]);

  //Render linechart Chart Here
  const lineChart = () => {
    d3.select(`#${chartId}`)
      .select("svg")
      .remove()

    let svg = d3.select(`#${chartId}`)
      .append('svg')
      .attr('height', 100)
      .attr('width', 250);

    let segmentWidth = 250;
    svg.append('rect')
      .attr('class', 'bg-rect')
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('fill', '#bad6e9')
      .attr('height', 15)
      .attr('width', segmentWidth)
      .attr('x', 0);

    svg.append('rect')
      .attr('class', 'progress-rect')
      .attr('fill', '#54C0FD')
      .attr('height', 15)
      .attr('width', data * (segmentWidth / 100))
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('x', 0);
  }

  return <div id={`${chartId}`} style={{height: height, textAlign: "center"}}></div>
}

// default props
LineChart.defaultProps = {
  classes: {},
  chartId: "lineChart",
  data: "",
};
// prop types
LineChart.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.any,
  chartId: PropTypes.string,
  height: PropTypes.number
};

export default LineChart;
