
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import Link from "@mui/material/Link";
import arrayMove from "array-move";
import {
    SortableContainer,
    SortableElement
} from "react-sortable-hoc";

import { Plus_blue } from "../../../assets/images/svgComponents/ImageComponent";
import SearchInputComponent from "../../../components/SearchInputCompoment/SearchInputComponent";
import AddActionAlert from "./ActionAlertTab/AddActionAlert";
import RenderActionAlert from "./ActionAlertTab/RenderActionAlert";
import ContentLibraryStyles from "../ContentLibraryStyles";

import { contentLibraryAction, updateStateReducerAction } from "../../../redux/actions"
import { contentLibraryConstant } from "../../../redux/constants"


/**
   * Sortable Item
   */
const SortableItem = SortableElement(props => {
    return (
        props.children
    );
});

/**
 * Sortable List
 */
const SortableList = SortableContainer(props => {
    const { classes, actionAlert } = props;
    return (
        <div className={"relative"}>
            {
                actionAlert.map((optItem, index) => (
                    <SortableItem
                        key={`optItem-${index}`}
                        index={index}
                    >
                        <div key={`div-${index}`}
                            index={index}>
                            <RenderActionAlert
                                classes={classes}
                                item={optItem}
                                index={index}
                            />
                        </div>
                    </SortableItem>
                ))}
        </div>
    )
})

function ActionAlert(props) {

    const { classes } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(contentLibraryAction.getOne());
    }, [dispatch]);

    const { addActionAlert } = useSelector(state => state.contentLibrary);
    const { actionAlert } = useSelector(state => state.contentLibrary);

    const [addNewActionAlert, setAddNewActionAlert] = React.useState(false);

    // Handle Search Text Box Event
    const handleChange = (searchField, searchKey) => {
        dispatch(contentLibraryAction.search(searchKey));
    };

    /**
 * Handle Clear Search Text
 */
    const handleClearSearch = () => {
        dispatch(contentLibraryAction.search(""));
    };

    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;
        const orderactionAlert = arrayMove(actionAlert, oldIndex, newIndex);
        dispatch(updateStateReducerAction.update(contentLibraryConstant.CONTENTLIBRARY_ACTIONALERT_REDUCER_UPDATE, orderactionAlert));
    };


    return (
        <Grid item sm={12} className={"pl-3 pr-3"}>
            <Grid item sm={12} className={"p5 dflex spacebetween pb-15"} >
                <SearchInputComponent
                    id="searchActionAlert"
                    className={classes.content_lib_search}
                    ariaLabel={"Search"}
                    placeholder={"Search"}
                    //   value={sValIndex >= 0 ? sValues[sValIndex] : ""}
                    showClearIcon={true}
                    onInputChangeRequest={handleChange}
                    handleClearSearch={handleClearSearch}
                />
                <Grid item>
                    <Link
                        onClick={() => setAddNewActionAlert(true)}
                        className={`${classes.content_linkimg}${" cursor-pointer"}`}
                        color="primary">
                        <IconButton color="inherit" className={"p-0"} size="large">{Plus_blue()}</IconButton>
                        Add action alert
                    </Link>
                </Grid>
            </Grid>
            <Grid item sm={12} >
                {addNewActionAlert &&
                    <AddActionAlert
                        classes={classes}
                        actionAlert={actionAlert}
                        addActionAlert={addActionAlert}
                        updateAddActionFlag={(isclose) => {
                            setAddNewActionAlert(isclose)
                        }}
                    />}
                {actionAlert &&
                    <SortableList
                        axis="xy"
                        useDragHandle
                        actionAlert={actionAlert}
                        classes={classes}
                        onSortEnd={onSortEnd}
                        {...props}
                    />
                }
            </Grid>
        </Grid>
    );
}

export default withStyles(ContentLibraryStyles)(ActionAlert);

