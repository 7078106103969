
import { amber } from '@mui/material/colors';
import  { palette } from "../../assets/theme/palette"

const SnackBarComponentStyle = theme => ({
  ALERT_SUCCESS: {
    backgroundColor: "#54C0FD !important",
    fontWeight: "500"
  },
  ALERT_ERROR: {
    backgroundColor: `${palette.error.dark} !important`,
    fontWeight: "500"
  },
  ALERT_INFO: {
    backgroundColor: `${palette.primary.dark} !important`,
    fontWeight: "500"
  },
  ALERT_WARNING: {
    backgroundColor: amber[700],
    fontWeight: "500"
  },
  ALERT_CLOSE: {
    // padding: theme.spacing(0.5)
  }
});

export default SnackBarComponentStyle;
