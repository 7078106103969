/* eslint-disable no-unused-vars */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from '@mui/material/Box';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

// Import Styles and Image
import { DialogueClose } from "../../../../assets/images/svgComponents/ImageComponent";

import SurveysStyles from "../../SurveysStyles";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants, surveyConstants, surveySettingsConstants, surveyTypes } from "../../../../config/constants";

// Import Actions
import { updateStateReducerAction, alertAction, surveyAction } from "../../../../redux/actions";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
// import ButtonBaseComponent from "../../../../components/ButtonBaseComponent/ButtonBaseComponent";

import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import PoweredByGoogleComponent from "../../../../components/PoweredByGoogleComponent/PoweredByGoogleComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";


// Import Pages
import AnswerSetTabs from "./CustomiseAnswerSet/AnswerSetTabs";
import AnswerSetTabPanel from "./CustomiseAnswerSet/AnswerSetTabPanel";

function CustomizeAnswerSet(props) {
  const dispatch = useDispatch();
  const { classes, match } = props;
  const { params } = match;

  /**
        * Get Question by survey id
    */
  useEffect(() => {
    dispatch(surveyAction.getAnswerSet(params.id ? params.id : questions._id));
  }, [dispatch]);

  /**
     * Initial Values
     */
  const [activeAnswerSetTab, setActiveAnswerSetTab] = useState(0);
  const [lang, setlang] = useState("English");
  const [langcode, setlangcode] = useState("en");
  const [translationConfirmDialog, setTranslationConfirmDialog] = useState({ flag: false });


  /**
    variable declarations
    */
  const translatedHistory = useSelector((state) => state.survey.translated_history);
  let answerSet = useSelector((state) => state.survey.answerSet);
  const answerSetOriginal = useSelector((state) => state.survey.answerSetCopy);
  const questions = useSelector((state) => state.survey.questions);
  const responseDetails = useSelector((state) => state.survey.responseDetails);
  const { loading } = useSelector((state) => state.UI);
  const { getOne } = useSelector((state) => state.client);
  const { languageTranslation } = getOne

  if (questions.type === surveyTypes.MULTI_RATER_FEEDBACK && answerSet.sets.length === 1 && answerSet.sets["1-5 agreement scale"] && Object.keys(answerSet.sets["1-5 agreement scale"]).length === 2) {
    answerSet = surveyQuestionsConstants.ME_CUSTOM_ANSWERSET
  }

  //Update answerset for Old survey
  if (questions.isOldSurvey && answerSet.sets["1-5 agreement scale"]) {
    answerSet = surveyQuestionsConstants.OLD_CUSTOM_DEFAULT_ANSWERSET
  }


  // eslint-disable-next-line camelcase
  const survey_id = useSelector((state) => state.survey.getOne._id);
  const quesSets = {};
  responseDetails["sets"] = [];
  responseDetails["languageset"] = [];
  const tabarray = answerSet.tabarray;
  const languages = {};

  for (let i = 0; i < questions.languages.length; i++) {
    if (responseDetails.languages.indexOf(questions.languages[i].name) !== -1) {
      responseDetails["languageset"].push(questions.languages[i].code);
    }
    languages[questions.languages[i].code] = questions.languages[i].name;
  }

  for (let j = 0; j < questions.questions.length; j++) {
    quesSets[questions.questions[j].baseQusId] = 0;
    if (questions.questions[j].ansSet && questions.questions[j].qusType === "Single Select") {
      let ansset = questions.questions[j].ansSet.en;
      for (let keys in answerSetOriginal.sets) {
        if (JSON.stringify(ansset) === JSON.stringify(answerSetOriginal.sets[keys].en)) {
          quesSets[questions.questions[j].baseQusId] = keys;
          if (responseDetails.question_ids.indexOf(questions.questions[j].baseQusId) !== -1) {
            if (responseDetails["sets"].indexOf(keys) === -1) {
              responseDetails["sets"].push(keys);
            }
          }
        }
      }
    }
  }

  /**
     * Set Active Anserset Tab
     */
  useEffect(() => {
    setActiveAnswerSetTab(Object.keys(answerSet.sets).indexOf(answerSet.selected));
  }, []);

  /**
     * Update reducer data
     * @param {*} answerSet
     * @param  {...any} updateFields
     */
  const updateReducer = (answerSet, ...updateFields) => {
    updateFields.map((item, index) => {
      answerSet = { ...answerSet, item };
    });
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_EDITANSWERSET_REDUCER_UPDATE, answerSet));
    return true;
  };

  /**
     * Find Unique Values in Array
    */
  const distinct = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  /**
     * Save Answer Set
    */
  const saveAnswerSet = () => {
    const sets = answerSet.sets;
    let checkflag = true;
    for (let setname in sets) {
      if (sets[setname]["en"]["5"] === undefined) {
        checkflag = false;
        // dispatch(alertAction.error(`<b>${setname}</b> set has to be in five scale`));
        dispatch(alertAction.error("Answer set has to be in five scale"));
      } else {
        for (let langkey in sets[setname]) {
          const checkemptyval = Object.values(sets[setname][langkey]).includes("");
          const checkemptyvalwithspace = Object.values(sets[setname][langkey]).includes(" ");
          if (checkemptyval || checkemptyvalwithspace) {
            checkflag = false;
            // dispatch(alertAction.error(`Empty values found in <b>${setname}</b> for <b>${languages[langkey]}</b> language`));
            dispatch(alertAction.error("Answer set contains empty values"));
          } else {
            const distinctval = Object.values(sets[setname][langkey]).map(function (v) {
              return v.toLowerCase();
            }).filter(distinct).length;
            if (distinctval < Object.values(sets[setname][langkey]).length) {
              checkflag = false;
              // dispatch(alertAction.error(`Duplicate values found in <b>${setname}</b> for <b>${languages[langkey]}</b> language`));
              dispatch(alertAction.error("Answer set contains duplicate values"));
            }
          }
        }
      }
    }
    if (checkflag === true) {
      for (let k = 0; k < questions.questions.length; k++) {
        if (questions.questions[k].ansSet && questions.questions[k].qusType === "Single Select") {
          if (tabarray.indexOf(quesSets[questions.questions[k].baseQusId]) !== -1) {
            questions.questions[k].ansSet = sets[quesSets[questions.questions[k].baseQusId]];
          }
        }
      }
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_QUESTIONS_REDUCER_UPDATE, questions.questions));
      dispatch(surveyAction.saveAnswerSet({ _id: params.id ? params.id : questions._id, answerSet }));
      dispatch(alertAction.successAlert("Answer set saved successfully"));
    }
  };

  /**
     * Handle Tab Change
    */
  const handleTabChange = (value) => {
    setActiveAnswerSetTab(value);
  };

  /**
     * Handle Language Change Event
    */
  const handlelangchange = (event) => {
    setlang(event.value);
    findlangkey(event.value);
  };

  /**
     * Find Language Key
     * @param {*} panel
    */
  const findlangkey = (event) => {
    for (let i = 0; i < questions.languages.length; i++) {
      if (questions.languages[i].name === event) {
        setlangcode(questions.languages[i].code);
        updatesetdata(questions.languages[i].code);
      }
    }
  };

  /**
     * Update Set Data
     * @param {*} panel
    */
  const updatesetdata = (code) => {
    const sets = answerSet.sets;
    for (let answerset in sets) {
      for (let keys in sets[answerset]["en"]) {
        if (sets[answerset][code] === undefined) {
          sets[answerset][code] = {};
        }
        if (sets[answerset][code][keys] === undefined) {
          sets[answerset][code][keys] = "";
        }
      }
      updateReducer(answerSet, sets);
    }
    updateReducer(answerSet, sets);
  };

  /**
     * Aplly Answer Set
     * @param {*} panel
    */
  const applyAnswerSet = () => {
    const sets = answerSet.sets;
    const currentAnswerSet = sets[tabarray[activeAnswerSetTab]];
    if (currentAnswerSet["en"]["5"] === undefined || currentAnswerSet["en"]["5"] === " " || currentAnswerSet["en"]["5"] === "") {
      // dispatch(alertAction.error(`Empty values found for <b>English</b> language`));
      dispatch(alertAction.error("Answer choice is empty"));
    } else {
      for (let langkey in currentAnswerSet) {
        const checkemptyval = Object.values(currentAnswerSet[langkey]).includes("");
        const checkemptyvalwithspace = Object.values(currentAnswerSet[langkey]).includes(" ");
        if (checkemptyval || checkemptyvalwithspace) {
          // dispatch(alertAction.error(`Empty values found for <b>${languages[langkey]}</b> language`));
          dispatch(alertAction.error("An answer choice is missing a translation"));
        } else {
          const distinctval = Object.values(currentAnswerSet[langkey]).map(function (v) {
            return v.toLowerCase();
          }).filter(distinct).length;
          if (distinctval < Object.values(currentAnswerSet[langkey]).length) {
            // dispatch(alertAction.error(`Duplicate values found for <b>${languages[langkey]}</b> language`));
            dispatch(alertAction.error("Duplicate answer choice found"));
          } else {
            for (let k = 0; k < questions.questions.length; k++) {
              if (questions.questions[k].ansSet && questions.questions[k].qusType === "Single Select") {
                let ansset = questions.questions[k].ansSet.en;
                if (Object.keys(ansset).length === 5 && ansset.hasOwnProperty("5")) {
                  questions.questions[k].ansSet = currentAnswerSet;
                } else if (Object.keys(ansset).length === 6 && ansset.hasOwnProperty("0")) {
                  questions.questions[k].ansSet = currentAnswerSet;
                }
              }
            }
            dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_QUESTIONS_REDUCER_UPDATE, questions.questions));
            dispatch(alertAction.successAlert("Answer set applied to all single select questions in the survey"));
          }
        }
      }
    }
  };

  /**
    Handle Dialog Close
    */
  const handleDialogClose = () => {
    props.handleDialogClose();
  };

  /**
     * Delete Answer Set
    */
  const handleDeleteAnswerSet = (event) => {
    const { sets, tabarray } = event;
    updateReducer(answerSet, sets, tabarray);
  };

  const handleTranslate = () => {
    const activeLang = questions.languages.find((curLanguage, index) => curLanguage.code === langcode)
    const langCode = activeLang.code;
    if (langCode && langCode !== "en") {
      const surveyDataObj = {
        "survey_name": questions.name || "",
        answerSet,
        translated_history: translatedHistory || {}
      };
      const data = { surveyData: surveyDataObj, targetLanguage: langCode }
      dispatch(surveyAction.translateCustomAnswerSet(data));
      setTranslationConfirmDialog(false)
    }
  }

  return (
    <div className={"centercontainer mt-0 pt-2"}>
      {loading && <LoaderComponent></LoaderComponent>}
      {!loading &&
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.dialog_close}>
            <Button
              variant="text"
              size={"small"}
              onClick={handleDialogClose}
              pageClassName="ml-1">
              {DialogueClose()}
              <TypographyComponent variant="h5" className="pl5">{surveyConstants.CLOSE_BUTTON}</TypographyComponent>
            </Button>
          </Grid>
          {answerSet && answerSet.sets &&
            <React.Fragment>
              <Grid item xs={12}>
                <TypographyComponent variant="h4" className="txtSemiBold pt-35">
                  {surveyQuestionsConstants.CUSTOMIZE_ANSWER_SET}
                </TypographyComponent>
              </Grid>
              <Grid item xs={12}>
                <TypographyComponent variant="h6">
                  {surveyQuestionsConstants.CUSTOMIZE_ANSWER_SET_TEXT}
                </TypographyComponent>
              </Grid>
              <Grid item xs={12} className={"pt-3 mb8"}>
                <Grid container>
                  <Grid item md={8} xs={12}>
                    <AnswerSetTabs
                      classes={classes}
                      answerData={answerSet.sets}
                      answerSet={answerSet}
                      tabarray={tabarray}
                      responseDetails={responseDetails}
                      changeTabValue={(e) => handleTabChange(e)}
                      activeAnswerSetTab={activeAnswerSetTab}
                      langcode={langcode}
                      handleDeleteAnswerSet={(e) => handleDeleteAnswerSet(e)}
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="txtright">
                    <Box className="dflex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                      <SelectFieldComponent
                        select
                        name="lang"
                        disabled={questions.languages.length > 1 ? (languageTranslation === false ? true : false) : true}
                        value={lang}
                        variant="outlined"
                        fullWidth={false}
                        handleChange={(e) => handlelangchange(e)}
                        options={questions.languages}
                      />

                      {questions.languages && questions.languages.length > 1 && languageTranslation === true &&
                        <>
                          <Button variant="contained" className={"buttonOrange txtwhite ml-1"} disabled={lang === "English" ? true : false} disableElevation size="small" onClick={() => { setTranslationConfirmDialog({ flag: true }) }}>
                            {surveySettingsConstants.APPLY_TRANSLATION_BUTTON}
                          </Button>
                          <InformationComponent>
                            <TypographyComponent variant="tooltipbody">
                              {surveySettingsConstants.APPLY_TRANSLATION_INFO_ANSWERSET}
                            </TypographyComponent>
                          </InformationComponent>
                        </>
                      }
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AnswerSetTabPanel
                  classes={classes}
                  answerData={answerSet.sets}
                  tabarray={tabarray}
                  activeAnswerSetTab={activeAnswerSetTab}
                  langcode={langcode}
                  answerSet={answerSet}
                  responseDetails={responseDetails}
                  translatedFields={translatedHistory}
                />
              </Grid>
              <Grid item xs={12} className={"mt-2"}>
                <Grid container spacing={1}>
                  <Grid item sm={6}>
                    {responseDetails.question_ids.length < 1 && langcode === "en" &&
                      <Grid container justifyContent={"flex-start"}>
                        <ButtonComponent
                          title={surveyConstants.APPLY_TO_ALL_BUTTON}
                          color={"primary"}
                          size={"small"}
                          pageClassName="buttonOrange"
                          handleClick={applyAnswerSet}
                        ></ButtonComponent>
                      </Grid>
                    }
                  </Grid>
                  <Grid item sm={6}>
                    <Grid container justifyContent={"flex-end"}>
                      <ButtonComponent
                        title={surveyConstants.SAVE_BUTTON}
                        color={"primary"}
                        size={"small"}
                        pageClassName={classes.settings_addrulebtn}
                        handleClick={saveAnswerSet}
                      ></ButtonComponent>
                      {/* <ButtonComponent
                                        title={surveyConstants.CANCEL_BUTTON}
                                        color={"secondary"}
                                        size={"small"}
                                        handleClick={handleDialogClose}
                                        pageClassName="ml-1"
                                    ></ButtonComponent> */}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          }
        </Grid>
      }

      {translationConfirmDialog.flag && (
        <Dialog open={translationConfirmDialog.flag}>
          <DialogContent>
            <TypographyComponent
              title={surveyQuestionsConstants.TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_ANSWERSET.split('\n').map(str => <div>{str}</div>)}
              variant={"div"}
              align={"center"}
              className="txtcenter"
            > </TypographyComponent>
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Yes"}
              color={"primary"}
              size={"small"}
              handleClick={() => { handleTranslate() }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
            <ButtonComponent
              title={"No"}
              color={"default"}
              size={"small"}
              handleClick={() => { setTranslationConfirmDialog({ flag: false }); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
          <DialogContent>
            <PoweredByGoogleComponent />
          </DialogContent>
        </Dialog>
      )}

    </div>
  );
}

// default props
CustomizeAnswerSet.defaultProps = {
  classes: {},
  match: {},
  handleDialogClose: () => { }
};

// prop types
CustomizeAnswerSet.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.any,
  handleDialogClose: PropTypes.func
};

export default withStyles(SurveysStyles)(CustomizeAnswerSet);
