import { alertConstant, meSurveyConstant, uiConstant } from "../constants";
import { meSurveyService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All ME Survey Actions Functions
export const meSurveyAction = {
  search,
  subjects,
  raters,
  updateSelectedParticipants,
  saveOrUpdate,
  dataFilterUpdate,
  getDemographics,
  getDemographicsItems,
  addOrEditRater,
  updateRaterChange,
  getManagerRelationship,
  updateManagerRelationship,
  addOrEditRelationship,
  deleteRelationship,
  getProcessStatus,
  updateSeenStatus,
  clearSearchValues,
  noSaveRaterUpdate,
  hasResponse,
  resetAssignData,
  updateRaterErrorNotification
};

/**
 * Get All ME Survey
 */
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_SURVEY_SEARCH_REQUEST));
    meSurveyService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_SURVEY_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_SURVEY_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get All ME Assign Subjects
 */
function subjects(skip, limit, sortBy, sort, sFields, sValues, survey_id) {
  return (dispatch, getState) => {
    const { managerEffectiveness: { selectedParticipants, dataFilter } } = getState()
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_SURVEY_ASSIGN_REQUEST));
    meSurveyService.subjectsandRaters(skip, limit, sortBy, sort, sFields, sValues, {
      survey_id,
      type: 'subjects',
      selectedParticipants,
      dataFilter
    }).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_SURVEY_ASSIGN_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_SURVEY_ASSIGN_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get All ME Assign Raters
 */
function raters(skip, limit, sortBy, sort, sFields, sValues, survey_id) {
  return (dispatch, getState) => {
    const { managerEffectiveness: { selectedParticipants, dataFilter: { showOnlyManager } } } = getState()
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_SURVEY_ASSIGN_REQUEST));
    meSurveyService.subjectsandRaters(skip, limit, sortBy, sort, sFields, sValues, {
      survey_id,
      type: 'raters',
      selectedParticipants,
      dataFilter: { showOnlyManager }
    }).then(
      (data) => {
        if (data) {
          data.type = "raters"
        }
        dispatch(success(meSurveyConstant.ME_SURVEY_ASSIGN_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_SURVEY_ASSIGN_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Updated ME Assign selected lists
 * @param {*} selectedParticipants 
 * @returns 
 */
function updateSelectedParticipants(selectedParticipants = []) {
  return {
    type: meSurveyConstant.ME_SELECTED_PARTICIPANTS,
    data: selectedParticipants
  }
}

/**
 * Save or Update Assignes
 */
function saveOrUpdate(survey_id) {
  return (dispatch, getState) => {
    const { managerEffectiveness: { selectedParticipants, dataFilter: { showOnlyManager } } } = getState()
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_SAVE_PARTICIPANTS_REQUEST));
    meSurveyService.saveOrUpdate({
      survey_id,
      selectedParticipants,
      showOnlyManager
    }).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_SAVE_PARTICIPANTS_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_SAVE_PARTICIPANTS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Date Filter Apply or Update
 */
function dataFilterUpdate(dataFilter) {
  return {
    type: meSurveyConstant.ME_DATA_FILTER_UPDATE,
    data: dataFilter
  }
}


/**
 * Get Demographics
 */
function getDemographics(isRaterTab) {
  return (dispatch, getState) => {
    const { dataFilter: { filterData: { search } } } = getState().managerEffectiveness
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_REQUEST));
    meSurveyService.getDemographics({ search, isRaterTab }).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Demographics Items
 */
function getDemographicsItems(formData) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_DDN_REQUEST));
    meSurveyService.getDemographicsItems(formData).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_DDN_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Add or Edit Rater
 */
function addOrEditRater(raterData) {
  return {
    type: meSurveyConstant.ME_ADD_OR_EDIT_RATER,
    data: raterData
  }
}

/**
 * Save ADDED / EDITED Rater changes
 */
function updateRaterChange(raterData, survey_id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_ADD_OR_EDIT_RATER_REQUEST));
    meSurveyService.updateRaterChange(raterData, survey_id).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_ADD_OR_EDIT_RATER_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_ADD_OR_EDIT_RATER_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * get Manager Relationship
 */
function getManagerRelationship(survey_id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_MANAGER_RELATIONSHIP_GET_REQUEST));
    meSurveyService.getRelationship(survey_id).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_MANAGER_RELATIONSHIP_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_MANAGER_RELATIONSHIP_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Add or Edit Relationship
 */
function addOrEditRelationship(relationships) {
  return {
    type: meSurveyConstant.ME_ADD_OR_EDIT_RELATIONSHIP,
    data: relationships
  }
}

/**
 * update Manager Relationship
 */
function updateManagerRelationship(survey_id, formData) {
  return (dispatch, getState) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_MANAGER_RELATIONSHIP_UPDATE_REQUEST));
    meSurveyService.updateRelationship(survey_id, formData).then(
      (data) => {
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(success(meSurveyConstant.ME_MANAGER_RELATIONSHIP_UPDATE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        // refresh records
        const { dataFilter } = getState().managerEffectiveness
        dispatch(dataFilterUpdate({
          ...dataFilter,
          filterChanged: true
        }))
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_MANAGER_RELATIONSHIP_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Delete Manager Relationship
 */
function deleteRelationship(survey_id, { _id, orderIndex }) {
  return (dispatch, getState) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_MANAGER_RELATIONSHIP_DELETE_REQUEST));
    meSurveyService.deleteRelationship(survey_id, _id).then(
      (data) => {
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(success(meSurveyConstant.ME_MANAGER_RELATIONSHIP_DELETE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        // remove orderIndex
        if (data.status === "success") {
          let { relationships } = getState().managerEffectiveness;
          relationships.splice(orderIndex, 1)
          data.data = relationships
          dispatch(success(meSurveyConstant.ME_MANAGER_RELATIONSHIP_GET_SUCCESS, data));
        }
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_MANAGER_RELATIONSHIP_DELETE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Process status
 */
function getProcessStatus(survey_id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_GET_PROCESS_STATUS_REQUEST));
    meSurveyService.gerProcessStatus(survey_id).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_GET_PROCESS_STATUS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_GET_PROCESS_STATUS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Process Seen status
 */
function updateSeenStatus(survey_id, process_id, updateFields) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_GET_PROCESS_STATUS_REQUEST));
    meSurveyService.updateSeenStatus(survey_id, process_id, updateFields).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_GET_PROCESS_STATUS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_GET_PROCESS_STATUS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

// clear search field
function clearSearchValues() {
  return {
    type: meSurveyConstant.ME_SEARCH_CLEAR_VALUES
  }
}

/**
 *when user exits without saving need to delete new added rater under the uunsaved subjects
 */
function noSaveRaterUpdate(survey_id, cb = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(meSurveyConstant.ME_NO_SAVE_PARTICIPANTS_REQUEST));
    meSurveyService.noSaveCheck(survey_id).then(
      (data) => {
        dispatch(success(meSurveyConstant.ME_NO_SAVE_PARTICIPANTS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        cb()
      },
      (error) => {
        dispatch(failure(meSurveyConstant.ME_NO_SAVE_PARTICIPANTS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Is rater has response
 */
 function hasResponse(survey_id, raterData, cb = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    meSurveyService.hasResponse(survey_id, raterData).then(
      (data) => {
        console.log(data)
        dispatch(request(uiConstant.LOADING_FALSE));
        cb()
      },
      (error) => {
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


// reset assign list datas
function resetAssignData() {
  return {
    type: meSurveyConstant.ME_SURVEY_ASSIGN_RESET
  }
}

// update rater notification status
function updateRaterErrorNotification() {
  return {
    type: meSurveyConstant.ME_RATER_ERROR_NOTIFICATION
  }
}