/* eslint-disable no-unneeded-ternary */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import SvgIcon from '@mui/material/SvgIcon';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { surveyAction } from "../../../../redux/actions";

// Import Icons
import { EmailAccord } from "../../../../assets/images/svgComponents/ImageComponent";

import emailicon from "../../../../assets/images/svg/email.svg";

// Import Components
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import PoweredByGoogleComponent from "../../../../components/PoweredByGoogleComponent/PoweredByGoogleComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";


// Import Panels
import Email from "./DistributionPanels/Email";
import Sms from "./DistributionPanels/Sms";
import Kiosk from "./DistributionPanels/Kiosk";
import Transflo from "./DistributionPanels/Transflo";
import ManagerAlerts from "./DistributionPanels/ManagerAlerts";

// constants
import { surveySettingsConstants, translateLanguagesList, surveyQuestionsConstants, surveyTypes } from "../../../../config/constants";

function DistributionPanel(props) {
  const { classes, data, expanded, surveyPermission, anonKioskShortName, panel, panelError, settingsForm } = props;
  const { settings, translated_history } = useSelector((state) => state.survey);
  const { getOne } = useSelector((state) => state.client);
  const { languageTranslation } = getOne

  const dispatch = useDispatch();

  const [lang, setlang] = useState("English");
  const [langcode, setlangcode] = useState("en");
  const [translationConfirmDialog, setTranslationConfirmDialog] = useState({ flag: false });


  /**
   * Handle language Change Event
   * @param {*} panel
   */

  const handlelangchange = (event) => {
    setlang(event.value);
    findlangkey(event.value);
  };


  const handletranslationsubmit = () => {
    let surveyData = settings
    surveyData = { ...surveyData, translated_history }
    const targetLanguage = langcode
    dispatch(surveyAction.translateSurveySettings({
      surveyData,
      targetLanguage
    }));
    setTranslationConfirmDialog(false)
  };



  /**
   * Find Language Key
   * @param {*} panel
   */

  const findlangkey = (event) => {
    for (var i = 0; i < translateLanguagesList.length; i++) {
      if (translateLanguagesList[i].name === event) {
        setlangcode(translateLanguagesList[i].code);
      }
    }
  };

  /**
   * Handle Tab Change Event
   * @param {*} name
   */
  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  return (
    <React.Fragment>
      <Accordion
        className={`mb-2 pe-accordion ${panelError ? "panel-error" : ""}`}
        expanded={expanded === "distribution-panel"}
        onChange={handleTabChange("distribution-panel")}
      >

        {/*
        panel header summary
      */}

        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expandicon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <SvgIcon className={`pt4 ${classes.settings_icons} ${expanded === "distribution-panel" ? "panel-active" : "panel-inactive"} `} alt={"settings"}>
            {EmailAccord()}
          </SvgIcon>
          <TypographyComponent
            className={` ${classes.setting_accordheading} ${expanded === "distribution-panel" ? "panel-active" : ""} `}
            variant={"h5"}
          >{data.type === surveyTypes.MULTI_RATER_FEEDBACK ? surveySettingsConstants.DISTRIBUTION_PANEL_TITLE_ME : surveySettingsConstants.DISTRIBUTION_PANEL_TITLE}</TypographyComponent>
          <TypographyComponent
            className={`${"oneline_ellipse mt2"} ${expanded === "distribution-panel" ? "panel-active" : ""}`}
            variant={"h6"}
          >{data.type === surveyTypes.MULTI_RATER_FEEDBACK ? surveySettingsConstants.DISTRIBUTION_PANEL_MESSAGE_ME : surveySettingsConstants.DISTRIBUTION_PANEL_MESSAGE}</TypographyComponent>
        </AccordionSummary>

        {/*
        panel body
      */}

        {(data.distribute && surveyPermission) &&
          <AccordionDetails>
            <Grid container spacing={3} className={`${classes.setting_expandcontainer} ${"relative"}`}>
              <Grid item sm={12} className={`dflex ${classes.distri_langslt}`} justifyContent="flex-end" alignItems="center">
                <SelectFieldComponent
                  select
                  name="lang"
                  value={lang}
                  disabled={data.languages.length > 1 ? (languageTranslation === false ? true : false) : true}
                  variant="outlined"
                  fullWidth={false}
                  handleChange={(e) => handlelangchange(e)}
                  options={data.languages}
                />
                {languageTranslation === true &&
                  <>
                    <Button variant="contained" className={"buttonOrange txtwhite  ml-1"} disabled={lang === "English" ? true : false} disableElevation size="small" onClick={() => { setTranslationConfirmDialog({ flag: true }) }}>
                      {surveySettingsConstants.APPLY_TRANSLATION_BUTTON}
                    </Button>
                    <InformationComponent>
                      <TypographyComponent variant="tooltipbody">
                        {surveySettingsConstants.APPLY_TRANSLATION_INFO_DISTRIBUTION}
                      </TypographyComponent>
                    </InformationComponent>
                  </>}
              </Grid>

              {/* email tab */}

              <Email classes={classes} data={data} surveyPermission={surveyPermission}
                langcode={langcode} panel={panel} translatedFields={translated_history} />

              {/* ME Survey Settings Tab */}
              {data.type === surveyTypes.MULTI_RATER_FEEDBACK &&
                <ManagerAlerts classes={classes} data={data} surveyPermission={surveyPermission} langcode={langcode} translatedFields={translated_history} panel={panel} />
              }

              {/* sms tab */}
              {data.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
                <Sms classes={classes} data={data} surveyPermission={surveyPermission} langcode={langcode} settingsForm={settingsForm} translatedFields={translated_history} />
              }

              {/* kiosk tab */}
              {data.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
                <Kiosk classes={classes} data={data} anonKioskShortName={anonKioskShortName} panel={panel} translatedFields={translated_history} />
              }

              {/* transflo tab */}
              {surveyPermission.transfloAccess === true && data.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
                <Transflo classes={classes} data={data} surveyPermission={surveyPermission} langcode={langcode} translatedFields={translated_history} />
              }

            </Grid>

          </AccordionDetails>
        }
      </Accordion>

      {translationConfirmDialog.flag && (
        <Dialog open={translationConfirmDialog.flag}>
          <DialogContent>
            <TypographyComponent
              title={surveyQuestionsConstants.TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_DISTRIBUTE.split('\n').map(str => <div>{str}</div>)}
              variant={"div"}
              align={"center"}
              className="txtcenter"
            > </TypographyComponent>
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Yes"}
              color={"primary"}
              size={"small"}
              handleClick={() => { handletranslationsubmit() }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
            <ButtonComponent
              title={"No"}
              color={"default"}
              size={"small"}
              handleClick={() => { setTranslationConfirmDialog({ flag: false }); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
          <DialogContent>
            <PoweredByGoogleComponent />
          </DialogContent>
        </Dialog>
      )}

    </React.Fragment>
  );
}

// default props
DistributionPanel.defaultProps = {
  classes: {},
  data: {},
  anonKioskShortName: {},
  expanded: false,
  handleTabChange: () => { },
  surveyPermission: {}
};

// prop types
DistributionPanel.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  anonKioskShortName: PropTypes.object,
  expanded: PropTypes.any,
  handleTabChange: PropTypes.func,
  surveyPermission: PropTypes.object
};

export default (DistributionPanel);
