import React from 'react';
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

// Constants
import { ReportsConstants, dashboardConstants } from "../../../../config/constants"

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import { Invited } from '../../../../assets/images/svgComponents/ImageComponent';

function CardWidget(props) {
    // props
    const { classes, label, value, label1, value1, widgetPaddingCls, width, style, type, dashboardData, centerLayout, fullHeight, adminPreference, isAnonymous } = props;
    const centerLayoutCondition = fullHeight ? true : centerLayout;

    const frequencyCondition =
        dashboardData?.surveyFrequency && dashboardData?.surveyFrequency.length > 0 ?
            dashboardData?.surveyFrequency.length === 1 && dashboardData?.surveyFrequency?.[0] === 'Always on' ?
                'always-on'
                : dashboardData?.surveyFrequency.length === 1 && dashboardData?.surveyFrequency?.[0] === 'Point in time' ?
                    'point-in-time'
                    : 'Other'
            : '';

    let rollingPercent = 0;
    let rollingParticipationText = 0;
    if (dashboardData?.rollingParticipation && frequencyCondition === 'always-on') {
        rollingPercent = Math.round(dashboardData.rollingParticipation);
        rollingParticipationText = 1;
    } else if (frequencyCondition === 'point-in-time' && value1 && value) {
        rollingPercent = Math.round(value1 / value * 100);
        rollingParticipationText = 2;
    }

    // Dont show if percent is more than 100
    if (rollingPercent > 100) {
        rollingPercent = 0;
        rollingParticipationText = 0;
    }

    const centerLayoutStyle = {
        display: 'grid',
        placeContent: 'center'
    }

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.participation === 'boolean') {
            adminPreference['data']['hideDashWidgets']['participation'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return (
        <>
            {type === "Multi-rater feedback" ?
                <Grid item className={widgetPaddingCls} style={{ width: width, flexGrow: "1", ...style }}>
                    <Grid className={classes.dashboardTile}>
                        {/* Single Tile */}
                        <Grid item>
                            <TypographyComponent variant="h6">{label}</TypographyComponent>
                            <TypographyComponent variant="h2">{value}</TypographyComponent>
                        </Grid>
                        {/*** Single Tile */}
                    </Grid>
                </Grid>
                :
                <Grid item className={`${widgetPaddingCls} ${fullHeight ? 'height100' : ''}`} style={{ width: width, flexGrow: "1", ...style }}>
                    <Grid className={classes.dashboardTile} flexDirection={"column"}>
                        <Grid item className='dflexCenter width100 spacebetween'>
                            <TypographyComponent variant='h5' className={'fontWeight600 width100 txtLeft'}>
                                {dashboardConstants.PARTICIPANTS.TITLE}
                            </TypographyComponent>
                            <ToolTipComponent title={ReportsConstants.CLOSE}>
                                <IconButton onClick={handleClose} size="large">
                                    {DashboardClose()}
                                </IconButton>
                            </ToolTipComponent>
                        </Grid>
                        {/* Added condition to show the total invitied count for pulse surveys */}
                        {(value > 0 && !isAnonymous && dashboardData?.surveyFrequency && Array.isArray(dashboardData?.surveyFrequency) && dashboardData?.surveyFrequency.length === 1) ?
                            <Grid className={`width100 pt-2 ${fullHeight ? 'height100' : ''}`} style={fullHeight ? centerLayoutStyle : {}}>
                                <Grid className='dflexCenter' gap={'50px'}>
                                    {/* Single Tile */}
                                    <Grid item style={{ display: "flex", flexWrap: "wrap" }}>
                                        <Grid className={classes.participationIcon}>
                                            <Invited />
                                        </Grid>
                                        <Grid>
                                            <TypographyComponent variant="h6">{label}</TypographyComponent>
                                            <TypographyComponent variant="h5" className={`fontSize30 fontWeight600`}>{value}</TypographyComponent>
                                        </Grid>
                                    </Grid>
                                    <Grid item style={{ display: "flex", flexWrap: "wrap", }}>
                                        <Grid className={classes.participationIcon}>
                                            <Invited />
                                        </Grid>
                                        <Grid>
                                            <TypographyComponent variant="h6">{label1}</TypographyComponent>
                                            <TypographyComponent variant="h5" className={`fontSize30 fontWeight600`}>{value1}</TypographyComponent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/*** Single Tile */}
                                {
                                    (rollingPercent > 0 && rollingParticipationText > 0) &&
                                    <Grid container alignItems={'flex-end'} justifyContent={'center'} gap={'6px'}>
                                        { rollingParticipationText == 1 &&
                                            <Grid item>
                                                <InformationComponent>
                                                    {dashboardConstants.PARTICIPANTS.ROLLING_PARTICIPATION_HELPER_TEXT}
                                                </InformationComponent>
                                            </Grid>
                                        }
                                        <Grid item>
                                            <TypographyComponent variant='h6' className='fontSize14' color={"#B1B2B3"}>{dashboardConstants.PARTICIPANTS.TITLE}: {rollingPercent}%</TypographyComponent>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                            :
                            <Grid className={'width100 pt-2 height100 displayGrid placeItemCenter'}>
                                <Grid style={{ display: "grid", placeItems: "center" }}>
                                    <Grid item style={{ display: "flex", flexWrap: "wrap", }}>
                                        <Grid className={classes.participationIcon}>
                                            <Invited />
                                        </Grid>
                                        <Grid>
                                            <TypographyComponent variant="h6">{label1}</TypographyComponent>
                                            <TypographyComponent variant="h5" className={`fontSize30 fontWeight600`}>{value1}</TypographyComponent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {/*** Single Tile */}
                                {
                                    (rollingPercent > 0 && rollingParticipationText > 0 && !isAnonymous) &&
                                    <Grid container justifyContent={'center'} gap={'6px'}>
                                        {
                                            <Grid item>
                                                <InformationComponent>
                                                    {dashboardConstants.PARTICIPANTS.ROLLING_PARTICIPATION_HELPER_TEXT}
                                                </InformationComponent>
                                            </Grid>
                                        }
                                        <Grid item className='dflexCenter'>
                                            <TypographyComponent variant='h6' className='fontSize14' color={"#B1B2B3"}>{dashboardConstants.PARTICIPANTS.TITLE}: {rollingPercent}%</TypographyComponent>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
        </>
    )
}
// default props
CardWidget.defaultProps = {
    classes: {},
    label: "",
    label1: "",
    value: "",
    value1: "",
    widgetPaddingCls: "",
    width: "100%",
    style: {},
    centerLayout: false,
    fullHeight: false,
    isAnonymous: false,
    dashboardData: {},
    type: "",
    adminPreference: {},
};

// prop types
CardWidget.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.string,
    label1: PropTypes.string,
    value: PropTypes.string,
    value1: PropTypes.string,
    widgetPaddingCls: PropTypes.string,
    width: PropTypes.number,
    centerLayout: PropTypes.bool,
    fullHeight: PropTypes.bool,
    isAnonymous: PropTypes.bool,
    dashboardData: PropTypes.object,
    type: PropTypes.string,
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(CardWidget);