import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import MeSurveyStyles from "./MeSurveyStyles";

// Import TakeSurvey Header
import TakeSurveyHeader from "./TakeSurvey/TakeSurveyHeader";
import TakeSurveyFooter from "./TakeSurvey/TakeSurveyFooter";
import ErrorPageComponent from "./TakeSurvey/ErrorPageComponent";

// Network Detector
import NetworkDetector from "../../components/NetworkDetector/NetworkDetector";

// Get Table Height and Row Size
import { getTableHeight, getTableRowSize } from "../../config/layout";

// Import Component
import TableComponent from "../../components/TableComponent/TableComponent";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";

// Import Container
import Container from "../Containers/Container";

//import constants
import { TakeSurveyConstants } from "../../config/constants";

// Import Actions
import { meTakeSurveyAction, alertAction } from "../../redux/actions";
import { linkIfy } from "../../helpers/appHelper";

// Import helper
import { getQueryVariable } from "../../helpers";

function MeSurveyWelcome(props) {
    //get props
    const { classes, match, shortUrl } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    const survey = useSelector((state) => state.meTakeSurvey.getOne);
    const questionData = useSelector((state) => state.meTakeSurvey.questions);
    const { surveyLang, isUrlDelete } = useSelector((state) => state.meTakeSurvey);
    const welcome_name_access = useSelector((state) => state?.meTakeSurvey?.survey_data?.welcome_name_access);
    const { languages, survey_name } = questionData;
    const survey_data = useSelector((state) => state.meTakeSurvey.survey_data);
    const { search, meParticipant, message } = useSelector((state) => state.meTakeSurvey);
    const langCode = surveyLang ? surveyLang : "en";
    let { data, page } = search;


    // Table size
    const tableHeight = getTableHeight() - 120;
    const tableRowSize = getTableRowSize();

    // Get Page Reset 
    let isPageReset = getQueryVariable(props.location.search, "reset") === "true";

    useEffect(() => {
        dispatch(meTakeSurveyAction.search(1, tableRowSize, "active", "desc", [], [], shortUrl));
    }, [dispatch]);

    useEffect(() => {
        if (isUrlDelete) {
            dispatch(alertAction.error('Invalid URL'))
        }
    }, [isUrlDelete])

    useEffect(() => {
        if (message === TakeSurveyConstants.INVALID_SURVEY_URL) {
            dispatch(alertAction.error(message))
        }
    }, [message])


    /**
 * Handle Action click button
 * @param {*} field 
 * @param {*} itemId 
 */
    const handelActionButtonClick = (field, itemId) => {
        let actionData = data.find((val) => String(val._id) === String(itemId));
        let { empId, subject_id } = actionData;
        var originUrl = window.location.origin;
        let page = `${originUrl}/#/me-takesurvey/${shortUrl}/${empId}/${subject_id}`;
        // history.replace(page);
        window.location.replace(page);
    }


    /**
 * Handle Table Search Input Change Event
 */
    const onInputChangeRequest = (searchField, searchKey) => {
        const { sFields, sValues } = page;
        if (sFields.indexOf(searchField) >= 0) {
            if (searchKey === "") {
                const index = sFields.indexOf(searchField);
                sValues.splice(index, 1);
                sFields.splice(index, 1);
            } else {
                sValues[sFields.indexOf(searchField)] = searchKey;
            }
        } else {
            sFields.push(searchField);
            sValues.push(searchKey);
        }
        if (!isPageReset) {
            dispatch(meTakeSurveyAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues, shortUrl));
        }
    };
    const welcomeName = meParticipant && meParticipant.firstName && meParticipant.lastName ? `${meParticipant.firstName} ${meParticipant.lastName}` : (meParticipant && meParticipant.firstName ? `${meParticipant.firstName}` : (meParticipant && meParticipant.lastName ? `${meParticipant.lastName}` : ""));

    const welcomeHeader = survey_data?.welcome_header ? (survey_data.welcome_header[langCode] && survey_data.welcome_header[langCode] !== "" ? survey_data.welcome_header[langCode] : survey_data.welcome_header["en"]) : "";
    const welcomeDesc = survey_data?.welcome_description ? (survey_data.welcome_description[langCode] && survey_data.welcome_description[langCode] !== "" ? survey_data.welcome_description[langCode] : survey_data.welcome_description["en"]) : "";

    /**
     * Handle Table pagination Event
     */
    const onChangePagination = (skip) => {
        dispatch(meTakeSurveyAction.search(skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues, shortUrl));
    };

    return (
        <React.Fragment>
            {
                message === TakeSurveyConstants.INVALID_SURVEY_URL ?
                    <ErrorPageComponent takeSurveyError={message} />
                : <Grid container aria-live="polite" aria-relevant="text" className={classes.takeSurvey_welcome_page_root}>
                    <TakeSurveyHeader langCode={langCode} />
                    <Grid item xs={12} className="mt-1" justifyContent="space-between" alignItems="center">
                        <Grid item style={{ padding: "0px 12px" }}>
                            <Grid className={"dflex"} alignItems="center" justifyContent="center">
                                <div className={"fontSize30 txtcenter"} dangerouslySetInnerHTML={{ __html: welcomeHeader ? linkIfy(welcomeHeader) : `` }} />
                                <div className={"fontSize30 txtcenter pl5"} dangerouslySetInnerHTML={{ __html: linkIfy(welcomeName && welcome_name_access ? welcomeName : "") }} />
                            </Grid>
                            <Grid className={"dflex"} alignItems="center" justifyContent="center">
                                <div className={"txtSemiBold txtcenter txtgrey fontSize18 fontMontserrat orderedList bulletinLeft"}
                                    dangerouslySetInnerHTML={{ __html: linkIfy(welcomeDesc ? welcomeDesc : "") }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Container page={"MEtakeSurvey"} className={classes.takeSurvey_table_container}>
                        <Grid item className={classes.takeSurvey_container}>


                            <Grid container alignItems="center">
                                <Grid item xs={12} className="survey-list">

                                    <TableComponent
                                        data={data}
                                        header={page.fieldFilters.headerCols}
                                        field={page.fieldFilters.fieldCols}
                                        search={page.fieldFilters.searchCols}
                                        actions={["TAKESURVEY"]}
                                        height={tableHeight}
                                        page={page.skip}
                                        count={page.count}
                                        rowsPerPage={page.limit}
                                        // links={[page.fieldFilters.fieldCols[0]]}
                                        sort={page.sort}
                                        sortBy={page.sortBy}
                                        sFields={page.sFields}
                                        sValues={page.sValues}
                                        noDataErrorMessage={"No Records Found"}
                                        removeTooltipCols={[...page.fieldFilters.fieldCols]}
                                        // paginationVisible={!page.lastPage}
                                        alignCenterFields={["status"]}
                                        // deleteContent={surveyConstants.DELETE_CONTENT}
                                        // handleNavigateAction={handleNavigateAction}
                                        onChangePagination={onChangePagination}
                                        // onSortRequest={onSortRequest}
                                        onInputChangeRequest={onInputChangeRequest}
                                        handelActionButtonClick={handelActionButtonClick}
                                    >
                                    </TableComponent>

                                </Grid>
                            </Grid>

                        </Grid>
                    </Container>
                    <TakeSurveyFooter langCode={langCode} />
                </Grid>
            }
            

            <NetworkDetector />
        </React.Fragment>
    );
}
// default props
MeSurveyWelcome.defaultProps = {
    classes: {},
};

// prop types
MeSurveyWelcome.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(MeSurveyStyles)(MeSurveyWelcome);
