import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import { isIE } from 'react-device-detect';

// Layout Style
import PublicLayoutStyle from "./PublicLayoutStyle.jsx";

// Import Cofig Constants
import { errorsConstants } from "../../config/constants";

// import child router components
import PageNoIeSupport from "../../containers/PageNoIeSupport/PageNoIeSupport";

//import component 
// import LiveChatComponent from "../../components/LiveChatComponent/LiveChatComponent";

function PublicLayout(props) {
  return (
    <>
      {isIE ? <PageNoIeSupport errorMsg={errorsConstants.IE_NOT_SUPPORTED} /> :
        <React.Fragment>
          {props.children}
        </React.Fragment>
      }
      {/* <LiveChatComponent showChat={false} page={props.location} /> */}
    </>
  );
}

// Define Props Types
PublicLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element
};

export default withStyles(PublicLayoutStyle)(PublicLayout);
