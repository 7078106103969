import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import MeSurveyStyles from "./MeSurveyStyles";

// Import Actions
import { meTakeSurveyAction } from "../../redux/actions";

import TakeSurvey from "./TakeSurvey/TakeSurvey";
import ThankYouComponent from "./TakeSurvey/ThankYouComponent";
import ErrorPageComponent from "./TakeSurvey/ErrorPageComponent";

//Import Loading Container
import LoadingComponent from "../TakeSurvey/LoadingComponent";

function MeTakeSurvey(props) {
    //get props
    const { match, takeSurveyUrl, queryString, surveyDeviceType, shortUrl } = props;
    let { params } = match;

    // Define Dispatch
    const dispatch = useDispatch();

    params = { ...params, shortUrl }
    //Get Survey
    useEffect(() => {
        const surveyGetQuery = { ...params, takeSurveyUrl, queryString, surveyDeviceType };
        dispatch(meTakeSurveyAction.getSurvey(surveyGetQuery));
    }, [dispatch]);

    const { takeSurveyStatus, takeSurveyErrors } = useSelector((state) => state.meTakeSurvey);

    return (
        <React.Fragment>
            {takeSurveyStatus === true ? (
                <TakeSurvey {...props} />
            ) : takeSurveyStatus == "done" ? (
                <ThankYouComponent props={{ ...props }} match={match} shortUrl={shortUrl} />
            ) : takeSurveyErrors == null ? (
                <LoadingComponent takeSurveyStatus={takeSurveyStatus} />
            ) : (
                <ErrorPageComponent takeSurveyError={takeSurveyErrors} />
            )}
        </React.Fragment>
    );
}

// default props
MeTakeSurvey.defaultProps = {
    classes: {},
};

// prop types
MeTakeSurvey.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(MeSurveyStyles)(MeTakeSurvey);
