import React, { useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

if (window.cachedCharts === undefined) {
    window.cachedCharts = {
        hotspot: false,
    };
}

export default function CacheComponent({ timeout, onUpdate, chart, isCached, className }) {
  useEffect(() => {
    setTimeout(() => {
      if (window.cachedCharts[chart] === false) {
        window.cachedCharts[chart] = true;
        onUpdate();
      }
    }, timeout);
  }, []);
  return !window.cachedCharts[chart] && isCached ? (
    <div style={{ display: "flex" }}>
      <CircularProgress  size={18} className={className} />
      <div style={{ marginLeft: "8px", marginRight: "8px" }}>
        Updating for latest records
      </div>
    </div>
  ) : null;
}
