import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// import Components
import ImportFileComponent from "../../../../components/ImportFileComponent/ImportFileComponent";
import DeleteDialogComponent from "../../../../components/DeleteDialogComponent/DeleteDialogComponent";

// Import Actions
import { excelAction, alertAction } from "../../../../redux/actions";

// import constants
import { participantConstants, excelConstants } from "../../../../config/constants";

function ImportPage(props) {
  const dispatch = useDispatch();
  const [importData, setImportData] = useState({})
  const [confirmationOne, setConfirmationOne] = useState(false);
  const [confirmationTwo, setConfirmationTwo] = useState(false);

  // update file data in reducer and redirect to submit page
  const importFileData = (file) => {
    const fileData = file ? file : {};
    // Redirect To Submit Page
    if (fileData && fileData.path) {
      setConfirmationOne(true);
      setImportData(fileData);
    } else {
      dispatch(alertAction.error("Please select a file to upload"));
    }
  };

  const fileImport = () => {
    const fileData = importData;
    const excelType = excelConstants.BULK_DELETE_PARTICIPANTS_UPLOAD;
    var formData = new FormData();
    formData.append("file", fileData);
    formData.append("excelType", excelType);
    setConfirmationOne(false);
    setConfirmationTwo(false);
    dispatch(excelAction.upload(formData, excelType, null, true));
  }

  const clearConfirmations = () => {
    setConfirmationOne(false);
    setConfirmationTwo(false);
  }

  return (
    <>
      <ImportFileComponent
        backBtnURL={"/manage_people/participants/bulkDelete"}
        headerText={participantConstants.BULK_DELETE}
        dropText={participantConstants.UNASSIGN_IMPORT}
        importFileData={importFileData}
      />

      {/* First Confirmation Validation */}
      <DeleteDialogComponent
        open={confirmationOne && !confirmationTwo}
        content={participantConstants.BULK_DELETE_IMPORT_CONFIRMATION_ONE}
        handleDeleteAction={() => setConfirmationTwo(true)}
        handleDeleteOnCloseAction={() => setConfirmationOne(false)}
      />

      {/*  */}
      < DeleteDialogComponent
        open={confirmationOne && confirmationTwo}
        content={participantConstants.BULK_DELETE_IMPORT_CONFIRMATION_TWO}
        handleDeleteAction={(e) => fileImport(e)}
        handleDeleteOnCloseAction={(e) => clearConfirmations(e)}
      />
    </>
  );
}
// default props
ImportPage.defaultProps = {
  match: {}
};

// prop types
ImportPage.propTypes = {
  match: PropTypes.object
};
export default ImportPage;
