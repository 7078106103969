import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "./TakeSurveyStyles";

// Import Actions
import { alertAction } from "../../redux/actions";

//Import Constants
import { TakeSurveyConstants } from "../../config/constants";
import LoadingPage from "../PageSetupPreference/PageSetUpPreference";

function LoadingComponent(props) {
  const { classes, takeSurveyStatus } = props;

  //"done", "closed", "pending", "expired"
  const errMessage =
    takeSurveyStatus == "expired"
      ? TakeSurveyConstants.ERROR_SURVEY_EXPIRED
      : takeSurveyStatus == "closed"
      ? TakeSurveyConstants.ERROR_SURVEY_CLOSED
      : takeSurveyStatus == "pending"
      ? TakeSurveyConstants.ERROR_SURVEY_NOT_STARTED_YET
      : false;

  // Define Dispatch
  const dispatch = useDispatch();
  if (errMessage) {
    dispatch(alertAction.error(errMessage));
  }

  return (
    <>
      <Grid container className={classes.thankYouRoot}>
        <Grid item sm={12} className={classes.thankYouContainer}>
          <span className={classes.textDescription}>{!errMessage ? <LoadingPage /> : null}</span>
        </Grid>
      </Grid>
    </>
  );
}

// default props
LoadingComponent.defaultProps = {
  classes: {},
};

// prop types
LoadingComponent.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(TakeSurveyStyles)(LoadingComponent);
