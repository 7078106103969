/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ValidatorForm } from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import { Accordion, Link } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SvgIcon from '@mui/material/SvgIcon';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

// Import Icons
import { UserAudience } from "../../../../assets/images/svgComponents/ImageComponent";

import { CopySetting } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
import QueryBuilderComponent from "../../../../components/QueryBuilderComponent/QueryBuilderComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";

// constants
import { surveySettingsConstants, errorsConstants, participantConstants } from "../../../../config/constants";
import { surveyConstant } from "../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, queryBuilderAction, surveyAction, alertAction } from "../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData } from "../../../../helpers";

import { fieldDataTypes, deFormatQuery } from "../../../../components/QueryBuilderComponent/config";
import { surveyTypes } from "../../../../config/constants";

import { compareBetweenQueries } from "../../../../components/QueryBuilderComponent/config";
function AudiencePanel(props) {
  const { classes, data, expanded, anonKioskShortName, panel, panelError, settingsForm, isError } = props;
  const dispatch = useDispatch();
  const { audience: audienceQueryBuilderData } = useSelector(state => state.queryBuilder);
  const { user } = useSelector(state => state.profile);
  const dlaAccess = user?.queryDLA ?? '';
  const anonymous_url = `${window.location.protocol}//${window.location.host}/#${surveySettingsConstants.ANONYMOUS_SURVEY_URL_MESSAGE}${data.distribute && data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.shortName
    ? data.distribute.audience.anonymous.shortName : ""}`;
  const comparedQueryResult = dlaAccess && data?.distribute?.triggers ? compareBetweenQueries(dlaAccess, data?.distribute?.triggers) : false;

  const [queryBuilder, setQueryBuilder] = useState(false)
  const [assignSurveyPopup, setAssignSurveyPopup] = useState(false);
  const [showAutoPopulatePopup, setShowAutoPopulatePopup] = useState(false);
  const [showDisabledAutoTriggerPopup, setShowDisabledAutoTriggerPopup] = useState(comparedQueryResult);

  useEffect(() => {
    ValidatorForm.addValidationRule("anonymousNameExists", (value) => {
      if (anonKioskShortName.anonymous.indexOf(data.distribute.audience.anonymous.shortName) !== -1) {
        return false;
      } else {
        return true;
      }
    });
    ValidatorForm.addValidationRule("specialCharacters", (value) => {
      // Regex for Valid Characters i.e. Alphabets, Numbers, Underscore and Space.
      if (data.distribute.audience.anonymous.shortName) {
        const regex = /^[a-zA-Z0-9_-]+$/;

        // Validate TextBox value against the Regex.
        const isValid = regex.test(data.distribute.audience.anonymous.shortName);
        return isValid;
      } else {
        return true;
      }
    });
    ValidatorForm.addValidationRule("maximumLength", (value) => {
      if (data.distribute.audience.anonymous.shortName.length > 100) {
        return false;
      } else {
        return true;
      }
    });
  }, [data]);

  useEffect(() => {
    // call fieldname action if the default value is trigger
    if (data.distribute.audience &&
      data.distribute.audience.select === surveySettingsConstants.RADIOGROUP_TRIGGER) {
      dispatch(queryBuilderAction.getFieldNames("audience"));
    }
    setTimeout(() => {
      setQueryBuilder(true)
    }, 2000);
  }, [dispatch]);

  /**
   * Handle Radio Group Changes
   * @param {*} event
   */
  const radiogrouphandlechange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
    // trigger get field names if changes
    if (event.target.value === surveySettingsConstants.RADIOGROUP_TRIGGER) {
      if (data?.distribute?.triggers === null && Boolean(dlaAccess) && !showAutoPopulatePopup) {
        setShowAutoPopulatePopup(true);
        setShowDisabledAutoTriggerPopup(comparedQueryResult);
        dispatch(surveyAction.updateSurveyAutomaticTriggers(dlaAccess));
      } else if (data?.distribute?.triggers && Boolean(dlaAccess) && !showDisabledAutoTriggerPopup) {
        // const comparedResult = compareBetweenQueries(dlaAccess, data?.distribute?.triggers)
        setShowDisabledAutoTriggerPopup(comparedQueryResult)
      }
      dispatch(queryBuilderAction.getFieldNames("audience"));
    }
  };

  /**
   * Handle Change Event
   * @param {*} panel
   */
  const handleChange = (event) => {
    if (!data.isLoading) {
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
   * Handle Tab Change Event
   * @param {*} name
   */
  const handleTabChange = (panel) => (event, isExpanded) => {
    if (isExpanded && data?.distribute?.audience?.select === surveySettingsConstants.RADIOGROUP_TRIGGER) {
      if (data?.distribute?.triggers === null && Boolean(dlaAccess) && !showAutoPopulatePopup) {
        setShowAutoPopulatePopup(true);
        setShowDisabledAutoTriggerPopup(comparedQueryResult);
        dispatch(surveyAction.updateSurveyAutomaticTriggers(dlaAccess));
      } else if (data?.distribute?.triggers && Boolean(dlaAccess) && !showDisabledAutoTriggerPopup) {
        // const comparedResult = compareBetweenQueries(dlaAccess, data?.distribute?.triggers)
        setShowDisabledAutoTriggerPopup(comparedQueryResult)
      } 
    }
    props.handleTabChange({ isExpanded, panel });
  };

  /**
   * handle trigger
   */
  const handleTrigger = () => {
    setAssignSurveyPopup(false);
    const distribute = {
      triggers: data.distribute.triggers
    };
    // validate query builder and then assign survey
    if (settingsForm && settingsForm.current) {
      settingsForm.current.isFormValid(false).then(formErrors => {
        const audienceErr = settingsForm.current.childs.filter(s => !s.state.isValid && s.props.panel === "audience");
        if (audienceErr.length === 0) {
          dispatch(surveyAction.triggerAssignSurvey({ _id: data._id, distribute, frequency: data.frequency, pulse_obj: data.pulse_obj }));
        }
      });
    }
  };

  const hasDateFieldInQueryBuilder = () => {
    if (data.distribute.audience && data.distribute.audience.select === surveySettingsConstants.RADIOGROUP_TRIGGER && data._id) {
      if (data.distribute.triggers && audienceQueryBuilderData.fields && audienceQueryBuilderData.fields.length > 0) {
        const { fieldNames } = deFormatQuery(data.distribute.triggers)
        return audienceQueryBuilderData.fields.filter(f => fieldNames && fieldNames.indexOf(f.name) > -1
          && f.type === fieldDataTypes.DATE).length > 0
      }
    }
    return false
  }

  return (
    <React.Fragment>
    <Accordion
      className={`mb-2 pe-accordion ${panelError || isError ? "panel-error" : ""}`}
      expanded={expanded === "audience-panel" ? true : false}
      onChange={handleTabChange("audience-panel")}
    >

      {/*
        panel header summary
      */}

      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <SvgIcon className={`${classes.settings_icons} ${expanded === "audience-panel" ? "panel-active" : "panel-inactive"} `} alt={"settings"} xmlns="http://www.w3.org/2000/svg" width="30" height="17.818" viewBox="0 0 30 17.818">
          {UserAudience()}
        </SvgIcon>
        <TypographyComponent
          className={` ${classes.setting_accordheading} ${expanded === "audience-panel" ? "panel-active" : ""} `}
          variant={"h5"}
        >{surveySettingsConstants.AUDIENCE_PANEL_TITLE}</TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "audience-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{surveySettingsConstants.AUDIENCE_PANEL_MESSAGE}</TypographyComponent>
      </AccordionSummary>

      {/*
        panel body
      */}

      {data.distribute &&
        <AccordionDetails>
          <Grid container spacing={3}
            className={`${classes.setting_expandcontainer} `}>
            <Grid item xs={12} className="pt-0 mt-1">
              <RadioGroup aria-label="distribute.audience.select"
                row name="distribute.audience.select"
                value={data.distribute.audience && data.distribute.audience.select ? data.distribute.audience.select : surveySettingsConstants.RADIOGROUP_MANUAL}
                onChange={radiogrouphandlechange}
                className="spacebetween">
                {data.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
                  <Grid>
                    <FormControlLabel
                      value={surveySettingsConstants.RADIOGROUP_TRIGGER}
                      control={<Radio color="primary" />}
                      label={surveySettingsConstants.AUTOMATIC_TRIGGER} />
                    <InformationComponent>
                      <TypographyComponent variant="tooltipbody">
                        {surveySettingsConstants.AUTOMATIC_TRIGGER_HELP}
                      </TypographyComponent>
                    </InformationComponent>
                  </Grid>

                }
                <React.Fragment>
                  <Grid>
                    <FormControlLabel
                      value={surveySettingsConstants.RADIOGROUP_MANUAL}
                      control={<Radio color="primary" />}
                      label={surveySettingsConstants.MANUALLY_ASSIGN} />
                    <InformationComponent>
                      {surveySettingsConstants.MANUALLY_ASSIGN_HELP}
                    </InformationComponent>
                    {data.type === surveyTypes.MULTI_RATER_FEEDBACK &&
                      <Grid>
                        <TypographyComponent variant="tooltipbody" className={`${classes.triggerInstructionColor}`}>
                          {surveySettingsConstants.ME_HELP_LINK}
                          <Link
                            href="https://20709424.hs-sites.com/manager-360#survey-setup"
                            target="_blank"
                            className={"pr5"}
                          >
                            {"(click here for setup instructions)."}
                          </Link>
                        </TypographyComponent>
                      </Grid>
                    }
                  </Grid>

                </React.Fragment>
                {data.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
                  <Grid>
                    <FormControlLabel
                      value={surveySettingsConstants.RADIOGROUP_ANONYMOUS}
                      control={<Radio color="primary" />}
                      label={surveySettingsConstants.ANONYMOUS} />
                    <InformationComponent>
                      <TypographyComponent variant="tooltipbody">
                        {surveySettingsConstants.ANONYMOUS_HELP}
                      </TypographyComponent>
                    </InformationComponent>
                  </Grid>
                }
              </RadioGroup>
            </Grid>
            {data.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
              <Grid item xs={12} className="pb-1 mb-1">
                <TypographyComponent variant="tooltipbody" className={`${classes.triggerInstructionColor}`}>
                  <Link
                    href="https://20709424.hs-sites.com/trigger-events"
                    target="_blank"
                    className={"pr5"}
                    // component="button"
                    onClick={(event) => {
                      event.preventDefault()
                      window.open("https://20709424.hs-sites.com/trigger-events")
                    }}
                  >
                    {"Click here"}
                  </Link>
                  {surveySettingsConstants.AUTOMATIC_TRIGGER_VIDEO_LINK}
                </TypographyComponent>
              </Grid>
            }
            {/*
              Displays when selected radiogroup is trigger
            */}

            {(data.distribute.audience && data.distribute.audience.select === surveySettingsConstants.RADIOGROUP_TRIGGER) && data._id &&
              <Grid container spacing={3}
                className={classes.audience_expandinsidediv}>
                {hasDateFieldInQueryBuilder() &&
                  <Grid className={"querybuilderhelptext"}>
                    {participantConstants.QUERYBUILDER_DATE_INTEGER_HELP}
                  </Grid>
                }
                {queryBuilder &&
                  <QueryBuilderComponent
                    name="distribute.triggers"
                    value={data.distribute.triggers || ""}
                    key={"Audience-QueryBuilder"}
                    fieldOnChange={(fieldName) => {
                      dispatch(queryBuilderAction.getFieldValues("audience", fieldName));
                    }}
                    handleQueryChange={(json, mongoQuery) => {
                      handleChange({ name: "distribute.triggers", value: mongoQuery }, data);
                    }}
                    classes={classes}
                    collectionFields={audienceQueryBuilderData.fields}
                    values={audienceQueryBuilderData.fieldValues}
                    query={data.distribute.triggers}
                    dlaQuery={dlaAccess}
                    isDlaUser={Boolean(dlaAccess)}
                    isCustomDla={true}
                    isDisableAll={comparedQueryResult}
                    isLoadedFromDB={data._id}
                    panel={panel}
                    allowedOperators={[
                      {
                        type: fieldDataTypes.INTEGER,
                        allowedOperators: ["between", "$lte", "$gte"]
                      },
                      {
                        type: fieldDataTypes.DATE,
                        allowedOperators: ["between", "$lte", "$gte"]
                      }
                    ]}
                    restrictRepetitiveFields={true}
                    isAutomaticTriggers={true}
                  />
                }
              </Grid>
            }

            {/*
              Displays when selected radiogroup is anonymous
            */}

            {data.distribute.audience && data.distribute.audience.select === surveySettingsConstants.RADIOGROUP_ANONYMOUS &&
              <Grid container spacing={3}
                className={classes.audience_expandinsidediv}>
                <Grid item md={4} xs={12} className="pt-2">
                  <TypographyComponent variant="body1">
                    {surveySettingsConstants.ANONYMOUS_SURVEY_URL}
                    <InformationComponent>
                      <TypographyComponent variant="tooltipbody">
                        {surveySettingsConstants.ANONYMOUS_SURVEY_URL_HELP}
                      </TypographyComponent>
                    </InformationComponent>
                  </TypographyComponent>
                  <Grid container alignItems="center">
                    <Grid item md={12} xs={12}>
                      <TextFieldComponent
                        id="distribute.audience.anonymous.shortName"
                        name="distribute.audience.anonymous.shortName"
                        size={"small"}
                        pattern="^[a-zA-Z0-9_.-]*$"
                        fullWidth={true}
                        variant="outlined"
                        validators={["required", "anonymousNameExists", "specialCharacters", "maximumLength"]}
                        errorMessages={[errorsConstants.required, errorsConstants.anonymousNameExists, errorsConstants.specialCharacters, errorsConstants.maximumLength]}
                        value={data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.shortName
                          ? data.distribute.audience.anonymous.shortName : ""}
                        handleChange={(e) => handleChange(e)}
                        className={`${classes.settings_input}`}
                        panel={panel}
                      />
                    </Grid>
                  </Grid>

                </Grid>
                <Grid item md={4} xs={12}>
                  <SwitchComponent
                    name="distribute.audience.anonymous.multiResponse"
                    checked={data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.multiResponse
                      ? data.distribute.audience.anonymous.multiResponse : surveySettingsConstants.CHECKED_FALSE}
                    disabled={data.distribute.editresp ? surveySettingsConstants.CHECKED_TRUE : surveySettingsConstants.CHECKED_FALSE}
                    color="primary"
                    handleChange={(e) => handleChange(e)}
                    label={surveySettingsConstants.MULTIPLE_RESPONSE}
                  />
                  <InformationComponent>
                    <Grid container>
                      <TypographyComponent variant="tooltipbody" ><b>ON: </b>{surveySettingsConstants.MULTIPLE_RESPONSE_TOOLTIP_ON}</TypographyComponent>
                      <TypographyComponent variant="tooltipbody" ><b>OFF: </b>{surveySettingsConstants.MULTIPLE_RESPONSE_TOOLTIP_OFF}</TypographyComponent>
                    </Grid>

                  </InformationComponent>

                </Grid>
                <Grid item md={4} xs={12}>
                  <SwitchComponent
                    name="distribute.audience.anonymous.ispwdProtection"
                    checked={data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.ispwdProtection ? data.distribute.audience.anonymous.ispwdProtection : surveySettingsConstants.CHECKED_FALSE}
                    color="primary"
                    handleChange={(e) => handleChange(e)}
                    label={data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.ispwdProtection ? surveySettingsConstants.PASSWORD_PROTECTION : surveySettingsConstants.PASSWORD_PROTECTIONIMP}
                  />
                  <InformationComponent >
                    <Grid container>
                      <TypographyComponent variant="tooltipbody">
                        <b>{"ON: "}</b>{surveySettingsConstants.PASSWORD_PROTECTION_HELP_ON}
                      </TypographyComponent>
                      <TypographyComponent variant="tooltipbody">
                        <b>{"OFF: "}</b>{surveySettingsConstants.PASSWORD_PROTECTION_HELP_OFF}
                      </TypographyComponent>
                    </Grid>

                  </InformationComponent>

                  {/*
                    Displays when password protection is enabled
                  */}

                  {data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.ispwdProtection === surveySettingsConstants.CHECKED_TRUE &&
                    <Grid container alignItems="center">
                      <Grid item md={8} xs={12}>
                        <TextFieldComponent id="distribute.audience.anonymous.password"
                          name="distribute.audience.anonymous.password"
                          size={"small"}
                          fullWidth={true}
                          variant="outlined"
                          validators={['required']}
                          value={data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.password ? data.distribute.audience.anonymous.password : ""}
                          handleChange={(e) => handleChange(e)}
                          className={`${classes.settings_input}`} />
                      </Grid>
                    </Grid>
                  }
                </Grid>
                <Grid item md={12} xs={12}>
                  <TypographyComponent variant="h6" className="txtgrey">
                    {surveySettingsConstants.ANONYMOUS_SURVEY_URL}
                    <CopyToClipboard
                      text={anonymous_url}
                      onCopy={() => {
                        dispatch(alertAction.successAlert("Anonymous Survey URL copied!"));
                      }}>
                      <IconButton className={`${classes.anonymousCopyIcon} p-1 `} size="large">
                        <SvgIcon className={classes.iconContainer}>
                          {CopySetting()}
                        </SvgIcon>

                      </IconButton>
                    </CopyToClipboard>
                  </TypographyComponent>
                  <TypographyComponent variant="h6" className="txtgrey fontSize13 breakWord">
                    {anonymous_url}
                  </TypographyComponent>
                </Grid>
              </Grid>
            }
            {data.distribute.audience && data.distribute.audience.select === surveySettingsConstants.RADIOGROUP_TRIGGER &&
              <Grid item xs={12}>
                <ButtonComponent
                  title={"Assign Survey Now"}
                  color={"primary"}
                  size={"small"}
                  disabled={comparedQueryResult ? true : data.distribute.triggers ? (data.status === surveySettingsConstants.SURVEY_STATUS_CLOSED ? true : false) : true}
                  handleClick={() => setAssignSurveyPopup(true)}
                ></ButtonComponent>
                <InformationComponent>
                  <TypographyComponent variant="tooltipbody">
                    {surveySettingsConstants.ASSIGN_SURVEY_HELP}
                  </TypographyComponent>
                </InformationComponent>

              </Grid>
            }
          </Grid>
        </AccordionDetails >
      }
    </Accordion >

    {/* Assign SUrvey Popup */}
    <Dialog open={assignSurveyPopup}>
      <DialogContent>
        <TypographyComponent
          title={surveySettingsConstants.ASSIGN_SURVEY_NOW_POPUP}
          variant={"div"}
          align={"center"}
          className="txtcenter"
        > </TypographyComponent>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          title={"Yes"}
          color={"primary"}
          size={"small"}
          handleClick={() => handleTrigger()}
          componentClassName={"marginLR5"}
        ></ButtonComponent>
        <ButtonComponent
          title={"Cancel"}
          color={"default"}
          size={"small"}
          handleClick={() => setAssignSurveyPopup(false)}
          componentClassName={"marginLR5"}
        ></ButtonComponent>
      </DialogActions>
    </Dialog>

    {/* Assign Survey Auto Populate Triggers for DLA User */}
    <Dialog open={showAutoPopulatePopup}>
      <DialogContent className="txtcenter">
        <TypographyComponent
          title={surveySettingsConstants.ASSIGN_SURVEY_AUTO_POPULATE}
          variant={"div"}
          align={"center"}
          className="txtcenter"
        > </TypographyComponent>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          title={"Okay"}
          color={"primary"}
          size={"small"}
          handleClick={() => setShowAutoPopulatePopup(false)}
        ></ButtonComponent>
      </DialogActions>
    </Dialog>

    {/* Disable Automatic Triggers for DLA User when triggers not matched */}
    <Dialog open={showDisabledAutoTriggerPopup}>
      <DialogContent className="txtcenter" style={{padding: '20px 14px'}}>
        <TypographyComponent
          title={surveySettingsConstants.ASSIGNED_SURVEY_DISABLE_AUTOMATIC_TRIGGERS}
          variant={"div"}
          align={"center"}
          className="txtcenter"
        > </TypographyComponent>
      </DialogContent>
      <DialogActions>
        <ButtonComponent
          title={"Okay"}
          color={"primary"}
          size={"small"}
          handleClick={() => setShowDisabledAutoTriggerPopup(false)}
        ></ButtonComponent>
      </DialogActions>
    </Dialog>
    </React.Fragment>
  );
}

// default props
AudiencePanel.defaultProps = {
  classes: {},
  data: {},
  anonKioskShortName: {},
  expanded: false,
  handleTabChange: () => { },
  panel: "",
  isError: false,
};

// prop types
AudiencePanel.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  anonKioskShortName: PropTypes.object,
  expanded: PropTypes.any,
  handleTabChange: PropTypes.func,
  panel: PropTypes.string,
  isError: PropTypes.bool,
};

export default (AudiencePanel);
