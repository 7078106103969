const SurveyPreviewStyles = (theme) => ({
  previewRoot: {
    padding: "0px",
    "& .MuiTabs-root": {
      zIndex: "999",
    },
  },
  previewBox: {
    height: "90vh",
  },
  previewLoadingRoot: {
    padding: "0px",
    position: "absolute",
    zIndex: "999999",
    background: "#fff",
    opacity: "0.75",
    height: "100%",
  },
  PreviewLoadingPage: {
    display: "flex",
    width: "100%",
    height: "100%",
    justifyContent: "center",
    textAlign: "center",
    zIndex: "9999999",
  },
  confirmBox: {
    height: "100%",
    alignItems: "center",
    width: "50%",
    margin: "auto",
    "& .title": {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: "600",
      marginBottom: "1rem",
    },
    "& p": {
      fontSize: "1rem",
      textAlign: "justify",
    },
  },
  sectionSurvey: {
    width: "77.5%",
    maxWidth: "77.5%",
    position: "relative",
  },
  sectionFilter: {
    width: "22.5%",
    maxWidth: "22.5%",
    backgroundColor: "#e3e4e6",
    zIndex: "99999999999",
    position: "relative",
  },
  filterItems: {
    "& .filterItem": {
      display: "block",
    },
  },
  surveyPreviewClose: {
    position: "absolute",
    right: "0",
    margin: "0.25rem",
    borderRadius: "10px",
    padding: "0px",
    zIndex: "999999999999",
    "& span": {
      padding: "0px",
      color: "#111"
    },
    "&.MuiButton-root:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.04) !important"
    }
  },
  sendPreviewMailRoot: {
    padding: "22px 22px 0px 22px",
    "& .previewClose": {
      float: "right",
    },
  },
  chipInput: {
    "& .MuiInputBase-root": {
      borderRadius: "0px",
      lineHeight: "20px",
      padding: "1rem",
    },
    "& .MuiChip-root": {
      background: "#EDF9FF",
    },
    "& .MuiChip-deleteIcon": {},
  },

  //Take Survey Header
  rootHeader: {
    flexGrow: 1,
  },
  headerAppBar: {
    height: 56,
    position: "relative"
  },
  headerToolBar: {
    height: "auto",
  },
  headerRightBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  headerRight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
  },
  headerUserInfo: {
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "10px",
    "& span": {
      marginRight: "5px",
      height: "26px",
      lineHeight: "26px",
    },
  },
  logo: {
    marginRight: "20px",
    flexGrow: 1,
  },
  headerLogoImg: {
    maxHeight: "45px",
  },

  //Preview Opitions
  rootBar: {
    height: "56px !important",
  },
  rootToolBar: {
    minHeight: "56px !important",
  },
  rootPreview: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - 350px)`,
    marginRight: "350px",
  },
  pe_drawer: {
    width: "350px",
    flexShrink: 0,
    "& .MuiDrawer-paper": {
      padding: "15px",
      background: "#E3E4E6",
    },
  },
  drawerPaper: {
    width: "350px",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  //Preview Opitions

  takeSurvey_container: {
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "6px",
    padding: "15px",
  },

  //Tabs
  rightPanelAppBarRoot: {
    background: "transparent",
    height: "45px",
  },
  tabcharlimit: {
    whiteSpace: "nowrap",
  },
  rightPanelTabsRoot: {
    "& .MuiTab-root": { maxWidth: "100%" },
  },
  rightPanelTabRoot: {
    borderRadius: "10px 10px 0px 0px",
    backgroundColor: "#EBECED",
    margin: "0px 4px",
    color: "rgba(0, 0, 0, 0.87) !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#54C0FD !important",
    },
    "&:hover .peTabEdit, &:hover .peTabDelete": {
      visibility: "visible !important",
      filter: "brightness(0) invert(1)",
    },
    "&$selected": {
      color: "#fff",
      backgroundColor: "#54C0FD",
    },
    "&:focus": {
      color: "#fff",
      backgroundColor: "#54C0FD",
    },
  },
  rightPanelTabSelected: {
    backgroundColor: "#54C0FD !important",
    color: "#fff !important",
  },

  surveyCateDescription: {
    color: "black",   
    fontWeight: "bold",  
    fontStyle: "normal" 
  },

  //Multi Quest Answer
  answerSet: {
    padding: "0px 6px",
    "& .answerSettxt": {
      fontSize: "10px !important",
      wordBreak: "break-word",
      overflowWrap: "anywhere !important",
    },
  },
  answerSetTxt: {
    overflowWrap: "anywhere !important",
  },
  singleSelect: {
    padding: "6px",
  },
  singleSelectTile: {
    padding: "10px",
    width: "100%",
    padding: "5px",
    borderRadius: "0px",
    color: "#000",
    minWidth: "50px !important",
    "& span.MuiButton-label": {
      padding: "0px !important",
    },
    "&.activeSelect": {
      background: "#FFA12D",
    },
    "&.activeSelectBlue": {
      background: "#54C0FD",
    },
    "&.op0": {
      backgroundColor: "rgb(184, 182, 182)",
    },
    "&.op1": {
      backgroundColor: "rgba(255,161,45,0.1)",
    },
    "&.op2": {
      backgroundColor: "rgba(255,161,45,0.2)",
    },
    "&.op3": {
      backgroundColor: "rgba(255,161,45,0.3)",
    },
    "&.op4": {
      backgroundColor: "rgba(255,161,45,0.4)",
    },
    "&.op5": {
      backgroundColor: "rgba(255,161,45,0.5)",
    },
    "&.op6": {
      backgroundColor: "rgba(255,161,45,0.6)",
    },
    "&.op7": {
      backgroundColor: "rgba(255,161,45,0.7)",
    },
    "&.op8": {
      backgroundColor: "rgba(255,161,45,0.8)",
    },
    "&.op9": {
      backgroundColor: "rgba(255,161,45,0.9)",
    },
    "&.op10": {
      backgroundColor: "rgba(255,161,45,1)",
    },
  },

  singleQuestion: {
    borderBottom: "0.2px #EBECED solid",
    padding: "15px 0",
  },
  singleSelectQuestion: {
    display: "flex",
    flexDirection: "column-reverse",
  },
  //Matrix
  subQuestionChoices: {
    textAlign: "center",
  },
  singleQuestionMatrix: {
    padding: "10px",
  },
  subQuestionChoicesRadio: {
    padding: "0px !important",
    "&$checked": {
      color: "#54C0FD",
    },
    "&.Mui-checked": {
      color: "#54C0FD",
    },
  },
  table: {
    "& .MuiTableCell-root": {
      padding: "8px 5px",
      borderBottom: "none !important",
    },
  },
  matricTabHeader: {
    "&..MuiTableCell-head": {},
  },

  //YesorNo
  yesNoSingleQuestion: {
    borderBottom: "0.2px #EBECED solid",
    padding: "15px 0",
    "& .MuiButton-root": {
      marginRight: "10px",
      borderRadius: 0,
    },
    "& .MuiGrid-container": {
      justifyContent: "flex-end",
    },
  },
  yesNoActive: {
    background: "#54C0FD !important",
  },

  //Dropdown
  dropdownSingleQuestion: {
    borderBottom: "0.2px #EBECED solid",
    padding: "15px 0",
  },
  dropdownTextBox: {
    padding: "0px",
  },
  //Open Ended
  textAreaResize: {
    "& textarea": {
      resize: "auto",
    },
  },

  //Preview Options
  pe_drawer: {
    "& .MuiDrawer-paper": {
      padding: "15px",
      background: "#E3E4E6",
    },
  },

  // openended question
  bulk_ans_textarea: {
    minWidth: "320px",
    lineHeight: "24px",
    resize: "vertical !important",
    [theme.breakpoints.down('sm')]: {
      minHeight: '78px',
      maxHeight: '140px'
    }
  },

  //Expansion Panel
  peaccordion_nocolor: {
    margin: "0px !important",
    width: "100%",
  },
  peaccordion_nocolor: {
    "&.MuiPaper-root": {
      borderBottom: "1px solid #E9E9E9",
      width: "100%",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // boxShadow: '0px 0px 4px #0000000F !important',
      color: "#292929 !important",
      background: "#fff !important",
      // borderTop: '1px solid #E9E9E9',
      borderBottom: "1px solid #E9E9E9",
    },
    "& .panel-active": {
      color: "#292929 !important",
    },
  },

  takeSurveyFooter: {
    height: "50px",
    background: "#54C0FD",
  },

  textDanger: {
    color: "red",
  },

  /**** Chip Error Validation Styles ****/
  errorChip: {
    background: "#F64242 !important",
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
      opacity: "0.75",
      "&:focus": {
        opacity: "1"
      }
    }
  },

  /**** Thank You Component Styles ****/
  thankYouRoot: {
    width: "100%",
    height: "100vh",
    display: "flex",
    background: "#fff",
    textAlign: "center",
    alignItems: "center",
    color: "#292929",
    padding: "3rem",
  },
  thankYouRootFull: {
    width: "100%",
    height: "100vh",
    display: "flex",
    background: "#fff",
    textAlign: "center",
    alignItems: "center",
    color: "#292929",
    padding: "3rem",
  },
  thankYouContainer: {
    display: "flex",
    flexDirection: "column",
    width: "auto",
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    textAlign: "center",
  },
  gridIcon: {
    fontSize: "3rem",
    fontWeight: "600",
    "& img": {
      maxWidth: "100%",
    },
  },
  textThankYou: {
    fontFamily: "Montserrat",
    fontSize: "3rem",
    fontWeight: "600",
  },
  textDescription: {
    fontSize: "1.5rem",
  },

  /***** Footer Bar *****/
  rootFooter: {
    flexGrow: 1,
  },
  footerAppBar: {
    height: 56,
  },
  footerToolBar: {
    height: 56,
  },
  takeActionMobile: {
    position: "fixed",
    left: "0",
    bottom: "0",
    padding: "1rem",
    background: "#fff",
    boxShadow: "0px 0px 20px 0px #c5c5c5",
    zIndex: "99999 !important",
  },
  footerBox: {
    padding: "0.75rem 1rem",
    background: "#fff",
  },
  mobileLangDropDown: {
    width: "180px",
    marginLeft: "auto",
    // position: "absolute",
    bottom: "0px",
    right: "1rem",
  },
  quesPanel: {
    padding: "10px",
  },
  "@media screen and (max-width:768px)": {
    showDiv: {
      display: "none !important"
    },
    subQuestionChoicesRadio: {
      padding: "10px !important",
      "&$checked": {
        color: "#54C0FD",
      },
      "& .MuiRadio-colorSecondary.Mui-checked": {
        color: "#54C0FD !important",
      },
      "&.Mui-checked": {
        color: "#54C0FD !important",
      },
    },
    yesNoActive: {
      background: "#54C0FD !important",
    },
    gridIcon: {
      fontSize: "2rem",
      fontWeight: "600",
      "& img": {
        maxWidth: "100%",
      },
    },
    textThankYou: {
      fontFamily: "Montserrat",
      fontSize: "2rem",
      fontWeight: "600",
    },
    textDescription: {
      fontSize: "1rem",
    },
    rootHeader: {
      "& header": {
        height: "80px",
        position: "relative",
      },
    },
    answerSet: {
      minWidth: "25px",
      quesPanel: {
        padding: "10px 0px",
      },
      padding: "0px 2px",
      "& .answerSettxt": {
        fontSize: "10px !important",
        wordBreak: "break-word",
        overflowWrap: "anywhere !important",
      },
    },
    singleSelectTile: {
      minWidth: "25px !important",
      fontSize: "10px"
    },
    table: {
      "& .MuiTableCell-root": {
        padding: "0px",
        minWidth: "40px",
        maxWidth: "40px",
        fontSize: "10px",
        lineHeight: 1.5,
        overflowWrap: "break-word"
      },
    },
    bulk_ans_textarea: {
      minWidth: "282px"
    }
  },
});

export default SurveyPreviewStyles;
