import React, { useState } from "react";
import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from "@mui/styles/withStyles";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles and Image
import APIIntegrationStyles from "../../APIIntegrationStyles";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import SurveyQuestions from "./SurveyQuestionsPanel";
import ResponseDemographics from "./ResponseDemographicsPanel";

// Import History
import history from "../../../../config/history";
import { apiIntegrationAction } from "../../../../redux/actions";
import ExitDialogInapi from "./ExitDialog";

const ConfigTabPanel = (props) => {
  const [exitDialog , setExitDialog] = useState(false)
  //Get props
  const { classes, match, location, respDemo ,configdata, allSurveys } = props;
  const InternalAPI_Data = useSelector(
    (state) => state.apiIntegration.internalAPI
  );
  const handleSubmit = (e) => {
    if (InternalAPI_Data?.selectedSurveyIds?.length > 0) {
      history.push({
        pathname: `${match.url}/generate-token`,
      });
    }
  };

  const dispatch = useDispatch()

  // const handleClose = () => {
  //   setExitDialog(false);
  // };

  // const handleCancel = () => {
  //   setExitDialog(true)
  // }

  const handleCancel = () => {
    // setExitDialog(true)
    dispatch(apiIntegrationAction.selectedSurveys([]));
    dispatch(apiIntegrationAction.selectedDemographics([]));
    dispatch(apiIntegrationAction.wishlistDomain_update(""));
    dispatch(apiIntegrationAction.startDate_update(""));
    dispatch(apiIntegrationAction.endDate_update(""));
    dispatch(apiIntegrationAction.limits_per_update(""));
    dispatch(apiIntegrationAction.limits_update(""));
    history.push({ pathname: "/surveys" });
  };

  return (
    <ValidatorForm
      id="configTabPanelForm"
      name="configTabPanelForm"
      useref="configTabPanelForm"
      autoComplete="off"
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
    >
      <Grid container justifyContent={"center"} mb={15}>
        <Grid item xs={8}>
          <Box mt={"55px"} mb={"40px"}>
            <TypographyComponent variant="h6">
            These are potential Response demographics. Does not mean that all are associated with the selected surveys
              {/* <Link href="#">{"Click here"}</Link> */}
            </TypographyComponent>
          </Box>
          <SurveyQuestions classes={classes} configdata={configdata} allSurveys={allSurveys}/>
          <ResponseDemographics
            classes={classes}
            match={match}
            location={location}
            respDemo = {respDemo}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent="flex-end"
        className={`${classes.configFooterBtn}`}
      >
        <Grid item>
          <ButtonComponent
            title={"Next"}
            color={"primary"}
            size={"small"}
            type="submit"
            pageClassName="mr-1"
            handleClick={handleSubmit}
          ></ButtonComponent>
          <ButtonComponent
            title={"Cancel"}
            color={"default"}
            size={"small"}
            type={"button"}
            handleClick={handleCancel}
          ></ButtonComponent>
        </Grid>
      </Grid>
      {/* <ExitDialogInapi exitDialog={exitDialog} handleClose={handleClose}/> */}
    </ValidatorForm>
  );
};
// default props
ConfigTabPanel.defaultProps = {
  classes: {},
  match: {},
  location: {},
};

// prop types
ConfigTabPanel.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};
export default withStyles(APIIntegrationStyles)(ConfigTabPanel);
