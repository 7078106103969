import React from "react";
import { ValidatorComponent } from "react-form-validator-core";

export class TextValidator extends ValidatorComponent {
  renderValidatorComponent () {
    const {
      error, errorMessages, validators, requiredError, validatorListener,
      inputField: InputField, className, validateData, ...rest
    } = this.props;

    return (
      <InputField
        {...rest}
        className={`${className} ${!this.isValid() ? "error" : ""} `}
        helperText={this.getErrorMessage() || null}
        ref={(r) => { this.input = r; }}
      />
    );
  }
}

export class AutocompleteValidator extends ValidatorComponent {
  renderValidatorComponent () {
    const {
      error, errorMessages, validators, requiredError, validatorListener,
      inputField: InputField, className, ...rest
    } = this.props;

    const isValid = this.isValid();

    return (
      <div className="text-validator-wrapper">
        <InputField
          {...rest}
          className={`${className} ${!isValid ? "error" : ""}`}
          ref={(r) => { this.input = r; }}
          onOpen={() => {
            this.setState({ open: true });
          }}
          onClose={(e, reason) => {
            if (reason === "blur") {
              this.setState({ open: false });
            } else {
              this.setState({ open: true });
            }
          }}
          open={this.state.open || false}
        />
        {!isValid && <p className="MuiFormHelperText-root">{this.getErrorMessage()}</p>}
      </div>
    );
  }
}

export class SelectValidator extends ValidatorComponent {
  renderValidatorComponent () {
    const {
      error, errorMessages, validators, requiredError, validatorListener,
      inputField: InputField, className, children, ...rest
    } = this.props;
    const isValid = this.isValid();
    return (
      <div className="text-validator-wrapper">
        <InputField
          {...rest}
          className={`${className} ${!isValid ? "error" : ""}`}
          ref={(r) => { this.input = r; }}
        >
          {children}
        </InputField>
        {!isValid && <p className="MuiFormHelperText-root">{this.getErrorMessage()}</p>}
      </div>
    );
  }
};
