import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import CircularProgress from '@mui/material/CircularProgress';

// Images
import PlusImg from '../../../../../../assets/images/svg/plus_grey.svg';
import MinusImg from '../../../../../../assets/images/svg/minus_blue.svg';

//Import Actions
import { meSurveyAction } from '../../../../../../redux/actions'

// Import Style
import FilterStyles from "../../../../../Filter/FilterStyles";

// Import Component
import TypographyComponent from "../../../../../../components/TypographyComponent/TypographyComponent";

function Demographic(props) {

    // props
    const { classes, demographicData, demographicKey, onChange } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Get filter Data
    const { managerEffectiveness, metadata: { metadata } } = useSelector(state => state)
    const { dataFilter, demographicItems, loading } = managerEffectiveness
    const { filterData, expanded } = dataFilter

    // Get Demographic Name
    let selData = metadata.filter(e => e.key === demographicKey)
    const demographicName = selData[0] && selData[0].value ? selData[0].value : "";

    const getDemographicsItem = (key) => {
        if (expanded !== key) {
            dispatch(meSurveyAction.dataFilterUpdate({
                ...dataFilter,
                expanded: key
            }))
            dispatch(meSurveyAction.getDemographicsItems({
                demographic: key,
                search: filterData.search
            }))
        } else {
            dispatch(meSurveyAction.dataFilterUpdate({
                ...dataFilter,
                expanded: null
            }))
        }
    }

    /**
     * Handle Change
     * @param {*} e 
     * @param {*} item 
     */
    const handleChange = (e, item) => {
        onChange(e, item, demographicKey)
    }

    // generate demographics items
    const generateDemographicItems = () => {
        // sort data to avoid element shuffle
        if (demographicItems[demographicKey]) {
            return demographicItems[demographicKey].sort().map((item) => {
                return <Grid item xs={12} className={`${classes.expansionDetail}`}>
                    <Grid item className={classes.demographyCheckbox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name={item._id}
                                    color="primary"
                                    checked={filterData.demographics && filterData.demographics[demographicKey] && filterData.demographics[demographicKey][item._id] ? true : false}
                                    onChange={e => handleChange(e, item)}
                                />
                            }
                            label={`${item._id} (${item.data})`}
                        />
                    </Grid>
                </Grid>
            })
        }
    }

    /**
     * Show Loading
     */
    const showLoading = () => {
        return (
            <Grid item xs={12} className={`${classes.expansionDetail} txtcenter`}>
                <CircularProgress size={20} />
            </Grid>
        )
    }

    return (
        <>
            <Accordion
                classes={{
                    root: classes.peaccordion_nocolor_demography
                }}
                expanded={expanded === demographicKey}
                onChange={() => getDemographicsItem(demographicKey)}>
                <AccordionSummary
                    aria-controls={`${demographicKey}_controll`}
                    id={`${demographicKey}_id`}>
                    <TypographyComponent variant={"h6"}
                        className={`${""} ${expanded === demographicKey ? "panel-active" : ""} `}>
                        <Grid container alignItems="center">
                            <img className={`${classes.expansionPlus} ${"expansionPlus pr-1"}`} src={PlusImg}></img>
                            <img className={`${classes.expansionMinus} ${"expansionMinus pr-1"}`} src={MinusImg}></img>
                            {`${demographicName} (${demographicData})`}
                        </Grid>
                    </TypographyComponent>
                </AccordionSummary>
                <AccordionDetails className="pl-2">

                    {/* Demographic datas */}
                    {loading ? showLoading() : generateDemographicItems()}

                </AccordionDetails>
            </Accordion>
        </>
    )
}
// default props
Demographic.defaultProps = {
    classes: {},
    demographicData: "",
    demographicKey: "",
    preference: {},
    metadata: [],
    searchKey: '',
    handlePanelChange: () => { },
    expanded: null
};

// prop types
Demographic.propTypes = {
    classes: PropTypes.object,
    demographicData: PropTypes.string,
    demographicKey: PropTypes.string,
    preference: PropTypes.object,
    metadata: PropTypes.array,
    searchKey: PropTypes.string
};
export default withStyles(FilterStyles)(Demographic);