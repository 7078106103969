import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import ToggleButton from '@mui/material/ToggleButton';
import { useDispatch, useSelector } from "react-redux";
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

//Images 
import { BackPage } from "../../../../assets/images/svgComponents/ImageComponent";

import { IconButton } from "@mui/material";

//import components
import TopicChart from "./TopicChart";
import WordCloudChart from "./WordCloudChart";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

// Import Actions
import { adminsAction, dashboardAction } from "../../../../redux/actions";

function WordCloudPanel(props) {
  const dispatch = useDispatch();

  const { topics, topicCount, wordCloud, allAns, classes } = props;

  //get reducer data
  let selectedItem = useSelector((state) => state.dashboard.topicSelected);

  const [selectedType, setSelectedType] = useState("topic");

  // Handle value for toggle button
  const handleTypeChange = (event, value) => {
    if (value !== null) {
      setSelectedType(value);
      const selectedType = value === "topic" ? "Topics" : "Words";
      selectedItem["type"] = selectedType;
      // selectedItem["mainWord"] = ""
      dispatch(dashboardAction.updateNLPSelected(selectedItem));
      dispatch(adminsAction.updatePreferenceNLPSelected(selectedType));
    }

  };

  const setDefaultData = () => {
    const updateItem = {
      item: "",
      word: "",
      data: [],
      wordCount: 0,
      type: "Topics",
      mainWord: ""
    };
    dispatch(dashboardAction.updateNLPSelected(updateItem));
  };

  return (
    <React.Fragment>
      {((topics && Object.keys(topics).length > 0) ||
        (wordCloud && Object.keys(wordCloud).length > 0)) && (
          <Grid className={classes.WordCloud}>
            <Grid style={{ display: "flex" }}>
              <Grid container className="justifycenter">
                <ToggleButtonGroup
                  className={classes.wordCloudToggle}
                  value={Object.keys(topics).length === 0 ? "word" : selectedType}
                  exclusive
                  onChange={(e, v) => {
                    handleTypeChange(e, v);
                  }}
                  aria-label="text alignment"
                >
                  <ToggleButton
                    value="topic"
                    aria-label="centered"
                    disabled={Object.keys(topics).length === 0}
                    className={`${classes.toggleLeft} wordtoggle wordactive`}
                    style={{
                      textTransform: 'capitalize'
                    }}
                  >
                    Topics
                </ToggleButton>
                  <ToggleButton
                    value="word"
                    aria-label="centered"
                    className={`${classes.toggleRight} wordtoggle wordactive`}
                    style={{
                      textTransform: 'capitalize'
                    }}
                  >
                    Words
                </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>
            <Grid style={{ display: "flex" }}>
            {selectedItem["mainWord"] && selectedItem["type"] === "Topics" &&
              <Grid className="p-1">
                <IconButton onClick={() => setDefaultData()} size="large">
                  {BackPage()}
                </IconButton>
              </Grid>
            }
            {selectedItem["mainWord"] && selectedItem["type"] === "Topics" &&
              <Grid className="p-1">
                <TypographyComponent variant="h6">
                  {selectedItem["mainWord"] ? selectedItem["mainWord"] : ""}
                </TypographyComponent>
              </Grid>
            }
            </Grid>
            <Grid className={classes.wordCloudcontainer} container>
              <React.Fragment>
                {selectedType === "topic" && Object.keys(topics).length > 0 && (
                  <TopicChart topics={topics} topicCount={topicCount} />
                )}
              </React.Fragment>
              <React.Fragment>
                {(selectedType === "word" || Object.keys(topics).length === 0) && (
                  <WordCloudChart wordCloud={wordCloud} allAns={allAns} />
                )}
              </React.Fragment>
            </Grid>
          </Grid>
        )}
      {(!topics || Object.keys(topics).length === 0) &&
        (!wordCloud || Object.keys(wordCloud).length === 0) && (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className="mt-10"
          >
            <TypographyComponent variant="h3">
              {ReportsConstants.NO_RESPONSE_DATA_CHART}
            </TypographyComponent>
          </Grid>
        )}
    </React.Fragment>
  );
}

// prop types
WordCloudPanel.propTypes = {
  topics: PropTypes.object,
  topicCount: PropTypes.object,
  wordCloud: PropTypes.object,
  allAns: PropTypes.array,
  classes: PropTypes.object,
};

// default types
WordCloudPanel.defaultProps = {
  topics: {},
  topicCount: {},
  wordCloud: {},
  allAns: [],
  classes: {},
};

export default WordCloudPanel;
