import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Import Styles and Image
import APIIntegrationStyles from "../../APIIntegrationStyles";

// Import Icons
import {
    SettingAccordGrey,
    SettingAccordWhite,
} from "../../../../assets/images/svgComponents/ImageComponent";

// Import Constants
import { internalAPIConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import MultiSelectComponent from "../../../../components/MultiSelectComponent/MultiSelectComponent";

// Import Actions
import { apiIntegrationAction } from "../../../../redux/actions";

const SurveyQuestions = (props) => {
    // Define Dispatch
    const dispatch = useDispatch();

    //Get props
    const { classes, configdata , allSurveys} = props;

    // Accordion Declaration and Action
    const [expanded, setExpanded] = React.useState("default-panel");
    const handlePanelChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    
    const [selectedSurvey, setSelectedSurvey] = useState([]);
    const [selectedSurveyName, setSelectedSurveyName] = useState([]);
    const [initialChange,setInitialChange] = useState(true)
    const [surveyNames, setSurveyNames] = useState(["Select All"]);

    useEffect(()=>{
        if(initialChange){
        const dat = allSurveys?.filter((ele) => configdata?.includes(ele._id) )
        let temp = []
        dat.map((e)=>{temp.push(e.name)
        })
        setSelectedSurveyName(temp)
    }
    },[configdata])

    
    useEffect(() => {
        dispatch(apiIntegrationAction.getAllSurveys((filterData)=>{
            filterData.map((e)=>(surveyNames.push(e.name)))
        }));
    }, [dispatch]);        


    useEffect(() => {
        if(!initialChange){
        dispatch(apiIntegrationAction.selectedSurveys(selectedSurvey));
        }
    }, [selectedSurvey])

  //autocomplete component change
  const handleAutoCompleteChange = (event) => {
    setInitialChange(false)
    const selectedVal = event.value;
    const action = event.action
    let selectedSurveyId = [];
    let selectedSurveyNames = [];
    const isAllSelected = selectedVal.find((ele) => ele === "Select All");
    if (isAllSelected) {
      if (action === "selectOption") {
        JSON.parse(JSON.stringify(allSurveys)).forEach((val) => {
          selectedSurveyId.push(val._id);
          selectedSurveyNames.push(val.name);
        })
        setSelectedSurvey([...selectedSurveyId]);
        setSelectedSurveyName(["Select All", ...selectedSurveyNames]);
      }
      else {
        selectedVal.forEach((val) => {
          if (val !== "Select All") {
            let surveyData = JSON.parse(JSON.stringify(allSurveys)).find((ele) => ele.name === val);
            selectedSurveyId.push(surveyData._id);
            selectedSurveyNames.push(surveyData.name);
          }
        })
        setSelectedSurvey([...selectedSurveyId]);
        setSelectedSurveyName([...selectedSurveyNames]);
      }
    }
    else {
      if (allSurveys.length === selectedVal.length && action === "removeOption") {
        setSelectedSurvey([]);
        setSelectedSurveyName([]);
      }
      else if (allSurveys.length === selectedVal.length && action === "selectOption") {
        JSON.parse(JSON.stringify(allSurveys)).forEach((val) => {
          selectedSurveyId.push(val._id);
          selectedSurveyNames.push(val.name);
        })
        setSelectedSurvey([...selectedSurveyId]);
        setSelectedSurveyName(["Select All", ...selectedSurveyNames]);
      }
      else {
        selectedVal.forEach((val) => {
          let surveyData = JSON.parse(JSON.stringify(allSurveys)).find((ele) => ele.name === val);
          selectedSurveyId.push(surveyData._id);
          selectedSurveyNames.push(surveyData.name);
        })

        setSelectedSurvey([...selectedSurveyId]);
        setSelectedSurveyName([...selectedSurveyNames]);
      }
    }
  }
    return (
        <Accordion
            className="mb-2 pe-accordion"
            expanded={expanded === "default-panel"}
            onChange={handlePanelChange("default-panel")}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon className="expandicon" />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                {expanded === "default-panel" ? (
                    <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                ) : (
                    <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                )}
                <TypographyComponent
                    className={`${"txtBold flexBasis33 mt2"} ${expanded === "default-panel" ? "panel-active" : ""
                        } `}
                    variant={"h5"}
                >
                    {internalAPIConstants.SURVEY_QUESTIONS_PANEL.PANEL_LABEL}
                </TypographyComponent>
            </AccordionSummary>
            <AccordionDetails>
                <Grid className="p-2">
                    <Grid container spacing={3} className={``}>
                        <Grid item md={12} xs={12}>
                            <Grid container my={3.125}>
                                <Grid item md={12}>
                                    <TypographyComponent
                                        className={``}
                                        title={
                                            internalAPIConstants.SURVEY_QUESTIONS_PANEL
                                                .SURVEY_INPUT_LABEL
                                        }
                                        variant={"h6"}
                                    ></TypographyComponent>
                                </Grid>
                                <Grid item md={12}>
                                    <MultiSelectComponent
                                        name={"surveys"}
                                        className={`height40`}
                                        checkboxClassName={`${classes.autoComplete}`}
                                        fullWidth={true}
                                        suggestions={surveyNames}
                                        selectedOptions={selectedSurvey}
                                        handleAutoCompolete={(name, value, event, action) => {
                                            handleAutoCompleteChange({ name, value, event, action });
                                        }}
                                        value={selectedSurveyName}
                                        validators={["required"]}
                                        showErrorMessages={true}
                                        isArrayObjects={true}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

// default props
SurveyQuestions.defaultProps = {
    classes: {}
};

// prop types
SurveyQuestions.propTypes = {
    classes: PropTypes.object
};
export default withStyles(APIIntegrationStyles)(SurveyQuestions);
