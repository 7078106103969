import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

// Import Authentication Layout
import AuthenticationLayout from "../layouts/AuthenticationLayout/AuthenticationLayout";

// Routes the page without any authentication by the user
const AuthenticationRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <AuthenticationLayout {...props}>
      <Component {...props} />
    </AuthenticationLayout>
  )} />
);

// Define Props Types
AuthenticationRoute.propTypes = {
  // component: PropTypes.elementType
};

export default AuthenticationRoute;
