/* eslint-disable no-unneeded-ternary */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { IconButton } from "@mui/material";

// Import Styles and Image
import PariticipantStyles from "../PariticipantStyles";
import { BackPage } from "../../../../assets/images/svgComponents/ImageComponent";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import Container from "../../../Containers/Container";

// Import Actions
import { excelAction } from "../../../../redux/actions";

// import constants
import { participantConstants, excelConstants } from "../../../../config/constants";

// Import History
import history from "../../../../config/history";

function ArchiveOption(props) {
    const dispatch = useDispatch();

    // get props
    const { classes, match } = props;

    // export data to Archive 
    const archiveExport = () => {
        dispatch(excelAction.download({ "excelType": excelConstants.ARCHIVE_PARTICIPANTS_DOWNLOAD }, true));
    };

    // export Archived data
    const archivedDataExport = () => {
        dispatch(excelAction.download({ "excelType": excelConstants.ARCHIVED_DATA_PARTICIPANTS_DOWNLOAD }, true));
    };

    return (
        <React.Fragment>
            <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                className="p-2"
            >
                <Grid container alignItems="center">
                    <Grid onClick={() => history.push("/manage_people/participants")} className={classes.backimg}>
                        {BackPage()}
                    </Grid>
                    <TypographyComponent
                        variant={"h3"}
                        className="ml-1">
                        {participantConstants.BULK_ARCHIVE_RESTORE}
                    </TypographyComponent>
                </Grid>
            </Grid>

            <Container page={"sub"}>
                <Grid container>
                    <Grid className="container" justifyContent="center">
                        <Grid item className="mb-2">
                            <TypographyComponent
                                variant="h2"
                                align="center"
                                className="txtBold"
                            >
                                {participantConstants.BULK_ARCHIVE_RESTORE}
                            </TypographyComponent>
                        </Grid>
                        <Grid item className="mb-4">
                            <TypographyComponent variant="h6" align="center" className="">
                                {participantConstants.ARCHIVE_SUBTEXT.split('\n').map(str => <p dangerouslySetInnerHTML={{__html: str}} />)}
                            </TypographyComponent>
                        </Grid>
                        <Grid item>
                            <Grid container justifyContent="center">
                                <ButtonComponent
                                    disabled={false}
                                    title={participantConstants.EXPORT_BUTTON_ARCHIVE}
                                    color={"primary"}
                                    size={"small"}
                                    pageClassName="mr-1 buttonOrange"
                                    handleClick={() => {
                                        archivedDataExport();
                                    }}
                                ></ButtonComponent>
                                <ButtonComponent
                                    disabled={false}
                                    title={participantConstants.EXPORT_BUTTON}
                                    color={"primary"}
                                    size={"small"}
                                    pageClassName="mr-1"
                                    handleClick={() => {
                                        archiveExport();
                                    }}
                                ></ButtonComponent>
                                <ButtonComponent
                                    title={participantConstants.IMPORT_BUTTON}
                                    color={"primary"}
                                    size={"small"}
                                    pageClassName="mr-1 "
                                    handleClick={() => {
                                        history.push(`${match.url}/upload`);
                                    }}
                                ></ButtonComponent>
                                <ButtonComponent
                                    title={participantConstants.CANCEL_BUTTON}
                                    // color={"secondary"}
                                    size={"small"}
                                    className={ classes.cancelBtn }
                                    pageClassName="mr-1"
                                    handleClick={() => history.push("/manage_people/participants")}
                                ></ButtonComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}
// default props
ArchiveOption.defaultProps = {
    classes: {},
    match: {}
};

// prop types
ArchiveOption.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object
};
export default withStyles(PariticipantStyles)(ArchiveOption);
