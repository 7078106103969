import { alertConstant, surveyConstant, uiConstant } from "../constants";
import { surveyService } from "../services";
import { baseAction } from "./baseAction";
import { updateStateReducerAction } from "./updateStateReducerAction"

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// Export All Survey Actions Functions
export const surveyAction = {
  search,
  getOne,
  create,
  update,
  remove,
  copy,
  getQuestions,
  saveQuestions,
  getSettings,
  saveSettings,
  saveAnswerSet,
  applyAnswerSet,
  getAnswerSet,
  getResponseDetails,
  getAnonKioskShortName,
  sendSurveyMail,
  sendActionCoachMail,
  sendSurveySMS,
  sendSurveyTransflo,
  triggerAssignSurvey,
  handleSurveyPreview,
  translateSurveyQuestions,
  translateSurveySettings,
  translateSurveySettingsCommunication,
  translateCustomAnswerSet,
  updateSurveyErrors,
  getMESystemID,
  getActionAlert,
  updateSurveyAutomaticTriggers,
};

/**
 * Get All  Survey
 */
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SEARCH_REQUEST));
    surveyService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Survey
 */
function getOne(id, successCallback = () => { }) {
  return (dispatch) => {
    if (id) {
      dispatch(request(uiConstant.LOADING_TRUE));
      dispatch(request(surveyConstant.SURVEY_GETONE_REQUEST));
      surveyService.getOne(id).then(
        (data) => {
          dispatch(success(surveyConstant.SURVEY_GETONE_SUCCESS, data));
          dispatch(request(uiConstant.LOADING_FALSE));
          successCallback()
        },
        (error) => {
          dispatch(failure(surveyConstant.SURVEY_GETONE_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      );
    } else {
      dispatch(success(surveyConstant.SURVEY_GETONE_CLEAR, null));
    }
  };
}

/**
 * Create Survey
 */
function create(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_CREATE_REQUEST));
    surveyService.create(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_CREATE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));

        if (localStorage.getItem("formSubmitCancelAction")) {
          localStorage.removeItem("formSubmitCancelAction")
          history.replace("/surveys/manage?reset=true");
        } else {
          history.push({
            pathname: `/surveys/${data.data}/questions`
          });
        }

      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_CREATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Copy Survey
 */
function copy(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_COPY_REQUEST));
    surveyService.copy(data).then(
      (data) => {
        successCallback()
        dispatch(success(surveyConstant.SURVEY_COPY_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_COPY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Survey
 */
function update(outerData, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_UPDATE_REQUEST));
    surveyService.update(outerData).then(
      (data) => {
        successCallback();
        dispatch(success(surveyConstant.SURVEY_UPDATE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        // To check if stepper is to change
        if (outerData.isStepperChanger) {
          if (localStorage.getItem("formSubmitCancelAction")) {
            localStorage.removeItem("formSubmitCancelAction")
            history.replace("/surveys/manage?reset=true");
          } else {
            history.push({
              pathname: `/surveys/${data.data}/questions`
            });
          }
        } else {
          // dispatch(getOne(outerData._id));
          // dispatch(getSettings(outerData._id));
          // dispatch(getQuestions(outerData._id));
          if(outerData.name) {
            dispatch(updateStateReducerAction.update(surveyConstant.UPDATE_SURVEY_NAME, outerData.name))
          }
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        }

      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


/**
 * Delete One  Survey
 */
function remove(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_DELETE_REQUEST));
    surveyService.remove(data).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        if (data.data) {
          successCallback()
        } else {
          successCallback()
          dispatch(success(surveyConstant.SURVEY_DELETE_SUCCESS, data));
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        }
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_DELETE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * save survey settings
 */
function saveSettings(data, successCallback) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_SAVE_REQUEST));
    return surveyService.saveSettings(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SETTINGS_SAVE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
        localStorage.removeItem("formSubmitCancelAction")
        history.push({
          pathname: `/surveys/manage`
        });
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_SAVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * get survey settings
 */
function getSettings(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_GET_REQUEST));
    surveyService.getSettings(id).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SETTINGS_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_GET_FAILURE, error));
        // dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Survey Questions
 */
function getQuestions(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_GET_QUESTIONS_REQUEST));
    surveyService.getQuestions(id).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_GET_QUESTIONS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_GET_QUESTIONS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Save Survey Questions
 */
function saveQuestions(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SAVE_QUESTIONS_REQUEST));
    surveyService.saveQuestions(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SAVE_QUESTIONS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));

        if (localStorage.getItem("formSubmitCancelAction")) {
          localStorage.removeItem("formSubmitCancelAction")
          history.replace("/surveys/manage?reset=true");
        } else {
          history.push({
            pathname: `/surveys/${data.data._id}/settings`
          });
        }
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SAVE_QUESTIONS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Save Answer set 
 */
function saveAnswerSet(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_EDITANSWERSET_SAVE_REQUEST));
    surveyService.saveAnswerSet(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_EDITANSWERSET_SAVE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_EDITANSWERSET_SAVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}
/**
 * Apply Answer set 
 */
function applyAnswerSet(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_EDITANSWERSET_APPLY_REQUEST));
    surveyService.create(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_EDITANSWERSET_APPLY_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_EDITANSWERSET_APPLY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}
/**
 * GET ANSWERSET
 */
function getAnswerSet(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_EDITANSWERSET_GET_REQUEST));
    surveyService.getAnswerSet(id).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_EDITANSWERSET_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_EDITANSWERSET_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET RESPONSE DETAILS
 */
function getResponseDetails(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_RESPONSE_GET_REQUEST));
    surveyService.getResponseDetails(id).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_RESPONSE_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_RESPONSE_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * GET ANONYMOUS KIOSK SHORTNAME
 */
function getAnonKioskShortName(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.ANONYMOUS_KIOSK_SHORTNAME_GET_REQUEST));
    surveyService.getAnonKioskShortName(id).then(
      (data) => {
        dispatch(success(surveyConstant.ANONYMOUS_KIOSK_SHORTNAME_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.ANONYMOUS_KIOSK_SHORTNAME_GET_FAILURE, error));
        //dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Send Survey Mail From Distribution Panel
 * @param {*} id 
 */
function sendSurveyMail(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_SENDMAIL_REQUEST));
    surveyService.sendSurveyMail(id).then(
      (data) => {
        setTimeout(() => {
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
          dispatch(success(surveyConstant.SURVEY_SETTINGS_SENDMAIL_SUCCESS, data));
          dispatch(request(uiConstant.LOADING_FALSE));
        }, 2000)
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_SENDMAIL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


/**
 * Send Survey Mail From Distribution Panel
 * @param {*} id 
 */
function sendActionCoachMail(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_ACTIONCOACH_MAIL_REQUEST));
    surveyService.sendActionCoachMail(id).then(
      (data) => {
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(success(surveyConstant.SURVEY_SETTINGS_ACTIONCOACH_MAIL_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_ACTIONCOACH_MAIL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Send Survey SMS From Distribution Panel
 * @param {*} id 
 */
function sendSurveySMS(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_SENDSMS_REQUEST));
    surveyService.sendSurveySMS(id).then(
      (data) => {
        setTimeout(() => {
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(success(surveyConstant.SURVEY_SETTINGS_SENDSMS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      }, 2000)
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_SENDSMS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Send Survey Transflo Notifications From Distribution Panel
 * @param {*} id 
 */
function sendSurveyTransflo(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_SENDTRANSFLO_REQUEST));
    surveyService.sendSurveyTransflo(id).then(
      (data) => {
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(success(surveyConstant.SURVEY_SETTINGS_SENDTRANSFLO_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_SENDTRANSFLO_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * trigger assign survey
 */
function triggerAssignSurvey(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.TRIGGER_ASSIGN_SURVEY_GET_REQUEST));
    surveyService.triggerAssignSurvey(data).then(
      (data) => {
        if (data.message === "Report group item not mapped to participants") {
          dispatch(failure(alertConstant.ALERT_ERROR, "Report group item not mapped to participants"));
        } else {
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        }
        dispatch(success(surveyConstant.TRIGGER_ASSIGN_SURVEY_GET_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.TRIGGER_ASSIGN_SURVEY_GET_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/***
 * Handle Survey Preview 
 */

function handleSurveyPreview(data) {
  return (dispatch) => {
    dispatch(success(surveyConstant.SURVEY_PREVIEW_SUCCESS, data));
  };
}

/**
 * Translate Survey Questions
 */
function translateSurveyQuestions(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    surveyService.translateSurveyQuestions(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_QUESTIONS_TRANSLATE_SUCCESS, data.data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_QUESTIONS_TRANSLATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Translate Survey Questions
 */
function translateCustomAnswerSet(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    surveyService.translateCustomAnswerSet(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_SUCCESS, data.data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_CUSTOM_ANSWER_SET_TRANSLATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/***
 * Handle auto translation for survey settings
 */

function translateSurveySettings(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_TRANSLATE_REQUEST));
    surveyService.translateSurveySettings(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SETTINGS_TRANSLATE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_TRANSLATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/***
 * Handle auto translation for survey settings communication
 */

function translateSurveySettingsCommunication(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_COMMUNICATION_TRANSLATE_REQUEST));
    surveyService.translateSurveySettingsCommunication(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SETTINGS_COMMUNICATION_TRANSLATE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_COMMUNICATION_TRANSLATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

function updateSurveyErrors(errors) {
  return (dispatch) => {
    dispatch(success(surveyConstant.SURVEY_ERRORS_UPDATE, errors));
  };
}

/***
 * Handle auto translation for survey settings
 */

function getMESystemID(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_MESYSTEMID_REQUEST));
    surveyService.getMESystemID(data).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SETTINGS_MESYSTEMID_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_MESYSTEMID_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/***
 * Get Action Alert name from content library
 */

function getActionAlert(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(surveyConstant.SURVEY_SETTINGS_ACTIONALERT_REQUEST));
    surveyService.getActionAlert(id).then(
      (data) => {
        dispatch(success(surveyConstant.SURVEY_SETTINGS_ACTIONALERT_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(surveyConstant.SURVEY_SETTINGS_ACTIONALERT_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

// Update Automatic Triggers in Survey Settings
function updateSurveyAutomaticTriggers(data) {
  return (dispatch) => {
    dispatch(success(surveyConstant.UPDATE_SURVEY_AUTOMATIC_TRIGGERS, data));
  }
}