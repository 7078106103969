// Profile reducer types
export const profileConstant = {
    // To User By Id
    PROFILE_GET_ONE_REQUEST: "PROFILE_GET_ONE_REQUEST",
    PROFILE_GET_ONE_SUCCESS: "PROFILE_GET_ONE_SUCCESS",
    PROFILE_GET_ONE_FAILURE: "PROFILE_GET_ONE_FAILURE",

    // To Update GetOne Reducer state
    PROFILE_GET_ONE_UPDATE: "PROFILE_GET_ONE_UPDATE",

    // To Store Init Profile
    PROFILE_GET_ONE_INIT: "PROFILE_GET_ONE_INIT",

    // To update Participant details
    PROFILE_UPDATE_REQUEST: "PROFILE_UPDATE_REQUEST",
    PROFILE_UPDATE_SUCCESS: "PROFILE_UPDATE_SUCCESS",
    PROFILE_UPDATE_FAILURE: "PROFILE_UPDATE_FAILURE",

    // To Get Particular login in user Details
    PROFILE_LOGGED_IN_REQUEST: "PROFILE_LOGGED_IN_REQUEST",
    PROFILE_LOGGED_IN_SUCCESS: "PROFILE_LOGGED_IN_SUCCESS",
    PROFILE_LOGGED_IN_FAILURE: "PROFILE_LOGGED_IN_FAILURE",

    //Update Preference
    PROFILE_UPDATE_PREFERENCE_CLIENTID_REQUEST: 'PROFILE_UPDATE_PREFERENCE_CLIENTID_REQUEST',
    PROFILE_UPDATE_PREFERENCE_CLIENTID_SUCCESS: 'PROFILE_UPDATE_PREFERENCE_CLIENTID_SUCCESS',
    PROFILE_UPDATE_PREFERENCE_CLIENTID_FAILURE: 'PROFILE_UPDATE_PREFERENCE_CLIENTID_FAILURE',

    // Update Client Data
    PROFILE_UPDATE_CLIENT_REDUCER : "PROFILE_UPDATE_CLIENT_REDUCER"
};
