import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { Tooltip, Grid, Chip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

import { Information } from "../../../../../../assets/images/svgComponents/ImageComponent";
import TypographyComponent from "../../../../../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../../../../../components/SearchInputCompoment/SearchInputComponent";
import InformationComponent from "../../../../../../components/InformationComponent/InformationComponent";

import { surveySettingsConstants } from "../../../../../../config/constants";


import Demographic from "./Demographic";

// Import Styles and Image
import TabStyle from "../../styles/METabStyles";

//Import Actions
import { meSurveyAction } from '../../../../../../redux/actions'

const DemographicFilter = (props) => {

    const { classes, onSearchkeyChange } = props
    const dispatch = useDispatch()

    // Get filter Data
    const { managerEffectiveness, metadata: { metadata } } = useSelector(state => state)
    const { demographics, dataFilter } = managerEffectiveness
    const { filterData } = dataFilter
    const demoMeta = {}
    metadata.map(meta => {
        demoMeta[meta.key] = meta.value
    })

    const removeSelectedDemogrphicsItem = (key, item) => {
        handleChange({ target: { checked: false } }, { _id: item }, key)
    }

    /**
     * Handle Change
     * @param {*} e 
     * @param {*} item 
     */
    const handleChange = (e, item, demographicKey) => {
        let updatedDemographicsItems = filterData.demographics[demographicKey] || {}
        if (e.target.checked) {
            updatedDemographicsItems[item._id] = item.empIds
        } else {
            delete updatedDemographicsItems[item._id]
        }

        const upDemographic = {
            ...filterData.demographics,
            [demographicKey]: {
                ...updatedDemographicsItems
            }
        }

        const selectedEmpIds = Object.values(upDemographic).reduce((a, b) => {
            Object.values(b).map(m => {
                a = [...a, ...m]
            })
            return a
        }, [])

        dispatch(meSurveyAction.dataFilterUpdate({
            ...dataFilter,
            filterData: {
                ...filterData,
                demographics: { ...upDemographic },
                selectedEmpIds: selectedEmpIds
            }
        }))
    }


    /**
     * Bind Selected Demographics as Chips 
     */
    const bindSelectedDemographics = () => {
        if (filterData && filterData.demographics) {
            return Object.keys(filterData.demographics).map((key) => {
                return Object.keys(filterData.demographics[key]).map((item, index) => {
                    return (
                        <Tooltip title={item} arrow>
                            <Chip
                                key={`${item}_${index}`}
                                name={item}
                                label={`${demoMeta[key]} ${item}`}
                                classes={{
                                    root: classes.demographicsChips
                                }}
                                onDelete={e => removeSelectedDemogrphicsItem(key, item)}
                                deleteIcon={<CloseIcon className={classes.demogrpahicsChipClose} />}
                            />
                        </Tooltip>
                    )
                })
            })
        }
    }

    return (
        <>
            <Grid item>
                <Grid container alignItems="center">
                    <TypographyComponent variant={"h4"}
                        className="txtMedium">
                        {"Demographics"}
                    </TypographyComponent>
                    <InformationComponent>
                        <TypographyComponent variant="tooltipbody">
                            {surveySettingsConstants.ME_DATA_FILTER}
                        </TypographyComponent>
                    </InformationComponent>
                </Grid>
            </Grid>
            <Grid item xs={12} className={'mb-1'}>
                {bindSelectedDemographics()}
            </Grid>
            <Grid item xs={12}>
                <SearchInputComponent
                    id="me-demographic-search"
                    placeholder={"Search"}
                    value={filterData.search}
                    field={"search"}
                    showClearIcon={true}
                    fullWidth={true}
                    className={`${classes.demographySearch}`}
                    onInputChangeRequest={(e, value) => onSearchkeyChange(value)}
                    handleClearSearch={() => onSearchkeyChange("")}
                    onEnter={() => { }}
                />
            </Grid>
            <Grid item xs={12} style={{ paddingTop: '8px' }}>
                {
                    Object.keys(demographics).map((demographicKey) => {
                        return (
                            <Demographic
                                demographicKey={demographicKey}
                                demographicData={demographics[demographicKey]}
                                onChange={handleChange}
                            />
                        )
                    })
                }
            </Grid>
        </>
    )
}

export default withStyles(TabStyle)(DemographicFilter);