import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Actions
import { authAction } from "../../redux/actions";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import AnchorComponent from "../../components/AnchorComponent/AnchorComponent";

// Import Cofig Constants
import { authenticationConstants } from "../../config/constants";

function ForgotPassword (props) {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  /**
   * Handle Textbox Changes
   */
  function handleChange (e) {
    setEmail(e.value);
  }

  /**
   * Forgot Password Form Submit
   * @param {*}
   */
  function handleSubmit (e) {
    e.preventDefault();
    if (email) {
      dispatch(authAction.forgotPassword(email));
    }
  }

  return (
    <ValidatorForm
      name="ForgotPasswordForm"
      useref="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.SIGNIN_FORGOT_PASSWORD}
            variant={"h2"}
            className="txtBold"
          ></TypographyComponent>
          <TypographyComponent
            title={authenticationConstants.FORGOT_PASSWORD_SUB_TEXT}
            variant={"h5"}
            className={"txtgrey mt-1"}
          ></TypographyComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.SIGNIN_EMAIL_ID}
            variant={"h6"}
          ></TypographyComponent>
          <TextFieldComponent
            id={"forgotpwd_email_txtbox"}
            name={"email"}
            value={email}
            margin={"none"}
            handleChange={(e) => handleChange(e)}
            validators={["required", "isEmail"]}
          ></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"}>
            <Grid item xs={6}>
              <ButtonComponent
                title={authenticationConstants.FORGOT_PASSWORD_BTN_TEXT}
                color={"primary"}
                size={"large"}
                type="submit"
              ></ButtonComponent>
            </Grid>
            <Grid item xs={6} className="txtright">
              <AnchorComponent
                title={authenticationConstants.BACK_TO_SIGNIN}
                href="#/login"
              ></AnchorComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

// default props
ForgotPassword.defaultProps = {
  classes: {}
};

// prop types
ForgotPassword.propTypes = {
  classes: PropTypes.object
};

export default ForgotPassword;
