/* eslint-disable no-unneeded-ternary */
/* eslint-disable curly */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-const */
import { ValidatorForm } from 'react-material-ui-form-validator';
import moment from 'moment';

// custom phone validation rule
const phoneRegEx = /^[0-9-\s.()+/]+$/;
// const phoneRegEx = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
const numberFieldRegEx = /^\-?\d+((\.|\,)\d+)?$/;
const indianPhoneRegEx = /^(\+91[\-\s]?)?[0]?(91)?[789]\d{9}$/;
const numberAndDecimalRegex = /^-?(0|[1-9]\d*)(\.\d+)?$/;
const numberAlone = /^\d+$/;
const fileNameRegex = /^[^*|\":<>\\/?]+$/;
const folderNameRegex = /^[A-Za-z0-9_-\s]+$/;

ValidatorForm.addValidationRule("isPhone", (value) => {
  try {
    // if phone is empty return true
    if (!value.trim()) {
      return true;
    }
  } catch (e) {
    return true;
  }

  return phoneRegEx.test(value.trim()) || indianPhoneRegEx.test(value.trim());
});

ValidatorForm.addValidationRule("isFileName", (value) => {
  return value.trim() === '' ? true : fileNameRegex.test(value.trim());
});

ValidatorForm.addValidationRule("folderName", (value) => {
  return value.trim() === '' ? true : folderNameRegex.test(value.trim());
});

// min number greater than 1
ValidatorForm.addValidationRule("gtZero", (value) => {
  value = value.replace("$", "");
  let floatValue = parseFloat(value);
  if (floatValue === "NaN" || floatValue <= 0 || floatValue === null) {
    return false;
  }
  return true;
});

ValidatorForm.addValidationRule("gtEqZero", (value) => {
  let intValue = parseInt(value, 10);
  if (intValue === "NaN" || intValue < 0 || intValue === null) {
    return false;
  }
  return true;
});

// is empty
ValidatorForm.addValidationRule("isEmpty", (value) => {
  return value.trim() === "" ? false : true;
});

ValidatorForm.addValidationRule("requiredEmployee", (value) => {
  return value ? (value.trim() === "" ? false : true) : false;
});

ValidatorForm.addValidationRule("negativeFloatingOnly", (value) => {
  if (numberFieldRegEx.test(value))
    return true;
  return false;
});
ValidatorForm.addValidationRule("isNumber", (value) => {
  if (value === "" || value === null)
    return true;
  else if (numberAndDecimalRegex.test(value))
    return true;
  return false;
});

// Profile Password Validators
ValidatorForm.addValidationRule("isSixChar", value => {
  return value.length < 6 ? false : true;
});
ValidatorForm.addValidationRule("isSymbol", value => {
  return value.search(/\d/) === -1 && value.search(/[!@#$^&*()_]/) === -1 ? false : true;
});
ValidatorForm.addValidationRule("isUpperCase", value => {
  return value.search(/[A-Z]/) === -1 ? false : true;
});
ValidatorForm.addValidationRule("isLowerCase", value => {
  return value.search(/[a-z]/) === -1 ? false : true;
});
ValidatorForm.addValidationRule("isMaxed", value => {
  return value.length > 50 ? false : true;
});

ValidatorForm.addValidationRule('isGoodDate', (value) => {
    if (!value) return true;

    value = moment(value).format("MM/DD/YYYY")
    if (value === "Invalid date") {
        return false
    }

    var reGoodDate = /^((0?[1-9]|1[012])[- /.](0?[1-9]|[12][0-9]|3[01])[- /.](19|20)?[0-9]{2})*$/;
    return reGoodDate.test(value);
})

ValidatorForm.addValidationRule("onlyNumberCanEmpty", value => {
  if (!value) return true
  return numberAlone.test(value)
});

ValidatorForm.addValidationRule('isFutureDate', (value) => {
  if (!value) return true;

  value = moment(value).format("MM/DD/YYYY")
  if (value === "Invalid date") {
      return false
  }

  return moment(new Date(value)).diff(moment(new Date()), 'days') >= 0
});

// to validate the given time is between 7AM and 6PM
ValidatorForm.addValidationRule('is7To6', (value) => {
  if(!value) return true
  if (value == "Invalid Date") return false;

  // if string convert to date
  if (typeof value == "string") {
    value = moment(`${moment().format("YYYY/MM/DD")} ${value}`)
  }

  if (!moment(value).isValid()) {
    return false
  }
  // greater than 7 AM and less than 6
  const selectedHour = moment(value).hour();
  return selectedHour >= 7 && selectedHour < 18;
});

// export the validations
export default ValidatorForm;
