// import constants
import { formStepperConstant } from "../constants";
import { baseAction } from "./baseAction";
const { success } = baseAction;

// Export All Alert Actions
export const formStepperAction = {
  prev,
  next,
  setPage,
};

/**
 * Paginate Prev Page
 *
 * @param {*} page
 * @returns
 */
function prev() {
  return (dispatch) => {
    dispatch(success(formStepperConstant.SURVEY_FORM_PREV, null));
  }
}

/**
 * Paginate Next Page
 *
 * @param {*} page
 * @returns
 */
function next() {
  return (dispatch) => {
    dispatch(success(formStepperConstant.SURVEY_FORM_NEXT, null));
  }
}

  /**
 * Paginate Next Page
 *
 * @param {*} page
 * @returns
 */
function setPage(page) {
  return (dispatch) => {
    dispatch(success(formStepperConstant.SURVEY_FORM_SET_PAGE, page));
  }
}
