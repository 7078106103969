import React, { useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

// Import History
import history from "../../config/history";

import ButtonComponent from "../ButtonComponent/ButtonComponent";
import TypographyComponent from "../TypographyComponent/TypographyComponent";

const style = {
  height: "100%",
  background: "#fff",
  maxWidth: "1000px",
  width: "100%",
  border: "1.5px dashed #A2A2A2",
  margin: "auto",
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  padding: "20px"
};

const DropZoneComponent = (props) => {
  // Get Props
  const { textData, importFileData, cancelBtnURL, accept } = props;

  // Set State
  const [disable, setDiasble] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState([]);

  // Dragde Data
  const onDrop = useCallback((fileData, reject) => {
    if (fileData && fileData.length > 0) {
      setDiasble(true);
      setFileName(fileData[0].name);
      setFileData(fileData[0]);
    } else {
      alert("Invalid File format");
    }
  }, []);

  const onImport = () => {
    if (fileName) {
      importFileData(fileData);
    }
  };
  // get drop zone props
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    disabled: disable,
    accept
  });

  return (
    <React.Fragment>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <Grid>
          <Grid container className="cursor-pointer">
            <Grid className="dflex justifycenter w100">
              <TypographyComponent
                variant="h1"
                className="colorGrey txtcenter txtMedium pr-1"
              >
                {textData}
              </TypographyComponent>
              <TypographyComponent
                variant="h1"
                className="colorTheme txtcenter txtMedium"
              >
                Import
              </TypographyComponent>
            </Grid>
          </Grid>

          <TypographyComponent
            variant="h3"
            className="pt-2 colorGrey txtcenter txtMedium"
          >
            {fileName}
          </TypographyComponent>
        </Grid>
        {disable &&
          <Grid className="pt-2">
            <ButtonComponent
              disabled={!disable}
              title={"Submit"}
              color={"primary"}
              size={"small"}
              pageClassName="mr-1"
              handleClick={(e) => {
                e.stopPropagation();
                onImport(fileData);
              }}
            ></ButtonComponent>
            <ButtonComponent
              title={"Cancel"}
              color={"secondary"}
              size={"small"}
              pageClassName="mr-1"
              handleClick={(e) => {
                e.stopPropagation();
                if (cancelBtnURL) {
                  history.push({ pathname: cancelBtnURL });
                } else {
                  history.push({ pathname: "/manage_people/participants" });
                }
              }}
            ></ButtonComponent>
          </Grid>
        }
      </div>
    </React.Fragment>
  );
};

DropZoneComponent.defaultProps = {
  textData: "",
  cancelBtnURL: "/manage_people",
  importFileData: () => { },
  accept: "application/vnd.openxmlformats-officedocument.wordprocessingml.document ,.csv,.xls,.xlsx"
};
DropZoneComponent.propTypes = {
  textData: PropTypes.string,
  importFileData: PropTypes.func,
  cancelBtnURL: PropTypes.string,
  accept: PropTypes.string
};

export default DropZoneComponent;
