import React from "react";
import { Link } from '@mui/material';

import Google from "../../assets/images/svg/white-regular.svg";
import { Grid } from "@mui/material";
import TypographyComponent from "../TypographyComponent/TypographyComponent";



const PoweredByGoogleComponent = () => {

    return (
        <Grid className="width100 applytranslatebold">
            <Grid className = "pt5">
                <TypographyComponent className="txtBold fontSize9" variant="h6">
                    DISCALIMER
            </TypographyComponent>
            </Grid>
            <Grid className={"pt5"}>
                <TypographyComponent className="fontSize9" variant="h6">
                    THIS SERVICE MAY CONTAIN TRANSLATIONS POWERED BY GOOGLE. GOOGLE DISCLAIMS ALL WARRANTIES RELATED TO THE TRANSLATIONS, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTIES OF ACCURACY, RELIABILITY, AND ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT.
            </TypographyComponent>
            </Grid>
            <Grid className={"pt5"}>
            <Link href="https://translate.google.com" target="_blank">
                    <img
                        src={Google}
                        className="mr-1"
                        alt="warning"
                    />
                </Link>
            </Grid>
        </Grid>
    );
}


export default PoweredByGoogleComponent;
