/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";

// import actions
import { alertAction, updateStateReducerAction } from "../../../../redux/actions";

// import components
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import ToolTipComponent from "../../../../components/ToolTipComponent/ToolTipComponent";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

// import images
import { PencilEdit } from "../../../../assets/images/svgComponents/ImageComponent";

function EditCategory(props) {
  const dispatch = useDispatch();

  // get Reducer data
  //  const activeCategoryTab = useSelector((state) => state.survey.activeCategoryTab);

  // Get Props Values
  const { classes, category, questionData } = props;
  let { questions, catOrder, catDetails } = questionData;

  // define state
  const [editCategory, setEditCategory] = useState(category);
  const [editCatPopup, setEditCatPopup] = useState(null);

  useEffect(() => {
    setEditCategory(category);
  }, [dispatch, catOrder, questionData]);

  // Update reducer data
  const updateReducer = (questions, catDetails, catOrder) => {
    const reducerData = { ...questionData, questions: questions, catDetails: catDetails, catOrder: catOrder };
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, reducerData));
  };

  // Anchor tag events
  const handleEditClick = (event) => {
    setEditCatPopup(event.currentTarget);
  };
  const handleClose = (value) => {
    setEditCatPopup(null);
    setEditCategory(value);
  };

  const open = Boolean(editCatPopup);
  const id = open ? "edit-category-popover" : undefined;

  // Edit category
  const saveEditCategory = (editCategory) => {
    let isDuplicateCategory = catOrder.filter(c => c !== category).filter(c => c.toLowerCase() === editCategory.toLowerCase()).length > 0;

    if (editCategory && !isDuplicateCategory) {
      // Change category in questions
      let changedQuestions = questions.map((ques, indx) => {
        if (ques.category === category) {
          ques.category = editCategory;
        }
        return ques;
      });
      // Change category in catDetails
      let changedCatDetails = catDetails[category];
      if (changedCatDetails) {
        changedCatDetails["en"]["name"] = editCategory;
        delete catDetails[category];
        catDetails[editCategory] = changedCatDetails;
      }
      // Change category in catOrder;
      let categoryIndex = catOrder.indexOf(category);
      if (categoryIndex > -1) {
        catOrder[categoryIndex] = editCategory;
      }
      updateReducer(changedQuestions, catDetails, catOrder);
      handleClose(editCategory);
    } else if (!editCategory) {
      dispatch(alertAction.error(questionData.template==="Manager 360"?surveyQuestionsConstants.EMPTY_COMPETENCY_NAME:surveyQuestionsConstants.EMPTY_CATEGORY_NAME));
    } else {
      dispatch(alertAction.error(questionData.template==="Manager 360" ? surveyQuestionsConstants.COMPETENCY_EXISTS : surveyQuestionsConstants.CATEGORY_EXISTS));
    }
  };

  return (
    <React.Fragment>
      <ToolTipComponent title={questionData.template==="Manager 360"?surveyQuestionsConstants.EDIT_COMP_NAME:surveyQuestionsConstants.EDIT_CAT_NAME} arrow>
        <div className={`${classes.peTabEdit} ${"peTabEdit pr5 pl5"}`} onClick={handleEditClick}>{PencilEdit()}</div>
        {/* <img src={TabAdd} alt="add tab" data-custom={true} className={`${classes.peTabEdit} ${"peTabEdit pr5 pl5 "}`} onClick={handleEditClick} /> */}
      </ToolTipComponent>
      <Popover
        id={id}
        open={open}
        anchorEl={editCatPopup}
        onClose={() => handleClose(category)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        classes={{
          root: classes.editCategoryPopover
        }}
      >
        <Grid className={`${classes.quest_addtab} ${"p15"}`}>
          <TextFieldComponent
            name="catname"
            value={editCategory}
            placeholder={questionData.template==="Manager 360"?"Enter competency name":"Enter category name"}
            margin={"dense"}
            autoComplete="off"
            maxLength={60}
            handleChange={(e) => {
              setEditCategory(e.value);
            }}
            className={"mb-1"}
            validators={["required"]}
          ></TextFieldComponent>
          <Button
            title={"Save"}
            size={"small"}
            variant="contained"
            color="primary"
            className={classes.add_catbtn}
            disableElevation
            onClick={() => {
              saveEditCategory(editCategory.trim());
            }}
          >
            Save
          </Button>
          <Button
            title={"Cancel"}
            variant="contained"
            color="secondary"
            disableElevation
            size={"small"}
            className={`${"ml-1"} ${classes.add_catbtn}`}
            onClick={() => handleClose(category)}
          >
            Cancel
          </Button>
        </Grid>
      </Popover>
    </React.Fragment>
  );
}

// default props
EditCategory.defaultProps = {
  classes: {},
  category: "",
  questionData: { questions: [], catOrder: [], catDetails: {} }
};

// prop types
EditCategory.propTypes = {
  classes: PropTypes.object,
  category: PropTypes.string,
  questionData: PropTypes.object
};

export default EditCategory;
