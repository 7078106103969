import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Link from "@mui/material/Link";

// Imports Styles
import AnchorComponentStyle from "./AnchorComponentStyle";

/**
 * Anchor Component
 * @param {*} props
 */
export function AnchorComponent (props) {
  // const preventDefault = (event) => event.preventDefault();

  const {
    classes,
    pageClassName,
    componentClassName,
    component,
    title,
    variant,
    color,
    href
  } = props;
  return (
    <Link
      variant={variant}
      component={component}
      color={color}
      href={href}
      className={`${pageClassName} ${classes[componentClassName]}`}
    // onClick={preventDefault}
    >
      {title}
    </Link>
  );
}

// default props
AnchorComponent.defaultProps = {
  classes: {},
  title: "",
  color: "inherit",
  href: "#",
  underline: "none",
  component: "a",
  variant: "h6",
  pageClassName: "",
  componentClassName: ""
};

// prop types
AnchorComponent.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  underline: PropTypes.string,
  variant: PropTypes.string,
  component: PropTypes.string,
  pageClassName: PropTypes.string,
  componentClassName: PropTypes.string,
  href: PropTypes.string
};

/** Export Component */
export default withStyles(AnchorComponentStyle)(AnchorComponent);
