import { actionsConstant } from "../constants/actionsConstant";

// Set Initial Table Data
const tableDatas = (sortName) => {
    return {
        data: [],
        page: {
            fieldFilters: {
                headerCols: [],
                fieldCols: [sortName],
                searchCols: []
            }
        }
    };
};

// Set Initial State
const initialState = {
    search: { ...tableDatas("name") },
    getOne: {},
    getActionplan: [],
    addedchoice: {}
};

export default function (state = initialState, action) {
    switch (action.type) {

        //ACTION PLAN Search
        case actionsConstant.ACTION_PLAN_SEARCH_REQUEST:
            return {
                ...state
            };
        case actionsConstant.ACTION_PLAN_SEARCH_SUCCESS:
            return {
                ...state,
                search: {
                    data: action.data.data,
                    page: action.data.page
                }
            };
        case actionsConstant.ACTION_PLAN_SEARCH_FAILURE:
            return {
                ...state
            };

        // //To get a Particular action plan
        case actionsConstant.ACTION_PLAN_GET_ONE_REQUEST:
            return {
                ...state,
                getOne: initialState.getOne
            };
        case actionsConstant.ACTION_PLAN_GET_ONE_SUCCESS:
            return {
                ...state,
                getOne: action.data.data
            };
        case actionsConstant.ACTION_PLAN_GET_ONE_FAILURE:
            return {
                ...state,
                search: initialState.search
            };
            //To clear previous state in Action plan in Opportunity areas Tab
            case actionsConstant.ACTION_PLAN_GET_ONE_CLEAR:
            return {
                ...state,
                getOne: {}
            };

        //To Create a Action Plan
        case actionsConstant.ACTION_PLAN_CREATE_REQUEST:
            return {
                ...state
            };
        case actionsConstant.ACTION_PLAN_CREATE_SUCCESS:
            return {
                ...state,
                getOne: {...initialState.getOne},
                addNewPlan: false
            };
        case actionsConstant.ACTION_PLAN_CREATE_FAILURE:
            return {
                ...state,
            };

        //To Update a Particular User
        case actionsConstant.ACTION_PLAN_UPDATE_REQUEST:
            return {
                ...state
            };
        case actionsConstant.ACTION_PLAN_UPDATE_SUCCESS:
            return {
                ...state,
                getOne: initialState.getOne
            };
        case actionsConstant.ACTION_PLAN_UPDATE_FAILURE:
            return {
                ...state,
                search: initialState.search
            };
        // UPDATE GET ONE CLIENT REDUCER DATA 
        case actionsConstant.ACTION_PLAN_GET_ONE_UPDATE:
            return {
                ...state,
                getOne: action.data
            };
        //To Remove a Particular User
        case actionsConstant.ACTION_PLAN_REMOVE_REQUEST:
            return {
                ...state
            };
        case actionsConstant.ACTION_PLAN_REMOVE_SUCCESS:
            return {
                ...state,
            };
        case actionsConstant.ACTION_PLAN_REMOVE_FAILURE:
            return {
                ...state,
            };

        //To get Action plan data
        case actionsConstant.ACTION_PLAN_DATA_REQUEST:
            return {
                ...state
            };
        case actionsConstant.ACTION_PLAN_DATA_SUCCESS:
            return {
                ...state,
                getOne: action.data.data
            };
        case actionsConstant.ACTION_PLAN_DATA_FAILURE:
            return {
                ...state,
            };

        // Add new choices to add plan
        case actionsConstant.OPPORTNITY_AREA_CHOICES:
            return {
                ...state,
                addedchoice: action.data
            }
        
        case actionsConstant.TOGGLE_ADD_PLAN_DIALOG:
            return {
                ...state,
                getOne: {}
            }

        default:
            return state;
    }
}
