import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
/* import { ValidatorForm } from "react-material-ui-form-validator";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab"; */
import IconButton from "@mui/material/IconButton";

// Import Styles and Image
import SpCallerStyles from "./SpCallerStyles";
import BackImg from "../../assets/images/svg/chev_back.svg";
import downloadImg from "../../assets/images/svg/reports_download.svg";
import SettingsImg from "../../assets/images/svg/setting_grey.svg";
// import OptionImg from "../../assets/images/svg/options.svg";

// Import Component
import Container from "../Containers/Container";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
/* import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";
import InformationComponent from "../../components/InformationComponent/InformationComponent"; */

// import constants
import { SpcallerConstants } from "../../config/constants";

// Import History
import history from "../../config/history";

function Search(props) {
  // get props
  const { classes } = props;

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid>
                <Grid container alignItems="center">
                  <img onClick={() => history.push("/spcaller")} className={classes.backimg} src={BackImg} alt="back"></img>
                  <TypographyComponent variant="h3" className="ml-1">{SpcallerConstants.SEARCH}
                  </TypographyComponent>
                </Grid>
              </Grid>

              <Grid>
                <Grid container alignItems="center">
                  <TypographyComponent
                    variant="h6" className="mr5">
                    27/08/2020 - 03/09/2020
                  </TypographyComponent>
                  <IconButton size="large">
                    <img src={SettingsImg} alt={"Settings"} />
                  </IconButton>
                  <IconButton size="large">
                    <img src={downloadImg} alt={"download"} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12}>

          </Grid>

        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
Search.defaultProps = {
  classes: {},
  handlePopoverClick: () => { }
};

// prop types
Search.propTypes = {
  classes: PropTypes.object,
  handlePopoverClick: PropTypes.func
};
export default withStyles(SpCallerStyles)(Search);
