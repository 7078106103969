const PageNoIeSupportStyle = theme => ({
  ieErrorRoot: {
    width: "100%",
    height: "100vh",
    display: "flex",
    background: "#fff",
    textAlign: "center",
    alignItems: "center",
    color: "#292929",
  },
  ieErrorContainer: {
    height: "100vh",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  errorText: {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    fontWeight: "600",
    color: "black",
  }
});

export default PageNoIeSupportStyle;
