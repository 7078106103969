import React from 'react';
import PropTypes from "prop-types";

import { Grid, IconButton } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

// Constants
import { dashboardConstants, ReportsConstants } from "../../../../config/constants";

//Components
import DashboardGaugeComponent from "../../../../components/DashboardGaugeComponent/DashboardGaugeComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

function OveralleNPSWidget(props) {
    const { classes, data, adminPreference } = props;
    const { overalleNPS } = data
    const { detractorsPercent, passivesPercent, promoterspercent, passives, promoters, detractors } = overalleNPS
    const fillPercent = promoterspercent - detractorsPercent

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.overalleNPS === 'boolean') {
            adminPreference['data']['hideDashWidgets']['overalleNPS'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return <Grid className={`${classes.dashboardTile} flexdirection`}>
        <Grid container>
            <Grid item className='dflexCenter width100 spacebetween'>
                <Grid item className='dflexCenter'>
                    <TypographyComponent variant='h5' className={'fontWeight600 width100 txtLeft'}>{ReportsConstants.OVERALL_ENPS_TITLE}</TypographyComponent>
                    <InformationComponent>
                        {ReportsConstants.OVERALL_ENPS_INFO}
                    </InformationComponent>
                </Grid>
                <ToolTipComponent title={ReportsConstants.CLOSE}>
                    <IconButton onClick={handleClose} size="large">
                        {DashboardClose()}
                    </IconButton>
                </ToolTipComponent>
            </Grid>
            <TypographyComponent variant='h6' className={'txtRegular fontSize13 mt5 width100 txtLeft'}>{ReportsConstants.OVERALL_ENPS_SUB_TITLE}</TypographyComponent>
        </Grid>
        <Grid container className={`height100 ${classes.overalleNPSContainer}`}>
            <DashboardGaugeComponent
                classes={classes}
                data={{ fillPercent: fillPercent }}
                chartType={"eNPS"}
                min={-100}
                max={100}
                mid={0} />
            <Grid className='dflex flexdirection justifycenter' gap={'15px'}>
                <Grid style={{ display: "flex", alignItems: "center" }} gap={'10px'}>
                    <TypographyComponent variant="h6" className="fontSize16 fontWeight600 txt-right" style={{minWidth: '46px'}}>
                        {`${promoterspercent}%`}
                    </TypographyComponent>
                    <Grid
                        style={{
                            width: "2px",
                            height: "20px",
                            background: "#B1B2B3",
                        }}
                    ></Grid>
                    <TypographyComponent variant='p' className="fontSize14" color="#B1B2B3">
                        {`${dashboardConstants.OVERALL_ENPS.PROMOTERS} (N=${promoters})`}
                    </TypographyComponent>
                </Grid>
                <Grid style={{ display: "flex", alignItems: "center" }} gap={'10px'}>
                    <TypographyComponent variant="h6" className="fontSize16 fontWeight600 txt-right" style={{minWidth: '46px'}}>
                        {`${passivesPercent}%`}
                    </TypographyComponent>
                    <Grid
                        style={{
                            width: "2px",
                            height: "20px",
                            background: "#B1B2B3",
                        }}
                    ></Grid>
                    <TypographyComponent variant='p' className="fontSize14"color="#B1B2B3">
                        {`${dashboardConstants.OVERALL_ENPS.PASSIVE} (N=${passives})`}
                    </TypographyComponent>
                </Grid>
                <Grid style={{ display: "flex", alignItems: "center" }} gap={'10px'}>
                    <TypographyComponent variant="h6" className="fontSize16 fontWeight600 txt-right" style={{minWidth: '46px'}}>
                        {`${detractorsPercent}%`}
                    </TypographyComponent>
                    <Grid
                        style={{
                            width: "2px",
                            height: "20px",
                            background: "#B1B2B3",
                        }}
                    ></Grid>
                    <TypographyComponent variant='p' className="fontSize14" color="#B1B2B3">
                        {`${dashboardConstants.OVERALL_ENPS.DETRACTORS} (N=${detractors})`}
                    </TypographyComponent>
                </Grid>
            </Grid>
        </Grid>
    </Grid>
}

// default props
OveralleNPSWidget.defaultProps = {
    classes: {},
    data: {},
    adminPreference: {},
};

// prop types
OveralleNPSWidget.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(OveralleNPSWidget);
