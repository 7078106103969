import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Chip from "@mui/material/Chip";
import CloseIcon from '@mui/icons-material/Close';

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";
import DeleteChipImg from "../../../../../assets/images/svg/close_white.svg";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";

//Import Actions
import { reportAction } from "../../../../../redux/actions";

function DemographicSelection(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // get props
  const { classes, reportData, metadata } = props;
  const { demographics } = reportData;

  // Chip Delete
  const handleDelete = (item, index) => {
    demographics[item].splice(index, 1);
    if (demographics[item].length === 0) {
      delete demographics[item];
    }
    const updatedReportData = {
      ...reportData,
      demographics: demographics,
    };
    dispatch(reportAction.updateReport(updatedReportData));
  };

  const dispNameGenerator = (item) => {
    // Get Demographic Name
    let selData=metadata.filter((e) => e.key === item)
    const demographicName = selData[0] && selData[0].value ? selData[0].value:"";
    return (
      <TypographyComponent
        variant="h6"
        title={demographicName}
      ></TypographyComponent>
    );
  };

  return (
    <React.Fragment>
      {demographics &&
        Object.keys(demographics).map((item) => (
          <Grid container alignItems="center" className="pb-1" key={item}>
            <Grid item sm={4}>
              {dispNameGenerator(item)}
            </Grid>
            <Grid item sm={8}>
              {demographics[item] &&
                Array.isArray(demographics[item]) &&
                demographics[item].map((itm, indx) => (
                  <Chip
                    key={itm}
                    label={itm}
                    classes={{
                      root: classes.demographicsChips
                  }}
                    onDelete={() => {
                      handleDelete(item, indx);
                    }} 
                    deleteIcon={<CloseIcon className={classes.demogrpahicsChipClose} />}
                  />
                ))}
            </Grid>
          </Grid>
        ))}
    </React.Fragment>
  );
}
// default props
DemographicSelection.defaultProps = {
  classes: {},
  reportData: {},
  metadata:[],
};

// prop types
DemographicSelection.propTypes = {
  classes: PropTypes.object,
  reportData: PropTypes.object,
  metadata:PropTypes.array
};
export default withStyles(ReportsStyles)(DemographicSelection);
