/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextValidator } from "react-material-ui-form-validator";

// Import Config Constants
import { errorsConstants } from "../../config/constants";

// Imports Styles
import TextFieldComponentStyle from "./TextFieldComponentStyle";

/**
 * TextField Component
 * @param {*} props
 */
function TextFieldComponent(props) {
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  /**
   * Handle
   * TextField Events
   */
  const handleChange = (event) => {
    if (
      (props.name === "pulse_obj.period.frequency" ||
        props.name === "expiry.period") &&
      event.target.value &&
      event.target.value <= 0
    ) {
      props.handleChange({ name: props.name, value: "" });
    } else {
      props.handleChange({ name: props.name, value: event.target.value });
    }
  };

  const handleClickShowPassword = (event) => {
    props.handleClickShowPassword(!props.showPassword);
  };

  const {
    id,
    name,
    type,
    value,
    variant,
    margin,
    fullWidth,
    validators,
    autoComplete,
    placeholder,
    className,
    showErrorMessages,
    size,
    showPassword,
    pwdVisibileHide,
    disabled,
    min,
    pattern,
    panel,
    InputProps,
    maxLength,
    onClick,
  } = props;
  // eslint-disable-next-line prefer-const
  let errorMessages = showErrorMessages
    ? validators.map((s) => errorsConstants[s])
    : [];

  return (
    // <div className="divWidth" style={{ width: "100%" }}>
      <TextValidator
        id={id}
        placeholder={placeholder}
        name={name}
        type={showPassword ? "text" : type}
        pattern={pattern}
        min={min}
        size={size}
        value={value}
        variant={variant}
        margin={margin}
        autoComplete={autoComplete}
        onClick={onClick}
        fullWidth={fullWidth}
        className={className}
        validators={validators}
        errorMessages={errorMessages}
        onChange={handleChange}
        disabled={disabled}
        inputProps={{
          maxLength,
        }}
        InputProps={
          pwdVisibileHide
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      style={{ width: "36px" }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      tabIndex={-1}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? (
                        <Visibility className="txtgrey" />
                      ) : (
                        <VisibilityOff className="txtgrey" />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }
            : {
                ...InputProps,
              }
        }
        panel={panel}
      />
    // </div>
  );
}

// default props
TextFieldComponent.defaultProps = {
  id: "",
  name: "",
  type: "",
  value: "",
  size: "medium",
  pattern: "",
  placeholder: "",
  min: "",
  variant: "outlined",
  fullWidth: true,
  margin: "dense",
  disabled: false,
  classes: {},
  className: "",
  autoComplete: "off",
  showErrorMessages: true,
  validators: [],
  maxLength: 2000,
  pwdVisibileHide: false,
  showPassword: false,
  handleChange: () => {},
  handleClear: () => {},
  handleClickShowPassword: () => {},
  InputProps: {},
};

// prop types
TextFieldComponent.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  pattern: PropTypes.string,
  min: PropTypes.string,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  fullWidth: PropTypes.bool,
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,
  showErrorMessages: PropTypes.bool,
  validators: PropTypes.array,
  maxLength: PropTypes.number,
  showPassword: PropTypes.bool,
  pwdVisibileHide: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleClear: PropTypes.func,
  handleClickShowPassword: PropTypes.func,
  InputProps: PropTypes.object,
};

// export component
export default withStyles(TextFieldComponentStyle)(TextFieldComponent);
