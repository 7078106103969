
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { contentLibraryAction, updateStateReducerAction } from "../../../../redux/actions";
import { contentLibraryConstant } from "../../../../redux/constants";

import AddRecomendation from "./AddRecomendation";
import RecomendationCard from "./RecomendationCard";

function RenderRecomendation(props) {

    const { classes, item, index } = props;
    const dispatch = useDispatch();

    const { questions } = useSelector(state => state.contentLibrary.recommendation_search);
    const [questionData, setQuestionData] = useState(null);

    // update the edited action alert here for both save or cancel
    const updateRecommendationData = (baseQusId, updatedQuestion) => {
        dispatch(contentLibraryAction.updateRecommendation(updatedQuestion));
    };

    // update the edited action alert here for both save or cancel
    const updateRecommendationCancel = (baseQusId, updatedQuestion) => {
        questions.filter((qus, index) => {
            if (baseQusId === qus.baseQusId) {
                questions[index] = { ...updatedQuestion };
            }
        });
        dispatch(updateStateReducerAction.update(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATION_QUESTIONS_REDUCER_UPDATE, questions));
    };

    return (
        <Grid item sm={12} className={"mt5"}>
            <Grid item sm={12} >
                <React.Fragment>
                    {item.editable ?
                        <AddRecomendation
                            classes={classes}
                            item={questionData || item}
                            questions={questions}
                            index={index}
                            editRecommendationData={(reducerData) => setQuestionData(reducerData)}
                            updateQuestionFlag={(isSave) => {
                                // if changes and save clicked
                                // else no changes or cancel
                                if (isSave && questionData) {
                                    // remove editable state after save
                                    delete questionData.editable;
                                    delete item.editable;
                                    // reset to old Action Alert
                                    updateRecommendationData(item.baseQusId, questionData);
                                    setQuestionData(null);
                                } else {
                                    // reset to old question item and disable edit
                                    delete item.editable;
                                    updateRecommendationCancel(item.baseQusId, item);
                                    setQuestionData(null);
                                }
                            }}
                        /> :
                        <RecomendationCard
                            classes={classes}
                            item={questionData || item}
                            questions={questions}
                            index={index}
                        />
                    }
                </React.Fragment>
            </Grid>
        </Grid>
    )
}

export default RenderRecomendation;

