import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

//Import Component
import Dropdown from "./QuestionTypes/Dropdown";
import Matrix from "./QuestionTypes/Matrix";
import OpenEnded from "./QuestionTypes/OpenEnded";
import SingleSelect from "./QuestionTypes/SingleSelect";
import YesorNo from "./QuestionTypes/YesorNo";

// Import Actions
import { meTakeSurveyAction } from "../../../redux/actions";

//import Constants
import { surveyQuestionsConstants } from "../../../config/constants";

function Question(props) {
  // Get Props Values
  const { langCode, item, requiredStatus, questionPanelHeight } = props;
  const { qusType, baseQusId } = item;

  // Define Dispatch
  const dispatch = useDispatch();
  let surveyQuesAnswer = "";

  const [errorStatus, setErrorStatus] = useState(requiredStatus);
  const response = useSelector((state) => state.meTakeSurvey.response);

  if (response.length > 0) {
    const quesResponse = response.find((res) => res.baseQusId == baseQusId);
    if (qusType == surveyQuestionsConstants.DROPDOWN) {
      surveyQuesAnswer = quesResponse && (quesResponse.answer_native || quesResponse.answer_native === 0) ? quesResponse.answer_native : "";
    }
    else {
      surveyQuesAnswer = quesResponse && (quesResponse.answer || quesResponse.answer === 0) ? quesResponse.answer : "";
    }
  }



  const handleAnswerUpdate = (response, responseEN = null) => {
    if (item.isRequired) {
      setErrorStatus(!response && response !== 0 ? true : false);
    }
    dispatch(meTakeSurveyAction.handleSurveyResponse(item, response, langCode, responseEN));
  };

  return (
    <>
      <Grid container>
        {qusType == surveyQuestionsConstants.SINGLE_SELECT ? (
          <SingleSelect {...props} handleAnswerUpdate={handleAnswerUpdate} surveyQuesAnswer={surveyQuesAnswer} errorStatus={requiredStatus ? requiredStatus : errorStatus} />
        ) : qusType == surveyQuestionsConstants.MATRIX ? (
          <Matrix
            {...props}
            handleAnswerUpdate={handleAnswerUpdate}
            surveyQuesAnswer={surveyQuesAnswer}
            errorStatus={requiredStatus ? requiredStatus : errorStatus}
            questionPanelHeight={questionPanelHeight}
          />
        ) : qusType == surveyQuestionsConstants.DROPDOWN ? (
          <Dropdown {...props} handleAnswerUpdate={handleAnswerUpdate} surveyQuesAnswer={surveyQuesAnswer} errorStatus={requiredStatus ? requiredStatus : errorStatus} />
        ) : qusType == surveyQuestionsConstants.YES_NO ? (
          <YesorNo {...props} handleAnswerUpdate={handleAnswerUpdate} surveyQuesAnswer={surveyQuesAnswer} errorStatus={requiredStatus ? requiredStatus : errorStatus} />
        ) : (
          <OpenEnded {...props} handleAnswerUpdate={handleAnswerUpdate} surveyQuesAnswer={surveyQuesAnswer} errorStatus={requiredStatus ? requiredStatus : errorStatus} />
        )}
      </Grid>
    </>
  );
}

// default props
Question.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  singleSelectRepeat: false,
  requiredStatus: false,
};

// prop types
Question.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  singleSelectRepeat: PropTypes.bool,
  requiredStatus: PropTypes.bool,
};

export default Question;
