import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import APIIntegrationStyles from "../APIIntegrationStyles";
import { BackPage } from "../../../assets/images/svgComponents/ImageComponent";

// Import Constants
import { internalAPIConstants } from "../../../config/constants";

// Import History
import history from "../../../config/history";

// Import Container
import Container from "../../Containers/Container";

// Import Child Containers
import InternalAPITabs from "./InternalAPIIntegration.Tabs";
import ConfigPanel from "./ConfigPanels/ConfigTabPanel";
import GenerateTokenPanel from "./GenerateTokenPanel";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import { apiIntegrationAction } from "../../../redux/actions";

// Tab Panel Function
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        //Tab Panel
        <Grid>
          <TypographyComponent variant="h6">{children}</TypographyComponent>
        </Grid>
      )}
    </div>
  );
}

// Tab Panel Props
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const InternalAPIIntegration = (props) => {
  // Assign Props
  const { classes, match, location } = props;
  const [respDemo,setRespdemo]= useState([])
  const [configdata,setconfigData] = useState(true)
 const dispatch = useDispatch()
  //Set Tabs State

  const { selectedSurveyIds , allSurveys , token} = useSelector((state) => state.apiIntegration.internalAPI);
  const responseDemographics = useSelector((state) => state.apiIntegration.internalAPI.responseDemographics);

  // const [value, setValue] = React.useState(0);

  // const surveyList = useSelector(state => state.survey.search);
  // let { data, page } = surveyList;
  // const surveyPreview = useSelector(state => state.survey.previewSurveyStatus);

  useEffect(()=>{
    if(configdata){
    dispatch(apiIntegrationAction.getPrevToken(()=>{
      setconfigData(false)
    }))}
  },[])

  useEffect(()=> {
    const list = responseDemographics?.map(function (item) {
      var obj = [];
      obj[item.value] = item.status;
      return obj;
    });
    let li = [];
    if (list?.length > 0) {
      li = list?.reduce(function (prev, curr) {
        return Object.assign(prev, curr);
      });
    }
    setRespdemo(li)
  },[responseDemographics])
  
  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid item xs={12} mb={"40px"}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid
                      onClick={() => history.push("/surveys")}
                      className={classes.backimg}
                    >
                      {BackPage()}
                    </Grid>
                    <TypographyComponent
                      variant={"h3"}
                      className="pl-1"
                      title={internalAPIConstants.SETUP_LABEL}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <InternalAPITabs classes={classes} match={match} location={location} />
          </Grid>
        </Grid>
        <Switch>
          <Route
            exact
            path={match.url}
            render={(props) => {
              return (
                <ConfigPanel {...props} respDemo={respDemo} configdata={selectedSurveyIds} allSurveys={allSurveys}/>
              );
            }}
          />

          <Route
            exact
            path={`${match.url}/generate-token`}
            render={(props) => {
              return (
                <GenerateTokenPanel {...props} />
              );
            }}
          />

          <Route
            exact
            path={`${match.url}/api-docs`}
            render={(props) => {
              return (
                <h2 style={{width: "100%", textAlign: "center"}}>API Docs is In Progress.</h2>
              );
            }}
          />
        </Switch>
      </Container>
    </React.Fragment >
  );
}
// default props
InternalAPIIntegration.defaultProps = {
  classes: {},
  match: {},
  location: {}
};

// prop types
InternalAPIIntegration.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
};
export default withStyles(APIIntegrationStyles)(InternalAPIIntegration);
