/* eslint-disable no-duplicate-case */
import { metadataConstant } from "../constants";

// Set Initial State
const initialState = {
  demographicsName: [],
  metadataWithRGI: [],
  metadata: [],
  metadataSelected: {}
};
const defaultField = ["country", "state"];

export default function (state = initialState, action) {
  switch (action.type) {
    // GET CLIENT METADATA FOR CONVERSION
    case metadataConstant.GET_METADATA_RGI_FAILURE:
      return {
        ...state
      };
    case metadataConstant.GET_METADATA_RGI_SUCCESS:
      return {
        ...state,
        metadataWithRGI: action.data.data
      };
    case metadataConstant.GET_METADATA_RGI_FAILURE:
      return {
        ...state,
        metadataWithRGI: initialState.metadataWithRGI
      };

    // GET CLIENT METADATA NAMES
    case metadataConstant.GET_METADATA_NAME_REQUEST:
      return {
        ...state
      };
    case metadataConstant.GET_METADATA_NAME_SUCCESS:
      return {
        ...state,
        demographicsName: action.data.data
      };
    case metadataConstant.GET_METADATA_NAME_FAILURE:
      return {
        ...state,
        demographicsName: initialState.demographicsName
      };

    // GET CLIENT METADATA
    case metadataConstant.GET_METADATA_REQUEST:
      return {
        ...state
      };
    case metadataConstant.GET_METADATA_SUCCESS:
      return {
        ...state,
        metadata: action.data.data
      };
    case metadataConstant.GET_METADATA_FAILURE:
      return {
        ...state,
        metadata: initialState.metadata
      };

    // Update single metadata field
    case metadataConstant.UPDATE_SINGLE_METADATA:
      return {
        ...state,
        metadataSelected: action.metadataSelected
      };

    // Update Metadata
    case metadataConstant.METADATA_UPDATE_REQUEST:
      return {
        ...state
      };
    case metadataConstant.METADATA_UPDATE_SUCCESS:
      return {
        ...state
      };
    case metadataConstant.METADATA_UPDATE_FAILURE:
      return {
        ...state
      };

    // Update metadata reducer
    case metadataConstant.UPDATE_METADATA_REDUCER:
      let metadataWithRGIUpdated = action.data.filter(m => ((m.type !== "system") && ((m.type === "auto") || (m.type === "demographic") || (m.type === "default" && defaultField.indexOf(m.key) > -1))));
      metadataWithRGIUpdated = metadataWithRGIUpdated.map(m => {
        const rgi = state.metadataWithRGI.filter(s => s.key === m.key)[0];
        return {
          ...m,
          rgi: rgi ? rgi.rgi : null
        }
      })
      return {
        ...state,
        metadata: action.data,
        metadataWithRGI: metadataWithRGIUpdated
      };
    case metadataConstant.UPDATE_METADATA_OPTIONS:
        return {
          ...state,
          metadataWithRGI: action.data,
        };
    default:
      return state;
  }
}
