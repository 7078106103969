const SearchInputComponentStyle = theme => ({
  root: {
    position: "relative",
    backgroundColor: "#f2f4f5",
    borderRadius: "30px"
  },
  inputRootWithValue: {
    paddingRight: "5px",
    height: "1.1876em !important"
  },
  inputRootWithOutValue: {
    paddingRight: "28px"
  },
  clearButton: {
    fontSize: "18px"
  },
  closeicon:{
    width: "28px",
    height: "28px"
  }
});

export default SearchInputComponentStyle;
