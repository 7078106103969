import { alertConstant, authConstant, uiConstant } from "../constants";
import { authService } from "../services";
import { baseAction } from "./baseAction";

// Import History
import history from "../../config/history";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const authAction = {
  login,
  logout,
  samlLogin,
  forgotPassword,
  resetPassword,
  resetPasswordToken
};

/**
 * Login User
 *
 * @param {*} username - username
 * @param {*} password - password
 */
function login(username, password) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(authConstant.LOGIN_REQUEST));

    authService.login(username, password).then(
      (data) => {
        dispatch(success(authConstant.LOGIN_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        // Redirect to roles based screen
        history.push({
          pathname: `/${data.page == "people" ? "manage_people" : data.page}`,
          state: {
            defaultCall: true
          }
        });
      },
      (error) => {
        if (error === 'Please reset your password') {
          dispatch(success(alertConstant.ALERT_SUCCESS, error));
        } else {
          dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
        dispatch(failure(authConstant.LOGIN_FAILURE, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Logout
 */
function logout(isTokenAvailable) {
  // disconnect from socket
  if (window.socketIO) {
    window.socketIO.disconnect();
  }
  return (dispatch) => {
    authService.logout(isTokenAvailable).then(
      (res) => {
        if (res?.['message'] === 'Valid Token') {
          history.push({pathname: `/surveys`});
        } else {
          dispatch(success(authConstant.LOGOUT));
        }
      }, error => {
        console.error(error)
      })
  };  
}

/**
 * Validate Saml Login
 * @param {*} email 
 */
function samlLogin(samlToken) {
  return (dispatch) => {
    dispatch(request(authConstant.SAML_LOGIN_REQUEST, samlToken));
    dispatch(request(uiConstant.LOADING_TRUE));
    authService.samlLogin(samlToken).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(authConstant.SAML_LOGIN_SUCCESS, data));

        // Redirect to roles based screen
        history.push({
          pathname: `/${data.page == "people" ? "manage_people" : data.page}`
        });
      },
      (error) => {
        dispatch(failure(authConstant.SAML_LOGIN_FAILURE, error));
        //dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


/**
 * A service method to create hash for the particular email user
 *
 * @param {*} email E mail string to forgot password
 * @returns
 */
function forgotPassword(email) {
  return (dispatch) => {
    dispatch(request(authConstant.FORGOT_PASSWORD_REQUEST, email));
    dispatch(request(uiConstant.LOADING_TRUE));
    authService.forgotPassword(email).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(authConstant.FORGOT_PASSWORD_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
      },
      (error) => {
        dispatch(failure(authConstant.FORGOT_PASSWORD_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Reset Password Token
 * @param {*} data
 * @param {*} callBack
 */
function resetPassword(params) {
  return dispatch => {
    authService.resetPassword(params)
      .then(
        data => {
          dispatch(success(authConstant.RESET_PASSWORD_SUCCESS, data));
          dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
          history.push({
            pathname: "/login"
          });
        },
        error => {
          dispatch(failure(authConstant.RESET_PASSWORD_FAILURE, error));
          dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
      );
  };
}

/**
 * Reset Password
 * @param {*} data
 * @param {*} callBack
 */
function resetPasswordToken(params) {
  return dispatch => {
    authService.resetPasswordToken(params)
      .then(
        data => {
          dispatch(success(authConstant.RESET_PASSWORD_TOKEN_SUCCESS, data));
          // Redirect to Expired Page
          history.push({
            pathname: "/expired"
          });
        },
        error => {
          dispatch(failure(authConstant.RESET_PASSWORD_TOKEN_FAILURE, error));
          // dispatch(failure(alertConstant.ALERT_ERROR, error));
        }
      );
  };
}