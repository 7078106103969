/* eslint-disable no-unneeded-ternary */
/* eslint-disable quote-props */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { IconButton } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles and Image
import PariticipantStyles from "../PariticipantStyles";
import BackImg from "../../../../assets/images/svg/chev_back.svg";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import Container from "../../../Containers/Container";

// Import Actions
import { participantAction, excelAction, alertAction } from "../../../../redux/actions";

// import constants
import { participantConstants, excelConstants } from "../../../../config/constants";

// Import History
import history from "../../../../config/history";

function SelectSurvey (props) {
  const dispatch = useDispatch();

  // get props
  const { classes, match } = props;

  // Get Survey Data
  useEffect(() => {
    dispatch(participantAction.overrideSurveysData());
  }, [dispatch]);

  // Get Survey Details
  const surveysData = useSelector((state) => state.participant.overrideSurveysData);

  // set state for survey name
  const [selectedSurvey, setSelectedSurvey] = useState("");

  // export data
  const OverrideDemographicsExport = (selectedSurvey) => {
    if (selectedSurvey) {
      dispatch(excelAction.download({ "survey": selectedSurvey, "excelType": excelConstants.OVERRIDE_DEMOGRAPHICS_DOWNLOAD }, true));
    } else {
      dispatch(alertAction.error(participantConstants.SELECT_SURVEY));
    }
  };

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p-2"
      >
        <Grid item>
          <Grid container alignItems="center">
            <IconButton
              color="inherit"
              className="p5"
              onClick={() => history.push("/manage_people/participants")}
              size="large">
              <img className={classes.backimg} src={BackImg} alt="back"></img>
            </IconButton>
            <TypographyComponent variant={"h3"} className="ml-1">
              {participantConstants.OVERRIDE_REPORT_DEMOGRAPHICS}
            </TypographyComponent>
          </Grid>
        </Grid>
      </Grid>

      <Container page={"sub"}>
        <Grid container>
          <Grid className="container" justifyContent="center">
            <Grid item className="mb-2">
              <TypographyComponent
                variant="h2"
                align="center"
                className="txtBold"
              >
                {participantConstants.OVERRIDE_DEMOGRAPHICS_TITLE}
              </TypographyComponent>
            </Grid>
            <Grid item className="mb-4">
              <TypographyComponent variant="h6" align="center" className="">
                {participantConstants.OVERRIDE_DEMOGRAPHICS_SUB_TEXT}
              </TypographyComponent>
            </Grid>
            <Grid item className={`${"mb-7"} ${classes.SelectSurvey}`}>
              <ValidatorForm
                id="add_participant"
                name="Add Participant"
                autoComplete="off"
                className="width100 height100"
                // onSubmit={handleSubmit}
                onError={(error) => {
                  console.log(error);
                }}
              >
                <TypographyComponent
                  title={participantConstants.UNASSIGN_SELECTASURVEY}
                  variant={"h6"}
                ></TypographyComponent>
                <FormControl className="width100">
                  <Select
                    className={"height40"}
                    name={"SelectRemoveSurvey"}
                    id="demographics"
                    value={selectedSurvey}
                    displayEmpty
                    variant="outlined"
                    onChange={(e) => setSelectedSurvey(e.target.value)}
                  >
                    <MenuItem value="">Select a survey</MenuItem>
                    {surveysData.map((item, keyIndex) => (
                      <MenuItem
                        key={keyIndex}
                        name={item.name}
                        value={item._id}
                      >
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ValidatorForm>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center">
                <ButtonComponent
                  disabled={!selectedSurvey ? true : false}
                  // disabled={true}
                  title={participantConstants.EXPORT_BUTTON}
                  color={"primary"}
                  size={"small"}
                  pageClassName="mr-1 buttonOrange"
                  handleClick={() => {
                    OverrideDemographicsExport(selectedSurvey);
                  }}
                ></ButtonComponent>
                <ButtonComponent
                  // disabled={true}
                  title={participantConstants.IMPORT_BUTTON}
                  color={"primary"}
                  size={"small"}
                  pageClassName="mr-1"
                  handleClick={() => {
                    history.push(`${match.url}/upload`);
                  }}
                ></ButtonComponent>
                <ButtonComponent
                  title={participantConstants.CANCEL_BUTTON}
                  // color={"secondary"}
                  className={classes.cancelBtn}
                  size={"small"}
                  pageClassName="mr-1"
                  handleClick={() => history.push("/manage_people/participants")}
                ></ButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
SelectSurvey.defaultProps = {
  classes: {},
  match: {}
};

// prop types
SelectSurvey.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object
};
export default withStyles(PariticipantStyles)(SelectSurvey);
