/* eslint-disable dot-notation */
// Check invalid conditions
export const conversionFieldValidation = (currentMetadata, metadata) => {
  try {
    const stringOperator = ["in", "nin", "contains"];
    const dateOperator = ["in", "nin", "less", "greater", "between"];
    const intOperator = ["in", "nin", "less", "greater", "between", "contains"];
    let invalidCount = 0;
    let isAllEmpty = false;

    if(currentMetadata.conversion[0].from.trim() ==='' && currentMetadata.conversion[0].operator.trim() === '' && currentMetadata.conversion[0].match.length <= 0 && currentMetadata.conversion[0].value.trim() === '')
      isAllEmpty = true
    
    const conversionArray = currentMetadata["conversion"] ? currentMetadata["conversion"] : [];
    const emptyRows = [];
    for (let i = 0; i < conversionArray.length; i++) {
      let fromFound = false;

      // get total empty rows
      const rowData = Object.keys(conversionArray[i]).filter(k =>  k !== "to").map(c => conversionArray[i][c]).join("");
      if (!rowData) {
        emptyRows.push(i)
      }

      // check all field datas are found
      let fieldNotFound = false;
      if ((!conversionArray[i]["from"] || conversionArray[i]["from"].trim() === "") ||
        (!conversionArray[i]["to"] || conversionArray[i]["to"].trim() === "") ||
        (!conversionArray[i]["operator"] || conversionArray[i]["operator"].trim() === "") ||
        // Added the condition to avoid ALl conditions required error message
        (!conversionArray[i]["value"] || conversionArray[i]["value"].trim() === "" || conversionArray[i]["value"].trim() === "NaN/NaN/NaN") ||
        (!conversionArray[i]["match"] || conversionArray[i]["match"].length === 0)) {
        fieldNotFound = true;
      }
      if (fieldNotFound === false) {
        // check from type
        let fromDataType = "";
        for (let j = 0; j < metadata.length; j++) {
          if (conversionArray[i]["from"] === metadata[j]["key"]) {
            fromDataType = metadata[j]["dataType"];
            fromFound = true;
            // break;
          }
        }
        // check Operator
        let operatorFound = false;
        if (fromDataType === "string" && stringOperator.indexOf(conversionArray[i]["operator"]) > -1) {
          operatorFound = true;
        } else if (fromDataType === "date" && dateOperator.indexOf(conversionArray[i]["operator"]) > -1) {
          operatorFound = true;
        } else if (fromDataType === "integer" && intOperator.indexOf(conversionArray[i]["operator"]) > -1) {
          operatorFound = true;
        }
        // check match field array
        let matchCheck = false;
        if (fromDataType === "date") {
          if (conversionArray[i]["operator"] === "between") {
            if (conversionArray[i]["match"].length === 2 &&
              new Date(conversionArray[i]["match"][0]) !="Invalid Date" &&
              new Date(conversionArray[i]["match"][1]) !="Invalid Date" &&
              new Date(conversionArray[i]["match"][1]) >= new Date(conversionArray[i]["match"][0])) {
              matchCheck = true;
            }
          } else {
            let invalidDate = false;
            let excessData = false;
            if ((conversionArray[i]["operator"] === "in" || conversionArray[i]["operator"] === "nin") && conversionArray[i]["match"].length !== 1) {
              // excessData = true;
            }
            for (let t = 0; t < conversionArray[i]["match"].length; t++) {
              if ((new Date(conversionArray[i]["match"][t]) instanceof Date) === false) {
                invalidDate = true;
              }
            }
            if (!invalidDate && !excessData) { matchCheck = true; }
          }
        } else if (fromDataType === "string") {
          matchCheck = true;
        } else if (fromDataType === "integer") {
          let isNotInt = false;
          for (let t = 0; t < conversionArray[i]["match"].length; t++) {
            if (isNaN(Number(conversionArray[i]["match"][t]))) {
              isNotInt = true;
            }
          }
          if (!isNotInt) { matchCheck = true; }
        }
        // Check To field
        // let toValid = false;
        // if (currentMetadata["dataType"] === "string") {
        //   toValid = true;
        // } else if (currentMetadata["dataType"] === "date" && (new Date(conversionArray[i]["value"]) instanceof Date) === true) {
        //   toValid = true;
        // } else if (currentMetadata["dataType"] === "integer") {
        //   toValid = true;
        // }
        // validation
        if (!fromFound || !operatorFound || !matchCheck) {
          invalidCount++;
        }
      } else {
        invalidCount++;
      }
    }
    const errorMessage = isAllEmpty ? 'No Conversions found' : 'All fields are required for conversion'
    return { conditionValidation: invalidCount, emptyRows: emptyRows.length, errorMessage  };
  } catch (err) {
    console.log(err)
    return false;
  }
};
