import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import Link from "@mui/material/Link";
import { baseAction } from "../../../../redux/actions/baseAction";

// Import Icons
import { SettingAccordGrey, SettingAccordWhite, Plus_blue, Delete } from "../../../../assets/images/svgComponents/ImageComponent";

// Import redux
import { useDispatch, useSelector } from "react-redux";
import { updateStateReducerAction } from "../../../../redux/actions";
import { participantAction } from "../../../../redux/actions";
import { metadataConstant, alertConstant } from "../../../../redux/constants";

// Import Constants
import { participantConstants } from "../../../../config/constants";

//Import Styles
import DemographicsStyles from '../DemographicsStyles'

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
import ConversionPopup from "./Conversion/ConversionPopup";
import CancelDialogComponent from "../../../../components/CancelDialogComponent/CancelDialogComponent";
import { ValidatorForm } from "react-material-ui-form-validator";

function OtherDemographicsPanel(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // Assign Props
  const { classes, metadataWithRGI, metadata, externalApiAccess } = props;

  // init delete
  const initDelete = {
    isDelete: false,
    demographic: {
      value: ""
    }
  };

  // state
  const [deleteObject, setDelete] = useState(initDelete);
  
  const [gridSize, setGridSize] = React.useState(3)

  // Get alerts
  const { request, success, failure } = baseAction;

  // Accordion
  const [expanded, setExpanded] = useState("otherdemographic-panel");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // Set Dialog State
  const [showDialog, setShowDialog] = useState({
    show: false,
    event: null,
    data: null
  })

  /**
  * Show Dialog 
   * Show Dialog 
  * Show Dialog 
  * @param {*} e 
   * @param {*} e 
  * @param {*} e 
  */
  const showAlertBox = (e, data) => {
    if (data && data.visible === true) {
      setShowDialog({
        show: true,
        event: e,
        data: data
      })
    } else {
      handleDemographicChange(e, data)
    }
  }

  /**
   * Handle Dialgo Yes Action
   */
  const handleDialogYesAction = () => {
    handleDemographicChange(showDialog.event, showDialog.data)
    setShowDialog({
      show: false,
      event: null,
      data: null
    })
  }

  /**
 * Handle Dialog Cancel Action
 */
  const handleDialogCancelAction = () => {
    return setShowDialog({
      show: false,
      event: null,
      data: null
    })
  }

  useEffect(()=>{
    if(externalApiAccess){
      setGridSize(2)
    }else{
      setGridSize(4)
    }
  },[externalApiAccess])

  // On demographic change
  // const handleDemographicChange = (e, data) => {
  //   if (e.name === "dataType") {
  //     let updateDataTypeNameData = e.value.toLowerCase()
  //     // if number change to integer
  //     if (e && e.name === 'dataType' && (updateDataTypeNameData === 'number' || updateDataTypeNameData === 'Number')) {
  //       updateDataTypeNameData = 'integer';
  //     }
  //     dispatch(participantAction.containsParticipants(data.key, (res) => {
  //       if (res) {
  //         dispatch(failure(alertConstant.ALERT_ERROR, "Cannot change datatype for this demographic"));
  //       } else {
  //         let resultData = [...metadata]
  //         resultData.filter((d) => d.key === data.key)[0][
  //           e.name
  //         ] = updateDataTypeNameData;
  //         dispatch(
  //           updateStateReducerAction.update(
  //             metadataConstant.UPDATE_METADATA_REDUCER,
  //             resultData
  //           )
  //         );
  //       }
  //     }));
  //   } else {

  //     let updateNameData;
  //     let updateData;
  //     let dataMeta = [...metadata];

  //     updateNameData = e.name === "dataType" ? e.value.toLowerCase() : e.value;
  //     if (e && e.name === "value") {
  //       let updateKeyData = updateNameData.toLowerCase().split(" ").join("_").replace(/[^a-zA-Z0-9]/g, "_") + new Date().getTime()
  //       updateKeyData = updateKeyData.replace(/\./g, "_");
  //       updateData = dataMeta.filter((d) => d.key === data.key)[0];
  //       updateData[e.name] = updateNameData;
  //       // only update key for newly added one
  //       if (data.isDeletable) {
  //         updateData.key = updateKeyData;
  //       }
  //     }

  //     updateData = dataMeta.filter((d) => d.key === data.key)[0][
  //       e.name
  //     ] = updateNameData;
  //     dispatch(
  //       updateStateReducerAction.update(
  //         metadataConstant.UPDATE_METADATA_REDUCER,
  //         dataMeta
  //       )
  //     );
  //   }
  // };

  // On demographic change
  const handleDemographicChange = (e, data) => {
    if (e.name === "dataType") {
      let updateDataTypeNameData = e.value.toLowerCase()
      // if number change to integer
      if (e && e.name === 'dataType' && (updateDataTypeNameData === 'number' || updateDataTypeNameData === 'Number')) {
        updateDataTypeNameData = 'integer';
      }
      dispatch(participantAction.containsParticipants(data.key, (res) => {
        if (res) {
          dispatch(failure(alertConstant.ALERT_ERROR, "The demographics datatype cannot be changed if the demographic value has been assigned already"));
        } else {
          let resultData = [...metadata]
          resultData.filter((d) => d.key === data.key)[0][
            e.name
          ] = updateDataTypeNameData;
          dispatch(
            updateStateReducerAction.update(
              metadataConstant.UPDATE_METADATA_REDUCER,
              resultData
            )
          );
        }
      }));
    } else {

      let updateNameData;
      let updateData;
      let dataMeta = [...metadata];

      updateNameData = e.name === "dataType" ? e.value.toLowerCase() : e.value;
      if (e && e.name === "value") {
        let updateKeyData = updateNameData.toLowerCase().split(" ").join("_").replace(/[^a-zA-Z0-9]/g, "_") + new Date().getTime()
        updateKeyData = updateKeyData.replace(/\./g, "_");
        updateData = dataMeta.filter((d) => d.key === data.key)[0];
        updateData[e.name] = updateNameData;
        // only update key for newly added one
        if (data.isDeletable) {
          updateData.key = updateKeyData;
        }
      }

      updateData = dataMeta.filter((d) => d.key === data.key)[0][
        e.name
      ] = updateNameData;
      dispatch(
        updateStateReducerAction.update(
          metadataConstant.UPDATE_METADATA_REDUCER,
          dataMeta
        )
      );
    }
  };

  // captilize first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (string === 'integer') string = 'number'

    return string && string.charAt(0).toUpperCase() + string.slice(1);
  };

  // create new demographics
  const generateNewDemographics = () => {
    let updatedData = [
      ...metadata,
      {
        dataType: "string",
        key: `other_demographics_${new Date().getTime()}`,
        type: "demographic",
        value: "",
        api_key_value: "",
        visibleInFilter: true,
        rgi: null,
        visible: true,
        conversion: null,
        isDeletable: true
      }
    ];
    dispatch(
      updateStateReducerAction.update(
        metadataConstant.UPDATE_METADATA_REDUCER,
        updatedData
      )
    );
  };

  /**
   * Delete newly created demograhic
   * @param {*} data contains to delete object
   */
  const deleteDemoGraphic = (data) => {
    let updatedData = metadata.filter(m => m.key !== data.key);
    dispatch(
      updateStateReducerAction.update(
        metadataConstant.UPDATE_METADATA_REDUCER,
        updatedData
      )
    );
  }

  // generate demographics
  const generateOtherDemographics = (metadata,externalApiAccess) => {

    useEffect(()=>{
      ValidatorForm.addValidationRule("Obj_key_valid", (value) => {
        if (/\s/.test(value)) {
          return false
        } else {
          return true
        }
      });
    },[metadata])
    // sort meta data by visiblity
    return metadata
      .filter((d) => d.type === "demographic")
      .map((data, pos) => {
        return (
          <React.Fragment>
            <Grid
              container
              alignItems="center"
              className={`${"mb-1"} ${classes.Demography_list}`}
            >
              <Grid item sm={3} className={`${"pr5 pl5"}`}>
                <TextFieldComponent
                  name="value"
                  id={data.key}
                  value={data.value}
                  size={"small"}
                  margin={"none"}
                  fullWidth={false}
                  // disabled={!data.visible}
                  validators={["isSameCustomName", "required"]}
                  handleChange={(e) => handleDemographicChange(e, data)}
                  className={`${classes.demography_input} ${classes.customName} ${!data.value ? "empty_field" : ""}`}
                ></TextFieldComponent>
              </Grid>
              <Grid item sm={gridSize} className={`${"pr5 pl5"}`}>
                <SelectFieldComponent
                  select
                  name="dataType"
                  value={capitalizeFirstLetter(data.dataType)}
                  validators={["required"]}
                  fullWidth={true}
                  // disabled={!data.visible}
                  handleChange={(e) => handleDemographicChange(e, data)}
                  options={participantConstants.DEMOGRAPHY_TYPES}
                />
              </Grid>
              {externalApiAccess ? 
              <Grid item sm={2} className={`${"pr5 pl5"}`}>
                <TextFieldComponent
                  name="api_key_value"
                  id={data.key}
                  value={data.api_key_value}
                  size={"small"}
                  margin={"none"}
                  fullWidth={false}
                  // disabled={!data.visible}
                  validators={["Obj_key_valid"]}
                  handleChange={(e) => handleDemographicChange(e, data)}
                  className={`${classes.demography_input} ${classes.customName} ${!data.value ? "empty_field" : ""}`}
                ></TextFieldComponent>
              </Grid>
              :""}
              <Grid item sm={1.5} className={`${"pr5 pl5 txtcenter"}`}>
                <SwitchComponent
                  name="visibleInFilter"
                  color="primary"
                  align="center"
                  disabled={!data.visible}
                  checked={data.visibleInFilter}
                  handleChange={(e) => handleDemographicChange(e, data)}
                />
              </Grid>
              <Grid item sm={1.5} className={`${"pr5 pl5 txtcenter"}`}>
                <Grid container alignItems="center" className={`${"txtcenter"}`}>
                  <ConversionPopup
                    classes={classes}
                    metadataSelected={data}
                    metadataWithRGI={metadataWithRGI}
                    metadata={metadata}
                  />
                </Grid>
              </Grid>
              <Grid container item sm={2} className={`${"pr5 pl5 txtcenter"}`}>
                <Grid item alignItems="center" className="pl-4">
                  <SwitchComponent
                    name="visible"
                    color="primary"
                    align="center"
                    checked={data.visible}
                    handleChange={(e) => showAlertBox(e, data)}
                  />
                </Grid>
                <Grid item alignItems="center" className="pl-4">
                  {
                    data.isDeletable && !data.value && (
                      <IconButton
                        color="inherit"
                        className={"p-0"}
                        onClick={() => deleteDemoGraphic(data)}
                        size="large">
                        {Delete()}
                      </IconButton>
                    )
                  }
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        );
      });
  };

  return (
    <Accordion
      className="mb-6 pe-accordion"
      expanded={expanded === "otherdemographic-panel"}
      onChange={handlePanelChange("otherdemographic-panel")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {expanded === "otherdemographic-panel" ?
          <Grid className={"pr5"}>
            {SettingAccordWhite()}
          </Grid>
          :
          <Grid className={"pr5"}>
            {SettingAccordGrey()}
          </Grid>
        }
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${expanded === "otherdemographic-panel" ? "panel-active" : ""
            } `}
          variant={"h5"}
        >
          {participantConstants.DEMOGRAPH_OTHERDEMOGRAPHICS}
        </TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "otherdemographic-panel" ? "panel-active" : ""
            }`}
          variant={"h6"}
        >
          {participantConstants.DEMOGRAPH_OTHERDEMOGRAPHICS_HELP}
        </TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className="p-2 width100">
          <Grid
            container
            spacing={3}
            className={`${classes.setting_expandcontainer}`}
          >
            <Grid item sm={12}>
              <Grid container className="pb-15">
                <Grid item sm={3} className={'pl-2'}>
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_CUSTOM_NAME}
                    variant={"h6"}
                    className="txtMedium"
                  >
                    <InformationComponent>{participantConstants.DEMOGRAPHY_CUSTOM_NAME_HELP}</InformationComponent>
                  </TypographyComponent>
                </Grid>
                <Grid item sm={gridSize} className={'pl-2'}>
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_TYPE}
                    variant={"h6"}
                    className="txtMedium"
                  ></TypographyComponent>
                </Grid>
                {externalApiAccess?
                <Grid item sm={2}>
                  <TypographyComponent
                    title={participantConstants.API_CUSTOM_NAME}
                    variant={"h6"}
                    className="txtMedium txtcenter"
                  ><InformationComponent>{participantConstants.DEMOGRAPHY_API_KEY_NAME_HELP}</InformationComponent></TypographyComponent>
                </Grid>
                 : ""}
                <Grid item sm={1.7}>
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_DISPLAYIN_REPORT}
                    variant={"h6"}
                    className="txtMedium txtcenter"
                  ></TypographyComponent>
                </Grid>
                <Grid item sm={1.5}>
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_CONVERSION}
                    variant={"h6"}
                    className="txtMedium txtcenter"
                  ></TypographyComponent>
                </Grid>
                <Grid item sm={1.5}>
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_VISIBLE}
                    variant={"h6"}
                    className="txtMedium txtcenter"
                  >
                    <InformationComponent>{participantConstants.DEFAULT_STATUS_HELP_TWO}</InformationComponent>
                  </TypographyComponent>
                </Grid>
              </Grid>
              {generateOtherDemographics(metadata.filter(m => m.visible),externalApiAccess,gridSize)}
              {generateOtherDemographics(metadata.filter(m => !m.visible),externalApiAccess,gridSize)}
            </Grid>
            <Link
              onClick={() => generateNewDemographics()}
              className={`${classes.quest_linkimg} cursor-pointer`}
              color="primary"
            >
              <IconButton color="inherit" className={"pl-2"} size="large">
                {Plus_blue()}
              </IconButton>
              {participantConstants.DEMOGRAPHY_ADDANITEM}
            </Link>
          </Grid>
        </Grid>
      </AccordionDetails>

      <CancelDialogComponent
        open={showDialog.show}
        content={participantConstants.DEMOGRAPHY_AUTOMATED_ALERT}
        showCancelBtn={false}
        handleOnNoAction={handleDialogCancelAction}
        handleOnYesAction={handleDialogYesAction}
      />
    </Accordion>
  );
}

// default props
OtherDemographicsPanel.defaultProps = {
  classes: {},
  metadataWithRGI: [],
  metadata: [],
  clientMetadata: [],
  handleChange: () => { }
};

// prop types
OtherDemographicsPanel.propTypes = {
  classes: PropTypes.object,
  metadataWithRGI: PropTypes.array,
  metadata: PropTypes.array,
  clientMetadata: PropTypes.array,
  handleChange: PropTypes.func
};

export default withStyles(DemographicsStyles)(OtherDemographicsPanel);
