import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Link from '@mui/material/Link';
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import _ from "lodash";

//Components
import StackedBar from "../../../../components/Charts/StackedBarChart/StackedBar";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

//import action
import { dashboardAction } from "../../../../redux/actions";

//constants
import { ReportsConstants } from "../../../../config/constants";

function GridContent(props) {
    const dispatch = useDispatch();

    const { classes, gridData, onClickButton, handleSorting, preference} = props

    // sort
    let yAxisSortField = preference?.["gridChart"]?.["yAxisSort"]?.sortField ? preference["gridChart"]["yAxisSort"].sortField : 1
    let yAxisSortBy = preference?.["gridChart"]?.["yAxisSort"]?.sortBy ? preference["gridChart"]["yAxisSort"].sortBy  : "desc"

    const sortOptions = ReportsConstants.MATRIX_SORT_OPTIONS
    
    //update key array
    const keyarrChange=(value, quesId)=>{
        if (quesId) {
            dispatch(dashboardAction.gridKeysArrUpdate({"quesId": quesId, "keyArr": value}))
        }
    }

    // custom demographics sort function
    const demographicsSortFunction = (currentData, keyArr, sortBy, groupArrCopiedForSorting) => (item) => {
        if ([1,2].includes(yAxisSortField)) {
            const meanRemovedDemographic = item.yAxis.replace(/\s\s\(N=\d+\)$/g, "")
            return meanRemovedDemographic.toLowerCase()
        } else if ([3,4].includes(yAxisSortField)) {
            if(keyArr.filter(val => val !== null).length===groupArrCopiedForSorting.filter(val => val !== null).length){
                const presentProperty = keyArr.reduce((acc, curr) => {
                    if (acc === undefined && currentData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
                        acc = curr
                    }
            
                    return acc;
                }, undefined);
                const val = item?.[presentProperty] || (sortBy === 'asc' ? Infinity : -Infinity)
                return -val;
            }else{
            const presentProperty = keyArr.reduce((acc, curr) => {
                if (currentData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
                    acc.push(curr);
                }
        
                return acc;
            }, []);
            const val = presentProperty.map(prop => item[prop]).reduce((acc, value) => acc + (value || 0), 0) || (sortBy === 'asc' ? Infinity : -Infinity)
            return -val;
        }

        }
    }

    let chartData = {}
    let groupArr = {}
    let keyArr = {}

    Object.keys(gridData).map((item)=>{
        let chartDataCopiedForSorting = gridData[item].finanlResponse.chartData
        let groupArrCopiedForSorting = [...gridData[item].finanlResponse.groupArr]
        let keyArrCopiedForSorting = [...gridData[item].finanlResponse.keyArr.filter(val => val)]
        let tempKeyArr = [...keyArrCopiedForSorting]

        chartDataCopiedForSorting = _.orderBy(chartDataCopiedForSorting, [demographicsSortFunction(chartDataCopiedForSorting, tempKeyArr, yAxisSortBy,groupArrCopiedForSorting)], yAxisSortBy)
        
        chartData[item] = chartDataCopiedForSorting
        groupArr[item] = groupArrCopiedForSorting
        keyArr[item] = keyArrCopiedForSorting
    })

    // get display name for sortby options
    const getSeletedSortOption = (options, value) => {
        let sortOptionSelected = options.filter((item) => item.value === value)[0] || ""
        return sortOptionSelected
    }
    
    return (
        <React.Fragment>
            <Grid container spacing={3} className="pb-2">
                <Grid item md={5}>
                    <Grid container>
                        <Grid md={2} className={"pt-1"}>
                        <TypographyComponent
                            className={"txtBold txtcenter"}
                            title={ReportsConstants.SORT_BY}
                            variant={"h6"}
                        ></TypographyComponent>
                        </Grid>
                        <Grid md={7} className={"pr-1"}>
                        <Autocomplete
                            id={"EngagnementIndexRGI"}
                            disableClearable
                            autoHighlight
                            blurOnSelect
                            options={sortOptions}
                            value={getSeletedSortOption(ReportsConstants.MATRIX_SORT_OPTIONS, yAxisSortField)}
                            className={`input-value-editor autocomplete heightone  ${classes.boxMaxWidth}`}
                            getOptionLabel={(option) => option.name || ""}
                            onChange={(e, val)=>{handleSorting({sortField: val.value, sortBy: val.sortBy })}}
                            renderInput={(params) => ( 
                            <TextField
                                {...params}
                                variant={"outlined"}
                                className={`${"height28 conversionAutoComplete boderRadius4"}`}
                                placeholder="Select Field"
                            />
                            )}
                        />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {Object.keys(gridData).map((item, index) => (
                <Grid container spacing={3} className="pb-2">
                    <Grid item xs={12}>
                        <Grid container>
                        <Grid className={"ml-1 mr-15"}>
                            <span className="dotyesnochart"></span>
                        </Grid>
                        <TypographyComponent
                            title={gridData[item].questionText}
                            variant={"body1"}
                        >
                        </TypographyComponent>
                        </Grid>
                        
                    </Grid>
                    <Grid className={`ml-auto pr-4 pt-1 pb-1 heightAuto ${classes.paddingLeft120}`} item xs={9}>
                        <StackedBar
                            className={`stackedbar${index}`}
                            chartId={`grid${index}chart`}
                            chartData={chartData[item]}
                            groupArr={groupArr[item]}
                            zoomMode={false}
                            rangeArr={gridData[item].finanlResponse.rangeArr}
                            keyarrChange={keyarrChange}
                            keyArr={keyArr[item]}
                            quesId={item}
                            chartWidth={1000}
                            chartHeight={320}
                        />
                        {gridData[item].finanlResponse.chartData.length > 7 &&
                            <Link onClick={onClickButton} className={`txtright`}>
                                Expand to view all
                            </Link>
                        }
                    </Grid>
                </Grid>
            ))}
        </React.Fragment>
    );
}

// default props
GridContent.defaultProps = {
    classes: {},
    gridData: {},
    onClickButton: () => {}
};

// prop types
GridContent.propTypes = {
    classes: PropTypes.object,
    gridData: PropTypes.object,
    onClickButton: PropTypes.func
};

export default GridContent;