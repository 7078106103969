const InformationComponentStyles = theme => ({
// c_tooltip : {
// position: 'relative',
// display: 'inline-block'
// },
// c_tooltiptext : {
// visibility: 'hidden',
// minWidth: '300px',
// background: '#F2F4F5 0% 0%',
// boxShadow: '0px 0px 1px #0000001F',
// color: '#292929',
// textAlign: 'center',
// padding: '20px',
// position: 'absolute',
// zIndex: '1',
// top: '110%',
// // left: '50%',
// right:'0',
// marginLeft:' -60px',
//  },

});

export default InformationComponentStyles;
