const HeaderComponentStyle = theme => ({
  root: {
    flexGrow: 1
  },
  rootBar: {
    height: 56
  },
  rootToolBar: {
    height: 56
  },
  logo: {
    marginRight: "20px",
    "& .logo_img": {
      height: "45px",
    }
  },
  headerSelectBox: {
    margin: "0 12px",
    // height: "44px"
  },
  profileImg: {
    "& img": {
      objectFit: "fill !important"
    }
  }
});

export default HeaderComponentStyle;
