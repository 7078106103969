// Base Questionn reducer types
export const baseQuestionsConstant = {
  BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_REQUEST: "BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_REQUEST",
  BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_SUCCESS: "BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_SUCCESS",
  BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_FAILURE: "BASE_QUESTIONS_BY_CATAGORY_AND_SURVEY_TYPE_FAILURE",

  BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_REQUEST: "BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_REQUEST",
  BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_SUCCESS: "BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_SUCCESS",
  BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_FAILURE: "BASE_QUESTIONS_BY_QUESTION_AND_SURVEY_TYPE_FAILURE",
};
