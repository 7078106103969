
import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

// Layout Style
import ContainerStyle from "./ContainerStyle.jsx";

// Import Config Layout
import { getContainterHeight, getSubContainterHeight, getHeaderTabsHeight, getTakeSurveyContainerHeight, getMETakeSurveyContainerHeight, getMETakeSurveySingleContainerHeight } from "../../config/layout";

function Container (props) {
  const { children, classes, align, page } = props;
 
  const height = page === "main" ? getContainterHeight() : page === "HeaderTabs" ? getHeaderTabsHeight() : page=="takeSurvey" ? getTakeSurveyContainerHeight() : page=="MEtakeSurvey" ? getMETakeSurveyContainerHeight() : page=="MEtakeSurveySingle" ? getMETakeSurveySingleContainerHeight() : getSubContainterHeight();

  return (
    <Grid container className={`${"scrolly scrollContainer heightUnsetOnMobile"} ${classes.root}`} alignItems={align} style={{ height: height }}>
      {children}
    </Grid>
  );
}

// default props
Container.defaultProps = {
  classes: {},
  children: {},
  align: "center",
  page: "main"
};

// Define Props Types
Container.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  align: PropTypes.string,
  page: PropTypes.string
};

export default withStyles(ContainerStyle)(Container);
