import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import Dashboard from "./Dashboard"
import Insight from "./Insight"
import InsightComment from "./InsightComment"
import Report from "./Report/Report"

// Import constants
import { userAccess, rolesAccess, reportAccess } from "../../config/pageAccess";

function ReportsRouter(props) {
  const { match, user } = props;
  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={(props) => {
          window.cachedCharts = {
            saoa: false
          };
          if (user.page.indexOf(userAccess.reports) > -1 && (user.access.indexOf(rolesAccess.reports) === -1 && user.access.some(r => reportAccess.indexOf(r) > -1))) {
            return <Report {...props} user={user} />
          } else {
            return <Dashboard {...props} user={user} />
          }
        }}
      />

      <Route
        exact
        path={`${match.url}/insight`}
        render={(props) => {
          window.cachedCharts = {
            hotspot: false,
            comment: false
          };
          return <Insight {...props} user={user} />
        }}
      />

      <Route
        exact
        path={`${match.url}/report`}
        render={(props) => {
          return <Report {...props} user={user} />
        }}
      />

      <Route
        exact
        path={`${match.url}/comment`}
        render={(props) => {
          return <InsightComment {...props} user={user} />
        }}
      />
    </Switch>
  );
}

/**
 * Bind Component Property Types
 */
ReportsRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default ReportsRouter;