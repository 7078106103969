/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import InputBase from "@mui/material/InputBase";
import { Grid, ButtonGroup, Button, Select, MenuItem, IconButton, TextField, Checkbox } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ValidatorForm } from "react-material-ui-form-validator";
import QueryBuilder, { Rule } from "react-querybuilder";
import Tooltip from "@mui/material/Tooltip";
import moment from 'moment';
import _ from 'lodash';

// Import Constants
import { participantConstants } from "../../config/constants";

// Import Components
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import { TextValidator, AutocompleteValidator, SelectValidator } from "./widgets/TextValidator";
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import InformationComponent from "../InformationComponent/InformationComponent";
import informationicon from "../../assets/images/svg/information.svg";

// Import css
import "./QueryBuilderStyle.css";

import { operators, getOperators, formatQuery, deFormatQuery, getDefaultRow, compareBetweenQueries } from "./config";
import { validations } from "./validations";

// Import images
import { Delete } from "../../assets/images/svgComponents/ImageComponent";
import { includes } from "lodash";


const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3)
    }
  },
  input: {
    borderRadius: 15,
    border: "1px solid transparent",
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: "13px",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderRadius: 15,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const getOptionSelected = (options, value, key = "name") => {
  let optionSelected = options.filter(p => p[key] === value)[0] || "";
  return optionSelected;
};

const sortOptions = (options, values, maxCount, inputValue = "", isCustomize = false) => {
  values = values || [];
  options = options || [];

  // for single select
  if (!Array.isArray(values)) {
    values = [values];
  }

  // filter values from options
  // filter empty or null options
  if (options && options.length > 0 && values.length > 0) {
    options = options.filter(op => values.indexOf(`${op}`) < 0 || !op);
  }

  return [...values.sort(), ...options]?.filter(f => {
    if (f) return `${f}`.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
    else return f; // for null values
  }).slice(0, maxCount);
};

// const validateRule = (rules) => {
//   return (rules || []).filter(r => !r.combinator).filter(r => !r.field || !r.operator || !r.value);
// };

// const validateGroup = (rules) => {
//   return [...validateRule(rules), ...(rules || []).filter(r => r.combinator).filter(r => r.rules.length === 0)];
// };

// const parseJSONOnLevel = ((json, level, type, propValue) => {
//   if (json?.constructor !== Object && !level && !type && !propValue) {
//     return type === 'value' ? [] : -1;
//   }

//   if (level === 0) {
//     const ruleIndex = json?.['rules'].findIndex(matchType => {
//       if (type === 'value') {
//         return _.isEqual(_.intersection(propValue, matchType?.[type]), propValue)
//       } else {
//         return matchType?.[type] === propValue
//       }
//     })

//     if (ruleIndex === -1) {
//       return type === 'value' ? [] : -1;
//     } else {
//       return type === 'value' ?
//         json?.['rules']?.[ruleIndex]?.[type] :
//         json?.['rules']?.[ruleIndex]?.[type] === propValue ?
//           ruleIndex :
//           -1;
//     }
//   } else if (json?.['rules'].some(rule => rule?.['combinator'])) {
//     return json?.['rules'].reduce((acc, curr, index) => {
//       if (curr?.['combinator']) {
//         // const ruleIndex = curr?.['rules'].findIndex(matchType => matchType?.[type] === propValue);

//         const ruleIndex = curr?.['rules'].findIndex(matchType => {
//           if (type === 'value') {
//             return _.isEqual(_.intersection(propValue, matchType?.[type]), propValue)
//           } else {
//             return matchType?.[type] === propValue
//           }
//         })

//         if (ruleIndex === -1) {
//           return type === 'value' ? [] : -1;
//         } else {
//           if (type === 'value') {
//             acc = curr?.['rules']?.[ruleIndex]?.[type]
//           } else {
//             acc.push(index, curr?.['rules']?.[ruleIndex]?.[type] === propValue ? ruleIndex : -1)
//           }
//         }
//       }

//       return acc;
//     }, [])
//   } else {
//     return type === 'value' ? [] : -1;
//   }
// });

const matchConditionBasedOnJSONType = (jsonType, ruleValue, propValue) => {
  // Query propValue is not reflected to ruleValue, so returing true
  return jsonType === 'dla' ? _.isEqual(_.sortBy(_.intersection(ruleValue, propValue)), _.sortBy(propValue)) : true;
}

const pathFinder = ((json, jsonType, field, operator, value, path = [], type='', restProps={}) => {
  if ((type === '' && type !== 'group') && (!field || !operator || !value)) {
    return undefined;
  }

  if (type === 'group') {
    const initalChecker = restProps?.['rules'].length === 0 ? undefined : restProps?.['rules']?.[0];
    if (initalChecker) {
      return pathFinder(json, jsonType, initalChecker?.field, initalChecker?.operator, initalChecker?.value, path, '', restProps)
    } else {
      return path;
    }
  }

  const ruleIndex =
    json?.['rules'] && json?.['rules'].length > 0 ?
      json?.['rules'].findIndex(rule => rule.field === field && rule.operator === operator && matchConditionBasedOnJSONType(jsonType, rule.value, value)) :
      -1;

  if (ruleIndex !== -1) {
    path.push(ruleIndex);
    return path;
  } else if (json?.rules && json?.rules.length > 0 && json?.rules.some(rule => rule?.combinator)) {
    const combinatorIndex =
      json?.rules && json?.rules.length > 0 ?
        json?.rules.findIndex(rule => rule?.combinator)
        : -1;
    if (combinatorIndex !== -1) {
      const pathValue = path.length > 0 ? [...path, combinatorIndex] : [combinatorIndex];
      path.push(combinatorIndex);
      return pathFinder(json?.['rules']?.[combinatorIndex], jsonType, field, operator, value, pathValue, '', {})
    }
  } else {
    return path;
  }
})

const isMatchedByContext = ((queryJSON, dlaQueryJSON, props) => {
  let queryJSONLevel = {};
  let dlaQueryJSONLevel = {};

  if (props?.context?.queryContext && props?.context?.queryContext?.constructor === Array && props?.context?.queryContext.length > 0) {
    props?.context?.queryContext.map((path, index) => {
      queryJSONLevel = index === 0 ? queryJSON?.['rules']?.[path] : queryJSONLevel?.['rules']?.[path];
      
      const updatedqueryLevel = index === 0 ? [index] : queryJSONLevel?.level &&  queryJSONLevel?.level.length > 0 ? [...queryJSONLevel?.level, index] : [index];
      
      queryJSONLevel = { ...queryJSONLevel, level: updatedqueryLevel};
    })
  }

  if (props?.context?.dlaContext && props?.context?.dlaContext.constructor === Array && props?.context?.dlaContext.length > 0) {
    props?.context?.dlaContext.map((path, index) => {
      dlaQueryJSONLevel = index === 0 ? dlaQueryJSON?.['rules']?.[path] : dlaQueryJSONLevel?.['rules']?.[path];
      
      const updateddlaQueryLevel = index === 0 ? [index] : dlaQueryJSONLevel?.level &&  dlaQueryJSONLevel?.level.length > 0 ? [...queryJSONLevel?.level, index] : [index];
      
      dlaQueryJSONLevel = { ...dlaQueryJSONLevel, level: updateddlaQueryLevel};
    })
  }

  return queryJSONLevel?.constructor === Object && dlaQueryJSONLevel?.constructor === Object && queryJSONLevel?.field === dlaQueryJSONLevel?.field && queryJSONLevel?.operator === dlaQueryJSONLevel?.operator && _.isEqual(_.intersection(queryJSONLevel?.value, dlaQueryJSONLevel?.value), queryJSONLevel?.value);
})

function QueryBuilderComponent(props) {
  const {
    classes, collectionFields, values, fieldOnChange, query, key,
    needSaveCancel, hideCancelButton, handleSaveOnClick, handleCancelOnClick, allowedOperators, isLoadedFromDB,
    defaultValidation, panel, needInnerGroup, component, dlaQuery, isDlaUser, isCustomDla, isDisableAll, restrictRepetitiveFields, isAutomaticTriggers
  } = props;
  const [isDisable, setIsDisable] = useState(false);

  useState(() => {
    setIsDisable(isDlaUser && isCustomDla && isDisableAll && query !== dlaQuery);
  }, [])
  const { fieldNames, queryJSON, hadEmptySet } = deFormatQuery(query);
  const { fieldNames: dlaQueryFieldNames, queryJSON: dlaQueryJSON, hadEmptySet: dlaQueryHadEmptySet } = deFormatQuery(dlaQuery);
  const [callMe, setCallMe] = useState(false);
  const [initQuery, setInitQuery] = useState(null);
  const [editQuery, setEditQuery] = useState(null);
  const [isUserEdited, setIsUserEdited] = useState(false);

  useEffect(() => {
    if (!callMe && queryJSON && (fieldNames && fieldNames.length > 0)) {
      // in case server issue set some delay and call the request to get field values
      fieldNames.map(f => fieldOnChange(f));
      setCallMe(true);
      setInitQuery(queryJSON);
      setEditQuery(queryJSON);
    } else if (isLoadedFromDB && !queryJSON && !callMe && (collectionFields && collectionFields.length > 0)) {
      let { name: fieldName } = collectionFields[0];
      const { defaultJSON } = getDefaultRow(fieldName);
      // fieldOnChange(fieldName)
      setCallMe(true);
      setInitQuery(defaultJSON);
      setEditQuery(defaultJSON);
    }
  }, [callMe, queryJSON, fieldNames, isLoadedFromDB, collectionFields]);

  useEffect(() => {
    if (hadEmptySet && JSON.stringify(queryJSON) !== JSON.stringify(initQuery)) {
      setInitQuery(queryJSON);
    }
  }, [hadEmptySet, queryJSON])

  const needValidation = (level) => {
    if (!defaultValidation && level === 0 && !queryJSON) {
      return false
    } else {
      return true
    }
  }

  const valueParser = ((json, context) => {
    let jsonLevel = null;

    if (context && context.length > 0) {
      if (context.length === 1) {
        jsonLevel = json?.['rules']?.[context[0]];
      } else {
        context.map((path, index) => {
          jsonLevel = index === 0 ? json?.['rules']?.[path] : jsonLevel?.['rules']?.[path];
        })
      }
    }

    return jsonLevel?.['value'] ? jsonLevel?.['value'] : null
  })

  const ValueEditor = (editorProps) => {
    let { handleOnChange, value, operator, field, level, context } = editorProps;
    
    const parsedDlaValue = valueParser(dlaQueryJSON, context?.dlaContext);
    const isMatchWithDla = isMatchedByContext(queryJSON, dlaQueryJSON, editorProps);
    
    const isCustomize = isDlaUser && isCustomDla && isMatchWithDla;
    const valueCondition = isDisable || (isDlaUser && parsedDlaValue && parsedDlaValue.length > 0) || isCustomize;

    if (value && `${value}`.split("-").length === 3 && field !== "tenure_groupings") {
      value = moment(value).isValid() ? moment(value).format("MM/DD/YYYY") : value;
    }
    const fieldValues = valueCondition ? parsedDlaValue : values[field];
    const MAX_OPTIONS_COUNT = 100;

    // Check end date is greater than start date
    useEffect(() => {
      ValidatorForm.addValidationRule("isEndDateValid", () => {
        return validations(editorProps);
      });

      ValidatorForm.addValidationRule("isValidBetweenValue1", () => {
        const { value } = editorProps;
        if (value && value.value1) {
          return /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value.value1) || /^[-+]?\d+$/.test(value.value1);
        }
        else if (!Array.isArray(value)) {
          return /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value) || /^[-+]?\d+$/.test(value);
        }

        return true;
      });

      ValidatorForm.addValidationRule("isValidBetweenValue2", () => {
        const { value } = editorProps;
        if (value && value.value2) {
          return /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(value.value2) || /^[-+]?\d+$/.test(value.value2);
        }

        return true;
      });

      ValidatorForm.addValidationRule("isValidDateFormat", (sValue) => {
        const { value } = editorProps;
        if (sValue) {
          return /^(0[1-9]|1[012])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/.test(sValue) || /^[-+]?\d+$/.test(sValue);
        }
        return true;
      });

    }, [editorProps]);

    const renderEditor = () => {
      switch (operator) {
        case "in":
        case "notIn":
          value = Array.isArray(value) ? value.map(v => `${v}`) : [];
          return (
            <AutocompleteValidator
              panel={panel}
              classes={{
                paper: "query-builder-option-wrapper",
                option: "query-builder-autocomplete-option"
              }}
              multiple
              disableClearable
              autoHighlight
              options={sortOptions(fieldValues, value, MAX_OPTIONS_COUNT, "")}
              filterOptions={(opt, { inputValue }) => {
                if (inputValue) {
                  const fOptions = sortOptions(fieldValues, value, MAX_OPTIONS_COUNT, inputValue);
                  if (fOptions.length > 0) {
                    return fOptions;
                  } else {
                    // return add options concep
                    return [{ option: inputValue, type: "new" }];
                  }
                } else {
                  return sortOptions(fieldValues, value, MAX_OPTIONS_COUNT, "");
                }
              }}
              value={value}
              className="input-value-editor autocomplete text-validator"
              getOptionLabel={(option) => option ? option || option.key || option.option : "null or empty"}
              onChange={(e, val) => {
                                val = val.map(v => v.option || v);
                                handleOnChange(val);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={Array.isArray(value) && value.length > 0
                    ? `(${value.length}) selected` : component ? "Type to Search" : "Search Values"}
                  variant="outlined"
                  disabled={isDisable || isCustomize}
                />
              )}
              renderTags={(value, getTagProps) => {
                return null;
              }}
              getOptionDisabled={(option) => {
                // return isCustomDla && isDlaUser && isMatchWithDla && fieldValues.length === 1;
                return isDisable;
              }}
              renderOption={(props, option, { selected }) => {
                return (
                  option && option.type === "new" ? (
                    <li {...props}>
                      <span>Create
                        "<span style={{ color: "#54C0FD" }}>{option.option}</span>" ?
                      </span>
                    </li>
                  ) : (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        className="autocomplete-checkbox"
                        checked={selected}
                      />
                      {option}
                    </li>
                  )
                );
              }}
              validators={needValidation(level) ? ["required"] : []}
              errorMessages={needValidation(level) ? ["This field is required"] : []}
              inputField={Autocomplete}
              // disabled={isDisable}
            />
          );
        case "=":
        case "!=":
        case ">":
        case "<":
        case ">=":
          return (
            <TextValidator
              panel={panel}
              className="text-validator-wrapper"
              name={`value-field-${field}-1`}
              type={"text"}
              size="small"
              placeholder={"Enter Value"}
              onChange={(e) => handleOnChange(e.target.value)}
              value={value || ""}
              validators={["required", "isValidDateFormat"]}
              errorMessages={["This field is required", "Number or Date(MM/DD/YYYY)"]}
              InputProps={{
                className: "input-value-editor text-validator"
              }}
              inputField={TextField}
              disabled={isDisable}
            />
          );
        case "<=":
          return (
            <TextValidator
              panel={panel}
              className="text-validator-wrapper"
              name={`value-field-${field}-1`}
              type={"text"}
              placeholder={"Enter Value"}
              onChange={(e) => handleOnChange(e.target.value)}
              value={value || ""}
              validators={["required", "isValidDateFormat"]}
              size="small"
              errorMessages={["This field is required", "Number or Date(MM/DD/YYYY)"]}
              InputProps={{
                className: "input-value-editor text-validator"
              }}
              inputField={TextField}
              disabled={isDisable}
            />
          );
        // return (
        //   <AutocompleteValidator
        //     panel={panel}
        //     disableClearable
        //     autoHighlight
        //     blurOnSelect={false}
        //     options={sortOptions(fieldValues, value, MAX_OPTIONS_COUNT, "")}
        //     filterOptions={(opt, { inputValue }) => {
        //       if (inputValue) {
        //         return sortOptions(fieldValues, value, MAX_OPTIONS_COUNT, inputValue);
        //       } else {
        //         return sortOptions(fieldValues, value, MAX_OPTIONS_COUNT, "");
        //       }
        //     }}
        //     value={value ? value : ""}
        //     className="input-value-editor autocomplete text-validator"
        //     getOptionLabel={(option) => option || "null or empty"}
        //     onChange={(e, val) => {
        //       handleOnChange(val);
        //     }}
        //     renderInput={(params) => (
        //       <TextField
        //         {...params}
        //         placeholder="Search Values"
        //       />
        //     )}
        //     inputField={Autocomplete}
        //   />
        // );
        case "between":
          return (
            <>
              <TextValidator
                panel={panel}
                className="text-validator-wrapper"
                name={`value-field-${field}-1`}
                type={"text"}
                size="small"
                placeholder={"Enter Value"}
                onChange={(e) => handleOnChange({ ...value, value1: e.target.value })}
                value={value && (value.value1 || value.value1 === 0) ? value.value1 : ""}
                validators={["required", "isValidBetweenValue1"]}
                errorMessages={["This field is required", "Number or Date(MM/DD/YYYY)"]}
                InputProps={{
                  className: "input-value-editor text-validator"
                }}
                inputField={TextField}
                disabled={isDisable}
              />
              <TextValidator
                panel={panel}
                className="text-validator-wrapper"
                name={`value-field-${field}-2`}
                type={"text"}
                size="small"
                placeholder={"Enter Value"}
                onChange={(e) => handleOnChange({ ...value, value2: e.target.value })}
                value={value && (value.value2 || value.value2 === 0) ? value.value2 : ""}
                validators={["required", "isEndDateValid", "isValidBetweenValue2"]}
                errorMessages={["This field is required", "End Date must be greater", "Number or Date(MM/DD/YYYY)"]}
                InputProps={{
                  className: "input-value-editor text-validator"
                }}
                validateData={editorProps}
                inputField={TextField}
                disabled={isDisable}
              />
            </>
          );
        case "isnull":
        case "notNull":
        default: return null;
      };
    };
    return renderEditor();
  };

  const QueryBuilderMemo = useMemo(() => (
    <QueryBuilder
      query={initQuery}
      key={key}
      controlElements={{
        combinatorSelector: (props) => {
          const activeClassName = props.rules.length > 1 ? "active" : "";
          const disableStatus = props.rules.length > 1 ? false : true;

          const queryContext = pathFinder(queryJSON, 'query', props?.field, props?.operator, props?.value, [], 'group', {rules: props?.rules, level: props?.level});
          const dlaQueryContext = pathFinder(dlaQueryJSON, 'dla', props?.field, props?.operator, props?.value, [], 'group', {rules: props?.rules, level: props?.level});
          props['context'] = {
            'dlaContext': dlaQueryContext,
            'queryContext': queryContext
          }

          let isMatchWithDla = false;
          const combinatorFilteredDla = (dlaQueryJSON?.['rules'] || []).filter(rule => !rule?.['combinator']);
          const combinatorFilteredQuery = (props?.['rules'] || []).filter(rule => !rule?.['combinator']);

          for (let inc = 0; inc < combinatorFilteredDla.length; inc++) {
            let flag = false;
            for (let innerInc = 0; innerInc < combinatorFilteredQuery.length; innerInc++) {
              const rule = combinatorFilteredQuery?.[innerInc];
              const queryParsed = combinatorFilteredDla?.[inc];

              const ruleCondition = rule?.field && rule?.operator && rule?.value && Array.isArray(rule?.value) && rule?.value.length > 0;
              const queryCondition = queryParsed?.field && queryParsed?.operator && queryParsed?.value && Array.isArray(queryParsed?.value) && queryParsed?.value.length > 0;

              if (ruleCondition && queryCondition) {
                const fieldCondition = rule?.field === queryParsed?.field;
                const operatorCondition = rule?.operator === queryParsed?.operator;
                const valueCondition = _.isEqual(_.intersection(_.sortBy(rule?.value), _.sortBy(queryParsed?.value)), _.sortBy(rule?.value));
                
                if (fieldCondition && operatorCondition && valueCondition) {
                  flag = true;
                  break;
                }
              }          

            }

            if (flag) {
              isMatchWithDla = true;
              break;
            }
          }
          
          return (
            <React.Fragment>
              <ButtonGroup
                size="small"
                aria-label="outlined button group"
                className="combinator-selector-btngrp"
              >
                <Button
                  disabled={disableStatus || isMatchWithDla || isDisable}
                  className={`${"query_and"} ${props.value === "and" ? activeClassName : ""}`}
                  onClick={(e) => props.handleOnChange("and")}>
                  AND
                </Button>
                <Button
                  disabled={disableStatus || isMatchWithDla || isDisable}
                  className={`${"query_or"} ${props.value === "or" ? activeClassName : ""}`}
                  onClick={(e) => props.handleOnChange("or")}>
                  OR
                </Button>

              </ButtonGroup>
              <Tooltip title={participantConstants.DLA_QUERYBUILDER_ANDOR_HELP} arrow>
                <IconButton color="inherit" className={"helpicon"} style={{ padding: "5px" }} size="large">
                  <img src={informationicon} alt="" />
                </IconButton>
              </Tooltip>
            </React.Fragment>
          );
        },
        addGroupAction: (props) => {
          // only display add group button on root
          if (props.level > 0 && needInnerGroup === false) return null;
          return (
            <span>
              <ButtonComponent
                title={"Add Group"}
                color={"primary"}
                size={"small"}
                style={{ padding: "4px 10px" }}
                handleClick={(e) => props.handleOnClick(e)}
                pageClassName={classes.settings_addrulebtn}
                disabled={isDisable}
              // disabled={validateGroup(props.rules).length > 0 || props.rules.length === 0}
              /><InformationComponent className="tabIcon">
                <TypographyComponent variant="tooltipbody">
                  {participantConstants.DLA_QUERYBUILDER_ADDRULE_HELP}
                </TypographyComponent>
              </InformationComponent>
            </span>
          );
        },
        removeGroupAction: (props) => {
          const path = pathFinder(queryJSON, 'query', props?.field, props?.operator, props?.value, [], 'group', {rules: props?.rules, level: props.level});
          const dlaPath = pathFinder(queryJSON, 'dla', props?.field, props?.operator, props?.value, [], 'group', {rules: props?.rules, level: props.level});

          const isMatchWithDla = path && path?.constructor === Array && path.length > 0 ? _.isEqual(path, dlaPath) : false;
          const renderCondition = isDisable ? false : isDlaUser && isCustomDla ? !isMatchWithDla : true;

          return (
            renderCondition &&
            <Tooltip arrow title={"Delete"}>
              <IconButton
                color="inherit"
                className="p5"
                onClick={(e) => props.handleOnClick(e)}
                size="large">
                {Delete()}
              </IconButton>
            </Tooltip>
          );
        },
        addRuleAction: (props) => {
          return (
            <ButtonComponent
              title={"Add Rule"}
              color={"primary"}
              size={"small"}
              style={{ padding: "4px 10px" }}
              handleClick={(e) => props.handleOnClick(e)}
              pageClassName={classes.settings_addrulebtn}
              disabled={isDisable}
            // disabled={validateRule(props.rules).length > 0}
            />
          );
        },
        fieldSelector: (props) => {
          const isMatchWithDla = isMatchedByContext(queryJSON, dlaQueryJSON, props);
          const optionSelected = getOptionSelected(collectionFields, props.value, "name", fieldNames);

          let resultantOperators = collectionFields || [];

          if (restrictRepetitiveFields || Array.isArray(fieldNames) && (fieldNames || []).length > 0) {
            resultantOperators = resultantOperators.filter(field => {
              const fieldTypeRestrictionCondition = ['string'].indexOf(field?.['type']) !== -1;
              const fieldNameRepetitiveCondition = (fieldNames || []).indexOf(field?.name) === -1;
              
              const dataLevelAccessCondition = !isAutomaticTriggers ? 
                // fieldTypeRestrictionCondition && fieldNameRepetitiveCondition :
                fieldNameRepetitiveCondition :
                fieldTypeRestrictionCondition ? fieldNameRepetitiveCondition : true;

              const returnCondition = field?.['name'] === optionSelected?.['name'] ? 
                true : 
                dataLevelAccessCondition
              return returnCondition;
            })
          }

          if (isMatchWithDla && optionSelected?.name) {
            const filterFields = restrictRepetitiveFields ? resultantOperators : collectionFields;
            resultantOperators = filterFields.filter((currentField) => { return currentField.name === optionSelected.name });
          }

          return (
            <AutocompleteValidator
              panel={panel}
              disableClearable
              autoHighlight
              blurOnSelect
              options={resultantOperators}
              value={optionSelected}
              className="input-value-editor text-validator autocomplete"
              getOptionLabel={(option) => option.label || ""}
              onChange={(e, val) => {
                props.handleOnChange(val.name);
                fieldOnChange(val.name);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Field"
                  variant="outlined"
                  disabled={isDisable || (isDlaUser && isCustomDla && isMatchWithDla)}
                />
              )}
              validators={needValidation(props.level) ? ["required"] : []}
              errorMessages={needValidation(props.level) ? ["This field is required"] : []}
              inputField={Autocomplete}
              disabled={isDisable}
            />
          );
        },
        operatorSelector: (props) => {
          const isMatchWithDla = isMatchedByContext(queryJSON, dlaQueryJSON, props);
          const validOperators = getOperators(collectionFields, props.field, allowedOperators);
          const resultantOperators = isMatchWithDla && props?.value ? validOperators.filter((operator) => { return operator.name === props.value }) : validOperators;

          return (
            <SelectValidator
              panel={panel}
              className="input-value-editor text-validator"
              value={props.value || ""}
              onChange={(e) => props.handleOnChange(e.target.value)}
              input={<BootstrapInput />}
              validators={needValidation(props.level) ? ["required"] : []}
              errorMessages={needValidation(props.level) ? ["This field is required"] : []}
              inputField={Select}
              disabled={isMatchWithDla || isDisable}
            >
              {
                resultantOperators.length < 1 &&
                <MenuItem disabled value={"No Options"} >{"No Options"}</MenuItem>
              }
              {
                resultantOperators.map((option, index) => (
                  <MenuItem value={option.name} key={index}>{option.label}</MenuItem>
                ))
              }
            </SelectValidator>
          );
        },
        valueEditor: ValueEditor,
        removeRuleAction: (props) => {
          const isMatchWithDla = isMatchedByContext(queryJSON, dlaQueryJSON, props);
          const renderCondition = isDisable ? false : isDlaUser && isCustomDla ? !isMatchWithDla : true;

          return (
            renderCondition &&
            <Tooltip arrow title={"Delete"}>
              <IconButton
                color="inherit"
                className="p5 rule-del-icon"
                onClick={(e) => props.handleOnClick(e)}
                size="large">
                {Delete()}
              </IconButton>
            </Tooltip>
          );
        },
        rule: (props) => {
          const context = {
            queryContext: pathFinder(queryJSON, 'query', props?.field, props?.operator, props?.value, [], '', {}),
            dlaContext: pathFinder(dlaQueryJSON, 'dla', props?.field, props?.operator, props?.value, [], '', {})
          };

          const ruleProps = {
            ...props,
            context: context
          };

          return <div className="rule-wrapper"><Rule {...ruleProps} /></div>;
        }
      }}
      operators={operators}
      fields={[
        { name: "", label: "Select Field" },
        ...collectionFields
      ]}
      onQueryChange={(updatedQuery) => {
        if (isLoadedFromDB && callMe) {
          if (updatedQuery && updatedQuery.rules && updatedQuery.rules.length) {
            const queryString = JSON.stringify(formatQuery(updatedQuery, collectionFields));
            if (queryString.indexOf("$selectField") < 0) {
              setEditQuery(queryString);
              // if user changes anything
              if (!isUserEdited) {
                setIsUserEdited(true);
              }
              props.handleQueryChange(updatedQuery, queryString, hadEmptySet);
            }
          } else {
            // all rules has been delete
            setEditQuery(null);
            props.handleQueryChange(updatedQuery, null, hadEmptySet);
          }
        }
      }}
      resetOnFieldChange={true}
      resetOnOperatorChange={true}
    />
  ), [initQuery, collectionFields, values]);
  return (
    <div className="queryBuilder-wrapper" key={`root-${key}`}>
      {QueryBuilderMemo}
      {
        needSaveCancel && (
          <Grid item xs={12} className="mt-1">
            <Grid container justifyContent={"flex-end"}>
              <Grid item>
                <ButtonComponent
                  title={"Save"}
                  color={"primary"}
                  size={"small"}
                  pageClassName={classes.settings_addrulebtn}
                  handleClick={() => {
                    handleSaveOnClick(editQuery);
                  }}
                ></ButtonComponent>
                {!hideCancelButton &&
                  <ButtonComponent
                    title={"Cancel"}
                    color={"secondary"}
                    size={"small"}
                    handleClick={() => {
                      handleCancelOnClick();
                    }}
                    pageClassName="ml-1"
                  ></ButtonComponent>
                }
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </div>
  );
}

QueryBuilderComponent.defaultProps = {
  collectionFields: [],
  key: "QueryBuilder",
  handleQueryChange: () => { },
  values: {},
  fieldOnChange: () => { },
  query: null,
  needSaveCancel: false,
  hideCancelButton: false,
  handleSaveOnClick: () => { },
  handleCancelOnClick: () => { },
  allowedOperators: [],
  isLoadedFromDB: null,
  defaultValidation: true,
  needInnerGroup: true,
  dlaQuery: null,
  isDlaUser: false,
  isCustomDla: false,
  isDisableAll: false,
  restrictRepetitiveFields: false,
  isAutomaticTriggers: false,
};

QueryBuilderComponent.propTypes = {
  classes: PropTypes.object,
  key: PropTypes.string,
  collectionFields: PropTypes.array,
  handleQueryChange: PropTypes.func,
  values: PropTypes.object,
  fieldOnChange: PropTypes.func,
  query: PropTypes.object,
  needSaveCancel: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  handleSaveOnClick: PropTypes.func,
  handleCancelOnClick: PropTypes.func,
  allowedOperators: PropTypes.array,
  isLoadedFromDB: PropTypes.string,
  defaultValidation: PropTypes.bool,
  needInnerGroup: PropTypes.bool,
  dlaQuery: PropTypes.object,
  isDlaUser: PropTypes.bool,
  isCustomDla: PropTypes.bool,
  isDisableAll: PropTypes.bool,
  restrictRepetitiveFields: PropTypes.bool,
  isAutomaticTriggers: PropTypes.bool,
};

export default QueryBuilderComponent;