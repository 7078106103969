import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
// import { TableLoader } from "../../../assets/images/svgComponents/ImageComponent"

import { NoReport } from "../../../assets/images/svgComponents/noReport"

import ReportsStyles from "../ReportsStyles";

// Import History
import history from "../../../config/history";

// import constants
import { ReportsConstants } from "../../../config/constants";

// Import Actions
import { reportAction } from "../../../redux/actions";

//import components
import SavedReports from "./SavedReports";
import CreateReport from "./CreateReport/CreateReport";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

//import table Dimentsions
import { getReportTableRowSize } from "../../../config/layout";

function Report(props) {
  // get props
  const { classes, location } = props;
  const dispatch = useDispatch();

  const tableRowSize = getReportTableRowSize();

  // Get reports List
  const { _id } = useSelector(state => state.profile.user.client_id)
  const reportList = useSelector((state) => state.report.savedReports);
  const { data, reportName, page } = reportList;
  //Get reports List on init
  useEffect(() => {
    if(page.skip){
      dispatch(reportAction.getSavedReports(page.skip,page.limit,page.sortBy,page.sort,page.sFields,page.sValues));
    } else {
      dispatch(reportAction.getSavedReports(1, tableRowSize, "name", "asc", [], []));
    }
  }, [dispatch, _id]);

  // Get loading status
  // const loader = useSelector((state) => state.report.loaders.Createreport);


  return (
    <React.Fragment>
      {/* {loader &&
          <Grid container justifyContent="center" alignItems="center" direction="column" className="height100">
          {TableLoader()}
        </Grid>
        } */}
      <Grid container>
        {((data && data.length > 0) || (reportName && reportName.length > 0)) &&
          <Grid container justifyContent="center" alignItems="center">
            <Grid item sm={12}>
              <Grid container justifyContent="space-between" className="mb-15">
                <Grid>
                  <TypographyComponent variant="h3">
                    {ReportsConstants.MYREPORTS}
                  </TypographyComponent>
                </Grid>
                <Grid>
                {reportList.page && reportList.page.sFields && reportList.page.sFields.length > 0 &&
                  <ButtonComponent
                      title={"Clear all"}
                      color={"primary"}
                      size={"small"}
                      type="submit"
                      variant={"text"}
                      pageClassName="mr-1"
                      handleClick={() => {
                        history.replace(`${props.location.pathname}?reset=true`);
                      }}
                    />
                }
                  <CreateReport classes={classes} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              <SavedReports classes={classes} location={location} />
            </Grid>
          </Grid>
        }
        {data && data.length === 0 &&
          <Grid container justifyContent="center" alignItems="center" direction="column" className="mt-5">
            <Grid className={"mb-2"}>
            {NoReport()}
            </Grid>
            <TypographyComponent variant={"h2"} className="mb-1 fontRoboto txtRegular">{ReportsConstants.HAVENT_SAVED}</TypographyComponent>
            <TypographyComponent variant={"h5"} className="mb-2">{ReportsConstants.HAVENT_SAVED_TEXT}</TypographyComponent>
            <CreateReport classes={classes} />
          </Grid>
        }

      </Grid>
    </React.Fragment>
  );
}
// default props
Report.defaultProps = {
  classes: {},
};

// prop types
Report.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(Report);
