import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { isIE } from 'react-device-detect';

// import constants
import { errorsConstants } from "../../config/constants";

// import child router components
import MeSurvey from "./MeSurvey";
import PageNoIeSupport from "../PageNoIeSupport/PageNoIeSupport";

function MeSurveyRouter(props) {
  const { match, takeSurveyUrl, surveyDeviceType, location, classes } = props;
  const qryString = queryString.parse(location.search);
  return (
    <>
      {
        isIE ? (<PageNoIeSupport errorMsg={errorsConstants.IE_NOT_SUPPORTED} />)
          :
          <MeSurvey {...props} takeSurveyUrl={takeSurveyUrl} surveyDeviceType={surveyDeviceType} match={match} queryString={qryString} />
      }
    </>
  );
}

/**
 * Bind Component Property Types
 */
MeSurveyRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
};

/**
 * Export Component
 */
export default MeSurveyRouter;
