import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

// Import Component
import LineChartComponent from "../../../../components/Charts/YesNoLineChart/YesNoLineChart"
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import YNOverallChart from "./YesNoOverallChart";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";

const YNWithRGIChart = (props) => {

  const { chartData, selectedValue, selectedValueIndex, classes, loading } = props

  // custom demographics sort function
  const demographicsSortFunction = (a, b) =>{
    const meanRemovedDemographic_a = a.replace(/\(\d+\)$/g, "")
    const meanRemovedDemographic_b = b.replace(/\(\d+\)$/g, "")
    if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue]){
      if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] !== undefined && ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b] !==undefined){
        if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] > ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b]){
          return 1
        }
        else if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] < ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b]){
          return -1
        }
      }
      else{
        if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_a] === undefined){
          return 1
        }
        else if (ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedValue][meanRemovedDemographic_b] === undefined){
          return -1
        }
        else{
          return 0
        }
      }
    }
    else{
      return 0
    }
  }
  
  Object.keys(chartData).map((item) => {
    if (chartData[item][selectedValue]) {
      chartData[item][selectedValue] = Object.keys(chartData[item][selectedValue]).sort().reduce((r, k) => (r[k] = chartData[item][selectedValue][k], r), {})
    }
  })
  return (
    <React.Fragment>
      {loading &&
        <LoaderComponent></LoaderComponent>
      }
      {!loading &&
        <Grid container spacing={4} className={classes.YesorNocontainers}>
          {chartData && selectedValue !== "no_breakout" && Object.keys(chartData).length > 0 && Object.keys(chartData).map((item, index) => (
            <Grid item sm={12} id="YesorNocontainerChilds">
              {item !== "demographics" &&
                <Grid container id="YesorNocontainerChildrens">
                  <Grid className={"ml-1 mr-15"}>
                    <span className="dotyesnochart"></span>
                  </Grid>
                  <Grid item sm={11}>
                    <TypographyComponent
                      title={`${chartData[item].OverallYesNo.questionText} (N=${chartData[item].OverallYesNo.participantCount})`}
                      variant={"body1"}
                    >
                    </TypographyComponent>
                  </Grid>
                  <Grid item sm={12}>
                    <Grid container>

                      <Grid container className="justifycenter mt-2">
                        <Grid className={classes.iconRow}>
                          <Grid className={classes.iconContainer}>
                            <Grid className={classes.YesLabelButton} />
                            <Grid className="fontSize14 txtgrey mr-15">{chartData?.[item]?.ansSet?.en?.[0] ? chartData[item].ansSet.en[0] : "Yes"}</Grid>
                            <Grid className={classes.NoLabelButton} />
                            <Grid className="fontSize14 txtgrey">{chartData?.[item]?.ansSet?.en?.[1] ? chartData[item].ansSet.en[1] : "No"}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={3} className={"selfcenter txtcenter"}>
                        <YNOverallChart
                          data={chartData[item]}
                          index={index}
                        />
                      </Grid>
                      {selectedValue && chartData["demographics"].indexOf(selectedValue) !== -1 &&
                        <Grid item sm={9} className={`${classes.lineChartContainer}`}>
                          {Object.keys(chartData[item][selectedValue]).sort(demographicsSortFunction).map((item1, index1) => (
                            <Grid item sm={9}>
                              {item1 !== "OverallYesNo" &&
                                <Grid container className="pb-2">
                                  <Grid item sm={3} className={"pr-15"}>
                                    <TypographyComponent
                                      variant={"p6"} className="fontSize13">{`${['Yes', 'No'].indexOf(item1) !== -1 && chartData?.[item]?.ansSet?.en?.[item1.toLowerCase() === 'yes' ? 0 : 1] ? chartData?.[item]?.ansSet?.en?.[item1.toLowerCase() === 'yes' ? 0 : 1] : item1} (${chartData[item][selectedValue][item1].participantCount})`}
                                    </TypographyComponent>
                                  </Grid>
                                  <Grid item sm={1} className={"dflex spacearound"}>
                                    <TypographyComponent variant="h5" className="fontSize14">{`${Math.round((chartData[item][selectedValue][item1].yes / chartData[item][selectedValue][item1].response) * 100)}%`}</TypographyComponent>
                                  </Grid>
                                  <Grid item sm={4}>
                                    <LineChartComponent
                                      height={"10px"}
                                      data={Math.round((chartData[item][selectedValue][item1].yes / chartData[item][selectedValue][item1].response) * 100)}
                                      chartId={`line${index}${selectedValueIndex}${index1}chart`}
                                    />
                                  </Grid>
                                  <Grid item sm={1} className={"dflex spacearound"}>
                                    <TypographyComponent variant="h5" className="fontSize14">{`${Math.round((chartData[item][selectedValue][item1].no / chartData[item][selectedValue][item1].response) * 100)}%`}</TypographyComponent>
                                  </Grid>
                                </Grid>
                              }
                            </Grid>
                          ))}
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          ))}
          {/* {(!chartData || Object.keys(chartData).length === 0 && (chartData.demographics && chartData.demographics.length === 0)) && ( */}
          { chartData.demographics && chartData.demographics.length === 0 && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="mt-3"
            >
              <TypographyComponent variant="h3">
                {ReportsConstants.NO_DATA_CHART}
              </TypographyComponent>
            </Grid>
          )}
          { selectedValue === "no_breakout" && Object.keys(chartData).map((item, index) => (
            <Grid item sm={12} id="YesorNocontainerChilds">
              {item !== "demographics" &&
                <Grid container id="YesorNocontainerChildrens">
                  <Grid item sm={7}>
                    <TypographyComponent
                      className={"pt-87"}
                      title={`${chartData[item].OverallYesNo.questionText} (N=${chartData[item].OverallYesNo.participantCount})`}
                      variant={"body1"}
                    >
                    </TypographyComponent>
                  </Grid>
                  <Grid item sm={5}>
                    <Grid container>                      
                      <Grid item sm={12} className={"selfcenter txtcenter"}>
                        <YNOverallChart
                          data={chartData[item]}
                          index={index}
                        />
                      </Grid>
                      <Grid container className="justifycenter mb-4 mt-2">
                        <Grid className={classes.iconRow}>
                          <Grid className={classes.iconContainer}>
                            <Grid className={classes.YesLabelButton} />
                            <Grid className="fontSize14 txtgrey mr-15">{chartData?.[item]?.ansSet?.en?.[0] ? chartData[item].ansSet.en[0] : "Yes"}</Grid>
                            <Grid className={classes.NoLabelButton} />
                            <Grid className="fontSize14 txtgrey">{chartData?.[item]?.ansSet?.en?.[1] ? chartData[item].ansSet.en[1] : "No"}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              }
            </Grid>
          )          
          )}
        </Grid>
      }

    </React.Fragment>
  );
};
// default props
YNWithRGIChart.defaultProps = {
  classes: {},
  chartData: {},
  selectedValue: "",
  selectedValueIndex: ""
};

// prop types
YNWithRGIChart.propTypes = {
  classes: PropTypes.object,
  chartData: PropTypes.object,
  selectedValue: PropTypes.string,
  selectedValueIndex: PropTypes.any
};
export default withStyles(ReportsStyles)(YNWithRGIChart);
