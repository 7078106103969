import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";

// Import Styles and Image
import { DialogueClose } from "../../../../../assets/images/svgComponents/ImageComponent";

import ParticipantStyles from "../../../Pariticipant/PariticipantStyles";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import LoaderComponent from "../../../../../components/LoaderComponent/LoaderComponent";

import ConversionList from "./ConversionList";
import AddConversion from "./AddConversion";
import SaveAndApplyButton from "./SaveAndApplyButton";
import ConversionDownload from "./ConversionDownload";
import ConversionUpload from "./ConversionUpload";
import { metadataAction } from "../../../../../redux/actions"

// import constants
import { participantConstants } from "../../../../../config/constants";

function Conversion(props) {
  // get props
  const { classes, metadataSelected, metadataWithRGI, metadata, onClose, isRendered, selConversion } = props;

  const [isLoading, setLoad] = useState(true)
  // get current metadata form redux
  const selectedMetadata = useSelector((state) => state.metadata.metadataSelected);

  const dispatch = useDispatch();

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setLoad(true)
      mounted.current = true;
    }
  });
  // useEffect(() => {
  //   dispatch(metadataAction.getMetadataWithRGI())
  // }, []);
  return (
    <React.Fragment>
      <div className={"centercontainer mt-0 pt-2"}>
        <Grid container spacing={1}>
          <Grid item xs={12} className={classes.dialog_close}>
            <Button variant="text" size={"small"} onClick={onClose}>
              {DialogueClose()}
              <TypographyComponent variant="h5" className="pl5">
                {participantConstants.CLOSE_BUTTON}
              </TypographyComponent>
            </Button>
          </Grid>

          <Grid container justifyContent="space-between" className="mt-4">
            <Grid item xs={10}>
              <Grid item xs={12}>
                <TypographyComponent variant="h4" className="txtSemiBold">
                  {metadataSelected.value}
                </TypographyComponent>
              </Grid>
              <Grid item xs={12}>
                <TypographyComponent variant="h6" color="textSecondary">
                  {participantConstants.DEMOGRAPHIC_CONVERSION_TEXT}
                </TypographyComponent>
              </Grid>
            </Grid>
            <Grid item xs={2}>
              <Grid container justifyContent="flex-end">
                <AddConversion
                  classes={classes}
                  metadata={metadata}
                  metadataSelected={{ ...selectedMetadata }}
                />
                <ConversionUpload
                  classes={classes}
                  metadataSelected={{ ...selectedMetadata }}
                />
                <ConversionDownload
                  classes={classes}
                  metadataSelected={{ ...selectedMetadata }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12} sm={12} xs={12} className={"pt-3 mb-1"}>
            <Grid container>
              {isRendered ? <ConversionList
                classes={classes}
                selConversion={selConversion}
                metadataSelected={{ ...selectedMetadata }}
                metadataWithRGI={metadataWithRGI}
                metadata={metadata}
              /> : <LoaderComponent />}
            </Grid>
          </Grid>

          <Grid item xs={12} className={"mt-1"}>
            <SaveAndApplyButton
              classes={classes}
              metadataSelected={{ ...selectedMetadata }}
              metadataWithRGI={metadataWithRGI}
              metadata={metadata}
              saveCallback={onClose}
            />
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}
// default props
Conversion.defaultProps = {
  classes: {},
  onClose: () => { },
  metadataSelected: {},
  metadataWithRGI: [],
  metadata: [],
  selConversion: []
};

// prop types
Conversion.propTypes = {
  classes: PropTypes.object,
  onClose: PropTypes.func,
  metadataSelected: PropTypes.object,
  metadataWithRGI: PropTypes.array,
  metadata: PropTypes.array,
  selConversion: PropTypes.array,
};
export default withStyles(ParticipantStyles)(Conversion);
