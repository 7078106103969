import React from "react";
import PropTypes from "prop-types";
// import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import Tooltip from "@mui/material/Tooltip";

// Import Icon
import { Information } from "../../assets/images/svgComponents/ImageComponent";

import informationicon from "../../assets/images/svg/information.svg";


// Imports Styles
import InformationComponentStyles from "./InformationComponentStyles";
// import { classes } from "istanbul-lib-coverage";


/**
 *Tool tip Information component
 *
 * @class Information Component
 * @extends {React.Component}
 */
function InformationComponent (props) {
  /**
   * Bind
   * HTML to DOM
   */
  const { children } = props;
  return (
    // <Grid className={`${classes.c_tooltip} ${"c_tooltip"}`}>
    //   <IconButton color="inherit" className={"p-0 ml-1"}>
    //     <img src={informationicon} alt="" />
    //   </IconButton>
    //   <span className={`${classes.c_tooltiptext} ${"c_tooltiptext"}`}>
    //     <span className={`${"c_tooltiptext_inside"}`}>
    //       {
    //         children
    //       }
    //     </span>
    //   </span>
    // </Grid>
    <Tooltip title={children} arrow className= "infoToolTip">
      <IconButton color="inherit" className={"p-0 ml-1"} size="large">
        {/* <img src={informationicon} alt="" /> */}
        { Information() }
      </IconButton>
    </Tooltip>
  );
}

// default props
InformationComponent.defaultProps = {
  title: "",
  placement: "bottom",
  className: "",
  children: ""
};

// prop types
InformationComponent.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any
};

// export component
export default withStyles(InformationComponentStyles)(InformationComponent);
