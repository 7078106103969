/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";


// Import Styles and Image
import AdminStyles from "./AdminStyles";

// Import Icons
import { SettingAccordGrey, SettingAccordWhite, BackPage } from "../../../assets/images/svgComponents/ImageComponent";


// Import Constants
import { adminConstants, clientConstants } from "../../../config/constants";
import { adminsConstant } from "../../../redux/constants";

// Import Actions
import { adminsAction, updateStateReducerAction, queryBuilderAction, alertAction, clientAction } from "../../../redux/actions";

// Import Container
import Container from "../../Containers/Container";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../components/InformationComponent/InformationComponent";
import AutoCompleteComponent from "../../../components/AutoCompleteComponent/AutoCompleteComponent";
import MultiSelectComponent from "../../../components/MultiSelectComponent/MultiSelectComponent";
import QueryBuilderComponent from "../../../components/QueryBuilderComponent/QueryBuilderComponent";
import DeleteDialogComponent from "../../../components/DeleteDialogComponent/DeleteDialogComponent";

// Import Helpers
import { getReducerUpdatedData } from "../../../helpers";
import { deFormatQuery } from "../../../components/QueryBuilderComponent/config";

// Import Icons
import settingsicon from "../../../assets/images/svg/settings-accord.svg";

// Import History
import history from "../../../config/history";
import MultiSelectComponentwithsuboption from "../../../components/MultiSelectComponent/MultiSelectComponentwithsuboption";

function EditAdmin(props) {

  // Define Dispatch
  const dispatch = useDispatch();

  const { classes, match } = props;
  const { params } = match;
  const { surveys, meSurveys, surveyNames, meSurveyNames } = useSelector(state => state.admin.surveyAccess);
  const { getOne } = useSelector(state => state.admin);
  const surveyAccess = getOne && getOne.surveyAccess ? getOne.surveyAccess : null;


  // Accordion
  const [expandedDefault, setExpandedDefault] = React.useState("default-panel");
  const [expandedDLA, setExpandedDLA] = React.useState("datalevel-panel");
  const [expandedSLA, setExpandedSLA] = React.useState("surveylevel-panel");
  const [openCancelDialog, setOpenCancelDialog] = useState({ value: "", open: false });
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [selectedSurveyNames, setSelectedSurveyNames] = useState([]);
  const [selectedMeSurvey, setSelectedMeSurvey] = useState([]);
  const [selectedMeSurveyNames, setSelectedMeSurveyNames] = useState([]);


  /**
     * Handle Defualt Panel
     */
  const handlePanelChangeDefault = (panel) => (event, newExpanded) => {
    setExpandedDefault(newExpanded ? panel : false);
  };

  /**
       * Handle DLA Panel
       */
  const handlePanelChangeDLA = (panel) => (event, newExpanded) => {
    setExpandedDLA(newExpanded ? panel : false);
  };
  /**
        * Handle SLA Panel
        */
  const handlePanelChangeSLA = (panel) => (event, newExpanded) => {
    setExpandedSLA(newExpanded ? panel : false);
  };

  const getSurveyNames = () => {
    let surveyNames = surveys.length > 0 ? ["Select All"] : []; // if need select all option
    surveys.forEach((ele) => {
      surveyNames.push(ele.name);
    })
    return surveyNames;
  }

  const getPulseSurveys = () => {
    let pulseSurveys = [];
    surveys.forEach((ele) => {
      if (ele.frequency === "Pulse") {
        pulseSurveys.push(ele.name);
      }
    })
    return pulseSurveys;
  }

  // state
  const [isDelete, setDelete] = React.useState(false);
  let admin = { ...getOne };
  const role = useSelector(state => state.admin.getRoles);
  // Add subroles to filter
  const reportSubRole = ["Comments", "Compliance", "Rehire Potential", "Raw Data"];
  const filtersubOption = role.filter(e => !reportSubRole.includes(e));
  
  const rolesList = filtersubOption.map((e) => {
    if (e === "Report") {
      return {
        option: e,
        subOption: reportSubRole.filter((subRole) => role.includes(subRole)),
      };
    } else {
      return {
        option: e,
      };
    }
  });
  // const rolesList = [{option :"Survey" },{option :"People" },{option :"Report",subOption:["Comments","Compliance","Rehire Potential","Raw Data"]},{option :"Actions" }];
  const [reloadQB, setReloadQB] = useState(0);

  /**
    * Get Particular Admin
    */
  useEffect(() => {
    dispatch(adminsAction.getRoles());
    dispatch(adminsAction.getSurveys());
    dispatch(clientAction.getOne());
  }, [dispatch]);

  const { audience: queryBuilderData, loading } = useSelector(state => state.queryBuilder);
  const isAllLoaded = queryBuilderData.fields.length > 0 && admin.client_id;
  const clientMeStatus = useSelector(state => state.client.getOne.managerEffectiveness);

  /**
      * Get Admin data
      */
  useEffect(() => {
    if (surveyAccess && surveyAccess.surveys && surveyAccess.surveys.length > 0 && selectedSurveyNames.length == 0) {
      let selectedSurvey = [];
      let selectedSurveyName = surveyAccess.surveys.length === surveys.length ? ["Select All"] : [];
      surveys.filter((val) => {
        if (surveyAccess.surveys.includes(val._id)) {
          selectedSurvey.push(val._id);
          selectedSurveyName.push(val.name);
        }
      });
      setSelectedSurvey([...selectedSurvey]);
      setSelectedSurveyNames([...selectedSurveyName]);
    }

    if (surveyAccess && surveyAccess.meSurveys && surveyAccess.meSurveys.length > 0 && selectedMeSurveyNames.length == 0) {
      let selectedMeSurvey = []; let selectedMeSurveyName = surveyAccess.meSurveys.length === meSurveys.length ? ["Select All"] : [];
      meSurveys.filter((val) => {
        if (surveyAccess.meSurveys.includes(val._id)) {
          selectedMeSurvey.push(val._id);
          selectedMeSurveyName.push(val.name);
        }
      });
      setSelectedMeSurvey([...selectedMeSurvey])
      setSelectedMeSurveyNames([...selectedMeSurveyName]);
    }
  }, [surveyAccess]);



  /**
     * Get Particular Admin
     */
  useEffect(() => {
    dispatch(adminsAction.getOne(params.id));
  }, [dispatch, params.id]);

  /**
     * Query Builder Componenet for DLA
     */
  useEffect(() => {
    dispatch(queryBuilderAction.getFieldNames("audience"));
  }, [dispatch]);

  /**
    * Handle form change
    */
  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, admin);
    dispatch(updateStateReducerAction.update(adminsConstant.ADMINS_GET_ONE_UPDATE, reducerData));
  };

  /**
    * Handle Form Submit
    */
  function handleSubmit(e) {
    e.preventDefault();
    dispatch(adminsAction.update(admin));
  }

  /**
    * Handle Delete Submit
    */

  function handleDelete(e) {
    setDelete(true);
  }

  const handleAdminDelete = () => {
    dispatch(adminsAction.remove(admin._id, () => {
      history.push({
        pathname: "/manage_people/admins"
      });
    }))
  };
  /**
       * Cancel Button Event
       */
  const handleCancel = () => {
    setOpenCancelDialog({ value: "", open: true });
  };

  /**
    * Handle Dialog No Action
    */
  const handleDialogNoAction = () => {
    history.push({
      pathname: "/manage_people/admins"
    });
  };

  /**
    * Handle Dialog Close Action
    */
  const handleDialogCancelAction = () => {
    setOpenCancelDialog({ value: "", open: false });
  };

  /**
    * Handle Participant Delete Cancel
    */
  const handleAdminCancelDelete = () => {
    setDelete(false);
  };

  /**
    * Handle form change
    */
  const handleRolesChange = (e) => {
    const { name, value } = e;
    let values = []
    value.map((e)=>{if(e.option){
      values.push(e.option)
    }else{
      values.push(e)
    }})
    e["value"] = values
    console.log(e)
    admin = { ...admin, [name]: values };
    handleChange(e);
  };

  /**
     * handleBack
     */
  function handleBack(e) {
    history.replace("/manage_people/admins?refresh=true");
  }

  const getMeSurveyNames = () => {
    let surveyNames = meSurveys.length > 0 ? ["Select All"] : []; // if need select all option
    meSurveys.forEach((ele) => {
      surveyNames.push(ele.name);
    })
    return surveyNames;

  }

  //autocomplete component change
  const handleMeAutoCompleteChange = (event) => {
    const selectedVal = event.value;
    const action = event.action

    let selectedMeSurveyIds = [];
    let selectedMeSurveyNames = [];
    let meSelectAll = false;
    const isAllSelected = selectedVal.find((ele) => ele === "Select All");

    if (isAllSelected) {
      meSelectAll = true;
      if (action === "selectOption") {
        meSurveys.forEach((val) => {
          selectedMeSurveyIds.push(val._id);
          selectedMeSurveyNames.push(val.name);

        })
        setSelectedMeSurvey([...selectedMeSurveyIds]);
        setSelectedMeSurveyNames(["Select All", ...selectedMeSurveyNames]);
        dispatch(adminsAction.updateSurveyAccess({ type: "ME", data: { meSurveys: [...selectedMeSurveyIds], meSurveyNames: ["Select All", ...selectedMeSurveyNames], meSurveySelectAll: true } }))
      }
      else {
        dispatch(alertAction.error('You must unselect “All Surveys” to assign only specific surveys to this user.'))
      }
    }
    else {
      if (meSurveys.length === selectedVal.length && action === "removeOption") {
        meSelectAll = false;
        setSelectedMeSurvey([]);
        setSelectedMeSurveyNames([]);
        dispatch(adminsAction.updateSurveyAccess({ type: "ME", data: { meSurveys: [], meSurveyNames: [], meSurveySelectAll: false } }))

      }
      else if (meSurveys.length === selectedVal.length && action === "selectOption") {
        meSurveys.forEach((val) => {
          selectedMeSurveyIds.push(val._id);
          selectedMeSurveyNames.push(val.name);
        })
        meSelectAll = true;

        setSelectedMeSurvey([...selectedMeSurveyIds]);
        setSelectedMeSurveyNames(["Select All", ...selectedMeSurveyNames]);
        dispatch(adminsAction.updateSurveyAccess({ type: "ME", data: { meSurveys: [...selectedMeSurveyIds], meSurveyNames: ["Select All", ...selectedMeSurveyNames], meSurveySelectAll: true } }))

      }
      else {
        selectedVal.forEach((val) => {
          let surveyData = meSurveys.find((ele) => ele.name === val);
          selectedMeSurveyIds.push(surveyData._id);
          selectedMeSurveyNames.push(surveyData.name);
        })
        meSelectAll = false;
        setSelectedMeSurvey([...selectedMeSurveyIds]);
        setSelectedMeSurveyNames([...selectedMeSurveyNames]);
        dispatch(adminsAction.updateSurveyAccess({ type: "ME", data: { meSurveys: [...selectedMeSurveyIds], meSurveyNames: [...selectedMeSurveyNames], meSurveySelectAll: false } }))

      }
    }
    // handleChange({ name: "meSelectAll", value: meSelectAll })
  }

  //autocomplete component change
  const handleAutoCompleteChange = (event) => {
    const selectedVal = event.value;
    const action = event.action
    let selectedSurveyIds = [];
    let selectedSurveyNames = [];
    let nonMeSelectAll = false;

    const isAllSelected = selectedVal.find((ele) => ele === "Select All");
    if (isAllSelected) {
      nonMeSelectAll = true;
      if (action === "selectOption") {
        surveys.forEach((val) => {
          selectedSurveyIds.push(val._id);
          selectedSurveyNames.push(val.name);

        })
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames(["Select All", ...selectedSurveyNames]);
        dispatch(adminsAction.updateSurveyAccess({ type: "NON-ME", data: { surveys: [...selectedSurveyIds], surveyNames: ["Select All", ...selectedSurveyNames], surveySelectAll: true } }))
      }
      else {
        dispatch(alertAction.error('You must unselect “All Surveys” to assign only specific surveys to this user.'))
      }
    }
    else {
      if (surveys.length === selectedVal.length && action === "removeOption") {
        setSelectedSurvey([]);
        setSelectedSurveyNames([]);
        nonMeSelectAll = false;
        dispatch(adminsAction.updateSurveyAccess({ type: "NON-ME", data: { surveys: [], surveyNames: [], surveySelectAll: false } }))
      }
      else if (surveys.length === selectedVal.length && action === "selectOption") {
        surveys.forEach((val) => {
          selectedSurveyIds.push(val._id);
          selectedSurveyNames.push(val.name);
        })
        nonMeSelectAll = true;
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames(["Select All", ...selectedSurveyNames]);
        dispatch(adminsAction.updateSurveyAccess({ type: "NON-ME", data: { surveys: [...selectedSurveyIds], surveyNames: ["Select All", ...selectedSurveyNames], surveySelectAll: true } }))
      }
      else {
        selectedVal.forEach((val) => {
          let surveyData = surveys.find((ele) => ele.name === val);
          selectedSurveyIds.push(surveyData._id);
          selectedSurveyNames.push(surveyData.name);
        })
        nonMeSelectAll = false;
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames([...selectedSurveyNames]);
        dispatch(adminsAction.updateSurveyAccess({ type: "NON-ME", data: { surveys: [...selectedSurveyIds], surveyNames: [...selectedSurveyNames], surveySelectAll: false } }))
      }
    }
    // handleChange({ name: "selectAll", value: nonMeSelectAll })
  }

  /**
     * Use Memo For Query Builder
     */
  const QB = useMemo(() => {
    return (
      <QueryBuilderComponent
        key={"DLA-Querybuilder"}
        classes={classes || {}}
        fieldOnChange={(fieldName) => {
          dispatch(queryBuilderAction.getFieldValues("audience", fieldName));
        }}
        handleQueryChange={(queryJSON, moongoQuery) => {
          const { hadEmptySet } = deFormatQuery(moongoQuery);
          dispatch(updateStateReducerAction.update(adminsConstant.UPDATE_DLA_QUERY, moongoQuery));
          if (hadEmptySet) {
            setReloadQB(reloadQB + 1);
          }
        }}
        collectionFields={queryBuilderData.fields.filter(t => t.type !== "date")}
        values={queryBuilderData.fieldValues}
        allowedOperators={["includes", "does not include"]}
        query={admin.queryDLA}
        isLoadedFromDB={true}
        defaultValidation={false}
        needInnerGroup={false}
        component = {true}
        restrictRepetitiveFields={true}
      />
    );
  }, [queryBuilderData, admin._id, reloadQB]);


  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid className={classes.backimg} onClick={(e) => handleBack(e)}>
                    {BackPage()}
                  </Grid>
                  <TypographyComponent
                    variant={"h3"}
                    className="pl-1"
                    title={admin.firstName ? admin.firstName + " " + (admin.lastName ? admin.lastName : "") : ""} />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ValidatorForm
              id="adminEditForm"
              name="adminEditForm"
              useref="adminEditForm"
              autoComplete="off"
              onSubmit={handleSubmit}
              onError={(error) => { console.log(error); }}>
              <div className={"container"}>

                <Accordion className="mb-2 pe-accordion"
                  expanded={expandedDefault === "default-panel"}
                  onChange={handlePanelChangeDefault("default-panel")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandicon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    {expandedDefault === "default-panel" ?
                      <Grid className={"pr5"}>
                        {SettingAccordWhite()}
                      </Grid>
                      :
                      <Grid className={"pr5"}>
                        {SettingAccordGrey()}
                      </Grid>}
                    <TypographyComponent
                      className={`${"txtBold"} ${expandedDefault === "default-panel" ? "panel-active" : ""} `}
                      variant={"h5"}>
                      {adminConstants.DEFAULT}</TypographyComponent>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid className="p-2">
                      <Grid container spacing={3} >
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.FIRST_NAME}
                            variant={"h6"}
                          >
                          </TypographyComponent>
                          <TextFieldComponent
                            id={"admin_firstName"}
                            name={"firstName"}
                            value={admin.firstName}
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.LAST_NAME}
                            variant={"h6"}
                          ></TypographyComponent>
                          <TextFieldComponent
                            id={"admin_lastName"}
                            name={"lastName"}
                            value={admin.lastName}
                            type='text'
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.EMAIL}
                            variant={"h6"}
                          ></TypographyComponent>
                          <TextFieldComponent
                            id={"admin_email"}
                            name={"email"}
                            value={admin.email}
                            type='text'
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                            validators={["required", "isEmail"]}
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.ROLES}
                            variant={"h6"}
                          ></TypographyComponent>
                          {/* <MultiSelectComponent
                            name={"access"}
                            suggestions={role}
                            handleAutoCompolete={(name, value) => {
                              handleRolesChange({ name, value });
                            }}
                            isArrayObjects={true}
                            value={admin.access}
                          /> */}
                          <MultiSelectComponentwithsuboption
                            name={"access"}
                            suggestions={rolesList}
                            handleAutoCompolete={(name, value) => {
                              handleRolesChange({ name, value });
                            }}
                            isArrayObjects={true}
                            value={admin.access}
                          />
                        </Grid>
                        {admin.samlAccessField && <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={admin.samlAccessField}
                            variant={"h6"}>
                            <InformationComponent>
                              {clientConstants.SSO_IDENTIFIER_HELP}
                            </InformationComponent>
                          </TypographyComponent>
                          <TextFieldComponent
                            id={"admin_samlsso"}
                            name="samlFieldValue"
                            value={admin.samlFieldValue ? admin.samlFieldValue : ""}
                            type='text'
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                          ></TextFieldComponent>
                        </Grid>}
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.ACTIVE_STATUS}
                            variant={"h6"}>
                            <InformationComponent>
                              {adminConstants.ACTIVE_STATUS_HELP}
                            </InformationComponent>
                          </TypographyComponent>
                          <AutoCompleteComponent
                            name={"status"}
                            suggestions={adminConstants.ACTIVE}
                            handleAutoCompolete={(event, name) => handleChange({ name, value: event.name === "Active" ? true : false })}
                            value={admin.status ? { name: "Active" } : { name: "Inactive" }}
                            validators={["required"]}
                            showErrorMessages={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>


                <Accordion className="mb-2 pe-accordion"
                  expanded={expandedSLA === "surveylevel-panel"}
                  onChange={handlePanelChangeSLA("surveylevel-panel")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandicon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <img src={settingsicon}
                      className={`${classes.settings_icons} ${expandedSLA === "surveylevel-panel" ? "panel-active" : ""}`}
                      alt={"settings"} />
                    <TypographyComponent
                      className={`${"txtBold flexBasis33"} ${expandedSLA === "surveylevel-panel" ? "panel-active" : ""} `}
                      variant={"h5"}>
                      {adminConstants.SURVEY_LEVEL_ACCESS}
                    </TypographyComponent>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className={classes.surveylevel}>
                      <Grid item md={4} xs={12}>
                        <MultiSelectComponent
                          name={"survey"}
                          suggestions={getSurveyNames()}
                          selectedOptions={surveyAccess.surveys}
                          placeholder={adminConstants.SELECT_EMPLOYEE_EXPERIENCE_SURVEY}
                          handleAutoCompolete={(name, value, event, action) => {
                            handleAutoCompleteChange({ name, value, event, action });
                          }}
                          value={surveyAccess.surveyNames}
                          isArrayObjects={true}
                          pulseSurveys={getPulseSurveys()}
                        />
                      </Grid>

                      {clientMeStatus ? (meSurveys && meSurveys.length > 0 ? <Grid item md={4} xs={12}>
                        <MultiSelectComponent
                          name={"meSurvey"}
                          suggestions={getMeSurveyNames()}
                          selectedOptions={surveyAccess.meSurveys}
                          placeholder={adminConstants.SELECT_MULTIRATER_FEEDBACK_SURVEY}
                          handleAutoCompolete={(name, value, event, action) => {
                            handleMeAutoCompleteChange({ name, value, event, action });
                          }}
                          value={surveyAccess.meSurveyNames}
                          isArrayObjects={true}
                        />
                      </Grid> :
                        <Grid item md={4} xs={12}>
                          <MultiSelectComponent
                            name={"meSurvey"}
                            suggestions={[]}
                            selectedOptions={[]}
                            placeholder={adminConstants.SELECT_MULTIRATER_FEEDBACK_SURVEY}
                            handleAutoCompolete={(name, value, event, action) => {
                              handleMeAutoCompleteChange({ name, value, event, action });
                            }}
                            value={selectedMeSurveyNames}
                            isArrayObjects={true}
                          />
                        </Grid>
                      ) : null}

                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="mb-6 pe-accordion"
                  expanded={expandedDLA === "datalevel-panel"}
                  onChange={handlePanelChangeDLA("datalevel-panel")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandicon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    {expandedDLA === "datalevel-panel" ?
                      <Grid className={"pr5"}>
                        {SettingAccordWhite()}
                      </Grid>
                      :
                      <Grid className={"pr5"}>
                        {SettingAccordGrey()}
                      </Grid>
                    }
                    <TypographyComponent
                      className={`${"txtBold flexBasis33"} ${expandedDLA === "datalevel-panel" ? "panel-active" : ""} `}
                      variant={"h5"}>
                      {adminConstants.DLA}</TypographyComponent>
                    <TypographyComponent
                      className={`${"oneline_ellipse mt2"} ${expandedDLA === "datalevel-panel" ? "panel-active" : ""
                        }`}
                      variant={"h6"}
                    >
                      {adminConstants.DLA_HELP}
                    </TypographyComponent>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid xs={12}>
                      {
                        isAllLoaded ? QB
                          : (
                            <div>{loading === false && queryBuilderData.fields.length === 0 ? "Data is empty" : ""}</div>
                          )
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>

              </div>
            </ValidatorForm>
          </Grid>
        </Grid>
      </Container>

      <Grid container justifyContent="flex-end" className={classes.footerBtns_container}>
        <Grid item>
          <ButtonComponent
            title={"Save"}
            color={"primary"}
            size={"small"}
            type="submit"
            form="adminEditForm"
            pageClassName="mr-1"
          ></ButtonComponent>
          <ButtonComponent
            title={"Delete"}
            color={"primary"}
            size={"small"}
            handleClick={(e) => handleDelete(e)}
            pageClassName="buttonOrange mr-1"
            to={"/manage_people/admins"}
          ></ButtonComponent>
          <ButtonComponent
            title={"Cancel"}
            color={"secondary"}
            size={"small"}
            type="submit"
            handleClick={handleCancel}
          ></ButtonComponent>
        </Grid>
      </Grid>
      <DeleteDialogComponent
        open={isDelete}
        content={adminConstants.DELETE_CONTENT}
        handleDeleteAction={handleAdminDelete}
        handleDeleteOnCloseAction={handleAdminCancelDelete}
      />
      {/* Dialog questions and settings Content */}
      <Dialog
        open={openCancelDialog.open}
        onClose={handleDialogCancelAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {adminConstants.CANCEL_CONTENT}
        </DialogContent>

        <DialogActions>
          <Button
            color={"primary"}
            size={"small"}
            type="submit"
            form="adminEditForm"
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={handleDialogNoAction}
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            autoFocus>
            No
          </Button>
          <Button
            onClick={handleDialogCancelAction}
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            autoFocus>
            Cancel
          </Button>
        </DialogActions >
      </Dialog >

    </React.Fragment >
  );
}
export default withStyles(AdminStyles)(EditAdmin);
