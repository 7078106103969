/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/jsx-key */
/* eslint-disable curly */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Import Icons
import { SettingAccordGrey, SettingAccordWhite } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Constants
import { participantConstants, translateLanguagesList } from "../../../../config/constants";
import stateCodes from "./state_codes";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";

// Import helpers
import { replaceString } from "../../../../helpers";

let abbrevatedState = null;
const extraOptions = {
  // eslint-disable-next-line quote-props
  "country": ({ rgi, value }) => {
    rgi = rgi || []
    return {
      options: rgi || [],
      filterOptions: (options, { inputValue }) => {
        const filteredOpt = options.filter(opt => {
          if (opt && opt.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
            return true;
          }
          return false;
        });
        if (filteredOpt.length === 0) {
          filteredOpt.push(`Create "${inputValue}"?`);
        }
        return filteredOpt;
      },
      renderOption: (option, { selected }) => {
        return option;
      }
    };
  },
  "languages": ({ rgi, value }) => {
    return {
      options: rgi || [],
      filterOptions: (options, { inputValue }) => {
        const filteredOpt = options.filter(opt => {
          if (opt && opt.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0) {
            return true;
          }
          return false;
        });
        if (filteredOpt.length === 0 && inputValue) {
          filteredOpt.push(`Create "${inputValue}"?`);
        }
        return filteredOpt;
      },
      renderOption: (option, { selected }) => {
        return option;
      }
    };
  },
};

function DeafaultPanel(props) {
  // Accordion Declaration and Action
  const [expanded, setExpanded] = React.useState("default-panel");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // ref
  const inputEl = useRef(null);

  // Popover
  const [anchorEl] = useState(null);

  /**
   * Generate Help Text
   * @param {*} type
   */
  const generateHelp = ({ key: type, value }) => {
    switch (type) {
      case "empId":
        return <InformationComponent> {replaceString(participantConstants.DEFAULT_EMPLOYEE_ID_HELP, "Employee ID", value)}</InformationComponent>;
      case "driver_id":
        return <InformationComponent> {replaceString(participantConstants.DEFAULT_DRIVER_ID_HELP, "Driver ID", value)}</InformationComponent>;
      case "email":
        return <InformationComponent> {participantConstants.DEFAULT_EMAIL_HELP}</InformationComponent>;
      case "altEmail":
        return <InformationComponent> {participantConstants.DEFAULT_ALTEMAIL_HELP}</InformationComponent>;
      case "status":
        return <InformationComponent> {participantConstants.DEFAULT_STATUS_HELP}</InformationComponent>;
      case "kiosk_username":
        return <InformationComponent> {participantConstants.DEFAULT_KIOSK_USERNAME_HELP}</InformationComponent>;
      case "kiosk_password":
        return <InformationComponent> {participantConstants.DEFAULT_KIOSK_PASSWORD_HELP}</InformationComponent>;
      case "phone":
        return <InformationComponent> {participantConstants.DEFAULT_PHONE_HELP}</InformationComponent>;
      case "altPhone":
        return <InformationComponent> {participantConstants.DEFAULT_ALTPHONE_HELP}</InformationComponent>;
      default:
        return null;
    }
  };

  // Popovers
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Assign Props
  const { classes, handleChange, participant, metadata, handleMetadataChange } = props;

  // Column Variables
  const allowedTypes = ["default", "system"];
  const hideColmns = ["survey", "access", "settings", "actions", "skip", "tenure_calculation", "age_calculation"];
  const [state, setState] = useState("");

  /**
   * Bind Default Fields
   */
  const bindDefaultFields = () => {
    let defaultFields = metadata.filter(a => allowedTypes.indexOf(a.type) > -1);
    defaultFields.push(...defaultFields.splice(defaultFields.findIndex(v => v.key === "status"), 1));

    return defaultFields.map(m => {
      if (hideColmns.indexOf(m.key) > -1)
        return;
      return (
        <Grid item md={4} xs={12}>
          <Grid container alignItems="center">
            <Grid item md={5} xs={12}>
              <TypographyComponent
                className={classes.wordContainer}
                title={m.key === "empId" ? m.value + "*" : m.value}
                variant={"h6"}
              >
                {generateHelp(m)}
              </TypographyComponent>
            </Grid>
            <Grid item md={7} xs={12}>
              {generateField(m.key, m.rgi, m.dataType)}
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  /**
     * Generate Textvalidations
     */
  const generateValidators = (key) => {
    switch (key) {
      case "empId":
        return ["requiredEmployee"];
      case "email":
      case "altEmail":
        return ["isEmail"];
      case "altPhone":
      case "phone":
        return ["isPhone"];
      default:
        return [];
    }
  };

  /**
   * Convert Zip Code
   * @param {*} code
   */
  const convertZipCodes = (code) => {
    if (!code) return null;
    let strippedCode = code.replace(/[^A-Za-z0-9]/g, "");
    return (stateCodes[strippedCode.toUpperCase()]);
  };

  const getAbbrevatedStateName = (name, value, dataType) => {
    if (name === "state" && value) {
      value = convertZipCodes(value) ? convertZipCodes(value) : value;
      setState(value);
      handleChange({ value: '', name: 'state' }, dataType);
      handleChange({ value, name: 'state' }, dataType);
    }
  }

  /**
   * Generate Default Fields
   * @param {*} key
   * @param {*} rgi
   * @param {*} dataType
   */
  const generateField = (key, rgi, dataType) => {
    // if filter out null, empty, duplicate
    rgi = rgi ? rgi.filter(r => r) : rgi;

    if( key === "languages" ) {
      rgi = translateLanguagesList.map((val) => {
        return val.name
      })
    }

    if ((rgi && rgi.length) || key === "languages") {
      return key === "languages" ? <AutoCompleteComponent
        suggestionType='array'
        name={key}
        // freeSolo={true}
        validators={generateValidators(key)}
        variant='outlined'
        value={participant[key]?.name || ""}
        fullWidth={true}
        suggestions={rgi ? rgi : [""]}
        className={"heightone"}
        handleAutoCompolete={(value, name) => {
          if (name === "languages" && value && value.indexOf("Create") >= 0) {
            // metada rgi country push
            value = value.replace(/Create/, "").replace(/["?]/g, "");
            value = value.trim();
            const updatedMetadata = metadata.map(m => {
              return { ...m, rgi: m.key === "languages" ? [...(rgi || []), value] : m.rgi };
            });
            handleChange({ name, value }, dataType);
            handleMetadataChange(updatedMetadata);
          } else {
            handleChange({ name, value }, dataType);
          }
        }}
        extraProps={
          extraOptions[key] ? { ...extraOptions[key]({ rgi, value: participant[key] }) } : {}
        }
      /> : <AutoCompleteComponent
          suggestionType='array'
          name={key}
          ref={inputEl}
          freeSolo={["state"].indexOf(key) >= 0}
          validators={generateValidators(key)}
          variant='outlined'
          className={"heightone"}
          autoHighlight={false}
          value={key === "country" ? participant[key] || "US" : participant[key]}
          fullWidth={true}
          onKeyDown={e => {
            if ((e.keyCode === 13 || e.keyCode === 9) && e.target.value) {
              getAbbrevatedStateName(e.target.name, e.target.value, dataType)
            }
          }}
          suggestions={rgi}
          handleAutoCompolete={(value, name, reason) => {
            console.log(value, name, reason)
            if (name === "country" && value && value.indexOf("Create") >= 0) {
              // metada rgi country push
              value = value.replace(/Create/, "").replace(/["?]/g, "");
              value = value.trim();
              const updatedMetadata = metadata.map(m => {
                return { ...m, rgi: m.key === "country" ? [...(rgi || null), value] : m.rgi };
              });
              handleChange({ name, value }, dataType);
              handleMetadataChange(updatedMetadata);
            } else {
              if (name === "state" && reason === "selectOption") {
                abbrevatedState = value;
              }
              handleChange({ name, value }, dataType);
            }
          }}
          onInputChange={(e, name, reason) => {
            // only for input
            if (e && reason === "input") {
              const value = e && e.target ? e.target.value : null;
              if (name === "state" && value !== 0) {
                setState(value);
                abbrevatedState = value;
              }
            }
          }}
          state={state}
          extraProps={
            extraOptions[key] ? { ...extraOptions[key]({ rgi, value: participant[key] }) } : {}
          }
        />;
    }

    if (key == "status") {
      return <AutoCompleteComponent
        suggestionType="array"
        name={key}
        className={"heightone"}
        freeSolo={false}
        validators={generateValidators(key)}
        variant="outlined"
        value={participant[key] ? "Active" : "Inactive"}
        fullWidth={true}
        suggestions={["Active", "Inactive"]}
        handleAutoCompolete={(value, name) => {
          value = value === "Active" ? true : false;
          handleChange({ name, value }, dataType);
        }}
        // onInputChange={(value, name) => {
        //   value = (value === "Active" || value === null) ? true : false;
        //   handleChange({ value, name }, dataType);
        // }}
        placeholder={"Search"}
      />
    } else {
      return <TextFieldComponent
        id={key}
        name={key}
        value={participant[key] || ""}
        size={"small"}
        margin={"none"}
        validators={generateValidators(key)}
        handleChange={(e) => {
          handleChange(e, dataType, key);
        }}
      />
    }

    // if (key == "status"){
    //     return <AutoCompleteComponent
    //       suggestionType="array"
    //       name={key}
    //       freeSolo={false}
    //       validators={generateValidators(key)}
    //       variant="outlined"
    //       value={participant[key] ? "Active" : "Inactive"}
    //       fullWidth={true}
    //       suggestions={["Active", "Inactive"]}
    //       handleAutoCompolete={(value, name) => {
    //         value = value === "Active" ? true : false;
    //         handleChange({ name, value }, dataType);
    //       }}
    //       onInputChange={(value, name) => {
    //         value = (value === "Active" || value === null) ? true : false;
    //         handleChange({ value, name }, dataType);
    //       }}
    //       placeholder={"Search"}
    //     />
    // }else{
    //   return <TextFieldComponent
    //       id={key}
    //       name={key}
    //       value={participant[key] || ""}
    //       size={"small"}
    //       margin={"none"}
    //       validators={generateValidators(key)}
    //       handleChange={(e) => {
    //         handleChange(e, dataType, key);
    //       }}
    //     />
    // }
  };

  return (
    <Accordion
      className="mb-2 pe-accordion"
      expanded={expanded === "default-panel"}
      onChange={handlePanelChange("default-panel")}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {expanded === "default-panel" ?
          <Grid className={"pr5"}>
            {SettingAccordWhite()}
          </Grid>
          :
          <Grid className={"pr5"}>
            {SettingAccordGrey()}
          </Grid>}
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${expanded === "default-panel" ? "panel-active" : ""} `}
          variant={"h5"}>
          {participantConstants.DEFAULT}</TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "default-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{participantConstants.DEFAULT_HELPTEXT}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className="p-2">
          <Grid container spacing={3} className={`${classes.setting_expandcontainer}`}>
            {
              bindDefaultFields()
            }
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

DeafaultPanel.defaultProps = {
  metadata: [],
  handleMetadataChange: () => { }
};
export default (DeafaultPanel);
