export const fileManagerConstants = {
    // get current logged in user's export and import files list
    GET_FILES_LIST_REQUEST: 'GET_FILES_LIST_REQUEST',
    GET_FILES_LIST_SUCCESS: 'GET_FILES_LIST_SUCCESS',
    GET_FILES_LIST_FAILURE: 'GET_FILES_LIST_FAILURE',

    // update seen status
    UPDATE_FILE_SEEN_STATUS_REQUEST: 'UPDATE_FILE_SEEN_STATUS_REQUEST',
    UPDATE_FILE_SEEN_STATUS_SUCCESS: 'UPDATE_FILE_SEEN_STATUS_SUCCESS',
    UPDATE_FILE_SEEN_STATUS_FAILURE: 'UPDATE_FILE_SEEN_STATUS_FAILURE',

    // download file
    DOWNLOAD_FILE_REQUEST: 'DOWNLOAD_FILE_REQUEST',
    DOWNLOAD_FILE_SUCCESS: 'DOWNLOAD_FILE_SUCCESS',
    DOWNLOAD_FILE_FAILURE: 'DOWNLOAD_FILE_FAILURE',

    // delete file
    DELETE_FILE_REQUEST: 'DELETE_FILE_REQUEST',
    DELETE_FILE_SUCCESS: 'DELETE_FILE_SUCCESS',
    DELETE_FILE_FAILURE: 'DELETE_FILE_FAILURE',

    // update notified status
    UPDATE_FILE_NOTIFIED_STATUS_REQUEST: 'UPDATE_FILE_NOTIFIED_STATUS_REQUEST',
    UPDATE_FILE_NOTIFIED_STATUS_SUCCESS: 'UPDATE_FILE_NOTIFIED_STATUS_SUCCESS',
    UPDATE_FILE_NOTIFIED_STATUS_FAILURE: 'UPDATE_FILE_NOTIFIED_STATUS_FAILURE',
}