import React, { useEffect, useRef } from "react";
import {
  select,
  scaleBand,
  axisBottom,
  stack,
  max,
  scaleLinear,
  axisLeft,
  stackOrderAscending,
} from "d3";
import * as d3 from "d3";
import useResizeObserver from "./useResizeObserver";

function StackedBarChart(props) {
  const { data, keys, colors, zoomMode, datLength, padding, chartId, className } = props;
  //const svgRef = useRef();
  const svgRef = chartId;
  const wrapperRef = useRef();
  //use this if want to pass dimesions automatically
  const dimensions = useResizeObserver(wrapperRef);
// Define tooltipId outside of useEffect
  const tooltipId = `${chartId}-tooltip`;

  //called initially and on every data change
  useEffect(() => {

    const svg = select(`#${chartId}`);
    let width = 0
    let height = 0

    const dimensionData = dimensions || wrapperRef.current.getBoundingClientRect();
    width = dimensionData.width;
    height = dimensionData.height;

    // stacks / layers
    const stackGenerator = stack().keys(keys);
    const layers = stackGenerator(data);
    const extent = [0, max(layers, layer => max(layer, sequence => sequence[1]))];

    // scales
    const xScale = scaleLinear()
      .domain(extent)
      .range([0, width])

    const yScale = scaleBand()
      .domain(data.map(d => d.yAxis))
      .range([height, 0])
      .padding(padding);

    // rendering rectangel
    svg.selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .attr("fill", layer => colors[layer.key])
      .selectAll("rect")
      .data(layer => layer)
      .join("rect")
      .attr("y", sequence => yScale(sequence.data.yAxis) + 8)
      .attr("x", sequence => xScale(sequence[0]))
      .attr("width", sequence => xScale(sequence[1]) - xScale(sequence[0]))
      .attr("height", 25)
      .on("mouseover", function (d) {
        d3.select(this)
          .style("cursor", "pointer")
          .attr("fill", "#B8B8B8")
      })
      .on("mouseout", function (d) {
        d3.select(this)
          .style("cursor", "none")
          .attr("fill", d => { return d.color })
      })

    //rendering text
    svg.selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .selectAll("text")
      .data(layer => layer)
      .join("text")
      .attr("x", sequence => xScale(sequence[1] - 4))
      .attr("y", sequence => yScale(sequence.data.yAxis) + 26)
      .attr("width", sequence => xScale(sequence[1]) - xScale(sequence[0]))
      .attr("height", 25)
      .text(function (d) { let dta = Math.round(d[1] - d[0]); if (dta > 4) { return dta + "%"; } else { return " "; } })
      .style("fill", '#000');

    let tooltip = d3.select(`#${tooltipId}`);

    svg.selectAll(".layer")
      .on("mouseover", function (ds, d) {
        tooltip.append('div').attr('class', 'nameTool');
        let currentData = ds.target.__data__.data;
        let htmlData = `<div style="min-width:210px;font-weight:600;font-size:14px;margin-bottom:10px;font-size:14px;padding:2px;border-bottom:1px solid #b5b5b5">${currentData.yAxis}</div>`
        for (let key in currentData) {
          if (key !== "yAxis") {
            htmlData += `<p style="background-color:#ffff;padding:0px;margin:0px;display:flex;align-items:baseline;border-bottom:1px solid #EAEBED;font-size:13px;padding">
                        <span style="background:${colors[key]};width:19px;height:11px"></span>
                        <span style="padding-left:10px;width:64%;text-align:left;"> ${key} </span>
                        <span style="width:20%"> ${Math.round(currentData[key])}%</span>
                        </p>
                        `
          }
        }

        tooltip.select('.nameTool').html(htmlData);
        tooltip.style('display', 'block');
        tooltip.style('top', (window.event.layerY - 200) + 'px')
        tooltip.style('left', (window.event.layerX - 220) + 'px');
      })
      .on('mousemove', function (d) {
        tooltip.style('width', '300px')
          .style('top', (window.event.layerY - 200) + 'px')
          .style('left', (window.event.layerX - 220) + 'px');
      })
      .on("mouseout", function (d) {
        tooltip.style('display', 'none');
      })

    // axes
    const xAxis = axisBottom(xScale).tickFormat(d => {
      return `${d}%`
    });
    svg.select(".x-axis")
      .attr("transform", `translate(0, ${height})`)
      .call(xAxis);

    const yAxis = axisLeft(yScale)
    //.tickSize(0)
    svg.select(".y-axis")
      .call(yAxis)
      .selectAll("text")
      .call(wrapText, (width / 3));

    // Wrap Text
    function wrapText(text, width) {
      text.each(function () {
        var text = d3.select(this),
          textContent = text.text(),
          tempWord = addBreakSpace(textContent).split(/\s+/),
          x = text.attr('x'),
          y = text.attr('y'),
          dy = parseFloat(text.attr('dy') || 0),
          tspan = text.text(null).append('tspan').attr('x', x).attr('y', y).attr('dy', dy + 'em');
        tempWord = tempWord.filter(f => (f))
        textContent = tempWord.join(" ");
        var words = textContent.split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          spanContent,
          breakChars = ['/', '&', '-'];
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(' '));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            spanContent = line.join(' ');
            breakChars.forEach(char => {
              // Remove spaces trailing breakChars that were added above
              spanContent = spanContent.replace(char + ' ', char);
            });
            tspan.text(spanContent);
            line = [word];
            tspan = text.append('tspan').attr('x', x).attr('y', y).attr('dy', lineHeight + 'em').text(word);
          }
        }
        var emToPxRatio = parseInt(window.getComputedStyle(text._groups[0][0]).fontSize.slice(0, -2));
        text.attr("transform", "translate(-" + (0 - 13) + ", -" + lineHeight + ")");

      });

      function addBreakSpace(inputString) {
        var breakChars = ['/', '&', '-']
        breakChars.forEach(char => {
          // Add a space after each break char for the function to use to determine line breaks
          inputString = inputString.replace(char, char + ' ');
        });
        return inputString;
      }
    }
  }, [colors, dimensions, data, keys, tooltipId]);

  return (
    <React.Fragment>
        <div className={`stackChart`} ref={wrapperRef} style={{ marginBottom: "35px", height: zoomMode && datLength > 5 ? `${35 * (datLength)}px` : zoomMode && datLength < 7 ? `${35 * (datLength)}px` : ``}}>
        <svg className={zoomMode ? "heightZoom" : "heightNormal"} id={`${chartId}`}>
          <g className="x-axis axisgrey" />
          <g className="y-axis axisgrey" />
        </svg>
        <div id={tooltipId} className={'tooltipstackbar'}></div>
      </div>
    </React.Fragment>
  );
}

export default StackedBarChart;