/* eslint-disable react/prop-types */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable dot-notation */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

// import constants
import { surveyQuestionsConstants, surveyConstants } from "../../../../../config/constants";
import { surveyTypes } from "../../../../../config/constants";

// Import Helpers
import { getReducerUpdatedData, addLanguageInNewQuestion, deepClone } from "../../../../../helpers";

// import components
import SelectFieldComponent from "../../../../../components/SelectFieldComponent/SelectFieldComponent";
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";

// Import Actions
import { categoryAction } from "../../../../../redux/actions";

function QuestionType(props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, questionData, category, questionDataSet, item } = props;
  const { languages } = questionData;
  const [openDropDownDialogue, setOpenDropDownDialogue] = useState(false)

  //Update answerset for Old survey
  let defaultAnswerSet = surveyQuestionsConstants.DEFAULT_SINGLE_SEL_ANS_SET
  if(questionData.isOldSurvey) {
    defaultAnswerSet  = surveyQuestionsConstants.OLD_DEFAULT_SINGLE_SEL_ANS_SET
  }


  // Change Question type value
  const handleQuesTypeChange = (event) => {
    if (event.value && event.value === "Dropdown") {
      setOpenDropDownDialogue(true);
    }
    let reducerData = getReducerUpdatedData(event, questionDataSet);

    if (!reducerData["ansSet"]) {
      reducerData = addLanguageInNewQuestion(reducerData, languages);
    }
    if (reducerData.qusType !== surveyQuestionsConstants.MATRIX && reducerData.subQues) {
      delete reducerData.subQues;
    }
    if (reducerData.qusType === surveyQuestionsConstants.YES_NO) {
      reducerData["ansSet"]["en"] = deepClone(surveyQuestionsConstants.DEFAULT_YES_NO_ANS_SET);
    } else if (reducerData.qusType === surveyQuestionsConstants.SINGLE_SELECT) {
      reducerData["ansSet"]["en"] = deepClone(defaultAnswerSet);
    } else if (reducerData.qusType === surveyQuestionsConstants.MATRIX) {
      reducerData = addLanguageInNewQuestion(reducerData, languages);
      reducerData["ansSet"]["en"] = deepClone(surveyQuestionsConstants.DEFALULT_MATRIX_ANS_SET);
    } else if (reducerData.qusType === surveyQuestionsConstants.DROPDOWN) {
      reducerData["ansSet"]["en"] = deepClone({ 0: "" });
    } else if (reducerData.qusType === surveyQuestionsConstants.OPEN_ENDED) {
      delete reducerData["ansSet"];
    }
    // To update answerchoice for ME type survey
    if (questionData.type === surveyTypes.MULTI_RATER_FEEDBACK) {
      reducerData["ansSet"] = JSON.parse(JSON.stringify(surveyQuestionsConstants.ME_CUSTOM_ANSWERSET_DATA));
    }
    console.log("🚀 ~ file: QuestionType.jsx ~ line 70 ~ handleQuesTypeChange ~ reducerData", reducerData)

    dispatch(categoryAction.addOrChangeQuestion(category, reducerData));
  };

  const handleDialogClose = () => {
    setOpenDropDownDialogue(false)
  }

  return (
    <React.Fragment>
      <SelectFieldComponent
        className={`${"boderRadius2 height40 "} ${classes.qustType}`}
        name={"qusType"}
        id="questionType"
        variant="outlined"
        value={questionDataSet.qusType}
        options={questionData.type === surveyTypes.MULTI_RATER_FEEDBACK ? surveyQuestionsConstants.ME_QUESTIONS_TYPE : surveyQuestionsConstants.QUESTIONS_TYPE}
        fullWidth={true}
        disabled={item ? true : false}
        handleChange={(e) => handleQuesTypeChange(e)}
      >
      </SelectFieldComponent>
      <Dialog
        className={"dialogtype"}
        open={openDropDownDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {surveyConstants.DROPDOWN_QUESTION_MESSAGE}
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            title={"OK"}
            color={"primary"}
            size={"small"}
            handleClick={handleDialogClose}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

// default props
QuestionType.defaultProps = {
  questionDataSet: {},
  questionData: {},
  item: null,
  editQuestionsData: () => { }
};

// prop types
QuestionType.propTypes = {
  questionDataSet: PropTypes.object,
  questionData: PropTypes.object,
  item: PropTypes.object,
  editQuestionsData: PropTypes.func
};

export default QuestionType;
