/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable dot-notation */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// import components
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import DatePickerComponent from "../../../../../components/DatePickerComponent/DatePicker";

// Import helper
import { dateFormatter } from "../../../../../helpers";

// Import Actions
import { metadataAction } from "../../../../../redux/actions";
import MatchDropDown from "./MatchDropDown";

const ConversionMatchField = (props) => {
  const dispatch = useDispatch();

  // get Props
  const {
    classes,
    metadataSelected,
    metadataWithRGI,
    currentItem,
    currentIndex,
    currentName
  } = props;

  let RGIArray = [];
  let fromDataType = "string";
  let fromDataKey = null;
  for (let i = 0; i < metadataWithRGI.length; i++) {
    if (currentItem["from"] && currentItem["from"] === metadataWithRGI[i]["key"]) {
      RGIArray = metadataWithRGI[i]["rgi"]?metadataWithRGI[i]["rgi"]:[];
      fromDataType = metadataWithRGI[i]["dataType"] ? metadataWithRGI[i]["dataType"] : "string";
      fromDataKey = metadataWithRGI[i]["key"] ? metadataWithRGI[i]["key"] : null;
    }
  }

  // change between field values
  const handleBetweenChange = (e, ind) => {
    const { name, value } = e;
    if (metadataSelected && metadataSelected["conversion"]) {
      for (let i = 0; i < metadataSelected["conversion"].length; i++) {
        if (i === currentIndex) {
          metadataSelected["conversion"][i][name][ind] = value;
        }
      }
      dispatch(metadataAction.selectedMetadata(metadataSelected));
    }
  };

  // change Date Format to mm/dd/yyyy
  const handleBetweenChangeDate = (e, ind) => {
    e["value"] = dateFormatter(e["value"]);
    handleBetweenChange(e, ind);
  };

  return (
    <React.Fragment>
      {currentItem && (
        <React.Fragment>
          {currentItem.operator === "in" || currentItem.operator === "nin" ? (
            <MatchDropDown
              RGIArray={RGIArray || []}
              matchFieldValue={currentItem.match}
              metadataSelected={metadataSelected}
              currentIndex={currentIndex}
              fromDataType={fromDataType}
              fromDataKey={fromDataKey}
            />
          ) : (
            <React.Fragment>
              {fromDataType !== "date" ? (
                <React.Fragment>
                  {currentItem.operator === "between" ? (
                    <React.Fragment>
                      <TextFieldComponent
                        id={currentName}
                        name={currentName}
                        size={"small"}
                        value={currentItem.match[0]}
                        margin={"dense"}
                        fullWidth={false}
                        handleChange={(e) => handleBetweenChange(e, 0)}
                      ></TextFieldComponent>
                      <TypographyComponent variant="h6" className="pr5 pl5">
                        {" - "}
                      </TypographyComponent>
                      <TextFieldComponent
                        id={`${currentName}1`}
                        name={currentName}
                        size={"small"}
                        value={currentItem.match[1]}
                        margin={"dense"}
                        fullWidth={false}
                        handleChange={(e) => handleBetweenChange(e, 1)}
                      ></TextFieldComponent>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <TextFieldComponent
                        id={`${currentName}1`}
                        name={currentName}
                        size={"small"}
                        value={currentItem.match[0]}
                        margin={"dense"}
                        fullWidth={false}
                        handleChange={(e) => handleBetweenChange(e, 0)}
                      ></TextFieldComponent>
                    </React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {currentItem.operator === "between" ? (
                    <React.Fragment>
                      <DatePickerComponent
                        id={currentName}
                        name={currentName}
                        value={currentItem.match[0]}
                        className={classes.textField}
                        handleChange={(e) => { handleBetweenChangeDate(e, 0); }}
                        inputVariant="outlined"
                        margin="dense"
                        size="small"
                        fullWidth={true}
                        disabled={false}
                      />
                      <TypographyComponent variant="h6" className="pr5 pl5">
                        {" - "}
                      </TypographyComponent>
                      <DatePickerComponent
                        id={`${currentName}1`}
                        name={currentName}
                        className={classes.textField}
                        value={currentItem.match[1]}
                        margin={"dense"}
                        handleChange={(e) => { handleBetweenChangeDate(e, 1); }}
                        inputVariant="outlined"
                        size="small"
                        fullWidth={true}
                        disabled={false}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <DatePickerComponent
                        id={currentName}
                        name={currentName}
                        className={classes.textField}
                        value={currentItem.match[0]}
                        margin={"dense"}
                        handleChange={(e) => { handleBetweenChangeDate(e, 0); }}
                        inputVariant="outlined"
                        size="small"
                        fullWidth={true}
                        disabled={false}
                      />
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

// default props
ConversionMatchField.defaultProps = {
  classes: {},
  metadataSelected: {},
  metadataWithRGI: [],
  metadata: [],
  currentItem: {},
  currentIndex: 0,
  currentName: ""
};

// prop types
ConversionMatchField.propTypes = {
  classes: PropTypes.object,
  metadataSelected: PropTypes.object,
  metadataWithRGI: PropTypes.array,
  metadata: PropTypes.array,
  currentItem: PropTypes.object,
  currentIndex: PropTypes.number,
  currentName: PropTypes.string
};

export default ConversionMatchField;
