// import constants
import { alertConstant, uiConstant, opportunityAreas } from "../constants";
import { baseAction } from "./baseAction";

// import { ActionConstants } from "../../config/constants";
import { opportunityAreasService } from "../services";

const { request, success, failure } = baseAction;

export const opportunityAreasAction = {
  getOne
};

/**
 * Get One Add Plan Action
 *
 * @param {*} id  Action plan id to fetch
 * @returns
 */
function getOne(preference) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(opportunityAreas.OPPORTUNITY_GET_ONE_REQUEST));

    opportunityAreasService.getOne(preference).then(
      (data) => {
        dispatch(success(opportunityAreas.OPPORTUNITY_GET_ONE_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(opportunityAreas.OPPORTUNITY_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}
