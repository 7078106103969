/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable dot-notation */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import { Grid } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// import constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

// import components
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";

// Import Actions
import { categoryAction, alertAction } from "../../../../../redux/actions";

function BulkAnswerSet (props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, category, anchorEl, answerSet, questionDataSet, item } = props;

  // set state value
  const [bulkAnsSet, setBulkAnsSet] = useState(Object.values(answerSet).join("\n"));

  // update bulkAnsSet on answer set change
  useEffect(() => {
    setBulkAnsSet(Object.values(answerSet).join("\n"));
  }, [dispatch, questionDataSet, answerSet]);

  // check for empty or duplicate Category
  const duplicateAnsSetCheck = () => {
    let emptyFound = false;
    let duplicateFound = false;
    let answerChoices = bulkAnsSet.split("\n");
    // check for empty
    emptyFound = answerChoices.indexOf("") > -1 || answerChoices.indexOf(" ") > -1 ? true : false;
    // check for duplicate ES6
    answerChoices = answerChoices.map(v => v.toLowerCase());
    duplicateFound = new Set(answerChoices).size !== answerChoices.length;

    if (questionDataSet["qusType"] === surveyQuestionsConstants.SINGLE_SELECT && (questionDataSet["ansSet"]["en"].hasOwnProperty("0") && answerChoices.length > 11 || (!questionDataSet["ansSet"]["en"].hasOwnProperty("0") && answerChoices.length > 10))) {
      return 3;
    } else if (emptyFound) {
      return 1;
    } else if (duplicateFound) {
      return 2;
    } else {
      return 0;
    }
  };

  // update bulk answer data state
  const updateAnserSetState = (e) => {
    setBulkAnsSet(e.target.value);
  };

  // update answer choices
  const addBulkDataToAnsSet = () => {
    const validation = duplicateAnsSetCheck();
    if (!validation) {
      const startIndex = (Object.keys(questionDataSet["ansSet"]["en"]).length <= 0 || questionDataSet["ansSet"]["en"].hasOwnProperty("0")) ? 0 : 1;
      let ansArrToObject = Object.fromEntries((bulkAnsSet.split("\n")).map((value, index) => [(index + startIndex).toString(), value]));
      const resultData = { ...questionDataSet, ansSet: { ...questionDataSet.ansSet, en: ansArrToObject } };
      if (!item) {
        dispatch(categoryAction.addOrChangeQuestion(category, resultData));
      } else {
        props.editQuestionsData(resultData);
      }
      props.showBulkAnsChoices(null);
    } else if (validation === 1) {
      dispatch(alertAction.error(surveyQuestionsConstants.EMPTY_ANS_CHOICE));
    } else if (validation === 2) {
      dispatch(alertAction.error(surveyQuestionsConstants.DUPLICATE_ANS_CHOICE));
    } else if (validation === 3) {
      dispatch(alertAction.error(surveyQuestionsConstants.ANS_SET_LENGTH_EXCEEDS));
    }
  };

  // Popover Open Control
  const open = Boolean(anchorEl);
  const id = open ? "ans_set_popup" : undefined;

  return (
    <Popover
      className={`${classes.bulk_Answer_Set_popover} ${"bulk_Answer_Set_popover"}`}
      open={open}
      anchorEl={anchorEl}
      onClose={() => { props.showBulkAnsChoices(null); }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
    >
      <Grid container className={classes.descriptionText}>
      {surveyQuestionsConstants.DESCRIPTION_TEXT}
      </Grid>
      <Grid className={"p15"}>
        <Grid className={`${"scrolly"} ${classes.bulk_Answer_Set}`}>
          <TextareaAutosize
            className={`${"width100 pe_textarea bgGrey lineHeight20 scrolly"} ${classes.bulk_ans_textarea}`}
            name={"BulkansSet"}
            placeholder={"Add answer choices here"}
            value={bulkAnsSet}
            onChange={updateAnserSetState}
            minRows={3}
          />
        </Grid>
        <Grid className={"pt-1"}>
          <ButtonComponent
            title={"Save"}
            color={"primary"}
            size={"small"}
            handleClick={() => { addBulkDataToAnsSet(); } }
            componentClassName={"marginLR5"}
            type="submit"
          ></ButtonComponent>
          <ButtonComponent
            title={"Cancel"}
            color={"default"}
            size={"small"}
            handleClick={() => { props.showBulkAnsChoices(null); } }
            componentClassName={"marginLR5"}
          ></ButtonComponent>
        </Grid>
      </Grid>
    </Popover>
  );
}

// default props
BulkAnswerSet.defaultProps = {
  classes: {},
  questionDataSet: {},
  item: null,
  category: null,
  answerSet: {},
  editQuestionsData: () => { }
};

// prop types
BulkAnswerSet.propTypes = {
  classes: PropTypes.object,
  questionDataSet: PropTypes.object,
  item: PropTypes.object,
  category: PropTypes.string,
  answerSet: PropTypes.object,
  editQuestionsData: PropTypes.func
};

export default BulkAnswerSet;
