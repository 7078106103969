/* eslint-disable no-case-declarations */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Pagination from '@mui/material/Pagination';

// Import Styles and Image
import { Plus, BackPage, TableLoader } from "../../../assets/images/svgComponents/ImageComponent";

import PariticipantStyles from "./PariticipantStyles";

// Import Table Size
import { getTableHeight, getTableRowSize } from "../../../config/layout";

// Import History
import history from "../../../config/history";

// Import Component
import TableComponent from "../../../components/TableComponent/TableComponent";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import SwitchComponent from "../../../components/SwitchComponent/SwitchComponent";
import Container from "../../Containers/Container";
import OptionsMenu from "../Pariticipant/OptionsMenu";
import SendIndividualEmail from "./SendIndividualEmail"

// Import helper
import { getQueryVariable, replaceString } from "../../../helpers";

// Import Actions
import { participantAction, excelAction, metadataAction, profileAction } from "../../../redux/actions";

// import constants
import { participantConstants, excelConstants } from "../../../config/constants";
import { Tooltip } from "@mui/material";

const ParticipantList = (props) => {
  // Define Dispatch
  const dispatch = useDispatch();

  // Assign Props
  const { classes, match, location } = props;

  // Table size
  const tableHeight = getTableHeight();
  const tableRowSize = getTableRowSize();

  // Get Participant from store
  const participantList = useSelector(state => state.participant.search);
  const { metadata } = useSelector((state) => state.metadata);
  const { surveyNotificationData, isOpenSurveyAvail } = useSelector((state) => state.participant);

  const { managerEffectiveness } = useSelector((state) => state.client.surveyPermission);

  const [openMailDrawer, setOpenMailDrawer] = useState(false);
  const [selectedParticipantIndec, setSelectedParticipantIndex] = useState(null);

  // const loader = useSelector(state => state.participant.peopleloader);
  const { page, data } = participantList;
  const defaultSelctedColumns = [];

  /**
  * Reset all search text box to empty
  */
  let isPageReset = getQueryVariable(location.search, "reset") === "true";
  let isPageRefresh = getQueryVariable(location.search, "refresh") === "true";
  let actionFields = []
  if (isOpenSurveyAvail) {
    actionFields = ["SEND_SURVEY"]
  }
  /**
   * Get Participants
   */
  useEffect(() => {
    if (!isPageRefresh) {
      const pageSkip = location.state && location.state.skip ? location.state.skip : 1
      let { sFields, sValues } = page;
      if (typeof sFields === 'undefined')
        sFields = []
      if (typeof sValues === 'undefined')
        sValues = []
      dispatch(participantAction.search(pageSkip, tableRowSize, "active", "desc", sFields, sValues, defaultSelctedColumns));
    }
    // dispatch(metadataAction.getMetadataWithRGI())
    dispatch(profileAction.user());
  }, [dispatch]);

  //   // if clear search and call api
  // useEffect(() => {
  //   if (isPageReset) {
  //     dispatch(participantAction.search(1, tableRowSize, "active", "desc", [], [], defaultSelctedColumns));
  //     history.replace(props.location.pathname);
  //   }
  // }, [isPageReset]);
  // clear query url
  useEffect(() => {
    if (isPageReset) {
      history.replace(props.location.pathname)
      dispatch(participantAction.search(1, tableRowSize, "active", "desc", [], []));
    }
    if (isPageRefresh) {
      history.replace(props.location.pathname)
    }
  }, [isPageRefresh, isPageReset])

  /**
  * Handle Table pagination Event
  */
  const onChangePagination = (e, skip) => {
    dispatch(participantAction.search(skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  /**
  * Handle Table Sort Request Event
  */
  const onSortRequest = (sortBy, sort) => {
    dispatch(participantAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if (isPageRefresh) {
      dispatch(participantAction.search(1, tableRowSize, "active", "desc", [], []));
    } else {
      dispatch(participantAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
    }
  };

  /**
  * Handle Column settings demographic change
  */
  const onDemographicChange = (updatedVisibleColumns) => {
    dispatch(participantAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues, updatedVisibleColumns));
  };

  /**
    * Handle Table Navigation Event
    */
  const handleNavigateAction = (id) => {
    history.push({
      pathname: `${match.url}/${id}/edit`,
      state: { skip: page.skip }
    });
  };

  /**
   * Handle Action Button Event
   */
  const handelActionButtonClick = (field, id, name, index) => {
    /**
     *
     * handle action switch
     */
    switch (field) {
      case "SEND_SURVEY":
        setSelectedParticipantIndex(index)
        dispatch(participantAction.getSurveydataForNotification({ participant_id: id }, () => {
          setOpenMailDrawer(true)
        })
        );
        break;
      default:
        break;
    }
  };

  /**
 * toggle drawer
 */
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOpenMailDrawer(open);
  };

  /**
  * Handle Field Component On Change Event
  */
  const fieldComponentOnChange = (field, data, value) => {
    switch (field) {
      case "status":
        const updatedData = { _id: data._id, empId: data.empId, [field]: value };
        dispatch(participantAction.update(updatedData, () => {
          dispatch(participantAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
        })
        );
        break;
      default:
    }
  };

  /**
   * Switch Toggles
   */
  const StatusSwitch = ({ data, handleChange }) => {
    return (
      <Grid item xs={12} title={data.status ? "Active" : "Inactive"}>
        <SwitchComponent item xs={12}
          name={"status"}
          color="primary"
          checked={data.status}
          handleChange={({ value }) => {
            handleChange("status", data, value);
          }}
          label={
            <span>
              <TypographyComponent
                variant="h6"
              >{data.label}</TypographyComponent>

            </span>
          }
        />
      </Grid>
    );
  };
  const componentMap = {
    status: StatusSwitch
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  /**
   * Select excel popup
   */
  const excelImport = () => {
    hiddenFileInput.current.click();
  };

  /**
   * upload excel
   */
  const excelUpload = (event) => {
    var formData = new FormData();
    const fileUploaded = event.target.files[0];
    formData.append("file", fileUploaded);
    formData.append("excelType", excelConstants.PARTICIPANT_UPLOAD);
    dispatch(excelAction.upload(formData, excelConstants.PARTICIPANT_UPLOAD, null, true));
  };

  /**
   * download excel "status", "desc", [], []
   */
  const excelDownload = () => {
    dispatch(excelAction.download({
      sortBy: page.sortBy ? page.sortBy : "status",
      sort: page.sort ? page.sort : "desc",
      sFields: page.sFields ? page.sFields : [],
      sValues: page.sValues ? page.sValues : [],
      fieldFilters: page.fieldFilters ? page.fieldFilters : [],
      excelType: excelConstants.PARTICIPANT_DOWNLOAD,
      excelName: "People.xlsx"
    }, true));
  };

  const getTableSize = (leftDefaultCols, visibleColumns, demometadataCount, totalCols) => {
    const nonDefaultColumns = visibleColumns.filter(s => leftDefaultCols.indexOf(s) === -1 && totalCols.indexOf(s) >= 0);
    const colSize = visibleColumns.length === 0 ? 12 : (nonDefaultColumns.length === 0 ? 12 : 8);
    return { left: colSize, right: 12 - colSize };
  };

  const getInfoHeaders = () => {
    const { headerCols, fieldCols } = page.fieldFilters;
    const infoMap = {
      empId: (replaceword = "") => replaceString(participantConstants.DEFAULT_EMPLOYEE_ID_HELP, "Employee ID", replaceword),
      driver_id: (replaceword = "") => replaceString(participantConstants.DEFAULT_DRIVER_ID_HELP, "Driver ID", replaceword),
      kiosk_username: () => participantConstants.DEFAULT_KIOSK_USERNAME_HELP,
      kiosk_password: () => participantConstants.DEFAULT_KIOSK_PASSWORD_HELP,
      altEmail: () => participantConstants.DEFAULT_ALTEMAIL_HELP,
      email: () => participantConstants.DEFAULT_EMAIL_HELP,
      phone: () => participantConstants.DEFAULT_PHONE_HELP,
      altPhone: () => participantConstants.DEFAULT_ALTPHONE_HELP,
      status: () => participantConstants.DEFAULT_STATUS_HELP,
      manager_id: () => managerEffectiveness ? participantConstants.AUTOMATED_MANAGERID_HELP : participantConstants.AUTOMATED_MANAGERID_DISABLE_HELP,
      manager_role: () => managerEffectiveness ? participantConstants.AUTOMATED_MANAGERROLE_HELP : participantConstants.AUTOMATED_MANAGERROLE_DISABLE_HELP

    };
    const infoHeaders = {};
    Object.keys(infoMap).map(m => {
      if (fieldCols.indexOf(m) >= 0) {
        const headerName = headerCols[fieldCols.indexOf(m)];
        infoHeaders[headerName] = infoMap[m](headerName)
      }
    })


    // infoHeaders['Active Status'] =  participantConstants.DEFAULT_STATUS_HELP
    return infoHeaders;
  }

  /**
   * Get Detault Metadata Fields
   */
  const getDefaultMetaData = () => {
    const { columnSettings, leftDefaultCols, visibleColumns, searchCols } = page.fieldFilters;
    const defaultHeader = [];
    const defaultFields = [];
    const defaultSearch = [];
    let demometadataCount = 0;
    columnSettings.map(column => {
      if (leftDefaultCols.indexOf(column.key) >= 0 && visibleColumns.indexOf(column.key) >= 0) {
        defaultHeader.push(column.value);
        defaultFields.push(column.key);
        if (searchCols.indexOf(column.key) >= 0) {
          defaultSearch.push(column.key);
        }
        demometadataCount++;
      }
    });

    const { left } = getTableSize(leftDefaultCols, visibleColumns, demometadataCount, columnSettings.map(m => m.key));

    return (
      <React.Fragment>
        {/* {defaultHeader.length > 0 && */}
        <Grid item xs={left} className={"pr4"}>
          <TableComponent
            className="MangeParticipantList"
            data={data}
            header={defaultHeader}
            field={defaultFields}
            search={defaultSearch}
            height={tableHeight}
            page={page.skip}
            count={page.count}
            rowsPerPage={page.limit}
            // links={[page.fieldFilters.fieldCols[0]]}
            actions={actionFields}
            actionName={"Send Survey"}
            links={["empId"]}
            dateFields={page.fieldFilters.dateCols}
            sort={page.sort}
            sortBy={page.sortBy}
            sFields={page.sFields}
            sValues={page.sValues}
            noDataErrorMessage={""}
            removedSortingRow={[]}
            moreInfoIcons={[]}
            componentMap={[]}
            columnsMap={{}}
            showLoading={false}
            prevNext={false}
            paginationVisible={!page.lastPage}
            handleNavigateAction={handleNavigateAction}
            onChangePagination={onChangePagination}
            onSortRequest={onSortRequest}
            onInputChangeRequest={onInputChangeRequest}
            handelActionButtonClick={handelActionButtonClick}
            resetSearchText={isPageReset}
            showPagination={false}
            isLeftTable={true}
            infoHeaders={getInfoHeaders()}
            selectedIndex={selectedParticipantIndec}
            toggleDrawer={openMailDrawer}
          >
          </TableComponent>
        </Grid>
        {/* } */}
      </React.Fragment>
    );
  };

  /**
  * Get Detault Metadata Fields
  */
  const getDemographicsMetaData = () => {
    let { columnSettings, leftDefaultCols, visibleColumns, searchCols } = page.fieldFilters;
    let excludeDemographics = participantConstants.EXCLUDED_AUTOMATED_DEMOGRAPHICS;
    columnSettings = columnSettings.filter((col) => !excludeDemographics.includes(col.key));

    const demographicsHeader = [];
    const demographicsFields = [];
    const demographicsSearch = [];

    const defaultColsVisible = visibleColumns.filter(s => leftDefaultCols.indexOf(s) >= 0);

    let columnSettingsList = [];
    let columnOrder = ["default", "auto", "other", "demographic"];
    columnOrder.map((ord) => {
      let columnObj = columnSettings.filter((obj) => obj.type === ord);
      columnSettingsList = [...columnSettingsList, ...columnObj]
    })

    columnSettingsList.map(column => {
      if (leftDefaultCols.indexOf(column.key) < 0 && visibleColumns.indexOf(column.key) >= 0) {
        demographicsHeader.push({ "value": column.value, "key": column.key });
        demographicsFields.push(column.key);
        if (searchCols.indexOf(column.key) >= 0) {
          demographicsSearch.push(column.key);
        }
      }
    });

    // For Active status to be the last field in participant list
    if (demographicsFields.includes("status")) {
      const statusInx = demographicsHeader.findIndex(e => e.key === "status")
      demographicsFields.push(demographicsFields.splice(demographicsFields.indexOf("status"), 1)[0]);
      demographicsHeader.push(demographicsHeader.splice(statusInx, 1)[0]);
    }


    // For Active Status Border Issue Fix
    if (visibleColumns.indexOf('status') >= 0)
      demographicsSearch.push(null)
    const { right } = getTableSize(leftDefaultCols, visibleColumns, defaultColsVisible.length, columnSettings.map(m => m.key));

    return (
      <React.Fragment>
        {demographicsHeader.length > 0 &&
          <Grid item xs={right}>
            <TableComponent
              className="MangeParticipantList"
              data={data}
              header={demographicsHeader}
              field={demographicsFields}
              search={demographicsSearch}
              height={tableHeight}
              page={page.skip}
              count={page.count}
              rowsPerPage={page.limit}
              links={["empId"]}
              dateFields={page.fieldFilters.dateCols}
              sort={page.sort}
              sortBy={page.sortBy}
              sFields={page.sFields}
              sValues={page.sValues}
              noDataErrorMessage={""}
              removedSortingRow={[]}
              moreInfoIcons={[]}
              componentMap={{ ...componentMap }}
              alignCenterFields={['Active Status', 'status']}
              columnsMap={{}}
              showLoading={false}
              prevNext={false}
              paginationVisible={!page.lastPage}
              handleNavigateAction={handleNavigateAction}
              onChangePagination={onChangePagination}
              onSortRequest={onSortRequest}
              onInputChangeRequest={onInputChangeRequest}
              fieldComponentOnChange={fieldComponentOnChange}
              handelActionButtonClick={handelActionButtonClick}
              resetSearchText={isPageReset}
              showPagination={false}
              isRightTable={true}
              infoHeaders={getInfoHeaders()}
              selectedIndex={selectedParticipantIndec}
            >
            </TableComponent>
          </Grid>
        }
      </React.Fragment>
    );
  };

  // Set Pagination Rows Dispaly Message
  const displayPageInfo1 = ((page.skip * page.limit) - page.limit) + 1;
  const displayPageInfo2 = data.length >= page.limit ? page.skip * page.limit : (page.skip * page.limit) - (page.limit - data.length);

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <Grid onClick={() => history.push("/manage_people")} className={classes.backimg}>
                    {BackPage()}
                  </Grid>
                  <TypographyComponent
                    variant={"h3"}
                    className="ml-1">
                    {participantConstants.MANAGE_PATICIPANTS}
                  </TypographyComponent>
                </Grid>
              </Grid>
              <Grid item>
                {page && page.sFields && page.sFields.length > 0 &&
                  <ButtonComponent
                    form="clear-table-search"
                    title={"Clear all"}
                    color={"primary"}
                    size={"small"}
                    type="submit"
                    variant={"text"}
                    pageClassName="mr-1"
                    handleClick={() => {
                      history.replace(`${props.location.pathname}?reset=true`);
                    }}
                  />
                }
                <Tooltip arrow title={participantConstants.ADD_PARTICIPANTS}>
                  <IconButton
                    variant="contained"
                    color="primary"
                    className="p-0 mr-1"
                    onClick={() => history.push(`${match.url}/new`)}
                    size="large">
                    {Plus()}
                  </IconButton>
                </Tooltip>

                <ButtonComponent
                  title={participantConstants.PARTICIPANT_EXPORT}
                  color={"primary"}
                  size={"small"}
                  pageClassName="mr-1"
                  handleClick={excelDownload}
                ></ButtonComponent>
                <input color={"primary"} ref={hiddenFileInput} onChange={excelUpload} type="file" accept=".xlsx" style={{ display: "none" }} />
                <ButtonComponent
                  title={participantConstants.PARTICIPANT_IMPORT}
                  color={"primary"}
                  size={"small"}
                  handleClick={() => {
                    history.push(`${match.url}/excel/upload`);
                  }}
                ></ButtonComponent>
                <OptionsMenu
                  scroll="delBtn"
                  match={match}
                  classes={classes}
                  page={page}
                  onDemographicChange={onDemographicChange}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              {getDefaultMetaData()}
              {getDemographicsMetaData()}
              <Grid item xs={12} className={"custom-pagination"}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item>
                    {data.length > 0 ? <TypographyComponent variant="h6" > Displaying {displayPageInfo1} to  {displayPageInfo2} of {page.count} records</TypographyComponent>
                      : <TypographyComponent variant="h6" > No records</TypographyComponent>
                    }
                  </Grid>
                  <Grid item>
                    <Pagination count={Math.ceil(page.count / page.limit)} color={"primary"} shape="rounded" page={parseInt(page.skip)} onChange={onChangePagination} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
        <SendIndividualEmail
          classes={classes}
          toggleDrawer={toggleDrawer}
          drawer={openMailDrawer}
          surveyNotificationData={surveyNotificationData}
        />
      </Container>
    </React.Fragment>
  );
};
export default withStyles(PariticipantStyles)(ParticipantList);
