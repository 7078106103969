const PeopleStyles = () => ({

  // People page Styles

  people_tile:
  {
    background: "#fff",
    padding: "45px 30px",
    borderRadius: "7px",
    position: "relative",
    margin: "20px",
    boxShadow: "0px 2px 20px #2929290F"
  },
  people_tile_img: {
    width: "110px",
    height: "110px",
    position: "absolute",
    background: "#fff",
    top: "-55px",
    borderRadius: "100%",
    border: "10px solid #EAECED",
    boxShadow: "0 0 10px #00000014",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  cancelBtn: {
    color: "#6a6a6a",
    backgroundColor: "#EBECED"
  }

});

export default PeopleStyles;
