// category reducer types
export const categoryConstant = {
  CATEGORY_GET_CATEGORY_REQUEST: "CATEGORY_GET_CATEGORY_REQUEST",
  CATEGORY_GET_CATEGORY_SUCCESS: "CATEGORY_GET_CATEGORY_SUCCESS",
  CATEGORY_GET_CATEGORY_FAILURE: "CATEGORY_GET_CATEGORY_FAILURE",

  CATEGORY_GET_QUESTIONS_REQUEST: "CATEGORY_GET_QUESTIONS_REQUEST",
  CATEGORY_GET_QUESTIONS_SUCCESS: "CATEGORY_GET_QUESTIONS_SUCCESS",
  CATEGORY_GET_QUESTIONS_FAILURE: "CATEGORY_GET_QUESTIONS_FAILURE",

  CATEGORY_SEARCH_REQUEST: "CATEGORY_SEARCH_REQUEST",
  CATEGORY_SEARCH_SUCCESS: "CATEGORY_SEARCH_SUCCESS",
  CATEGORY_SEARCH_FAILURE: "CATEGORY_SEARCH_FAILURE",

  // add question to a category:
  CATEGORY_ON_ADD_QUESTION_ADD_OR_CHANGE: "CATEGORY_ON_ADD_QUESTION_ADD_OR_CHANGE",
  CATEGORY_ON_CANCEL_ADD_QUESTION: "CATEGORY_ON_CANCEL_ADD_QUESTION",
  CATEGORY_CANCEL_ADD_QUESTION:"CATEGORY_CANCEL_ADD_QUESTION",
  CATEGORY_QUESTION_SELECTED: "CATEGORY_QUESTION_SELECTED"
};
