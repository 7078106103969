const ContentLibraryStyles = () => ({

  // Tabs
  rightPanelAppBarRoot: {
    background: "transparent",
    height: "45px"
  },
  tabcharlimit:
  {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "175px"
  },
  rightPanelTabRoot: {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#EBECED",
    margin: "0px 4px",
    color: "rgba(0, 0, 0, 0.87) !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#54C0FD !important"
    },
    "&:hover .peTabEdit, &:hover .peTabDelete": {
      visibility: "visible !important",
      filter: "brightness(0) invert(1)"
    },
    "&$selected": {
      color: "#fff",
      backgroundColor: "#54C0FD"
    },
    "&:focus": {
      color: "#fff",
      backgroundColor: "#54C0FD"
    }
  },
  rightPanelTabSelected: {
    backgroundColor: "#54C0FD !important",
    color: "#fff !important"
  },

  // Reports
  reports_Container: {
    position: "relative"
  },
  ActionsSettings: {
    position: "absolute",
    right: 15,
    top: 22
  },
  ActionsSettingsText:
  {
    width: '570px',
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  reports_header: {
    borderBottom: "1px solid #DDDDDD !important",
    margin: '20px 20px 0 20px'
  },

  dashboardAlertTitle: {
    color: "#292929 !important"
  },
  dashBoardInformation: {
    borderRadius: "6px",
    background: "#fff",
    padding: "20px",
    height: "100%",
    border: "1px solid #54C0FD"
  },

  Accord_icons:
  {
    width: '32px',
    height: '32px',
    marginRight: '10px',
    padding: '8px',
    background: '#EEEEEE',
    borderRadius: '30px',
    marginTop: '4px'
  },

  ActionItems: {
    borderRadius: "4px !important"
  },
  ActionItem_Box: {
    border: "1px solid #EAEBED",
    borderRadius: "6px",
    padding: "10px",
  },

  Addaction_container: {
    border: '1px solid #EAEBED',
    borderRadius: "6px",
    padding: "10px",
    background: "#F4F6F7",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    '& .MuiFormControl-root': {
      margin: "0px !important"
    },
    '& .MuiOutlinedInput-input': {
      padding: "0px !important"
    }
  },
  ActionItem_container: {
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    '& .MuiFormControl-root': {
      margin: "0px !important"
    },
    '& .MuiOutlinedInput-input': {
      padding: "0px !important"
    }
  },
  TopicExpand: {
    boxShadow: "0px 3px 6px #00000014",
    background: "#eaebed",
    marginBottom: "15px"
  },
  actionCard: {
    border: "1px solid #EAEBED",
    borderRadius: "4px",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      border: "none",
    }
  },
  opportunityContainer: {
    // padding: "0 40px",
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '65.6px !important',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px'
    }
  },
  icon_btn: {
    padding: "0px 6px",
    "& .MuiIconButton-root": {
      padding: "0px",
    }
  },
  ContentLibMultiLineText: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "1px !important"
    },
    "& .MuiOutlinedInput-input": {
      lineHeight: "30px",
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "6px"
    }
  },
  Count_badge: {
    "& .MuiBadge-colorSecondary": {
      color: "#54c0fd",
      background: "#fff",
    }
  },
  ActionsSettingsIcon: {
    marginLeft: 10,
    padding: 5
  },
  setting_expandcontainer: {
    padding: "20px",
  },
  ActionPlanNotes:
  {
    borderColor: "rgba(0, 0, 0, 0.23)",
    '&:hover': {
      borderColor: 'rgb(95 95 95 / 87%)'
    }
  },
  content_lib_search: {
    background: "#fff",
    border: "1px solid rgb(234 235 237) !important "
  },
  content_linkimg: {
    display: "inline-flex",
    marginTop: "6px",
    alignItems: "center"
  },
  action_alert_item: {
    height: "50px",
    background: "#fff",
    padding: "3px 12px",
    boxShadow: "0px 0px 4px #0000000D",
    borderRadius: "6px",
    marginBottom: "15px",
    border: "1px solid #F3F4F5"
  },
  txtborder: {
    border: "1px solid rgba(0, 0, 0, 0.23)"
  },
  baseQusId_select: {
    border: "1px solid rgb(234 235 237) !important",
    width:"18%",
    borderRadius: "15px",
    background: "#fff",
    height: "29px",
  },
  recommendation_question_card: {
    paddingLeft: "10px",
    paddingRight: "10px",
    color:"#c0c1c2 !important",
  },
  recommendation_Addquestion_card: {
    height: "50px",
    paddingLeft: "10px",
    paddingRight: "10px",
    background:"#fff",
    display: "flex",
    alignItems: "center",
    border: "1px solid #dbdcde"
  },
  actionItem_DeleteIcon: {
    position: "absolute",
    top:0,
    right:0,
    marginTop: "15px",
    paddingRight: "10px"
  },
  addRecomendation_body: {
    width: "97%",
    marginTop: "10px"
  }

  // Report content css

});

export default ContentLibraryStyles;
