/* eslint-disable curly */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

// import components
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";

// Import Containers
import QuestionsPanel from "./QuestionsPanel";
import CategoryReorder from "./CategoryReorder";
import CustomizeAnswerSet from "./CustomizeAnswerSet";
import CategoryTabs from "./CategoryTabs";
import AddCategory from "./AddCategory";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../redux/actions";

// import Constants
import { surveyQuestionsConstants, FilterConstants } from "../../../../config/constants";
import { surveyConstant } from "../../../../redux/constants";

// Import Config Layout
import { getQuestionPanelHeight } from "../../../../config/layout";

// import images
import { Option } from "../../../../assets/images/svgComponents/ImageComponent";


function CategoryPanel (props) {
  // Get Props Values
  const { classes, questionData, addQuestions, queryBuilderData, match, errors } = props;
  const questionPanelHeight = getQuestionPanelHeight() + 5;

  // Define Dispatch
  const dispatch = useDispatch();

  // Get Active Tab Value
  const {activeCategoryTab, translated_history, getOne} = useSelector((state) => state.survey);

  const isMESurvey = getOne?.type;

  // Define state
  const [custAnsSetPopup, setCustAnsSetPopup] = useState(null);
  const [moreOptionsDialog, setMoreOptionsDialog] = useState("");

  // Get Selected Language
  let selectedLanguage = questionData.languages.filter((obj) => {
    return obj.active === true;
  });
  selectedLanguage = selectedLanguage.length > 0 ? selectedLanguage[0] : { code: "en", name: "English" };
  const langCode = selectedLanguage.code;

  // Active New Tab Based on Value
  const handleTabChange = (activeTab) => {
    if (questionData.catOrder && questionData.catOrder.length - 1 >= activeTab)
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_CATEGORY_ACTIVE_TAB, activeTab));
  };

  // Anchor tag events
  const handleClick = (event) => {
    setCustAnsSetPopup(event.currentTarget);
  };
  const handleClose = () => {
    setCustAnsSetPopup(null);
  };
  const handleDialogClose = (value) => {
    setMoreOptionsDialog("");
  };

  // check editable in questions
  const checkSaveQuestions = () => {
    let editable = false;
    for (let i = 0; i < questionData.questions.length; i++) {
      if (questionData.questions[i].editable) {
        editable = true;
      }
    }
    if (Object.keys(addQuestions).length > 0) {
      editable = true;
    }
    if (editable) {
      dispatch(alertAction.error(surveyQuestionsConstants.SAVE_QUESTION));
    } else {
      openMoreOptionMenu("reorderCategory");
    }
  };

  /**
   * Open More Options Menu
  * @param {*} menu
  */
  const openMoreOptionMenu = (menu) => {
    setMoreOptionsDialog(menu);
    setCustAnsSetPopup(null);
  };

  return (
    <React.Fragment>
      <Grid container alignItems="center">
        <Grid item sm={12} className="dflex">
          <Grid container>
            <Grid item sm={12} className={"relative"}>
              <CategoryTabs
                classes={classes}
                questionData={questionData}
                changeTabValue={handleTabChange}
                activeCategoryTab={activeCategoryTab}
                addQuestions={addQuestions}
                langCode= {langCode}
                errors={errors}
              >
              </CategoryTabs>
              <Grid className={classes.tapPanelAction}>
                {langCode === surveyQuestionsConstants.DEFAULT_LANG_CODE &&
                  <AddCategory
                    addQuestions={addQuestions}
                    classes={classes}
                    questionData={questionData}
                  ></AddCategory>
                }
                <Tooltip arrow title={FilterConstants.MORE_OPTIONS}>
                <IconButton
                  variant="contained"
                  color="inherit"
                  onClick={handleClick}
                  className={`${classes.moreOptions_Icon}`}
                  size="large">
                  {Option()}
                </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={custAnsSetPopup}
            keepMounted
            open={Boolean(custAnsSetPopup)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => openMoreOptionMenu("CustomizeAnswerSet")} data-my-value={"customeAnswerSet"}>
              Customize Answer Set
            </MenuItem>
            <MenuItem onClick={() => checkSaveQuestions()} data-my-value={"reorderCategory"}>
               {questionData.template==="Manager 360"?"Reorder Competencies":"Reordering Categories"}
            </MenuItem>
          </Menu>
        </Grid>
        <Grid item sm={12} style={{ height: questionPanelHeight }} className={"scrolly bgwhite boxShadow"}>
          <QuestionsPanel
            langCode={langCode}
            activeCategoryTab={activeCategoryTab}
            questionData={questionData}
            classes={classes}
            queryBuilderData={queryBuilderData}
            translatedFields={translated_history}
            errors={errors}
            isMESurvey={isMESurvey}
          ></QuestionsPanel>
        </Grid>
      </Grid>

      {moreOptionsDialog && (
        <DialogComponent open={true} fullScreen={true}>
          {moreOptionsDialog === "CustomizeAnswerSet" && (
            <CustomizeAnswerSet
              handleDialogClose={handleDialogClose}
              match={match}
              classes={classes}
            ></CustomizeAnswerSet>
          )}
          {moreOptionsDialog === "reorderCategory" && (
            <CategoryReorder
            questionData={questionData}
              handleDialogClose={handleDialogClose}
              classes={classes}
            ></CategoryReorder>
          )}
        </DialogComponent>
      )}
    </React.Fragment>
  );
}

// default props
CategoryPanel.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  match: {},
  queryBuilderData: {},
  addQuestions: {}
};

// prop types
CategoryPanel.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.any,
  questionData: PropTypes.object,
  queryBuilderData: PropTypes.object,
  addQuestions: PropTypes.object
};

export default CategoryPanel;
