import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { TextValidator } from "react-material-ui-form-validator";
import Autocomplete from '@mui/material/Autocomplete';
import { Checkbox } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';

// Import Component
import ButtonComponent from '../../components/ButtonComponent/ButtonComponent'


//Import Images
import { Pulse } from '../../assets/images/svgComponents/ImageComponent';

// Import Config Constants
import { errorsConstants } from "../../config/constants";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" color="primary" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

/**
 * Autocomplete Component
 */
function MultiSelectActionComponent(props) {
  const wrapperRef = useRef(null);
  const [open, setOpen] = useState(false);


  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (event.target.name !== "surveys")
          setOpen(false)
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);



  /**
   * render html
   */
  const { suggestions, disabled, value, placeholder, className, classes, validators, showErrorMessages, name, selectedOptions, readOnlyOptions, pulseSurveys } = props;
  const errorMessages = showErrorMessages ? validators.map(s => errorsConstants[s]) : [];

  const handleApply = () => {
    setOpen(false);
    props.handleApplyBtn()
  }

  const handleClearAll = (e) => {
    e.stopPropagation();
    props.handleClearAll();
  }

  return (
    <React.Fragment>
      <div style={{}} >
        {suggestions && suggestions.length > 0 &&
          <React.Fragment>
            <Autocomplete
              classes={{
                listbox: "multi-select-option-list"
              }}
              name={name}
              variant={"outlined"}
              multiple
              disableClearable
              autoHighlight
              blurOnSelect={false}
              value={Array.isArray(value) ? value : []}
              disabled={disabled}
              className={className}
              options={suggestions}
              isOptionEqualToValue={(option, value) => option === value}
              onChange={(event, newValue, action) => {
                props.handleAutoComplete(name, newValue, event, action);
              }}
              getOptionLabel={(option) => option}
              renderTags={(value, getTagProps) => {
                return null;
              }}
              renderInput={(params) => (
                <TextValidator
                  {...params}
                  variant={"outlined"}
                  value={Array.isArray(value) ? value : null}
                  name={name}
                  validators={validators}
                  errorMessages={errorMessages}
                  placeholder={value.length > 0 ? value.join(", ") : placeholder}
                  margin={"dense"}
                  InputProps={{ ...params.InputProps, type: "text", className: "multiSelectInput headerSearch pr-3" }}
                />
              )}
              renderOption={(props, option, state) => {
                return (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      className="autocomplete-checkbox"
                      checked={state.selected}
                      style={{ margin: 0, paddingRight: "4px" }}
                    />
                    {option} {option.length > 25 ? "..." : ""}
                    {pulseSurveys.includes(option.key) ? <Grid className="p3">{Pulse()}</Grid> : ""}
                  </li>
                );
              }}
              getOptionDisabled={option => readOnlyOptions.includes(option)}
              PopperComponent={({ children, ...rest }) => {
                return (
                  <React.Fragment>
                    <Popper {...rest} ref={wrapperRef}>
                      <Paper className={classes.actionDropdownBox}>
                        {children}
                        <Grid container justifyContent="center" alignItems="center" className="txtcenter action-box">
                          <Grid item md={6}>
                            <Link
                              className={`cursor-pointer colorTheme right`}
                              component="button"
                              variant="body2"
                              onClick={(e) => handleClearAll(e)}
                            >
                              {"Clear all"}
                            </Link>
                          </Grid>
                          <Grid item md={6}>
                            <ButtonComponent
                              title='Apply'
                              color='primary'
                              handleClick={handleApply}
                            />
                          </Grid>
                        </Grid>
                      </Paper>
                    </Popper>
                  </React.Fragment>
                );
              }}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={(e, reason) => {
                console.log(reason)
                if (reason === "toggleInput") {
                  setOpen(false);
                } else {
                  setOpen(true);
                }
              }}
              open={open || false}
            />
          </React.Fragment>
        }
      </div>


    </React.Fragment>
  );
}

// default props
MultiSelectActionComponent.defaultProps = {
  suggestions: [],
  value: "",
  disabled: false,
  name: "autocomplete",
  placeholder: "",
  validators: [],
  errorMessages: [],
  showErrorMessages: true,
  fieldErrors: [],
  warningFieldName: "",
  margin: "dense",
  className: "",
  suggestionType: "arrayObject",
  defaultValue: null,
  handleAutoComplete: () => { },
  multiple: true,
  isArrayObjects: false,
  selectedOptions: [],
  readOnlyOptions: [],
};

// prop types
MultiSelectActionComponent.propTypes = {
  suggestions: PropTypes.any,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  suggestionType: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  errorMessages: PropTypes.array,
  showErrorMessages: PropTypes.bool,
  fieldErrors: PropTypes.array,
  margin: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.any,
  handleAutoComplete: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  isArrayObjects: PropTypes.bool,
  selectedOptions: PropTypes.array,
  readOnlyOptions: PropTypes.array,
};

/** Export Component */
export default MultiSelectActionComponent;
