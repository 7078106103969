export const queryBuilderConstant = {
    // getting field names for query rule
    GET_FIELD_NAMES_ON_REQUEST: "GET_FIELD_NAMES_ON_REQUEST",
    GET_FIELD_NAMES_ON_SUCCESS: "GET_FIELD_NAMES_ON_SUCCESS",
    GET_FIELD_NAMES_ON_FAILURE: "GET_FIELD_NAMES_ON_FAILURE",

    // getting field values for query rule on changing field names
    GET_FIELD_VALUES_ON_REQUEST: "GET_FIELD_VALUES_ON_REQUEST",
    GET_FIELD_VALUES_ON_SUCCESS: "GET_FIELD_VALUES_ON_SUCCESS",
    GET_FIELD_VALUES_ON_FAILURE: "GET_FIELD_VALUES_ON_FAILURE",

    // save query on change
    SET_QUERY_ON_CHANGE: "SET_QUERY_ON_CHANGE",
};