import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import { isIE } from 'react-device-detect';

// // Import Styles and Image
import AuthenticationLayoutStyles from "./AuthenticationLayoutStyles";
import Logo from "../../assets/images/logo_text.png";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
// import LiveChatComponent from "../../components/LiveChatComponent/LiveChatComponent";

// Import Cofig Constants
import { authenticationConstants, errorsConstants } from "../../config/constants";

// import child router components
import PageNoIeSupport from "../../containers/PageNoIeSupport/PageNoIeSupport";

function AuthenticationLayout(props) {
  const { classes } = props;
  return <>
    {isIE ? <PageNoIeSupport errorMsg={errorsConstants.IE_NOT_SUPPORTED} /> :
      <Grid container direction="row" alignItems="stretch" className={"height100"}>
        <Grid item xs={5} lg={4} sm={4} className={`${classes.banner_container} desktopShow`} >
          <Grid container direction="column" alignItems="center" justifyContent="center" className={"height100"}>
            <Grid item style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              justifyContent: "space-around"
            }}>
              <Grid item style={{ paddingTop: "70%" }}>
                <TypographyComponent
                  title={authenticationConstants.BANNER_TEXT}
                  variant={"h2"}
                  align={"center"}
                  className={classes.banner_text}
                > </TypographyComponent>
                <TypographyComponent
                  title={authenticationConstants.BANNER_SUB_TEXT}
                  variant={"h5"}
                  align={"center"}
                  className={classes.banner_text_h5}
                > </TypographyComponent>
              </Grid>
              <Grid item>
                <div className={classes.info_panel}>
                  <div className={classes.info_panel__text1}>
                    Introducing Manager 360
                  </div>
                  <div className={classes.info_panel__text2}>
                    To learn more visit
                  </div>
                  <div className={`${classes.info_panel__text2}`}>
                    <a
                      className={`${classes.info_panel__text2} link`}
                      href="https://hubs.ly/H0ZYLYL0"
                      target="_blank"
                    >
                      peopleelement.com/manager360
                    </a>
                  </div>
                </div>
              </Grid>
              <Grid item className={classes.banner_copyright}> {authenticationConstants.COPYRIGHT} </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={8} sm={8} className={classes.auth_right_block}>
          <Grid container direction="row" className={classes.auth_section} >
            <Grid item xs={12} >
              <img src={Logo} alt={"People Element"} className={"txtright"}></img>
            </Grid>
            <Grid item xs={12} className={classes.auth_section}>
              <Grid container alignItems="center" justifyContent="center" className={"height100"}>
                <Grid item className={classes.auth_container} > {props.children} </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    }
    {/* <LiveChatComponent showChat={false} page={props.location} /> */}
  </>;
}

// prop types
AuthenticationLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.element.isRequired
};

export default withStyles(AuthenticationLayoutStyles)(AuthenticationLayout);
