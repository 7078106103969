import React from 'react';
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
import { Grid, IconButton } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

// Constants
import { ReportsConstants } from "../../../../config/constants"

//Components
import DashboardGaugeComponent from "../../../../components/DashboardGaugeComponent/DashboardGaugeComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

function LikelyToReturn(props) {
    const { classes, data, adminPreference } = props;
    const { likelyToReturn, historical_likelyToReturn } = data
    const { favCount, responseCount } = likelyToReturn
    let historical_favPercent = 0
    if (historical_likelyToReturn) {
        historical_favPercent = (historical_likelyToReturn.favCount / historical_likelyToReturn.responseCount) * 100
    }
    let favPercent = 0
    if (responseCount) {
        favPercent = (favCount / responseCount) * 100
    }
    let diffrencePoints = 0
    if (historical_favPercent) {
        diffrencePoints = Math.round(favPercent - historical_favPercent)
    }

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.LikelyToReturn === 'boolean') {
            adminPreference['data']['hideDashWidgets']['LikelyToReturn'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return <Grid className={`${classes.dashboardTile} flexdirection`}>
        <Grid container className='dflexCenter width100 spacebetween'>
            <TypographyComponent variant='h5' className={'fontWeight600 txtLeft'}>{ReportsConstants.LIKELY_TO_RETURN}</TypographyComponent>
            <ToolTipComponent title={ReportsConstants.CLOSE}>
                <IconButton onClick={handleClose} size="large">
                    {DashboardClose()}
                </IconButton>
            </ToolTipComponent>
        </Grid>
        <Grid style={{ position: "relative", display: "flex", flexDirection: "column" }}  className='height100' justifyContent={'center'} alignItems={'center'}>
            <DashboardGaugeComponent
                classes={classes}
                data={{ fillPercent: favPercent }}
                chartType={"Likely to Return"}
                responseCount={favCount}
                diff={diffrencePoints}
                isStartFromZero={true}
            />
        </Grid>
    </Grid >
}

// default props
LikelyToReturn.defaultProps = {
    classes: {},
    data: {},
    adminPreference: {},
};

// prop types
LikelyToReturn.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object,
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(LikelyToReturn);
