import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child route
import SelectSurvey from "./SelectSurvey";

function UnassignSurveyRouter (props) {
  // get props
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.url}`} render={(props) => {
        return <SelectSurvey
          match={props.match}
          {...props} />;
      }} />
    </Switch>
  );
}
// default props
UnassignSurveyRouter.defaultProps = {
  match: {}
};

// prop types
UnassignSurveyRouter.propTypes = {
  match: PropTypes.object
};
export default UnassignSurveyRouter;
