import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom";

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";

// Import components
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
// import constants
import DemographicSelection from "./DemographicSelection";
import DemographicList from "./DemographicList";

//Import Actions
import { reportAction } from "../../../../../redux/actions";

function Demographic(props) {
  const dispatch = useDispatch();

  // get props
  const { classes } = props;

  //get Reducer Data
  let { allDemographics, allDemographicsItems, reportData, allSurveys } = useSelector((state) => state.report);
  const { metadata } = useSelector((state) => state.metadata);
  const { reportType } = useSelector((state) => state.report.reportData)

  // To check if the surveys met survey threshold value
	const surveysId = []
	allSurveys.map((val) => {
		surveysId.push(val._id)
	})
	if( reportData.surveys ) {
		reportData.surveys = reportData.surveys.filter((sur) => surveysId.includes(sur._id) )
	}

  //Get datas for saved report
  useEffect(() => {
    if ((reportData.date && ((reportData.date.type==="Custom dates" && reportData.date.startDate && reportData.date.endDate) ||(reportData.date.type && reportData.date.type!=="Custom dates")))) {
      dispatch(
        reportAction.getDemographics({
          metadata: [],
          surveys: reportData.surveys,
          date: reportData.date,
        })
      );
    }
  }, [dispatch]);

  // Clear demographic Filter
  const handleClear = (e) => {
    const updatedReportData = {...reportData,demographics:{}};
    dispatch(reportAction.updateReport(updatedReportData));
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={2} className={`${classes.clearAllPosition} `}>
          <Link
            className={`cursor-pointer colorTheme right`}
            onClick={(e) => handleClear(e)}
          >
            {"Clear all"}
          </Link>
        </Grid>
        
        {reportData.demographics && Object.keys(reportData.demographics).length>0 && (
          <Grid item sm={10}>
            {/* {"Filters applied"} */}
          </Grid>
        )}
      </Grid>
      { allDemographics && Object.keys(allDemographics).length > 0 ?
      <Grid container className={classes.demographicContainer}>
      <Grid item sm={6}>
        <Grid className={`${classes.demographicWIdth}`}>
          {Object.keys(allDemographics).map((key, index) => (
            <React.Fragment key={key}>
              {allDemographics[key] > 0 && (
                <DemographicList
                  classes={classes}
                  key={key}
                  currentDemographic={key}
                  currentDemographicCount={allDemographics[key]}
                  allDemographicsItems={allDemographicsItems}
                  reportData={reportData}
                  metadata={metadata}
                />
              )}
            </React.Fragment>
          ))}
        </Grid>
      </Grid>
      <Grid item sm={6} className="pl-55">
        <DemographicSelection classes={classes} reportData={reportData} metadata={metadata}/>
      </Grid>
    </Grid>
    :
    <Grid container>
      <TypographyComponent className={"p-2"} variant="h6">{"No data found"}</TypographyComponent>
    </Grid> }
      
    </React.Fragment>
  );
}
// default props
Demographic.defaultProps = {
  classes: {},
};

// prop types
Demographic.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(Demographic);
