/* eslint-disable react/jsx-key */
/* eslint-disable prefer-const */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Chip from "@mui/material/Chip";
import { IconButton } from "@mui/material";
import moment from "moment";

// Import Styles and Image
import PariticipantStyles from "../../containers/People/Pariticipant/PariticipantStyles";
import CloudImg from "../../assets/images/svg/cloud_failed.svg";
import BackImg from "../../assets/images/svg/chev_back.svg";
import CloseImg from "../../assets/images/svg/close_orange.svg";
import AttentionImg from "../../assets/images/svg/attention.svg";

// Import Component
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Container from "../../containers/Containers/Container";

// import constants
import { participantConstants } from "../../config/constants";

// Import History
import history from "../../config/history";
const pages = ["Participant upload", "ME Participant upload"]

function SuccessPageComponent(props) {
  // get props
  const { classes, pageName, backPageURL, backBtnURL, onDone, title } = props;
  let { uploadResult } = props;
  uploadResult.cdt = new Date(uploadResult.cdt) ? moment(uploadResult.cdt).format("MM/DD/YYYY") : 0;
  uploadResult.updated = uploadResult.updated ? uploadResult.updated : 0;
  const newFileName = uploadResult.fileName && uploadResult.fileName.split("_")[0];
  const { actionType } = uploadResult;
  let successChip = participantConstants.UPLOAD_SUCCUESSCHIP
  if (pageName === "Delete Participants") {
    successChip = participantConstants.UPLOAD_DELETE_SUCCUESSCHIP
  } else if (actionType === "restored") {
    successChip = participantConstants.UPLOAD_RESTORE_SUCCUESSCHIP
  }  else if (newFileName === "archived") {
    successChip = participantConstants.UPLOAD_ARCHIVE_SUCCUESSCHIP
  } else if (pageName === "Archive/Restore participants") {
    successChip = participantConstants.UPLOAD_ARCHIVE_SUCCUESSCHIP
  }

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p-2"
      >
        <Grid item>
          <Grid container alignItems="center">
            <IconButton
              color="inherit"
              className="p5"
              onClick={() => history.push(backPageURL)}
              size="large">
              <img className={classes.backimg} src={BackImg} alt="back"></img>
            </IconButton>
            <TypographyComponent
              variant={"h3"}
              className="ml-1"
            >
              {title || pageName}
            </TypographyComponent>
          </Grid>
        </Grid>
      </Grid>

      <Container page={"sub"} justifyContent="center">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.fileUploadContainer}
        >
          <Grid item>
            <Grid item sm={12} className="pb-2">
              {
                uploadResult && uploadResult.err && uploadResult.error && (
                  <Grid container justifyContent="center">
                    <Grid className="relative">
                      <img src={CloudImg} alt="Cloud" />
                      <img
                        src={CloseImg}
                        className={classes.uploadFail_img}
                        alt="close"
                      />
                    </Grid>
                  </Grid>
                )
              }
              <TypographyComponent
                variant="h2"
                align="center"
                className="txtBold"
              >
                {uploadResult && uploadResult.err && uploadResult.error ? "File upload failed" : participantConstants.UPLOAD_TITLE}
              </TypographyComponent>
            </Grid>

            {
              uploadResult && uploadResult.err && uploadResult.error && (
                <Grid item sm={12}>
                  <TypographyComponent
                    variant="h3"
                    className="txtRegular"
                    align="center"
                  >
                    {uploadResult && uploadResult.error && uploadResult.message?.constructor === String ? `${uploadResult.error}- ${uploadResult.message}` : ""}
                  </TypographyComponent>
                </Grid>
              )
            }

            {uploadResult ? (
              <React.Fragment>
                {uploadResult.alert ? (
                  <Grid item sm={12} className="pb-3">
                    <TypographyComponent variant="h4" align="center">
                      {uploadResult.alert}
                    </TypographyComponent>
                  </Grid>
                ) : (
                  <React.Fragment>
                    {uploadResult.total >= 0 &&
                      uploadResult.fileName &&
                      uploadResult.message && (
                        <React.Fragment>
                          <Grid item sm={12} className="pb-3">
                            {pageName && pages.indexOf(pageName) >= 0 ?
                              <TypographyComponent variant="h4" align="center">
                                {`Your file ${uploadResult.fileName}, uploaded on ${uploadResult.cdt}, processed ${uploadResult.updated}/${uploadResult.total} records.`}
                              </TypographyComponent> :
                              <TypographyComponent variant="h4" align="center">
                                {`Processed records ${uploadResult.updated > uploadResult.total ? uploadResult.total : uploadResult.updated}/${uploadResult.total} from ${uploadResult.fileName}`}
                              </TypographyComponent>
                            }
                          </Grid>
                          <Grid item sm={12} className="pb-4">
                            <Grid container justifyContent="center">
                              <Chip
                                className="mr-1"
                                label={`${uploadResult.updated > uploadResult.total ? uploadResult.total : uploadResult.updated} ${successChip}`}
                                classes={{ root: classes.succussChip }}
                              />
                              <Chip
                                label={`${uploadResult.errRecords >= 0 ? uploadResult.errRecords : (uploadResult.updated > uploadResult.total ? 0 : uploadResult.total - uploadResult.updated)
                                  } ${participantConstants.UPLOAD_FAILCHIP}`}
                                classes={{ root: classes.failChip }}
                              />
                            </Grid>
                          </Grid>

                          <Grid item sm={12}>
                            {uploadResult.message &&
                              uploadResult.message.map((item, index) => (
                                <TypographyComponent
                                  variant="h6"
                                  className="mb-1"
                                  align="center"
                                >
                                  <Grid
                                    container
                                    alignItems="center"
                                    justifyContent="center"
                                  >
                                    {item.error && (
                                      <Grid item xs={12}>
                                        <TypographyComponent variant="h6" align="center">
                                          <img
                                            src={CloseImg}
                                            className="mr-1"
                                            alt="close" />
                                          {item.error}
                                        </TypographyComponent>
                                      </Grid>
                                    )}
                                    {item.alert && (
                                      <Grid item xs={12}>
                                        <TypographyComponent variant="h6" align="center">
                                          <img
                                            src={AttentionImg}
                                            className="mr-1"
                                            alt="warning"
                                          />
                                          {item.alert}
                                        </TypographyComponent>
                                      </Grid>
                                    )}
                                  </Grid>
                                </TypographyComponent>
                              ))}
                          </Grid>
                        </React.Fragment>
                      )}
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Grid>{"Data not updated"}</Grid>
              </React.Fragment>
            )}

            <Grid item sm={12} className="pt-5">
              <Grid container justifyContent="center">
                <ButtonComponent
                  title={"Done"}
                  color={"primary"}
                  size={"small"}
                  pageClassName="mr-1"
                  handleClick={() => {
                    if (!onDone) {
                      history.push(backBtnURL)
                    } else {
                      onDone()
                    }
                  }}
                ></ButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
SuccessPageComponent.defaultProps = {
  classes: {},
  uploadResult: {},
  backPageURL: "/manage_people",
  backBtnURL: "/manage_people",
  pageName: "",
  title: null
};

// prop types
SuccessPageComponent.propTypes = {
  classes: PropTypes.object,
  uploadResult: PropTypes.object,
  backPageURL: PropTypes.string,
  backBtnURL: PropTypes.string,
  pageName: PropTypes.string,
  onDone: null
};
export default withStyles(PariticipantStyles)(SuccessPageComponent);
