import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import PriorityHigh from '@mui/icons-material/PriorityHigh';

// Import History
import history from "../../config/history";

// Import Component
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

// Custom Style
const useStyles = makeStyles({
  root: {
    position: "relative",
    textAlign: "center",
    padding: "100px",
    height: "100%"
  },
  icon: {
    width: "100px",
    height: "100px",
    color: "#54C0FD"
  }
});

function PageNotHaveAccess() {
  const classes = useStyles();

  const goBack = () => {
    history.goBack()
  };

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="height100"
      >
        <Grid item xs>
          <PriorityHigh className={classes.icon}></PriorityHigh>
          <Typography variant="h2" className="mb-2 txtBold">
            Access Denied.
          </Typography>

          <Typography variant="h4">
            You dont have access for this page. Please contact people element admin.
          </Typography>

          <div className={"pt-2"}>
            <ButtonComponent
              title={'Go back'}
              color={"primary"}
              size={"large"}
              handleClick={() => goBack()}
            ></ButtonComponent>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default PageNotHaveAccess;
