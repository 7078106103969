/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";

// import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import AddQuestions from "./AddQuestions";
import RequiredQuestions from "./RequiredQuestions";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

// Import Images
// import Addquestion from "../../../../assets/images/svg/plus-blue.svg";
import { Plus_blue } from "../../../../assets/images/svgComponents/surveySVG";
import { getReducerUpdatedData, isTranslated, iseditedLangtxt } from "../../../../helpers";
import { PencilEdit } from "../../../../assets/images/svgComponents/ImageComponent";




function CategoryDescription(props) {
  const dispatch = useDispatch();
  const questionRef = useRef(null);

  // Get Props Values
  const { classes, questionData, activeCategoryTab, langCode, item, translatedFields } = props;
  const { questions, catOrder, catDetails } = questionData;

  // Set State
  const [addQuesFlag, setAddQuesFlag] = React.useState(null);
  const { addQuestions } = useSelector(state => state.category);
  const category = catOrder[activeCategoryTab];


  // Update reducer data
  const updateReducer = (updateFields) => {
    const reducerData = { ...questionData, catDetails: updateFields };
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, reducerData));
  };

  // question edit mode
  const updateAddQuesFlag = () => {
    setAddQuesFlag(null);
  };

  // Update translated history
  const updateTranslateHistory = (translateKey = "") => {
    const translateEditedData = iseditedLangtxt(translatedFields, langCode, translateKey);
    dispatch(updateStateReducerAction.update(surveyConstant.EDIT_TRANSLATE_HISTORY_UPDATE_SUCCESS, translateEditedData));
  };


  // Category Decsription editing
  const editCategoryDesc = (event, translateKey = "") => {
    let { name, value } = event;
    let field = name.split("  ");
    let categoryName = field[0];
    let language = field[1];
    catDetails[categoryName][language]["desc"] = value;
    updateReducer(catDetails);
    updateTranslateHistory(translateKey);
  };

  // Category Name editing
  const editCategoryName = (event, translateKey = "") => {
    let { name, value } = event;
    let field = name.split("  ");
    let categoryName = field[0];
    let language = field[1];
    catDetails[categoryName][language]["name"] = value;
    updateReducer(catDetails);
    updateTranslateHistory(translateKey);

  };

  // add new Question button binding
  const addQuestionButton = () => {
    return (
      <Grid item>
        <Link
          onClick={() => setAddQuesFlag(catOrder[activeCategoryTab])}
          className={`${classes.quest_linkimg} cursor-pointer`}
          color="primary"
        >
          <IconButton
            color="inherit"
            className={`${classes.anonymousCopyIcon} p-0`}
            size="large">{/* <img src={Addquestion} alt={""} /> */} {Plus_blue()} </IconButton>
          Add question
        </Link>
      </Grid>
    );
  };
  useEffect(() => {
    if (questionRef.current) {
      questionRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" });
    }
    //  window.scrollTo(0, 0)
  }, [])

  if (catDetails[item] === undefined) {
    return null
  }

  const handleTooltipClick = () => {
    const categoryDescriptionTextField = document.getElementById(`categoryDescription`);
    if (categoryDescriptionTextField) {
      categoryDescriptionTextField.focus(); 
    }
  }
  return (
    <React.Fragment>
      <Grid container alignItems="center" className="pb5" ref={questionRef}>
        {langCode === surveyQuestionsConstants.DEFAULT_LANG_CODE &&
          <React.Fragment>
            <Grid item sm={8} display={"flex"}>
              <Grid item className="dflex mt15 mr5" onClick={handleTooltipClick}>{PencilEdit()}</Grid>
              <TextFieldComponent
                id="categoryDescription"
                placeholder={questionData.template==="Manager 360"?"Add competency description...":"Add category description..."}
                size="small"
                type="string"
                name={`${item}  ${langCode}`}
                fullWidth={true}
                value={catDetails[item][surveyQuestionsConstants.DEFAULT_LANG_CODE]["desc"]}
                handleChange={(e) => editCategoryDesc(e)}
                className={`br-none oneline_ellipse_input colorOrange opacityone textBoldCat pl-0 w456`} />
            </Grid>
            <Grid item sm={4}>
              <Grid container alignItems={"center"} justifyContent={"flex-end"}>
                {addQuestionButton()}
                <Grid item>
                  <RequiredQuestions item={item} classes={classes} questionData={questionData} addQuestions={addQuestions} activeCategoryTab={activeCategoryTab} questions={questions} />
                </Grid>
              </Grid>
            </Grid>
          </React.Fragment>
        }
        {langCode !== surveyQuestionsConstants.DEFAULT_LANG_CODE &&
          <Grid container spacing={3} className="pb5 pt-1">
            <Grid item sm={6}>
              <TypographyComponent className={`${"fontSize13"} ${classes.category_desclabel}`} variant={"h6"}>{item}</TypographyComponent>
              <TextFieldComponent
                id={`itemnmae${langCode}`}
                placeholder={questionData.template==="Manager 360"?"Competency name":"Category name"}
                className={`${"boderRadius2 height36 bgGrey"} ${translatedFields && translatedFields.hasOwnProperty(langCode) ? (isTranslated(translatedFields, langCode, `catDetails_${item}_name`) ? classes.translatedField : classes.editedtranslatedField) : ""} `}
                name={`${item}  ${langCode}`}
                value={catDetails[item][langCode]["name"]}
                margin={"dense"}
                showErrorMessages={false}
                handleChange={(e) => editCategoryName(e, `catDetails_${item}_name`)}
              ></TextFieldComponent>
            </Grid>
            <Grid item sm={6}>
              {catDetails[item][surveyQuestionsConstants.DEFAULT_LANG_CODE][["desc"]] && catDetails[item][surveyQuestionsConstants.DEFAULT_LANG_CODE][["desc"]] !== " " &&
                <React.Fragment>
                  <TypographyComponent className={`${"fontSize13"} ${"colorOrange"} ${"fontWeightBold"} ${classes.category_desclabel}`} variant={"h6"}>
                    {catDetails[item][surveyQuestionsConstants.DEFAULT_LANG_CODE][["desc"]]}
                  </TypographyComponent>
                  <TextFieldComponent
                    id={`itemdesc${langCode}`}
                    placeholder={questionData.template==="Manager 360"?"Add competency description...":"Add category description..."}
                    className={`${"boderRadius2 colorOrange height36 opacityone bgGrey"} ${"textBoldCat"} ${translatedFields && translatedFields.hasOwnProperty(langCode) ? (isTranslated(translatedFields, langCode, `catDetails_${item}_desc`) ? classes.translatedField : classes.editedtranslatedField) : ""} `}
                    name={`${item}  ${langCode}`}
                    value={catDetails[item][langCode]["desc"]}
                    margin={"dense"}
                    showErrorMessages={false}
                    handleChange={(e) => editCategoryDesc(e, `catDetails_${item}_desc`)}
                  ></TextFieldComponent>
                </React.Fragment>
              }
            </Grid>
          </Grid>
        }
      </Grid>
      {(addQuesFlag === category || addQuestions[category]) && <AddQuestions classes={classes} updateAddQuesFlag={updateAddQuesFlag} questionData={questionData} category={catOrder[activeCategoryTab]} addQuestions={addQuestions} />}
    </React.Fragment>
  );
}

// default props
CategoryDescription.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE
};

// prop types
CategoryDescription.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  langCode: PropTypes.string,
  updateReqQuestions: PropTypes.func
};

export default CategoryDescription;
