const SpCallerStyles = () => ({

  backimg: {
    width: "28px",
    height: "28px",
    background: "#FFFFFF",
    border: "1px solid #E2E2E2",
    padding: "3px 9px",
    borderRadius: "30px",
    cursor: "pointer"
  },
  callerDetailPage: {
    background: "#fff !important",
    padding: " 0 25px"
  },

  // Spcaller
  spTiles:
  {
    height: "100%",
    padding: "20px",
    background: "#fff",
    boxShadow: "0px 0px 10px #0000002A",
    borderRadius: "6px"
  },

  sp_tile:
  {
    background: "#fff",
    padding: "45px 30px",
    borderRadius: "7px",
    position: "relative",
    // margin: "20px",
    boxShadow: "0px 2px 20px #2929290F"
  },
  sp_tile_img: {
    width: "110px",
    height: "110px",
    position: "absolute",
    background: "#fff",
    top: "-55px",
    borderRadius: "100%",
    border: "10px solid #EAECED",
    boxShadow: "0 0 10px #00000014",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  AssignedServeySearch: {
    border: "1px solid #E3E4E6",
    borderRadius: "30px",
    "& .MuiInput-root": {
      background: "#fff",
      borderTopRightRadius: "0",
      borderBottomRightRadius: "0"
    },
    "& .MuiButton-root": {
      borderTopLeftRadius: "0",
      borderBottomLeftRadius: "0"
    }
  },

  // Caller Detail
  callerDetailContainer: {
    background: "#F4F6F7",
    padding: "20px 40px",
    border: "1px solid #EAEBED",
    //boxShadow: "0px 0px 5px 2px #F1F3F4"
    boxShadow: "0px 2px 10px #00000016"

  },
  callerdetEditsendimg: {
    width: "28px",
    height: "28px"
  },
  editContainer: {
    background: "#EAEBED",
    borderRadius: "30px",
    padding: "0 5px",
    position: "relative",
    height: "36px",
    "& .MuiFormControl-marginDense": {
      marginTop: "4px !important"
    },
    "&.editable": {
      background: "#FFF"
    }
  },
  stateContainer: {
    background: "#EAEBED",
    borderRadius: "30px",
    padding: "0 5px",
    width: "190px",
    height: "28px !important",
    position: "relative",
    "& .MuiFormControl-marginDense": {
      marginTop: "4px !important"
    },
  },

  CutomerDetailDate: {
    maxWidth: "145px",
    marginRight: "10px"
  },
  CutomerDetailTime: {
    maxWidth: "118px",
    "& .MuiIconButton-root": {
      padding: "0px !important"
    }
  },

  callerdetCopyimg: {
    padding: "7px 7px 3px 8px"
  },
  callerdetEditimg: {
    width: "12px",
    height: "12px"
  },
  callerdetTickimg: {
    width: "14px",
    height: "14px"
  },
  callerdetCopyGrid: {
    background: "#c0c1c2",
    padding: "8px 8px",
    cursor: "pointer",
    "&:hover": {
      background: "#ccc",
    },
    "&.emailIcon": {
      padding: "0px",
      backgroundColor: "#fff",
    }
  },
  callerdetEditGrid: {
    background: " #fff",
    padding: "8px 8px !important",
    cursor: "pointer",
    marginLeft: "4px"
  },
  callderdetNextBtn: {
    "& .MuiButton-root": {
      borderTopLeftRadius: "6px",
      borderTopRightRadius: "0px",
      borderBottomLeftRadius: "6px",
      borderBottomRightRadius: "0px",
      padding: "10px",
      marginRight: "-62px"
    }
  },
  quoteIconRotate: {
    transform: "rotate(180deg)",
    textAlign: "start",
  },
  quoteContainer: {
    background: "#fff",
    borderRadius: "6px",
    border: "1px solid #EEEFF1",
    padding: "10px",
    maxWidth: "85%",
    "& img": {
      width: "12px",
      height: "12px",
    }
  },
  drawerTitle: {
    minHeight: "55px"
  },
  expansionDetail: {
    overflowY: "auto"
  },
  MuiAccordion: {
    marginTop: "0px !important",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      backgroundColor: "#fff",
      borderBottom: "1px solid #E9E9E9",
      "& .panel-active": {
        color: "#292929 !important",
      }
    }
  },
  selectColumnCheckbox: {
    padding: "0px 5px"
  },
  optionButton: {
    marginTop: "5px",
    width: "28px",
    height: "28px"
  },
});
export default SpCallerStyles;
