const ButtonBaseComponentStyle = (theme) => ({
  root: {
    width: "100%",
    height: "100%"
  },
  active: {
    // background: "#E3F5FF"
    border: "2px solid #54C0FD",
    color: "#54C0FD",
    "& h3": {
      color: "#54C0FD"
    }
  }

});

export default ButtonBaseComponentStyle;
