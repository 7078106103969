import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';


// Import Styles and Image 
import { BackPage } from "../../assets/images/svgComponents/ImageComponent";

import ImportFileComponentStyle from "./ImportFileComponentStyle";

// Import Component
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import DropZoneComponent from "../DropZoneComponent/DropZoneComponent";
import Container from "../../containers/Containers/Container";

// Import History
import history from "../../config/history";

function ImportFileComponent(props) {
  // get props
  const { classes, cancelBtnURL, headerText, dropText, importFileData, backBtnURL } = props;

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p-2"
      >
        <Grid item>
          <Grid container alignItems="center">
              <Grid onClick={() => history.push(backBtnURL)} className={classes.backimg}>
                {BackPage()}
              </Grid>
            <TypographyComponent data-test="import-test" variant={"h3"} className="ml-1">
              {headerText}
            </TypographyComponent>
          </Grid>
        </Grid>
      </Grid>

      <Container page={"sub"} justifyContent="center">
        <DropZoneComponent
          accept=".xlsx,.csv"
          textData={dropText}
          cancelBtnURL={cancelBtnURL}
          importFileData={importFileData}
        />
      </Container>
    </React.Fragment>
  );
}
// default props
ImportFileComponent.defaultProps = {
  classes: {},
  headerText: "",
  dropText: "",
  backBtnURL: "/manage_people",
  cancelBtnURL: "/manage_people",
  importFileData: () => { }
};

// prop types
ImportFileComponent.propTypes = {
  classes: PropTypes.object,
  headerText: PropTypes.string,
  dropText: PropTypes.string,
  backBtnURL: PropTypes.string,
  cancelBtnURL: PropTypes.string,
  importFileData: PropTypes.func
};
export default withStyles(ImportFileComponentStyle)(ImportFileComponent);
