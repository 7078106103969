import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from "@mui/material/Grid";
import Drawer from '@mui/material/Drawer';
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles and Image
import { DrawerClose } from "../../../../assets/images/svgComponents/ImageComponent"
import FilterStyles from "../../../Filter/FilterStyles";

// Panel
import IconButton from '@mui/material/IconButton'

import { getDrawerHeight } from "../../../../config/layout";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";

import { alertAction, reportAction } from "../../../../redux/actions";
import { handleExport, validateReportForm, handleSave } from "../../Report/CreateReport/CreateReportStepper";

//import validations
import { stepperValidation } from "../../Report/CreateReport/validation";


// panels component
import ReportSettingsPanel from "./ReportSettingsPanel"
import SaveReportPanel from "./SaveReportPanel"
import { ReportsConstants, REPORT_TYPE_CONSTANTS, surveyTypes } from "../../../../config/constants";
const ME_REPORTS = [REPORT_TYPE_CONSTANTS.GROUP_REPORT, REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT]

function AdditionalReports(props) {

    const dispatch = useDispatch()
    const { classes, open, toggleDrawer, exportCsv = false } = props
    const [currentPanel, setCurrentPanel] = useState("REPORT SETTINGS")
    const { reportData, commentReport, savedReports: { reportName } } = useSelector(state => state.report)
    const { reportType } = reportData

    const hasMEREPORTS = ME_REPORTS.indexOf(reportType) >= 0
    const hasMESurvey = reportData.surveys.length > 0 && reportData.surveys[0].type === surveyTypes.MULTI_RATER_FEEDBACK
    // report form ref
    const report_form_ref = useRef();
    const drawerHeight = getDrawerHeight();

    let helpText = ""
    if (reportType === REPORT_TYPE_CONSTANTS.GROUP_REPORT) {
        helpText = "Click the Export button for an aggregated PDF report. This report includes data for all Subjects in the selected survey."
    } else if (reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT) {
        helpText = "Click the Export button to view participation for each Subject in the selected survey. To schedule this report to be sent by email, click Save Report and configure the settings."
    } else if (reportType === REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT) {
        helpText = "Select a Subject name to create a PDF of their report. Click the Export button to generate their report."
    }
    const onChangePanel = (panel) => {
        setCurrentPanel(panel)
    }

    useEffect(() => {
        if (!open) {
            setCurrentPanel("REPORT SETTINGS")
        }
    }, [open])

    return (
        <Grid item>
            <Drawer
                className={`${classes.drawer} pe_drawer additional_reports`}
                anchor='right'
                variant="temporary"
                open={open !== null}
                onClose={toggleDrawer(null)}
                PaperProps={{
                    style: {
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        height: drawerHeight
                    }
                }}>
                <div style={{ height: drawerHeight - 107 }} className={'scrolly'}>
                    <ValidatorForm
                        id="reportForm"
                        name="reportForm"
                        // useref="reportForm"
                        ref={report_form_ref}
                        className={"width100"}
                        autoComplete="off"
                        // onSubmit={handleSubmit}
                        onError={(error) => {
                            console.log(error);
                        }}
                    >
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            className={`${"pr-1 pl-1 borderBottom"} ${classes.filterTitle}`}
                        >
                            <Grid item>
                                <Grid container alignItems="center">
                                    <TypographyComponent variant={"h4"}
                                        className="txtMedium">
                                        {open}
                                    </TypographyComponent>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton variant="contained" onClick={toggleDrawer(null)} size="large">
                                    {DrawerClose()}
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item>
                                <Grid container alignItems="center">
                                    {hasMESurvey &&
                                        <span className={classes.filterInfoGrid}>
                                            {helpText}
                                        </span>}
                                </Grid>
                            </Grid>
                            {!hasMESurvey ?
                                <ReportSettingsPanel
                                    classes={classes}
                                    onChangePanel={onChangePanel}
                                    currentPanel={currentPanel}
                                    hasMEREPORTS={hasMEREPORTS}
                                /> : (
                                    reportType === REPORT_TYPE_CONSTANTS.INDIVIDUAL_REPORT ?
                                        <ReportSettingsPanel
                                            classes={classes}
                                            onChangePanel={onChangePanel}
                                            currentPanel={currentPanel}
                                            hasMEREPORTS={hasMEREPORTS}
                                        /> : ''
                                )
                            }
                            <SaveReportPanel
                                classes={classes}
                                onChangePanel={onChangePanel}
                                currentPanel={currentPanel}
                                hasMEREPORTS={hasMEREPORTS}
                            />
                        </Grid>

                        <div className={`p-1 ${classes.saveFilterBtn} drawer`}>
                            <Grid
                                container
                                justifyContent="flex-end"
                                className={classes.footerBtns_container}
                                style={{ marginTop: "16px" }}
                            >
                                <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                                    {currentPanel === "SAVE REPORT (OPTIONAL)" && (
                                        <ButtonComponent
                                            title={ReportsConstants.SAVE}
                                            color={"primary"}
                                            size={"small"}
                                            type="button"
                                            pageClassName="mr-1"
                                            // disabled={reportData && reportData.reportType === "Raw Data"}
                                            handleClick={() => {
                                                const validation = stepperValidation(4, reportData, commentReport.commentQuestions, reportName, "save", true);
                                                validateReportForm(report_form_ref).then(isValid => {
                                                    if (isValid && validation === true) {
                                                        handleSave({ reportData, commentReport }, dispatch)
                                                    } else {
                                                        dispatch(alertAction.error(validation));
                                                    }
                                                })
                                            }}
                                        />
                                    )}
                                    {
                                        exportCsv &&
                                        <ButtonComponent
                                            title={ReportsConstants.EXPORT_CSV}
                                            color={"primary"}
                                            size={"small"}
                                            type={"button"}
                                            pageClassName={`mr-1`}
                                            handleClick={() => {
                                                const validation = stepperValidation(3, reportData, commentReport.commentQuestions, reportName, "export", false);
                                                validateReportForm(report_form_ref).then(isValid => {
                                                    if (validation === true) {
                                                        handleExport('Comment Excel Report', reportData.selectedDemographic, {
                                                            reportData, commentReport, dispatch
                                                        })
                                                    } else {
                                                        dispatch(alertAction.error(validation));
                                                    }
                                                })
                                            }}
                                        />
                                    }
                                    <ButtonComponent
                                        title={open === ReportsConstants.COMMENT_REPORT ? ReportsConstants.EXPORT_PDF : ReportsConstants.EXPORT}
                                        color={"primary"}
                                        size={"small"}
                                        type={"button"}
                                        
                                        pageClassName={`${open !== ReportsConstants.COMMENT_REPORT ? "buttonOrange buttonOrange-hover" : '' } mr-1`}
                                        handleClick={() => {
                                            const validation = stepperValidation(3, reportData, commentReport.commentQuestions, reportName, "export", false);
                                            if (validation === true) {
                                                handleExport(reportData.reportType, reportData.selectedDemographic, {
                                                    reportData, commentReport, dispatch
                                                })
                                            } else {
                                                dispatch(alertAction.error(validation));
                                            }
                                        }}
                                    />
                                    <ButtonComponent
                                        title={ReportsConstants.CANCEL}
                                        color={"default"}
                                        size={"small"}
                                        type={"button"}
                                        handleClick={toggleDrawer(null)}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </ValidatorForm>
                </div>
            </Drawer>
        </Grid>
    );
}

export default withStyles(FilterStyles)(AdditionalReports);