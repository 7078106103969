const HotspotTableStyle = theme => ({
  TableHelpIcon: {
    "& .ml-1": {
      marginLeft: "0px !important",
      paddingRight: "5px !important",
      top: "-1px"
    }
  },
  centerAlignTxt: {
    textAlign: "center !important",
    fontSize: '13px'
  },

  leftAlignTxt: {
    textAlign: "left !important",
    fontSize: '13px'
  },
  cell1Width: {
    minWidth: "415px",
  },
  greyRow: {
    "& .sticky-table-cell": {
      background: "#d1d1d1",
      color: "#292929"
    }
  },
  KeyLogo: {
    position: "absolute",
    top: "30%",
    right: "20px"
  },
  tableheadercolor: {
    '&:hover span':{
      pointerEvents: 'none'
    },
    '& .sticky-table-cell': {
      '&:first-child': {
        cursor: 'context-menu',
      },
      cursor: 'pointer',
      '&:hover .MuiTableSortLabel-icon': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  },
  hotspotViewbySelect: {
    '& > div': {
      paddingLeft: '4px !important',
    },
  },
});

export default HotspotTableStyle;
