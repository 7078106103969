import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import _ from "lodash";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

// Import Actions
import { dashboardAction } from "../../../../redux/actions";

//import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import RenderHotspotTable from "./RenderHotspotTable";

const HotspotTables = (props) => {
  const dispatch = useDispatch();

  //Get props
  const { classes, zoom, selectedType, updateSelectedType, keydriver, applyFilters, filtersApplied } = props;

  const isKeyDriver = keydriver.length > 0

  //get reducer data
  let hotspotData = useSelector((state) => state.dashboard.hotspot);
  let { data, color, header2, sorting } = hotspotData;

  const [sortvalue, setSortvalue] = useState("desc")
  const [sortByvalue, setSortByvalue] = useState("")

  let header = [];
  let rowOrder = [];
  let staticHeaders = 3;
  let sortingData = sorting;
  let staticRow = data.filter((d) => ["Number of Respondents (N)", "Overall % Favorable"].indexOf(d["_id"]) > -1);
  let tableData = data.filter((d) => ["Number of Respondents (N)", "Overall % Favorable"].indexOf(d["_id"]) === -1);
  const leftAlignCells = ["_id", "catName"];

  if (selectedType === "category") {
    header = ["text", "Overall", ...header2];
    rowOrder = ["_id", "Overall", ...header2];
    staticHeaders = 2;
  } else if (isKeyDriver) {
    header = ["text", "Key Driver", "Category", "Overall", ...header2];
    rowOrder = ["_id", "keyDriverValue", "catName", "Overall", ...header2];
    staticHeaders = 4;
  } else {
    header = ["text", "Category", "Overall", ...header2];
    rowOrder = ["_id", "catName", "Overall", ...header2];
    staticHeaders = 3;
  }

  const handleSorting = (item, sort) => {
    //replacing the header display name with its key for default rows
    let sortItem = item;
    if (sortItem === "text") {
      sortItem = "_id";
    }
    if (sortItem === "Category") {
      sortItem = "catName";
    }
    if (sortItem === "Key Driver") {
      sortItem = "corelation";
    }

    setSortByvalue(sortItem)
    //changeing the sorting options
    if (sort === "asc") {
      sort = "desc";
    } else {
      sort = "asc";
    }
    setSortvalue(sort)
    //updating current item sorting method and removing other field sorting methods
    for (let key in sorting) {
      sorting[key] = "";
    }
    sortingData = { ...sorting, [item]: sort };
    //perform sorting based in header
    let sortedArray = _.orderBy(tableData, [sortItem], [sort]);
    if (keydriver.length > 0 && sortItem === "corelation") {
      sortedArray = [..._.orderBy(tableData.filter(f => (f.keyDriverValue === 1)), [sortItem], [sort]), ...tableData.filter(f => (f.keyDriverValue === 0))]
    }
    //update reducer data
    let resultData = [...staticRow, ...sortedArray];
    hotspotData = { ...hotspotData, data: resultData, sorting: sortingData };

    //update reducer
    dispatch(dashboardAction.updateHotspotData(hotspotData));
  };

  // Key Driver Data Framing
  let selectType = keydriver.length > 0 ? selectedType : (selectedType === "keyDriver" ? "question" : selectedType)


  let keyDriverIds = keydriver.map((val) => val.question)
  // Filterring keydriver questions
  if (selectType === "keyDriver") {
    tableData = tableData.filter((d) => {
      return keyDriverIds.includes(d._id)
    })
  }

  //sorting Logic
  let sortItemTbl = ""
  let sortTbl = ""
  for (let key in sorting) {
    let k = key ? key.replace(/\[dot]/g, ".") : ""
    if (sorting[k]) {
      sortItemTbl = k
      sortTbl = sorting[k]
    }
  }

  // Adding keydriver logo
  if (selectType === "question" || selectType === "keyDriver") {

    if ((!sortItemTbl || !sortTbl) && keydriver.length === 0) {
      tableData = _.orderBy(tableData, ["text"], ["asc"]);
    }

    if (keydriver.length > 0) {
      tableData = tableData.map((d) => {
        d["keyDriverValue"] = 0
        d["corelation"] = 0
        keydriver.map((k) => {
          if (k.question === d._id) {
            d["keyDriverValue"] = 1
            d["corelation"] = k.corelation
          }
        })
        return d
      })
    }
    tableData = _.orderBy(tableData, [sortItemTbl ? sortItemTbl : "corelation"], [sortTbl ? sortTbl : "desc"]);
  }

  return (
    <React.Fragment>
      {data && data.length > 2 && (
        <React.Fragment>
          <Grid item sm={12}>
            <RenderHotspotTable
              header={header}
              staticRow={staticRow}
              tableData={tableData}
              rowOrder={rowOrder}
              staticHeaders={staticHeaders}
              colorRange={color}
              handleSorting={handleSorting}
              leftAlignCells={leftAlignCells}
              selectedType={selectType}
              updateSelectedType={updateSelectedType}
              zoom={zoom}
              header2={header2}
              sortingData={sortingData}
              keydriver={keydriver}
              applyFilters={applyFilters}
              filtersApplied={filtersApplied}
            />
          </Grid>
          <Grid className={`${classes} pt-3`}>
            <Grid className={classes.Hotspot_row}>
              <Grid className={classes.Hotspot_barContainer}>
                <div
                  style={{
                    width: "20%",
                    backgroundColor: "#f8ad4a",
                    marginRight: "3px",
                  }}
                ></div>
                <div
                  style={{
                    width: "20%",
                    backgroundColor: "#fbcb90",
                    marginRight: "3px",
                  }}
                ></div>
                <div
                  style={{
                    width: "20%",
                    backgroundColor: "#F5F5F5",
                    marginRight: "3px",
                  }}
                ></div>
                <div
                  style={{
                    width: "20%",
                    backgroundColor: "#94d6fb",
                    marginRight: "3px",
                  }}
                ></div>
                <div style={{ width: "20%", backgroundColor: "#54bffb" }}></div>
              </Grid>
            </Grid>
            <Grid className={classes.Hotspot_row}>
              <Grid className={classes.Hotspot_WordContainer}>
                <Grid> Minimum </Grid>
                <Grid> Median </Grid>
                <Grid> Maximum </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
      {(!data || data.length <= 2) && (
        <Grid container justifyContent="center" alignItems="center" className="mt-6">
          <TypographyComponent variant="h3">
            {ReportsConstants.NO_DATA_CHART}
          </TypographyComponent>
        </Grid>
      )}
    </React.Fragment>
  );
};
// default props
HotspotTables.defaultProps = {
  classes: {},
  zoom: false,
  updateSelectedType: () => { },
  selectedType: "question",
  applyFilters: () => {},
  filtersApplied: {},
};

// prop types
HotspotTables.propTypes = {
  classes: PropTypes.object,
  zoom: PropTypes.bool,
  updateSelectedType: PropTypes.func,
  selectedType: PropTypes.string,
  applyFilters: PropTypes.func,
  filtersApplied: PropTypes.object,
};
export default HotspotTables;
