const AdminStyles = () => ({

  // Add Particpant
  accordheading: {
    fontWeight: "bold"
  },
  settings_icons:
      {
        paddingRight: "8px",
        width: "28px"
      },
  backimg: {
    width: "28px",
    height: "28px",
    background: "#FFFFFF",
    border: "1px solid #E2E2E2",
    padding: "3px 9px",
    borderRadius: "30px",
    cursor: "pointer"
  },
  surveylevel:{
    justifyContent:"space-between",
    padding:"40px"
  },
  footerBtns_container: {
    position: "fixed",
    bottom: 0,
    background: "#fff",
    padding: "15px 20px",
    zIndex: "99"
  }

});

export default (AdminStyles);
