import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from "@mui/styles/withStyles";
import { ValidatorForm } from "react-material-ui-form-validator";
import moment from "moment";

import { useDispatch, useSelector } from "react-redux";

// Import Styles and Image
import APIIntegrationStyles from "../APIIntegrationStyles";

// Import Constants
import { internalAPIConstants } from "../../../config/constants";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import InformationComponent from "../../../components/InformationComponent/InformationComponent";
import DatePickerComponent from "../../../components/DatePickerComponent/CustomDatePicker";
import AutoCompleteComponent from "../../../components/AutoCompleteComponent/AutoCompleteComponent";

// Import Actions
import { apiIntegrationAction } from "../../../redux/actions";
import TokenDialog from "./TokenDialog";
import history from "../../../config/history";
import { IconButton, SvgIcon } from "@mui/material";
import { CopySetting } from "../../../assets/images/svgComponents/ImageComponent";
import ExitDialogInapi from "./ConfigPanels/ExitDialog";

const GenereateTokenPanel = (props) => {
  //Get props
  const { classes, match, location } = props;
  const dispatch = useDispatch();
  const internal_api_config = useSelector(
    (state) => state.apiIntegration.internalAPI
  );
  const [exitDialog , setExitDialog] = useState(false)
  const [wishlistUrl, setWishListUrl] = useState(internal_api_config.domainUrl);
  const [limitDate, setLimitDate] = useState({
    startDate: internal_api_config.startDate,
    endDate: internal_api_config.endDate,
  });
  const [repeatTime, SetRepeatTime] = useState(internal_api_config.apiHit);
  const [repeatPeriod, SetRepeatPeriod] = useState(
    internal_api_config.limitsPer
  );
  const apiData = useSelector((state) => state.apiIntegration?.internalAPI)
  let token = ""
  if(apiData.token){
    token =  apiData.token.token
  }

  // validation for end date
  useEffect(() => {
    if (limitDate && limitDate.startDate && limitDate.endDate) {
      ValidatorForm.addValidationRule("isEnddategreater", (value) => {
        if (new Date(limitDate.endDate) < new Date(limitDate.startDate)) {
          return false;
        } else {
          return true;
        }
      });
      ValidatorForm.addValidationRule("pastDate", (value) => {
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        var dd = yesterday.getDate();
        var mm = yesterday.getMonth() + 1; //January is 0!
        var yyyy = yesterday.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        yesterday = yyyy + "-" + mm + "-" + dd;
        if (new Date(limitDate.endDate) <= new Date(yesterday)) {
          return false;
        } else {
          return true;
        }
      });
      ValidatorForm.addValidationRule("pastDateStartDate", (value) => {
        var yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        var dd = yesterday.getDate();
        var mm = yesterday.getMonth() + 1; //January is 0!
        var yyyy = yesterday.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
        if (mm < 10) {
          mm = "0" + mm;
        }
        yesterday = yyyy + "-" + mm + "-" + dd;
        if (new Date(limitDate.startDate) <= new Date(yesterday)) {
          return false;
        } else {
          return true;
        }
      });
    }
  }, [limitDate.startDate, limitDate.endDate,]);

  useEffect(() => {
    if (repeatTime) {
      ValidatorForm.addValidationRule("max Limit", (value) => {
        if (repeatTime > 10000) {
          return false;
        } else {
          return true;
        }
      })
    }
  }, [repeatTime]);

  const handleChange = (e) => {
    let { name, value } = e;
    if (value !== null) {
      setLimitDate({
        ...limitDate,
        [name]: moment(new Date(value)).format("YYYY-MM-DD"),
      });
    } else {
      setLimitDate({ ...limitDate, [name]: "" });
    }
  };

  useEffect(() => {
    dispatch(apiIntegrationAction.wishlistDomain_update(wishlistUrl));
    dispatch(apiIntegrationAction.startDate_update(limitDate.startDate));
    dispatch(apiIntegrationAction.endDate_update(limitDate.endDate));
    dispatch(apiIntegrationAction.limits_per_update(repeatPeriod));
    dispatch(apiIntegrationAction.limits_update(repeatTime));
  }, [wishlistUrl, limitDate, repeatTime, repeatPeriod]);

  const handleCancel = () => {
    // setExitDialog(true)
    dispatch(apiIntegrationAction.selectedSurveys([]));
    dispatch(apiIntegrationAction.selectedDemographics([]));
    dispatch(apiIntegrationAction.wishlistDomain_update(""));
    dispatch(apiIntegrationAction.startDate_update(""));
    dispatch(apiIntegrationAction.endDate_update(""));
    dispatch(apiIntegrationAction.limits_per_update(""));
    dispatch(apiIntegrationAction.limits_update(""));
    history.push({ pathname: "/surveys" });
  };

  const handleSubmit = () => {
    dispatch(apiIntegrationAction.generateToken(internal_api_config));
  };

  const OpenTokenDialog = () =>{
    if(token){
    dispatch(apiIntegrationAction.dialogOpen()) 
    }
  }

  const handleClose = () => {
    setExitDialog(false);
  };
  return (
    <ValidatorForm
      id="generateTokenForm"
      name="generateTokenForm"
      useref="generateTokenForm"
      autoComplete="off"
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
      className={`width100`}
    >
      <Grid
        container
        xs={12}
        mb={15}
        className={`${classes.dashBoardInformation}`}
      >
        <Grid item md={12}>
          <Grid item md={8}>
            <Box mb={3.75} md={8}>
              <TypographyComponent variant="h6">
                {
                  internalAPIConstants.GENERATE_TOKEN_PANEL
                    .DOMAIN_WISHLIST_LABEL
                }
              </TypographyComponent>
              <TextFieldComponent
                id={"domain_wishlist"}
                className="height36"
                name={"domainWishlist"}
                value={wishlistUrl}
                margin={"dense"}
                handleChange={(e) => setWishListUrl(e.value)}
                validators={["required"]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={`${classes.innerBgPanel}`}>
            <Box className={`${classes.simpleFlex} spacebetween`} mb={4.375}>
              <TypographyComponent
                title={
                  internalAPIConstants.GENERATE_TOKEN_PANEL.API_HIT_LIMIT_PANEL
                    .LABEL
                }
                variant={"h5"}
                className={`txtMedium ${classes.simpleFlex}`}
              >
                <InformationComponent>
                  {
                    internalAPIConstants.GENERATE_TOKEN_PANEL
                      .API_HIT_LIMIT_PANEL.LABEL_HELP
                  }
                </InformationComponent>
              </TypographyComponent>
              <TypographyComponent variant="h6" className="txtgrey">
                Copy API key
                <IconButton
                  className={`${classes.anonymousCopyIcon} p-1 `}
                  size="large"
                  onClick={OpenTokenDialog}
                >
                  <SvgIcon className={classes.iconContainer}>
                    {CopySetting()}
                  </SvgIcon>
                </IconButton>
              </TypographyComponent>
            </Box>
            <Grid container spacing={6} mb={3.75}>
              <Grid item xs={2.5}>
                <TypographyComponent
                  title={
                    internalAPIConstants.GENERATE_TOKEN_PANEL
                      .API_HIT_LIMIT_PANEL.START_DATE_LABEL
                  }
                  variant={"body2"}
                  mb={1}
                  className={`txtMedium ${classes.simpleFlex}`}
                ></TypographyComponent>
                <DatePickerComponent
                  name={"startDate"}
                  value={limitDate.startDate}
                  className={`${classes.datePicker}`}
                  handleChange={(e) => handleChange(e)}
                  inputVariant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  disabled={false}
                  minDate={new Date()}
                  validators={["required","pastDateStartDate"]}
                  errorMessages={["required", "Start Date must be in future"]}
                />
              </Grid>
              <Grid item xs={2.5}>
                <TypographyComponent
                  title={
                    internalAPIConstants.GENERATE_TOKEN_PANEL
                      .API_HIT_LIMIT_PANEL.END_DATE_LABEL
                  }
                  variant={"body2"}
                  mb={1}
                  className={`txtMedium ${classes.simpleFlex}`}
                >
                  <InformationComponent>
                    {
                      internalAPIConstants.GENERATE_TOKEN_PANEL
                        .API_HIT_LIMIT_PANEL.END_DATE_LABEL_INFO
                    }
                  </InformationComponent>
                </TypographyComponent>
                <DatePickerComponent
                  name={"endDate"}
                  value={limitDate.endDate}
                  className={`${classes.datePicker}`}
                  handleChange={(e) => handleChange(e)}
                  inputVariant="outlined"
                  margin="dense"
                  size="small"
                  fullWidth={true}
                  disabled={false}
                  minDate={new Date()}
                  validators={["required", "isEnddategreater"]}
                  errorMessages={[
                    "required",
                    "End Date must be greater than start date",
                    "End Date must be in future",
                  ]}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs={2.5}>
                <TypographyComponent
                  title={
                    internalAPIConstants.GENERATE_TOKEN_PANEL
                      .API_HIT_LIMIT_PANEL.REPEAT_LABEL
                  }
                  variant={"body2"}
                  mb={1}
                  className={`txtMedium ${classes.simpleFlex}`}
                ></TypographyComponent>
                <Box className={`${classes.repeatBlock}`}>
                  <TextFieldComponent
                    className={`rtnoborder`}
                    name={"repeatPeriod"}
                    value={repeatTime}
                    margin={"dense"}
                    maxLength={4}
                    handleChange={(e) => SetRepeatTime(e.value)}
                    validators={["required","max Limit"]}
                    errorMessages={["Maximum limit 10000"]}
                  />
                  <AutoCompleteComponent
                    name="repeatType"
                    suggestionType="array"
                    suggestions={
                      internalAPIConstants.GENERATE_TOKEN_PANEL
                        .API_HIT_LIMIT_PANEL.REPEAT_DROPDOWN
                    }
                    handleAutoCompolete={(e) => SetRepeatPeriod(e)}
                    value={repeatPeriod.charAt(0).toUpperCase() +  repeatPeriod.slice(1)}
                    className={`ltnoborder`}
                  ></AutoCompleteComponent>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          className={`${classes.configFooterBtn}`}
        >
          <Grid item>
            <ButtonComponent
              title={"Generate Token"}
              color={"primary"}
              size={"small"}
              type={"submit"}
              pageClassName="mr-1"
            ></ButtonComponent>
            <ButtonComponent
              title={"Cancel"}
              color={"default"}
              size={"small"}
              type={"button"}
              handleClick={handleCancel}
            ></ButtonComponent>
          </Grid>
        </Grid>
      </Grid>
      <TokenDialog classes={classes} />
      <ExitDialogInapi exitDialog = {exitDialog} handleClose={handleClose} />
    </ValidatorForm>
  );
};
// default props
GenereateTokenPanel.defaultProps = {
  classes: {},
  match: {},
  location: {},
};

// prop types
GenereateTokenPanel.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};
export default withStyles(APIIntegrationStyles)(GenereateTokenPanel);
