import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from '@mui/styles/withStyles';

// Styles
import MEStyles from "../styles/MEStyles";

// Import History
import history from "../../../../../config/history";

// Import Table Size
import { getTableHeight, getTableRowSize } from "../../../../../config/layout";

// Import Component
import TableComponent from "../../../../../components/TableComponent/TableComponent";
import TableSelectButton, { getUpdatedParticipants } from "./TableSelectButton";
import ConfirmDialogComponent from "../../../../../components/ConfirmDialogComponent/ConfirmDialogComponent";
import DialogComponent from "../../../../../components/DialogComponent/DialogComponent";

// Import Actions
import { meSurveyAction, alertAction } from "../../../../../redux/actions";

// Import helper
import { getQueryVariable } from "../../../../../helpers";

const customFilterComponent = {
  selected: TableSelectButton
}

const componentMap = {
  selected: TableSelectButton
}

const MESubjectsList = (props) => {

  const [popup, setPopup] = useState(null)
  const [infoPopup, setInfoPopup] = useState(null)

  // Define Dispatch
  const dispatch = useDispatch();

  const { assign, selectedParticipants,
    dataFilter: { filterChanged },
    all_ids, response_ids } = useSelector(state => state.managerEffectiveness);
  const { data, page } = assign;

  // Assign Props
  const { match, survey_id, editDisabled } = props;

  // Table size
  const tableHeight = getTableHeight(196 + 56);
  const tableRowSize = getTableRowSize(196 + 56);

  // Get Page Reset 
  let isPageReset = getQueryVariable(props.location.search, "reset") === "true";

  /**
   * Get ME Subjects List
   */
  useEffect(() => {
    if (!isPageReset) {
      dispatch(meSurveyAction.subjects(1, tableRowSize, "selected", "asc", [], [], survey_id));
      isPageReset = true
    }
  }, [dispatch]);

  /**
   * when data filters are changed
   */
  useEffect(() => {
    if (filterChanged) {
      dispatch(meSurveyAction.subjects(1, tableRowSize, "selected", "asc", [], [], survey_id));
    }
  }, [filterChanged]);


  /**
    * Handle Table Navigation Event
    */
  const handleNavigateAction = (id) => {
    history.push({
      pathname: `${match.url}/assign/${id}`
    });
  };

  /**
     * Handle Table pagination Event
     */
  const onChangePagination = (skip, limit) => {
    dispatch(meSurveyAction.subjects(skip, limit, page.sortBy, page.sort, page.sFields, page.sValues, survey_id));
  };

  /**
   * Handle Table Sort Request Event
   */
  const onSortRequest = (sortBy, sort) => {
    dispatch(meSurveyAction.subjects(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues, survey_id));
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey, userChange) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if (!isPageReset) {
      dispatch(meSurveyAction.subjects(1, tableRowSize,
        page.sortBy, page.sort, sFields, sValues, survey_id));
    }
  };

  /**
   * Clear Search Text By Field
   * @param {*} searchField
   * @param {*} searchKey
   */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(meSurveyAction.subjects(1, tableRowSize, page.sortBy, page.sort,
      sFields, sValues, survey_id));
  };

  /**
  * Handle Field Component On Change Event
  */
  const fieldComponentOnChange = (field, selData, value) => {
    switch (field) {
      case 'selected':
        if (selData === "header") {
          setPopup({
            text: value ? "Select all subjects?" : "Unselect all subjects?",
            value: value,
            field,
            selData,
            yesCallBack: value ? confirmSelectAllSubjects : confirmUnSelectAllSubjects,
            cancelCallBack: value ? cancelSelectAllSubjects : cancelUnSelectAllSubjects
          })
        } else {
          dispatch(meSurveyAction.updateSelectedParticipants(
            getUpdatedParticipants({
              value, selData, selectedParticipants, data, count: page.count,
              all_ids,
              response_ids
            })
          ))
        }
        break;
      default:
    }
  };

  /**
   * select all confirm ok popup
   */
  const confirmSelectAllSubjects = ({ value, selData }) => {
    dispatch(meSurveyAction.updateSelectedParticipants(
      getUpdatedParticipants({
        value, selData, selectedParticipants, data, count: page.count,
        all_ids,
        response_ids
      })
    ))
    setPopup(null)
  }

  /**
   * unselect all confirm ok popup
   */
  const confirmUnSelectAllSubjects = () => {
    setInfoPopup({
      text: "Subjects with responses will not be unselected.",
      callback: () => {
        dispatch(meSurveyAction.updateSelectedParticipants(response_ids))
        setInfoPopup(null)
      }
    })
    setPopup(null)
  }

  /**
   * select all cancel ok popup
   */
  const cancelSelectAllSubjects = () => {
    setPopup(null)
  }

  /**
   * unselect all cancel ok popup
   */
  const cancelUnSelectAllSubjects = () => {
    setPopup(null)
  }

  return (
    <>
      <TableComponent
        data={data}
        header={page.fieldFilters.headerCols}
        field={page.fieldFilters.fieldCols}
        search={page.fieldFilters.searchCols}
        height={tableHeight}
        page={page.skip}
        count={page.count}
        rowsPerPage={page.limit}
        links={["name"]}
        sort={page.sort}
        sortBy={page.sortBy}
        sFields={page.sFields}
        sValues={page.sValues}
        noDataErrorMessage={"No Records Found"}
        paginationVisible={!page.lastPage}
        handleNavigateAction={handleNavigateAction}
        onChangePagination={onChangePagination}
        onSortRequest={onSortRequest}
        onInputChangeRequest={onInputChangeRequest}
        handleClearSearchByField={handleClearSearchByField}
        customFilterComponent={customFilterComponent}
        componentMap={componentMap}
        fieldComponentOnChange={fieldComponentOnChange}
        selectedRows={selectedParticipants}
        componentProps={{
          editDisabled
        }}
        resetSearchText={page.sFields.length === 0 || isPageReset}
      />
      {
        popup !== null && (
          <ConfirmDialogComponent
            className="no-title custom-popup"
            open={popup !== null}
            content={popup.text}
            handleConfirmAction={() => {
              popup.yesCallBack({ ...popup })
            }}
            handleNoAction={() => {
              popup.cancelCallBack({ ...popup })
            }}
            handleCloseAction={() => setPopup(null)}
            textTrueBtn={"Ok"}
            textFalseBtn={"Cancel"}
          />
        )
      }
      {
        infoPopup !== null && (
          <DialogComponent
            info
            className={"custom-popup"}
            open={infoPopup !== null}
            btnText={"Ok"}
            content={infoPopup.text}
            handleClick={() => {
              infoPopup.callback()
            }}
          />
        )
      }
    </>
  )
}

export default withStyles(MEStyles)(MESubjectsList);