import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import DemographicsSetup from "./DemographicsSetup";
import ConversionRoute from "./DemographicsPanels/Conversion/ConversionUpload/Conversion.Router";

function DemographicsRouter (props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url} render={(props) => {
        return <DemographicsSetup
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/conversion`} render={(props) => {
        return <ConversionRoute
          match={props.match}
          {...props} />;
      }} />
    </Switch>
  );
}

DemographicsRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

export default DemographicsRouter;
