/* eslint-disable prefer-const */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";

// import Constants
import { surveyConstant } from "../../../../redux/constants";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

function MoveQuestionsToCategory (props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // Get Props Values
  const { questionData, question, anchorEl } = props;
  const { catOrder, questions } = questionData;

  // Handel Category Click
  const handleClick = (e) => {
    if (questions.indexOf(question) !== -1) {
      questions.splice(questions.indexOf(question), 1);
      question.category = e.target.innerText;
      questions.push(question);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_MOVE_CATEGORY_REDUCER_UPDATE, questions));
    }

    props.handelCloseMoveQuesToCat(null);
  };

  /**
   * Generate Categories
   * @param {*} question
   */
  let generateCategories = (question) => {
    return catOrder.map((item, index) => {
      if (item !== question.category) {
        return <MenuItem key={`questionCat-${index}`} onClick={(e) => handleClick(e)} >{item}</MenuItem>;
      }
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "move_ques_to_category" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => { props.handelCloseMoveQuesToCat(null); }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      {generateCategories(question)}
    </Popover>
  );
}

// default props
MoveQuestionsToCategory.defaultProps = {
  classes: {},
  questionData: [],
  question: {},
  anchorEl: null,
  questionIndex: 0,
  handelCloseMoveQuesToCat: () => { }
};

// prop types
MoveQuestionsToCategory.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  question: PropTypes.object,
  anchorEl: PropTypes.any,
  questionIndex: PropTypes.number,
  handelCloseMoveQuesToCat: PropTypes.func
};

export default MoveQuestionsToCategory;
