import { apiIntegrationConstants } from "../constants";

// set Initial State
export const initialState = {
    internalAPI: {
        allSurveys: [],
        apiHit: "",
        domainUrl: "",
        startDate: "",
        endDate: "",
        limitsPer: "Day",
        selectedSurveyIds: [],
        responseDemographics: [],
        token:"",
        dialog: false,
        tokenFetch: true
    }
};

export default function (state = initialState, action) {
    switch (action.type) {
        // Get ALL Survey 
        case apiIntegrationConstants.GET_ALL_SURVEY_REQUEST:
            return {
                ...state
            };
        case apiIntegrationConstants.GET_ALL_SURVEYS_SUCCESS:
            return {
                ...state,
                internalAPI: { ...state.internalAPI, allSurveys: action.data}
            };
        case apiIntegrationConstants.GET_ALL_SURVEYS_FAILURE:
            return {
                ...state
            };
        // Get Previous Token 
        case apiIntegrationConstants.GET_PREV_TOKEN_REQUEST:
            return {
                ...state
            };
        case apiIntegrationConstants.GET_PREV_TOKEN_SUCCESS:
            return {
                ...state,
                internalAPI: { ...state.internalAPI, 
                                apiHit: action.data.apiHit ,
                                domainUrl:action.data.domainUrl,
                                endDate:action.data.endDate,
                                limitsPer:action.data.limitsPer,
                                responseDemographics:action.data.responseDemographics,
                                selectedSurveyIds:action.data.selectedSurveyIds,
                                startDate:action.data.startDate,
                                token:action.data.token , 
                                tokenFetch:false
                            }
            };
        case apiIntegrationConstants.GET_PREV_TOKEN_FAILURE:
            return {
                ...state
            };

        case apiIntegrationConstants.REMOVE_TOKEN:
            return {
                ...state,
                internalAPI: { 
                    ...state.internalAPI, 
                    token:"", 
                    tokenFetch:false
                }
            };
        case apiIntegrationConstants.PUT_SURVEY_IDS_REQUEST:
            return { ...state };

        case apiIntegrationConstants.PUT_SURVEY_IDS_SUCCESS:
            return {
                ...state,
                internalAPI: { ...state.internalAPI, selectedSurveyIds: action.params }
            };

        case apiIntegrationConstants.PUT_SURVEY_IDS_FAILURE:
            return { ...state };

        case apiIntegrationConstants.PUT_DEMOGRPAHICS_REQUEST:
            return {
                ...state,
                internalAPI: { ...state.internalAPI, responseDemographics: action.params }
            };
        
        case apiIntegrationConstants.PUT_WISHLIST_URL_REQUEST:
            return {
                ...state,
                internalAPI: { ...state.internalAPI, domainUrl: action.params }
            };
            
        case apiIntegrationConstants.PUT_START_DATE_REQUEST:
            return {
                ...state,
                internalAPI: { ...state.internalAPI, startDate: action.params }
            };

        
        case apiIntegrationConstants.PUT_END_DATE_REQUEST:
                return {
                    ...state,
                    internalAPI: { ...state.internalAPI, endDate: action.params }
             };

        case apiIntegrationConstants.PUT_LIMIT_PER_REQUEST:
                return {
                    ...state,
                    internalAPI: { ...state.internalAPI, limitsPer: action.params }
             };
        
        case apiIntegrationConstants.PUT_LIMIT_REQUEST:
                return {
                    ...state,
                    internalAPI: { ...state.internalAPI, apiHit: action.params }
             };
        case apiIntegrationConstants.POST_GENERATE_TOKEN_REQUEST:
            return {
                ...state
            };
        case apiIntegrationConstants.POST_GENERATE_TOKEN_SUCCESS:
            return {
                ...state,
                internalAPI: { ...state.internalAPI, token: action.data.data , dialog:true}
            };
        case apiIntegrationConstants.POST_GENERATE_TOKEN_FAILURE:
            return {
                ...state
                };
        case apiIntegrationConstants.SHOW_TOKEN_REQUEST:
            return {
                internalAPI: { ...state.internalAPI, dialog:false}
                };
        case apiIntegrationConstants.SHOW_TOKEN_REQUEST_SUCCESS:
            return {
                internalAPI: { ...state.internalAPI, dialog:true}
                };
        default:
            return state;
    }
}