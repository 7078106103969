import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import lodash from "lodash";
import moment from "moment";

// Styles
import MEStyles from "./styles/MEStyles";

// Import Styles and Image
// import { BackPage } from "../../../../assets/images/svgComponents/ImageComponent";

// Import History
import history from "../../../../config/history";

// Import Containers
import Container from "../../../Containers/Container";

// import constants
import { meSurveyConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import ProgressPageComponent from "../../../../components/ProgressPageComponent/ProgressPageComponent";
import SuccessPageComponent from "../../../../components/SuccessPageComponent/SuccessPageComponent";
import ConfirmDialogComponent from "../../../../components/ConfirmDialogComponent/ConfirmDialogComponent";

// Import ME Tabs
import METab from './Tabs/MESurveyAssign.Tab'

// Tab Router
import MESurveyAssignRouter from './Tabs/MESurveyAssign.Router'
import { meSurveyAction, alertAction } from "../../../../redux/actions";

const MESurveyAssign = (props) => {

  const { classes, match, location } = props;
  const { params } = match;
  const { survey_id } = params;
  const dispatch = useDispatch();

  const [popup, setPopup] = useState(null)
  const { survey,
    assign: { page: { count } }, selectedParticipants, selectedSubjectsWithEmail,
    dup_selectedParticipants,
    process_status: { isLoading, status,
      _id: process_id, response,
      fileName, cdt }, dupGroupedEmails } = useSelector(state => state.managerEffectiveness)
  const { name: surveyName, status: surveyStatus } = survey || {}

  const isImportScreen = location.pathname.indexOf("import") === -1
  const isRaterScreen = location.pathname.indexOf("raters") >= 0;
  const editDisabled = surveyStatus === "Closed"
  const diff_left = lodash.difference(selectedParticipants, dup_selectedParticipants).filter(f => f !== "all")
  const diff_right = lodash.difference(dup_selectedParticipants, selectedParticipants).filter(f => f !== "all")
  const hasDiff = diff_left.length > 0 || diff_right.length > 0

  const goBack = () => {
    if (!isImportScreen) {
      history.push(`/manage_people/participants/me-survey/assign/${survey_id}/raters`)
    } else {
      history.push(`/manage_people/participants/me-survey`)
    }
  }

  const BackPage = (props) =>
  (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.254" height="10.646" viewBox="0 0 6.254 14.646"><defs>
    </defs><g transform="translate(-101.48)"><g transform="translate(101.48 0)">
      <path fill="#c0c1c2" class="a" d="M103.582,5.325l3.983-3.983a.584.584,0,0,0,0-.823L107.215.17a.583.583,0,0,0-.824,0L101.65,4.912a.588.588,0,0,0,0,.826l4.737,4.737a.583.583,0,0,0,.824,0l.349-.349a.583.583,0,0,0,0-.824Z" transform="translate(-101.48 0)" /></g></g></svg>
  )

  const confirmGoBack = (btnname) => {

    if (btnname === "goback" && hasDiff) {
      setPopup({ text: "Do you want to save changes before exiting the subject/rater setup?" })
    }
    else if (hasDiff) {
      setPopup({ text: "You are about to exit the survey and will lose any changes made. Do you want to save your changes?" })
    } else {
      goBack()
    }
  }

  const confirmSave = () => {
    if (Object.keys(dupGroupedEmails).length > 0) {
      if (selectedParticipants.length === 1) {
        dispatch(meSurveyAction.saveOrUpdate(survey_id));
      } else {
        const selectedSubjects = selectedParticipants.reduce((results, item) => {
          let splitSub = item.split('_to_');
          if (splitSub[0] === splitSub[1]) results.push(splitSub[0])
          return results
        }, [])

        const selSubGroupEmail = selectedSubjectsWithEmail.filter(sub => selectedSubjects.indexOf(sub._id) > -1).reduce((acc, curr) => {
          if (curr.email in dupGroupedEmails) {
            acc[curr.email] = acc[curr.email] === undefined ? [curr._id] : [...acc[curr.email], curr._id]
          }
          return acc
        }, {});

        let conditionFlag = Object.keys(selSubGroupEmail).find(selSubEmail => (selSubGroupEmail[selSubEmail].length > 1));
        if (conditionFlag) {
          dispatch(alertAction.error(meSurveyConstants.SUBJECT_SAME_EMAIL))
        } else {
          dispatch(meSurveyAction.saveOrUpdate(survey_id));
        }
      }
    } else {
      dispatch(meSurveyAction.saveOrUpdate(survey_id));
    }
  }

  const getSubjectAndRatersCountText = () => {
    const total_subjects = selectedParticipants.reduce((a, b) => {
      const ids = b.split("_to_")
      if (ids[0] === ids[1]) a++
      return a
    }, 0)
    if (!isImportScreen) {
      return ""
    } else if (location.pathname.indexOf('raters') > -1) {
      return ` (${total_subjects} Subjects / ${(count || 0)} Raters)`
    } else {
      return ` (${total_subjects} Subjects)`
    }
  }

  useEffect(() => {
    dispatch(meSurveyAction.getProcessStatus(survey_id));
  }, [dispatch])

  // render process style component
  if (isLoading || status !== undefined) {
    const status_type = fileName ? "_FILE" : ""
    const sw_type = `${status}${status_type}`
    switch (sw_type) {
      case "DONE_FILE":
        response.errRecords = (response.errorDocs || []).length
        return (
          <SuccessPageComponent
            pageName={"ME Participant upload"}
            redirectURL={goBack}
            uploadResult={{
              ...response,
              cdt: moment(cdt).format("MM/DD/YYYY"),
              fileName
            }}
            classes={classes}
            content={meSurveyConstants[`${status}${status_type}`] ? meSurveyConstants[`${status}${status_type}`]({
              ...response,
              cdt: moment(cdt).format("MM/DD/YYYY"),
              fileName
            }) : "Loading..."}
            title={survey ? survey.name || "" : ""}
            needButton={status !== "PENDING" && status !== undefined}
            onDone={() => {
              dispatch(meSurveyAction.updateSeenStatus(survey_id, process_id, { notified: true, isSeen: true }));
              dispatch(meSurveyAction.resetAssignData())
              history.push(`/manage_people/participants/me-survey/assign/${survey_id}/raters`)
            }}
          />
        )
      default:
        return (
          <ProgressPageComponent
            redirectURL={goBack}
            uploadResult={response}
            classes={classes}
            content={meSurveyConstants[`${status}${status_type}`] ? meSurveyConstants[`${status}${status_type}`]({
              ...response,
              cdt: moment(cdt).format("MM/DD/YYYY"),
              fileName
            }) : "Loading..."}
            title={survey ? survey.name || "" : ""}
            needButton={status !== "PENDING" && status !== undefined}
            onButtonClick={() => {
              dispatch(meSurveyAction.updateSeenStatus(survey_id, process_id, { notified: true, isSeen: true }));
              dispatch(meSurveyAction.resetAssignData())
              history.push(`/manage_people/participants/me-survey/assign/${survey_id}/raters`)
            }}
          />
        )
    }
  }

  return (
    <Container align={"baseline"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                {
                  !isRaterScreen && (
                    <Grid onClick={() => confirmGoBack("goback")} className={classes.backimg}>
                      {BackPage()}
                    </Grid>
                  )
                }
                <TypographyComponent
                  variant={"h3"}
                  className="ml-1">
                  {surveyName || ""}
                  {getSubjectAndRatersCountText()}
                </TypographyComponent>
              </Grid>
            </Grid>
            {
              !isRaterScreen && isImportScreen && !editDisabled && (
                <Grid item>
                  <ButtonComponent
                    title={meSurveyConstants.ME_PARTICIPANTS_ASSIGN_SAVE}
                    color={"primary"}
                    size={"small"}
                    type='submit'
                    pageClassName="mr-1"
                    handleClick={confirmSave}
                    disabled={!hasDiff}
                  />
                  <ButtonComponent
                    title={meSurveyConstants.ME_PARTICIPANTS_ASSIGN_CANCEL}
                    // color={"secondary"}
                    size={"small"}
                    type={"button"}
                    className={classes.cancelBtn}
                    handleClick={confirmGoBack}
                  />
                </Grid>
              )
            }
          </Grid>
        </Grid>
        {
          isImportScreen && (
            <Grid item xs={12} className="me-surveys-tabs" style={{ paddingBottom: '0px' }}>
              <METab {...props} survey_id={survey_id} editDisabled={editDisabled} />
            </Grid>
          )
        }
        <Grid item xs={12} className="me-surveys-tab-container" style={{ paddingTop: '0px' }}>
          <MESurveyAssignRouter {...props} survey_id={survey_id} editDisabled={editDisabled} />
        </Grid>
      </Grid>
      {
        popup !== null && (
          <ConfirmDialogComponent
            className="no-title custom-popup"
            open={popup !== null}
            content={popup.text}
            handleConfirmAction={() => {
              confirmSave()
            }}
            handleNoAction={() => {
              goBack()
            }}
            handleCloseAction={() => setPopup(null)}
            textTrueBtn={"Yes"}
            textNoBtn={"No"}
            textFalseBtn={"Cancel"}
          />
        )
      }
    </Container>
  )
}

export default withStyles(MEStyles)(MESurveyAssign);