import * as React from "react";
import { CircularProgress, TextField } from "@mui/material";
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}

function Asynchronous({
  apiCallback,
  required,
  matchBy,
  display,
  label,
  value,
  handleChange,
  name,
  initialValue,
  optionType,
  className,
  extraProps
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([initialValue]);
  const [newValues, setNewValues] = React.useState([]);
  let loading = open && options.length === 0;
  const isObject = optionType === "object"

  const filter = createFilterOptions();

  React.useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      const response = await apiCallback(name, isObject ? initialValue[display] : initialValue);
      if(response.data.length === 0) {
        response.data.push("No Data Found")
      }
      if (active) {
        setOptions([...response.data, ...newValues]);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onChangeHandle = async (value) => {
    if (value.length > 3) {
      const response = await apiCallback(name, value);
      setOptions([...response.data, ...newValues]);
    }
  };

  return (
    <Autocomplete
      open={open}
      value={value}
      name={name}
      className={className}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) =>
        isObject ? option[matchBy] === value[matchBy] : option === value
      }
      getOptionDisabled={option => option === "No Data Found"}
      getOptionLabel={(option) => {
        if (isObject) {
          if (value && value[matchBy]) {
            return option[display] || initialValue[display] || ""
          } else {
            return option[display] || ""
          }
        }
        return option
      }}
      options={options}
      loading={loading}
      onChange={(e, value, reason) => {
        if (value.indexOf('Create') >= 0 && value.indexOf('?')) {
          const n_val = value.match(/Create "(.*?)"\?/)[1]
          if (n_val) {
            setNewValues([...newValues, n_val])
            setOptions([...options, n_val])
            handleChange(name, n_val)
          }
        } else {
          handleChange(name, value)
        }
      }}
      clearOnBlur
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}
          className="custom-form-input"
          InputLabelProps={{
            required,
            shrink: true,
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          onChange={(ev) => {
            // dont fire API if the user delete or not entered anything
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChangeHandle(ev.target.value);
            }
          }}
        />
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options || [], params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = (options || []).some((option) => inputValue.toString() === option.toString());
        if (inputValue !== '' && !isExisting && filtered.length === 0) {
          filtered.push(`Create "${inputValue}"?`);
        }

        return filtered;
      }} />
  );
}

Asynchronous.defaultProps = {
  display: "value",
  matchBy: "id",
  required: false,
  label: "",
  handleChange: () => { },
  name: "async",
  options: [],
  optionType: "object"
};

export default Asynchronous;
