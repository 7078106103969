import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { ValidatorForm } from "react-material-ui-form-validator";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../SurveyPreviewStyles";
import CloseIcon from "@mui/icons-material/Close";

//import constants
import { TakeSurveyConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";

//Import Send Test Email Component
import PreviewSendMail from "./PreviewSendMail";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";

// Import Actions
import { surveyPreviewAction } from "../../../../redux/actions";

function PreviewFilter(props) {
  // get props
  const { classes, langCode } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  const surveyId = useSelector((state) => state.survey.previewSurveyId);
  const questionData = useSelector((state) => state.surveyPreview.questions);
  const clientData = useSelector((state) => state.surveyPreview.client);
  const openSendMail = useSelector(
    (state) => state.surveyPreview.surveyMailBox
  );
  const demoGraphicFilters = useSelector(
    (state) => state.surveyPreview.demoGraphicFilters
  );
  const { languages } = questionData;
  const [activeLanguage, setActiveLanguage] = useState("en");
  useEffect(() => {
    dispatch(surveyPreviewAction.updateSurveyLanguage(activeLanguage));
  }, [ activeLanguage]);

  const handleSurveyLanguage = (lang) => {
    setActiveLanguage(lang.code);
  };

  // Tabs
  const [selectedFilters, setSelectedFilters] = useState([]);
  const handleChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;
    const fieldName = e.target.id;
    let newSelect = [];
    if (checked) {
      const nonFieldDataSet = selectedFilters.filter(
        (data) => data.field != field
      );
      newSelect = [...nonFieldDataSet, { field, value, fieldName }];

      /* 
        //multiple filter selection code
        if (value == "None of the above") {
          const nonFieldDataSet = selectedFilters.filter(
            (data) => data.field != field
          );
          newSelect = [...nonFieldDataSet, { field, value }];
        } else {
          const selectedFilterDataSet = selectedFilters.filter(
            (data) =>
              data.field != field ||
              (data.value != "None of the above" && data.field == field)
          );

          newSelect = [...selectedFilterDataSet, { field, value }];
        } 
      */
    } else {
      newSelect = selectedFilters.filter((item, key) => item.value !== value);
    }
    setSelectedFilters(newSelect);
    dispatch(surveyPreviewAction.getFilteredPreviewSurvey(newSelect));
  };

  const handleDelete = (i) => {
    const newSelect = selectedFilters.filter((item, key) => key !== i);
    setSelectedFilters(newSelect);
    dispatch(surveyPreviewAction.getFilteredPreviewSurvey(newSelect));
  };

  const handleClearAll = (e) => {
    e.preventDefault();
    const newSelect = [];
    setSelectedFilters(newSelect);
    dispatch(surveyPreviewAction.getFilteredPreviewSurvey(newSelect));
  };

  // Accordion
  const [expanded, setExpanded] = useState("default-panel");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  function handleSendMailPopup() {
    dispatch(surveyPreviewAction.handleSendMailPopup(!openSendMail));
  }

  function handlePrintSurvey() {
    dispatch(surveyPreviewAction.getPrintSurvey(surveyId));
  }

  const activeLangObject = languages.find((lang) => lang.code == langCode);
  const metaData = clientData.metadata ? clientData.metadata : [];

  return (
    <>
      <Grid container className={classes.previewFilterRoot}>
        <Grid item xs={12} className="mb-15 pt-1">
          <TypographyComponent variant={"h3"} className="txtBold">
            {TakeSurveyConstants.PREVIEW_OPTION}
          </TypographyComponent>
        </Grid>

        <Grid item xs={12} className="mb-15">
          <Grid container justifyContent="space-between">
            <ButtonComponent
              title={TakeSurveyConstants.SEND_TESTMAIL_BTN}
              color={"primary"}
              size={"small"}
              pageClassName="buttonOrange"
              handleClick={handleSendMailPopup}
            ></ButtonComponent>
            <ButtonComponent
              title={TakeSurveyConstants.PRINTSURVEY_BTN}
              color={"primary"}
              size={"small"}
              handleClick={handlePrintSurvey}
            ></ButtonComponent>
          </Grid>
        </Grid>

        {languages.length > 1 ? (
          <Grid
            item
            xs={12}
            className={`${classes.languageContainer} ${"mb-1"}`}
          >
            <Grid container className="borderRadius6 bgwhite">
              <Grid item xs={12} className="p-1 borderBottom">
                <TypographyComponent variant={"h6"}>
                  {TakeSurveyConstants.PREVIEW_LANGUAGE}
                </TypographyComponent>
              </Grid>

              <Grid item xs={12} className="p-1">
                <ValidatorForm onSubmit={() => {}}>
                  <AutoCompleteComponent
                    name={"clients"}
                    suggestions={languages}
                    handleAutoCompolete={handleSurveyLanguage}
                    value={activeLangObject}
                    placeholder={"Select Language"}
                    validators={[]}
                  ></AutoCompleteComponent>
                </ValidatorForm>
              </Grid>
            </Grid>
          </Grid>
        ) : null}

        {demoGraphicFilters.length > 0 ? (
          <Grid
            item
            xs={12}
            className={`${
              classes.demographicContainer
            } ${"mb-1 borderRadius6 bgwhite"}`}
          >
            <Grid container justifyContent="space-between" className="borderBottom">
              <Grid item className="p-1 ">
                <TypographyComponent variant={"h6"}>
                  {TakeSurveyConstants.PREVIEW_DEMOGRAPHY_FILTER}
                  <InformationComponent>
                    <TypographyComponent variant="tooltipbody">
                      {TakeSurveyConstants.PREVIEW_DEMOGRAPHY_FILTER_HELP}
                    </TypographyComponent>
                  </InformationComponent>
                </TypographyComponent>
              </Grid>
              <Grid item className="p-1 ">
                <TypographyComponent variant={"h6"}>
                  {selectedFilters.length > 0 ? (
                    <Link onClick={handleClearAll}>Clear All</Link>
                  ) : null}
                </TypographyComponent>
              </Grid>
            </Grid>

            {selectedFilters.length > 0 ? (
              <Grid container className="p-1">
                {selectedFilters.map((data, i) => (
                  <Chip
                    label={`${data.fieldName}: ${
                      data.value.length > 20
                        ? data.value.substr(0, 20) + "..."
                        : data.value
                    }`}
                    color="primary"
                    onDelete={(e) => handleDelete(i)}
                    deleteIcon={
                      <CloseIcon className={classes.previewFilterChipClose} />
                    }
                    key={i}
                  />
                ))}
              </Grid>
            ) : null}

            <Grid container>
              {demoGraphicFilters.map((filter, key) => {
                const isNoneSelect = selectedFilters.find(
                  (data) =>
                    data.field == filter.field &&
                    data.value == "None of the above"
                );

                const fieldMeta = metaData.find(
                  (data, i) => data.key === filter.field
                );
                return (
                  <Accordion
                    classes={{
                      root: classes.peaccordion_nocolor,
                    }}
                    expanded={expanded === key}
                    onChange={handlePanelChange(key)}
                    key={key}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <TypographyComponent
                        variant={"h6"}
                        className={`${"txtMedium "} ${
                          expanded === "default-panel" ? "panel-active" : ""
                        } `}
                      >
                        {fieldMeta && fieldMeta.value
                          ? fieldMeta.value
                          : filter.field}
                      </TypographyComponent>
                    </AccordionSummary>

                    <AccordionDetails>
                      <Grid
                        item
                        xs={12}
                        className={`${classes.filterItems} ${"scrolly"}`}
                      >
                        {filter.value.map((val, ind) => {
                          const isSelect = selectedFilters.find(
                            (data) =>
                              data.field == filter.field && data.value == val
                          );
                          return (
                            <FormControlLabel
                              className={`filterItem pl5`}
                              xs={12}
                              control={
                                <Checkbox
                                  checked={
                                    isSelect && isSelect.value == val
                                      ? true
                                      : false
                                  }
                                  onChange={handleChange}
                                  name={filter.field}
                                  value={val}
                                  id={
                                    fieldMeta && fieldMeta.value
                                      ? fieldMeta.value
                                      : filter.field
                                  }
                                  color="primary"
                                />
                              }
                              label={val}
                              key={ind}
                            />
                          );
                        })}

                        {/* None Of the About  */}
                        <FormControlLabel
                          className={`${classes.filterOption} pl5`}
                          xs={12}
                          control={
                            <Checkbox
                              checked={
                                isNoneSelect &&
                                isNoneSelect.value == "None of the above"
                                  ? true
                                  : false
                              }
                              onChange={handleChange}
                              name={filter.field}
                              value="None of the above"
                              id={
                                fieldMeta && fieldMeta.value
                                  ? fieldMeta.value
                                  : filter.field
                              }
                              color="primary"
                            />
                          }
                          label="None of the above"
                          key={filter.field + "_none"}
                        />
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <DialogComponent
        fullScreen={false}
        open={openSendMail}
        disableBackdropClick={true}
      >
        <PreviewSendMail closePreviewSendMail={handleSendMailPopup} />
      </DialogComponent>
    </>
  );
}

// default props
PreviewFilter.defaultProps = {
  classes: {},
  langCode: "en",
};

// prop types
PreviewFilter.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
};
export default withStyles(SurveyPreviewStyles)(PreviewFilter);
