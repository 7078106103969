/* eslint-disable react/jsx-key */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


// Import Icons
import { SettingAccordGrey, SettingAccordWhite } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Constants
import { participantConstants } from "../../../../config/constants";

//Import Styles
import DemographicsStyles from "../DemographicsStyles";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
import ConversionPopup from "./Conversion/ConversionPopup";
import withStyles from '@mui/styles/withStyles';
import { clientAction } from "../../../../redux/actions";
import { ValidatorForm } from "react-material-ui-form-validator";

function AutomatedDemographicsPanel(props) {
  // Assign Props
  const { classes, handleChange, metadata,externalApiAccess, metadataWithRGI,handleApiKeyChange } = props;
  const dispatch = useDispatch();
  const enable = useSelector((state) => state.client.getOne.managerEffectiveness);
  
  const [gridSize, setGridSize] = React.useState(4)

   useEffect(()=>{
      if(externalApiAccess){
        setGridSize(3)
      }else{
        setGridSize(4)
      }
    },[externalApiAccess])

  /**
   * Fetch Client Data
   */
  useEffect(() => {
    dispatch(clientAction.getOne());
  }, [dispatch]);

  // Accordion
  const [expanded, setExpanded] = React.useState("automated-panel");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  //conversion items for aautomated demographics
  const conversionItems = ["generation", "tenure_groupings", "age_groupings"]

  useEffect(()=>{
    ValidatorForm.addValidationRule("Obj_key_valid", (value) => {
      if (/\s/.test(value)) {
        return false
      } else {
        return true
      }
    });
  },[metadata])

  return (
    <Accordion className="mb-2 pe-accordion"
      expanded={expanded === "automated-panel"}
      onChange={handlePanelChange("automated-panel")}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        {expanded === "automated-panel" ?
          <Grid className={"pr5"}>
            {SettingAccordWhite()}
          </Grid> :
          <Grid className={"pr5"}>
            {SettingAccordGrey()}
          </Grid>
        }
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${expanded === "automated-panel" ? "panel-active" : ""} `}
          variant={"h5"}>
          {participantConstants.DEMOGRAPH_AUTOMATED}
        </TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "automated-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{participantConstants.DEMOGRAPH_AUTOMATED_HELP}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className="p-2 width100">
          <Grid container spacing={3} className={`${classes.setting_expandcontainer}`}>
            <Grid item sm={12}>
              <Grid container className="pb-15">
                <Grid item sm={gridSize} className="pl-15">
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_SYSTEM_NAME}
                    variant={"h6"}
                    className="txtMedium"
                  >
                  </TypographyComponent>
                </Grid>
                <Grid item sm={gridSize} className="pl-15">
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_CUSTOM_NAME}
                    variant={"h6"}
                    className="txtMedium"
                  >
                    <InformationComponent>{participantConstants.DEMOGRAPHY_CUSTOM_NAME_HELP}</InformationComponent>
                  </TypographyComponent>
                </Grid>
                {externalApiAccess ?
                <Grid item sm={gridSize} className="pl-15">
                  <TypographyComponent
                    title={participantConstants.API_CUSTOM_NAME}
                    variant={"h6"}
                    className="txtMedium"
                  >
                    <InformationComponent>{participantConstants.DEMOGRAPHY_API_KEY_NAME_HELP}</InformationComponent>
                  </TypographyComponent>
                </Grid>
                :""}
                <Grid item sm={gridSize} className="pl-15">
                  <TypographyComponent
                    title={participantConstants.DEMOGRAPHY_DISPLAYIN_REPORT}
                    variant={"h6"}
                    className="txtMedium"
                  >
                  </TypographyComponent>
                </Grid>
                <Grid item sm={2} className="pl-15">
                </Grid>
              </Grid>
              {metadata && metadata.filter(data => data.type === "auto")
                .map((item, index) => (
                  <Grid container alignItems="center" className={`${"mb-1"} ${classes.Demography_list}`}>
                    <Grid item sm={gridSize} className={"dflex"}>
                      <TypographyComponent
                        title={item.dispName ? item.dispName : item.key}
                        variant={"h6"}
                      >
                      </TypographyComponent>
                      {item.key === "manager_id" &&
                        <InformationComponent>{enable === true ? participantConstants.AUTOMATED_MANAGERID_HELP : "360+ Manager Effectiveness toggle has to be enabled for Manager ID demographic"}</InformationComponent>
                      }
                      {item.key === "manager_role" &&
                        <InformationComponent>{enable === true ? participantConstants.AUTOMATED_MANAGERROLE_HELP : "360+ Manager Effectiveness toggle has to be enabled for Manager Role demographic"}</InformationComponent>
                      }
                    </Grid>
                    <Grid item sm={gridSize}>
                      <TextFieldComponent
                        name={item.key}
                        id={item.key}
                        value={item.value}
                        size={"small"}
                        margin={"none"}
                        fullWidth={false}
                        handleChange={(e) => handleChange(e)}
                        validators={item.visible ? ["required", "isSameCustomName"] : ["required"]}
                        className={`${classes.demography_input} ${classes.customName}`}
                      ></TextFieldComponent>
                    </Grid>
                    {externalApiAccess ? 
                    <Grid item sm={3}>
                      <TextFieldComponent
                        name={item.key}
                        id={item.key}
                        value={item.api_key_value}
                        size={"small"}
                        margin={"none"}
                        fullWidth={false}
                        handleChange={(e) => handleApiKeyChange(e)}
                        validators={["Obj_key_valid"]}
                        className={`${classes.demography_input} ${classes.customName}`}
                      ></TextFieldComponent>
                    </Grid>
                    :""}
                    {item.key !== "manager_id" &&
                      <Grid item sm={gridSize} className="pl-4">
                        <SwitchComponent
                          color="primary"
                          name={item.key}
                          checked={item.visibleInFilter}  
                          handleChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    }
                    {item && conversionItems.indexOf(item.key) > -1 &&
                      <Grid item sm={2} className={`${"pr5 pl5"}`}>
                        {/* <Grid container justifyContent={"flex-start"} alignItems="center">
                          <ConversionPopup
                            classes={classes}
                            metadataSelected={item}
                            metadataWithRGI={metadataWithRGI}
                            metadata={metadata}
                          />
                        </Grid> */}
                      </Grid>
                    }
                  </Grid>
                ))
              }
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

// default props
AutomatedDemographicsPanel.defaultProps = {
  classes: {},
  metadata: [],
  handleChange: () => { }
};

// prop types
AutomatedDemographicsPanel.propTypes = {
  classes: PropTypes.object,
  metadata: PropTypes.array,
  handleChange: PropTypes.func
};

export default withStyles(DemographicsStyles)(AutomatedDemographicsPanel);
