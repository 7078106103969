/* eslint-disable prefer-const */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import PoweredByGoogleComponent from "../../../../components/PoweredByGoogleComponent/PoweredByGoogleComponent";

// Import Actions
import { updateStateReducerAction, surveyAction, alertAction } from "../../../../redux/actions";

// Import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants, surveySettingsConstants, translateLanguagesList } from "../../../../config/constants";

// Import Components
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";

// Import Helpers
import { addLanguageInQuestion } from "../../../../helpers";
// import { deleteLanguageInQuestion } from "../../../../helpers"

function LanguageList(props) {

  //remove language
  const [removeLangDialog, setRemoveLangDialog] = useState({ flag: false });
  const [translationConfirmDialog, setTranslationConfirmDialog] = useState({ flag: false });

  // Get Questions Languages
  let questions = useSelector((state) => state.survey.questions);
  const { addQuestions } = useSelector((state) => state.category);
  const surveyData = useSelector((state) => state.survey);
  const { getOne } = useSelector((state) => state.client);
  const { languageTranslation } = getOne
  const { errors } = surveyData
  let languages = questions.languages;
  const activeLang_code = languages.find((curLanguage, index) => curLanguage.active === true).code
  const disableTabs = questions.questions.filter(q => q.editable).length > 0 || Object.keys(addQuestions).length > 0;

  const dispatch = useDispatch();

  // Handle Auto Complete
  const handleAutoCompolete = (e) => {
    const { name } = e;
    let updatedLang = [
      ...languages,
      languages.map(function (e) { return e.name; }).indexOf(name) !== -1 ? undefined : e
    ];
    updatedLang = updatedLang.filter((c) => { return c !== undefined; });

    questions = { ...questions, languages: updatedLang };
    const updateQuesLanguage = addLanguageInQuestion(questions);
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, updateQuesLanguage));
  };

  // handle chip delete
  const handleDelete = (language) => {
    languages = languages.filter((lang) => lang.code !== language.code);

    if (languages.length === 1) {
      languages = languages.map(obj => obj.code === "en" ? { ...obj, active: true } : { ...obj, active: false });
    } else if (language.active) {
      languages[languages.length - 1].active = true;
    } else { }

    questions = { ...questions, languages: languages };
    // const updateQuesLanguage = deleteLanguageInQuestion(questions)
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, questions));
    setRemoveLangDialog({ flag: false, language: "" })
  };

  const handleTranslate = () => {
    const activeLang = languages.find((curLanguage, index) => curLanguage.active === true)
    const langCode = activeLang.code;
    if (langCode && langCode !== "en") {
      let { questions, answerSetCopy, translated_history } = surveyData;
      const surveyDataObj = {
        ...questions,
        answerSet: answerSetCopy,
        translated_history: translated_history || {}
      };
      const data = { surveyData: surveyDataObj, targetLanguage: langCode }
      dispatch(surveyAction.translateSurveyQuestions(data));
      setTranslationConfirmDialog(false)
    }
  }

  // Set Selected Language
  const handleChipClick = (selectedLanguage) => () => {
    const tempLang = languages.map(obj => obj.code === selectedLanguage.code ? { ...obj, active: true } : { ...obj, active: false });
    if (!disableTabs) {
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SET_ACTIVE_LANGUAGE, tempLang));
    } else {
      dispatch(alertAction.error(surveyQuestionsConstants.SAVE_QUESTION));
    }
  };

  // Generate chips
  let generateChips = () => {

    const lang_codes = Object.keys(errors)

    return languages.map((curLanguage, index) => {
      if (curLanguage.code === "en") {
        return (
          curLanguage === null ? null : <Chip
            className={`lang_chip ${lang_codes.indexOf("en") > -1 ? "se_err" : ""}`}
            clickable
            key={index}
            color={curLanguage.active ? "primary" : "default"}
            onClick={handleChipClick(curLanguage)}
            label={curLanguage.name} />
        );
      } else {
        return (
          curLanguage === null ? null : <Chip
            className={`lang_chip ${lang_codes.indexOf(curLanguage.code) > -1 ? "se_err" : ""}`}
            clickable
            key={index}
            color={curLanguage.active ? "primary" : "default"}
            onClick={handleChipClick(curLanguage)}
            onDelete={() => { setRemoveLangDialog({ flag: true, language: curLanguage }) }}
            label={curLanguage.name} />
        );
      }
    });
  };

  return (
    <React.Fragment>
      { languageTranslation ? (
        <Grid container justifyContent="space-between" direction="column" spacing={1} className="height100">
          <Grid className="scrolly" style={{ height: "calc(100% - 140px)" }}>
            <Grid item xs={12} className="pl-15 pr-15">
              {translateLanguagesList &&
                <AutoCompleteComponent
                  name={"languageList"}
                  suggestions={translateLanguagesList}
                  value={{ name: "", code: "" }}
                  handleAutoCompolete={(e) => handleAutoCompolete(e)}
                  className={"searchinput mt7 inputbg br-none"}
                  placeholder="Search languages"
                  // clear input value text after select
                  extraProps={{
                    key: `language_Select_${new Date().getTime()}`
                  }}
                ></AutoCompleteComponent>
              }
            </Grid>
            <Grid item xs={12} className="pl-15 pr-15">
              {generateChips()}
            </Grid>
          </Grid>
          {
            languages && languages.length > 1 &&
            <Grid item className="mt-2 p5 applytranslate">
              <Grid className="dflex" justifyContent="center" alignItems="center">
                <Button variant="contained" className="buttonOrange txtwhite" disabled={activeLang_code==="en" ? true : false} disableElevation size="small" onClick={() => { setTranslationConfirmDialog({ flag: true }); }}>
                  {surveySettingsConstants.APPLY_TRANSLATION_BUTTON}
                </Button>
                <InformationComponent>
                  <TypographyComponent variant="tooltipbody">
                    {questions.template==="Manager 360"?surveySettingsConstants.APPLY_TRANSLATION_INFO_COMP:surveySettingsConstants.APPLY_TRANSLATION_INFO}
                  </TypographyComponent>
                </InformationComponent>
              </Grid>
              <Grid className={"pt-1 txtunderline"}>
                <TypographyComponent variant="h6" align="center">
                  <Link
                    href="https://20709424.hs-sites.com/survey-translations"
                    target="_blank"
                    className={"pr5"}
                  >
                    {"Click here"}
                  </Link>
                  {surveySettingsConstants.APPLY_TRANSLATION_RESOURCE_LINK_MESSAGE}
                </TypographyComponent>
              </Grid>
            </Grid>
          }

        </Grid>) : (
        <Grid container spacing={1} className="height100">
          <Grid item xs={12} className="pl-15 pr-15 pt-15" style={{ height: "calc(100% - 130px)" }}>
            <TypographyComponent variant="h5" align="center" color="textSecondary" className="fontRoboto">
              {surveySettingsConstants.LANGUAGE_NOT_ENABLED_MESSAGE}
            </TypographyComponent>
          </Grid>
          <Grid item className="mt-2 p5">
            <Grid className={"pt-1"}>
              <TypographyComponent variant="h6" align="center">
                <Link
                  href="https://20709424.hs-sites.com/survey-translations"
                  target="_blank"
                  className={"pr5"}
                >
                  {"Click here"}
                </Link>
                {surveySettingsConstants.APPLY_TRANSLATION_RESOURCE_LINK_MESSAGE}
              </TypographyComponent>
            </Grid>
          </Grid>
        </Grid>
      )
      }

      {removeLangDialog.language !== "" && (
        <Dialog open={removeLangDialog.flag}>
          <DialogContent>
            {surveyQuestionsConstants.LANG_DELETE_DIALOG_MESSSAGE}
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Yes"}
              color={"primary"}
              size={"small"}
              handleClick={() => { handleDelete(removeLangDialog.language); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
            <ButtonComponent
              title={"No"}
              color={"secondary"}
              size={"small"}
              handleClick={() => { setRemoveLangDialog({ flag: false, language: "" }); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
        </Dialog>
      )}

      {translationConfirmDialog.flag && (
        <Dialog open={translationConfirmDialog.flag}>
          <DialogContent>
            <TypographyComponent
              title={(questions.template==="Manager 360"?surveyQuestionsConstants.TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_COMP:surveyQuestionsConstants.TRANSLATE_CONFIRMATION_DIALOG_MESSAGE).split('\n').map(str => <div>{str}</div>)}
              variant={"div"}
              align={"center"}
              className="txtcenter"
            > </TypographyComponent>
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Yes"}
              color={"primary"}
              size={"small"}
              handleClick={() => { handleTranslate() }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
            <ButtonComponent
              title={"No"}
              color={"default"}
              size={"small"}
              handleClick={() => { setTranslationConfirmDialog({ flag: false }); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
          <DialogContent>
          <PoweredByGoogleComponent/>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
}

// default props
LanguageList.defaultProps = {
  classes: {}
};

// prop types
LanguageList.propTypes = {
  classes: PropTypes.object
};

export default LanguageList;
