/* eslint-disable indent */
/* eslint-disable no-dupe-keys */
const ParticipantStyles = () => ({

  // Add Particpant
  accordheading: {
    fontWeight: "bold"
  },
  settings_icons:
  {
    paddingRight: "8px",
    width: "28px"
  },
  backimg: {
    width: "28px",
    height: "28px",
    background: "#FFFFFF",
    border: "1px solid #E2E2E2",
    padding: "0px 9px",
    borderRadius: "30px",
    cursor: "pointer !important"
  },
  footerBtns_container: {
    left: 0,
    position: "fixed",
    bottom: 0,
    background: "#fff",
    padding: "15px 20px"
  },

  peaccordion_nocolor: {
    margin: "0px !important"
  },
  peaccordion_nocolor: {
    "&.MuiPaper-root": {
      borderBottom: "1px solid #E9E9E9"
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important"
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // boxShadow: '0px 0px 4px #0000000F !important',
      color: "#292929 !important",
      background: "#fff !important",
      // borderTop: '1px solid #E9E9E9',
      borderBottom: "1px solid #E9E9E9"
    },
    "& .panel-active": {
      color: "#292929 !important"
    }
  },
  pe_drawer: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important"
    },
    "& .MuiDrawer-paper": {
      overflowY: "hidden"
    }
  },
  expansionDetail:
  {
    // padding: "2px 0px",
    // maxHeight: "25vh",
    maxHeight: 'calc(100vh - 236px)',
    overflowY: "auto"
  },
  surveyNotificationExpansion:
  {
    // padding: "2px 0px",
    // maxHeight: "25vh",
    maxHeight: 'calc(100vh - 300px)',
    overflowY: "auto"
  },
  demographyCheckbox: {
    padding: "0px 5px"
  },
  assignSurvey_ListItem: {
    padding: "5px 16px",
    "&.MuiList-root .MuiListItem-button:hover": {
      background: "#f2f4f5 !important"
    }
  },
  assignSurveyChip: {
    "&.MuiChip-root": {
      background: "#EDF9FF",
      border: "1px solid #C0E2F5",
      padding: "9px 10px",
      height: "36px"
    }
  },
  assignSurevy_chipClose: {
    width: "14px",
    height: "14px",
    color: "#C0E2F5 !important"
  },
  codeicon: {
    cursor: "default",
    width: "23px",
    height: "23px",
    background: "#E3E4E6",
    "& .Mui-disabled": {
      backgroundColor: "#E3E4E6 !important"
    },
    "& .MuiIconButton-root:hover": {
      backgroundColor: "#E3E4E6 !important"
    }
  },

  // UnAssigned Survey
  SelectSurvey: {
    maxWidth: "450px",
    margin: "auto"
  },

  // Import Survey
  importContainer: {
    height: "100%",
    background: "#fff",
    maxWidth: "1000px",
    border: "1.5px dashed #A2A2A2",
    margin: "auto"
  },
  fileUploadContainer:
  {
    height: "100%",
    background: "#fff",
    maxWidth: "1000px",
    margin: "auto",
    background: " #FFFFFF",
    boxShadow: "0px 0px 6px #0000000F",
    background: "#fff",
    overflowY: "auto",
    paddingTop: "30px"
  },
  wordContainer: {
    wordBreak: "break-word",
    maxWidth: "120px"
  },

  // succespage
  succussChip: {
    "& .MuiChip-label": {
      padding: "11px 20px !important",
      background: "#DCF2FE",
      border: "1px solid #54C0FD",
      borderRadius: "30px"
    }
  },
  failChip: {
    "& .MuiChip-label": {
      padding: "11px 20px !important",
      background: "#FFF0DD",
      border: "1px solid #FFA12D",
      borderRadius: "30px"
    }
  },

  // Fail Page
  uploadFail_img: {
    width: "36px",
    height: "36px",
    position: "absolute",
    right: "-5px",
    top: "10px"
  },

  //
  Demography_list: {
    background: "#F4F6F7",
    border: "1px solid #E3E4E6",
    height: "46px",
    padding: "0 15px"
  },
  demography_input: {
    width: "90%",
    "& .MuiOutlinedInput-root": {
      backgroundColor: "#fff0 !important"
    },
    "& .Mui-error.MuiFormHelperText-marginDense": {
      left: "0"
    },
    "& fieldset": {
      border: "none !important"
    },
    "&:hover": {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#fff !important"
      },
      "& fieldset": {
        border: "1px solid rgba(0, 0, 0, 0.23) !important"
      }
    },
    "&.empty_field": {
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#fff !important"
      },
      "& fieldset": {
        border: "1px solid rgba(0, 0, 0, 0.23) !important"
      }
    }
  },
  DrawerDemographyTitle: {
    minHeight: "55px"
  },

  // Setup Demographcs
  quest_linkimg:
  {
    display: "inline-flex",
    alignItems: "center"
  },
  // Dialog
  dialog_close: {
    textAlign: "right"
  },
  demographyDialogBtn: {
    width: "40px",
    height: "40px"
  },
  table: {
    "& .MuiTableCell-root": {
      padding: "8px 16px",
      borderBottom: "none !important"
    }
  },
  deleteIcon: {
    width: "40px",
    height: "40px",
  },
  sendMailButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    "&.drawer": {
      left: 0,
      margin: "auto"
    }
  },
  survey_list: {
    "&:hover": {
      color: "#54C0FD !important",
      backgroundColor: "#EDF9FF",
      borderColor: "#54C0FD"
    }
  }

});

export default ParticipantStyles;
