import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

// Styles
import MEStyles from "./styles/MEStyles";

// Import Styles and Image
// import { BackPage } from "../../../../assets/images/svgComponents/ImageComponent";

// Import History
import history from "../../../../config/history";

// Import Table Size
import { getTableHeight, getTableRowSize } from "../../../../config/layout";

// Import Containers
import Container from "../../../Containers/Container";

// import constants
import { meSurveyConstants } from "../../../../config/constants";

// Import Component
import TableComponent from "../../../../components/TableComponent/TableComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

// Import Actions
import { excelAction, meSurveyAction } from "../../../../redux/actions";

// Import helper
import { getQueryVariable } from "../../../../helpers";

const MESurveyList = (props) => {

  // Define Dispatch
  const dispatch = useDispatch();

  const { match } = props;

  const { search, dataFilter } = useSelector(state => state.managerEffectiveness);
  const { filterData } = dataFilter
  let { data, page } = search;

  // Assign Props
  const { classes } = props;

  // Table size
  const tableHeight = getTableHeight();
  const tableRowSize = getTableRowSize();

  // Get Page Reset 
  const isPageReset = getQueryVariable(props.location.search, "reset") === "true";

  /**
   * Get ME Survey List
   */
  useEffect(() => {
    dispatch(meSurveyAction.dataFilterUpdate({
      ...dataFilter,
      filterData: {
        ...filterData,
        demographics: {},
        selectedEmpIds: []
      }
    }))
    if (!isPageReset) {
      dispatch(meSurveyAction.search(1, tableRowSize, "status", "desc", [], []));
    }
  }, [dispatch]);


  /**
    * Handle Table Navigation Event
    */
  const handleNavigateAction = (id) => {
    history.push({
      pathname: `${match.url}/assign/${id}`
    });
  };

  /**
     * Handle Table pagination Event
     */
  const onChangePagination = (skip, limit) => {
    dispatch(meSurveyAction.search(skip, limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Sort Request Event
   */
  const onSortRequest = (sortBy, sort) => {
    dispatch(meSurveyAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey, userChange) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if (!isPageReset) {
      dispatch(meSurveyAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
    }
  };

  const BackPage = (props) =>
  (
      <svg xmlns="http://www.w3.org/2000/svg" width="6.254" height="10.646" viewBox="0 0 6.254 13.646"><defs>
      </defs><g transform="translate(-101.48)"><g transform="translate(101.48 0)">
          <path fill="#c0c1c2" class="a" d="M103.582,5.325l3.983-3.983a.584.584,0,0,0,0-.823L107.215.17a.583.583,0,0,0-.824,0L101.65,4.912a.588.588,0,0,0,0,.826l4.737,4.737a.583.583,0,0,0,.824,0l.349-.349a.583.583,0,0,0,0-.824Z" transform="translate(-101.48 0)" /></g></g></svg>
  )

  /**
   * Clear Search Text By Field
   * @param {*} searchField
   * @param {*} searchKey
   */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(meSurveyAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
  };

  /**
   * Handle Action click button
   * @param {*} field 
   * @param {*} itemId 
   */
  const handelActionButtonClick = (field, itemId) => {
    switch (field) {
      case "EXPORT":
        dispatch(excelAction.download({
          excelType: meSurveyConstants.ME_PARTICIPANT_DOWNLOAD,
          survey_id: itemId,
          type: 'all'
        }, true))
        break;
      default:
    }
  }

  return (
    <Container align={"baseline"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid onClick={() => history.push("/manage_people/participants")} className={classes.backimg}>
                  {BackPage()}
                </Grid>
                <TypographyComponent
                  variant={"h3"}
                  className="ml-1">
                  {meSurveyConstants.ME_SURVEY}
                </TypographyComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className="me-surveys-list">
          <TableComponent
            actions={["EXPORT"]}
            data={data}
            header={page.fieldFilters.headerCols}
            field={page.fieldFilters.fieldCols}
            search={page.fieldFilters.searchCols}
            height={tableHeight}
            page={page.skip}
            count={page.count}
            rowsPerPage={page.limit}
            links={["name"]}
            sort={page.sort}
            sortBy={page.sortBy}
            sFields={page.sFields}
            sValues={page.sValues}
            noDataErrorMessage={"No Records Found"}
            paginationVisible={!page.lastPage}
            handleNavigateAction={handleNavigateAction}
            onChangePagination={onChangePagination}
            onSortRequest={onSortRequest}
            onInputChangeRequest={onInputChangeRequest}
            handleClearSearchByField={handleClearSearchByField}
            handelActionButtonClick={handelActionButtonClick}
            actionsTitle={{
              "EXPORT": "Download subjects and raters list"
            }}
          />
        </Grid>
      </Grid>
    </Container>
  )
}

export default withStyles(MEStyles)(MESurveyList);