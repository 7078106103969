import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import AdminList from "./AdminList";
import EditAdmin from "./EditAdmin";
import NewAdmin from "./NewAdmin";
import AdminUploadRoute from "./AdminUpload/AdminUpload.Router";

function AdminRouter (props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url} render={(props) => {
        return <AdminList
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/:id/edit`} render={props => {
        return (<EditAdmin
          match={props.match}
          {...props}
        />
        );
      }} />
      <Route exact path={`${match.url}/new`} render={(props) => {
        return <NewAdmin
          match={props.match}
          {...props} />;
      }} />
      <Route path={`${match.url}/upload`} render={(props) => {
        return <AdminUploadRoute
          match={props.match}
          {...props} />;
      }} />
    </Switch>
  );
}

/**
 * Bind Component Property Types
 */
AdminRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default AdminRouter;
