import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabScrollButton from "@mui/material/TabScrollButton";

function CategoryTabs(props) {
  // const dispatch = useDispatch();

  // Get Props Values
  const { classes, questionData, activeCategoryTab, langCode } = props;
  const { questions, catOrder, catDetails } = questionData;

  const activeCategories = questions.map(({ category }) => category);
  const newCatOrder = catOrder.filter((cat) => activeCategories.includes(cat));
  const oldActiveCategory = activeCategoryTab > 0 ? catOrder[activeCategoryTab] : newCatOrder[0];

  let newActiveTab = activeCategoryTab;
  if (activeCategoryTab > 0) {
    newActiveTab = Object.keys(newCatOrder).find((key) => newCatOrder[key] === oldActiveCategory);
    newActiveTab = parseInt(newActiveTab);
  } else {
    newActiveTab = activeCategoryTab;
  }

  // pass State Value for Tabs
  const handleChange = (event, value) => {
    props.changeTabValue(value);
    event.preventDefault();
  };

  // Set Id and Controls for tabs
  function getCategoryTabsProps(index) {
    return {
      id: `category-tab-${index}`,
      "aria-controls": `Category-Tab-Panel-${index}`,
    };
  }

  function handleArrow(type) {
    const newTab = type == "next" ? activeCategoryTab + 1 : activeCategoryTab - 1;

    if ((newTab) => 0 && newTab <= newCatOrder.length - 1) {
      props.changeTabValue(newTab, type);
    }
  }

  return (
    <React.Fragment>
      <AppBar position="static" color="default" className={`${classes.rightPanelAppBarRoot} ${"tabborder_none pe_color_appbar takesurvey_appbar mt-1"}`}>
        <TabScrollButton direction="left" onClick={(e) => handleArrow("prev")} className={`mobileButtons buttonLeft`} disabled={activeCategoryTab > 0 ? false : true} />
        <TabScrollButton direction="right" onClick={(e) => handleArrow("next")} className={`mobileButtons buttonRight`} disabled={activeCategoryTab < newCatOrder.length - 1 ? false : true} />
        <Tabs value={activeCategoryTab} onChange={handleChange} variant="scrollable" scrollButtons="auto" className={`${classes.rightPanelTabsRoot} ${"peTabs"}`} aria-label="categoryTabs">
          {newCatOrder.map((item, index) => {
            const categoryName = catDetails[item][langCode];
            return (
              <Tab
                key={index}
                component="div"
                color={"primary"}
                classes={{
                  root: classes.rightPanelTabRoot,
                  selected: classes.rightPanelTabSelected,
                }}
                fullWidth={true}
                label={
                  <span className="dflex">
                    <span className={classes.tabcharlimit}>{categoryName && categoryName.hasOwnProperty("name") ? categoryName.name : null}</span>
                  </span>
                }
                {...getCategoryTabsProps(index)}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
}

// default props
CategoryTabs.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  langCode: "en",
};

// prop types
CategoryTabs.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  langCode: PropTypes.string,
};

export default CategoryTabs;
