import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import withStyles from '@mui/styles/withStyles';

// Import TakeSurvey Header
import TakeSurveyHeader from "./TakeSurveyHeader";
import TakeSurveyFooter from "./TakeSurveyFooter";

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import { PaperPlaneWhite } from "../../assets/images/svgComponents/ImageComponent";
import Container from "../Containers/Container";

//import constants
import { TakeSurveyConstants } from "../../config/constants";

// Import Actions
import { takeSurveyAction } from "../../redux/actions";
import { linkIfy } from "../../helpers/appHelper";
import { takeSurveyConstant } from "../../redux/constants";

const TakeSurveyWelcome = (props) => {

    //get props
    const { classes, match, shortUrl, welcomeHeader, welcomeDesc } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    const survey = useSelector((state) => state.takeSurvey.getOne);
    const questionData = useSelector((state) => state.takeSurvey.questions);
    const participantData = useSelector((state) => state.takeSurvey.participant);
    const { firstName, lastName } = participantData;
    const { languages, survey_name } = questionData;
    const langCode = useSelector((state) => state.takeSurvey.surveyLang);
    const welcome_name_access = useSelector((state) => state?.takeSurvey?.getOne?.welcome_name_access);

    const handelActionButtonClick = () => {
        dispatch(takeSurveyAction.openTakeSurvey());
    }


    const welcomeName = `${firstName ? firstName : ""} ${lastName ? lastName : ""}`;


    return (
        <React.Fragment>
            <Grid container aria-live="polite" aria-relevant="text" className={classes.takeSurvey_welcome_page_root}>
                <TakeSurveyHeader langCode={langCode} />
                <Container page={"MEtakeSurveySingle"} >
                    <Grid item className={classes.takeSurvey_container}>
                        <Grid item xs={12} className="mt-1" justifyContent="space-between" alignItems="center">
                            <Grid item style={{ padding: "0px 12px" }}>
                                <Grid className={"dflex"} alignItems="center" justifyContent="center">
                                    <div className={"fontSize24 txtcenter bulletinLeft"} dangerouslySetInnerHTML={{ __html: welcomeHeader ? linkIfy(welcomeHeader) : `` }} />
                                    {welcome_name_access && <div className={"fontSize24 txtcenter pl5"} dangerouslySetInnerHTML={{ __html: linkIfy(welcomeName ? welcomeName : "") }} /> }
                                </Grid>
                                <Grid className={"dflex"} alignItems="center" justifyContent="center">
                                    <div className={"txtSemiBold txtcenter txtgrey fontSize18 fontMontserrat orderedList"}
                                        dangerouslySetInnerHTML={{ __html: linkIfy(welcomeDesc ? welcomeDesc : "") }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container alignItems="center" className={"txtcenter height70"}>
                            <Grid item xs={12} className="survey-list">
                                <Button
                                    data-test="button-data"
                                    variant={"contained"}
                                    color={"primary"}
                                    size={"small"}
                                    onClick={() => handelActionButtonClick()}
                                >
                                    <div className={"txtBold fontSize15"}>{"Take Survey"}</div>
                                    <div className={"pl-1 dflex"}>{PaperPlaneWhite()}</div>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                <TakeSurveyFooter langCode={langCode} />
            </Grid>
        </React.Fragment>
    )
}

TakeSurveyWelcome.propTypes = {

}

export default TakeSurveyWelcome
