import React from 'react';
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import CircularProgress from '@mui/material/CircularProgress';

// Import component
import LoaderStyles from "./LoaderComponentStyle"

function LoaderComponent(props) {

  const { height, classes } = props;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ height: height }}
    >
      <CircularProgress className={classes.circularBarPrimary} />
    </Grid>
  );
}

// default props
LoaderComponent.defaultProps = {
  classes: {},
  height: "350px"
};

// prop types
LoaderComponent.propTypes = {
  classes: PropTypes.object,
  height: PropTypes.number
};
export default withStyles(LoaderStyles)(LoaderComponent);
