import moment from "moment";
import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All filter Service Functions
 */
export const apiIntegrationService = {
    getSurveys,
    generateToken,
    getToken
};

/**
 * GET RESPONSE SURVEYS
 */
async function getSurveys() {
    const response = await apihelper.getRequest('survey');
    return response;
}

/**
 * GENERATE TOKEN 
 */
async function generateToken(payload) {
    const postPayload =    {
        "api_hit": Number(payload.apiHit),
        "domainURL": payload.domainUrl,
        "end_date":moment(payload.endDate).format('YYYY-MM-DD'),
        "limits_per": payload.limitsPer.charAt(0).toLowerCase() +  payload.limitsPer.slice(1),
        "response_demograpics": payload.responseDemographics,
        "start_date": moment(payload.startDate).format('YYYY-MM-DD'),
        "survey_id": payload.selectedSurveyIds
      }
    const response = await apihelper.postRequest("api/external/access-token",postPayload,false,"application/json","",'pyserver_url');
    return response;
}

/**
 * GET OLD TOKEN
 */
async function getToken() {
    const response = await apihelper.getRequest("api/external/getToken", false,'pyserver_url');
    return response;
}