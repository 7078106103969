import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Switch, Route } from "react-router-dom";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ExternalApiIntegrationStyles from "./ExternalApiIntegrationStyles";
import { BackPage } from "../../assets/images/svgComponents/ImageComponent";

import { useDispatch, useSelector } from "react-redux";

// Import Constants
import { externalApiConstants, surveyConstants } from "../../config/constants";

// Import History
import history from "../../config/history";

// Import Container
import Container from "../Containers/Container";

// Import Child Containers
import ExternalApiTabs from "./ExternalApiIntegration.Tabs";

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ConfirmDialogComponent from '../../components/ConfirmDialogComponent/ConfirmDialogComponent';
import DataIntegrationsTab from "./ExternalApiTabs/DataIntegrations";
import ConfigTab from "./ExternalApiTabs/ConfigTab";
import { externalApiAction } from "../../redux/actions/externalAPIActions";

// Tab Panel Function
function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        //Tab Panel
        <Grid>
          <TypographyComponent variant="h6">{children}</TypographyComponent>
        </Grid>
      )}
    </div>
  );
}

// Tab Panel Props
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const ExternalAPIIntegration = (props) => {
  // Assign Props
  const { classes, match, location } = props;

  // const [historyInterceptor, setHistoryInterceptor] = useState(false);

  const dispatch = useDispatch();

  const apiConfig = useSelector((state) => state.externalApiConfig);
  const externalApiAccess = useSelector(state => state.client.getOne.externalApiAccess)
 
  useEffect(()=>{
    if(externalApiAccess){
    dispatch(externalApiAction.getApiConfig());
    }else{
      history.push({
        pathname: "/surveys"
      });
    }
  },[])

  const handleBackButton = () => {
    // match.url === "/external-api-integration" ? 
      history.push("/surveys")
    // : setHistoryInterceptor(true)
  }
  
  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2} direction={"column"}>
          <Grid item xs={12}>
            <Grid item xs={12} mb={"40px"}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Grid container alignItems="center">
                    <Grid
                      onClick={() => handleBackButton()}
                      className={classes.backimg}
                    >
                      {BackPage()}
                    </Grid>
                    <TypographyComponent
                      variant={"h3"}
                      className="pl-1"
                      title={externalApiConstants.SETUP_LABEL}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <ExternalApiTabs classes={classes} match={match} location={location} />
          </Grid>
        </Grid>
        <Switch>
          <Route
            exact
            path={match.url}
            render={(props) => {
              return (
                <DataIntegrationsTab classes={classes} match={match} />
              );
            }}
          />

          <Route
            exact
            path={`${match.url}/configuration`}
            render={(props) => {
              return (
                <ConfigTab apiConfig={apiConfig} />
              );
            }}
          />

          <Route
            exact
            path={`${match.url}/generate-token`}
            render={(props) => {
              return (
                ""
              );
            }}
          />

          <Route
            exact
            path={`${match.url}/api-docs`}
            render={(props) => {
              return (
                ""
              );
            }}
          />
        </Switch>
        {/* {
          historyInterceptor && */}
          {/* <ConfirmDialogComponent
            open={historyInterceptor}
            content={surveyConstants.CONFIRM_UNSAVED_CONTENT}
            handleConfirmAction={() => {history.push("/surveys")}}
            handleCloseAction={() => setHistoryInterceptor(false)}
            textTrueBtn={'Yes'}
            textFalseBtn={'No'}
          /> */}
        {/* } */}
        
      </Container>
    </React.Fragment >
  );
}
// default props
ExternalAPIIntegration.defaultProps = {
  classes: {},
  match: {},
  location: {}
};

// prop types
ExternalAPIIntegration.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object
};
export default withStyles(ExternalApiIntegrationStyles)(ExternalAPIIntegration);