import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";

import { Grid, IconButton } from "@mui/material";

// Import Styles and Image
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

// Constants
import { ReportsConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TurnOverChart from "../../../../components/Charts/TurnoverChart/TurnoverChart";
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

const TurnOverWidget = (props) => {
    // Get Props
    const { data, classes, surveyTypes, adminPreference } = props;
    const { chartData, total } = data;

    const chartRef = useRef(null);

    const [containerWidth, setContainerWidth] = useState(0);

    const cRef = chartRef && chartRef?.current?.offsetWidth ? chartRef.current.offsetWidth : 0;

    const isProjectedDatas = surveyTypes.reduce((acc, curr) => {
        if (chartData.filter(data => data.type === curr).length !== chartData.length) {
            acc.push(chartData.some(data => data.type === curr));
        }
        return acc;
    }, []);
    const isProjected = surveyTypes.indexOf('Exit') === -1 && surveyTypes.length === 1;

    useEffect(() => {
        setContainerWidth(chartRef.current.offsetWidth)
    }, [cRef])

    const handleClose = () => {
        const turnOverKey = isProjected ? 'projectedTurnover' : 'turnOver';

        if (typeof adminPreference?.data?.hideDashWidgets?.[turnOverKey] === 'boolean') {
            adminPreference['data']['hideDashWidgets'][turnOverKey] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return (
        <Grid className={`${classes.dashboardTile} flexdirection`}>
            <Grid container>
                <Grid item className='dflexCenter width100 spacebetween'>
                    <TypographyComponent variant='h5' className={'fontWeight600 width100 txtLeft'}>
                        {isProjected ? ReportsConstants.PROJECTED_TURNOVER : ReportsConstants.TURNOVER}
                    </TypographyComponent>
                    <ToolTipComponent title={ReportsConstants.CLOSE}>
                        <IconButton onClick={handleClose} size="large">
                            {DashboardClose()}
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
                {
                    (isProjectedDatas.length === surveyTypes.length ? false : !isProjectedDatas.some(d => d === false)) &&
                        <TypographyComponent variant='h6' className={'txtRegular fontSize13 mt5 width100 txtLeft'}>
                            {isProjected ? ReportsConstants.PROJECTED_TURNOVER_SUB_TITLE : ReportsConstants.TURNOVER_SUB_TITLE}
                        </TypographyComponent>
                }
            </Grid>
            <Grid container ref={chartRef} id="turnOverChartContainer" style={{height: '100%'}}>
                <TurnOverChart data={chartData} chartWidth={containerWidth} />
            </Grid>
        </Grid>
    )
}

// default props
TurnOverWidget.defaultProps = {
    classes: {},
    data: {},
    surveyTypes: [],
    adminPreference: {},
};

// prop types
TurnOverWidget.propTypes = {
    classes: PropTypes.object,
    data: PropTypes.object.isRequired,
    surveyTypes: PropTypes.array.isRequired,
    adminPreference: PropTypes.object,
};
export default TurnOverWidget;
// export default withStyles(ReportsStyles)(SAOAWidget);