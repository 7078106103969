/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";

// Import History
import history from "../../../config/history";

// Import Styles and Image
import SurveysStyles from "../SurveysStyles";
import { CreateNewSurvey } from "../../../assets/images/svgComponents/ImageComponent";

import createnewsurvey from "../../../assets/images/svg/createnewsurvey.svg";

// Import Page Constants
import { surveyConstants, surveyTypes, errorsConstants } from "../../../config/constants";
import { surveyConstant } from "../../../redux/constants";

// Import Actions
import { alertAction, surveyAction, updateStateReducerAction, clientAction } from "../../../redux/actions";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import ButtonBaseComponent from "../../../components/ButtonBaseComponent/ButtonBaseComponent";

// Import Helpers
import { getReducerUpdatedData } from "../../../helpers";

import createSurveyVector from "../../../assets/images/create_survey_vector.png";
function SurveyPanel(props) {
  const dispatch = useDispatch();
  const { classes, match: { params }, cancelButtonAction } = props;

  /**
 * Fetch Client Data
 */
  useEffect(() => {
    dispatch(clientAction.getOne());
  }, [dispatch]);
  /**
   * Get Survey By ID
   */
  const survey = useSelector((state) => state.survey.getOne);
  const transfloAccess = useSelector((state) => state.client.surveyPermission.transfloAccess);
  const { managerEffectiveness } = useSelector((state) => state.client.getOne);
  /**
      * Handle Textbox Changes
      */
  const handleChange = (e) => {
    e.value = e.value.replace(/  +/g, " ");
    if (e.value === " ") {
      e.value = e.value.trim();
    }
    if (e.value.length >= 60) {
      dispatch(alertAction.error(errorsConstants.surveyNameCharLimit));
    } else {
      let reducerData = getReducerUpdatedData(e, survey);
      reducerData["transfloAccess"] = transfloAccess ? transfloAccess : false;
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_GETONE_REDUCER_UPDATE, reducerData));
    }
  };

  /**
     * Handle Button Base
     */
  const handleButtonBaseChange = (value) => {
    if (survey.status !== "Open" && survey.status !== "Closed") {
      // handleChange({ name: "type", value });
      handleChange({ name: "template", value });
    }
  };

  /**
    * Suvey Panel Form Submit
   * @param {*}
   */
  function handleSubmit(e) {
    e.preventDefault();
    if (survey.name && survey.template) {
      if (params.id) {
        survey["type"] = survey.template
        if (survey.type === surveyTypes.ONBOARDING_WEEK_TWO || survey.type === surveyTypes.ONBOARDING_WEEK_FIVE) {
          survey["type"] = surveyTypes.NEW_HIRE
        } else if (survey.type === surveyTypes.MANAGER_EFFECTIVENESS) {
          survey["type"] = surveyTypes.MULTI_RATER_FEEDBACK
        }
        survey['isStepperChanger'] = true;
        dispatch(surveyAction.update(survey));
      } else {
        survey["type"] = survey.template
        if (survey.type === surveyTypes.ONBOARDING_WEEK_TWO || survey.type === surveyTypes.ONBOARDING_WEEK_FIVE) {
          survey["type"] = surveyTypes.NEW_HIRE
        } else if (survey.type === surveyTypes.MANAGER_EFFECTIVENESS) {
          survey["type"] = surveyTypes.MULTI_RATER_FEEDBACK
        }
        dispatch(surveyAction.create(survey));
      }
    } else {
      dispatch(alertAction.error(errorsConstants.surveyNameEmpty));
    }
    if (cancelButtonAction.open === true) {
      history.replace("/surveys/manage");
    }
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_CATEGORY_ACTIVE_TAB, 0));
  }

  /**
     * Handel Errors
     */
  function handleError() {
    dispatch(alertAction.error("Please enter a survey name and select a template option"));
  }

  return (
    <Grid className={classes.root}>
      <ValidatorForm
        id="surveyPanelForm"
        name="surveyPanelForm"
        useref="surveyPanelForm"
        autoComplete="off"
        onSubmit={handleSubmit}
        onError={handleError}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center">

          <Grid item className="width100 txtcenter">
            <Grid container className={`${classes.createsurv_div} ${"createsurv_div"}`} direction="row" justifyContent="center" alignItems="center">
              <Grid item md={6} xs={12}>
                <Grid container className={classes.createsurv_lediv} direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <TypographyComponent
                      variant={"h2"}
                      className={"mb-2 txtBold"}
                      align={"center"}
                      title={survey._id ? surveyConstants.EDIT_THE_SURVEY : surveyConstants.CREATE_A_NEW_SURVEY}>
                    </TypographyComponent>
                  </Grid>
                  <Grid item className={classes.nameursurvey} align={"center"} justifyContent="center" alignItems="center">
                    <TextFieldComponent
                      id={"survery_name"}
                      name={"name"}
                      className={"fontSize14"}
                      value={survey.name}
                      placeholder={surveyConstants.NAME_YOUR_SURVEY}
                      maxLength={60}
                      margin={"none"}
                      validators={["required"]}
                      showErrorMessages={false}
                      handleChange={(e) => handleChange(e)}
                      style={{
                        padding: "23px 15px"
                      }}
                    />
                  </Grid>

                  <Grid item className={`${classes.nameursurvey} pt-5 pb-5`} align={"center"} justifyContent="center" alignItems="center">
                    <img src={createSurveyVector} alt={"Create A New Survey"} className={"txtright"}></img>
                  </Grid>

                </Grid>
              </Grid>
              <Grid item md={6} xs={12}>
                <Grid container className={classes.createsurv_lediv} direction="row" justifyContent="center" alignItems="center">
                  <Grid item>
                    <TypographyComponent
                      className={"pt-1 pb-1 txtMedium"}
                      variant={"h4"}
                      align={"center"}
                      title={surveyConstants.CREATE_A_NEW_SURVEY_HELP_TEXT}>
                    </TypographyComponent>
                  </Grid>
                </Grid>
                <Grid container className={classes.createsurv_lediv} direction="row" justifyContent="center" alignItems="center">
                  <Grid item className={classes.createsurv_letiles}>
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.ENGAGEMENT}
                      className={survey.template === surveyTypes.ENGAGEMENT ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyTypes.ENGAGEMENT}
                        align={"center"}
                        className="txtBold"
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                  <Grid item className={classes.createsurv_letiles}>
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.EXIT}
                      className={survey.template === surveyTypes.EXIT ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyTypes.EXIT}
                        align={"center"}
                        className="txtBold"
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                  <Grid item className={classes.createsurv_letiles}>
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.STAY}
                      className={survey.template === surveyTypes.STAY ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyTypes.STAY}
                        align={"center"}
                        className="txtBold"
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                  <Grid item className={classes.createsurv_letiles} >
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.ONBOARDING_WEEK_TWO}
                      className={survey.template === surveyTypes.ONBOARDING_WEEK_TWO ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        align={"center"}
                        className="txtBold pl-2 pr-2"
                      >{surveyTypes.ONBOARDING_WEEK_TWO} </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>

                  <Grid item className={classes.createsurv_letiles} >
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.ONBOARDING_WEEK_FIVE}
                      className={survey.template === surveyTypes.ONBOARDING_WEEK_FIVE ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        align={"center"}
                        className="txtBold pl-2 pr-2"
                      > {surveyTypes.ONBOARDING_WEEK_FIVE}</TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                  <Grid item className={classes.createsurv_letiles} >
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.NEW_HIRE}
                      className={survey.template === surveyTypes.NEW_HIRE ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyTypes.NEW_HIRE}
                        align={"center"}
                        className="txtBold"
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                  <Grid item className={classes.createsurv_letiles}>
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.DIVERSITY_INCLUSION}
                      className={survey.template === surveyTypes.DIVERSITY_INCLUSION ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}
                    >
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyTypes.DIVERSITY_INCLUSION}
                        align={"center"}
                        className="txtBold p-1"
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>

                  <Grid item className={classes.createsurv_letiles}>
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.WELLBEING}
                      className={survey.template === surveyTypes.WELLBEING ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyTypes.WELLBEING}
                        align={"center"}
                        className="txtBold"
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                  <Grid item className={`${managerEffectiveness ? "" : "bgGreyStrict cursor-not_allowed"} ${classes.createsurv_letiles}`}>
                    <ButtonBaseComponent
                      disabled={managerEffectiveness ? false : true}
                      defaultValue={surveyTypes.MANAGER_EFFECTIVENESS}
                      className={survey.template === surveyTypes.MANAGER_EFFECTIVENESS ? "active" : ""}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyTypes.MANAGER_EFFECTIVENESS}
                        align={"center"}
                        className={`txtBold ${managerEffectiveness ? "" : "txtgrey"}`}
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                  <Grid item className={`${classes.createsurv_letiles} ${classes.createsurv_letiles_full}`}>
                    <ButtonBaseComponent
                      defaultValue={surveyTypes.CUSTOM_SURVEY}
                      className={`${survey.template === surveyTypes.CUSTOM_SURVEY ? "active" : ""}`}
                      handleButtonBaseChange={(e) => handleButtonBaseChange(e)}>
                      {/* <img className="p-2" src={createnewsurvey} alt={surveyConstants.CREATE_A_CUSTOM_SURVEY} /> */}
                      <Grid className="p-2 mt3">
                        {CreateNewSurvey()}
                      </Grid>
                      <TypographyComponent
                        variant={"h3"}
                        title={surveyConstants.CREATE_A_CUSTOM_SURVEY}
                        align={"center"}
                        className="txtBold txtLeft"
                      > </TypographyComponent>
                    </ButtonBaseComponent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ValidatorForm>
    </Grid>
  );
}

// default props
SurveyPanel.defaultProps = {
  match: {},
  cancelButtonAction: {}
};

// prop types
SurveyPanel.propTypes = {
  match: PropTypes.any,
  cancelButtonAction: PropTypes.object
};

export default withStyles(SurveysStyles)(SurveyPanel);
