import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

// import images
import { Option } from "../../../assets/images/svgComponents/ImageComponent";

// Import History
import history from "../../../config/history";

// Import Component
import ParticipantDemograpics from "./ParticipantDemograpics";

import { clientAction } from "../../../redux/actions";

// import constants
import { participantConstants, FilterConstants } from "../../../config/constants";

const OptionsMenu = (props) => {
  // Get Props
  const { classes, match, page, onDemographicChange, scroll } = props;

  const dispatch = useDispatch();


  // Get Reducer Data
  // const participantList = useSelector((state) => state.participant.search);
  // const { page, data } = participantList;

  // Set default drawer state
  const [drawer, setDrawer] = useState(false);
  const [optionsPopup, setOptionsPopup] = useState(null);

  const { surveyPermission } = useSelector((state) => state.client);
  const { metadata } = useSelector((state) => state.metadata);

  // Open options menu
  const OpenOptions = (event) => {
    setOptionsPopup(event.currentTarget);
  };

  useEffect(() => {
    dispatch(clientAction.getSurveyPermission());
  }, [dispatch]);

  // close options menu
  const handleClose = () => {
    setOptionsPopup(null);
  };

  /**
   * Open More Options Menu
   */
  const openMoreOptionMenu = (menu) => {
    setOptionsPopup(null);
    history.push(`${match.url}/${menu}`);
  };

  /**
   * toggle drawer
   */
  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setOptionsPopup(null);
    setDrawer(open);
  };

  //To check ME toogle option
  let meOption = false
  let isME = []
  isME = metadata.filter((val) => val.key === "manager_id")
  if (isME.length > 0 && surveyPermission.managerEffectiveness) {
    meOption = true
  }

  return (
    <React.Fragment>
      <Tooltip arrow title={FilterConstants.MORE_OPTIONS}>
        <IconButton color="inherit" onClick={OpenOptions} className="p5 ml5" size="large">
          {Option()}
        </IconButton>
      </Tooltip>
      {page && page.fieldFilters && page.fieldFilters.columnSettings.length > 0 &&
        <ParticipantDemograpics
          page={page}
          drawer={drawer}
          classes={classes}
          toggleDrawer={toggleDrawer}
          onDemographicChange={onDemographicChange}
        />
      }
      <Menu
        id="simple-menu"
        className={scroll ? scroll : ""}
        anchorEl={optionsPopup}
        keepMounted
        open={Boolean(optionsPopup)}
        onClose={handleClose}
      >
        <MenuItem onClick={toggleDrawer(true)} data-my-value={"columnSettings"}>
          {participantConstants.COLUMN_SETTINGS}
        </MenuItem>
        {meOption &&
          <MenuItem
            onClick={() => openMoreOptionMenu("me-survey")}
            data-my-value={"me-survey"}
          >
            {participantConstants.SETUP_ME}
          </MenuItem>
        }
        <MenuItem
          onClick={() => openMoreOptionMenu("assignedDateReport")}
          data-my-value={"assignedDateReport"}
        >
          {participantConstants.ASSIGNED_DATE_REPORT}
        </MenuItem>
        <MenuItem
          onClick={() => openMoreOptionMenu("unassignSurvey")}
          data-my-value={"unassignSurvey"}
        >
          {participantConstants.UNASSIGN_SURVEY}
        </MenuItem>
        <MenuItem
          onClick={() => openMoreOptionMenu("override/demographics")}
          data-my-value={"overrideDemographics"}
        >
          {participantConstants.OVERRIDE_REPORT_DEMOGRAPHICS}
        </MenuItem>
        <MenuItem
          onClick={() => openMoreOptionMenu("bulkDelete")}
          data-my-value={"bulkDelete"}
        >
          {participantConstants.BULK_DELETE}
        </MenuItem>
        <MenuItem
          onClick={() => openMoreOptionMenu("archive")}
          data-my-value={"archive"}
        >
          {participantConstants.BULK_ARCHIVE_RESTORE}
        </MenuItem>
        <MenuItem
          onClick={() => openMoreOptionMenu("exports")}
          data-my-value={"exports"}
        >
          {participantConstants.PARTICIPANT_EXPORTS}
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};

// default props
OptionsMenu.defaultProps = {
  classes: {},
  match: {},
  page: {},
  onDemographicChange: () => { }
};

// prop types
OptionsMenu.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  page: PropTypes.object,
  onDemographicChange: PropTypes.func
};

export default OptionsMenu;
