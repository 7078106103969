import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";

// Import Styles and Image
import TakeSurveyStyles from "./TakeSurveyStyles";

//Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";

//import constants
import { TakeSurveyConstants } from "../../../config/constants";

function TakeSurveyFooter(props) {
  const { classes } = props;
  const preventDefault = (event) => event.preventDefault();
  return (
    <Grid container className={`${classes.rootFooter}`}>
      <Grid item xs={12} className={classes.footerBox}>
        {/* <Link href="#" onClick={preventDefault} className="colorGreen">
          {TakeSurveyConstants.POWEREDBY_TEXT}
        </Link> */}
        <TypographyComponent variant="h6" className="colorGreen">
          {TakeSurveyConstants.POWEREDBY_TEXT}
        </TypographyComponent>
      </Grid>
    </Grid>
  );
}

// default props
TakeSurveyFooter.defaultProps = {
  classes: {},
};

// prop types
TakeSurveyFooter.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(TakeSurveyStyles)(TakeSurveyFooter);
