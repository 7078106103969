import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

// import components
import Questions from "./Questions";
import TranslateQuestions from "./TranslateQuestions";
import AddQuestions from "./AddQuestions";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

function RenderQuestions(props) {
  const { classes, questionData, activeCategoryTab, langCode, item, indx, queryBuilderData, translatedFields, errors } = props;
  const { editable } = item;
  const [questionItem, setQuestionItem] = useState(null);
  const dispatch = useDispatch();

  // update the edited question here for both save or cancel
  const updateQuestion = (baseQusId, updatedQuestion) => {
    const { questions } = questionData;
    questions.filter((question, index) => {
      if (baseQusId === question.baseQusId) {
        questions[index] = { ...updatedQuestion };
      }
    });
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, questionData));
  };

  if (editable) {
    return (
      <AddQuestions
        questionData={questionData}
        item={questionItem || JSON.parse(JSON.stringify(item))}
        category={item.category}
        classes={classes}
        editQuestionsData={(reducerData) => { setQuestionItem({ ...item, ...reducerData }); }}
        updateAddQuesFlag={(isSave) => {
          // if changes and save clicked
          // else no changes or cancel
          if (isSave && questionItem) {
            // remove editable state after save
            delete questionItem.editable;
            // reset to old question
            updateQuestion(item.baseQusId, questionItem);
            setQuestionItem(null);
          } else {
            // reset to old question item and disable edit
            delete item.editable;
            updateQuestion(item.baseQusId, item);
            setQuestionItem(null);
          }
        }}
      />
    );
  } else if (langCode === surveyQuestionsConstants.DEFAULT_LANG_CODE) {
    return (
      <Questions
        item={item}
        index={indx}
        langCode={langCode}
        activeCategoryTab={activeCategoryTab}
        questionData={questionData}
        classes={classes}
        queryBuilderData={queryBuilderData}
        errors={errors}
      />
    );
  } else {
    return (
      <TranslateQuestions
        item={item}
        key={indx}
        index={indx}
        activeCategoryTab={activeCategoryTab}
        langCode={langCode}
        questionData={questionData}
        classes={classes}
        translatedFields={translatedFields}
        errors={errors}
      />
    );
  }
}

// default props
RenderQuestions.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  item: {},
  queryBuilderData: {},
  indx: 0
};

// prop types
RenderQuestions.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  langCode: PropTypes.string,
  item: PropTypes.object,
  queryBuilderData: PropTypes.object,
  indx: PropTypes.any
};

export default RenderQuestions;
