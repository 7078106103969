import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import { DashboardClose, Expand } from "../../../../assets/images/svgComponents/ImageComponent";
import ReportsStyles from "../../ReportsStyles";

// Import Actions
import { dashboardAction, updateStateReducerAction } from "../../../../redux/actions";

// Import Reducer Constant
import { dashboardConstant } from "../../../../redux/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";
import OverallEIChart from "./OverallEIChart";
import EIWithRGIChart from "./EIWithRGIChart";
import EiRgiPopup from "./EiRgiPopup";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import { ReportsConstants } from "../../../../config/constants";

const EIChart = (props) => {
  const dispatch = useDispatch();

  //Get props
  const { classes, handleClose, DragHandle, isUpdate, preference, updateReportPreference, stopChartReload } = props;

  //get reducer data
  const { loading, showPopup, EiOverall, overall_loading } = useSelector((state) => state.dashboard.engagementIndex)


  // Get EI Chart Details on initial loading
  useEffect(() => {
    if (isUpdate && !loading) {
      dispatch(dashboardAction.EIWithRgi());
      stopChartReload("5")
    }
  }, [dispatch, isUpdate, loading]);

  // popup close
  const onClose = () => {
    dispatch(
      updateStateReducerAction.update(
        dashboardConstant.SHOW_EI_POPUP,
        !showPopup
      )
    );
  };

  // open popup
  const openPopup = () => {
    dispatch(
      updateStateReducerAction.update(
        dashboardConstant.SHOW_EI_POPUP,
        !showPopup
      )
    );
  };

  //Save Report Preferences on demographic change
  const saveReportPreferences = (selectedRgi) => {

    const data = {
      ...preference,
      surveys: preference.surveys.map((e) => e._id ? e._id : e),
      EI: {
        rg: selectedRgi,
      },
    };

    // Update Preferences
    updateReportPreference(data, "5")
  };

  const handleSorting = (sortObject) =>{
    if(sortObject.sortField){
      const data = {
        ...preference,
        EI: {
          ...preference.EI,
          yAxisSort: {sortField: sortObject.sortField, sortBy: sortObject.sortBy},
        },
      };
      // Update Preferences
      updateReportPreference(data)
    }

  }

  return (
    <React.Fragment>
      <Grid item sm={12} className={"mb-14"}>
        <Grid item className={classes.insightTile}>
          <Grid container alignItems="center" className="mb-1">
            <Grid item>
              <DragHandle />
            </Grid>
            <Grid item>
              <TypographyComponent variant="h4" className="txtBold fontSize16  ml-1">
                {"Engagement"} <InformationComponent>{ReportsConstants.ENGAGEMENT_INFO}</InformationComponent>
              </TypographyComponent>
            </Grid>
            <Grid item className={classes.viewActionButton}>
              <Grid container>
                <Tooltip title={"Expand"} arrow >
                  <IconButton onClick={() => { openPopup(); }} size="large">
                    {Expand()}
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Close"} arrow >
                  <IconButton onClick={() => handleClose('5')} size="large">
                    {DashboardClose()}
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          {(loading || overall_loading) &&
            <LoaderComponent></LoaderComponent>
          }
          {!loading && !showPopup && !overall_loading &&
            <Grid container>
              <OverallEIChart
                classes={classes}
                chartId={"Eicharts"}
              />
              <EIWithRGIChart
                chartId={"EngagementIndex"}
                saveReportPreferences={saveReportPreferences}
                preference={preference}
                classes={classes}
                handleSorting={handleSorting}
              />
            </Grid>
          }
        </Grid>
      </Grid>
      {showPopup && (
        <DialogComponent open={showPopup} fullScreen={true}>
          <EiRgiPopup
            saveReportPreferences={saveReportPreferences}
            onClose={onClose}
            preference={preference}
            handleSorting={handleSorting}
          ></EiRgiPopup>
        </DialogComponent>
      )}
    </React.Fragment>
  );
};
// default props
EIChart.defaultProps = {
  classes: {},
  isUpdate: false,
  preference: {},
  updateReportPreference: () => { },
  handleClose: () => { },
  stopChartReload: () => { }
};

// prop types
EIChart.propTypes = {
  classes: PropTypes.object,
  isUpdate: PropTypes.bool,
  preference: PropTypes.object,
  updateReportPreference: PropTypes.func,
  handleClose: PropTypes.func,
  stopChartReload: PropTypes.func
};
export default withStyles(ReportsStyles)(EIChart);