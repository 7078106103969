import React, { useEffect, useState } from 'react';
import withStyles from '@mui/styles/withStyles';

import LiveChatComponentStyle from './LiveChatComponentStyle';

const whiteListPages = ["surveys", "people", "reports", "actions"];

function LiveChatComponent(props) {

    const { page } = props;
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        window.LiveChatWidget.on('ready', () => {
            setLoaded(true)
        })
    }, [window.LiveChatWidget])

    useEffect(() => {
        if (window.LiveChatWidget && loaded && page ) {
            console.log(page.pathname)
            const root_page = page && page.hash ? page.hash.split("/")[1] : page.pathname.split("/")[1];
            if (whiteListPages.indexOf(root_page) === -1) {
                window.LiveChatWidget.call("hide")
            } else if (window.LiveChatWidget.get("state").visibility === "hidden") {
                window.LiveChatWidget.call("minimize")
            }
        }
    }, [page, loaded])

    return <div className="live-chat-controller" />
}

// default props
LiveChatComponent.defaultProps = {
    classes: {},
    showChat: true,
    page: null
};

export default withStyles(LiveChatComponentStyle)(LiveChatComponent);