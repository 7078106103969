// Survey reducer types
export const spCallerConstant = {
    // Survey Queue
    GET_SURVEY_QUEUE_LIST_REQUEST: "SPCALLER_GET_SURVEY_QUEUE_LIST_REQUEST",
    GET_SURVEY_QUEUE_LIST_SUCCESS: "SPCALLER_GET_SURVEY_QUEUE_LIST_SUCCESS",
    GET_SURVEY_QUEUE_LIST_FAILURE: "SPCALLER_GET_SURVEY_QUEUE_LIST_FAILURE",

    // Caller Queue
    GET_CALLER_QUEUE_LIST_REQUEST: "SPCALLER_GET_CALLER_QUEUE_LIST_REQUEST",
    GET_CALLER_QUEUE_LIST_SUCCESS: "SPCALLER_GET_CALLER_QUEUE_LIST_SUCCESS",
    GET_CALLER_QUEUE_LIST_FAILURE: "SPCALLER_GET_CALLER_QUEUE_LIST_FAILURE",

    // Caller Detail
    GET_CALLER_DETAIL_REQUEST: "SPCALLER_GET_CALLER_DETAIL_REQUEST",
    GET_CALLER_DETAIL_SUCCESS: "SPCALLER_GET_CALLER_DETAIL_SUCCESS",
    GET_CALLER_DETAIL_FAILURE: "SPCALLER_GET_CALLER_DETAIL_FAILURE",
    UPDATE_CALLER_DETAIL_REDUCER: "UPDATE_CALLER_DETAIL_REDUCER",

    // Caller Detail participant data update on next button click
    UPDATE_CALLER_DETAIL_REQUEST: "SPCALLER_UPDATE_CALLER_DETAIL_REQUEST",
    UPDATE_CALLER_DETAIL_SUCCESS: "SPCALLER_UPDATE_CALLER_DETAIL_SUCCESS",
    UPDATE_CALLER_DETAIL_FAILURE: "SPCALLER_UPDATE_CALLER_DETAIL_FAILURE",

    // Caller Detail add history
    ADD_HISTORY_TO_CALLER_DETAIL_REQUEST: "SPCALLER_ADD_HISTORY_TO_CALLER_DETAIL_REQUEST",
    ADD_HISTORY_TO_CALLER_DETAIL_SUCCESS: "SPCALLER_ADD_HISTORY_TO_CALLER_DETAIL_SUCCESS",
    ADD_HISTORY_TO_CALLER_DETAIL_FAILURE: "SPCALLER_ADD_HISTORY_TO_CALLER_DETAIL_FAILURE",

}