import { alertConstant } from "../constants/alertConstant";

const initialState = {
  message: "",
  type: "",
  duration: ""
};

export default function (state = initialState, action) {
  if (!action || (action && !action.type)) return state;

  switch (action.type) {
    case alertConstant.ALERT_ERROR:
      return {
        ...state,
        message: action.error,
        type: alertConstant.ALERT_ERROR,
        duration: action.duration
      };
    case alertConstant.ALERT_INFO:
      return {
        ...state,
        message: action.data,
        type: alertConstant.ALERT_INFO,
        duration: action.duration
      };
    case alertConstant.ALERT_SUCCESS:
      return {
        ...state,
        message: action.data,
        type: alertConstant.ALERT_SUCCESS,
        duration: action.duration
      };
    case alertConstant.ALERT_CLEAR:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
}
