import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const clientService = {
  getOne,
  getAll,
  create,
  update,
  remove,
  checkSurveys,
  getSurveyPermission,
  getIndustry,
  updateDemographics,
  getLanguageList
};

/**
 * Get Client By Id
 */
async function getOne() {
  const response = await apihelper.getRequest(`clients`);
  return response;
}

/**
 * Get All clients
 */
async function getAll() {
  const response = await apihelper.getRequest("clients/name");
  return response;
}

/**
 * Create Client
 */
async function create(data) {
  const response = await apihelper.postRequest("clients", data);
  return response;
}

/**
 * Call update Client Service
 * @param id
 * @param updatedData
 */
async function update(updatedData) {
  const response = await apihelper.putRequest("clients", updatedData);
  return response;
}

/**
 * Delete Client Service
 * @param id
 */
async function remove(id) {
  const response = await apihelper.deleteRequest("clients", id);
  return response;
}

/**
 * GET CLIENT PERMISSION
 */
 async function checkSurveys() {
  const response = await apihelper.getRequest("clients/checkSurveys");
  return response;
}

/**
 * GET CLIENT PERMISSION
 */
async function getSurveyPermission() {
  const response = await apihelper.getRequest("clients/permission/survey");
  return response;
}

/**
 * Update Client Demographics
 */
async function updateDemographics(updateFields){
  const response = await apihelper.putRequest("clients/updateDemographics", updateFields);
  return response;
}
/**
 * GET CLIENT INDUSTRY
 */
async function getIndustry() {
  const response = await apihelper.getRequest("clients/industry");
  return response;
}

/**
 * GET CLIENT LanguageList
 */
 async function getLanguageList(data) {
  const response = await apihelper.postRequest("clients/getLanguageList", data);
  return response;
}
