import { Grid } from "@mui/material"
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { SortableHandle } from "react-sortable-hoc";
import { updateStateReducerAction, contentLibraryAction } from "../../../../redux/actions";


import { contentLibraryConstant } from "../../../../redux/constants";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import { TrashSurvey } from "../../../../assets/images/svgComponents/ImageComponent";
import RepeatGrid from "../../../../assets/images/svg/repeat-grid.svg";



const DragHandle = SortableHandle(() => (
    <Tooltip arrow title="Reorder Icon">
        <img src={RepeatGrid} className="cursor-move pt5 icon-hover" alt={"Menu"} draggable={false} />
    </Tooltip>
));

function ActionAlertCard(props) {

    let { classes, item, actionAlert, index } = props;
    const dispatch = useDispatch();

    const [editable, setEditable] = useState(false);


    // Update reducer data
    const updateReducer = (actionAlert) => {
        const reducerData = actionAlert;
        dispatch(updateStateReducerAction.update(contentLibraryConstant.CONTENTLIBRARY_ACTIONALERT_REDUCER_UPDATE, reducerData));
    };


    const editOnClick = (event) => {
        const toggle = !editable;
        setEditable(toggle);
        actionAlert = actionAlert.map((alerts, ind) => {
            if (alerts.TopicName === item.TopicName) {
                actionAlert[ind].editable = true;
                return alerts
            } else {
                actionAlert[ind].editable = false
                return alerts
            }
        });
        updateReducer(actionAlert);
        return true;
    };

    // Delete Action Alert
    const deleteActionAlert = (data) => {
        actionAlert.filter((item, index) => {
            if (actionAlert[index].TopicName === data.TopicName) {
                actionAlert.splice(index, 1);
            }
        });
        updateReducer(actionAlert);
        dispatch(contentLibraryAction.remove(data));
        return true;
    };



    return (
        <Grid className="width100 mt-2">
            <Grid className={`${classes.action_alert_item} dflex`} onClick={editOnClick}>
                <Grid item className={`${"dflex pr-15 mt8 mb4"}`}>
                    <DragHandle></DragHandle>
                </Grid>
                <Grid item className={"pl-1 pr-2 selfcenter"}>
                    <Badge
                        color="secondary"
                        className={`badgebig`}
                        badgeContent={index + 1}
                    ></Badge>
                </Grid>
                <Grid item sm={12} className={"pl5 dflex spacebetween"}>
                    <Grid item className={"selfcenter"}>
                        <TypographyComponent
                            className={``}
                            variant={"h6"}>
                            {item.TopicName}
                        </TypographyComponent>
                    </Grid>
                    {item.TopicName !== "Subject pdf report" &&
                        <Grid item className="pl-3 dflex">
                            <Tooltip arrow title="Delete">
                                <IconButton
                                    color="inherit"
                                    className="p5 icon-hover ImgActiveOrange"
                                    onClick={(e) => {
                                        deleteActionAlert(item);
                                    }}
                                    size="large">
                                    {TrashSurvey()}
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ActionAlertCard;

