import * as apihelper from "../../helpers/apiHelper";

/**
 * Query Builder Service Functions
 */
export const queryBuilderService = {
  getFieldNames,
  getFieldValues,
};

/**
 * Call getfieldnames Service
 */
async function getFieldNames() {
  const response = await apihelper.getRequest(`query-builder/audience/fields`);
  return response;
}

/**
 * Call getFieldValues Service
 */
async function getFieldValues(fieldName="empty") {
    const response = await apihelper.getRequest(`query-builder/audience/values/${fieldName}`);
    return response;
  }