import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

import { timeout } from "../../assets/images/svgComponents/ImageComponent"
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent"
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent"

const SessionTimeComponent = (props) => {

    //get porps
    const { hanldeTimeOut } = props;

    const [showPopover, setShowPopover] = useState(false)

    //set state for timeout

    let logoutTimeout;

    const logout = () => {
        console.log("session timed out");
        hanldeTimeOut(true)
        setShowPopover(true)
    };

    const setTimeouts = () => {
        // 5 minites
        logoutTimeout = setTimeout(logout, 300000);
    };

    const clearTimeouts = () => {
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    useEffect(() => {
        const events = [
            "load",
            "click",
            "scroll",
            "keypress",
        ];

        const resetTimeout = () => {
            clearTimeouts();
            setTimeouts();
        };

        for (let i in events) {
            window.addEventListener(events[i], resetTimeout);
        }

        setTimeouts();
        return () => {
            for (let i in events) {
                window.removeEventListener(events[i], resetTimeout);
                clearTimeouts();
            }
        };
    }, []);

    return (showPopover &&
        <Grid className="internet-error">
            <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center" direction="column" style={{ marginTop: "200px" }}>
                    {timeout()}
                    <div className="pt-1">
                        <TypographyComponent variant={"h2"} className="thanksHeader txtcenter">Session Timed Out</TypographyComponent>
                        <TypographyComponent variant={"h4"} className="thanksDescription txtcenter mt-3">Your session has expired due to inactivity. Please refresh this page or click the "continue working" option below to access it.</TypographyComponent>
                    </div>
                    <ButtonComponent
                        title={"Continue Working"}
                        color={"primary"}
                        size={"small"}
                        pageClassName="mr-1 mt-3"
                        handleClick={() => setShowPopover(false)}
                    ></ButtonComponent>
                </Grid>
            </Grid>
        </Grid>
    )
};

SessionTimeComponent.defaultProps = {
    onTimeout: () => { },
    timeOut: 3600000
};

SessionTimeComponent.propTypes = {
    onTimeout: PropTypes.func,
    timeOut: PropTypes.number,
};
export default SessionTimeComponent;
