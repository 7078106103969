import { pdfConstant } from "../constants";

// Set Initial State
const initialState = {
  download: {},
};

export default function (state = initialState, action) {
  switch (action.type) {

      // DOWNLOAD PDF REDUCER
    case pdfConstant.PDF_DOWNLOAD_REQUEST:
      return {
        ...state
      };
    case pdfConstant.PDF_DOWNLOAD_SUCCESS:
      return {
        ...state
      };
    case pdfConstant.PDF_DOWNLOAD_FAILURE:
      return {
        ...state
      };
      
    default:
      return state;
  }
}
