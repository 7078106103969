/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// Import Actions
import { surveyAction, categoryAction, alertAction, baseQuestionsAction, updateStateReducerAction } from "../../../../redux/actions";

import { categoryConstant } from "../../../../redux/constants";


// Import CategoryTabs
import CategoryPanel from "./CategoryPanel";

// Import Components
import DropTarget from "../../../../components/DragDropComponents/DropTarget";

// Import constants
// import { surveyConstant } from "../../../../redux/constants";

function RightPanel(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  /**
 * Get Question by survey id
 */
  useEffect(() => {
    dispatch(surveyAction.getQuestions(params.id ? params.id : questions._id));
    dispatch(surveyAction.getResponseDetails(params.id ? params.id : questions._id));
    dispatch(surveyAction.getAnswerSet(params.id ? params.id : questions._id));
    dispatch(categoryAction.removeCategoryData());
  }, [dispatch]);

  // Get Categories in Survey Questions
  const { questions, errors } = useSelector((state) => state.survey);
  const { addQuestions } = useSelector((state) => state.category);
  const { audience: audienceQueryBuilderData } = useSelector(state => state.queryBuilder);
  const { classes, match } = props;
  const { params } = match;

  // Handle Drag and Drop Event
  const categoryDropped = (item) => {
    let newVal = new Array();
    dispatch(updateStateReducerAction.update(categoryConstant.CATEGORY_QUESTION_SELECTED, newVal))
    if (item) {
      const dragData = item.split("%/-+*/%");
      if (dragData[0] === "category") {
        if (questions && questions.catOrder.indexOf(dragData[1]) <= -1) {
          dispatch(baseQuestionsAction.getQuestionsByCategoryandSurveyType({ category: dragData[1], type: questions.type, languages: questions.languages, questions: questions.questions, addQuestions: addQuestions, isOldSurvey: questions.isOldSurvey }));
        } else {
          dispatch(alertAction.error(questions.template==="Manager 360"?"This competency already exists in the survey":"This category already exists in the survey"));
        }
      } else {
        if (questions.catOrder && questions.catOrder.length > 0) {
          if (dragData.length > 2) {
            // dragData.map((item, index) => {
            //   if (index !== 0) {
            //     dispatch(baseQuestionsAction.getQuestionsByQuestionandSurveyType({ question: item, type: questions.type, category: "", languages: questions.languages, questions: questions.questions }));
            //   }
            // })
            dispatch(baseQuestionsAction.getQuestionsByQuestionandSurveyType({ multiSel:true, question: dragData, type: questions.type, category: "", languages: questions.languages, questions: questions.questions, isOldSurvey: questions.isOldSurvey }));
          }
          else {
            dispatch(baseQuestionsAction.getQuestionsByQuestionandSurveyType({multiSel:false, question: dragData[1], type: questions.type, category: "", languages: questions.languages, questions: questions.questions, isOldSurvey: questions.isOldSurvey }));
          }
        } else {
          dispatch(alertAction.error(questions.template==="Manager 360"?"Survey must include at least one competency to add a question":"Survey must include at least one category to add a question"));
        }
      }
    }
  };

  return (
    <DropTarget onItemDropped={categoryDropped}>
      <CategoryPanel
        questionData={{ ...questions }}
        classes={classes}
        match={match}
        addQuestions={addQuestions}
        queryBuilderData={audienceQueryBuilderData}
        errors={errors}
      ></CategoryPanel>
    </DropTarget>
  );
}

// default props
RightPanel.defaultProps = {
  classes: {}
};

// prop types
RightPanel.propTypes = {
  classes: PropTypes.object
};

export default RightPanel;
