// particpants reducer types
export const contentLibraryConstant = {
    // To Get content library Details
    CONTENTLIBRARY_GETONE_REQUEST: "CONTENTLIBRARY_GETONE_REQUEST",
    CONTENTLIBRARY_GETONE_SUCCESS: "CONTENTLIBRARY_GETONE_SUCCESS",
    CONTENTLIBRARY_GETONE_FAILURE: "CONTENTLIBRARY_GETONE_FAILURE",

    // To search action alert/coach
    CONTENTLIBRARY_SEARCH_REQUEST: "CONTENTLIBRARY_SEARCH_REQUEST",
    CONTENTLIBRARY_SEARCH_SUCCESS: "CONTENTLIBRARY_SEARCH_SUCCESS",
    CONTENTLIBRARY_SEARCH_FAILURE: "CONTENTLIBRARY_SEARCH_FAILURE",


    // Delete action alert
    CONTENTLIBRARY_REMOVE_REQUEST: "CONTENTLIBRARY_REMOVE_REQUEST",
    CONTENTLIBRARY_REMOVE_FAILURE: "CONTENTLIBRARY_REMOVE_FAILURE",


    // To Update content library Details
    CONTENTLIBRARY_UPDATE_REQUEST: "CONTENTLIBRARY_UPDATE_REQUEST",
    CONTENTLIBRARY_UPDATE_SUCCESS: "CONTENTLIBRARY_UPDATE_SUCCESS",
    CONTENTLIBRARY_UPDATE_FAILURE: "CONTENTLIBRARY_UPDATE_FAILURE",

    // To Get report content library Details
    CONTENTLIBRARY_REPORTCONTENT_GETONE_REQUEST: "CONTENTLIBRARY_REPORTCONTENT_GETONE_REQUEST",
    CONTENTLIBRARY_REPORTCONTENT_GETONE_SUCCESS: "CONTENTLIBRARY_REPORTCONTENT_GETONE_SUCCESS",
    CONTENTLIBRARY_REPORTCONTENT_GETONE_FAILURE: "CONTENTLIBRARY_REPORTCONTENT_GETONE_FAILURE",


    // Delete report content
    CONTENTLIBRARY_REPORTCONTENT_REMOVE_REQUEST: "CONTENTLIBRARY_REPORTCONTENT_REMOVE_REQUEST",
    CONTENTLIBRARY_REPORTCONTENT_REMOVE_FAILURE: "CONTENTLIBRARY_REPORTCONTENT_REMOVE_FAILURE",


    // To update Report Content Details
    CONTENTLIBRARY_REPORTCONTENT_UPDATE_REQUEST: "CONTENTLIBRARY_REPORTCONTENT_UPDATE_REQUEST",
    CONTENTLIBRARY_REPORTCONTENT_UPDATE_SUCCESS: "CONTENTLIBRARY_REPORTCONTENT_UPDATE_SUCCESS",
    CONTENTLIBRARY_REPORTCONTENT_UPDATE_FAILURE: "CONTENTLIBRARY_REPORTCONTENT_UPDATE_FAILURE",

    // To search report content/coach
    CONTENTLIBRARY_REPORTCONTENT_SEARCH_REQUEST: "CONTENTLIBRARY_REPORTCONTENT_SEARCH_REQUEST",
    CONTENTLIBRARY_REPORTCONTENT_SEARCH_SUCCESS: "CONTENTLIBRARY_REPORTCONTENT_SEARCH_SUCCESS",
    CONTENTLIBRARY_REPORTCONTENT_SEARCH_FAILURE: "CONTENTLIBRARY_REPORTCONTENT_SEARCH_FAILURE",

    // To GET Message Data
    CONTENTLIBRARY_MESSAGE_GET_REQUEST: "CONTENTLIBRARY_MESSAGE_GET_REQUEST",
    CONTENTLIBRARY_MESSAGE_GET_SUCCESS: "CONTENTLIBRARY_MESSAGE_GET_SUCCESS",
    CONTENTLIBRARY_MESSAGE_GET_FAILURE: "CONTENTLIBRARY_MESSAGE_GET_FAILURE",

    // To update Message Data
    CONTENTLIBRARY_MESSAGE_UPDATE_REQUEST: "CONTENTLIBRARY_MESSAGE_UPDATE_REQUEST",
    CONTENTLIBRARY_MESSAGE_UPDATE_SUCCESS: "CONTENTLIBRARY_MESSAGE_UPDATE_SUCCESS",
    CONTENTLIBRARY_MESSAGE_UPDATE_FAILURE: "CONTENTLIBRARY_MESSAGE_UPDATE_FAILURE",

    // To Get All basequestion ID's Details
    CONTENTLIBRARY_GETBASEQUSID_REQUEST: "CONTENTLIBRARY_GETBASEQUSID_REQUEST",
    CONTENTLIBRARY_GETBASEQUSID_SUCCESS: "CONTENTLIBRARY_GETBASEQUSID_SUCCESS",
    CONTENTLIBRARY_GETBASEQUSID_FAILURE: "CONTENTLIBRARY_GETBASEQUSID_FAILURE",

    // To Get All questions Details
    CONTENTLIBRARY_GETALLQUESTIONS_REQUEST: "CONTENTLIBRARY_GETALLQUESTIONS_REQUEST",
    CONTENTLIBRARY_GETALLQUESTIONS_SUCCESS: "CONTENTLIBRARY_GETALLQUESTIONS_SUCCESS",
    CONTENTLIBRARY_GETALLQUESTIONS_FAILURE: "CONTENTLIBRARY_GETALLQUESTIONS_FAILURE",

    // To search with question and category text Recommendations
    CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_REQUEST: "CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_REQUEST",
    CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_SUCCESS: "CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_SUCCESS",
    CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_FAILURE: "CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_FAILURE",

    // To search with base question ID Recommendations
    CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_REQUEST: "CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_REQUEST",
    CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_SUCCESS: "CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_SUCCESS",
    CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_FAILURE: "CONTENTLIBRARY_RECOMMENDATIONS_SEARCH_BASEID_FAILURE",

    //To update Recommendation
    CONTENTLIBRARY_RECOMMENDATION_UPDATE_REQUEST: "CONTENTLIBRARY_RECOMMENDATION_UPDATE_REQUEST",
    CONTENTLIBRARY_RECOMMENDATION_UPDATE_FAILURE: "CONTENTLIBRARY_RECOMMENDATION_UPDATE_FAILURE",
    CONTENTLIBRARY_RECOMMENDATION_UPDATE_SUCCESS: "CONTENTLIBRARY_RECOMMENDATION_UPDATE_SUCCESS",



    //Adding new Action Alert
    CONTENTLIBRARY_ONADD_ACTIONALERT: "CONTENTLIBRARY_ONADD_ACTIONALERT",


    //Adding new Report Content
    CONTENTLIBRARY_ONADD_REPORTCONTENT: "CONTENTLIBRARY_ONADD_REPORTCONTENT",

    // Update reducer data
    CONTENTLIBRARY_ACTIONALERT_REDUCER_UPDATE: "CONTENTLIBRARY_ACTIONALERT_REDUCER_UPDATE",
    CONTENTLIBRARY_RECOMMENDATION_QUESTIONS_REDUCER_UPDATE: "CONTENTLIBRARY_RECOMMENDATION_QUESTIONS_REDUCER_UPDATE",

    // Update reducer data
    CONTENTLIBRARY_REPORTCONTENT_REDUCER_UPDATE: "CONTENTLIBRARY_REPORTCONTENT_REDUCER_UPDATE",
}