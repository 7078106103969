import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import { isMobile } from "react-device-detect";

// Import Style
import TakeSurveyStyles from "./TakeSurveyStyles";

// Import Components
import LinearProgressComponent from "../../../components/LinearProgressComponent/LinearProgressComponent";
import AutoCompleteComponent from "../../../components/AutoCompleteComponent/AutoCompleteComponent";

// Import Logo
import IconLogo from "../../../assets/images/svg/logo.svg";
import ProfileImg from "../../../assets/images/default_profile.svg";

// Import Actions
import { meTakeSurveyAction } from "../../../redux/actions";

function TakeSurveyHeader(props) {
  const { classes, langCode } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  const clientData = useSelector((state) => state.meTakeSurvey.client);
  const participantData = useSelector((state) => state.meTakeSurvey.participant);
  const questionData = useSelector((state) => state.meTakeSurvey.questions);
  const { languages } = questionData;
  const { firstName, lastName } = participantData;
  const [activeLanguage, setActiveLanguage] = useState(langCode);
  const welcome_name_access = useSelector((state) => state?.meTakeSurvey?.survey_data?.welcome_name_access);
  useEffect(() => {
    dispatch(meTakeSurveyAction.updateSurveyLanguage(activeLanguage));
  }, [dispatch, activeLanguage]);

  const handleSurveyLanguage = (lang) => {
    setActiveLanguage(lang.code);
  };

  useEffect(()=>{
    console.log(welcome_name_access)
  },[welcome_name_access])
  const activeLangObject = languages.find((lang) => lang.code == langCode);
  return (
    <Grid className={`${classes.rootHeader} ${isMobile && languages.length > 1 ? classes.rootHeaderMobile : ""}`}>
      <LinearProgressComponent></LinearProgressComponent>
      <AppBar position="static" color="default" className={classes.headerAppBar}>
        <Toolbar variant="dense" className={classes.headerToolBar}>
          <Grid item className={classes.logo} sm={6}>
            <img src={clientData && clientData.logo ? clientData.logo : IconLogo} className={classes.headerLogoImg} onClick={() => {}} alt={clientData && clientData.name ? clientData.name : "People Element"} title={clientData && clientData.name ? clientData.name : "People Element"} />
          </Grid>
          <Grid item sm={6}>
            <Grid item className={classes.headerRightBlock}>
              {languages.length > 1 ? (
                <Grid item sm={4} className={"desktopShow"}>
                  <ValidatorForm onSubmit={() => {}}>
                    <AutoCompleteComponent
                      name={"clients"}
                      suggestions={languages}
                      handleAutoCompolete={handleSurveyLanguage}
                      value={activeLangObject}
                      placeholder={"Select Language"}
                      validators={[]}
                    ></AutoCompleteComponent>
                  </ValidatorForm>
                </Grid>
              ) : null}
              {participantData && welcome_name_access ? (
                <Grid item className={classes.headerUserInfo}>
                  <span>
                     {firstName ? firstName : ""} {lastName ? lastName : ""}
                  </span>
                  <Avatar alt="Resource" src={ProfileImg} />
                </Grid>
              ) : (
                <Grid item className={classes.headerUserInfo}>
                  <Avatar alt="Resource" src={ProfileImg} />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Toolbar>
        {languages.length > 1 ? (
          <div className={`mobileShow ${classes.mobileLangDropDown}`}>
            <ValidatorForm onSubmit={() => {}}>
              <AutoCompleteComponent
                name={"clients"}
                suggestions={languages}
                handleAutoCompolete={handleSurveyLanguage}
                value={activeLangObject}
                placeholder={"Select Language"}
                validators={[]}
              ></AutoCompleteComponent>
            </ValidatorForm>
          </div>
        ) : null}
      </AppBar>
    </Grid>
  );
}

// default props
TakeSurveyHeader.defaultProps = {
  classes: {},
  langCode: "en",
};

// prop types
TakeSurveyHeader.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
};

/**
 * Export Container
 */
export default withStyles(TakeSurveyStyles)(TakeSurveyHeader);
