import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import Tooltip from "@mui/material/Tooltip";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";

// Import Icons
import { SettingAccordGrey, SettingAccordWhite, CodeConversionGrey } from "../../../../assets/images/svgComponents/ImageComponent";


// Import Constants
import { participantConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import DatePickerComponent from "../../../../components/DatePickerComponent/CustomDatePicker";
import AsyncAutocomplete from "../../../../components/AsyncAutocomplete";

// import autocomplete service
import { metadataService } from "../../../../redux/services/metadataService"

function OtherDemographicsPanel(props) {
  // Assign Props
  const {
    classes,
    handleChange,
    participant,
    metadata,
    metadataWithRGI,
  } = props;

  //Accordion
  const [expanded, setExpanded] = React.useState("otherdemographic-panel");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  // variables
  const allowedTypes = ["demographic"];

  // Get auto complete options
  const getOptionsArray = ({ key, rgi }) => {
    if (rgi === null) return [""];
    if (typeof rgi[0] !== "string") {
      try {
        return metadataWithRGI.filter((d) => d.key === key)[0].rgi;
      } catch {
        // rgi not exist return [] options
        return metadataWithRGI.filter((d) => d.key === key);
      }
    }
    if (Array.isArray(rgi)) {
      return rgi;
    } else {
      return Object.keys(rgi).map((m) => rgi[m]);
    }
  };

  // generate field validators
  const generateValidators = (type) => {
    switch (type) {
      case "integer":
        return ["isNumber"];
      default:
        return [];
    }
  };

  const extraOptions = (data) => {
    return {
      options: getOptionsArray(data),
      filterOptions: (options, { inputValue }) => {
        const filteredOpt = options.filter((opt) => {
          if (
            opt &&
            String(opt).toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
          ) {
            return true;
          }
          return false;
        });

        if (filteredOpt.length === 0 && inputValue) {
          filteredOpt.push(`Create "${inputValue}"?`);
        }
        else if (filteredOpt.length === 0 && inputValue.length === 0 ) {
          return "";
        }
        return filteredOpt;
      },
      renderOption: (option, { selected }) => {
        return option;
      },
    };
  };

  const handleAutoCompleteChange = (value, name, metaData) => {
    if (value && String(value).indexOf("Create") >= 0) {
      value = value.replace(/Create/, "").replace(/["?]/g, "");
      value = value.trim();
      handleChange({ name, value }, metaData.dataType, true); // new option for metaData
    } else {
      handleChange({ name, value }, metaData.dataType);
    }
  };

  async function autcompleteAPI(name, val) {
    const data = await metadataService.getMetadataValues(name)
    if (data && data.data && data.data[0] && data.data[0].rgi) {
      return { data: data.data[0].rgi }
    }
    return { data: [] }
  }

  const renderAutocomplete = (m) => {

    // if (m.key === "region") {
      return (
        <AsyncAutocomplete
          name={m.key}
          apiCallback={autcompleteAPI}
          optionType="string"
          className={"heightone"}
          initialValue={participant[m.key] || null}
          value={participant[m.key] || null}
          handleChange={(name, value) => {
            handleAutoCompleteChange(value, name, m);
          }}
          extraProps={{ ...extraOptions(m) }}
        />
      )
    // }

    // return (
    //   <AutoCompleteComponent
    //     suggestionType="array"
    //     name={m.key}
    //     freeSolo={true}
    //     variant="outlined"
    //     value={participant[m.key] || null}
    //     fullWidth={true}
    //     suggestions={getOptionsArray(m)}
    //     handleAutoCompolete={(value, name) => {
    //       handleAutoCompleteChange(value, name, m);
    //     }}
    //     extraProps={{ ...extraOptions(m) }}
    //     placeholder={""}
    //     disableClearable={participant[m.key] ? false : true}
    //   />
    // )
    
  }

  return (
    <Accordion
      className="mb-6 pe-accordion"
      expanded={expanded === "otherdemographic-panel"}
      onChange={handlePanelChange("otherdemographic-panel")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {expanded === "otherdemographic-panel" ?
          <Grid className={"pr5"}>
            {SettingAccordWhite()}
          </Grid>
          :
          <Grid className={"pr5"}>
            {SettingAccordGrey()
            }
          </Grid>}
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${expanded === "otherdemographic-panel" ? "panel-active" : ""
            } `}
          variant={"h5"}
        >
          {participantConstants.OTHER_DEMOGRAPHY}
        </TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "otherdemographic-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{participantConstants.OTHER_DEMOGRAPHY_HELPTEXT}</TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid className="p-2 width100">
          <Grid container spacing={3}>
            {metadata
              .filter((a) => allowedTypes.indexOf(a.type) > -1)
              .map((m) => {
                if (m.visible === false) return;
                if (m.key === "state" || m.key === "country") return;
                return (
                  <Grid item md={6} xs={12}>
                    <Grid container alignItems="center">
                      <Grid item md={5} xs={12}>
                        <TypographyComponent className={classes.wordContainer} title={m.value} variant={"h6"}>
                          {m.conversion && m.conversion.length > 0 && (
                            <Tooltip arrow title="Other demographics conversion">
                            <IconButton
                              //disabled={true}
                              disableRipple={true}
                              color="inherit"
                              className={`${"p5 ml-1"} ${classes.codeicon}`}
                              size="large">
                              {CodeConversionGrey()}
                            </IconButton>
                            </Tooltip>
                          )}
                        </TypographyComponent>
                      </Grid>
                      <Grid item md={7} xs={12}>
                        {m.rgi ? (
                          renderAutocomplete(m)
                        ) : m.dataType === "date" ? (
                          <DatePickerComponent
                            id={m.key}
                            name={m.key}
                            value={participant[m.key] || ""}
                            className={classes.textField}
                            handleChange={(e) => handleChange(e, m.dataType)}
                            inputVariant="outlined"
                            margin="dense"
                            size="small"
                            fullWidth={true}
                            disabled={false}
                          />
                        ) : (
                              <TextFieldComponent
                                id={m.key}
                                name={m.key}
                                value={participant[m.key] || ""}
                                size={"small"}
                                margin={"none"}
                                handleChange={(e) => {
                                  handleChange(e, m.dataType);
                                }}
                                validators={generateValidators(m.dataType)}
                              />
                            )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

OtherDemographicsPanel.defaultProps = {
  metadata: [],
};

export default OtherDemographicsPanel;
