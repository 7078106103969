import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

// Import Constants
import { FilterConstants } from "../../../../config/constants";

// import images
import { Option } from "../../../../assets/images/svgComponents/ImageComponent";


const OptionsMenu = ({ onMenuSelect, menus,pop }) => {
  const [optionsPopup, setOptionsPopup] = useState(null);

  // Open options menu
  const OpenOptions = (event) => {
    setOptionsPopup(event.currentTarget);
  };

  // close options menu
  const handleClose = () => {
    setOptionsPopup(null);
  };

  return (
    <React.Fragment>
      <Tooltip arrow title={FilterConstants.MORE_OPTIONS}>
        <IconButton color="inherit" onClick={OpenOptions} className="p5 ml5" size="large">
          {Option()}
        </IconButton>
      </Tooltip>
      <Menu
        className={(pop || pop !== "") ? pop : ""}
        id="simple-menu"
        anchorEl={optionsPopup}
        keepMounted
        open={Boolean(optionsPopup)}
        onClose={handleClose}
        classes={{ list: 'no-pad-top' }}
      >
        {
          menus.map((m, i) => {
            return (
              <MenuItem key={`opt_me_${i}`} onClick={() => {setOptionsPopup(null); onMenuSelect(m._id)}}>
                {m.name}
              </MenuItem>
            )
          })
        }
      </Menu>
    </React.Fragment>
  );
};

// default props
OptionsMenu.defaultProps = {
  classes: {},
  match: {},
  onChange: () => { }
};

// prop types
OptionsMenu.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  onChange: PropTypes.func
};

export default OptionsMenu;
