import React from "react";
import withStyles from '@mui/styles/withStyles';
import { Drawer } from "@mui/material";

// Import Styles and Image
import TabStyle from "../styles/METabStyles";

const DrawerPanel = (props) => {

    const { classes, open, onClose } = props

    return (
        <>
            <Drawer
                className={`${classes.drawer} ${"pe_drawer"}`}
                anchor='right'
                variant="temporary"
                open={open}
                onClose={() => { onClose(false) }}
            >
                {props.children}
            </Drawer>
        </>
    )
}

export default withStyles(TabStyle)(DrawerPanel);