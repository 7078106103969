const ActionsStyles = () => ({

  // Tabs
  rightPanelAppBarRoot: {
    background: "transparent",
    height: "45px"
  },
  tabcharlimit:
  {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "175px"
  },
  rightPanelTabRoot: {
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#EBECED",
    margin: "0px 4px",
    color: "rgba(0, 0, 0, 0.87) !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#54C0FD"
    },
    "&:hover .peTabEdit, &:hover .peTabDelete": {
      visibility: "visible !important",
      filter: "brightness(0) invert(1)"
    },
    "&$selected": {
      color: "#fff",
      backgroundColor: "#54C0FD"
    },
    "&:focus": {
      color: "#fff !important",
      backgroundColor: "#54C0FD"
    }
  },
  rightPanelTabSelected: {
    backgroundColor: "#54C0FD !important",
    color: "#fff !important"
  },

  // Reports
  reports_Container: {
    position:"relative"
  },
  ActionsSettings: {
    position: "absolute",
    right:15,
    top:22
  },
  ActionsSettingsText:
  {
    width: '570px',
    textAlign: 'right',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  reports_header: {
    borderBottom: "1px solid #DDDDDD !important",
    margin: '20px 20px 0 20px'
  },
  ItemScore: {
    margin: '10px 0 0 25px!important',
    padding: '1px 10px 1px 10px !important',
    border: '2px solid #54C0FD'
  },

  FilterField:{
    overflowWrap: "anywhere"
  },
  dashboardAlertTitle: {
    color: "#292929 !important"
  },
  dashBoardInformation: {
    borderRadius: "6px",
    background: "#fff",
    padding: "20px",
    height: "100%",
    border: "1px solid #54C0FD"
  },

  Accord_icons:
  {
    width: '32px',
    height: '32px',
    marginRight: '10px',
    padding: '8px',
    background: '#EEEEEE',
    borderRadius: '30px',
    marginTop: '4px'
  },

  ActionItems: {
    borderRadius: "4px !important"
  },
  ActionItem_Box: {
    border: "1px solid #EAEBED",
    padding: "10px",
    backgroundColor:"#EEF7FE", 
    borderRadius: "10px"
  },

  Addaction_container: {
    border: '1px solid #EAEBED',
    borderRadius: "6px",
    padding: "0px",
    background: "#F4F6F7",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    // alignItems: "center",
    '& .MuiFormControl-root': {
      margin: "0px !important"
    },
    '& .MuiOutlinedInput-input': {
      padding: "0px !important"
    }
  },
  Addaction_container_customize:{
    backgroundColor:"#FFF",
    borderRadius: "10px"
  },
  ActionItem_container: {
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    '& .MuiFormControl-root': {
      margin: "0px !important"
    },
    '& .MuiOutlinedInput-input': {
      padding: "0px !important"
    }
  },
  ActionExpand: {
    boxShadow: "0px 3px 6px #00000014",
  },
  actionCard: {
    border: "1px solid #EAEBED",
    borderRadius: "4px",
    "& .MuiAccordionSummary-root.Mui-expanded": {
      border: "none",
    }
  },
  opportunityContainer: {
    // padding: "0 40px",
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '65.6px !important',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px'
    }
  },
  icon_btn: {
    padding: "0px 6px",
    "& .MuiIconButton-root": {
      padding: "0px",
    }
  },
  ActionMultiLineText: {
    "& .MuiOutlinedInput-input": {
      lineHeight: "18px",
      margin: "0px",
      fontSize: "14px"
    },
    "& .MuiOutlinedInput-multiline": {
      padding: "5px"
    }
  },
  ActionsSettingsIcon: {
    marginLeft: 10,
    padding: 5
  },
  ActionPlanNotes:
  {
    borderColor: "rgba(0, 0, 0, 0.23)",
    '&:hover': {
      borderColor: 'rgb(95 95 95 / 87%)'
    }
  },

  ViewButton:{
    color: 'white', 
    fontWeight: 'bold', 
  }

});

export default ActionsStyles;
