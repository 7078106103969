// Filter reducer types
export const filterConstant = {

  UPDATE_FILTER_REQUEST: 'UPDATE_FILTER_REQUEST',
  UPDATE_FILTER_SUCCESS: 'UPDATE_FILTER_SUCCESS',
  UPDATE_FILTER_FAILURE: 'UPDATE_FILTER_FAILURE',

  FILTER_GET_DEMOGRAPHICS_REQUEST: 'FILTER_GET_DEMOGRAPHICS_REQUEST',
  FILTER_GET_DEMOGRAPHICS_SUCCESS: 'FILTER_GET_DEMOGRAPHICS_SUCCESS',
  FILTER_GET_DEMOGRAPHICS_FAILURE: 'FILTER_GET_DEMOGRAPHICS_FAILURE',

  FILTER_GET_DEMOGRAPHICS_DDN_REQUEST: 'FILTER_GET_DEMOGRAPHICS_DDN_REQUEST',
  FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS: 'FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS',
  FILTER_GET_DEMOGRAPHICS_DDN_FAILURE: 'FILTER_GET_DEMOGRAPHICS_DDN_FAILURE',

  FILTER_GET_SURVEYS_REQUEST: 'FILTER_GET_SURVEYS_REQUEST',
  FILTER_GET_SURVEYS_SUCCESS: 'FILTER_GET_SURVEYS_SUCCESS',
  FILTER_GET_SURVEYS_FAILURE: 'FILTER_GET_SURVEYS_FAILURE',

  FILTER_GET_ALL_SURVEYS_REQUEST: 'FILTER_GET_ALL_SURVEYS_REQUEST',
  FILTER_GET_ALL_SURVEYS_SUCCESS: 'FILTER_GET_ALL_SURVEYS_SUCCESS',
  FILTER_GET_ALL_SURVEYS_FAILURE: 'FILTER_GET_ALL_SURVEYS_FAILURE',

  FILTER_DEMOGRAPHICS_BY_NAME_REQUEST: 'FILTER_DEMOGRAPHICS_BY_NAME_REQUEST',
  FILTER_DEMOGRAPHICS_BY_NAME_SUCCESS: 'FILTER_DEMOGRAPHICS_BY_NAME_SUCCESS',
  FILTER_DEMOGRAPHICS_BY_NAME_FAILURE: 'FILTER_DEMOGRAPHICS_BY_NAME_FAILURE',
};
