/* eslint-disable prefer-const */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { DesktopTimePicker as TimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import {
  TextField,
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { ValidatorComponent } from "react-material-ui-form-validator";
import moment from "moment";

const PickerDialog = React.forwardRef(({ children }, ref) => {
  const {
    children: [PopElement],
  } = children.props;
  const { DialogProps } = PopElement.props;
  const { onCancel, onOk } = DialogProps;
  return (
    <Dialog className="custom-time-picker" open ref={ref} onClose={() => {}}>
      <DialogContent style={{ padding: "0px" }}>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} style={{ color: "#000" }}>
          Cancel
        </Button>
        <Button onClick={onOk} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const convertTimeToValue = (value) => {
  let newvalue = "0000-01-01T07:00:00";
  let arr = value.split(/:| /);
  if (arr[2] === "PM") {
    if (Number(arr[0]) !== 12) {
      arr[0] = Number(arr[0]) + 12;
    }
  }
  if (arr[2] === "AM") {
    if (Number(arr[0]) === 12) {
      arr[0] = "00";
    }
  }
  if (arr[0].toString().length === 1) {
    newvalue = "0000-01-01T0" + arr[0] + ":" + arr[1] + ":00";
  } else {
    newvalue = "0000-01-01T" + arr[0] + ":" + arr[1] + ":00";
  }
  return newvalue;
};

/**
 *TimePicker Component
 *
 * @class TimePicker
 * @extends {Component}
 */
function TimePickerComponent(props) {
  /**
   *render html
   *
   * @returns
   * @memberof TimePicker
   */
  const { name, margin, className, extraProps } = props;
  let { value } = props;

  let newvalue = "0000-01-01T07:00:00";
  if (value !== "" && !props.onValueChange) {
    newvalue = convertTimeToValue(value);
  }

  const [tempValue, setTempValue] = useState(
    props.onValueChange ? value : newvalue || null
  );

  // Handle TextField Events
  const handleChange = (event) => {
    // if this prop present no need to validate
    if (props.onValueChange && event) {
      let value = event;
      if (moment(event).isValid()) {
        value = moment(new Date(event)).format("LT");
      }
      props.onValueChange({ name: props.name, value, dateValue: event });
      return;
    }

    if (event) {
      let date = new Date(event);
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (!isNaN(hour) && !isNaN(minute)) {
        let finaltime = "";
        if (minute < 10) {
          minute = "0" + minute;
        }
        if (hour > 11) {
          if (hour !== 12) {
            hour = hour - 12;
          }
          finaltime = hour + ":" + minute + " PM";
        } else if (hour === 0) {
          finaltime = "12:" + minute + " AM";
        } else {
          finaltime = hour + ":" + minute + " AM";
        }
        setTempValue(convertTimeToValue(finaltime));
      }
    }
  };

  return (
    <TimePicker
      showToolbar
      closeOnSelect={false}
      value={tempValue}
      DialogProps={{
        onCancel: () => {},
        onOk: () => {
          if (moment(new Date(tempValue)).isValid()) {
            props.handleChange({ name: props.name, value: moment(new Date(tempValue)).format("LT") });
          } else {
            props.handleChange({ name: props.name, value: "" });
          }
        },
      }}
      TransitionComponent={PickerDialog}
      className={`${className} custom-time-picker`}
      onChange={handleChange}
      onClose={() => {}}
      renderInput={(props) => {
        props.inputProps.value = value;
        return (
          <TextField
            name={name}
            size="small"
            margin={margin}
            placeholder="09:00 AM"
            sx={{ svg: {color: "#C0C1C2"} }}
            {...extraProps}
            {...props}
          />
        );
      }}
    />
  );
}

// default props
TimePickerComponent.defaultProps = {
  name: "",
  value: "0000-01-01T07:00:00",
  className: "timepicker",
  margin: "dense",
  handleChange: () => {},
  renderInput: () => {},
  extraProps: {},
};

// prop types
TimePickerComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  extraProps: PropTypes.object,
  renderInput: PropTypes.func,
};
// Export Component
export default TimePickerComponent;

// Export Component
export class TimePickerValidator extends ValidatorComponent {
  getValue = (value) => {
    if (typeof value === "string") {
      return moment(`${moment().format("YYYY/MM/DD")} ${value}`);
    }
    return value;
  };

  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    const hText = (!isValid && this.getErrorMessage()) || helperText;

    return (
      <TimePickerComponent
        {...rest}
        value={this.getValue(this.props.value)}
        extraProps={{
          error: !isValid,
          helperText: hText,
        }}
      />
    );
  }
}
