import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";

/**
 *Confirm Dialog Component
 *
 * @class ConfirmDialogComponent
 * @extends {React.Component}
 */
function ConfirmDialogComponent(props) {
  /**Handle Dialog close Action  */

  const {
    handleConfirmAction,
    open,
    handleCloseAction,
    content,
    title,
    textTrueBtn,
    textFalseBtn,
    textNoBtn,
    handleNoAction,
    className
  } = props;

  const handleClose = () => {
    handleCloseAction();
  };

  /**Handle Dialog Delete Action */
  const handleConfirm = () => {
    handleConfirmAction();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={`p15 ${className}`}
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ paddingTop: 20, margin: "auto" }}>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          variant="contained"
          disableElevation
          size="small"
          color="primary"
        >
          {textTrueBtn}
        </Button>
        {
          textNoBtn && (
            <Button
              onClick={() => handleNoAction()}
              variant="contained"
              color="secondary"
              size="small"
              disableElevation
              autoFocus
            >
              {textNoBtn}
            </Button>
          )
        }
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          autoFocus
        >
          {textFalseBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

//default props
ConfirmDialogComponent.defaultProps = {
  open: false,
  id: null,
  content: "Do you want to continue this process?",
  handleConfirmAction: () => { },
  handleNoAction: () => { },
  handleCloseAction: () => { },
  textTrueBtn: "Confirm",
  textFalseBtn: "Cancel",
  className: "",
  textNoBtn: ""
};

//prop types
ConfirmDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.any,
  handleConfirmAction: PropTypes.func.isRequired,
  handleCloseAction: PropTypes.func.isRequired,
  textTrueBtn: PropTypes.string.isRequired,
  textFalseBtn: PropTypes.string.isRequired,
};

/**Export Component */
export default ConfirmDialogComponent;
