import React from "react";
import PropTypes from "prop-types";

// import child route
import ProgressPageComponent from "../../../../components/ProgressPageComponent/ProgressPageComponent";

// Import History
import history from "../../../../config/history";

function ProgressPage (props) {

  // Redirect to upload page
  const redirectURL = () => {
    history.push({ pathname: "/manage_people/participants" });
  };

  return (
    <ProgressPageComponent
      match={props.match}
      redirectURL={redirectURL}
    />
  );
}
// default props
ProgressPage.defaultProps = {
  match: {}
};

// prop types
ProgressPage.propTypes = {
  match: PropTypes.object
};
export default ProgressPage;
