import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

// Import Actions
import { alertAction } from "../../redux/actions";

// Import Styles
import SnackBarComponentStyle from "./SnackBarComponentStyle";

function TransitionDown (props) {
  return <Slide {...props} direction="down" />;
}

function SnackBarComponent (props) {
  /**
   * Redux Action and Reducer
   */
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alert);

  /**
   *
   * @param {*} event
   * @param {*} reason
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch(alertAction.clear());
  };

  /**
   *
   */
  const handleExited = () => {
    dispatch(alertAction.clear());
  };

  /**
   *
   */
  const { classes } = props;
  const { type, message, duration } = alert;
  
  return (
    <Snackbar
      key={type || undefined}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right"
      }}
      ContentProps={{
        classes: {
          root: classes[type]
        }
      }}
      open={!!type}
      TransitionComponent={TransitionDown}
      autoHideDuration={duration? duration: 3000}
      onClose={handleClose}
      message={<span dangerouslySetInnerHTML={{ __html: message }}></span>}
      action={
        <React.Fragment>
          <IconButton
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            size="large">
            <CloseIcon />
          </IconButton>
        </React.Fragment>
      }
      TransitionProps={{
        onExited: handleExited
      }} />
  );
}

// default props
SnackBarComponent.defaultProps = {
  classes: {}
};

// prop types
SnackBarComponent.propTypes = {
  classes: PropTypes.object
};

// export component
export default withStyles(SnackBarComponentStyle)(SnackBarComponent);
