/*eslint no-duplicate-imports: "error"*/
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

// Import Container
import Container from "../Containers/Container";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import SwitchComponent from "../../components/SwitchComponent/SwitchComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import SliderComponent from "../../components/SliderComponent";
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";
import InformationComponent from "../../components/InformationComponent/InformationComponent";

// Import Actions
import { alertAction, clientAction, profileAction, metadataAction, adminsAction, filterAction, updateStateReducerAction } from "../../redux/actions";

// Import Image
// eslint-disable-next-line camelcase
import Profile_IMG from "../../assets/images/svg/logo.svg";
import CreateIcon from "../../assets/images/svg/editprofile.svg";

// Import Constants
import { clientConstants, errorsConstants, surveySettingsConstants } from "../../config/constants";

// Import Reducer Constant
import { profileConstant, clientConstant } from "../../redux/constants";

// Import Style
import ClientStyles from "./ClientStyles";
import "./client.css";

// Import History
import history from "../../config/history";

// Import To Get Page Access Details
import { redirectPage } from "../../config/pageAccess";

function ClientNewEdit(props) {
  // Get Props
  const { classes, user } = props;
  const rPage = redirectPage(user);

  // Define Dispatch
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateStateReducerAction.update(clientConstant.CLEAR_CLIENT_GETONE))
  }, [])

  // Get Client By Id
  const industries = useSelector((state) => state.client.industries);

  const [newThreshold, setNewThreshold] = useState(3)

  // Set State
  const [client, setClient] = useState({
    name: "",
    logo: Profile_IMG,
    sharefile: "",
    industry: "",
    threshold: "3",
    smsAccess: false,
    transfloAccess: false,
    phoneAccess: false,
    emailAccess: false,
    rawDataAccess: false,
    samlssoAccess: false,
    transflo_recipient: "",
    transflo_auth: "",
    sso_attributeName: "",
    language_translation: false,
    manager_effectiveness: false,
  });

  const handleSurveyThreshold = (value) => {
    setNewThreshold(value)
    handleChange({ name: "threshold", value })
  }

  /**
  * Handle form change
  */
  const handleChange = (e) => {
    const { name, value } = e;
    setClient((client) => ({ ...client, [name]: value }));
  };

  /**
   *Handle Cancel Navigage to Tab based on Roles
   */
  const handleCancel = () => {
    history.goBack()
  };

  /**
   * Handle Form Submit
   */
  let handleSubmit = (e) => {
    dispatch(clientAction.create(client, () => {
      dispatch(updateStateReducerAction.update(profileConstant.PROFILE_UPDATE_PREFERENCE_CLIENTID_SUCCESS, []))
      // Get Client API Integration Access after saving the client
      dispatch(clientAction.getOne())
      dispatch(metadataAction.getClientMetadata(() => {
        dispatch(metadataAction.getMetadataWithRGI(() => {
          dispatch(clientAction.getIndustry(() => {
            dispatch(filterAction.getAllSurveys(() => {
              dispatch(profileAction.user(() => {
                dispatch(adminsAction.getReportPreference(() => {
                  history.push({
                    pathname: `/${rPage}`
                  });
                }))
              }));
            }));
          }));
        }))
      }));
    }));
  };

  /**
     * Profile image change handler
     */
  let imageHandler = e => {
    const validExts = [".png", ".jpeg", ".jpg"];
    let fileExt = e.target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));

    if (validExts.indexOf(fileExt) < 0) {
      dispatch(alertAction.error(errorsConstants.invalidImageFormat))
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setClient((client) => ({ ...client, ["logo"]: reader.result }));
        }
      };
      try {
        reader.readAsDataURL(e.target.files[0]);
      } catch (err) {
        console.error(err);
      }
    }
  };

  /**
  * Switch Toggles
  */
  let generateSwitches = (permissions) => {
    return permissions.map((data, index) => {
      return <Grid item xs={12} className="pb-1" key={`switch-${index}`}>
        <SwitchComponent item xs={12}
          name={data.name}
          color="primary"
          size="small"
          checked={client[data.name]}
          handleChange={(e) => { handleChange(e); }}
          label={
            <span>
              <TypographyComponent
                variant="h6"
              >{data.label}</TypographyComponent>
            </span>
          }
        />
      </Grid>;
    });
  };

  return (
    <React.Fragment>
      <Grid className={classes.page_title}>
        <TypographyComponent variant={"h3"}
          title={"Add Client"}
        />
      </Grid>
      <Container page={"sub"}>
        <Grid className={"container mt-8"}>
          <Badge
            className={classes.client_profileBadge}
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={<Grid className="image-upload">
              <label htmlFor="file-input">
                <img className={classes.profile_editicon} src={CreateIcon} alt="create" />
              </label>

              <input id="file-input" onChange={imageHandler} type="file" accept=".png,.jpeg,.jpg" />
            </Grid>}
          >

          </Badge>
          <Paper className="borderRadius6" elevation={1}>
            <Box p={5} >
              <ValidatorForm
                name="ClientNewForm"
                useref="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                onError={(errors) => console.log(errors)}
              >
                <Grid container justifyContent="center">
                  <img src={client.logo ? client.logo : Profile_IMG} className={classes.Client_Profile} alt="avatar" />
                </Grid>

                <Grid container spacing={2} className="pt-3">
                  <Grid item xs={8}>

                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                        <TypographyComponent
                          title={clientConstants.CLIENT_NAME}
                          variant={"h6"}
                        ></TypographyComponent>
                        <TextFieldComponent
                          id={"client_name"}
                          name={"name"}
                          size={"small"}
                          value={client.name}
                          margin={"none"}
                          handleChange={(e) => handleChange(e)}
                          validators={["required"]}
                          className="height36"
                        ></TextFieldComponent>
                      </Grid>

                      <Grid item xs={12} lg={6} md={6} sm={6} className="lg-pr-80">
                        <TypographyComponent
                          title={clientConstants.SHARE_FILE}
                          variant={"h6"}
                        ></TypographyComponent>
                        <TextFieldComponent
                          id={"folder_name"}
                          size={"small"}
                          name={"sharefile"}
                          value={client.sharefile}
                          margin={"none"}
                          handleChange={(e) => handleChange(e)}
                          className="height36"
                          validators={["folderName"]}
                        ></TextFieldComponent>
                      </Grid>

                      <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                        <TypographyComponent
                          title={clientConstants.INDUSTRY}
                          variant={"h6"}
                        ></TypographyComponent>
                        <AutoCompleteComponent
                          name={"industry"}
                          suggestions={industries}
                          handleAutoCompolete={(event, name) => handleChange({ name, value: event.name })}
                          value={{ value: client.industry, name: client.industry }}
                          placeholder={"Select Industry"}
                          validators={["required"]}
                          showErrorMessages={true}
                          className="height36"
                        />
                      </Grid>

                      <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                        <TypographyComponent
                          variant={"h6"}
                        >
                          {clientConstants.THRESHOLD}
                          <InformationComponent>
                            <TypographyComponent variant="tooltipbody">
                              {clientConstants.THRESHOLD_HELP}
                            </TypographyComponent>
                          </InformationComponent>
                        </TypographyComponent>

                        <SliderComponent
                          minValue={surveySettingsConstants.SURVEY_THRESHOLD_MIN_CLIENTPAGE}
                          maxValue={surveySettingsConstants.SURVEY_THRESHOLD_MAX}
                          defaultValue={surveySettingsConstants.SURVEY_THRESHOLD_MIN}
                          value={newThreshold}
                          onSelect={handleSurveyThreshold}
                        />

                        {/* <AutoCompleteComponent
                          name={"threshold"}
                          suggestions={clientConstants.THRESHOLDS()}
                          handleAutoCompolete={(event, name) => handleChange({ name, value: event })}
                          value={client.threshold}
                          placeholder={"Select Threshold"}
                          validators={["required"]}
                          showErrorMessages={true}
                          suggestionType={"array"}
                          className="height36"
                        /> */}
                      </Grid>

                      {client.transfloAccess &&
                        <React.Fragment>
                          <Grid item sm={12} className={`p-0 ${classes.hr}`}></Grid>

                          <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                            <TypographyComponent
                              title={clientConstants.RECIPIENT_ID}
                              variant={"h6"}
                            ></TypographyComponent>
                            <TextFieldComponent
                              id={"recepient_id"}
                              name={"transflo_recipient"}
                              size={"small"}
                              value={client.transflo_recipient}
                              margin={"none"}
                              handleChange={(e) => handleChange(e)}
                              validators={["required"]}
                              className="height36"
                            ></TextFieldComponent>
                          </Grid>

                          <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                            <TypographyComponent
                              title={clientConstants.AUTH_TOKEN}
                              variant={"h6"}
                            ></TypographyComponent>
                            <TextFieldComponent
                              id={"auth_token"}
                              size={"small"}
                              name={"transflo_auth"}
                              value={client.transflo_auth}
                              margin={"none"}
                              handleChange={(e) => handleChange(e)}
                              validators={["required"]}
                              className="height36"
                            ></TextFieldComponent>
                          </Grid>
                        </React.Fragment>
                      }

                      {client.samlssoAccess &&
                        <React.Fragment>
                          <Grid item sm={12} className={`p-0 ${classes.hr}`}></Grid>

                          <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                            <TypographyComponent
                              variant={"h6"}
                            >
                              {clientConstants.SSO_IDENTIFIER}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {clientConstants.SSO_IDENTIFIER_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TextFieldComponent
                              id={"attributeName"}
                              name={"sso_attributeName"}
                              size={"small"}
                              value={client.sso_attributeName}
                              margin={"none"}
                              handleChange={(e) => handleChange(e)}
                              validators={[]}
                              className="height36"
                            ></TextFieldComponent>
                          </Grid>
                        </React.Fragment>
                      }
                    </Grid>
                  </Grid>

                  <Grid item xs={4} className="lg-pl-30">
                    <Grid container justifyContent='center'>
                      <Grid item xs={12} className="bgGrey  p-3">
                        <TypographyComponent
                          title={"Enable Permission"}
                          variant={"h4"}
                          className="txtBold pb-2"
                        />
                        <Grid>
                          {generateSwitches(clientConstants.PERMISSIONS)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} className={"mt-2"}>
                    <Grid container justifyContent={"flex-end"}>
                      <Grid item>
                        <ButtonComponent
                          title={"Save"}
                          color={"primary"}
                          size={"small"}
                          type="submit"
                        ></ButtonComponent>
                        <ButtonComponent
                          title={"Cancel"}
                          color={"secondary"}
                          size={"small"}
                          pageClassName="ml-1"
                          handleClick={handleCancel}
                        ></ButtonComponent>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Box>
          </Paper>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

export default withStyles(ClientStyles)(ClientNewEdit);
