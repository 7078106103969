import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import "./DonutChartStyles.css";

const DonutChart = (props) => {

  // Get props value
  const { chartData, layoutData, domainArr, rangeArr, chartId, chartName, textFont, tooltip_text } = props;

  //console.log("donut chart", chartId)

  //assign chart datas and dimensions
  //default data format = [{ name: "Actively Engaged", value: 40 }];
  let data = chartData ? chartData : [];
  let width = layoutData.width;
  let height = layoutData.height;
  let thickness = layoutData.thickness;
  let text = chartName ? chartName : "";

  /**
   * initial call for chart binding
   */
  useEffect(() => {
    donutChartBinding();
  });

  /**
   * Donut Chart Generation
   */
  const donutChartBinding = () => {

    //calculate radius based in the width and height
    let radius = Math.min(width, height) / 2;

    /**
     *define color for each arc in bar
     *pass grup values as domainarr
     *pass colr array as raneArr 
     */
    let color = d3.scaleOrdinal()
      .domain(domainArr)
      .range(rangeArr);


    d3.select(`#${chartId}`).selectAll("*").remove();
    //append svg vhart to the ID
    let svg = d3.select(`#${chartId}`)
      .append('svg')
      .attr('class', 'pie')
      .attr('width', width)
      .attr('height', height);

    let g = svg.append('g')
      .attr('transform', 'translate(' + (width / 2) + ',' + (height / 2) + ')');

    let arc = d3.arc()
      .innerRadius(radius - thickness)
      .outerRadius(radius);

    let pie = d3.pie()
      .value(function (d) { return d.value; })
      .sort(null);
      
    let tooltip = d3.select(`#${chartId}`)
      .append('div')
      .attr('class', 'tooltip');

    tooltip.append('div')
      .attr('class', 'nameTool');

    //data binding in chart with tooltip functionality
    g.selectAll('path')
      .data(pie(data))
      .enter()
      .append("g")
      .on("mouseover", function (s, d) {
        let htmlData = `<div style="font-weight:600;font-size:14px;margin-bottom:10px;padding:2px;border-bottom:1px solid #b5b5b5">
        <span style="background:${rangeArr[data.indexOf(d.data)]};width:24px;height:12px;margin-right:4px;padding-right:20px"></span>
        ${d.data.name}
        </div>`
        if(tooltip_text !== "YesNo"){
          htmlData += `<p style="padding:0px;margin:0px;display:flex;text-align:left">
                    <span style="width:68%;padding-right:10px"> ${tooltip_text} </span>
                    <span style="width:10%;text-align:right;padding-right:4px;margin-left:10px"> ${Math.round(d.data.value)}%</span>
                    </p>`
        }
        else{
          htmlData += `<p style="padding:0px;margin:0px;display:flex;text-align:left">
                    <span style="width:100%;text-align:center;padding-right:4px;"> ${Math.round(d.data.value)}%</span>
                    </p>`
        }
        tooltip.select('.nameTool').html(htmlData);
        tooltip.style('display', 'block');
        tooltip.style('top', (window.event.layerY - 130) + 'px')
        tooltip.style('left', (window.event.layerX - 40) + 'px');
      })
      .on('mousemove', function (d) {
        tooltip.style('top', (window.event.layerY - 130) + 'px')
          .style('left', (window.event.layerX - 40) + 'px');
      })
      .on("mouseout", function (d) {
        tooltip.style('display', 'none');
      })
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => color(i))
      .on("mouseover", function (d) {
        d3.select(this)
          .style("cursor", "pointer")
          .attr("fill", "#B8B8B8")
      })
      .on("mouseout", function (d) {
        d3.select(this)
          .style("cursor", "none")
          .attr("fill", color(this._current))
      })
      .each(function (d, i) { this._current = i; });

    //text inside the each arc
    g.selectAll('g')
      .append("text")
      .attr("transform", function (d) { return "translate(" + arc.centroid(d) + ")"; })
      .attr("fill", "#000")
      .attr('text-anchor', 'middle')
      .style('font-size', '12px')
      .text(function (d, i) { if ((data[i].value) > 9) { return Math.round(data[i].value) + "%"; } else { return "" } })

    //pass the chart name as text here form parent component 
    g.append('text')
      .attr('text-anchor', 'middle')
      .attr('dy', '.35em')
      .style('font-size', textFont)
      .text(text);

  };

  return <div id={`${chartId}`} className="donutchart"></div>;
};

// default props
DonutChart.defaultProps = {
  classes: {},
  layoutData: { width: 140, height: 180, thickness: 30 },
  chartData: [],
  rangeArr: [],
  chartId: "donutChart",
  chartName: "",
  textFont: 13,
  tooltip_text: ""

};
// prop types
DonutChart.propTypes = {
  classes: PropTypes.object,
  layoutData: PropTypes.object,
  chartData: PropTypes.array,
  rangeArr: PropTypes.array,
  domainArr: PropTypes.array,
  chartId: PropTypes.string,
  chartName: PropTypes.string,
  textFont: PropTypes.string,
  tooltip_text: PropTypes.string,
};

export default DonutChart;
