import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { IconButton } from "@mui/material";

// Import Styles and Image
import FailedPageComponentStyle from "./FailedPageComponentStyle";
import CloudImg from "../../assets/images/svg/cloud_failed.svg";
import BackImg from "../../assets/images/svg/chev_back.svg";
import CloseImg from "../../assets/images/svg/close_orange.svg";

// Import Component
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Container from "../../containers/Containers/Container";

function FailedPageComponent(props) {
  // get props
  const { classes, redirectURL, errorData } = props;

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p-2"
      >
        <Grid item>
          <Grid container alignItems="center">
            <IconButton
              color="inherit"
              className="p5"
              onClick={() => {redirectURL()}}
              size="large">
              <img className={classes.backimg} src={BackImg} alt="back"></img>
            </IconButton>
            <TypographyComponent
              variant={"h3"}
              className="ml-1"
            ></TypographyComponent>
          </Grid>
        </Grid>
      </Grid>

      <Container page={"sub"} justifyContent="center">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.fileUploadContainer}
        >
          <Grid item>
            <Grid item sm={12} className="pb-2">
              <Grid container justifyContent="center">
                <Grid className="relative">
                  <img src={CloudImg} alt="Cloud"></img>
                  <img
                    src={CloseImg}
                    className={classes.uploadFail_img}
                    alt="close"
                  ></img>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={12} className="pb-2">
              <TypographyComponent
                variant="h2"
                align="center"
                className="txtBold"
              >
                {"File upload failed"}
              </TypographyComponent>
            </Grid>

            <Grid item sm={12}>
              <TypographyComponent
                variant="h3"
                className="txtRegular"
                align="center"
              >
                {errorData}
              </TypographyComponent>
            </Grid>

            <Grid item sm={12} className="pt-3">
              <Grid container justifyContent="center">
                <ButtonComponent
                  title={"Upload"}
                  color={"primary"}
                  size={"small"}
                  pageClassName="mr-1"
                  handleClick={redirectURL}
                ></ButtonComponent>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
FailedPageComponent.defaultProps = {
  classes: {},
  redirectURL: () => {},
  errorData: "error in upload",
};

// prop types
FailedPageComponent.propTypes = {
  classes: PropTypes.object,
  redirectURL: PropTypes.func,
  errorData: PropTypes.string,
};
export default withStyles(FailedPageComponentStyle)(FailedPageComponent);
