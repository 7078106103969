import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import AssignedCalls from "./AssignedCalls";
import CallerQueue from "./CallerQueue";
import SurveyQueue from "./SurveyQueue";
import Search from "./Search";
import CallerDetail from "./CallerDetail";

function SpCallerRouter (props) {
  const { match } = props;
  return (
    <Switch>
      <Route exact path={match.url} render={(props) => {
        return <SurveyQueue
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/assignedcalls`} render={(props) => {
        return <AssignedCalls
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/caller-queue/:survey_id`} render={(props) => {
        return <CallerQueue
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/survey-queue`} render={(props) => {
        return <Search
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/caller-detail/:survey_id/:spcaller_id`} render={(props) => {
        return <CallerDetail
          match={props.match}
          {...props} />;
      }} />

    </Switch>
  );
}

/**
 * Bind Component Property Types
 */
SpCallerRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default SpCallerRouter;
