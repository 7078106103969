import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import ContentLibrary from "./ContentLibrary";
import ActionAlert from "./ContentLibraryTabs/ActionAlert";
import Message from "./ContentLibraryTabs/Message";
import ReportContent from "./ContentLibraryTabs/ReportContent";
import Recomendations from "./ContentLibraryTabs/Recomendations";

function ContentLibraryRouter(props) {
  const { match } = props;
  return (
    <Switch>
      <Route
        exact
        path={match.url}
        render={(props) => {
          return <ActionAlert {...props} />
        }}
      />

      <Route
        exact
        path={`${match.url}/report-content`}
        render={(props) => {
          return <ReportContent {...props} />
        }}
      />

      <Route
        exact
        path={`${match.url}/message`}
        render={(props) => {
          return <Message {...props} />
        }}
      />
      <Route
        exact
        path={`${match.url}/recommendation`}
        render={(props) => {
          return <Recomendations {...props} />
        }}
      />
    </Switch>
  );
}

/**
 * Bind Component Property Types
 */
ContentLibraryRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default ContentLibraryRouter;
