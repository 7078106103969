/* eslint-disable no-unneeded-ternary */
/* eslint-disable quote-props */
/* eslint-disable prefer-const */
import { surveyQuestionsConstants } from "../config/constants";

/**
 * check Questions Data Set for empty values
 */
export const checkQuestionsDataSet = (questionsData, settings) => {
  try {
    const { questions, catDetails, catOrder, languages } = questionsData;

    let nullCatName = false;
    let nullQuestionText = false;
    let nullAnsSet = false;
    let duplicateQues = false;
    let duplicateBaseQues = false;
    let emptySubQues = false;
    let duplicateSubQues = false;
    let langKey = languages.map((item, value) => { return item.code; });
    let emptyCategory = {};
    let empCategory = false;
    let isEdit = false;
    const errors = {}

    // return if empty questions in survey
    if (questions.length <= 0) {
      return {
        questionValidations: surveyQuestionsConstants.NO_QUESTIONS
      };
    }

    // check and return if empty categories are found
    for (let i = 0; i < catOrder.length; i++) {
      for (let j = 0; j < questions.length; j++) {
        if (!emptyCategory[catOrder[i]]) {
          emptyCategory[catOrder[i]] = 0;
        }
        if (questions[j].category === catOrder[i]) {
          emptyCategory[catOrder[i]]++;
        }
      }
    }
    for (let key in emptyCategory) {
      if (emptyCategory[key] === 0) {
        empCategory = true;
      }
    }
    if (empCategory) {
      return {
        questionValidations: questionsData.template==="Manager 360"?"Please add questions or delete empty competency":"Please add questions or delete empty category"
      }
    }

    // check category name for null in catDetails object
    for (let key in catDetails) {
      langKey.map((code) => {
        if (!catDetails[key][code] || !catDetails[key][code].name || catDetails[key][code].name.trim() === "") {
          nullCatName = true;
        }
      });
    }
    if (nullCatName) {
      return {
        questionValidations: questionsData.template==="Manager 360"?surveyQuestionsConstants.EMPTY_COMPETENCY_NAME:surveyQuestionsConstants.EMPTY_CATEGORY_NAME
      };
    }

    //check if the questions have yes/no and matix when survey type is Pulse

    if (settings && settings.frequency && settings.frequency === "Pulse") {
      let matqueslist = questions.filter(item => item.qusType === "Matrix");
      let yesnoqueslist = questions.filter(item => item.qusType === "Yes/No");
      if (matqueslist.length > 0 || yesnoqueslist.length > 0) {
        return {
          questionValidations: surveyQuestionsConstants.PULSE_SURVEY_WITH_MATRIX
        };
      }
    }

    // check questions for null or duplicate
    for (let l = 0; l < langKey.length; l++) {
      const code = langKey[l];
      if (!errors[code]) {
        errors[code] = []
      }
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        // check for null values
        if (question.editable && question.editable === true) {
          isEdit = true;
        }
        if (!(question.text[code]) || (question.text[code]).trim() === "") {
          errors[code].push(`${question.category}_${question.baseQusId}_question`)
          nullQuestionText = true;
        }
        if (question.qusType !== surveyQuestionsConstants.OPEN_ENDED) {
          if (question.ansSet) {
            if (!(question.ansSet[code])) {
              errors[code].push(`${question.category}_${question.baseQusId}_question`)
              nullAnsSet = true;
            } else if (Object.keys(question.ansSet.en).length !== Object.keys(question.ansSet[code]).length) {
              errors[code].push(`${question.category}_${question.baseQusId}_question`)
              nullAnsSet = true;
            } else {
              for (let key in question.ansSet[code]) {
                if (!(question.ansSet[code][key]) || (question.ansSet[code][key]).trim() === "") {
                  errors[code].push(`${question.category}_${question.baseQusId}_question`)
                  nullAnsSet = true;
                }
              }
            }
          }
        } else {
          // nullAnsSet=false;
        }
        // check for duplicates
        for (let j = i + 1; j < questions.length; j++) {
          if (questions[i].baseQusId === questions[j].baseQusId) {
            duplicateBaseQues = true;
          } else if ((questions[i].text[code]).toLowerCase() === (questions[j].text[code]).toLowerCase()) {
            duplicateQues = true;
          }
        }

        // check gor null and duplicates in matrix sub questions
        if (question.qusType === surveyQuestionsConstants.MATRIX) {
          if (!question.subQues || question.subQues.length === 0) {
            emptySubQues = true;
          } else if (question.subQues.length > 0) {
            for (let k = 0; k < question.subQues.length; k++) {
              if (!question.subQues[k].text[code] || question.subQues[k].text[code].trim() === "") {
                emptySubQues = true;
              }
              let subText = question.subQues[k].text[code] ? question.subQues[k].text[code] : "";
              for (let j = k + 1; j < question.subQues.length; j++) {
                if (subText === question.subQues[j].text[code]) {
                  duplicateSubQues = true;
                }
              }
            }
          }
        }
      }

      // delete error lang if no errors
      if (errors[code].length === 0) {
        delete errors[code]
      }

    }

    // return statements
    if (nullQuestionText) {
      return {
        questionValidations: surveyQuestionsConstants.EMPTY_QUESTION,
        errors
      }
    }
    if (nullAnsSet) {
      return {
        questionValidations: surveyQuestionsConstants.EMPTY_ANS_CHOICE,
        errors
      };
    }
    if (duplicateBaseQues) {
      return {
        questionValidations: surveyQuestionsConstants.DUPLICATE_BASE_QUES_ID,
        errors
      };
    }
    if (duplicateQues) {
      return {
        questionValidations: surveyQuestionsConstants.DUPLICATE_QUESTION,
        errors
      };
    }
    if (emptySubQues) {
      return {
        questionValidations: surveyQuestionsConstants.EMPTY_SUB_QUESTION,
        errors
      };
    }
    if (duplicateSubQues) {
      return {
        questionValidations: surveyQuestionsConstants.DUPLICATE_SUB_QUESTION,
        errors
      };
    }
    if (isEdit) {
      return {
        questionValidations: surveyQuestionsConstants.SAVE_QUESTION,
        errors
      };
    }
    return { questionValidations: true, errors };
  } catch (err) {
    return "Error occured";
  }
};

// Add new language to questions
export const addLanguageInQuestion = (questionsData) => {
  const { questions, catDetails, languages } = questionsData;

  languages.map((item, value) => {
    let langCode = item.code;

    // Add new language to categories
    for (let key in catDetails) {
      if (!catDetails[key][langCode]) {
        catDetails[key][langCode] = { "name": "", "desc": "" };
      }
    }

    // Add new Language to Questions
    for (let i = 0; i < questions.length; i++) {
      questions[i].text[langCode] = questions[i].text[langCode] ? questions[i].text[langCode] : "";

      if (questions[i].qusType !== surveyQuestionsConstants.OPEN_ENDED) {
        questions[i].ansSet = questions[i].ansSet ? questions[i].ansSet : {};
        questions[i].ansSet[langCode] = questions[i].ansSet[langCode] ? questions[i].ansSet[langCode] : {};
      }
      if (questions[i].qusType === surveyQuestionsConstants.MATRIX) {
        if (!questions[i].subQues) {
          questions[i].subQues = [];
          questions[i].subQues.push({ "id": `subQues-${Math.random(new Date().getTime())}`.replace(".", ""), "text": { "en": "" } });
        }

        for (let j = 0; j < questions[i].subQues.length; j++) {
          if (!questions[i].subQues[j].text[langCode]) {
            questions[i].subQues[j].text[langCode] = "";
          }
        }
      }
    }
  });
  return { ...questionsData, questions, catDetails };
};

export const deleteLanguageInQuestion = (questionsData) => {
  const { questions, catDetails, languages } = questionsData;

  let langKey = languages.map((item, value) => { return item.code; });

  // delete the category of language
  for (let key in catDetails) {
    for (let lang in catDetails[key]) {
      if (langKey.indexOf(lang) === -1) {
        delete catDetails[key][lang];
      }
    }
  }

  // delete Language in Questions //currently not using
  for (let i = 0; i < questions.length; i++) {
    for (let lang in questions[i].text) {
      if (langKey.indexOf(lang) === -1) {
        delete questions[i].text[lang];
      }
    }

    if (questions[i].qusType !== surveyQuestionsConstants.OPEN_ENDED) {
      for (let lang in questions[i].ansSet) {
        if (langKey.indexOf(lang) === -1) {
          delete questions[i].ansSet[lang];
        }
      }
    }

    if (questions[i].qusType === surveyQuestionsConstants.MATRIX) {
      for (let j = 0; j < questions[i].subQues.length; j++) {
        for (let lang in questions[i].subQues[j].text) {
          if (langKey.indexOf(lang) === -1) {
            delete questions[i].subQues[j].text[lang];
          }
        }
      }
    }
  }
  return { ...questionsData, questions, catDetails };
};

// add all languages to new Question
export const addLanguageInNewQuestion = (question, languages) => {
  languages.map((item, value) => {
    let langCode = item.code;

    question.text = question.text ? question.text : {};
    question.text[langCode] = question.text[langCode] ? question.text[langCode] : "";

    if (question.qusType !== surveyQuestionsConstants.OPEN_ENDED) {
      question.ansSet = question.ansSet ? question.ansSet : {};
      question.ansSet[langCode] = question.ansSet[langCode] ? question.ansSet[langCode] : {};
    }
    if (question.qusType === surveyQuestionsConstants.MATRIX) {
      if (!question.subQues) {
        question.subQues = [];
        question.subQues.push({ "id": `subQues-${Math.random(new Date().getTime())}`.replace(".", ""), "text": { "en": "" } });
      }
      for (let j = 0; j < question.subQues.length; j++) {
        if (!question.subQues[j].text[langCode]) {
          question.subQues[j].text[langCode] = "";
        }
      }
    }
  });
  return question;
};

// check for duplicate questionID or questions
export const checkduplicateQuestion = (question, allQuestions, languages) => {
  let checkQuestion = [];
  let duplicateFound = false;
  if (!Array.isArray(question)) {
    checkQuestion.push(question);
  } else {
    checkQuestion = question;
  }
  for (let l = 0; l < languages.length; l++) {
    let langCode = languages[l].code;
    for (let q = 0; q < checkQuestion.length; q++) {
      for (let i = 0; i < allQuestions.length; i++) {
        if ((checkQuestion[q].text[langCode]) && (checkQuestion[q].baseQusId !== allQuestions[i].baseQusId) && (checkQuestion[q].text[langCode]).toLowerCase() === (allQuestions[i].text[langCode]).toLowerCase()) {
          duplicateFound = true;
          break;
        }
      }
    }
  }
  return duplicateFound;
};

// check for duplicate questionID or questions
export const checkduplicateQuestionInDND = (question, allQuestions, newQuestion = {}, languages = []) => {
  // Get Selected Language
  let selectedLanguage = languages.filter((obj, ind) => {
    return obj.active === true;
  });
  const langCode = selectedLanguage && selectedLanguage[0] && selectedLanguage[0].code ? selectedLanguage[0].code : "";

  let checkQuestion = [];
  let duplicateFound = false;
  let editable = false;
  if (!Array.isArray(question)) {
    checkQuestion.push(question);
  } else {
    checkQuestion = question;
  }
  // check edited Questions
  for (let i = 0; i < allQuestions.length; i++) {
    if (allQuestions[i].editable) {
      editable = true;
    }
  }
  if (newQuestion && Object.keys(newQuestion).length > 0) {
    editable = true;
  }
  for (let q = 0; q < checkQuestion.length; q++) {
    for (let i = 0; i < allQuestions.length; i++) {
      if (checkQuestion[q].baseQusId === allQuestions[i].baseQusId) {
        duplicateFound = true;
        break;
      }
    }
  }
  if (langCode !== "en") {
    return 3;
  } else if (duplicateFound) {
    return 2;
  } else if (editable) {
    return 1;
  } else {
    return false;
  }
};

// check Add question Data set
export const checkAddQuestion = (question, lKey) => {
  if (!question.text[lKey] || question.text[lKey].trim() === "") {
    return surveyQuestionsConstants.EMPTY_QUESTION;
  }
  if (question.qusType !== surveyQuestionsConstants.OPEN_ENDED) {
    // check answer set
    let emptyFound = false;
    let duplicateFound = false;
    let answerChoices = Object.values(question.ansSet[lKey]);

    if (answerChoices.length <= 0) {
      return surveyQuestionsConstants.EMPTY_ANS_CHOICE;
    }

    emptyFound = answerChoices.indexOf("") > -1 || answerChoices.indexOf(" ") > -1 ? true : false;
    answerChoices = answerChoices.map(v => v.toLowerCase());
    duplicateFound = new Set(answerChoices).size !== answerChoices.length;
    if (emptyFound) {
      return surveyQuestionsConstants.EMPTY_ANS_CHOICE;
    }
    if (duplicateFound) {
      return surveyQuestionsConstants.DUPLICATE_ANS_CHOICE;
    }

    if (question.qusType === surveyQuestionsConstants.MATRIX) {
      if (!question.subQues || question.subQues.length === 0) {
        return surveyQuestionsConstants.ADD_SUB_QUESTIONS;
      } else if (question.subQues.length > 0) {
        let emptySubQues = false;
        let duplicateSubQues = false;
        for (let i = 0; i < question.subQues.length; i++) {
          if (!question.subQues[i].text[lKey] || question.subQues[i].text[lKey].trim() === "") {
            emptySubQues = true;
          }
          let subText = question.subQues[i].text[lKey] ? question.subQues[i].text[lKey] : "";
          for (let j = i + 1; j < question.subQues.length; j++) {
            if (subText === question.subQues[j].text[lKey]) {
              duplicateSubQues = true;
            }
          }
        }
        if (emptySubQues) {
          return surveyQuestionsConstants.EMPTY_SUB_QUESTION;
        }
        if (duplicateSubQues) {
          return surveyQuestionsConstants.DUPLICATE_SUB_QUESTION;
        }
      }
    }
  }
  return false;
};

// check for duplicate questionID , questions
export const duplicateQuesFind = (questions, langCode) => {
  let duplicateQues = false;

  for (let i = 0; i < questions.length; i++) {
    for (let j = i + 1; j < questions.length; j++) {
      if (questions[i].baseQusId === questions[j].baseQusId) {
        duplicateQues = true;
      } else if (!questions[i].text[langCode] || (questions[i].text[langCode]).toLowerCase() === (questions[j].text[langCode]).toLowerCase()) {
        duplicateQues = true;
      }
    }
  }
  return duplicateQues;
};

// check for duplicate sub question in matrix
export const duplicateSubQuesFind = (question, lKey) => {
  let duplicateSubQues = false;

  if (question.subQues && question.subQues.length > 0) {
    for (let i = 0; i < question.subQues.length; i++) {
      if (!question.subQues[i].text[lKey] || question.subQues[i].text[lKey].trim() === "") {
        duplicateSubQues = true;
      }
      let subText = question.subQues[i].text[lKey] ? question.subQues[i].text[lKey] : "";
      for (let j = i + 1; j < question.subQues.length; j++) {
        if (subText.toLowerCase() === question.subQues[j].text[lKey].toLowerCase()) {
          duplicateSubQues = true;
        }
      }
    }
  }

  return duplicateSubQues;
};
