// import constants
import { queryBuilderConstant, uiConstant } from "../constants";
import { queryBuilderService } from "../services";
import { baseAction } from "./baseAction";
const { request, success, failure } = baseAction;

// Export All Querybuilder actions
export const queryBuilderAction = {
    getFieldNames,
    getFieldValues,
    handleQueryOnChange
};

/**
 *get field names for audience page
 *
 * @param {*} page 
 * @returns fields
 */
function getFieldNames(page="") {
    return (dispatch) => {
        dispatch(request(uiConstant.LOADING_TRUE));
        dispatch(request(queryBuilderConstant.GET_FIELD_NAMES_ON_REQUEST));
        queryBuilderService.getFieldNames().then(
            (data) => {
                dispatch(success(queryBuilderConstant.GET_FIELD_NAMES_ON_SUCCESS, { page, fields: data.data }));
                dispatch(request(uiConstant.LOADING_FALSE));
            },
            (error) => {
                dispatch(failure(queryBuilderConstant.GET_FIELD_NAMES_ON_FAILURE, error));
                dispatch(request(uiConstant.LOADING_FALSE));
            }
        );
    };
};

/**
 *get field values for audience page
 *
 * @param {*} page 
 * @returns fields
 */
function getFieldValues(page="", fieldName="") {
    return (dispatch) => {
        dispatch(request(uiConstant.LOADING_TRUE));
        dispatch(request(queryBuilderConstant.GET_FIELD_VALUES_ON_REQUEST));
        queryBuilderService.getFieldValues(fieldName).then(
            (data) => {
                dispatch(success(queryBuilderConstant.GET_FIELD_VALUES_ON_SUCCESS, { 
                    page, 
                    fieldValues: data.data,
                    fieldName
                }));
                dispatch(request(uiConstant.LOADING_FALSE));
            },
            (error) => {
                dispatch(failure(queryBuilderConstant.GET_FIELD_VALUES_ON_FAILURE, error));
                dispatch(request(uiConstant.LOADING_FALSE));
            }
        );
    };
};

function handleQueryOnChange(page, mongoQuery) {
    return {
        type: queryBuilderConstant.SET_QUERY_ON_CHANGE,
        data: { page, mongoQuery }
    }
}