import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";

//Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import CompetencySummaryCard from "./CompetencySummaryCard"

function CompetencySummaryContent(props) {

    const { classes, chartData, viewBy } = props
    let lastCatName = ""

    const getCatName = (currentCatName) => {
        if (lastCatName !== currentCatName && viewBy === "item") {
            lastCatName = currentCatName
            return (
                <TypographyComponent
                    variant={"h4"}
                    className="txtSemiBold item_group_heading fontSize15  paddingLeft15"
                >
                    {currentCatName}
                </TypographyComponent>
            )
        } else {
            return null
        }
    }

    return (
        <React.Fragment>
            {chartData.map((item, index) => {
                return (
                    <>
                        {getCatName(item.catName)}
                        <Grid
                            className={`mb-1 heightAuto ${classes.card_container} ${viewBy === "item" ? "item_group" : ""}`}
                            item xs={12}
                        >
                            <CompetencySummaryCard
                                classes={classes}
                                item={item}
                                viewBy={viewBy}
                            />
                        </Grid>
                    </>
                )
            })}
        </React.Fragment>
    );
}

// default props
CompetencySummaryContent.defaultProps = {
    classes: {},
    gridData: {},
    onClickButton: () => { }
};

// prop types
CompetencySummaryContent.propTypes = {
    classes: PropTypes.object,
    gridData: PropTypes.object,
    onClickButton: PropTypes.func
};

export default CompetencySummaryContent;