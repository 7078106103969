import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Tooltip, Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

// Styles
import MEStyles from "../styles/MEStyles";

// settigns icon data level access
import { Setting, DeleteOrange } from "../../../../../assets/images/svgComponents/ImageComponent";

// Import History
import history from "../../../../../config/history";

// Import Table Size
import { getTableHeight, getTableRowSize } from "../../../../../config/layout";

// Import Component
import TableComponent from "../../../../../components/TableComponent/TableComponent";
import DeleteDialogComponent from "../../../../../components/DeleteDialogComponent/DeleteDialogComponent";
import TableSelectButton, { getUpdatedParticipants } from "./TableSelectButton";

// Import Actions
import { meSurveyAction, alertAction } from "../../../../../redux/actions";

// Import helper
import { getQueryVariable } from "../../../../../helpers";

const SettingsIcon = ({ data, handleChange, classes }) => {
  // let isManager = data.relationship === 'Self';
  // if (isManager) return null
  return (
    <div className={classes.tableactions}>
      <Tooltip title="Settings" arrow>
        <Avatar
          alt="Setting"
          className={`tableIcon MuiAvatar-root MuiAvatar-circle ml5 mr5 scrollIntial cursor-pointer`}
          onClick={() => handleChange("ACTION_SETTING", data, null)}
        >
          <Grid>{Setting()}</Grid>
        </Avatar>
      </Tooltip>
    </div>
  );
};

const DeleteIcon = ({ data, handleChange, classes }) => {
  let isManager = data.relationship === 'Self';
  if (isManager) return <div style={{ width: '36px', height: '26px' }} />
  return (
    <div className={classes.tableactions}>
      <Tooltip title="Delete" arrow>
        <Avatar
          alt="Delete"
          className={`tableIcon MuiAvatar-root MuiAvatar-circle ml5 mr5 scrollIntial cursor-pointer`}
          onClick={() => handleChange("ACTION_DELETE", data, null)}
        >
          <Grid>{DeleteOrange()}</Grid>
        </Avatar>
      </Tooltip>
    </div>
  );
};

const actionComponent = {
  SETTING: SettingsIcon,
  DELETE: DeleteIcon
}

const customFilterComponent = {
  selected: TableSelectButton
}

const componentMap = {
  selected: TableSelectButton
}

const MERatersList = (props) => {

  const [deleteDialog, setDeleteDialog] = useState(null);

  // Define Dispatch
  const dispatch = useDispatch();

  const { assign, selectedParticipants,
    editRaters,
    dataFilter: { filterChanged }, all_ids, response_ids, isRaterErrorMessageShown } = useSelector(state => state.managerEffectiveness);
  const { data, page, raterErrorMessage } = assign;

  // Assign Props
  const { match, survey_id, editDisabled } = props;

  // Table size
  const tableHeight = getTableHeight(196 + 56);
  const tableRowSize = getTableRowSize(196 + 56);

  // Get Page Reset 
  const isPageReset = getQueryVariable(props.location.search, "reset") === "true";

  // show rater error message for duplicates
  useEffect(() => {
    if (!isRaterErrorMessageShown && raterErrorMessage && raterErrorMessage !== '') {
      dispatch(meSurveyAction.updateRaterErrorNotification())
      dispatch(alertAction.error(raterErrorMessage))
    }
  }, [assign])

  /**
   * Get ME Raters List
   */
  useEffect(() => {
    if (!isPageReset) {
      dispatch(meSurveyAction.raters(1, tableRowSize, "subject_id", "asc", [], [], survey_id));
    }
  }, [dispatch]);

  /**
   * when data filters are changed
   */
  useEffect(() => {
    if (filterChanged) {
      dispatch(meSurveyAction.raters(1, tableRowSize, "subject_id", "asc", [], [], survey_id));
    }
  }, [filterChanged]);

  /**
    * Handle Table Navigation Event
    */
  const handleNavigateAction = (id) => {
    history.push({
      pathname: `${match.url}/assign/${id}`
    });
  };

  /**
     * Handle Table pagination Event
     */
  const onChangePagination = (skip, limit) => {
    dispatch(meSurveyAction.raters(skip, limit, page.sortBy, page.sort, page.sFields, page.sValues, survey_id));
  };

  /**
   * Handle Table Sort Request Event
   */
  const onSortRequest = (sortBy, sort) => {
    dispatch(meSurveyAction.raters(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues, survey_id));
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey, userChange) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if (!isPageReset) {
      dispatch(meSurveyAction.raters(1, tableRowSize,
        page.sortBy, page.sort, sFields, sValues, survey_id));
    }
  };

  /**
   * Clear Search Text By Field
   * @param {*} searchField
   * @param {*} searchKey
   */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(meSurveyAction.raters(1, tableRowSize, page.sortBy, page.sort,
      sFields, sValues, survey_id));
  };

  /**
  * Handle Field Component On Change Event
  */
  const fieldComponentOnChange = (field, selData, value) => {
    switch (field) {
      case 'selected':
        dispatch(meSurveyAction.updateSelectedParticipants(
          getUpdatedParticipants({
            value, selData, selectedParticipants, data,
            count: page.count,
            all_ids,
            response_ids
          })
        ))
        break;
      case 'ACTION_SETTING':
        const clData = { ...selData }
        const selected = clData.relationship !== "Self" ? clData.relationship_label.find(a => a.name === clData.relationship) : { _id: "Self", name: "Self" }
        clData.relationship = selected._id || null
        dispatch(meSurveyAction.addOrEditRater({
          ...editRaters,
          getOne: { ...clData }
        }))
        break;
      case 'ACTION_DELETE':
        setDeleteDialog(selData)
        break;
      default:
    }
  };

  const confirmDelete = () => {
    const updateData = {
      ...deleteDialog,
      isDelete: true
    }
    const updatedSelectedParticipants = selectedParticipants.filter(f => f !== updateData.fromTo)
    dispatch(meSurveyAction.updateSelectedParticipants(updatedSelectedParticipants))
    dispatch(meSurveyAction.updateRaterChange(updateData, survey_id))
    setDeleteDialog(null)
  }

  const getTDClassName = (field, value) => {
    switch (field) {
      case 'email': return { title: !value ? 'Add/Edit Email in Settings' : '', classAttr: !value ? 'error' : '' }
      default: return { title: '', classAttr: '' }
    }
  }

  return (
    <>
      <TableComponent
        actions={editDisabled ? [] : ["SETTING", "DELETE"]}
        data={data}
        header={page.fieldFilters.headerCols}
        field={page.fieldFilters.fieldCols}
        search={page.fieldFilters.searchCols}
        height={tableHeight}
        page={page.skip}
        count={page.count}
        rowsPerPage={page.limit}
        links={["name"]}
        sort={page.sort}
        sortBy={page.sortBy}
        sFields={page.sFields}
        sValues={page.sValues}
        noDataErrorMessage={"No Records Found"}
        paginationVisible={!page.lastPage}
        handleNavigateAction={handleNavigateAction}
        onChangePagination={onChangePagination}
        onSortRequest={onSortRequest}
        onInputChangeRequest={onInputChangeRequest}
        handleClearSearchByField={handleClearSearchByField}
        customFilterComponent={!editDisabled ? customFilterComponent : {}}
        componentMap={!editDisabled ? componentMap : {}}
        fieldComponentOnChange={fieldComponentOnChange}
        selectedRows={selectedParticipants}
        actionComponent={actionComponent}
        resetSearchText={page.sFields.length === 0 || isPageReset}
        getTDClassName={getTDClassName}
      />
      <DeleteDialogComponent
        id={`delete-popup`}
        open={deleteDialog !== null}
        content={"You are about to delete this rater from the survey. Do you want to delete?"}
        handleDeleteAction={() => confirmDelete()}
        handleDeleteOnCloseAction={() => setDeleteDialog(null)}
      />
    </>
  )
}

export default withStyles(MEStyles)(MERatersList);