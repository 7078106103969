import React, { useState } from "react";
import PropTypes from "prop-types";
import ReactWordcloud from "react-wordcloud";
import { useDispatch, useSelector } from "react-redux";

// Import Actions
import { dashboardAction } from "../../../../redux/actions";

import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import TopicPopup from "./TopicPopup"

const TopicChart = (props) => {
  const dispatch = useDispatch();

  //get props
  const { topicCount, topics, classes } = props;

  //state for popup open/close
  const [Popup, setPopup] = useState(false);
  // popup close
  const onClose = () => {
    setPopup(false);
  };

  // open popup
  const openPopup = () => {
    setPopup(true);
  };

  //get reducer data for selected item
  let selectedItem = useSelector((state) => state.dashboard.topicSelected);
  let { item, word, wordCount, data } = selectedItem;

  if (item === "") {
    data = Object.keys(topicCount || {}).map((item) => {
      return { text: item, value: topicCount[item] };
    });
  } else if (item === "topics") {
    data = Object.keys(topics[word] || {}).map((item) => {
      return { text: item, value: topics[word][item]["count"] };
    });
  } else if (selectedItem["mainWord"]) {
      data = Object.keys(topics[selectedItem["mainWord"]] || {}).map((item) => {
        return { text: item, value: topics[selectedItem["mainWord"]][item]["count"] };
      });
  }

  const wordClickHandler = (word) => {
    const items =
      Object.keys(topicCount).indexOf(word["text"]) > -1 ? "topics" : "words";
    const updateItem = {
      item: items,
      type: selectedItem["type"], 
      word: word["text"],
      data: data,
      wordCount: word["value"],
    };
    if(items==="topics"){
      updateItem["mainWord"]=word["text"]
    }else{
      for(let key in topics){
        for(let key1 in topics[key]){
          if(key1===word["text"]){
            updateItem["mainWord"]=key;
          }
        }
      }
    }

    dispatch(dashboardAction.updateNLPSelected(updateItem));
    if(items === "words"){
      openPopup()
    }
  };

  const callbacks = {
    getWordColor: (word) => "black",
    onWordClick: wordClickHandler,
    getWordTooltip: (word) => ``,
  };


  const options = {
    rotations: 1,
    rotationAngles: [0, 0],
    fontFamily: 'roboto',
    fontSizes: [10, 40],
    // fontWeight: 'bold',
    padding: 1,
  };

  return (
    <React.Fragment>
      <ReactWordcloud
        words={data}
        callbacks={callbacks}
        options={options}
      />
      {Popup && (
        <DialogComponent open={true} fullScreen={true}>
          <TopicPopup
            onClose={onClose}
            classes={classes}
            topics={topics}
            word={word}
            wordCount={wordCount}
          />
        </DialogComponent>
      )}
    </React.Fragment>
  );
};

// default props
TopicChart.defaultProps = {
  classes: {},
  chartId: "lineChart",
  data: "",
};
// prop types
TopicChart.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.any,
  chartId: PropTypes.string,
};

export default TopicChart;
