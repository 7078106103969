/* eslint-disable dot-notation */
/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import Grid from "@mui/material/Grid";

// Import History
import history from "../../../config/history";

// Import Styles and Image
import SurveysStyles from "../SurveysStyles";

// Import Actions
import { formStepperAction, surveyAction, alertAction } from "../../../redux/actions";

// Import Config Layout
import { getTabsHeight } from "../../../config/layout";

// Import Left and Right Side Panel
import LeftPanel from "./QuestionsPanels/LeftPanel";
import RightPanel from "./QuestionsPanels/RightPanel";

// Import Helpers
import { checkQuestionsDataSet } from "../../../helpers/QuestionsHelper";

function QuestionsPanel (props) {
  const dispatch = useDispatch();
  const { classes, match, cancelButtonAction } = props;
  const { params } = match;
  const questions = useSelector((state) => state.survey.questions);
  const settings = useSelector((state) => state.survey.getOne);
  const translated_history = useSelector((state) => state.survey.translated_history);
  const { addQuestions } = useSelector(state => state.category);

  // Get Tabs Height
  const tabHeight = getTabsHeight();

  useEffect(() => {
    dispatch(surveyAction.getOne(params.id ? params.id : null));
  }, [dispatch]);

  /**
    * Suvey Question Panel Form Submit
   * @param {*}
   */
  function handleSubmit (e) {
    e.preventDefault();
    const newQuestion = addQuestions && Object.keys(addQuestions).length > 0 ? true : false;
    const { questionValidations, errors } = checkQuestionsDataSet(questions, settings);

    // update errors in reducers
    if (errors) {
      dispatch(surveyAction.updateSurveyErrors(errors));
    }

    if (questions.type === "" || questions.type === "Custom") {
      dispatch(alertAction.error("Please select the survey type"));
    } else if (newQuestion) {
      dispatch(alertAction.error("Please save the edited questions"));
    } else if (questionValidations === true) {
      // change active language to english
      let resultData = questions;
      for (let i = 0; i < resultData.languages.length; i++) {
        if (resultData.languages[i]["code"] === "en") {
          resultData.languages[i]["active"] = true;
        } else {
          resultData.languages[i]["active"] = false;
        }
      }
      // add ans Scale field for single select
      for (let i = 0; i < resultData.questions.length; i++) {
        if (resultData.questions[i]["qusType"] === "Single Select" && resultData.questions[i]["ansSet"] && resultData.questions[i]["ansSet"]["en"]) {
          let ansSetsLen = Object.keys(resultData.questions[i]["ansSet"]["en"]).length;
          resultData.questions[i]["maxAnsScale"] = parseInt(Object.keys(resultData.questions[i]["ansSet"]["en"])[ansSetsLen - 1]);
        }
        if(resultData.questions[i].baseQusId === "229") {
          resultData.questions[i]["RGConversion"] = "Remote or In Office"
        }
      }
      resultData["translated_history"] = translated_history

      dispatch(surveyAction.saveQuestions(resultData));
    } else {
      dispatch(alertAction.error(questionValidations));
    }
    if (cancelButtonAction.open) {
      history.replace("/surveys/manage");
    } else {
      dispatch(formStepperAction.next());
    }
  }

  return (
    <ValidatorForm
      id="surveyQuestionPanelForm"
      name="surveyQuestionPanelForm"
      useref="surveyPanelForm"
      autoComplete="off"
      onSubmit={handleSubmit}
      onError={() => { }}>
      <Grid container spacing={1} className={"height100"}>
        <Grid item md={3} lg={2} sm={12} xs={12}>
          <Grid style={{ height: tabHeight }} className={"bgwhite boxShadow"}>
            <LeftPanel settings={settings} classes={classes} match={match}></LeftPanel>
          </Grid>
        </Grid>
        <Grid item md={9} lg={10} sm={12} xs={12}>
          <Grid style={{ height: tabHeight }}>
            <RightPanel classes={classes} match={match}></RightPanel>
          </Grid>
        </Grid>
      </Grid >
    </ValidatorForm>
  );
}

// default props
QuestionsPanel.defaultProps = {
  classes: {},
  cancelButtonAction: {}
};

// prop types
QuestionsPanel.propTypes = {
  classes: PropTypes.object,
  cancelButtonAction: PropTypes.object
};

export default withStyles(SurveysStyles)(QuestionsPanel);
