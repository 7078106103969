/* eslint-disable curly */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from '@mui/material/ClickAwayListener';

// Import Config Layout
import { getQuestionCategoryPanelHeight } from "../../../../config/layout";

import { surveyTypes } from "../../../../config/constants";

// Import Actions
// import { categoryAction } from "../../../../redux/actions";
import { updateStateReducerAction, categoryAction, surveyAction } from "../../../../redux/actions";

// Import Redux Constant
import { dragdropConstant, categoryConstant } from "../../../../redux/constants";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../../../components/SearchInputCompoment/SearchInputComponent";
import CategoryQuestPreview from "./CategoryQuestPreview";
import { RightArrow } from "../../../../assets/images/svgComponents/ImageComponent";
import GreyArrow from "../../../../assets/images/svg/CustomSize1.svg";
import Drag from "../../../../components/DragDropComponents/Drag";

function CategoryList(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, params, settings } = props;

  // Set Question Panel Height
  const categoryPanelHeight = getQuestionCategoryPanelHeight();

  // Get Category List
  const searchData = useSelector((state) => state.category.search);


  // Get Category Question
  const { cat_ques } = useSelector((state) => state.category.search);

  const [openPreviewQuestion, setOpenPreviewQuestion] = React.useState(false);

  const [activeCategory, setActiveCategory] = React.useState("");


  // Handle Search Text Box Event
  const handleChange = (searchField, searchKey) => {
    dispatch(categoryAction.search(searchKey));
  };

  /**
   * Handle Clear Search Text
   */
  const handleClearSearch = () => {
    dispatch(categoryAction.search(""));
  };

  /**
   * Handle Menu Item Mouse Down Event
   */
  const handleMenuItemMouseDown = () => {
    dispatch(updateStateReducerAction.update(dragdropConstant.DRAG));
  };

  /**
   * Handle Menu Item Mouse Up Event
   */
  const handleMenuItemMouseUp = () => {
    dispatch(updateStateReducerAction.update(dragdropConstant.DROP));
  };

  /**
 * 
 * Handle preview question slider
 */
  const handelPreviewClick = (event, category_id) => {
    event.preventDefault();
    setActiveCategory(category_id)
    if (openPreviewQuestion && activeCategory !== category_id) {
      dispatch(categoryAction.getQuestions(category_id));
    }
    else {
      setOpenPreviewQuestion(true);
      dispatch(categoryAction.getQuestions(category_id));
    }
    if (activeCategory === category_id) {
      setOpenPreviewQuestion(!openPreviewQuestion);
    }
    let newVal = new Array();
    dispatch(updateStateReducerAction.update(categoryConstant.CATEGORY_QUESTION_SELECTED, newVal))

  };

  const handleClickAway = () => {
    let newVal = new Array();
    if (openPreviewQuestion === true) {
      dispatch(updateStateReducerAction.update(categoryConstant.CATEGORY_QUESTION_SELECTED, newVal))
    }
    setOpenPreviewQuestion(false);
  };


  /**
 * Bind Category Items
 */
  const categoryListing = (category) => {
    return (
      <React.Fragment>
        <List aria-label="category_list">
          {category.map((item, index) => (
            <Drag key={item._id} dataItem={`category%/-+*/%${item._id}`} type="category" dropEffect="copy">
              <ListItem className={`${classes.quest_list} ${"quest_list spacebetween"} ${openPreviewQuestion && item._id === activeCategory ? classes.selected_cat : ""}`} key={index + "-category"} /* onMouseDown={() => handleMenuItemMouseDown()} */ onMouseUp={() => handleMenuItemMouseUp()} onClick={(e) => { handelPreviewClick(e, item._id) }}>
                <Grid className="dflex">
                  <Tooltip arrow title={item._id}>
                    <ListItemText primary={(item._id.length > 20) ? item._id.slice(0,20) + "..." : item._id} className={"oneline_ellipseWordBreak fontSize13 initialtitle"} disableTypography={true} />
                  </Tooltip>
                  <span className={`${(!openPreviewQuestion && item._id !== activeCategory) && "txtgrey"} ml5 fontSize13 marginAuto`}>{`(${item.count})`}</span>
                </Grid>
                <IconButton className={`${classes.category_quest_icon} ${"rotate180"}`} size="large">
                  {RightArrow(openPreviewQuestion && item._id === activeCategory)}
                </IconButton>

                {openPreviewQuestion && item._id === activeCategory &&
                  <IconButton className={`${classes.preview_container_arrow}`} size="large">
                    <img src={GreyArrow} alt="arrow" ></img>
                    {/* {ArrowGrey()} */}
                  </IconButton>
                }
              </ListItem>
            </Drag>
          ))
          }
        </List>
      </React.Fragment>
    );
  };

  /**
 * Bind Questions Items
 */
  const questionsListing = (questions) => {
    return (
      <List aria-label="category_list" >
        {questions.map((item, index) => (
          <Drag key={item._id} dataItem={`question%/-+*/%${item.qusText.Default.en}`} type="question" dropEffect="copy">
            <ListItem className={`${classes.quest_list} ${"quest_list"}`} key={index + "qus"} onMouseDown={() => handleMenuItemMouseDown()} onMouseUp={() => handleMenuItemMouseUp()}>
              {/* <ListItemText data-title={item.qusText.Default.en} primary={item.qusText.Default.en} className={"oneline_ellipse fontSize13 initialtitle"} disableTypography={true}></ListItemText> */}
              <Tooltip arrow title={item.qusText.Default.en}>
                <ListItemText primary={item.qusText.Default.en} className={"oneline_ellipse fontSize13 initialtitle"} disableTypography={true}></ListItemText>
              </Tooltip>
            </ListItem>
          </Drag>
        ))}
      </List>
    );
  };



  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Grid container spacing={1} className={"relative"} >
        <Grid item xs={12} className="pl-15 pr-1" onClick={() => { setOpenPreviewQuestion(false) }}>
          <SearchInputComponent
            id="searchCategoryTex"
            placeholder={settings.template==="Manager 360"?"Search competency/questions":"Search category/questions"}
            value={searchData.key}
            field={"searchCategory"}
            showClearIcon={true}
            fullWidth={true}
            className={"mt15"}
            onInputChangeRequest={handleChange}
            handleClearSearch={handleClearSearch}
            onEnter={() => { }} />
        </Grid>
        <Grid
          item xs={12}
          style={{ height: categoryPanelHeight }}
          className={"scrolly pl-15 pr-1 mr1"}>
          {categoryListing(searchData.category)}

          {searchData.questions.length > 0 &&
            <div>
              <TypographyComponent
                variant={"h4"}
                title={"Questions"} />
              {questionsListing(searchData.questions)}
            </div>
          }
        </Grid>
        {openPreviewQuestion &&
          <CategoryQuestPreview
            classes={classes}
            cat_ques={cat_ques}
          />
        }
      </Grid>
    </ClickAwayListener>
  );
}

// default props
CategoryList.defaultProps = {
  classes: {}
};

// prop types
CategoryList.propTypes = {
  classes: PropTypes.object
};

export default CategoryList;
