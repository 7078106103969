import React from "react";
import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

/**
 * SwitchComponent
 * @param {*} props
 */

function SwitchComponent (props) {
  const { name, checked, size, className, color, label, disabled } = props;

  const handleChange = (event) => {
    props.handleChange({ name: props.name, value: event.target.checked });
  };

  return (
    <FormControlLabel
      control={<Switch checked={checked} className={className} size={size} onChange={handleChange} name={name} color={color} disabled={disabled}/>}
      label={label}
    />
  );
}

// default props
SwitchComponent.defaultProps = {
  name: "",
  checked: "",
  label: "",
  className: "",
  size: "small",
  disabled: false,
  handleChange: () => { }
};

// prop types
SwitchComponent.propTypes = {
  name: PropTypes.string,
  checked: PropTypes.bool,
  size: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func
};

export default SwitchComponent;
