import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';


export const ManageParticipants = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="44.249" height="36.69" viewBox="0 0 44.249 36.69" style={{ fill: "#54c0fd", fill: "#ffae4b" }}>
    <defs></defs><g transform="translate(0 -43.729)"><g transform="translate(0 59.8)"><g transform="translate(0 0)">
      <path style={{ fill: "#54c0fd" }} class="a" d="M37.767,233H33.436a6.466,6.466,0,0,0-1.7.229,6.487,6.487,0,0,0-5.78-3.554H18.3a6.487,6.487,0,0,0-5.78,3.554,6.466,6.466,0,0,0-1.7-.229H6.482A6.489,6.489,0,0,0,0,239.487v6.924A3.894,3.894,0,0,0,3.889,250.3H40.36a3.894,3.894,0,0,0,3.889-3.889v-6.924A6.489,6.489,0,0,0,37.767,233Zm-25.953,3.157v11.545H3.889a1.3,1.3,0,0,1-1.3-1.3v-6.924A3.893,3.893,0,0,1,6.482,235.6h4.331a3.878,3.878,0,0,1,1.017.136C11.821,235.875,11.815,236.018,11.815,236.162Zm18.027,11.545H14.407V236.162a3.893,3.893,0,0,1,3.889-3.889h7.656a3.893,3.893,0,0,1,3.889,3.889Zm11.815-1.3a1.3,1.3,0,0,1-1.3,1.3H32.434V236.162c0-.144-.006-.287-.016-.428a3.88,3.88,0,0,1,1.017-.136h4.331a3.893,3.893,0,0,1,3.889,3.889Z" transform="translate(0 -229.68)" />
      </g></g><g transform="translate(2.888 50.883)"><g transform="translate(0 0)"><path style={{ fill: "#54c0fd" }} class="a" d="M39.174,126.5a5.76,5.76,0,1,0,5.76,5.76A5.766,5.766,0,0,0,39.174,126.5Zm0,8.927a3.167,3.167,0,1,1,3.167-3.167A3.171,3.171,0,0,1,39.174,135.431Z" transform="translate(-33.414 -126.504)" />
      </g></g><g transform="translate(14.429 43.729)"><g transform="translate(0 0)"><path style={{ fill: "#ffae4b" }} class="b" d="M174.657,43.729a7.7,7.7,0,1,0,7.7,7.695A7.7,7.7,0,0,0,174.657,43.729Zm0,12.8a5.1,5.1,0,1,1,5.1-5.1A5.108,5.108,0,0,1,174.657,56.526Z" transform="translate(-166.962 -43.729)" />
      </g></g><g transform="translate(29.842 50.883)"><g transform="translate(0 0)"><path style={{ fill: "#54c0fd" }} class="a" d="M351.054,126.5a5.76,5.76,0,1,0,5.76,5.76A5.766,5.766,0,0,0,351.054,126.5Zm0,8.927a3.167,3.167,0,1,1,3.167-3.167A3.171,3.171,0,0,1,351.054,135.431Z" transform="translate(-345.294 -126.504)" /></g></g></g></svg>
);

export const ManageUserRole = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48.342" height="48.36" viewBox="0 0 48.342 48.36"><defs></defs>
  <g transform="translate(-0.094 -0.001)"><g transform="translate(0.094 22.763)"><g transform="translate(0)"><path style={{ fill: "#54c0fd" }} class="a" d="M43.021,253.46a7.082,7.082,0,1,0-11.681-5.386,7.084,7.084,0,1,0-11.674,5.391,9.978,9.978,0,0,0-2.476,1.855,9.9,9.9,0,0,0-2.5-1.864,7.084,7.084,0,1,0-9.2,0,10.055,10.055,0,0,0-5.4,8.879v2.834a1.4,1.4,0,0,0,1.4,1.417H47.02a1.417,1.417,0,0,0,1.417-1.417v-2.834A10.074,10.074,0,0,0,43.021,253.46Zm-4.6-9.634a4.25,4.25,0,1,1-4.25,4.25A4.255,4.255,0,0,1,38.424,243.826Zm-7.084,4.254a7.069,7.069,0,0,0,2.484,5.377,9.9,9.9,0,0,0-2.5,1.864,9.982,9.982,0,0,0-2.476-1.855A7.069,7.069,0,0,0,31.34,248.079Zm-7.084-4.254a4.25,4.25,0,1,1-4.25,4.25A4.255,4.255,0,0,1,24.256,243.826Zm-14.168,0a4.25,4.25,0,1,1-4.25,4.25A4.255,4.255,0,0,1,10.087,243.826Zm4.269,18.513v1.417H2.909v-1.417a7.275,7.275,0,0,1,7.179-7.179,7.072,7.072,0,0,1,5.4,2.5A10.012,10.012,0,0,0,14.357,262.339Zm17,1.417H17.171v-1.417a7.094,7.094,0,1,1,14.187,0v1.417Zm14.263,0H34.174v-1.417a10.027,10.027,0,0,0-1.151-4.678,7.071,7.071,0,0,1,5.4-2.5,7.291,7.291,0,0,1,7.2,7.179Z" transform="translate(-0.094 -240.992)" /></g></g>
  <g transform="translate(13.755 0)"><g transform="translate(0 0)"><path style={{ fill: "#ffae4b" }} class="b" d="M164.222,6.6l-5.433-.79L156.355.8a1.418,1.418,0,0,0-2.549,0l-2.434,5.016-5.433.79a1.418,1.418,0,0,0-.785,2.417l3.936,3.836-.929,5.417a1.418,1.418,0,0,0,2.056,1.494l4.865-2.558,4.865,2.558A1.418,1.418,0,0,0,162,18.273l-.929-5.417,3.936-3.836A1.418,1.418,0,0,0,164.222,6.6Zm-5.662,4.744a1.417,1.417,0,0,0-.407,1.254l.57,3.322-2.983-1.568a1.417,1.417,0,0,0-1.319,0l-2.983,1.568.57-3.322a1.417,1.417,0,0,0-.407-1.254l-2.413-2.353,3.335-.485a1.417,1.417,0,0,0,1.071-.783l1.487-3.065,1.488,3.065a1.417,1.417,0,0,0,1.071.783l3.335.485Z" transform="translate(-144.724 0)" /></g></g></g></svg>
);

export const Demographics = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39.073" height="41.678" viewBox="0 0 39.073 41.678"><defs></defs>
  <g transform="translate(-0.5 -0.001)"><path style={{ fill: "#54c0fd" }} class="a" d="M37.435,393.191A7.225,7.225,0,0,0,32.247,391a7.3,7.3,0,0,0-6.105,3.328,7.264,7.264,0,0,0-12.21,0A7.3,7.3,0,0,0,7.826,391,7.391,7.391,0,0,0,.5,398.4v1.221a1.221,1.221,0,0,0,1.221,1.221H38.352a1.221,1.221,0,0,0,1.221-1.221V398.4a7.4,7.4,0,0,0-2.138-5.213Zm-29.609.248A4.931,4.931,0,0,1,12.71,398.4H2.942a4.931,4.931,0,0,1,4.884-4.965Zm12.21,0a4.931,4.931,0,0,1,4.884,4.965H15.152a4.931,4.931,0,0,1,4.884-4.965Zm7.326,4.965a4.885,4.885,0,1,1,9.768,0Zm0,0" transform="translate(0 -359.168)" />
  <path style={{ fill: "#54c0fd" }} class="a" d="M40.269,248.322a4.876,4.876,0,0,0-1.832-3.809,4.873,4.873,0,0,1,6.106,0,4.884,4.884,0,1,0,6.1,0,4.874,4.874,0,0,1,6.107,0,4.9,4.9,0,1,0,2.441-1.036,7.324,7.324,0,0,0-10.99,0,4.744,4.744,0,0,0-1.221,0,7.325,7.325,0,0,0-10.99,0,4.883,4.883,0,1,0,4.274,4.845Zm21.979,0a2.442,2.442,0,1,1-2.442-2.442A2.445,2.445,0,0,1,62.247,248.322Zm-14.653,2.442a2.442,2.442,0,1,1,2.442-2.442A2.445,2.445,0,0,1,47.595,250.764Zm-12.21,0a2.442,2.442,0,1,1,2.442-2.442A2.445,2.445,0,0,1,35.385,250.764Zm0,0" transform="translate(-27.558 -221.378)" />
  <path style={{ fill: "#54c0fd" }} class="a" d="M115.272,125.876a4.884,4.884,0,1,0-4.884,4.884A4.89,4.89,0,0,0,115.272,125.876Zm-4.884,2.442a2.442,2.442,0,1,1,2.442-2.442A2.445,2.445,0,0,1,110.388,128.318Zm0,0" transform="translate(-96.457 -111.142)" />
  <path style={{ fill: "#54c0fd" }} class="a" d="M265.273,125.876a4.884,4.884,0,1,0-4.884,4.884A4.89,4.89,0,0,0,265.273,125.876Zm-4.884,2.442a2.442,2.442,0,1,1,2.442-2.442A2.445,2.445,0,0,1,260.389,128.318Zm0,0" transform="translate(-234.247 -111.142)" />
  <path style={{ fill: "#ffae4b" }} class="b" d="M197.588,4.527l.358-.358V8.547a1.221,1.221,0,1,0,2.442,0V4.169l.358.358A1.221,1.221,0,0,0,202.472,2.8L200.03.358a1.223,1.223,0,0,0-1.727,0L195.861,2.8a1.221,1.221,0,0,0,1.727,1.727Zm0,0" transform="translate(-179.13 0)" />
  <path style={{ fill: "#ffae4b" }} class="b" d="M347.592,4.527l.358-.358V8.547a1.221,1.221,0,1,0,2.442,0V4.169l.358.358A1.221,1.221,0,0,0,352.476,2.8L350.034.358a1.223,1.223,0,0,0-1.727,0L345.865,2.8a1.221,1.221,0,1,0,1.727,1.727Zm0,0" transform="translate(-316.923 0)" />
  <path style={{ fill: "#ffae4b" }} class="b" d="M47.585,4.531l.358-.358V8.552a1.221,1.221,0,0,0,2.442,0V4.173l.358.358A1.221,1.221,0,0,0,52.469,2.8L50.027.362a1.224,1.224,0,0,0-1.727,0L45.858,2.8a1.221,1.221,0,1,0,1.727,1.727Zm0,0" transform="translate(-41.337 -0.004)" /></g></svg>
);

