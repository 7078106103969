import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Paper from '@mui/material/Paper';

// Import Styles and Image
import ExternalApiStyles from "../ExternalApiIntegrationStyles";

// Import Constants
import { externalApiConstants } from "../../../config/constants";

// Import Actions
import history from "../../../config/history";
import { alertAction, externalApiAction } from "../../../redux/actions";

// Import Componenets
import { hrisLogos, Tick } from '../../../assets/images/svgComponents/ImageComponent';
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";

const DataIntegrations = (props) => {
    //Get props
    const { classes, match } = props;

    const dispatch = useDispatch();

    const externalApiConfig = useSelector(state => state.externalApiConfig);

    const [activeLogo, setActiveLogo] = useState('');

    useEffect(() => {
        setActiveLogo(externalApiConfig.type ? externalApiConfig.type : '')
    }, [externalApiConfig])
    

    const handleSubmit = _ => {
        if (activeLogo !== '') {            
            dispatch(externalApiAction.putHrisType("", activeLogo));
            history.push({ pathname: `${match.url}/configuration` });
        } else {
            dispatch(alertAction.error(externalApiConstants.DATA_INTEGRATION.ERROR_MESSAGE))
        }
    }
    const handleCancel = _ => {
        history.push({ pathname: "/surveys" });
    }
    const handleClick = name => {
        name === activeLogo ? setActiveLogo('') : setActiveLogo(name);
    }
    return (
        <Grid container className={classes.dataIntegrationContainer}>
            <Grid item xs={12}>
                <TypographyComponent
                    title={externalApiConstants.DATA_INTEGRATION.TITLE}
                    variant={"h5"}
                    className="txtMedium display-block mb-15"
                ></TypographyComponent>
                <TypographyComponent
                    title={externalApiConstants.DATA_INTEGRATION.DESCRIPTION}
                    variant={"h6"}
                    className="display-block mb8"
                    mb={2}
                ></TypographyComponent>
                <TypographyComponent
                    title={externalApiConstants.DATA_INTEGRATION.HELP_TEXT}
                    variant={"h6"}
                    className="display-block mb8"
                    mb={2}
                ></TypographyComponent>
                {
                    hrisLogos && (
                        <Grid container spacing={4} className={classes.dataIntegrationLogoContainer}>
                            {
                                Object.entries(hrisLogos).map(([key, value]) => {
                                    return (
                                        <Grid item lg={4}>
                                            <Paper
                                                variant="outlined"
                                                className={`${classes.dataIntegrationLogoWrap} ${activeLogo === key ? 'active' : ''}`}
                                                onClick={(e) => handleClick(key)}>
                                                <div className={classes.dataIntegrationLogos}>{value()}</div>
                                                {
                                                    (key === 'bamboohr' && externalApiConfig.api_key && externalApiConfig.sub_domain && externalApiConfig.report_id) && 
                                                    (
                                                        <TypographyComponent
                                                            component={"div"}
                                                            className={`${classes.configuredMsg} txtMedium`}
                                                        >{Tick()} Configured</TypographyComponent>
                                                    )
                                                }
                                            </Paper>
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    )
                }
            </Grid>
            <Grid
                container
                justifyContent="flex-end"
                className={`${classes.configFooterBtn} applytranslate`}
            >
                <Grid item>
                    <ButtonComponent
                        title={"Next"}
                        color={"primary"}
                        size={"small"}
                        type={"button"}
                        pageClassName="mr-1"
                        handleClick={handleSubmit}
                    ></ButtonComponent>
                    <ButtonComponent
                        title={"Cancel"}
                        color={"default"}
                        size={"small"}
                        type={"button"}
                        handleClick={handleCancel}
                    ></ButtonComponent>
                </Grid>
            </Grid>
        </Grid>
    )
}

// default props
DataIntegrations.defaultProps = {
    classes: {},
    match: {},
};

// prop types
DataIntegrations.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
};
export default withStyles(ExternalApiStyles)(DataIntegrations);