// import constants
import { alertConstant, uiConstant } from "../constants";
import { baseAction } from "./baseAction";
import { adminsConstant, filterConstant } from "../constants";
import { adminService } from "../services/adminService";

// Import History
import history from "../../config/history";

//import action
const { request, success, failure } = baseAction;

export const adminsAction = {
  search,
  getOne,
  create,
  update,
  remove,
  getRoles,
  updateList,
  excelUpload,
  excelDownload,
  updateReportPreference,
  getReportPreference,
  toggleFilterDrawer,
  getSurveys,
  updateSurveyAccess,
  updatePreferenceNLPSelected,
  updateDashWidgetPreference,
};

/**
 * Get All User Admins
 *
 * @param {*} id  client id to search all users
 * @returns
 */
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_SEARCH_REQUEST));

    adminService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_SEARCH_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get One user Admin
 *
 * @param {*} id  Admin id to update
 * @returns
 */
function getOne(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_GET_ONE_REQUEST));

    adminService.getOne(id).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_GET_ONE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Create Admin User
 *
 * @param {*} data data to be created
 * @returns message
 */
function create(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_CREATE_REQUEST));

    adminService.create(data).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_CREATE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        // Redirect to roles based screen
        history.push({
          pathname: "/manage_people/admins"
        });
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_CREATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Admin User
 *
 * @param {*} id  admin id to update
 * @param {*} updateData data to be updated
 * @returns
 */
function update(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_UPDATE_REQUEST));

    adminService.update(data).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(adminsConstant.ADMINS_UPDATE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));

        // setTimeout(() => {
        // Redirect to roles based screen
        history.push({
          pathname: "/manage_people/admins"
        });
        // }, 1000)
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Remove Admin User --soft Delete
 *
 * @param {*} id  id to Remove
 * @returns
 */
function remove(id, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_REMOVE_REQUEST));
    adminService.remove(id).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_REMOVE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_REMOVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get User Roles
 *
 *  @returns all roles
 */
function getRoles() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_ROLES_REQUEST));
    adminService.getRoles().then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_ROLES_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_ROLES_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

/**
 * Update Admin User
 *
 * @param {*} id  admin id to update
 * @param {*} updateData data to be updated
 * @returns
 */
function updateList(data, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_STATUS_UPDATE_REQUEST));

    adminService.updateStatus(data).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_STATUS_UPDATE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        successCallback();
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_STATUS_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Excel Upload
 */
function excelUpload(file) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_EXCEL_UPLOAD_REQUEST));
    adminService.excelUpload(file).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_EXCEL_UPLOAD_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_EXCEL_UPLOAD_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update report preferences
 */
function updateReportPreference(reportPreference, successCallback = () => { }, loader = true) {
  return dispatch => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(adminsConstant.ADMINS_REPORT_UPDATE_REQUEST));
    adminService.updateReportPreference(reportPreference).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_REPORT_UPDATE_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_REPORT_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * get preference
 */
function getReportPreference(successCallback = () => { }, loader = true, params = {}) {
  return dispatch => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(adminsConstant.ADMINS_GET_PREFERENCE_REQUEST));
    adminService.getReportPreference(params).then((data) => {
      dispatch(success(adminsConstant.ADMINS_GET_PREFERENCE_SUCCESS, data));
      dispatch(success(filterConstant.UPDATE_FILTER_SUCCESS, data.data));
      dispatch(request(uiConstant.LOADING_FALSE));
      successCallback();
    }, (error) => {
      dispatch(failure(adminsConstant.ADMINS_REPORT_UPDATE_FAILURE, error));
      dispatch(failure(alertConstant.ALERT_ERROR, error));
      dispatch(request(uiConstant.LOADING_FALSE));
    });
  };
}

/**
 * Excel Download
 */
function excelDownload(skip, limit, sortBy, sort, sFields, sValues) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.ADMINS_EXCEL_UPLOAD_REQUEST));
    adminService.excelUpload(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(adminsConstant.ADMINS_EXCEL_UPLOAD_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(adminsConstant.ADMINS_EXCEL_UPLOAD_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
};

// toggle filter drawer status open or close
function toggleFilterDrawer() {
  return dispatch => {
    dispatch(success(adminsConstant.TOGGLE_FILTER_DRAWER_OPEN_STATUS));
  }
}

/**
 * Get Client Surveys ME and Non ME Surveys
 *
 *  @returns all surveys for selected client
 */
function getSurveys() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(adminsConstant.GET_CLIENT_SURVEYS_REQUEST));
    adminService.getSurveys().then(
      (data) => {
        dispatch(success(adminsConstant.GET_CLIENT_SURVEYS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(adminsConstant.GET_CLIENT_SURVEYS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
      }
    );
  };
}

// toggle filter drawer status open or close
function updateSurveyAccess(params) {
  return dispatch => {
    const { type, data } = params;
    if (type === "ME") {
      dispatch(success(adminsConstant.UPDATE_ME_SURVEY_ACCESS, data));

    } else {
      dispatch(success(adminsConstant.UPDATE_SURVEY_ACCESS, data));
    }
  } 
}

// update preference for comment analysis about nlp selected
function updatePreferenceNLPSelected(params) {
  return dispatch => {
    dispatch(success(adminsConstant.UPDATE_SELECTED_NLP_PREFERENCE, params));
  }
}

// updadte dashboard hide widget preference
function updateDashWidgetPreference(preference, successCallback = () => { }, loader = true) {
  return dispatch => {
    if (loader) {
      dispatch(request(uiConstant.LOADING_TRUE));
    }
    dispatch(request(adminsConstant.UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_REQUEST));
    adminService.updateReportPreference(preference).then(
      (data) => {
        dispatch(success(adminsConstant.UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_SUCCESS, data));
        if (loader) {
          setTimeout(() => {
            dispatch(request(uiConstant.LOADING_FALSE));
          }, 1000)
        }
        successCallback();
      },
      (error) => {
        dispatch(failure(adminsConstant.UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        if (loader) {
          setTimeout(() => {
            dispatch(request(uiConstant.LOADING_FALSE));
          }, 1000)
        }
      }
    );
  }
}