import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Suvery Service Functions
 */
export const surveyService = {
  getOne,
  search,
  create,
  update,
  copy,
  saveSettings,
  getSettings,
  remove,
  getQuestions,
  saveQuestions,
  saveAnswerSet,
  applyAnswerSet,
  getAnswerSet,
  getResponseDetails,
  getAnonKioskShortName,
  sendSurveyMail,
  sendActionCoachMail,
  sendSurveySMS,
  sendSurveyTransflo,
  triggerAssignSurvey,
  translateSurveyQuestions,
  translateSurveySettings,
  translateSurveySettingsCommunication,
  translateCustomAnswerSet,
  getMESystemID,
  getActionAlert,
  checkSurveyName
};

/**
 * Call Survey GetOne Service
 */
async function getOne(id) {
  const response = await apihelper.getRequest("survey/" + id);
  return response;
}

/**
 * Create a survey
 * @param id
 */
async function create(data) {
  const response = await apihelper.postRequest("survey", { data });
  return response;
}

/**
 * Copy a survey
 * @param id
 */
async function copy(data) {
  const response = await apihelper.postRequest("survey/copy", data);
  return response;
}

/**
 * Create a survey
 * @param id
 */
async function update(data) {
  const response = await apihelper.putRequest("survey", data);
  return response;
}


/**
 * Call Survey Get All Search Service
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`survey/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

/**
 * Call Survey delete Service
 */
async function remove(data) {
  const response = await apihelper.deleteRequest("survey", data);
  return response;
}

/**
 * Call Survey getQuestions Service
 */
async function getQuestions(id) {
  const response = await apihelper.getRequest(`survey/questions/${id}`);
  return response;
}

/**
 * Call Survey saveQuestions Service
 */
async function saveQuestions(data) {
  const response = await apihelper.putRequest("survey/questions", data);
  return response;
}

/**
 * Call Survey Save Setting Service
 */
async function getSettings(id) {
  const response = await apihelper.getRequest("survey/settings/" + id);
  return response;
}

/**
 * Call Survey Save Setting Service
 */
async function saveSettings(data) {
  const response = await apihelper.putRequest("survey/settings", data);
  return response;
}

/**
 * save a answerset
 */
async function saveAnswerSet(data) {
  const response = await apihelper.postRequest("survey/saveAnswerSets", data);
  return response;
}

/**
 * apply a answerset
 */
async function applyAnswerSet(data) {
  const response = await apihelper.postRequest("surveys/applyAnswerSet", { data });
  return response;
}

/**
 * GET ANSWERSET
 */
async function getAnswerSet(id) {
  const response = await apihelper.getRequest("survey/getAnswerSet/" + id);
  return response;
}

/**
 * GET RESPONSE DETAILS
 */
async function getResponseDetails(id) {
  const response = await apihelper.getRequest("survey/getResposne/" + id);
  return response;
}

/**
 * GET ANONYMOUS KIOSK SHORTNAME
*/
async function getAnonKioskShortName(id) {
  const response = await apihelper.getRequest("survey/getAnonKioskShortName/" + id);
  return response;
}

/**
 * Send Survey Link Email to Participants 
*/
async function sendSurveyMail(id) {
  const response = await apihelper.postRequest("survey/distribute/mail", id);
  return response;
}

/**
 * Send action coach Link Email to Participants 
*/
async function sendActionCoachMail(id) {
  const response = await apihelper.postRequest("survey/distribute/actionCoachmail", id);
  return response;
}

/**
 * Send Survey Link SMS to Participants 
*/
async function sendSurveySMS(id) {
  const response = await apihelper.postRequest("survey/distribute/sms", id);
  return response;
}

/**
 * Send Survey Link Transflo Notifications to Participants 
*/
async function sendSurveyTransflo(id) {
  const response = await apihelper.postRequest("survey/distribute/transflo", id);
  return response;
}

/**
 * Triger assign survey
*/
async function triggerAssignSurvey(data) {
  const response = await apihelper.postRequest("survey/triggerassignsurvey", data);
  return response;
}

/**
 * Translate survey questions
*/
async function translateSurveyQuestions(data) {
  const response = await apihelper.postRequest("automated-translate/survey/questions", data);
  return response;
}

/**
 * Translate survey custom answer set
*/
async function translateCustomAnswerSet(data) {
  const response = await apihelper.postRequest("automated-translate/survey/customAnswerSet", data);
  return response;
}

/**
 * Translate survey setting
*/
async function translateSurveySettings(data) {
  const response = await apihelper.postRequest("automated-translate/survey/settings", data);
  return response;
}

/**
 * Translate survey setting communication
*/
async function translateSurveySettingsCommunication(data) {
  const response = await apihelper.postRequest("automated-translate/survey/settings/communication", data);
  return response;
}

/**
 * Get system ID
*/
async function getMESystemID(data) {
  const response = await apihelper.postRequest("/survey/settings/distribute/actioncoach", data);
  return response;
}

/**
 * Get ActionAlert
*/
async function getActionAlert(id) {
  const response = await apihelper.getRequest("survey/settings/distribute/actionAlert/"+ id);
  return response;
}

// Check Survey Name
async function checkSurveyName(data) {
  const response = await apihelper.putRequest(`survey/name`, data);
  return response;
}


