import React from 'react';
import { useSelector } from 'react-redux'
import { List, ListItem, ListItemText, ListItemAvatar, Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import { ArrowBlue, ArrowOrange } from "../../../assets/images/svgComponents/ImageComponent"

/* import HighRateArrowImg from "../../../assets/images/svg/dashboard_ratedarrowblue.svg"
import LowRateArrowImg from "../../../assets/images/svg/dashboard_ratedarroworange.svg" */

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";

//Import Constants
import { ReportsConstants } from "../../../config/constants"

//Import Styles
import ReportsStyles from "../ReportsStyles";

const BottomWidgets = (props) => {
    // Get props
    const { classes } = props;

    // Selectors
    const { dashboardData } = useSelector(state => state.dashboard)

    // Generate Question By Type
    const generateQuestions = (questType) => {
        let questions = [];
        if (dashboardData.questions.length > 0) {
            const sortedData = dashboardData.questions.sort((a, b) => {
                return (`${b.mean}_${b.percentfavorable}`).localeCompare(`${a.mean}_${a.percentfavorable}`)
            });

            const middleIndex = Math.floor(sortedData.length / 2);
            const high = sortedData.slice(0, middleIndex);
            const low = sortedData.slice(middleIndex);
            let range = Math.min(high.length, low.length);
            range = range > 5 ? 5 : range;

            questions = questType === 'Top Rated' ? high.slice(0, range) : low.slice(-range).reverse();
        }

        return questions.map(d =>
            <ListItem >
                <ListItemAvatar>
                    <Grid className={questType === 'Top Rated' ? classes.HighRatesCount : classes.LowRatesCount}>{`${Number(d.mean).toFixed(1)}`}
                        {questType === "Top Rated" ?
                            <Grid className={classes.RateArrowImgRight}>{ArrowBlue()}</Grid> :
                            <Grid className={classes.RateArrowImgRight}>{ArrowOrange()}</Grid>
                        }
                    </Grid>
                </ListItemAvatar>
                <ListItemText primary={d.questionText} />
            </ListItem>
        )
    }
    /* Highest and lowest */
    return dashboardData.questions && dashboardData.questions.length > 0 &&
        <Grid container alignItems="stretch" spacing={3} className="mb-2">
            <Grid item className={classes.dashboard_BelowTile}>
                <Grid container alignItems="center" className="mb-15">
                    <Grid item>
                        <TypographyComponent variant="h5" className="txtBold">
                            {ReportsConstants.HIGHESTAND_LOWESTITEMS}</TypographyComponent>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item sm={6}>
                        <TypographyComponent variant="body1" className="txtBold borderBottom">
                            {ReportsConstants.Highest}
                        </TypographyComponent>
                        <List className={classes.AlertsList}>
                            {generateQuestions('Top Rated')}
                        </List>
                    </Grid>
                    <Grid item sm={6}>
                        <TypographyComponent variant="body1" className="txtBold borderBottom">
                            {ReportsConstants.Lowest}
                        </TypographyComponent>
                        <List className={classes.AlertsList}>
                            {generateQuestions('Lowest Rated')}
                        </List>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
}

export default withStyles(ReportsStyles)(BottomWidgets);
