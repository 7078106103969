import React from 'react';
import { Grid } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

// Import Component

import CardWidget from './Widgets/CardWidget'
import FavorableWidget from './Widgets/FavorableWidget'
import SaoaWidget from './Widgets/SAOAWidget'

//Import Constants
import { ReportsConstants } from "../../../config/constants"

//Import Styles
import ReportsStyles from "../ReportsStyles";

// Selector
import { useSelector } from 'react-redux'

const countWidgetLabelMap = {
    "totalSubjects": ReportsConstants.TOTAL_SUBJECTS,
    "totalRaters": ReportsConstants.TOTAL_RATERS,
    "totalReviewItems": ReportsConstants.TOTAL_REVIEW_ITEMS,
    "comments": ReportsConstants.TOTAL_COMMENTS
}

const METopWidgets = (props) => {

    // Selectors
    const { dashboardData } = useSelector(state => state.dashboard)
    const { surveys } = useSelector(state => state.admin.preference)
    const zero_check = -1

    const overallFavorableGroupCount = dashboardData.overallFavorable && Object.keys(dashboardData.overallFavorable).length

    const countWidgetsData = [
        "totalSubjects",
        "totalRaters",
        "totalReviewItems",
        "comments"
    ].filter(r => dashboardData[r] > zero_check).map((m, i) => {
        return (
            <>
                <CardWidget
                    value={dashboardData[m]}
                    type = {surveys[0].type}
                    label={countWidgetLabelMap[m]}
                    style={{
                        // display: "flex",
                        // paddingLeft: "0px important",
                        marginTop: i > 1 && overallFavorableGroupCount > 4 ? `12px` : '0px',
                        width: overallFavorableGroupCount <= 4 ? "24%" : "49%",
                        height: "128px",
                        flexGrow: "none"
                    }}
                />

            </>
        )
    })

    let overallFavorable = 0.0
    if (dashboardData.hasMESurvey) {
        overallFavorable = (dashboardData.overallFavorable["_"] || {}).percentfavorable || 0
    } else {
        overallFavorable = dashboardData.overallFavorable
    }

    let widgets = []
    if (dashboardData.saoa.length > 0) {
        widgets.push((
            <Grid item style={{ width: "50%", display: "flex" }}>
                <SaoaWidget
                    label={ReportsConstants.SUGGESTED_AREAACTION}
                    saoa={dashboardData.saoa}
                    hasMESurvey={dashboardData.hasMESurvey}
                />
            </Grid>
        ))
    }
    widgets.push((
        <Grid item style={{ width: overallFavorableGroupCount > 4 ? "100%" : "50%", display: "flex" }}>
            <FavorableWidget
                type={'large'}
                value={dashboardData}
                label={ReportsConstants.OVERALL_FAVORABLITYl}
                hasMESurvey={dashboardData.hasMESurvey}
            />
        </Grid>
    ))

    // reverse if
    if (overallFavorableGroupCount <= 4) {
        widgets = widgets.reverse()
    }

    return (
        <React.Fragment>
            <Grid item style={{
                width: overallFavorableGroupCount <= 4 ? "100%" : "50%",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between"
            }}>
                {countWidgetsData}
            </Grid>
            {dashboardData.overallFavorable && 
            widgets
            }
        </React.Fragment >
    )
}

export default withStyles(ReportsStyles)(METopWidgets);
