import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Actions
import { authAction } from "../../redux/actions";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

// Import Cofig Constants
import { authenticationConstants } from "../../config/constants";

function ResetPassword(props) {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false
  });

  const { match: { params } } = props;
  const { password, confirmPassword, showPassword } = formData;
  const dispatch = useDispatch();

  /**
  * Reset Password Token
  */
  useEffect(() => {
    ValidatorForm.addValidationRule("passwordPolicy", (value) => {
      if (value.length < 6) {
        return false;
      } else if (value.length > 50) {
        return false;
      } else if (value.search(/\d/) === -1 && value.search(/[!@#$^&*()_]/) === -1) {
        return false;
      } else if (value.search(/[a-z]/) === -1) {
        return false;
      } else if (value.search(/[A-Z]/) === -1) {
        return false;
      } else { return true; }
    });

    dispatch(authAction.resetPasswordToken({ hash: decodeURIComponent(params.hash), key: decodeURIComponent(params.key) }));
  }, [dispatch, params]);

  // Check Password and Confirm Password Match
  useEffect(() => {
    ValidatorForm.addValidationRule("isPasswordMatch", (value) => {
      if (value !== password) {
        return false;
      }
      return true;
    });
  }, [password]);

  /**
   * Handle Textbox Changes
   */
  function handleChange(e) {
    const { name, value } = e;
    setFormData((formData) => ({ ...formData, [name]: value }));
  }

  // Show / Hide Password Visibility
  function handleClickShowPassword() {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  }

  /**
   * ResetPassword Form Submit
   * @param {*}
   */
  function handleSubmit(e) {
    e.preventDefault();
    if (password && confirmPassword) {
      dispatch(authAction.resetPassword({ ...formData, hash: decodeURIComponent(params.hash) }));
    }
  }

  return (
    <ValidatorForm
      name="ResetPasswordForm"
      useref="form"
      autoComplete="off"
      onSubmit={handleSubmit}
      onError={(errors) => console.log(errors)}
    >
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.RESET_PASSWORD_TEXT}
            variant={"h2"}
            className="txtBold"
          ></TypographyComponent>
          <TypographyComponent
            title={authenticationConstants.RESET_PASSWORD_SUB_TEXT}
            variant={"h5"}
            className={"txtgrey mt-1"}
          ></TypographyComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.SIGNIN_PASSWORD}
            variant={"h6"}
          ></TypographyComponent>
          <TextFieldComponent
            id={"reset_password_txtbox"}
            name={"password"}
            type={"password"}
            margin={"none"}
            value={password}
            pwdVisibileHide={true}
            showPassword={showPassword}
            handleChange={(e) => handleChange(e)}
            handleClickShowPassword={(e) => handleClickShowPassword(e)}
            validators={["required", "passwordPolicy"]}
          ></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent
            title={authenticationConstants.RESET_CONFIRM_PASSWORD}
            variant={"h6"}
          ></TypographyComponent>
          <TextFieldComponent
            id={"reset_confirm_password_txtbox"}
            name={"confirmPassword"}
            type={"password"}
            margin={"none"}
            value={confirmPassword}
            pwdVisibileHide={true}
            showPassword={showPassword}
            handleChange={(e) => handleChange(e)}
            handleClickShowPassword={(e) => handleClickShowPassword(e)}
            validators={["required", "isPasswordMatch"]}
          ></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <ButtonComponent
            title={authenticationConstants.RESET_PASSWORD_BTN_TEXT}
            color={"primary"}
            size={"large"}
            style={{
              height: "42px",
              minWidth: "112px",
              padding: "8px 22px",
            }}
            type="submit"
          ></ButtonComponent>
        </Grid>
        <Grid item xs={12} className="pb-1">
          <TypographyComponent
            title={"Password must meet below requirements"}
            variant={"h4"}
          ></TypographyComponent>
          <TypographyComponent
            title={"  At least 6 characters"}
            variant={"h6"}
          ></TypographyComponent>
          <TypographyComponent
            title={"At least 1 uppercase letter"}
            variant={"h6"}
          ></TypographyComponent>
          <TypographyComponent
            title={"At least 1 lowercase letter"}
            variant={"h6"}
          ></TypographyComponent>
          <TypographyComponent
            title={"At least 1 number or symbol [!@#$^&*()_]"}
            variant={"h6"}
          ></TypographyComponent>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

// default props
ResetPassword.defaultProps = {
  classes: {},
  match: {}
};

// prop types
ResetPassword.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object
};

export default ResetPassword;
