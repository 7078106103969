const ImportFileComponentStyle = () => ({
  backimg: {
    width: "28px",
    height: "28px",
    background: "#FFFFFF",
    border: "1px solid #E2E2E2",
    padding: "2px 9px",
    borderRadius: "30px",
    cursor: "pointer"
  }
});

export default ImportFileComponentStyle;
