/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

import ContentLibraryStyles from "./ContentLibraryStyles";

// Import Component
import Container from "../Containers/Container";
import ContentLibraryRouter from "./ContentLibrary.Router"
import ContentLibraryTabs from "./contentLibrary.Tabs"
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";



// Tab Panel Function
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        //Tab Panel
        <Grid>
          <TypographyComponent variant="h6">{children}</TypographyComponent>
        </Grid>
      )}
    </div>
  );
}

//Tabs
function ContentLibrary(props) {

  //get props
  const { classes, match, location } = props;

  //Set Tabs State
  const [value, setValue] = React.useState(0);

  return (
    <Grid container className={classes.reports_Container}>

      <Grid container alignItems="center" justifyContent="space-between" className={classes.reports_header}>
        <ContentLibraryTabs classes={classes} match={match} location={location} />
      </Grid>

      <TabPanel value={value} index={0} className="width100">
        <Container page="HeaderTabs" align="baseline">
          <ContentLibraryRouter {...props} />
        </Container>
      </TabPanel>
    </Grid>
  );
}
// default props
ContentLibrary.defaultProps = {
  classes: {},
  handlePopoverClick: () => { }
};

// prop types
ContentLibrary.propTypes = {
  classes: PropTypes.object,
  handlePopoverClick: PropTypes.func
};
export default withStyles(ContentLibraryStyles)(ContentLibrary);
