import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useDispatch, useSelector } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { Grid, IconButton, Tooltip } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";
import arrayMove from "array-move";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";

import {
    DrawerClose
} from "../../../../../../assets/images/svgComponents/ImageComponent";
import TypographyComponent from "../../../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../../../../../components/TextFieldComponent/TextFieldComponent";
import DeleteDialogComponent from "../../../../../../components/DeleteDialogComponent/DeleteDialogComponent";

// settigns icon data level access
import { Edit, DeleteOrange, Tick } from "../../../../../../assets/images/svgComponents/ImageComponent";
import RepeatGrid from "../../../../../../assets/images/svg/repeat-grid.svg";

// Import Config Layout
import { getDrawerHeight } from "../../../../../../config/layout";

// Import Styles and Image
import TabStyle from "../../styles/METabStyles";

import DrawerPanel from "../DrawerPanel"

import { meSurveyAction, alertAction } from '../../../../../../redux/actions'

// Manger relationship editor textfield
const RelationshipEditor = forwardRef((props, ref) => {
    const { classes, m, onChangeRelationship,
        setDeleteDialog, orderIndex: index, relationships } = props
    const [value, setValue] = useState(m.name)
    const [orderIndex] = useState(index)

    useEffect(() => {
        ValidatorForm.addValidationRule("isDuplicateMERaterGroup", (curValue) => {
            return !(relationships.filter((f) => f.name === curValue).length > 1)
        });
    }, [])

    useImperativeHandle(ref, () => ({
        getValue() {
            return m.editable ? { orderIndex, value } : { orderIndex: relationships.length, value: "" }
        }
    }))

    return <>
        <div className={classes.cardConent}>
            <TextFieldComponent
                id={`${m.name}_${orderIndex}`}
                className={`${classes.cardText} cardText mrg-tb`}
                name={`${m.name}_${orderIndex}`}
                margin={"dense"}
                validators={["required", "isDuplicateMERaterGroup"]}
                disabled={m.editable === false}
                value={value}
                handleChange={(e) => {
                    setValue(e.value)
                }}
            />
        </div>
        <div className={`${classes.cardActions} mr-edit-icon`}>
            <Tooltip title={m.editable ? "Update" : "Edit"} arrow>
                <IconButton
                    className={classes.IconSaveContainer}
                    onClick={() => {
                        onChangeRelationship(
                            m.editable ? value : m.name,
                            orderIndex,
                            { editable: !m.editable }
                        )
                    }}
                    disabled={m.editable && (value || "").trim() === ""}
                    size="large">
                    {m.editable ? Tick() : Edit()}
                </IconButton>
            </Tooltip>
            {/* Added Condition to not delete Supervisior and Direct Report Rater groups */}
            {
                (orderIndex !== 0 && orderIndex !== 1) && 
                    <Tooltip title={"Delete"} arrow>
                        <IconButton
                            className={classes.IconSaveContainer}
                            onClick={() => {
                                setDeleteDialog({ _id: m._id, orderIndex: orderIndex })
                            }}
                            size="large">
                            {DeleteOrange()}
                        </IconButton>
                    </Tooltip>
            }
        </div>
    </>;
})

const MangeRelationship = (props) => {

    const { classes, open, onClose, survey_id } = props
    const drawerHeight = getDrawerHeight()
    const dispatch = useDispatch()
    const [deleteDialog, setDeleteDialog] = useState({ _id: null })
    const editor = useRef()

    // Get filter Data
    const { managerEffectiveness, metadata: { metadata } } = useSelector(state => state)
    const { relationships } = managerEffectiveness

    const demoMeta = {}
    metadata.map(meta => {
        demoMeta[meta.key] = meta.value
    })

    useEffect(() => {
        if (open) {
            dispatch(meSurveyAction.getManagerRelationship(survey_id))
        }
    }, [dispatch, open])

    const onChangeRelationship = (value, index, extras = {}, newRelations = null) => {
        const updatedRelationships = !newRelations ? [...relationships] : [...newRelations]
        updatedRelationships[index] = {
            ...(updatedRelationships[index] || { _id: "", new: true, editable: true }),
            _id: updatedRelationships[index].new ? value : relationships[index]['_id'],
            prevName: relationships?.[index]?.['name'],
            name: value,
            ...extras
        }

        // update id for newly added relationship
        // if (updatedRelationships[index].new) {
        //     updatedRelationships[index]._id = `${value}`
        // }
        dispatch(meSurveyAction.addOrEditRelationship(updatedRelationships))
    }

    const confirmDelete = () => {
        dispatch(meSurveyAction.deleteRelationship(survey_id, deleteDialog))
        setDeleteDialog({ _id: null })
    }

    const onSave = () => {
        let checkEmpty = false
        relationships.map((rel) => {
            if (rel.name === "") {
                checkEmpty = true
            }
        })
        if (!checkEmpty) {
            const updatedRelationships = relationships.map((m, i) => {
                m.orderIndex = i
                return m
            })
            dispatch(meSurveyAction.updateManagerRelationship(survey_id, {
                relationships: updatedRelationships
            }))
            onClose(false)
        } else {
            dispatch(alertAction.error(`Relationship name empty`))
        }
    }

    // Drag handle
    const DragHandle = SortableHandle(() => (
        <Tooltip arrow title={"Reorder Icon"}>
            <img src={RepeatGrid} className="cursor-move pt5 icon-hover" alt={"Menu"} draggable={false} />
        </Tooltip>
    ));

    // sortable single item
    const SortableItem = SortableElement(({ m, orderIndex }) => {
        return (
            <Grid
                className={classes.cardStyle} index={orderIndex}
                style={{ position: "relative", zIndex: 1400 }}
            >
                <div className={classes.cardLeftIcon}>
                    <DragHandle />
                </div>
                <div className={classes.cardLeftLabel}>
                    <span className={classes.cardIndex}>{orderIndex + 1}</span>
                </div>
                <RelationshipEditor
                    m={m}
                    ref={editor}
                    classes={classes}
                    onChangeRelationship={onChangeRelationship}
                    setDeleteDialog={setDeleteDialog}
                    orderIndex={orderIndex}
                    relationships={relationships}
                />
            </Grid>
        )
    })

    // sortable container
    const SortableList = SortableContainer(() => {
        return (
            <div style={{ width: "100%" }}>
                {
                    relationships.map((m, index) => {
                        return (
                            <SortableItem
                                m={m}
                                index={index}
                                orderIndex={index}
                                key={`sort-item-${index}`}
                            />
                        )
                    })
                }
            </div>

        )
    })

    // handle sort on end event
    const onSortEnd = (e) => {
        const { oldIndex, newIndex } = e;
        const reorderedRelationships = arrayMove(relationships, oldIndex, newIndex);
        dispatch(meSurveyAction.addOrEditRelationship(reorderedRelationships))
    }

    const isEditEnabled = relationships.filter(m => m.editable).length > 0

    return <>
        <DrawerPanel
            className={`${classes.drawer} ${"pe_drawer"}`}
            anchor='right'
            variant="temporary"
            open={open}
            onClose={() => { }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={`${"pr-1 pl-1 borderBottom"} ${classes.filterTitle}`}>
                <Grid item>
                    <Grid container alignItems="center">
                        <TypographyComponent variant={"h4"}
                            className="txtMedium">
                            {"Manage Rater Groups"}
                        </TypographyComponent>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton variant="contained" onClick={() => { onClose(false) }} size="large">
                        {DrawerClose()}
                    </IconButton>
                </Grid>
            </Grid>
            <Grid style={{ padding: '12px' }}>
                <span className={classes.raterGroupInfo}>
                    Self Relationships are automatically included and cannot be edited
                </span>
            </Grid>

            <ValidatorForm
                id="mrForm"
                name="mrForm"
                useref="mrForm"
                autoComplete="off"
                onSubmit={onSave}
                onError={(errors) => console.log(errors)}
            >
                <div
                    style={{
                        height: drawerHeight - 162,
                        padding: '12px'
                    }}
                    className={'scrolly'}
                >
                    <SortableList
                        useDragHandle
                        lockAxis="y"
                        onSortEnd={onSortEnd}
                    />
                    <ButtonComponent
                        classes={classes}
                        componentClassName={'addLinkBtn'}
                        title={"+ Add Another Relationship"}
                        disabled={isEditEnabled}
                        color={"primary"}
                        size={"small"}
                        type={"button"}
                        handleClick={() => {
                            const { orderIndex, value } = relationships.length > 0 ? editor.current.getValue() : {
                                orderIndex: relationships.length,
                                value: ""
                            }
                            const updatedRelationships = [...relationships]
                            updatedRelationships[orderIndex] = {
                                orderIndex,
                                _id: value,
                                name: value,
                                editable: false,
                                isDelete: false,
                                editable: true,
                                new: true
                            }
                            onChangeRelationship("", relationships.length, {}, updatedRelationships)
                        }}

                    />
                </div>
                <div className={classes.saveFilterBtn}>
                    <ButtonComponent
                        classes={classes}
                        componentClassName={'saveFilterBtn_btn'}
                        title={"Save"}
                        color={"primary"}
                        size={"small"}
                        type={"submit"}
                        disabled={isEditEnabled}
                    />
                    <ButtonComponent
                        classes={classes}
                        componentClassName={'saveFilterBtn_btn'}
                        title={"Cancel"}
                        // color={"secondary"}
                        size={"small"}
                        type={"button"}
                        className={ classes.cancelBtn }
                        handleClick={() => {
                            onClose(false)
                        }}
                    />
                </div>
            </ValidatorForm>
        </DrawerPanel>
        <DeleteDialogComponent
            id={`delete-popup`}
            open={deleteDialog._id !== null}
            content={"Delete this rater group?"}
            handleDeleteAction={() => confirmDelete()}
            handleDeleteOnCloseAction={() => setDeleteDialog({ _id: null })}
        />
    </>;
}

export default withStyles(TabStyle)(MangeRelationship);