import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import {DashboardClose, Expand} from "../../../../assets/images/svgComponents/ImageComponent";

import ReportsStyles from "../../ReportsStyles";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

//import redux constants
import { dashboardConstant } from "../../../../redux/constants";

// Import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import { updateStateReducerAction } from "../../../../redux/actions";

//component
import GridPopup from "./GridPopUp"
import GridContent from "./GridContent"
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";

function GridChart(props) {

    // Get Props
    const { classes, handleClose, DragHandle, isUpdate, stopChartReload, updateReportPreference, preference } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Set State
    const [isPopup, setIsPopup] = useState(false);

    // Get Reducer Date
    const { gridData , loading, showPopup } = useSelector((state) => state.dashboard.gridChart);

    // Get grid Chart Details
    // useEffect(() => {
    //     if (isUpdate && !loading) {
    //         stopChartReload("3")  
    //     }
    // }, [dispatch, isUpdate, loading]);

    // open popup
    const openPopup = () => {
        dispatch(
        updateStateReducerAction.update(
            dashboardConstant.SHOW_GRIDCHART_POPUP,
            !showPopup
        )
        );
    };

    const handleSorting = (sortObject) => {
        if(sortObject.sortField){
            const data = {
            ...preference,
                gridChart: {
                    ...preference.gridChart,
                    yAxisSort: {sortField: sortObject.sortField, sortBy: sortObject.sortBy},
                },
            };
            // Update Preferences
            updateReportPreference(data)
        }
    }


    return (
        <React.Fragment>
            <Grid item xs={12} className={"mb-14"}>
                <Grid item className={classes.insightTile}>
                    <Grid container alignItems="center" className="mb-1">
                        {
                            !loading && <Grid item>
                                <DragHandle />
                            </Grid>
                        }
                        <Grid item>
                            <TypographyComponent variant="h4" className="txtBold fontSize16  ml-1">
                                {"Matrix Questions"}
                            </TypographyComponent>
                        </Grid>
                        <Grid item className={classes.viewActionButton}>
                            {!loading &&
                                <Grid container>
                                    <Tooltip title={"Expand"} arrow >
                                    <IconButton onClick={() => { openPopup() }} size="large">
                                        {Expand()}
                                    </IconButton>
                                    </Tooltip>
                                    <Tooltip title={"Close"} arrow >
                                    <IconButton onClick={() => handleClose('3')} size="large">
                                        {DashboardClose()}
                                    </IconButton>
                                    </Tooltip>
                                </Grid>
                            }

                        </Grid>
                    </Grid>

                    {loading &&
                        <LoaderComponent></LoaderComponent>
                    }

                    {!loading &&
                        <Grid container>
                            {gridData && Object.keys(gridData).length > 0 && !isPopup &&
                                <GridContent
                                    classes={classes}
                                    gridData={gridData}
                                    onClickButton={() => { openPopup() }}
                                    preference={preference}
                                    handleSorting = {handleSorting}
                                />
                            }
                            {(!gridData || Object.keys(gridData).length === 0) && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    className="mt-9"
                                >
                                    <TypographyComponent variant="h3">
                                        {ReportsConstants.NO_DATA_CHART}
                                    </TypographyComponent>
                                </Grid>
                            )}
                        </Grid>
                    }
                </Grid>
            </Grid>

            {showPopup && (
                <DialogComponent open={showPopup} fullScreen={true}>
                    <GridPopup
                        classes={classes}
                        onClose={openPopup}
                        gridData={gridData}
                        preference={preference}
                        handleSorting={handleSorting}
                    />
                </DialogComponent>
            )}
        </React.Fragment>
    );
}

// default props
GridChart.defaultProps = {
    classes: {},
    isUpdate: false,
    updateReportPreference: () => { },
    handleClose: () => { },
    stopChartReload: () => {}
};

// prop types
GridChart.propTypes = {
    classes: PropTypes.object,
    isUpdate: PropTypes.bool,
    updateReportPreference: PropTypes.func,
    handleClose: PropTypes.func,
    stopChartReload: PropTypes.func
};

export default withStyles(ReportsStyles)(GridChart);