/* eslint-disable dot-notation */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import { Tooltip } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

// Import Styles and Image
import ParticipantStyles from "../../../Pariticipant/PariticipantStyles";
import DeleteImg from "../../../../../assets/images/svg/delete.svg";

// Import Actions
import { metadataAction } from "../../../../../redux/actions";
import ConversionListItems from "./ConversionListItems";

//Import Constants
import { excelConstants } from "../../../../../config/constants";

import DeleteDialogComponent from "../../../../../components/DeleteDialogComponent/DeleteDialogComponent"

const ConversionList = (props) => {
  const dispatch = useDispatch();

  // get props
  const { classes, metadataSelected, metadataWithRGI, metadata, selConversion } = props;

  const [isDelete, setDelete] = React.useState(false);
  const [deleteIndex, setDeleteIndex] = React.useState(0);

  // Default data  for comversion row
  const defaultData = {
    from: "",
    to: metadataSelected["key"],
    match: [],
    operator: "",
    value: ""
  };
  
  let firstFlag=false;
  if(Array.isArray(selConversion) && Array.isArray(metadataSelected.conversion) && selConversion.length>0 && metadataSelected.conversion.length===0){
    firstFlag=true;
  }

  // Create first row by Default if not found
  if (!metadataSelected["conversion"] && !firstFlag) {
    metadataSelected["conversion"] = [];
    metadataSelected["conversion"].push(defaultData);
    dispatch(metadataAction.selectedMetadata(metadataSelected));
  } else if (metadataSelected["conversion"].length === 0 && !firstFlag) {
    metadataSelected["conversion"].push(defaultData);
    dispatch(metadataAction.selectedMetadata(metadataSelected));
  }

  // Delete a conversion rule at index
  const deleteConversion = () => {
    metadataSelected["conversion"].splice(deleteIndex, 1);
    dispatch(metadataAction.selectedMetadata(metadataSelected));
    setDelete(false);
  };

  
  const handleDelete = (index) => {
    setDelete(true);
    setDeleteIndex(index)
  }

  const handleAdminCancelDelete = () => {
    setDelete(false);
    setDeleteIndex(null)
  };


  return (
    <React.Fragment>
      <TableContainer>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>From report Group</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Items to convert</TableCell>
              <TableCell>New item</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {metadataSelected["conversion"] &&
              metadataSelected["conversion"].map((item, index) => (
                <TableRow key={index}>
                  <ConversionListItems
                    metadata={metadata}
                    metadataWithRGI={metadataWithRGI}
                    metadataSelected={metadataSelected}
                    currentItem={item}
                    currentIndex={index}
                  />
                  <TableCell>
                    <IconButton
                      className={classes.deleteIcon}
                      onClick={() => {
                        handleDelete(index)
                      }}
                      size="large">
                      <Tooltip arrow title={excelConstants.DELETE_CONVERSION}>
                      <img src={DeleteImg} alt="delete"></img>
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteDialogComponent
        open={isDelete}
        content={"Are you sure you want to delete the Conversion rules"}
        handleDeleteAction={deleteConversion}
        handleDeleteOnCloseAction={handleAdminCancelDelete}
      />
    </React.Fragment>
  );
};
// default props
ConversionList.defaultProps = {
  classes: {},
  metadataSelected: {},
  metadataWithRGI: [],
  metadata: []
};

// prop types
ConversionList.propTypes = {
  classes: PropTypes.object,
  metadataWithRGI: PropTypes.array,
  metadata: PropTypes.array,
  metadataSelected: PropTypes.object
};
export default withStyles(ParticipantStyles)(ConversionList);
