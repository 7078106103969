import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";
import _ from "lodash";

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

// Import Component
import StackedBar from "../../../../components/Charts/StackedBarChart/StackedBar";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

// Import Constants
import { ReportsConstants } from "../../../../config/constants";
import { dashboardAction } from "../../../../redux/actions";

const EIWithRGIChart = (props) => {
  const dispatch = useDispatch();

  //get props
  let { classes,saveReportPreferences, preference, chartId, handleSorting } = props;

  //get reducer data
  const metadataWithRGIAll = useSelector((state) => state.metadata.metadataWithRGI);
  const { EiRgi: chartData, keyArr } = useSelector((state) => state.dashboard.engagementIndex);
  const { demographicsDropdown:demographics } = useSelector((state) => state.filter);

  const demographicsData=Object.keys(demographics)
  let metadataWithRGI=metadataWithRGIAll.filter(d=>(d.visible===true && d.visibleInFilter===true && demographicsData.indexOf(d.key)>-1))
  metadataWithRGI = _.orderBy(metadataWithRGI, ["value"], ["asc"]);

  //set the selected report group value from the poreference
  let selectedRg = preference &&  preference["EI"] && preference["EI"]["rg"]?preference["EI"]["rg"]: "";

  let selectedMetadataWithRGI = {};
  if (selectedRg) {
    selectedMetadataWithRGI = Array.isArray(metadataWithRGI) && metadataWithRGI.length > 0 ? metadataWithRGI.filter(d => d.key === selectedRg && d.visible && d.visibleInFilter)?.[0] : {}
  }
  const { dataType } = selectedMetadataWithRGI || {};
  const selectedMetadaDataType = dataType ? dataType : 'string';
  // sort
  let yAxisSortField = preference?.["EI"]?.["yAxisSort"]?.sortField ? preference["EI"]["yAxisSort"].sortField : 1
  let yAxisSortBy = preference?.["EI"]?.["yAxisSort"]?.sortBy ? preference["EI"]["yAxisSort"].sortBy : "desc"

  //default EI chart datas and color
  const groupArr = [
    "Actively Disengaged",
    "Opportunity Group",
    "Engaged/Contributor",
    "Actively Engaged",
  ];

  const rangeArr = {
    "Actively Disengaged": "#FFAE4B",
    "Opportunity Group": "#FFCE93",
    "Engaged/Contributor": "#98D9FE",
    "Actively Engaged": "#54C0FD",
  };

  const keyarrChange = (value, quesId) => {
    const updatedValue = yAxisSortBy === 'desc' && yAxisSortField === 3 ? value.reverse() : value;
    dispatch(dashboardAction.eiKeysArrUpdate(updatedValue))
  }
  const getSortOptions = (options) => {
    if (selectedMetadaDataType === 'integer' || selectedMetadaDataType === 'date') {
      const updateAscValue = selectedMetadaDataType === 'integer' ? '0 - 9' : 'Oldest - Newest';
      const updateDescValue = selectedMetadaDataType === 'integer' ? '9 - 0' : 'Newest - Oldest';

      if (options?.[0]?.['name'] && options?.[0]?.['name'] === 'A - Z') {
        options[0]['name'] = updateAscValue;
      }
      if (options?.[1]?.['name'] && options?.[1]?.['name'] === 'Z - A') {
        options[1]['name'] = updateDescValue;
      }
    }

    return options;
  }

  // custom demographics sort function
  const sortProperties = yAxisSortField === 3 ? groupArr.reverse() : groupArr;
  const demographicsSortFunction = (item) => {
    if ([1,2].includes(yAxisSortField)) {
      const meanRemovedDemographic = item.yAxis.replace(/\s\s\(N=\d+\)$/g, "")

      if (ReportsConstants?.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY?.[selectedRg]) {
        return ReportsConstants?.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY?.[selectedRg]?.[meanRemovedDemographic]
      } else if (selectedMetadaDataType === 'integer' || selectedMetadaDataType === 'date') {
        const parsedValue = selectedMetadaDataType === 'integer' ? parseInt(meanRemovedDemographic) : new Date(meanRemovedDemographic);
        return !isNaN(parsedValue) ? parsedValue : meanRemovedDemographic;
      } else {
        return meanRemovedDemographic.toLowerCase()
      }
    } else if ([3,4].includes(yAxisSortField)) {
      const presentProperty = sortProperties.reduce((acc, curr) => {
        if (acc === undefined && chartData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
          acc = curr
        }

        return acc;
      }, undefined);
      const val = item?.[presentProperty]
  
      return -val;
    } else if ([5,6].includes(yAxisSortField)) {
      if(keyArr.length===groupArrCopiedForSorting.length){
        const presentProperty = keyArr.reduce((acc, curr) => {
            if (acc === undefined && chartData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
                acc = curr
            }
    
            return acc;
        }, undefined);
        const val = item?.[presentProperty]
        return -val;
    }else{
      const presentProperty = keyArr.reduce((acc, curr) => {
          if (chartData.some(data => data?.[curr] !== undefined) && keyArr.indexOf(curr) !== -1) {
              acc.push(curr);
          }

          return acc;
      }, []);
      const val = presentProperty.map(prop => item[prop]).reduce((acc, value) => acc + (value || 0), 0)
      return -val;
    }
    }
  }
  
  let chartDataCopiedForSorting = [...chartData]
  let groupArrCopiedForSorting = [...groupArr]
  let keyArrCopiedForSorting = [...keyArr]
  

  chartDataCopiedForSorting = _.orderBy(chartDataCopiedForSorting, [demographicsSortFunction], yAxisSortBy)
  if (yAxisSortBy === "desc" && yAxisSortField === 3) {
    keyArrCopiedForSorting.reverse();
  } else if (yAxisSortBy === "asc" && yAxisSortField === 4) {
    chartDataCopiedForSorting = chartDataCopiedForSorting.reverse();
  }
  

  // get display name for auto complete dropdown
  const getOptionSelected = (options, value) => {
    let optionSelected = options.filter((p) => p.key === value)[0] || "";
    return optionSelected;
  };
  // get display name for sortby options
  const getSeletedSortOption = (options, value) => {
    let sortOptionSelected = options.filter((item) => item.value === value)[0] || ""
    return sortOptionSelected
  }

  return (
    <React.Fragment>
      <Grid className={classes.EIWidth70}>
        <Grid container>
          <Grid item md={3}>
            <Grid container>
              <Grid md={5} className={"pt-1 paddingLefts"}>
                <TypographyComponent
                  className={"txtBold txtcenter"}
                  title={ReportsConstants.VIEW_BY}
                  variant={"h6"}
                ></TypographyComponent>
              </Grid>
              <Grid md={6} className={"pr-1"}>
                <Autocomplete
                  id={"EngagnementIndexRGI"}
                  disableClearable
                  autoHighlight
                  blurOnSelect
                  options={metadataWithRGI}
                  value={getOptionSelected(metadataWithRGI, selectedRg)}
                  className={`input-value-editor autocomplete heightone`}
                  getOptionLabel={(option) => option.value || ""}
                  onChange={(e, val) => {
                    selectedRg = val.key;
                    saveReportPreferences(selectedRg)
                  }}
                  renderInput={(params) => ( 
                    <TextField
                      {...params}
                      variant={"outlined"}
                      className={`${"height28 conversionAutoComplete boderRadius4"}`}
                      placeholder="Select Field"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3}>
            <Grid container>
              <Grid md={5} className={"pt-1 paddingLefts"}>
                <TypographyComponent
                  className={"txtBold txtcenter"}
                  title={ReportsConstants.SORT_BY}
                  variant={"h6"}
                ></TypographyComponent>
              </Grid>
              <Grid md={6} className={"pr-1"}>
                <Autocomplete
                  id={"EngagnementIndexRGI"}
                  disableClearable
                  autoHighlight
                  blurOnSelect
                  sx={{ width: 170 }}
                  options={getSortOptions(ReportsConstants.EICHART_SORT_OPTIONS)}
                  value={getSeletedSortOption(ReportsConstants.EICHART_SORT_OPTIONS, yAxisSortField)}
                  className={`input-value-editor autocomplete heightone`}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={(e, val)=>{handleSorting({sortField: val.value, sortBy: val.sortBy})}}
                  renderInput={(params) => ( 
                    <TextField
                      {...params}
                      variant={"outlined"}
                      className={`${"height28 conversionAutoComplete boderRadius4"}`}
                      placeholder="Select Field"
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid className={`ml-auto pr-4 pt-2 heightAuto ${classes.paddingLeft120}`} md={11}>
          {chartData && chartData.length > 0 && (
          <StackedBar
            chartId={chartId}
            chartData={chartDataCopiedForSorting}
            groupArr={groupArrCopiedForSorting}
            zoomMode={false}
            keyarrChange={keyarrChange}
            keyArr={keyArrCopiedForSorting}
            rangeArr={rangeArr}
            chartWidth={1000}
            chartHeight={320}
          />
          )}
          {(!chartData || chartData.length === 0) && (
                <Grid container>
                    <TypographyComponent variant="h3">{ReportsConstants.NO_DATA_CHART}</TypographyComponent>
                </Grid>
          )}

        </Grid>
      </Grid>
    </React.Fragment>
  );
};
// default props
EIWithRGIChart.defaultProps = {
  classes: {},
  saveReportPreferences: () => {},
  preference:{},
};

// prop types
EIWithRGIChart.propTypes = {
  classes: PropTypes.object,
  saveReportPreferences : PropTypes.func,
  preference : PropTypes.object,
};
export default withStyles(ReportsStyles)(EIWithRGIChart);