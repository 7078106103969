import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Take Survey Service Functions
 */
export const surveyPreviewService = {
  getSurvey,
  sentTestSurveyMail,
  sendFeedBackMail,
  getPrintSurvey,
  getFilteredPreviewSurvey,
};

async function getSurvey(surveyId, filterStr = "") {
  const postData = { filters: filterStr };
  const response = await apihelper.postRequest(
    "surveypreview/" + surveyId,
    postData
  );
  return response;
}

async function sentTestSurveyMail(survey_id, emails, message, options) {
  const postData = { survey_id, emails, message, options };
  const response = await apihelper.postRequest("surveypreview/mail", postData);
  return response;
}

async function getFilteredPreviewSurvey(survey_id, filters) {
  const postData = { survey_id, filters };
  const response = await apihelper.postRequest(
    "surveypreview/getfiltered",
    postData
  );
  return response;
}

async function getPrintSurvey(surveyId, filteredQues, langCode) {
  try {
    const postData = {
      survey_id: surveyId,
      filters: filteredQues,
      language: langCode,
    };
    const response = await apihelper.postRequest(
      "surveypreview/download",
      postData,
      false,
      'json',
      true
    );
    if (response.data && response.data.filePath) {
      const responseOne = await apihelper.postRequestExcel(
        "pdf/exportPdf",
        response.data,
        false,
        "arraybuffer",
        true
      );
      return responseOne;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e);
  }
}

async function sendFeedBackMail(user_id, message) {
  const postData = { user_id, message };
  const response = await apihelper.postRequest(
    "surveypreview/feed_back_mail",
    postData
  );
  return response;
}
