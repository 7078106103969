import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";

// Import Styles and Image
import {DashboardClose} from "../../../../assets/images/svgComponents/ImageComponent";

import ReportsStyles from "../../ReportsStyles";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

//import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TotChart from "../../../../components/Charts/TrendsOverTimeChart/totChart";

function TotPopup(props) {
  const { classes, onClose, totdata, filterData } = props;
  const { alldata, pulseData } = totdata;

  return (
    <React.Fragment>
      <Grid container spacing={4} style={{ padding: "20px" }}>
        <Grid item xs={6} className="mt-2">
          <TypographyComponent variant="h4" className="txtBold fontSize16 ml-1">
            {"Trends Over Time"}
          </TypographyComponent>
        </Grid>
        <Grid item xs={6} className={classes.dialog_close}>
          <Button variant="text" size={"small"} onClick={onClose} className="mt-1">
            {DashboardClose()}
            <TypographyComponent variant="h5" className="pl5">
              {"Close"}
            </TypographyComponent>
          </Button>
        </Grid>
        <Grid item xs={12}>
          {alldata && alldata.length &&
            <TotChart
              data={alldata}
              pulseData={pulseData}
              params={filterData}
              chartId="popupTotChart"
            />
          }
          {(!alldata || alldata.length === 0) && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className="mt-9"
            >
              <TypographyComponent variant="h3">
                {ReportsConstants.NO_DATA_CHART}
              </TypographyComponent>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
TotPopup.defaultProps = {
  classes: {},
  totdata: {},
  filterData: {},
};

// prop types
TotPopup.propTypes = {
  classes: PropTypes.object,
  totdata: PropTypes.object,
  filterData: PropTypes.object,
};
export default withStyles(ReportsStyles)(TotPopup);
