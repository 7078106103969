const FailedPageComponentStyle = () => ({
  backimg: {
    width: "28px",
    height: "28px",
    background: "#FFFFFF",
    border: "1px solid #E2E2E2",
    padding: "7px",
    borderRadius: "30px",
    cursor: "pointer"
  },
  fileUploadContainer: {
    height: "100%",
    background: "#fff",
    maxWidth: "1000px",
    margin: "auto",
    boxShadow: "0px 0px 6px #0000000F"
  },
  uploadFail_img: {
    width: "36px",
    height: "36px",
    position: "absolute",
    right: "-5px",
    top: "10px"
  }
});

export default FailedPageComponentStyle;
