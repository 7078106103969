import Loginbgimg from "../../assets/images/loginbanner.png";

const AuthenticationLayoutStyles = () => ({
  banner_container: {
    backgroundImage: `url(${Loginbgimg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  banner_text: {
    whiteSpace: "pre-line",
    color: "#fff"
  },
  banner_text_h5: {
    whiteSpace: "pre-line",
    color: "#fff",
    marginTop: "12px",
    fontStyle: "bold"
  },
  banner_copyright: {
    fontFamily: "Roboto",
    textAlign: "center",
    color: "#fff",
    fontSize: "14px"
  },
  auth_right_block: {
    backgroundColor: "#fff",
    padding: "40px 44px"
  },
  auth_section: {
    height: "calc(85% - 30px)"
  },
  auth_container: {
    width: "445px",
    margin: "0 auto",
    paddingTop: "70px"
  },
  info_panel: {
      padding: "7px 6px 9px",
      margin: "8px",
      textAlign: "center",
      border: "1px solid #ccc",
      backgroundColor: "rgba(23, 160, 201, 0.3)",
      color: "#fff"
  },
  info_panel__text1: {
    fontSize: "16px",
    lineHeight: "1.5"
  },
  info_panel__text2: {
    fontSize: "13px",
    lineHeight: "1.4",
    "&.link": {
      color: "#FFF"
    }
  }
});

export default AuthenticationLayoutStyles;
