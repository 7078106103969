/* eslint-disable prefer-const */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DesktopTimePicker as TimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { PickersModalDialog } from "@mui/x-date-pickers/internals/components/PickersModalDialog"
import { StaticTimePicker } from '@mui/x-date-pickers/StaticTimePicker';
import { TextField } from "@mui/material";
// import InputAdornment from "@mui/material/InputAdornment";
// import { Icon } from "@mui/material";
import { ValidatorComponent } from "react-material-ui-form-validator";
import moment from "moment";

// Import Images
// import clockImg from "../../assets/images/svg/clock.svg";

/**
 *TimePicker Component
 *
 * @class TimePicker
 * @extends {Component}
 */
function TimePickerComponent(props) {
  // Handle TextField Events
  const handleChange = (event) => {
    // if this prop present no need to validate
    if (props.onValueChange && event) {
      let value = event;
      if (moment(event).isValid()) {
        value = moment(new Date(event)).format("LT")
      }
      props.onValueChange({ name: props.name, value, dateValue: event });
      return;
    }

    if (event) {
      let date = new Date(event);
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (!isNaN(hour) && !isNaN(minute)) {
        let finaltime = "";
        if (minute < 10) {
          minute = "0" + minute;
        }
        if (hour > 11) {
          if (hour !== 12) {
            hour = hour - 12;
          }
          finaltime = hour + ":" + minute + " PM";
        } else if (hour === 0) {
          finaltime = "12:" + minute + " AM";
        } else {
          finaltime = hour + ":" + minute + " AM";
        }
        props.handleChange({ name: props.name, value: finaltime });
      }
    }
  };

  /**
     *render html
     *
     * @returns
     * @memberof TimePicker
     */
  const { name, inputVariant, margin, renderInput, onChange, showToolbar, open, onOpen, className, extraProps } = props;
  let { value } = props;

  const [tempValue, setTempValue] = React.useState(value);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    setTempValue(value)
  }, [value])

  let newvalue = "";
  if (value !== "" && !props.onValueChange) {
    let arr = value.split(/:| /);
    if (arr[2] === "PM") {
      if (Number(arr[0]) !== 12) {
        arr[0] = Number(arr[0]) + 12;
      }
    }
    if (arr[2] === "AM") {
      if (Number(arr[0]) === 12) {
        arr[0] = "00";
      }
    }
    if ((arr[0].toString()).length === 1) {
      newvalue = "0000-01-01T0" + arr[0] + ":" + arr[1] + ":00";
    } else {
      newvalue = "0000-01-01T" + arr[0] + ":" + arr[1] + ":00";
    }
  } else {
    newvalue = "0000-01-01T07:00:00";
  }

  return (
    <>
      <TimePicker
        value={props.onValueChange ? value : (newvalue || null)}
        // mask="__:__ _M"
        className={className}
        onChange={handleChange}
        open={false}
        onOpen={() => {
          setShow(true);
        }}
        renderInput={(props) => <TextField
          name={name}
          size="small"
          margin={margin}
          placeholder="09:00 AM"
          {...extraProps}
          {...props} />}
      />
      <PickersModalDialog
        open={show}
        onAccept={() => {
          setShow(false);
          handleChange(moment(tempValue), tempValue);
        }}
        onDismiss={() => setShow(false)}
        onCancel={() => setShow(false)}
      >
        <StaticTimePicker
          displayStaticWrapperAs="mobile"
          onChange={(setValue) => setTempValue(setValue)}
          value={tempValue || null}
          renderInput={(params) => <TextField {...params} />}
        />
      </PickersModalDialog>
    </>
  );
}

// default props
TimePickerComponent.defaultProps = {
  name: "",
  value: "0000-01-01T07:00:00",
  className: "timepicker",
  margin: "dense",
  handleChange: () => { },
  renderInput: () => { },
  extraProps: {}
};

// prop types
TimePickerComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  margin: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  extraProps: PropTypes.object,
  renderInput: PropTypes.func
};
// Export Component
export default TimePickerComponent;

// Export Component
export class TimePickerValidator extends ValidatorComponent {

  getValue = (value) => {
    if (typeof value === "string") {
      return moment(`${moment().format("YYYY/MM/DD")} ${value}`)
    }
    return value
  }

  renderValidatorComponent() {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    const hText = (!isValid && this.getErrorMessage()) || helperText;

    return (
      <TimePickerComponent
        {...rest}
        value={this.getValue(this.props.value)}
        extraProps={{
          error: !isValid,
          helperText: hText
        }}
      />
    );
  }
};
