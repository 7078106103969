
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import Link from "@mui/material/Link";

import { Plus_blue } from "../../../assets/images/svgComponents/ImageComponent";
import SearchInputComponent from "../../../components/SearchInputCompoment/SearchInputComponent";
import AddReportContent from "./ReportContentTab/AddReportContent";
import RenderReportContent from "./ReportContentTab/RenderReportContent";
import ContentLibraryStyles from "../ContentLibraryStyles";


import { contentLibraryAction } from "../../../redux/actions"

function ReportContent(props) {

    const { classes } = props;
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(contentLibraryAction.getOneReport());
    }, [dispatch]);

    const { addReportContent} = useSelector(state => state.contentLibrary);
    const { reportContent } = useSelector(state => state.contentLibrary);

    const [addNewReportContent, setAddNewReportContent] = React.useState(false);

    // Handle Search Text Box Event
    const handleChange = (searchField, searchKey) => {
        dispatch(contentLibraryAction.searchReport(searchKey));
    };

    /**
 * Handle Clear Search Text
 */
    const handleClearSearch = () => {
        dispatch(contentLibraryAction.searchReport(""));
    };


    return (
        <Grid item sm={12} className={"pl-3 pr-3"}>
            <Grid item sm={12} className={"p5 dflex spacebetween pb-15"} >
                <SearchInputComponent
                    id="searchReportContent"
                    className={classes.content_lib_search}
                    ariaLabel={"Search"}
                    placeholder={"Search"}
                    //   value={sValIndex >= 0 ? sValues[sValIndex] : ""}
                    showClearIcon={true}
                    onInputChangeRequest={handleChange}
                    handleClearSearch={handleClearSearch}
                />
                <Grid item>
                    <Link
                        onClick={() => setAddNewReportContent(true)}
                        className={`${classes.content_linkimg}${" cursor-pointer"}`}
                        color="primary">
                        <IconButton color="inherit" className={"p-0"} size="large">{Plus_blue()}</IconButton>
                        Add Report Content
                        </Link>
                </Grid>
            </Grid>
            <Grid item sm={12} >
                {addNewReportContent &&
                    <AddReportContent
                        classes={classes}
                        reportContent={reportContent}
                        addReportContent={addReportContent}
                        updateAddActionFlag={(isclose) => {
                            setAddNewReportContent(isclose)
                        }}
                    />}
                {reportContent && reportContent.map((optItem, index) => (
                    <React.Fragment>
                        <RenderReportContent
                            classes={classes}
                            item={optItem}
                            index={index}
                        />
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
}

export default withStyles(ContentLibraryStyles)(ReportContent);

