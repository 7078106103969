import { alertConstant, categoryConstant, uiConstant } from "../constants";
import { categoryService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All category Actions Functions
export const categoryAction = {
  getCategory,
  getQuestions,
  search,
  addOrChangeQuestion,
  onCancelAddQuestion,
  removeCategoryData
};

/**
 * Get Category
 */
function getCategory() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(categoryConstant.CATEGORY_GET_CATEGORY_REQUEST));
    categoryService.getCategory().then(
      (data) => {
        dispatch(success(categoryConstant.CATEGORY_GET_CATEGORY_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(categoryConstant.CATEGORY_GET_CATEGORY_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get All Questions in category
 */
function getQuestions(id) {
  return (dispatch, getState) => {
    const { survey: { getOne } } = getState()
    let category = ""
    if (getOne.type === "Multi-rater feedback") {
      category = "Manager"
    }
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(categoryConstant.CATEGORY_GET_QUESTIONS_REQUEST));
    categoryService.getQuestions(id, category).then(
      (data) => {
        dispatch(success(categoryConstant.CATEGORY_GET_QUESTIONS_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(categoryConstant.CATEGORY_GET_QUESTIONS_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Search category and questions
 */
function search(text) {
  return (dispatch, getState) => {
    const { survey: { getOne } } = getState()
    let category = ""
    if (getOne.type === "Multi-rater feedback") {
      category = "Manager"
    }
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(categoryConstant.CATEGORY_SEARCH_REQUEST, text));
    categoryService.search(text, category).then(
      (data) => {
        dispatch(success(categoryConstant.CATEGORY_SEARCH_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(categoryConstant.CATEGORY_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Add question to a category
 * param: categoryName - contains string of category name
 * param: questionDataSet - contains the updated question
 */
function addOrChangeQuestion(categoryName, questionDataSet) {
  return {
    type: categoryConstant.CATEGORY_ON_ADD_QUESTION_ADD_OR_CHANGE,
    data: { categoryName, questionDataSet }
  };
}

/**
 * CANCEL Add question from a category
 * param: categoryName - contains string of category name to Cancel add question
 */
function onCancelAddQuestion(categoryName = "") {
  return {
    type: categoryConstant.CATEGORY_ON_CANCEL_ADD_QUESTION,
    data: { categoryName }
  };
}

//remove category details in question
function removeCategoryData() {
  return {
    type: categoryConstant.CATEGORY_CANCEL_ADD_QUESTION,
    data: {}
  };
}