import React from "react";
import PropTypes from "prop-types";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";

function SelectQuestion(props) {

  //Get props
  const { classes, saveReportPreferences, commentAnalysis, questions } = props;
  let checkQuesExists = false;

  if (questions && questions.length > 0 && commentAnalysis && commentAnalysis.baseQuesId)
    checkQuesExists = questions.filter(e => e.baseQusId === commentAnalysis.baseQuesId).length > 0;

      //set default question seleted
  let selectedQues = commentAnalysis && commentAnalysis.baseQuesId && checkQuesExists ? commentAnalysis.baseQuesId : questions[0] ? questions[0]["baseQusId"] : "";

  const getOptionSelected = (data, value) => {
    let optionSelected = data.filter((p) => p.baseQusId === value)[0] || "";
    return optionSelected;
  };

  return (
    <React.Fragment>
        <Autocomplete
        id={"selectNlpQues"}
        disableClearable
        autoHighlight
        blurOnSelect
        options={questions}
        value={getOptionSelected(questions, selectedQues)}
        variant="outlined"
        className={classes.QuestionWidth}
        getOptionLabel={(option) => option.question || ""}
        onChange={(e, val) => {
          selectedQues = val.baseQusId;
          saveReportPreferences(selectedQues);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            multiline
            variant="outlined"
            className={`${"height28min conversionAutoComplete pl-3 fontSize15 boderRadius4"}`}
            placeholder="Select Field"
          />
        )}
      />
      
    </React.Fragment>
  );
}
// default props
SelectQuestion.defaultProps = {
  classes: {},
  saveReportPreferences: {},
  commentAnalysis: {},
  questions: []
};

// prop types
SelectQuestion.propTypes = {
  classes: PropTypes.object,
  saveReportPreferences: PropTypes.object,
  commentAnalysis: PropTypes.object,
  questions: PropTypes.array
};
export default SelectQuestion;
