import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All spCaller Service Functions
 */
export const spCallerService = {
  getSurveyQueue,
  getCallerQueue,
  getCallerDetail,
  updateCallerDetail,
  addHistoryToCallerDetail
};

/**
 * Call spCaller survey queue list Service
 */
async function getSurveyQueue(skip, limit, sortBy, sort, sFields, sValues, updatedVisibleColumns = []) {
    const response = await apihelper.postRequest(`spcaller/survey-queue?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, updatedVisibleColumns });
    return response;
}

/**
 * Call spCaller survey queue list Service
 */
async function getCallerQueue(skip, limit, sortBy, sort, sFields, sValues, survey_id = null, formData = {updatedVisibleColumns: []}) {
  // let urlString = 'caller-queue'
  let urlString = 'performance-caller-queue'
  if (formData && formData.isGlobalCallerQueue === "global-caller-queue") {
    urlString = 'global-caller-queue'
  }
  const response = await apihelper.postRequest(`spcaller/${urlString}/?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, survey_id, ...formData });
  return response;
}

/**
 * Call caller detail get one
 */
async function getCallerDetail(spcaller_id = null, fromData = { from: "", isSearch: "", getDataOnly: false }) {
  const response = await apihelper.getRequest(`spcaller/caller-detail/${spcaller_id}/${fromData.from}/${fromData.isSearch}/${fromData.getDataOnly}`);
  return response;
}

/**
 * Call caller detail update particiapnt data
 */
async function updateCallerDetail(spcaller_id = null, formData = {}) {
  const response = await apihelper.postRequest(`spcaller/caller-detail/${spcaller_id}`, formData);
  return response;
}

/**
 * Call caller detail update particiapnt data
 */
async function addHistoryToCallerDetail(spcaller_id = null, formData = {}) {
  const response = await apihelper.postRequest(`spcaller/caller-detail/add/history/${spcaller_id}`, formData);
  return response;
}
  