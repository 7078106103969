import { profileConstant } from "../constants";
// import PROFILE_IMAGE from '../../assets/images/default_profile.png'

// Set Initial State
const initialState = {
  getOne: {},
  user: {
    client_id: {
      _id: null,
      name: "",
      samlssoAccess: false,
      saml: {}
    }
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case profileConstant.PROFILE_GET_ONE_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case profileConstant.PROFILE_GET_ONE_SUCCESS:
      return {
        ...state,
        getOne: action.data.data
      };
    case profileConstant.PROFILE_GET_ONE_UPDATE:
      return {
        ...state,
        getOne: action.data
      }
    case profileConstant.PROFILE_GET_ONE_FAILURE:
      return {
        ...state,
        getOne: initialState.getOne
      };

    // GET LOGGED IN USER DETAILS
    case profileConstant.PROFILE_LOGGED_IN_REQUEST:
      return {
        ...state
      };
    case profileConstant.PROFILE_LOGGED_IN_SUCCESS:
      return {
        ...state,
        user: action.data.data
      };
    case profileConstant.PROFILE_LOGGED_IN_FAILURE:
      return {
        ...state,
        user: initialState.user
      };

    //UPDATE MY_PROFILE
    case profileConstant.PROFILE_UPDATE_REQUEST:
      return {
        ...state
      };
    case profileConstant.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.data.data,
        }
      };
    case profileConstant.PROFILE_UPDATE_FAILURE:
      return {
        ...state
      };

    case profileConstant.PROFILE_UPDATE_CLIENT_REDUCER:
      return {
        ...state,
        user: {
          ...state.user,
          client_id: action.data.data
        }
      }
    default:
      return state;
  }
}
