import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Badge from "@mui/material/Badge";
import TextEditor from "../../../../components/TextEditor";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";

import { updateStateReducerAction, contentLibraryAction, alertAction } from "../../../../redux/actions";
import { contentLibraryConstant } from "../../../../redux/constants";

import { getReducerUpdatedData } from "../../../../helpers";


function AddActionAlert(props) {

    const { classes, item, actionAlert, index, addActionAlert } = props;
    const dispatch = useDispatch();

    const [textvalue, setTextvalue] = React.useState()

    let actionAlertNewData = {}
    if (actionAlert && item) {
        actionAlertNewData = item
    } else if (addActionAlert["TopicName"] === undefined) {
        actionAlertNewData["TopicName"] = "";
        actionAlertNewData["mailSubject"] = "";
        actionAlertNewData["mailContent"] = "";
    } else {
        actionAlertNewData = addActionAlert
    }

    const saveNewActionAlert = () => {
        let duplicateFound = false;
        let emptyTopic = false
        let emptySubject = false
        actionAlert.filter((actions) => {
            if (actionAlertNewData.TopicName.trim() === "") {
                emptyTopic = true
            } else if (!item && actionAlertNewData.TopicName.toLowerCase() === actions.TopicName.toLowerCase()) {
                duplicateFound = true
            } else if (actionAlertNewData._id !== actions._id && actionAlertNewData.TopicName.toLowerCase() === actions.TopicName.toLowerCase()) {
                duplicateFound = true
            } else if (actionAlertNewData.mailSubject.trim() === "") {
                emptySubject = true
            }
        })
        if (!duplicateFound && !emptyTopic && !emptySubject) {
            if (!item) {
                // actionAlert.push(actionAlertNewData);
                dispatch(contentLibraryAction.update(actionAlertNewData));
                dispatch(contentLibraryAction.addOrChangeActionAlert({}));
                props.updateAddActionFlag(false);
            } else {
                // on save close edit
                props.updateAddActionAlertFlag(true);
            }
        } else if (duplicateFound) {
            dispatch(alertAction.error("duplicate action alert are not allowed"));
        } else if (emptySubject) {
            dispatch(alertAction.error("Empty Mail subject is not allowed"));
        } else {
            dispatch(alertAction.error("Empty Topic name is not allowed"));
        }
    };

    const closeAction = () => {
        if (!item) {
            dispatch(contentLibraryAction.addOrChangeActionAlert({}));
            props.updateAddActionFlag(false);
        } else {
            // set / remove editable false for that action alert
            actionAlert.filter((alerts, ind) => {
                if (alerts.TopicName === item.TopicName) {
                    actionAlert[ind].editable = false;
                }
                return true
            });
            props.updateAddActionAlertFlag(false)
        }
    };

    const handleChange = (event) => {
        let reducerData = getReducerUpdatedData(event, actionAlertNewData);
        if (!item) {
            dispatch(contentLibraryAction.addOrChangeActionAlert(reducerData));
        } else {
            props.editActionAlertItem(reducerData)
        }
    }

    // set badge value for add action alert
    let badgeValue = 1;
    if (item) {
        badgeValue = index + 1
    } else {
        badgeValue = actionAlert.length + 1;
    }



    return (
        <Grid className={` ${classes.TopicExpand} width100`}>
            <Grid container alignItems="stretch" className="p15">
                <Grid item className={"pl-1 pr-2 pt-2"}>
                    <Badge
                        color="secondary"
                        className={`${classes.Count_badge} badgeextrabig`}
                        badgeContent={badgeValue}
                    ></Badge>
                </Grid>
                <Grid item className={"pr-1 pl-1"} style={{ flexGrow: "7" }}>
                    <Grid item sm={12} className="mb-1">
                        <TextField
                            id={"actionItems"}
                            name={"TopicName"}
                            className={`${classes.ContentLibMultiLineText} ${" fieldset width100"}`}
                            type={"text"}
                            disabled={actionAlertNewData && actionAlertNewData.TopicName === "Subject pdf report" ? true : false}
                            placeholder={"Topic Name"}
                            value={actionAlertNewData ? actionAlertNewData.TopicName : ""}
                            onChange={(e) => { handleChange({ ...e, "name": "TopicName", "value": e.target.value }) }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={12} className="mb-1">
                        <TextField
                            id={"actionItems"}
                            name={"mailSubject"}
                            className={`${classes.ContentLibMultiLineText} ${" fieldset width100"}`}
                            type={"text"}
                            placeholder={"Email Subject"}
                            value={actionAlertNewData ? actionAlertNewData.mailSubject : ""}
                            onChange={(e) => { handleChange({ ...e, "name": "mailSubject", "value": e.target.value }) }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item sm={12} className="mb-1 pt5">
                        <TextEditor
                            height={"200px"}
                            data={actionAlertNewData ? actionAlertNewData.mailContent : ""}
                            onChange={(event, editor) => { handleChange({ ...event, "name": "mailContent", "value": editor.getData() }) }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item>
                                <ButtonComponent
                                    title={"OK"}
                                    color={"primary"}
                                    size={"small"}
                                    handleClick={() => saveNewActionAlert()}
                                    pageClassName={classes.settings_addrulebtn}
                                ></ButtonComponent>
                                <ButtonComponent
                                    title={"Cancel"}
                                    color={"secondary"}
                                    size={"small"}
                                    handleClick={() => closeAction()}
                                    pageClassName="ml-1"
                                ></ButtonComponent>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddActionAlert;

