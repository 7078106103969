// import { makeStyles, withStyles } from '@mui/material/styles';

// const ProfileStyles = makeStyles((theme) => ({
const ProfileStyles = () => ({
  root: {
    flexGrow: 1,
    overflow: "hidden",
    padding: "24px",
    margin: "8px auto"
  },

  paper: {
    maxWidth: 400,
    margin: "8px auto",
    padding: "16px"
  },
  imgStyle: {
    width: "100px",
    height: "100px",
    objectFit: "fill",
    borderRadius: "50%"
  },
  avatarRoot: {
    width: 22,
    height: 22,
    left: "80px"
  },
  badgeStyle: {
    width: "22px",
    height: "22px"
  },
  client_profileBadge:
  {
    position: "absolute",
    right: "50%",
    left: "50%",
    zIndex: "100"
  },
  Client_Profile: {
    width: "130px",
    height: "130px",
    borderRadius: "130px",
    objectFit: "cover",
    border: "10px solid #fff",
    position: "absolute",
    top: "-85px",
    background: "#f2f4f5",
    boxShadow: "0px -3px 5px -1px #d9dadb"
  },
  profile_editicon: {
    position: "absolute",
    left: "32px",
    top: "4px"
  },
  page_title:
  {
    margin: "auto",
    maxWidth: "1140px",
    padding: "30px 20px 20px 20px"
  },
  changePassword: {
    background: "#F4F6F7CC",
    padding: "30px"
  },
  upArrowImg:
  {
    position: "absolute",
    top: "-12px",
    zIndex: "1"
  }
});

export default ProfileStyles;
