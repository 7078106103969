import * as apihelper from "../../helpers/apiHelper";

export const externalApiService = {
  postExternalApiConfig,
  getExternalApiConfig
};

/**
 * Get Content Library
 */
async function postExternalApiConfig(data) {
  const response = await apihelper.postRequest(
    `post-external-api-config`,
    data
  );
  return response;
}

async function getExternalApiConfig() {
  const response = await apihelper.getRequest(`get-api_config_data`);
  return response;
}
