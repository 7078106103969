// particpants reducer types
export const clientConstant = {
  // To Get All Clients Details
  CLIENTS_GETALL_REQUEST: "CLIENTS_GETALL_REQUEST",
  CLIENTS_GETALL_SUCCESS: "CLIENTS_GETALL_SUCCESS",
  CLIENTS_GETALL_FAILURE: "CLIENTS_GETALL_FAILURE",

  // To Get Particular Client Details
  CLIENTS_GET_ONE_REQUEST: "CLIENTS_GET_ONE_REQUEST",
  CLIENTS_GET_ONE_SUCCESS: "CLIENTS_GET_ONE_SUCCESS",
  CLIENTS_GET_ONE_UPDATE: "CLIENTS_GET_ONE_UPDATE",
  CLIENTS_SURVEY_THRESHOLD_UPDATE_SUCCESS: "CLIENTS_SURVEY_THRESHOLD_UPDATE_SUCCESS",
  CLIENTS_GET_ONE_FAILURE: "CLIENTS_GET_ONE_FAILURE",

  // To Get Particular Client Details
  CLIENTS_GET_LANGUAGE_LIST_REQUEST: "CLIENTS_GET_LANGUAGE_LIST_REQUEST",
  CLIENTS_GET_LANGUAGE_LIST_SUCCESS: "CLIENTS_GET_LANGUAGE_LIST_SUCCESS",
  CLIENTS_GET_LANGUAGE_LIST_FAILURE: "CLIENTS_GET_LANGUAGE_LIST_FAILURE",

  // To Get Particular Client Details
  CLIENTS_CREATE_REQUEST: "CLIENTS_CREATE_REQUEST",
  CLIENTS_CREATE_SUCCESS: "CLIENTS_CREATE_SUCCESS",
  CLIENTS_CREATE_FAILURE: "CLIENTS_CREATE_FAILURE",

  // To update Client details
  CLIENTS_UPDATE_REQUEST: "CLIENTS_UPDATE_REQUEST",
  CLIENTS_UPDATE_SUCCESS: "CLIENTS_UPDATE_SUCCESS",
  CLIENTS_UPDATE_FAILURE: "CLIENTS_UPDATE_FAILURE",

  // Delete Client Details
  CLIENTS_REMOVE_REQUEST: "CLIENTS_REMOVE_REQUEST",
  CLIENTS_REMOVE_SUCCESS: "CLIENTS_REMOVE_SUCCESS",
  CLIENTS_REMOVE_FAILURE: "CLIENTS_REMOVE_FAILURE",

  // check open ME surveys
  CLIENTS_CHECK_SURVEYS_REQUEST: "CLIENTS_CHECK_SURVEYS_REQUEST",
  CLIENTS_CHECK_SURVEYS_SUCCESS: "CLIENTS_CHECK_SURVEYS_SUCCESS",
  CLIENTS_CHECK_SURVEYS_FAILURE: "CLIENTS_CHECK_SURVEYS_FAILURE",

  //GET CLIENT PERMISSION
  SURVEY_CLIENT_PERMISSION_GET_REQUEST: "SURVEY_CLIENT_PERMISSION_GET_REQUEST",
  SURVEY_CLIENT_PERMISSION_GET_SUCCESS: "SURVEY_CLIENT_PERMISSION_GET_SUCCESS",
  SURVEY_CLIENT_PERMISSION_GET_FAILURE: "SURVEY_CLIENT_PERMISSION_GET_FAILURE",

  // To Get All Clients Industries Details
  CLIENTS_GETINDUSTRY_REQUEST: "CLIENTS_GETINDUSTRY_REQUEST",
  CLIENTS_GETINDUSTRY_SUCCESS: "CLIENTS_GETINDUSTRY_SUCCESS",
  CLIENTS_GETINDUSTRY_FAILURE: "CLIENTS_GETINDUSTRY_FAILURE",

  //Update Metadata
  METADATA_UPDATE_REQUEST: 'METADATA_UPDATE_REQUEST',
  METADATA_UPDATE_SUCCESS: 'METADATA_UPDATE_SUCCESS',
  METADATA_UPDATE_FAILURE: 'METADATA_UPDATE_FAILURE',

  CLEAR_CLIENT_GETONE: "CLEAR_CLIENT_GETONE"

};
