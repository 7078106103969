import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabScrollButton from "@mui/material/TabScrollButton";

function CategoryTabs(props) {
  // Get Props Values
  const { classes, questionData, langCode } = props;
  const { questions, catOrder, catDetails } = questionData;

  const activeCategoryTab = useSelector(
    (state) => state.surveyPreview.activeCategoryTab
  );

  let activeCategories = [];
  questions.forEach((ques) => {
    if (!activeCategories.includes(ques.category)) {
      activeCategories.push(ques.category);
    }
  });

  const newCatOrder = catOrder.filter((cat) => activeCategories.includes(cat));

  // pass State Value for Tabs
  const handleChange = (event, value) => {

    props.changeTabValue(value);
    event.preventDefault();
  };

  // Set Id and Controls for tabs
  function getCategoryTabsProps(index) {
    return {
      id: `category-tab-${index}`,
      "aria-controls": `Category-Tab-Panel-${index}`,
    };
  }

  function handleArrow(type) {
    const newTab =
      type == "next" ? activeCategoryTab + 1 : activeCategoryTab - 1;

    if ((newTab) => 0 && newTab <= newCatOrder.length - 1) {
      props.changeTabValue(newTab);
    }
  }

  return (
    <React.Fragment>
      <AppBar
        position="static"
        color="default"
        className={`${
          classes.rightPanelAppBarRoot
        } ${"tabborder_none pe_color_appbar takesurvey_appbar mt-1"}`}
      >
        <TabScrollButton
          direction="left"
          onClick={(e) => handleArrow("prev")}
          className={`mobileButtons buttonLeft`}
          disabled={activeCategoryTab > 0 ? false : true}
        />
        <TabScrollButton
          direction="right"
          onClick={(e) => handleArrow("next")}
          className={`mobileButtons buttonRight`}
          disabled={activeCategoryTab < newCatOrder.length - 1 ? false : true}
        />

        <Tabs
          value={activeCategoryTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          className={`${classes.rightPanelTabsRoot} ${"peTabs"}`}
          aria-label="categoryTabs"
        >
          {newCatOrder.map((item, index) => {
            const categoryName = catDetails[item][langCode];
            return (
              <Tab
                key={index}
                component="div"
                color={"primary"}
                classes={{
                  root: classes.rightPanelTabRoot,
                  selected: classes.rightPanelTabSelected,
                }}
                label={
                  <span className="dflex">
                    <span className={classes.tabcharlimit}>
                      {categoryName.name}
                    </span>
                  </span>
                }
                {...getCategoryTabsProps(index)}
              />
            );
          })}
        </Tabs>
      </AppBar>
    </React.Fragment>
  );
}

// default props
CategoryTabs.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
};

// prop types
CategoryTabs.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
};

export default CategoryTabs;
