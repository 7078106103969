import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

import TextareaAutosize from "@mui/material/TextareaAutosize";

// Import Styles and Image
import SurveyPreviewStyles from "../../SurveyPreviewStyles";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import ErrMsgComponent from "./QuestionErrors/ErrMsgComponent";

//import Constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

function OpenEnded(props) {
  // Get Props Values
  const {
    classes,
    langCode,
    item,
    surveyQuesAnswer,
    errorStatus,
    questionIndex,
  } = props;

  const value = surveyQuesAnswer;
  // const [value, setValue] = useState(surveyQuesAnswer);

  function handleAnswerUpdate(e) {
    const value = e.target.value;
    // setValue(value);
    props.handleAnswerUpdate(value);
  }

  const { baseQusId, text, isRequired } = item;
  const questionText = text[langCode];

  return (
    <React.Fragment>
      {/* Open Ended */}
      <Grid
        container
        alignItems="center"
        className={classes.dropdownSingleQuestion}
      >
        <Grid item md={6} xs={12} className="pb5">
          <TypographyComponent variant="body1">
            {questionIndex}. {questionText} {isRequired ? "*" : ""}
          </TypographyComponent>
          {errorStatus ? <ErrMsgComponent /> : null}
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid xs={12} item className={classes.textAreaResize}>
            <TextareaAutosize
              className={`${classes.bulk_ans_textarea} width100 pe_textarea lineHeight20 scrolly fontRoboto`}
              name={baseQusId}
              placeholder={""}
              value={value}
              onChange={handleAnswerUpdate}
              minRows={2}
              maxRows={2}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
OpenEnded.defaultProps = {
  classes: {},
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  handleAnswerUpdate: () => {},
  surveyQuesAnswer: "",
  errorStatus: false,
};

// prop types
OpenEnded.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
  handleAnswerUpdate: PropTypes.func,
  surveyQuesAnswer: PropTypes.string,
  errorStatus: PropTypes.bool,
};
export default withStyles(SurveyPreviewStyles)(OpenEnded);
