import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Import Icons
import {
  SettingAccordGrey,
  SettingAccordWhite,
} from "../../../../assets/images/svgComponents/ImageComponent";

// Import Styles & Constants
import APIIntegrationStyles from "../../APIIntegrationStyles";
import { internalAPIConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
import { apiIntegrationAction } from "../../../../redux/actions";


const ResponseDemographicsPanel = (props) => {
  // Define Dispatch
  const dispatch = useDispatch();
  //Get props
  const { classes , respDemo } = props;
  // Accordion Declaration and Action
  const [expanded, setExpanded] = React.useState("default-panel");
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  let metadata = useSelector((state) => state.metadata.metadata);
  metadata = JSON.parse(JSON.stringify(metadata));

  const [selectedDemographics, setSelectedDemographics] = useState(respDemo);
  const [selectAllDemoGrap, setSelectAllDemoGrap] = useState(false);
  const [handleChangeState, setHandleChangeState] = useState(false);
  const defaultDemographics = [];
  metadata.map((e) => {
      defaultDemographics.push({ ...e });
  });

  useEffect(() => {
    let selectedDemographyValues = [];
    defaultDemographics.map((value) => {
      if (selectedDemographics[value.key])
        selectedDemographyValues.push({
          key: value.value,
          status: selectedDemographics[value.key],
          value: value.key,
        });
    });
    dispatch(
      apiIntegrationAction.selectedDemographics(selectedDemographyValues)
    );
  }, [handleChangeState]);

  useEffect(()=>{
    const selected = Object.fromEntries(
      Object.entries(selectedDemographics).filter(([key, value]) => value === true)
    );
    if(Object.keys(selected).length == defaultDemographics?.length ){
      setSelectAllDemoGrap(true)
    } else{
      setSelectAllDemoGrap(false)
    }
  },[selectedDemographics])

  useEffect(()=>{
    setSelectedDemographics(respDemo)
  },[respDemo])

  const selectAllDemo = (event) => {
    const demo = defaultDemographics?.map(function (item) {
      var obj = {};
      obj[item.key] = event.value;
      return obj;
    });
    let li = [];
    if (demo.length > 0) {
      li = demo?.reduce(function (prev, curr) {
        return Object.assign(prev, curr);
      });
    }
    setSelectedDemographics(li)
    setHandleChangeState(true);
    setInterval(() => {
      setHandleChangeState(false);
    }, 0);
    setSelectAllDemoGrap(!selectAllDemoGrap)
  }


  const handleChange = (event) => {
    setHandleChangeState(true);
    setInterval(() => {
      setHandleChangeState(false);
    }, 0);
    setSelectedDemographics({
      ...selectedDemographics,
      [event.name]: event.value,
    });
    if(selectAllDemoGrap){
      setSelectAllDemoGrap(false)
    }
  };

  const bindDemgraphicsData = () => {
    return defaultDemographics.map((e, index) => {
      return (
        <Grid item sm={6} key={e["key"]}>
          <Grid
            container
            className={`${classes.demographicsItem} ${classes.demographicsXPadding}`}
          >
            <Grid item sm={6}>
              <TypographyComponent
                title={e["value"]}
                variant={"body1"}
                className="txtMedium"
              ></TypographyComponent>
            </Grid>
            <Grid item sm={6}>
              <SwitchComponent
                name={e["key"]}
                color="primary"
                align="center"
                checked={
                  selectedDemographics[e["key"]]
                    ? selectedDemographics[e["key"]]
                    : false
                }
                handleChange={(e) => handleChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };
  return (
    <Accordion
      className="mb-2 pe-accordion"
      expanded={expanded === "default-panel"}
      onChange={handlePanelChange("default-panel")}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        {expanded === "default-panel" ? (
          <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
        ) : (
          <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
        )}
        <TypographyComponent
          className={`${"txtBold flexBasis33 mt2"} ${
            expanded === "default-panel" ? "panel-active" : ""
          } `}
          variant={"h5"}
        >
          {internalAPIConstants.RESPONSE_DEMOGRAPHICS_PANEL.PANEL_LABEL}
        </TypographyComponent>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        
              <Grid item xs={6}>
                
              </Grid>
          <Grid item xs={6} className="dflex justifyend">
          <TypographyComponent
                title="Select All"
                variant={"body1"}
                className="txtMedium p-1"
              ></TypographyComponent>
          <SwitchComponent
                name="Select All"
                color="primary"
                align="center"
                checked={selectAllDemoGrap}
                handleChange={(e) => selectAllDemo(e)}
              />
          </Grid>
        </Grid>
        <Grid className="p-2 width100">
          <Grid container rowSpacing={1} columnSpacing={6} className={``}>
            <Grid item sm={6}>
              <Grid
                container
                pb={1}
                className={`${classes.demographicsXPadding}`}
              >
                <Grid item sm={6}>
                  <TypographyComponent
                    title={
                      internalAPIConstants.RESPONSE_DEMOGRAPHICS_PANEL
                        .DEMOGRAPHY_CUSTOM_NAME
                    }
                    variant={"h6"}
                    className="txtMedium"
                  ></TypographyComponent>
                </Grid>

                <Grid item sm={6}>
                  <TypographyComponent
                    title={
                      internalAPIConstants.RESPONSE_DEMOGRAPHICS_PANEL
                        .ALLOW_TO_API_NAME
                    }
                    variant={"h6"}
                    className={`txtMedium ${classes.simpleFlex}`}
                  >
                    <InformationComponent>
                      {
                        internalAPIConstants.RESPONSE_DEMOGRAPHICS_PANEL
                          .ALLOW_TO_API_HELP
                      }
                    </InformationComponent>
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item sm={6}>
              <Grid
                container
                pb={1}
                className={`${classes.demographicsXPadding}`}
              >
                <Grid item sm={6}>
                  <TypographyComponent
                    title={
                      internalAPIConstants.RESPONSE_DEMOGRAPHICS_PANEL
                        .DEMOGRAPHY_CUSTOM_NAME
                    }
                    variant={"h6"}
                    className="txtMedium"
                  ></TypographyComponent>
                </Grid>
                <Grid item sm={6}>
                  <TypographyComponent
                    title={
                      internalAPIConstants.RESPONSE_DEMOGRAPHICS_PANEL
                        .ALLOW_TO_API_NAME
                    }
                    variant={"h6"}
                    className={`txtMedium ${classes.simpleFlex}`}
                  >
                    <InformationComponent>
                      {
                        internalAPIConstants.RESPONSE_DEMOGRAPHICS_PANEL
                          .ALLOW_TO_API_HELP
                      }
                    </InformationComponent>
                  </TypographyComponent>
                </Grid>
              </Grid>
            </Grid>
            {bindDemgraphicsData()}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
// default props
ResponseDemographicsPanel.defaultProps = {
  classes: {},
  match: {},
  location: {},
};

// prop types
ResponseDemographicsPanel.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  location: PropTypes.object,
};
export default withStyles(APIIntegrationStyles)(ResponseDemographicsPanel);
