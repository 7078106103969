
const SliderComponentStyle = theme => ({
    root: {
        color: '#52af77',
        height: "20px",
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: 0,
        marginLeft: 1,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
        '& .Mui-disabled': {
            height: 24,
            width: 24,
            marginTop: 0,
            marginLeft: 1,
        }
    },
    active: {},
    valueLabel: {
        left: 'calc(-50% + 4px)',
    },
    track: {
        height: 6,
        borderRadius: 4,
    },
    rail: {
        height: 8,
        borderRadius: 4,
    },
});

export default SliderComponentStyle;
