/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "./ReportsStyles";

import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";

// Import Constants
import { ReportsConstants, surveyTypes } from "../../config/constants"

// Import Child Containser
import Filter from "../Filter/Filter"
import CommentAnalysis from "./Insight/CommentAnalysis/CommentAnalysis";
import DlaRole from "./DlaRole";


// Redux Actions
import { adminsAction, dashboardAction, updateStateReducerAction, filterAction } from "../../redux/actions"
import { ConsoleView } from "react-device-detect";

function InsightComment(props) {

    // get props
    const { classes } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Set User Preference
    const { queryDLA, client_id } = useSelector(state => state.profile.user);
    const preference = useSelector(state => state.admin.preference);
    const { filterData, surveys: surveyList } = useSelector(state => state.filter)

    // Spread Values
    const adminPreference = { ...preference }
    const { surveys, reportVisibility } = adminPreference
    const hasMESurvey = (filterData.surveys || []).filter(f => f.type === "Multi-rater feedback").length > 0

    // Reset Chart Load
    useEffect(() => {
        loadInitialChartValue(true)
    }, [dispatch])

    // Load Initial Chart
    const loadInitialChartValue = (initialReload) => {
        dispatch(dashboardAction.updateCommentChartStatus(true));
        dispatch(filterAction.getDemographicsDropdown({
            metadata: [],
            surveys: preference.surveys,
            date: preference.date
        }))

        if (!hasMESurvey) {
            if (initialReload) {
                loadCompletedChart()
            }
        } else {
            if (hasMESurvey) {
                dispatch(dashboardAction.getCompetencySummary());
            }
        }
    }

    // Load Initial Chart
    const loadCompletedChart = () => {
        // KeyDriver Data
        if (!queryDLA || queryDLA === "") {
            dispatch(dashboardAction.getKeyDriverData(() => { }, false))
        }
    }


    const filterSaveEvent = (data) => {
        updateReportPreference(data);
    }

    // Update User Report Preferences
    const updateReportPreference = (data) => {
        dispatch(adminsAction.updateReportPreference(data, () => {
            dispatch(dashboardAction.updateCommentChartStatus(true));
        }));
    }

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                {queryDLA && queryDLA !== " " &&
                    <DlaRole />
                }
                 <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <CommentAnalysis
                            preference={adminPreference}
                            updateReportPreference={updateReportPreference}
                            stopChartReload={true}
                            classes={classes} />
                    </Grid>
                    </Grid>

            </Grid>

            {/* Filters */}
            <Filter
                classes={classes}
                filterSaveEvent={filterSaveEvent}
                className={(reportVisibility && Object.keys(reportVisibility).length !== Object.keys(ReportsConstants.CHARTS).length) || typeof reportVisibility === 'undefined' ? 'posAbsDataFilterInsightIconWithMoreButton' : 'posAbsDataFilterInsightIcon'}
                needIcon={false}
            />
        </React.Fragment>
    );
}

// default props
InsightComment.defaultProps = {
    classes: {}
};

// prop types
InsightComment.propTypes = {
    classes: PropTypes.object
};

export default withStyles(ReportsStyles)(InsightComment);