/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Popover from "@mui/material/Popover";

// import componenets
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";

// Import Actions
import { updateStateReducerAction, alertAction } from "../../../../redux/actions";
import { Grid } from "@mui/material";

import { isTranslated, iseditedLangtxt } from "../../../../helpers";

function AnsChoicePopUp(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, questionData, question, anchorEl, langCode, translatedFields } = props;
  const { questions } = questionData;
  const { baseQusId } = question;
  if (question && question.ansSet && !question.ansSet[langCode]) {
    question.ansSet[langCode] = {};
  }

  // Handel Category Click
  const handleChange = (e, translateKey = "") => {
    let { name, value } = e;
    let nameArray = name.split(".");
    questions.map((ques, index) => {
      if (ques.baseQusId === question.baseQusId) {
        questions[index][nameArray[0]][nameArray[1]][nameArray[2]] = value;
      }
    });
    const reducerData = { ...questionData, questions: questions };
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_REDUCER_UPDATE, reducerData));
    updateTranslateHistory(translateKey)
  };

  // Update translated history
  const updateTranslateHistory = (translateKey = "") => {
    const translateEditedData = iseditedLangtxt(translatedFields, langCode, translateKey);
    dispatch(updateStateReducerAction.update(surveyConstant.EDIT_TRANSLATE_HISTORY_UPDATE_SUCCESS, translateEditedData));
  };

  // check for empty or duplicate Category
  const duplicateAnsSetCheck = () => {
    let emptyFound = false;
    let duplicateFound = false;
    let answerChoices = question.ansSet && question.ansSet[langCode] ? Object.values(question.ansSet[langCode]) : [];
    // check for empty
    emptyFound = answerChoices.indexOf("") > -1 || answerChoices.indexOf(" ") > -1 ? true : false;
    // check for duplicate ES6
    answerChoices = answerChoices.map(v => v.toLowerCase());
    duplicateFound = new Set(answerChoices).size !== answerChoices.length;
    if (langCode !== surveyQuestionsConstants.DEFAULT_LANG_CODE) {
      if (emptyFound || (question.ansSet && question.ansSet[langCode] && Object.values(question.ansSet.en).length !== Object.values(question.ansSet[langCode]).length)) {
        dispatch(alertAction.error(surveyQuestionsConstants.EMPTY_ANS_CHOICE));
      } else if (duplicateFound) {
        dispatch(alertAction.error(surveyQuestionsConstants.DUPLICATE_ANS_CHOICE));
      }
    }
    props.showAnsChoices(null);
  };

  /**
   * Generate Answer Choices
   */
  let generateAnsChoices = (ansSet) => {
    return ansSet.map((item, index) => {
      return (
        <Grid key={index} className="mb5">
          {langCode === "en" &&
            <TypographyComponent
              title={question.ansSet.en[item.toString()]}
              variant={"h6"}
              className={"fontSize13"}
            >
            </TypographyComponent>
          }
          {langCode !== "en" &&
            <React.Fragment>
              <TypographyComponent
                title={question.ansSet.en[item.toString()]}
                variant={"h6"}
                className={"fontSize13"}
              >
              </TypographyComponent>
              <TextFieldComponent
                id={`indexans.${index}`}
                className={`pad14 br-none oneline_ellipse_input bgGrey ${translatedFields && translatedFields.hasOwnProperty(langCode) ? (isTranslated(translatedFields, langCode, `${baseQusId}_ansSet_${index}`) ? classes.translatedField : classes.editedtranslatedField) : ""} `}
                name={`ansSet.${langCode}.${item.toString()}`}
                value={question.ansSet[langCode][item.toString()]}
                margin={"dense"}
                showErrorMessages={false}
                handleChange={(e) => handleChange(e, `${baseQusId}_ansSet_${index}`)}
              ></TextFieldComponent>
            </React.Fragment>
          }
        </Grid>
      );
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? "ans_set_popup" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      className={"answer_popup"}
      anchorEl={anchorEl}
      // onClose={() => {duplicateAnsSetCheck()}}
      onClose={() => { props.showAnsChoices(null); }}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center"
      }}
    >
      {question.ansSet && question.ansSet[langCode] &&
        generateAnsChoices(Object.keys(question.ansSet.en))}
    </Popover>
  );
}

// default props
AnsChoicePopUp.defaultProps = {
  classes: {},
  questionData: [],
  question: {},
  anchorEl: null,
  questionIndex: 0,
  showAnsChoices: () => { }
};

// prop types
AnsChoicePopUp.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  question: PropTypes.object,
  anchorEl: PropTypes.any,
  questionIndex: PropTypes.number,
  showAnsChoices: PropTypes.func
};

export default AnsChoicePopUp;
