import { authConstant } from "../constants";

// Set Initial State
const initialState = {
};

export default function (state = initialState, action) {
  switch (action.type) {
    case authConstant.LOGIN_REQUEST:
      return {
        ...state
      };
    case authConstant.LOGIN_SUCCESS:
      return {
        ...state
      };
    case authConstant.LOGIN_FAILURE:
      return {
        ...state
      };

    case authConstant.LOGOUT:
      return {
        ...state
      };
    case authConstant.RESET_PASSWORD_REQUEST:
      return {
        ...state
      };
    case authConstant.RESET_PASSWORD_SUCCESS:
      return {
        ...state
      };

    case authConstant.SAML_LOGIN_REQUEST:
      return {
        ...state
      };
    case authConstant.SAML_LOGIN_SUCCESS:
      return {
        ...state
      };
    case authConstant.SAML_LOGIN_FAILURE:
      return {
        ...state
      };

    case authConstant.RESET_PASSWORD_FAILURE:
      return {
        ...state
      };
    case authConstant.FORGOT_PASSWORD_REQUEST:
      return {
        ...state
      };
    case authConstant.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state
      };
    case authConstant.FORGOT_PASSWORD_FAILURE:
      return {
        ...state
      };
    case authConstant.RESET_PASSWORD_TOKEN_REQUEST:
      return {
        ...state
      };
    case authConstant.RESET_PASSWORD_TOKEN_SUCCESS:
      return {
        ...state
      };
    case authConstant.RESET_PASSWORD_TOKEN_FAILURE:
      return {
        ...state
      };
    case authConstant.CLIENT_UPDATE_PREFERENCE_REQUEST:
      return {
        ...state,
        preference: action.params
      };
    case authConstant.CLIENT_UPDATE_PREFERENCE_SUCCESS:
      return {
        ...state
      };
    case authConstant.CLIENT_UPDATE_PREFERENCE_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}
