// Participant reducer types
export const participantConstant = {
    // To Get Particular Participant Details
    PARTICIPANT_GET_ONE_REQUEST: "PARTICIPANT_GET_ONE_REQUEST",
    PARTICIPANT_GET_ONE_SUCCESS: "PARTICIPANT_GET_ONE_SUCCESS",
    PARTICIPANT_GET_ONE_FAILURE: "PARTICIPANT_GET_ONE_FAILURE",

    // Search PARTICIPANT
    PARTICIPANT_SEARCH_REQUEST: "PARTICIPANT_SEARCH_REQUEST",
    PARTICIPANT_SEARCH_SUCCESS: "PARTICIPANT_SEARCH_SUCCESS",
    PARTICIPANT_SEARCH_FAILURE: "PARTICIPANT_SEARCH_FAILURE",

    // To update Participant details
    PARTICIPANT_UPDATE_REQUEST: "PARTICIPANT_UPDATE_REQUEST",
    PARTICIPANT_UPDATE_SUCCESS: "PARTICIPANT_UPDATE_SUCCESS",
    PARTICIPANT_UPDATE_FAILURE: "PARTICIPANT_UPDATE_FAILURE",

    // Add Participant
    PARTICIPANT_ADD_REQUEST: 'PARTICIPANT_ADD_REQUEST',
    PARTICIPANT_ADD_SUCCESS: 'PARTICIPANT_ADD_SUCCESS',
    PARTICIPANT_ADD_FAILURE: 'PARTICIPANT_ADD_FAILURE',

    //Update Participant Reducer
    PARTICIPANT_GET_ONE_UPDATE: 'PARTICIPANT_GET_ONE_UPDATE',

    //Survey Search
    PARTICIPANT_SURVEY_SEARCH_REQUEST: 'PARTICIPANT_SURVEY_SEARCH_REQUEST',
    PARTICIPANT_SURVEY_SEARCH_SUCCESS: 'PARTICIPANT_SURVEY_SEARCH_SUCCESS',
    PARTICIPANT_SURVEY_SEARCH_FAILURE: 'PARTICIPANT_SURVEY_SEARCH_FAILURE',

    // ADD PARTICIPANT
    PARTICIPANT_GET_ONE_INIT: 'PARTICIPANT_GET_ONE_INIT',

    // Remove Participant
    PARTICIPANT_REMOVE_REQUEST: 'PARTICIPANT_REMOVE_REQUEST',
    PARTICIPANT_REMOVE_SUCCESS: 'PARTICIPANT_REMOVE_SUCCESS',
    PARTICIPANT_REMOVE_FAILURE: 'PARTICIPANT_REMOVE_FAILURE',

    //Get Remove Surveys Data
    REMOVE_SURVEYS_GET_REQUEST:"REMOVE_SURVEYS_GET_REQUEST",
    REMOVE_SURVEYS_GET_SUCCESS:"REMOVE_SURVEYS_GET_SUCCESS",
    REMOVE_SURVEYS_GET_FAILURE:"REMOVE_SURVEYS_GET_FAILURE",

    //Get override demographics Surveys Data
    OVERRIDE_SURVEYS_GET_REQUEST:"OVERRIDE_SURVEYS_GET_REQUEST",
    OVERRIDE_SURVEYS_GET_SUCCESS:"OVERRIDE_SURVEYS_GET_SUCCESS",
    OVERRIDE_SURVEYS_GET_FAILURE:"OVERRIDE_SURVEYS_GET_FAILURE",

    //Apply conversion 
    APPLY_CONVERSION_REQUEST:"APPLY_CONVERSION_REQUEST",
    APPLY_CONVERSION_SUCCESS:"APPLY_CONVERSION_SUCCESS",
    APPLY_CONVERSION_FAILURE:"APPLY_CONVERSION_FAILURE",

    // Survey Response Check
    PARTICIPANT_SURVEY_CHECK_REQUEST: "PARTICIPANT_SURVEY_CHECK_REQUEST",
    PARTICIPANT_SURVEY_CHECK_SUCCESS: "PARTICIPANT_SURVEY_CHECK_SUCCESS",
    PARTICIPANT_SURVEY_CHECK_FAILURE: "PARTICIPANT_SURVEY_CHECK_FAILURE",

    // Participant  age tenure calculation
    PARTICIPANT_AGETENURE_REQUEST: "PARTICIPANT_AGETENURE_REQUEST",
    PARTICIPANT_AGETENURE_SUCCESS: "PARTICIPANT_AGETENURE_SUCCESS",
    PARTICIPANT_AGETENURE_FAILURE: "PARTICIPANT_AGETENURE_FAILURE",

    //Get all surveys
    PARTICIPANT_GET_ALL_SURVEY_REQUEST: 'PARTICIPANT_GET_ALL_SURVEY_REQUEST',
    PARTICIPANT_GET_ALL_SURVEY_SUCCESS: 'PARTICIPANT_GET_ALL_SURVEY_SUCCESS',
    PARTICIPANT_GET_ALL_SURVEY_FAILURE: 'PARTICIPANT_GET_ALL_SURVEY_FAILURE',

    //To check the participant has particular demographics
    PARTICIPANT_DEMOGRAPHICS_SEARCH_REQUEST: 'PARTICIPANT_DEMOGRAPHICS_SEARCH_REQUEST',
    PARTICIPANT_DEMOGRAPHICS_SEARCH_SUCCESS: 'PARTICIPANT_DEMOGRAPHICS_SEARCH_SUCCESS',
    PARTICIPANT_DEMOGRAPHICS_SEARCH_FAILURE: 'PARTICIPANT_DEMOGRAPHICS_SEARCH_FAILURE',

    //To get survey data for participant notification
    PARTICIPANT_NOTIFICATION_SURVEY_REQUEST: 'PARTICIPANT_NOTIFICATION_SURVEY_REQUEST',
    PARTICIPANT_NOTIFICATION_SURVEY_SUCCESS: 'PARTICIPANT_NOTIFICATION_SURVEY_SUCCESS',
    PARTICIPANT_NOTIFICATION_SURVEY_FAILURE: 'PARTICIPANT_NOTIFICATION_SURVEY_FAILURE'
};
