import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";

// Import Theme
import theme from "../assets/theme/main";

// Routes
import Routes from "../config/routeConfig";

// Import Alert Component
import SnackBarComponent from "../components/SnackBarComponent/SnackBarComponent";
import PageLoaderComponent from "../components/PageLoaderComponent/PageLoaderComponent";

function App () {
  return (
    <React.StrictMode>
      <CssBaseline />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </StyledEngineProvider>

      <SnackBarComponent />

      <PageLoaderComponent/>
    </React.StrictMode>
  );
}

export default App;
