import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child route
import MESurveyList from "./MESurveyList";
import MESurveyAssign from "./MESurveyAssign";

function MERouter(props) {
    // get props
    const { match } = props;

    return (
        <Switch>
            <Route exact path={`${match.url}`} render={(props) => {
                return (
                    <MESurveyList
                        match={props.match}
                        {...props}
                    />
                );
            }} />
            <Route path={`${match.url}/assign/:survey_id`} render={(props) => {
                return (
                    <MESurveyAssign
                        match={props.match}
                        {...props}
                    />
                );
            }} />
        </Switch>
    );
}
// default props
MERouter.defaultProps = {
    match: {}
};

// prop types
MERouter.propTypes = {
    match: PropTypes.object
};
export default MERouter;