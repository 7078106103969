/* eslint-disable no-case-declarations */
/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

// Import Styles and Image
import ActionsStyles from "../ActionsStyles";

// Import Table Size
import { getHeaderTabActionTableHeight, getTableRowSize } from "../../../config/layout";

// Import History
import history from "../../../config/history";

// Import Constants
import { ActionConstants, excelConstants } from "../../../config/constants";

// Import Helpers
import { getQueryVariable } from "../../../helpers"

// Import Component
import TableComponent from "../../../components/TableComponent/TableComponent";

// Import Actions
import { actionsAction, updateStateReducerAction, excelAction } from "../../../redux/actions";

function ActionsPlans(props) {
  //get props
  const { classes } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  // Table size
  const tableHeight = getHeaderTabActionTableHeight();
  const tableRowSize = getTableRowSize();

  // Get People from store
  const actionplan = useSelector(state => state.actionplan.search);
  const { preference: { client_id } } = useSelector(state => state.admin)

  const { page, data } = actionplan;
  const { _id } = data;

  // drawer state
  const [drawer, setDrawer] = useState(false);

  //To Navigate to Edit Plan
  const handleNavigateAction = (_id) => {
    history.push({
      pathname: `/actions/editplan/${_id}`
    });
  };

  // Get Page Reset
  const isPageReset = getQueryVariable(props.location.search, "reset") === "true";
  const isPageRefresh = getQueryVariable(props.location.search, "refresh") === "true";

    // if clear search and call api
  useEffect(() => {
    if (isPageReset) {
      dispatch(actionsAction.search(1, tableRowSize, "active", "desc", [], []));
      history.replace(props.location.pathname);
    }
  }, [isPageReset])

  useEffect(()=>{
    if(isPageRefresh)
      history.replace(props.location.pathname)
  }, [isPageRefresh])
  /**Get All Actions */
  useEffect(() => {
    if (!isPageRefresh) {
      dispatch(actionsAction.search(1, tableRowSize, "active", "desc", [], []));
    }
  }, [dispatch, client_id]);

  /**
  * Handle Table pagination Event
  */
  const onChangePagination = (skip, limit) => {
    dispatch(actionsAction.search(skip, limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Sort Request Event
   */
  const onSortRequest = (sortBy, sort) => {
    dispatch(actionsAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    if(isPageRefresh)
      dispatch(actionsAction.search(page.skip, tableRowSize, page.sortBy, page.sort, sFields, sValues));
    else
      dispatch(actionsAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
  };

  /**
     * Handle Field Component On Change Event
     */
  const fieldComponentOnChange = (field, data, value) => {
    switch (field) {
      case "status":
        let updatedData = {};
        updatedData = { ...data, [field]: value };
        dispatch(actionsAction.updateList(updatedData, () => {
          // retain old search values
          if (page.skip) {
            dispatch(actionsAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
          } else {
            dispatch(actionsAction.search(1, tableRowSize, "status", "desc", [], []));
          }
        })
        );
        break;
      case "dataLevelAccess":
        handleNavigateAction(data._id);
        break;
      default:
    }
  };

  /**
   * Handle Action Button Event
   * @param {*} field
   * @param {*} id
   */
  const handelActionButtonClick = (field, id, name) => {
    switch (field) {
      case "DELETE":
        break;
      default:
        break;
    }
  };

  /** Delete Function*
   * @param {*} field
   * @param {*} id
   */
  const handleTableDeleteAction = (_id) => {
    dispatch(actionsAction.remove(_id, () => {
      // retain old search values
      if (page.skip) {
        dispatch(actionsAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
      } else {
        dispatch(actionsAction.search(1, tableRowSize, "status", "desc", [], []));
      }
    })
    );
  };

  return (
    <React.Fragment>
      {page.sFields && page.sFields.length > 0 ? <ButtonComponent
        form="clear-table-search"
        title={ActionConstants.CLEAR_ALL}
        color={"primary"}
        size={"small"}
        type="submit"
        variant={"text"}
        pageClassName="mr-0"
        handleClick={() => {
          history.replace(`${props.location.pathname}?reset=true`);
        }}
      /> : ""}
      <Grid item xs={12}>

        <Grid container>
          <TableComponent
            padding= "0 16px 12px 16px"
            data={data}
            header={page.fieldFilters.headerCols}
            field={page.fieldFilters.fieldCols}
            search={page.fieldFilters.searchCols}
            actions={["DELETE"]}
            height={tableHeight}
            page={page.skip}
            count={page.count}
            rowsPerPage={page.limit}
            links={[page.fieldFilters.fieldCols[0]]}
            dateFields={page.fieldFilters.dateCols}
            sort={page.sort}
            sortBy={page.sortBy}
            sFields={page.sFields}
            sValues={page.sValues}
            noDataErrorMessage={"No records Found"}
            removedSortingRow={[]}
            moreInfoIcons={[]}
            columnsMap={{}}
            showLoading={false}
            prevNext={false}
            paginationVisible={!page.lastPage}
            deleteContent={ActionConstants.DELETE_CONTENT}
            handleNavigateAction={handleNavigateAction}
            onChangePagination={onChangePagination}
            onSortRequest={onSortRequest}
            onInputChangeRequest={onInputChangeRequest}
            fieldComponentOnChange={fieldComponentOnChange}
            handelActionButtonClick={handelActionButtonClick}
            handleTableDeleteAction={handleTableDeleteAction}
            resetSearchText={isPageReset}
          >
          </TableComponent>
        </Grid>
      </Grid>
    </React.Fragment >
  );
}
// default props
ActionsPlans.defaultProps = {
  classes: {}
};

// prop types
ActionsPlans.propTypes = {
  classes: PropTypes.object
};
export default withStyles(ActionsStyles)(ActionsPlans);
