export const opportunityAreas = {
    // To get single Opprtunity Area Data
    OPPORTUNITY_GET_ONE_REQUEST: "OPPORTUNITY_GET_ONE_REQUEST",
    OPPORTUNITY_GET_ONE_UPDATE: "OPPORTUNITY_GET_ONE_UPDATE",
    OPPORTUNITY_GET_ONE_SUCCESS: "OPPORTUNITY_GET_ONE_SUCCESS",
    OPPORTUNITY_GET_ONE_FAILURE: "OPPORTUNITY_GET_ONE_FAILURE",

    // Toggle Add plan Dialog
    TOGGLE_ADD_PLAN_DIALOG: "TOGGLE_ADD_PLAN_DIALOG"
};
