/* eslint-disable no-unneeded-ternary */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import withStyles from '@mui/styles/withStyles';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

// Import Styles and Image
import AdminStyles from "./AdminStyles";

// Import Icons
import settingsicon from "../../../assets/images/svg/settings-accord.svg";
import BackImg from "../../../assets/images/svg/chev_back.svg";

// Import Constants
import { adminConstants, clientConstants } from "../../../config/constants";

// Import Actions
import { adminsAction, queryBuilderAction, alertAction, clientAction } from "../../../redux/actions";

// Import Container
import Container from "../../Containers/Container";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../components/InformationComponent/InformationComponent";
import AutoCompleteComponent from "../../../components/AutoCompleteComponent/AutoCompleteComponent";
import MultiSelectComponent from "../../../components/MultiSelectComponent/MultiSelectComponent";
import QueryBuilderComponent from "../../../components/QueryBuilderComponent/QueryBuilderComponent";
import MultiSelectActionComponent from "../../../components/MultiSelectActionComponent/MultiSelectActionComponent";
import CancelDialogComponent from "../../../components/CancelDialogComponent/CancelDialogComponent";


import { deFormatQuery } from "../../../components/QueryBuilderComponent/config";

// Import History
import history from "../../../config/history";
import MultiSelectComponentwithsuboption from "../../../components/MultiSelectComponent/MultiSelectComponentwithsuboption";

function EditAdmin(props) {
  // Accordion
  const [openCancelDialog, setOpenCancelDialog] = useState({ isDelete: false, isCancel: false });
  const [expandedDefault, setExpandedDefault] = React.useState("default-panel");
  const [expandedDLA, setExpandedDLA] = React.useState("datalevel-panel");
  const [expandedSLA, setExpandedSLA] = React.useState("survey-data-level-panel");
  const [reloadQB, setReloadQB] = useState(0);
  const [selectedSurvey, setSelectedSurvey] = useState([]);
  const [selectedSurveyNames, setSelectedSurveyNames] = useState([]);
  const [selectedMeSurvey, setSelectedMeSurvey] = useState([]);
  const [selectedMeSurveyNames, setSelectedMeSurveyNames] = useState([])

  /**
     * Handle Default Panel Change
     * @param {*} panel
     */
  const handleDefaultPanelChange = (panel) => (event, newExpanded) => {
    setExpandedDefault(newExpanded ? panel : true);
  };

  /**
     * Handle DLA Panel Change
     * @param {*} panel
     */
  const handleDLAPanelChange = (panel) => (event, newExpanded) => {
    setExpandedDLA(newExpanded ? panel : true);
  };

  /**
     * Handle SLA Panel Change
     * @param {*} panel
     */
  const handleSLAPanelChange = (panel) => (event, newExpanded) => {
    setExpandedSLA(newExpanded ? panel : true);
  };
  const formRef = useRef(null);

  // Define Dispatch
  const dispatch = useDispatch();
  const { classes, match } = props;

  // Set State
  let [admin, setAdmin] = useState({
    firstName: "",
    lastName: "",
    email: "",
    access: [],
    status: true,
    isDelete: false,
    queryDLA: "",
    samlsso: null,
    samlFieldName: null,
    surveys: [],
    meSurveys: []
  });

  const role = useSelector(state => state.admin.getRoles);
  // Add subroles to filter
  const reportSubRole = ["Comments", "Compliance", "Rehire Potential", "Raw Data"];
  const filtersubOption = role.filter(e => !reportSubRole.includes(e));
  
  const rolesList = filtersubOption.map((e) => {
    if (e === "Report") {
      return {
        option: e,
        subOption: reportSubRole.filter((subRole) => role.includes(subRole)),
      };
    } else {
      return {
        option: e,
      };
    }
  });
  const clientMeStatus = useSelector(state => state.client.getOne.managerEffectiveness);
  const { client_id } = useSelector(state => state.profile.user);
  const { audience: queryBuilderData } = useSelector(state => state.queryBuilder);
  const { saml } = client_id;
  const { surveys, meSurveys } = useSelector(state => state.admin.surveyAccess);

  /**
     * Get Particular Admin
     */
  useEffect(() => {
    dispatch(adminsAction.getRoles());
    dispatch(adminsAction.getSurveys());
    dispatch(queryBuilderAction.getFieldNames("audience"));
    dispatch(clientAction.getOne());
  }, [dispatch]);

  useEffect(() => {
    if (surveys.length > 0) {
      let selIds = []; let selNames = ["Select All"];
      surveys.forEach((val) => {
        selIds.push(val._id);
        selNames.push(val.name);
      })
      setSelectedSurvey(selIds);
      setSelectedSurveyNames(selNames);
      setAdmin({ ...admin, surveys: selIds });
      handleChange({ name: "surveys", value: selIds });
      handleChange({ name: "surveySelectAll", value: true });
    }
  }, [surveys]);

  /**
    * Handle form change
    */
  const handleChange = (e) => {
    const { name, value } = e;
    if (saml && saml.attributeName === name) {
      admin["samlFieldName"] = name;
      admin["samlFieldValue"] = value;
    }
    setAdmin((admin) => ({ ...admin, [name]: value }));
  };

  const handleAdminYes = () => {
    formRef.current.submit();
    setOpenCancelDialog({ ...openCancelDialog, isCancel: false });
  };

  /**
 * Handle Form Submit
 */
  const handleSubmit = () => {
    // e.preventDefault();
    dispatch(adminsAction.create(admin));
  }

  /**
     * Use Memo For Query Builder
     */
  const QB = useMemo(() => {
    return (
      <QueryBuilderComponent
        key={"DLA-Querybuilder"}
        classes={classes || {}}
        fieldOnChange={(fieldName) => {
          dispatch(queryBuilderAction.getFieldValues("audience", fieldName));
        }}
        handleQueryChange={(queryJSON, moongoQuery) => {
          const { hadEmptySet } = deFormatQuery(moongoQuery);
          handleChange({ name: "queryDLA", value: moongoQuery });
          if (hadEmptySet) {
            setReloadQB(reloadQB + 1);
          }
        }
        }
        collectionFields={queryBuilderData.fields.filter(t => t.type !== "date")}
        values={queryBuilderData.fieldValues}
        allowedOperators={["includes", "does not include"]}
        query={admin.queryDLA}
        isLoadedFromDB={true}
        defaultValidation={false}
        needInnerGroup={false}
        component = {true}
        restrictRepetitiveFields={true}
      />
    );
  }, [queryBuilderData, reloadQB]);

  /**
    * handleBack
    */
  function handleBack(e) {
    history.replace("/manage_people/admins?refresh=true");
  }

  /**
     * Handle Cancel
     */
  const handleCancel = () => {
    setOpenCancelDialog({ ...openCancelDialog, isCancel: true });
  };

  /**
* Handle Dialog Close Action
*/
  const handleDialogCancelAction = () => {
    setOpenCancelDialog({ isCancel: false, isDelete: false });
  };
  /**
     * Handle Dialog No Action
     */
  const handleDialogNoAction = () => {
    // localStorage.removeItem("formSubmitCancelAction");
    history.push({
      pathname: "/manage_people/admins"
    });
  };

  //autocomplete component change
  const handleAutoCompleteChange = (event) => {
    const selectedVal = event.value;
    const action = event.action
    let selectedSurveyIds = [];
    let selectedSurveyNames = [];
    let nonMeSelectAll = false;
    const isAllSelected = selectedVal.find((ele) => ele === "Select All");
    if (isAllSelected) {
      nonMeSelectAll = true;
      if (action === "selectOption") {
        surveys.forEach((val) => {
          selectedSurveyIds.push(val._id);
          selectedSurveyNames.push(val.name);

        })
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames(["Select All", ...selectedSurveyNames]);
      }
      else {
        dispatch(alertAction.error('You must unselect “All Surveys” to assign only specific surveys to this user.'))
        // selectedVal.forEach((val) => {
        //   if (val !== "Select All") {
        //     let surveyData = surveys.find((ele) => ele.name === val);
        //     selectedSurveyIds.push(surveyData._id);
        //     selectedSurveyNames.push(surveyData.name);
        //   }
        // })
        // setSelectedSurvey([...selectedSurveyIds]);
        // setSelectedSurveyNames([...selectedSurveyNames]);
      }
    }
    else {
      if (surveys.length === selectedVal.length && action === "removeOption") {
        nonMeSelectAll = false;
        setSelectedSurvey([]);
        setSelectedSurveyNames([]);
      }
      else if (surveys.length === selectedVal.length && action === "selectOption") {
        surveys.forEach((val) => {
          selectedSurveyIds.push(val._id);
          selectedSurveyNames.push(val.name);
        })
        nonMeSelectAll = true;
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames(["Select All", ...selectedSurveyNames]);
      }
      else {
        selectedVal.forEach((val) => {
          let surveyData = surveys.find((ele) => ele.name === val);
          selectedSurveyIds.push(surveyData._id);
          selectedSurveyNames.push(surveyData.name);
        })

        nonMeSelectAll = false;
        setSelectedSurvey([...selectedSurveyIds]);
        setSelectedSurveyNames([...selectedSurveyNames]);
      }
    }
    handleChange({ name: "surveys", value: selectedSurveyIds });
    handleChange({ name: "surveySelectAll", value: nonMeSelectAll });
  }

  const getSurveyNames = () => {
    let surveyNames = surveys.length > 0 ? ["Select All"] : []; // if need select all option
    surveys.forEach((ele) => {
      surveyNames.push(ele.name);
    })
    return surveyNames;
  }

  const getMeSurveyNames = () => {
    let surveyNames = meSurveys.length > 0 ? ["Select All"] : []; // if need select all option
    meSurveys.forEach((ele) => {
      surveyNames.push(ele.name);
    })
    return surveyNames;
  }


  const getPulseSurveys = () => {
    let pulseSurveys = [];
    surveys.forEach((ele) => {
      if (ele.frequency === "Pulse") {
        pulseSurveys.push(ele.name);
      }
    })
    return pulseSurveys;
  }

   /**
    * Handle form change
    */
   const handleRolesChange = (e) => {
    const { name, value } = e;
    let values = []
    value.map((e)=>{if(e.option){
      values.push(e.option)
    }else{
      values.push(e)
    }})
    e["value"] = values
    console.log(e)
    admin = { ...admin, [name]: values };
    handleChange(e);
  };

  //autocomplete component change
  const handleMeAutoCompleteChange = (event) => {
    const selectedVal = event.value;
    const action = event.action
    let selectedMeSurveyIds = [];
    let selectedMeSurveyNames = [];
    let meSelectAll = false;

    const isAllSelected = selectedVal.find((ele) => ele === "Select All");
    if (isAllSelected) {
      meSelectAll = true;
      if (action === "selectOption") {
        meSurveys.forEach((val) => {
          selectedMeSurveyIds.push(val._id);
          selectedMeSurveyNames.push(val.name);

        })
        setSelectedMeSurvey([...selectedMeSurveyIds]);
        setSelectedMeSurveyNames(["Select All", ...selectedMeSurveyNames]);
      }
      else {
        dispatch(alertAction.error('You must unselect “All Surveys” to assign only specific surveys to this user.'))
      }
    }
    else {
      if (meSurveys.length === selectedVal.length && action === "removeOption") {
        meSelectAll = false;
        setSelectedMeSurvey([]);
        setSelectedMeSurveyNames([]);
      }
      else if (meSurveys.length > 0 && meSurveys.length === selectedVal.length && action === "selectOption") {
        meSurveys.forEach((val) => {
          selectedMeSurveyIds.push(val._id);
          selectedMeSurveyNames.push(val.name);
        })
        meSelectAll = true;
        setSelectedMeSurvey([...selectedMeSurveyIds]);
        setSelectedMeSurveyNames(["Select All", ...selectedMeSurveyNames]);
      }
      else {
        selectedVal.forEach((val) => {
          let surveyData = meSurveys.find((ele) => ele.name === val);
          selectedMeSurveyIds.push(surveyData._id);
          selectedMeSurveyNames.push(surveyData.name);
        })
        meSelectAll = false;
        setSelectedMeSurvey([...selectedMeSurveyIds]);
        setSelectedMeSurveyNames([...selectedMeSurveyNames]);
      }
    }
    handleChange({ name: "meSurveys", value: selectedMeSurveyIds })
    handleChange({ name: "meSurveySelectAll", value: meSelectAll })
  }

  //multiselect dropdown value
  /*  const autocompleteValue = () => {
     let surveyNames = [];
     if (selectedSurvey.length > 0) {
       let resArr = []
       for (let i = 0; i < selectedSurvey.length; i++) {
         let sId = selectedSurvey[i]._id;
         if (sId == "all") {
           resArr.push("Select All")
         } else {
           let sData = allSurveys.find((ele) => ele._id == sId);
           resArr.push(sData.name);
         }
       }
       surveyNames = resArr;
     }
     return surveyNames;
   } */

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Grid container alignItems="center">
                  <img className={classes.backimg} src={BackImg} alt="back" onClick={(e) => handleBack(e)} />
                  <TypographyComponent
                    variant={"h3"}
                    className="pl-1"
                    title={admin.firstName + " " + admin.lastName} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ValidatorForm
              ref={formRef}
              id="adminNewForm"
              name="adminNewForm"
              useref="adminNewForm"
              autoComplete="off"
              onSubmit={handleSubmit}
              onError={(error) => { console.log(error); }}>
              <div className={"container"}>
                <Accordion className="mb-2 pe-accordion"
                  expanded={expandedDefault === "default-panel"}
                  onChange={handleDefaultPanelChange("default-panel")}>

                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandicon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <img src={settingsicon}
                      className={`${classes.settings_icons} ${expandedDefault === "default-panel" ? "panel-active" : ""}`}
                      alt={"settings"} />
                    <TypographyComponent
                      className={`${"txtBold"} ${expandedDefault === "default-panel" ? "panel-active" : ""} `}
                      variant={"h5"}>
                      {adminConstants.DEFAULT}</TypographyComponent>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid className="p-2">
                      <Grid container spacing={3}>
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.FIRST_NAME}
                            variant={"h6"}>
                          </TypographyComponent>
                          <TextFieldComponent
                            id={"admin_firstName"}
                            name={"firstName"}
                            value={admin.firstName}
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.LAST_NAME}
                            variant={"h6"}
                          ></TypographyComponent>
                          <TextFieldComponent
                            id={"admin_lastName"}
                            name={"lastName"}
                            value={admin.lastName}
                            type='text'
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.EMAIL}
                            variant={"h6"}
                          ></TypographyComponent>
                          <TextFieldComponent
                            id={"admin_email"}
                            name={"email"}
                            value={admin.email}
                            type='text'
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                            validators={["required", "isEmail"]}
                          ></TextFieldComponent>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.ROLES}
                            variant={"h6"}
                          ></TypographyComponent>
                          {/* <MultiSelectComponent
                            name={"access"}
                            suggestions={rolesList}
                            handleAutoCompolete={(name, value) => {
                              handleChange({ name, value });
                            }}
                            isArrayObjects={true}
                            value={admin.access}
                          /> */}
                          <MultiSelectComponentwithsuboption
                            name={"access"}
                            suggestions={rolesList}
                            handleAutoCompolete={(name, value) => {
                              handleRolesChange({ name, value });
                            }}
                            isArrayObjects={true}
                            value={admin.access}
                          />
                        </Grid>
                        {saml && saml.attributeName && <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={saml.attributeName}
                            variant={"h6"}>
                            <InformationComponent>
                              {clientConstants.SSO_IDENTIFIER_HELP}
                            </InformationComponent>
                          </TypographyComponent>
                          <TextFieldComponent
                            id={"admin_samlsso"}
                            name={saml.attributeName}
                            value={admin.samlsso}
                            type='text'
                            size={"small"}
                            margin={"none"}
                            handleChange={(e) => handleChange(e)}
                          ></TextFieldComponent>
                        </Grid>}
                        <Grid item md={4} xs={12}>
                          <TypographyComponent
                            title={adminConstants.ACTIVE_STATUS}
                            variant={"h6"}>
                            <InformationComponent>
                              {adminConstants.ACTIVE_STATUS_HELP}
                            </InformationComponent>
                          </TypographyComponent>
                          <AutoCompleteComponent
                            name={"status"}
                            suggestions={adminConstants.ACTIVE}
                            handleAutoCompolete={(event, name) => handleChange({ name, value: event.name === "Active" ? true : false })}
                            value={admin.status ? { name: "Active" } : { name: "Inactive" }}
                            validators={["required"]}
                            showErrorMessages={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="mb-2 pe-accordion"
                  expanded={expandedSLA === "survey-data-level-panel"}
                  onChange={handleSLAPanelChange("survey-data-level-panel")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandicon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <img src={settingsicon}
                      className={`${classes.settings_icons} ${expandedSLA === "survey-data-level-panel" ? "panel-active" : ""}`}
                      alt={"settings"} />
                    <TypographyComponent
                      className={`${"txtBold flexBasis33"} ${expandedSLA === "survey-data-level-panel" ? "panel-active" : ""} `}
                      variant={"h5"}>
                      {adminConstants.SURVEY_LEVEL_ACCESS}
                    </TypographyComponent>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={2} className={classes.surveylevel}>
                      <Grid item md={4} xs={12}>
                        <MultiSelectComponent
                          name={"survey"}
                          suggestions={getSurveyNames()}
                          selectedOptions={selectedSurvey}
                          placeholder={adminConstants.SELECT_EMPLOYEE_EXPERIENCE_SURVEY}
                          handleAutoCompolete={(name, value, event, action) => {
                            handleAutoCompleteChange({ name, value, event, action });
                          }}
                          value={selectedSurveyNames}
                          isArrayObjects={true}
                          pulseSurveys={getPulseSurveys()}
                        />
                      </Grid>

                      {clientMeStatus ? (meSurveys && meSurveys.length > 0 ? <Grid item md={4} xs={12}>
                        <MultiSelectComponent
                          name={"meSurvey"}
                          suggestions={getMeSurveyNames()}
                          selectedOptions={selectedMeSurvey}
                          placeholder={adminConstants.SELECT_MULTIRATER_FEEDBACK_SURVEY}
                          handleAutoCompolete={(name, value, event, action) => {
                            handleMeAutoCompleteChange({ name, value, event, action });
                          }}
                          value={selectedMeSurveyNames}
                          isArrayObjects={true}
                        />
                      </Grid> :
                        <Grid item md={4} xs={12}>
                          <MultiSelectComponent
                            name={"meSurvey"}
                            suggestions={[]}
                            selectedOptions={[]}
                            placeholder={adminConstants.SELECT_MULTIRATER_FEEDBACK_SURVEY}
                            handleAutoCompolete={(name, value, event, action) => {
                              handleMeAutoCompleteChange({ name, value, event, action });
                            }}
                            value={selectedMeSurveyNames}
                            isArrayObjects={true}
                          />
                        </Grid>
                      ) : null}

                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="mb-6 pe-accordion"
                  expanded={expandedDLA === "datalevel-panel"}
                  onChange={handleDLAPanelChange("datalevel-panel")}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandicon" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <img src={settingsicon}
                      className={`${classes.settings_icons} ${expandedDLA === "datalevel-panel" ? "panel-active" : ""}`}
                      alt={"settings"} />
                    <TypographyComponent
                      className={`${"txtBold flexBasis33"} ${expandedDLA === "datalevel-panel" ? "panel-active" : ""} `}
                      variant={"h5"}>
                      {adminConstants.DLA}
                    </TypographyComponent>
                    <TypographyComponent
                      className={`${"oneline_ellipse mt2"} ${expandedDLA === "datalevel-panel" ? "panel-active" : ""
                        }`}
                      variant={"h6"}
                    >
                      {adminConstants.DLA_HELP}
                    </TypographyComponent>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid xs={12}>
                      {
                        queryBuilderData.fields.length > 0 ? QB : (
                          <div>Metadata is empty</div>
                        )
                      }
                    </Grid>
                  </AccordionDetails>
                </Accordion>

              </div>
              <Grid container justifyContent="flex-end" className={classes.footerBtns_container}>
                <Grid item>
                  <ButtonComponent
                    title={"Save"}
                    color={"primary"}
                    size={"small"}
                    type="submit"
                    form="adminNewForm"
                    pageClassName="mr-1"
                  ></ButtonComponent>
                  <ButtonComponent
                    title={"Cancel"}
                    color={"secondary"}
                    size={"small"}
                    type="button"
                    handleClick={handleCancel}
                  ></ButtonComponent>
                </Grid>
              </Grid>
              <CancelDialogComponent
                open={openCancelDialog.isCancel}
                constent={adminConstants.CANCEL_CONTENT}
                handleCancelAction={handleDialogCancelAction}
                handleOnNoAction={handleDialogNoAction}
                handleOnYesAction={handleAdminYes}
              />
            </ValidatorForm>
          </Grid>
        </Grid>
      </Container>

    </React.Fragment>
  );
}
export default withStyles(AdminStyles)(EditAdmin);
