import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';


export const CreateSurvey = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="46.014" height="46.015" viewBox="0 0 46.014 46.015" style={{ margin: "20px" }}><defs></defs><g transform="translate(0 0)"><path style={{ fill: "#54c0fd" }} class="a" d="M242.168,18.527a1.681,1.681,0,0,1-1.6-2.2l1.516-4.632-3.956-2.861a1.679,1.679,0,0,1,.984-3.04H244l1.5-4.628a1.68,1.68,0,0,1,3.2,0l.456,1.41a.9.9,0,0,1-1.71.553l-.343-1.062-1.41,4.363a1.674,1.674,0,0,1-1.6,1.163H239.48l3.73,2.7a1.674,1.674,0,0,1,.612,1.883l-1.429,4.365,3.71-2.708a1.675,1.675,0,0,1,1.98,0l3.71,2.708-1.429-4.365a1.674,1.674,0,0,1,.612-1.883l3.73-2.7h-1.577a.9.9,0,0,1,0-1.8h1.941a1.679,1.679,0,0,1,.984,3.04L252.1,11.692l1.516,4.632A1.679,1.679,0,0,1,251.03,18.2l-3.936-2.873L243.158,18.2A1.675,1.675,0,0,1,242.168,18.527Zm7.369-11.181a.9.9,0,0,1-.647-.275l-.014-.015a.9.9,0,1,1,.661.289Zm.055-.958-.059.055Zm0,0" transform="translate(-216.097 0)" />
        <path style={{ fill: "#54c0fd" }} class="a" d="M291.7,305.6a1.75,1.75,0,0,1-.176-.009,1.658,1.658,0,0,1-1.452-1.311l-1.123-5.341-5.43-.559a1.662,1.662,0,0,1-.658-3.094l4.733-2.719-1.147-5.337a1.661,1.661,0,0,1,2.739-1.581l4.048,3.661,4.721-2.74a1.662,1.662,0,0,1,2.35,2.116l-2.231,4.982,4.064,3.644a1.661,1.661,0,0,1-1.286,2.889l-5.427-.582-2.209,4.991A1.656,1.656,0,0,1,291.7,305.6Zm-7.578-8.965,5.109.526a1.656,1.656,0,0,1,1.456,1.311l1.057,5.026,2.079-4.7a1.656,1.656,0,0,1,1.7-.98l5.107.548-3.825-3.429a1.656,1.656,0,0,1-.407-1.916l2.1-4.688-4.442,2.578a1.657,1.657,0,0,1-1.948-.2l-3.81-3.445,1.079,5.022a1.656,1.656,0,0,1-.8,1.79Zm0,0" transform="translate(-256.681 -259.589)" />
        <path style={{ fill: "#54c0fd" }} class="a" d="M9.029,174.414a1.639,1.639,0,0,1-.17-.009,1.6,1.6,0,0,1-1.4-1.266l-1.03-4.9-4.981-.513a1.605,1.605,0,0,1-.635-2.989l4.341-2.494L4.1,157.349a1.605,1.605,0,0,1,2.646-1.528l3.714,3.358,4.33-2.513a1.605,1.605,0,0,1,2.271,2.045l-2.046,4.569,3.728,3.342a1.605,1.605,0,0,1-1.242,2.791l-4.978-.534-2.026,4.578a1.6,1.6,0,0,1-1.466.956ZM2.222,166l4.527.466a1.6,1.6,0,0,1,1.406,1.266l.937,4.454,1.842-4.162a1.6,1.6,0,0,1,1.639-.946l4.525.486-3.388-3.038a1.6,1.6,0,0,1-.394-1.851l1.86-4.154-3.936,2.284a1.6,1.6,0,0,1-1.882-.2l-3.375-3.053L6.938,162a1.6,1.6,0,0,1-.77,1.729Zm0,0" transform="translate(-0.003 -141.439)" />
        <path style={{ fill: "#ffae4b" }} class="b" d="M124.993,43.039a.9.9,0,0,1-.9-.9V40.9a.9.9,0,0,1,1.8,0V42.14A.9.9,0,0,1,124.993,43.039Zm0,0" transform="translate(-112.941 -36.406)" /><path style={{ fill: "#ffae4b" }} class="b" d="M124.993,109.226a.9.9,0,0,1-.9-.9v-1.241a.9.9,0,0,1,1.8,0v1.241A.9.9,0,0,1,124.993,109.226Zm0,0" transform="translate(-112.941 -96.645)" />
        <path style={{ fill: "#ffae4b" }} class="b" d="M152.422,81.8H151.18a.9.9,0,1,1,0-1.8h1.241a.9.9,0,1,1,0,1.8Zm0,0" transform="translate(-136.775 -72.811)" /><path style={{ fill: "#ffae4b" }} class="b" d="M86.234,81.8H84.992a.9.9,0,0,1,0-1.8h1.241a.9.9,0,0,1,0,1.8Zm0,0" transform="translate(-76.536 -72.811)" />
        <path style={{ fill: "#ffae4b" }} class="b" d="M162.68,374.851a.9.9,0,0,1-.9-.9v-1.241a.9.9,0,0,1,1.8,0v1.241A.9.9,0,0,1,162.68,374.851Zm0,0" transform="translate(-147.241 -338.397)" /><path style={{ fill: "#ffae4b" }} class="b" d="M162.68,441.039a.9.9,0,0,1-.9-.9V438.9a.9.9,0,0,1,1.8,0v1.241A.9.9,0,0,1,162.68,441.039Zm0,0" transform="translate(-147.241 -398.637)" />
        <path style={{ fill: "#ffae4b" }} class="b" d="M190.112,413.61h-1.241a.9.9,0,1,1,0-1.8h1.241a.9.9,0,1,1,0,1.8Zm0,0" transform="translate(-171.079 -374.803)" /><path style={{ fill: "#ffae4b" }} class="b" d="M123.922,413.61H122.68a.9.9,0,1,1,0-1.8h1.241a.9.9,0,1,1,0,1.8Zm0,0" transform="translate(-110.837 -374.803)" />
        <path style={{ fill: "#d1d5d8" }} class="c" d="M2.7,457.326a2.7,2.7,0,1,1,2.7-2.7A2.7,2.7,0,0,1,2.7,457.326Zm0-3.595a.9.9,0,1,0,.9.9A.9.9,0,0,0,2.7,453.731Zm0,0" transform="translate(0 -411.318)" /><path style={{ fill: "#d1d5d8" }} class="c" d="M440.7,211.521a2.7,2.7,0,1,1,2.7-2.7A2.7,2.7,0,0,1,440.7,211.521Zm0-3.595a.9.9,0,1,0,.9.9A.9.9,0,0,0,440.7,207.926Zm0,0" transform="translate(-398.636 -187.604)" /></g></svg>
)


export const ManageSurvey = (props) =>
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" style={{ margin: "20px" }}><defs></defs>
            <g transform="translate(2.968)"><path style={{ fill: "#ffae4b" }} class="a" d="M292.774,285.188v-5.6l-2.146-.548c-.015-.036-.03-.072-.045-.108l1.13-1.9-3.963-3.963-1.9,1.13-.108-.045L285.188,272h-5.6l-.548,2.146-.108.045-1.9-1.13-3.963,3.963,1.13,1.9-.045.108-2.146.548v5.6l2.146.548c.015.036.03.072.045.108l-1.13,1.9,3.963,3.963,1.9-1.13.108.045.548,2.146h5.6l.548-2.146.108-.045,1.9,1.13,3.963-3.963-1.13-1.9.045-.108Zm-10.387-.576a2.226,2.226,0,1,1,2.226-2.226A2.225,2.225,0,0,1,282.387,284.613Zm0,0" transform="translate(-249.742 -246.774)" />
                <path style={{ fill: "#ffae4b" }} class="a" d="M182.677,0A6.685,6.685,0,0,0,176,6.677V9.645h.742a2.225,2.225,0,0,1,2.226-2.226h.128a5.064,5.064,0,0,0,3.582-1.484,5.065,5.065,0,0,0,3.582,1.484h.128a2.225,2.225,0,0,1,2.226,2.226h.742V6.677A6.685,6.685,0,0,0,182.677,0Zm0,0" transform="translate(-162.645)" />
            </g><path style={{ fill: "#fff" }} class="b" d="M325.935,320a5.935,5.935,0,1,0,5.935,5.935A5.942,5.942,0,0,0,325.935,320Zm0,10.387a4.452,4.452,0,1,1,4.452-4.452A4.456,4.456,0,0,1,325.935,330.387Zm0,0" transform="translate(-290.323 -290.323)" />
            <path style={{ fill: "#54c0fd" }} class="c" d="M354.968,352a2.968,2.968,0,1,0,2.968,2.968A2.971,2.971,0,0,0,354.968,352Zm0,4.452a1.484,1.484,0,1,1,1.484-1.484A1.485,1.485,0,0,1,354.968,356.452Zm0,0" transform="translate(-319.355 -319.355)" />
            <path style={{ fill: "#54c0fd" }} class="c" d="M156.693,60.357a4.459,4.459,0,0,0,1.243,1.875v.629l-3.528,1.323A3.726,3.726,0,0,0,152,67.657v3.879h17.806V67.657a3.728,3.728,0,0,0-2.407-3.474l-3.529-1.323v-.629a4.45,4.45,0,0,0,1.243-1.875,2.963,2.963,0,0,0-.5-5.885h-.128a4.293,4.293,0,0,1-3.058-1.267l-.525-.525-.525.525a4.3,4.3,0,0,1-3.058,1.267h-.128a2.964,2.964,0,0,0-.5,5.885Zm5.694,2.813L160.9,65.642l-1.484-2.473v-.054a4.368,4.368,0,0,0,2.968,0Zm-4.028,1.117,1.5,2.492-1.486.743-.96-2.879Zm9.964,3.371v2.395H153.484V67.657a2.236,2.236,0,0,1,1.444-2.084l1.091-.409,1.483,4.45,3.4-1.7,3.4,1.7,1.483-4.45,1.092.409a2.235,2.235,0,0,1,1.444,2.083ZM164.4,64.643l-.96,2.879-1.486-.743,1.5-2.493Zm1.7-7.2a1.479,1.479,0,0,1-.742,1.278V56.162a1.477,1.477,0,0,1,.742,1.277ZM160.9,54.72a5.737,5.737,0,0,0,2.968,1.184v3.019a2.968,2.968,0,0,1-5.935,0V55.9A5.738,5.738,0,0,0,160.9,54.72Zm-4.452,1.441v2.555a1.471,1.471,0,0,1,0-2.555Zm0,0" transform="translate(-137.904 -47.794)" />
            <path style={{ fill: "#ffae4b" }} class="a" d="M0,307.29H14.839V288H0Zm1.484-17.806H13.355v16.323H1.484Zm0,0" transform="translate(0 -261.29)" />
            <path style={{ fill: "#54c0fd" }} class="c" d="M32,320h8.9v1.484H32Zm0,0" transform="translate(-29.032 -290.323)" /><path style={{ fill: "#54c0fd" }} class="c" d="M32,352h1.484v1.484H32Zm0,0" transform="translate(-29.032 -319.355)" />
            <path style={{ fill: "#54c0fd" }} class="c" d="M64,352h5.935v1.484H64Zm0,0" transform="translate(-58.065 -319.355)" /><path style={{ fill: "#54c0fd" }} class="c" d="M32,384h8.9v1.484H32Zm0,0" transform="translate(-29.032 -348.387)" />
            <path style={{ fill: "#54c0fd" }} class="c" d="M32,416h8.9v1.484H32Zm0,0" transform="translate(-29.032 -377.419)" /><path style={{ fill: "#54c0fd" }} class="c" d="M112,448h1.484v1.484H112Zm0,0" transform="translate(-101.613 -406.452)" />
            <path style={{ fill: "#54c0fd" }} class="c" d="M32,448h5.935v1.484H32Zm0,0" transform="translate(-29.032 -406.452)" /><path style={{ fill: "#54c0fd" }} class="c" d="M169.229,347.393l-1.049-1.049-3.492,3.492,3.492,3.492,1.049-1.049-1.7-1.7h5.628v-1.484h-5.628Zm0,0" transform="translate(-149.414 -314.223)" />
            <path style={{ fill: "#54c0fd" }} class="c" d="M73.484,79.32a.743.743,0,0,1,.742-.742h3.4l-1.7,1.7,1.049,1.049,3.492-3.492-3.492-3.492-1.049,1.049,1.7,1.7h-3.4A2.229,2.229,0,0,0,72,79.32V92.675h1.484Zm0,0" transform="translate(-65.323 -67.449)" />
            <path style={{ fill: "#ffae4b" }} class="c" d="M340.452,106.226v9.338l-1.7-1.7-1.049,1.049,3.492,3.492,3.492-3.492-1.049-1.049-1.7,1.7v-9.338A2.229,2.229,0,0,0,339.71,104H336v1.484h3.71a.743.743,0,0,1,.742.742Zm0,0" transform="translate(-304.839 -94.355)" /></svg>
    )

export const Plus = (props) =>
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.456" height="25.456" viewBox="0 0 25.456 25.456">
            <g id="close_7_" data-name="close (7)" transform="translate(12.728) rotate(45)">
                <g id="Group_1979" data-name="Group 1979">
                    <path id="Path_825" data-name="Path 825" d="M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0Zm3.64,11.58a.375.375,0,0,1,0,.53l-.53.53a.375.375,0,0,1-.53,0L9,10.061,6.42,12.64a.375.375,0,0,1-.53,0l-.53-.53a.375.375,0,0,1,0-.53L7.939,9,5.36,6.42a.375.375,0,0,1,0-.53l.53-.53a.375.375,0,0,1,.53,0L9,7.939l2.58-2.58a.375.375,0,0,1,.53,0l.53.53a.375.375,0,0,1,0,.53L10.061,9Z" fill="#ffae4b" />
                </g>
            </g>
        </svg>
    )

export const Plus_blue = (props) =>
    (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.456" height="25.456" viewBox="0 0 25.456 25.456">
            <g id="close_7_" data-name="close (7)" transform="translate(12.728) rotate(45)">
                <g id="Group_1979" data-name="Group 1979">
                    <path id="Path_825" data-name="Path 825" d="M9,0a9,9,0,1,0,9,9A9.01,9.01,0,0,0,9,0Zm3.64,11.58a.375.375,0,0,1,0,.53l-.53.53a.375.375,0,0,1-.53,0L9,10.061,6.42,12.64a.375.375,0,0,1-.53,0l-.53-.53a.375.375,0,0,1,0-.53L7.939,9,5.36,6.42a.375.375,0,0,1,0-.53l.53-.53a.375.375,0,0,1,.53,0L9,7.939l2.58-2.58a.375.375,0,0,1,.53,0l.53.53a.375.375,0,0,1,0,.53L10.061,9Z" fill="#54c0fd" />
                </g>
            </g>
        </svg>

    )
