import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Actions
import { takeSurveyAction } from "../../../redux/actions";

// Import Components
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

// Import Config Constants
import { TakeSurveyConstants } from "../../../config/constants";

function AuthAnonymous(props) {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    password: "",
    showPassword: false,
  });
  const { password, showPassword } = formData;

  /**
   * Handle Textbox Changes
   */
  function handleChange(e) {
    const { name, value } = e;
    setFormData((formData) => ({ ...formData, [name]: value }));
  }

  // Show / Hide Password Visibility
  function handleClickShowPassword() {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  }

  /**
   * AuthKiosk Form Submit
   * @param {*}
   */
  function handleSubmit(e) {
    e.preventDefault();
    if (password) {
      dispatch(takeSurveyAction.anonymousUserAuthentication(password));
    }
  }

  return (
    <ValidatorForm name="AuthAnonymousForm" useref="form" autoComplete="off" onSubmit={handleSubmit} onError={(errors) => console.log(errors)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TypographyComponent title={TakeSurveyConstants.ANONYMOUS_SIGNIN_TITLE} variant={"h5"} className={" mt-1"}></TypographyComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent id={"signin_email_txtbox"} title={TakeSurveyConstants.ANONYMOUS_SIGNIN_PASSWORD} variant={"h6"}></TypographyComponent>
          <TextFieldComponent
            id={"signin_password_txtbox"}
            name={"password"}
            type={"password"}
            margin={"none"}
            value={password}
            pwdVisibileHide={true}
            showPassword={showPassword}
            handleChange={(e) => handleChange(e)}
            handleClickShowPassword={(e) => handleClickShowPassword(e)}
            validators={["required"]}
          ></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={5} alignItems={"center"}>
            <Grid item xs={6}>
              <ButtonComponent title={TakeSurveyConstants.ANONYMOUS_SIGNIN_BTN_TEXT} color={"primary"} size={"large"} type="submit"></ButtonComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

// default props
AuthAnonymous.defaultProps = {
  classes: {},
};

// prop types
AuthAnonymous.propTypes = {
  classes: PropTypes.object,
};

export default AuthAnonymous;
