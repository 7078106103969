import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../SurveyPreviewStyles";

// Import Preview Survey Components
import TakeSurvey from "./TakeSurvey";
import ThankYouComponent from "./ThankYouComponent";
import PreviewLoadingPage from "./PreviewLoadingPage";

// Import Actions
import { surveyPreviewAction } from "../../../../redux/actions";

function PreviewTakeSurvey(props) {
  const surveyId = useSelector((state) => state.survey.previewSurveyId);
  const previewSurveySubmit = useSelector(
    (state) => state.surveyPreview.previewSurveySubmit
  );
  const pageLoading = useSelector((state) => state.surveyPreview.pageLoading);

  // Define Dispatch
  const dispatch = useDispatch();

  //Get Survey
  useEffect(() => {
    if (!previewSurveySubmit) {
      dispatch(surveyPreviewAction.getSurvey(surveyId));
    }
  }, [dispatch, previewSurveySubmit]);

  return (
    <>
      {pageLoading ? <PreviewLoadingPage /> : null}
      {!previewSurveySubmit ? <TakeSurvey /> : <ThankYouComponent />}
    </>
  );
}
// default props
PreviewTakeSurvey.defaultProps = {
  classes: {},
};

// prop types
PreviewTakeSurvey.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(PreviewTakeSurvey);
