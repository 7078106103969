/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { isIE } from 'react-device-detect';

// Layout Style
import PrivateLayoutStyle from "./PrivateLayoutStyle.jsx";

// Import Components
import Header from "../../containers/Header/Header";

// Page Dont Have Access
import PageNotHaveAccess from "../../containers/PageNotHaveAccess/PageNotHaveAccess";

// Import Cofig Constants
import { errorsConstants } from "../../config/constants";

// import child router components
import PageNoIeSupport from "../../containers/PageNoIeSupport/PageNoIeSupport";

//import component 
// import LiveChatComponent from "../../components/LiveChatComponent/LiveChatComponent";

function PrivateLayout(props) {
  const { match, user, access } = props;

  /**
 * Handle Header Menu Action
 */
  return (
    <>
      {isIE ? <PageNoIeSupport errorMsg={errorsConstants.IE_NOT_SUPPORTED} /> :
        <React.Fragment>
          {user && user._id &&
            <React.Fragment>
              <Grid container>
                <Grid item xs={12}>
                  <Header match={match} user={user} page={props.location} />
                </Grid>
              </Grid>

              <React.Fragment>
                {access ? props.children : <PageNotHaveAccess></PageNotHaveAccess>}
              </React.Fragment>
            </React.Fragment>
          }
        </React.Fragment>
      }
      {/* <LiveChatComponent showChat={false} page={props.location} /> */}
    </>
  );
}

// Define Props Types
PrivateLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
  access: PropTypes.bool.isRequired
};

export default withStyles(PrivateLayoutStyle)(PrivateLayout);
