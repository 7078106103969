export const adminsConstant = {
    // To get All Admin Users
    ADMINS_SEARCH_REQUEST: "ADMINS_SEARCH_REQUEST",
    ADMINS_SEARCH_SUCCESS: "ADMINS_SEARCH_SUCCESS",
    ADMINS_SEARCH_FAILURE: "ADMINS_SEARCH_FAILURE",

    // To get single Admin User
    ADMINS_GET_ONE_REQUEST: "ADMINS_GET_ONE_REQUEST",
    ADMINS_GET_ONE_UPDATE: "ADMINS_GET_ONE_UPDATE",
    ADMINS_GET_ONE_SUCCESS: "ADMINS_GET_ONE_SUCCESS",
    ADMINS_GET_ONE_FAILURE: "ADMINS_GET_ONE_FAILURE",

    // To Create Single Admin User
    ADMINS_CREATE_REQUEST: "ADMINS_CREATE_REQUEST",
    ADMINS_CREATE_SUCCESS: "ADMINS_CREATE_SUCCESS",
    ADMINS_CREATE_FAILURE: "ADMINS_CREATE_FAILURE",

    // To update single user ADMINS
    ADMINS_UPDATE_REQUEST: "ADMINS_UPDATE_REQUEST",
    ADMINS_UPDATE_SUCCESS: "ADMINS_UPDATE_SUCCESS",
    ADMINS_UPDATE_FAILURE: "ADMINS_UPDATE_FAILURE",

    // To Remove single user ADMINS
    ADMINS_REMOVE_REQUEST: "ADMINS_REMOVE_REQUEST",
    ADMINS_REMOVE_SUCCESS: "ADMINS_REMOVE_SUCCESS",
    ADMINS_REMOVE_FAILURE: "ADMINS_REMOVE_FAILURE",

    // To get All admin Roles
    ADMINS_ROLES_REQUEST: "ADMINS_ROLES_REQUEST",
    ADMINS_ROLES_SUCCESS: "ADMINS_ROLES_SUCCESS",
    ADMINS_ROLES_FAILURE: "ADMINS_ROLES_FAILURE",

    //To update Admin Status
    ADMINS_STATUS_UPDATE_REQUEST: "ADMINS_STATUS_UPDATE_REQUEST",
    ADMINS_STATUS_UPDATE_SUCCESS: "ADMINS_STATUS_UPDATE_SUCCESS",
    ADMINS_STATUS_UPDATE_FAILURE: "ADMINS_STATUS_UPDATE_FAILURE",

    //To upload Admin Excel
    ADMINS_EXCEL_UPLOAD_REQUEST: "ADMINS_EXCEL_UPLOAD_REQUEST",
    ADMINS_EXCEL_UPLOAD_SUCCESS: "ADMINS_EXCEL_UPLOAD_SUCCESS",
    ADMINS_EXCEL_UPLOAD_FAILURE: "ADMINS_EXCEL_UPLOAD_FAILURE",

    //To download Admin Excel
    ADMINS_EXCEL_DOWNLOAD_REQUEST: "ADMINS_EXCEL_DOWNLOAD_REQUEST",
    ADMINS_EXCEL_DOWNLOAD_SUCCESS: "ADMINS_EXCEL_DOWNLOAD_SUCCESS",
    ADMINS_EXCEL_DOWNLOAD_FAILURE: "ADMINS_EXCEL_DOWNLOAD_FAILURE",

    //To Update Report Preference
    ADMINS_REPORT_UPDATE_REQUEST: 'ADMINS_REPORT_UPDATE_REQUEST',
    ADMINS_REPORT_UPDATE_SUCCESS: 'ADMINS_REPORT_UPDATE_SUCCESS',
    ADMINS_REPORT_UPDATE_FAILURE: 'ADMINS_REPORT_UPDATE_FAILURE',

    // Update Is Surveys
    RESET_IS_SURVEYS_CHANGED: 'RESET_IS_SURVEYS_CHANGED',

    //To Get Report Preference
    ADMINS_GET_PREFERENCE_REQUEST: 'ADMINS_GET_PREFERENCE_REQUEST',
    ADMINS_GET_PREFERENCE_SUCCESS: 'ADMINS_GET_PREFERENCE_SUCCESS',
    ADMINS_GET_PREFERENCE_FAILURE: 'ADMINS_GET_PREFERENCE_FAILURE',

    //Update Admin Preference Reducer
    ADMINS_UPDATE_PREFERENCE_REDUCER: 'ADMINS_UPDATE_PREFERENCE_REDUCER',

    // update DLA QUery in Edit admin page
    UPDATE_DLA_QUERY: 'UPDATE_DLA_QUERY',

    // Toggle Filter drawer open status
    TOGGLE_FILTER_DRAWER_OPEN_STATUS: 'TOGGLE_FILTER_DRAWER_OPEN_STATUS',


    // To get All admin Roles
    GET_CLIENT_SURVEYS_REQUEST: "GET_CLIENT_SURVEYS_REQUEST",
    GET_CLIENT_SURVEYS_SUCCESS: "GET_CLIENT_SURVEYS_SUCCESS",
    GET_CLIENT_SURVEYS_FAILURE: "GET_CLIENT_SURVEYS_FAILURE",

    //to get admin selected surveys
    ADMIN_SELECTED_SURVEYS_REQUEST: "ADMIN_SELECTED_SURVEYS_REQUEST",
    ADMIN_SELECTED_SURVEYS_SUCCESS: "ADMIN_SELECTED_SURVEYS_SUCCESS",
    ADMIN_SELECTED_SURVEYS_FAILURE: "ADMIN_SELECTED_SURVEYS_FAILURE",

    UPDATE_ME_SURVEY_ACCESS: "UPDATE_ME_SURVEY_ACCESS",
    UPDATE_SURVEY_ACCESS: "UPDATE_ME_SURVEY_ACCESS",

    // Update NLP Selected Type in Preference
    UPDATE_SELECTED_NLP_PREFERENCE: "UPDATE_SELECTED_NLP_PREFERENCE",

    // Update Dashboard Hide Widgets Preference
    UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_REQUEST: "UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_REQUEST",
    UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_SUCCESS: "UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_SUCCESS",
    UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_FAILURE: "UPDATE_DASHBOARD_HIDE_WIDGETS_PREFERENCE_FAILURE",
};