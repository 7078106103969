import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from '@mui/material/TextField';
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import TextEditor from "../../../../components/TextEditor";
import Autocomplete from '@mui/material/Autocomplete';

import { Plus_blue, TrashSurvey } from "../../../../assets/images/svgComponents/ImageComponent";
import { updateStateReducerAction, contentLibraryAction, alertAction } from "../../../../redux/actions";
import { contentLibraryConstant } from "../../../../redux/constants";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";



function AddNewRecomendation(props) {

    const { classes, item, questions, index } = props;
    const dispatch = useDispatch();

    const [selectedQuestion, setSelectedQuestion] = React.useState("");

    useEffect(() => {
        dispatch(contentLibraryAction.getAllQuestions());
    }, [dispatch]);

    const questionText = []
    questions.map((qus, ind) => {
        questionText.push({ baseQusId: qus.baseQusId, question: qus.qusText.Default.en })
    })

    let recommendationNewData = {}
    if (selectedQuestion && item) {
        recommendationNewData = item
    }

    const handleNewRecommendation = () => {
        recommendationNewData.actionItems.Default = recommendationNewData.actionItems && recommendationNewData.actionItems.Default ? recommendationNewData.actionItems.Default : [];
        recommendationNewData.actionItems.Default = [...recommendationNewData.actionItems.Default, " "]
        props.editNewRecommendationData(recommendationNewData)

    }

    const handleQuestionChange = (event, value) => {
        setSelectedQuestion(value)
        const reducerData = questions.filter((q) => q.baseQusId === value.baseQusId)[0] || ""
        props.editNewRecommendationData(reducerData)
    };

    const handleChangeAct = (actItems, index) => {
        const updatedAcc = { ...recommendationNewData };
        recommendationNewData["actionItems"]["Default"] = actItems;
        props.editNewRecommendationData(updatedAcc)
    };

    const saveQuestion = () => {
        // on save close edit
        props.updateAddRecommendationFlag(true);
    };

    const closeQuestion = () => {
        // set / remove editable false for that action alert
        props.updateAddRecommendationFlag(false)
    };

    // let selectedQues = "";

    const getOptionSelected = (data, value) => {
        let optionSelected = data.filter((p) => p.baseQusId === value)[0] || "";
        return optionSelected;
    };

    return (
        <Grid className={` ${classes.TopicExpand} width100`}>
            <Grid container className="p15">
                <Grid item className={"pl-1 pr-2 pt-2"}>
                    <Badge
                        color="secondary"
                        className={`${classes.Count_badge} badgeextrabig`}
                        badgeContent={recommendationNewData ? recommendationNewData.baseQusId : "1"}
                        max={400}
                    ></Badge>
                </Grid>
                <Grid item className={`pr-1 pl-1 ${classes.addRecomendation_body}`} >
                    <Grid item sm={12} className="dflex mb-1">
                        <Grid item className={"mr5 width20"}>
                            <TypographyComponent
                                className={`txtgrey mt8 ${classes.recommendation_Addquestion_card}`}
                                variant={"h6"}>
                                {recommendationNewData && recommendationNewData.category && recommendationNewData.category.Default ? recommendationNewData.category.Default.en.name : ""}
                            </TypographyComponent>
                        </Grid>
                        <Grid item className={"width80"}>
                            <Autocomplete
                                id={"selectNlpQues"}
                                disableClearable
                                autoHighlight
                                blurOnSelect
                                options={questionText}
                                value={getOptionSelected(questionText, selectedQuestion.baseQusId)}
                                className={classes.QuestionWidth}
                                getOptionLabel={(option) => option.question || ""}
                                onChange={(e, val) => {
                                    handleQuestionChange(e, val)
                                    // setSelectedQuestion(val)
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        multiline
                                        variant="outlined"
                                        className={`${"fontSize15 boderRadius4"}`}
                                        placeholder="Select Field"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    {recommendationNewData && recommendationNewData.actionItems && recommendationNewData.actionItems.Default && recommendationNewData.actionItems.Default.map((actions, i) => (
                        <Grid item sm={12} className="mb-1 pt5 relative">
                            <TextEditor
                                height={"200px"}
                                data={actions ? actions : ""}
                                onChange={(event, editor) => {
                                    const actionItems = [...recommendationNewData["actionItems"]["Default"]];
                                    actionItems[i] = editor.getData();
                                    handleChangeAct(actionItems, index);
                                }}
                            />
                            <Grid item className={classes.actionItem_DeleteIcon}>
                                <Tooltip arrow title="Delete">
                                    <IconButton
                                        color="inherit"
                                        className="p5 icon-hover ImgActiveOrange"
                                        onClick={(e) => {
                                            const actionItems = [...recommendationNewData["actionItems"]["Default"]];
                                            actionItems.splice(i, 1);
                                            handleChangeAct(actionItems, index);
                                        }}
                                        size="large">
                                        {TrashSurvey()}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    ))}
                    <Grid item sm={12} className={"p5 dflex spacebetween"}>
                        <Grid item>
                            <Link
                                onClick={handleNewRecommendation}
                                className={`${classes.content_linkimg}${" cursor-pointer"}`}
                                color="primary">
                                <IconButton color="inherit" className={"p-0"} size="large">{Plus_blue()}</IconButton>
                                Add Another
                            </Link>
                        </Grid>
                        <Grid item>
                            <ButtonComponent
                                title={"OK"}
                                color={"primary"}
                                size={"small"}
                                handleClick={() => saveQuestion()}
                                pageClassName={classes.settings_addrulebtn}
                            ></ButtonComponent>
                            <ButtonComponent
                                title={"Cancel"}
                                color={"secondary"}
                                size={"small"}
                                handleClick={() => closeQuestion()}
                                pageClassName="ml-1"
                            ></ButtonComponent>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AddNewRecomendation;

