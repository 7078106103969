import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

// Import Component
import DonutChart from "../../../../components/Charts/DonutChart/DonutChart";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

const OverallEIChart = (props) => {
  //get props
  const { classes, chartId } = props;

  // Get chart data from reducer
  const { EiOverall : EIoverallChart } = useSelector((state) => state.dashboard.engagementIndex);
  const RGI_data = EIoverallChart["RGIdata"]
  const rangeArr = [];
  const domainArr = [];

  if (RGI_data) {
    RGI_data.map(item => {
      domainArr.push(item["name"])
      if (item["name"] == "Actively Disengaged") {
        rangeArr.push("#FFAE4B")
      }
      else if (item["name"] == "Opportunity Group") {
        rangeArr.push("#FFCE93")
      }
      else if (item["name"] == "Engaged/Contributor") {
        rangeArr.push("#98D9FE")
      }
      else if (item["name"] == "Actively Engaged") {
        rangeArr.push("#54C0FD")
      }
    })
  }


  //chart layout data and colors
  const chartLayout = { width: 200, height: 260, thickness: 45 };
  //const domainArr=["Actively Disengaged", "Opportunity Group","Engaged/Contributor", "Actively Engaged",]

  return (
    <React.Fragment>
      <Grid className={` bgGrey ${classes.EIWidth30}`}>
        {EIoverallChart.overallPct >= 0 && EIoverallChart.RGIdata && EIoverallChart.RGIdata.length > 0 && (
          <React.Fragment>
            <Grid className="pt-4">
              <TypographyComponent
                className={"txtcenter fontSize64 fontWeight600"}
                variant="h1"
              >
                {`${EIoverallChart.overallPct}%`}
              </TypographyComponent>
              <TypographyComponent className={"txtcenter fontSize18 txtMedium"} variant="h5">
                {"Engaged"}
              </TypographyComponent>
            </Grid>
            <Grid className={"txtcenter pt-4 pb-4"}>
              <DonutChart
                chartId={chartId}
                chartName="Overall"
                textFont={16}
                chartData={EIoverallChart.RGIdata}
                layoutData={chartLayout}
                domainArr={domainArr}
                rangeArr={rangeArr}
                tooltip_text={"Engagement Index"}
              />
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
};
// default props
OverallEIChart.defaultProps = {
  classes: {},
  chartId: "chart1"
};

// prop types
OverallEIChart.propTypes = {
  classes: PropTypes.object,
  chartId: PropTypes.string,
};
export default withStyles(ReportsStyles)(OverallEIChart);
