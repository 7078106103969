import React, { useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Import Components
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import AutoCompleteComponent from "../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ButtonComponent from "../../../../../components/ButtonComponent/ButtonComponent";
import SwitchComponent from "../../../../../components/SwitchComponent/SwitchComponent";
import TimePickerComponent from "../../../../../components/TimePickerComponent/CustomTimePicker";
import MultiDatePickerComponent from "../../../../../components/MultiDatePickerComponent/MultiDatePickerComponent";
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";

// constants
import { surveySettingsConstants, surveyTypes } from "../../../../../config/constants";
import { surveyConstant } from "../../../../../redux/constants";

// Import Actions
import { updateStateReducerAction, surveyAction, alertAction } from "../../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData, isTranslated, iseditedLangtxt } from "../../../../../helpers";

function Email(props) {
  const { classes, data, langcode, surveyPermission, panel, translatedFields } = props;
  const dispatch = useDispatch();
  const {initial_notification} = useSelector((state) => state.survey);

  if (data.distribute && data.distribute.audience === undefined) {
    data.distribute.audience = {
      select: ""
    };
  }

  // To change mail constants for me
  if (data.type === surveyTypes.MULTI_RATER_FEEDBACK && data.distribute.notification.email.message.subject["en"] === surveySettingsConstants.EMAIL_SUBJECT_NON_ME) {
    data.distribute.notification.email.message.subject["en"] = surveySettingsConstants.EMAIL_SUBJECT_ME
  }
  if (data.type === surveyTypes.MULTI_RATER_FEEDBACK && data.distribute.notification.email.message.body["en"] === surveySettingsConstants.EMAIL_BODY_NON_ME) {
    data.distribute.notification.email.message.body["en"] = surveySettingsConstants.EMAIL_BODY_ME
  }

  /**
   * Handle Radio Group Change Event
   * @param {*} panel
   */

  const radiogrouphandlechange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };


  /**
   * Handle Text area Change Event
   * @param {*} panel
   */
  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value, fieldName: "email_message_body" });
  };

  // Handle Auto Complete
  const handleAutoCompolete = (value, name) => {
    handleChange({ name, value });
  };

  /**
    * Handle Change Event
    * @param {*} panel
    */
  const handleChange = (event) => {
    if (data._id && !data.isLoading) {
      if(event.name=== "data.email_name_access"){
        data.email_name_access = event.value
      }
      if (event.name === "distribute.notification.email.schedule.type" && event.value === data.distribute.notification.email.schedule.type) {
        event["value"] = "";
      }
      iseditedLangtxt(translatedFields, langcode, event.fieldName)
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
   * Get Multi Select Date Values
   * @param {*} name
   * @param {*} value
   */
  const handleMultiDateSelect = (name, value) => {
    if (data._id && !data.isLoading) {
      const reducerData = getReducerUpdatedData({ name, value }, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
   * Send Mail to participants
   */
  const handleSendMailBtnClick = () => {
    if (data.status === surveySettingsConstants.SURVEY_STATUS_OPEN) {
      dispatch(surveyAction.sendSurveyMail({ _id: data._id, emailContent: data.distribute.notification.email , email_name_access : data.email_name_access}));
    }
    else {
      dispatch(alertAction.error(surveySettingsConstants.SMS_EMAIL_TRANSFLO_SEND_ERROR));
    }
  };

  useEffect(()=>{
    if(data.distribute.audience.select === "anonymous"){
      data.distribute.notification.email.active = false
    } else if (surveyPermission.emailAccess) { 
      data.distribute.notification.email.active = initial_notification?.notification?.email?.active
    }
  },[data.distribute.audience.select])

  useEffect(() => {

  }, [data]);

  return (
    <Grid item sm={12}>
      <Grid item md={12} xs={12} className="mb-2">
        <SwitchComponent
          name="distribute.notification.email.active"
          checked={surveyPermission.emailAccess 
            ? data.distribute.notification.email.active : surveySettingsConstants.CHECKED_FALSE}
          disabled={(surveyPermission.emailAccess && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? surveySettingsConstants.CHECKED_FALSE : surveySettingsConstants.CHECKED_TRUE}
          color={(surveyPermission.emailAccess && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS)
            ? "primary" : "default"}
          handleChange={(e) => handleChange(e)}
          label={
            <span>
              <TypographyComponent
                variant="h5"
                className="pt-2"
              >{surveySettingsConstants.EMAIL_TOGGLE_TITLE}
                <InformationComponent>
                  <Grid container>
                    <TypographyComponent variant="tooltipbody">
                      {surveySettingsConstants.SURVEY_SENT_ON_DATE}
                    </TypographyComponent>
                  </Grid>
                </InformationComponent>
              </TypographyComponent>
              <TypographyComponent
                variant="h6"
                className="txtgrey"
              >{surveySettingsConstants.EMAIL_TOGGLE_MESSAGE}</TypographyComponent>
            </span>
          }
        />
      </Grid>

      {/*
                Displays when email toggle enabled and email permission from client
              */}

      {data.distribute.notification.email.active === true && surveyPermission.emailAccess === true && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS &&
        <Grid item sm={12} className={`${"pl-5"} ${"pt-13"}`}>
          <Grid container spacing={3}
            className={`${classes.settings_expandinsidediv}`}>
            {data.frequency !== "Pulse" &&
              <Grid item xs={12}>
                <Grid item xs={12}>
                  <RadioGroup aria-label="distribute.notification.email.schedule.type"
                    row name="distribute.notification.email.schedule.type"
                    value={data.distribute.notification.email.schedule.type || ""}
                    // onChange={radiogrouphandlechange}
                    className={classes.audiencetype_div}>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        value={surveySettingsConstants.RADIOGROUP_CUSTOM_DAY}
                        control={<Radio color="primary" onClick={radiogrouphandlechange} />}
                        label={surveySettingsConstants.DISTRIBUTION_RADIOGRP_NAME1} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormControlLabel
                        value={surveySettingsConstants.RADIOGROUP_CUSTOM_DATE}
                        control={<Radio color="primary" onClick={radiogrouphandlechange} />}
                        label={surveySettingsConstants.DISTRIBUTION_RADIOGRP_NAME2} />
                    </Grid>
                  </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item md={6} xs={12}>

                      {/*
                          Displays when email schedule type is day
                          */}
                      {data.distribute.notification.email.schedule.type === surveySettingsConstants.RADIOGROUP_CUSTOM_DAY &&
                        <Grid container spacing={1}>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_A_DAY}
                            </TypographyComponent>
                            <AutoCompleteComponent
                              name="distribute.notification.email.schedule.triggerDay"
                              suggestionType="array"
                              suggestions={surveySettingsConstants.WEEK_DAYS}
                              handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                              value={data.distribute.notification.email ? (data.distribute.notification.email.schedule ? (data.distribute.notification.email.schedule.triggerDay ? data.distribute.notification.email.schedule.triggerDay : "") : "") : ""}
                            ></AutoCompleteComponent>
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_TIME_MST}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {surveySettingsConstants.SELECT_TIME_MST_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TimePickerComponent
                              name="distribute.notification.email.schedule.time"
                              value={data.distribute.notification.email.schedule.time || ""}
                              handleChange={(e) => handleChange(e)}
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                    <Grid item md={6} xs={12}>

                      {/*
                          Displays when email schedule type is date
                        */}

                      {data.distribute.notification.email.schedule.type === surveySettingsConstants.RADIOGROUP_CUSTOM_DATE &&
                        <Grid container spacing={1}>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_DATES}
                            </TypographyComponent>

                            <MultiDatePickerComponent
                              handleMultiDateSelect={handleMultiDateSelect}
                              name={"distribute.notification.email.schedule.triggerDate"}
                              value={data.distribute.notification.email.schedule.triggerDate || []} />
                          </Grid>
                          <Grid item md={6} xs={6}>
                            <TypographyComponent variant="h6">
                              {surveySettingsConstants.SELECT_TIME_MST}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {surveySettingsConstants.SELECT_TIME_MST_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TimePickerComponent
                              name="distribute.notification.email.schedule.time"
                              value={data.distribute.notification.email.schedule.time || ""}
                              handleChange={(e) => handleChange(e)}
                            />
                          </Grid>
                        </Grid>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            }
            {data.frequency === "Pulse" &&
              <Grid item xs={12} container>
                {data.pulse_obj.period.frequencyType !== surveySettingsConstants.WEEKS &&
                  <Grid item md={6}>
                    <Grid item md={6} xs={12}>
                      <TypographyComponent variant="h6">
                        {surveySettingsConstants.SELECT_A_DAY}
                      </TypographyComponent>
                      <AutoCompleteComponent
                        name="distribute.notification.email.schedule.triggerDay"
                        suggestionType="array"
                        suggestions={surveySettingsConstants.WEEK_DAYS}
                        handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                        value={data.distribute.notification.email ? (data.distribute.notification.email.schedule ? (data.distribute.notification.email.schedule.triggerDay ? data.distribute.notification.email.schedule.triggerDay : "") : "") : ""}
                      ></AutoCompleteComponent>
                    </Grid>
                  </Grid>
                }
                <Grid item md={6}>
                  <Grid item md={6} xs={12}>
                    <TypographyComponent variant="h6">
                      {surveySettingsConstants.SELECT_TIME_MST}
                      <InformationComponent>
                        <TypographyComponent variant="tooltipbody">
                          {surveySettingsConstants.SELECT_TIME_MST_HELP}
                        </TypographyComponent>
                      </InformationComponent>
                    </TypographyComponent>
                    <TimePickerComponent
                      name="distribute.notification.email.schedule.time"
                      value={data.distribute.notification.email.schedule.time || ""}
                      handleChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid item md={4} xs={12}>
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.EMAIL_REPLYTO}
                  </TypographyComponent>
                  <TextFieldComponent id="distribute.notification.email.reply"
                    name="distribute.notification.email.reply"
                    size={"small"}
                    fullWidth={true}
                    variant="outlined"
                    margin="none"
                    validators={["required", "isEmail"]}
                    value={data.distribute.notification.email.reply || ""}
                    handleChange={(e) => handleChange(e)}
                    className={`${classes.settings_input}`}
                    panel={panel}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.EMAIL_SUBJECT}
                  </TypographyComponent>
                  <TextFieldComponent id="distribute.notification.email.message.subject"
                    name={`distribute.notification.email.message.subject.${langcode}`}
                    size={"small"}
                    fullWidth={true}
                    variant="outlined"
                    margin="none"
                    placeholder={data.distribute.notification.email.message.subject["en"] ? data.distribute.notification.email.message.subject["en"] : ""}
                    validators={langcode === "en" ? ["required"] : []}
                    value={data.distribute.notification.email.message ? (data.distribute.notification.email.message.subject ? (data.distribute.notification.email.message.subject[langcode] ? data.distribute.notification.email.message.subject[langcode] : "") : "") : ""}
                    handleChange={(e) => handleChange({ ...e, "fieldName": "email_message_subject" })}
                    className={`${classes.settings_input} ${"boderRadius2"} ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "email_message_subject") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""} `}
                    panel={panel}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.EMAIL_MESSAGE}
              </TypographyComponent>
              <Grid className={classes.txtborder}>
                <Grid container alignItems="center" className={`bgwhite ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "email_message_greetings") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""}`}>
                  <Grid item sm={2.5} marginRight="10px">
                    <TextFieldComponent id="distribute.notification.email.message.greetings"
                      name={`distribute.notification.email.message.greetings.${langcode}`}
                      placeholder={data.distribute.notification.email.message.greetings["en"] ? data.distribute.notification.email.message.greetings["en"] : surveySettingsConstants.PLACEHOLDER_GREETINGS}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      value={data.distribute.notification.email.message ? (data.distribute.notification.email.message.greetings ? (data.distribute.notification.email.message.greetings[langcode] ? data.distribute.notification.email.message.greetings[langcode] : "") : "") : ""}
                      handleChange={(e) => handleChange({ ...e, "fieldName": "email_message_greetings" })}
                      className={`${"br-0 br-none m-0"} ${classes.distri_emailmsg}  ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "email_message_greetings") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""} `} />
                  </Grid>
                  <SwitchComponent item xs={4.5}
                  name="data.email_name_access"
                  color="primary"
                  size="small"
                  checked={data.email_name_access}
                  handleChange={(e) => { handleChange(e); }}
                  label={
                    <span>
                      {/* <TypographyComponent
                        variant="h6"
                      >{data.label}</TypographyComponent> */}
                    </span>
                  }
                />
                  <Grid item sm={4.5} className={classes.Greetings_constant} color={data.email_name_access ? "" :"#888383" }>
                    {surveySettingsConstants.GREETINGS_CONSTANT_NAME}
                  </Grid>
                </Grid>
                <TextareaAutosize
                  className={` width100 pe_textarea mb-m4 ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "email_message_body") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""} `}
                  name={`distribute.notification.email.message.body.${langcode}`}
                  placeholder={data.distribute.notification.email.message.body["en"] ? data.distribute.notification.email.message.body["en"] : surveySettingsConstants.PLACEHOLDER_MESSAGE}
                  value={data.distribute.notification.email.message ? (data.distribute.notification.email.message.body ? (data.distribute.notification.email.message.body[langcode] ? data.distribute.notification.email.message.body[langcode] : "") : "") : ""}
                  onChange={handleTextChange}
                  minRows={3} />
                <Grid container alignItems="center" className={`bgwhite ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "email_message_url") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""}`}>
                  <Grid item sm={6}>
                    <TextFieldComponent id="distribute.notification.email.message.url"
                      name={`distribute.notification.email.message.url.${langcode}`}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      placeholder={data.distribute.notification.email.message.url["en"] ? data.distribute.notification.email.message.url["en"] : ""}
                      value={data.distribute.notification.email.message ? (data.distribute.notification.email.message.url ? (data.distribute.notification.email.message.url[langcode] ? data.distribute.notification.email.message.url[langcode] : "") : "") : ""}
                      handleChange={(e) => handleChange({ ...e, "fieldName": "email_message_url" })}
                      className={`${"br-0 fontSize13 br-none m-0"} ${classes.distri_emailmsg} ${translatedFields.hasOwnProperty(langcode) ? (isTranslated(translatedFields, langcode, "email_message_url") ? classes.translatedField : classes.editedtranslatedField_Mail) : ""} `} />
                  </Grid>
                  <Grid item sm={6} className={classes.url_constant}>
                    {surveySettingsConstants.URL_CONSTANT_NAME}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{padding:"12px", paddingTop:"24px"}}>
              <ButtonComponent
                title={surveySettingsConstants.EMAIL_SEND_BUTTON}
                handleClick={handleSendMailBtnClick}
                color={"primary"}
                size={"small"}
              ></ButtonComponent>
              <InformationComponent>
                <TypographyComponent variant="tooltipbody">
                  <TypographyComponent variant="tooltipbody">
                    {surveySettingsConstants.EMAIL_SEND_BUTTON_HELP}
                  </TypographyComponent>
                </TypographyComponent>
              </InformationComponent>
            </Grid>
          </Grid>
        </Grid>
      }

      {/*
                Displays when no email access is given from client
              */}

      {surveyPermission.emailAccess !== true &&
        <Grid container spacing={3}
          className={classes.settings_expandinsidediv}>
          <TypographyComponent variant="h6" >{surveySettingsConstants.EMAIL_DISABLED_MESSAGE}</TypographyComponent>
        </Grid>
      }
    </Grid>
  );
}

// default props
Email.defaultProps = {
  classes: {},
  data: {},
  langcode: "en",
  surveyPermission: {}
};

// prop types
Email.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  langcode: PropTypes.string,
  surveyPermission: PropTypes.object
};

export default (Email);
