import React,{useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import moment from "moment";
import { Link } from "react-router-dom";

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../../../components/SelectFieldComponent/SelectFieldComponent";
import DatePickerComponent from "../../../../../components/DatePickerComponent/CustomDatePicker";
import AutoCompleteComponent from '../../../../../components/AutoCompleteComponent/AutoCompleteComponent'

// import constants
import { ReportsConstants } from "../../../../../config/constants";

//Import Actions
import { reportAction } from "../../../../../redux/actions";

// import helpers
import { dateFormatter, dateValidator, getStartDate, getEndDate } from "../../../../../helpers/appHelper";

function ChooseDates(props) {
  const dispatch = useDispatch();

  // get props
  const { classes } = props;

  // Get saved reports Data
  const { reportData,allSurveys } = useSelector((state) => state.report);

  let { surveys, date } = reportData;
  const pulseFound = surveys.filter((d) => d.frequency === "Pulse").length > 0;
  let TIME_FRAMES = [
    "Last month",
    "Last 3 months",
    "Last 6 months",
    "Last year",
    "Custom dates",
  ];

  if (TIME_FRAMES.indexOf("Pulse dates") === -1 && pulseFound) {
    TIME_FRAMES.push("Pulse dates");
  } else if (TIME_FRAMES.indexOf("Pulse dates") > -1 && !pulseFound) {
    const pulseIndex = TIME_FRAMES.indexOf("Pulse dates");
    TIME_FRAMES.splice(pulseIndex, 1);
  }

  let pulseDateArray = [];
  let survey=[]
  if (surveys && surveys.length === 1 && surveys[0].frequency === "Pulse") {
    survey = allSurveys.filter(obj => obj._id + "" === surveys[0]._id + "")
    if (survey[0] && survey[0].pulse_obj && survey[0].pulse_obj.pulseDetails) {
        Object.keys(survey[0].pulse_obj.pulseDetails).map((key) => {
            pulseDateArray.push(survey[0].pulse_obj.pulseDetails[key].displayName)
        })
    }
}

  //Get demographics List
  useEffect(() => {
   if(date.type!==""){
    getDemographics(date);
   }
  }, [dispatch]);

  // Get Demographics Data
  const getDemographics = (dateUpdated) => {
    dispatch(
      reportAction.getDemographics({
        metadata: [],
        surveys: surveys,
        date: dateUpdated,
      })
    );
  };

  //change date type and update reducer
  const changeDateType = (e) => {
    const updatedReportData = {
      ...reportData,
      date: { ...date, [e.name]: e["value"] },
    };
    dispatch(reportAction.updateReport(updatedReportData));
    if (e["value"] !== "Pulse Dates" || e["value"] !== "Custom dates") {
      getDemographics(updatedReportData["date"]);
    }
  };

  //change date ranges
  const changeDates = (e) => {
    const updatedReportData = {
      ...reportData,
      date: { ...date, [e.name]: dateFormatter(e["value"]) },
    };
    dispatch(reportAction.updateReport(updatedReportData));

    if (dateValidator(date["startDate"]) && (e["name"]==="endDate" && dateValidator(e["value"]))) {
      getDemographics(updatedReportData["date"]);
    }
  };

  // Clear all data
  const handleClear = (e) => {
    const updatedReportData = {
      ...reportData,
      date: { type: "", startDate: "", endDate: "", pulseDate: "" },
    };
    dispatch(reportAction.updateReport(updatedReportData));
  };

    // Handle auto complete change
    const handleAutoCompleteChange = (event) => {
      if(survey[0] && survey[0].pulse_obj && survey[0].pulse_obj.pulseDetails){
        Object.keys(survey[0].pulse_obj.pulseDetails).map((key) => {
          if (survey[0].pulse_obj.pulseDetails[key].displayName && survey[0].pulse_obj.pulseDetails[key].displayName === event.value) {
              event.value = key
          }
      })
      }else{
        event.value = ""
      }
      const updatedReportData = {
        ...reportData,
        date: { ...date, [event.name]: event["value"] },
      };
      dispatch(reportAction.updateReport(updatedReportData));
  
      if (event["value"]) {
        getDemographics(updatedReportData["date"]);
      }
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={2} className={classes.clearAllPosition}>
          <Link
            className={`cursor-pointer colorTheme right`}
            onClick={(e) => handleClear(e)}
          >
            {"Clear all"}
          </Link>
        </Grid>
        {reportData.date && reportData.date.type !== "" && (
          <Grid item sm={10}>
            {/* {displyDates()} */}
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent="space-between" className="pt-1 pb-1">
        <Grid item xs={12} className="sm-30">
          <TypographyComponent variant="h6">
            {ReportsConstants.DATE_TYPE}
          </TypographyComponent>
          <SelectFieldComponent
            select
            name="type"
            options={TIME_FRAMES}
            value={date ? date.type : ""}
            fullWidth={true}
            handleChange={(e) => changeDateType(e)}
            validators={['required']}
            errorMessages={['Required']}
          />
        </Grid>
        {date.type && date.type === "Custom dates" && (
          <React.Fragment>
            <Grid item xs={12} className="sm-30">
              <TypographyComponent variant="h6">
                {ReportsConstants.START_DATE}
              </TypographyComponent>
              <DatePickerComponent
                name="startDate"
                value={date.startDate || ""}
                className={classes.textField}
                handleChange={(e) => changeDates(e)}
                inputVariant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={false}
                validators={['required']}
                errorMessages={['Required']}
              />
            </Grid>
            <Grid item xs={12} className="sm-30">
              <TypographyComponent variant="h6">
                {ReportsConstants.END_DATE}
              </TypographyComponent>
              <DatePickerComponent
                name="endDate"
                value={date.endDate || ""}
                className={classes.textField}
                handleChange={(e) => changeDates(e)}
                inputVariant="outlined"
                margin="dense"
                size="small"
                fullWidth={true}
                disabled={false}
                validators={['required']}
                errorMessages={['Required']}
              />
            </Grid>
          </React.Fragment>
        )}
        {date.type && date.type === "Pulse dates" && (
          <React.Fragment>
              <Grid item xs={12} className={'pt5 pr5'}>
                <Grid className="sm-30">
                <AutoCompleteComponent
                      name="pulseDate"
                      suggestions={pulseDateArray || [] }
                      handleAutoCompolete={(e, n) => handleAutoCompleteChange({ value: e, name: n })}
                      suggestionType="array"
                      value={(date.pulseDate && survey && survey[0] && survey[0].pulse_obj && survey[0].pulse_obj.pulseDetails && survey[0].pulse_obj.pulseDetails[date.pulseDate])  ? survey[0].pulse_obj.pulseDetails[date.pulseDate].displayName : ""}
                      validators={["required"]}
                      errorMessages={['Required']}
                  ></AutoCompleteComponent>
                </Grid>
              </Grid>
          </React.Fragment>
        )}
      </Grid>
    </React.Fragment>
  );
}
// default props
ChooseDates.defaultProps = {
  classes: {},
};

// prop types
ChooseDates.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(ChooseDates);
