import { alertConstant } from "../constants";

// Export All Base Action Functions
export const baseAction = {
  success,
  failure,
  request
};

/**
 *
 * @param type
 * @param params
 */
function request(type, params, duration = alertConstant.ALERT_DEFAULT_DURATION) {
  return {
    type,
    params, 
    duration
  };
};

/**
 *
 * @param type
 * @param data
 */
function success(type, data, duration = alertConstant.ALERT_DEFAULT_DURATION) {
  return {
    type,
    data,
    duration
  };
};

/**
 *
 * @param type
 * @param error
 */
function failure(type, error, duration = alertConstant.ALERT_DEFAULT_DURATION) {
  if (!error) {
    error = "Request timeout."
  }
  return {
    type,
    error: error.toString(),
    duration
  };
};
