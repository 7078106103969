const ExternalApiIntegrationStyles = () => ({
    backimg: {
        width: "28px",
        height: "28px",
        background: "#FFFFFF",
        border: "1px solid #E2E2E2",
        borderRadius: "30px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    simpleFlex: {
        display: "flex",
    },

    //Tabs
    rightPanelAppBarRoot: {
        background: "transparent",
        height: "45px",
    },
    rightPanelTabsRoot: {
        "& .MuiTab-root": { maxWidth: "100%" },
    },
    rightPanelTabRoot: {
        borderRadius: "10px 10px 0px 0px",
        backgroundColor: "#EBECED",
        margin: "0px 4px",
        color: "rgba(0, 0, 0, 0.87) !important",
        "&:hover": {
            color: "#fff !important",
            backgroundColor: "#54C0FD !important",
        },
        "&:hover .peTabEdit, &:hover .peTabDelete": {
            visibility: "visible !important",
            filter: "brightness(0) invert(1)",
        },
        "&$selected": {
            color: "#fff",
            backgroundColor: "#54C0FD",
        },
    },
    rightPanelTabSelected: {
        backgroundColor: "#54C0FD !important",
        color: "#fff !important",
    },

    // Demographics Fields
    demographicsItem: {
        border: "1px solid #E3E4E6",
        background: "#F4F6F7",
        height: "46px",
        display: "flex",
        alignItems: "center",
    },
    demographicsXPadding: {
        paddingLeft: "15px",
        paddingRight: "15px"
    },
    configFooterBtn: {
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        padding: "15px 20px",
        backgroundColor: "#fff"
    },

    // Generate Token Panel
    dashBoardInformation: {
        borderRadius: "6px",
        background: "#fff",
        padding: "50px 40px",
        // height: "100%",
    },
    innerBgPanel: {
        padding: "45px 60px",
        backgroundColor: "#f4f6f7",
    },
    datePicker: {
        height: "36px!important",
        padding: "15px 12px!important",
    },
    repeatBlock: {
        display: "flex",
        flexFlow: "row",
        alignItems: "stretch",
        '& input': {
            padding: "15px",
        }
    },

    // AutoComplete
    autoComplete: {
        padding: "5px 30px!important",
        display: "flex",
        gap: "8px",
        fontSize: "1.1em",
        "& .MuiCheckbox-root": {
            padding: "0!important",
            marginRight: "0!important",
            "& .MuiSvgIcon-root": {
                width: "20px!important",
                height: "20px!important",
                color: "#ccc",
            },
            "&.Mui-checked": {
                "& .MuiSvgIcon-root": {
                    color: "#54C0FD",
                }
            }
        }
    },

    // External API - Data Integrations
    dataIntegrationContainer: {
        backgroundColor: "#fff",
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
        padding: '40px 20px',
        marginBottom: '60px',
        "& h3": {
            marginTop: 0
        }
    },
    dataIntegrationLogoWrap: {
        padding: '20px 30px',
        cursor: 'pointer',
        width: '100%',
        height: '200px',
        display: 'grid',
        placeContent: 'center',
        position: 'relative',
        "&:hover": {
            backgroundColor: '#f8f9fa',
        },
        "&.active": {
            border: '2px solid #54C0FD'
        }
    },
    dataIntegrationLogoContainer: {
        marginTop: '10px'
    },
    dataIntegrationLogos: {
        width: '200px',
    },
    configuredMsg: {
        position: 'absolute',
        bottom: '1em',
        right: '1em',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        fontSize: '14px',
        pointerEvents: 'none',
        margin: 0,
        "& svg": {
            width: '20px',
            height: '20px'
        }
    },
    hrisDescription: {
        lineHeight: 1.6,
        letterSpacing: '0.0075em',
        textAlign: 'left',
        fontSize: '14px',
    }

});

export default ExternalApiIntegrationStyles;