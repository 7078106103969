/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";

// import components
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import InformationComponent from "../../../../../components/InformationComponent/InformationComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";

import BulkAnswerSet from "./BulkAnswerSet";

// Import Images
import { Plus_blue, Option, Delete } from "../../../../../assets/images/svgComponents/ImageComponent";

// Import Actions
import { categoryAction } from "../../../../../redux/actions";

// import constants
import { surveyQuestionsConstants, surveyIntentionQuesTextDisable, surveyEngagementQuesTextDisable, translateLanguagesList, surveyQuestionDeleteOptionDisable, surveyTypes } from "../../../../../config/constants";
import { InputAdornment } from "@mui/material";

function AnswerChoices(props) {
  const dispatch = useDispatch();

  // Get Reducer Data
  const questionWithResponse = useSelector(state => state.survey.responseDetails.question_ids);
  const surveyType = useSelector(state => state.survey.getOne.type);

  // Get Props Values
  const { classes, category, questionData, item } = props;
  let { questionDataSet } = props
  const { languages } = questionData;

  // set state
  const [ansSetLength, setAnsSetLength] = useState(7);
  const [bulkAnsPopUp, setBulkAnsPopUp] = useState(null);
  const [showAllOptions, setShowAllOptions] = useState(false);
  const ansChoiceLength = Object.keys(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE]).length;

  //Update answerset for Old survey
  if (questionData.isOldSurvey && questionDataSet && questionDataSet.qusType === "Single Select" && questionDataSet.ansSet.en && JSON.stringify(questionDataSet.ansSet.en) === JSON.stringify(surveyQuestionsConstants.OLD_DEFAULT_SINGLE_SEL_ANS_SET)) {
    questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE] = surveyQuestionsConstants.OLD_DEFAULT_SINGLE_SEL_ANS_SET
  }

  // update answer set length
  useEffect(() => {
    setAnsSetLength(12);
  }, [dispatch, questionDataSet]);

  // Set Answer Set Display Length
  const ansSetDisplayLength = () => {
    setShowAllOptions(true);
    setAnsSetLength(Object.keys(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE]).length);
  };

  // update anwer set value
  const updateAnsSet = (event) => {
    let { name, value } = event;
    const nameArray = name.split(".");
    let ansSetUpdate = questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE];
    ansSetUpdate[nameArray[2].toString()] = value;

    // Emptying Other Translated Answer Set in order to apply the translation and get the updated translated text
    const updatedIndex = name.slice(-1);
    if (questionDataSet?.ansSet && Object.keys(questionDataSet?.ansSet || {}).length > 1) {
      Object.keys(questionDataSet?.ansSet).map(ansSet => {
        if (ansSet !== 'en') {
          questionDataSet["ansSet"][ansSet][updatedIndex] = '';
        }
      })
    }

    const reducerData = { ...questionDataSet, ansSet: { ...questionDataSet.ansSet, en: ansSetUpdate } };
    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, reducerData));
    } else {
      props.editQuestionsData(reducerData);
    }
  };

  // show Bulk choices On  Clicking question Type
  const showBulkAnsChoices = (event) => {
    setBulkAnsPopUp(event.currentTarget);
  };

  // Delete Ans Set in all languages
  const deleteAnsSet = (index) => {
    const langs = [...translateLanguagesList]

    for (let i = 0; i < langs.length; i++) {
      if (questionDataSet["ansSet"][langs[i]["code"]] && (questionDataSet["ansSet"][langs[i]["code"]][(index).toString()] || questionDataSet["ansSet"][langs[i]["code"]][(index).toString()] === "")) {
        delete questionDataSet["ansSet"][langs[i]["code"]][(index).toString()];
        // update the numeric key value to previous number
        let keyIndex = (index === 0 || (!questionDataSet["ansSet"][langs[i]["code"]].hasOwnProperty("0"))) ? 1 : 0;
        let updatedAnsSet = {};
        for (let key in questionDataSet["ansSet"][langs[i]["code"]]) {
          updatedAnsSet[(keyIndex).toString()] = questionDataSet["ansSet"][langs[i]["code"]][key];
          keyIndex++;
        }
        questionDataSet["ansSet"][langs[i]["code"]] = updatedAnsSet;
      }
    }
    setAnsSetLength(Object.keys(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE]).length);
    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, questionDataSet));
    } else {
      props.editQuestionsData(questionDataSet);
    }
  };

  // Add Zero Index Ans Set in all languages
  const addAnsSetChoice = (index) => {
    for (let i = 0; i < languages.length; i++) {
      questionDataSet["ansSet"][languages[i]["code"]][(index).toString()] = questionData.isOldSurvey ? "" : "";

      let updatedAnsSet = {}; let keyIndex = 0;
      for (let key in questionDataSet["ansSet"][languages[i]["code"]]) {
        updatedAnsSet[(keyIndex).toString()] = questionDataSet["ansSet"][languages[i]["code"]][key];
        keyIndex++;
      }

      questionDataSet["ansSet"][languages[i]["code"]] = updatedAnsSet;
    }
    setAnsSetLength(Object.keys(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE]).length);
  };
  return (
    <React.Fragment>
      {questionDataSet["ansSet"] && questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE] &&
        <React.Fragment>
          <Grid item sm={12}>
            <Grid container spacing={3} className="pl-5">
              {Object.keys(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE]).map((value, index) => (
                <React.Fragment key={index}>
                  {(index <= 7 || showAllOptions) &&
                    <Grid item sm={4}>
                      <Grid className={`divWidth ${classes.answerset_items}`}>
                        <Badge
                          badgeContent={questionDataSet.qusType === surveyQuestionsConstants.SINGLE_SELECT ? parseInt(value) : index + 1}
                          showZero
                          max={200}
                          color="secondary"
                          className={`${classes.answerset_items_badge} ${"badgeMedium"}`}
                        ></Badge>
                        <TextFieldComponent
                          id={value}
                          className={`${classes.answerset_items_txt} ${"bordernone"}`}
                          name={`ansSet.en.${value}`}
                          value={questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE][(value).toString()]}
                          margin={"dense"}
                          size="small"
                          variant="outlined"
                          showErrorMessages={false}
                          handleChange={(e) => updateAnsSet(e)}
                        ></TextFieldComponent>

                        {questionWithResponse.indexOf(questionDataSet["baseQusId"]) === -1 && questionDataSet.qusType !== surveyQuestionsConstants.YES_NO && !surveyIntentionQuesTextDisable.includes(questionDataSet.baseQusId) && !surveyEngagementQuesTextDisable.includes(questionDataSet.baseQusId) && surveyQuestionDeleteOptionDisable.indexOf(questionDataSet["baseQusId"]) === -1 &&
                          <IconButton
                            onClick={() => deleteAnsSet(parseInt(value))}
                            className={`${classes.answerset_items_dlt} p5 icon-hover ImgActiveOrange`}
                            style={{ left: 0 }}
                            size="large" 
                            position="end">
                            {Delete()}
                          </IconButton>
                        }
                        {
                          (surveyIntentionQuesTextDisable.includes(questionDataSet.baseQusId) || surveyEngagementQuesTextDisable.includes(questionDataSet.baseQusId)) && value == 0 && questionWithResponse.indexOf(questionDataSet["baseQusId"]) === -1 &&
                          <IconButton
                            onClick={() => deleteAnsSet(parseInt(value))}
                            position="end"
                            className={`${classes.answerset_items_dlt} p5 icon-hover ImgActiveOrange`}
                            size="large">
                            {Delete()}
                          </IconButton>
                        }
                      </Grid>
                    </Grid>
                  }
                </React.Fragment>
              ))}
              <Grid item sm={4} className={classes.answerChoices}>
                <Grid container justifyContent="space-between" alignItems="center">
                  {Object.keys(questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE]).length > 8 && !showAllOptions &&
                    <Grid item>
                      <IconButton
                        className={`${classes.morechoices_img} p-0`}
                        onClick={() => { ansSetDisplayLength(); }}
                        size="large">
                        {/* <img src={OptionsImg} alt={"More Coices"} className={classes.morechoices_img} /> */}
                        {Option()}
                      </IconButton>
                    </Grid>
                  }
                  {questionDataSet.qusType !== surveyQuestionsConstants.YES_NO && (questionDataSet.qusType === surveyQuestionsConstants.DROPDOWN || questionWithResponse.indexOf(questionDataSet["baseQusId"]) === -1) && !surveyIntentionQuesTextDisable.includes(questionDataSet.baseQusId) && !surveyEngagementQuesTextDisable.includes(questionDataSet.baseQusId) && (questionDataSet["baseQusId"] !== "16") &&
                    <Grid item className="p12 pt-0 pl-0 pr-0">
                      <Link
                        onClick={(e) => { showBulkAnsChoices(e); }}
                        className={`${" cursor-pointer"}`}
                        color="primary">
                        <IconButton color="inherit" className={"p-0"} size="large">{Plus_blue()}</IconButton>
                        Add Answer Choices
                      </Link>
                      <InformationComponent>
                        <TypographyComponent variant="tooltipbody">
                          {surveyQuestionsConstants.ADD_ANSWER_CHOICES_HELP}
                        </TypographyComponent>
                      </InformationComponent>
                    </Grid>
                  }

                  {/* Intention To Stay Single Select Components */}
                  {questionDataSet.qusType !== surveyQuestionsConstants.YES_NO && questionDataSet.qusType !== surveyQuestionsConstants.DROPDOWN  && questionDataSet.qusType !== surveyQuestionsConstants.MATRIX && (questionWithResponse.indexOf(questionDataSet["baseQusId"]) === -1) && (questionDataSet["baseQusId"] !== "16") &&
                    <Grid item className="p12 pt-0 pl-0" >                      
                      {!questionDataSet["ansSet"][surveyQuestionsConstants.DEFAULT_LANG_CODE].hasOwnProperty("0") 
                        ? <Link
                          onClick={() => { addAnsSetChoice(0); }}
                          className={`${classes.quest_linkimg} ${"cursor-pointer"}`}
                          color="primary">
                          <IconButton color="inherit" className={"p-0"} size="large">{Plus_blue()}</IconButton>
                          {surveyQuestionsConstants.ADD_ZERO_ANSWER_CHOICE}
                        </Link>
                        : ""}
                    </Grid>
                  }


                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <BulkAnswerSet
            anchorEl={bulkAnsPopUp}
            classes={classes}
            item={item}
            category={category}
            questionDataSet={questionDataSet}
            answerSet={questionDataSet.ansSet.en}
            showBulkAnsChoices={() => { setBulkAnsPopUp(null); }}
            editQuestionsData={(reducerData) => props.editQuestionsData(reducerData)}
          />
        </React.Fragment>
      }
    </React.Fragment>
  );
}

// default props
AnswerChoices.defaultProps = {
  classes: {},
  questionDataSet: {},
  questionData: {},
  item: null,
  category: null,
  editQuestionsData: () => { }
};

// prop types
AnswerChoices.propTypes = {
  classes: PropTypes.object,
  questionDataSet: PropTypes.object,
  questionData: PropTypes.object,
  item: PropTypes.object,
  category: PropTypes.string,
  editQuestionsData: PropTypes.func
};

export default AnswerChoices;
