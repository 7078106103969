/* eslint-disable camelcase */
import { clientConstant } from "../constants";
import Profile_IMG from "../../assets/images/default_profile.svg";
// Set Initial State
const initialState = {
  getOne: {
    _id: "",
    name: "",
    logo: Profile_IMG,
    shareFileFolder: "",
    industry: "",
    threshold: "",
    smsAccess: false,
    transfloAccess: false,
    phoneAccess: false,
    emailAccess: false,
    samlssoAccess: false,
    rawDataAccess: false,
    languageTranslation: false,
    managerEffectiveness: false,
    transflo: {
      recipient: "",
      auth: ""
    },
    saml: {
      attributeName: "",
      acs_url: "",
      issuer_url: "",
      idp_url: "",
      logout_url: "",
      pe_app_url: "",
      contextKey: "",
      metadataFile: ""
    },
    internalApiAccess: false,
    externalApiAccess: false,
    engagementComparison: true,
  },
  getAll: [],
  LanguageList: [],
  surveyPermission: {
    emailAccess: false,
    phoneAccess: false,
    smsAccess: false,
    managerEffectiveness: false,
    transfloAccess: false
  },
  survey_threshold: "",
  prev_threshold_value: null,
  industries: [],
  me_surveys: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case clientConstant.CLIENTS_GETALL_REQUEST:
      return {
        ...state
      };
    case clientConstant.CLIENTS_GETALL_SUCCESS:
      return {
        ...state,
        getAll: action.data.data
      };
    case clientConstant.CLIENTS_GETALL_FAILURE:
      return {
        ...state,
        getAll: initialState
      };

    // CREATE CLIENT DETAILS
    case clientConstant.CLIENTS_CREATE_REQUEST:
      return {
        ...state
      };
    case clientConstant.CLIENTS_CREATE_SUCCESS:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case clientConstant.CLIENTS_CREATE_FAILURE:
      return {
        ...state,
        getOne: initialState.getOne
      };

    // GET ONE CLIENT DETAILS
    case clientConstant.CLIENTS_GET_ONE_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case clientConstant.CLIENTS_GET_ONE_SUCCESS:
      return {
        ...state,
        getOne: {
          ...initialState.getOne, ...action.data.data
        },
        prev_threshold_value: action.data.data && action.data.data.threshold ? action.data.data.threshold : initialState.prev_threshold_value
      };
    case clientConstant.CLIENTS_GET_ONE_FAILURE:
      return {
        ...state,
        getOne: initialState
      };

    // UPDATE GET ONE CLIENT REDUCER DATA
    case clientConstant.CLIENTS_GET_ONE_UPDATE:
      return {
        ...state,
        getOne: action.data
      };

    // GET CLIENT PERMISSION
    case clientConstant.SURVEY_CLIENT_PERMISSION_GET_REQUEST:
      return {
        ...state
      };
    case clientConstant.SURVEY_CLIENT_PERMISSION_GET_SUCCESS:
      if (action.data.data) {
        return {
          ...state,
          surveyPermission: action.data.data
        };
      } else {
        return {
          ...state,
          surveyPermission: initialState.surveyPermission
        };
      }
    case clientConstant.SURVEY_CLIENT_PERMISSION_GET_FAILURE:
      return {
        ...state,
        surveyPermission: initialState.surveyPermission
      };

    // UPDATE PARTICULAR CLIENT
    case clientConstant.CLIENTS_UPDATE_REQUEST:
      return {
        ...state
      };
    case clientConstant.CLIENTS_UPDATE_SUCCESS:
      return {
        ...state,
        // getOne: initialState.getOne,
        survey_threshold: action.data
      };
    case clientConstant.CLIENTS_SURVEY_THRESHOLD_UPDATE_SUCCESS:
      return {
        ...state,
        // getOne: initialState.getOne,
        survey_threshold: action.data
      };
    case clientConstant.CLIENTS_UPDATE_FAILURE:
      return {
        ...state
      };

    // // GET client ME survey details
    case clientConstant.CLIENTS_CHECK_SURVEYS_REQUEST:
      return {
        ...state,
      };
    case clientConstant.CLIENTS_CHECK_SURVEYS_SUCCESS:
      return {
        ...state,
        me_surveys: action.data.data
      };
    case clientConstant.CLIENTS_CHECK_SURVEYS_FAILURE:
      return {
        ...state
      };

    // GET CLIENT INDUSTRY
    case clientConstant.CLIENTS_GETINDUSTRY_REQUEST:
      return {
        ...state,
        industries: initialState.industries
      };
    case clientConstant.CLIENTS_GETINDUSTRY_SUCCESS:
      return {
        ...state,
        industries: action.data.data
      };
    case clientConstant.CLIENTS_GETINDUSTRY_FAILURE:
      return {
        ...state
      };

    // GET CLIENT Language List
    case clientConstant.CLIENTS_GET_LANGUAGE_LIST_REQUEST:
      return {
        ...state,
        LanguageList: initialState.industries
      };
    case clientConstant.CLIENTS_GET_LANGUAGE_LIST_SUCCESS:
      return {
        ...state,
        LanguageList: action.data.data ? action.data.data : []
      };
    case clientConstant.CLIENTS_GET_LANGUAGE_LIST_FAILURE:
      return {
        ...state
      };

    case clientConstant.CLEAR_CLIENT_GETONE:
      return {
        ...state,
        getOne: initialState.getOne
      };


    // Update Metadata
    // eslint-disable-next-line no-sequences
    case clientConstant.METADATA_UPDATE_REQUEST,
      clientConstant.METADATA_UPDATE_SUCCESS,
      clientConstant.METADATA_UPDATE_FAILURE:
      return {
        ...state
      };
    default:
      return state;
  }
}
