import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import PropTypes from "prop-types";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from "react-redux";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

import withStyles from "@mui/styles/withStyles";


import { CopyToClipboard } from "react-copy-to-clipboard";

// Import History
import history from "../../../config/history";

// Import Styles and Image
import APIIntegrationStyles from "../APIIntegrationStyles";

import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";

// Import Actions
import { alertAction, apiIntegrationAction } from "../../../redux/actions";

const TokenDialog = (props) => {
//Get props
const { classes } = props;
const dialog = useSelector((state) => state.apiIntegration.internalAPI.dialog)
const apiData = useSelector((state) => state.apiIntegration?.internalAPI)
  let token = ""
  if(apiData.token){
    token =  apiData.token.token
  }
const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(apiIntegrationAction.dialog())
    dispatch(alertAction.successAlert("API token copied!"));
    // dispatch(apiIntegrationAction.selectedSurveys([]))
    // dispatch(apiIntegrationAction.selectedDemographics([]))
    // dispatch(apiIntegrationAction.wishlistDomain_update(""));
    // dispatch(apiIntegrationAction.startDate_update(""));
    // dispatch(apiIntegrationAction.endDate_update(""));
    // dispatch(apiIntegrationAction.limits_per_update(""));
    // dispatch(apiIntegrationAction.limits_update(""))
    // history.push({ pathname: "/surveys" })
  };

  const closeDialog = () =>{
    dispatch(apiIntegrationAction.dialog())
  }

  return (
    <div>
      <Dialog open={dialog} onClose={closeDialog}>
        <DialogTitle>API TOKEN</DialogTitle>
        <DialogContent>
        <TextFieldComponent
            id={"api_key"}
            className={classes.tokenField}
            value={token}
            // margin={"dense"}
        />
        </DialogContent>
        <DialogActions>
        <CopyToClipboard
            text={token}
            onCopy={handleClose}>
            <Button
                    title={"Copy"}
                    color={"primary"}
                    size={"small"}
                    variant={"contained"}
                    pageClassName="mr-1"
                >Copy</Button>
            </CopyToClipboard>
          <ButtonComponent
                title={"Close"}
                color={"default"}
                size={"small"}
                type="submit"
                pageClassName="mr-1"
                handleClick = {closeDialog}
            />
        </DialogActions>
      </Dialog>
    </div>
  );
}
// default props
TokenDialog.defaultProps = {
    classes: {}
};

// prop types
TokenDialog.propTypes = {
    classes: PropTypes.object
};
export default withStyles(APIIntegrationStyles)(TokenDialog);