import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const actionsService = {
  search,
  getOne,
  create,
  update,
  remove,
  actionplan
};

/**
 * Get All Admin Users
 * @param id
 * @param updatedData
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`actions/actionplan/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

/**
* Get Particular User Admin
* @param id
* @param updatedData
*/
async function getOne(id) {
  const response = await apihelper.getRequest("actions/actionplan/" + id);
  return response;
}

/**
* Create New Admin
* @param id
* @param updatedData
*/
async function create(data) {
  const response = await apihelper.postRequest("actions/actionplan", data);
  return response;
}

/**
* Update Particular Admin Roles
* @param id
* @param updatedData
*/
async function update(updatedData) {
  const response = await apihelper.putRequest("actions/actionplan", updatedData);
  return response;
}

/**
* Remove Particular Admin Roles
* @param id
*/
async function remove(id) {
  const response = await apihelper.deleteRequest("actions/actionplan/", { _id: id });
  return response;
}

/**
* Get action plan data
*/
async function actionplan(data) {
  const response = await apihelper.postRequest("actions/actionplan/data", data);
  return response;
}
