/* eslint-disable dot-notation */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import TableCell from "@mui/material/TableCell";

// Import Styles and Image
import ParticipantStyles from "../../../Pariticipant/PariticipantStyles";

// Import Components
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";
import DatePickerComponent from "../../../../../components/DatePickerComponent/DatePicker";
import SelectFields from "./SelectFields";
import ConversionMatchField from "./ConversionMatchField";

// Import helper
import { dateFormatter } from "../../../../../helpers";

// Import Actions
import { metadataAction } from "../../../../../redux/actions";

const ConversionListItems = (props) => {
  const dispatch = useDispatch();

  // get props
  const { classes, metadataSelected, metadataWithRGI, metadata, currentItem, currentIndex } = props;

  // change the value field data
  const changeConversionValue = (e, index) => {
    const { name, value } = e;
    if (metadataSelected && metadataSelected["conversion"]) {
      for (let i = 0; i < metadataSelected["conversion"].length; i++) {
        if (i === index) {
          metadataSelected["conversion"][i][name] = value;
        }
      }
      dispatch(metadataAction.selectedMetadata(metadataSelected));
    }
  };

  // Date format changes
  const changeConversionValueDate = (e, currentIndex) => {
    e["value"] = dateFormatter(e["value"]);
    changeConversionValue(e, currentIndex);
  };

  return (
    <React.Fragment>
      <TableCell>
        <SelectFields
          metadata={metadata}
          metadataWithRGI={metadataWithRGI}
          metadataSelected={metadataSelected}
          currentValue={currentItem.from}
          currentName={"from"}
          currentItem={currentItem}
          currentIndex={currentIndex}
        />
      </TableCell>
      <TableCell>
        <SelectFields
          metadata={metadata}
          metadataWithRGI={metadataWithRGI}
          metadataSelected={metadataSelected}
          currentValue={currentItem.operator}
          currentName={"operator"}
          currentItem={currentItem}
          currentIndex={currentIndex}
        />
      </TableCell>
      <TableCell>
        <Grid container alignItems="center" style={{display: "block"}}>
          <ConversionMatchField
            metadata={metadata}
            metadataWithRGI={metadataWithRGI}
            metadataSelected={metadataSelected}
            currentValue={currentItem.match}
            currentName={"match"}
            currentItem={currentItem}
            currentIndex={currentIndex}
          />
        </Grid>
      </TableCell>
      <TableCell>
        {(metadataSelected && metadataSelected["dataType"] && metadataSelected["dataType"] === "date") ? <DatePickerComponent
          id={"value"}
          name={"value"}
          value={currentItem.value}
          className={classes.textField}
          handleChange={(e) => changeConversionValueDate(e, currentIndex)}
          inputVariant="outlined"
          margin="dense"
          size="small"
          fullWidth={true}
          disabled={false}
        />
          : <TextFieldComponent
            id={"value"}
            name={"value"}
            size={"small"}
            value={currentItem.value}
            margin={"dense"}
            handleChange={(e) => changeConversionValue(e, currentIndex)}
          ></TextFieldComponent>
        }
      </TableCell>
    </React.Fragment>
  );
};
// default props
ConversionListItems.defaultProps = {
  classes: {},
  metadataSelected: {},
  metadataWithRGI: [],
  metadata: [],
  currentItem: {},
  currentIndex: 0
};

// prop types
ConversionListItems.propTypes = {
  classes: PropTypes.object,
  metadataWithRGI: PropTypes.array,
  metadata: PropTypes.array,
  metadataSelected: PropTypes.object,
  currentItem: PropTypes.object,
  currentIndex: PropTypes.number
};
export default withStyles(ParticipantStyles)(ConversionListItems);
