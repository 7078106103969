import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextEditor from "../../../../components/TextEditor";

import { getReducerUpdatedData } from "../../../..//helpers";
import { contentLibraryAction } from "../../../../redux/actions";
import { surveySettingsConstants } from "../../../../config/constants";


function ThankYouMessage(props) {
  const { classes, data, messageData, expanded } = props;
  const dispatch = useDispatch();


  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };

  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, data);
    props.editMessageContent(reducerData)
  };


  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  const saveMessageData = () => {
    delete data.updatedData;
    messageData.filter((msg, index) => {
      if (msg.type === data.type) {
        messageData[index] = data
      }
    })
    dispatch(contentLibraryAction.updateMessage(messageData));
  };

  const closeMessage = () => {
    props.handleCancelMessage()
  };

  return (
    <React.Fragment>
      <Accordion
        className="mb-2 pe-accordion"
        expanded={expanded === "thankyou-panel"}
        onChange={handleTabChange("thankyou-panel")}
      >

        {/*
      panel header summary
    */}

        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expandicon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyComponent
            className={` ${classes.setting_accordheading} ${expanded === "thankyou-panel" ? "panel-active" : ""} `}
            variant={"h5"}
          >{"Thank You Message"}</TypographyComponent>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={3} className={`${classes.setting_expandcontainer} `}>
            <Grid item sm={12}>
              <Grid>
                <TypographyComponent variant="h6">
                  {surveySettingsConstants.SURVEY_DESCRIPTION}
                </TypographyComponent>

                <TextareaAutosize
                  className={`width100 pe_textarea mb-m5 borderRadius6 `}
                  name={`thankyou_page.description.`}
                  value={data.thankyou_page ? data.thankyou_page.description : ""}
                  onChange={(e) => handleTextChange(e)}
                  minRows={3} />
              </Grid>

              <Grid className="mt-2 pt-1 pb-1">
                <TypographyComponent variant="h6">
                  {surveySettingsConstants.SURVEY_THANK_YOU_TITLE}
                </TypographyComponent>
              </Grid>

              <Grid className="pt-1 pb-1">
                <TypographyComponent variant="h6">
                  {surveySettingsConstants.SURVEY_THANK_YOU_HEADER}
                </TypographyComponent>

                <TextareaAutosize
                  className={`width100 pe_textarea mb-m5 borderRadius6`}
                  name={`thankyou_page.thanks_header`}
                  value={data.thankyou_page ? data.thankyou_page.thanks_header : ""}
                  onChange={(e) => handleTextChange(e)}
                  minRows={3} />
              </Grid>

              <Grid className="pt-1 pb-1">
                <TypographyComponent variant="h6">
                  {surveySettingsConstants.SURVEY_THANK_YOU_DESC}
                </TypographyComponent>

                {/* <TextareaAutosize
                  className={`width100 pe_textarea mb-m5 borderRadius6`}
                  name={`thankyou_page.thanks_description`}
                  value={data.thankyou_page ? data.thankyou_page.thanks_description : ""}
                  onChange={(e) => handleTextChange(e)}
                  rowsMin={3} /> */}
                  <TextEditor
                  id={`welcome_description`}
                  height={"200px"}
                  // placeholder={langcode !== "en" && data.welcome_description ? data.welcome_description["en"] : ""}
                  data={data.thankyou_page ? data.thankyou_page.thanks_description : ""}
                  onChange={(e, editor) => handleChange({ "name": `thankyou_page.thanks_description`, "value": editor.getData()})}
                />
              </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  <ButtonComponent
                    title={"OK"}
                    color={"primary"}
                    size={"small"}
                    handleClick={() => saveMessageData()}
                    pageClassName={classes.settings_addrulebtn}
                  ></ButtonComponent>
                  <ButtonComponent
                    title={"Cancel"}
                    color={"secondary"}
                    size={"small"}
                    handleClick={() => closeMessage()}
                    pageClassName="ml-1"
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

    </React.Fragment>
  );
}

export default ThankYouMessage;

