import { categoryConstant } from "../constants/categoryConstant";

const initialState = {
  category: [],
  questions: [],
  search: {
    key: "",
    category: [],
    questions: [],
    cat_ques: []
  },
  selectedQues: [],
  addQuestions: {} // need to be {[categoryName]:[questionDataSet]}
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Category getCategory Reducer
    case categoryConstant.CATEGORY_GET_CATEGORY_REQUEST:
      return {
        ...state
      };
    case categoryConstant.CATEGORY_GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.data.data
      };
    case categoryConstant.CATEGORY_GET_CATEGORY_FAILURE:
      return {
        ...state,
        category: initialState.category
      };

    // Category GetQuestions Reducer
    case categoryConstant.CATEGORY_GET_QUESTIONS_REQUEST:
      return {
        ...state
      };
    case categoryConstant.CATEGORY_GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          cat_ques: action.data.data
        }
      };
    case categoryConstant.CATEGORY_GET_QUESTIONS_FAILURE:
      return {
        ...state,
      };

    // Category Search Reducer
    case categoryConstant.CATEGORY_SEARCH_REQUEST:
      return {
        ...state,
        search: {
          ...state.search
        }
      };
    case categoryConstant.CATEGORY_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          category: action.data.data,
          questions: action.data.questions
        }
      };
    case categoryConstant.CATEGORY_SEARCH_FAILURE:
      return {
        ...state,
        search: initialState.search
      };

    // add question in a category (add question fields on change or on add question click)
    case categoryConstant.CATEGORY_ON_ADD_QUESTION_ADD_OR_CHANGE:
      return {
        ...state,
        addQuestions: {
          // ...state.addQuestions,
          [action.data.categoryName]: action.data.questionDataSet
        }
      };
    case categoryConstant.CATEGORY_ON_CANCEL_ADD_QUESTION:
      delete state.addQuestions[action.data.categoryName];
      return {
        ...state,
        addQuestions: state.addQuestions
      };
    case categoryConstant.CATEGORY_CANCEL_ADD_QUESTION:
      return {
        ...state,
        addQuestions: {}
      };
      case categoryConstant.CATEGORY_QUESTION_SELECTED:
      return {
        ...state,
        selectedQues: action.data
      };
    // Category Default Reducer Update
    default:
      return state;
  }
}
