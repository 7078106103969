import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import FormControl from "@mui/material/FormControl";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import { DashboardClose, Expand } from "../../../../assets/images/svgComponents/ImageComponent";
import ReportsStyles from "../../ReportsStyles";

//components
import TotContent from './TotContent'
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import TotPopup from './TotPopup'
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";
import MultiSelectActionComponent from "../../../../components/MultiSelectActionComponent/MultiSelectActionComponent";

// Import Actions
import { dashboardAction, adminsAction, alertAction } from "../../../../redux/actions";


//import report constants
import { ReportsConstants, surveyTypes } from "../../../../config/constants";

function TrendsOverTime(props) {

    // Get Props
    const { classes, handleClose, DragHandle, isUpdate, preference, stopChartReload } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Get Survey Details
    let { allSurveys } = useSelector(state => state.filter);
    const { totinitialReload } = useSelector((state) => state.dashboard.initialReload);
    const { tot } = preference
    allSurveys = allSurveys.filter((s) => s.type !== surveyTypes.MULTI_RATER_FEEDBACK)

    let totalSurveySelected = tot && tot.surveys && tot.surveys.length > 0 && tot.surveys[0] ? tot.surveys : preference.surveys;
    totalSurveySelected = totalSurveySelected.map(m => m._id || m)
    const surveysSelected = allSurveys.filter(m => totalSurveySelected.indexOf(`${m._id}`) >= 0);


    let selSurveyIds = [];
    let selSurveyNames = [];
    let filterSelSurveyNames = [];
    let filterSelSurveyIds = [];

    surveysSelected.map((val) => {
        selSurveyIds.push(val._id);
        selSurveyNames.push(val.name)
    })

    // useEffect(() => {
    //     dispatch(dashboardAction.updateTrendsOverTimePreference(surveysSelected))
    // }, [tot, totinitialReload])

    // pre select from data filter
    if (preference.surveys && preference.surveys.length > 0) {
        filterSelSurveyNames = preference.surveys.map(m => m.name)
        filterSelSurveyIds = preference.surveys.map(m => m._id)
    }

    // Set State
    const [isPopup, setIsPopup] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState([...surveysSelected]);
    const [selectedSurveyNames, setSelectedSurveyNames] = useState([...selSurveyNames]);

    const [preSurveys, setPreSurveys] = useState([...filterSelSurveyIds]);
    const [preSurveyNames, setPreSurveyNames] = useState([...filterSelSurveyNames]);

    // Get Data From Reducer
    const { data: totdata, loading } = useSelector((state) => state.dashboard.trendsOverTime);


    // open popup
    const openPopup = () => {
        setIsPopup(true);
    };

    // popup close
    const onClose = () => {
        setIsPopup(false);
    };

    //autocomplete component change
    const handleAutoCompleteChange = (event) => {
        const selectedVal = event.value;
        const action = event.action
        let selectedSurveyIds = [];
        let selectedSurveyNames = [];
        if (selectedVal.length <= 10) {
            selectedVal.forEach((val) => {
                let surveyData = JSON.parse(JSON.stringify(allSurveys)).find((ele) => ele.name === val);
                selectedSurveyIds.push(surveyData._id);
                selectedSurveyNames.push(surveyData.name);
            })

            setSelectedSurvey([...selectedSurveyIds]);
            setSelectedSurveyNames([...selectedSurveyNames]);
            let allTotSurveys = allSurveys.filter((obj) => selectedSurveyIds.includes(obj._id))

            dispatch(dashboardAction.updateTrendsOverTimePreference(allTotSurveys))
        } else {
            dispatch(alertAction.error(ReportsConstants.TOT_SURVEY_EXCEEDED));
        }
    }

    const handleApplyBtn = () => {
        let totSelected = [];
        selectedSurvey.forEach((val) => {
            let surveyData = JSON.parse(JSON.stringify(allSurveys)).find((ele) => ele._id == val);
            totSelected.push(surveyData);
        })


        // update in tot preference in DB
        const data = {
            ...preference,
            tot: {
                surveys: [...totSelected]
            }
        }

        dispatch(adminsAction.updateReportPreference(data, () => {
            // update tot chart
            dispatch(dashboardAction.getTrendsOverTimeDataIn(selectedSurvey));
        }))
    }

    const handleClearAll = () => {
        setSelectedSurvey([...preSurveys])
        setSelectedSurveyNames([...preSurveyNames])
        let allTotSurveys = allSurveys.filter((obj) => preSurveys.includes(obj._id))
        dispatch(dashboardAction.updateTrendsOverTimePreference(allTotSurveys))
    }

    const getSurveyNames = () => {
        let surveyNames = [];
        let totSelectedSurveys = []

        selectedSurveyNames.forEach((ele => {
            if (!preSurveyNames.includes(ele)) {
                totSelectedSurveys.push(ele)
            }
        }))

        JSON.parse(JSON.stringify(allSurveys)).forEach((ele) => {
            if (!selectedSurveyNames.includes(ele.name) && !preSurveyNames.includes(ele.name))
                surveyNames.push(ele.name);
        })

        return [...preSurveyNames, ...totSelectedSurveys, ...surveyNames];
    }


    const getPulseSurveys = () => {
        let surveyNames = [];
        JSON.parse(JSON.stringify(allSurveys)).forEach((ele) => {
            if (ele.frequency === 'Pulse')
                surveyNames.push(ele.name);
        })
        return surveyNames
    }

    const getSelectedSurveys = () => {
        let surveyNames = [...preSurveyNames];
        selectedSurveyNames.forEach((ele) => {
            if (!surveyNames.includes(ele)) {
                surveyNames.push(ele);
            }
        })
        return surveyNames
    }

    const surveySuggestions = getSurveyNames();

    return (
        <React.Fragment>
            <Grid item xs={12} className={"mb-14"}>
                <Grid item xs={12} className={classes.insightTile}>
                    <Grid item >
                        <Grid container alignItems="center" className="mb-1">
                            {!loading &&
                                <Grid item>
                                    <DragHandle />
                                </Grid>
                            }
                            <Grid item>
                                <TypographyComponent variant="h4" className="txtBold fontSize16 ml-1">
                                    {"Trends Over Time"}
                                </TypographyComponent>
                            </Grid>
                            <Grid item className={classes.totViewActionButton}>
                                {!loading &&
                                    <Grid container className="buttonContainer">
                                        {
                                            surveySuggestions.length > 1 && <ValidatorForm
                                                id="add_participant"
                                                name="Add Participant"
                                                autoComplete="off"
                                                className="width70 height100"
                                                // onSubmit={handleSubmit}
                                                onError={(error) => {
                                                    console.log(error);
                                                }}
                                            >
                                                <FormControl className="width70 txtright" alignItems="right" justifyContent="space-between" >
                                                    <MultiSelectActionComponent
                                                        name={"surveys"}
                                                        className={`${"height40"} ${classes.textField}`}
                                                        classes={classes}
                                                        suggestions={surveySuggestions}
                                                        selectedOptions={selectedSurvey}
                                                        handleAutoComplete={(name, value, event, action) => {
                                                            handleAutoCompleteChange({ name, value, event, action });
                                                        }}
                                                        handleClearAll={handleClearAll}
                                                        handleApplyBtn={handleApplyBtn}
                                                        value={getSelectedSurveys()}
                                                        placeholder={"select surveys"}
                                                        readOnlyOptions={preSurveyNames}
                                                        pulseSurveys={getPulseSurveys()}
                                                    />
                                                </FormControl>
                                            </ValidatorForm>
                                        }
                                        <Tooltip title={"Expand"} arrow >
                                            <IconButton onClick={() => { openPopup() }} size="large">
                                                {Expand()}
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={"Close"} arrow >
                                            <IconButton onClick={() => handleClose('6')} size="large">
                                                {DashboardClose()}
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {loading &&
                        <LoaderComponent></LoaderComponent>
                    }
                    {!loading &&
                        <Grid container>
                            <TotContent
                                classes={classes}
                                totdata={totdata}
                                filterData={preference}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>
            {isPopup && !loading && (
                <DialogComponent open={true} fullScreen={true}>
                    <TotPopup
                        totdata={totdata}
                        filterData={preference}
                        onClose={onClose}
                    />
                </DialogComponent>
            )}
        </React.Fragment >
    );
}

// default props
TrendsOverTime.defaultProps = {
    classes: {},
    isUpdate: false,
    handleClose: () => { },
    updateReportPreference: () => { },
    stopChartReload: () => { }
};

// prop types
TrendsOverTime.propTypes = {
    classes: PropTypes.object,
    isUpdate: PropTypes.bool,
    handleClose: PropTypes.func,
    updateReportPreference: PropTypes.func,
    stopChartReload: PropTypes.func
};
export default withStyles(ReportsStyles)(TrendsOverTime);