import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child router components
import People from "./People";
import AdminsRouter from "./Admins/AdminRouter";

import DemographicsRouter from "./Demographics/Demographics.Route";
import ParticipantRouter from "./Pariticipant/Participant.Router";

//import component
import LiveChatComponent from "../../components/LiveChatComponent/LiveChatComponent";

function PeopleRouter(props) {
  const { match } = props;
  return (
    <>
      <Switch>
        <Route exact path={match.url} render={(props) => {
          return <People
            match={props.match}
            {...props} />;
        }} />
        <Route path={`${match.url}/participants`} render={(props) => {
          return <ParticipantRouter
            match={props.match}
            {...props} />;
        }} />
        <Route path={`${match.url}/demographics`} render={(props) => {
          return <DemographicsRouter
            match={props.match}
            {...props} />;
        }} /> ;
        <Route path={`${match.url}/admins`} render={(props) => {
          return <AdminsRouter
            match={props.match}
            {...props} />;
        }} />
      </Switch>
    </>
  );
}

/**
 * Bind Component Property Types
 */
PeopleRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default PeopleRouter;
