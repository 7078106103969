/* eslint-disable no-unused-vars */
/* eslint-disable no-dupe-keys */
import { createTheme } from "@mui/material/styles";
// import { padding } from "@mui/system";
// import { Popper } from "@mui/material";
import { palette} from "./palette"

const theme = createTheme({
  palette: palette,

  components: {
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottomStyle: "1px solid #ECEBED !important",
          padding: "0px 4px"
        },
        head: {
          fontWeight: "500",
          fontSize: "14px",
          color: "#222",
          height: "52px",
          minWidth: "140px"
        },
        body: {
          fontWeight: "400",
          fontSize: "13px"
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          height: "48px"
        }
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          position: "relative"
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        barColorPrimary: {
          backgroundColor: "#FFA12D"
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff0"
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paperFullScreen: {
          opacity: "0.98"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          //color: "#292929",
          //backgroundColor: "#fff",
          boxShadow: "1px 1px 3px #22222233",
          fontSize: "13px",
          fontWeight: "400px !important",
          textOverflow: 'ellipsis',
          //overflow: 'hidden',
          display: "-webkit-box"
        },
        popper: {
          zIndex: "99999999"
        },
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          marginTop: "20px"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "5px"
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontFamily: "Montserrat",
          color: "#292929",
          letterSpacing: "0.5px",
          fontWeight: "600",
          fontSize: "42px",
          lineHeight: "normal"
        },
        h2: {
          fontFamily: "Montserrat",
          color: "#292929",
          fontWeight: "600",
          fontSize: "30px"
        },
        h3: {
          fontFamily: "Montserrat",
          color: "#292929",
          letterSpacing: "0.5px",
          fontWeight: "600",
          fontSize: "20px"
        },
        h4: {
          fontFamily: "Montserrat",
          color: "#292929",
          fontWeight: "400",
          fontSize: "18px"
        },
        h5: {
          fontFamily: "Roboto",
          color: "#292929",
          fontWeight: "400",
          fontSize: "16px"
        },
        h6: {
          fontFamily: "Roboto",
          color: "#292929",
          fontWeight: "400",
          fontSize: "14px"
        },
        body1: {
          fontFamily: "Roboto",
          fontSize: "13px",
          color: "#292929"
        },
        tooltipbody: {
          fontFamily: "Roboto",
          color: "#fff",
          fontWeight: "400",
          fontSize: "14px"
        }
      }
    },
    MuiTabScrollButton: {
      styleOverrides:{
        root: {
          '& .Mui-disabled': {
            opacity: '0.2 !important'
          }
        }
      }
    },

    MuiAccordion: {
      styleOverrides: {
        "&.Mui-Mui-expanded": {
          margin: "10px 0 !important"
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          //boxShadow: "0px 0px 20px red",
        },
        Popper: {
         // boxShadow: "0px 2px 20px red"
        },
        endAdornment: {
          //   top: `calc(50% - 10px)`,
          right: "5px !important"
        },
        paper: {
          fontSize: "13px",
          borderBottom: "2px solid #E8E8E8",
          borderTop: "2px solid #E8E8E8",
          borderLeft: "2px solid #E8E8E8",
          boxShadow: "1px 1px 3px #22222233 !important",
        },
        input: {
          // height: "1em",
          color: "black !important"
          // padding: "3px 2px 5px 3px !important"
        },
        // inputFocused: {
        //   height: "3.3em !important"
        // }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 2px 20px #2929291f"
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          "&$error": {
            position: "absolute",
            margin: 0,
            right: 0,
            top: 50
          }
        },
        marginDense: {
          "&$error": {
            position: "absolute",
            margin: 0,
            right: 0,
            top: 31
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          marginTop: 8,
          marginBottom: 4
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: "28px",
          margin: 5,
          backgroundColor: "#F5F5F5"
        },
        deleteIconColorPrimary: {
          color: "#fff"
        },
        colorPrimary: {
          backgroundColor: "#54C0FD !important"
        }
        // deletable: {
        //   "&:focus": {
        //     color: "#fff",
        //     backgroundColor: "#54C0FD"
        //   },
        // }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        track: {
          height: "18px",
          marginTop: "-3.99px",
          borderRadius: "30px",
          width: "45px"
        },
        switchBase: {
          left: "4px"
        },
        sizeSmall: {
          width: "48px"
        },
        thumb: {
          boxShadow: "transparent"
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "30px",
          borderColor: "#F2F4F5",
          backgroundColor: "#fff",
          "&:hover $notchedOutline": {
            borderColor: "rgb(95 95 95 / 87%)"
          }
        },
        input: {
          padding: "23px 15px",
          fontSize: "13px",
          lineHeight: "13px",
          boxSizing: "inherit"
        },
        inputMarginDense: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
          paddingLeft: "14px !important",
          // paddingRight: "14px !important",
          fontSize: "13px !important",
          lineHeight: "28px",
          height: "28px !important"
        }     
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: "Roboto",
          color: "#292929",
          borderRadius: "30px"
        },
        root: {
          "&.Mui-disabled": {
            color: "gray !important"
          }
        },
        input: {
          // height: "3.3em",
          "&.MuiInputBase-inputAdornedEnd": {
            // height: "3.3em"
          },
          "&.Mui-disabled": {
            cursor: "not-allowed"
          },
          "&.MuiInputBase-inputSizeSmall": {
            height: "28px !important",
            padding: "8.5px 14px"
          },
          "&.MuiInputBase-inputAdornedStart": {
            height: "27px !important"
          } 
        },
        multiline: {
          height: "auto",
          marginTop: "1em",
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "6px"
          // color: "rgba(0, 0, 0, 0.4)",
          // opacity: "0.7"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
          }
        },
        input: {
          borderRadius: 15,
          fontSize: "13px",
          padding: "6px 14px",
          height: "1.1876em"
        }
      }
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorAction: {
          color: "#FFA12D"
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "30px",
          "&.Mui-disabled": {
            color: "#6a6a6a !important",
            backgroundColor: "#EBECED !important"
          }
        },
        label: {
          padding: "0 13px",
          display: "flex"
        },
        contained: {
          backgroundColor: "#EBECED",
          padding: "4px 23px"
        },
        containedPrimary: {
          color: "#fff",
          backgroundColor: "#54c0fd"
        },
        containedSecondary: {
          // color: "#A7A7A8"
          color: "#6a6a6a"
        },
        containedSizeSmall: {
          label: {
            fontSize: "13px"
          }
        },
        textSizeLarge: {
          padding: "8px 19px !important"
        },
        containedSizeMedium: {
          padding: "6px 29px !important"
        },
        textSizeSmall: {
          '&:hover': {
            backgroundColor: "rgba(0, 0, 0, 0.04)"
          }
        }       
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: "0px"
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          "& .MuiDialog-paper, & .MuiDialog-root": {
            padding: "0px !important",
            minWidth: "240px !important",
            paddingBottom: "16px !important"
          },
          "& .MuiDatePickerToolbar-root": {
            backgroundColor: "#54c0fd",
            paddingBottom: "24px",
            paddingTop: "24px",
            "& .MuiTypography-overline": {
              color: "#fff",
              opacity: "0.5"
            }
          },
          "& .MuiDatePickerToolbar-title": {
            color: "#fff !important"
          },
          "& .MuiPickerStaticWrapper-root": {
            maxWidth: "310px",
            maxHeight: "425px",
            minWidth: "310px"
          },
          "& .MuiDialogActions-root": {
            marginTop: "4px",
            padding: "6px"
          },
          "& .MuiPickersToolbar-penIconButton": {
            display: "none"
          },
          "& .MuiPickersDay-root.Mui-selected:hover":{
            backgroundColor: "#54c0fd"
          },
          "& .MuiPickersDay-root.Mui-selected":{
            backgroundColor: "#54c0fd !important"
          },
          "& .MuiClockPicker-root": {
            marginTop: "30px",
            marginBottom: "20px"
          },
          "& .MuiTimePickerToolbar-root": {
            backgroundColor: "#54c0fd",
            padding: "24px 24px",
            "& .MuiTypography-overline": {
              color: "#fff",
              opacity: "0.5",
              display: "none"
            }            
          },
          "& .MuiTimePickerToolbar-ampmLabel": {
            color: "#fff",
            opacity: "0.5"
          },
          "& .MuiTimePickerToolbar-ampmLabel.Mui-selected": {
            color: "#fff",
            opacity: "1"
          },
          "& .MuiTimePickerToolbar-separator": {
            margin: "0px 20px 0px 20px"
          },  
          "& .MuiTimePickerToolbar-hourMinuteLabel": {
            paddingBottom: "11px",
            color: "#fff",
            paddingLeft: "40px",
            marginRight: "33px",
            "& .MuiTypography-root": {
              color: "#fff",
              opacity: "0.5",
              fontSize: "32px"
            },
            "& .MuiTypography-root.Mui-selected": {
              color: "#fff",
              opacity: "1",
              fontSize: "32px"
            }
          }
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: "15px",
          "&.MuiDialog-paperWidthSm": {
            padding: "11px",
            fontSize : "0.875rem",
            lineHeight: "1.43",
            letterSpacing: "0.01071em"
          }
        },
        root: {
          "& .MuiPickersModal-dialogRoot": {
            padding: "0px !important"
          }
        },
        paperFullScreen: {
          opacity: "0.98"
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorDefault: {
          backgroundColor: "#fff",
          borderBottom: "1px solid #eee",
          boxShadow: "none"
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 26,
          height: 26
        },
        img: {
          width: 26,
          height: 26,
          objectFit: "scale-down"
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          opacity: "0 !important"
        }
      }
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: "8px"
        }
      }
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          marginTop: "5px !important"
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: "45px !important",
          height: "45px",
          minWidth: "auto !important",
          textTransform: "capitalize !important",
          fontWeight: "400"
        },
        textColorInherit: {
          opacity: "1 !important"
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          flex: "inherit"
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          "& .MuiListItem-button:hover": {
            color: "#54C0FD !important",
            background: "rgba(0, 0, 0, 0.04) !important"
          }
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: "290px !important",
        }
      }
    },
    MuiPaginationItem: {
      styleOverrides: {
        icon: {
          color: "#CFD0D1",
          fontSize: "1.75rem"
        },
        root: {
          height: "26px",
          minWidth: "26px"
        }
      }
    },

    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            backgroundColor: "#54C0FD",
            color: "#fff",
            minHeight: "48px"
            // margin:'10px 0px !important'
          }
        },
        content: {
          "&.Mui-expanded": {
            margin: "0px"
          }
        },
        expandIcon: {
          width: "36px",
          height: "36px"
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "13px",
          "&:hover": {
            color: "#54c0fd !important"
          }
        }
      }
    },

    MuiTabs: {
      styleOverrides:{
        root: {
          height: "48px"
        },
        scroller: {
          MuiInputBase: {
            root: {
              color: "#fff",
              backgroundColor: "#54C0FD"
            }
          },
          "&$selected": {
            color: "#fff",
            backgroundColor: "red"
          },
          "&.Mui-selected": {
            background: "black",
            color: "#fff",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px"
          }
        },
        indicator: {
          backgroundColor: "#54C0FD",
          height: "1px"
        }
      }
    },

    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0px 0px 4px #0000000F !important"
        },
        root: {
          "&.MuiAccordion-root:before": {
            backgroundColor: "rgba(0, 0, 0, 0) !important"
          }
          // opacity: '0.95 !important'
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          cursor: "pointer",
          textDecoration: "none !important"
        }
      }
    },
    MuiCheckbox: {
      styleOverrides:{
        root: {
          padding: "6px",
  
          "& .MuiSvgIcon-root": {
            width: " 0.9em !important",
            height: "0.9em !important"
          }
        }
      }
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: "13px"
        },
        select: {
          "&.MuiOutlinedInput-input, &.MuiSelect-select, &.MuiSelect-outlined, &.MuiOutlinedInput-input, &.MuiInputBase-input": {
          height: "2.2em",
          padding: "8.5px 14px",
          paddingRight: "33px",
          },
          "&:focus": {
            backgroundColor: "transparent"
          }
        },
        icon: {
          top: "auto !important"
        },
        selectMenu: {
          fontSize: "13px !important"
        }
      }
    },
    MuiCircularProgress : {
      styleOverrides: {
        colorPrimary: {
          // color: "#54C0FD !important"
        }
      }
    },
    MuiStepConnector : {
      styleOverrides: {
        vertical: {
          paddingBottom: "10px !important",
          paddingTop: "10px !important"
        }
      }
    }
  }

});

export default theme;
