import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All category Service Functions
 */
export const categoryService = {
  getCategory,
  getQuestions,
  search
};

/**
 * Call getCategory Service
 */
async function getCategory() {
  const response = await apihelper.getRequest("category");
  return response;
}

/**
 * Call getQuestions Service
 */
async function getQuestions(id, category) {
  const response = await apihelper.getRequest("category/"+`${id}/${category}`);
  return response;
}

/**
 * Call searchAll Service
 */
async function search(text, category) {
  const response = await apihelper.getRequest(`category/search/?text=${text}&category=${category}`);
  return response;
};