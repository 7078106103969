const MultiDatePickerComponentStyle = (theme) => ({
  button: {
    padding: "2px",
    marginBottom: "4px",
    marginTop: "8px",
    backgroundColor: "#fff"
  }
});

export default MultiDatePickerComponentStyle;
