import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "./ReportsStyles";

// Import Constants
import { surveyTypes } from "../../config/constants"

// Import Support Containers
import Filter from "../Filter/Filter"
import ME_TopWidgets from './Dashboard/ME_TopWidgets'
import TopWidgets from './Dashboard/TopWidgets'
import MEFavorableWidget from './Dashboard/Widgets/MEFavourableWidget'
import BottomWidgets from './Dashboard/BottomWidgets'
import ME_BottomWidgets from './Dashboard/ME_BottomWidgets'
import DlaRole from "./DlaRole"

//Import Actions
import { dashboardAction, adminsAction } from '../../redux/actions'

function Dashboard(props) {

    // Get props
    const { classes, match, location } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Redux Selectors
    const preference = useSelector(state => state?.admin?.preference);
    const { queryDLA } = useSelector(state => state.profile.user)
    const { dashboard: { dashboardData } } = useSelector(state => state)
    const { filterData } = useSelector(state => state.filter)
    const { overAllDashboard } = useSelector((state) => state.dashboard.initialReload);

    const MEtype = filterData.surveys.length > 0 && filterData.surveys.filter((val) => val.type === surveyTypes.MULTI_RATER_FEEDBACK)

    const callDashboardApi = () => {
        dispatch(dashboardAction.getDashboardData(() => { }, 'count'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'rollingParticipant'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'questions'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'favourableCategory'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'overalleNPS'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToStay'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'likelyToReturn'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'turnOver'))
        dispatch(dashboardAction.getDashboardData(() => { }, 'engagementBenchmark'))

        dispatch(dashboardAction.getParticipantInvite(false))
        dispatch(dashboardAction.getDashboardActionData(() => { }, false))
        if (!queryDLA || queryDLA === "") {
            dispatch(dashboardAction.getKeyDriverData(() => { }, false))
        }
    }

    // Load Dashboard Data
    useEffect(() => {
        if (match.path === "/reports" && !overAllDashboard) {
            callDashboardApi();
        }
    }, []);

    // Object to use current admin Prefence and update the dashboard hide widget in preference
    const adminPreference = {
        data: preference,
        updatePreference: (preference) => {
            dispatch(adminsAction.updateDashWidgetPreference(preference));
        }
    }

    return (
        <Grid container>
            <Grid container spacing={3} alignItems="stretch" className={`mb8 ${classes.execDashContainer}`}>
                {queryDLA && queryDLA !== " " &&
                    <DlaRole />
                }
                {MEtype.length > 0 && dashboardData.totalReviewItems !== 0 ? <ME_TopWidgets /> : <TopWidgets adminPreference={adminPreference} />}
            </Grid>

            <Grid container alignItems="stretch" spacing={3} className="mb-2">
                <Grid item style={{ flexGrow: "4" }}>
                    <Grid container alignItems="stretch" style={{ width: "100%", height: "100%" }}>
                        {MEtype.length > 0 && dashboardData.totalReviewItems !== 0 ? <ME_BottomWidgets /> : <BottomWidgets adminPreference={adminPreference} />}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
// default props
Dashboard.defaultProps = {
    classes: {},
    dashboardData: {
        comments: 0,
        overallFavorable: "0.00",
        questions: [],
        saoa: [],
        totalresponse: 0,
        totalInvite: 0
    }
};

// prop types
Dashboard.propTypes = {
    classes: PropTypes.object,
    dashboardData: PropTypes.object,
};
export default withStyles(ReportsStyles)(Dashboard);