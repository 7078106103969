const DemographicsStyles = () => ({
  customName:{
    "& .MuiOutlinedInput-inputMarginDense":{
      fontSize: '14px',
    }
  }
});


export default DemographicsStyles;
