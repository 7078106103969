export const fieldDataTypes = {
  INTEGER: "integer",
  DATE: "date",
  STRING: "string"
};

export const operators = [
  // { name: "null", label: "is null", operator: "$eq", type: ["string", "number"] },
  // { name: "notNull", label: "is not null", operator: "$ne", type: ["string", "number"] },
  { name: "", label: "Select Operator", operator: "", type: [] },
  { name: "in", label: "includes", operator: "$in", type: [fieldDataTypes.INTEGER, fieldDataTypes.STRING, null] },
  { name: "notIn", label: "does not include", operator: "$nin", type: [fieldDataTypes.INTEGER, fieldDataTypes.STRING, null] },
  // { name: "=", label: "equal", operator: "$eq", type: [fieldDataTypes.INTEGER, fieldDataTypes.STRING, fieldDataTypes.DATE] },
  // { name: "!=", label: "not equal", operator: "$ne", type: [fieldDataTypes.INTEGER, fieldDataTypes.STRING, fieldDataTypes.DATE] },
  // { name: "<", label: "less than", operator: "$lt", type: [fieldDataTypes.DATE, fieldDataTypes.INTEGER] },
  // { name: ">", label: "greater than", operator: "$gt", type: [fieldDataTypes.DATE, fieldDataTypes.INTEGER] },
  { name: "<=", label: "less than or equal to", operator: "$lte", type: [fieldDataTypes.DATE, fieldDataTypes.INTEGER] },
  { name: ">=", label: "greater than or equal to", operator: "$gte", type: [fieldDataTypes.DATE, fieldDataTypes.INTEGER] },
  { name: "between", label: "between", operator: "between", type: [fieldDataTypes.DATE, fieldDataTypes.INTEGER] }
  // { name: "contains", label: "contains" },
  //   { name: "beginsWith", label: "begins with" },
  //   { name: "endsWith", label: "ends with" },
  //   { name: "doesNotContain", label: "does not contain" },
  //   { name: "doesNotBeginWith", label: "does not begin with" },
  //   { name: "doesNotEndWith", label: "does not end with" }
];

export const operatorsMap = operators.reduce((obj, item) => {
  let d = {};
  if (item.name) {
    d = { [item.name]: item.operator };
  }
  return {
    ...obj,
    ...d
  };
}, {});

// for reverse conversion from operator to label name
export const operatorsNameMap = operators.reduce((obj, item) => {
  let d = {};
  if (item.name) {
    d = { [item.operator]: item.name };
  }
  return {
    ...obj,
    ...d
  };
}, {});
