export const palette = {
    primary: {
      main: "#54C0FD",
      fontFamily: "Montserrat",
      contrastText: "#fff",
      color: "#fff",
      dark: "rgb(58, 134, 177)"
    },
    secondary: {
      main: "#EBECED",
    },
    error: {
      main: "#c62828",
      light: "#c62828",
      dark: "#c62828",
    }
  }