import { combineReducers } from "redux";

// Import Reducers
import alertReducer from "./alertReducer";
import uiReducer from "./uiReducer";
import dragdropReducer from "./dragdropReducer";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import surveryReducer from "./surveryReducer";
import surveyPreviewReducer from "./surveyPreviewReducer";
import baseQuestionsReducer from "./baseQuestionsReducer";
import clientReducer from "./clientReducer";
import categoryReducer from "./categoryReducer";
import formStepperReducer from "./formStepperReducer";
import peopleReducer from "./peopleReducer";
import queryBuilderReducer from "./queryBuilderReducer";
import participantReducer from "./participantReducer";
import adminReducer from "./adminReducer";
import excelReducer from "./excelReducer";
import metadataReducer from "./metadataReducer";
import takeSurveyReducer from "./takeSurveyReducer";
import filterReducer from "./filterReducer";
import dashboardReducer from "./dashboardReducer";
import actionsReducer from "./actionsReducer";
import spCallerReducer from "./spCallerReducer";
import opportunityReducer from "./opportunityAreasReducer";
import reportReducer from "./reportReducer";
import pdfReducer from "./pdfReducer";
import fileManager from "./fileManager";
import contentLibrary from "./contentLibraryReducer"
import managerEffectiveness from "./meReducer";
import meTakeSurveyReducer from "./meTakeSurveyReducer";
import externalApiReducer from "./externalApiReducer";
import apiIntegrationReducer from "./apiIntegrationReducer";
import { internalAPIConstants } from "../../config/constants";


export default combineReducers({
  alert: alertReducer,
  dragdrop: dragdropReducer,
  UI: uiReducer,
  auth: authReducer,
  profile: profileReducer,
  admin: adminReducer,
  survey: surveryReducer,
  surveyPreview: surveyPreviewReducer,
  baseQuestions: baseQuestionsReducer,
  client: clientReducer,
  category: categoryReducer,
  contentLibrary: contentLibrary,
  externalApiConfig: externalApiReducer,
  formStepper: formStepperReducer,
  people: peopleReducer,
  queryBuilder: queryBuilderReducer,
  participant: participantReducer,
  excel: excelReducer,
  metadata: metadataReducer,
  takeSurvey: takeSurveyReducer,
  meTakeSurvey: meTakeSurveyReducer,
  filter: filterReducer,
  dashboard: dashboardReducer,
  actionplan: actionsReducer,
  spCaller: spCallerReducer,
  opportunity: opportunityReducer,
  report: reportReducer,
  pdf: pdfReducer,
  fileManager: fileManager,
  managerEffectiveness: managerEffectiveness,
  apiIntegration: apiIntegrationReducer
});
