import React from "react";
import PropTypes from "prop-types";

import { Box, Grid, IconButton} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import CircularProgress from "@mui/material/CircularProgress";

import _ from "lodash";

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

//Import Constants
import { dashboardConstants, ReportsConstants } from "../../../../config/constants";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

function FavorableWidget(props) {
  // props
  const { classes, value, type, hasMESurvey, adminPreference } = props;
  const { positivepercentfavorable, negativepercentfavorable, neturalpercentfavorable, overallFavorable } = value
  let values = [];

  if (!hasMESurvey) {
    values.push({
      label: "Overall Favorability",
      value: value,
    });
  } else {
    if (typeof overallFavorable == 'object') {
      Object.keys(overallFavorable).map((k) => {
        values.push({
          ...overallFavorable[k],
          label: k === "_" ? "Overall Favorability" : k,
          value: overallFavorable[k].percentfavorable || 0,
        });
      });
      values = _.sortBy(values, "relationship_order");
      values = values.filter((val) => val.percentfavorable !== 0);
    }
  }

  const handleClose = () => {
    if (typeof adminPreference?.data?.hideDashWidgets?.overallFavorability === 'boolean') {
      adminPreference['data']['hideDashWidgets']['overallFavorability'] = true;
      adminPreference.updatePreference(adminPreference?.data)
    }
  }

  const getMEGrid = (v, wIndex, isLeft) => {
    return (
      <Grid
        className={
          `${classes.overallChart}
                width100 height100 ${hasMESurvey ? `me me_${isLeft ? 0 : wIndex + 1}` : ""}`
        }
        container
        direction="column"
      >
        {
          isLeft && (
            <Grid container alignItems="center" justifyContent="center">
              <TypographyComponent variant="h4" className="txtBold fontSize16">
                {v.label}
              </TypographyComponent>
            </Grid>
          )
        }
        <Grid item className="marginAuto p5">
          <Grid style={{ position: "relative", display: "flex" }}>
            <CircularProgress
              className={classes.circularBar}
              variant="determinate"
              value={v.value}
              size={hasMESurvey && isLeft ? 120 : 90}
            />
            <CircularProgress
              className={classes.circularBarValue}
              variant="determinate"
              value={100}
              size={hasMESurvey && isLeft ? 120 : 90}
            />
            <TypographyComponent variant="h4" className={`${classes.circularvalue} txtBold fontSize22`}>
              {`${Math.round(
                v.value,
              )}%`}</TypographyComponent>
          </Grid>
        </Grid>
        {!isLeft && <Grid container alignItems="center" justifyContent="center">
          <TypographyComponent
            variant="h4"
            className={`txtBold fontSize16 ${classes.overall_text} ${hasMESurvey ? `me` : ""}`}
          >
            {v.label}
          </TypographyComponent>
        </Grid>}
      </Grid>
    );
  }


  const getGrid = (v, wIndex, isLeft) => {
    return (
      <Grid
        className={`${classes.overallChart}
                    width100 height100 ${hasMESurvey ? `me me_${isLeft ? 0 : wIndex + 1}` : ""
          }`}
        container
        direction="column"
      >
        {isLeft && (
          <Grid container className='dflexCenter width100 spacebetween'>
            <TypographyComponent variant='h5' className={'fontWeight600 txtLeft'}>
                {v.label}
              </TypographyComponent>
            <ToolTipComponent title={ReportsConstants.CLOSE}>
              <IconButton onClick={handleClose} size="large">
                {DashboardClose()}
              </IconButton>
            </ToolTipComponent>
          </Grid>
        )}
        <Grid
          item
          className={`mt-4 ${classes.overallFavorabilityContainer}`}
        >
          <Grid
            position={'relative'} display={'flex'} flexWrap={'wrap'} flexDirection={'row'}
            className="mb-15"
          >
            <CircularProgress
              className={classes.circularBarorange}
              variant="determinate"
              value={100}
              size={hasMESurvey && isLeft ? 120 : 160}
              thickness={5}
            />
            <CircularProgress
              className={classes.circularBar}
              variant="determinate"
              value={positivepercentfavorable + neturalpercentfavorable}
              size={hasMESurvey && isLeft ? 120 : 160}
              thickness={5}
            />
            <CircularProgress
              className={classes.circularBarValue}
              variant="determinate"
              value={positivepercentfavorable}
              size={hasMESurvey && isLeft ? 120 : 160}
              thickness={5}
            />
            <TypographyComponent
              variant="h5"
              className={`${classes.circularvalue} fontWeightBold fontSize30`}
            >
              {`${Math.round(positivepercentfavorable)}%`}
            </TypographyComponent>
          </Grid>
          <Grid className="dflex" flexDirection={'column'} gap={'12px'}>
            <Grid style={{ display: "grid", alignItems: "center", width: "min-content", gridTemplateColumns: "20px 50px 1fr", gap: "8px" }}>
              <Grid
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  background: "#54C0FD",
                  margin: "7px",
                }}
              ></Grid>
              <TypographyComponent variant="h6" className="fontSize16 fontWeight600 txt-right pr5">
                {`${positivepercentfavorable}%`}
              </TypographyComponent>
              <TypographyComponent variant="p" className="fontSize14" color="#B1B2B3">
                {dashboardConstants.OVERALL_FAVORABILITY.POSITIVE}
              </TypographyComponent>
            </Grid>
            <Grid style={{ display: "grid", alignItems: "center", width: "min-content", gridTemplateColumns: "20px 50px 1fr", gap: "8px" }}>
              <Grid
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  background: "#B1B2B3",
                  margin: "7px",
                }}
              ></Grid>
              <TypographyComponent variant="h6" className="fontSize16 fontWeight600 txt-right pr5">
                {`${neturalpercentfavorable}%`}
              </TypographyComponent>
              <TypographyComponent variant="p" className="fontSize14" color="#B1B2B3">
                {dashboardConstants.OVERALL_FAVORABILITY.NEUTRAL}
              </TypographyComponent>
            </Grid>
            <Grid style={{ display: "grid", alignItems: "center", width: "min-content", gridTemplateColumns: "20px 50px 1fr", gap: "8px" }}>
              <Grid
                style={{
                  width: "10px",
                  height: "10px",
                  borderRadius: "50%",
                  background: "#FFAE4B",
                  margin: "7px",
                }}
              ></Grid>
              <TypographyComponent variant="h6" className="fontSize16 fontWeight600 txt-right pr5">
                {`${negativepercentfavorable}%`}
              </TypographyComponent>
              <TypographyComponent variant="p" className="fontSize14" color="#B1B2B3">
                {dashboardConstants.OVERALL_FAVORABILITY.NEGATIVE}
              </TypographyComponent>
            </Grid>
          </Grid>
        </Grid>
        {!isLeft && (
          <Grid container alignItems="center" justifyContent="center">
            <TypographyComponent
              variant="h4"
              className={`txtBold fontSize16 ${classes.overall_text} ${hasMESurvey ? `me` : ""
                }`}
            >
              {v.label}
            </TypographyComponent>
          </Grid>
        )}
      </Grid>
    );
  };

  const overall = values.splice(0, 1);

  return type === "large" ? (
    <Grid className={`${classes.dashboardTile} ${hasMESurvey ? "me" : "ss"}`}>
      {hasMESurvey ? (
        <>
          <div className="overall-left-wrapper">
            <div className="overall-left">
              {overall.map((v, wIndex) => getMEGrid(v, wIndex, true))}
            </div>
          </div>
          <div className="overall-right-wrapper">
            <div className="overall-right">
              {values.map((v, wIndex) => getMEGrid(v, wIndex, false))}
            </div>
          </div>
        </>
      ) : (
        getGrid(overall[0], 0, true)
      )}
    </Grid>
  ) : (
    <Grid container>
      {/* <Grid item className={`${classes.dashboard_Tile2}`}> */}
      <Grid container alignItems="center">
        <Grid item className='dflexCenter width100 spacebetween'>
          <TypographyComponent variant="h5" className="txtBold pl-1">
            {ReportsConstants.OVERALL_FAVORABLITYl}
          </TypographyComponent>
          <ToolTipComponent title={ReportsConstants.CLOSE}>
            <IconButton onClick={() => {}} size="large">
              {DashboardClose()}
            </IconButton>
          </ToolTipComponent>
        </Grid>
        <Grid item className="marginAuto p5">
          <Grid  position={'relative'} display={'flex'} flexWrap={'wrap'}>
            <CircularProgress
              className={classes.circularBar}
              variant="determinate"
              value={value}
              size={60}
            />
            <CircularProgress
              className={classes.circularBarValue}
              variant="determinate"
              value={100}
              size={60}
            />
            <TypographyComponent
              variant="h4"
              className={`${classes.circularvalue} txtBold`}
            >
              {`${Math.round(value)}%`}
            </TypographyComponent>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
}
// default props
FavorableWidget.defaultProps = {
  classes: {},
  value: "",
  type: "",
  hasMESurvey: false,
  adminPreference: {},
};

// prop types
FavorableWidget.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string,
  type: PropTypes.string,
  adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(FavorableWidget);
