import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import arrayMove from "array-move";
import {
  SortableContainer,
  SortableElement
} from "react-sortable-hoc";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

// import components
import CategoryDescription from "./CategoryDescription";
import RenderQuestions from "./RenderQuestions";

// import components
import TabPanelComponent from "../../../../components/TabPanelComponent/TabPanelComponent";

// import Constants
import { surveyConstant } from "../../../../redux/constants";
import { surveyQuestionsConstants } from "../../../../config/constants";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";


/**
   * Sortable Item
   */
const SortableItem = SortableElement(props => {
  return (
    props.children
  );
});

/**
 * Sortable List
 */
const SortableList = SortableContainer(props => {
  const { langCode, catOrder, questionData, classes, activeCategoryTab, questions, ind, queryBuilderData, translatedFields, errors } = props;
  return (
    <div className={"relative"}>
      {
        questions.filter((question) => question.category === catOrder[ind])
          .map((item, index) => (
            <SortableItem
              key={`item-${index}`}
              index={index}
            >
              <div key={`div-${index}`}
                index={index}>
                <RenderQuestions
                  classes={classes}
                  questionData={questionData}
                  activeCategoryTab={activeCategoryTab}
                  langCode={langCode}
                  item={item}
                  indx={index}
                  queryBuilderData={queryBuilderData}
                  translatedFields={translatedFields}
                  errors={errors}
                />
              </div>
            </SortableItem>
          ))
      }
    </div>
  );
});

function QuestionsPanel (props) {
  // Get Props Values
  const { classes, questionData, activeCategoryTab, langCode, translatedFields, isMESurvey, } = props;
  const { questions, catOrder } = questionData;

  // Define Dispatch
  const dispatch = useDispatch();

  /**
    * Handle Sort End Event
    * @param {*} e
    */
  const onSortEnd = (e) => {
    const { oldIndex, newIndex } = e;
    const categoryReorderQuestions = questions.filter((ques) => ques.category === catOrder[activeCategoryTab]);
    const notReorderCategoryQuestions = questions.filter((ques) => ques.category !== catOrder[activeCategoryTab]);
    const orderQuestions = arrayMove(categoryReorderQuestions, oldIndex, newIndex);
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_QUESTIONS_QUESTIONS_REDUCER_UPDATE, [...notReorderCategoryQuestions, ...orderQuestions]));
  };

  return (
    <React.Fragment>

      {catOrder.length > 0 && catOrder.map((item, ind) => (
        <TabPanelComponent value={activeCategoryTab} key={ind} index={ind}>
          <Grid className={"bgwhite pt5 pb-2 pl-2 pr-2"}>
            <CategoryDescription
              classes={classes}
              activeCategoryTab={activeCategoryTab}
              langCode={langCode}
              item={item}
              questionData={questionData}
              translatedFields={translatedFields}
            />
            <SortableList
              axis="xy"
              useDragHandle
              onSortEnd={onSortEnd}
              ind={ind}
              catOrder={catOrder}
              langCode={langCode}
              questionData={questionData}
              classes={classes}
              activeCategoryTab={activeCategoryTab}
              questions={questions}
              {...props}
            />
          </Grid>
        </TabPanelComponent>
      ))}
      {catOrder.length === 0 &&
        <Grid container alignItems="center" justifyContent="center" className="height100">
          <TypographyComponent variant={'h2'} className="txtgrey txtcenter p-5">{`${isMESurvey ? isMESurvey==="Multi-rater feedback"?"Select a Competency to drag and drop or create a new Competency to begin adding individual questions.":"Select a Category to drag and drop or create a new category to begin adding individual questions." : ""}`}</TypographyComponent>
        </Grid>

      }
    </React.Fragment>
  );
}

// default props
QuestionsPanel.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  requiredQues: {},
  queryBuilderData: {},
  isMESurvey: false,
};

// prop types
QuestionsPanel.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  langCode: PropTypes.string,
  requiredQues: PropTypes.object,
  updateReqQuestions: PropTypes.func,
  queryBuilderData: PropTypes.object,
  isMESurvey: PropTypes.boolean,
};

export default QuestionsPanel;
