import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "../SurveyPreviewStyles";

//Import Loading Container
import LoadingComponent from "../../../PageSetupPreference/PageSetUpPreference";

function PreviewLoadingPage(props) {
  const { classes } = props;
  return (
    <>
      <Grid container className={classes.previewLoadingRoot}>
        <Grid item sm={12}>
          <Box
            display="flex"
            p={0}
            flexWrap="nowrap"
            flexDirection="column"
            justifyContent="center"
            className={classes.PreviewLoadingPage}
          >
            <LoadingComponent />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

// default props
PreviewLoadingPage.defaultProps = {
  classes: {},
};

// prop types
PreviewLoadingPage.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(PreviewLoadingPage);
