import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles and Image
import { DialogueClose } from "../../../../assets/images/svgComponents/ImageComponent";

import ReportsStyles from "../../ReportsStyles";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import CreateReportStepper from "./CreateReportStepper";

// import constants
import { ReportsConstants } from "../../../../config/constants";

function CreateReportPopup(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // report form ref
  const report_form_ref = useRef();

  // get props
  const { classes, closePopup, isSaveReport, title } = props;

  //get reducer data
  const { reportData, commentReport } = useSelector((state) => state.report);
  const { emailIds } = reportData;
  const { preference } = useSelector((state) => state.admin);

  useEffect(()=>{
    if(!isSaveReport){
      reportData.surveys=preference.surveys;
      reportData.date=preference.date;
      reportData.demographics=preference.demographics;
    }
  },[dispatch])

  return (
    <React.Fragment>
      <Grid className="centercontainer bgwhite mt-1">
        <Grid container>
          <Grid item xs={12} className={classes.dialog_close}>
            <Button variant="text" size={"small"} onClick={closePopup} style={{ padding: "2px 16px" }}>
              {DialogueClose()}
              <TypographyComponent variant="h5" className="pl5">
                Close
              </TypographyComponent>
            </Button>
          </Grid>

          <Grid item xs={12} className="mb5">
            <TypographyComponent variant="h4" className="txtSemiBold fontSize20">
              {title}
            </TypographyComponent>
          </Grid>
          <Grid item xs={12} className="mb-5">
            <TypographyComponent variant="h6">
              {ReportsConstants.CREATEA_REPORT_TEXT}
            </TypographyComponent>
          </Grid>
          <ValidatorForm
            id="reportForm"
            name="reportForm"
            // useref="reportForm"
            ref={report_form_ref}
            className={"width100"}
            autoComplete="off"
            // onSubmit={handleSubmit}
            onError={(error) => {
              console.log(error);
            }}
          >
            <CreateReportStepper
              closePopup={closePopup}
              reportData={reportData}
              commentReport={commentReport}
              isSaveReport={isSaveReport}
              reportForm={report_form_ref}
              emailIds={emailIds}
            />

          </ValidatorForm>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
// default props
CreateReportPopup.defaultProps = {
  classes: {},
  handleCancel: () => {},
};

// prop types
CreateReportPopup.propTypes = {
  classes: PropTypes.object,
  handleCancel: PropTypes.func,
};
export default withStyles(ReportsStyles)(CreateReportPopup);