import { alertConstant, spCallerConstant, uiConstant } from "../constants";
import { spCallerService } from "../services";
import { baseAction } from "./baseAction";
import { surveyAction } from "./index";
import history from "../../config/history";
import { isNineToNine } from "../../helpers"

const { request, success, failure } = baseAction;

// Export All Sp caller Actions Functions
export const spCallerAction = {
  getSurveyQueue,
  getCallerQueue,
  getCallerDetail,
  updateCallerDetail,
  addHistoryToCallerDetail
};

/**
 * Get All Survey Queue
 */
function getSurveyQueue(skip, limit, sortBy, sort, sFields, sValues, 
  updatedVisibleColumns = [], locationState = {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(spCallerConstant.GET_SURVEY_QUEUE_LIST_REQUEST));
    spCallerService.getSurveyQueue(skip, limit, sortBy, sort, sFields, sValues, updatedVisibleColumns).then(
      (data) => {
        dispatch(success(spCallerConstant.GET_SURVEY_QUEUE_LIST_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        // if 9 to 9
        if (isNineToNine() && locationState.defaultCall) {
          history.push({
            pathname: '/spcaller/caller-queue/nine-to-nine',
            state: {
              from: 'nine-to-nine',
              defaultCall: locationState.defaultCall
            }
          });
        }
      },
      (error) => {
        dispatch(failure(spCallerConstant.GET_SURVEY_QUEUE_LIST_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get All Caller Queue
 */
function getCallerQueue(skip, limit, sortBy, sort, sFields, sValues, 
  survey_id = null, formData, callback = null) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(spCallerConstant.GET_CALLER_QUEUE_LIST_REQUEST));
    spCallerService.getCallerQueue(skip, limit, sortBy, sort, sFields, sValues, 
      survey_id, formData).then(
      (data) => {
          dispatch(request(uiConstant.LOADING_FALSE));
          if (!formData.getRecordsCount) {
            dispatch(success(spCallerConstant.GET_CALLER_QUEUE_LIST_SUCCESS, data));
            if (data.data && data.data.length > 0 && formData.defaultCall) {
              // if 9 to 9 move to detail page
              if (isNineToNine()) {
                history.push({
                  pathname: `/spcaller/caller-detail/${data.data[0]._id}/${data.data[0].spcaller._id}`,
                  state: {
                    ...formData,
                    from: formData.from,
                    defaultCall: formData.defaultCall
                  }
                });
              }
            }
          } else {
            // callback with records count data
            if (data.data && callback) {
              callback(data.data)
            }
          }
      },
      (error) => {
        dispatch(failure(spCallerConstant.GET_SURVEY_QUEUE_LIST_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get Caller Detail
 */
function getCallerDetail(spcaller_id = null, callerQueueIds = [], formData = { from: "", isSearch: "" }, getDataOnly = false) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(spCallerConstant.GET_CALLER_DETAIL_REQUEST));
    spCallerService.getCallerDetail(spcaller_id, { ...formData, getDataOnly }).then(
      (data) => {
        if (data.data.lockedStatus && !getDataOnly) {
          // if all list completed
          const currentCallerIndex = callerQueueIds.indexOf(spcaller_id);
          const nextCallerId = callerQueueIds[currentCallerIndex + 1];
          if (nextCallerId) {
            // add next status
            data.data.nexCallerId = nextCallerId;
            dispatch(getCallerDetail(nextCallerId, callerQueueIds, formData, getDataOnly));
            dispatch(request(uiConstant.LOADING_FALSE));
          } else {
            // add empty status
            data.data.callerEmpty = true;
            dispatch(success(spCallerConstant.GET_CALLER_DETAIL_SUCCESS, data));
            dispatch(request(uiConstant.LOADING_FALSE));
          }
        } else {
          dispatch(success(spCallerConstant.GET_CALLER_DETAIL_SUCCESS, data));
          dispatch(request(uiConstant.LOADING_FALSE));
        }
      },
      (error) => {
        dispatch(failure(spCallerConstant.GET_CALLER_DETAIL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update caller detail (particiapnt detail)
 * form data contains phone, altPhone, email, languages to update in participant collection
 */
function updateCallerDetail(spcaller_id = null, formData = {}, callerQueueIds = []) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(spCallerConstant.UPDATE_CALLER_DETAIL_REQUEST));
    spCallerService.updateCallerDetail(spcaller_id, formData).then(
      (data) => {
        dispatch(success(spCallerConstant.UPDATE_CALLER_DETAIL_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        // dispatch(success(alertConstant.ALERT_SUCCESS,data.message));
        if (formData.callNext) {
          const currentCallerIndex = callerQueueIds.indexOf(spcaller_id);
          const nextCallerId = callerQueueIds[currentCallerIndex + 1];
          if (nextCallerId) {
            dispatch(getCallerDetail(nextCallerId, callerQueueIds, formData))
          } else {
            // add empty status
            const emptyCaller = { data: { callerEmpty: true, survey_id: formData.survey_id } }
            dispatch(success(spCallerConstant.GET_CALLER_DETAIL_SUCCESS, emptyCaller));
          }
        } else if (formData.sendEmail) {
          // update data and send email
          dispatch(surveyAction.sendSurveyMail({ ...formData.sendEmail }));
        }
      },
      (error) => {
        dispatch(failure(spCallerConstant.UPDATE_CALLER_DETAIL_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Add history to caller detail call history
 * form data contains contains new history data to push in spcaller collection
 */
function addHistoryToCallerDetail(spcaller_id = null, formData = {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(spCallerConstant.ADD_HISTORY_TO_CALLER_DETAIL_REQUEST));
    spCallerService.addHistoryToCallerDetail(spcaller_id, formData).then(
      (data) => {
        dispatch(success(spCallerConstant.ADD_HISTORY_TO_CALLER_DETAIL_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        // dispatch(success(alertConstant.ALERT_SUCCESS,data.message));
      },
      (error) => {
        dispatch({
          type: spCallerConstant.ADD_HISTORY_TO_CALLER_DETAIL_FAILURE,
          data: formData
        });
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}