/* eslint-disable react/jsx-key */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';

// Import Styles and Image
import { DialogueClose, DrawerClose } from "../../assets/images/svgComponents/ImageComponent";

import ActionsStyles from "./ActionsStyles";

// Import Component
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import DatePickerComponent from "../../components/DatePickerComponent/CustomDatePicker";
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";
import ToolTipComponent from '../../components/ToolTipComponent/ToolTipComponent';


// Import Constants
import { ActionConstants, errorsConstants, surveyTypes } from "../../config/constants";
import { actionsConstant, opportunityAreas } from "../../redux/constants";

// Import Actions
import { actionsAction, updateStateReducerAction, alertAction } from "../../redux/actions";

// Import Helpers
import { getReducerUpdatedData, updateLinksInHTML, dateFormatter } from "../../helpers";

// Import History
import history from "../../config/history";

function EditActionPlan(props) {
  const [open, setOpen] = React.useState(true);

  /**Close the pop up*/
  const handleClose = () => {
    setOpen(false);
    dispatch({
      type: opportunityAreas.TOGGLE_ADD_PLAN_DIALOG,
      data: false
    })
    history.replace("/actions?refresh=true&tab=1");
  };

  // Define Dispatch
  const dispatch = useDispatch();

  // Get props
  const { classes, match } = props;
  const { params } = match;

  // Get Particular Plan from store
  let actionplan = useSelector(state => state.actionplan.getOne);
  let user = useSelector(state => state.profile.user);
  const { metadata } = useSelector(state => state.metadata)

  // convert - to / in selectedsurveyDate endDate
  let upDate = actionplan.selectedSurveyDates

  actionplan['demographicFilters'] = ""
  let filterFields = `Filters applied: ${actionplan.surveysAssociated} | ${upDate}`

  if (actionplan.selectedDemographics) {
    Object.keys(actionplan.selectedDemographics).map((key) => {
      let metaVal = metadata.filter(obj => obj.key === key)
      actionplan['demographicFilters'] += `${metaVal[0] ? metaVal[0].value : key}: ${actionplan.selectedDemographics[key].rgItemArray}; `
    })
    filterFields += ` | ${actionplan['demographicFilters']}`
  }

  //To give access to users
  let disable;
  if ((actionplan.admin_id == user._id) || user.role == 'superadmin') {
    disable = false
  } else {
    disable = true
  }

  /**
    * Action Dispatching to Particular Action Plan
    */
  useEffect(() => {
    dispatch(actionsAction.getOne(params.id));
  }, []);

  // convert date
  const convertDate = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    const finaldate = `${month}/${dt}/${year}`;
    return finaldate === "01/01/1970" ? "" : finaldate;
  };

  /**
     * Handle Form Submit
     */
  function handleSubmit(e) {
    let actionItemCheck = actionplan.actionItems.filter(data => data !== null && data !== "" && data.trim() !== "")
    if (actionItemCheck.length === 0) {
      dispatch(alertAction.error("Actions cannot be empty"));
    }
    else {
      e.preventDefault();
      const action_startDate = convertDate(actionplan.startDate)
      const action_endDate = convertDate(actionplan.dueDate)
      actionplan["startDate"] = dateFormatter(action_startDate)
      actionplan["dueDate"] = dateFormatter(action_endDate)
      dispatch(actionsAction.update(actionplan));
    }
  }

  /**
    * Handle Add aciton Items
    */
  function handleAddactionItems(e) {
    let { actionItems } = actionplan;
    actionplan.actionItems = [...actionItems, ""];
    e.preventDefault();
    handleChange({ name: "actionItems", value: actionplan.actionItems });
  }

  /**
     * Date Validation
     */
  useEffect(() => {
    ValidatorForm.addValidationRule("isEnddategreater", (value) => {
      if (new Date(actionplan.dueDate) >= new Date(actionplan.startDate)) {
        return true;
      } else {
        return false;
      }
    });
  });

  /**
   * Handle form change
   */
  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, actionplan);
    dispatch(updateStateReducerAction.update(actionsConstant.ACTION_PLAN_GET_ONE_UPDATE, reducerData));
  };

  return (
    <React.Fragment>
      <ValidatorForm
        id="actionEditForm"
        name="actionEditForm"
        useref="actionEditForm"
        autoComplete="off"
        onSubmit={handleSubmit}
        onError={(errors) => console.log(errors)}
      >
        <Dialog fullScreen open={open} onClose={handleClose}>
          <Grid className="centercontainer">
            <Grid container justifyContent="flex-end" className="mt-1">
              <Button variant="text" size={"small"} onClick={handleClose} className="mr-5">
                {DialogueClose()}
                <TypographyComponent variant="h5" className="pl5">Close</TypographyComponent>
              </Button>
            </Grid>
            <Grid container spacing={3}>
              <Grid item sm={12}>
                <TypographyComponent variant="h4" className="mb5 fontWeightBold">
                  {ActionConstants.EDIT_PLAN}
                </TypographyComponent>
                <TypographyComponent variant="h6"  className="font-italic">
                  {ActionConstants.ADD_PLAN_TEXT}
                </TypographyComponent>
                <hr className="horizontal-line" />
              </Grid>
              <Grid item sm={12} marginTop={'-15px'}>
                <Grid container>
                  <Grid item sm={12} display={"flex"}>
                    <TypographyComponent sm={4} variant="h6" className="fontWeightBold">
                      {ActionConstants.SURVEY_NAME}:
                    </TypographyComponent>
                    <Grid container marginLeft={1} sm={8} className={"divWidth"}>
                      <TypographyComponent
                      variant="h6"
                        id={"actionplan_surveyname"}
                        className="height36"
                        name={"surveysAssociated"}
                        disabled={true}
                        handleChange={(e) => handleChange(e)}
                      > {actionplan?.surveysAssociated} </TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} marginTop={'-15px'}>
                <Grid container spacing={8}>
                  <Grid item sm={12} display={"flex"}>
                    <TypographyComponent sm={4} variant="h6" className="fontWeightBold">
                      {ActionConstants.ITEMOF_FOCUS}:
                    </TypographyComponent>
                    <Grid container sm={8} marginLeft={1} className={"divWidth"}>
                      <TypographyComponent
                      variant="h6"
                        id={"actionplan_question"}
                        className="height36"
                        name={"question"}
                        disabled={true}
                      >{actionplan?.question}</TypographyComponent>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={`bgwhite borderRadius30 dflexOnly ${classes.ItemScore}`} >
                    <TypographyComponent variant="h6">
                      {ActionConstants.ITEM_SCORE}:
                    </TypographyComponent>
                      <TypographyComponent
                      variant="h6"
                        id={"actionplan_score"}
                        className="height36 pl5"
                        name={"score"}
                        handleChange={(e) => handleChange(e)}
                        validators={["required"]}
                        disabled={true}
                      >{actionplan?.score}%</TypographyComponent>
              </Grid>  
              <Grid item sm={12}>
                <Grid container spacing={8}>
                  <Grid item sm={6}>
                    <TypographyComponent variant="h6" className={"fontWeightBold"}>
                      {ActionConstants.PLAN_NAME}
                    </TypographyComponent>
                    <Grid container sm={12} className={"divWidth"}>
                      <TextFieldComponent
                        id={"actionplan_name"}
                        className="height36"
                        name={"name"}
                        value={actionplan.name}
                        margin={"dense"}
                        handleChange={(e) => handleChange(e)}
                        validators={["required"]}
                        disabled={disable}
                      ></TextFieldComponent>
                    </Grid>

                  </Grid>                  
                  <Grid item sm={6}>
                    <TypographyComponent variant="h6"  className={"fontWeightBold"}>
                      {ActionConstants.PLAN_CREATOR}
                    </TypographyComponent>
                    <Grid container sm={12} className={"divWidth"}>
                      <TextFieldComponent
                        id={"actionplan_creator"}
                        className="height36"
                        name="creator"
                        value={actionplan?.creator}
                        margin={"dense"}
                        handleChange={(e) => handleChange(e)}
                        disabled={disable}
                      ></TextFieldComponent>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
              
              <Grid item sm={12}>
                <Grid container spacing={8}>
                  <Grid item sm={12}>
                    <Grid item sm={12} className={classes.ActionItem_Box}>
                    <TypographyComponent variant="h6" className="pb5 fontWeightBold m-1">
                      {ActionConstants.ACTION_ITEM}
                    </TypographyComponent>
                      {actionplan.actionItems && actionplan.actionItems.map((item, index) => {
                        item = updateLinksInHTML(item)
                        return (
                          <Grid container alignItems="center" className="pb-1">
                            <Grid item sm={12} className={classes.Addaction_container} style={{backgroundColor:"#FFF",borderRadius: "10px",}}>
                              <TextField
                                id={"actionItems"}
                                name={"actionItems"}
                                className={`${classes.ActionMultiLineText} ${"bordernone fieldset bgWhite width100 hauto lineHeight18 removeTopMargin"}`}
                                value={item}
                                type={"text"}
                                multiline
                                onChange={(e) => {
                                  const actionItems = [...actionplan.actionItems];
                                  actionItems[index] = e.target.value;
                                  handleChange({ name: "actionItems", value: actionItems });
                                }}
                                variant="outlined"
                                disabled={disable}
                              />
                              <Grid item sm={1} className="txtright bgWhite"
                                onClick={(e) => {
                                  const actionItems = [...actionplan.actionItems];
                                  actionItems.splice(index, 1);
                                  handleChange({ name: "actionItems", value: actionItems });
                                }}
                              >
                                {!disable ? <IconButton className="mr5 mt5 "  size="large">
                                  {DrawerClose()}
                                </IconButton> : ""}
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })
                      }
                      {!disable ? <Grid container className={classes.ActionItem_container} marginBottom={"10px"}>
                      <ButtonComponent
                          title={ActionConstants.ADD_ACTION}
                          color={'primary'}
                          variant="contained"
                          size="small"
                          disableElevation
                          handleClick={(e) => handleAddactionItems(e)}
                        >
                          {ActionConstants.ADD_ACTION}
                          </ButtonComponent>
                      </Grid> : ""}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Grid container spacing={8}>
                  <Grid item sm={4}>
                    <TypographyComponent variant="h6">
                      {ActionConstants.START_DATE}
                    </TypographyComponent>
                    <DatePickerComponent
                      name="startDate"
                      value={actionplan.startDate}
                      className={`${"height36"} ${classes.textField}`}
                      handleChange={(e) => handleChange(e)}
                      inputVariant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      disabled={disable}
                      validators={["required", "isEnddategreater"]}
                      errorMessages={[errorsConstants.required, errorsConstants.invalidDateRange]}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TypographyComponent variant="h6">
                      {ActionConstants.DUE_DATE}
                    </TypographyComponent>
                    <DatePickerComponent
                      name="dueDate"
                      value={actionplan?.dueDate}
                      className={`${"height36"} ${classes.textField}`}
                      handleChange={(e) => handleChange(e)}
                      inputVariant="outlined"
                      margin="dense"
                      size="small"
                      fullWidth={true}
                      disabled={disable}
                      validators={["required", "isEnddategreater"]}
                      errorMessages={[errorsConstants.required, errorsConstants.invalidDateRange]}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <TypographyComponent variant="h6">
                      {ActionConstants.PLAN_STATUS}
                    </TypographyComponent>
                    <AutoCompleteComponent
                      name={"status"}
                      className={`${"height36"}`}
                      suggestions={ActionConstants.statusDetails}
                      handleAutoCompolete={(event, name) => handleChange({ name, value: event.name })}
                      value={{ name: actionplan.status }}
                      validators={["required"]}
                      showErrorMessages={true}
                      disabled={disable}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Grid container spacing={8}>
                  <Grid item sm={12}>
                    <TypographyComponent variant="h6" className="pb5">
                      {ActionConstants.NOTES}
                    </TypographyComponent>
                    <TextareaAutosize
                      className={`${"width100 borderRadius6 pe_textarea"} ${classes.ActionPlanNotes}`}
                      name={"notes"}
                      placeholder={"Notes"}
                      value={actionplan?.notes}
                      onChange={(e) => handleChange({ name: "notes", value: e.target.value })}
                      minRows={3}
                      disabled={disable}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item sm={12}>
                <Grid container sm={12} className="spacebetween">
                  {/* <div className="actionblue"></div> */}
                  <ToolTipComponent title={actionplan.surveyType && actionplan.surveyType[0] !== surveyTypes.MULTI_RATER_FEEDBACK ? filterFields : ""} >
                    <Grid sm={10} className={classes.FilterField} item><TypographyComponent className='cursor-pointer' color="primary" variant="h6">{actionplan.surveyType && actionplan.surveyType[0] !== surveyTypes.MULTI_RATER_FEEDBACK ? filterFields : ""}</TypographyComponent></Grid>
                  </ToolTipComponent>
                  <Grid sm={2} className="txtright">
                    <ButtonComponent
                      title={"Save"}
                      color={"primary"}
                      size={"small"}
                      type="submit"
                      form="actionEditForm"
                      pageClassName="mr-1"
                      disabled={disable}
                    ></ButtonComponent>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>

      </ValidatorForm>
    </React.Fragment>
  );
}
// default props
EditActionPlan.defaultProps = {
  classes: {}
};

// prop types
EditActionPlan.propTypes = {
  classes: PropTypes.object
};
export default withStyles(ActionsStyles)(EditActionPlan);
