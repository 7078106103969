import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ValidatorForm } from "react-material-ui-form-validator";
import Link from "@mui/material/Link";

// Import Styles and Image
import ExternalApiStyles from "../ExternalApiIntegrationStyles";

// Import Icons
import {
  SettingAccordGrey,
  SettingAccordWhite,
  DeleteOrange,
  Plus_blue
} from "../../../assets/images/svgComponents/ImageComponent";

// Import Constants
import { externalApiConstants } from "../../../config/constants";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import SwitchComponent from "../../../components/SwitchComponent/SwitchComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import ToolTipComponent from "../../../components/ToolTipComponent/ToolTipComponent"

// Import Actions
import { externalApiAction } from "../../../redux/actions/externalAPIActions";
import history from "../../../config/history";

const ConfigTab = (props) => {
  // Define Dispatch
  const dispatch = useDispatch();

  //Get props
  const { classes, apiConfig } = props;

  // Accordion Declaration and Action
  const [expanded, setExpanded] = useState("default-panel");

  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  }
  const [hrisType, setHrisType] = useState(apiConfig.type)
  const [dataSync, setDataSync] = useState(apiConfig.data_sync)
  const [apiKey, setApiKey] = useState(apiConfig.api_key)
  const [subDomain, setSubDomain] = useState(apiConfig.sub_domain)
  const [reportId, setReportId] = useState(apiConfig.report_id)

  const [pathToRecords, setPathToRecords] = useState('');
  const [apiMethod, setApiMethod] = useState('');
  const [endPointUrl, setEndPointUrl] = useState('');
  const [headerKey, setHeaderKey] = useState('');
  const [headerValue, setHeaderValue] = useState('');
  const [authType, setAuthType] = useState('');
  const [authKey, setAuthKey] = useState('');
  const [authValue, setAuthValue] = useState('');
  const [queryParamsKey, setQueryParamsKey] = useState('');
  const [queryParamsValue, setQueryParamsValue] = useState('');
  const [postParamsKey, setPostParamsKey] = useState('');
  const [postParamsValue, setPostParamsValue] = useState('');
  const [skip, setSkip] = useState('');
  const [limit, setLimit] = useState('');
  const [defaultLimit, setDefaultLimit] = useState('');
  const [addTo, setAddTo] = useState('');

  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    setDataSync(apiConfig.data_sync)
    setApiKey(apiConfig.api_key)
    setSubDomain(apiConfig.sub_domain)
    setReportId(apiConfig.report_id)
  }, [apiConfig])

  useEffect(() => {
    ValidatorForm.addValidationRule("notAnNumber", (value) => {
      if (isNaN(reportId)) {
        return false
      } else {
        return true
      }
    });
  }, [reportId])

  useEffect(() => {
  
    setIsUpdated(
      (dataSync === apiConfig.data_sync && apiKey === apiConfig.api_key && subDomain === apiConfig.sub_domain && Number(reportId) === Number(apiConfig.report_id)) ? false : true
    )
  }, [dataSync, apiKey, subDomain, reportId])
  

  const handleSubmit = () => {
    if (hrisType === 'bamboohr') {
      const postPayload =
      {
        "data_sync": dataSync,
        "type": hrisType,
        "api_key": apiKey,
        "report_id": reportId,
        "sub_domain": subDomain
      }
      dispatch(externalApiAction.postApiConfig("", postPayload));
    }
  }

  const handleCancel = () => {
    history.push({ pathname: "/surveys" });
  }

  return (
    <Grid container justifyContent={"center"} mb={15}>
      <Grid item xs={10}>
        <ValidatorForm
          id="generateTokenForm"
          name="generateTokenForm"
          useref="generateTokenForm"
          autoComplete="off"
          onSubmit={handleSubmit}
          onError={(errors) => console.log(errors)}
          className={`width100`}
        >
          <Grid container justifyContent={"space-between"} alignItems={"center"} mt={2} mb={2}>
            <Grid item>
              <TypographyComponent
                variant={"h5"}
                className="txtMedium"
              >{externalApiConstants.HRIS_TITLES[hrisType]} Configuration</TypographyComponent>
            </Grid>
            <ToolTipComponent
              title="Please make sure to enable the data sync to get the response via API">
              <Grid item className="dflex">
                <TypographyComponent
                  title="Data Sync"
                  variant={"body1"}
                  className="txtMedium p-1"
                ></TypographyComponent>
                <SwitchComponent
                  name="Select All"
                  color="primary"
                  align="center"
                  checked={dataSync}
                  handleChange={(e) => setDataSync(e.value)}
                />
              </Grid>
            </ToolTipComponent>

            {
              externalApiConstants.HRIS_DESCRIPTION[hrisType] && (
                <Grid item xs={12}>
                  <TypographyComponent
                    variant={"h6"}
                    className=""
                  >{externalApiConstants.HRIS_DESCRIPTION[hrisType]} {
                    hrisType && (
                    <Link
                      href="https://www.bamboohr.com/api/documentation/"
                      target="_blank"
                    >
                    {"BambooHR Reports API"}
                  </Link>
                    )
                  }.</TypographyComponent>
                  
                </Grid>
              )
            }
          </Grid>

          {
            hrisType === 'bamboohr' ?
              (<Accordion
                className="mb-2 pe-accordion"
                expanded={expanded === "default-panel"}
                onChange={handlePanelChange("default-panel")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className="expandicon" />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {expanded === "default-panel" ? (
                    <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                  ) : (
                    <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                  )}
                  <TypographyComponent
                    className={`${"txtBold flexBasis33 mt2"} ${expanded === "default-panel" ? "panel-active" : ""
                      } `}
                    variant={"h5"}
                  >
                    {externalApiConstants.CONFIG_PANEL.PANEL_LABEL}
                  </TypographyComponent>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid >
                    <Grid container spacing={3}>
                      <Grid item md={12} xs={12} >
                        <Grid container mt={3.125} className="p-2">
                          <Grid item md={12}>
                            <TypographyComponent
                              className={``}
                              title={`${externalApiConstants.HRIS_TITLES[hrisType]} ${externalApiConstants.CONFIG_PANEL.API_KEY}`}
                              variant={"h6"}
                            ></TypographyComponent>
                          </Grid>
                          <Grid item md={12}>
                            <TextFieldComponent
                              id={"domain_wishlist"}
                              className="height36"
                              name={"domainWishlist"}
                              value={apiKey}
                              margin={"dense"}
                              handleChange={(e) => setApiKey(e.value)}
                              validators={["required"]}
                            />
                          </Grid>
                        </Grid>
                        <Grid container md={12} >
                          <Grid md={6} className="p-2">
                            <Grid item md={12}>
                              <TypographyComponent
                                className={``}
                                title={externalApiConstants.CONFIG_PANEL.SUB_DOMAIN}
                                variant={"h6"}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item md={12}>
                              <TextFieldComponent
                                id={"domain_wishlist"}
                                className="height36"
                                name={"domainWishlist"}
                                value={subDomain}
                                margin={"dense"}
                                handleChange={(e) => setSubDomain(e.value)}
                                validators={["required"]}
                              />
                            </Grid>
                          </Grid>
                          <Grid container md={6} className="p-2">
                            <Grid item md={12}>
                              <TypographyComponent
                                className={``}
                                title={externalApiConstants.CONFIG_PANEL.REPORT_ID}
                                variant={"h6"}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item md={12}>
                              <TextFieldComponent
                                id={"domain_wishlist"}
                                className="height36"
                                name={"reportId"}
                                value={reportId}
                                margin={"dense"}
                                handleChange={(e) => setReportId(e.value)}
                                validators={["required", "notAnNumber"]}
                                errorMessages={["required", "Must be a Number"]}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>)
              : (
                <>
                  {/* General Panel */}
                  <Accordion
                    mb={3.5}
                    className="pe-accordion"
                    expanded={expanded.indexOf("general-panel") !== 0}
                  // onChange={handlePanelChange("general-panel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="genaral-content"
                      id="general-header"
                    >
                      {expanded.indexOf("general-panel") !== 0 ? (
                        <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                      ) : (
                        <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                      )}
                      <TypographyComponent
                        className={`${"txtBold flexBasis33 mt2"} ${expanded.indexOf("general-panel") !== 0 ? "panel-active" : ""
                          } `}
                        variant={"h5"}
                      >
                        {externalApiConstants.GENERAL_PANEL.PANEL_LABEL}
                      </TypographyComponent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid >
                        <Grid container>
                          <Grid item md={12} mt={4.5} mb={2.5} mx={2} >
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={1.25}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.GENERAL_PANEL.PATH_TO_RECORDS}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={6}>
                                <TextFieldComponent
                                  id={"path_to_records"}
                                  className="height36"
                                  name={"pathToRecords"}
                                  value={pathToRecords}
                                  margin={"dense"}
                                  handleChange={(e) => { setPathToRecords(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* API End point URL Panel */}
                  <Accordion
                    mb={3.5}
                    className="pe-accordion"
                    expanded={expanded.indexOf("api-endpoint-panel") !== 0}
                  // onChange={handlePanelChange("api-endpoint-panel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="genaral-content"
                      id="general-header"
                    >
                      {expanded.indexOf("api-endpoint-panel") !== 0 ? (
                        <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                      ) : (
                        <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                      )}
                      <TypographyComponent
                        className={`${"txtBold flexBasis33 mt2"} ${expanded.indexOf("api-endpoint-panel") !== 0 ? "panel-active" : ""
                          } `}
                        variant={"h5"}
                      >
                        {externalApiConstants.API_ENDPOINT_PANEL.PANEL_LABEL}
                      </TypographyComponent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid mx={2} mt={4.5} mb={2.5}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={2.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.API_ENDPOINT_PANEL.API_METHOD}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={4}>
                                <TextFieldComponent
                                  id={"api_method"}
                                  className="height36"
                                  name={"apiMethod"}
                                  value={apiMethod}
                                  margin={"dense"}
                                  handleChange={(e) => { setApiMethod(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={3}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.API_ENDPOINT_PANEL.END_POINT_URL}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs>
                                <TextFieldComponent
                                  id={"end_point_url"}
                                  className="height36"
                                  name={"endPointUrl"}
                                  value={endPointUrl}
                                  margin={"dense"}
                                  handleChange={(e) => { setEndPointUrl(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* Header Panel */}
                  <Accordion
                    mb={3.5}
                    className="pe-accordion"
                    expanded={expanded.indexOf("header-panel") !== 0}
                  // onChange={handlePanelChange("header-panel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="genaral-content"
                      id="general-header"
                    >
                      {expanded.indexOf("header-panel") !== 0 ? (
                        <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                      ) : (
                        <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                      )}
                      <TypographyComponent
                        className={`${"txtBold flexBasis33 mt2"} ${expanded.indexOf("header-panel") !== 0 ? "panel-active" : ""
                          } `}
                        variant={"h5"}
                      >
                        {externalApiConstants.HEADER_PANEL.PANEL_LABEL}
                      </TypographyComponent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid mx={2} mt={4.5} mb={2.5}>
                        <Grid container alignItems='center' justifyContent="space-between">
                          <Grid item xs={6}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={2.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.KEY}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={7}>
                                <TextFieldComponent
                                  id={"header_key"}
                                  className="height36"
                                  name={"headerKey"}
                                  value={headerKey}
                                  margin={"dense"}
                                  handleChange={(e) => { setHeaderKey(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={3.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.VALUE}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs>
                                <TextFieldComponent
                                  id={"header_value"}
                                  className="height36"
                                  name={"headerValue"}
                                  value={headerValue}
                                  margin={"dense"}
                                  handleChange={(e) => { setHeaderValue(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item>
                            <Grid container>
                              <Grid item mb={-1.2}>
                                {DeleteOrange()}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} mt={2.5}>
                            <Grid container alignItems="center">
                              <Grid item mr={1} pt={0.5}>
                                {Plus_blue()}
                              </Grid>
                              <Grid item>
                                <TypographyComponent
                                  className={`actionblue`}
                                  title={externalApiConstants.COMMON_PANEL.ADD_ITEM}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                            </Grid>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* Authorization Panel */}
                  <Accordion
                    mb={3.5}
                    className="pe-accordion"
                    expanded={expanded.indexOf("authorization-panel") !== 0}
                  // onChange={handlePanelChange("authorization-panel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="genaral-content"
                      id="general-header"
                    >
                      {expanded.indexOf("authorization-panel") !== 0 ? (
                        <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                      ) : (
                        <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                      )}
                      <TypographyComponent
                        className={`${"txtBold flexBasis33 mt2"} ${expanded.indexOf("authorization-panel") !== 0 ? "panel-active" : ""
                          } `}
                        variant={"h5"}
                      >
                        {externalApiConstants.AUTHORIZATION_PANEL.PANEL_LABEL}
                      </TypographyComponent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid mx={2} mt={4.5} mb={2.5}>
                        <Grid container alignItems='center' justifyContent="space-between">
                          <Grid item xs={12} mb={2.5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={1.25}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.TYPE}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={2.5}>
                                <TextFieldComponent
                                  id={"auth_type"}
                                  className="height36"
                                  name={"authType"}
                                  value={authType}
                                  margin={"dense"}
                                  handleChange={(e) => { setAuthType(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={2.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.KEY}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={7}>
                                <TextFieldComponent
                                  id={"auth_key"}
                                  className="height36"
                                  name={"authKey"}
                                  value={authKey}
                                  margin={"dense"}
                                  handleChange={(e) => { setAuthKey(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={3.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.VALUE}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs>
                                <TextFieldComponent
                                  id={"auth_value"}
                                  className="height36"
                                  name={"headerValue"}
                                  value={authValue}
                                  margin={"dense"}
                                  handleChange={(e) => { setAuthValue(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item>
                            <Grid container>
                              <Grid item mb={-1.2}>
                                {DeleteOrange()}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} mt={2.5}>
                            <Grid container alignItems="center">
                              <Grid item mr={1} pt={0.5}>
                                {Plus_blue()}
                              </Grid>
                              <Grid item>
                                <TypographyComponent
                                  className={`actionblue`}
                                  title={externalApiConstants.COMMON_PANEL.ADD_ITEM}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                            </Grid>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* Query Params Panel */}
                  <Accordion
                    mb={3.5}
                    className="pe-accordion"
                    expanded={expanded.indexOf("query-params-panel") !== 0}
                  // onChange={handlePanelChange("query-params-panel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="genaral-content"
                      id="general-header"
                    >
                      {expanded.indexOf("query-params-panel") !== 0 ? (
                        <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                      ) : (
                        <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                      )}
                      <TypographyComponent
                        className={`${"txtBold flexBasis33 mt2"} ${expanded.indexOf("query-params-panel") !== 0 ? "panel-active" : ""
                          } `}
                        variant={"h5"}
                      >
                        {externalApiConstants.QUERY_PARAMS_PANEL.PANEL_LABEL}
                      </TypographyComponent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid mx={2} mt={4.5} mb={2.5}>
                        <Grid container alignItems='center' justifyContent="space-between">
                          <Grid item xs={6}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={2.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.KEY}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={7}>
                                <TextFieldComponent
                                  id={"query_params_key"}
                                  className="height36"
                                  name={"queryParamsKey"}
                                  value={queryParamsKey}
                                  margin={"dense"}
                                  handleChange={(e) => { setQueryParamsKey(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={3.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.VALUE}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs>
                                <TextFieldComponent
                                  id={"query_params_value"}
                                  className="height36"
                                  name={"queryParamsValue"}
                                  value={queryParamsValue}
                                  margin={"dense"}
                                  handleChange={(e) => { setQueryParamsValue(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item>
                            <Grid container>
                              <Grid item mb={-1.2}>
                                {DeleteOrange()}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} mt={2.5}>
                            <Grid container alignItems="center">
                              <Grid item mr={1} pt={0.5}>
                                {Plus_blue()}
                              </Grid>
                              <Grid item>
                                <TypographyComponent
                                  className={`actionblue`}
                                  title={externalApiConstants.COMMON_PANEL.ADD_ITEM}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                            </Grid>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* Post Params Panel */}
                  <Accordion
                    mb={3.5}
                    className="pe-accordion"
                    expanded={expanded.indexOf("post-params-panel") !== 0}
                  // onChange={handlePanelChange("post-params-panel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="genaral-content"
                      id="general-header"
                    >
                      {expanded.indexOf("post-params-panel") !== 0 ? (
                        <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                      ) : (
                        <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                      )}
                      <TypographyComponent
                        className={`${"txtBold flexBasis33 mt2"} ${expanded.indexOf("post-params-panel") !== 0 ? "panel-active" : ""
                          } `}
                        variant={"h5"}
                      >
                        {externalApiConstants.POST_PARAMS_PANEL.PANEL_LABEL}
                      </TypographyComponent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid mx={2} mt={4.5} mb={2.5}>
                        <Grid container alignItems='center' justifyContent="space-between">
                          <Grid item xs={6}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={2.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.KEY}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={7}>
                                <TextFieldComponent
                                  id={"post_params_key"}
                                  className="height36"
                                  name={"postParamsKey"}
                                  value={postParamsKey}
                                  margin={"dense"}
                                  handleChange={(e) => { setPostParamsKey(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={3.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.COMMON_PANEL.VALUE}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs>
                                <TextFieldComponent
                                  id={"post_params_value"}
                                  className="height36"
                                  name={"postParamsValue"}
                                  value={postParamsValue}
                                  margin={"dense"}
                                  handleChange={(e) => { setPostParamsValue(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item>
                            <Grid container>
                              <Grid item mb={-1.2}>
                                {DeleteOrange()}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} mt={2.5}>
                            <Grid container alignItems="center">
                              <Grid item mr={1} pt={0.5}>
                                {Plus_blue()}
                              </Grid>
                              <Grid item>
                                <TypographyComponent
                                  className={`actionblue`}
                                  title={externalApiConstants.COMMON_PANEL.ADD_ITEM}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                            </Grid>
                          </Grid> */}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  {/* Skip & Limit Panel */}
                  <Accordion
                    mb={3.5}
                    className="pe-accordion"
                    expanded={expanded.indexOf("skip-limit-panel") !== 0}
                  // onChange={handlePanelChange("skip-limit-panel")}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="expandicon" />}
                      aria-controls="genaral-content"
                      id="general-header"
                    >
                      {expanded.indexOf("skip-limit-panel") !== 0 ? (
                        <Grid className={"pr5"}>{SettingAccordWhite()}</Grid>
                      ) : (
                        <Grid className={"pr5"}>{SettingAccordGrey()}</Grid>
                      )}
                      <TypographyComponent
                        className={`${"txtBold flexBasis33 mt2"} ${expanded.indexOf("skip-limit-panel") !== 0 ? "panel-active" : ""
                          } `}
                        variant={"h5"}
                      >
                        {externalApiConstants.SKIP_LIMIT_PANEL.PANEL_LABEL}
                      </TypographyComponent>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid mx={2} mt={4.5} mb={2.5}>
                        <Grid container alignItems='center' justifyContent="space-between">
                          <Grid item xs={6}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={2.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.SKIP_LIMIT_PANEL.SKIP}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={7}>
                                <TextFieldComponent
                                  id={"skip"}
                                  className="height36"
                                  name={"skip"}
                                  value={skip}
                                  margin={"dense"}
                                  handleChange={(e) => { setSkip(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5.5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={3.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.SKIP_LIMIT_PANEL.LIMIT}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs>
                                <TextFieldComponent
                                  id={"limit"}
                                  className="height36"
                                  name={"limit"}
                                  value={limit}
                                  margin={"dense"}
                                  handleChange={(e) => { setLimit(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6} mt={3.5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={2.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.SKIP_LIMIT_PANEL.DEFAULT_LIMIT}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs={7}>
                                <TextFieldComponent
                                  id={"default_limit"}
                                  className="height36"
                                  name={"defaultLimit"}
                                  value={defaultLimit}
                                  margin={"dense"}
                                  handleChange={(e) => { setDefaultLimit(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5.5} mt={3.5}>
                            <Grid container alignItems='center' spacing={1.75}>
                              <Grid item xs={3.5}>
                                <TypographyComponent
                                  className={``}
                                  title={externalApiConstants.SKIP_LIMIT_PANEL.ADD_TO}
                                  variant={"h6"}
                                ></TypographyComponent>
                              </Grid>
                              <Grid item xs>
                                <TextFieldComponent
                                  id={"add_to"}
                                  className="height36"
                                  name={"addTo"}
                                  value={addTo}
                                  margin={"dense"}
                                  handleChange={(e) => { setAddTo(e.value) }}
                                  validators={["required"]}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              )
          }
          <Grid
            container
            justifyContent="flex-end"
            className={`${classes.configFooterBtn}`}
          >
            <Grid item>
              <ButtonComponent
                title={"Save & Connect"}
                color={"primary"}
                size={"small"}
                type={"submit"}
                pageClassName="mr-1"
              ></ButtonComponent>
              <ButtonComponent
                title={"Cancel"}
                color={"default"}
                size={"small"}
                type={"button"}
                handleClick={handleCancel}
              ></ButtonComponent>
            </Grid>
          </Grid>
        </ValidatorForm>
      </Grid>
    </Grid>
  );
};

// default props
ConfigTab.defaultProps = {
  classes: {},
};

// prop types
ConfigTab.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ExternalApiStyles)(ConfigTab);
