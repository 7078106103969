// particpants reducer types
export const authConstant = {
  // Authentication
  SET_AUTHENTICATED: "SET_AUTHENTICATED",
  SET_UNAUTHENTICATED: "SET_UNAUTHENTICATED",

  // Login
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  // Logout
  LOGOUT: "LOGOUT",

  // Saml Login
  SAML_LOGIN_REQUEST: "SAML_LOGIN_REQUEST",
  SAML_LOGIN_SUCCESS: "SAML_LOGIN_SUCCESS",
  SAML_LOGIN_FAILURE: "SAML_LOGIN_FAILURE",

  // FORGOT Password
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",

  // RESET password
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",

  // RESET password token
  RESET_PASSWORD_TOKEN_REQUEST: "RESET_PASSWORD_TOKEN_REQUEST",
  RESET_PASSWORD_TOKEN_SUCCESS: "RESET_PASSWORD_TOKEN_SUCCESS",
  RESET_PASSWORD_TOKEN_FAILURE: "RESET_PASSWORD_TOKEN_FAILURE",
};
