const TableComponentStyle = theme => ({
  root: {
    width: "100%",
    padding: "0px 16px",
    position: "relative",
    borderRadius: 10,
    background: "#fff"
  },
  leftroot: {
    width: "100%",
    padding: "0px 5px 0 16px",
    position: "relative",
    borderRadius: "10px 0px 0px 0px",
    background: "#fff",
    boxShadow: "3px 0 5px -2px #00000029"
  },
  rightroot: {
    width: "100%",
    padding: "0px 16px 0 5px",
    position: "relative",
    borderRadius: "0px 10px 0px 0px",
    background: "#fff"
  },
  tableContainer: {
    marginBottom: "16px"
  },
  tableCustomContainer: {
    background: "#fff"
  },
  tableRowSelected: {
    background: "#f2f4f5"
  },
  table: {
    minWidth: "700px"
  },
  actions: {
    display: "flex"
    // justifyContent: "flex-end"
  },
  TableHelpIcon: {
    paddingLeft: "14px",
    "& .ml-1": {
      marginLeft: "0px !important",
      paddingRight: "5px !important",
      top: "-1px"
    }
  }
});

export default TableComponentStyle;
