import { formStepperConstant } from "../constants/formStepperConstant";

// Set Initial State
const initialState = {
  page: 0
};

export default function (state = initialState, action) {
  switch (action.type) {
    case formStepperConstant.SURVEY_FORM_PREV:
      return {
        ...state,
        page: state.page === 0 ? 0 : state.page - 1
      };
    case formStepperConstant.SURVEY_FORM_NEXT:
      return {
        ...state,
        page: state.page >= 2 ? 2 : state.page + 1
      };
    case formStepperConstant.SURVEY_FORM_SET_PAGE:
      return {
        ...state,
        page: action.data
      };
    default:
      return state;
  }
}
