
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { contentLibraryAction, updateStateReducerAction } from "../../../../redux/actions";
import { contentLibraryConstant } from "../../../../redux/constants";

import AddReportContent from "./AddReportContent";
import ReportContentCard from "./ReportContentCard";

function RenderReportContent(props) {

    const { classes, item, index } = props;
    const dispatch = useDispatch();

    const { reportContent } = useSelector(state => state.contentLibrary);
    const [reportContentItem, setReportContentItem] = useState(null);

    // update the edited report content here for both save or cancel
    const updateReportContent = (description, updatedAction) => {
       reportContent.filter((action, index) => {
            if (description === action.description) {
                reportContent[index] = { ...updatedAction };
            }
        });
        dispatch(contentLibraryAction.updateReport(updatedAction));
    };

    // update the edited report content here for both save or cancel
    const updateReportContentCancel = (description, updatedAction) => {
        reportContent.filter((action, index) => {
            if (description === action.description) {
                reportContent[index] = { ...updatedAction };
            }
        });
        dispatch(updateStateReducerAction.update(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_REDUCER_UPDATE, reportContent));
    };

    return (
        <Grid item sm={12} className={"mt5"}>
            <Grid item sm={12} >
                <React.Fragment>
                    {item.editable ?
                        <AddReportContent
                            classes={classes}
                            item={ reportContentItem || item}
                            reportContent={reportContent}
                            index={index}
                            editReportContentItem={(reducerData) => setReportContentItem(reducerData)}
                            updateAddReportContentFlag={(isSave) => {
                                // if changes and save clicked
                                // else no changes or cancel
                                if (isSave && reportContentItem) {
                                    // remove editable state after save
                                    delete reportContentItem.editable;
                                    // reset to old Report Content
                                    updateReportContent(item.description, reportContentItem);
                                    setReportContentItem(null);
                                } else {
                                    // reset to old question item and disable edit
                                    delete item.editable;
                                    updateReportContentCancel(item.description, item);
                                    setReportContentItem(null);
                                }
                            }}
                        /> :
                        <ReportContentCard
                            classes={classes}
                            item={reportContentItem || item}
                            reportContent={reportContent}
                            index={index}
                        />
                    }
                </React.Fragment>
            </Grid>
        </Grid>
    )
}

export default RenderReportContent;

