
import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import withStyles from '@mui/styles/withStyles';
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Link from "@mui/material/Link";

import { Plus_blue } from "../../../assets/images/svgComponents/ImageComponent";
import SearchInputComponent from "../../../components/SearchInputCompoment/SearchInputComponent";
import AddNewRecomendation from "./RecomendationsTab/AddNewRecomendation";
import RenderRecomendation from "./RecomendationsTab/RenderRecomendation";
import ContentLibraryStyles from "../ContentLibraryStyles";

import { contentLibraryAction } from "../../../redux/actions"

function Recomendations(props) {

    const { classes } = props;
    const dispatch = useDispatch();

    const [baseQuestionId, setBaseQuestionId] = useState("Base question ID")

    useEffect(() => {
        dispatch(contentLibraryAction.getBaseQusID());
        dispatch(contentLibraryAction.searchQuestionCategory(""));
    }, [dispatch]);

    const { allQuestions } = useSelector(state => state.contentLibrary.recommendation_search);
    const { recommendation_search } = useSelector(state => state.contentLibrary);
    const { baseQuestionIds } = useSelector(state => state.contentLibrary);

    const [addNewRecommendation, setAddNewRecommendation] = React.useState(false);
    const [selectedQuestionData, setSelectedQuestionData] = React.useState({});

    // Handle Search Text Box Event
    const handleChange = (searchField, searchKey) => {
        dispatch(contentLibraryAction.searchQuestionCategory(searchKey));
    };

    // Handle basequestion id dropdown change
    const handleBaseQusIdChange = (event) => {
        const selectedBaseID = event.target.value
        setBaseQuestionId(selectedBaseID)
        dispatch(contentLibraryAction.searchBaseQuestionID(selectedBaseID));
    }

    /**
 * Handle Clear Search Text
 */
    const handleClearSearch = () => {
        dispatch(contentLibraryAction.search(""));
    };

    const updateNewRecommendationData = (updatedQuestion) => {
        dispatch(contentLibraryAction.updateRecommendation(updatedQuestion));
        setSelectedQuestionData(null);
    };



    return (
        <Grid item sm={12} className={"pl-3 pr-3"}>
            <Grid item sm={12} className={"p5 dflex spacebetween pb-15"} >
                <Grid item sm={6}>
                    <Select
                        className={`${classes.baseQusId_select} mr5`}
                        value={baseQuestionId}
                        disableUnderline={true}
                        placeholder={"Base question ID"}
                        onChange={(e) => { handleBaseQusIdChange(e) }}
                    >
                        <MenuItem disabled value="Base question ID">
                            {"Base question ID"}
                        </MenuItem>
                        {baseQuestionIds.map((item, keyIndex) => (
                            <MenuItem
                                key={keyIndex}
                                value={item.baseQusId}
                            >
                                {item.baseQusId}
                            </MenuItem>
                        ))}
                    </Select>
                    <SearchInputComponent
                        id="searchActionAlert"
                        className={classes.content_lib_search}
                        ariaLabel={"Search"}
                        placeholder={"Search"}
                        //   value={sValIndex >= 0 ? sValues[sValIndex] : ""}
                        showClearIcon={true}
                        onInputChangeRequest={handleChange}
                        handleClearSearch={handleClearSearch}
                    />
                </Grid>
                <Grid item>
                    <Link
                        onClick={() => setAddNewRecommendation(true)}
                        className={`${classes.content_linkimg}${" cursor-pointer"}`}
                        color="primary">
                        <IconButton color="inherit" className={"p-0"} size="large">{Plus_blue()}</IconButton>
                        Add Recommendations
                    </Link>
                </Grid>
            </Grid>
            <Grid item sm={12} >
                {addNewRecommendation &&
                    <AddNewRecomendation
                        classes={classes}
                        item={selectedQuestionData}
                        questions={allQuestions}
                        editNewRecommendationData={(reducerData) => {
                            setSelectedQuestionData(reducerData)
                        }}
                        updateAddRecommendationFlag={(isSave) => {
                            if (isSave) {
                                // remove state after save
                                // reset to old Action Alert
                                updateNewRecommendationData(selectedQuestionData);
                                setAddNewRecommendation(false)
                            } else {
                                setAddNewRecommendation(isSave)
                                setSelectedQuestionData(null);
                            }
                        }}
                    />}
                {recommendation_search.questions && recommendation_search.questions.map((optItem, index) => (
                    <React.Fragment>
                        <RenderRecomendation
                            classes={classes}
                            item={optItem}
                            index={index}
                        />
                    </React.Fragment>
                ))}
            </Grid>
        </Grid>
    );
}

export default withStyles(ContentLibraryStyles)(Recomendations);

