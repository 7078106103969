/* eslint-disable no-sequences */
import { filterConstant } from "../constants";

// Set Initial State
export const initialState = {
  filterData:{
    surveys: [],
    date: {
      type: "Custom dates"
    },
    demographics: {}
  },
  loading:false,
  demographicsLoading : false,
  demographics: {},
  surveys: [],
  allSurveys:[],
  demographicItems: {},
  demographicsDropdown:{}
};

export default function (state = initialState, action) {
  switch (action.type) {

     // Update Filter Query 
     case filterConstant.UPDATE_FILTER_REQUEST:
      return {
        ...state
      };
    case filterConstant.UPDATE_FILTER_SUCCESS:
      return {
        ...state,
        filterData:action.data
      };
    case filterConstant.UPDATE_FILTER_FAILURE:
      return {
        ...state
      };

    // Get Demographic 
    case filterConstant.FILTER_GET_DEMOGRAPHICS_REQUEST:
      return {
        ...state,
        demographicsLoading: true
      };
    case filterConstant.FILTER_GET_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        demographics:  action.data.data,
        demographicsLoading: false
      };
    case filterConstant.FILTER_GET_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        demographicsLoading: false
      };

      // Get Demographic 
      case filterConstant.FILTER_GET_DEMOGRAPHICS_DDN_REQUEST:
        return {
          ...state,
        };
      case filterConstant.FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS:
        return {
          ...state,
          demographicsDropdown:  action.data.data,
        };
      case filterConstant.FILTER_GET_DEMOGRAPHICS_DDN_FAILURE:
        return {
          ...state,
          demographicsDropdown:{}
        };

       // Get Demographic items 
    case filterConstant.FILTER_DEMOGRAPHICS_BY_NAME_REQUEST:
      return {
        ...state,
        loading:true
      };
    case filterConstant.FILTER_DEMOGRAPHICS_BY_NAME_SUCCESS:
      return {
        ...state,
        demographicItems:action.data.data,
        loading:false
      };
    case filterConstant.FILTER_DEMOGRAPHICS_BY_NAME_FAILURE:
      return {
        ...state,
        loading:false
      };

    // Get Survey 
    case filterConstant.FILTER_GET_SURVEYS_REQUEST:
      return {
        ...state
      };
    case filterConstant.FILTER_GET_SURVEYS_SUCCESS:
      return {
        ...state,
        surveys: action.data.data
      };
    case filterConstant.FILTER_GET_SURVEYS_FAILURE:
      return {
        ...state
      };

      // Get ALL Survey 
    case filterConstant.FILTER_GET_ALL_SURVEYS_REQUEST:
      return {
        ...state
      };
    case filterConstant.FILTER_GET_ALL_SURVEYS_SUCCESS:
      return {
        ...state,
        allSurveys: action.data.data
      };
    case filterConstant.FILTER_GET_ALL_SURVEYS_FAILURE:
      return {
        ...state
      };

    default:
      return state;
  }
}
