import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import withStyles from '@mui/styles/withStyles';
import { Grid, IconButton } from "@mui/material";
import { ValidatorForm } from "react-material-ui-form-validator";

import { DrawerClose } from "../../../../../../assets/images/svgComponents/ImageComponent";
import TypographyComponent from "../../../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../../../components/TextFieldComponent/TextFieldComponent";
import AutoCompleteComponent from "../../../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import ButtonComponent from "../../../../../../components/ButtonComponent/ButtonComponent";

// Import Config Layout
import { getDrawerHeight } from "../../../../../../config/layout";

// Import Styles and Image
import TabStyle from "../../styles/METabStyles";

//Import Actions
import { meSurveyAction } from '../../../../../../redux/actions'

import DrawerPanel from "../DrawerPanel"

const Rater = (props) => {

    const { classes, open, survey_id } = props
    const drawerHeight = getDrawerHeight()
    const dispatch = useDispatch()

    // Get filter Data
    const { managerEffectiveness, metadata: { metadata } } = useSelector(state => state)
    const { dataFilter, editRaters, subject_names, relationships, isAlreadySaved } = managerEffectiveness
    const { getOne } = editRaters
    const {
        _id,
        subject_name,
        subject_id,
        firstName,
        lastName,
        email,
        relationship,
        empId,
        assigned_status,
        new_rater,
        manager_id,
        fromTo,
    } = getOne || {}
    const { filterData } = dataFilter
    const demoMeta = {}
    metadata.map(meta => {
        demoMeta[meta.key] = meta.value
    })

    const handleSubmit = () => {
        const empIdUp = empId || email
        const updateData = {
            ...getOne,
            empId: empIdUp,
            manager_id: manager_id || subject_id,
            new_rater: !empId || new_rater ? true : false,
            fromTo: fromTo || `${empIdUp}_to_${(manager_id || subject_id)}`,
            assigned_status: assigned_status === undefined ? true : assigned_status,
            isDelete: false
        }
        dispatch(meSurveyAction.updateRaterChange(updateData, survey_id))
    }

    const handleClose = () => {
        dispatch(meSurveyAction.addOrEditRater({
            ...editRaters,
            getOne: null
        }))
    }

    const handleChange = (e) => {
        let value = (Array.isArray(e) ? e : [e]).reduce((a, b) => {
            a[b.name] = b.value
            return a
        }, {})

        // update fromTo value for new rater
        // for rater from participants empId is already saved it cant be changed
        const toUpdateData = {
            ...getOne,
            ...value
        }
        if (new_rater) {
            toUpdateData.empId = toUpdateData.email
            toUpdateData.fromTo = `${toUpdateData.empId}_to_${toUpdateData.subject_id}`
        }

        dispatch(meSurveyAction.addOrEditRater({
            ...editRaters,
            getOne: { ...toUpdateData }
        }))
    }

    useEffect(() => {
        if (open) {
            dispatch(meSurveyAction.getManagerRelationship(survey_id))
        }
    }, [dispatch, open])

    const selected_relationship = relationships.find(a => a._id === relationship) || {}
    const { name: subject_name_label } = subject_names.find(f => f._id === subject_id) || { name: subject_name }

    return <>
        <DrawerPanel
            className={`${classes.drawer} ${"pe_drawer"}`}
            anchor='right'
            variant="temporary"
            open={open}
            onClose={() => {
                if (getOne) {
                    handleClose()
                }
            }}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={`${"pr-1 pl-1 borderBottom"} ${classes.filterTitle}`}>
                <Grid item>
                    <Grid container alignItems="center">
                        <TypographyComponent variant={"h4"}
                            className="txtMedium">
                            {_id ? "Edit Rater" : "Add a Rater"}
                        </TypographyComponent>
                    </Grid>
                </Grid>
                <Grid item>
                    <IconButton variant="contained" onClick={() => { handleClose() }} size="large">
                        {DrawerClose()}
                    </IconButton>
                </Grid>
            </Grid>
            <ValidatorForm
                id="raterForm"
                name="raterForm"
                useref="raterForm"
                autoComplete="off"
                onSubmit={handleSubmit}
                onError={(errors) => console.log(errors)}
            >
                <div
                    style={{
                        height: drawerHeight - 107,
                        padding: '12px'
                    }}
                    className={'scrolly'}
                >
                    <Grid container xs={12}>
                        <Grid item xs={12} classes={classes.fieldGroup}>
                            <TypographyComponent variant="h6">
                                {"Subject Name"}
                            </TypographyComponent>
                            <AutoCompleteComponent
                                name={"subject_id"}
                                className={`${"height36"}`}
                                suggestions={subject_names}
                                handleAutoCompolete={(event, name) => {
                                    handleChange([
                                        { name, value: event._id },
                                        { name: 'subject_name', value: event.subject_name }
                                    ])
                                }}
                                value={{ 
                                    _id: subject_id, 
                                    name: subject_name_label || ""
                                }}
                                validators={["required"]}
                                showErrorMessages={true}
                                extraProps={{
                                    getOptionSelected: (option, value) => option._id === value._id
                                }}
                                disabled={_id}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.fieldGroup}>
                            <TypographyComponent variant="h6">
                                {"First Name"}
                            </TypographyComponent>
                            <TextFieldComponent
                                id={"firstName"}
                                className="height36"
                                name={"firstName"}
                                value={firstName}
                                margin={"dense"}
                                handleChange={(e) => handleChange(e)}
                                validators={["required"]}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.fieldGroup}>
                            <TypographyComponent variant="h6">
                                {"Last Name"}
                            </TypographyComponent>
                            <TextFieldComponent
                                id={"lastName"}
                                className="height36"
                                name={"lastName"}
                                value={lastName}
                                margin={"dense"}
                                handleChange={(e) => handleChange(e)}
                                validators={["required"]}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.fieldGroup}>
                            <TypographyComponent variant="h6">
                                {"Email Address"}
                            </TypographyComponent>
                            <TextFieldComponent
                                id={"email"}
                                className="height36"
                                name={"email"}
                                value={email}
                                margin={"dense"}
                                handleChange={(e) => handleChange(e)}
                                validators={["required", "isEmail"]}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.fieldGroup}>
                            <TypographyComponent variant="h6">
                                {"Relationship"}
                            </TypographyComponent>
                            <AutoCompleteComponent
                                name={"relationship"}
                                className={`${"height36"}`}
                                suggestions={relationships}
                                handleAutoCompolete={(event, name) => {
                                    handleChange({ name, value: event._id })
                                }}
                                value={{ name: selected_relationship.name || relationship }}
                                validators={["required"]}
                                showErrorMessages={true}
                                disabled={relationship === "Self"}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.saveFilterBtn}>
                    <ButtonComponent
                        classes={classes}
                        componentClassName={'saveFilterBtn_btn'}
                        title={_id ? "Update" : "Add"}
                        color={"primary"}
                        size={"small"}
                        type={"submit"}

                    />
                    <ButtonComponent
                        classes={classes}
                        componentClassName={'saveFilterBtn_btn'}
                        title={"Cancel"}
                        color={"default"}
                        size={"small"}
                        type={"button"}
                        handleClick={() => handleClose()}
                    />
                </div>
            </ValidatorForm>
        </DrawerPanel>
    </>;
}

export default withStyles(TabStyle)(Rater);