/* eslint-disable prefer-const */
import { fieldDataTypes } from "../utils/operators";

export function validations ({ fieldData, value, ...rest }) {
  /// date validations
  const dataType = fieldData ? fieldData.type : null;
  switch (dataType) {
    case fieldDataTypes.DATE:
      if (value && value.value1 && value.value2) {
        if (value.value1.indexOf("/") === -1 && value.value2.indexOf("/") === -1) {
          // value 2 must be greater than value 1
          let value1 = parseInt(value.value1);
          let value2 = parseInt(value.value2);
          if (typeof value1 === "number" && typeof value2 === "number") {
            return value1 < value2;
          }
        }
      }
      return true;
    default:
      return true;
  }
};
