import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Base Questions Service Functions
 */
export const baseQuestionsService = {
  getQuestionsByCategoryandSurveyType,
  getQuestionsByQuestionandSurveyType
};

/**
 * Call Get Questions By Category  Service
 */
async function getQuestionsByCategoryandSurveyType(params) {
  const response = await apihelper.postRequest("questions/category", params);
  return response;
}

/**
 * Call Get Question By Question , Catagory Service
 */
async function getQuestionsByQuestionandSurveyType(params) {
  const response = await apihelper.postRequest("questions/text", params);
  return response;
}