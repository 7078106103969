import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";

//Import Component
import CategoryDescription from "./CategoryDescription";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TabPanelComponent from "../../../../components/TabPanelComponent/TabPanelComponent";
import Questions from "./Questions";

// Import Actions
import { surveyPreviewAction } from "../../../../redux/actions";

//import Constants
import {
  surveyQuestionsConstants,
  TakeSurveyConstants,
} from "../../../../config/constants";
import { surveyButtons } from "../../../../config/translatedConstants";

function QuestionsPanel(props) {
  // Get Props Values
  const { classes, questionData, langCode } = props;
  const { questions, catOrder, catDetails } = questionData;

  const activeCategoryTab = useSelector(
    (state) => state.surveyPreview.activeCategoryTab
  );

  //Active Categories when apply filters
  let activeCategories = [];
  questions.forEach((ques) => {
    if (!activeCategories.includes(ques.category)) {
      activeCategories.push(ques.category);
    }
  });
  const newCatOrder = catOrder.filter((cat) => activeCategories.includes(cat));
  const categoryCount = newCatOrder.length - 1;

  // Define Dispatch
  const dispatch = useDispatch();
  const surveyRequiredQuestions = useSelector(
    (state) => state.surveyPreview.surveyRequiredQuestions
  );
  const validatedQues = useSelector(
    (state) => state.surveyPreview.validatedQues
  );
  function handleContinue(e) {
    const activeCategory = newCatOrder[activeCategoryTab];
    const activeCatRequiredQues = questions.filter(
      (ques) => ques.isRequired && ques.category === activeCategory
    );

    let errorQuestions = [];
    if (activeCatRequiredQues.length > 0) {
      activeCatRequiredQues.forEach((ques) => {
        if (!surveyRequiredQuestions[ques.baseQusId]) {
          errorQuestions = [...errorQuestions, ques.baseQusId];
        }
      });
    }

    if (errorQuestions.length > 0) {
      let requiredQuesArr = [...validatedQues];
      errorQuestions.forEach((ques) => {
        if (!validatedQues.includes(ques)) {
          requiredQuesArr.push(ques);
        }
      });
      dispatch(surveyPreviewAction.updateRequiredQuestions(requiredQuesArr));
    } else {
      props.handleSurveyContinue(e);
    }
  }

  const btnText = surveyButtons.find((ele) => ele.code === langCode);

  return (
    <>
      {newCatOrder.length > 0 &&
        newCatOrder.map((item, ind) => (
          <TabPanelComponent value={activeCategoryTab} key={ind} index={ind}>
            <Grid container>
              <CategoryDescription
                catDetails={catDetails}
                langCode={langCode}
                activeCategoryTab={activeCategoryTab}
                activeCategoryName={item}
              />
              <Grid item sm={12} className={`${classes.quesPanel} scrolly`}>
                <Questions
                  classes={classes}
                  langCode={langCode}
                  catIndex={ind}
                  catOrder={newCatOrder}
                  questionCategory={newCatOrder[ind]}
                  questions={questions}
                  validatedQues={validatedQues}
                />
              </Grid>
            </Grid>
          </TabPanelComponent>
        ))}

      <Grid container justifyContent="flex-end" className="mt-2 mb-1">
        <ButtonComponent
          title={activeCategoryTab !== categoryCount ? btnText["continue"] : btnText["submit"]}
          size="small"
          color="primary"
          pageClassName="mr-1"
          handleClick={handleContinue}
        ></ButtonComponent>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={`pt-15 takeSureveyButtomAction ${classes.takeActionMobile}`}
      >
        <Grid item>
          <TypographyComponent variant="h6" className="colorGreen">
            {TakeSurveyConstants.POWEREDBY_TEXT}
          </TypographyComponent>
        </Grid>
        <Grid item>
          <ButtonComponent
            color={"primary"}
            title={activeCategoryTab !== categoryCount ? btnText["continue"] : btnText["submit"]}
            pageClassName="mr-1"
            handleClick={handleContinue}
          ></ButtonComponent>
        </Grid>
      </Grid>
    </>
  );
}

// default props
QuestionsPanel.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
  activeCategoryTab: 0,
  langCode: surveyQuestionsConstants.DEFAULT_LANG_CODE,
  requiredQues: {},
  handleSurveyContinue: () => {},
};

// prop types
QuestionsPanel.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
  activeCategoryTab: PropTypes.number,
  langCode: PropTypes.string,
  requiredQues: PropTypes.object,
  handleSurveyContinue: PropTypes.func,
};

export default QuestionsPanel;
