/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Avatar from "@mui/material/Avatar";
import { Pagination } from '@mui/material';
import { PaginationItem } from '@mui/lab';
import Tooltip from "@mui/material/Tooltip";
import IconButton from '@mui/material/IconButton';
import Link from "@mui/material/Link";
import CachedIcon from '@mui/icons-material/Cached';

// Import Styles
import TableComponentStyle from "./TableComponentStyle";


// Import SVG Icons
import { Setting, MailBlue, Delete, Copy, TakeSurvey, CallerAvatar, Download, Find, HeartInBorderOrange, PaperPlane, PaperPlaneCross, PaperPlaneCrossGrey } from "../../assets/images/svgComponents/ImageComponent";


// Import Component
import SearchInputComponent from "../SearchInputCompoment/SearchInputComponent";
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import DeleteDialogComponent from "../DeleteDialogComponent/DeleteDialogComponent";
import InformationComponent from "../InformationComponent/InformationComponent";

// Import Constants
import { addEllipsis } from "../../helpers";

function TableComponent(props) {
  const {
    height,
    count,
    alignCenterFields,
    rowsPerPage,
    page,
    padding,
    classes,
    data,
    header,
    actions,
    actionName,
    field,
    links,
    sort,
    sortBy,
    search,
    sFields,
    sValues,
    resetSearchText,
    removeSortingHeader,
    ellipsis,
    config,
    componentMap,
    deleteContent,
    customIconFields,
    fieldComponentOnChange,
    customDelete,
    showPagination,
    isRightTable,
    isLeftTable,
    removeTooltipCols,
    actionComponent,
    searchVisible,
    infoHeaders,
    noDataErrorMessage,
    dynamicPagination,
    id,
    customAriaLabel,
    onEnterSearch,
    totalRecords,
    getTotalRecords,
    customFilterComponent,
    selectedRows,
    componentProps,
    actionsTitle,
    getTDClassName,
    selectedIndex,
    toggleDrawer,
  } = props;
  // Set State
  const [deleteItem, setDeleteItem] = React.useState({
    id: null,
    content: "",
    open: false
  });

  /**
      * Handle Sorting
      */
  const handleSorting = property => event => {
    const sortBy = property;
    let sort = "asc";

    if (props.sortBy === sortBy && props.sort === "asc") {
      sort = "desc";
    }

    props.onSortRequest(sortBy, sort);
  };

  // Request change on input function
  const onInputChangeRequest = (field, value, userChange) => {
    props.onInputChangeRequest(field, value, userChange);
  };

  // Handle Clear Search Text In Field
  const handleClearSearchByField = (field, value) => {
    props.handleClearSearchByField(field, value);
  };

  /**
   * Handle
   * Table Navigation Event with two links
   */
  const handleNavigateAction = (item, field) => {
    props.handleNavigateAction(item._id, field, item);
  };

  /**
   * Handle SETTINGS, COPY and PREVIEW Action Click Event
   * @param {*} field
   * @param {*} itemId
   */
  const handelActionButtonClick = (field, itemId, itemName, ind) => {
    props.handelActionButtonClick(field, itemId, itemName, ind);
  };

  /**
   * Page Navigation Event
   */
  const onChangePagination = (event, page) => {
    props.onChangePagination(page, props.rowsPerPage);
  };

  // Search Component Enter Event
  const onEnter = (field, value) => {
    if (onEnterSearch) {
      onEnterSearch();
    }
  };

  /**
   * Handle Table Item Delete Event Action
   * @param {*} item
   */
  const handleDelete = (item) => {
    if (customDelete) {
      props.customDeleteClickHandle(item._id);
    } else {
      setDeleteItem({
        id: item._id,
        content: deleteContent,
        open: true
      });
    }
  };

  /**
  * Handle Dialog Item Delete Event
  */
  const handleTableDeleteAction = () => {
    props.handleTableDeleteAction(deleteItem.id);
    setDeleteItem({
      id: null,
      content: deleteContent,
      open: false
    });
  };

  // Handle Dialog Delete Item Cancel Event
  const handleTableDeleteOnCloseAction = () => {
    setDeleteItem({
      id: null,
      content: deleteContent,
      open: false
    });
  };

  /**
      * Get Readable String from Large Strings value
      */
  const getReadableString = (str) => {
    // truncate if too long and elipsis...
    if (str && str.length > 35 && typeof (str) === "string" && ellipsis) {
      return str.substring(0, 32) + "...";
    }
    return str;
  };

  /**
       * Binding ToolTip Value
       */
  const bindToolTipValue = (item, pathArr) => {
    // disable title for component map field
    if (componentMap[pathArr]) return null;

    const arr = pathArr.split(".");
    return arr.reduce((obj, key) => (obj && obj[key] !== "undefined") ? obj[key] : undefined, item);
  };

  /**
           * Table Header Row
           */
  const tableHeaderRow = () => {
    return (
      <TableRow>
        {header.map((item, index) => {
          const headerColumn = item.value ? item.value : item;
          const headerColumnkey = item && item.key ? item.key : item
          return <React.Fragment key={"frow-" + index}>
            {removeSortingHeader.indexOf(headerColumn) < 0 &&
              <TableCell className={`${classes.TableHelpIcon} ${alignCenterFields.indexOf(headerColumn) >= 0 ? "txtcenter" : ""} ${headerColumnkey && headerColumnkey !== null && alignCenterFields.indexOf("status") >= 0 ? "txtcenter" : ""}`} key={"hrow-" + index} sortDirection={sortBy === field[index] ? sort : false}>
                <TableSortLabel
                  active={sortBy === field[index]}
                  direction={sort}
                  onClick={handleSorting(field[index])}>{addEllipsis(headerColumn, 25)}
                  {infoHeaders[headerColumn] && <span>&nbsp;<InformationComponent className={classes.TableHelpIcon}>
                    {infoHeaders[headerColumn]}
                  </InformationComponent></span>}
                </TableSortLabel>
              </TableCell>
            }

            {removeSortingHeader.indexOf(headerColumn) >= 0 &&
              <TableCell className={`${alignCenterFields.indexOf(headerColumn) >= 0 ? "txtcenter" : ""} `} > {addEllipsis(headerColumn, 30)} </TableCell>
            }
          </React.Fragment>;
        })
        }

        {header.length > 0 &&
          actions.length > 0 &&
          <TableCell key={"hrow-action"} className="txtcenter" > {actionName && actionName !== "" ? actionName : "Actions"} </TableCell>
        }
      </TableRow >
    );
  };

  /**
       * Table Search Input Row
       */
  const tableFilterRow = () => {
    const tableRow = search.map((item, index) => {
      const colName = item || "";
      const sValIndex = sFields.indexOf(colName);
      const FilterComponent = customFilterComponent[colName] || null
      if (FilterComponent) {
        return (
          <TableCell key={"searchTC" + index} className="select-all-box pl-14">
            <FilterComponent
              data={"header"}
              handleChange={fieldComponentOnChange}
              selectedRows={selectedRows}
              componentProps={{ ...componentProps }}
            />
          </TableCell>
        )
      }
      return (
        <TableCell key={"searchTC" + index} >
          {
            item != null &&
            <SearchInputComponent
              ariaLabel={header[index] || "Search"}
              placeholder={"Search"}
              value={sValIndex >= 0 ? sValues[sValIndex] : ""}
              reset={resetSearchText}
              field={field[index]}
              showClearIcon={true}
              fullWidth={true}
              onInputChangeRequest={(field, value, userChange) => onInputChangeRequest(field, value, userChange)}
              handleClearSearch={() => handleClearSearchByField}
              onEnter={onEnter}
            />
          }
        </TableCell>
      );
    });

    return (
      <TableRow>
        {tableRow}
        {header.length > 0 &&
          actions.length > 0 &&
          <TableCell />
        }
      </TableRow>
    );
  };

  /**
      * Table Data Rows
      */
  const tableDataRow = () => {
    return (
      <React.Fragment>
        {data.map((item, index) =>
          <TableRow key={index} className={toggleDrawer && selectedIndex === index ? classes.tableRowSelected : ""}>
            {tableDataRowValues(item, index)}
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  // Value entry in table row
  const tableDataRowValues = (item, ind) => {
    return (
      <React.Fragment>
        {field.map((field, index) => {
          return links && links.indexOf(field) >= 0
            ? <TableCell
              key={"value-" + index}
              className={"link tablecellfocusdisable"}
            >
              <div
                tabIndex="0"
                // role="button"
                aria-label={customAriaLabel ? customAriaLabel(item, field, index) : 'Naviation Link'}
                onClick={() => handleNavigateAction(item, field)}
                onKeyPress={(e) => {
                  const enterOrSpace =
                    e.key === "Enter" ||
                    e.key === " " ||
                    e.key === "Spacebar" ||
                    e.which === 13 ||
                    e.which === 32;
                  if (enterOrSpace) {
                    e.preventDefault();
                    handleNavigateAction(item, field);
                  }
                }}
              >
                <div id={`text_col_${field}_${index}`} className="colorTheme txtMedium fontSize14 cursor-pointer">
                  {bindSelectField(item, field)}
                </div>
              </div>
            </TableCell>
            : (
              field === "status"
                ? <TableCell
                  key={"value-" + index}
                  className={alignCenterFields.indexOf(field) >= 0 ? "txtcenter" : ""}
                >
                  <div className={`${"status"} ${item[field]}`}> {bindSelectField(item, field)} </div>
                </TableCell>
                : <TableCell
                  key={"value-" + index}
                  className={alignCenterFields.indexOf(field) >= 0 ? "txtcenter" : "pl-14"}
                >
                  {bindSelectField(item, field)}
                </TableCell>);
        }
        )}

        {actions &&
          (actions.indexOf("COPY") >= 0 ||
            actions.indexOf("EXPORT") >= 0 ||
            actions.indexOf("TAKESURVEY") >= 0 ||
            actions.indexOf("DELETE") >= 0 ||
            actions.indexOf("SETTINGS") >= 0 ||
            actions.indexOf("EMAIL") >= 0 ||
            actions.indexOf("TAKE_SURVEY") >= 0 ||
            actions.indexOf("SEND_SURVEY") >= 0 ||
            actions.indexOf("CALLER_DETAIL") >= 0) &&
          addAction(item, ind)
        }
      </React.Fragment>
    );
  };
  // Adding action
  const addAction = (item, ind) => {
    const actionItems = actions.slice();
    const { SETTING, EXPORT, DELETE } = actionComponent;
    return (
      <TableCell key={"actions"} >
        <div className={`${classes.actions} justifycenter`}>
          {actionItems.indexOf("SETTING") >= 0 &&
            SETTING ? <SETTING data={item} handleChange={fieldComponentOnChange} classes={classes} /> : actionItems.indexOf("SETTING") >= 0 && <Tooltip arrow title="Settings"><Avatar alt="Setting" /* src={IconSetting} */ className={`tableIcon ml5 mr5 scrollIntial cursor-pointer`} onClick={() => handelActionButtonClick("SETTINGS", item._id)} >{Setting()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("PREVIEW") >= 0 &&
            <Tooltip arrow title="Preview"><Avatar alt="Preview" className={"tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handelActionButtonClick("PREVIEW", item._id)} >{Find()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("COPY") >= 0 &&
            <Tooltip arrow title="Copy"><Avatar alt="Copy" className={"tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handelActionButtonClick("COPY", item._id, item.name)} >{Copy()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("EXPORT") >= 0 &&
            EXPORT ? <EXPORT data={item} handleChange={fieldComponentOnChange} classes={classes} /> :
            actionItems.indexOf("EXPORT") >= 0 && <Tooltip arrow title={actionsTitle["EXPORT"] || "Download"}><Avatar alt="Export" className={"tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handelActionButtonClick("EXPORT", item._id, item)} >{Download()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("DELETE") >= 0 &&
            DELETE ? <DELETE data={item} handleChange={fieldComponentOnChange} classes={classes} /> : actionItems.indexOf("DELETE") >= 0 &&
          <Tooltip arrow title="Delete"><Avatar alt="Resource" className={"tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handleDelete(item)} >{Delete()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("TAKESURVEY") >= 0 &&
            <Tooltip arrow title="Take Survey"><Avatar alt="Resource" className={"tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handelActionButtonClick("TAKESURVEY", item._id, item.name)} >{PaperPlane()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("EMAIL") >= 0 &&
            <Tooltip arrow title="Send Survey"><Avatar tabIndex="0" aria-label="Send Survey Button" role="button" alt="Send Survey Icon" /* src={IconEmail} */ className={"tablefocusdisable tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handelActionButtonClick("EMAIL", item._id, item)} onKeyPress={(e) => {
              const enterOrSpace =
                e.key === "Enter" ||
                e.key === " " ||
                e.key === "Spacebar" ||
                e.which === 13 ||
                e.which === 32;
              if (enterOrSpace) {
                e.preventDefault();
                handelActionButtonClick("EMAIL", item._id, item)
              }
            }}>{MailBlue()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("TAKE_SURVEY") >= 0 &&
            <Tooltip arrow title="Take Survey"><Avatar tabIndex="0" aria-label="Take Survey Button" role="button" alt="Take Survey Icon" /* src={IconPlay} */ className={"tablefocusdisable tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handelActionButtonClick("TAKE_SURVEY", item._id, item)} onKeyPress={(e) => {
              const enterOrSpace =
                e.key === "Enter" ||
                e.key === " " ||
                e.key === "Spacebar" ||
                e.which === 13 ||
                e.which === 32;
              if (enterOrSpace) {
                e.preventDefault();
                handelActionButtonClick("TAKE_SURVEY", item._id, item)
              }
            }}>{TakeSurvey()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("CALLER_DETAIL") >= 0 &&
            <Tooltip arrow title="Caller Detail"><Avatar tabIndex="0" aria-label="Caller Detail Button" role="button" alt="Caller Detail Icon" /* src={IconAvatar} */ className={"tablefocusdisable tableIcon ml5 mr5 scrollIntial cursor-pointer"} onClick={() => handelActionButtonClick("CALLER_DETAIL", item._id, item)} onKeyPress={(e) => {
              const enterOrSpace =
                e.key === "Enter" ||
                e.key === " " ||
                e.key === "Spacebar" ||
                e.which === 13 ||
                e.which === 32;
              if (enterOrSpace) {
                e.preventDefault();
                handelActionButtonClick("CALLER_DETAIL", item._id, item)
              }
            }}>{CallerAvatar()}</Avatar></Tooltip>
          }
          {actionItems.indexOf("SEND_SURVEY") >= 0 &&
            <Tooltip arrow title="Send Survey"><Avatar tabIndex="0" aria-label="Send Survey Button" role="button" alt="Send Survey Icon" /* src={IconAvatar} */ className={`tablefocusdisable tableIcon ml5 mr5 scrollIntial ${item && item.status ? "cursor-pointer" : ""}`} onClick={() => item && item.status && handelActionButtonClick("SEND_SURVEY", item._id, item, ind)} onKeyPress={(e) => {
              const enterOrSpace =
                e.key === "Enter" ||
                e.key === " " ||
                e.key === "Spacebar" ||
                e.which === 13 ||
                e.which === 32;
              if (enterOrSpace && item && item.status) {
                e.preventDefault();
                handelActionButtonClick("SEND_SURVEY", item._id, item, ind)
              }
            }}>{item && item.status ? PaperPlaneCross() : PaperPlaneCrossGrey()}</Avatar></Tooltip>
          }
        </div>
      </TableCell>
    );
  };

  /**
       * Bind Table Row Cell Value or Value Component
       * Select Field
       */
  const bindSelectField = (item, pathArr) => {

    if (componentMap[pathArr]) {
      const FieldComponent = componentMap[pathArr];
      return <FieldComponent
        key={item._id}
        config={config}
        path={pathArr}
        data={item}
        handleChange={fieldComponentOnChange}
        classes={classes}
        actions={actions.slice()}
        selectedRows={selectedRows}
        componentProps={{ ...componentProps }}
      />;
    } else {
      const arr = pathArr.split(".");
      const value = arr.reduce((obj, key) => (obj && obj[key] !== "undefined")
        ? obj[key]
        : undefined,
        item
      );

      const { title, classAttr } = getTDClassName ? getTDClassName(pathArr, value) : { title: "", classAttr: "" }

      let highlightClass;
      if (item?.isDuplicate) {
        highlightClass = pathArr === 'email' ? 'error' : '';
      }

      if (removeTooltipCols.indexOf(pathArr) >= 0) {
        return <div className={`oneline_ellipse ${classAttr} ${highlightClass}`} aria-label={title}>{value}</div>;
      } else {
        const title_trim = (`${value}` || "").trim() ? value : title
        return <Tooltip arrow aria-label={value} title={title_trim} placement="bottom-start"><div className={`oneline_ellipse ${classAttr} ${highlightClass}`}>{getReadableString(value)} {pathArr === 'name' && getCustomFieldBasedIcon(item)}</div></Tooltip>;
      }
    }
  };

  /**
   * Set Custom Icons Based On Fields
   * @param {*} obj 
   */
  const getCustomFieldBasedIcon = (obj) => {
    return obj[customIconFields] && obj[customIconFields] === "Dashboard Report" ? <span style={{ position: "relative", top: "5px", left: "5px" }}>{HeartInBorderOrange()}</span> : ''
  }

  // Set Pagination Rows Dispaly Message
  const displayPageInfo1 = ((page * rowsPerPage) - rowsPerPage) + 1;
  const displayPageInfo2 = data.length >= rowsPerPage ? page * rowsPerPage : (page * rowsPerPage) - (rowsPerPage - data.length);

  return (
    <div style={{ padding: padding }} className={`${isLeftTable ? classes.leftroot : (isRightTable ? classes.rightroot : classes.root)}`}>
      <div className={`scrollx ${showPagination ? classes.tableContainer : classes.tableCustomContainer}`} style={{ height: height }}>
        <Table
          className={`${isRightTable === false ? classes.table : ""}`}
          id={id}
          aria-live="polite"
          aria-atomic="true"
          aria-relevant="additions text"
        >
          <TableHead>
            {header.length > 0 && tableHeaderRow()}
            {header.length > 0 && searchVisible ? tableFilterRow() : null}
          </TableHead>
          <TableBody>
            {data.length > 0 && tableDataRow()}
          </TableBody>
        </Table>
      </div>
      {showPagination &&
        <React.Fragment>
          <Grid container justifyContent="space-between" alignItems="center" spacing={3} style={{ margin: "-12px" }}>
            <Grid item style={{ padding: "12px" }}>
              {data.length > 0
                ? <TypographyComponent variant="h6" > Displaying {displayPageInfo1} to  {displayPageInfo2} of {count} records</TypographyComponent>
                : <TypographyComponent variant="h6" > {noDataErrorMessage || "No records"}</TypographyComponent>
              }
            </Grid>
            <Grid item style={{ padding: "12px" }}>
              <Pagination count={Math.ceil(count / rowsPerPage)} color={"primary"} shape="rounded" onChange={onChangePagination} page={page} />
            </Grid>
          </Grid>
          {/* <div className={`${"scrollx peTable"}`} style={{ height: height }}></div> */}
        </React.Fragment>
      }
      {
        dynamicPagination &&
        <React.Fragment>
          <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
            <Grid item>
              {data.length > 0
                ? <TypographyComponent variant="h6" >
                  Displaying {displayPageInfo1} to  {displayPageInfo2} records.
                  Total Records: {totalRecords}
                  <IconButton
                    aria-label="Get Total Records Count"
                    title="Get Total Records Count"
                    onClick={() => getTotalRecords()}
                    size="large">
                    {/* <CachedIcon fontSize="small" /> */}
                    <Link>Count</Link>
                  </IconButton>
                </TypographyComponent>
                : <TypographyComponent variant="h6" > {noDataErrorMessage || "No records"}</TypographyComponent>
              }
            </Grid>
            <Grid item>
              <Pagination
                disabled={false}
                hideNextButton={false}
                hidePrevButton={false}
                renderItem={(item) => {
                  return <PaginationItem {...item} disabled={false} />
                }}
                count={0}
                color={"primary"} shape="rounded" onChange={onChangePagination} page={page} />
            </Grid>
          </Grid>
          {/* <div className={`${"scrollx peTable"}`} style={{ height: height }}></div> */}
        </React.Fragment>
      }
      <DeleteDialogComponent
        id={deleteItem.id}
        open={deleteItem.open}
        content={deleteItem.content}
        handleDeleteAction={handleTableDeleteAction}
        handleDeleteOnCloseAction={handleTableDeleteOnCloseAction}
      />
    </div>
  );
}

// prop types
TableComponent.propTypes = {
  height: PropTypes.number,
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  field: PropTypes.array.isRequired,
  search: PropTypes.array.isRequired,
  actions: PropTypes.array,
  actionName: PropTypes.string,
  padding: PropTypes.string,
  links: PropTypes.array,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  removeSortingHeader: PropTypes.array,
  classes: PropTypes.object.isRequired,
  sFields: PropTypes.array,
  sValues: PropTypes.array,
  ellipsis: PropTypes.bool,
  dateFields: PropTypes.array,
  resetSearchText: PropTypes.bool,
  config: PropTypes.object,
  componentMap: PropTypes.object,
  deleteContent: PropTypes.string,
  alignCenterFields: PropTypes.array,
  customDelete: PropTypes.bool,
  showPagination: PropTypes.bool,
  isRightTable: PropTypes.bool,
  isLeftTable: PropTypes.bool,
  removeTooltipCols: PropTypes.array,
  customIconFields: PropTypes.string,
  onChangePagination: PropTypes.func.isRequired,
  onSortRequest: PropTypes.func.isRequired,
  onInputChangeRequest: PropTypes.func,
  handleNavigateAction: PropTypes.func,
  fieldComponentOnChange: PropTypes.func,
  handelActionButtonClick: PropTypes.func,
  handleTableDeleteAction: PropTypes.func,
  customDeleteClickHandle: PropTypes.func,
  actionComponent: PropTypes.object,
  searchVisible: PropTypes.bool,
  infoHeaders: PropTypes.object,
  toggleDrawer: PropTypes.bool,
  selectedIndex: PropTypes.number,
  // customAriaLabel: null
};

// default types
TableComponent.defaultProps = {
  id: "table-new",
  height: 0,
  data: [],
  header: [],
  field: [],
  search: [],
  actions: [],
  actionName: "",
  links: [],
  page: 0,
  rowsPerPage: 0,
  count: 0,
  sortBy: "",
  sort: "asc",
  dateFields: [],
  removeSortingHeader: [],
  classes: {},
  sFields: [],
  sValues: [],
  ellipsis: true,
  resetSearchText: false,
  config: {},
  componentMap: {},
  deleteContent: "",
  alignCenterFields: [],
  customDelete: false,
  showPagination: true,
  isRightTable: false,
  isLeftTable: false,
  removeTooltipCols: [],
  customIconFields: "",
  onChangePagination: () => { },
  onSortRequest: () => { },
  onInputChangeRequest: () => { },
  handleNavigateAction: () => { },
  fieldComponentOnChange: () => { },
  handelActionButtonClick: () => { },
  handleTableDeleteAction: () => { },
  handleClearSearchByField: () => { },
  customDeleteClickHandle: () => { },
  actionComponent: {},
  searchVisible: true,
  infoHeaders: {},
  noDataErrorMessage: "",
  dynamicPagination: false,
  onEnterSearch: null,
  totalRecords: null,
  getTotalRecords: () => { },
  customFilterComponent: {}, // like field component it is for header filter (e-g select all button)
  selectedRows: [],
  componentProps: {},
  actionsTitle: {},
  getTDClassName: null,
  toggleDrawer: false,
  selectedIndex: null,
};

export default withStyles(TableComponentStyle)(TableComponent);
