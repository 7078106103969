import React from 'react';
import Grid from "@mui/material/Grid";

// Panel
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SelectReport from "../../Report/CreateReport/ReportSteppers/SelectReport";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

function ReportSettingsPanel(props) {

    const { classes, currentPanel, onChangePanel, hasMEREPORTS } = props

    return (
        <Grid item xs={12}>
            <Accordion
                classes={{
                    root: classes.peaccordion_nocolor
                }}
                expanded={currentPanel === "REPORT SETTINGS"}
                onChange={() => onChangePanel("REPORT SETTINGS")}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <TypographyComponent variant={"h6"}
                        className={`${"txtBold "} ${currentPanel === "REPORT SETTINGS" ? "panel-active" : ""} `}>
                        REPORT SETTINGS
                    </TypographyComponent>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container>
                        <SelectReport
                            isDrawer
                        />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    )
}

export default ReportSettingsPanel