import React, { useState } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Tooltip from "@mui/material/Tooltip";
import ListItemText from "@mui/material/ListItemText";

// Import Constants
import { FilterConstants } from "../../config/constants";

// Assets
import OptionImg from "../../assets/images/svg/options.svg";

const OptionsMenu = (props) => {

    const { menus, menuOnClick, classes } = props;

    // Dropdown
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <Tooltip arrow title={FilterConstants.MORE_OPTIONS}>
            <IconButton
                className={classes.optionButton}
                aria-describedby={id}
                onClick={handleClick}
                size="large">
                <img src={OptionImg} alt={"Option"} />
            </IconButton>
            </Tooltip>
            {
                menus.length > 0 && (
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        {
                            menus.map(m => (
                                <List component="nav" style={{ padding: "0px" }}>
                                    <ListItem
                                        button
                                        onClick={() => {
                                            menuOnClick(m);
                                            handleClose();
                                        }}
                                    >
                                        <ListItemText primary={m} />
                                    </ListItem>
                                </List>
                            ))
                        }
                    </Popover>
                )
            }
        </React.Fragment>
    );
};

// default props
OptionsMenu.defaultProps = {
    classes: {},
    menus: [],
    menuOnClick: () => { }
};

// prop types
OptionsMenu.propTypes = {
    classes: PropTypes.object,
    menus: PropTypes.array,
    menuOnClick: PropTypes.func
};

export default OptionsMenu;
