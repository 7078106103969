import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { IconButton } from "@mui/material";

// Import Styles and Image
import ProgressPageComponentStyle from "./ProgressPageComponentStyle";
import BackImg from "../../assets/images/svg/chev_back.svg";
import CloseImg from "../../assets/images/svg/close_orange.svg";
import AttentionImg from "../../assets/images/svg/attention.svg";

// Import Component
import TypographyComponent from "../TypographyComponent/TypographyComponent";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import Container from "../../containers/Containers/Container";

const PageSetupPreference = (classes) => {
  return (

    <div className={classes.loaderIcon}>

      <svg className="machine" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 645 526" className={classes.loaderIcon}>
        <defs />
        <g>
          <path x="-173,694" y="-173,694" className="large-shadow" d="M645 194v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L602 68l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L482 10h-21l-4 29c-10 1-19 3-28 6l-14-25 -19 8 7 28c-8 5-16 10-24 16l-23-17L341 68l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L645 194zM471 294c-61 0-110-49-110-110S411 74 471 74s110 49 110 110S532 294 471 294z" />
        </g>
        <g>
          <path x="-136,996" y="-136,996" className="medium-shadow" d="M402 400v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L352 323c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L402 400zM265 463c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C338 430 305 463 265 463z" />
        </g>
        <g >
          <path x="-100,136" y="-100,136" className="small-shadow" d="M210 246v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H100l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L10 225v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L210 246zM110 272c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S131 272 110 272z" />
        </g>
        <g>
          <path x="-100,136" y="-100,136" className="small" d="M200 236v-21l-29-4c-2-10-6-18-11-26l18-23 -15-15 -23 18c-8-5-17-9-26-11l-4-29H90l-4 29c-10 2-18 6-26 11l-23-18 -15 15 18 23c-5 8-9 17-11 26L0 215v21l29 4c2 10 6 18 11 26l-18 23 15 15 23-18c8 5 17 9 26 11l4 29h21l4-29c10-2 18-6 26-11l23 18 15-15 -18-23c5-8 9-17 11-26L200 236zM100 262c-20 0-37-17-37-37s17-37 37-37c20 0 37 17 37 37S121 262 100 262z" />
        </g>
        <g>
          <path x="-173,694" y="-173,694" className="large" d="M635 184v-21l-29-4c-1-10-3-19-6-28l25-14 -8-19 -28 7c-5-8-10-16-16-24L592 58l-15-15 -23 17c-7-6-15-11-24-16l7-28 -19-8 -14 25c-9-3-18-5-28-6L472 0h-21l-4 29c-10 1-19 3-28 6L405 9l-19 8 7 28c-8 5-16 10-24 16l-23-17L331 58l17 23c-6 7-11 15-16 24l-28-7 -8 19 25 14c-3 9-5 18-6 28l-29 4v21l29 4c1 10 3 19 6 28l-25 14 8 19 28-7c5 8 10 16 16 24l-17 23 15 15 23-17c7 6 15 11 24 16l-7 28 19 8 14-25c9 3 18 5 28 6l4 29h21l4-29c10-1 19-3 28-6l14 25 19-8 -7-28c8-5 16-10 24-16l23 17 15-15 -17-23c6-7 11-15 16-24l28 7 8-19 -25-14c3-9 5-18 6-28L635 184zM461 284c-61 0-110-49-110-110S401 64 461 64s110 49 110 110S522 284 461 284z" />
        </g>
        <g>
          <path x="-136,996" y="-136,996" className="medium" d="M392 390v-21l-28-4c-1-10-4-19-7-28l23-17 -11-18L342 313c-6-8-13-14-20-20l11-26 -18-11 -17 23c-9-4-18-6-28-7l-4-28h-21l-4 28c-10 1-19 4-28 7l-17-23 -18 11 11 26c-8 6-14 13-20 20l-26-11 -11 18 23 17c-4 9-6 18-7 28l-28 4v21l28 4c1 10 4 19 7 28l-23 17 11 18 26-11c6 8 13 14 20 20l-11 26 18 11 17-23c9 4 18 6 28 7l4 28h21l4-28c10-1 19-4 28-7l17 23 18-11 -11-26c8-6 14-13 20-20l26 11 11-18 -23-17c4-9 6-18 7-28L392 390zM255 453c-41 0-74-33-74-74 0-41 33-74 74-74 41 0 74 33 74 74C328 420 295 453 255 453z" />
        </g>
      </svg>
    </div>

  );
}

function ProgressPageComponent(props) {
  // get props
  const { classes, redirectURL, title, content, needButton, onButtonClick, uploadResult } = props;
  let meDone = false
  if(content === 'Subjects and raters successfully assigned to the survey.') {
    meDone = true
  }

  return (
    <React.Fragment>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className="p-2"
      >
        <Grid item>
          <Grid container alignItems="center">
            <IconButton
              color="inherit"
              className="p5"
              onClick={() => { redirectURL() }}
              size="large">
              <img className={classes.backimg} src={BackImg} alt="back"></img>
            </IconButton>
            <TypographyComponent
              variant={"h3"}
              className="ml-1"
            >
              {title}
            </TypographyComponent>
          </Grid>
        </Grid>
      </Grid>

      <Container page={"sub"} justifyContent="center">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={classes.fileUploadContainer}
        >
          <Grid item>
            <Grid item sm={12} className="pb-2">
              { !meDone &&
                <Grid container justifyContent="center">
                {PageSetupPreference(classes)}
              </Grid>
              }
              
              <Grid item sm={12} className="p-4">
                <TypographyComponent
                  variant="h3"
                  align="center"
                  className="semibold lineHeight30"
                >
                  {!content ? "Your file is being uploaded. This can take several minutes. You may leave this page and will receive a notification once your import is complete. If you log out, your file will continue processing." : content}
                </TypographyComponent>
              </Grid>
            </Grid>
            <Grid item sm={12}>
              {uploadResult.message &&
                uploadResult.message.map((item, index) => (
                  <TypographyComponent
                    variant="h6"
                    className="mb-1"
                    align="center"
                  >
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="center"
                    >
                      {item.error && (
                        <Grid item xs={12}>
                          <TypographyComponent variant="h6" align="center">
                            <img
                              src={CloseImg}
                              className="mr-1"
                              alt="close" />
                            {item.error}
                          </TypographyComponent>
                        </Grid>
                      )}
                      {item.alert && (
                        <Grid item xs={12}>
                          <TypographyComponent variant="h6" align="center">
                            <img
                              src={AttentionImg}
                              className="mr-1"
                              alt="warning"
                            />
                            {item.alert}
                          </TypographyComponent>
                        </Grid>
                      )}
                    </Grid>
                  </TypographyComponent>
                ))}
            </Grid>
            {
              needButton && (
                <Grid item sm={12} className="pt-5">
                  <Grid container justifyContent="center">
                    <ButtonComponent
                      title={"Done"}
                      color={"primary"}
                      size={"small"}
                      pageClassName="mr-1"
                      handleClick={onButtonClick}
                    ></ButtonComponent>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
ProgressPageComponent.defaultProps = {
  classes: {},
  redirectURL: () => { },
  extraText: "Please wait!!!",
  title: null,
  content: null,
  needButton: false,
  onButtonClick: null,
  uploadResult: {}
};

// prop types
ProgressPageComponent.propTypes = {
  classes: PropTypes.object,
  redirectURL: PropTypes.func,
  extraText: PropTypes.string,
};
export default withStyles(ProgressPageComponentStyle)(ProgressPageComponent);
