// import constants
import { alertConstant, uiConstant, opportunityAreas } from "../constants";
import { baseAction } from "./baseAction";
import { actionsConstant } from "../constants/actionsConstant";

// Import History
import history from "../../config/history";
// import { ActionConstants } from "../../config/constants";
import { actionsService } from "../services";

const { request, success, failure } = baseAction;

export const actionsAction = {
  search,
  getOne,
  create,
  update,
  remove,
  actionplan
};

/**
 * Get All Action Plans
 *
 * @param {*} id  client id to search all users
 * @returns
 */
function search(skip, limit, sortBy, sort, sFields, sValues) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(actionsConstant.ACTION_PLAN_SEARCH_REQUEST));

    actionsService.search(skip, limit, sortBy, sort, sFields, sValues).then(
      (data) => {
        dispatch(success(actionsConstant.ACTION_PLAN_SEARCH_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(actionsConstant.ACTION_PLAN_SEARCH_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Get One Add Plan Action
 *
 * @param {*} id  Action plan id to fetch
 * @returns
 */
function getOne(id) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(actionsConstant.ACTION_PLAN_GET_ONE_REQUEST));

    actionsService.getOne(id).then(
      (data) => {
        dispatch(success(actionsConstant.ACTION_PLAN_GET_ONE_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(actionsConstant.ACTION_PLAN_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Create Action Plan
 *
 * @param {*} data data to be created
 * @returns message
 */
function create(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(actionsConstant.ACTION_PLAN_CREATE_REQUEST));

    actionsService.create(data).then(
      (data) => {
        dispatch(success(actionsConstant.ACTION_PLAN_CREATE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        setTimeout(() => {
          dispatch({
            type: opportunityAreas.TOGGLE_ADD_PLAN_DIALOG,
            data: false
          })
        }, 100)
      },
      (error) => {
        dispatch(failure(actionsConstant.ACTION_PLAN_CREATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Update Action Plan
 *
 * @param {*} id  Action Plan id to update
 * @param {*} updateData data to be updated
 * @returns
 */
function update(data) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(actionsConstant.ACTION_PLAN_UPDATE_REQUEST));

    actionsService.update(data).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));
        dispatch(success(actionsConstant.ACTION_PLAN_UPDATE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        history.replace("/actions?refresh=false&tab=1");
      },
      (error) => {
        dispatch(failure(actionsConstant.ACTION_PLAN_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Remove Action Plan
 *
 * @param {*} id  id to Remove
 * @returns
 */
function remove(id, successCallback = () => { }) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(actionsConstant.ACTION_PLAN_REMOVE_REQUEST));
    actionsService.remove(id).then(
      (data) => {
        dispatch(request(uiConstant.LOADING_FALSE));

        if (successCallback !== undefined) {
          successCallback();
        } else {
          history.push({
            pathname: "/actions"
          });
          dispatch(request(uiConstant.LOADING_FALSE));
        }
        dispatch(success(actionsConstant.ACTION_PLAN_REMOVE_SUCCESS, data));
        setTimeout(() => {
          if(data.status!=="failed"){
            dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
          }
          else{
            dispatch(failure(alertConstant.ALERT_ERROR, data.message));
          }
        }, 100);
      },
      (error) => {
        dispatch(failure(actionsConstant.ACTION_PLAN_REMOVE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 *get Action plan data
 */
function actionplan(data) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(actionsConstant.ACTION_PLAN_DATA_REQUEST));
    actionsService.actionplan(data).then(
      (data) => {
        dispatch(success(actionsConstant.ACTION_PLAN_DATA_SUCCESS, data));
        // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(actionsConstant.ACTION_PLAN_DATA_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
};
