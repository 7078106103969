import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child route
import FailedPage from "./FailedPage";
import ImportPage from "./ImportPage";
import SuccessPage from "./SuccessPage";

function UploadParticipantRouter (props) {
  // get props
  const { match } = props;

  return (
    <Switch>
      <Route exact path={`${match.url}/upload`} render={(props) => {
        return <ImportPage
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/success`} render={(props) => {
        return <SuccessPage
          match={props.match}
          {...props} />;
      }} />
      <Route exact path={`${match.url}/fail`} render={(props) => {
        return <FailedPage
          match={props.match}
          {...props} />;
      }} />
    </Switch>
  );
}
// default props
UploadParticipantRouter.defaultProps = {
  match: {}
};

// prop types
UploadParticipantRouter.propTypes = {
  match: PropTypes.object
};
export default UploadParticipantRouter;
