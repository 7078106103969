import { reportConstant } from "../constants";

const tableDatas = (sortName) => {
  return {
    data: [],
    reportName: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: []
      }
    }
  };
};

const reportDatas = () => {
  const data = {
    surveys: [],
    date: {
      type: "",
      startDate: "",
      endDate: "",
      pulseDate: "",
    },
    demographics: {},
    reportType: "",
    selectedDemographic: "",
    selectType: "",
    chosensurveytype: "",
    name: "",
    emailIds: [],
    frequency: "",
    isPulse: false,
  }
  return JSON.parse(JSON.stringify(data))
}
const initReportDatas = () => {
  const data = {
    surveys: [],
    date: {
      type: "",
      startDate: "",
      endDate: "",
      pulseDate: "",
    },
    demographics: {},
    reportType: "",
    selectedDemographic: "",
    chosensurveytype: "",
    selectType: "",
    name: "",
    emailIds: [],
    frequency: "",
    isPulse: false,
    subject_name: "",
    selectedSubject: null // for Individual report
  }
  return JSON.parse(JSON.stringify(data))
}

// Set Initial State
const initialState = {
  allSurveys: [],
  allDemographics: {},
  allDemographicsItems: {},
  rawData: {},
  reportData: reportDatas(),
  initReportData: initReportDatas(),
  commentQuestions: [],
  commentReport: {
    commentQuestions: []
  },
  savedReports: { ...tableDatas("name") },
  benchmarkIndustryList: [],
  subjects: [], // for ME Survey individual report
  loaders: {
    reportloader: false,
    Createreport: false,
  },
  loading_r: {},
};

export default function (state = initialState, action) {
  switch (action.type) {

    //get reports
    case reportConstant.GET_SAVED_REPORT_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, Createreport: true }
      };
    case reportConstant.GET_SAVED_REPORT_SUCCESS:
      return {
        ...state,
        savedReports: {
          data: action.data.data,
          page: action.data.page,
          reportName: action.data.reportName
        },
        loaders: { ...state.loaders, Createreport: false }
      };
    case reportConstant.GET_SAVED_REPORT_FAILURE:
      return {
        ...state,
        savedReports: initialState.savedReports,
        loaders: { ...state.loaders, Createreport: false }

      };

    //get Survy  List
    case reportConstant.GET_SURVEYS_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }

      };
    case reportConstant.GET_SURVEYS_SUCCESS:
      return {
        ...state,
        allSurveys: action.data.data,
        loaders: { ...state.loaders, reportloader: false }
      };
    case reportConstant.GET_SURVEYS_FAILURE:
      return {
        ...state,
        allSurveys: initialState.allSurveys,
        loaders: { ...state.loaders, reportloader: false }
      };

    // Get Demographic 
    case reportConstant.GET_DEMOGRAPHICS_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }
      };
    case reportConstant.GET_DEMOGRAPHICS_SUCCESS:
      return {
        ...state,
        allDemographics: action.data.data || {},
        loaders: { ...state.loaders, reportloader: false }
      };
    case reportConstant.GET_DEMOGRAPHICS_FAILURE:
      return {
        ...state,
        allDemographics: initialState.demographics,
        loaders: { ...state.loaders, reportloader: false }
      };

    // Get Demographic items 
    case reportConstant.GET_DEMOGRAPHICS_BY_NAME_REQUEST:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }
      };
    case reportConstant.GET_DEMOGRAPHICS_BY_NAME_SUCCESS:
      return {
        ...state,
        allDemographicsItems: { ...state.allDemographicsItems, ...action.data.data },
        loaders: { ...state.loaders, reportloader: false }
      };
    case reportConstant.GET_DEMOGRAPHICS_BY_NAME_FAILURE:
      return {
        ...state,
        allDemographicsItems: initialState.allDemographicsItems,
        loaders: { ...state.loaders, reportloader: false }
      };

    // Get Demographic items 
    case reportConstant.GET_COMMENT_QUESTIONS_REQUEST:
      return {
        ...state,
      };
    case reportConstant.GET_COMMENT_QUESTIONS_SUCCESS:
      return {
        ...state,
        commentQuestions: action.data.data,
      };
    case reportConstant.GET_COMMENT_QUESTIONS_FAILURE:
      return {
        ...state,
        commentQuestions: initialState.commentQuestions
      };


    //Save or update Report 
    case reportConstant.SAVE_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.SAVE_REPORT_SUCCESS:
      return {
        ...state,
      };
    case reportConstant.SAVE_REPORT_FAILURE:
      return {
        ...state,
      };

    //Remove Report 
    case reportConstant.REMOVE_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.REMOVE_REPORT_SUCCESS:
      return {
        ...state,
      };
    case reportConstant.REMOVE_REPORT_FAILURE:
      return {
        ...state,
      };


    //get selected report data Report 
    case reportConstant.GET_REPORT_REQUEST:
      return {
        ...state,
      };
    case reportConstant.GET_REPORT_SUCCESS:
      const data = action.data.data;
      let commentQuestions = []
      if (data.reportType === "Comment Report") {
        commentQuestions = data.commentQuestions ? data.commentQuestions : []
      }
      return {
        ...state,
        reportData: { ...initialState.reportData, ...action.data.data },
        commentReport: { ...initialState.commentReport, commentQuestions: commentQuestions }
      };
    case reportConstant.GET_REPORT_FAILURE:
      return {
        ...state,
        reportData: { ...initialState.reportData },
        commentReport: { ...initialState.commentReport }
      };

    //update report data 
    case reportConstant.UPDATE_REPORT_DATA:
      return {
        ...state,
        reportData: { ...initialState.reportData, ...action.data }
      };

    //reset report data 
    case reportConstant.RESET_REPORT_DATA:
      return {
        ...state,
        reportData: { ...initialState.initReportData, emailIds: [] },
        commentReport: { commentQuestions: [] },

      };

    //update commnet report data 
    case reportConstant.UPDATE_COMMENT_REPORT_DATA:
      return {
        ...state,
        commentReport: { ...initialState.commentReport, ...action.data }
      };

    // Raw Data
    case reportConstant.GET_RAW_DATA_REQUEST,
      reportConstant.GET_RAW_DATA_FAILURE:
      return {
        ...state,
        rawData: initialState.getOne
      };
    case reportConstant.GET_RAW_DATA_SUCCESS:
      return {
        ...state,
        rawData: action.data.data
      };

    // Get Benchmark Industry List 
    case reportConstant.GET_BENCHMARK_INDUSTRY_LIST_REQUEST:
      return {
        ...state,
        loading_r: {
          ...state.loading_r,
          'industry': true
        }
      };
    case reportConstant.GET_BENCHMARK_INDUSTRY_LIST_SUCCESS:
      return {
        ...state,
        benchmarkIndustryList: action.data.data,
        loading_r: {
          ...state.loading_r,
          'industry': false
        }
      };
    case reportConstant.GET_BENCHMARK_INDUSTRY_LIST_FAILURE:
      return {
        ...state,
        benchmarkIndustryList: initialState.benchmarkIndustryList,
        loading_r: {
          ...state.loading_r,
          'industry': false
        }
      };
    case reportConstant.REPORT_LOADING_TRUE:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: true }
      };
    case reportConstant.REPORT_LOADING_FALSE:
      return {
        ...state,
        loaders: { ...state.loaders, reportloader: false }
      };
    // Get Subjects list for ME IR 
    case reportConstant.GET_IR_ME_SUBJECTS_LIST_REQUEST:
      return {
        ...state
      };
    case reportConstant.GET_IR_ME_SUBJECTS_LIST_SUCCESS:
      return {
        ...state,
        subjects: action.data.data || []
      };
    case reportConstant.GET_IR_ME_SUBJECTS_LIST_FAILURE:
      return {
        ...state,
        subjects: []
      };
    //retturn default state
    default:
      return state;
  }
}
