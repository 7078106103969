/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

// Import Helpers
import { getReducerUpdatedData } from "../../../../../helpers";

// Import Actions
import { categoryAction, alertAction } from "../../../../../redux/actions";

// import constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

function SelectDemographics(props) {
  const dispatch = useDispatch();

  // Get state data
  const demographicsName = useSelector((state) => state.metadata.demographicsName);


  // Get Props Values
  const { category, questionDataSet, questionData, item, classes } = props;
  const { questions } = questionData;

  //intention to stay demograpic value 
  const intentionDemograpichData = demographicsName.find((item) => item.name == "IntentiontoStay");
  const demographicList = demographicsName.filter((item) => item.name !== "IntentiontoStay" && item.name !== "Remote or In Office" && item.name !== "manager_id" && item.name !== "manager_role" );

  // Change demographics value
  const handleDemographicsChange = (event,baseQus) => {
    let duplicateDemographics = false;

    for (let i = 0; i < questions.length; i++) {
      if (questions[i]["RGConversion"] === event.value) {
        duplicateDemographics = true;
      }
    }
    const isIntentionSurvey = questions.find((val)=> baseQus && val.baseQusId === "52") && baseQus!=='52' && (event.value).toLowerCase()==='intentiontostay';

    if (duplicateDemographics) {
      dispatch(alertAction.error(surveyQuestionsConstants.DUPLICATE_DEMOGRAPHICS));
    }
    else if(isIntentionSurvey){
      dispatch(alertAction.error(surveyQuestionsConstants.DUPLICATE_DEMOGRAPHICS));
    }
    else {
      let reducerData = getReducerUpdatedData(event, questionDataSet);
      reducerData["demographicConversion"] = reducerData.RGConversion && reducerData.RGConversion !== "" ? reducerData["demographicConversion"] : false;
      if (!item) {
        dispatch(categoryAction.addOrChangeQuestion(category, reducerData));
      } else {
        props.editQuestionsData(reducerData);
      }
    }
  };

  return (
    <React.Fragment>
      <FormControl className={`${classes.demographicField} width100`}>
        {
          item && item.hasOwnProperty("baseQusId") && item.baseQusId == "52" ?
            <Select
              className={"boderRadius2 height40"}
              name={"RGConversion"}
              id="demographics"
              value={"IntentiontoStay"}
              displayEmpty
              variant="outlined"
              dataValue={item.baseQusId}
              onChange={(e) => handleDemographicsChange(e.target, item.baseQusId)}
              disabled
            >
              <MenuItem name={intentionDemograpichData === undefined ? "IntentiontoStay" : intentionDemograpichData.name } value={intentionDemograpichData === undefined ? "IntentiontoStay" : intentionDemograpichData.name } selected={true}>
                {intentionDemograpichData === undefined ? "Intention to Stay" : intentionDemograpichData.displayName}
              </MenuItem>
            </Select>
            :
            (
              item && item.hasOwnProperty("baseQusId") && item.baseQusId == "229" ?
            <Select
              className={"boderRadius2 height40"}
              name={"RGConversion"}
              id="demographics"
              value={"Remote or In Office"}
              displayEmpty
              variant="outlined"
              disabled  
            >
              <MenuItem name={"Remote or In Office"} value={"Remote or In Office"} selected={true}>
              {"Remote or In Office"}
              </MenuItem>
            </Select>
            :
            <Select
              className={"boderRadius2 height40"}
              name={"RGConversion"}
              id="demographics"
              value={questionDataSet.RGConversion || ""}
              displayEmpty
              variant="outlined"
              onChange={(e) => handleDemographicsChange(e.target,item ?  item.baseQusId : null)}
            >
              <MenuItem className={"txtgrey"} value="">Link to a demographic</MenuItem>
              {demographicList.map((item, keyIndex) => (
                <MenuItem key={keyIndex} name={item.name} value={item.name} >
                  {item.displayName}
                </MenuItem>
              ))}
            </Select>
            )
        }
      </FormControl>
    </React.Fragment>
  );
}

// default props
SelectDemographics.defaultProps = {
  questionDataSet: {},
  item: null,
  category: null,
  questionData: {},
  editQuestionsData: () => { }
};

// prop types
SelectDemographics.propTypes = {
  questionDataSet: PropTypes.object,
  item: PropTypes.object,
  questionData: PropTypes.object,
  category: PropTypes.string,
  editQuestionsData: PropTypes.func
};

export default SelectDemographics;
