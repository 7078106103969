import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";

/**
 *Tool tip component
 *
 * @class ToolTip
 * @extends {React.Component}
 */
function ToolTipComponent (props) {
  /**
   * Bind
   * HTML to DOM
   */
  const { title, placement, children } = props;
  return (
    <Tooltip title={title} placement={placement} arrow>
      {children}
    </Tooltip>
  );
}

// default props
ToolTipComponent.defaultProps = {
  title: "",
  placement: "bottom",
  TooltipComponent: null,
  classes: {},
  children: {}
};

// prop types
ToolTipComponent.propTypes = {
  title: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  TooltipComponent: PropTypes.func,
  classes: PropTypes.object,
  children: PropTypes.object
};

// export component
export default ToolTipComponent;
