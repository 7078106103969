import moment from "moment";
import { alertConstant, uiConstant, meTakeSurveyConstant } from "../constants";
import { meTakeSurveyService } from "../services";
import { baseAction } from "./baseAction";
import { browserName, osName } from "react-device-detect";

const { request, success, failure } = baseAction;

// Export All Survey Actions Functions
export const meTakeSurveyAction = {
    getSurvey,
    getSurveyRaters,
    search,
    handleSurveyResponse,
    updateSurveyResponse,
    updateRequiredQuestions,
    updateSurveyLanguage,
    clearMeSurveyData
};

/**
 * Get Survey
 * id => surveyId
 * pId=> participantId default null
 */
function getSurvey(params) {
    return (dispatch, getState) => {
        if (params) {
            dispatch(request(uiConstant.LOADING_TRUE));
            dispatch(request(meTakeSurveyConstant.ME_SURVEY_GET_ONE_REQUEST));
            const surveyGetDataQuery = { ...params, browserName };
            meTakeSurveyService.getOne(surveyGetDataQuery).then(
                (data) => {
                    let actionData = data && data.data ? data.data : {};
                    let surveyActionData = {
                        ...actionData
                    };

                    dispatch(success(meTakeSurveyConstant.ME_SURVEY_GET_ONE_SUCCESS, surveyActionData));
                    dispatch(request(uiConstant.LOADING_FALSE));
                },
                (error) => {
                    dispatch(failure(meTakeSurveyConstant.ME_SURVEY_GET_ONE_FAILURE, error));
                    dispatch(failure(alertConstant.ALERT_ERROR, error));
                    dispatch(request(uiConstant.LOADING_FALSE));
                }
            );
        } else {
            dispatch(success(meTakeSurveyConstant.ME_SURVEY_GET_ONE_CLEAR, null));
        }
    };
}

/**
 * Get All Raters
 *
 * @param {*} id  client id to search all users
 * @returns
 */
function search(skip, limit, sortBy, sort, sFields, sValues, shortUrl) {
    return (dispatch) => {
        dispatch(request(uiConstant.LOADING_TRUE));
        dispatch(request(meTakeSurveyConstant.ME_TAKESURVEY_SEARCH_REQUEST));
        meTakeSurveyService.search(skip, limit, sortBy, sort, sFields, sValues, shortUrl).then(
            (data) => {
                dispatch(success(meTakeSurveyConstant.ME_TAKESURVEY_SEARCH_SUCCESS, data));
                // dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
                dispatch(request(uiConstant.LOADING_FALSE));
            },
            (error) => {
                dispatch(failure(meTakeSurveyConstant.ME_TAKESURVEY_SEARCH_FAILURE, error));
                dispatch(failure(alertConstant.ALERT_ERROR, error));
                dispatch(request(uiConstant.LOADING_FALSE));
            }
        );
    };
}


/**
 * Get Survey Raters
 * id => shortId
 * pId=> participantId default null
 */
function getSurveyRaters(params) {
    return (dispatch, getState) => {
        if (params) {

            dispatch(request(uiConstant.LOADING_TRUE));
            dispatch(request(meTakeSurveyConstant.ME_SURVEY_GET_RATERS_REQUEST));
            meTakeSurveyService.getSurveyRaters(params).then(
                (data) => {
                    let actionData = data && data.data ? data.data : {};
                    dispatch(success(meTakeSurveyConstant.ME_SURVEY_GET_RATERS_SUCCESS, actionData));
                    dispatch(request(uiConstant.LOADING_FALSE));
                },
                (error) => {
                    dispatch(failure(meTakeSurveyConstant.ME_SURVEY_GET_RATERS_FAILURE, error));
                    dispatch(failure(alertConstant.ALERT_ERROR, error));
                    dispatch(request(uiConstant.LOADING_FALSE));
                }
            );
        } else {
            dispatch(success(meTakeSurveyConstant.ME_SURVEY_GET_RATERS_CLEAR, null));
        }
    };
}


function handleSurveyResponse(question, answer, langCode, responseEN = null) {
    return (dispatch, getState) => {
        const state = getState();
        const { response, surveyRequiredQuestions, validatedQues, rgResponses, questions, surveyLang } = state.meTakeSurvey;
        if (question) {
            const { baseQusId, category, maxAnsScale, qusType, text, isRequired, RGConversion, ansSet, subQues } = question;

            const questionText = text["en"]; //text[langCode];
            let quesResponse = {
                baseQusId,
                category,
                qusType,
                question: questionText,
                answer,
                maxAnsScale,
            };

            if (qusType == "Matrix") {
                const langs = Object.keys(ansSet);
                let newAnsSet = {};
                langs.forEach((lang) => {
                    newAnsSet[lang] = answer;
                });
                quesResponse = {
                    ...quesResponse,
                    answer: newAnsSet,
                    subQuestions: subQues,
                    answerSet: ansSet,
                };
            }

            if (qusType == "Dropdown") {
                quesResponse = {
                    ...quesResponse,
                    answer: responseEN,
                    answer_native: answer,
                    subQuestions: subQues,
                    answerSet: ansSet,
                };
            }


            let newRgResponse = { ...rgResponses };
            if (RGConversion && answer) {
                let answerTxt = "";
                let answerOri = answer
                if (qusType == "Yes/No") {
                    answerTxt = answerOri === 1 ? "Yes" : "No";
                } else if (qusType == "Single Select") { // always english for demographic fields no translate needed
                    const ansSet = question.ansSet["en"];
                    answerTxt = ansSet[answerOri];
                } else if (qusType == "Dropdown") { // always english for demographic fields no translate needed
                    answerOri = responseEN || answer;
                    answerTxt = responseEN || answer;
                } else {
                    answerTxt = answerOri;
                }

                newRgResponse = {
                    ...rgResponses,
                    [RGConversion]: answerTxt,
                };
                // newRgResponse = { ...rgResponses, [RGConversion]: answer};
                quesResponse = {
                    ...quesResponse,
                    RGConversion: {
                        column: RGConversion,
                        answer: answerOri,
                        answerTxt: answerTxt,
                    },
                };
            } else if (rgResponses.hasOwnProperty(RGConversion) && !answer) {
                newRgResponse = delete rgResponses[RGConversion];
            }

            if (isRequired) {
                if (Array.isArray(answer)) {
                    surveyRequiredQuestions[baseQusId] = answer.length > 0 ? true : false;
                } else {
                    surveyRequiredQuestions[baseQusId] = answer || answer === 0 ? true : false;
                }
            }

            //remove new response questions on existing answered questions
            const newResponse = response.length > 0 ? response.filter((res) => res.baseQusId != baseQusId && (res.answer || res.answer === 0)) : [];

            let errorQuestions = [];
            if (newResponse.length > 0) {
                validatedQues.forEach((qid) => {
                    const reqStatus = newResponse.filter((res) => qid === res.baseQusId && (res.answer || res.answer === 0));
                    if (!reqStatus) {
                        errorQuestions.push(qid);
                    }
                });
            } else if (Object.keys(surveyRequiredQuestions).length > 0) {
                let requiredQusKeys = Object.keys(surveyRequiredQuestions);
                errorQuestions = requiredQusKeys.filter((qKey) => !surveyRequiredQuestions[qKey]);
            } else {
                errorQuestions = [...validatedQues];
            }
            let surveyResponse = [];

            if (Array.isArray(answer)) {
                surveyResponse = answer.length > 0 ? [...newResponse, quesResponse] : newResponse;
            } else if (answer && typeof answer === "object" && Object.keys(answer).length === 0) {
                surveyResponse = newResponse;
            } else {
                surveyResponse = answer || answer === 0 ? [...newResponse, quesResponse] : newResponse;
            }

            const requiredQuesList = errorQuestions;

            //survey completion percentage calculation
            const totalQuestions = questions.questions;
            const participated = surveyResponse.filter((ans) => ans.answer || ans.answer === 0);

            const surveyPercentage = (parseInt(participated.length) / parseInt(totalQuestions.length)) * 100;
            const actionData = {
                surveyResponse,
                surveyRequiredQuestions,
                requiredQuesList,
                rgResponses: newRgResponse,
                surveyPercentage,
            };
            dispatch(success(meTakeSurveyConstant.ME_SURVEY_RESPONSE_UPDATE, actionData));
        }
    };
}

function updateSurveyResponse(actionType) {
    return (dispatch, getState) => {
        const { meTakeSurvey } = getState();
        const { response, getOne, participant, rgResponses, surveyLang, surveyDeviceType, completionPercentage, surveyUpdateFlag, callerId, spCallerHistory, takeSurveyUrl, surveyStartsAt, assignedDate, meParticipant } = meTakeSurvey;

        const surveyStarts = moment(surveyStartsAt);
        const currentTime = moment();
        const takeSurveyDuration = currentTime.diff(surveyStarts, "seconds");
        const survey_id = getOne._id;
        const participant_id = participant._id ? participant._id : "";
        const rg_responses = rgResponses ? rgResponses : null;

        let data = {
            response,
            survey_id,
            participant_id,
            rg_responses,
            browserName,
            osName,
            langCode: surveyLang,
            deviceType: surveyDeviceType,
            pctCompleted: completionPercentage,
            surveyUpdateFlag,
            actionType,
            callerId,
            spCallerHistory,
            takeSurveyDuration,
            takeSurveyUrl: takeSurveyUrl ? takeSurveyUrl : "shortUrl",
            assignedDate,
            meParticipant,
        };

        if (response) {
            dispatch(request(uiConstant.LOADING_TRUE));
            dispatch(request(meTakeSurveyConstant.ME_SURVEY_RESPONSE_UPDATE_REQUEST));

            let apiUrl = "me-takesurvey/saveSurvey";


            meTakeSurveyService.updateSurveyResponse(data, apiUrl).then(
                (data) => {
                    const respData = { data: data, actionType: actionType };
                    dispatch(success(meTakeSurveyConstant.ME_SURVEY_RESPONSE_UPDATE_SUCCESS, respData));
                    dispatch(request(uiConstant.LOADING_FALSE));
                },
                (error) => {
                    dispatch(failure(meTakeSurveyConstant.ME_SURVEY_RESPONSE_UPDATE_FAILURE, error));
                    dispatch(failure(alertConstant.ALERT_ERROR, error));
                    dispatch(request(uiConstant.LOADING_FALSE));
                }
            );
        }
    };
}

function updateRequiredQuestions(questions) {
    return (dispatch) => {
        if (questions) {
            dispatch(success(meTakeSurveyConstant.ME_SURVEY_REQUIRED_QUESTIONS_UPDATE, questions));
        }
    };
}

function updateSurveyLanguage(language) {
    return (dispatch) => {
        if (language) {
            dispatch(success(meTakeSurveyConstant.ME_TAKE_SURVEY_UPDATE_LANGUAGE, language));
        }
    };
}

function clearMeSurveyData(callback = null) {
    return (dispatch) => {
        dispatch(success(meTakeSurveyConstant.CLEAR_ME_TAKE_SURVEY_DATA));
        callback();
    };
}