import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// import child route
import BulkDeleteOption from "./BulkDeleteOptions";
import FailedPage from "./FailedPage";
import ImportPage from "./ImportPage";
import SuccessPage from "./SuccessPage";

function BulkDeleteRouter(props) {
    // get props
    const { match } = props;

    return (
        <Switch>
            <Route exact path={`${match.url}`} render={(props) => {
                return <BulkDeleteOption
                    match={props.match}
                    {...props} />;
            }} />
            <Route exact path={`${match.url}/upload`} render={(props) => {
                return <ImportPage
                    match={props.match}
                    {...props} />;
            }} />
            <Route exact path={`${match.url}/success`} render={(props) => {
                return <SuccessPage
                    match={props.match}
                    {...props} />;
            }} />
            <Route exact path={`${match.url}/fail`} render={(props) => {
                return <FailedPage
                    match={props.match}
                    {...props} />;
            }} />
        </Switch>
    );
}
// default props
BulkDeleteRouter.defaultProps = {
    match: {}
};

// prop types
BulkDeleteRouter.propTypes = {
    match: PropTypes.object
};
export default BulkDeleteRouter;
