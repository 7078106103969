import React , {useEffect} from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { AppBar } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useSelector ,useDispatch} from "react-redux";

// Import Constants
import { externalApiConstants } from "../../config/constants";

// Import History
import history from "../../config/history";

// Import Styles
import ExternalApiIntegrationStyles from "./ExternalApiIntegrationStyles";



// Set Tabs Id and Control Name
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const ExternalApiTabs = (props) => {
    // Define Dispatch
    const dispatch = useDispatch();
    //Get props
    const { classes, match, location } = props;
    
    const externalApiConfig = useSelector(state => state.externalApiConfig);
    
    // Handle Tabs Change Event
    const handleChange = (event, newValue) => {
        if(newValue === match.url){
            history.push({
                pathname: newValue
            });
        } else if(newValue === `${match.url}/generate-token`){
            history.push({
                pathname: newValue
            })
        }
    };
    return (
        <Grid item>
            <AppBar
                position="static"
                color="default"
                className={`${classes.rightPanelAppBarRoot} ${"tabborder_none pe_color_appbar"}`}
            >
                <Tabs
                    value={location.pathname}
                    onChange={handleChange}
                    component="div"
                    variant="scrollable"
                    scrollButtons="auto"
                    className={classes.rightPanelTabsRoot}>
                    <Tab
                        label={externalApiConstants.TABS_LIST[0]}
                        value={`${match.url}`}
                        classes={{
                            root: classes.rightPanelTabRoot,
                            selected: classes.rightPanelTabSelected,
                        }}
                        {...a11yProps(0)} />
                    <Tab
                        label={externalApiConstants.TABS_LIST[1]}
                        value={`${match.url}/configuration`}
                        classes={{
                            root: classes.rightPanelTabRoot,
                            selected: classes.rightPanelTabSelected,
                        }}
                        {...a11yProps(1)} />
                    {/* <Tab
                        label={externalApiConstants.TABS_LIST[2]}
                        value={`${match.url}/history-logs`}
                        classes={{
                            root: classes.rightPanelTabRoot,
                            selected: classes.rightPanelTabSelected,
                        }}
                        {...a11yProps(2)} /> */}
                </Tabs>
            </AppBar>
        </Grid>
    )
}
// default props
ExternalApiTabs.defaultProps = {
    classes: {},
    match: {},
    location: {}
};

// prop types
ExternalApiTabs.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object
};
export default withStyles(ExternalApiIntegrationStyles)(ExternalApiTabs);