import { Grid } from "@mui/material"
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
// import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import { updateStateReducerAction, contentLibraryAction } from "../../../../redux/actions";


import { contentLibraryConstant } from "../../../../redux/constants";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import { TrashSurvey } from "../../../../assets/images/svgComponents/ImageComponent";


function RecomendationCard(props) {

    const { classes, item, questions, index } = props;
    const dispatch = useDispatch();

    const [editable, setEditable] = useState(false);


    // Update reducer data
    const updateReducer = (questions) => {
        const reducerData = questions;
        dispatch(updateStateReducerAction.update(contentLibraryConstant.CONTENTLIBRARY_RECOMMENDATION_QUESTIONS_REDUCER_UPDATE, reducerData));
    };


    const editOnClick = (event) => {
        const toggle = !editable;
        setEditable(toggle);
        questions.filter((alerts, ind) => {
            if (alerts.baseQusId === item.baseQusId) {
                questions[ind].editable = true;
            }
            return true
        });
        updateReducer(questions);
        return true;
    };




    return (
        <Grid className="width100 mt-2">
            <Grid className={`${classes.action_alert_item} dflex`} onClick={editOnClick}>
                <Grid item className={"pl-1 pr-2 selfcenter"}>
                    <Badge
                        color="secondary"
                        className={`badgebig`}
                        badgeContent={item.baseQusId}
                        max={400}
                    ></Badge>
                </Grid>
                <Grid item sm={12} className={"pl5 dflex spacebetween"}>
                    <Grid item sm={12} className={"selfcenter dflex"}>
                        <Grid item sm={2}>
                            <TypographyComponent
                                className={classes.recommendation_question_card}
                                variant={"h6"}>
                                {item.category.Default.en.name}
                            </TypographyComponent>
                        </Grid>
                        <Grid item sm={8}>
                            <TypographyComponent
                                className={``}
                                variant={"h6"}>
                                {item.qusText.Default.en}
                            </TypographyComponent>
                        </Grid>
                        {/* <Grid item sm={2}>
                            <Link
                                color="primary">
                                {"In PDF only"}
                            </Link>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default RecomendationCard;

