import { peopleConstant } from "../constants";

// Set Initial Table Data
const tableDatas = (sortName) => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: []
      }
    }
  };
};


// Set Initial State
const initialState = {
  getOne: {},
  getAll: [],
  search: { ...tableDatas("firstName") },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case peopleConstant.PEOPLE_GET_ONE_REQUEST:
      return {
        ...state
      };
    case peopleConstant.PEOPLE_GET_ONE_SUCCESS:
      return {
        ...state,
        getOne: action.data.data
      };
    case peopleConstant.PEOPLE_GET_ONE_FAILURE:
      return {
        ...state,
        getOne: initialState
      };

    // UPDATE GET ONE REDUCER
    case peopleConstant.PEOPLE_GET_ONE_UPDATE:
      return {
        ...state,
        getOne: action.data
      }

    // Initial Profile
    case peopleConstant.PEOPLE_GET_ONE_INIT:
      return {
        ...state,
        getOneInit: action.data.data
      };

    /**
     * To get All participants
     */
    case peopleConstant.PEOPLE_GET_ALL_REQUEST:
      return {
        ...state
      };
    case peopleConstant.PEOPLE_GET_ALL_SUCCESS:
      return {
        ...state,
        getAll: action.data.data
      };
    case peopleConstant.PEOPLE_GET_ALL_FAILURE:
      return {
        ...state,
        getAll: initialState
      };

    //UPDATE MY_PROFILE
    case peopleConstant.PEOPLE_UPDATE_REQUEST:
      return {
        ...state
      };
    case peopleConstant.PEOPLE_UPDATE_SUCCESS:
      return {
        ...state
      };
    case peopleConstant.PEOPLE_UPDATE_FAILURE:
      return {
        ...state
      };

    default:
      return state;
  }
}
