import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from '@mui/styles/makeStyles';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


// Custom Style
const useStyles = makeStyles({
  root: {
    position: "relative",
    textAlign: "center",
    padding: "100px",
    height: "100%"
  },
  icon: {
    width: "100px",
    height: "100px",
    color: "#54C0FD"
  }
});

function SamlErrorPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className="height100"
      >
        <Grid item xs>
          <ReportProblemIcon className={classes.icon}></ReportProblemIcon>
          <Typography variant="h2" className="mb-2 txtBold">
            Error to login.
          </Typography>

          <Typography variant="h4">
            Please contact your network administrator for Single Sign On support
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default SamlErrorPage;
