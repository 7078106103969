import React from "react";
import { useSelector } from "react-redux";
import makeStyles from '@mui/styles/makeStyles';
import LinearProgress from "@mui/material/LinearProgress";

const useStyles = makeStyles({
  root: {
    position: "absolute",
    width: "100%",
    top: "54px",
    height: "3px",
    zIndex: "2"
  },
  "@media screen and (max-width:768px)": {
    root: {
      top: top ? "70px" : "54px"
    }
  }
});

const LinearProgressComponent = (props) => {
  const { top } = props;
  const classes = useStyles();
  const UI = useSelector((state) => state.UI);

  if (!UI.loading) { return null; }
  return (
    <LinearProgress
      className={classes.root}
      size={20}
      thickness={1}>
    </LinearProgress>
  );
};

export default LinearProgressComponent;
