import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";

import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";

import { getReducerUpdatedData } from "../../../..//helpers";
import { contentLibraryAction } from "../../../../redux/actions";
import { surveySettingsConstants } from "../../../../config/constants";


function Transflo(props) {
  const { classes, data, messageData, expanded } = props;
  const dispatch = useDispatch();


  const handleTextChange = (event) => {
    handleChange({ name: event.target.name, value: event.target.value });
  };

  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, data);
    props.editMessageContent(reducerData)
  };


  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  const saveMessageData = () => {
    delete data.updatedData;
    messageData.filter((msg, index) => {
      if (msg.type === data.type) {
        messageData[index] = data
      }
    })
    dispatch(contentLibraryAction.updateMessage(messageData));
  };

  const closeMessage = () => {
    props.handleCancelMessage()
  };

  return (
    <React.Fragment>
      <Accordion
        className="mb-2 pe-accordion"
        expanded={expanded === "transflo-panel"}
        onChange={handleTabChange("transflo-panel")}
      >

        {/*
      panel header summary
    */}

        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expandicon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyComponent
            className={` ${classes.setting_accordheading} ${expanded === "transflo-panel" ? "panel-active" : ""} `}
            variant={"h5"}
          >{"Transflo"}</TypographyComponent>
        </AccordionSummary>

        <AccordionDetails>
          <Grid container spacing={3} className={`${classes.setting_expandcontainer} `}>
            <Grid item xs={12}>
              <Grid container item xs={12}>
                <Grid item md={10} xs={12}>
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.TRANSFLO_SUBJECT}
                  </TypographyComponent>
                  <TextFieldComponent
                    id="transflo.subject"
                    name={`transflo.subject`}
                    size={"small"}
                    fullWidth={true}
                    variant="outlined"
                    margin="none"
                    value={data.transflo ? data.transflo.subject : ""}
                    handleChange={(e) => handleChange(e)}
                    className={`${classes.settings_input} ${"boderRadius2"} `}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={10} xs={12}>
              <TypographyComponent variant="h6">
                {surveySettingsConstants.TRANSFLO_MESSAGE}
              </TypographyComponent>
              <Grid className={classes.txtborder}>
                <Grid container alignItems="center" className={`bgwhite `}>
                  <Grid item sm={2}>
                    <TextFieldComponent
                      id="transflo.greetings"
                      name={`transflo.greetings`}
                      size={"medium"}
                      fullWidth={true}
                      variant="outlined"
                      margin="none"
                      value={data.transflo ? data.transflo.greetings : ""}
                      handleChange={(e) => handleChange(e)}
                      className={`${"br-0 br-none m-0"} ${classes.distri_emailmsg}  `} />
                  </Grid>
                  <Grid item sm={10}>
                    {surveySettingsConstants.GREETINGS_CONSTANT_NAME}
                  </Grid>
                </Grid>
                <TextareaAutosize
                  className={` width100 pe_textarea mb-m7`}
                  name={`transflo.body`}
                  value={data.transflo ? data.transflo.body : ""}
                  onChange={(e) => handleTextChange(e)}
                  minRows={3} />
              </Grid>
            </Grid>
            <Grid item md={10} xs={12}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item>
                  <ButtonComponent
                    title={"OK"}
                    color={"primary"}
                    size={"small"}
                    handleClick={() => saveMessageData()}
                    pageClassName={classes.settings_addrulebtn}
                  ></ButtonComponent>
                  <ButtonComponent
                    title={"Cancel"}
                    color={"secondary"}
                    size={"small"}
                    handleClick={() => closeMessage()}
                    pageClassName="ml-1"
                  ></ButtonComponent>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

    </React.Fragment>
  );
}

export default Transflo;

