import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Import Styles and Image
import ReportsStyles from "./ReportsStyles";

// Import History
import history from "../../config/history";

import { updateStateReducerAction } from "../../redux/actions"

import { dashboardConstant } from "../../redux/constants"

// Import constants
import { userRoles, userAccess, rolesAccess, reportAccess, accessReportsList } from "../../config/pageAccess";

// Set Tabs Id and Control Name
function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

function ReportsTabs(props) {

	const dispatch = useDispatch();

	//Get props
	const { classes, match, location } = props;

	// Redux Selectors
	const { user } = useSelector(state => state.profile);
	const { preference  } = useSelector(state => state.admin);

	let me_survey = preference?.['surveys']?.[0] ? (preference?.['surveys'] || []).some(survey => survey.type === "Multi-rater feedback") : preference?.['surveys']?.type === "Multi-rater feedback";

	if(location.pathname == "/reports/comment") {
		if(!user.access.includes("Comments") || me_survey){
			history.push({
				pathname: "/reports"
			})
		}
	}

	useEffect(() => {
		dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_TAB_NAME, location.pathname))
	})

	// Handle Tabs Change Event
	const handleChange = (event, newValue) => {
		history.push({
			pathname: newValue
		});
	};

	return (
		<Grid item>
			<AppBar
				position="static"
				color="default"
				className={`${classes.rightPanelAppBarRoot} ${"tabborder_none pe_color_appbar"}`}>
				<Tabs
					value={location.pathname}
					onChange={handleChange}
					component="div"
					variant="scrollable"
					scrollButtons="auto"
					aria-label="simple tabs example"
					className={classes.rightPanelTabsRoot}>
					{(accessReportsList('/reports', user).R_CURRENT_PAGE) &&
						<Tab
							value={`${match.url}`}
							label="Dashboard" {...a11yProps(0)}
							style={{ width: "95px" }}
							classes={{
								root: classes.rightPanelTabRoot,
								selected: classes.rightPanelTabSelected,
							}}
						/>
					}
					{(accessReportsList('/reports/insight', user).R_CURRENT_PAGE) &&
						<Tab
							value={`${match.url}/insight`}
							label="Report" {...a11yProps(1)}
							style={{ width: "68px" }}
							classes={{
								root: classes.rightPanelTabRoot,
								selected: classes.rightPanelTabSelected,
							}}
						/>
					}
					{(accessReportsList('/reports/comment', user).R_CURRENT_PAGE) &&
					user.access.includes("Comments") && !me_survey &&
						<Tab
							value={`${match.url}/comment`}
							label="Comments" {...a11yProps(2)}
							classes={{
								root: classes.rightPanelTabRoot,
								selected: classes.rightPanelTabSelected,
							}}
						/>
					}
					{(accessReportsList('/reports/report', user).R_CURRENT_PAGE) &&
						<Tab
							value={`${match.url}/report`}
							label="Favorites" {...a11yProps(3)}
							style={{ width: "85px" }}
							classes={{
								root: classes.rightPanelTabRoot,
								selected: classes.rightPanelTabSelected,
							}}
						/>
					}
				</Tabs>
			</AppBar>
		</Grid>
	)
}
// default props
ReportsTabs.defaultProps = {
	classes: {},
	match: {},
	location: {}
};

// prop types
ReportsTabs.propTypes = {
	classes: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object
};
export default withStyles(ReportsStyles)(ReportsTabs);
