import { meSurveyConstant } from "../constants/meSurveyConstants";
import _ from "lodash";

// Set Initial Table Data
const tableDatas = (sortName) => {
    return {
        data: [],
        page: {
            sFields: [],
            sValues: [],
            fieldFilters: {
                headerCols: [],
                fieldCols: [sortName],
                searchCols: []
            }
        }
    };
};

const dataFilter = () => {
    return {
        showOnlyManager: false,
        filterData: {
            demographics: {},
            selectedEmpIds: [],
            search: ""
        },
        filterChanged: false
    }
}

const editRaters = () => {
    return {
        data: {}, // with the key of from_id to to_id
        getOne: null // it become empty object for new rater and filled detail object to edit rater
    }
}

// Set Initial State
const initialState = {
    search: { ...tableDatas("name") },
    assign: { ...tableDatas("name") },
    dataFilter: { ...dataFilter() },
    editRaters: { ...editRaters() },
    relationships: [],
    subject_names: [],
    selectedParticipants: [], // in the format of manager_id_to_rater_to_empId,
    response_ids: [],
    all_ids: [],
    isEdited: false,
    survey: {},
    isAlreadySaved: false,
    expanded: null,
    demographics: {},
    demographicItems: {},
    process_status: { isLoading: false },
    loading: false,
    isRaterErrorMessageShown: false,
    dupGroupedEmails: {},
    selectedSubjectsWithEmail: [],
};

export const populateSelectedSubjects = (selSub, subject_names, prevState) => {
    let currentState = selSub.reduce((results, item) => {
        let splitSub = item.split('_to_');
        if (splitSub[0] === splitSub[1]) {
            const prevFind = prevState.findIndex(id => id._id === splitSub[0]);
            const subNameFind = subject_names.filter(r => r._id === splitSub[0]);
            if (subNameFind.length > 0) {
                results.push(subNameFind[0]);
                prevState.splice(prevFind, 1)
            }
        }
        return results
    }, []);

    return [...currentState.filter(r => r !== undefined), ...prevState];
}

export const addOrRemoveSelectedSubjects = (selSub, subject_names, prevState) => {
    let currentState = [];

    const currentSelEmpID = selSub.reduce((results, item) => {
        let splitSub = item.split('_to_');
        if (splitSub[0] === splitSub[1]) results.push(splitSub[0])
        return results
    }, []);
    const prevSelEmpID = prevState.map(r => r._id);
    const pushEmpID = _.difference(currentSelEmpID, prevSelEmpID);
    const popEmpID = _.difference(prevSelEmpID, currentSelEmpID);

    if (pushEmpID.length > 0) {
        pushEmpID.map(empID => {
            const prevFind = prevState.findIndex(id => id._id === empID);
            const subNameFind = subject_names.filter(r => r._id === empID);
            if (prevFind > -1) {
                currentState.push(prevState[prevFind][0]);
            }
            if (subNameFind.length > 0) {
                currentState.push(subNameFind[0]);
            }
        })
    }

    if (popEmpID.length > 0) {
        popEmpID.map(empID => {
            const find = prevState.findIndex(id => id._id === empID);
            if (find > -1) {
                prevState.splice(find, 1);
            }
        })
    }

    return [...prevState, ...currentState];
}

export const isAllSelected = (all_ids, updatedData) => {
    if (_.difference(all_ids, updatedData).length > 0) {
        updatedData = updatedData.filter(f => f !== "all")
    } else if (all_ids.length > 0 && updatedData.indexOf("all") === -1) {
        updatedData.push("all")
    }
    return updatedData
}

export default function (state = initialState, action) {
    switch (action.type) {
        // ME Survey List
        case meSurveyConstant.ME_SURVEY_SEARCH_REQUEST:
            return {
                ...state,
                isEdited: false,
                selectedParticipants: [],
                all_ids: [],
                response_ids: [],
                dup_selectedParticipants: null,
                assign: { ...tableDatas("name") },
                survey: {}
            };
        case meSurveyConstant.ME_SURVEY_SEARCH_SUCCESS:
            return {
                ...state,
                search: {
                    data: action.data.data,
                    page: action.data.page
                }
            };
        case meSurveyConstant.ME_SURVEY_ASSIGN_RESET:
            return {
                ...state,
                search: { ...tableDatas("name") },
                assign: { ...tableDatas("name") },
                dataFilter: { ...dataFilter() },
                editRaters: { ...editRaters() },
                relationships: [],
                subject_names: [],
                selectedParticipants: [],
                dup_selectedParticipants: null,
                response_ids: [],
                all_ids: [],
                isEdited: false,
                dupGroupedEmails: {},
            };
        // ME Subjects and Raters reset
        case meSurveyConstant.ME_SURVEY_SEARCH_FAILURE:
            return {
                ...state
            };
        // ME Assign Subjects / Raters
        case meSurveyConstant.ME_SURVEY_ASSIGN_REQUEST:
            return {
                ...state,
                dataFilter: {
                    ...state.dataFilter,
                    filterChanged: false
                }
            };
        case meSurveyConstant.ME_SURVEY_ASSIGN_SUCCESS:
            // for reset db subject selections
            // store in initial loading
            let ext = {}
            if (!state.dup_selectedParticipants || action.data.type === "raters") {
                ext = {
                    dup_selectedParticipants: [...action.data.selectedParticipants]
                }
            }
            // all subject ids in list page
            if (action.data.all_ids) {
                ext.all_ids = [...action.data.all_ids]
            }

            // all response ids in list page
            if (action.data.all_ids) {
                ext.response_ids = [...action.data.response_ids]
            }

            return {
                ...state,
                process_status: action.data.process_status,
                assign: {
                    data: action.data.data,
                    page: action.data.page,
                    raterErrorMessage: action.data.raterErrorMessage,
                },
                selectedParticipants: isAllSelected(action.data.all_ids || [], action.data.selectedParticipants),
                ...ext,
                subject_names: [...action.data.subject_names],
                survey: action.data.survey,
                isAlreadySaved: action.data.isAlreadySaved,
                isRaterErrorMessageShown: action.data.type === 'raters' ?
                    state.isRaterErrorMessageShown ? true : false
                    : false,
                dupGroupedEmails: action.data.dupGroupedEmails,
                selectedSubjectsWithEmail: populateSelectedSubjects(action.data.selectedParticipants, action.data.subject_names, state.selectedSubjectsWithEmail),
            };
        case meSurveyConstant.ME_SURVEY_ASSIGN_FAILURE:
            return {
                ...state
            };
        // ME Seletec Participants update
        case meSurveyConstant.ME_SELECTED_PARTICIPANTS:
            return {
                ...state,
                isEdited: true,
                selectedParticipants: [...action.data],
                selectedSubjectsWithEmail: addOrRemoveSelectedSubjects(action.data, state.subject_names, state.selectedSubjectsWithEmail),
            };
        // ME Data Filter update
        case meSurveyConstant.ME_DATA_FILTER_UPDATE:
            return {
                ...state,
                dataFilter: {
                    ...action.data
                }
            };
        // ME Get Demographics
        case meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_REQUEST:
            return {
                ...state
            };
        case meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_SUCCESS:
            return {
                ...state,
                demographics: action.data.data
            };
        case meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_FAILURE:
            return {
                ...state
            };
        // ME Get Demographics Items
        case meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_DDN_REQUEST:
            return {
                ...state,
                expanded: action.data,
                loading: true
            };
        case meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS:
            return {
                ...state,
                demographicItems: {
                    ...state.demographicItems,
                    ...action.data.data
                },
                loading: false
            };
        case meSurveyConstant.ME_FILTER_GET_DEMOGRAPHICS_DDN_FAILURE:
            return {
                ...state,
                loading: false
            };
        // ME Add or Edit Rater
        case meSurveyConstant.ME_ADD_OR_EDIT_RATER:
            return {
                ...state,
                editRaters: {
                    ...state.editRaters,
                    ...action.data
                }
            };
        // ME Update Rater
        case meSurveyConstant.ME_ADD_OR_EDIT_RATER_REQUEST:
            return {
                ...state
            };
        case meSurveyConstant.ME_ADD_OR_EDIT_RATER_SUCCESS:
            return {
                ...state,
                editRaters: {
                    data: {},
                    getOne: null, // close the drawer
                },
                dataFilter: {
                    ...state.dataFilter,
                    filterChanged: true // to reload the page after adding / updating rater
                }
            };
        case meSurveyConstant.ME_ADD_OR_EDIT_RATER_FAILURE:
            return {
                ...state
            };
        // ME Get Relationship
        case meSurveyConstant.ME_MANAGER_RELATIONSHIP_GET_REQUEST:
            return {
                ...state
            };
        case meSurveyConstant.ME_MANAGER_RELATIONSHIP_GET_SUCCESS:
            return {
                ...state,
                relationships: action.data.data
            };
        case meSurveyConstant.ME_MANAGER_RELATIONSHIP_GET_FAILURE:
            return {
                ...state
            };
        // ME Add or Edit Rater
        case meSurveyConstant.ME_ADD_OR_EDIT_RELATIONSHIP:
            return {
                ...state,
                relationships: [...action.data]
            };
        // ME Update relationship
        case meSurveyConstant.ME_MANAGER_RELATIONSHIP_UPDATE_REQUEST:
            return {
                ...state
            };
        case meSurveyConstant.ME_MANAGER_RELATIONSHIP_UPDATE_SUCCESS:
            return {
                ...state
            };
        case meSurveyConstant.ME_MANAGER_RELATIONSHIP_UPDATE_FAILURE:
            return {
                ...state
            };
        // ME Get Process status
        case meSurveyConstant.ME_GET_PROCESS_STATUS_REQUEST:
            return {
                ...state,
                process_status: { isLoading: true }
            };
        case meSurveyConstant.ME_GET_PROCESS_STATUS_SUCCESS:
            return {
                ...state,
                process_status: { ...action.data.data, isLoading: false }
            };
        case meSurveyConstant.ME_GET_PROCESS_STATUS_FAILURE:
            return {
                ...state,
                process_status: { isLoading: false }
            };
        // save or update
        case meSurveyConstant.ME_SAVE_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                process_status: { ...action.data.data, isLoading: false }
            };
        // clear table search datas
        case meSurveyConstant.ME_SEARCH_CLEAR_VALUES:
            return {
                ...state,
                search: { ...tableDatas("name") },
                assign: { ...tableDatas("name") },
            }
        // update rater notification status
        case meSurveyConstant.ME_RATER_ERROR_NOTIFICATION:
            return {
                ...state,
                isRaterErrorMessageShown: true
            }
        default:
            return state;
    }
}