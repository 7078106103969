/* eslint-disable quotes */
import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import * as dropEffects from "./dropEffects";
import Grid from "@mui/material/Grid";

// Import Cofig Constants
import { dragDrop, surveyTypes } from "../../config/constants";

const insideStyle = {
  backgroundColor: "#fff",
  zIndex: 99999
};

const dragContainer = {
  position: "absolute",
  backgroundColor: "#fff",
  zIndex: "9",
  opacity: "0.97",
  width: "100%",
  height: "100%"
};

const dragInside = {
  width: `calc(100% - 60px)`,
  height: `calc(100% - 60px)`,
  border: "1px dashed #C0C1C2",
  margin: "30px"
};

const DropTarget = props => {
  // Set State
  // const [isOver, setIsOver] = React.useState(false);

  const { getOne} = useSelector((state) => state.survey);
  const isMESurvey = getOne?.type === surveyTypes.MULTI_RATER_FEEDBACK;
  const drag = useSelector(state => state.dragdrop.drag);

  const dragOver = ev => {
    ev.preventDefault();
    ev.dataTransfer.dropEffect = props.dropEffect;
  };

  const drop = ev => {
    const droppedItem = ev.dataTransfer.getData("drag-item");
    if (droppedItem) {
      // setIsOver(false)
      props.onItemDropped(droppedItem);
    }
    // setIsOver(false);
  };

  const dragEnter = ev => {
    ev.dataTransfer.dropEffect = props.dropEffect;
    // setIsOver(true);
  };

  // const dragLeave = () => { setIsOver(false) };

  return (
    <Grid
      onDragOver={dragOver}
      onDrop={drop}
      onDragEnter={dragEnter}
      // onDragLeave={dragLeave}
      style={{ width: "100%", position: "relative", height: "100%", ...(drag ? insideStyle : {}) }}
    >
      {drag &&
                <Grid
                  container
                  className={"fontSize30 txtgrey txtMedium"}
                  alignItems="center"
                  justifyContent="center"
                  style={dragContainer}>
                  <Grid
                    container
                    className={"fontSize30 txtgrey txtMedium"}
                    alignItems="center"
                    justifyContent="center"
                    style={dragInside}>
                    {isMESurvey?dragDrop.QUESTIONS_DRAG_DROP_COMP:dragDrop.QUESTIONS_DRAG_DROP}
                  </Grid>
                </Grid>}
      {props.children}
    </Grid>
  );
};

DropTarget.propTypes = {
  onItemDropped: PropTypes.func.isRequired,
  dropEffect: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
};

DropTarget.defaultProps = {
  dropEffect: dropEffects.All
};

export default DropTarget;
