import moment from 'moment';
/* eslint-disable no-self-assign */
import { fieldDataTypes, operators, operatorsMap } from "./utils/operators";

export const getFieldData = (fields, fieldName) => {
  return fields.filter(f => f.name === fieldName)[0] || {};
};

export const getOperators = (fields, fieldName, allowedOperators = []) => {
  const fieldData = getFieldData(fields, fieldName);
  let validOperators = operators.filter(op => op.type.indexOf(fieldData.type) >= 0);
  if (allowedOperators.length > 0) {
    // for all 
    if (typeof allowedOperators[0] === "string") {
      validOperators = validOperators.filter(op => allowedOperators.indexOf(op.label) >= 0);
    } else { // for specific type operator filter
      const dataTypeAO = allowedOperators.filter(f => f.type === fieldData.type)
      if (dataTypeAO && dataTypeAO.allowedOperators) {
        const allOp = dataTypeAO.allowedOperators
        validOperators = validOperators.filter(op => allOp.indexOf(op.label) >= 0);
      }
    }
  }
  return validOperators;
};

export const valueValidations = (fieldData, value) => {
  // only number if field type valdidation
  if (fieldData.type === fieldDataTypes.INTEGER) {
    if (value && Array.isArray(value)) {
      value = value.map(v => parseFloat(v, 10));
    } else {
      value = value ? parseFloat(value, 10) : null;
    }
  }

  // if date
  if (fieldData.type === fieldDataTypes.DATE && (value && !Array.isArray(value))) {
    value = value ? `${value}` : "";
    // if date format
    if (value && value.split("/").length === 3) {
      value = moment(value).isValid() ? moment(value).format("YYYY-MM-DD") : value;
    } else {
      value = value;
    }
  }

  return value;
};

const getValue = ({ value, operator, field }, fields) => {
  const fieldData = getFieldData(fields, field);

  if (operator === "null" || operator === "notNull") return null;

  // only allow array for in and notin
  if (Array.isArray(value) && ["in", "notIn"].indexOf(operator) < 0) {
    value = value.join(" ");
  }

  return valueValidations(fieldData, value);
};

const customQueryOperator = {
  // for between operator
  between: (rule, fields) => {
    const fieldData = getFieldData(fields, rule.field);
    let { value1, value2 } = rule.value || { value1: null, value2: null };
    value1 = valueValidations(fieldData, value1);
    value2 = valueValidations(fieldData, value2);
    value1 = value1 === 0 || value1 ? value1 : null;
    value2 = value2 === 0 || value2 ? value2 : null;

    // return {
    //   $and: [
    //     { $gte: [rule.field, value1 || null] },
    //     { $lte: [rule.field, value2 || null] }
    //   ]
    // };
    return {
      [rule.field]: { $gte: value1, $lte: value2 }
    };
  }
};

export function formatQuery (query, fields) {
  const combinators = { and: "$and", or: "$or" };

  const mapRule = rule => {
    if (customQueryOperator[rule.operator]) {
      return customQueryOperator[rule.operator](rule, fields);
    } else {
      return {
        // [operatorsMap[rule.operator]]: ["$" + rule.field, getValue(rule, fields)]
        [rule.field]: operatorsMap?.[rule.operator] ? { [operatorsMap[rule.operator]]: getValue(rule, fields) } : ''
      };
    }
  };

  const mapRuleSet = ruleSet => {
    if (ruleSet.rules) {
      return {
        [combinators[ruleSet.combinator]]: ruleSet.rules.map(
          rule => rule?.field || rule.operator ? mapRule(rule) : mapRuleSet(rule)
        )
      };
    } else {
      return {};
    }
  };

  return mapRuleSet(query);
}

// exports
export * from "./utils/deFormatQuery";
export { fieldDataTypes, operators, operatorsMap };
