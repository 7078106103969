import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All filter Service Functions
 */
export const fileManagerService = {
  getList,
  updateSeenStatus,
  downloadFile,
  deleteFile,
  updateNotifiedStatus
};

/**
 * GET File List
 */
async function getList(skip, limit, sortBy, sort, sFields, sValues, formData = {}) {
  const response = await apihelper.postRequest(`file-manager/list?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues, ...formData });
  return response;
}

/**
 * Update file seen status
 */
async function updateSeenStatus(id) {
  const response = await apihelper.putRequest(`file-manager/seen/${id}`);
  return response;
}

/* Excel download
 */
async function downloadFile(id, responseType) {
  try {
    const response = await apihelper.postRequest(`file-manager/download/${id}`, { id }, false, responseType, true);
    if (response.data && response.data.filePath) {
      const responseOne = await apihelper.postRequestExcel('excel/exportExcel', response.data, false, "arraybuffer", true);
      return responseOne;
    } else {
      return response;
    }
  } catch (e) {
    console.error(e)
  }
}

/**
 * Delete file
 */
async function deleteFile(id) {
  const response = await apihelper.deleteRequest(`file-manager/delete/${id}`, {}, true);
  return response;
}

/**
 * Update file notified status
 */
async function updateNotifiedStatus(id) {
  const response = await apihelper.putRequest(`file-manager/notified/${id}`);
  return response;
}