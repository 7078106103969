import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";
import { isMobile } from "react-device-detect";

// Import Style
import TakeSurveyStyles from "./TakeSurveyStyles";

// Import Components
import LinearProgressComponent from "../../components/LinearProgressComponent/LinearProgressComponent";
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";

// Import Logo
import IconLogo from "../../assets/images/svg/logo.svg";
import ProfileImg from "../../assets/images/default_profile.svg";

// Import Actions
import { takeSurveyAction } from "../../redux/actions";

function TakeSurveyHeader(props) {
  const { classes, langCode } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  const clientData = useSelector((state) => state.takeSurvey.client);
  const participantData = useSelector((state) => state.takeSurvey.participant);
  const questionData = useSelector((state) => state.takeSurvey.questions);
  const welcome_name_access = useSelector((state) => state?.takeSurvey?.getOne?.welcome_name_access);
  const { languages } = questionData;
  const { firstName, lastName } = participantData;
  const [activeLanguage, setActiveLanguage] = useState(langCode);
  useEffect(() => {
    dispatch(takeSurveyAction.updateSurveyLanguage(activeLanguage));
  }, [dispatch, activeLanguage]);

  const handleSurveyLanguage = (lang) => {
    setActiveLanguage(lang.code);
  };

  const activeLangObject = languages.find((lang) => lang.code == langCode);
  return (
    <Grid className={`${classes.rootHeader} ${isMobile && languages.length > 1 ? classes.rootHeaderMobile : ""}`}>
      <LinearProgressComponent top={`${languages.length > 1 ? "70px" : ""}`}></LinearProgressComponent>
      <AppBar position="static" color="default" className={classes.headerAppBar}>
        <Toolbar variant="dense" className={classes.headerToolBar}>
          <Grid item className={classes.logo} sm={6}>
            <img src={clientData && clientData.logo ? clientData.logo : IconLogo} className={classes.headerLogoImg} onClick={() => {}} alt={clientData && clientData.name ? clientData.name : "People Element"} title={clientData && clientData.name ? clientData.name : "People Element"} />
          </Grid>
          <Grid item sm={6} className={classes.headerRight}>
            <Grid item className={classes.headerRightBlock}>
              {languages.length > 1 ? (
                <Grid item sm={4} className={"desktopShow"}>
                  <ValidatorForm onSubmit={() => {}}>
                    <AutoCompleteComponent
                      name={"clients"}
                      suggestions={languages}
                      handleAutoCompolete={handleSurveyLanguage}
                      value={activeLangObject}
                      placeholder={"Select Language"}
                      validators={[]}
                    ></AutoCompleteComponent>
                  </ValidatorForm>
                </Grid>
              ) : null}
              {participantData && welcome_name_access? (
                <Grid item className={classes.headerUserInfo}>
                  <span>
                     {firstName ? firstName : ""} {lastName ? lastName : ""}
                  </span>
                  <Avatar alt="Resource" src={ProfileImg} />
                </Grid>
              ) : (
                <Grid item className={classes.headerUserInfo}>
                  <Avatar alt="Resource" src={ProfileImg} />
                </Grid>
              )}
            </Grid>
            {languages.length > 1 ? (
            <div className={`mobileShow ${classes.mobileLangDropDown}`}>
              <ValidatorForm onSubmit={() => {}}>
                <AutoCompleteComponent
                  name={"clients"}
                  suggestions={languages}
                  handleAutoCompolete={handleSurveyLanguage}
                  value={activeLangObject}
                  placeholder={"Select Language"}
                  validators={[]}
                ></AutoCompleteComponent>
              </ValidatorForm>
            </div>
          ) : null}
          </Grid>
        </Toolbar>
      </AppBar>
    </Grid>
  );
}

// default props
TakeSurveyHeader.defaultProps = {
  classes: {},
  langCode: "en",
};

// prop types
TakeSurveyHeader.propTypes = {
  classes: PropTypes.object,
  langCode: PropTypes.string,
};

/**
 * Export Container
 */
export default withStyles(TakeSurveyStyles)(TakeSurveyHeader);
