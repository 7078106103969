// ME reducer types
export const meSurveyConstant = {
  // List page constants
  ME_SURVEY_SEARCH_REQUEST: "ME_SURVEY_SEARCH_REQUEST",
  ME_SURVEY_SEARCH_SUCCESS: "ME_SURVEY_SEARCH_SUCCESS",
  ME_SURVEY_SEARCH_FAILURE: "ME_SURVEY_SEARCH_FAILURE",

  // Assign Page Subjects and Raters
  ME_SURVEY_ASSIGN_REQUEST: "ME_SURVEY_ASSIGN_REQUEST",
  ME_SURVEY_ASSIGN_SUCCESS: "ME_SURVEY_ASSIGN_SUCCESS",
  ME_SURVEY_ASSIGN_FAILURE: "ME_SURVEY_ASSIGN_FAILURE",
  ME_SURVEY_ASSIGN_RESET: "ME_SURVEY_ASSIGN_RESET",

  // update selected participants
  ME_SELECTED_PARTICIPANTS: "ME_SELECTED_PARTICIPANTS",

  // Save or Update Assignes
  ME_SAVE_PARTICIPANTS_REQUEST: "ME_SAVE_PARTICIPANTS_REQUEST",
  ME_SAVE_PARTICIPANTS_SUCCESS: "ME_SAVE_PARTICIPANTS_SUCCESS",
  ME_SAVE_PARTICIPANTS_FAILURE: "ME_SAVE_PARTICIPANTS_FAILURE",

  // Checking on NO Save
  ME_NO_SAVE_PARTICIPANTS_REQUEST: "ME_NO_SAVE_PARTICIPANTS_REQUEST",
  ME_NO_SAVE_PARTICIPANTS_SUCCESS: "ME_NO_SAVE_PARTICIPANTS_SUCCESS",
  ME_NO_SAVE_PARTICIPANTS_FAILURE: "ME_NO_SAVE_PARTICIPANTS_FAILURE",

  // Data Filter update
  ME_DATA_FILTER_UPDATE: "ME_DATA_FILTER_UPDATE",

  // Data Filter Demographics
  ME_FILTER_GET_DEMOGRAPHICS_REQUEST: 'ME_FILTER_GET_DEMOGRAPHICS_REQUEST',
  ME_FILTER_GET_DEMOGRAPHICS_SUCCESS: 'ME_FILTER_GET_DEMOGRAPHICS_SUCCESS',
  ME_FILTER_GET_DEMOGRAPHICS_FAILURE: 'ME_FILTER_GET_DEMOGRAPHICS_FAILURE',

  // Data Filter Demographics Items
  ME_FILTER_GET_DEMOGRAPHICS_DDN_REQUEST: 'ME_FILTER_GET_DEMOGRAPHICS_DDN_REQUEST',
  ME_FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS: 'ME_FILTER_GET_DEMOGRAPHICS_DDN_SUCCESS',
  ME_FILTER_GET_DEMOGRAPHICS_DDN_FAILURE: 'ME_FILTER_GET_DEMOGRAPHICS_DDN_FAILURE',

  // ADD or EDIT Rater
  ME_ADD_OR_EDIT_RATER: 'ME_ADD_OR_EDIT_RATER',
  ME_ADD_OR_EDIT_RATER_REQUEST: 'ME_ADD_OR_EDIT_RATER_REQUEST',
  ME_ADD_OR_EDIT_RATER_SUCCESS: 'ME_ADD_OR_EDIT_RATER_SUCCESS',
  ME_ADD_OR_EDIT_RATER_FAILURE: 'ME_ADD_OR_EDIT_RATER_FAILURE',

  // Manage Relationship get and update
  ME_MANAGER_RELATIONSHIP_GET_REQUEST: 'ME_MANAGER_RELATIONSHIP_GET_REQUEST',
  ME_MANAGER_RELATIONSHIP_GET_SUCCESS: 'ME_MANAGER_RELATIONSHIP_GET_SUCCESS',
  ME_MANAGER_RELATIONSHIP_GET_FAILURE: 'ME_MANAGER_RELATIONSHIP_GET_FAILURE',

  ME_ADD_OR_EDIT_RELATIONSHIP: 'ME_ADD_OR_EDIT_RELATIONSHIP',
  ME_MANAGER_RELATIONSHIP_UPDATE_REQUEST: 'ME_MANAGER_RELATIONSHIP_UPDATE_REQUEST',
  ME_MANAGER_RELATIONSHIP_UPDATE_SUCCESS: 'ME_MANAGER_RELATIONSHIP_UPDATE_SUCCESS',
  ME_MANAGER_RELATIONSHIP_UPDATE_FAILURE: 'ME_MANAGER_RELATIONSHIP_UPDATE_FAILURE',

  // Relationship Delete Request
  ME_MANAGER_RELATIONSHIP_DELETE_REQUEST: 'ME_MANAGER_RELATIONSHIP_DELETE_REQUEST',
  ME_MANAGER_RELATIONSHIP_DELETE_SUCCESS: 'ME_MANAGER_RELATIONSHIP_DELETE_SUCCESS',
  ME_MANAGER_RELATIONSHIP_DELETE_FAILURE: 'ME_MANAGER_RELATIONSHIP_DELETE_FAILURE',

  // Get process status
  ME_GET_PROCESS_STATUS_REQUEST: 'ME_GET_PROCESS_STATUS_REQUEST',
  ME_GET_PROCESS_STATUS_SUCCESS: 'ME_GET_PROCESS_STATUS_SUCCESS',
  ME_GET_PROCESS_STATUS_FAILURE: 'ME_GET_PROCESS_STATUS_FAILURE',

  // clear search values
  ME_SEARCH_CLEAR_VALUES: 'ME_SEARCH_CLEAR_VALUES',

  // update error notification for rater
  ME_RATER_ERROR_NOTIFICATION: 'ME_RATER_ERROR_NOTIFICATION',
};