/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import SvgIcon from '@mui/material/SvgIcon';


// Import Icons 
import { MessageAccord } from "../../../../assets/images/svgComponents/ImageComponent";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import AutoCompleteComponent from "../../../../components/AutoCompleteComponent/AutoCompleteComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import SliderComponent from "../../../../components/SliderComponent";

// constants
import { surveySettingsConstants, errorsConstants, surveyConstants, surveyTypes } from "../../../../config/constants";
import { surveyConstant } from "../../../../redux/constants";

// Import Actions
import { updateStateReducerAction } from "../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData } from "../../../../helpers";

function ResponsePanel(props) {
  const { classes, data, expanded, panel, panelError } = props;

  const { native_language } = useSelector(state => state.survey);
  const { threshold, languageTranslation } = useSelector(state => state.client.getOne);
  const [responseCheck, setResponseCheck] = useState(false);
  const dispatch = useDispatch();
  if (data.expiry === undefined) {
    data.expiry = {};
  }
  if (data.distribute && data.distribute.audience === undefined) {
    data.distribute.audience = {
      select: ""
    };
  }

  useEffect(() => {
    if (data.frequency === "Pulse" && data.pulse_obj.period !== undefined && data.pulse_obj.period !== "" && data.expiry.period !== undefined && data.expiry.period !== "") {
      let multiplier = (data.pulse_obj.period.frequencyType !== surveySettingsConstants.WEEKS) ? 30 : 7;
      let duration = Number(data.pulse_obj.period.frequency);
      const pulseDuration = multiplier * duration;
      console.log(data.expiry.type)
      multiplier = data.expiry.type ? (data.expiry.type === surveySettingsConstants.WEEKS) ? 7 : (data.expiry.type === surveySettingsConstants.MONTHS ? 30 : 1) : 7;
      duration = data.expiry.period;
      const responseDuration = multiplier * duration;
      console.log(responseDuration, pulseDuration)
      if (responseDuration > pulseDuration) {
        setResponseCheck(true);
      } else {
        setResponseCheck(false);
      }
    } else if (data.endDate && data.expiry.period !== undefined && data.expiry.period !== "" && data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS) {
      const multiplier = (data.expiry.type === surveySettingsConstants.WEEKS) ? 7 : (data.expiry.type === surveySettingsConstants.MONTHS ? 30 : 7);
      const duration = data.expiry.period;
      const responseDuration = multiplier * duration;
      const date1 = new Date();
      const date2 = new Date(data.endDate);
      const Difference_In_Time = date2.getTime() - date1.getTime();
      const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Math.sign(Difference_In_Days) > -1 && responseDuration > Difference_In_Days) {
        setResponseCheck(true);
      } else {
        setResponseCheck(false);
      }
    } else {
      setResponseCheck(false);
    }
  }, [data]);

  /**
  * Handle Change Event
  * @param {*} panel
  */
  const handleChange = (event) => {
    if (event.name === "distribute.editresp") {
      event.value = (event.value === true) ? surveySettingsConstants.CHECKED_FALSE : surveySettingsConstants.CHECKED_TRUE
    }
    const reducerData = getReducerUpdatedData(event, data);
    if (data._id && !data.isLoading && data.frequency) {
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
* Handle Native Language Change Event
* @param {*} panel
*/
  const handleNativeChange = (event) => {
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_NATIVE_LANGUAGE_REDUCER_UPDATE, event.value));
  };



  // Handle Auto Complete
  const handleAutoCompolete = (value, name) => {
    handleChange({ name, value });
  };

  const handlePulseCheckCloseAction = () => {
    setResponseCheck(false);
    handleChange({ name: "expiry.period", value: "" });
  };

  const handleSurveyThreshold = (value) => {
    handleChange({ name: "survey_threshold", value })
  }

  /**
   * Handle Tab Change Event
   * @param {*} name
   */
  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };

  return (
    <Accordion
      className={`mb-2 pe-accordion ${panelError ? "panel-error" : ""}`}
      expanded={expanded === "response-panel"}
      onChange={handleTabChange("response-panel")}
    >

      {/*
        panel header summary
      */}

      <AccordionSummary
        expandIcon={<ExpandMoreIcon className="expandicon" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Dialog
          open={responseCheck}
          onClose={handlePulseCheckCloseAction}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            {data.frequency === "Pulse" ? errorsConstants.reponseWindowCheckPulse : errorsConstants.reponseWindowCheck}
          </DialogContent>
          <DialogActions>
            <Button variant="contained" disableElevation size="small" onClick={handlePulseCheckCloseAction} color="primary" >
              {surveyConstants.CLOSE_BUTTON}
            </Button>
          </DialogActions>
        </Dialog>
        <SvgIcon className={`${classes.settings_icons} ${expanded === "response-panel" ? "panel-active" : "panel-inactive"} `} alt={"settings"} >
          {MessageAccord()}
        </SvgIcon>
        <TypographyComponent
          className={` ${classes.setting_accordheading} ${expanded === "response-panel" ? "panel-active" : ""} `}
          variant={"h5"}
        >{surveySettingsConstants.RESPONSE_PANEL_TITLE}</TypographyComponent>
        <TypographyComponent
          className={`${"oneline_ellipse mt2"} ${expanded === "response-panel" ? "panel-active" : ""}`}
          variant={"h6"}
        >{surveySettingsConstants.RESPONSE_PANEL_MESSAGE}</TypographyComponent>
      </AccordionSummary>

      {/*
        panel body
      */}
      {(data.distribute && data.expiry) &&
        <AccordionDetails>
          <Grid container spacing={3} className={`${classes.setting_expandcontainer} `}>
            <Grid item md={4} xs={12}>
              <SwitchComponent
                name="distribute.editresp"
                checked={data.distribute.editresp === true ? surveySettingsConstants.CHECKED_FALSE : surveySettingsConstants.CHECKED_TRUE}
                disabled={data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.multiResponse ? surveySettingsConstants.CHECKED_TRUE : surveySettingsConstants.CHECKED_FALSE}
                color={data.distribute.audience && data.distribute.audience.anonymous && data.distribute.audience.anonymous.multiResponse ? "default" : "primary" }
                handleChange={(e) => handleChange(e)}
                label={
                  <span>
                    <TypographyComponent variant="h5" className="pt-2">{surveySettingsConstants.EDIT_RESPONSE_TITLE}
                      <InformationComponent>
                        <Grid container>
                          <TypographyComponent variant="tooltipbody">
                            <b>{"ON: "}</b>{surveySettingsConstants.EDIT_RESPONSE_TITLE_HELP_ON}
                          </TypographyComponent>
                          <TypographyComponent variant="tooltipbody">
                            <b>{"OFF: "}</b>{surveySettingsConstants.EDIT_RESPONSE_TITLE_HELP_OFF}
                          </TypographyComponent>
                        </Grid>

                      </InformationComponent>
                    </TypographyComponent>
                    <TypographyComponent variant="h6" className="txtgrey">{surveySettingsConstants.EDIT_RESPONDE_MESSAGE}</TypographyComponent>
                  </span>
                }
              />
            </Grid>
            {data.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS && data.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
              < Grid item md={4} xs={12}>
            <TypographyComponent variant="h6"
            >{surveySettingsConstants.RESPONSE_WINDOW}
              <InformationComponent>
                <TypographyComponent variant="tooltipbody" className={"textWrap"}>
                  {surveySettingsConstants.RESPONSE_WINDOW_HELP}
                </TypographyComponent>
              </InformationComponent>
            </TypographyComponent>
            <Grid container alignItems="center">
              <Grid item md={4} xs={6}>
                <TextFieldComponent
                  id="expiry.period"
                  name="expiry.period"
                  type="text"
                  size={"small"}
                  fullWidth={true}
                  variant="outlined"
                  value={data.expiry.period || ""}
                  handleChange={(e) => handleChange(e)}
                  className={`${"rtnoborder"} ${classes.nowarpValidationTxt}`}
                  validators={['onlyNumberCanEmpty']}
                  panel={panel}
                  InputProps={{
                    onKeyDown: (e) => {
                      const isNonNumeric = (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105);
                      const allowedKeyCodes = [8, 46];
                      if (!isNonNumeric && allowedKeyCodes.indexOf(e.keyCode) === -1) {
                        e.preventDefault();
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item md={4} xs={6}>
                <AutoCompleteComponent
                  name="expiry.type"
                  suggestionType="array"
                  suggestions={data.frequency === surveySettingsConstants.SURVEY_FREQUENCY_PULSE ? surveySettingsConstants.PULSE_DROPDOWN : surveySettingsConstants.NONPULSE_DROPDOWN}
                  handleAutoCompolete={(e, n) => handleAutoCompolete(e, n)}
                  value={data.expiry.type || "Weeks"}
                  className={` ${"ltnoborder"} ${classes.noValidationTxt}`}
                ></AutoCompleteComponent>
              </Grid>
            </Grid>
          </Grid>
            }

          <Grid item md={4} xs={12}>
            <TypographyComponent variant="h6"
            >{surveySettingsConstants.SURVEY_THRESHOLD_TITLE}
              <InformationComponent>
                <TypographyComponent variant="tooltipbody" className={"textWrap"}>
                  {surveySettingsConstants.SURVEY_THRESHOLD_HELP}
                </TypographyComponent>
              </InformationComponent>
            </TypographyComponent>
            <SliderComponent
              minValue={threshold ? threshold : surveySettingsConstants.SURVEY_THRESHOLD_MIN}
              maxValue={surveySettingsConstants.SURVEY_THRESHOLD_MAX}
              defaultValue={surveySettingsConstants.SURVEY_THRESHOLD_MIN}
              value={data.hasOwnProperty("survey_threshold") && data.survey_threshold ? data.survey_threshold : surveySettingsConstants.SURVEY_THRESHOLD_MIN}
              onSelect={handleSurveyThreshold}
              disable={data && data.status && data.status === "Closed" ? true : false}
            />
          </Grid>
          {languageTranslation &&
            <Grid item md={12} xs={12}>
              <SwitchComponent
                name="native_language"
                checked={native_language ? native_language : surveySettingsConstants.CHECKED_FALSE}
                disabled={surveySettingsConstants.CHECKED_FALSE}
                color="primary"
                handleChange={(e) => handleNativeChange(e)}
                label={
                  <span>
                    <TypographyComponent variant="h5">{surveySettingsConstants.DISPLAY_NATIVE_LANGUAGE}
                      <InformationComponent>
                        <Grid container>
                          <TypographyComponent variant="tooltipbody">
                            <b>{"ON: "}</b>{surveySettingsConstants.DISPLAY_NATIVE_LANGUAGE_MESSAGE_ON}
                          </TypographyComponent>
                          <TypographyComponent variant="tooltipbody">
                            <b>{"OFF: "}</b>{surveySettingsConstants.DISPLAY_NATIVE_LANGUAGE_MESSAGE_OFF}
                          </TypographyComponent>
                        </Grid>

                      </InformationComponent>
                    </TypographyComponent>
                    <TypographyComponent variant="h6" className="txtgrey">{surveySettingsConstants.DISPLAY_NATIVE_LANGUAGE_MESSAGE}</TypographyComponent>
                  </span>
                }
              />
            </Grid>
          }
          </Grid>
        </AccordionDetails>
      }
    </Accordion >
  );
}

// default props
ResponsePanel.defaultProps = {
  classes: {},
  data: {},
  expanded: false,
  handleTabChange: () => { }
};

// prop types
ResponsePanel.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  expanded: PropTypes.any,
  handleTabChange: PropTypes.func
};

export default (ResponsePanel);
