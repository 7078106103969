export const externalApiConstant = {
  POST_EXTERNAL_API_CONFIG: "POST_EXTERNAL_API_CONFIG",
  POST_EXTERNAL_API_CONFIG_SUCCESS: "POST_EXTERNAL_API_CONFIG_SUCCESS",
  POST_EXTERNAL_API_CONFIG_FAILURE: "POST_EXTERNAL_API_CONFIG_FAILURE",

  GET_EXTERNAL_API_CONFIG_SUCCESS: "GET_EXTERNAL_API_CONFIG_SUCCESS",
  GET_EXTERNAL_API_CONFIG_FAILURE: "GET_EXTERNAL_API_CONFIG_FAILURE",
  GET_EXTERNAL_API_CONFIG: "GET_EXTERNAL_API_CONFIG",

  PUT_EXTERNAL_API_HRIS_CONFIG: "PUT_EXTERNAL_API_HRIS_CONFIG",
};
