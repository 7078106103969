import { alertConstant, profileConstant, uiConstant } from "../constants";
import { profileService } from "../services";
import { baseAction } from "./baseAction";

const { request, success, failure } = baseAction;

// Export All Auth Actions Functions
export const profileAction = {
  getOne,
  user,
  update,
  updatePreference
};

/**
 * Get User Details 
 */
function getOne() {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(profileConstant.PROFILE_GET_ONE_REQUEST));
    profileService.getOne().then(
      (data) => {
        dispatch(success(profileConstant.PROFILE_GET_ONE_SUCCESS, data));
        // dispatch(success(profileConstant.PROFILE_GET_ONE_INIT, data));
        dispatch(request(uiConstant.LOADING_FALSE));
      },
      (error) => {
        dispatch(failure(profileConstant.PROFILE_GET_ONE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


/**
 * Get Logged In User Details
 */
function user(successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(profileConstant.PROFILE_LOGGED_IN_REQUEST));
    return profileService.user().then(
      (data) => {
        dispatch(success(profileConstant.PROFILE_LOGGED_IN_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback()
      },
      (error) => {
        dispatch(failure(profileConstant.PROFILE_LOGGED_IN_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}


/**
 * Update Profile
 *
 * @param {*} id  client id to update
 * @param {*} updateData data to be updated
 * @returns
 */
function update(updateData, successCallback = ()=>{}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(profileConstant.PROFILE_UPDATE_REQUEST, updateData));

    profileService.update(updateData).then(
      (data) => {
        dispatch(success(profileConstant.PROFILE_UPDATE_SUCCESS, data));
        dispatch(success(alertConstant.ALERT_SUCCESS, data.message));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        dispatch(failure(profileConstant.PROFILE_UPDATE_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}

/**
 * Client Preference for super Admins
 */
function updatePreference (data, successCallback = () => {}) {
  return (dispatch) => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(profileConstant.PROFILE_UPDATE_PREFERENCE_CLIENTID_REQUEST, data));
    profileService.updatePreference(data).then(
      (data) => {
        dispatch(success(profileConstant.PROFILE_UPDATE_PREFERENCE_CLIENTID_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));
        successCallback();
      },
      (error) => {
        dispatch(failure(profileConstant.PROFILE_UPDATE_PREFERENCE_CLIENTID_FAILURE, error));
        dispatch(failure(alertConstant.ALERT_ERROR, error));
        dispatch(request(uiConstant.LOADING_FALSE));
      }
    );
  };
}