import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

// Import Styles and Image
import CloseImg from "../../../../assets/images/svg/dashboard_close.svg";
import ReportsStyles from "../../ReportsStyles";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SentimentAnalysis from "./SentimentAnalysis";

const CommentAnalysisPopup = (props) => {
  //Get props
  const { classes, onClose, frequency, sentiment, preference, questions } = props;

  let QuesText = ""
  if (questions && questions.length) {
    questions.map(item => {
      if (preference.commentAnalysis && preference.commentAnalysis.baseQuesId && item["baseQusId"] === preference.commentAnalysis.baseQuesId) {
        QuesText = item["question"]
      }
    })
  }
  if (!QuesText) {
    QuesText = questions[0].question ? questions[0].question : ""
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          xs={12}
          className={`${classes.dialog_close}`}
        >
          <Button variant="text" size={"small"} onClick={onClose}>
            <img src={CloseImg} alt="CloseImg" />
            <TypographyComponent variant="h5" className="pl5">
              {"Close"}
            </TypographyComponent>
          </Button>
        </Grid>
        <Grid style={{ width: "100%" }}>
          <Grid item>
            <TypographyComponent
              variant="h4"
              className="txtBold fontSize16 ml-1"
            >
              {"Comment Analysis"}
            </TypographyComponent>
          </Grid>
          <Grid>
            <TypographyComponent align={"left"} variant="h5" className="pl5">
              {QuesText}
            </TypographyComponent>
          </Grid>
          <SentimentAnalysis
            classes={classes}
            frequency={frequency}
            sentiment={sentiment}
            zoom={true}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
// default props
CommentAnalysisPopup.defaultProps = {
  classes: {},
};

// prop types
CommentAnalysisPopup.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(CommentAnalysisPopup);
