/* eslint-disable dot-notation */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { SortableHandle } from "react-sortable-hoc";

// import constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

// import components
import TextFieldComponent from "../../../../../components/TextFieldComponent/TextFieldComponent";

// Import Images
import Tiledelete from "../../../../../assets/images/svg/trash.svg";
import RepeatGrid from "../../../../../assets/images/svg/repeat-grid.svg";

// Import Actions
import { categoryAction } from "../../../../../redux/actions";
import InputAdornment from "@mui/material/InputAdornment";

/**
 * Drage Handle
 */
const DragHandle = SortableHandle(() => <img src={RepeatGrid} className="cursor-move pt2 icon-hover" alt={"Menu"} draggable={false}/>);

function MatrixSubQuestionText (props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // Get Props Values
  const { category, questionDataSet, item, classes, value, index } = props;

  // Delete subQuestion
  const deleteSubQuestion = (delIndex) => {
    questionDataSet["subQues"].splice(delIndex, 1);
    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, questionDataSet));
    } else {
      props.editQuestionsData(questionDataSet);
    }
  };

  // Set State Value
  const changeSubQuestionText = (e) => {
    let { name, value } = e;
    name = name.split(".");
    questionDataSet["subQues"][name[1]]["text"][surveyQuestionsConstants.DEFAULT_LANG_CODE] = value;
    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, questionDataSet));
    } else {
      props.editQuestionsData(questionDataSet);
    }
  };

  return (
    <Grid item sm={6}>
      <Grid container alignItems="center">
        <Grid className={classes.width30}>
          <DragHandle></DragHandle>
        </Grid>
        <Grid className={`${"flexgrow"} ${classes.answerset_items} divWidth`}>
          <Badge
            badgeContent={index + 1}
            color="secondary"
            showZero
            className={`${classes.answerset_items_badge} ${"badgeMedium"}`}
          ></Badge>
          <TextFieldComponent
            id={`subQues${index}`}
            className={`${classes.answerset_items_txt} ${"bordernone"}`}
            name={`matric_subQuestion.${index}`}
            value={value.text.en}
            margin={"dense"}
            size="small"
            variant="outlined"
            showErrorMessages={false}
            handleChange={(e) => changeSubQuestionText(e)}
            InputProps= {{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                  onClick={() => { deleteSubQuestion(index); }}
                  className="p5 icon-hover ImgActiveOrange"
                  size="large"
                  edge="end">
                  <img
                  src={Tiledelete}
                  alt={"ans_iitem_delete"}
                  className={classes.answerset_items_dlt}
                  />
                  </IconButton>
                </InputAdornment>
              )
            }}
          ></TextFieldComponent>
          {/* <IconButton
            onClick={() => { deleteSubQuestion(index); }}
            className="p5 icon-hover ImgActiveOrange"
            size="large"
            position="end">
            <img
              src={Tiledelete}
              alt={"ans_iitem_delete"}
              className={classes.answerset_items_dlt}
            />
          </IconButton> */}
        </Grid>
      </Grid>
    </Grid>
  );
}

// default props
MatrixSubQuestionText.defaultProps = {
  classes: {},
  value: {},
  index: 0,
  category: "",
  questionDataSet: {},
  item: {},
  editQuestionsData: () => { }
};

// prop types
MatrixSubQuestionText.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
  index: PropTypes.number,
  category: PropTypes.string,
  questionDataSet: PropTypes.object,
  item: PropTypes.object,
  editQuestionsData: PropTypes.func
};

export default MatrixSubQuestionText;
