import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Styles
import FilterStyles from "../FilterStyles";

// Import Constants
import { FilterConstants, errorsConstants } from '../../../config/constants';
import { surveyTypes } from "../../../config/constants";

// Import Actions
import { updateStateReducerAction, filterAction } from '../../../redux/actions';

// Import Reducer Contants
import { filterConstant, dashboardConstant } from '../../../redux/constants';

// import helpers
import { dateFormatter } from '../../../helpers/appHelper'

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../components/SelectFieldComponent/SelectFieldComponent"
import DatePickerComponent from '../../../components/DatePickerComponent/CustomDatePicker';
import AutoCompleteComponent from '../../../components/AutoCompleteComponent/AutoCompleteComponent'

function DateFilterPanel(props) {

    const { classes, parentExpanded, handlePanel, surveys } = props;
    let dateFilters = [...FilterConstants.TIME_FRAMES];

    // Define Dispatch
    const dispatch = useDispatch();

    // Get filter Data
    const { filterData, surveys: surveyList } = useSelector(state => state.filter)
    let { startDate, endDate } = filterData.date || { startDate: null, endDate: null };
    startDate = dateFormatter(startDate)
    endDate = dateFormatter(endDate)

    let pulseDateArray = [];
    let survey = []

    if (filterData.surveys.length === 1 && filterData.surveys[0].frequency === "Pulse") {
        dateFilters.push("Pulse dates")
        dateFilters = [...new Set(dateFilters)]
        pulseDateArray = []
        survey = surveyList.filter(obj => obj._id + "" === filterData.surveys[0]._id + "")
        if (survey[0]) {
            Object.keys(survey[0].pulse_obj.pulseDetails).map((key) => {
                pulseDateArray.push(survey[0].pulse_obj.pulseDetails[key].displayName)
            })
        }
    }
    else {
        if (dateFilters.indexOf("Pulse dates") !== -1) {
            dateFilters.splice(dateFilters.indexOf("Pulse dates"), 1)
        }
    }

    // validation for end date
    useEffect(() => {
        if (startDate && endDate) {
            ValidatorForm.addValidationRule("isEnddategreater", (value) => {
                if (new Date(endDate) < new Date(startDate)) {
                    return false;
                } else {
                    return true;
                }
            });
        }
    }, [startDate, endDate])

    /**
   * Handle Date Filter Type Change
   * @param {*} e 
   * @param {*} type 
   */
    const handleChange = (e) => {
        if (filterData.date.pulseDate !== "" && e.value !== "Pulse") {
            filterData.date = {
                ...filterData.date,
                ["pulseDate"]: ""
            }
        }
        filterData.date = {
            ...filterData.date,
            [e.name]: e.value
        }
        dispatch(updateStateReducerAction.update(dashboardConstant.UPDATE_INITIAL_RELOAD, "all", () => {
            dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, filterData))
        }))

        // Get Demographics Data
        // getDemograpicsData()
    }

    /**
     * Handle Date Change
     * @param {*} e 
     */
    const handleDateChange = (e) => {
        // update to reducer alone
        filterData.date = {
            ...filterData.date,
            [e.name]: e.value ? dateFormatter(e.value) : null
        }
        dispatch(updateStateReducerAction.update(filterConstant.UPDATE_FILTER_SUCCESS, filterData))

        // if date is valid call api
        // if (moment(new Date(e.value)).isValid() && e.value) {
        //     // Get Demographics Data
        //     getDemograpicsData()
        // }
    }

    // Get Demographics Data
    const getDemograpicsData = () => {
        dispatch(filterAction.getDemographics({
            metadata: [],
            surveys: filterData.surveys,
            date: filterData.date
        }))
    }

    // Handle auto complete change
    const handleAutoCompleteChange = (event) => {
        Object.keys(survey[0].pulse_obj.pulseDetails).map((key) => {
            if (survey[0].pulse_obj.pulseDetails[key].displayName === event.value) {
                event.value = key
            }
        })
        handleChange(event)
    }

    const surveyType = filterData.surveys.length > 0 ? filterData.surveys[0].type : ""
    return (
        <React.Fragment>
            {surveyType !== "Multi-rater feedback" &&
                <Accordion
                    classes={{
                        root: classes.peaccordion_nocolor
                    }}
                    expanded={parentExpanded === 'date-panel'}
                    onChange={handlePanel('date-panel')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                        <Grid container justifyContent="space-between">
                            <TypographyComponent variant={"h6"}
                                className={`${"txtBold "}  ${parentExpanded === 'date-panel' ? "panel-active" : ""} `}>
                                {FilterConstants.DATES}
                            </TypographyComponent>
                        </Grid>
                    </AccordionSummary>

                    <AccordionDetails>
                        <Grid item xs={12}
                            className={`${classes.expansionDetail} ${"scrolly"}`}>
                            <Grid container name='validatable'>
                                <Grid item xs={12} name='validatable' className={'mt5'}>
                                    <TypographyComponent name='validatable' variant="h6">{FilterConstants.SELECT_TIMEFRAMES}
                                    </TypographyComponent>
                                    <SelectFieldComponent
                                        select
                                        name="type"
                                        className="height36 boderRadius4"
                                        options={dateFilters}
                                        value={filterData.date ? filterData.date.type : ""}
                                        fullWidth={true}
                                        handleChange={e => handleChange(e)}
                                    />
                                </Grid>
                                {filterData.date.type === 'Custom dates' &&
                                    <React.Fragment>
                                        <Grid name='validatable' item xs={6} className={'pt5 pr5'}>
                                            <TypographyComponent variant="h6">{FilterConstants.START_DATE}
                                            </TypographyComponent>
                                            <DatePickerComponent
                                                select
                                                name="startDate"
                                                value={startDate}
                                                className="height36 boderRadius4"
                                                handleChange={e => handleDateChange(e)}
                                                fullWidth={true}
                                                disabled={false}
                                                border={"square"}
                                                validators={["required", "isGoodDate"]}
                                                errorMessages={[errorsConstants.required, errorsConstants.invalidDateFormat, errorsConstants.isStartdategreater]}
                                            />
                                        </Grid>
                                        <Grid item xs={6} className={'pt5 pl5'}>
                                            <TypographyComponent variant="h6">{FilterConstants.END_DATE}
                                            </TypographyComponent>
                                            <DatePickerComponent
                                                name="endDate"
                                                value={endDate}
                                                className="height36 boderRadius4"
                                                fullWidth={true}
                                                disabled={false}
                                                border={"square"}
                                                handleChange={e => handleDateChange(e)}
                                                validators={["required", "isGoodDate", "isEnddategreater"]}
                                                errorMessages={[errorsConstants.required, errorsConstants.invalidDateFormat, errorsConstants.invalidDateRange, errorsConstants.isEnddategreater]}
                                            />
                                        </Grid>
                                    </React.Fragment>
                                }
                                {filterData.date.type === 'Pulse dates' &&
                                    <React.Fragment>
                                        <Grid item xs={12} className={'pt5 pr5'}>
                                            <AutoCompleteComponent
                                                name="pulseDate"
                                                suggestions={pulseDateArray}
                                                handleAutoCompolete={(e, n) => handleAutoCompleteChange({ value: e, name: n })}
                                                suggestionType="array"
                                                value={(filterData.date.pulseDate && survey[0]) ? survey[0].pulse_obj.pulseDetails[filterData.date.pulseDate].displayName : ""}
                                                validators={["required"]}
                                                errorMessages={[errorsConstants.required]}
                                            ></AutoCompleteComponent>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
        </React.Fragment>
    )
}

// default props
DateFilterPanel.defaultProps = {
    classes: {},
    panelHeight: 0,
    preference: {},
    parentExpanded: null,
    handlePanel: () => { }
};

// prop types
DateFilterPanel.propTypes = {
    classes: PropTypes.object,
    panelHeight: PropTypes.number,
    preference: PropTypes.object
};

export default withStyles(FilterStyles)(DateFilterPanel);