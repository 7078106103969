import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";
import queryString from "query-string";
import { isIE } from 'react-device-detect';

// import constants
import { errorsConstants } from "../../config/constants";

// import child router components
import TakeSurveyAuthentication from "./TakeSurveyAuthentication";
import TakeSurveyPreview from "./TakeSurveyPreview";
import PageNoIeSupport from "../PageNoIeSupport/PageNoIeSupport";

function TakeSurveyRouter(props) {
  const { match, takeSurveyUrl, surveyDeviceType, location } = props;
  const qryString = queryString.parse(location.search);
  return (
    <>
      {
        isIE ? (<PageNoIeSupport errorMsg={errorsConstants.IE_NOT_SUPPORTED} />)
          : (takeSurveyUrl !== "preview" ? (
            <Switch>
              <Route
                exact
                path={match.url}
                render={(props) => {
                  return <TakeSurveyAuthentication {...props} takeSurveyUrl={takeSurveyUrl} surveyDeviceType={surveyDeviceType} match={match} queryString={qryString} />;
                }}
              />
            </Switch>
          ) : (
              <TakeSurveyPreview {...props} takeSurveyUrl={takeSurveyUrl} surveyDeviceType={surveyDeviceType} match={match} queryString={qryString} />
            )
          )}
    </>
  );
}

/**
 * Bind Component Property Types
 */
TakeSurveyRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object,
};

/**
 * Export Component
 */
export default TakeSurveyRouter;
