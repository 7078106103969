import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "react-router-dom";

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";

// Import Helpers
import { addEllipsis } from "../../../../../helpers";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../../../../components/SearchInputCompoment/SearchInputComponent";

// import constants
import { FilterConstants, ReportsConstants, surveyTypes } from "../../../../../config/constants";

// Import images
import PulseImg from "../../../../../assets/images/svg/pulseicon.svg";
import { MeSurvey } from '../../../../../assets/images/svgComponents/ImageComponent';

//Import Actions
import { reportAction, alertAction } from "../../../../../redux/actions";
import { constant } from "lodash";

function SelectSurvey(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // get props
  const { classes, isSaveReport } = props;

  // Set Search State
  const [search, setSearch] = useState("");

  // Get surveys Data
  const { allSurveys, reportData } = useSelector((state) => state.report);
  let { surveys, date } = reportData;
  const pulseFound = surveys.filter((d) => d.frequency === "Pulse").length > 0;

  //Handle Search
  const handleSearch = (name, value) => {
    setSearch(value);
    dispatch(reportAction.getSurveys(value));
  };

  // on clear search
  const handleClearSearch = () => {
    setSearch("");
    dispatch(reportAction.getSurveys(""));
  };

  //check box values
  const getCheckedData = (id) => {
    if (reportData.reportType === "Dashboard Report") {
      let totSurveys = reportData.dashboardPreference.trendsOverTime
    }
    return surveys.filter((d) => d._id === id).length > 0 ? true : false;
  };

  //update surveys
  const updateSurveys = (e) => {
    const isPulse = surveys.filter((d) => d.frequency === "Pulse").length > 0;
    const isNonPulse = surveys.filter((d) => d.frequency !== "Pulse").length > 0;
    const isNonRater = surveys.filter((d) => d.type !== surveyTypes.MULTI_RATER_FEEDBACK).length > 0;
    const isMultiRater = surveys.filter((d) => d.type === surveyTypes.MULTI_RATER_FEEDBACK).length > 0;

    // if pulse and chosen not pulse OR if other and chosen pulse show alert
    if ((isMultiRater && e.type !== surveyTypes.MULTI_RATER_FEEDBACK) || (isNonRater && e.type === surveyTypes.MULTI_RATER_FEEDBACK)) {
      dispatch(alertAction.error(FilterConstants.COMBINE_ME_ALERT));
    } else if ((isPulse && e.frequency !== "Pulse") || (isNonPulse && e.frequency === "Pulse")) {
      dispatch(alertAction.error(FilterConstants.COMBINE_ALERT));
    } else {
      if (surveys.filter((d) => d._id === e._id).length > 0) {
        surveys = surveys.filter((d) => d._id !== e._id);
      } else {
        surveys.push(e);
      }

      allSurveys.map((eachSurvey) => {
        const surveydetails = surveys.filter(obj => obj._id === eachSurvey._id)
        if (surveydetails.length > 0) {
          reportData.date.type = "Custom dates"
          reportData.date.startDate = eachSurvey.startDate
          reportData.date.endDate = eachSurvey.endDate
        }
      })

      // then reset report type
      if (surveys.length === 0) {
        reportData.reportType = null
      }

      const updatedReportData = { ...reportData, surveys: surveys, demographics: {} };
      dispatch(reportAction.updateReport(updatedReportData));
    }
  };

    // Get Demographics Data
    const getDemographics = (dateUpdated) => {
    dispatch(
      reportAction.getDemographics({
        metadata: [],
        surveys: surveys,
        date: dateUpdated,
      })
    );
  };

  useEffect(()=>{
    if(date.type!==""){
      getDemographics(date);
     }
  },[date])

  // Get Survey Data
  useEffect(() => { 
    dispatch(reportAction.getSurveys("", (getSurveysRes) => {
      //default selecet first survey
      if (reportData["surveys"] && reportData["surveys"].length === 0 && getSurveysRes.length > 0 && !isSaveReport) {
        let surveyData = {
          name: getSurveysRes[0].name,
          _id: getSurveysRes[0]._id,
          frequency: getSurveysRes[0].frequency,
          type: getSurveysRes[0].type,
          hasRehireBaseQues: getSurveysRes[0].hasRehireBaseQues,
          hasComplianceBaseQues: getSurveysRes[0].hasComplianceBaseQues,
          startDate: getSurveysRes[0].startDate,
          endDate: getSurveysRes[0].endDate
        }
        updateSurveys(surveyData)
      }
    }));
  }, [dispatch]);


  // bind surveys
  const generateSurveys = () => {
    if (allSurveys && allSurveys.length > 0) {
      let checkboxJSX = [];
      allSurveys.map((data, ind) => {
        if (data['frequency'] === 'Pulse' || data['type'] === surveyTypes.MULTI_RATER_FEEDBACK) {
          return
        }
        checkboxJSX.push(
          <React.Fragment key={ind}>
            {data["frequency"] !== "Pulse" && (
              <Grid
                item
                xs={12}
                key={"div_" + data._id}
                className={classes.demographyCheckbox}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      key={data._id}
                      id={data._id}
                      name={data.name}
                      color="primary"
                      checked={getCheckedData(data._id)}
                      onChange={(e) =>
                        updateSurveys({
                          name: e.target.name,
                          _id: e.target.id,
                          frequency: data.frequency,
                          type: data.type,
                          hasRehireBaseQues: data.hasRehireBaseQues,
                          hasComplianceBaseQues: data.hasComplianceBaseQues,
                          startDate: data.startDate,
                          endDate: data.endDate
                        })
                      }
                    />
                  }
                  label={addEllipsis(data.name, 80)}
                />
              </Grid>
            )}
          </React.Fragment>
        )
      })
      return checkboxJSX.sort((a, b) => {
        let checked = false;
        let checkedTwo = false;
        if ('children' in a.props)
          checked = a.props.children.props.children.props.control.props.checked
        else
          checked = a.props

        if ('children' in b.props)
          checkedTwo = b.props.children.props.children.props.control.props.checked
        else
          checkedTwo = b.props;
        return checkedTwo - checked
      })
    } else {
      return (
        <Grid item xs={12} className={"mt-1"}>
          <TypographyComponent
            title="No Surveys Responded!"
            variant="inherit"
          />
        </Grid>)
    }
  }


  // Clear survey Filter 
  const handleClear = (e) => {
    const updatedReportData = { ...reportData, surveys: [] };
    dispatch(reportAction.updateReport(updatedReportData))
  }

  // //surveyNames as string for header
  // let surveyNames=surveys.map((d) => {return d.name });
  // surveyNames = surveyNames.toString()
  const  ME =   allSurveys.filter((data) => data.type === surveyTypes.MULTI_RATER_FEEDBACK );
  const  Pulse =   allSurveys.filter((data) => data["frequency"] === "Pulse");  
  return (
    <React.Fragment>
      <Grid container>
        <Grid item sm={2} className={classes.clearAllPosition} style={{ top: 0 }}>
          <Link
            className={`cursor-pointer colorTheme right`}
            onClick={e => handleClear(e)}
          >
            {"Clear all"}
          </Link>
        </Grid>
        <Grid item sm={10}>
          {/* {surveyNames} */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={6}>
          <Grid container>
            <Grid item sm={8}>
              <SearchInputComponent
                id="searchCategoryTex"
                placeholder={"Search"}
                value={search}
                field={"searchCategory"}
                showClearIcon={true}
                fullWidth={true}
                className={`${"mt-1 mb-1"}`}
                onInputChangeRequest={handleSearch}
                handleClearSearch={() => handleClearSearch}
                onEnter={() => { }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          {pulseFound && (
            <TypographyComponent variant="h5" className={`txtBold pt-1`}>
              {ReportsConstants.PULSE_SURVEY}
            </TypographyComponent>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {allSurveys.length !== (ME.length + Pulse.length) &&
        <Grid item sm={4} className={classes.surveyContainer}>
          {generateSurveys()}
        </Grid>}
        {Pulse.length > 0 &&
        <Grid item sm={4} className={classes.surveyContainer}>
          {allSurveys &&
            allSurveys.length > 0 &&
            allSurveys.map((data, ind) => (
              <React.Fragment key={ind}>
                {data["frequency"] === "Pulse" && (
                  <Grid
                    item
                    xs={12}
                    key={"div_" + data._id}
                    className={classes.demographyCheckbox}
                  >
                    <Grid
                      xs={6} className={"dflex"}>
                      <FormControlLabel
                        className={`${classes.checkboxContainer} pr5`}
                        control={
                          <Checkbox
                            key={data._id}
                            id={data._id}
                            name={data.name}
                            color="primary"
                            checked={getCheckedData(data._id)}
                            onChange={(e) =>
                              updateSurveys({
                                name: e.target.name,
                                _id: e.target.id,
                                frequency: data.frequency,
                                hasRehireBaseQues: data.hasRehireBaseQues,
                                hasComplianceBaseQues: data.hasComplianceBaseQues,
                                startDate: data.startDate,
                                endDate: data.endDate
                              })
                            }
                          />
                        }
                        label={addEllipsis(data.name, 80)}
                      />
                      <img className={`${classes.expansionPlus} ${"expansionPlus pr-1"}`} src={PulseImg}></img>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
        </Grid>}
        {ME.length > 0 &&
        <Grid item sm={4} className={classes.surveyContainer}>
          {allSurveys &&
            allSurveys.length > 0 &&
            allSurveys.map((data, ind) => (
              <React.Fragment key={ind}>
                {data.type === surveyTypes.MULTI_RATER_FEEDBACK && (
                  <Grid
                    item
                    xs={12}
                    key={"div_" + data._id}
                    className={classes.demographyCheckbox}
                  >
                    <Grid
                      xs={6} className={"dflex"}>
                      <FormControlLabel
                        className={`${classes.checkboxContainer} pr5`}
                        control={
                          <Checkbox
                            key={data._id}
                            id={data._id}
                            name={data.name}
                            color="primary"
                            checked={getCheckedData(data._id)}
                            onChange={(e) =>
                              updateSurveys({
                                name: e.target.name,
                                _id: e.target.id,
                                frequency: data.frequency,
                                type: data.type,
                                hasRehireBaseQues: data.hasRehireBaseQues,
                                hasComplianceBaseQues: data.hasComplianceBaseQues,
                                startDate: data.startDate,
                                endDate: data.endDate
                              })
                            }
                          />
                        }
                        label={addEllipsis(data.name, 80)}
                      />
                      <Grid className="p3">{MeSurvey()}</Grid>
                    </Grid>
                  </Grid>
                )}
              </React.Fragment>
            ))}
        </Grid>}
      </Grid>
    </React.Fragment>
  );
}
// default props
SelectSurvey.defaultProps = {
  classes: {},
};

// prop types
SelectSurvey.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(SelectSurvey);
