import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DesktopDatePicker as DatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { PickersModalDialog } from "@mui/x-date-pickers/internals/components/PickersModalDialog"
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';

import { TextField } from "@mui/material";
import { ValidatorComponent } from "react-material-ui-form-validator";
import moment from "moment"
import {InputAdornment} from "@mui/material";
import {IconButton} from "@mui/material";
import CalendarIcon from "../../assets/images/svg/calendar.svg";

// Import Images

/** 
 *DatePicker Component
 *
 * @class DatePicker
 * @extends {Component}
 */
function DatePickerComponent (props) {
  // Handle TextField Events
  const handleChange = (event, value) => {
    if (event) {
      let d = new Date();
      if(event._d && event._d.toString() === "Invalid Date") {
        d = new Date();
      } else {
        d = new Date(event);
      }
      // let date = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
      props.handleChange({ name: props.name, value: d });
    } else {
      props.handleChange({ name: props.name, value: null });
    }
  };
  /**
   *render html
   *
   * @returns
   * @memberof DatePicker
   */
  const { name, value, open, fullWidth, label, disabled, margin, size, className, validators, errorMessages, validations, onBlur, onOpen, onClose, inputFormat,showToolbar, style, helperText, notRequired, panel, minDate, maxDate, border } = props;
  const customClass = validators && validators.length > 0 && !notRequired ? className + " required" : className;
  
  const [tempValue, setTempValue] = React.useState(value);
  const [show, setShow] = React.useState(false);

  useEffect(()=>{
    setTempValue(value)
  },[value])

  return (
    <>
      <DatePicker      
        value={value || null}
        onChange={handleChange}    
        inputFormat={inputFormat} 
        className={`${customClass} date-picker-custom`}
        disabled={disabled}
        open={false}
        invalidDateMessage={errorMessages}
        validators={validators}
        errorMessages={errorMessages}
        onOpen={() => {
          setShow(true);
        }}
        onClose={onClose}
        {...validations}
        panel={panel}  
        minDate={minDate? minDate: new Date("1900-01-01")}
        maxDate={maxDate? maxDate: new Date("2100-01-01")}
        
        renderInput={(props) => <TextField  
          // InputProps={
          //   {
          //     endAdornment: (
          //       < InputAdornment position="end" style={{ cursor: "pointer" }}>
          //         <IconButton position="end" style={{padding: "5px"}}>
          //           <img src={CalendarIcon} alt="calender" style={{ height: "16px", weight: "16px", position: "relative", top: "3px", left: "5px" }} />
          //         </IconButton>
          //       </InputAdornment>
          //     )
          //   }
          // }
          // helperText={props?.InputProps?.placeholder}
          // className={`date-picker-custom ${helperText && "errorBorder"}`} uncomment to bring error border color
          className={`${(border === "square") ? "date-picker-custom " + className : "date-picker-custom"}`}
          helperText={helperText}
          label={label}
          name={name}
          margin={margin}
          size={size}
          placeholder="__/__/____"
          fullWidth={fullWidth}
          {...props}
          sx={{ svg: { color: "#C0C1C2" } }}
        />}
      />
      <PickersModalDialog
        open={show}
        onAccept={() => {
          setShow(false);
          handleChange(moment(tempValue), tempValue);
        }}
        onDismiss={()=> setShow(false)}
        onCancel={()=> setShow(false)}
      >
        <StaticDatePicker
          displayStaticWrapperAs="mobile"
          onChange={(newValue) => setTempValue(newValue)}
          value={tempValue || new Date()}
          renderInput={(params) => <TextField {...params} />}
        />
      </PickersModalDialog>

    </>
  );
}

// default props
DatePickerComponent.defaultProps = {
  name: "",
  value: "",
  label: "",
  helperText: "",
  fullWidth: "",
  disabled: false,
  className: "cursor-pointer",
  errorMessages: [],
  validators: [],
  onOpen: ()=>{},
  onClose: ()=>{},
  handleChange: () => { },
  validations: {},
  inputFormat: "MM/dd/yyyy",
  fieldErrors: [],
  warningFieldName: "",
  notRequired: false,
  margin: "dense",
  size: "small",
  maxDate: null,
  minDate: null,
  border: "",
  renderInput: ()=>{}
};

// prop types
DatePickerComponent.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  showToolbar: PropTypes.bool,
  open: PropTypes.bool,
  fullWidth: PropTypes.bool,
  inputFormat: PropTypes.string,
  margin: PropTypes.string,
  className: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
  validators: PropTypes.array,
  helperText: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  maxDate: PropTypes.any,
  minDate: PropTypes.any,
  renderInput: PropTypes.func,
  border: PropTypes.string
};

// Export Component
export default class Picker extends ValidatorComponent {
  renderValidatorComponent () {
    const {
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      ...rest
    } = this.props;
    const { isValid } = this.state;
    const hText = (!isValid && this.getErrorMessage()) || helperText;

    return (
      <DatePickerComponent
        {...rest}
        error={!isValid}
        helperText={hText}
      />
    );
  }
};
