/* eslint-disable no-dupe-keys */
const TabStyle = () => ({
    // drawer
    drawer: {
        "& .MuiPaper-elevation1": {
            boxShadow: "none !important",
        },
    },
    // Tabs
    rightPanelAppBarRoot: {
        background: "transparent",
        height: "45px",
    },
    tabcharlimit: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "175px",
    },
    rightPanelTabRoot: {
        borderRadius: "5px 5px 0px 0px",
        backgroundColor: "#EBECED",
        margin: "0px 4px",
        color: "rgba(0, 0, 0, 0.87) !important",
        "&:first-child": {
            marginLeft: '0px'
        },
        "&:hover": {
            color: "#fff !important",
            backgroundColor: "#54C0FD !important",
        },
        "&:hover .peTabEdit, &:hover .peTabDelete": {
            visibility: "visible !important",
            filter: "brightness(0) invert(1)",
        },
        "&$selected": {
            color: "#fff",
            backgroundColor: "#54C0FD",
        },
        "&:focus": {
            color: "#fff",
            backgroundColor: "#54C0FD",
        },
    },
    rightPanelTabSelected: {
        backgroundColor: "#54C0FD !important",
        color: "#fff !important",
    },
    customRightMenus: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        paddingRight: '8px'
    },
    customRightMenu: {
        marginLeft: '0px',
        display: 'flex',
        paddingRight: '10px',
    },
    filterTitle: {
        minHeight: "55px"
    },
    raterGroupInfo: {
        background: "#eaf8fe",
        display: "block",
        padding: "5px",
        border: "2px dashed #5dc3fd",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "justify"
    },
    clearAll: {
        float: "left",
        marginTop: "-2px",
        marginRight: "13px",
        fontSize: "0.875rem",
        textDecoration: 'underline !important'
    },
    saveFilterBtn: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '12px'
    },
    saveFilterBtn_btn: {
        marginRight: '8px'
    },
    demographySearchImg: {
        position: "absolute",
        right: "10px",
        top: "9px",
        zIndex: "1",
    },
    demographySearch: {
        "& .MuiInput-input": {
            height: "21px",
        },
        saveReportEmail: {
            border: "1px solid #E3E4E6",
            borderRadius: "10px",
            padding: "15px",
        }
    },
    demographicsChips: {
        "&.MuiChip-root": {
            background: "#EDF9FF",
            border: "1px solid #C0E2F5",
            padding: "10px",
            height: "28px",
            maxWidth: "320px",
        },
    },
    demogrpahicsChipClose: {
        width: "14px",
        height: "14px",
    },
    // rater form field
    fieldGroup: {
        marginTop: '8px'
    },
    // Manager relationship
    cardStyle: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 0px 4px rgba(0,0,0,0.2)',
        margin: '0px 8px',
        padding: '8px',
        borderRadius: '3px',
        marginBottom: '12px'
    },
    cardLeftIcon: {
        display: 'flex',
        flex: 0,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    cardLeftLabel: {
        display: 'flex',
        flex: 0,
        margin: '0px 8px'
    },
    cardIndex: {
        display: 'flex',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        backgroundColor: '#f2f4f5',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cardConent: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    cardActions: {
        display: 'flex',
        flex: 0,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    cardText: {
        // margin: '0px 8px',
        borderColor: 'transparent',
        '& .MuiOutlinedInput-input': {
            height: "30px"
        },
        "&.editable": {
            borderColor: ""
        }
    },
    addLinkBtn: {
        "&.MuiButton-containedPrimary": {
            backgroundColor: 'transparent !important',
            color: '#54C0FD'
        }
    }
});

export default TabStyle;
