/* eslint-disable quote-props */
/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import IconButton from "@mui/material/IconButton";

// import constants
import { surveyQuestionsConstants } from "../../../../../config/constants";

// Import Images
import Addquestion from "../../../../../assets/images/svg/plus-blue.svg";

// Import Actions
import { categoryAction, alertAction } from "../../../../../redux/actions";

function AddSubQuestions (props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, category, questionDataSet, item } = props;

  // Add new Sub Question
  const addSubQuestion = () => {
    if (!questionDataSet["subQues"]) {
      questionDataSet["subQues"] = [];
    }

    // check duplicates or empty before adding new question
    let emptySubQues = 0;
    let duplicateQues = false;
    for (let i = 0; i < questionDataSet["subQues"].length; i++) {
      if (!questionDataSet["subQues"][i]["text"][surveyQuestionsConstants.DEFAULT_LANG_CODE] || questionDataSet["subQues"][i]["text"][surveyQuestionsConstants.DEFAULT_LANG_CODE].trim() === false) {
        emptySubQues++;
      }
      let subText = questionDataSet["subQues"][i]["text"][surveyQuestionsConstants.DEFAULT_LANG_CODE] ? questionDataSet["subQues"][i]["text"][surveyQuestionsConstants.DEFAULT_LANG_CODE] : "";
      for (let j = i + 1; j < questionDataSet["subQues"].length; j++) {
        if (subText.toLowerCase() === questionDataSet["subQues"][j]["text"][surveyQuestionsConstants.DEFAULT_LANG_CODE].toLowerCase()) {
          duplicateQues = true;
        }
      }
    }
    if (!duplicateQues && emptySubQues < 1) {
      questionDataSet["subQues"].push({ "id": `matSubQues-${Math.random(new Date().getTime())}`.replace(".", ""), "text": { "en": "" } });
      if (!item) {
        dispatch(categoryAction.addOrChangeQuestion(category, questionDataSet));
      } else {
        props.editQuestionsData(questionDataSet);
      }
    } else {
      if(emptySubQues>=1){
        dispatch(alertAction.error("Subquestion cannot be empty"));
      }else{
        dispatch(alertAction.error(surveyQuestionsConstants.DUPLICATE_SUB_QUESTION));
      }
    }
  };

  return (
    <Grid item sm={6} className={classes.answerChoices}>
      <Grid item>
        <Link
          onClick={() => { addSubQuestion(); }}
          className={`${classes.quest_linkimg} ${"cursor-pointer"}`}
          color="primary"
        >
          <IconButton color="inherit" className={"p-0"} size="large">
            <img src={Addquestion} alt={""} />
          </IconButton>
      Add sub questions
        </Link>
      </Grid>
    </Grid>
  );
}

// default props
AddSubQuestions.defaultProps = {
  classes: {},
  category: null,
  questionDataSet: {},
  item: null,
  editQuestionsData: () => { }
};

// prop types
AddSubQuestions.propTypes = {
  classes: PropTypes.object,
  questionDataSet: PropTypes.object,
  item: PropTypes.object,
  category: PropTypes.string,
  editQuestionsData: PropTypes.func
};

export default AddSubQuestions;
