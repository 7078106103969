import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// MUI
import { 
    Grid, TextField, Checkbox, List, IconButton,
    FormControl, FormControlLabel,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";

// Import Styles
import MultiSelectChecboxWithSearchStyle from "./MultiSelectChecboxWithSearchStyle";

// Import Components
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import ToolTipComponent from "../ToolTipComponent/ToolTipComponent";
import TypographyComponent from "../TypographyComponent/TypographyComponent";

const orderCheckboxData = ((checkboxData, selectedCheckboxDatas) => {
    const selectedItems = (selectedCheckboxDatas || []).reduce((acc, curr, index) => {
        if (curr && checkboxData?.[curr]) {
            acc = {...acc, [curr]: checkboxData?.[curr]};
        }

        return acc;
    }, {});

    const nonSelectedItems = Object.keys((checkboxData || {})).reduce((acc, curr, index) => {
        if (selectedCheckboxDatas.indexOf(curr) === -1 && curr && checkboxData?.[curr]) {
            acc = {...acc, [curr]: checkboxData?.[curr]};
        }

        return acc;
    }, {});

    return {
        ...selectedItems,
        ...nonSelectedItems
    }
});

const MultiSelectCheckboxWithSearch = (props) => {
    const { classes, checkboxData, handleApply, handleCancel, defaultSelectAll, selectedCheckboxDatas, triggerType } = props;
    const dataValidation = checkboxData && _.isObject(checkboxData) && Object.keys(checkboxData).length > 0;

    const [searchText, setSearchText] = useState('');
    const [selectedItems, setSelectedItems] = useState(defaultSelectAll && selectedCheckboxDatas.length === 0 ? Object.keys(checkboxData) : selectedCheckboxDatas);
    const [checkboxItems, setCheckboxItems] = useState(checkboxData);
    const searchRef = useRef(null);

    const handleCheckboxChange = (checkboxStatus, itemId) => {
        // Toggle the selected state of the item
        setSelectedItems((prevSelected) => {
            if (!checkboxStatus) {
                return prevSelected.filter((id) => id !== itemId);
            } else {
                return [...prevSelected, itemId];
            }
        });
    };

    const generateFormControlLabel = (label, key) => {
        return (
            <FormControlLabel
                className={`${classes.checkBoxEllipsis} width100`}
                control={
                    <Checkbox
                        size="small"
                        className={classes.checkBox}
                        defaultChecked={selectedCheckboxDatas?.length === 0 && defaultSelectAll}
                        checked={(selectedItems || []).indexOf(key) !== -1}
                        inputProps={{ 'aria-label': 'controlled' }}
                        onChange={(e) => {
                            handleCheckboxChange(e.target.checked, key)
                        }}
                    />
                }
                label={label}
            />
        )
    }

    useEffect(() => {
        if (dataValidation) {
            searchRef.current.focus();
        }
    }, [])

    // Effect to handle debounced search text changes
    useEffect(() => {
        const debouncedSearch = _.debounce((searchText) => {
            // Filter items based on the input text
            let filteredItems = {};
            
            if (searchText) {
                Object.keys(checkboxData).map((key) => {
                    if (checkboxData?.[key].toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
                        filteredItems[key] = checkboxData?.[key];
                    }
                })
            } else {
                filteredItems = checkboxData;
            }

            // Update the list with filtered items
            setCheckboxItems(filteredItems);
        }, searchText ? 300 : 0);

        // Call the debounced function when the input text changes
        debouncedSearch(searchText);

        // Cleanup the debounce function on component unmount
        return () => debouncedSearch.cancel();
    }, [searchText]);

    return (
        <Grid container spacing={2} className={`${classes.compContainer} ${!dataValidation ? 'autoWidth' : ''}`}>
            {/* Render data only data came as prop */}
            {
                dataValidation &&
                    <FormControl
                    className={"width100"}
                    >
                        <Grid item xs>
                            <TextField
                                variant="outlined"
                                value={searchText}
                                inputRef={searchRef}
                                placeholder="Search"
                                className={`${"height28 width100"}`}
                                onChange= {(e) => {setSearchText(e.target.value)}}
                                InputProps={{
                                    endAdornment: Object.keys(checkboxItems).length === 0 &&
                                        <IconButton onClick={() => {setSearchText(''); searchRef.current.focus();}} edge="end">
                                            <Cancel className={classes.closeIcon} />
                                        </IconButton>,
                                }}
                            />
                        </Grid>
                        <Grid item xs>
                            <List className={classes.compList}>
                                {/* Show Select All only when search text is empty */}
                                {
                                    Object.keys(checkboxItems).length === Object.keys(checkboxData).length &&
                                        <FormControlLabel
                                            className={"width100"}
                                            control={
                                                <Checkbox 
                                                    size="small"
                                                    className={classes.checkBox}
                                                    defaultChecked={defaultSelectAll}
                                                    onChange={(e) => {
                                                        setSelectedItems(e.target.checked ? Object.keys(checkboxData) : []);
                                                    }}
                                                    checked={Object.keys(checkboxData).length === selectedItems.length}
                                                />
                                            } 
                                            label="Select All"
                                        />
                                }
                                {/* Render Checkbox Items only when it's empty or search text matches */}
                                {
                                    (Object.keys(checkboxItems).length > 0) &&
                                        Object.keys(orderCheckboxData(checkboxItems, selectedItems)).map((currentKey) => {
                                            const currentLabel = checkboxItems?.[currentKey];
                                            const MAX_STRING_CHAR = 59;
                                            const checkboxLabelCondition = currentLabel.length > MAX_STRING_CHAR;
                                            const truncatedCheckBoxLabel = currentLabel.substring(0, MAX_STRING_CHAR - 3) + '...';

                                            return (
                                                checkboxLabelCondition ?
                                                    <ToolTipComponent
                                                        title={currentLabel}
                                                    >
                                                        {generateFormControlLabel(truncatedCheckBoxLabel, currentKey)}
                                                    </ToolTipComponent>
                                                :   <>{generateFormControlLabel(currentLabel, currentKey)}</>
                                            )
                                        })
                                }
                                {/* Show No Result FOund message if search text not matches */}
                                {
                                    Object.keys(checkboxItems).length === 0 &&
                                        <Grid container>
                                            <Grid item xs={12} textAlign={'center'}>
                                                <TypographyComponent className={classes.noSearchResults} title={"No Search Results Found"} variant={"body"} />
                                            </Grid>
                                        </Grid>
                                }
                            </List>
                        </Grid>
                        {
                            Object.keys(checkboxItems).length > 0 &&
                                <Grid item xs>
                                    <Grid container justifyContent={'flex-end'} alignItems={'center'} gap={1}>
                                        <ButtonComponent 
                                            title={'Apply'}
                                            color={'primary'}
                                            size={'small'}
                                            handleClick={() => { handleApply(triggerType, selectedItems) }}
                                            disabled={selectedItems.length === 0}
                                        />
                                        <ButtonComponent
                                            title={'Cancel'}
                                            size={'small'}
                                            handleClick={() => {handleCancel()}}
                                        />
                                    </Grid>
                                </Grid>
                        }
                    </FormControl>
            }
            {/* Passed data issue */}
            {
                !dataValidation &&
                    <Grid item xs={12} textAlign={'center'}>
                        <TypographyComponent className={classes.noSearchResults} title={"Not Enough Data to Filter"} variant={"body"} />
                    </Grid>
            }
        </Grid>
    )
}

// default props
MultiSelectCheckboxWithSearch.defaultProps = {
    checkboxData: {},
    handleApply: () => {},
    handleCancel: () => {},
    defaultSelectAll: false,
    selectedCheckboxDatas: [],
    triggerType: '',
}

// prop types
MultiSelectCheckboxWithSearch.propTypes = {
    // Pass array of objects with key and value pair of {key1: value1, key2: value2}
    checkboxData: PropTypes.object.isRequired,
    handleApply: PropTypes.func,
    handleCancel: PropTypes.func,
    defaultSelectAll: PropTypes.bool,
    selectedCheckboxDatas: PropTypes.array,
    triggerType: PropTypes.string,
}

export default MultiSelectChecboxWithSearchStyle(MultiSelectCheckboxWithSearch);