import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

/**
 *Cancel Dialog Component
 *
 * @class CancelDialogComponent
 * @extends {React.Component}
 */
function CancelDialogComponent (props) {
  /** Handle Dialog close Action  */

  const { handleCancelAction, open, handleOnNoAction, handleOnYesAction, content, showCancelBtn } = props;

  const handleNo = () => {
    handleOnNoAction();
  };

  /** Handle Dialog Cancel Action */
  const handleCancel = () => {
    handleCancelAction();
  };

  const handleYes = () => {
    handleOnYesAction();
  };
  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="p15 dialog-padding"
    >
      {/* <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle> */}
      <DialogContent className="p-40">
        {/* <DialogContentText id="alert-dialog-description"> */}
        {content}
        {/*
                    </DialogContentText> */}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleYes}
          variant="contained"
          disableElevation
          size="small"
          color="primary">
                    Yes
        </Button>
        <Button
          onClick={handleNo}
          variant="contained"
          color="secondary"
          size="small"
          disableElevation
          autoFocus>
                    No
        </Button>
        {showCancelBtn &&
          <Button
            onClick={handleCancel}
            variant="contained"
            color="secondary"
            size="small"
            disableElevation
            autoFocus>
            Cancel
        </Button>
        }
      </DialogActions>
    </Dialog>
  );
}

// default props
CancelDialogComponent.defaultProps = {
  open: false,
  id: null,
  showCancelBtn: true,
  content: "You are about to exit the participant detail page. Do you want to save your changes?",
  handleCancelAction: () => { },
  handleOnYesAction: () => { },
  handleOnNoAction: () => { }
};

// prop types
CancelDialogComponent.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.any,
  showCancelBtn:PropTypes.bool,
  handleCancelAction: PropTypes.func.isRequired,
  handleOnNoAction: PropTypes.func.isRequired,
  handleOnYesAction: PropTypes.func.isRequired
};

/** Export Component */
export default CancelDialogComponent;
