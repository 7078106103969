/* eslint-disable no-constant-condition */
/* eslint-disable no-self-compare */
/* eslint-disable prefer-const */
import React from "react";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";

// Imports Styles
import ButtonComponentStyle from "./ButtonComponentStyle";

/**
 * Button Component
 * @param {*} props
 */
function ButtonComponent(props) {
  const {
    classes,
    type,
    component,
    to,
    pageClassName,
    componentClassName,
    title,
    variant,
    color,
    disabled,
    disableElevation,
    size,
    form,
    style
  } = props;

  const handleClick = (e) => {
    props.handleClick(e);
  };

  let className = componentClassName ? `${pageClassName} ${classes[componentClassName]}` : pageClassName;

  let updatedColor = color
  if (color === "default" || !color) {
    updatedColor = "secondary"
  }
  else {
    updatedColor = "primary"
  }

  return (
    <React.Fragment>
      {form
        ? <Button
          data-test="button-data"
          type={type}
          variant={variant}
          color={updatedColor}
          disabled={disabled}
          size={size}
          component={component}
          to={to}
          disableElevation={disableElevation}
          className={className}
          form={form}
          onClick={handleClick}
          style={style}
        >
          {false === false ? title : <CircularProgress color="secondary" style={{ height: 24, width: 24 }} />}
        </Button>
        : <Button
          data-test="button-data"
          type={type}
          variant={variant}
          color={updatedColor}
          disabled={disabled}
          size={size}
          component={component}
          to={to}
          disableElevation={disableElevation}
          className={className}
          onClick={handleClick}
          style={style}
        >
          {false === false ? title : <CircularProgress color="secondary" style={{ height: 24, width: 24 }} />}
        </Button>
      }
    </React.Fragment>
  );
}

// default props
ButtonComponent.defaultProps = {
  classes: {},
  type: "button",
  title: "",
  color: "",
  disableElevation: true,
  size: "small",
  variant: "contained",
  pageClassName: "",
  loading: false,
  componentClassName: "",
  component: "button",
  to: "",
  disabled: false,
  form: "",
  href: "",
  handleClick: () => { },
  style: {}
};

// prop types
ButtonComponent.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  pageClassName: PropTypes.string,
  loading: PropTypes.bool,
  componentClassName: PropTypes.string,
  // component: PropTypes.elementType,
  to: PropTypes.string,
  disabled: PropTypes.bool,
  disableElevation: PropTypes.bool,
  href: PropTypes.string,
  form: PropTypes.string,
  handleClick: PropTypes.func,
  style: PropTypes.object
};

/** Export Component */
export default withStyles(ButtonComponentStyle)(ButtonComponent);
