export const surveyButtons = [
    {
        "language": "Afrikaans",
        "code": "af",
        "submit": "Indien",
        "continue": "Aanhou"
    },
    {
        "language": "Albanian",
        "code": "sq",
        "submit": "paraqes",
        "continue": "vazhdoj"
    },
    {
        "language": "Amharic",
        "code": "am",
        "submit": "አስገባ",
        "continue": "ቀጥል"
    },
    {
        "language": "Arabic",
        "code": "ar",
        "submit": "إرسال",
        "continue": "يكمل"
    },
    {
        "language": "Armenian",
        "code": "hy",
        "submit": "Ներկայացնել",
        "continue": "Շարունակել"
    },
    {
        "language": "Azerbaijani",
        "code": "az",
        "submit": "təqdim",
        "continue": "davam etdirmək"
    },
    {
        "language": "Basque",
        "code": "eu",
        "submit": "Bidali",
        "continue": "Jarraitu"
    },
    {
        "language": "Belarusian",
        "code": "be",
        "submit": "прадставіць",
        "continue": "працягваць"
    },
    {
        "language": "Bengali",
        "code": "bn",
        "submit": "জমা দিন",
        "continue": "চালিয়ে"
    },
    {
        "language": "Bosnian",
        "code": "bs",
        "submit": "podnijeti",
        "continue": "nastaviti"
    },
    {
        "language": "Bulgarian",
        "code": "bg",
        "submit": "Изпращане",
        "continue": "продължи"
    },
    {
        "language": "Catalan",
        "code": "ca",
        "submit": "Presentar",
        "continue": "continuar"
    },
    {
        "language": "Cebuano",
        "code": "ceb",
        "submit": "Isumiter",
        "continue": "Padayon"
    },
    {
        "language": "Simplified Chinese",
        "code": "zh-CN",
        "submit": "提交",
        "continue": "继续"
    },
    {
        "language": "Traditional Chinese",
        "code": "zh-TW",
        "submit": "提交",
        "continue": "繼續"
    },
    {
        "language": "Corsican",
        "code": "co",
        "submit": "cunfirmate",
        "continue": "continuà"
    },
    {
        "language": "Croatian",
        "code": "hr",
        "submit": "podnijeti",
        "continue": "Nastaviti"
    },
    {
        "language": "Czech",
        "code": "cs",
        "submit": "Předložit",
        "continue": "Pokračovat"
    },
    {
        "language": "Danish",
        "code": "da",
        "submit": "Indsend",
        "continue": "Blive ved"
    },
    {
        "language": "Dutch",
        "code": "nl",
        "submit": "voorleggen",
        "continue": "Doorgaan met"
    },
    {
        "language": "English",
        "code": "en",
        "submit": "Submit",
        "continue": "Continue"
    },
    {
        "language": "Esperanto",
        "code": "eo",
        "submit": "Proponu",
        "continue": "Daŭrigu"
    },
    {
        "language": "Estonian",
        "code": "et",
        "submit": "Esita",
        "continue": "jätkama"
    },
    {
        "language": "Finnish",
        "code": "fi",
        "submit": "Lähetä",
        "continue": "Jatkaa"
    },
    {
        "language": "French",
        "code": "fr",
        "submit": "Soumettre",
        "continue": "Continuer"
    },
    {
        "language": "Frisian",
        "code": "fy",
        "submit": "submit",
        "continue": "Trochgean"
    },
    {
        "language": "Galician",
        "code": "gl",
        "submit": "someter",
        "continue": "continuar"
    },
    {
        "language": "Georgian",
        "code": "ka",
        "submit": "წარუდგინოს",
        "continue": "გაგრძელება"
    },
    {
        "language": "German",
        "code": "de",
        "submit": "einreichen",
        "continue": "Fortsetzen"
    },
    {
        "language": "Greek",
        "code": "el",
        "submit": "υποβάλλουν",
        "continue": "Να συνεχίσει"
    },
    {
        "language": "Gujarati",
        "code": "gu",
        "submit": "સબમિટ",
        "continue": "ચાલુ"
    },
    {
        "language": "Haitian Creole",
        "code": "ht",
        "submit": "soumèt",
        "continue": "kontinye"
    },
    {
        "language": "Hausa",
        "code": "ha",
        "submit": "sallama",
        "continue": "Ci gaba"
    },
    {
        "language": "Hawaiian",
        "code": "haw",
        "submit": "waiho i",
        "continue": "E hoomau"
    },
    {
        "language": "Hebrew",
        "code": "he",
        "submit": "שלח",
        "continue": "לְהַמשִׁיך"
    },
    {
        "language": "Hindi",
        "code": "hi",
        "submit": "प्रस्तुत",
        "continue": "जारी रखें"
    },
    {
        "language": "Hmong",
        "code": "hmn",
        "submit": "Xa",
        "continue": "Mus"
    },
    {
        "language": "Hungarian",
        "code": "hu",
        "submit": "Beküldés",
        "continue": "Folytatni"
    },
    {
        "language": "Icelandic",
        "code": "is",
        "submit": "Senda inn",
        "continue": "Halda áfram"
    },
    {
        "language": "Igbo",
        "code": "ig",
        "submit": "nyefere",
        "continue": "Continue"
    },
    {
        "language": "Indonesian",
        "code": "id",
        "submit": "Kirimkan",
        "continue": "Terus"
    },
    {
        "language": "Irish",
        "code": "ga",
        "submit": "Cuir",
        "continue": "Leanúint ar aghaidh"
    },
    {
        "language": "Italian",
        "code": "it",
        "submit": "Invia",
        "continue": "Continua"
    },
    {
        "language": "Japanese",
        "code": "ja",
        "submit": "参加する",
        "continue": "継続する"
    },
    {
        "language": "Javanese",
        "code": "jv",
        "submit": "Kirim",
        "continue": "terus"
    },
    {
        "language": "Kannada",
        "code": "kn",
        "submit": "ಸಲ್ಲಿಸು",
        "continue": "ಮುಂದುವರಿಸಿ"
    },
    {
        "language": "Kazakh",
        "code": "kk",
        "submit": "Жіберу",
        "continue": "жалғастыру"
    },
    {
        "language": "Khmer",
        "code": "km",
        "submit": "ដាក់ស្នើ",
        "continue": "បន្ត"
    },
    {
        "language": "Kinyarwanda",
        "code": "rw",
        "submit": "Submit",
        "continue": "gukomeza"
    },
    {
        "language": "Korean",
        "code": "ko",
        "submit": "제출",
        "continue": "계속하다"
    },
    {
        "language": "Kurdish",
        "code": "ku",
        "submit": "Nermijîn",
        "continue": "Berdewamkirin"
    },
    {
        "language": "Kyrgyz",
        "code": "ky",
        "submit": "баш ийүү",
        "continue": "улантуу"
    },
    {
        "language": "Lao",
        "code": "lo",
        "submit": "ຍື່ນສະເຫນີ",
        "continue": "ສືບຕໍ່ການ"
    },
    {
        "language": "Latin",
        "code": "la",
        "submit": "submit",
        "continue": "continue"
    },
    {
        "language": "Latvian",
        "code": "lv",
        "submit": "Iesniegt",
        "continue": "Turpināt"
    },
    {
        "language": "Lithuanian",
        "code": "lt",
        "submit": "Pateikti",
        "continue": "Tęsti"
    },
    {
        "language": "Luxembourgish",
        "code": "lb",
        "submit": "validéieren",
        "continue": "weider"
    },
    {
        "language": "Macedonian",
        "code": "mk",
        "submit": "достават",
        "continue": "продолжи"
    },
    {
        "language": "Malagasy",
        "code": "mg",
        "submit": "manaiky",
        "continue": "hanohy"
    },
    {
        "language": "Malay",
        "code": "ms",
        "submit": "hantar",
        "continue": "Teruskan"
    },
    {
        "language": "Malayalam",
        "code": "ml",
        "submit": "സമർപ്പിക്കുക",
        "continue": "തുടരുക"
    },
    {
        "language": "Maltese",
        "code": "mt",
        "submit": "Issottometti",
        "continue": "Kompli"
    },
    {
        "language": "Maori",
        "code": "mi",
        "submit": "tuku",
        "continue": "Haere tonu"
    },
    {
        "language": "Marathi",
        "code": "mr",
        "submit": "प्रस्तुत करणे",
        "continue": "सुरू"
    },
    {
        "language": "Mongolian",
        "code": "mn",
        "submit": "оруулах",
        "continue": "Continue"
    },
    {
        "language": "Myanmar (Burmese)",
        "code": "my",
        "submit": "Submit",
        "continue": "ဆက်လက်"
    },
    {
        "language": "Nepali",
        "code": "ne",
        "submit": "पेश",
        "continue": "जारी राख्न"
    },
    {
        "language": "Norwegian",
        "code": "no",
        "submit": "Sende inn",
        "continue": "Fortsette"
    },
    {
        "language": "Nyanja (Chichewa)",
        "code": "ny",
        "submit": "kugonjera",
        "continue": "Pitirizani"
    },
    {
        "language": "Odia (Oriya)",
        "code": "or",
        "submit": "ସମର୍ପଣ କରିବା",
        "continue": "ଜାରି"
    },
    {
        "language": "Pashto",
        "code": "ps",
        "submit": "سپارل",
        "continue": "ادامه"
    },
    {
        "language": "Persian",
        "code": "fa",
        "submit": "ارسال",
        "continue": "ادامه هید"
    },
    {
        "language": "Polish",
        "code": "pl",
        "submit": "Zatwierdź",
        "continue": "Kontyntynuj"
    },
    {
        "language": "Portuguese (Portugal, Brazil)",
        "code": "pt",
        "submit": "Enviar",
        "continue": "Prosseguir"
    },
    {
        "language": "Punjabi",
        "code": "pa",
        "submit": "ਜਮ੍ਹਾਂ ਕਰੋ",
        "continue": "ਜਾਰੀ ਰੱਖੋ"
    },
    {
        "language": "Romanian",
        "code": "ro",
        "submit": "Trimite",
        "continue": "Continua"
    },
    {
        "language": "Russian",
        "code": "ru",
        "submit": "Представлять на рассмотрение",
        "continue": "Продолжать"
    },
    {
        "language": "Samoan",
        "code": "sm",
        "submit": "gauaʻi",
        "continue": "faaauau"
    },
    {
        "language": "Scots Gaelic",
        "code": "gd",
        "submit": "Cuir a-steach",
        "continue": "cumail oirnn a '"
    },
    {
        "language": "Serbian",
        "code": "sr",
        "submit": "прихвати",
        "continue": "Настави"
    },
    {
        "language": "Sesotho",
        "code": "st",
        "submit": "romela",
        "continue": "tsoela pele"
    },
    {
        "language": "Shona",
        "code": "sn",
        "submit": "bvuma",
        "continue": "Ramba"
    },
    {
        "language": "Sindhi",
        "code": "sd",
        "submit": "جميع",
        "continue": "جاري"
    },
    {
        "language": "Sinhala (Sinhalese)",
        "code": "si",
        "submit": "ඉදිරිපත්",
        "continue": "දිගටම"
    },
    {
        "language": "Slovak",
        "code": "sk",
        "submit": "Predložiť",
        "continue": "ďalej"
    },
    {
        "language": "Slovenian",
        "code": "sl",
        "submit": "predloži",
        "continue": "Naprej"
    },
    {
        "language": "Somali",
        "code": "so",
        "submit": "Gudbi",
        "continue": "Continue"
    },
    {
        "language": "Spanish",
        "code": "es",
        "submit": "Enviar",
        "continue": "Continuar"
    },
    {
        "language": "Sundanese",
        "code": "su",
        "submit": "patuh",
        "continue": "neruskeun"
    },
    {
        "language": "Swahili",
        "code": "sw",
        "submit": "wasilisha",
        "continue": "kuendelea"
    },
    {
        "language": "Swedish",
        "code": "sv",
        "submit": "Skicka in",
        "continue": "Fortsätta"
    },
    {
        "language": "Tagalog (Filipino)",
        "code": "tl",
        "submit": "Ipasa",
        "continue": "magpatuloy"
    },
    {
        "language": "Tajik",
        "code": "tg",
        "submit": "Садо",
        "continue": "давом додан"
    },
    {
        "language": "Tamil",
        "code": "ta",
        "submit": "சமர்ப்பி",
        "continue": "தொடர்ந்து"
    },
    {
        "language": "Tatar",
        "code": "tt",
        "submit": "Җибәрү",
        "continue": "дәвам"
    },
    {
        "language": "Telugu",
        "code": "te",
        "submit": "సమర్పించండి",
        "continue": "కొనసాగించు"
    },
    {
        "language": "Thai",
        "code": "th",
        "submit": "เสนอ",
        "continue": "ต่อ"
    },
    {
        "language": "Turkish",
        "code": "tr",
        "submit": "Sunmak",
        "continue": "Devam et"
    },
    {
        "language": "Turkmen",
        "code": "tk",
        "submit": "ỳazgy giriz",
        "continue": "dowam etmek"
    },
    {
        "language": "Ukrainian",
        "code": "uk",
        "submit": "уявити",
        "continue": "продовжувати"
    },
    {
        "language": "Urdu",
        "code": "ur",
        "submit": "جمع کرائیں",
        "continue": "جاری رہے"
    },
    {
        "language": "Uyghur",
        "code": "ug",
        "submit": "تاپشۇرۇشى",
        "continue": "داۋاملىق"
    },
    {
        "language": "Uzbek",
        "code": "uz",
        "submit": "Submit",
        "continue": "Davom"
    },
    {
        "language": "Vietnamese",
        "code": "vi",
        "submit": "Gửi đi",
        "continue": "Tiếp tục"
    },
    {
        "language": "Welsh",
        "code": "cy",
        "submit": "Cyflwyno",
        "continue": "parhau"
    },
    {
        "language": "Xhosa",
        "code": "xh",
        "submit": "Hambisa",
        "continue": "Qhubeka"
    },
    {
        "language": "Yiddish",
        "code": "yi",
        "submit": "פאָרלייגן",
        "continue": "פאָרזעצן"
    },
    {
        "language": "Yoruba",
        "code": "yo",
        "submit": "fi",
        "continue": "Tesiwaju"
    },
    {
        "language": "Zulu",
        "code": "zu",
        "submit": "Hambisa",
        "continue": "Qhubeka"
    }
]