import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "./TakeSurveyStyles";

function ThankYouComponent(props) {
  const { classes } = props;
  const { takeSurveyError } = props;
  return (
    <>
      <Grid container className={classes.thankYouRoot}>
        <Grid item sm={12} className={`${classes.thankYouContainer} ${classes.thankYouErrorContainer} `}>
          <span className={classes.errorText} color>
            {takeSurveyError}
          </span>
        </Grid>
      </Grid>
    </>
  );
}

// default props
ThankYouComponent.defaultProps = {
  classes: {},
};

// prop types
ThankYouComponent.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(TakeSurveyStyles)(ThankYouComponent);
