const FilterStyles = () => ({
    drawer: {
        "& .MuiPaper-elevation1": {
            boxShadow: "none !important",
        },
    },
    filterTitle: {
        minHeight: "55px",
        borderBottom: "1px solid #80808040",
    },
    filterInfoGrid:{
        background: "#eaf8fe",
        display: "block",
        padding: "5px",
        border: "2px dashed #5dc3fd",
        borderRadius: "10px",
        fontSize: "12px",
        textAlign: "justify",
        margin: "10px"
    },
    clearAll: {
        float: "left",
        marginTop: "-2px",
        marginRight: "13px",
        fontSize: "0.875rem"
    },
    dataFiltersClearAll: {
        float: "left",
        marginTop: "5px",
        marginRight: "7px",
        fontSize: "0.875rem"
    },
    saveFilterBtn: {
        position: "absolute",
        bottom: 0,
        right: 0,
        "&.drawer": {
            left: 0,
            margin: "auto"
        }
    },
    expansionDetail:
    {
        overflowY: "auto"
    },
    demographyCheckbox: {
        display: "flex",
        padding: "0px 5px"
    },
    demographicsChips: {
        "&.MuiChip-root": {
            background: "#EDF9FF",
            border: "1px solid #C0E2F5",
            padding: "10px",
            height: "28px",
            maxWidth: "320px",
        },
    },
    demogrpahicsChipClose: {
        width: "14px",
        height: "14px",
    },
    peaccordion_nocolor: {
        margin: "0px !important",
    },
    peaccordion_nocolor: {
        "&.MuiPaper-root": {
            borderBottom: "1px solid #E9E9E9",
        },
        "&.MuiAccordion-root.Mui-expanded": {
            margin: "0px !important",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            color: "#292929 !important",
            background: "#fff !important",
            borderBottom: '1px solid #80808040'
        },
        "& .panel-active": {
            color: "#292929 !important",
        },
    },
    peaccordion_nocolor_demography: {
        "&.MuiPaper-root": {
            borderBottom: "none !important",
        },
        "&.MuiAccordion-root.Mui-expanded": {
            margin: "0px !important",
            boxShadow: "none !important",
        },
        "& .MuiAccordionSummary-root.Mui-expanded": {
            color: "#292929 !important",
            background: "#fff !important",
            borderBottom: "none !important",
            padding: "0px !important",
        },
        "& .panel-active": {
            color: "#292929 !important",
            "& .expansionPlus": {
                display: "none",
            },
            "& .expansionMinus": {
                display: "block",
            },
        },
        "& .MuiAccordionDetails-root": {
            flexDirection: "column !important",
            paddingLeft: "20px !important",
        },
        "& .MuiAccordionSummary-root": {
            padding: "0px !important",
            minHeight: "38px"
        },
        "& .MuiAccordionSummary-content": {
            margin: "6px 0px !important"
        },
    },
    assignSurveyChip: {
        "&.MuiChip-root": {
            background: "#EDF9FF",
            border: "1px solid #C0E2F5",
            padding: "9px 3px",
            height: "28px",
        },
    },
    assignSurevy_chipClose: {
        width: "14px",
        height: "14px",
        color: "#333333",
    },
    demographySearchImg: {
        position: "absolute",
        right: "10px",
        top: "9px",
        zIndex: "1",
    },
    demographySearch: {
        "& .MuiInput-input": {
            height: "21px",
        },
        saveReportEmail: {
            border: "1px solid #E3E4E6",
            borderRadius: "10px",
            padding: "15px",
        }
    },
    expansionPlus: {
        display: "block",
    },
    expansionMinus: {
        display: "none",
    },
})

export default FilterStyles;