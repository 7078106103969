/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ContentLibraryStyles from "./ContentLibraryStyles";

// import constants

// Import Helpers
import history from "../../config/history";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`
    };
}

//Tabs
function ContentLibraryTabs(props) {

    //get props
    const { classes, match, location } = props;

    //CHanges tabs
    const handleChange = (event, newValue) => {
        history.push({
            pathname: newValue
        });
    };

    return (
        <Grid item>
            <AppBar
                position="static"
                color="default"
                className={`${classes.rightPanelAppBarRoot
                    } ${"tabborder_none pe_color_appbar"}`}>
                <Tabs
                    value={location.pathname}
                    onChange={handleChange}
                    component="div"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="simple tabs example"
                    className={classes.rightPanelTabsRoot}>
                    <Tab label="Action Coach" {...a11yProps(0)}
                        value={`${match.url}`}
                        classes={{
                            root: classes.rightPanelTabRoot,
                            selected: classes.rightPanelTabSelected
                        }}
                    />
                    <Tab label="Message" {...a11yProps(2)}
                        value={`${match.url}/message`}
                        classes={{
                            root: classes.rightPanelTabRoot,
                            selected: classes.rightPanelTabSelected
                        }}
                    />
                    <Tab label="Recommendation" {...a11yProps(3)}
                        value={`${match.url}/recommendation`}
                        classes={{
                            root: classes.rightPanelTabRoot,
                            selected: classes.rightPanelTabSelected
                        }}
                    />
                    <Tab label="Report Content" {...a11yProps(1)}
                        value={`${match.url}/report-content`}
                        classes={{
                            root: classes.rightPanelTabRoot,
                            selected: classes.rightPanelTabSelected
                        }}
                    />
                </Tabs>
            </AppBar>
        </Grid>
    );
}
// default props
ContentLibraryTabs.defaultProps = {
    classes: {},
    handlePopoverClick: () => { }
};

// prop types
ContentLibraryTabs.propTypes = {
    classes: PropTypes.object,
    handlePopoverClick: PropTypes.func
};
export default withStyles(ContentLibraryStyles)(ContentLibraryTabs);
