
const HighLevelSummaryTableStyle = theme => ({

  root: {
    width: "100%",
    padding: "0px 16px",
    position: "relative",
    borderRadius: 10,
    background: "#fff",
  },
  leftroot: {
    width: "100%",
    padding: "0px 5px 0 16px",
    position: "relative",
    borderRadius: "10px 0px 0px 0px",
    background: "#fff",
    boxShadow: "6px 0 5px -2px #00000029"
  },
  rightroot: {
    width: "100%",
    padding: "0px 16px 0 5px",
    position: "relative",
    borderRadius: "0px 10px 0px 0px",
    background: "#fff"
  },
  tableContainer: {
    marginBottom: "16px",
    border: "2px solid #f2f4f5"
  },
  tableCustomContainer: {
    background: "#fff"
  },
  table: {
    minWidth: "700px"
  },
  actions: {
    display: "flex"
    // justifyContent: "flex-end"
  },
  TableHelpIcon: {
    "& .ml-1": {
      marginLeft: "0px !important",
      paddingRight: "5px !important",
      top: "-1px"
    }
  },
  arrow_img_up: {
    transform: "rotate(-90deg)"
  },
  arrow_img_down: {
    transform: "rotate(90deg)"
  },
  tableheadercolor: {
    fontWeight: "700",
    height: '42px',
    fontFamily: "Montserrat",
    '& .MuiTableCell-stickyHeader': {
      background: "#f2f4f5"
    },
    '& .MuiTableCell-head': {
      '&:first-child': {
        // minWidth: '260px !important'
        cursor: 'context-menu',
      },
      height: '42px',
      cursor: 'pointer',
      // minWidth: "140px !important"
      '&:hover .MuiTableSortLabel-icon': {
        visibility: 'visible',
        opacity: 1,
      },
    },
    '& .datecomp': {
      cursor: 'context-menu',
    },
  },
  tableRow: {
    height: '42px',
    '& .MuiTableCell-head': {
      height: '42px'
    }
  },
  HLSLine: {
    position: "absolute",
    width: "1px",
    background: "#dadada",
    top: 0,
    bottom: 0,
    marigin: "auto",
    right: 0,
    left: "50%"
  },
  HLSPositiveWrapper: {
    width: "50%",
    float: "right",
    paddingLeft: "0px !important",
    overflow: "hidden"

  },
  HLSNegativeWrapper: {
    width: "50%",
    float: "left",
    paddingRight: "0px !important",
    overflow: "hidden"
  },
  HLSchartNegcontainer: {
    position: "relative",
    width: "100%",
    float: "right",

  },
  HLSchartPoscontainer: {
    position: "relative",
    float: "left",
    width: "100%",

  },
  HLSpositivevalue: {
    float: "left",
    margin: "5px 0px 0px 5px"
  },
  HLSnegtivevalue: {
    float: "right",
    margin: "5px 5px 0px 0px"
  },
  HLSselectMenu: {
    "& .MuiSelect": {
      fontFamily: "Montserrat"
    }
  },
  KeyLogo: {
    position: "absolute",
    top: "30%",
    right: 10
  },

  Head: {
    backgroundColor: theme.palette.common.black
  },
  hlsSurveySearchError: {
    fontSize: '13px',
    fontStyle: 'italic',
    padding: '6px 16px',
  }

});

export default HighLevelSummaryTableStyle;
