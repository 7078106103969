/* eslint-disable no-unneeded-ternary */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// import Components
import ImportFileComponent from "../../../../components/ImportFileComponent/ImportFileComponent";
import ProgressPage from "./ProgressPage";
import SuccessPage from "./SuccessPage";

// Import Actions
import { excelAction, alertAction, fileManagerAction } from "../../../../redux/actions";

// import constants
import { participantConstants, excelConstants } from "../../../../config/constants";
import { getTableRowSize } from "../../../../config/layout";

function ImportPage(props) {
  const dispatch = useDispatch();
  const { fileManager: { data, isLoading }, profile: { user } } = useSelector(state => state)
  const client_id = user && user.client_id ? user.client_id._id : null
  const uploadsData = data.filter(d => d.type === "Import")
  const isProcessing = uploadsData.filter(f => (f.status === "PENDING" || f.status === "QUEUE")
    && !f.isSeen && `${f.client_id}` === client_id)[0]
  const isSuccess = uploadsData.filter(f => f.status === "DONE"
    && !f.isSeen && `${f.client_id}` === client_id)[0]
  const isFailure = uploadsData.filter(f => f.status === "ERROR"
    && !f.isSeen && `${f.client_id}` === client_id)[0]

  useEffect(() => {
    dispatch(fileManagerAction.getList(1, getTableRowSize(), "cdt", "desc", [], [], {
      getAll: true
    }))
  }, [dispatch])

  // update file data in reducer and redirect to submit page
  const importFileData = (file) => {
    const fileData = file ? file : {};
    if (fileData && fileData.path) {
      const excelType = excelConstants.PARTICIPANT_UPLOAD;
      var formData = new FormData();
      formData.append("file", fileData);
      formData.append("excelType", excelType);
      dispatch(excelAction.upload(formData, excelType, null, true));
    } else {
      dispatch(alertAction.error("Please select a file to upload"));
    }
  };

  if (isLoading) {
    return null
  } else if (isProcessing) {
    return <ProgressPage />
  } else if (isSuccess) {
    return <SuccessPage pageName="Participant upload" uploadResult={{...isSuccess, ...isSuccess.response}} onDone={() => {
      dispatch(fileManagerAction.upadateSeenStatus(isSuccess._id))
    }} />
  } else if (isFailure) {
    return <SuccessPage pageName="Participant upload" uploadResult={{...isFailure, ...isFailure.response}} onDone={() => {
      dispatch(fileManagerAction.upadateSeenStatus(isFailure._id))
    }}/>
  } else {
    return (
      <ImportFileComponent
        headerText={participantConstants.UPLOAD_PARTICIPANTS}
        dropText={participantConstants.UNASSIGN_IMPORT}
        importFileData={importFileData}
        cancelBtnURL={'/manage_people/participants'}
        backBtnURL={'/manage_people/participants'}
      />
    );
  }
}

// default props
ImportPage.defaultProps = {
  match: {}
};

// prop types
ImportPage.propTypes = {
  match: PropTypes.object
};
export default ImportPage;
