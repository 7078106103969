import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField, IconButton, Tooltip, Button } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import { DashboardClose, Expand } from "../../../../assets/images/svgComponents/ImageComponent";

import CompetencySummaryStyle from "./CompetencySummaryStyle";

//import report constants
import { ReportsConstants } from "../../../../config/constants";

// Import components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";
import CompetencySummaryContent from "./CompetencySummaryContent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import { dashboardAction } from "../../../../redux/actions";

const viewByOptions = [
    {
        key: "category",
        value: "Competency"
    },
    {
        key: "item",
        value: "Item"
    }
]

// get display name for auto complete dropdown
const getOptionSelected = (options, value) => {
    let optionSelected = options.filter((p) => p.key === value)[0] || "";
    return optionSelected;
};

function CompetencySummaryChart(props) {

    // Get Props
    const { classes, handleClose, DragHandle, isUpdate, stopChartReload, preference, updateReportPreference } = props;
    const { competency } = preference

    // Define Dispatch
    const dispatch = useDispatch();

    // Set State
    const [isPopup, setIsPopup] = useState(false);

    // Get Reducer Date
    const { category_group, item_group, legend_val, loading } = useSelector((state) => state.dashboard.competency);
    const chartData = competency.viewBy === "category" ? category_group : item_group

    // Get Competency Summary Chart Details
    useEffect(() => {
        if (isUpdate && !loading) {
            // dispatch(dashboardAction.getCompetencySummary())
            stopChartReload("7")
        }
    }, [dispatch, isUpdate, loading]);

    // open popup
    const openPopup = () => {
        setIsPopup(true);
    };

    // popup close
    const onClose = () => {
        setIsPopup(false);
    };

    //Save Report Preferences on demographic change
    const saveReportPreferences = (selectedViewBy) => {
        const data = {
            ...preference,
            competency: {
                viewBy: selectedViewBy
            },
        };
        // Update Preferences
        updateReportPreference(data, "1")
    };
    return (
        <React.Fragment>
            <Grid item xs={12} className={"mb-14"}>
                <Grid item className={classes.insightTile}>
                    <Grid container alignItems="center" className="mb-1">
                        {
                            !loading && <Grid item>
                                <DragHandle />
                            </Grid>
                        }
                        <Grid item>
                            <TypographyComponent variant="h4" className="txtBold fontSize16  ml-1">
                                {competency.viewBy === "item" ? "Individual Review Items" : "Competency Summary"}
                            </TypographyComponent>
                        </Grid>
                        <Grid item className={classes.viewActionButton}>
                            {!loading &&
                                <Grid container>
                                    <Grid item>
                                        <TypographyComponent
                                            variant="h6"
                                            className="txtBold"
                                            extraProps={{
                                                style: {
                                                    padding: "12px 8px"
                                                }
                                            }}
                                        >
                                            {"View by"}
                                        </TypographyComponent>
                                    </Grid>
                                    <Autocomplete
                                        id={"competency-view-by"}
                                        disableClearable
                                        autoHighlight
                                        blurOnSelect
                                        options={viewByOptions}
                                        value={getOptionSelected(viewByOptions, competency.viewBy)}
                                        className={`input-value-editor autocomplete  ${classes.boxMaxWidth}`}
                                        getOptionLabel={(option) => option.value || ""}
                                        onChange={(e, val) => {
                                            saveReportPreferences(val.key)
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant={"outlined"}
                                                className={`${"height28 conversionAutoComplete boderRadius4"}`}
                                                placeholder="Select Field"
                                            />
                                        )}
                                        style={{
                                            minWidth: "150px"
                                        }}
                                    />
                                    <Tooltip title={"Expand"} arrow >
                                        <IconButton onClick={() => { openPopup() }} size="large">
                                            {Expand()}
                                        </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip title={"Close"} arrow >
                                        <IconButton onClick={() => handleClose('7')} size="large">
                                            {DashboardClose()}
                                        </IconButton>
                                    </Tooltip> */}
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                    {loading && <LoaderComponent />}

                    {!loading &&
                        <Grid container className={classes.scrollc}>
                            {!isPopup && chartData.length > 0 && (
                                <CompetencySummaryContent
                                    classes={classes}
                                    viewBy={competency.viewBy}
                                    chartData={chartData}
                                    onClickButton={() => { openPopup() }}
                                />
                            )}
                            {chartData.length === 0 && !loading && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    className="mt-9"
                                >
                                    <TypographyComponent variant="h3">
                                        {ReportsConstants.NO_DATA_CHART}
                                    </TypographyComponent>
                                </Grid>
                            )}
                        </Grid>
                    }
                    <Grid container alignItems="center" className="pt-1 mb-1" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {
                           competency.viewBy !== "item" && legend_val && legend_val.map((val) =>
                                <Grid item className={classes.legendItem}>
                                    <span style={{ background: val.color }} />
                                    <TypographyComponent variant="h6">
                                        {`${val.relationship} (N=${val.value})`}
                                    </TypographyComponent>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>

            {isPopup && (
                <DialogComponent
                    open={isPopup}
                    fullScreen={true}
                    className={'competency-popup'}
                >
                    <Grid container xs={12} style={{ margin: "12px 0px" }}>
                        <Grid item xs={6}>
                            <TypographyComponent variant="h4" className="txtBold fontSize16  ml-1">
                                {competency.viewBy === "item" ? "Individual Review Items" : "Competency Summary"}
                            </TypographyComponent>
                        </Grid>
                        <Grid item xs={6} style={{ textAlign: "right" }}>
                            <Button variant="text" size={"small"} onClick={() => setIsPopup(false)}>
                                {DashboardClose()}
                                <TypographyComponent variant="h5" className="pl5">
                                    {"Close"}
                                </TypographyComponent>
                            </Button>
                        </Grid>
                    </Grid>
                    <div style={{ height: `${window.innerHeight - 100}px`, overflow: "auto", padding: "0px" }}>
                        <CompetencySummaryContent
                            classes={classes}
                            chartData={chartData}
                            viewBy={competency.viewBy}
                            onClickButton={() => { openPopup() }}
                        />
                    </div>
                </DialogComponent>
            )}
        </React.Fragment>
    );
}

// default props
CompetencySummaryChart.defaultProps = {
    classes: {},
    isUpdate: false,
    updateReportPreference: () => { },
    handleClose: () => { },
    stopChartReload: () => { }
};

// prop types
CompetencySummaryChart.propTypes = {
    classes: PropTypes.object,
    isUpdate: PropTypes.bool,
    updateReportPreference: PropTypes.func,
    handleClose: PropTypes.func,
    stopChartReload: PropTypes.func
};

export default withStyles(CompetencySummaryStyle)(CompetencySummaryChart);