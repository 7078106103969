/* eslint-disable dot-notation */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

// Import Actions
import { categoryAction } from "../../../../../redux/actions";

// import Sub Container
import MatrixSubQuestionText from "./MatrixSubQuestionText";
import AddSubQuestions from "./AddSubQuestions";

/**
  * Sortable Item
  */
const SortableItem = SortableElement(props => {
  return (
    props.children
  );
});

/**
* Sortable List
*/
const SortableList = SortableContainer(props => {
  const { classes, questionDataSet, category, item, editQuestionsData } = props;

  /**
* Update Matrix Sub Question Text Change
* @param {*} reducerData
*/
  const updateMatrixSubQuesText = (reducerData) => {
    editQuestionsData(reducerData);
  };

  // Add Sub Questions
  const addSubQuestion = (reducerData) => {
    editQuestionsData(reducerData);
  };

  return (
    <Grid container spacing={2}>
      {
        questionDataSet["subQues"] && questionDataSet["subQues"].length > 0 &&
        questionDataSet["subQues"].map((value, index) => (
          <SortableItem
            key={`sort-item-${index}`}
            index={index}
          >
            <MatrixSubQuestionText
              classes={classes}
              value={value}
              index={index}
              key={index}
              category={category}
              item={item}
              questionDataSet={questionDataSet}
              editQuestionsData={updateMatrixSubQuesText}
            />
          </SortableItem>
        ))
      }

      <AddSubQuestions
        classes={classes}
        category={category}
        item={item}
        questionDataSet={questionDataSet}
        editQuestionsData={addSubQuestion}
      />
    </Grid>
  );
});

function MatrixSubQuestion (props) {
  const dispatch = useDispatch();

  // Get Props Values
  const { classes, category, questionDataSet, item, editQuestionsData } = props;

  /**
  * Handle Sort End Event
  */
  const onSortEnd = (e) => {
    const { oldIndex, newIndex } = e;
    const resultData = arrayMove(questionDataSet["subQues"], oldIndex, newIndex);
    const reducerData = { ...questionDataSet, subQues: resultData };
    if (!item) {
      dispatch(categoryAction.addOrChangeQuestion(category, reducerData));
    } else {
      props.editQuestionsData(reducerData);
    }
  };

  return (
    <Grid item sm={12}>
      <Grid container spacing={3} className="pl-5">
        <Grid item sm={12}>
          <SortableList
            useDragHandle
            axis="xy"
            enableUserSelectHack={false}
            questionDataSet={questionDataSet}
            onSortEnd={onSortEnd}
            category={category}
            editQuestionsData={editQuestionsData}
            item={item}
            classes={classes}
          ></SortableList>
        </Grid>
      </Grid>
    </Grid>
  );
}

// default props
MatrixSubQuestion.defaultProps = {
  classes: {},
  category: null,
  questionDataSet: {},
  item: null,
  editQuestionsData: () => { }
};

// prop types
MatrixSubQuestion.propTypes = {
  classes: PropTypes.object,
  questionDataSet: PropTypes.object,
  item: PropTypes.object,
  category: PropTypes.string,
  editQuestionsData: PropTypes.func
};

export default MatrixSubQuestion;
