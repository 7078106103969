import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

// Import Component
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import CreateReportPopup from "./CreateReportPopup";

// import constants
import { ReportsConstants } from "../../../../config/constants";

// Import Actions
import { reportAction } from "../../../../redux/actions";

function CreateReport(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  // get props
  const { classes } = props;

  //state for open close popup
  const [savePopup, setSavePopup] = useState(false);
  
  const openPopup = () => {
    setSavePopup(true);
  };
  const closePopup = () => {
    setSavePopup(false);
    dispatch(reportAction.resetReportData())
  };

  return (
    <React.Fragment>
      <ButtonComponent
        size="small"
        color="primary"
        handleClick={openPopup}
        title={ReportsConstants.CREATE_REPORT}
      ></ButtonComponent>
      {savePopup && (
        <DialogComponent open={true} fullScreen={true}>
          <CreateReportPopup title={ReportsConstants.CREATEA_REPORT} closePopup={closePopup} classes={classes} isSaveReport={false} />
        </DialogComponent>
      )}
    </React.Fragment>
  );
}
// default props
CreateReport.defaultProps = {
  classes: {},
};

// prop types
CreateReport.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(ReportsStyles)(CreateReport);
