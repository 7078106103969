import { Grid } from "@mui/material"
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import { updateStateReducerAction, contentLibraryAction } from "../../../../redux/actions";


import { contentLibraryConstant } from "../../../../redux/constants";

import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import { TrashSurvey } from "../../../../assets/images/svgComponents/ImageComponent";


function ReportContentCard(props) {

    let { classes, item, reportContent, index } = props;
    const dispatch = useDispatch();

    const [editable, setEditable] = useState(false);


    // Update reducer data
    const updateReducer = (reportContent) => {
        const reducerData = reportContent;
        dispatch(updateStateReducerAction.update(contentLibraryConstant.CONTENTLIBRARY_REPORTCONTENT_REDUCER_UPDATE, reducerData));
    };


    const editOnClick = (event) => {
        const toggle = !editable;
        setEditable(toggle);
        reportContent = reportContent.map((reports, ind) => {
            if (reports.description === item.description) {
                reportContent[ind].editable = true;
                return reports
            } else {
                reportContent[ind].editable = false;
                return reports
            }
        });
        updateReducer(reportContent);
        return true;
    };

    // Delete report Content
    const deleteReportContent = (data) => {
        reportContent.filter((item, index) => {
            if (reportContent[index]._id === data._id) {
                reportContent.splice(index, 1);
            }
            return true
        });
        updateReducer(reportContent);
        dispatch(contentLibraryAction.removeReport(item));
        return true;
    };



    return (
        <Grid className="width100 mt-2">
            <Grid className={`${classes.action_alert_item} dflex`} onClick={editOnClick}>
                <Grid item className={"pl-1 pr-2 selfcenter"}>
                    <Badge
                        color="secondary"
                        className={`badgebig`}
                        badgeContent={index + 1}
                    ></Badge>
                </Grid>
                <Grid item sm={12} className={"pl5 dflex spacebetween"}>
                    <Grid item className={"selfcenter"}>
                        <TypographyComponent
                            className={``}
                            variant={"h6"}>
                            {item.description}
                        </TypographyComponent>
                    </Grid>
                    {/* <Grid item className="pl-3 dflex">
                        <Tooltip arrow title="Delete">
                            <IconButton
                                color="inherit"
                                className="p5 icon-hover ImgActiveOrange"
                                onClick={(e) => {
                                    deleteReportContent(item);
                                }}
                            >
                                {TrashSurvey()}
                            </IconButton>
                        </Tooltip>
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ReportContentCard;

