import { excelConstant } from "../constants";
 
// Set Initial State
const initialState = {
  upload: {},
  download: {},
  fileDetails: {},
  rawDataLoader : false
 
  // loaders: {
  //   reportexcel: false,
  // }
 
};
 
export default function (state = initialState, action) {
  switch (action.type) {
    // UPLOAD ECEL REDUCER
    case excelConstant.EXCEL_UPLOAD_REQUEST:
      return {
        ...state
      };
    case excelConstant.LOADER_REQUEST_SUCCESS:
      return {
        ...state,
        rawDataLoader : false
      };
    case excelConstant.EXCEL_UPLOAD_SUCCESS:
      return {
        ...state,
        upload: action.data.data
      };
    case excelConstant.EXCEL_UPLOAD_FAILURE:
      return {
        ...state,
        upload: {
          ...state.upload,
          error: action.error
        }
      };
 
      // DOWNLOAD ECEL REDUCER
    case excelConstant.EXCEL_DOWNLOAD_REQUEST:
      return {
        ...state,
        rawDataLoader : true
        // loaders: { ...state.loaders, reportexcel: true }
      };
    case excelConstant.EXCEL_DOWNLOAD_SUCCESS:
      return {
        ...state,
        rawDataLoader : false
 
        // loaders: { ...state.loaders, reportexcel: false }
      };
    case excelConstant.EXCEL_DOWNLOAD_FAILURE:
      return {
        ...state,
        rawDataLoader : false
 
        // loaders: { ...state.loaders, reportexcel: false }
      };
    case excelConstant.EXCEL_DATA_UPDATE:
      return {
        ...state,
        fileDetails: action.fileDetails
      };
 
    default:
      return state;
  }
}
