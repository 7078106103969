/* eslint-disable no-dupe-keys */
const ReportsStyles = (theme) => ({
  // Tabs
  rightPanelAppBarRoot: {
    background: "transparent",
    height: "45px",
  },
  tabcharlimit: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "175px",
  },
  rightPanelTabRoot: {
    // width: "95px",
    borderRadius: "5px 5px 0px 0px",
    backgroundColor: "#EBECED",
    margin: "0px 4px",
    color: "rgba(0, 0, 0, 0.87) !important",
    "&:hover": {
      color: "#fff !important",
      backgroundColor: "#54C0FD",
    },
    "&:hover .peTabEdit, &:hover .peTabDelete": {
      visibility: "visible !important",
      filter: "brightness(0) invert(1)",
    },
    "&$selected": {
      color: "#fff",
      backgroundColor: "#54C0FD",
    },
    "&:focus": {
      color: "#fff",
      backgroundColor: "#54C0FD",
    },
  },
  rightPanelTabSelected: {
    backgroundColor: "#54C0FD !important",
    color: "#fff !important",
  },

  /**** Chip Error Validation Styles ****/
  errorChipReport: {
    background: "#F64242 !important",
    color: "white",
    "& .MuiChip-deleteIcon": {
      color: "white",
      opacity: "0.75",
      "&:focus": {
        opacity: "1"
      }
    }
  },

  tooltip: {
    background: "#ffff",
    boxShadow: "0 0 5px #999999",
    color: "#333",
    display: "none",
    fontSize: "13px",
    padding: "20px",
    position: "absolute",
    textAlign: "center",
    minWidth: "275px",
    height: "auto",
    zIndex: "2"
  },

  // Reports
  // reports_Container: {
  //   padding: "20px",
  // },
  reports_header: {
    borderBottom: "1px solid #DDDDDD !important",
    margin: '20px 20px 0 20px'
  },

  // Dashboard
  dashboard_BelowTile: {
    boxShadow: "0px 0px 10px #0000001A",
    borderRadius: "6px",
    background: "#fff",
    padding: "20px",
    flexGrow: "1",
    minWidth: "200px",
    // maxWidth: '300px',
    margin: "40px",
    marginLeft: "26px",
    marginRight: "0px"
  },
  dashboard_Tile2: {
    boxShadow: "0px 0px 10px #0000001A",
    borderRadius: "6px",
    background: "#fff",
    padding: "10px",
    flexGrow: "1",
    minWidth: "200px",
    // maxWidth: "360px",
    // margin: '15px',
  },
  dashboardTile: {
    boxShadow: "0px 0px 10px #0000002A",
    borderRadius: "6px",
    background: "#fff",
    padding: "20px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    "&.me": {
      padding: "0px",
      flex: 1
    },
    "&.me_saoa": {
      padding: "20px",
    }
  },
  overallChart: {
    "&.me": {
      padding: "2px",
      borderTopLeftRadius: "6px",
      borderBottomLeftRadius: "6px",
      width: "130px"
    },
    "&.me_0": {
      padding: "20px",
      backgroundColor: "aliceblue",
      minWidth: "300px"
    }
  },
  overall_text: {
    "&.me": {
      textAlign: "center"
    }
  },
  insightTile: {
    boxShadow: "0px 0px 10px #0000002A",
    borderRadius: "6px",
    background: "#fff",
    padding: "20px",
    height: "100%",
    minHeight: '320px'
  },

  viewActionButton: {
    marginLeft: "auto !important",
  },
  totViewActionButton: {
    marginLeft: "auto !important",
    width: "35%",
    "& .buttonContainer": {
      justifyContent: "flex-end",
    },
    "& .MuiFormControl-marginDense": {
      margin: "0px !important",
    }
  },
  actionDropdownBox: {
    borderTop: "2px solid #E8E8E8",
    boxShadow: "1px 1px 3px #22222233 !important",
    borderLeft: "2px solid #E8E8E8",
    borderBottom: "2px solid #E8E8E8",
    "& .MuiAutocomplete-paper": {
      boxShadow: "none !important",
      border: "0px !important",
      borderBottom: "2px solid #E8E8E8 !important",
      "&.MuiPaper-rounded": {
        borderRadius: "4px 0px 4px 0px",
        marginBottom: "0px",
      }
    },
    ".MuiGrid-spacing-xs-3": {
      margin: "0px",
      width: "100%",
    },
    "& .action-box": {
      padding: "7px 0px",
    }
  },
  SuggestedActionList: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "& .MuiListItemText-multiline": {
      borderLeft: "5px solid #C0E2F5",
      paddingLeft: "7px",
      marginTop: "3px !important",
      marginBottom: "3px !important",
    },
    "& .MuiListItem-root": {
      paddingtop: "2px",
      paddingBottom: "2px",
    },
    "& .MuiTypography-body1": {
      color: "#B1B2B3 !important",
      fontSize: "14px !important",
    },
    "& .MuiTypography-body2": {
      color: "#333333 !important",
      fontSize: "14px !important",
    },
  },
  AlertsList: {
    "& .MuiListItem-gutters": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "& .MuiListItemAvatar-root": {
      minWidth: "40px",
    },
  },

  HighRatesCount: {
    width: "56px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#54C0FD ",
    color: "#fff",
    marginRight: "25px",
    position: "relative",
    cursor: "alias"
  },
  LowRatesCount: {
    width: "56px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#FFAE4B",
    color: "#fff",
    marginRight: "25px",
    position: "relative",
    cursor: "alias"
  },
  RateArrowImgRight: {
    position: "absolute",
    right: "-10px",
    top: "6px",
  },
  circularBar: {
    position: "absolute",
    color: "#C8C8C8",
    "& .MuiCircularProgress-colorSecondary": {
      color: "#C8C8C8"
    }
  },
  circularBarorange: {
    position: "absolute",
    color: "#FFAE4B",
    "& .MuiCircularProgress-colorSecondary": {
      color: "#FFAE4B"
    }
  },
  circularBarPrimary: {
    color: "#54C0FD",
    "& .MuiCircularProgress-colorSecondary": {
      color: "#54C0FD"
    }
  },
  circularBarValue: {
    color: "#54C0FD"
  },
  circularvalue: {
    position: "absolute",
    transform: "translate(-50%, -50%)",
    top: "50%",
    left: "50%",
    /* -webkit-transform: "translate(-50%, -50%)",
    -moz-transform: "translate(-50%, -50%)",
    -ms-transform: "translate(-50%, -50%)", */

  },

  // Insight
  dashboardAlertTitle: {
    color: "#292929 !important",
  },
  dashBoardInformation: {
    borderRadius: "6px",
    background: "#fff",
    padding: "20px",
    height: "100%",
    border: "1px solid #54C0FD",
  },

  // Report
  dialog_close: {
    textAlign: "right",
  },

  // Demographics
  pe_drawer: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important",
    },
  },
  DrawerDemographyTitle: {
    // Remove
    minHeight: "55px",
  },
  peaccordion_nocolor: {
    margin: "0px !important",
  },
  peaccordion_nocolor: {
    "&.MuiPaper-root": {
      borderBottom: "1px solid #E9E9E9",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // boxShadow: '0px 0px 4px #0000000F !important',
      color: "#292929 !important",
      background: "#fff !important",
      // borderTop: '1px solid #E9E9E9',
      borderBottom: "1px solid #E9E9E9",
    },
    "& .panel-active": {
      color: "#292929 !important",
    },
  },
  peaccordion_nocolor_demography: {
    "&.MuiPaper-root": {
      borderBottom: "none !important",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important",
      boxShadow: "none !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // boxShadow: '0px 0px 4px #0000000F !important',
      color: "#292929 !important",
      background: "#fff !important",
      // borderTop: '1px solid #E9E9E9',
      borderBottom: "none !important",
      padding: "0px !important",
    },
    "& .panel-active": {
      color: "#292929 !important",
      "& .expansionPlus": {
        display: "none",
      },
      "& .expansionMinus": {
        display: "block",
      },
    },
    "& .MuiAccordionDetails-root": {
      flexDirection: "column !important",
      padding: "0px !important",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px !important",
    },
  },
  expansionPlus: {
    display: "block",
  },
  expansionMinus: {
    display: "none",
  },

  demographyCheckbox: {
    padding: "0px 5px",
  },
  surveyContainer: {
    maxHeight: "330px",
    overflow: "auto"
  },

  // Demographics
  pe_drawer: {
    "& .MuiPaper-elevation1": {
      boxShadow: "none !important",
    },
  },
  DrawerDemographyTitle: {
    minHeight: "55px",
  },
  peaccordion_nocolor: {
    margin: "0px !important",
  },
  peaccordion_nocolor: {
    "&.MuiPaper-root": {
      borderBottom: "1px solid #E9E9E9",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // boxShadow: '0px 0px 4px #0000000F !important',
      color: "#292929 !important",
      background: "#fff !important",
      // borderTop: '1px solid #E9E9E9',
      borderBottom: "1px solid #E9E9E9",
    },
    "& .panel-active": {
      color: "#292929 !important",
    },
  },
  peaccordion_nocolor_demography: {
    "&.MuiPaper-root": {
      borderBottom: "none !important",
    },
    "&.MuiAccordion-root.Mui-expanded": {
      margin: "0px !important",
      boxShadow: "none !important",
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      // boxShadow: '0px 0px 4px #0000000F !important',
      color: "#292929 !important",
      background: "#fff !important",
      // borderTop: '1px solid #E9E9E9',
      borderBottom: "none !important",
      padding: "0px !important",
    },
    "& .panel-active": {
      color: "#292929 !important",
      "& .expansionPlus": {
        display: "none",
      },
      "& .expansionMinus": {
        display: "block",
      },
    },
    "& .MuiAccordionDetails-root": {
      flexDirection: "column !important",
      padding: "0px !important",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px !important",
    },
  },
  expansionPlus: {
    display: "block",
  },
  expansionMinus: {
    display: "none",
  },

  demographyCheckbox: {
    display: "flex",
    flexDirection: "column !important",
    padding: "0px 5px",
  },
  demographySearchImg: {
    position: "absolute",
    right: "10px",
    top: "23px",
    zIndex: "1",
  },
  demographySearch: {
    "& .MuiInput-input": {
      height: "21px",
    },
    saveReportEmail: {
      border: "1px solid #E3E4E6",
      borderRadius: "10px",
      padding: "15px",
    },
    filter_apply_btn: {
      position: "absolute",
      right: "15px",
      top: "17px",
      width: "36px",
      height: "36px",
    },
    drawer_footercontainer: {
      position: "fixed",
      top: "auto",
      bottom: 0,
      boxSizing: "border-box",
    },
  },
  // Comment Analysis
  QuestionWidth: {
    width: "50% !important",
    textAlign: "center",
    "& .MuiInput-underline:after": {
      borderBottom: "none"
    },
    "& textarea": {
      lineHeight: "1.5"
    },
  },
  resize: {
    fontSize: "15px",
  },
  Listdisplay: {
    width: "100%",
    display: "flex",
    flexDirection: "rows",
    fontSize: "17px",
  },
  WordCloud: {
    background: "#e6f7ff",
    marginBottom: "20px",
    height: "97%"
  },

  wordCloudToggle: {
    padding: "20px",
    '& .MuiToggleButton-label':
    {
      fontSize: 14,
      textTransform: "Capitalize"
    }
    // marginLeft: "20px",
  },
  toggleLeft: {
    borderRadius: "50px 0px 0px 50px",
    padding: "7px 15px 7px 15px",
    height: '32px',
    width: '82px'
  },
  toggleRight: {
    borderRadius: "0px 50px 50px 0px",
    padding: "7px 15px 7px 15px",
    height: '32px',
    width: '82px'
  },
  sentimentContainer: {
    width: "100%",
    overflow: "auto",
    marginBottom: "60px"
  },

  LegendContainer: {
    width: "100%",
    fontWeight: 300,
    position: "absolute",
    bottom: 0,
  },

  CAlinechartRow: {
    width: "80%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "50px",
    position: "relative",
  },
  CARowContainer: {
    position: "absolute",
    width: "100%",
    top: "4px"
  },
  barContainer: {
    width: "100%",
    height: "10px",
    borderRadius: "50%,0,0,50%",
    flex: "1",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    position: "absolute",
    top: "-10px",
  },
  wordContainer: {
    width: "100%",
    display: "flex",
  },
  borderline: {
    borderBottom: "1px solid #D3D3D3",
  },

  // Hotspot chart styles
  Hotspot_row: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "50px",
    position: "relative"
  },
  Hotspot_barContainer: {
    width: "30%",
    height: "15px",
    flex: "1",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
    position: "absolute",
    top: "-15px"
  },
  Hotspot_WordContainer: {
    width: "30%",
    display: "flex",
    justifyContent: "space-between",
    fontSize: "12px",
    marginTop: '2px'
  },

  //Yes No chart styles
  YesorNocontainers: {
    '& #YesorNocontainerChilds #YesorNocontainerChildrens':
    {
      borderBottom: "1px solid #ddd",
      paddingBottom: "10px"
    },
    '& #YesorNocontainerChilds:last-child #YesorNocontainerChildrens':
    {
      borderBottom: "none !important",
    }

  },
  Yesno_Form_align: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: 'center',
    paddingRight: "15px"
  },
  YesLabelButton: {
    background: "#54c0fd",
    borderRadius: "30px",
    width: "34px",
    height: "16px",
    marginRight: "3px",
    marginTop: "3px"
  },
  NoLabelButton: {
    background: "#bad6e9",
    borderRadius: "30px",
    width: "34px",
    height: "16px",
    marginRight: "3px",
    marginTop: "3px"
  },
  IconContainer: {
    width: "25px",
    height: "25px",
    marginBottom: "10px"
  },
  IconContainerCusColumn: {
    width: "27px",
    height: "27px",
  },
  paddingDownload: {
    paddingTop: "12px",
    paddingLeft: "12px"
  },
  IconContainerSmall: {
    width: "25px",
    height: "25px",
    marginBottom: "3px"
  },
  IconSaveContainer: {
    width: "34px",
    height: "20px"
  },
  iconRow: {
  },
  iconRow: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginLeft: "10px",
  },
  iconContainer: {
    height: "20px",
    borderRadius: "50%,0,0,50%",
    flex: "1",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
  lineChartContainer: {
    maxHeight: "300px",
    minHeight: "210px",
    overflowY: "auto",
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",
  },

  //Emgagement index chart styles
  EIWidth70: { width: "80% !important" },
  paddingLeft120: { paddingLeft: "250px" },
  paddingLeft360: { paddingLeft: "310px !important", paddingRight: "10px" },
  EIWidth30: { width: "20% !important" },
  EIheight100: { height: "100% !important" },
  EIheight10: { height: "10%" },
  EIheight90: { height: "90%" },
  boxMaxWidth: { maxWidth: "55%" },
  heightAuto: { height: "auto !important" },
  paddingtop10: { paddingtop: "10px" },
  paddingTop20: { paddingTop: "20px" },

  nodataText: {
    marginTop: "180px",
    marginLeft: "180px",
  },
  demographicContainer: {
    minHeight: "300px",
    maxHeight: "calc(100vh - 545px)",
    overflow: "auto",
  },

  demographicWIdth: {
    minHeight: "300px",
    maxHeight: "calc(100vh - 545px)",
    overflow: "auto",
  },
  commentQuesHeight: {
    maxHeight: "200px",
    overflow: "auto",
  },
  chartHeadings: {
    fontWeight: "700",
    fontFamily: "Montserrat",
  },

  //download chart pdf
  download_popup: {
    width: "403px !important"
  },

  // save reports styles
  chipInput: {
    "& .MuiInputBase-root": {
      lineHeight: "20px",
      padding: "1rem",
    },
    "& .MuiChip-root": {
      background: "#EDF9FF",
    },
    "& .MuiChip-deleteIcon": {},
  },

  demographicsChips: {
    "&.MuiChip-root": {
      background: "#EDF9FF",
      border: "1px solid #C0E2F5",
      padding: "10px",
      height: "28px",
      maxWidth: "320px",
    },
  },
  demogrpahicsChipClose: {
    width: "14px",
    height: "14px",
  },
  stepperLabel: {
    "& .MuiStepLabel-label.MuiStepLabel-active": {
      fontSize: "18px"
    },
    "& .MuiStepLabel-label": {
      marginTop: "0px !important"
    }
  },
  frequencyWidth: {
    minWidth: "50%",
    maxWidth: "100%",
    "&.drawer": {
      width: "100%"
    }
  },
  footerBtns_container: {
    position: "fixed",
    bottom: 0,
    background: "#fff",
    padding: "15px 20px",
    zIndex: "99",
    right: 0,
    borderTop: "0.2px #EBECED solid"
  },
  dialogBox_popover: {
    "& .MuiDialog-paper": {
      overflowY: "hidden !important",
    }
  },
  clearAllPosition: {
    position: "absolute",
    left: "280px",
    top: "6px",
    "&.drawer": {
      position: "relative",
      top: "auto",
      left: "auto"
    }
  },
  noDataContainer: {
    height: "calc(100vh - 205px)",
    width: "100%"
  },
  warningPosition: {
    position: "absolute",
    left: "280px",
    top: "6px",
    color: "#ff0000"
  },
  progressContainer: {
    display: "flex",
    position: "relative"
  },
  checkboxContainer: {
    "&.MuiFormControlLabel-root": {
      height: "-moz-fit-content",
      width: "-moz-fit-content",
    },
  },
  engagementInnerContainer: {
    padding: '55px 20px 0',
    display: 'grid',
    gridAutoColumns: '1fr',
    placeContent: 'center',
  },
  overallFavorabilityContainer: {
    display: "flex",
    flexFlow: "row wrap",
    alignItems: "center",
    justifyContent: "center",
    gap: "50px",
    height: "calc(100% - 70px)",
    [theme.breakpoints.between('lg', '1400')]: {
      gap: "25px"
    },
    [theme.breakpoints.down('lg')]: {
      gap: '15px'
    }
  },
  participationIcon: {
    '& svg': {
      [theme.breakpoints.between('lg', '1400')]: {
        maxWidth: "60px"
      },
      [theme.breakpoints.down('lg')]: {
        maxWidth: "50px"
      }
    }
  },
  execDashContainer: {
    '& .dflex': {
      whiteSpace: 'nowrap'
    },
    '& > .MuiGrid-item': {
      [theme.breakpoints.down('lg')]: {
        flexBasis: '50%',
        maxWidth: '50%',
        minHeight: '280px',
      },
      [theme.breakpoints.down('768')]: {
        flexBasis: '100%',
        maxWidth: '100%',
      }
    },
    '& > .MuiGrid-item:nth-child(odd):nth-last-child(odd):last-child': {
      [theme.breakpoints.down('lg')]: {
        flexBasis: '100%',
        maxWidth: '100%',
        minHeight: '280px',
      },
    }
  },
  overalleNPSContainer: {
    margin: '50px 0 40px',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      margin: '20px 0 0',
      gap: '15px',
    },
    '& > *': {
      [theme.breakpoints.down('lg')]: {
        height: 'unset'
      }
    }
  },
  dashboardDataFilterIcon: {
    '& > div': {
      position: 'relative',
      width: '25px',
      height: '27.5px',
      '& > div': {
        margin: '0!important',
        position: 'unset!important',
        '& > button': {
          width: 'unset!important',
          height: 'unset!important',
          padding: 0,
          '& > div': {
            margin: '0!important',
          }
        }
      }
    }
  },
  saveReportIcon: {
    '& > div': {
      width: '25px!important',
      height: '30px!important',
      '& > button': {
        padding: '0!important',
        width: 'inherit!important',
        height: 'inherit!important',
        '& > div': {
          margin: '0!important',
          width: 'inherit!important',
          height: 'inherit!important',
        }
      }
    }
  },
  radioGroup: {
    '& .MuiFormControl-root': {
        width: '100%'
    },
    '& .MuiFormGroup-root': {
        justifyContent: 'space-between',
        width: '50%',
    },
  },
  sortSelect: {
    width: '90px'
    },
  yAxisSortIcon: {
    marginLeft: "300px"
  },
  xAxisSortIcon: {
    transform: "rotate(-90deg)",
    marginLeft: "300px"
  },
  yAxisSortIconGridChart: {
    marginLeft: "325px"
  },
  xAxisSortIconGridChart: {
    transform: "rotate(90deg)",
    marginLeft: "325px"
  },
  emptyContainter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    marginLeft: "10px" ,
    boxShadow: "0px 0px 10px #0000002A",
    borderRadius: "6px",
    background: "#fff",
    padding: "20px",
    height: "430px"
  }
  
});
export default ReportsStyles;