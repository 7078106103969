/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route } from "react-router-dom";

// Import child router components
import NewClient from "./NewClient";
import EditClient from "./EditClient";

function ClientRouter (props) {
  const { match, user } = props;
  return (
    <Switch>
      <Route exact path={`${match.url}`} render={props => {
        return (
          <NewClient
            user={user}
            match={match}
            {...props}
          />
        );
      }} />
      <Route exact path={`${match.url}/edit`} render={props => {
        return (
          <EditClient
            user={user}
            match={match}
            {...props}
          />
        );
      }} />
      <Route exact path={`${match.url}/new`} render={props => {
        return (
          <NewClient
            user={user}
            match={match}
            {...props}
          />
        );
      }} />
    </Switch>
  );
}

// default props
ClientRouter.defaultProps = {
  match: {},
  user: {},
  classes: {}
};

/**
 * Bind Component Property Types
 */
ClientRouter.propTypes = {
  match: PropTypes.object,
  classes: PropTypes.object
};

/**
 * Export Component
 */
export default ClientRouter;
