/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { ValidatorForm } from "react-material-ui-form-validator";
import withStyles from '@mui/styles/withStyles';
import moment from "moment"

// Import Styles and Image
import SurveysStyles from "../SurveysStyles";

// Import Actions
import { surveyAction, alertAction, metadataAction, clientAction } from "../../../redux/actions";

// Import History
// import history from "../../../config/history";

// Import panels
import PhonecollectionPanel from "./SettingPanels/PhonecollectionPanel";
import CommunicationPanel from "./SettingPanels/CommunicationPanel";
import DistributionPanel from "./SettingPanels/DistributionPanel";
import ResponsePanel from "./SettingPanels/ResponsePanel";
import AudiencePanel from "./SettingPanels/AudiencePanel";
import SetupPanel from "./SettingPanels/SetupPanel";

// constants
import { surveySettingsConstants, surveyTypes } from "../../../config/constants";

function SettingsPanel(props) {
  const settingsForm = useRef(null);

  // Get Props Values
  const { classes, match, isAudienceTabError } = props;
  const { params } = match;

  // Define Dispatch and State
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState("setup-panel");
  const [errorPanels, setErrorPanels] = useState({});

  // Assign Settings Data
  const surveyPermission = useSelector((state) => state.client.surveyPermission);
  const settings = useSelector((state) => state.survey.settings);
  const { translated_history, native_language, getOne } = useSelector((state) => state.survey);

  const questions = useSelector((state) => state.survey.questions);
  const anonKioskShortName = useSelector((state) => state.survey.anonKioskShortName);

  // Get Settings Data
  useEffect(() => {
    dispatch(clientAction.getOne());
    dispatch(surveyAction.getSettings(params.id ? params.id : settings._id));
    dispatch(surveyAction.getQuestions(params.id ? params.id : settings._id));
    dispatch(surveyAction.getAnonKioskShortName(params.id ? params.id : settings._id));
  }, [dispatch]);

  /**
   * Handle Tab Change
   * @param {*} panel
   */
  const handleTabChange = (data) => {
    const { panel } = data;
    // setExpanded(isExpanded ? panel : false);
    setExpanded(panel);
  };

  /**
   * Suvey Settings Panel Form Submit
  * @param {*}
  */
  function handleSubmit(e) {
    e.preventDefault();
    let saveFlag = true;
    settings["translated_history"] = translated_history
    settings["native_language"] = native_language

    if(settings["startDate"]) {
      settings["startDateString"] = settings.startDate ? moment(settings.startDate).format('MM/DD/YYYY') : null;
    }

    if(settings["endDate"]) {
      settings["endDateString"] = settings.endDate ? moment(settings.endDate).format('MM/DD/YYYY') : null;
    }
    //Delete new flag for Action Coach
    if(settings.distribute.notification.me && settings.distribute.notification.me.active) {
      let meSchedule = settings.distribute.notification.me.schedule
      meSchedule && meSchedule.map((val) => {
        delete val.new;
      })
      settings["distribute"]["notification"]["me"]["schedule"] = meSchedule
    }
    
    // remove other languages checking
    // only check english
    for (let i = 0; i < settings.languages.length; i++) {
      if (settings.distribute.notification.email.active === true && surveyPermission.emailAccess === true &&
        settings.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS && !settings.distribute.notification.email.message.subject["en"]) {
        saveFlag = false;
        dispatch(alertAction.error(`Subject field cannot be blank for English language`));
      } else if (surveyPermission.transfloAccess === true && settings.distribute.notification.transflo.active === true &&
        settings.distribute.audience.select !== surveySettingsConstants.RADIOGROUP_ANONYMOUS && !settings.distribute.notification.transflo.message.subject["en"]) {
        saveFlag = false;
        dispatch(alertAction.error(`Transflo subject not found for English language`));
      } else if (i === settings.languages.length - 1 && saveFlag === true) {
        dispatch(surveyAction.saveSettings(settings, () => {
          dispatch(metadataAction.getClientMetadata())
        }));
      }
    }
    if (saveFlag === true) {
      dispatch(surveyAction.saveSettings(settings, () => {
        dispatch(metadataAction.getClientMetadata())
      }));
    }
  }

  return (
    <ValidatorForm
      id="surveySettingsPanelForm"
      name="surveySettingsPanelForm"
      useref="surveyPanelForm"
      ref={settingsForm}
      autoComplete="off"
      onSubmit={handleSubmit}
      onError={(error) => {
        const errPanels = {};
        error.map(({ props: { panel } }) => {
          if (!errPanels[panel]) {
            errPanels[panel] = true;
          }
        });
        setErrorPanels(errPanels);
        console.log(error);
      }}>
      <div className={"containerTop0"}>
        {/* setup panel */}
        <SetupPanel classes={classes} data={settings} questions={questions} expanded={expanded} handleTabChange={handleTabChange} panel={"setup"} panelError={errorPanels.setup} />

        {/* audience panel */}
        <AudiencePanel classes={classes} data={settings} anonKioskShortName={anonKioskShortName} expanded={expanded} handleTabChange={handleTabChange} panel={"audience"} panelError={errorPanels.audience} settingsForm={settingsForm} isError={isAudienceTabError} />

        {/* responses panel */}
        <ResponsePanel classes={classes} data={settings} expanded={expanded}
          handleTabChange={handleTabChange} panel={"response"} panelError={errorPanels.response} />

        {/* survey distribution option panel */}
        <DistributionPanel classes={classes} data={settings} surveyPermission={surveyPermission} anonKioskShortName={anonKioskShortName} expanded={expanded}
          handleTabChange={handleTabChange} panel={"distribution"} panelError={errorPanels.distribution || errorPanels.sms} settingsForm={settingsForm} />

        {/* communication panel */}
        <CommunicationPanel classes={classes} data={settings} expanded={expanded}
          handleTabChange={handleTabChange} panel={"communication"} panelError={errorPanels.communication} />

        {/* phone collection panel */}
        {getOne.type !== surveyTypes.MULTI_RATER_FEEDBACK &&
          <PhonecollectionPanel classes={classes} data={settings} surveyPermission={surveyPermission} expanded={expanded} handleTabChange={handleTabChange} panel={"phone"}
            panelError={errorPanels.phone} />
        }

      </div>
    </ValidatorForm>
  );
}

// default props
SettingsPanel.defaultProps = {
  classes: {},
  isAudienceTabError: false,
};

// prop types
SettingsPanel.propTypes = {
  classes: PropTypes.object,
  isAudienceTabError: PropTypes.bool,
};

export default withStyles(SurveysStyles)(SettingsPanel);
