import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const metadataService = {
  getDemographicsName,
  updateMetadata,
  getMetadataWithRGI,
  getClientMetadata,
  getMetadataValues
};

/**
 * GET CLIENT DEMOGRAPHICS
 */
async function getDemographicsName() {
  const response = await apihelper.getRequest("metadata/getDemographicsName");
  return response;
}

/**
 * Update Client Demographics
 */
async function updateMetadata(updateFields){
  const response = await apihelper.putRequest("metadata/updateMetadata", updateFields);
  return response;
}

/**
 * GET CLIENT DEMOGRAPHICS FOR CONVERSION
 */
async function getMetadataWithRGI() {
  const response = await apihelper.getRequest("metadata/getDemographicsRGI");
  return response;
}

/**
 * GET CLIENT METADATA
 */
async function getClientMetadata() {
  const response = await apihelper.getRequest("metadata/getMetadata");
  return response;
}

/**
 * GET Metadata values for specific field value
 */
async function getMetadataValues(selectedRG) {
  // Replace / character into __ becuase it content after / will be act as another param
  if (selectedRG && selectedRG.indexOf('/') !== -1) {
    selectedRG = selectedRG.replaceAll('/', '__')
  }
  const response = await apihelper.getRequest(`metadata/rgi/${selectedRG}`);
  return response;
}