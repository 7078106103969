import {
  participantConstant
} from "../constants";
// Set Initial Participant Table Data
const tableDatas = (sortName) => {
  return {
    data: [],
    page: {
      fieldFilters: {
        headerCols: [],
        fieldCols: [sortName],
        searchCols: [],
        columnSettings: [],
        visibleColumns: [],
        leftDefaultCols: []
      }
    }
  };
};

// Set Initial State
const initialState = {
  getOne: {
    firstName: "",
    lastName: "",
    empId: "",
    email: "",
    phone: "",
    altPhone: "",
    country: "US",
    status: true,
    state: "",
    survey: [],
    languages: {
      code: 'en',
      name: 'English'
    }
  },
  getAll: [],
  search: {
    ...tableDatas("firstName")
  },
  surveys: [],
  removeSurveysData: [],
  overrideSurveysData: [],
  peopleloader: false,
  allSurveys: [],
  clientSurveys: [],
  surveyNotificationData: [],
  isOpenSurveyAvail: true
};

export default function (state = initialState, action) {
  switch (action.type) {

    //GET PARTICIPANT
    case participantConstant.PARTICIPANT_GET_ONE_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case participantConstant.PARTICIPANT_GET_ONE_SUCCESS:
      if (action.data.data.languages === undefined || action.data.data.languages === {})
        action.data.data.languages = {
          "code": "en",
          "name": "English"
        }
      return {
        ...state,
        getOne: {
          ...action.data.data,
          dup_manager_id: action.data.data.manager_id
        }
      };
    case participantConstant.PARTICIPANT_GET_ONE_FAILURE:
      return {
        ...state,
        getOne: initialState.getOne
      };
    case participantConstant.PARTICIPANT_GET_ONE_INIT:
      return {
        ...state,
        getOne: {
          ...initialState.getOne,
          survey: []
        }
      };

    //SEARCH PARTICIPANTS REDUCER
    case participantConstant.PARTICIPANT_SEARCH_REQUEST:
      return {
        ...state,
        // to reset get one on search page
        getOne: initialState.getOne,
        peopleloader: true
      };
    case participantConstant.PARTICIPANT_SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          data: action.data.data,
          page: action.data.page
        },
        isOpenSurveyAvail: action.data.isOpenSurveyAvail,
        peopleloader: false
      };
    case participantConstant.PARTICIPANT_SEARCH_FAILURE:
      return {
        ...state,
        search: initialState.search,
        peopleloader: false
      };

    //ADD PARTICIPANT
    case participantConstant.PARTICIPANT_ADD_REQUEST:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_ADD_SUCCESS:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_ADD_FAILURE:
      return {
        ...state
      };

    //UPDATE PARTICIPANT
    case participantConstant.PARTICIPANT_UPDATE_REQUEST:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_UPDATE_SUCCESS:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_UPDATE_FAILURE:
      return {
        ...state
      };

    // UPDATE GET ONE PARTICIPANT REDUCER DATA
    case participantConstant.PARTICIPANT_GET_ONE_UPDATE:
      return {
        ...state,
        getOne: action.data,
        surveys: action.data.survey
      };

    //SEARCH PARTICIPANTS SURVEY REDUCER
    case participantConstant.PARTICIPANT_SURVEY_SEARCH_REQUEST:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_SURVEY_SEARCH_SUCCESS:
      return {
        ...state,
        surveys: action.data.data,
        clientSurveys: action.data.data
      };
    case participantConstant.PARTICIPANT_SURVEY_SEARCH_FAILURE:
      return {
        ...state
      };
    // Participant Delete Reducer Update
    case participantConstant.PARTICIPANT_REMOVE_REQUEST:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_REMOVE_SUCCESS:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_REMOVE_FAILURE:
      return {
        ...state
      };
    // Participant Delete Reducer Update
    case participantConstant.PARTICIPANT_GET_ALL_SURVEY_REQUEST:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_GET_ALL_SURVEY_SUCCESS:
      return {
        ...state,
        allSurveys: action.data.data
      };
    case participantConstant.PARTICIPANT_GET_ALL_SURVEY_FAILURE:
      return {
        ...state
      };
    // Apply conversion
    case participantConstant.APPLY_CONVERSION_REQUEST:
      return {
        ...state
      };
    case participantConstant.APPLY_CONVERSION_SUCCESS:
      return {
        ...state
      };
    case participantConstant.APPLY_CONVERSION_FAILURE:
      return {
        ...state
      };
    //SURVEYS DATA REDUCER
    case participantConstant.REMOVE_SURVEYS_GET_REQUEST:
      return {
        ...state
      };
    case participantConstant.REMOVE_SURVEYS_GET_SUCCESS:
      return {
        ...state,
        removeSurveysData: action.data.data
      };
    case participantConstant.REMOVE_SURVEYS_GET_FAILURE:
      return {
        ...state,
        removeSurveysData: initialState.removeSurveysData
      };
    //SURVEYS DATA REDUCER
    case participantConstant.OVERRIDE_SURVEYS_GET_REQUEST:
      return {
        ...state
      };
    case participantConstant.OVERRIDE_SURVEYS_GET_SUCCESS:
      return {
        ...state,
        overrideSurveysData: action.data.data
      };
    case participantConstant.OVERRIDE_SURVEYS_GET_FAILURE:
      return {
        ...state,
        overrideSurveysData: initialState.overrideSurveysData
      };
    case participantConstant.PARTICIPANT_AGETENURE_SUCCESS:
      return {
        ...state,
        getOne: {
          ...state.getOne,
          tenure_calculation: action.data.data.tenure_calculation,
          age_calculation: action.data.data.age_calculation,
          age_groupings: action.data.data.age_groupings,
          tenure_groupings: action.data.data.tenure_groupings,
          generation: action.data.data.generation
        }
      }
    case participantConstant.PARTICIPANT_AGETENURE_FAILURE,
      participantConstant.PARTICIPANT_AGETENURE_REQUEST:
      return {
        ...state
      }

    //Survey data for induvidual send notification
    case participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_REQUEST:
      return {
        ...state
      };
    case participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_SUCCESS:
      return {
        ...state,
        surveyNotificationData: action.data.data
      };
    case participantConstant.PARTICIPANT_NOTIFICATION_SURVEY_FAILURE:
      return {
        ...state,
        surveyNotificationData: initialState.surveyNotificationData
      };
    default:
      return state;
  }
}