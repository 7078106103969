import withStyles from '@mui/styles/withStyles';

const MultiSelectChecboxWithSearchStyle = {
    compContainer: {
        width: '500px',
        padding: '16px 32px',
        '&.autoWidth': {
            width: 'auto',
        }
    },
    compList: {
        maxHeight: '200px',
        overflowY: 'auto',
        margin: '0 0 16px',
        paddingLeft: '8px',
    },
    checkBox: {
        padding: '3px 6px',
    },
    checkBoxEllipsis: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
    },
    noSearchResults: {
        color: '#B1B2B3',
        fontStyle: 'italic',
        paddingRight: '8px',
        fontSize: '14px',
    },
    closeIcon: {
        color: '#e5e5e5',
        fontSize: 20,
    }
};

export default withStyles(MultiSelectChecboxWithSearchStyle);