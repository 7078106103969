import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Take Survey Service Functions
 */
export const takeSurveyService = {
  getOne,
  updateSurveyResponse,
  kioskUserAuthValidation,
};

/**
 * Call Survey GetOne Service
 * id => surveyId
 * pId=> participantId default null
 */
async function getOne(surveyGetQuery) {
  const { takeSurveyUrl, params, queryString } = surveyGetQuery;

  let paramUrl = "";
  if (takeSurveyUrl == "existing" && queryString && queryString.a && queryString.b && queryString.c) {
    //existing survey urls (old format clientId,survey_id & participant_id in query String)
    paramUrl = `${takeSurveyUrl}/${queryString.b}/${queryString.a}`;
  } else if (takeSurveyUrl == "sp_caller" && queryString && queryString.a && queryString.b && queryString.caller_name) {
    //existing sp_caller route without caller Id (old format clientId,survey_id & participant_id in query String)
    paramUrl = `${takeSurveyUrl}/${queryString.a}/${queryString.b}/${queryString.caller_name}`;
  } else if (takeSurveyUrl == "sp_caller" && params.hasOwnProperty("caller_id")) {
    //sp_caller route
    paramUrl = `${takeSurveyUrl}/${params.survey_id}/${params.participant_id}/${params.caller_id}`;
    if (queryString && queryString.lang) {
      paramUrl = paramUrl + `?lang=${queryString.lang && queryString.lang !== "null" ? queryString.lang : "en"}`;
    }
  } else if (params.hasOwnProperty("client_id")) {
    //exiting take survey route
    paramUrl = `${takeSurveyUrl}/${params.survey_id}/${params.participant_id}/${params.client_id}`;
  } else if (takeSurveyUrl == "sp_caller" && params.hasOwnProperty("survey_url")) {
    //sp_caller short url route
    paramUrl = `shortUrl/${params.survey_url}`;
  } else {
    paramUrl = `${takeSurveyUrl}/${params.survey_url}`;
  }

  if (takeSurveyUrl === "anonymous") {
    // Send Participant Id
    let participant_id = null;
    if (localStorage.getItem(params.survey_url))
      participant_id = localStorage.getItem(params.survey_url)

    paramUrl = paramUrl + `/${participant_id}`;
  }
  const response = await apihelper.getRequest("takesurvey/" + paramUrl);
  return response;
}

async function updateSurveyResponse(data, apiUrl) {
  const responseData = { ...data };

  const response = await apihelper.postRequest(apiUrl, responseData);
  return response;
}

async function kioskUserAuthValidation(data) {
  const response = await apihelper.postRequest("takesurvey/kiosk/auth/", data);
  return response;
}
