/* eslint-disable prefer-const */
/* eslint-disable dot-notation */
/* eslint-disable quote-props */
/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";

// Import Actions
import { metadataAction } from "../../../../../redux/actions";

const SelectFields = (props) => {
  const dispatch = useDispatch();

  // get Props
  const { metadataWithRGI, metadataSelected, currentValue, currentItem, currentIndex, currentName } = props;

  // operator values
  const operators = [
    { key: "in", value: "includes" },
    { key: "nin", value: "does not include" },
    { key: "contains", value: "contains" },
    { key: "between", value: "between" },
    { key: "greater", value: "greater than or equal to" },
    { key: "less", value: "less than or equal to" }
  ];
  const operatorType = {
    "integer": ["in", "nin", "between", "greater", "less", "contains"],
    "date": ["in", "nin", "between", "greater", "less"],
    "string": ["in", "nin", "contains"]
  };

  // Set dropdown values
  let arrayValues = [];
  if (currentName === "from") {
    arrayValues = metadataWithRGI
    arrayValues = arrayValues.filter((val)=> val.key !== "manager_id" && val.key !== "manager_role");
  } else if (currentName === "operator") {
    for (let i = 0; i < operators.length; i++) {
      for (let j = 0; j < metadataWithRGI.length; j++) {
        if (metadataWithRGI[j]["key"] === currentItem["from"] && operatorType[metadataWithRGI[j]["dataType"]] &&
          operatorType[metadataWithRGI[j]["dataType"]].indexOf(operators[i]["key"]) > -1) {
          arrayValues.push(operators[i]);
        }
      }
    }
  }

  // Change Conversion Rule Data
  const handleDemographicsChange = (e) => {
    if (metadataSelected && metadataSelected["conversion"]) {
      for (let i = 0; i < metadataSelected["conversion"].length; i++) {
        if (i === currentIndex) {
          metadataSelected["conversion"][i][e.name] = e.value;
          if (e.name === "from") {
            metadataSelected["conversion"][i]["match"] = [];
            metadataSelected["conversion"][i]["value"] = "";
            metadataSelected["conversion"][i]["operator"] = "";
          } else {
            metadataSelected["conversion"][i]["match"] = [];
            metadataSelected["conversion"][i]["value"] = "";
          }
        }
      }
      dispatch(metadataAction.selectedMetadata(metadataSelected));
    }
  };

  // get display name for auto complete
  const getOptionSelected = (options, value) => {
    let optionSelected = options.filter((p) => p["key"] === value)[0] || "";
    return optionSelected;
  };

  return (
    <React.Fragment>
      <Autocomplete
        id={`${currentName}${currentIndex}`}
        disableClearable
        autoHighlight
        blurOnSelect
        options={arrayValues}
        value={getOptionSelected(arrayValues, currentValue)}
        className="input-value-editor autocomplete"
        getOptionLabel={(option) => option.value || ""}
        onChange={(e, val) => { handleDemographicsChange({ "name": currentName, "value": val["key"] }); }}
        renderInput={(params) => (
          <TextField {...params}
            variant={"outlined"}
            className={`${"height28 conversionAutoComplete"}`}
            placeholder="Select Field"
            />
        )}
      />
    </React.Fragment>
  );
};

// default props
SelectFields.defaultProps = {
  classes: {},
  metadata: [],
  metadataWithRGI: [],
  metadataSelected: {},
  currentItem: {},
  currentIndex: 0,
  currentName: ""
};

// prop types
SelectFields.propTypes = {
  classes: PropTypes.object,
  metadataSelected: PropTypes.object,
  metadata: PropTypes.array,
  metadataWithRGI: PropTypes.array,
  currentItem: PropTypes.object,
  currentIndex: PropTypes.number,
  currentName: PropTypes.string
};

export default SelectFields;
