import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "./TakeSurveyStyles";
//import constants
import { TakeSurveyConstants } from "../../../config/constants";
//import commponent
import Button from "@mui/material/Button";
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';


//import helper
import { linkIfy } from "../../../helpers/appHelper";
import history from "../../../config/history";

// Import Logo
import SuccessIcon from "../../../assets/images/svg/takesurvey_success-message.gif";

import { meTakeSurveyAction } from "../../../redux/actions";

function ThankYouComponent(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  const { classes, match, shortUrl } = props;
  const langCode = useSelector((state) => state.meTakeSurvey.surveyLang);
  const survey = useSelector((state) => state.meTakeSurvey.getOne);
  const { params } = match;

  //If Sp Caller Survey
  if (params.caller_id) {
    setTimeout(() => {
      window.close();
    }, 1500);
  }

  const handleAnotherSurvey = () => {

    var originUrl = window.location.origin;
    let page = `${originUrl}/#/me-takesurvey/${shortUrl}`;
    dispatch(meTakeSurveyAction.clearMeSurveyData(() => {
      window.location.replace(page);
    }));
    // const page = `/me-takesurvey/${shortUrl}`;
    // history.push(page);
  }

  const thanksHeader = survey.thanks_header ? (survey.thanks_header[langCode] && survey.thanks_header[langCode] !== "" ? survey.thanks_header[langCode] : survey.thanks_header["en"]) : TakeSurveyConstants.THANKS_MESSAGE_HEADER;
  const thanksDesc = survey.thanks_description ? (survey.thanks_description[langCode] && survey.thanks_description[langCode] !== "" ? survey.thanks_description[langCode] : survey.thanks_description["en"]) : TakeSurveyConstants.THANKS_MESSAGE_DESCRIPTION;

  return (
    <>
      <Grid item xs={12}>
        <Grid container justifyContent="center" alignItems="center" direction="column" className={classes.thankYouAnimationContainer}>
          <img src={SuccessIcon} onClick={() => { }} alt={TakeSurveyConstants.SURVEY_THANKS_PAGE_MESSAGE} />
          <div className={`scrolly ${classes.thankYouAnimationMessage}`}>
            <div className="thanksHeader" dangerouslySetInnerHTML={{ __html: linkIfy(thanksHeader) }} />
            <div className="thanksDescription" dangerouslySetInnerHTML={{ __html: linkIfy(thanksDesc) }} />
            <div className="" >
              <Button 
              className={classes.takeAnotherSurveyButton} 
              size="small"
              onClick={() => handleAnotherSurvey()}  
              >
                <KeyboardBackspaceRoundedIcon/> 
                  <Grid marginLeft={1}>Take Another Survey</Grid>
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

// default props
ThankYouComponent.defaultProps = {
  classes: {},
  thanksHeader: "Thank You",
  thanksDesc: "Your feedback has been received.",
};

// prop types
ThankYouComponent.propTypes = {
  classes: PropTypes.object,
  thanksHeader: PropTypes.string,
  thanksDesc: PropTypes.string
};
export default withStyles(TakeSurveyStyles)(ThankYouComponent);
