let API_POINTS = {
  url: "http://localhost:8080/v1",
  ms_url: "http://localhost:3030/v1/ms",
  pyserver_url: "http://localhost:5001",
  socket_url: 'ws://localhost:3030'
};

const SERVER_POINT = process.env.REACT_APP_BUILD_TO || "LOCAL"; // QA, UAT, PROD, LOCAL

switch (SERVER_POINT) {
  case "PROD":
    API_POINTS = {
      url: "https://server.peopleelement.net/v1",
      ms_url: "https://ms.peopleelement.net/v1/ms",
      pyserver_url: "https://pyserver.peopleelement.net",
      socket_url: 'wss://ms.peopleelement.net'
    };
    break;
  case "STACK-PRE-PROD":
    API_POINTS = {
      url: "https://perf5-serverapi.peopleelement.net/v1",
      ms_url: "https://perf5-serverms.peopleelement.net/v1/ms",
      pyserver_url: "https://pe5-perf-pyserver.peopleelement.net",
      socket_url: 'wss://perf5-serverms.peopleelement.net'
    };
    break; 
  case "DISASTER":
    API_POINTS = {
      url: "https://rhel-dr-api.peopleelement.net/v1",
      ms_url: "https://rhel-dr-ms.peopleelement.net/v1/ms",
      pyserver_url: "https://rhel-dr-pyserver.peopleelement.net",
      socket_url: 'wss://rhel-dr-ms.peopleelement.net'
    };
    break;
  case "PERF":
    API_POINTS = {
      url: "https://perf-serverapi.peopleelement.net/v1",
      ms_url: "https://perf-serverms.peopleelement.net/v1/ms",
      pyserver_url: "https://pe-perf-pyserver.peopleelement.net",
      socket_url: 'wss://perf-serverms.peopleelement.net'
    };
    break;
  case "UAT":
    API_POINTS = {
      url: "https://rh-demo.peopleelement.net:9045/v1",
      ms_url: "https://rh-demo.peopleelement.net:9046/v1/ms",
      pyserver_url: "https://uat-pyserver.peopleelement.net",
      socket_url: 'wss://rh-demo.peopleelement.net:9046'
    };
    break;
  case "QA":
    API_POINTS = {
      url: "https://pe-qa-rhel.peopleelement.net:9045/v1",
      ms_url: "https://pe-qa-rhel.peopleelement.net:9046/v1/ms",
      pyserver_url: "https://pe-qa-rhel.peopleelement.net:5001",
      socket_url: 'wss://pe-qa-rhel.peopleelement.net:9046'
    };
    break;
  case "STACK-QA":
    API_POINTS = {
      url: "https://qa.peopleelement.net:9045/v1",
      ms_url: "https://qa.peopleelement.net:9046/v1/ms",
      pyserver_url: "https://qa-py.peopleelement.net",
      socket_url: 'wss://qa.peopleelement.net:9046'
    };
    break;
  case "STACK-UAT":
    API_POINTS = {
      url: "https://peperf-stack-api.peopleelement.net/v1",
      ms_url: "https://peperf-stack-ms.peopleelement.net/v1/ms",
      pyserver_url: "https://peperf-stack-py.peopleelement.net",
      socket_url: 'wss://peperf-stack-ms.peopleelement.net'
    };
    break;    
  case "DEV":
    API_POINTS = {
      url: "http://192.168.1.88:4051/v1",
      ms_url: "http://192.168.1.88:4050/v1/ms",
      pyserver_url: "http://localhost:5001",
      socket_url: 'ws://localhost:4050'
    };
    break;
  default:
    API_POINTS = {
      url: "http://localhost:8080/v1",
      ms_url: "http://localhost:3030/v1/ms",
      pyserver_url: "http://localhost:5001",
      socket_url: 'ws://localhost:3030'
    };
    break;
}

export const _api = API_POINTS;
