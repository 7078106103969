import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { ValidatorForm } from "react-material-ui-form-validator";

// Import Actions
import { takeSurveyAction } from "../../../redux/actions";

// Import Components
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../../components/TextFieldComponent/TextFieldComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";

// Import Config Constants
import { TakeSurveyConstants } from "../../../config/constants";

function AuthKiosk(props) {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const UI = useSelector((state) => state.UI);
  const survey = useSelector((state) => state.takeSurvey.getOne);

  const { email, password, showPassword } = formData;
  const dispatch = useDispatch();

  /**
   * Handle Textbox Changes
   */
  function handleChange(e) {
    const { name, value } = e;
    setFormData((formData) => ({ ...formData, [name]: value }));
  }

  // Show / Hide Password Visibility
  function handleClickShowPassword() {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  }

  /**
   * AuthKiosk Form Submit
   * @param {*}
   */
  function handleSubmit(e) {
    e.preventDefault();
    if (email && password) {
      dispatch(takeSurveyAction.kioskUserAuthentication(email, password));
    }
  }

  return (
    <ValidatorForm name="AuthKioskForm" useref="form" autoComplete="off" onSubmit={handleSubmit} onError={(errors) => console.log(errors)}>
      <Grid container spacing={5}>
        <Grid item xs={12}>
          <TypographyComponent title={TakeSurveyConstants.KIOSK_SIGNIN_TITLE} variant={"h2"} className="txtBold"></TypographyComponent>
          <TypographyComponent title={TakeSurveyConstants.KIOSK_SIGNIN_SUB_TEXT} variant={"h5"} className={"txtgrey mt-1"}></TypographyComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent title={survey.kioskMetaData && survey.kioskMetaData.hasOwnProperty("kiosk_username") && survey.kioskMetaData.kiosk_username ? survey.kioskMetaData.kiosk_username : TakeSurveyConstants.KIOSK_SIGNIN_EMAIL_ID} variant={"h6"}></TypographyComponent>
          <TextFieldComponent id={"signin_email_txtbox"} name={"email"} value={email} margin={"none"} handleChange={(e) => handleChange(e)} validators={["required"]}></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <TypographyComponent id={"signin_email_txtbox"} title={survey.kioskMetaData && survey.kioskMetaData.hasOwnProperty("kiosk_password") && survey.kioskMetaData.kiosk_password ? survey.kioskMetaData.kiosk_password : TakeSurveyConstants.KIOSK_SIGNIN_PASSWORD} variant={"h6"}></TypographyComponent>
          <TextFieldComponent
            id={"signin_password_txtbox"}
            name={"password"}
            type={"password"}
            margin={"none"}
            value={password}
            pwdVisibileHide={true}
            showPassword={showPassword}
            handleChange={(e) => handleChange(e)}
            handleClickShowPassword={(e) => handleClickShowPassword(e)}
            validators={["required"]}
          ></TextFieldComponent>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={4} alignItems={"center"}>
            <Grid item xs={6}>
              <ButtonComponent title={TakeSurveyConstants.KIOSK_SIGNIN_BTN_TEXT} color={"primary"} size={"large"} type="submit" loading={UI.loading}></ButtonComponent>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default AuthKiosk;
