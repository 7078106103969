import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SurveyPreviewStyles from "./SurveyPreviewStyles";

//import Child Components
import SurveyPreviewConfirmation from "./SurveyPreviewConfirmation";
import SurveyPreviewComponent from "./SurveyPreviewComponent";

//import components
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import CloseImg from "../../../assets/images/svg/dialog_close.svg";

// Import Actions
import { surveyAction } from "../../../redux/actions";

function SurveyPreview(props) {
  const { classes } = props;
  // Define Dispatch
  const dispatch = useDispatch();
  const surveyId = useSelector((state) => state.survey.previewSurveyId);
  const previewConfQuestions = useSelector(
    (state) => state.surveyPreview.previewConfQuestions
  );
  const langCode = useSelector((state) => state.surveyPreview.surveyLang);

  const closeSurveyPreview = () => {
    dispatch(surveyAction.handleSurveyPreview({ _id: null, preview: false }));
  };
  return <>
    {!previewConfQuestions.includes(surveyId) ? (
      <SurveyPreviewConfirmation {...props} />
    ) : (
      <SurveyPreviewComponent {...props} langCode={langCode} />
    )}
    
    
    <Button
      variant="text"
      size={"small"}
      className={classes.surveyPreviewClose}
      onClick={closeSurveyPreview}>
      <img src={CloseImg} alt="CloseImg" />
      <TypographyComponent variant="h5" className="pl5">
        Close
      </TypographyComponent>
    </Button>
  </>;
}

// default props
SurveyPreview.defaultProps = {
  classes: {},
};

// prop types
SurveyPreview.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(SurveyPreviewStyles)(SurveyPreview);
