import React from 'react';
import PropTypes from "prop-types";

import { Grid, IconButton } from "@mui/material";
import withStyles from '@mui/styles/withStyles';

import _ from 'lodash';

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";
import { DashboardClose } from "../../../../assets/images/svgComponents/ImageComponent";

//Import Constants
import { ReportsConstants, dashboardConstants } from "../../../../config/constants"

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import PercentageComponent from '../../../../components/PercentageComponent/PercentageComponent';
import ToolTipComponent from '../../../../components/ToolTipComponent/ToolTipComponent';

import { useSelector } from 'react-redux';

function FavorableByCategoryWidget(props) {
    // props
    const { classes, value, type, hasMESurvey, adminPreference } = props;
    const { dashboardData } = useSelector(state => state.dashboard)
    let data = dashboardData.favourable_category
    let minWidth = 40;
    let comparelength=0;
    for(let i =0 ; i < data?.length ; i++) {
        if(data[i].category.length < 8){

        }else if(data[i].category.length < 19 && data[i].category.length > 7 ){
            if(data[i].category.length > comparelength){
                minWidth = 120; 
                comparelength=data[i].category.length;
            }
        }else {
            if(data[i].category.length > comparelength){
                minWidth = 160; 
                comparelength=data[i].category.length;
            }  
        }
    }

    const handleClose = () => {
        if (typeof adminPreference?.data?.hideDashWidgets?.favorableByCategory === 'boolean') {
            adminPreference['data']['hideDashWidgets']['favorableByCategory'] = true;
            adminPreference.updatePreference(adminPreference?.data)
        }
    }

    return <Grid className={`${classes.dashboardTile} ${hasMESurvey ? "me" : "ss"}`}>
        <Grid
            className={
                `${classes.overallChart}
                    width100 height100 ${hasMESurvey ? `me me_${isLeft ? 0 : wIndex + 1}` : ""}`
            }
            container
            direction="column"
        >
            <Grid item className='dflexCenter width100 spacebetween'>
                <TypographyComponent variant='h5' className={'fontWeight600 width100 txtLeft'} >{dashboardConstants.FAVOURABLE_BY_CATEGORY.TITLE}</TypographyComponent>
                <ToolTipComponent title={ReportsConstants.CLOSE}>
                    <IconButton onClick={handleClose} size="large">
                        {DashboardClose()}
                    </IconButton>
                </ToolTipComponent>
            </Grid>
            <Grid item className="mt-2 dflex">
                <Grid className='w100' style={{ display:"grid", "grid-template-columns": "auto-fill", "align-content": data.length < 10 ? "center" : "space-between","align-item": "center","justify-item": "center", "padding": minWidth < 120 && data.length < 13  ? "0 32px 0 28px":minWidth > 120 && data.length > 12?"0 32px 0 0":"0 34px 0 0", "grid-auto-rows":data.length < 13 ? "34px":"32px", height:"380px", overflowY:"auto",overflowX:"hidden" }}>
                    {data?.map((item) => (
                        <PercentageComponent item={item} minWidth={minWidth}/>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    </Grid >
}
// default props
FavorableByCategoryWidget.defaultProps = {
    classes: {},
    value: "",
    type: "",
    hasMESurvey: false,
    adminPreference: {},
};

// prop types
FavorableByCategoryWidget.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    type: PropTypes.string,
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(FavorableByCategoryWidget);