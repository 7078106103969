

const ContentLibraryStyles = () => ({

    card_container: {
        border: '1px solid #EAEBED',
        borderRadius: "0px",
        padding: "10px",
        background: "#F4F6F7",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        // minHeight: '150px',
        '& .MuiFormControl-root': {
            margin: "0px !important"
        },
        '& .MuiOutlinedInput-input': {
            padding: "0px !important"
        },
        '& .MuiTypography-h4': {
            color: "#292929",
            fontSize:"16px",
            fontFamily:"Roboto",
            fontWeight:"400",
            paddingLeft:"12px"
        },
        '&.item_group': {
            border: 'none',
            background: 'none',
            borderBottom: '1px solid #EAEBED',
        }
    },
    Relation_Percentage_Wrapper: {
        width: "50%",
        marginBottom: "10px",
        paddingLeft: "0px !important",
        overflow: "hidden"

    },
    Fav: {
        width: "37px",
    },
    Relation_Percentage_container: {
        // position: "relative",
        // float: "left",
        width: "100%",
    },
    txtCenter: {
        paddingLeft: "15px"
    },
    scrollc: {
        maxHeight: "600px !important",
        overflow: "auto",
        paddingLeft: "25px"
    },
    legendItem: {
        display:"flex",
        flexDirection:"row",
        justifyContent: "center",
        alignItems: "center",
        margin: "0px 10px",
        "& span":{
            width: "20px",
            height: "15px",
            marginRight: "5px"
        }
    }
});

export default ContentLibraryStyles;
