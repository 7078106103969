/* eslint-disable curly */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import { Avatar } from "@mui/material";

// Import Config Layout
import { getQuestionCategoryPanelHeight } from "../../../../config/layout";

// Import constants
import { surveyQuestionsConstants } from "../../../../config/constants";

// Import redux actions
import { updateStateReducerAction, categoryAction, alertAction } from "../../../../redux/actions";

// Import Redux Constant
import { dragdropConstant, categoryConstant } from "../../../../redux/constants";

// Import Components
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import Drag from "../../../../components/DragDropComponents/Drag";

function CategoryQuestPreview(props) {

    // Get Props Values
    const { classes, cat_ques } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    const { questions, activeCategoryTab } = useSelector((state) => state.survey);

    const drag = useSelector(state => state.dragdrop.drag);

    const checkedQues = useSelector((state) => state.category.selectedQues);

    const category_ques = useSelector((state) => state.category.search.cat_ques);

    const allQuestions = questions.questions

    let activeCategoryQuestions = new Array();

    allQuestions.forEach((qus) => {
        activeCategoryQuestions.push(qus.baseQusId);
    });

    const [checkedquesTxt, setCheckedquesTxt] = useState([]);

    // Handle multiselecting questions

    const handlequesSelect = (value, index) => () => {
        const currentIndex = checkedQues.indexOf(value._id);
        const newChecked = [...checkedQues];
        let newCheckedquesTxt = [...checkedquesTxt];
        if (checkedQues.length < 1) {
            newCheckedquesTxt = [];
        }

        if (!checkedQues.includes(value._id)) {
            newChecked.push(value._id);
            newCheckedquesTxt.push(value.qusText)
        } else {
            newChecked.splice(currentIndex, 1);
            newCheckedquesTxt.splice(currentIndex, 1);
        }
        let newchecked_ordered = new Array();
        category_ques.forEach(val => {
            if(newCheckedquesTxt.includes(val.qusText)){
                newchecked_ordered.push(val.qusText);
            }
        });

        dispatch(updateStateReducerAction.update(categoryConstant.CATEGORY_QUESTION_SELECTED, newChecked)) 

        setCheckedquesTxt(newchecked_ordered)
    };

    // Set Question Panel Height
    const categoryPanelHeight = getQuestionCategoryPanelHeight();

    /**
     * Handle Menu Item Mouse Down Event
     */
    const handleMenuItemMouseDown = () => {
        dispatch(updateStateReducerAction.update(dragdropConstant.DRAG));
    };

    /**
     * Handle Menu Item Mouse Up Event
     */
    const handleMenuItemMouseUp = () => {
        dispatch(updateStateReducerAction.update(dragdropConstant.DROP));
        // setChecked([]);
    };

    // Badge text for question type
    const getQuestionBadge = (qustxt) => {
        if (qustxt === "Single Select") {
            return "SS"
        }
        else if (qustxt === "Yes/No") {
            return "YN"
        }
        else if (qustxt === "Matrix") {
            return "M"
        }
        else if (qustxt === "Dropdown") {
            return "D"
        }
        else if (qustxt === "Open Ended") {
            return "OE"
        }
        else {
            return ""
        }
    }


    return (
        <Grid className={`${"boxShadow"} ${classes.categoryquesContainer}  ${drag && "opacity0"}`}>

            <Grid
                item xs={12}
                style={{ height: `${categoryPanelHeight + 100}px` }}
                className={"scrolly"}>
                <Grid className={"pr5"}>
                    <TypographyComponent className={"pl-1 pt-1 fontSize13"} variant="h6">
                        {surveyQuestionsConstants.PREVIEW_QUESTION}
                        <InformationComponent >
                            <TypographyComponent variant="tooltipbody">
                                {(questions.template==="Manager 360"?surveyQuestionsConstants.PREVIEW_QUESTIONS_HELP_COMP:surveyQuestionsConstants.PREVIEW_QUESTIONS_HELP).split('\n').map(str => <p>{str}</p>)}
                            </TypographyComponent>
                        </InformationComponent>
                    </TypographyComponent>

                </Grid>
                <Grid className={`${classes.categoryquestions}`}>
                    {cat_ques.map((item, index) => (
                        <Grid>
                            <List className={classes.preview_questList}>
                                {
                                    activeCategoryQuestions.includes(item._id) ?

                                        <ListItem
                                            disabled
                                            className={`${classes.category_quest_list} ${"quest_list"}`}
                                            onClick={() => dispatch(alertAction.error(surveyQuestionsConstants.EXISTING_QUESTION))}
                                        >
                                            <Grid className={`${"pl-1 pr-1 pt2"} ${classes.questype_preview}`}>
                                                <Badge
                                                    color="secondary"
                                                    className="badgebig opacity5"
                                                    badgeContent={getQuestionBadge(item.qusType)}
                                                ></Badge>
                                            </Grid>

                                            <ListItemText
                                                className={`${classes.category_quest_listText} pl-1`}
                                                primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                                                disableTypography={false}
                                                primary={item.qusText}
                                            />
                                        </ListItem>

                                        : (checkedQues.length > 0) ? (
                                            <Drag key={item._id} dataItem={checkedquesTxt} type="question" dropEffect="copy">

                                                <ListItem
                                                    key={index + "qus"}
                                                    // onMouseDown={() => handleMenuItemMouseDown()} 
                                                    onMouseUp={() => handleMenuItemMouseUp()}
                                                    className={`${classes.category_quest_list} ${"quest_list"} ${checkedQues.includes(item._id) ? classes.categoryques_Selected : ""}`}
                                                    onClick={handlequesSelect(item, index)}
                                                >
                                                    <Grid className={`${"pl-1 pr-1 pt2"} ${classes.questype_preview}`}>
                                                        <Badge
                                                            color="secondary"
                                                            className="badgebig"
                                                            badgeContent={getQuestionBadge(item.qusType)}
                                                        ></Badge>
                                                    </Grid>
                                                    <ListItemText
                                                        className={"pl-1"}
                                                        primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                                                        disableTypography={false}
                                                        primary={item.qusText}
                                                    />
                                                </ListItem>
                                            </Drag>
                                        )
                                            : (
                                                <Drag key={item._id} dataItem={`question%/-+*/%${item.qusText}`} type="question" dropEffect="copy">

                                                    <ListItem
                                                        key={index + "qus"}
                                                        // onMouseDown={() => handleMenuItemMouseDown()} 
                                                        onMouseUp={() => handleMenuItemMouseUp()}
                                                        className={`${classes.category_quest_list} ${"quest_list"} ${checkedQues.includes(item._id) ? classes.categoryques_Selected : ""}`}
                                                        onClick={handlequesSelect(item, index)}
                                                    >
                                                        <Grid className={`${"pl-1 pr-1 pt2"} ${classes.questype_preview}`}>
                                                            <Badge
                                                                color="secondary"
                                                                className="badgebig"
                                                                badgeContent={getQuestionBadge(item.qusType)}
                                                            ></Badge>
                                                        </Grid>
                                                        <ListItemText
                                                            className={"pl-1"}
                                                            primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
                                                            disableTypography={false}
                                                            primary={item.qusText}
                                                        />
                                                    </ListItem>
                                                </Drag>
                                            )

                                }
                            </List>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}

// default props
CategoryQuestPreview.defaultProps = {
    classes: {}
};

// prop types
CategoryQuestPreview.propTypes = {
    classes: PropTypes.object
};

export default CategoryQuestPreview;
